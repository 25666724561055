/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';
import getConfig from '../../../getConfig';


const propTypes = {
    template: PropTypes.object,
    selectedTemplateId: stringOrNumberPropTypes,
    setSelectedTemplateId: PropTypes.func,
    translations: PropTypes.object,
    editAction: PropTypes.func,
};

const LayoutTemplateController = ({
    template,
    selectedTemplateId,
    setSelectedTemplateId,
    translations,
    onPreviewClick,
    editAction,
    userFile,
}) => {
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const [dropdownOpen, setOpen] = useState(false);

    const toggle = () => setOpen(!dropdownOpen);

    const handleSelectTemplate = () => {
        setSelectedTemplateId(template.templateId);
    };

    const handleEditAction = () => {
        editAction(template.templateId);
    };

    const handlePreview = (type) => {
        onPreviewClick(template.templateId, type);
    }

    const generateGradient = () => {
        const step = 100.0 / template.colors.length;
        let style = '';
        for(let i = 0; i < template.colors.length; i++) {
            style = style + template.colors[i] + ' ' + ((i + 0) * step).toFixed(2) + '%, ';
            style = style + template.colors[i] + ' ' + ((i + 1) * step).toFixed(2) + '%';
            if (i !== template.colors.length - 1) {
                style = style + ', '
            }
        }
        return 'linear-gradient(-45deg, ' + style + ')';
    }

    return (
        <div className={clsx(styles.container, selectedTemplateId === template.templateId && styles.highlightSelected)}>
            <div className='w-100 d-flex justify-content-center align-items-center'>
                <div style={{width: '100%', height: '30px', background: generateGradient()}}>
                </div>
                <Button className='mx-2' style={{height: '40px', minWidth: '120px'}} color={'primary'} onClick={handleSelectTemplate} disabled={selectedTemplateId === template.templateId}>
                    <i className={clsx('fa fa-check mr-2')}/>
                    {
                        selectedTemplateId === template.templateId
                            ? <span>{translations.buttons.selected}</span>
                            : <span>{translations.buttons.select}</span>
                    }
                </Button>
                <Button className='mx-2' style={{height: '40px', minWidth: '45px'}} color={'success'} onClick={handleEditAction}>
                    <i className={clsx('fa fa-edit')}/>
                    {/*<span>{translations.buttons.edit}</span>*/}
                </Button>
                {!userFile &&
                    <Button className='mx-2' style={{height: '40px', minWidth: '45px'}} color={'warning'} onClick={() => { handlePreview('system'); }}>
                        <i className={clsx('fa fa-eye')}/>
                        {/*<span>{translations.buttons.edit}</span>*/}
                    </Button>
                }
                {userFile &&
                    <ButtonDropdown className='mx-2' style={{height: '40px', minWidth: '45px'}} color={'warning'} isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret className="btn-warning">
                            <i className={clsx('fa fa-eye')}/>
                            {/*<span>{translations.buttons.preview}</span>*/}
                        </DropdownToggle>
                        <DropdownMenu right={true}>
                            <DropdownItem onClick={() => { handlePreview('system'); }}>{translations.buttons.systemCSS}</DropdownItem>
                            <DropdownItem onClick={() => { handlePreview('user'); }} disabled={!(userFile)}>{translations.buttons.userCSS}</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                }
            </div>
        </div>
    );
};

LayoutTemplateController.propTypes = propTypes;

export default LayoutTemplateController;