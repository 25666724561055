import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { Input, UncontrolledTooltip, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
    max: PropTypes.number,
    min: PropTypes.number,
    required: PropTypes.bool,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    step: PropTypes.string,
    symbol: PropTypes.string,
    disabled: PropTypes.bool,
    plaintext: PropTypes.bool,
    readOnly: PropTypes.bool,
    symbolLabel: PropTypes.string
};

const FormFieldTextOrNumber = ({
    name, label, max, min, className, tooltipText = null, symbol = null, step = '1',
    required = false, type = 'text', inputClassName = null, disabled, onChange, innerRef,
    plaintext = false, readOnly = false, defaultValue, mutator, parse, initialValue
}) => {
    return (
        <Field
            name={name}
            defaultValue={defaultValue}
            initialValue={initialValue}
            parse={value => {
                    if(mutator){
                        mutator(value);
                    }

                    if(parse){
                        return parse(value);
                    }

                    return value;
                }}
            >
            {
                props => {
                    return <span className={className}>
                        {tooltipText &&
                            <React.Fragment>
                                <i className="fa fa-info-circle mr-1 text-primary" id={`tooltip-${name}`} />
                                <UncontrolledTooltip placement="top" target={`tooltip-${name}`}>
                                    {tooltipText}
                                </UncontrolledTooltip>
                            </React.Fragment>
                        }
                        <span>{label}</span>
                        {symbol ? (
                            <InputGroup>
                                <Input
                                    readOnly={readOnly}
                                    plaintext={plaintext}
                                    innerRef={innerRef}
                                    {...props.input}
                                    max={max}
                                    min={min}
                                    required={required}
                                    type={type}
                                    step={step}
                                    disabled={disabled}
                                    onChange={onChange ? onChange(props.input.onChange) : props.input.onChange}
                                />
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{symbol}</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        ) : (
                            <React.Fragment>
                                <Input
                                    plaintext={plaintext}
                                    readOnly={readOnly}
                                    innerRef={innerRef}
                                    {...props.input}
                                    max={max}
                                    min={min}
                                    required={required}
                                    type={type}
                                    step={step}
                                    className={inputClassName}
                                    disabled={disabled}
                                    onChange={onChange ? onChange(props.input.onChange) : props.input.onChange}
                                    invalid={!!(props.meta.error || props.meta.submitError) && !!props.meta.touched}
                                />
                                {(props.meta.error || props.meta.submitError) && props.meta.touched && (<small className='text-danger'>{props.meta.error || props.meta.submitError}</small>)}
                            </React.Fragment>
                        )}
                    </span>;
                }
            }
        </Field>
    );
};

FormFieldTextOrNumber.propTypes = propTypes;

export default FormFieldTextOrNumber;