import { useState } from 'react';

const useAddFetch = fetchHandler => {
    const [ id, setId ] = useState(null);

    const resetId = () => { setId(null); };

    const hydrateValues = async data => {
        const fetchedValues = await fetchHandler(data);

        setId(fetchedValues);
    };

    return [ id, hydrateValues, resetId ];
};

export default useAddFetch;