import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TableGenerator from '../../generators/TableGenerator';

import { stringOrNumberPropTypes } from '../../../propTypes';

const propTypes = {
    languages: PropTypes.arrayOf(PropTypes.shape({
        id: stringOrNumberPropTypes,
        isActive: PropTypes.bool,
        name: PropTypes.string,
        englishName: PropTypes.string,
        iso: PropTypes.string,
    })),
    changeStatus: PropTypes.func,
};

const LanguagesTable = ({ languages, changeStatus, currentLanguage, checkedCheckboxIds, setCheckedCheckboxIds }) => {
    const handleUpdateSelected = (status) => () => {
        changeStatus(null, status);
    };

    const translations = useIntl().messages.languages.table;
    return (
        <TableGenerator
            items={languages}
            checkedItemsIds={checkedCheckboxIds}
            setCheckedItemsIds={setCheckedCheckboxIds}
            translations={{
                emptyTable: translations.emptyTable
            }}
            customButtons={[
                {
                    labelTrue: translations.actions.activate,
                    labelFalse: translations.actions.deactivate,
                    action: handleUpdateSelected,
                    type: 'selector',
                    isDisabled: checkedCheckboxIds.length < 1,
                },
            ]}
            columns={[
                {type: 'checkbox'},
                {
                    header: translations.name,
                    value: currentLanguage?.toLowerCase() === 'pl' ? 'name' : 'englishName'
                },
                {
                    header: translations.iso,
                    value: 'iso'
                },
                {
                    header: translations.isActive,
                    value: 'isActive',
                    type: 'switchWithAction',
                    onSwitch: changeStatus
                },
            ]}
        />
    );
};

LanguagesTable.propTypes = propTypes;

export default LanguagesTable;