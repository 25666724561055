import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { generateShopPreviewLink } from '../../../../utils/visualSettings';
import { getTranslationForIso } from '../../../../utils/components';
import FontSelector from '../../../fonts/FontSelector';

import { Container, Button, Input, Label, Card, CardHeader, CardBody } from 'reactstrap';
import LayoutColorEditionController from '../../../../components/LayoutController/LayoutColorEditionController';
import { translateFontFamilyToObject } from '../../../../utils/fonts';

const propTypes = {
    template: PropTypes.object,
    translations: PropTypes.object,
    currentLanguage: PropTypes.string,
    onSubmit: PropTypes.func
};

const TemplateEditParams = ({
    template,
    translations,
    currentLanguage,
    onSubmit,
}) => {
    const [ params, setParams ] = useState([]);

    const [ isRestoreDefaultParamsRequired, setIsRestoreDefaultParamsRequired ] = useState(false);

    const extractedParams = template?.params;

    const pickParamForController = (customValue, defaultValue, type) => {
        const value = isRestoreDefaultParamsRequired
                ? defaultValue
                : customValue
                    ? customValue
                    : defaultValue;

        if(type === 'Font') {
            return translateFontFamilyToObject(value);
        }

        return value;
    };

    const handleAddParam = (value, name) => {
        const paramPair = { [name]: value };

        setParams(prevState => ({...prevState, ...paramPair }));
    };

    const handleRestoreDefaultParams = () => {
        setIsRestoreDefaultParamsRequired(!isRestoreDefaultParamsRequired);
    };

    const getForSend = preview => {
        return {
            selectedTemplateId: template.templateId,
            selectedLayoutId: template.layoutId,
            preview: preview,
            customParams: Object.keys(params).map(key => ({
                tag: key,
                customValue: typeof params[key] === 'object' ? params[key].id : params[key],
                type: extractedParams.find(p => p.tag === key).type
            }))
        };
    };

    useEffect(() => {
        const paramArray = extractedParams.reduce((acc, param) => {
            return { ...acc, [param.tag]: pickParamForController(param.customValue, param.defaultValue, param.type) };
        }, {});

        setParams(paramArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRestoreDefaultParamsRequired]);

    const handleOnSubmit = () => {
        const objectToSend = getForSend(false);

        onSubmit(objectToSend);
    };

    const handleOnPreview = () => {
        const objectToSend = getForSend(true);

        onSubmit(objectToSend);

        // solution implemented to avoid security risk described here: https://www.Sjitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
        const shopPreviewWindow = window.open(generateShopPreviewLink('preview', currentLanguage), '_blank', 'noopener, noreferrer');

        if (shopPreviewWindow) {
            shopPreviewWindow.opener = null;
        }
    };

    return (
        <React.Fragment>
            <Container fluid>
                <Card>
                    <CardHeader>{translations.templateParamsSections.colors}</CardHeader>
                    <CardBody>
                        {
                            extractedParams.filter(({type}) => type === 'Color').map(
                                param =>
                                    <LayoutColorEditionController
                                        key={`${param.tag}-templateColor`}
                                        onAdd={handleAddParam}
                                        color={params[param.tag]}
                                        tag={param.tag}
                                        translations={translations.colorController}
                                        name={getTranslationForIso(param.name, currentLanguage)}
                                        isRestoreDefaultColorsRequired={isRestoreDefaultParamsRequired}
                                    />
                            )
                        }
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>{translations.templateParamsSections.fonts}</CardHeader>
                    <CardBody>
                        {
                            extractedParams.filter(({type}) => type === 'Font').map(
                                param =>
                                    <div key={`${param.tag}-templateFont`} className='mb-3'>
                                        <span className='d-block mb-2'>{getTranslationForIso(param.name, currentLanguage)}</span>
                                        <FontSelector
                                            setSelectedFont={value => handleAddParam(value, param.tag)}
                                            selectedFont={params[param.tag]}
                                        />
                                    </div>
                            )
                        }
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>{translations.templateParamsSections.fontSizes}</CardHeader>
                    <CardBody>
                        {
                            extractedParams.filter(({type}) => type === 'FontSize').map(
                                param =>
                                    <div key={`${param.tag}-templateFontSize`} className='mb-3'>
                                        <Label>
                                            {getTranslationForIso(param.name, currentLanguage)}
                                            <Input value={params[param.tag]} onChange={e => handleAddParam(e.target.value, param.tag)} />
                                        </Label>
                                    </div>
                            )
                        }
                    </CardBody>
                </Card>
                <div className='w-100 my-3 d-flex flex-wrap justify-content-end'>
                    <Button
                        className='mx-md-3'
                        color={'primary'}
                        onClick={handleRestoreDefaultParams}
                    >
                        <i className={clsx('fa fa-window-restore mr-2')}/>
                        <span>{translations.buttons.restoreColors}</span>
                    </Button>
                    <Button
                        className='mx-md-3'
                        color={'warning'}
                        onClick={handleOnPreview}
                    >
                        <i className={clsx('fa fa-search mr-2')}/>
                        <span>{translations.buttons.preview}</span>
                    </Button>
                    <Button
                        className='mx-md-1'
                        color={'success'}
                        onClick={handleOnSubmit}
                    >
                        <i className={clsx('fa fa-edit mr-2')}/>
                        <span>{translations.buttons.save}</span>
                    </Button>
                </div>
            </Container>
        </React.Fragment>
    );
};

TemplateEditParams.propTypes = propTypes;

export default TemplateEditParams;
