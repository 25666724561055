import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Alert } from 'reactstrap';
import ModalWithFinalForm from '../ModalWithFinalForm';
import FormFieldTextOrNumber from '../../formFields/FormFieldTextOrNumber';

const propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    translations: PropTypes.object,
    orderItem: PropTypes.object
};

const OrderDetailsEdit = ({
    isOpen,
    setIsOpen,
    onSubmit,
    translations,
    orderItem
}) => {

    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ isAlertOpen, setIsAlertOpen ] = useState(false);

    const handleOnSubmit = formData => {

        const realized = Number(formData.realized);

        if (isNaN(realized)) {
            setAlertMessage(translations.alertMessage.notANumber);
            setIsAlertOpen(true);
            return;
        }

        if (realized < 0 || realized > orderItem.amount) {
            setAlertMessage(translations.alertMessage.wrongValue + orderItem.amount);
            setIsAlertOpen(true);
            return;
        }

        setAlertMessage(null);
        setIsAlertOpen(false);

        onSubmit(formData);
    };

    const handleSetIsOpen = (open) => {
        setAlertMessage(null);
        setIsAlertOpen(false);
        setIsOpen(open);
    };

    return (
        <ModalWithFinalForm
            isOpen={isOpen}
            initialValues={orderItem}
            setIsOpen={handleSetIsOpen}
            onConfirm={handleOnSubmit}
            header={translations.modalHeader}
            buttons={{
                confirm: {
                    color: 'success',
                    label: translations.buttons.confirm,
                },
                cancel: {
                    label: translations.buttons.cancel,
                    color: 'danger'
                }
            }}
            modalBody={
                <React.Fragment>
                    <Alert color='danger' isOpen={isAlertOpen} toggle={() => setIsAlertOpen(false)} >
                        <span>{alertMessage}</span>
                    </Alert>
                    <Row className='my-2'>
                        <FormFieldTextOrNumber
                            className="w-100"
                            type='text'
                            name='realized'
                            label={translations.labels.realized}
                        />
                    </Row>
                </React.Fragment>
            }
        />
    );
};

OrderDetailsEdit.propTypes = propTypes;

export default OrderDetailsEdit;