const predefinedSearch = {
    title: '[EN] Wyszukiwanie predefiniowane',
    actions: {
        add: '[EN] DODAJ WYSZUKIWANIE'
    },
    table: {
        emptyPlaceholder: '[EN] [BRAK NAZWY]',
        headerLabels: {
            name: '[EN] Nazwa',
            actions: '[EN] Akcje',
        },
        deleteModalt: {
            header: '[EN] Usuwanie wyszukiwania predefiniowanego',
            body: '[EN] Czy na pewno chcesz usunąć to wyszukiwanie?',
            confirm: '[EN] USUŃ',
            cancel: '[EN] ANULUJ',
        },
        tooltips: {
            remove: '[EN] Usuń',
            edit: '[EN] Edytuj',
        },
    }
};

export default predefinedSearch;