import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleLogout } from '../../../redux/methods/auth';

import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import avatarPlaceholder from '../../../assets/avatar_placeholder.png';

const propTypes = {
    user: PropTypes.object,
    handleLogout: PropTypes.func
};

const HeaderUserController = ({ user, handleLogout, history }) => {
    const onClickLogout = () => {
        handleLogout();
    };

    const handleAccountSettingsRedirect = () => {
        history.push('/panelUsers/account');
    };

    const intl = useIntl();
    const translations = intl.messages.defaultLayout.appGlobal;

    return (
        <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
                {/* TODO: Implement user avatar in BE */}
                <img src={avatarPlaceholder} className="img-avatar" alt={user.name}/>
            </DropdownToggle>
            <DropdownMenu right>
                {
                    user &&
                    <DropdownItem className='d-flex flex-column' onClick={handleAccountSettingsRedirect}>
                        <span className='font-weight-bold'>{user.firstName}</span>
                        <span>{user.email}</span>
                        <span>{user.companyName}</span>
                        <span>{user.nip}</span>
                        <span className='font-weight-bold text-primary'>{user.role}</span>
                    </DropdownItem>
                }
                <DropdownItem onClick={onClickLogout}>
                    <i className="fa fa-lock"/>
                    <span>{translations.logout}</span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

HeaderUserController.propTypes = propTypes;

const mapStateToProps = state => ({
    user: state.auth.user
});

const mapDispatch = {
    handleLogout
};

export default withRouter(connect(mapStateToProps, mapDispatch)(HeaderUserController));