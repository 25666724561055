const exchangeRates = {
    view: {
        title: 'Lista kursów walut',
        actions: {
            addCurrency: 'DODAJ KURS WALUT',
        },
        headerLabels: {
            primaryCurrency: 'Waluta główna',
            secondaryCurrency: 'Waluta przyrównywana',
            exchangeRate: 'Kurs waluty',
            dateFrom: 'Obowiązuje od',
            dateTo: 'Obowiązuje do',
            actions: 'Akcje',
        },
        tooltips: {
            edit: 'Edytuj kurs walut',
            remove: 'Usuń kurs walut',
        },
        deleteModal: {
            header: 'Usuń kurs walut',
            body: 'Czy jesteś pewien, że chcesz usunąć kurs walut?',
            cancel: 'WRÓC',
            confirm: 'USUŃ',
        },
    },
    searchForm: {
        currency: 'Waluta główna:',
        dateFrom: 'Obowiazuje od:',
        dateTo: 'Obowiązuje do:',
        showActive: 'Pokaż dla aktywnych walut',
        action: 'SZUKAJ',
        reset: 'RESETUJ',
        currencyPlaceholder: 'dowolna',
    },
    edit: {
        tabs: {
            basicData: {
                labels: {
                    rate: 'Kurs waluty',
                    primaryCurrency: 'Waluta główna',
                    secondaryCurrency: 'Waluta przyrównywana',
                    validFrom: 'Obowiązuje od',
                    validTo: 'Obowiązuje do',
                },
                title: 'Dane podstawowe'
            },
            errorModalText: 'Wystapił błąd podczas edytowania kursu walutowego.',
            errorModalClose: 'ZAMKNIJ',
        }
    }
};

export default exchangeRates;
