import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { Container, Row, Button } from 'reactstrap';

import { getTranslationForIso } from '../../../../../utils/components';
import useFetch from '../../../../../hooks/useFetch';
import { getCeneoMappings } from '../../../../../redux/methods/integrations';

import CategoryMappingModal from '../../../../../components/modals/CategoryMappingModal';
import CeneoCategoriesTable from '../../../../../components/tables/CeneoCategoriesTable';
import DefaultSpinner from '../../../../../components/loaders/DefaultSpinner';

const propTypes = {
    translations: PropTypes.object,
    categoryTree: PropTypes.array,
    currentLanguage: PropTypes.string,
    addAction: PropTypes.func,
    deleteAction: PropTypes.func,
};

const CategoryMappingSection = ({
    translations,
    categoryTree,
    currentLanguage,
    addAction,
    deleteAction,
    ceneoCategories,
    getCeneoMappings,
    forceRefetch,
}) => {

    const [ mappings ] = useFetch(getCeneoMappings, null, [], forceRefetch);

    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const handleAddAction = (shopCategoryId, externalCategoryId) => {
        const objectToSend = {
            categoryId: shopCategoryId,
            ceneoCategoryId: externalCategoryId,
        };

        addAction(objectToSend);
        setIsModalOpen(false);
    };

    const handleAddMapping = () => {
        setIsModalOpen(true);
    };

    const translateMappings = (mappings) => {
        return mappings.map(mapping => ({
            ...mapping,
            categoryName: getTranslationForIso(mapping.categoryName, currentLanguage),
            ceneoCategoryName: getTranslationForIso(mapping.ceneoCategoryName, currentLanguage)
        }));
    };

    return ceneoCategories && mappings ? (
        <Container fluid>
            <Row>
                <CeneoCategoriesTable
                    translations={translations.table}
                    removeAction={deleteAction}
                    mappings={translateMappings(mappings)}
                />
            </Row>
            <Row>
                {ceneoCategories.categoryTree.length == 0 && (
                    <React.Fragment>{translations.categoriesNotImported}</React.Fragment>
                )}
                {ceneoCategories.categoryTree.length > 0 && (
                    <div className='w-100 my-3 d-flex flex-wrap justify-content-end'>
                        <Button
                            className='mx-md-3'
                            color={'success'}
                            onClick={handleAddMapping}
                            disabled={!ceneoCategories}>
                            <i className={clsx('fa fa-plus mr-2')}/>
                            <span>{translations.buttons.addMapping}</span>
                        </Button>
                    </div>
                )}
            </Row>
            <CategoryMappingModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                categoryTree={categoryTree}
                currentLanguage={currentLanguage}
                translations={translations}
                externalCategories={ceneoCategories.categoryTree}
                addAction={handleAddAction}
            />
        </Container>
    ) : <DefaultSpinner isLoading={true} />;
};

CategoryMappingSection.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.ceneoMappings,
    isLoading: state.loaders.global,
});

const mapDispatch = {
    getCeneoMappings,
};

export default connect(mapStateToProps, mapDispatch)(CategoryMappingSection);