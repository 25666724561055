import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import useSearchConfig from '../../../hooks/useSearchConfig';
import useAddFetch from '../../../hooks/useAddFetch';

import { addService } from '../../../redux/methods/products';

import { Container, Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';

import OtherServiceSearchForm from '../../../components/OtherServiceSearchForm';

import { otherServicesSearchConfig } from '../../../config/searchConfigs';

import styles from './index.module.scss';

import { withFormUtils } from '../../../hoc/withFormUtils';
import CategoryTreeSection from '../../../features/products/categoryAssignSections/CategoryTreeSection';

import Table from './Table';

const propTypes = {
    history: PropTypes.object,
    getProducts: PropTypes.func,
    addService: PropTypes.func,
    forceRefetch: PropTypes.bool,
    storeCurrentLanguage: PropTypes.string,
    deleteProduct: PropTypes.func,
};

const OtherServicesList = ({
    utils,
    history,
    addService,
    isConnectedToERP,
    isShopCategoryActive,
}) => {
    const intl = useIntl();
    const translations = intl.messages.services.list.view;

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(otherServicesSearchConfig);
    const [ id, add ] = useAddFetch(addService);

    useEffect(() => {
        if (id) {
            history.push(`/otherServices/list/edit?serviceID=${id}`);
        }
    }, [history, id]);

    const handleAddService = id => {
        add(4, id);
    };

    const handleOpenSelectCategoryModal = () => {
        utils.selectCategory(handleAddService, translations.modal.body);
    };

    const [categoryTreeIsOpen, setCategoryTreeIsOpen] = useState(true);
    const toggleCategoryTree = () => setCategoryTreeIsOpen(!categoryTreeIsOpen);

    const showTwoTrees = isConnectedToERP && isShopCategoryActive;
    return (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className="animated fadeIn">
                <Card>
                    <CardHeader>
                        <i className={clsx('fa fa-align-justify')}></i>
                        <span>{translations.title}</span>
                    </CardHeader>
                    <CardBody className="position-relative">
                        <main>
                            <OtherServiceSearchForm
                                performServiceSearch={changeSearchConfig}
                                searchConfig={searchConfig}
                            />
                            <Button className='mt-3 mb-0' onClick={toggleCategoryTree} color="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                                {categoryTreeIsOpen ? translations.hideCategoryTreeSelect : translations.showCategoryTreeSelect}
                            </Button>
                            <Row>
                                {categoryTreeIsOpen && (
                                    <Col xs='12' xl='3'>
                                        <div className="pt-3">
                                            <CategoryTreeSection
                                                translations={translations.categoryTree}
                                                setSearchConfig={(categoryId) => changeSearchConfig({ ...searchConfig, categoryId })}
                                                currentLanguage={utils.currentLanguage()}
                                                scroll={false}
                                                showTwoTrees={showTwoTrees}
                                                searchConfig={searchConfig}
                                            />
                                        </div>
                                    </Col>
                                )}
                                <Col xs='12' xl={categoryTreeIsOpen ? '9' : '12'}>
                                    <Table
                                        searchConfig={searchConfig}
                                        changeSearchConfig={changeSearchConfig}
                                        changePage={changePage}
                                    />
                                </Col>
                            </Row>
                        </main>
                        <aside className={styles.footer}>
                            <Button color="primary" onClick={handleOpenSelectCategoryModal}>
                                <i className="fa fa-plus mr-2"/>
                                <span>{translations.actions.addService}</span>
                            </Button>
                        </aside>
                    </CardBody>
                </Card>
            </div>
        </Container>
    );
};

OtherServicesList.propTypes = propTypes;

const mapDispatch = {
    addService,
};

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
    isShopCategoryActive: state.appInit.isShopCategoryActive,
});

export default withFormUtils(connect(mapStateToProps, mapDispatch)(OtherServicesList));