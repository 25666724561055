import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import SearchForm from '../../../components/generators/SearchForm';

const propTypes = {
    handleSubmit: PropTypes.func,
};

const CountrySearchForm = ({ handleSubmit, filters }) => {
    const intl = useIntl();
    const translations = intl.messages.countries.searchForm;

    const onSubmit = formData => {
        const iso = formData.countryIso ? formData.countryIso.toUpperCase() : null;
        const country = formData.countryName ? formData.countryName.toLowerCase() : null;

        handleSubmit({ iso: iso, country: country });
    };

    return (
        <SearchForm
            translations={{
                buttons: {
                    reset: translations.reset,
                    submit: translations.action
                }
            }}
            inputs={[
                {
                    label: translations.name.label,
                    name: 'countryName',
                    type: 'text',
                    value: filters.country
                },
                {
                    label: translations.iso.label,
                    name: 'countryIso',
                    type: 'text',
                    value: filters.iso
                },
            ]}
            searchConfig={{
                countryName: filters.country,
                countryIso: filters.iso
            }}
            performSearch={onSubmit}
        />
    );
};

CountrySearchForm.propTypes = propTypes;

export default CountrySearchForm;