export const bannerImageObjectConfig = {
    bannerImageId: null,
    bannerid: null,
    lp: null,
    link: null,
    buttonLink: null,
    activeDesktop: true,
    activeTablet: true,
    activeMobile: true,
    activeInLanguages: [],
    desktop: {
        device: 'desktop',
        imageSource: null,
        content: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        imageAlternativeText: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        button: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        horizontalalignment: 'Right',
        verticalalignment: 'Bottom',
        buttonid: null,
        buttonlink: null
    },
    tablet: {
        device: 'tablet',
        imageSource: null,
        content: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        imageAlternativeText: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        button: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        horizontalalignment: 'Right',
        verticalalignment: 'Bottom',
        buttonid: null,
        buttonlink: null
    },
    mobile: {
        device: 'mobile',
        imageSource: null,
        content: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        imageAlternativeText: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        button: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        horizontalalignment: 'Right',
        verticalalignment: 'Bottom',
        buttonid: null,
        buttonlink: null
    }
};

export const animatedBannerImageObjectConfig = {
    animatedBannerImageId: null,
    animatedBannerId: null,
    lp: null,
    link: null,
    activeDesktop: true,
    activeTablet: true,
    activeMobile: true,
    activeInLanguages: [],
    desktop: {
        device: 'desktop',
        imageSource: null,
        imageAlternativeText: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        slideSettings: null
    },
    tablet: {
        device: 'tablet',
        imageSource: null,
        imageAlternativeText: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        slideSettings: null
    },
    mobile: {
        device: 'mobile',
        imageSource: null,
        imageAlternativeText: [ {
            iso: "Pl",
            value: ""
        },
        {
            iso: "En",
            value: ""
        },
        {
            iso: "Ru",
            value: ""
        },
        {
            iso: "Ua",
            value: ""
        }],
        slideSettings: null
    }
};

export const promotionBarSlideObjectConfig = {
    promotionBarId: null,
        content: [
            {
                iso: "Pl",
                value: ""
            },
            {
                iso: "En",
                value: ""
            },
            {
                iso: "Ru",
                value: ""
            },
            {
                iso: "Ua",
                value: ""
            }
        ],
        hint: [
            {
                iso: "Pl",
                value: ""
            },
            {
                iso: "En",
                value: ""
            },
            {
                iso: "Ru",
                value: ""
            },
            {
                iso: "Ua",
                value: ""
            }
        ],
        activeInLanguage: [],
        dateFrom: null,
        dateTo: null,
        link: "",
        active: false,
};

export const sectionTypes = [
    {
        id: 1,
        label: 'SliderWithButton'
    },
    {
        id: 2,
        label: 'ArticlesWithBookmarks'
    },
    {
        id: 3,
        label: 'Articles'
    },
    {
        id: 4,
        label: 'PhotoWithText'
    },
    {
        id: 5,
        label: 'Tiny'
    },
    {
        id: 6,
        label: 'Promoted'
    },
    {
        id: 7,
        label: 'DoubleImage'
    },
    {
        id: 8,
        label: 'Manufacturer'
    },
    {
        id: 9,
        label: 'Video'
    },
    {
        id: 10,
        label: 'AnimatedBanner'
    },
    {
        id: 11,
        label: 'ContactForm'
    },
    {
        id: 12,
        label: 'Map'
    }
];

export const defaultHeightConfig = {
    desktop: 500,
    tablet: 400,
    mobile: 250,
};