import React from 'react';
import PropTypes from 'prop-types';

import { generateEditLinkForDiscount, generateStatuses } from '../../../utils/discounts';
import { getTranslationFromEnum } from '../../../utils/components';

import TableGenerator from '../../generators/TableGenerator';

import { discountPropTypes, deleteModalPropTypes } from '../../../propTypes';
import { discountTypeEnum, discountRangeEnum, discountStatusEnum } from '../../../enums/discounts';

const propTypes = {
    discounts: PropTypes.arrayOf(discountPropTypes),
    headers: PropTypes.shape({
        status: PropTypes.string,
        name: PropTypes.string,
        code: PropTypes.string,
        validFrom: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        validTo: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        currency: PropTypes.string,
        type: PropTypes.string,
        range: PropTypes.string,
        actions: PropTypes.string,
    }),
    fillers: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        currency: PropTypes.string,
        type: PropTypes.string,
        range: PropTypes.string,
    }),
    translations: PropTypes.shape({
        tooltips: PropTypes.shape({
            edit: PropTypes.string,
            remove: PropTypes.string,
        }),
        deleteModal: deleteModalPropTypes,
        emptyTable: PropTypes.string,
        type: PropTypes.shape({
            percentage: PropTypes.string,
            amount: PropTypes.string,
        }),
        range: PropTypes.shape({
            entireOrder: PropTypes.string,
            specificProducts: PropTypes.string,
            shipmentMethod: PropTypes.string,
            buyXGetYSingle: PropTypes.string,
            buyXGetYGroup: PropTypes.string,
        }),
        statusLabels: PropTypes.shape({
            created: PropTypes.string,
            visible: PropTypes.string,
            hidden: PropTypes.string,
            utilized: PropTypes.string,
        }),
    }),
    removeHandler: PropTypes.func,
    history: PropTypes.object,
};

const DiscountsTable = ({
    discounts,
    headers,
    fillers,
    translations,
    removeHandler,
    history,
    currentPage,
    pageSize,
    performPageSizeSelect,
}) => {
    const deleteAction = discountId => () => {
        removeHandler(discountId);
    };

    const defaultActionHandler = id => () => {
        history.push(generateEditLinkForDiscount(id));
    };

    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            pageSize={pageSize}
            currentPage={currentPage}
            items={discounts}
            translations={translations}
            actions={{deleteAction}}
            editLink={generateEditLinkForDiscount}
            defaultAction={defaultActionHandler}
            expectedActions={['edit', 'delete']}
            columns={[
                {
                    header: headers.status,
                    value: 'statusId',
                    type: 'badge',
                    config: generateStatuses(discountStatusEnum, translations.statusLabels)
                },
                {
                    header: headers.name,
                    value: 'name',
                    filler: fillers.name
                },
                {
                    header: headers.code,
                    value: 'code',
                    filler: fillers.code
                },
                {
                    header: headers.validFrom,
                    value: 'validFrom',
                    type: 'date'
                },
                {
                    header: headers.validTo,
                    value: 'validTo',
                    type: 'date'
                },
                {
                    header: headers.currency,
                    value: 'currency',
                    filler: fillers.currency
                },
                {
                    header: headers.type,
                    value: 'discountType',
                    filler: fillers.type,
                    customSelector: getTranslationFromEnum(translations.type, discountTypeEnum)
                },
                {
                    header: headers.range,
                    value: 'discountRange',
                    filler: fillers.range,
                    customSelector: getTranslationFromEnum(translations.range, discountRangeEnum)
                },
                {
                    header: 'Priorytet',
                    value: 'priority'
                },
                {
                    header: headers.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

DiscountsTable.propTypes = propTypes;

export default DiscountsTable;