const colorEditionController = {
    colorsHeader: 'Partial colors:',
    emptyPlaceholder: 'No partial colors present',
    actions: {
        add: 'ADD PARTIAL COLOR',
        edit: 'Edit partial color',
        remove: 'Remove partial color'
    }
};

export default colorEditionController;