const taxRates = {
    title: '[EN] Stawki VAT',
    table: {
        emptyTable: '[EN] Brak stawek VAT',
        headerLabels: {
            name: '[EN] Nazwa',
            code: '[EN] Kod',
            amount: '[EN] Stawka',
        }
    }
};

export default taxRates;