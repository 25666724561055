const uploadFile = {
    buttons: {
        upload: 'PRZEŚLIJ PLIK',
        remove: 'USUŃ PLIK',
        edit: 'PODMIEŃ PLIK',
        closeModal: 'ZAMKNIJ',
    },
    errorHeader: 'Wystąpił błąd podczas przesyłania pliku',
    errorBody: 'Dopuszczalne rozszerzenia to ',
    errorBodySize: 'Dopuszczalny rozmiar pliku to ',
    sizeLabel: 'Maksymalny rozmiar pliku: ',
    extLabel: 'Dopuszczalne rozszerzenia: ',
    preferWidth: 'Preferowana szerokość: ',
    preferHeight: 'Preferowana wysokość: ',
    deleteModal: {
        delete: 'USUŃ',
        cancel: 'WRÓĆ',
        body: 'Czy napewno chcesz usunąć plik?',
        header: 'Usuń plik'
    },
    graphicGuides: {
        default: 'Preferowana szerokość zdjęcia to:',
    }
};

export default uploadFile;