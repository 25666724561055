import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getIntegrationFacebook, updateIntegrationFacebook } from '../../../redux/methods/integrations';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';

import { Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';

const propTypes = {
    getIntegrationFacebook: PropTypes.func,
    updateIntegrationFacebook: PropTypes.func,
};

const Facebook = ({ getIntegrationFacebook, updateIntegrationFacebook }) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.facebook;

    const [ data ] = useFetch(getIntegrationFacebook);

    return data ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-facebook'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateIntegrationFacebook}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>
                                <div className='mt-3'>
                                    <FormFieldTextOrNumber
                                        label={translations.label}
                                        name='code'
                                    />
                                </div>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconClass='fa fa-facebook'/>;
};

const mapDispatch = {
    getIntegrationFacebook,
    updateIntegrationFacebook,
};

Facebook.propTypes = propTypes;

export default connect(null, mapDispatch)(Facebook);