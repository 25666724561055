const orderStatuses = {
    formsTable: {
        title: "Order statuses",
        headers: {
            name: 'Name',
            description: 'Description',
            erpOrderStatusName: 'Name from ERP'
        },
        tooltips: {
            edit: 'Edit order status',
        },
        editModal: {
            title: 'Editing the order status',
            inputs: {
                name: 'Name',
                description: 'Description',
                erpOrderStatusId: 'Order status from ERP system',
                color: 'Color',
                colorButton: 'Select',
            },
            buttons: {
                remove: 'BACK',
                save: 'SAVE'
            },
            nameModalHeader: 'Editing the order status name',
            descriptionModalHeader: 'Edit order status description'
        }
    }
};

export default orderStatuses;