const appGlobal = {
    errorModal: {
        close: "Zamknij",
        header: "Podczas wykonywania operacji wystąpił błąd"
    },
    editResult: {
        success: 'Zmiany zostały zapisane!',
    },
    logout: 'Wyloguj',
    refresh: 'Odśwież widok',
};

export default appGlobal;