const accountSettings = {
    title: '[EN] Ustawienia konta',
    personalDataTab: {
        title: '[EN] Dane osobowe',
        inputs: {
            name: '[EN] Imię',
            surname: '[EN] Nazwisko',
            companyName: '[EN] Nazwa firmy',
            nip: '[EN] NIP'
        }
    },
    passwordsTab: {
        title: '[EN] Hasła',
        inputs: {
            oldPassword: '[EN] Aktualne hasło',
            newPassword: '[EN] Nowe hasło',
            confirmNewPassword: '[EN] Potwierdź nowe hasło'
        },
        errors: {
            newPasswordNotRepeatedCorrectly: '[EN] Podane hasło i potwierdzenie hasła różnią się'
        }
    },
    emailTab: {
        title: '[EN] E-mail',
        inputs: {
            email: '[EN] Adres e-mail'
        },
        submitSucceeded: '[EN] Mail potwierdzający zmianę został wysłany na Twój aktualny adres email. Zaloguj się, aby potwierdzić zmianę. Jeśli nie otrzymasz maila w przeciągu 2h - prosimy skontaktuj się z Administratorem.'
    }
};

export default accountSettings;