import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Field } from 'react-final-form';

import styles from './index.module.scss';

const propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
		thin: PropTypes.bool,
};

const FormFieldSwitchPill = ({ label, name, disabled, thin, className }) => {
    return (
        <div className={clsx('d-flex align-items-center', className)}>
            <Field name={name} type='checkbox'>
                {
                    props => (
                        <label className={clsx("switch switch-pill switch-success m-0", styles.switchAdjust)}>
                            <input
                                type="checkbox"
                                name={props.input.name}
                                value={props.input.value}
                                onChange={props.input.onChange}
                                className="switch-input"
                                checked={props.input.checked}
                                disabled={disabled}
                            />
                            <span
                                className={clsx("switch-slider", !props.input.checked &&  "bg-danger border-danger")}
                            ></span>
                        </label>
                    )
                }
            </Field>
						{thin
							? <span className='ml-3 font-weight-normal'>{label}</span>
							: <span className='ml-3 font-weight-bolder'>{label}</span>
						}
        </div>
    );
};

FormFieldSwitchPill.propTypes = propTypes;

export default FormFieldSwitchPill;