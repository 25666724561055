import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import leftBottomCorner from '../../../assets/leftBottomCorner.png';
import leftTopCorner from '../../../assets/leftTopCorner.png';
import rightBottomCorner from '../../../assets/rightBottomCorner.png';
import rightTopCorner from '../../../assets/rightTopCorner.png';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, TabContent } from "reactstrap";

import TabulatorNav from '../../../components/TabulatorNav';

import SimpleColorPicker from '../../SimpleColorPicker';

import { formUtilsHocPropTypes } from '../../../propTypes';

import AnimatedBannerSettingsModalPreview from '../AnimatedBannerSettingsModalPreview';

import { getAnchorLocation } from '../../../utils/homePage';

import clsx from 'clsx';

const propTypes = {
    settings: PropTypes.object,
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    utils: formUtilsHocPropTypes
};

const AnimatedBannerSettingsModal =  ({
    element,
    elements,
    isModalOpen,
    setIsModalOpen,
    onSubmit
}) => {

    const [initialization, setInitialization] = useState(true);
    const [originalItem, setOriginalItem] = useState(element);
    const [item, setItem] = useState(JSON.parse(JSON.stringify(originalItem)));

    const [activeTab, setActiveTab] = useState(0);
    const [previous] = useState({
        anchor: element.position.anchor,
        parentIndex: element.position.parentIndex,
        parentAnchor: element.position.parentAnchor });

    const setPosition = (obj, callback) => {
        const newItem = { ...item };
        newItem.position = Object.assign(newItem.position, obj);
        setItem(newItem);

        if (typeof (callback) === 'function') {
            callback(newItem);
        }
    };

    const setProperty = (obj) => {
        const newItem = { ...item };
        newItem.properties = Object.assign(newItem.properties, obj);
        setItem(newItem);
    };

    const setAnimationIn = (obj) => {
        const newItem = { ...item };
        newItem.animation.in = obj === null ? null : Object.assign(newItem.animation.in ?? {}, obj);
        setItem(newItem);
    };

    const setAnimationOut = (obj) => {
        const newItem = { ...item };
        newItem.animation.out = obj === null ? null : Object.assign(newItem.animation.out ?? {}, obj);
        setItem(newItem);
    };

    const setHover = (obj) => {
        const newItem = { ...item };
        newItem.hover = obj.type === 'none' ? null : Object.assign(newItem.hover ?? {}, obj);
        setItem(newItem);
    };

    const setHref = (obj) => {
        const newItem = { ...item };
        newItem.href = obj.url === null || obj.url.trim() === '' ? null : Object.assign(newItem.href ?? {}, obj);
        setItem(newItem);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = () => {

        const translation = {
            left: (item.position.anchor == 'lt' || item.position.anchor == 'lb')
                ? (previous.anchor === 'rt' || previous.anchor === 'rb' ? -item.position.width : 0)
                : (previous.anchor === 'lt' || previous.anchor === 'lb' ? +item.position.width : 0),
            top: (item.position.anchor == 'lt' || item.position.anchor == 'rt')
                ? (previous.anchor === 'lb' || previous.anchor === 'rb' ? -item.position.height : 0)
                : (previous.anchor === 'lt' || previous.anchor === 'rt' ? +item.position.height : 0),
        };

        const calculateRelativePosition = (position) => {
            if (position.parentIndex === -1) {
                return { left: 0, top: 0 };
            }

            const parentRelativePosition = calculateRelativePosition(elements[position.parentIndex].position);
            const itemRelativePosition = getAnchorLocation(elements[position.parentIndex].position, position.parentAnchor);

            return {
                left: parentRelativePosition.left + itemRelativePosition.left,
                top: parentRelativePosition.top + itemRelativePosition.top
            };
        };

        const previousRelativePosition = calculateRelativePosition(previous);
        const currentRelativePosition = calculateRelativePosition(item.position);

        const newPosition = {
            left: item.position.left + translation.left + previousRelativePosition.left - currentRelativePosition.left,
            top: item.position.top + translation.top + previousRelativePosition.top - currentRelativePosition.top,
        };

        setPosition(newPosition, (item) => {
            setOriginalItem(item);
        });
    };

    useEffect(() => {
        if (initialization) {
            setInitialization(false);
        } else {
            if (originalItem !== null) {
                onSubmit(originalItem);
                setIsModalOpen(false);
            }
        }
    }, [originalItem]);

    return (
        <Modal isOpen={isModalOpen} size='xl'>
            <ModalHeader>Edycja elementu</ModalHeader>
            <ModalBody>
                <TabulatorNav
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tabsList={[
                        {iso: 0, label: 'Wygląd'},
                        {iso: 1, label: 'Animacje'},
                        {iso: 2, label: 'Odnośnik'},
                        {iso: 3, label: 'Ograniczenie rozmiaru'},
                        {iso: 4, label: 'Pozycjonowanie'},
                    ]}
                    withoutFlag={true}
                />
                <TabContent>
                    {activeTab === 0 &&
                        <div className="m-3">
                            <Row>
                                <AnimatedBannerSettingsModalPreview item={item} />
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-header">Cień tekstu</div>
                                        <div className="card-body">
                                            <Row>
                                                <div className="col-6">
                                                    <label>Rozmiar w poziomie [px]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.properties.textShadowOffsetX} onChange={(e) => { setProperty({textShadowOffsetX: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                                <div className="col-6">
                                                    <label>Rozmiar w pionie [px]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.properties.textShadowOffsetY} onChange={(e) => { setProperty({textShadowOffsetY: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-6">
                                                    <label>Rozmycie [px]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.properties.textShadowBlurRadius} onChange={(e) => { setProperty({textShadowBlurRadius: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                                <div className="col-6">
                                                    <label>Kolor</label>
                                                    <SimpleColorPicker value={item?.properties.textShadowColor} onSelect={(color) => { setProperty({textShadowColor: color}); }}/>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">Obramowanie elementu</div>
                                        <div className="card-body">
                                            <Row>
                                                <div className="col-6">
                                                    <label>Szerokość ramki [px]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.properties.borderWidth} onChange={(e) => { setProperty({borderWidth: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                                <div className="col-6">
                                                    <label>Zaokrąglenie narożników [px]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.properties.borderRadius} onChange={(e) => { setProperty({borderRadius: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-6">
                                                    <label>Kolor obramowania</label>
                                                    <SimpleColorPicker value={item?.properties.borderColor} onSelect={(color) => { setProperty({borderColor: color}); }}/>
                                                </div>
                                                <div className="col-6">
                                                    <label>Wygląd obramowania</label>
                                                    <select className="form-control" value={item?.properties.borderStyle} onChange={(e) => { setProperty({borderStyle: e.currentTarget.value}); }} >
                                                        <option value='dotted'>dotted</option>
                                                        <option value='dashed'>dashed</option>
                                                        <option value='solid'>solid</option>
                                                        <option value='double'>double</option>
                                                        <option value='groove'>groove</option>
                                                        <option value='ridge'>ridge</option>
                                                        <option value='inset'>inset</option>
                                                        <option value='outse'>outse</option>
                                                        <option value='none'>none</option>
                                                        <option value='hidden'>hidden</option>
                                                    </select>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-header">Cień tła</div>
                                        <div className="card-body">
                                            <Row>
                                                <div className="col-6">
                                                    <label>Rozmiar w poziomie [px]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.properties.boxShadowOffsetX} onChange={(e) => { setProperty({boxShadowOffsetX: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                                <div className="col-6">
                                                    <label>Rozmiar w pionie [px]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.properties.boxShadowOffsetY} onChange={(e) => { setProperty({boxShadowOffsetY: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-6">
                                                    <label>Rozmycie [px]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.properties.boxShadowBlurRadius} onChange={(e) => { setProperty({boxShadowBlurRadius: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                                <div className="col-6">
                                                    <label>Odstęp [%]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.properties.boxShadowSpreadRadius} onChange={(e) => { setProperty({boxShadowSpreadRadius: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-12">
                                                    <label>Kolor</label>
                                                    <SimpleColorPicker value={item?.properties.boxShadowColor} onSelect={(color) => { setProperty({boxShadowColor: color}); }}/>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">Odstęp i przeźroczystość</div>
                                        <div className="card-body">
                                            <Row>
                                                <div className="col-6">
                                                    <label>Odstęp z lewej i prawej strony [px]</label>
                                                    <input type="number" min="0" disabled={item?.type !== 'text'} className="form-control" style={{textAlign:'right'}} value={item?.properties.paddingHorizontal} onChange={(e) => { setProperty({paddingHorizontal: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                                <div className="col-6">
                                                    <label>Odstęp od góry i od dołu [px]</label>
                                                    <input type="number" min="0" disabled={item?.type !== 'text'} className="form-control" style={{textAlign:'right'}} value={item?.properties.paddingVertical} onChange={(e) => { setProperty({paddingVertical: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-6">
                                                    <label>Przeźroczystość [%]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={100 * item?.properties.opacity ?? 100} onChange={(e) => { setProperty({opacity: parseInt(e.currentTarget.value) / 100.0}); }} />
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    }
                    {activeTab === 1 &&
                        <div className="m-3">
                            <Row>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-header">Animacja początkowa</div>
                                        <div className="card-body">
                                            <Row>
                                                <div className="col-6">
                                                    <label>Typ animacji</label>
                                                    <select className="form-control" value={item?.animation?.in?.type} onChange={(e) => { setAnimationIn(e.currentTarget.value === 'none' ? null : {type: e.currentTarget.value}); }}>
                                                        <option value='none'>none</option>
                                                        <option value='opacity'>opacity</option>
                                                        <option value='slideFromLeft'>slideFromLeft</option>
                                                        <option value='slideFromRight'>slideFromRight</option>
                                                        <option value='slideFromTop'>slideFromTop</option>
                                                        <option value='slideFromBottom'>slideFromBottom</option>
                                                    </select>
                                                </div>
                                                <div className="col-6">
                                                    <label>Przebieg</label>
                                                    <select disabled={item?.animation?.in === null} className="form-control" value={item?.animation?.in?.ease} onChange={(e) => { setAnimationIn({ease: e.currentTarget.value}); }}>
                                                        <option value=''></option>
                                                        <option value='ease'>ease</option>
                                                        <option value='linear'>linear</option>
                                                        <option value='ease-in'>ease-in</option>
                                                        <option value='ease-out'>ease-out</option>
                                                        <option value='ease-in-out'>ease-in-out</option>
                                                        <option value='bounce'>bounce</option>
                                                    </select>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-6">
                                                    <label>Moment rozpoczęcia [ms]</label>
                                                    <input disabled={item?.animation?.in === null} type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.animation?.in?.moment} onChange={(e) => { setAnimationIn({moment: parseInt(e.currentTarget.value)}); }}/>
                                                </div>
                                                <div className="col-6">
                                                    <label>Czas trwania [ms]</label>
                                                    <input disabled={item?.animation?.in === null} type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.animation?.in?.duration} onChange={(e) => { setAnimationIn({duration: parseInt(e.currentTarget.value)}); }}/>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-header">Animacja końcowa</div>
                                        <div className="card-body">
                                            <Row>
                                                <div className="col-6">
                                                    <label>Typ animacji</label>
                                                    <select className="form-control" value={item?.animation?.out?.type} onChange={(e) => { setAnimationOut(e.currentTarget.value === 'none' ? null : {type: e.currentTarget.value}); }}>
                                                        <option value='none'>none</option>
                                                        <option value='opacity'>opacity</option>
                                                        <option value='slideToLeft'>slideToLeft</option>
                                                        <option value='slideToRight'>slideToRight</option>
                                                        <option value='slideToTop'>slideToTop</option>
                                                        <option value='slideToBottom'>slideToBottom</option>
                                                    </select>
                                                </div>
                                                <div className="col-6">
                                                    <label>Przebieg</label>
                                                    <select disabled={item?.animation?.out === null} className="form-control" value={item?.animation?.out?.ease} onChange={(e) => { setAnimationOut({ease: e.currentTarget.value}); }}>
                                                        <option value=''></option>
                                                        <option value='ease'>ease</option>
                                                        <option value='linear'>linear</option>
                                                        <option value='ease-in'>ease-in</option>
                                                        <option value='ease-out'>ease-out</option>
                                                        <option value='ease-in-out'>ease-in-out</option>
                                                        <option value='bounce'>bounce</option>
                                                    </select>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-6">
                                                    <label>Moment rozpoczęcia [ms]</label>
                                                    <input disabled={item?.animation?.out === null} type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.animation?.out?.moment} onChange={(e) => { setAnimationOut({moment: parseInt(e.currentTarget.value)}); }}/>
                                                </div>
                                                <div className="col-6">
                                                    <label>Czas trwania [ms]</label>
                                                    <input disabled={item?.animation?.out === null} type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.animation?.out?.duration} onChange={(e) => { setAnimationOut({duration: parseInt(e.currentTarget.value)}); }}/>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    }
                    {activeTab === 2 &&
                        <div className="m-3">
                            <Row>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-header">Animacja po wskazaniu myszką</div>
                                        <div className="card-body">
                                            <Row>
                                                <div className="col-6">
                                                    <label>Typ animacji</label>
                                                    <select className="form-control" value={item?.hover?.type} onChange={(e) => { setHover({type: e.currentTarget.value}); }}>
                                                        <option value='none'>none</option>
                                                        <option value='zoom'>zoom</option>
                                                    </select>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-header">Przycisk</div>
                                        <div className="card-body">
                                            <Row>
                                                <div className="col-12">
                                                    <label>Odnośnik po kliknięciu w element</label>
                                                    <input type="text" className="form-control" value={item?.href?.url} onChange={(e) => { setHref({url: e.currentTarget.value}); }} />
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    }
                    {activeTab === 3 &&
                        <div className="m-3">
                            <Row>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">Szerokość</div>
                                        <div className="card-body">
                                            <Row>
                                                <div className="col-6">
                                                    <label>Minimalna [px]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.position.minWidth} onChange={(e) => { setPosition({minWidth: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                                <div className="col-6">
                                                    <label>Maksymalna [px]</label>
                                                    <input type="number" min="0" className="form-control" style={{textAlign:'right'}} value={item?.position.maxWidth} onChange={(e) => { setPosition({maxWidth: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                {/*
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-header">Wysokość</div>
                                        <div className="card-body">
                                            <Row>
                                                <div className="col-6">
                                                    <label>Minimalna [%]</label>
                                                    <input type="number" className="form-control" style={{textAlign:'right'}} value={item?.position.minHeight} onChange={(e) => { setPosition({minHeight: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                                <div className="col-6">
                                                    <label>Maksymalna [%]</label>
                                                    <input type="number" className="form-control" style={{textAlign:'right'}} value={item?.position.maxHeight} onChange={(e) => { setPosition({maxHeight: parseInt(e.currentTarget.value)}); }} />
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                */}
                            </Row>
                        </div>
                    }
                    {activeTab === 4 &&
                        <div className="m-3">
                            <div className="card">
                                <div className="card-header">Przyklejenie do elementu nadrzędnego</div>
                                <div className="card-body">
                                    <Row>
                                        <div className="col-12">
                                            <label>Element nadrzędny</label>
                                            <select className="form-control" value={item?.position.parentIndex} onChange={(e) => { setPosition({parentIndex: parseInt(e.currentTarget.value)}); }}>
                                                <option value='-1'>none</option>
                                                {elements && elements.map((el,idx) => {
                                                        if (el === element) {
                                                            return null;
                                                        }
                                                        return (
                                                            <option key={idx} value={idx}>{el.properties.content}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-12 mt-3" style={{display: item?.position.parentIndex === -1 ? 'none' : 'block'}}>
                                            <label>Narożnik elementu nadrzędnego</label>
                                            <div>
                                                <button className={clsx("btn btn-sm", item.position.parentAnchor === 'lt' ? "btn-primary" : "")} onClick={() => { setPosition({parentAnchor: 'lt'}); }}>
                                                    <img style={{width: '24px', height: '24px', margin: '5px'}} src={leftTopCorner} alt='' />
                                                </button>
                                                <button className={clsx("btn btn-sm", item.position.parentAnchor === 'rt' ? "btn-primary" : "")} onClick={() => { setPosition({parentAnchor: 'rt'}); }}>
                                                    <img style={{width: '24px', height: '24px', margin: '5px'}} src={rightTopCorner} alt='' />
                                                </button>
                                                <button className={clsx("btn btn-sm", item.position.parentAnchor === 'rb' ? "btn-primary" : "")} onClick={() => { setPosition({parentAnchor: 'rb'}); }}>
                                                    <img style={{width: '24px', height: '24px', margin: '5px'}} src={rightBottomCorner} alt='' />
                                                </button>
                                                <button className={clsx("btn btn-sm", item.position.parentAnchor === 'lb' ? "btn-primary" : "")} onClick={() => { setPosition({parentAnchor: 'lb'}); }}>
                                                    <img style={{width: '24px', height: '24px'}} src={leftBottomCorner} alt='' />
                                                </button>
                                            </div>
                                            <br/>
                                            <i>Wybranie elementu nadrzędnego i jego narożnika sprawi, że wskazany w ramce poniżej &ldquo;Aktywny narożnik&rdquo; edytowanego elementu będzie &ldquo;podążał&rdquo; za wskazanym narożnikiem elementu nadrzędnego. Sprawi to, że wzajemna odległość między elementami będzie utrzymywana podczas przeskalowywania rozmiarów banera.</i>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">Aktywny narożnik</div>
                                <div className="card-body">
                                    <Row>
                                        <div className="col-12">
                                            <label>Pozycjonuj element na banerze względem poniższego narożnika</label>
                                            <div>
                                                <button className={clsx("btn btn-sm", item.position.anchor === 'lt' ? "btn-primary" : "")} onClick={() => { setPosition({anchor: 'lt'}); }}>
                                                    <img style={{width: '24px', height: '24px', margin: '5px'}} src={leftTopCorner} alt='' />
                                                </button>
                                                <button className={clsx("btn btn-sm", item.position.anchor === 'rt' ? "btn-primary" : "")} onClick={() => { setPosition({anchor: 'rt'}); }}>
                                                    <img style={{width: '24px', height: '24px', margin: '5px'}} src={rightTopCorner} alt='' />
                                                </button>
                                                <button className={clsx("btn btn-sm", item.position.anchor === 'rb' ? "btn-primary" : "")} onClick={() => { setPosition({anchor: 'rb'}); }}>
                                                    <img style={{width: '24px', height: '24px', margin: '5px'}} src={rightBottomCorner} alt='' />
                                                </button>
                                                <button className={clsx("btn btn-sm", item.position.anchor === 'lb' ? "btn-primary" : "")} onClick={() => { setPosition({anchor: 'lb'}); }}>
                                                    <img style={{width: '24px', height: '24px'}} src={leftBottomCorner} alt='' />
                                                </button>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    }
                </TabContent>
            </ModalBody>
            <ModalFooter>
                <div className='d-flex justify-content-between w-100'>
                    <Button color="danger" onClick={handleCancel}>
                        <i className="mr-2 fa fa-remove"></i>
                        <span>Anuluj zmiany</span>
                    </Button>
                    <Button color="success" type="submit" onClick={handleSubmit}>
                        <i className="mr-2 fa fa-save"></i>
                        <span>Zapisz zmiany</span>
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

AnimatedBannerSettingsModal.propTypes = propTypes;

export default AnimatedBannerSettingsModal;