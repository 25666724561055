import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getShipmentServiceParams } from '../../../../redux/methods/shipments';

import PackagePropertyTable from '../PackagePropertyTable';

const propTypes = {
    selectedOrdersList: PropTypes.array,
    serviceProperties: PropTypes.object,
    getShipmentServiceParams: PropTypes.func
};

const PackagePropertySelection = ({
    selectedOrdersList,
    serviceProperties
}) => {
    const changechangePropertyValue = (id, value, item, columnName) => {
        if (columnName === 'paramDimension1' || columnName === 'paramDimension2' || columnName === 'paramDimension3') {
            item[columnName] = parseInt(value);
        }
        else {
            item[columnName] = value;
        }
    };

    return serviceProperties && selectedOrdersList ? (
        <PackagePropertyTable
            selectedOrdersList={selectedOrdersList.filter(s => s.sent === false)}
            serviceProperties={serviceProperties}
            changePropertyValueHandler={changechangePropertyValue}
    />
    ) : null;
};

PackagePropertySelection.propTypes = propTypes;

const mapDispatch = {
    getShipmentServiceParams
};

export default connect(null, mapDispatch)(PackagePropertySelection);