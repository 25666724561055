import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Alert } from 'reactstrap';

const propTypes = {
    values: PropTypes.shape({
        levelLittle: PropTypes.number,
        levelMedium: PropTypes.number,
        levelMany: PropTypes.number,
    }),
};

const GraphicInfo = ({ values }) => {
    const intl = useIntl();
    const translations = intl.messages.quantity.view.tooltip;

    const { levelLittle, levelMedium, levelMany } = values;

    return (
        <Alert color="info" className='mt-2'>
            <p><b>{translations.title}</b></p>
            <p>{`${translations.none} ${levelLittle - 1 !== 0 ? '0 - ' : ''}${levelLittle - 1}`}</p>
            <p>{`${translations.little} ${levelMedium - 1 !== levelLittle ? levelLittle + ' - ' : ''}${levelMedium - 1}`}</p>
            <p>{`${translations.medium} ${levelMany - 1 !== levelMedium ? levelMedium + ' - ' : ''}${levelMany - 1}`}</p>
            <p>{`${translations.many} ${levelMany} - ...`}</p>
        </Alert>
    );
};

GraphicInfo.propTypes = propTypes;

export default GraphicInfo;