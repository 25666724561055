const technicalBreak = {
    title: '[EN] Przerwa techniczna',
    button: 'ZAPISZ ZMIANY',
    labels: {
        isActive: '[EN] Aktywna',
        startDate: '[EN] Start przerwy',
        endDate: '[EN] Koniec przerwy',
        backgroundColor: '[EN] Kolor tła',
        backgroundButton: '[EN] Wybierz kolor tła',
        content: '[EN] Zawartość',
    },
    info: '[EN] Własna strona przerwy wyświetlona tylko w przypadku zdefiniowania treści i tła.',
    fileSender: {
        label: 'Tło obrazkowe',
        buttons: {
            edit: '[EN] PODMIEŃ',
            remove: '[EN] USUŃ',
            upload: '[EN] WGRAJ'
        },
    },
};

export default technicalBreak;