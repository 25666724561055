import AccountSettings from '../../views/AccountSettings';
import UserEdit from '../../views/UserEdit';

export const panelUsersRoutes = {
    ROUTE_PANEL_USERS_ACCOUNT_SETTINGS: {
        id: 'ROUTE_PANEL_USERS_ACCOUNT_SETTINGS',
        path: '/panelUsers/account',
        type: 'panelUsers',
        exact: true,
        icon: 'cil-settings',
        component: AccountSettings
    },
    /*
    ROUTE_PANEL_USERS_ADMIN: {
        id: 'ROUTE_PANEL_USERS_ADMIN',
        path: '/panelUsers/admin',
        type: 'panelUsers',
        exact: true,
        icon: 'cil-contact',
        component: null,
    },
    ROUTE_PANEL_USERS_LIST: {
        id: 'ROUTE_PANEL_USERS_LIST',
        path: '/panelUsers/list',
        type: 'panelUsers',
        exact: true,
        icon: 'cil-list',
        component: null,
    },
    */
    ROUTE_PANEL_USERS_EDIT: {
        id: 'ROUTE_PANEL_USERS_EDIT',
        path: '/panelUsers/edit',
        component: UserEdit
    }
};