var signal = 'SIGPIPE';

module.exports = function Profiler() {
    this.stats = {};
    this.record = (label, value) => {
        if (!this.stats[label]) {
            this.stats[label] = [];
        }
        this.stats[label].push(value);
    };

    if (process && process.on) {
        process.on(signal, () => {
            var tableData = Object.entries(this.stats).map(([url, timingsArray]) => {
                return {
                    url,
                    avg: average(timingsArray),
                    median: median(timingsArray),
                    count: timingsArray.length,
                }
            })

            if (console.table) {
                console.table(tableData);
            } else {
                console.info(tableData);
            }
        });
        // console.debug(`profiler initialized for signal ${signal}, PID ${process.pid}, PPID ${process.ppid}`);
    } else {
        // console.debug(`profiler not initialized because global.process is missing`);
    }

    return this;
}

function median(_array) {
    var array = _array.sort((a, b) => a - b);
    if (array.length % 2 === 0) { // array with even number elements
        return (array[array.length / 2] + array[(array.length / 2) - 1]) / 2;
    } else {
        return array[(array.length - 1) / 2]; // array with odd number elements
    }
};

function average(array) {
    return array.reduce((sum, v) => {
        return sum + v;
    }, 0) / array.length;
}