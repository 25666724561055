const agreements = {
    view: {
        title: 'Lista zgód',
        actions: {
            addAgreement: 'DODAJ ZGODĘ',
        },
        buttons: {
            activate: 'Aktywuj',
            deactivate: 'Deaktywuj'
        },
        headerLabels: {
            name: 'Nazwa',
            type: 'Lokalizacja',
            isActive: 'Aktywna',
            isRequired: 'Wymagana',
            regions: 'Regiony',
            actions: 'Akcje',
        },
        emptyPlaceholders: {
            name: '[BRAK NAZWY]',
            type: '[BRAK TYPU]',
            regions: '[BRAK REGIONU]',
            table: 'Nie znaleziono żadnych zgód',
        },
        statuses:{
            active: 'Aktywna',
            inactive: 'Nieaktywna',
            required: 'Wymagana',
            notRequired: 'Opcjonalna',
        },
        tooltips: {
            edit: 'Edytuj zgodę',
            remove: 'Usuń zgodę'
        },
        deleteModal: {
            header: 'Usuwanie zgody',
            body: 'Czy na pewno chcesz usuąć zgodę?',
            cancel: 'WRÓĆ',
            confirm: 'USUŃ'
        },
    },
    edit: {
        tabSelectorTitle: 'Sekcje',
        fallbackName: '[BRAK NAZWY]',
        tabs: {
            basic: {
                title: 'Dane podstawowe',
                labels: {
                    name: 'Nazwa',
                    type: 'Lokalizacja zgody',
                    status: {
                        active: 'AKTYWNA',
                        inactive: 'NIEAKTYWNA',
                        label: 'Aktywna',
                    },
                    required: {
                        active: 'WYMAGANA',
                        inactive: 'NIEWYMAGANA',
                        label: 'Wymagana',
                    },
                }
            },
            descriptions: {
                title: 'Opisy',
                label: 'Opis',
            },
        }
    },
    register: {
        title: 'Rejestr zgód',
        fileName: 'rejestrZgod',
        downloadButton: 'Pobierz CSV',
        search: {
            action: 'SZUKAJ',
            reset: 'RESETUJ',
            labels: {
                email: 'Email',
                ip: 'IP',
                onlyModified: 'Czy modyfikowany'

            }
        },
        table: {
            emptyTable: 'Brak danych w rejestrze',
            ipFiller: 'Brak danych',
            tooltips: {
                showDetails: 'Pokaż szczegóły',
            },
            statuses: {
                modified: 'Modyfikowany',
                notModified: 'Niemodyfikowany',
            },
            headerLabels: {
                email: 'Email',
                modifiedDate: 'Data modyfikacji',
                ip: 'IP',
                isModified: 'Modyfikowane',
                actions: 'Akcje',
            }
        },
        modal: {
            header: 'Lista zgód',
            headerConfirm: 'Potwierdzenia usunięcia zgody',
            cancel: 'WRÓĆ',
            confirm: 'POTWIERDŹ',
            table: {
                statuses: {
                    agree: 'Zgadza się',
                    disagree: 'Nie zgadza się',
                    remove: 'Usunięta na prośbę'
                },
                headerLabels: {
                    name: 'Nazwa',
                    status: 'Status',
                    modifiedDate: 'Data modyfikacji',
                    comment: 'Komentarz',
                    actions: 'Akcje',
                },
                tooltips: {
                    remove: 'Usuń zgode',
                },
                emptyTable: 'Brak zgód',
            },
            info: 'Aby zmienić status kliknij na wiersz',
            warning: 'Możesz zmienić tylko status ZGADZA SIĘ na USUNIĘTO NA PROŚBĘ',
            warningConfirm: 'Ta operacja jest niemożliwa do cofnięcia!',
            comment: 'Komentarz',
        }
    }
};

export default agreements;