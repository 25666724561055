import {
    cAPI
} from '../../../utils/cAPI';

import {
    addEditData,
    getData,
    removeData
} from '../generic';

export const getCustomPages = () => dispatch => getData(dispatch, cAPI.APGetCustomPages, null, 'customPages');

export const getCustomPageBasicData = id => dispatch => getData(dispatch, cAPI.APGetCustomPageBasicData, id, 'customPages');

export const updateCustomPageBasicData = formData => dispatch => addEditData(dispatch, cAPI.APUpdateCustomPageBasicData, formData, 'customPages');

export const removeCustomPage = customPageId => dispatch => removeData(dispatch, cAPI.APDeleteCustomPage, customPageId, 'customPages');

export const updateCustomPageStatus = formData => dispatch => addEditData(dispatch, cAPI.APUpdateCustomPageStatus, formData, 'customPages');
