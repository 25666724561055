import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import TableGenerator from '../../generators/TableGenerator';
import DefaultSpinner from '../../loaders/DefaultSpinner';
import { generateEditLinkForUsers } from '../../../utils/clients';

const propTypes = {
    agreements: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.number,
        modifiedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        comment: PropTypes.string,
    })),
    handleShow: PropTypes.func,
    isLoading: PropTypes.bool,
};

const ClientsDashboardTable = ({ users, isLoading, changeUserStatus, removeAction }) => {
    const intl = useIntl();
    const translations = intl.messages.dashboard.clients.table;
    const history = useHistory();

    const redirectToEdit = (id) => () => {
        history.push(generateEditLinkForUsers(id));
    };

    const deleteAction = (id) => () => {
        removeAction(id);
    };

    return !isLoading ? (
        <TableGenerator
            items={users}
            defaultAction={redirectToEdit}
            translations={translations}
            editLink={generateEditLinkForUsers}
            actions={{deleteAction}}
            expectedActions={['edit', 'delete']}
            columns={[
                {
                    header: translations.headers.login,
                    value: 'login',
                },
                {
                    header: translations.headers.name,
                    value: 'name',
                },
                {
                    header: translations.headers.surname,
                    value: 'surname',
                },
                {
                    header: translations.headers.contractorInErp,
                    value: 'contractorInErp',
                },
                {
                    header: translations.headers.accountType,
                    value: 'accountType',
                },
                {
                    header: translations.headers.isActive,
                    value: 'isActive',
                    type: 'switchWithAction',
                    onSwitch: changeUserStatus,
                },
                {
                    header: translations.headers.registerDate,
                    value: 'registrationDate',
                    type: 'date'
                },
                {
                    header: translations.headers.actions,
                    type: 'actions'
                },
            ]}
        />
    ) : <DefaultSpinner />;
};

ClientsDashboardTable.propTypes = propTypes;

export default ClientsDashboardTable;