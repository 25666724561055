import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';

import SearchableDropdownWithCheckboxes from '../../../../SearchableDropdownWithCheckboxes';

import { withArticleColumns } from '../../../../../hoc/withArticleColumns';
import { withFormUtils } from '../../../../../hoc/withFormUtils';

const propTypes = {
    name: PropTypes.string
};

const ArticleColumnSelectField = ({ utils, name, articleColumns }) => {
    const handleChange = (id, change, values) => {
        if(!values.includes(id) && values.length < 3) {
            return change([...values, id]);
        } else {
            return change(values.filter(val => val !== id));
        }
    };

    const options = articleColumns.map(articleColumn => ({
        id: articleColumn.id,
        label: articleColumn.translations.find(n => n.iso.toLowerCase() === utils.currentLanguage())?.value,
    }));

    return (
        <Field name={name}>
            {
                props => {
                    const currentSelectionLabel = props.input.value === null || props.input.value === '' || (Array.isArray(props.input.value) && (props.input.value.length === 0))
                        ? 'Wybierz kolumny'
                        : (
                            props.input.value.length <= 3
                                ? articleColumns
                                    .filter(articleColumn => props.input.value.includes(articleColumn.id))
                                    .map(articleColumn => articleColumn.translations.find(n => n.iso.toLowerCase() === utils.currentLanguage())?.value)
                                    .join(', ')
                                : `Wybrano: ${props.input.value.length}`
                        );

                    return (
                        <span className='m-1'>
                            <SearchableDropdownWithCheckboxes
                                name={name}
                                options={options}
                                currentSelectionLabel={currentSelectionLabel}
                                setCheckedIds={id => handleChange(id, props.input.onChange, props.input.value)}
                                label='Kolumny na liście artykułów na panelu (maksymalnie trzy)'
                                checkedIds={props.input.value}
                            />
                        </span>
                    );
                }
            }
        </Field>
    );
};

ArticleColumnSelectField.propTypes = propTypes;

export default withFormUtils(withArticleColumns(ArticleColumnSelectField));