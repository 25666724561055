import React from 'react';
import FormFieldTextOrNumber from '../../../../../../components/formFields/FormFieldTextOrNumber';

import { useIntl } from 'react-intl';

const PriceEditor = ({ values, form, index, calculateNetPrice, max, disabled }) => {
    const translations = useIntl().messages.products.edit.tabs.prices;

    return (
        <div className='flex-wrap flex-md-nowrap d-inline-flex justify-content-between w-100 mt-3' >
            <FormFieldTextOrNumber
                label={translations.inputs.grossPrice}
                min={0}
                step='0.01'
                name={`prices[${index}].grossPrice`}
                type='number'
                mutator={(value) => form.mutators.setNetPrice(index, calculateNetPrice(value, values.tax))}
                parse={value => value ? parseFloat(value) : null}
                className='w-100'
                max={max}
                disabled={disabled}
            />
            <FormFieldTextOrNumber
                label={translations.inputs.netPrice}
                min={0}
                type='number'
                name={`prices[${index}].netPrice`}
                disabled
                parse={value => value ? parseFloat(value) : null}
                className='w-100 pl-md-3'
            />
        </div>
    );
};

export default PriceEditor;