/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { getTranslationForIso } from '../../../utils/components';

import { FormattedDate } from 'react-intl';
import { Table, Row, ButtonGroup, Button } from "reactstrap";

import TableActionCell from "../TableActionCell";

import styles from "./index.module.scss";
import getConfig from '../../../getConfig';


const propTypes = {
    slides: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.shape({
        columnNames: PropTypes.shape({
            photo: PropTypes.string,
            alternativeText: PropTypes.string,
            actions: PropTypes.string,
        }),
        tooltipTranslations: PropTypes.shape({
            remove: PropTypes.string,
            edit: PropTypes.string,
        }).isRequired,
        emptyTable: PropTypes.string,
        deleteModalTranslations: PropTypes.shape({
            header: PropTypes.string,
            body: PropTypes.string,
            confirm: PropTypes.string,
            cancel: PropTypes.string
        }).isRequired,
    }),
    removeAction: PropTypes.func.isRequired,
    editAction: PropTypes.func.isRequired,
    changeSlideOrder: PropTypes.func,
};

const BannerImagesTable = ({
    slides,
    removeAction,
    translations,
    editAction,
    changeSlideOrder,
    updateBannerImage,
    currentLanguage,
}) => {
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const removeActionHandler = id => () => {
        removeAction(id);
    };
    const editActionHandler = id => () => {
        editAction(id);
    };

    const changeOrderHandler = (id, delta, slide) => e => {
        e.stopPropagation();

        changeSlideOrder(id, delta, slide);
    };

    const handleActiveSwitch = (slide, activeKey, isActive) => () => {
        const objectToSend = {
            ...slide,
            [activeKey]: !isActive,
        };

        updateBannerImage(slide.bannerImageId, objectToSend);
    };

    return slides && slides.length > 0 ? (
        <Table responsive hover size="sm">
            <thead>
                <tr>
                    <th>
                        {translations.columnNames.order}
                    </th>
                    <th>
                        {translations.columnNames.desktopImage}
                    </th>
                    <th>
                        {translations.columnNames.tabletImage}
                    </th>
                    <th>
                        {translations.columnNames.mobileImage}
                    </th>
                    <th>
                        {translations.columnNames.link}
                    </th>
                    <th>
                        {translations.columnNames.dates}
                    </th>
                    <th>
                        {translations.columnNames.activeInLanguage}
                    </th>
                    <th className={styles.actionHeader}>
                        {translations.columnNames.actions}
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    slides.sort((slideA, slideB) => slideA.lp - slideB.lp).map((slide, index) => {

                        return (
                            <tr className={styles.row} onClick={editActionHandler(slide)} key={`photo-${slide.bannerImageId}`}>
                                <td className={clsx(styles.cell)}>
                                    <ButtonGroup vertical>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            disabled={index === 0}
                                            onClick={changeOrderHandler(slide.bannerImageId, -1, slide)}
                                        >
                                            <i className="cil-arrow-thick-top"></i>
                                        </Button>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            disabled={index === slides.length - 1}
                                            onClick={changeOrderHandler(slide.bannerImageId, 1, slide)}
                                        >
                                            <i className="cil-arrow-thick-bottom"></i>
                                        </Button>
                                    </ButtonGroup>
                                </td>
                                <td className={clsx(styles.imageContainer, styles.cellBottom)}>
                                    <img className={styles.image} src={`${apiUrl}${slide.desktop.imageSource}`} alt={getTranslationForIso(slide.desktop.imagealternativetext, currentLanguage)}/>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', margin: '5px'}} onClick={(e) => {e.stopPropagation()}}>
                                        <label className="switch switch-pill switch-success m-0">
                                            <input
                                                type="checkbox"
                                                className="switch-input"
                                                checked={slide.activeDesktop}
                                                onChange={handleActiveSwitch(slide, 'activeDesktop', slide.activeDesktop)}
                                            />
                                            <span
                                                className={clsx("switch-slider", !slide.activeDesktop &&  "bg-danger border-danger")}
                                            ></span>
                                        </label>
                                    </div>
                                </td>
                                <td className={clsx(styles.imageContainer, styles.cellBottom)}>
                                    <img className={styles.image} src={`${apiUrl}${slide.tablet.imageSource}`} alt={getTranslationForIso(slide.tablet.imagealternativetext, currentLanguage)}/>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', margin: '5px'}} onClick={(e) => {e.stopPropagation()}}>
                                        <label className="switch switch-pill switch-success m-0">
                                            <input
                                                type="checkbox"
                                                className="switch-input"
                                                checked={slide.activeTablet}
                                                onChange={handleActiveSwitch(slide, 'activeTablet', slide.activeTablet)}
                                            />
                                            <span
                                                className={clsx("switch-slider", !slide.activeTablet &&  "bg-danger border-danger")}
                                            ></span>
                                        </label>
                                    </div>
                                </td>
                                <td className={clsx(styles.imageContainer, styles.cellBottom)}>
                                    <img className={styles.image} src={`${apiUrl}${slide.mobile.imageSource}`} alt={getTranslationForIso(slide.mobile.imagealternativetext, currentLanguage)}/>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', margin: '5px'}} onClick={(e) => {e.stopPropagation()}}>
                                        <label className="switch switch-pill switch-success m-0">
                                            <input
                                                type="checkbox"
                                                className="switch-input"
                                                checked={slide.activeMobile}
                                                onChange={handleActiveSwitch(slide, 'activeMobile', slide.activeMobile)}
                                            />
                                            <span
                                                className={clsx("switch-slider", !slide.activeMobile &&  "bg-danger border-danger")}
                                            ></span>
                                        </label>
                                    </div>
                                </td>
                                <td className={clsx(styles.cell)}>
                                    <span>{slide.link}</span>
                                </td>
                                <td className={clsx(styles.cell)}>
                                    {
                                        slide.dateFrom
                                            ? <FormattedDate value={slide.dateFrom} day='2-digit' month='2-digit' year='numeric' />
                                            : null
                                    }
                                    <span>-</span>
                                    {
                                        slide.dateTo
                                            ? <FormattedDate value={slide.dateTo} day='2-digit' month='2-digit' year='numeric' />
                                            : null
                                    }
                                </td>
                                <td className={clsx(styles.cell)}>
                                    {
                                        slide.activeInLanguage
                                    }
                                </td>
                                <td className={clsx(styles.cell)} onClick={e => e.stopPropagation()}>
                                    <TableActionCell
                                        itemId={slide.productImageId}
                                        actions={{
                                            edit: {
                                                tooltipTranslation: translations.tooltipTranslations.edit,
                                                action: editActionHandler(slide),
                                            },
                                            delete: {
                                                action: removeActionHandler(slide.bannerImageId),
                                                modalTranslations: translations.deleteModalTranslations,
                                                tooltipTranslation: translations.tooltipTranslations.remove
                                            },
                                        }}
                                    />
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    ) : (
        <Row className="d-flex justify-content-center mt-4 mb-4">
            {translations.emptyTable}
        </Row>
    );
};

BannerImagesTable.propTypes = propTypes;

export default BannerImagesTable;
