import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCurrencies } from '../../../../redux/methods/currencies';
import { editExchangeRate } from '../../../../redux/methods/exchangeRates';
import useFetch from '../../../../hooks/useFetch';

import { generateDropdownOptionsForCurrencies } from '../../../../utils/exchangeRates';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import FormFieldWithCalendarPicker from '../../../../components/formFields/FormFieldWithCalendarPicker';
import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';

import { withFormUtils } from '../../../../hoc/withFormUtils';
import { formUtilsHocPropTypes, exchangeRatePropTypes } from '../../../../propTypes';

const propTypes = {
    history: PropTypes.object,
    utils: formUtilsHocPropTypes.isRequired,
    editExchangeRate: PropTypes.func,
    getCurrencies: PropTypes.func,
    exchangeRate: exchangeRatePropTypes,
};

const ExchangeRateEditBasic = ({
    history,
    utils,
    exchangeRate,
    getCurrencies,
    editExchangeRate
}) => {
    const intl = useIntl();
    const translations = intl.messages.exchangeRates.edit.tabs.basicData;

    const [ currencies ] = useFetch(getCurrencies);

    const handleOnSubmit = formData => {
        editExchangeRate(formData);

        history.push('/exchangeRates');
    };

    const isNewRecord = !exchangeRate.exchangeRateId;
    const isDateNull = !exchangeRate.dateFrom;

    return currencies ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={exchangeRate}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        const { values } = form.getState();
                        const actualPrimaryCurrency = currencies.find(currency => currency.id === parseInt(values.currencyId1));
                        const actualSecondaryCurrency = currencies.find(currency => currency.id === parseInt(values.currencyId2));

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='px-0' fluid>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldTextOrNumber
                                            name='rate'
                                            label={translations.labels.rate}
                                            className='w-100 mr-md-3'
                                            type='number'
                                        />
                                        <FormFieldWithCalendarPicker
                                            label={translations.labels.validFrom}
                                            name='dateFrom'
                                            storeCurrentLanguage={utils.currentLanguage()}
                                            className='w-100 mt-3 mt-md-0'
                                            isDisabled={!isDateNull}
                                        />
                                    </Row>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldWithDropdown
                                            name='currencyId1'
                                            currentSelectionLabel={actualPrimaryCurrency ? actualPrimaryCurrency.name : null}
                                            options={generateDropdownOptionsForCurrencies(currencies)}
                                            label={translations.labels.primaryCurrency}
                                            className='w-100 mr-md-3'
                                            isDisabled={!isNewRecord}
                                        />
                                        <FormFieldWithDropdown
                                            name='currencyId2'
                                            currentSelectionLabel={actualSecondaryCurrency ? actualSecondaryCurrency.name : null}
                                            options={generateDropdownOptionsForCurrencies(currencies)}
                                            label={translations.labels.secondaryCurrency}
                                            className='w-100 mt-3 mt-md-0'
                                            isDisabled={!isNewRecord}
                                        />
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

ExchangeRateEditBasic.propTypes = propTypes;

const mapDispatch = {
    getCurrencies,
    editExchangeRate
};

export default withFormUtils(connect(null, mapDispatch)(withRouter(ExchangeRateEditBasic)));