import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TableGenerator from '../../generators/TableGenerator';

import styles from './index.module.scss';
import { stringOrNumberPropTypes } from '../../../propTypes';
import { handleCheckboxAction } from '../../../utils/components';

const propTypes = {
    opinions: PropTypes.arrayOf(PropTypes.shape({
        productReviewId: stringOrNumberPropTypes,
        productId: stringOrNumberPropTypes,
        name: PropTypes.string,
        date: PropTypes.string,
        content: PropTypes.string,
        isContentVisible: PropTypes.bool,
        rating: PropTypes.number,
    })),
    productId: stringOrNumberPropTypes,
    removeAction: PropTypes.func,
    switchAction: PropTypes.func,
    handleAcceptAction: PropTypes.func,
};

const OpinionsForApprovalTable = ({ opinions, removeAction, handleSelectedAction, handleAcceptAction }) => {
    const intl = useIntl();
    const translations = intl.messages.report.opinions;
    const [checkedRows, setCheckedRows] = useState([]);

    const { userName, name, date, content, rating, actions } = translations.headers;

    const deleteAction = id => () => {
        removeAction(id);
    };

    const generateEditLinkForProduct = (_, item) => `/products/list/edit?productID=${item.productId}`;

    const handleSelected = (type) => () => {
        handleSelectedAction(type, checkedRows);
    };

    const defaultActionOnClick = (id) => () => {
        handleCheckboxAction(id, checkedRows, setCheckedRows);
    };

    const acceptAction = (id) => () => {
        handleAcceptAction(id);
    };

    return (
        <TableGenerator
            items={opinions}
            actions={{deleteAction, acceptAction}}
            editLink={generateEditLinkForProduct}
            defaultAction={defaultActionOnClick}
            expectedActions={['accept', 'delete', 'edit']}
            translations={{
                tooltips: translations.tooltips,
                emptyTable: translations.emptyTable,
                deleteModal: translations.deleteModal,
            }}
            checkedItemsIds={checkedRows}
            setCheckedItemsIds={setCheckedRows}
            customButtons={[
                {
                    labelTrue: translations.buttons.accept,
                    labelFalse: translations.buttons.remove,
                    action: handleSelected,
                    type: 'selector',
                    isDisabled: checkedRows.length < 1,
                },
            ]}
            columns={[
                {
                    type: 'checkbox'
                },
                {
                    header: userName,
                    value: 'name',
                },
                {
                    header: name,
                    value: 'productName',
                },
                {
                    header: date,
                    value: 'date',
                    type: 'date'
                },
                {
                    header: content,
                    value: 'content',
                    additionalStyle: styles.content,
                },
                {
                    header: rating,
                    value: 'rating',
                    type: 'rating',
                },
                {
                    header: actions,
                    type: 'actions'
                },
            ]}
        />
    );
};

OpinionsForApprovalTable.propTypes = propTypes;

export default OpinionsForApprovalTable;