import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-date-picker';

const propTypes = {
    activeStartDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    maxDateLimit: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    minDateLimit:PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    value: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    onChange: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
};

const CalendarPicker = ({ activeStartDate, maxDateLimit, minDateLimit, value, onChange, locale = 'PL-pl', isDisabled = false }) => {
    const handleOnChange = updatedValue => {
        onChange(updatedValue);
    };

    return (
        <DatePicker
            /** 'locale accepts any IETF tag */
            activeStartDate={activeStartDate}
            locale={locale}
            format="y/MM/dd"
            maxDate={maxDateLimit ? new Date(maxDateLimit) : null}
            minDate={minDateLimit ? new Date(minDateLimit) : null}
            onChange={handleOnChange}
            nextLabel={<i className="fa fa-step-forward fa-lg"/>}
            next2Label={<i className="fa fa-arrow-right fa-lg"/>}
            prevLabel={<i className="fa fa-step-backward fa-lg"/>}
            prev2Label={<i className="fa fa-arrow-left fa-lg"/>}
            value={value ? new Date(value) : null}
            clearIcon={<i className="fa fa-remove"/>}
            calendarIcon={<i className="fa fa-calendar"/>}
            disabled={isDisabled}
        />
    );
};

CalendarPicker.propTypes = propTypes;

export default CalendarPicker;