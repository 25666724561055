import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';

import TooltipWrapper from '../TooltipWrapper';
import { withFormUtils } from '../../hoc/withFormUtils';

import styles from './index.module.scss';

const propTypes = {
    type: PropTypes.oneOf(["link", "expander"]),
    id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
    label: PropTypes.string,
    labelLink: PropTypes.string,
    editLink: PropTypes.string,
    isExpanded: PropTypes.bool,
    modalTranslations: PropTypes.shape({
        header: PropTypes.string,
        body: PropTypes.string,
        confirm: PropTypes.string,
        cancel: PropTypes.string
    }).isRequired,
    tooltipTranslations: PropTypes.shape({
        edit: PropTypes.string,
        delete: PropTypes.string,
    }).isRequired,
    deleteAction: PropTypes.func,
};

const TreeItem = ({ utils, type, id, label, labelLink, editLink, modalTranslations, tooltipTranslations, deleteAction }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.treeItem;

    const [ isEditTooltipOpen, setIsEditTooltipOpen ] = useState(false);
    const [ isDeleteTooltipOpen, setIsDeleteTooltipOpen ] = useState(false);

    const performDeleteAction = () => {
        utils.confirm(
            () => deleteAction(),
            modalTranslations.header,
            modalTranslations.body
        );
    };

    return (
        <React.Fragment>
            <div className={styles.container}>
                <span className={styles.mainControls}>
                    <span className={styles.mainControlsLabel}>
                        {
                            label
                                ? (
                                    type === 'link' && labelLink
                                        ? (
                                            <Link to={labelLink}>
                                                {label}
                                            </Link>
                                        )
                                        : <span>label</span>
                                )
                                : (
                                    <span className="text-danger">{translations.labelFiller}</span>
                                )
                        }
                    </span>
                </span>
                <span className={styles.actionControls}>
                    {
                        editLink &&
                            <TooltipWrapper
                                target={`editCategory-${id}`}
                                placement="top"
                                isOpen={isEditTooltipOpen}
                                setIsOpen={setIsEditTooltipOpen}
                                tooltipContent={tooltipTranslations.edit}
                            >
                                <Link className={styles.actionControlsItem} to={editLink} id={`editCategory-${id}`}>
                                    <i className={clsx('fa fa-edit fa-lg', styles.icon, styles.iconEdit)}/>
                                </Link>
                            </TooltipWrapper>
                    }
                    {
                        deleteAction &&
                            <TooltipWrapper
                                target={`removeCategory-${id}`}
                                placement="top"
                                isOpen={isDeleteTooltipOpen}
                                setIsOpen={setIsDeleteTooltipOpen}
                                tooltipContent={tooltipTranslations.delete}
                            >
                                <button className={styles.actionControlsItem} onClick={performDeleteAction} id={`removeCategory-${id}`}>
                                    <i className={clsx('fa fa-trash-o fa-lg', styles.icon, styles.iconDelete)}/>
                                </button>
                            </TooltipWrapper>
                    }
                </span>
            </div>
        </React.Fragment>
    );
};

TreeItem.propTypes = propTypes;

export default withFormUtils(TreeItem);