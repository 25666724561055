import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import PackagePropertySelection from './PackagePropertySelection';
import ServiceSelection from './ServiceSelection';

import { Form as FinalForm } from 'react-final-form';

import { getShipmentServiceParams, sendShipments } from '../../../redux/methods/shipments';
import { setError } from '../../../features/errors/slice';

import { Button } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    sendShipments: PropTypes.func,
    selectedOrders: PropTypes.arrayOf(Object),
    closeSendPackageModal: PropTypes.func,
    getShipmentServiceParams: PropTypes.func
};

const SendPackage = ({
    sendShipments,
    selectedOrders,
    closeSendPackageModal,
    setError,
    getShipmentServiceParams
}) => {
    selectedOrders.forEach(element => {
        if (!element.sent){
            element.sent = false;
        }
    });

    const intl = useIntl();
    const translations = intl.messages.orders.sendPackages;

    const [ selectedOrdersList, setSelectedOrdersList ] = useState(selectedOrders);
    const [ serviceProperties, setServiceProperties] = useState(null);
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ selectedService, setSelectedService ] = useState(null);

    const handleNext = () => {
        setCurrentStep(prevState => prevState + 1);
    };

    const handlePrev = () => {
        setCurrentStep(prevState => prevState - 1);
    };

    const handleSetSelectedService = async (serviceId) => {

        setSelectedService(serviceId);

        const result = await getShipmentServiceParams(serviceId);

        if (result) {
            setServiceProperties(result.payload);

            const { shipmentParamDefaults } = result.payload;

            setSelectedOrdersList(selectedOrdersList.map(element => {
                element.paramServiceId = null;
                element.paramDimension1 = shipmentParamDefaults.dimension1;
                element.paramDimension2 = shipmentParamDefaults.dimension2;
                element.paramDimension3 = shipmentParamDefaults.dimension3;
                element.paramWeight = shipmentParamDefaults.weight;
                element.paramValue = shipmentParamDefaults.value;
                element.paramNonStandard = shipmentParamDefaults.nonStandard;
                element.paramServiceId = shipmentParamDefaults.service;
                element.paramType = shipmentParamDefaults.type;
                element.paramDescription = shipmentParamDefaults.description;

                return element;
            }));
        }

        handleNext();
    };

    const handleOnSubmit = async () => {
        const objectToSend = {
            orders: selectedOrdersList.filter(order => order.sent === false).map(order => ({
                id: order.id,
                serviceId: order.paramServiceId,
                dimension1: order.paramDimension1,
                dimension2: order.paramDimension2,
                dimension3: order.paramDimension3,
                weight: typeof(order.paramWeight) === 'string'
                    ? parseFloat(order.paramWeight.replace(',','.'))
                    : order.paramWeight,
                value: typeof(order.paramValue) === 'string'
                    ? parseFloat(order.paramValue.replace(',','.'))
                    : order.paramValue,
                type: order.paramType,
                nonStandard: order.paramNonStandard,
                description: order.paramDescription
            })),
			serviceId: selectedService
        };

        const result = await sendShipments(objectToSend);

        let allSent = true;

        if (result && result.results) {
            const ordersSent = result.results.filter(r => r.result === 'OK').map(r => r.orderId);
            const ordersUnsent = result.results.filter(r => r.result === 'Error').map(r => r.orderId);

            setSelectedOrdersList(selectedOrdersList.map(element => {
                if (ordersSent.includes(element.id)){
                    element.sent = true; }
                if (ordersUnsent.includes(element.id)){
                    element.sent = false; }
                return element;
            }));

            // hack to force rerender PackagePropertySelection control
            setCurrentStep(prevState => prevState + 1);
            setCurrentStep(prevState => prevState - 1);

            allSent = ordersUnsent.length === 0;
        }

        if (allSent) {
            await closeSendPackageModal();
        } else{
            setError({
                error: translations.sendPackagesErrors,
                additionalData: {
                    errors: result.results.filter(r => r.result === 'Error')
                }
            });
        }
    };

    const checkIfNextStepAllowed = () => {
        if (currentStep === 0 && !selectedService) {
            return false;
        }
        else {
            return true;
        }
    };

    const renderProperStepSelection = (step, orders, properties) => {
        switch(step) {
            case 0: return (
                <ServiceSelection
                    label={translations.steps.chooseService}
                    translations={translations}
                    selectedService={selectedService}
                    setSelectedService={handleSetSelectedService}
                    setNextStep={handleNext}
                />
            );
            case 1: return (
                <PackagePropertySelection
                    serviceId={selectedService}
                    selectedOrdersList={orders}
                    serviceProperties={properties}
                    label={translations.steps.setProperties}
                    translations={translations}
                />
            );
            default: return null;
        }
    };

    return (
        <div className='w-100 h-100'>
            <FinalForm
                onSubmit={handleOnSubmit}
                render={({ handleSubmit }) => {
                    return (
                        <React.Fragment>
                            {
                                renderProperStepSelection(currentStep, selectedOrdersList, serviceProperties)
                            }
                            <aside className={styles.footer}>
                                { currentStep !== 0 &&
                                    <Button color="danger" onClick={handlePrev} className='mr-2'>
                                        <span>{translations.buttons.prev}</span>
                                    </Button>
                                }
                                { serviceProperties &&
                                <Button color="success" onClick={currentStep === 1 ? handleSubmit : handleNext} className='mr-2' disabled={!checkIfNextStepAllowed()}>
                                    <span>{currentStep === 1 ? translations.buttons.send : translations.buttons.next}</span>
                                </Button>
                                }
                            </aside>
                        </React.Fragment>
                    );
                }}
            />
        </div>
    );
};

SendPackage.propTypes = propTypes;

const mapDispatch = {
    getShipmentServiceParams,
    sendShipments,
    setError
};

export default connect(null, mapDispatch)(SendPackage);