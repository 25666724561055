import P24 from '../../views/integrations/P24';
import PayU from '../../views/integrations/PayU';
import InPost from '../../views/integrations/InPost';
import APaczka from '../../views/integrations/APaczka';
import Furgonetka from '../../views/integrations/Furgonetka';
import Facebook from '../../views/integrations/Facebook';
import Ceneo from '../../views/integrations/Ceneo';
import Google from '../../views/integrations/Google';
import Freshmail from '../../views/integrations/Freshmail';
import Chat from '../../views/integrations/Chat';
import Recaptcha from '../../views/integrations/Recaptcha';
import GoogleSearchConsole from '../../views/integrations/GoogleSearchConsole';
import Inspectlet from '../../views/integrations/Inspectlet';

export const integrationsRoutes = {

    ROUTE_INTEGRATIONS_ONLINE_PAYMENTS: {
        id: 'ROUTE_INTEGRATIONS_ONLINE_PAYMENTS',
        path: '#',
        type: 'integrations',
        exact: true,
        icon: 'fal fa-envelope-open-dollar',
        children: [
            {
                id: 'ROUTE_INTEGRATIONS_ONLINE_PAYMENTS_P24',
                path: '/integrations/onlinePayments/p24',
                component: P24,
                icon: 'fa fa-cogs'
            },
            {
                id: 'ROUTE_INTEGRATIONS_ONLINE_PAYMENTS_PAYU',
                path: '/integrations/onlinePayments/payu',
                component: PayU,
                icon: 'fa fa-cogs'
            }
        ]
    },
    ROUTE_INTEGRATIONS_COURIERS: {
        id: 'ROUTE_INTEGRATIONS_COURIERS',
        path: '#',
        type: 'integrations',
        exact: true,
        icon: 'fal fa-person-carry',
        children: [
			{
                id: 'ROUTE_INTEGRATIONS_COURIERS_APACZKA',
                path: '/integrations/couriers/apaczka',
                component: APaczka,
                icon: 'fa fa-cogs'
            },
			{
                id: 'ROUTE_INTEGRATIONS_COURIERS_FURGONETKA',
                path: '/integrations/couriers/furgonetka',
                component: Furgonetka,
                icon: 'fa fa-cogs'
            },
            {
                id: 'ROUTE_INTEGRATIONS_COURIERS_INPOST',
                path: '/integrations/couriers/inpost',
                component: InPost,
                icon: 'fa fa-cogs'
            }
        ]
    },
    ROUTE_INTEGRATIONS_COMPARISIONS: {
        id: 'ROUTE_INTEGRATIONS_COMPARISIONS',
        path: '#',
        type: 'integrations',
        exact: true,
        icon: 'fal fa-balance-scale-left',
        children: [
            {
                id: 'ROUTE_INTEGRATIONS_COMPARISIONS_CENEO',
                path: '/integrations/comparisions/ceneo',
                component: Ceneo,
                icon: 'cib-c'
            }
            // {
            //     id: 'ROUTE_INTEGRATIONS_COMPARISIONS_GOOGLE',
            //     path: '/integrations/comparisions/google',
            //     component: null,
            //     icon: 'cib-google'
            // }
        ]
    },
    ROUTE_INTEGRATIONS_SOCIAL: {
        id: 'ROUTE_INTEGRATIONS_SOCIAL',
        path: '#',
        type: 'integrations',
        exact: true,
        icon: 'fal fa-icons',
        children: [
            {
                id: 'ROUTE_INTEGRATIONS_SOCIAL_FACEBOOK',
                path: '/integrations/social/facebook',
                component: Facebook,
                icon: 'fa fa-facebook'
            }
        ]
    },
    ROUTE_INTEGRATIONS_MARKETING: {
        id: 'ROUTE_INTEGRATIONS_MARKETING',
        path: '#',
        type: 'integrations',
        exact: true,
        icon: 'fal fa-sparkles',
        children: [
            {
                id: 'ROUTE_INTEGRATIONS_MARKETING_GOOGLE',
                path: '/integrations/marketing/google',
                component: Google,
                icon: 'fal fa-analytics'
            },
            // {
            //     id: 'ROUTE_INTEGRATIONS_MARKETING_SALES_MENAGO',
            //     path: '/integrations/marketing/salesmenago',
            //     component: null,
            //     icon: 'fal fa-analytics'
            // },
            {
                id: 'ROUTE_INTEGRATIONS_MARKETING_FRESHMAIL',
                path: '/integrations/marketing/freshmail',
                component: Freshmail,
                icon: 'cil-at'
            },
            {
                id: 'ROUTE_INTEGRATIONS_MARKETING_INSPECTLET',
                path: '/integrations/marketing/inspectlet',
                component: Inspectlet,
                icon: 'fal fa-analytics'
            }
        ]
    },
    // ROUTE_INTEGRATIONS_REVIEWS: {
    //     id: 'ROUTE_INTEGRATIONS_REVIEWS',
    //     path: '#',
    //     type: 'integrations',
    //     exact: true,
    //     icon: 'fas fa-star-half-alt',
    //     children: [
    //         {
    //             id: 'ROUTE_INTEGRATIONS_REVIEWS_GOOGLE',
    //             path: '/integrations/reviews/google',
    //             component: null,
    //             icon: 'fab fa-google'
    //         },
    //         {
    //             id: 'ROUTE_INTEGRATIONS_REVIEWS_OPINEO',
    //             path: '/integrations/reviews/opineo',
    //             component: null,
    //             icon: 'fal fa-check-circle'
    //         }
    //     ]
    // },
    ROUTE_INTEGRATIONS_COMMUNICATION: {
        id: 'ROUTE_INTEGRATIONS_COMMUNICATION',
        path: '#',
        type: 'integrations',
        exact: true,
        icon: 'fal fa-exchange-alt',
        children: [
            {
                id: 'ROUTE_INTEGRATIONS_COMMUNICATION_CHAT',
                path: '/integrations/communication/chat',
                component: Chat,
                icon: 'cil-chat-bubble'
            }
            // {
            //     id: 'ROUTE_INTEGRATIONS_COMMUNICATION_SMS',
            //     path: '/integrations/communication/sms',
            //     component: null,
            //     icon: 'fal fa-sms'
            // }
        ]
    },
    ROUTE_INTEGRATIONS_OTHER: {
        id: 'ROUTE_INTEGRATIONS_OTHER',
        path: '#',
        type: 'integrations',
        exact: true,
        icon: 'fal fa-cogs',
        children: [
            {
                id: 'ROUTE_INTEGRATIONS_OTHER_RECAPTCHA',
                path: '/integrations/other/recaptcha',
                component: Recaptcha,
                icon: 'fa fa-google'
            },
            {
                id: 'ROUTE_INTEGRATIONS_OTHER_GOOGLE_SEARCH_CONSOLE',
                path: '/integrations/other/googleSearchConsole',
                component: GoogleSearchConsole,
                icon: 'fa fa-google'
            }
        ]
    }
};