import React from 'react';
import PropTypes from 'prop-types';

import { Row, Alert } from 'reactstrap';

import FileSender from '../FileSender';
import FormFieldTranslationsQuill from '../../../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldColorPicker from '../../../../../components/formFields/FormFieldColorPicker';
import FormFieldWithRange from '../../../../../components/formFields/FormFieldWithRange';
import FormFieldTextOrNumber from '../../../../../components/formFields/FormFieldTextOrNumber';

import styles from './index.module.scss';
import FormFieldDefiningButton from '../../../../../components/formFields/FormFieldDefiningButton';

const propTypes = {
    translations: PropTypes.object,
};

const ImageEditFields = ({
    translations,
    section,
    form,
    utils,
    imageName,
    isMaxCharLimitExceeded,
    setIsMaxCharLimitExceeded,
    values,
    setFile,
}) => {

    const charControlHandler = editor => {
        const charNumber = editor.plugins.wordcount.body.getCharacterCount();

        if (charNumber > 100) {
            setIsMaxCharLimitExceeded(true);
        } else {
            setIsMaxCharLimitExceeded(false);
        }
    };

    return (
        <React.Fragment>
            <Alert
                isOpen={isMaxCharLimitExceeded}
                toogle={() => setIsMaxCharLimitExceeded(!isMaxCharLimitExceeded)}
                color='danger'
                className="px-3"
            >
                {translations.charLimitExceeded}
            </Alert>
            <Row className='mt-3 px-3'>
                <FileSender
                    section={section}
                    translations={translations}
                    sectionId={section.id}
                    isFirstImage={imageName ===  'firstImage'}
                    setFile={setFile}
                />
            </Row>
            <Row className='mt-3 px-3'>
                <FormFieldTextOrNumber
                    label={translations.inputs.link}
                    name={`${imageName}.link`}
                    className='w-100'
                />
            </Row>
            <Row className='mt-3 px-3'>
                <FormFieldColorPicker
                    button={translations.inputs.backgroundColorButton}
                    label={translations.inputs.shadeColor}
                    name={`${imageName}.backgroundColor`}
                />
            </Row>
            <Row className='mt-3 px-3'>
                <div className={styles.range}>
                    <FormFieldWithRange
                        disabled={!values[`${imageName}`].backgroundColor}
                        label={translations.inputs.opacityRange}
                        name={`${imageName}.opacity`}
                        min={0}
                        max={100}
                    />
                </div>
            </Row>
            <Row className='mt-2 px-3'>
                <FormFieldTranslationsQuill
                    form={form}
                    utils={utils}
                    propertyName={`${imageName}Content`}
                    header={translations.inputs.content}
                    charControlHandler={charControlHandler}
                />
            </Row>
            <FormFieldDefiningButton
                customStructureName={imageName}
                form={form}
                names={{
                    button: `${imageName}Button`,
                    buttonLink: `${imageName}.buttonLink`,
                    buttonId: `${imageName}.buttonId`
                }}
            />
        </React.Fragment>
    );
};

ImageEditFields.propTypes = propTypes;

export default ImageEditFields;