import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { getTranslationForIso } from '../../../../../utils/components';

import { withFormUtils } from '../../../../../hoc/withFormUtils';
import { Form as FinalForm } from "react-final-form";
import { Form, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Tabulator from "../../../../../components/Tabulator";
import WarningModal from '../../../../../components/modals/WarningModal';
import ImageTileElement from './ImageTileElement';
import EditionFields from './EditionFields';
import FormFieldTextOrNumber from '../../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldWithCalendarAndTimePicker from '../../../../../components/formFields/FormFieldWithCalendarAndTimePicker';

import { formUtilsHocPropTypes, stringOrNumberPropTypes, homePageBannerImagePropTypes } from '../../../../../propTypes';
import DefaultSpinner from "../../../../../components/loaders/DefaultSpinner";

const propTypes = {
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    utils: formUtilsHocPropTypes,
    handleRemove: PropTypes.func,
    handleUpload: PropTypes.func,
    animatedBannerId: stringOrNumberPropTypes,
    translations: PropTypes.shape({
        inputs: PropTypes.shape({
            file: PropTypes.shape({
                upload: PropTypes.string,
                remove: PropTypes.string,
                edit: PropTypes.string
            }),
            alternativeText: PropTypes.string,
            dateTo: PropTypes.string,
            dateFrom: PropTypes.string
        }),
        actions: PropTypes.shape({
            save: PropTypes.string,
            calncel: PropTypes.string
        }),
    }),
    slide: homePageBannerImagePropTypes
};

const EditAnimatedBannerImageModal = ({
    translations,
    slide,
    isModalOpen,
    setIsModalOpen,
    onSubmit,
    handleRemove,
    handleUpload,
    animatedBannerId,
    utils,
    isLoading
}) => {
    const config = {
        translations: ['desktopImageAlternativeText', 'tabletImageAlternativeText', 'mobileImageAlternativeText'],
    };

    const isFormChanged = useRef();
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);

    const toggleModal = () => {
        if (isFormChanged.current) {
            setIsWarningModalOpen(true);
        }
        else {
            setIsModalOpen(!isModalOpen);
        }
    };

    const handleSubmit = formData => {
        const convertedData = utils.generateFormToSubmitValues(formData, config);
        const objectToSend = {
            ...convertedData,
            desktop: {
                ...formData.desktop,
                imageAlternativeText: convertedData.desktopImageAlternativeText
            },
            tablet: {
                ...formData.tablet,
                imageAlternativeText: convertedData.tabletImageAlternativeText
            },
            mobile: {
                ...formData.mobile,
                imageAlternativeText: convertedData.mobileImageAlternativeText
            }
        };

        onSubmit(objectToSend);
        setIsModalOpen(!isModalOpen);
    };

    const uploadFileHandler = (data) => {
        handleUpload(animatedBannerId, slide.animatedBannerImageId, data);
    };

    const removeFileHandler = () => {
        handleRemove(animatedBannerId, slide.animatedBannerImageId);
    };

    return (
        <Modal isOpen={isModalOpen} toggle={toggleModal} size='xl'>
            <ModalHeader toggle={toggleModal}>{translations.editModal.header}</ModalHeader>
            <FinalForm
                onSubmit={handleSubmit}
                initialValues={utils.generateFormInitialValues(slide, config)}
                render={({ handleSubmit, form, pristine }) => {
                    isFormChanged.current = !pristine;

                    return (
                        <Form onSubmit={handleSubmit} className='w-100 p-0'>
                            <ModalBody>
                                <Tabulator
                                    listOfElements={[
                                        {
                                            label: translations.inputs.desktopImage,
                                            id: `${slide.animatedBannerImageId}-DesktopTile`,
                                            component: (
                                                <React.Fragment>
                                                    <ImageTileElement
                                                        imageUrl={slide.desktop.imageSource}
                                                        buttonsTranslations={translations.inputs.file}
                                                        uploadFileHandler={uploadFileHandler}
                                                        removeFileHandler={removeFileHandler}
                                                        imageAlternativeText={getTranslationForIso(slide.desktopImageAlternativeText, utils.currentLanguage())}
                                                        fileKey='Image'
                                                        fileType='desktop'
                                                    />
                                                    <EditionFields
                                                        form={form}
                                                        translations={translations}
                                                        utils={utils}
                                                        name={'desktop'}
                                                    />
                                                </React.Fragment>
                                            )
                                        },
                                        {
                                            label: translations.inputs.tabletImage,
                                            id: `${slide.animatedBannerImageId}-TabletTile`,
                                            component: (
                                                <React.Fragment>
                                                    <ImageTileElement
                                                        imageUrl={slide.tablet.imageSource}
                                                        buttonsTranslations={translations.inputs.file}
                                                        uploadFileHandler={uploadFileHandler}
                                                        removeFileHandler={removeFileHandler}
                                                        imageAlternativeText={getTranslationForIso(slide.tabletImageAlternativeText, utils.currentLanguage())}
                                                        fileKey='Image'
                                                        fileType='tablet'
                                                    />
                                                    <EditionFields
                                                        form={form}
                                                        translations={translations}
                                                        utils={utils}
                                                        name={'tablet'}
                                                    />
                                                </React.Fragment>
                                            )
                                        },
                                        {
                                            label: translations.inputs.mobileImage,
                                            id: `${slide.animatedBannerImageId}-MobileTile`,
                                            component: (
                                                <React.Fragment>
                                                    <ImageTileElement
                                                        imageUrl={slide.mobile.imageSource}
                                                        buttonsTranslations={translations.inputs.file}
                                                        uploadFileHandler={uploadFileHandler}
                                                        removeFileHandler={removeFileHandler}
                                                        imageAlternativeText={getTranslationForIso(slide.mobileImageAlternativeText, utils.currentLanguage())}
                                                        fileKey='Image'
                                                        fileType='mobile'
                                                    />
                                                    <EditionFields
                                                        form={form}
                                                        translations={translations}
                                                        utils={utils}
                                                        name={'mobile'}
                                                    />
                                                </React.Fragment>
                                            )
                                        },
                                    ]}
                                />
                                <div className='my-3 d-flex flex-wrap'>
                                    <FormFieldWithCalendarAndTimePicker label={translations.inputs.dateFrom} name='dateFrom' />
                                    <FormFieldWithCalendarAndTimePicker label={translations.inputs.dateTo} name='dateTo'  />
                                </div>
                                <FormFieldTextOrNumber
                                    className='d-block mb-3'
                                    label={translations.inputs.link}
                                    name='link'
                                />
                            </ModalBody>
                            <ModalFooter>
                                <DefaultSpinner isLoading={isLoading} />
                                <Button color="primary" type="submit">
                                    {translations.actions.save}
                                </Button>
                                <Button color="secondary" type="button" onClick={toggleModal}>
                                    {translations.actions.cancel}
                                </Button>
                            </ModalFooter>
                            <WarningModal
                                setIsOpen={setIsWarningModalOpen}
                                isOpen={isWarningModalOpen}
                                setIsMainModalOpen={setIsModalOpen}
                            />
                        </Form>
                    );
                }}
            />
        </Modal>
    );
};

EditAnimatedBannerImageModal.propTypes = propTypes;

const mapStateToProps = state => ({
    isLoading: state.loaders.global
});

export default withFormUtils(connect(mapStateToProps, null)(EditAnimatedBannerImageModal));
