import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row, Button, Alert, Label } from 'reactstrap';
import { FormattedDate, FormattedTime } from 'react-intl';

import { ceneoAvailParams, productIndexForCeneoOptions } from '../../../../../config';

import { generateNonIdsOptionsForDropdown, detectProperPath } from '../../../../../utils/components';

import CategoryTypeController from '../../../../../features/menu/editControllers/CategoryTypeController';

import FormFiedSwitchPill from '../../../../../components/formFields/FormFieldSwitchPill';
import FormFieldTextOrNumber from '../../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldWithCheckbox from '../../../../../components/formFields/FormFieldWithCheckbox';
import FormFieldWithDropdown from '../../../../../components/formFields/FormFieldWithDropdown';

import { formUtilsHocPropTypes } from '../../../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    utils: formUtilsHocPropTypes,
    translations: PropTypes.object,
    onSubmit: PropTypes.func,
};

const SettingsSection = ({
    translations,
    settings,
    onSubmit,
    generateCeneoXML,
    updateCeneoCategories,
    ceneoCategories,
    utils,
}) => {
    const [ isCopied, setIsCopied ] = useState(false);
    const [ isAlertOpen, setIsAlertOpen ] = useState(false);
    const [ selectedCategoryForUnmappedId, setSelectedCategoryForUnmappedId ] = useState(null);

    const ceneoPathRef = useRef(null);

    useEffect(() => {
        if(settings) {
            setSelectedCategoryForUnmappedId(settings.defaultCategoryForUnmapped);
        }
    }, [settings]);

    const handlePreviewXML = () => {
        const ceneoFilePath = settings.ceneoPath;

        window.open(detectProperPath(ceneoFilePath));
    };

    const handleOnSubmit = (form) => {
        const objectToSend = {
            ...form,
            defaultCategoryForUnmapped: selectedCategoryForUnmappedId,
            xmlGenerateHourFrequency: form.generateXMLAuto ? form.xmlGenerateHourFrequency : null,
        };

        onSubmit(objectToSend);
    };

    const handleSetEmptyCategory = () => {
        setSelectedCategoryForUnmappedId(0);
    };

    const handleCopyToClipboard = e => {
        ceneoPathRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setIsCopied(true);
        setIsAlertOpen(true);

        setTimeout(() => {
            setIsAlertOpen(false);
        }, 3000);
    };

    const handleHideAlert = () => {
        setIsAlertOpen(false);
    };

    const availDropdownOptions = Object.keys(ceneoAvailParams).map(key => ({
        id: ceneoAvailParams[key],
        label: translations.availParamsLabels[key]
    }));

    const productIndexOptions = generateNonIdsOptionsForDropdown(productIndexForCeneoOptions, translations.productIndexOptions);

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    initialValues={settings ? {
                        ...settings,
                        generateXMLAuto: settings.xmlGenerateHourFrequency ? true : false
                    } : {}}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, values }) => {
                        const currentLabelAvailableProduct = availDropdownOptions.find(option => option.id === values.availParamAvailableProducts) || translations.dropdownSelectLabel;
                        const currentLabelUnavailableProduct = availDropdownOptions.find(option => option.id === values.availParamUnavailableProducts) || translations.dropdownSelectLabel;
                        const currentLabelProductIndex = productIndexOptions.find(option => option.id === values.productIndex) || translations.dropdownSelectLabel;

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container fluid className={clsx("pt-3 px-0")}>
                                    <Row className="mb-4">
                                        <FormFiedSwitchPill
                                            name='enabled'
                                            label={translations.inputs.enable}
                                        />
                                    </Row>
                                    <Row className="mb-4">
                                        <div onClick={handleCopyToClipboard}>
                                            <FormFieldTextOrNumber
                                                readOnly
                                                name='ceneoPath'
                                                innerRef={ceneoPathRef}
                                                label={translations.inputs.ceneoPath}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="flex-md-nowrap my-3 flex-column">
                                        <FormFieldWithCheckbox
                                            name='generateXMLAuto'
                                            label={translations.inputs.generateXLMAuto}
                                        />
                                        <FormFieldTextOrNumber
                                            disabled={!values.generateXMLAuto}
                                            name='xmlGenerateHourFrequency'
                                            type='number'
                                            min={1}
                                            max={168}
                                            label={translations.inputs.generateFileFrequency}
                                            inputClassName={styles.editionField}
                                        />
                                        <div>
                                            <span>{translations.inputs.lastGeneratedFileDate}</span>
                                            <strong>
                                                <span>
                                                    <FormattedTime value={values.lastGenerationDate} />
                                                </span>
                                                <span className='ml-1'>
                                                    <FormattedDate value={values.lastGenerationDate} day='2-digit' month='2-digit' year='numeric' />
                                                </span>
                                            </strong>
                                        </div>
                                    </Row>
                                    <Row className='mb-4'>
                                        <FormFieldWithDropdown
                                            name='productIndex'
                                            label={translations.productIndexDropdown.label}
                                            className={styles.editionField}
                                            options={productIndexOptions}
                                            currentSelectionLabel={currentLabelProductIndex.label || translations.dropdownSelectLabel}
                                        />
                                    </Row>
                                    <Row className='mb-4'>
                                        <FormFieldWithDropdown
                                            name='availParamAvailableProducts'
                                            label={translations.availableAvailParam.label}
                                            className={styles.editionField}
                                            options={availDropdownOptions}
                                            currentSelectionLabel={currentLabelAvailableProduct.label || translations.dropdownSelectLabel}
                                        />
                                    </Row>
                                    <Row className='mb-4'>
                                        <FormFieldWithDropdown
                                        name='availParamUnavailableProducts'
                                        label={translations.unavailableAvailParam.label}
                                        className={styles.editionField}
                                        options={availDropdownOptions}
                                        currentSelectionLabel={currentLabelUnavailableProduct.label || translations.dropdownSelectLabel}
                                        />
                                    </Row>
                                    <Row className="flex-md-nowrap mb-4">
                                        <FormFieldWithCheckbox
                                            name='useStockParam'
                                            label={translations.inputs.useStockParam}
                                        />
                                    </Row>
                                    <Row className='mb-4 flex-column'>
                                        <FormFieldWithCheckbox
                                            name='generateOnlyWithMappedProducts'
                                            label={translations.inputs.generateMappedProductsFile}
                                        />
                                    </Row>
                                    <Row className='d-flex flex-column'>
                                        <Label>{translations.defaultUnmappedCategory.label}</Label>
                                        <div className={styles.categorySelector}>
                                            <Button
                                                color='info'
                                                disabled={!selectedCategoryForUnmappedId}
                                                onClick={handleSetEmptyCategory}
                                            >
                                                <i className={clsx('fa fa-check mr-2')}/>
                                                <span>{translations.buttons.emptyCategory}</span>
                                            </Button>
                                            <CategoryTypeController
                                                flattenedCategoryTree={ceneoCategories}
                                                setSelectedCategoryId={setSelectedCategoryForUnmappedId}
                                                currentLanguage={utils.currentLanguage()}
                                                initialySelectedId={selectedCategoryForUnmappedId}
                                            />
                                        </div>
                                    </Row>
                                </Container>
                                <Row className='w-100 mt-5 mb-4 d-flex flex-wrap justify-content-end'>
                                    <Button
                                        className='mr-md-auto  my-2 my-md-0'
                                        color={'primary'}
                                        onClick={updateCeneoCategories}
                                    >
                                        <i className={clsx('fa fa-plus mr-2')}/>
                                        <span>{translations.buttons.updateCeneoCategories}</span>
                                    </Button>
                                    <Button
                                        className='mx-md-1 my-2 my-md-0'
                                        color={'warning'}
                                        onClick={handlePreviewXML}
                                    >
                                        <i className={clsx('fa fa-search mr-2')}/>
                                        <span>{translations.buttons.preview}</span>
                                    </Button>
                                    <Button
                                        className='mx-md-3  my-2 my-md-0'
                                        color={'primary'}
                                        onClick={generateCeneoXML}
                                    >
                                        <i className={clsx('fa fa-spinner mr-2')}/>
                                        <span>{translations.buttons.generateXML}</span>
                                    </Button>
                                    <Button
                                        className='mx-md-1  my-2 my-md-0'
                                        color={'success'}
                                        onClick={handleSubmit}
                                    >
                                        <i className={clsx('fa fa-save mr-2')}/>
                                        <span>{translations.buttons.save}</span>
                                    </Button>
                                </Row>
                            </Form>
                        );
                    }}
                />
            </Container>
            {
                isCopied &&
                    <Alert
                        className={clsx('fixed-top', styles.alert)}
                        color='primary'
                        isOpen={isAlertOpen}
                        toggle={handleHideAlert}
                    >
                        <span>{translations.pathCopiedAlert}</span>
                    </Alert>
            }
        </React.Fragment>
    );
};

SettingsSection.propTypes = propTypes;

export default SettingsSection;