import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    message: null,
    type: null,
    visible: false,
    duration: 4000
};

const toastReducer = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        performShowToast(state, action) {
            const { message, type, duration } = action.payload;

            state.message = message;
            state.type = type || 'success';
            state.duration = duration || 4000;
            state.visible = true;
        },

        performHideToast(state) {
            state.message = null;
            state.type = null,
            state.duration = null;
            state.visible = false;
        },

        setIsSuccess(state, action) {
            const { isSuccess } = action.payload;

            state.message = null;
            state.type = 'success';
            state.duration = 4000;
            state.visible = isSuccess;
        },
    }
});

export const { performShowToast, performHideToast, setIsSuccess } = toastReducer.actions;

export default toastReducer.reducer;