import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import useFetch from '../../../../hooks/useFetch';
import { getAttributeDictionaryValues } from '../../../../redux/methods/attributes';
import { generateDropdownOptionsForTags } from '../../../../utils/components';
import { addUpdatePageSection } from '../../../../redux/methods/homePage';

import { withFormUtils } from '../../../../hoc/withFormUtils';
import Tabulator from '../../../../components/Tabulator';
import WarningModal from '../../../../components/modals/WarningModal';
import TabProductAssigner from '../../../../features/products/TabProductAssigner';
import TabSectionParams from './TabSectionParams';
import { formUtilsHocPropTypes } from '../../../../propTypes';

const propTypes = {
    getAttributeDictionaryValues: PropTypes.func,
    utils: formUtilsHocPropTypes,
    updateProductCarouselGeneral: PropTypes.func,
    getProductCarouselGeneral: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const ArticlesSection = ({
    getAttributeDictionaryValues,
    utils,
    section,
    addUpdatePageSection,
    sectionLocation
}) => {
    const query = new URLSearchParams(location.search);
    const chosenSectionId = query.get('sectionID');

    const intl = useIntl();

    const translations = intl.messages.products.settings;
    const translationsHomePage = intl.messages.homePage;

    const tagId = 1;
    const [ tags ] = useFetch(getAttributeDictionaryValues, tagId);

    const isFormChanged = useRef();

    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);

    const [ isMainModalOpen, setIsMainModalOpen ] = useState(() => () => { });

    const [ isEdited, setIsEdited ] = useState(false);

    const canChangeTab = changeTab => {
        if (isFormChanged.current || isEdited) {
            setIsWarningModalOpen(true);
            setIsMainModalOpen(() => changeTab);

            return false;
        }
        else {
            return true;
        }
    };

    return tags && section ? (
        <>
            <WarningModal
                setIsOpen={setIsWarningModalOpen}
                isOpen={isWarningModalOpen}
                setIsMainModalOpen={isMainModalOpen}
            />
            <Tabulator
                canChangeTab={canChangeTab}
                listOfElements={[
                    {
                        label: translationsHomePage.tabs.basicData,
                        id: 'homePageTab-settings',
                        component: (
                            <TabSectionParams section={section} addUpdatePageSection={addUpdatePageSection} sectionLocation={sectionLocation} />
                        )
                    },
                    ...section.tabs.map(settings => {
                        return (chosenSectionId > -1) && ({
                            id: `homePageTab${settings.lp}-settings`,
                            label: `${translations.tabs.tab} ${settings.lp}`,
                            component: (
                                <TabProductAssigner
                                    tagOptions={generateDropdownOptionsForTags(tags, utils.currentLanguage())}
                                    translations={translations}
                                    currentLanguage={utils.currentLanguage()}
                                    onSubmit={addUpdatePageSection}
                                    settings={settings}
                                    utils={utils}
                                    section={section}
                                    sectionLocation={sectionLocation}
                                    isFormChanged={isFormChanged}
                                    setIsEdited={setIsEdited}
                                />
                            )
                        });
                    })
                ]}
            />
        </>
    ) : null;
};

ArticlesSection.propTypes = propTypes;

const mapDispatch = {
    getAttributeDictionaryValues,
    addUpdatePageSection
};

export default withFormUtils(connect(null, mapDispatch)(ArticlesSection));