import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';

import { extendSession } from '../../../redux/methods/appInit';
import { convertToMs } from '../../../utils/components';

import SessionTimeoutCard from '../SessionTimeoutCard';

import { sessionRemainingTimeInMin } from '../../../config';

import { stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    extendSession: PropTypes.func,
    sessionTimeoutValue: stringOrNumberPropTypes,
    timeoutRefetcher: PropTypes.bool,
    history: PropTypes.object,
};

const EndOfSessionWarner = ({ timeoutRefetcher, extendSession, sessionTimeoutValue, history }) => {
    const intl = useIntl();
    const translations = intl.messages.defaultLayout.endOfSession.modal;

    const [remainingTime, setRemainingTime] = useState(sessionTimeoutValue);
    const [timeoutCounter, setTimeoutCounter] = useState(59);

    const convertedSessionTimeoutValueToMs = convertToMs(sessionTimeoutValue);

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    useEffect(() => {
        setRemainingTime(sessionTimeoutValue);
    }, [timeoutRefetcher, sessionTimeoutValue]);

    useEffect(() => {
        if (remainingTime && remainingTime === sessionTimeoutValue) {
            const timeout = setTimeout(() => {
                setRemainingTime(sessionRemainingTimeInMin.firstStep);
            }, convertedSessionTimeoutValueToMs - convertToMs(sessionRemainingTimeInMin.firstStep));

            return () => {
                clearTimeout(timeout);
            };
        }
        else if ( remainingTime && remainingTime === sessionRemainingTimeInMin.firstStep) {
            const timeout = setTimeout(() => {
                setRemainingTime(sessionRemainingTimeInMin.secondStep);
            }, convertToMs(sessionRemainingTimeInMin.firstStep) - convertToMs(sessionRemainingTimeInMin.secondStep));

            return () => {
                clearTimeout(timeout);
            };
        }
        else if (remainingTime && remainingTime === sessionRemainingTimeInMin.secondStep) {
            const timeout = setTimeout(() => {
                setRemainingTime(sessionRemainingTimeInMin.thirdStep);
            }, convertToMs(sessionRemainingTimeInMin.secondStep) - convertToMs(sessionRemainingTimeInMin.thirdStep));

            return () => {
                clearTimeout(timeout);
            };
        }
        else if (remainingTime && remainingTime === sessionRemainingTimeInMin.thirdStep) {
            const thirdStepInterval = setInterval(() => {
                setTimeoutCounter((prevState) => {
                    if (prevState > 0) {
                        return prevState - 1;
                    }
                    else {
                        clearInterval(thirdStepInterval);
                        history.push('/sessionEnd');
                        return 0;
                    }
                });
            }, 1000);

            return () => {
                clearInterval(thirdStepInterval);
            };
        }

    }, [convertedSessionTimeoutValueToMs, remainingTime, sessionTimeoutValue, timeoutRefetcher, history]);

    return (
        remainingTime === sessionRemainingTimeInMin.firstStep
            ? isMobile
                ? <i className={clsx("fa fa-exclamation-circle", styles.mobileIcon)} />
                : <span className={styles.info}>
                    {translations.timeRemainingLabels.firstStep}
                </span>
            : remainingTime === sessionRemainingTimeInMin.secondStep
                ? isMobile
                    ? <i className={clsx("fa fa-exclamation-circle", styles.mobileIcon, styles.flash)} />
                    : <span className={clsx(styles.info, styles.flash)}>
                        {translations.timeRemainingLabels.secondStep}
                    </span>
                : remainingTime === sessionRemainingTimeInMin.thirdStep
                    ? isMobile
                        ? <i className={clsx("fa fa-exclamation-circle", styles.mobileIcon, styles.flash)} />
                        : <SessionTimeoutCard
                            translations={translations}
                            timeoutCounter={timeoutCounter}
                            onClick={extendSession}
                        />
                    : null
    );
};

EndOfSessionWarner.propTypes = propTypes;

const mapStateToProps = state => ({
    timeoutRefetcher: state.refetchers.sessionTimeout,
    sessionTimeoutValue: state.appInit.sessionTimeoutValue,
});

const mapDispatch = {
    extendSession,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(EndOfSessionWarner));