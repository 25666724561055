import { addEditData, getData, removeData } from '../generic';
import { cAPI } from '../../../utils/cAPI';

export const performAttributeSearch = searchConfig => dispatch => getData(dispatch, cAPI.APGetAttributesSearchResult, searchConfig, 'attributes');

export const getAttributeBasicData =                id => dispatch => getData(dispatch, cAPI.APGetAttributeBasicData, id, 'attributesEdit');
export const getAttributeCategories =               id => dispatch => getData(dispatch, cAPI.APGetAttributeCategories, id, 'attributesCategories');
export const getAttributeDictionaryValues =         id => dispatch => getData(dispatch, cAPI.APGetAttributeDictionaryValues, id, 'attributesDictionaryValues');
export const getAttributeColorValues =              id => dispatch => getData(dispatch, cAPI.APGetAttributeColorValues, id, 'attributesColorValues');
export const getAttributeSettings =                 id => dispatch => getData(dispatch, cAPI.APGetAttributeSettings, id);

export const updateAttributeBasicData =             formData => dispatch => addEditData(dispatch, cAPI.APUpdateAttributeBasicData, formData);
export const updateAttributeDictionaryValue =       formData => dispatch => addEditData(dispatch, cAPI.APUpdateAttributeDictionaryValue, formData, 'attributesDictionaryValues');
export const updateAttributeColorValue =            formData => dispatch => addEditData(dispatch, cAPI.APUpdateAttributeColorValue, formData, 'attributesColorValues');
export const updateAttributeSettings =              formData => dispatch => addEditData(dispatch, cAPI.APUpdateAttributeSettings, formData);
export const addAttributeCategory =                 formData => dispatch => addEditData(dispatch, cAPI.APAddAttributeCategory, formData, 'attributesCategories');
export const deleteAttributeCategory =              formData => dispatch => addEditData(dispatch, cAPI.APDeleteAttributeCategory, formData, 'attributesCategories');

export const deleteAttributeById =                  id => dispatch => removeData(dispatch, cAPI.APDeleteAttributeById, id, 'attributes');
export const deleteAttributeDictionaryValue =       id => dispatch => removeData(dispatch, cAPI.APDeleteAttributeDictionaryValue, id, 'attributesDictionaryValues');
export const deleteAttributeColorValue =            id => dispatch => removeData(dispatch, cAPI.APDeleteAttributeColorValue, id, 'attributesColorValues');

export const updateAttributeDictionaryValueActivity = formData => dispatch => addEditData(dispatch, cAPI.APUpdateAttributeDictionaryValueActivity, formData, 'attributesDictionaryValues');

export const getFilters = () => dispatch => getData(dispatch, cAPI.APGetFilters, null, 'attributes');
export const getDictionaryMappingFields = () => dispatch => getData(dispatch, cAPI.APGetDictionaryMappingFields);