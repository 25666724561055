import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getSynchronizationsErp, updateSynchronizationsErp } from '../../../../redux/methods/synchronizations';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFiedSwitchPill from '../../../../components/formFields/FormFieldSwitchPill';

import useFetch from '../../../../hooks/useFetch';
import { erpIdsEnum } from '../../../../enums/synchronizations';
import { getKeyByValue } from '../../../../utils/shared';
//import Modal from '../../../../components/modals/Modal';

const propTypes = {
    getSynchronizationsErp: PropTypes.func,
    updateSynchronizationsErp: PropTypes.func,
};

const Erp = ({ getSynchronizationsErp, updateSynchronizationsErp }) => {
    const intl = useIntl();
    const translations = intl.messages.synchronizations.erp;

    //const [isOpen, setIsOpen] = useState(false);

    const [ data ] = useFetch(getSynchronizationsErp);

    const getActiveId = formData => {
        const activeKey = getKeyByValue(formData, true);
        const activeId = parseInt(getKeyByValue(erpIdsEnum, activeKey));

        return activeId || null;
    };

    const handleOnSubmit = formData => {
        const activeId = getActiveId(formData);

        if(activeId) {
            updateSynchronizationsErp({ activatedErpId: activeId }); //setIsOpen(true);
        } else {
            updateSynchronizationsErp({ activatedErpId: null });
        }
    };

    // const handleConfirmModal = formData => () => {
    //     const activeId = getActiveId(formData);

    //     updateSynchronizationsErp({ activatedErpId: activeId });
    //     setIsOpen(false);
    // };

    const initialValues = data && data.activatedErpId ? { [erpIdsEnum[data.activatedErpId]]: true } : {};

    return data ? (
        <Container fluid>
            <FinalForm
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values }) => {
                    const disabled = Object.values(values).includes(true);
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <FormFiedSwitchPill
                                    name='wapro-mag'
                                    label={translations.wapro}
                                    disabled={disabled && !values['wapro-mag']}
                                />
                            </Row>
                            <SectionEditFormButtons formReset={form.reset}/>
                            {/* <Modal
                                isOpen={isOpen}
                                header={translations.modal.header}
                                body={translations.modal.body}
                                buttons={{
                                    confirm: {
                                        color: 'danger',
                                        label: translations.modal.confirm,
                                    },
                                    cancel: {
                                        label: translations.modal.cancel,
                                    },
                                }}
                                onConfirm={handleConfirmModal(values)}
                                setIsOpen={setIsOpen}
                            /> */}
                        </Form>
                    );
                }}
            />
        </Container>
    ) : null;
};

Erp.propTypes = propTypes;

const mapDispatch = {
    getSynchronizationsErp,
    updateSynchronizationsErp,
};

export default connect(null, mapDispatch)(Erp);