import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { assingIconToFeatureAttributeType, generateEditLinkForFeature } from '../../../../utils/attributes';
import { attributeTypeEnum } from '../../../../enums/attributes';
import { translateEnumValue } from '../../../../utils/components';

import { translatedFeaturesAttributesListItemPropTypes } from '../../../../propTypes';

import styles from './index.module.scss';
import TableGenerator from '../../../../components/generators/TableGenerator';

const propTypes = {
    translatedFeaturesList: PropTypes.arrayOf(translatedFeaturesAttributesListItemPropTypes),
    removeHandler: PropTypes.func,
    history: PropTypes.object
};

const FeatureTable = ({
    translatedFeaturesList,
    removeHandler,
    history,
    currentPage,
    pageSize,
    performPageSizeSelect,
}) => {
    const intl = useIntl();
    const translations = intl.messages.features.table;
    const attributeTypeTranslations = intl.messages.features.searchForm.filters.attributeType;
    const attributePresentationTranslations = intl.messages.features.searchForm.filters.attributePresentation;

    const deleteAction = featureId => () => {
        removeHandler(featureId);
    };

    const handleDefaultAction = id => () => {
        history.push(generateEditLinkForFeature(id));
    };

    const CategoriesCell = item => {
        return (
            <ul className={styles.categoriesList} onClick={handleDefaultAction(item.id)}>
                {
                    item.translatedAttributeCategories.map((categoryObject, i) =>
                        <li key={`feature-${item.id}-category-${i}`} className={styles.categoriesListItem}>
                            {categoryObject}
                        </li>
                    )
                }
            </ul>
        );
    };

    const TypeCell = item => {
        return (
            <span onClick={handleDefaultAction(item.id)}>
                <i className={clsx(assingIconToFeatureAttributeType(item.attributeTypeId), 'mr-1')}/>
                <span>{translateEnumValue(attributeTypeEnum, attributeTypeTranslations, item.attributeType)}</span>
            </span>
        );
    };

    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            pageSize={pageSize}
            currentPage={currentPage}
            items={translatedFeaturesList}
            editLink={generateEditLinkForFeature}
            translations={translations}
            expectedActions={['edit', 'delete']}
            actions={{deleteAction}}
            defaultAction={handleDefaultAction}
            columns={[
                {
                    header: translations.headers.name,
                    value: 'translatedName',
                    filler: translations.nameFiller
                },
                {
                    header: translations.headers.categories,
                    type: 'custom',
                    customCell: CategoriesCell,
                },
                {
                    header: translations.headers.type,
                    type: 'custom',
                    customCell: TypeCell,
                },
                {
                    header: translations.headers.presentation,
                    value: 'attributePresentation',
                    customTranslation: attributePresentationTranslations
                },
                {
                    header: translations.headers.actions,
                    type: 'actions',
                }
            ]}
        />
    );
};

FeatureTable.propTypes = propTypes;

export default FeatureTable;