import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

const propTypes = {
    listOfElements: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            component: PropTypes.node,
        })
    ).isRequired,
    onTabChange: PropTypes.func,
};

const Tabulator = ({ listOfElements, onTabChange, defaultActiveTabId, canChangeTab = () => true }) => {
    const [activeTabId, setActiveTabId] = useState(defaultActiveTabId || listOfElements[0].id);

    const handleActiveTabIdSelection = id => () => {
        if (activeTabId !== id) {
            if(canChangeTab(() => setActiveTabId(id))) {
                setActiveTabId(id);
            }

            if(onTabChange) {
                onTabChange(id);
            }
        }
    };

    return (
        <div>
            <Nav tabs>
                {listOfElements.map(element => {
                    return (
                        <NavItem key={`${element.id}TabulatorNavItem`}>
                            <NavLink
                                className={clsx({
                                    active: activeTabId === element.id,
                                })}
                                onClick={handleActiveTabIdSelection(element.id)}
                            >
                                {element.label}
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={activeTabId}>
                {listOfElements.map(element => {
                    return element.id === activeTabId && (
                        <TabPane
                            key={`${element.id}TabulatorTabPane`}
                            tabId={element.id}
                        >
                            {element.component}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
};

Tabulator.propTypes = propTypes;

export default Tabulator;