import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getNotFound, updateNotFound, updateNotFoundImage } from '../../../redux/methods/extrasSettings';

import useFetch from '../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Row, Form } from 'reactstrap';

import SectionAsideButtons from '../../../components/SectionAsideButtons';
import FormFieldTranslationsQuill from '../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldColorPicker from '../../../components/formFields/FormFieldColorPicker';
import ContainerWithCard from '../../../components/ContainerWithCard';
import FileSender from './FileSender';
import FormFieldTranslations from '../../../components/formFields/FormFieldTranslations';

import { withFormUtils } from '../../../hoc/withFormUtils';

import { formUtilsHocPropTypes } from '../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getNotFound: PropTypes.func,
    updateNotFound: PropTypes.func,
};

const NotFound = ({ utils, getNotFound, updateNotFound, updateNotFoundImage }) => {
    const intl = useIntl();
    const translations = intl.messages.notFound;

    const [ notFoundData ] = useFetch(getNotFound);
    const [ imageFile, setImageFile ]= useState(null);

    const config = { translations: ['content', 'button'] };

    const handleOnSubmit = formData => {
        updateNotFound(utils.generateFormToSubmitValues(formData, config));

        if(imageFile && imageFile.generatedFile) {
            updateNotFoundImage(imageFile.generatedFile);
        }
    };

    return notFoundData ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search'>
            <FinalForm
                onSubmit={handleOnSubmit}
                initialValues={utils.generateFormInitialValues(notFoundData, config)}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row className='mt-3'>
                                <FormFieldColorPicker
                                    name='mainColor'
                                    button={translations.labels.mainColorButton}
                                    label={translations.labels.mainColor}
                                />
                            </Row>
                            <Row className='mt-3'>
                                <FileSender translations={translations} setFile={setImageFile} />
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldTranslationsQuill
                                    form={form}
                                    utils={utils}
                                    propertyName='content'
                                    header={translations.labels.content}
                                />
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldTranslations
                                    form={form}
                                    propertyName='button'
                                    currentIso={utils.currentLanguage()}
                                    label={translations.labels.button}
                                    modalHeader={translations.labels.button}
                                />
                            </Row>
                            <SectionAsideButtons
                                buttons={{
                                    add: {
                                        color: 'success',
                                        label: translations.button,
                                        action: handleSubmit,
                                        iconClass: 'fa fa-save mr-2',
                                    }
                                }}
                            />
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

NotFound.propTypes = propTypes;

const mapDispatch = {
    getNotFound,
    updateNotFound,
    updateNotFoundImage
};

export default withFormUtils(connect(null, mapDispatch)(NotFound));