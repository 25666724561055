import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Form, Row } from 'reactstrap';

import { Form as FinalForm  } from 'react-final-form';

import ContainerWithCard from '../../components/ContainerWithCard';

import SectionEditFormButtons from '../../components/SectionEditFormButtons';

import useFetch from '../../hooks/useFetch';

import { generateDropDownOptionsForEnum } from '../../utils/components';

import { identifiersEnum, columnSeparatorsEnum } from '../../enums/productsDescriptionsImport';

import FormFieldWithDropdown from '../../components/formFields/FormFieldWithDropdown';

import FormFieldSwitchPill from '../../components/formFields/FormFieldSwitchPill';

import FormFieldTextOrNumber from '../../components/formFields/FormFieldTextOrNumber';

import { getProductDescriptionImportSettings, updateProductDescriptionImportSettings, importProductDescription } from '../../redux/methods/productsDescriptionsImport';

import FileSender from '../../components/FileSender';

const propTypes = {
    getProductsDescriptionsImportSettings: PropTypes.func,
    updateProductDescriptionImportSettings: PropTypes.func,
    importProductDescription: PropTypes.func
};

const ProductsDescriptionsImport = ({
    getProductDescriptionImportSettings,
    updateProductDescriptionImportSettings,
    importProductDescription,
    forceRefetch
}) => {
    const intl = useIntl();
    const translations = intl.messages.productsDescriptionsImport;

    const [ data ] = useFetch(getProductDescriptionImportSettings, null, [], forceRefetch);

    const identifierOptions = generateDropDownOptionsForEnum(identifiersEnum, translations.identifiers, false);

    const separatorOptions = generateDropDownOptionsForEnum(columnSeparatorsEnum, translations.separators, false);

    const handleImportProductDescription = formData => async file => {
        let result = await updateProductDescriptionImportSettings(formData, true);

        if(!result) {
            result = await importProductDescription(file);
        }

        return result;
    };

    return data ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-file-import'>
            <FinalForm
                initialValues={data}
                onSubmit={updateProductDescriptionImportSettings}
                render={({ handleSubmit, form, values }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <FormFieldWithDropdown
                                    name='identifier'
                                    label={translations.identifier}
                                    options={identifierOptions}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <FormFieldWithDropdown
                                    name='columnSeparator'
                                    label={translations.separator}
                                    options={separatorOptions}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <FormFieldSwitchPill
                                    name='hasHeader'
                                    label={translations.hasHeader}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <FormFieldSwitchPill
                                    name='overrideExistingDescriptions'
                                    label={translations.overrideExistingDescriptions}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <FormFieldSwitchPill
                                    name='hasQuote'
                                    label={translations.hasQuote}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <FormFieldTextOrNumber
                                    type='number'
                                    min={1}
                                    name='identifierColumnNumber'
                                    step='1'
                                    label={translations.identifierColumnNumber}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <FormFieldTextOrNumber
                                    type='number'
                                    min={1}
                                    name='htmlDescriptionColumnNumber'
                                    step='1'
                                    label={translations.htmlDescriptionColumnNumber}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <FileSender
                                    buttons={{upload: translations.import}}
                                    validateFormats={['csv']}
                                    handleUpload={handleImportProductDescription(values)}
                                />
                            </Row>
                            <SectionEditFormButtons formReset={form.reset} />
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ): null;
};

ProductsDescriptionsImport.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.productDescriptionImport
});

const mapDispatch = {
    getProductDescriptionImportSettings,
    updateProductDescriptionImportSettings,
    importProductDescription
};

export default connect(mapStateToProps, mapDispatch)(ProductsDescriptionsImport);