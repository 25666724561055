import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { updatePaymentBasicData, updateShipmentBasicData } from '../../../../redux/methods/products';
import { generateStatusesConfig } from '../../../../utils/products';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldTranslations from '../../../../components/formFields/FormFieldTranslations';
import FormFieldSwitchPill from '../../../../components/formFields/FormFieldSwitchPill';
import FormFieldStatus from '../../../../components/formFields/FormFieldStatus';

import { productStatusEnum } from '../../../../enums/products';

import { formUtilsHocPropTypes } from '../../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    updatePaymentBasicData: PropTypes.func,
    updateShipmentBasicData: PropTypes.func,
    forceRefetch: PropTypes.bool,
    isConnectedToERP: PropTypes.bool,
};

const ServiceEditBasicSection = ({
    utils,
    data,
    updatePaymentBasicData,
    updateShipmentBasicData,
    isConnectedToERP,
    productType
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.basic;

    const config = { translations: ['name'] };

    const handleOnSubmit = formData => {
        const dataToSend = utils.generateFormToSubmitValues(formData, config);

        Object.assign(dataToSend, { productType: productType });

        switch(productType){
            case 2: updateShipmentBasicData(dataToSend); break;
            case 3: updatePaymentBasicData(dataToSend); break;
            default: throw Error('Unknown product type');
        }
    };

    const statuses = generateStatusesConfig(productStatusEnum, translations.statusLabels);

    return data ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    initialValues={utils.generateFormInitialValues(data, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        const { values } = form.getState();

                        const selectedStatus = statuses.find(status => status.id === values.status) || statuses[0];
                        const selectedStatusErp = statuses.find(status => status.id === values.statusErp) || statuses[0];

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='pt-3 px-0' fluid>
                                    <Row className='my-3 flex-md-nowrap'>
                                        <FormFieldStatus
                                            label={translations.inputs.status}
                                            name='status'
                                            selectedStatus={selectedStatus}
                                            statuses={statuses}
                                        />
                                        {isConnectedToERP &&
                                            <FormFieldStatus
                                                label={translations.inputs.statusErp}
                                                statuses={statuses}
                                                selectedStatus={selectedStatusErp}
                                                name='statusErp'
                                                disabled
                                            />
                                        }
                                    </Row>
                                    <Row className='mb-3'>
                                        <FormFieldTranslations
                                            form={form}
                                            modalHeader={translations.modalHeader}
                                            propertyName='name'
                                            currentIso={utils.currentLanguage()}
                                            label={translations.inputs.productName}
                                            className='w-100'
                                        />
                                    </Row>
                                    <Row className='my-3'>
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.originalName}
                                            name='originalName'
                                            className='w-100'
                                        />
                                    </Row>
                                    {isConnectedToERP &&
                                        <React.Fragment>
                                            <Row className='flex-md-nowrap'>
                                                <FormFieldSwitchPill
                                                    utils={utils}
                                                    name='externalNameSync'
                                                    label={translations.inputs.syncErp}
                                                />
                                            </Row>
                                            <Row className='my-3'>
                                                <FormFieldTextOrNumber
                                                    disabled={true}
                                                    label={translations.inputs.nameFromERP}
                                                    name='externalName'
                                                    className='w-100'
                                                />
                                            </Row>
                                        </React.Fragment>
                                    }
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

ServiceEditBasicSection.propTypes = propTypes;

const mapStateToProps = (state) => ({
    isConnectedToERP: state.appInit.isConnectedToERP
});

const mapDispatch = {
    updatePaymentBasicData,
    updateShipmentBasicData
};

export default withRouter(withFormUtils(connect(mapStateToProps, mapDispatch)(ServiceEditBasicSection)));