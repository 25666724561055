import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { cssGradientGenerator } from '../../utils/attributes';

import { Dropdown as ReactstrapDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input  } from 'reactstrap';

import styles from './index.module.scss';
import { stringOrNumberPropTypes } from '../../propTypes';

const propTypes = {
    currentSelectionLabel: stringOrNumberPropTypes,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
            label: stringOrNumberPropTypes,
            className: PropTypes.string,
        })
    ),
    isDisabled: PropTypes.bool,
    performSelection: PropTypes.func,
    withCheckboxes: PropTypes.bool,
    withColors: PropTypes.bool,
    disableCheckbox: PropTypes.bool,
    className: PropTypes.string,
};

const Dropdown = ({
    currentSelectionLabel,
    options,
    isDisabled,
    performSelection,
    withCheckboxes,
    checkedIds,
    withColors,
    currentColorSelection,
    disableCheckbox = false,
    className = null,
    withSearch = false
}) => {
    const [ isOpen, setIsOpen ] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handlePerformSelection = id => () => {
        performSelection(id);
    };

    const isCheckboxDisabled = id => {
        return disableCheckbox
            ? checkedIds.includes(id)
                ? false
                : true
            : false;
    };

    const [ searchPhrase, setSearchPhrase ] = useState('');

    const onChangeSearchPhrase = e => {
        setSearchPhrase(e.target.value);
    };

    return (
            <ReactstrapDropdown
                direction='down'
                className={clsx(styles.dropdown, className)}
                isOpen={isOpen}
                toggle={handleToggle}
                disabled={isDisabled}
            >
                <DropdownToggle caret className={styles.dropdownToggler} disabled={isDisabled}>
                    {currentSelectionLabel}
                    {
                        withColors
                        && <span
                            style={{ background: cssGradientGenerator(currentColorSelection, -225) }}
                            className={clsx(styles.color, styles.colorInLabel)}
                        />
                    }
                </DropdownToggle>
                <DropdownMenu className={styles.dropdownMenu}>
                    {
                        withSearch &&
                        <input
                            className={styles.search}
                            value={searchPhrase}
                            onChange={onChangeSearchPhrase}
                            type="text"
                        />
                    }
                    {
                        options
                            .filter(option =>
                                !withSearch || option.id === null || (option.label && option.label.toLowerCase().includes(searchPhrase.toLowerCase()))
                            )
                            .map(option =>
                                <DropdownItem
                                    key={`selector-${option.id}`}
                                    className={styles.dropdownItem}
                                    onClick={handlePerformSelection(option.id)}
                                    disabled={isCheckboxDisabled(option.id)}
                                    toggle={!withCheckboxes}
                                >
                                    {
                                        withCheckboxes
                                            &&  <Input
                                                    checked={checkedIds.includes(option.id)}
                                                    id={`checkboxItem-${option.id}`}
                                                    type='checkbox'
                                                    onChange={handlePerformSelection(option.id)}
                                                    className={'position-static m-0 p-0 mr-2'}
                                            />
                                    }
                                    <div className='d-inline-flex'>
                                        {
                                            withColors
                                                && <span
                                                        style={{ background: cssGradientGenerator(option.colors, -225) }}
                                                        className={clsx(styles.color, option.colors.length === 0 && styles.colorEmpty)}
                                                    />
                                        }
                                        <span className={clsx(styles.span, option.className)}>{option.label || option.name || option.id}</span>
                                    </div>
                                </DropdownItem>
                            )
                    }
                </DropdownMenu>
            </ReactstrapDropdown>
    );
};

Dropdown.propTypes = propTypes;

export default Dropdown;