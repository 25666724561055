import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getManufacturers, addManufacturer, removeManufacturer, updateManufacturerShowInFilters } from '../../redux/methods/manufacturers';

import { generateEditLinkForManufacturers, translateManufacturers } from '../../utils/manufacturers';

import useFetch from '../../hooks/useFetch';
import useAddFetch from '../../hooks/useAddFetch';
import useSearchConfig from '../../hooks/useSearchConfig';

import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';

import Modal from '../../components/modals/Modal';
import ManufacturerTable from '../../components/tables/ManufacturersTable';
import ManufacturersSearchForm  from '../../components/ManufacturersSearchForm';

import { stringOrNumberPropTypes } from '../../propTypes';

import styles from './index.module.scss';

import { manufacturersSearchConfig } from '../../config/searchConfigs';
import Paginator from '../../components/Paginator';

import { withFormUtils } from '../../hoc/withFormUtils';

const propTypes = {
    getManufacturers: PropTypes.func,
    addManufacturer: PropTypes.func,
    clearNewlyCreatedManufacturerId: PropTypes.func,
    newlyCreatedManufacturerId: stringOrNumberPropTypes,
    history: PropTypes.object,
    forceRefetch: PropTypes.bool,
    removeManufacturer: PropTypes.func,
    updateManufacturerShowInFilters: PropTypes.func,
    isConnectedToERP: PropTypes.bool,
};

const Manufacturers = ({
    getManufacturers,
    addManufacturer,
    history,
    forceRefetch,
    removeManufacturer,
    updateManufacturerShowInFilters,
    isConnectedToERP,
    utils
}) => {
    const intl = useIntl();
    const translations = intl.messages.manufacturers.view;

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(manufacturersSearchConfig);

    const [ manufacturers, pages ] = useFetch(getManufacturers, searchConfig, [ searchConfig ], forceRefetch);
    const [columnSortedByDescendingOrder, setColumnSortedByDescendingOrder ] = useState(null);

    const handlePerformSort = (direction) => {
        changeSearchConfig({ ...searchConfig, orderDirection: direction });
    };

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    const [ newlyCreatedId, add ] = useAddFetch(addManufacturer);

    const [isAdditionModalOpen, setIsAdditionModalOpen] = useState(false);

    useEffect(() => {
        if (newlyCreatedId) {
            history.push(generateEditLinkForManufacturers(newlyCreatedId));
        }
    }, [ newlyCreatedId, history ]);

    const toggleAdditionModal = () => {
        setIsAdditionModalOpen(!isAdditionModalOpen);
    };
    return manufacturers ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody>
                                <main>
                                    <ManufacturersSearchForm
                                        onSubmit={changeSearchConfig}
                                        translations={translations}
                                        searchConfig={searchConfig}
                                    />
                                    <ManufacturerTable
                                        performPageSizeSelect={handleChangePageSize}
                                        pageSize={searchConfig.pageSize}
                                        currentPage={searchConfig.curentPage + 1}
                                        performSort={handlePerformSort}
                                        columnSortedByDescendingOrder={columnSortedByDescendingOrder}
                                        setColumnSortedByDescendingOrder={setColumnSortedByDescendingOrder}
                                        manufacturersList={translateManufacturers(manufacturers, utils)}
                                        removeHandler={removeManufacturer}
                                        history={history}
                                        switchHandler={updateManufacturerShowInFilters}
                                        withSwitch={isConnectedToERP}
                                    />
                                    {
                                        pages > 1 &&
                                            <Paginator
                                                performPageSizeSelect={handleChangePageSize}
                                                pageSize={searchConfig.pageSize}
                                                currentPage={searchConfig.curentPage + 1}
                                                totalPages={pages}
                                                onPageChange={changePage}
                                            />
                                    }
                                </main>
                                <aside className={styles.footer}>
                                    <Button color='primary' onClick={toggleAdditionModal}>
                                        <i className='fa fa-plus mr-2'/>
                                        <span>{translations.actions.addManufacturer}</span>
                                    </Button>
                                </aside>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal
                isOpen={isAdditionModalOpen}
                setIsOpen={setIsAdditionModalOpen}
                header={translations.additionModal.header}
                body={translations.additionModal.body}
                buttons={{
                    confirm: {
                        label: translations.additionModal.confirm,
                        color: 'primary'
                    },
                    cancel: {
                        label: translations.additionModal.cancel,
                        color: 'secondary'
                    }
                }}
                onConfirm={add}
            />
        </Container>
    ) : null;
};

Manufacturers.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.manufacturers,
    isConnectedToERP: state.appInit.isConnectedToERP
});

const mapDispatch = {
    getManufacturers,
    addManufacturer,
    removeManufacturer,
    updateManufacturerShowInFilters
};

export default withFormUtils(withRouter(connect(mapStateToProps, mapDispatch)(Manufacturers)));