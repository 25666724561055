const menuSettings = {
    menu: {
        title: '[EN] Pozycje menu',
        tree: {
            activePositionsExceededAlert: '[EN] Maksymalna liczba aktywnych pozycji menu została osiągnięta.',
            nameFiller: '[EN] BRAK NAZWY',
            upperMenu: '[EN] Menu górne',
            bottomMenu: '[EN] Menu dolne',
            footerSectionsLimitReached: '[EN] Maksymalna ilość (12) widocznych sekcji w menu dolnym osiągnięta.',
            sectionStatusTooltip: '[EN] Sekcja widoczna w menu górnym sklepu',
            deleteModal: {
                header: '[EN] Usuń sekcję',
                body: '[EN] Czy napewno chcesz usunąć sekcję?',
                confirm: '[EN] Usuń',
                cancel: '[EN] Wróc'
            },
            actions: {
                delete: '[EN] Usuń',
                edit: '[EN] Edytuj',
                addSub: '[EN] Dodaj podsekcję',
                addRoot: '[EN] Dodaj sekcję'
            },
            additionModal: {
                header: '[EN] Dodaj sekcję',
                body: '[EN] Czy napewno chcesz dodać nową sekcję?',
                confirm: '[EN] Dodaj',
                cancel: '[EN] Anuluj',
            },
            warningModal: {
                header: '[EN] Aktywuj sekcję',
                body: '[EN] Aktywowanie sekcji spowoduje aktywację jej podsekcji. Czy chcesz kontynuować?',
                confirm: '[EN] AKTYWUJ',
                cancel: '[EN] WRÓĆ'
            },
        },
        edit: {
            title: '[EN] Ustawienia sekcji',
            mapGuides: '[EN] - Kliknij dwa razy w wybrane miejsce aby ustawić znacznik',
            captionAlert: '[EN] Nazwa pozycji menu we wszystkich językach nie może pozostać pusta oraz nie może zawierać więcej niż 25 znaków. Popraw nazwę i zapisz zmiany.',
            inputs: {
                enabled: 'Sekcja widoczna',
                type: 'Typ sekcji',
                name: 'Nazwa sekcji',
                selectCategoryLabel: 'Wybierz kategorię',
                urlLabel: 'Url',
                isOpeningInNewTabLabels: {
                    true: 'Otwórz w nowej karcie',
                    false: 'Otwórz w tej samej karcie'
                },
                customPage: 'Wybierz stronę własną',
                contactPageContent: 'Treść strony',
                selectCsutomPageLabel: 'Wybierz stronę własną',
                emptyCustomPageOptionLabel: 'Wybierz',
                statusTop: 'Aktywność w menu górnym',
                statusBottom: 'Aktywność w menu dolnym',
                redirectFromHomePage: 'Przekieruj ze strony głównej',
                columnLayout: 'Układ kolumnowy',
                file: {
                    edit: 'PODMIEŃ ZDJĘCIE',
                    remove: 'USUŃ ZDJĘCIE',
                    upload: 'PRZEŚLIJ ZDJĘCIE'
                }
            },
            mapSizes: {
                Small: '[EN] Mała - 300px',
                Medium: '[EN] Średnia - 500px',
                Large: '[EN] Duża - 700px'
            },
            typeOptions: {
                manufacturers: '[EN] Lista producentów',
                category: '[EN] Kategoria',
                container: '[EN] Kontener',
                url: '[EN] Link',
                customPage: '[EN] Strona własna',
                selectTypeLabel: '[EN] Wybierz typ sekcji',
                search: '[EN] Wyszukiwanie'
            },
            buttons: {
                addEmail: '[EN] Dodaj email',
                removeEmail: '[EN] Usuń'
            },
            dropdownSelector: {
                label: 'Widoczność kategorii',
                options: {
                    all: 'Wszystkie',
                    activeOnly: 'Tylko aktywne'
                }
            }
        }
    },
    footer: {
        title: '[EN] Menu dolne',
        leftBgc: '[EN] Tło sekcji lewej newslettera',
        edit: {
            inputs: {
                backgroundColor: '[EN] Kolor tła',
                fontColor: '[EN] Kolor czcionki',
                showMenu: '[EN] Pokazuj menu',
                menuOnLeft: '[EN] Pokazuj menu po lewej stronie',
                additionalInfo: '[EN] Dodatkowe informacje',
                newsletter: '[EN] Newsletter',
                customFooter: '[EN] Stopka własna',
                customFooterContent: '[EN] Treść stopki własnej',
                showNewsletter: '[EN] Pokazuj newsletter',
                newsletterLink: '[EN] Link do newslettera',
                newsletterButtonContent: '[EN] Treść przycisku',
                newsletterContentUnderButton: '[EN] Treść pod przyciskiem',
                newsletterContent: '[EN] Treść w sekcji newslettera',
                systemFooter: '[EN] Stopka systemowa',
                colorButton: '[EN] Wybierz',
                newsletterHeader: 'Nagłówek',
                footer: '[EN] Stopka',
                leftContent: '[EN] Treść sekcji ze zdjęciem',
                contact: '[EN] Sekcja kontaktowa',
                socialMediaLink: '[EN] Podaj linki do social media',
                socialMedia: {
                    facebook: 'Facebook',
                    instagram: 'Instagram',
                    youtube: 'YouTube',
                    pinterest: 'Pinterest',
                    twitter: 'Twitter',
                    linkedIn: 'LinkedIn'
                },
                file: {
                    upload: '[EN] PRZEŚLIJ',
                    remove: '[EN] USUŃ',
                    edit: '[EN] EDYTUJ',
                },
                alertMaxCharExceeded: '[EN] Maksymalna ilośc znaków (300) w polu Dodatkowe informacje przekroczona.',
            }
        },

    },
};

export default menuSettings;