import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import SearchForm from '../generators/SearchForm';

const propTypes = {
    performAgreementSearch: PropTypes.func
};

const AgreementRegisterSearchForm = ({ performAgreementSearch, searchConfig }) => {
    const intl = useIntl();
    const translations = intl.messages.agreements.register.search;

    const onSubmit = formData => {
        performAgreementSearch(formData);
    };

    return (
        <SearchForm
            searchConfig={searchConfig}
            translations={{
                buttons: {
                    submit: translations.action,
                    reset: translations.reset,
                }
            }}
            inputs={[
                {
                    label: translations.labels.ip,
                    name: 'ip',
                    type: 'text',
                },
                {
                    label: translations.labels.email,
                    name: 'email',
                    type: 'text',
                },
                {
                    label: translations.labels.onlyModified,
                    name: 'onlyModified',
                    type: 'checkbox',
                },
            ]}
            performSearch={onSubmit}
        />
    );
};

AgreementRegisterSearchForm.propTypes = propTypes;

export default (AgreementRegisterSearchForm);