import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addDeleteAssignedManufacturers, getUnassignedManufacturersBySectionId} from '../../../../../../redux/methods/homePage';

import ManufacturersForHomePageTable from '../../../../../../components/tables/ManufacturersForHomePageTable';
import DefaultSpinner from '../../../../../../components/loaders/DefaultSpinner';
import Modal from '../../../../../../components/modals/Modal';

import useFetch from '../../../../../../hooks/useFetch';

const propTypes = {
    getUnassignedManufacturersBySectionId: PropTypes.func,
    forceRefetch: PropTypes.bool,
    addDeleteAssignedManufacturers: PropTypes.func,
};

const AssignManufacturersModal = ({ id, getUnassignedManufacturersBySectionId, forceRefetch, setIsOpen, isOpen, addDeleteAssignedManufacturers }) => {
    const [ unassignedManufacturers ] = useFetch(getUnassignedManufacturersBySectionId, id, [], forceRefetch);
    const translations = useIntl().messages.homePage.manufacturersSection.manufacturers.modal;

    const [ checkedProductsIds, setCheckedProductsIds] = useState([]);

    const assignSelected = () => {
        if (checkedProductsIds.length > 0) {
            addDeleteAssignedManufacturers({
                ManufacturerSectionId: id,
                ManufacturerIds: checkedProductsIds,
                Delete: false
            });

            setIsOpen(false);
        }
    };

    return (
        <Modal
            header={translations.header}
            onConfirm={assignSelected}
            className='modal-lg'
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            buttons={{
                cancel: {
                    label: translations.cancel,
                },
                confirm: {
                    label: translations.confirm,
                    disabled: checkedProductsIds.length === 0,
                    color: 'success',
                }
            }}
            body={
                unassignedManufacturers ? (
                    <ManufacturersForHomePageTable
                        checkedProductsIds={checkedProductsIds}
                        setCheckedProductsIds={setCheckedProductsIds}
                        manufacturersList={unassignedManufacturers}
                        isModal
                    />
                ) : (
                    <DefaultSpinner />
                )
            }
        />
    );
};

AssignManufacturersModal.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.homePageManufacturers,
});

const mapDispatch = {
    getUnassignedManufacturersBySectionId,
    addDeleteAssignedManufacturers,
};

export default connect(mapStateToProps, mapDispatch)(AssignManufacturersModal);