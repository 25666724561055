import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { ChromePicker } from 'react-color';
import { Button } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    color: PropTypes.string,
    isEdit: PropTypes.bool,
    onClose: PropTypes.func,
    onAdd: PropTypes.func
};

const ColorPicker = ({ color, isEdit, onClose, onAdd }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.colorPicker;

    const [ innerColor, setInnerColor ] = useState(color || '#FFFFFF');

    const handleOnChange = color => {
        setInnerColor(color.hex);
    };

    const handleOnAdd = () => {
        onAdd(innerColor);
        onClose();
    };

    return (
        <div className={styles.container}>
            <div className={styles.cover} onClick={onClose}/>
            <ChromePicker disableAlpha color={innerColor} onChange={handleOnChange}/>
            <span className={styles.controls}>
                <Button type="button" color="secondary" onClick={onClose}>
                    {translations.close}
                </Button>
                <span className={styles.preview} style={{ backgroundColor: innerColor }}/>
                <Button type="button" color="primary" onClick={handleOnAdd}>
                    {isEdit ? translations.change : translations.add}
                </Button>
            </span>
        </div>
    );
};

ColorPicker.propTypes = propTypes;

export default ColorPicker;