import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withFormUtils } from '../../../../hoc/withFormUtils';
import { addUpdatePageSection } from '../../../../redux/methods/homePage';

import { generateNonIdsOptionsForDropdown } from '../../../../utils/components';
import { mapSizesOptions } from '../../../../config';

import { Form as FinalForm } from 'react-final-form';
import { Row, Alert, Form } from 'reactstrap';

import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';
import MapLeaflet from '../../../../components/MapLeaflet';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import HomePageSectionMainData from '../HomePageSectionMainData';

import { formUtilsHocPropTypes } from '../../../../propTypes';

import styles from './index.module.scss';

import Tabulator from '../../../../components/Tabulator';

import { getHideCancelButton, getGoBack, onSubmit } from '..';

const propTypes = {
    utils: formUtilsHocPropTypes,
    section: PropTypes.object,
    addUpdatePageSection: PropTypes.func,
};

const MapSection = ({ utils, section, addUpdatePageSection, sectionLocation }) => {
    const intl = useIntl();
    const translations = intl.messages.homePage.mapSection;
    const translationsHomePage = intl.messages.homePage;

    const history = useHistory();

    const [ mapMarkers, setMapMarkers ] = useState([]);

    useEffect(() => {
        if(section && section.content) {
            setMapMarkers(section.content.markers);
        }
    }, [section]);

    const config = { translations: ['title'] };

    const mapSizesOptionsDropdown = generateNonIdsOptionsForDropdown(mapSizesOptions, translations.mapSizes);

    const handleOnSubmit = async formData => {
        const formToSend = utils.generateFormToSubmitValues(formData, config);

        const dataToSend = {
            ...formToSend,
            content: {
                ...formData.content,
                markers: mapMarkers
            }
        };

        const response = await addUpdatePageSection(dataToSend);

        return onSubmit(section, sectionLocation, history, response);
    };

    const generateInitialStructure = section => {
        const mapContentEmptyStrucutre = {
            markers: [],
            mapSize: 'Small',
        };

        return ({
            ...section,
            content: section.content
                ? section.content
                : mapContentEmptyStrucutre
        });
    };

    return (
        <React.Fragment>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={utils.generateFormInitialValues(generateInitialStructure(section), config)}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values }) => {
                    const currentMapSelectionLabel = mapSizesOptionsDropdown.find(size => size.id === values.content.mapSize) || translations.inputs.chooseMapSize;
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Tabulator
                                listOfElements={[
                                    {
                                        label: translationsHomePage.tabs.basicData,
                                        id: 'searchTerms-table',
                                        component: <HomePageSectionMainData
                                            isSectionHeightController={true}
                                            isNumberOfTilesController={false}
                                            form={form} />
                                    },
                                    {
                                        label: translationsHomePage.tabs.settings,
                                        id: 'lastPhrases-table',
                                        component:
                                            <React.Fragment>
                                                <Row className='d-flex flex-column px-3'>
                                                    <div className={styles.mapSizeInput}>
                                                        <FormFieldWithDropdown
                                                            name={'content.mapSize'}
                                                            currentSelectionLabel={currentMapSelectionLabel.label || translations.inputs.chooseMapSize}
                                                            options={mapSizesOptionsDropdown}
                                                            label={translations.inputs.mapSize}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="px-3">
                                                    <Alert
                                                        className='mt-3 w-100'
                                                        color='primary'>
                                                        <span>{translations.mapGuides}</span>
                                                    </Alert>
                                                    <MapLeaflet
                                                        utils={utils}
                                                        markers={mapMarkers}
                                                        setMarkers={setMapMarkers}
                                                    />
                                                </Row>
                                            </React.Fragment>
                                    },
                                ]}
                            />
                            <SectionEditFormButtons hideCancelButton={getHideCancelButton(sectionLocation, section)} goBack={getGoBack(sectionLocation, section, history)} />
                        </Form>
                    );
                }}
            />
        </React.Fragment>
    );
};

MapSection.propTypes = propTypes;

const mapDispatch = {
    addUpdatePageSection
};

export default withFormUtils(connect(null, mapDispatch)(MapSection));