import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Card, CardBody, CardHeader } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    tabulatorHeader: PropTypes.shape({
        label: PropTypes.string,
        iconClassName: PropTypes.string
    }).isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
            header:  PropTypes.shape({
                label: PropTypes.string,
                iconClassName: PropTypes.string
            }).isRequired,
            component: PropTypes.node.isRequired,
            disabled: PropTypes.bool,
            onClick: PropTypes.func,
        })
    ).isRequired,
    setSelectedTab: PropTypes.func,
    className: PropTypes.string,
};

const SettingsTabulatorNavDesktop = ({ tabulatorHeader, tabs, selectedTab, setSelectedTab, className }) => {
    const handleTabSelection = tab => () => {
        setSelectedTab(tab);
    };

    return (
        <Card className={className}>
            <CardHeader className="border-0 rounded">
                <span className={styles.tabHeader}>
                    <i className={clsx(tabulatorHeader.iconClassName)}/>
                </span>
            </CardHeader>
            <CardBody className="p-0">
                {
                    tabs.map(tab => {
                        const isSelected = tab.id === selectedTab.id;

                        return (
                            <li className={clsx(styles.tabListItem, styles.tabList)} key={tab.id} onClick={tab.onClick}>
                                <button
                                    disabled={tab.disabled}
                                    className={clsx(styles.tabButton, isSelected && styles.tabButtonSelected)}
                                    onClick={handleTabSelection(tab)}
                                >
                                    <i className={clsx(tab.header.iconClassName)}/>
                                    <span className={styles.tabButtonLabel}>{tab.header.label}</span>
                                </button>
                            </li>
                        );
                    })
                }
            </CardBody>
        </Card>
    );
};

SettingsTabulatorNavDesktop.propTypes = propTypes;

export default SettingsTabulatorNavDesktop;