const mediaSelectionModal = {
    info: {
        label: '[EN] Aby edytowa pliki przejdź do',
        link: '[EN] biblioteki mediów',
    },
    breadcumpsRoot: '[EN] ...',
    buttons: {
        confirm: '[EN] WYBIERZ',
        cancel: '[EN] ANULUJ',
    },
    header: '[EN] Wybieranie plików',
};

export default mediaSelectionModal;