const emails = {
	view: {
		title: 'Serwery pocztowe',
		actions: {
			addServer: 'Dodaj serwer pocztowy'
		},
		columnHeaders:{
			senderdisplayname: 'Nazwa nadawcy',
			senderemail: 'Email nadawcy',
			isdefault: 'Konto domyślne',
			host: 'Host',
			port: 'Port',
			useimap: 'Konto IMAP',
			imapport: 'Port IMAP',
			securesocketoption: 'Zabezpieczenia',
			actions: 'Akcje'
		},
		emptyTable: 'Brak zdefiniowanych serwerów pocztowych',
		tooltips: {
			edit: 'Edytuj',
			remove: 'Usuń'
		},
		deleteModal: {
			header: 'Email account:',
			body: 'Czy na pewno usunąć wskazany serwer pocztowy?',
			confirm: 'REMOVE',
			cancel: 'CANCEL'
		},
	},
	tableDefinitions: {
		viewHeader: 'Lista tabel przypisanych do szablonu e-mail',
		table:{
			headerLabels: {
				id: 'Id',
				name: 'Nazwa tabeli',
				actions: 'Akcje'
			},
			tooltips:{
				edit: 'Edytuj wygląd tabeli'
			}
		}
	},
    settings: {
        emailTemplatesHeader: '[EN] Lista szablonów e-mail',
        table: {
            headerLabels: {
                index: '[EN] Lp',
                name: '[EN] Nazwa',
				account: '[EN] Serwer pocztowy',
                edited: '[EN] Stan',
                actions: '[EN] Akcje'
            },
            nameFiller: '[EN] [BRAK NAZWY]',
			accountFiller: '[EN] [DOMYŚLNY]',
            emptyTable: '[EN] Brak szablonów do wyświetlenia',
            tooltips: {
                edit: '[EN] Edytuj'
            },
            isEdited: {
                edited: '[EN] Edytowany',
                unedited: '[EN] Nieedytowany'
            },
			tableDefinitionsActionTooltip: 'Przejdź do definicji wyglądu tabel'
        },
		sections: {
			general: 'Ustawienia podstawowe',
			additionalEmails: 'Dodatkowe adresy e-mail'
		},
        inputs: {
            sendToAdmin: '[EN] Wysyłaj do administratora',
            addButtonText: '[EN] Dodaj kolejny adres email',
            contnet: '[EN] Treść',
            title: '[EN] Temat'
        },
        actions: {
            save: '[EN] ZAPISZ ZMIANY',
            cancel: '[EN] ANULUJ'
        },
        editModal: {
            header: '[EN] Edycja szablonu email'
        },
        insertValueButton: '[EN] Wstaw wartość'
    },
    server: {
        title: '[EN] Ustawienia serwera SMTP',
		smtpHeader: 'Ustawienia SMTP',
		imapHeader: 'Ustawienia IMAP',
		loginHeader: 'Ustawienia logowania',
        testMessageSectionLabel: '[EN] Wiadomość testowa',
        wrongPasswordAlert: 'Passwords did not match.',
        labels: {
            sendersEmail: '[EN] Adres e-mail nadawcy',
            sendersNameToDisplay: '[EN] Wyświetlana nazwa nadawcy',
            SMTPServerAddress: '[EN] Adres serwera SMTP',
            SMTPPortNumber: '[EN] Numer portu SMTP',
            IMAPServer: '[EN] Serwer IMAP',
            IMAPPort: 'Port IMAP',
            useSSL: '[EN] Użyj bezpiecznego połączenia SSL',
            useTLS: '[EN] Użyj bezpiecznego połączenia TLS',
            userName: '[EN] Nazwa użytkownika',
            password: '[EN] Hasło',
            confirmPassword: '[EN] Potwierdź hasło',
            websiteAdress: '[EN] Adres strony (dla newslettera)',
            emailAddress: '[EN] Adres e-mail',
			isDefault: '[EN] Default account',
            imapSentFolderName: 'Folder IMAP dla wysłanych maili',
            secureSocketOptionLabel: 'Ustawienia bezpieczeństwa',
            sectureTokenOptions: {
                none: 'Brak',
                Auto: 'Automatyczne',
                sslOnConnect: 'SSL',
                startTls: 'TLS',
                startTlsWhenAvailable: 'TLS jeśli jest dostępne'
            },
			useAuthentication: 'Używaj autoryzacji'
        },
        actions: {
            sendTestEmail: 'Wyślij email testowy'
        }
    },
	emailTemplateTable: {
		viewTableHeader: 'Wygląd nagłówków tabeli',
		viewColumnsHeader: 'Kolumny tabeli',
		previewHeader: 'Podgląd wyglądu tabeli',
		tableHeaders:{
			name: 'Nazwa',
			font: 'Czcionka',
			fontColor: 'Kolor tekstu',
			fontSize: 'Rozmiar tekstu [px]',
			backgroundColor: 'Kolor tła',
			verticalAlignment: 'Wyr. pion',
			horizontalAlignment: 'Wyr. poz',
			isBold: 'Pogrubienie',
			isItalic: 'Kursywa',
			isUnderline: 'Podkreślenie'
		},
		tableColumnHeaders: {
			id: 'Id',
			name: 'Nazwa',
			font: 'Czcionka',
			fontColor: 'Kolor tekstu',
			fontSize: 'Rozmiar tekstu [px]',
			backgroundColor: 'Kolor tła',
			verticalAlignment: 'Wyr. pion',
			horizontalAlignment: 'Wyr. poz',
			isBold: 'Pogrubienie',
			isItalic: 'Kursywa',
			isUnderline: 'Podkreślenie',
			visible: 'Widoczna'
		}
	}
};

export default emails;