const products = {
    view: {
        title: 'Attributes list',
        summaryProductModalLabel: 'Tworzenie artykułu zbiorczego',
        paginator: {
            previous: 'Previous',
            next: 'Next'
        },
        actions: {
            addProduct: 'ADD PRODUCT',
            defineFillers: 'Aktywuj reklamy uzupełniające',
        },
        modal: {
            body: '[EN] Wybierz kategorię dla której ma zostać utworzony produkt',
        },
        categoryTree: {
            categoryTreeLabels: {
                erpCategories: '[EN] Kategorie ERP',
                shopCategories: '[EN] Kategorie Sklepowe'
            },
            generalCategoryLabel: '[EN] Nazwa kategorii ogólnej',
            dropdownSelector: {
                label: '[EN] Widok kategorii',
                options: {
                    all: '[EN] Wszystkie',
                    active: '[EN] Aktywne'
                }
            },
        },
    },
    searchForm: {
        productName: {
            label: 'Product name:',
            placeholder: ''
        },
        catalogueIndex: {
            label: 'Catalogue index:',
            placeholder: ''
        },
        tradeIndex: {
            label: 'Trade index:',
            placeholder: ''
        },
        ean: {
            label: '[EN] Kod kreskowy:',
            placeholder: ''
        },
        status: {
            label: '[EN] Aktywny',
        },
        warning: {
            label: '[EN] Zawierające uwagi'
        },
        promotion: {
            label: '[EN] Będące w promocji'
        },
        action: 'SEARCH',
        reset: 'RESET',
        defaultSearchLabel: '[EN] Wszystkie',
        productType: '[EN] Typ produktu',
        inSubcategories: '[EN] Szukaj w podkategoriach',
        types: {
            defaultType: '[EN] Wszystkie',
            None: '[EN] Zwykłe',
            Set: '[EN] Produkty zbiorcze',
            Variant: '[EN] Warianty'
        }
    },
    edit: {
        tabSelectorTitle: 'Sections',
        tabs: {
            basic: {
                statusLabels: {
                    unpublished: '[EN] Nieaktywny',
                    published: '[EN] Aktywny',
                },
                title: 'Basic data',
                modalHeader: 'Product name',
                anyLabel: 'Any',
                buttons: {
                    defineFillers: 'Aktywuj reklamy uzupełniające',
                    disconnect: 'Odłącz'
                },
                types: {
                    Set: 'Zbiorczy',
                    Variant: 'Wariant',
                    None: 'Zwykły'
                },
                inputs: {
                    status: '[EN] Status',
                    statusErp: '[EN] Status Erp',
                    productName: 'Product name',
                    nameCD: '[EN] Nazwa CD',
                    nameFromERP: '[EN] Nazwa z Erp',
                    syncErp: '[EN] Synchronizuj nazwy z Erp',
                    isMasked: '[EN] Wymuś stan maskowany',
                    booked: '[EN] Ilość zarezerowana',
                    quantity: '[EN] Ilość na stanie',
                    minQuantity: 'Minimum quantity',
                    maskedQuantity: 'Masked quantity',
                    catalogueIndex: 'Catalogue index',
                    tradeIndex: 'Trade index',
                    mainCategory: 'Main category *',
                    manufacturer: 'Manufacturer *',
                    pkwiu: 'PKWiU Code',
                    swwku: 'SWW/KU Code',
                    localisation: 'Localisation',
                    distinguish: 'Distinguishing feature',
                    cnCode: 'CN Code',
                    weight: 'Weight',
                    ean: 'EAN Code',
                    kgo: 'KGO rate',
                    measure: 'Measure type *',
                    mainMeasureUnit: 'Main measure unit',
                    country: '[EN] Kraj pochodzenia',
                    select: '[EN] Wybierz',
                    manufacturerIndex: '[EN] Indeks producenta',
                    originalName: '[EN] Nazwa oryginalna',
                    includeMinQuantity: '[EN] Wymuś ilość minimalną',
                    externalShortDescriptionSync: '[EN] Zewnętrzna synchronizacja krótkiego opisu',
                    externalShortDescription: '[EN] Zewnętrzny krótki opis',
                    externalLongDescriptionSync: '[EN] Zewnętrzna synchronizacja długiego opisu',
                    externalLongDescription: '[EN] Zewnętrzny długi opis',
                    includeReservations: '[EN] Odlicz rezerwacje',
                    isAddToCartHidden: '[EN] Zablokuj dodawanie artykułu do koszyka',
                    externalId: '[EN] Identyfikator zewnętrzny',
                    height: '[EN] Wysokość',
                    width: '[EN] Szerokość',
                    depth: '[EN] Głębokość',
                    sizeUnit: '[EN] Jednostka wielkości'
                },
                explanations: {
                    isMaskedTrue: '* artykuł będzie posiadał stałą ilość maskowaną',
                    isMaskedFalse: '* artykuł nie będzie posiadał stałej ilości maskowanej',
                    isMaskedNull: '* o tym czy artykuł będzie posiadał stałą ilość maskowaną zdecydują ustawienia globalne (sekcja Ustawienia -> Artykuł -> Stan artykułu)',

                    includeMinQuantityTrue: '* artykuł będzie posiadał stałą ilość minimalną',
                    includeMinQuantityFalse: '* artykuł nie będzie posiadał stałej ilości minimalnej',
                    includeMinQuantityNull: '* o tym czy artykuł będzie posiadał stałą ilość minimalną zdecydują ustawienia globalne (sekcja Ustawienia -> Artykuł -> Stan artykułu)',

                    includeReservationsTrue: '* od stanu artykułu będą odliczane rezerwacje',
                    includeReservationsFalse: '* od stanu artykułu nie będą odliczane rezerwacje',
                    includeReservationsNull: '* o tym czy od stanu artykułu będą odliczane rezerwacje zdecydują ustawienia globalne (sekcja Ustawienia -> Artykuł -> Stan artykułu)',

                    leaveNull: '* Pozostawienie powyższego pola pustego będzie powodować pobieranie wartości z ustawień globalnych (sekcja Ustawienia -> Artykuł -> Stan artykułu)'
                },
                isPromotionInfo: '[EN] Artykuł jest w promocji',
                dateCreated: '[EN] Data utworzenia',
                type: 'Typ',
                parentProduct: 'Artykuł nadrzędny',
                deleteOneModal: {
                    header: 'Odłączanie artykułu',
                    body: 'Czy jestes pewien, że chcesz odłączyć artykuł?'
                }
            },
            description: {
                title: 'Description',
                headers: {
                    short: 'Short description',
                    long: 'Long description',
                },
                buttons: {
                    save: 'SAVE CHANGES',
                    cancel: 'DISCARD CHANGES',
                }
            },
            photos: {
                title: 'Photos',
                addPhotoButton: 'ADD PHOTO',
                deletePhotoButton: 'DELETE SELECTED PHOTOS',
                cancel: 'DISCARD CHANGES',
                save: 'SAVE CHANGES',
                isDefaultText: 'Yes',
                isExternalTranslations: {
                    isExternal: 'Yes',
                    isNotExternal: 'No'
                },
                emptyAlternativeText: '[NO TRANSLATION]',
                emptyAlternativeName: '[NO NAME]',
                emptyTranslation: 'No photos to display',
                imageAlignmentTranslations: {
                    horizontal: 'Horizontal',
                    vertical: 'Vertical',
                },
                alignmentText: {
                    default: 'Default',
                    modified: 'Modified',
                },
                columnNames: {
                    active: 'Active',
                    order: 'Order',
                    photo: 'Photo',
                    name: 'Name',
                    alternativeText: 'Alternative text',
                    isDefault: 'Default',
                    isDefaultDouble: '[EN] Domyślne podwójny kafelek',
                    isExternal: '[EN] Pochodzi z ERP',
                    alignment: 'Alignment',
                    actions: 'Actions',
                },
                deleteModalTranslations: {
                    header: 'Removing photos',
                    body: 'Are you sure you want to remove checked photos?'
                },
                deleteSingleModalTranslations: {
                    header: 'Removing photo',
                    body: 'Are you sure you want to remove photo?',
                    confirm: 'REMOVE',
                    cancel: 'CANCEL',
                },
                tooltipTranslations: {
                    remove: 'Remove photo',
                    edit: 'Edit photo',
                    showDetails: 'Show zoom',
                },
                editModal: {
                    buttons: {
                        confirm: 'SAVE',
                        cancel: 'CANCEL'
                    },
                    checkbox: 'Default product photo',
                    header: 'Editing photo',
                    inputWithFlagsLabel: 'Alternative text',
                    inputNameLabel: 'Name',
                    horizontalAlignmentLabel: 'Horizontal alignment',
                    verticalAlignmentLabel: 'Vertical alignment',
                    tabTileName: 'Tile (preview)',
                    tabBannerName: 'Dobule tile (preview)',
                    dropdown: {
                        center: 'Center',
                        bottom: 'Bottom',
                        top: 'Top',
                        left: 'Left',
                        right: 'Right',
                    }
                }
            },
            metaTags: {
                title: 'Meta Tags'
            },
            featuresAndAttributes: {
                title: 'Features / Attributes',
                valuesTable: {
                    headers: {
                        name: 'Name',
                        value: 'Value',
                        actions: 'Actions'
                    }
                },
                editAttributes: 'Edit product attributes',
                editFeatures: 'Edit product features',
                labels: {
                    type: 'Attribute type'
                },
                placeholers: {
                    type: 'Default',
                    selectAttributes: 'Select attributes',
                    selectFeatures: 'Select features',
                    empty: 'None',
                    selected: '[EN] Wybrano:'
                },
                tooltips: {
                    edit: 'Edit value',
                    add: 'Assign value',
                    remove: 'Unassign value'
                },
                attributeType: {
                    placeholder: 'Default',
                    tag: 'Tag',
                    dictionary: 'Dictionary',
                    int: 'Number range',
                    decimal: 'Decimal range',
                    dateTime: 'Date range',
                    color: 'Color'
                },
            },
            prices: {
                title: 'Prices',
                basePrice: 'Default price',
                promoPrice: '[EN] Cena promocyjna (zmiana trybu zeruje formularz ceny promocyjnej)',
                showAllCurrencies: 'Pokaż wszystkie waluty',
                showOnlyActiveCurrencies: 'Pokaż tylko aktywne waluty',
                inputs: {
                    netPrice: 'Net price',
                    grossPrice: 'Gross price',
                    tax: 'Tax rate',
                    discountRate: 'Discount rate',
                    exchangeRate: 'Exchange rate',
                    dateFrom: 'Valid from',
                    dateTo: 'Valid to',
                    afterPromo: '[EN] Cena brutto po promocji',
                    isPriceHidden: 'Ukryj cenę produktu',
                },
                checkboxes: {
                    undefined: 'Undefined',
                    precentageDiscount: 'Percentage discount over the default price',
                    definedPrice: 'Directly defined amount',
                    exchangeRateTable: 'Price converted according to the table of rates',
                    constantRate: 'Price convertes at fixed rate'
                }
            },
            measures: {
                title: 'Units and measures',
                emptyTable: 'No units',
                nullOpt: 'None',
                headers: {
                    name: {
                        label: 'Name',
                        filler: '[NO UNIT]'
                    },
                    measureType: {
                        label: 'Type',
                        filler: '[NO TYPE]'
                    },
                    rate: {
                        label: 'Calculator',
                        filler: '[NO INVERTER]'
                    }
                },
                measureTypes: {
                    storage: 'Warehousing',
                    sales: 'Sales',
                    purchases: 'Purchase',
                    reference: 'Reference',
                    additional: 'Additional'
                },
                tooltips: {
                    edit: 'Edit article unit of measure',
                    remove: 'Remove article measurement unit'
                },
                buttons: {
                    create: 'Add'
                },
                editModal: {
                    title: 'Editing the unit of measure of the article',
                    buttons: {
                        save: 'SAVE',
                        cancel: 'BACK'
                    },
                    inputs: {
                        measure: 'Measure unit',
                        rate: 'Calculator',
                        measureType: 'Type'
                    }
                },
                deleteModal: {
                    header: 'Delete item measurement unit',
                    body: 'Are you sure you want to delete this article measurement unit?',
                    confirm: 'Delete',
                    cancel: 'Return'
                }
            },
            additionalCategories: {
                title: 'Additional categories',
                emptyTranslation: 'No additional categories to display',
                columnNames: {
                    categories: 'Category name',
                    actions: 'Actions',
                },
                buttons: {
                    delete: 'UNASSIGN SELECTED CATEGORIES',
                    add: 'ASSIGN CATEGORY',
                    cancel: 'DISCARD CHANGES',
                },
                tooltipTranslation: 'Unassign category',
                deleteModalTranslations: {
                    header: 'Unassign categories',
                    body: 'Are you sure you want to unassign selected categories?',
                },
                deleteSingleModalTranslations: {
                    header: 'Unassign category',
                    bodyStart: 'Are you sure want to unassign category',
                    bodyEnd: 'from list of additional categories assigned to this product',
                    confirm: 'UNASSIGN',
                    cancel: 'CANCEL',
                },
                categorySelectionModal: {
                    body: 'Select category to assign to this product',
                }
            },
            alternativeProducts: {
                title: '[EN] Zamienniki',
                labels: {
                    isTwoWay: 'Dwustronne przypisane? [ do zmiany ] '
                },
                tooltips: {
                    remove: 'Odłącz produkt',
                    edit: 'Edytuj',
                    isTwoWay: 'Jakieś tłumacznie',
                    showDetails: 'Pokaż w sklepie',
                },
                empty: '[EN] Brak produktów do wyświetlenia',
                headers: {
                    photo: '[EN] Zdjęcie',
                    name: '[EN] Nazwa artykułu',
                    catalogueIndex: '[EN] Indeks katalogowy',
                    tradeIndex: '[EN] Indeks handlowy',
                    actions: '[EN] Akcje',
                },
                deleteModal: {
                    header: '[EN] Odłączanie zamiennika',
                    body: '[EN] Czy jestes pewien, że chcesz odłączyć zamiennik?',
                    confirm: '[EN] ODŁĄCZ',
                    cancel: '[EN] ANULUJ'
                },
                deleteManyModal: {
                    header: '[EN] Odłączanie zamienników',
                    body: '[EN] Czy jestes pewien, że chcesz odłączyć wybrane zamienniki?'
                },
                buttons: {
                    delete: '[EN] ODŁĄCZ ZAZNACZONE ARTYKUŁY',
                    add: '[EN] PRZYPISZ ARTYKUŁY',
                },
                fallbacks: {
                    name: '[EN] [BRAK NAZWY]',
                    catalogueIndex: '[EN] [BRAK INDEKSU KATALOGOWEGO]',
                    tradeIndex: '[EN] [BRAK INDEKSU HANDLOWEGO]',
                },
            },
            relatedProducts: {
                title: '[EN] Artykuł powiązane',
                tooltips: {
                    remove: 'Odłącz produkt',
                    edit: 'Edytuj',
                },
                empty: '[EN] Brak produktów do wyświetlenia',
                headers: {
                    photo: '[EN] Zdjęcie',
                    name: '[EN] Nazwa artykułu',
                    catalogueIndex: '[EN] Indeks katalogowy',
                    tradeIndex: '[EN] Indeks handlowy',
                    actions: '[EN] Akcje',
                },
                deleteModal: {
                    header: '[EN] Odłączanie artykułu powiązanego',
                    body: '[EN] Czy jestes pewien, że chcesz odłączyć artykuł powiązany?',
                    confirm: '[EN] ODŁĄCZ',
                    cancel: '[EN] ANULUJ'
                },
                deleteManyModal: {
                    header: '[EN] Odłączanie artykułów powiązanych',
                    body: '[EN] Czy jestes pewien, że chcesz odłączyć wybrane artykuł powiązane?'
                },
                buttons: {
                    delete: '[EN] ODŁĄCZ ZAZNACZONE ARTYKUŁY',
                    add: '[EN] PRZYPISZ ARTYKUŁY',
                },
                fallbacks: {
                    name: '[EN] [BRAK NAZWY]',
                    catalogueIndex: '[EN] [BRAK INDEKSU KATALOGOWEGO]',
                    tradeIndex: '[EN] [BRAK INDEKSU HANDLOWEGO]',
                },
            },
            opinions: {
                title: '[EN] Opinie',
                removeTooltip: '[EN] Usuń opinie',
                emptyTable: '[EN] Brak opinii',
                deleteModal: {
                    header: '[EN] Usuwanie opinii',
                    body: '[EN] Czy na pewno chcesz usunąć opinię?',
                    confirm: '[EN] USUŃ',
                    cancel: '[EN] ANULUJ'
                },
                statuses: {
                    accepted: '[EN] Zaakceptowana',
                    deleted: '[EN] Usunięta',
                    notChecked: '[EN] Niesprawdzona',
                },
                inputs: {
                    name: {
                        label: '[EN] Nazwa',
                        filler: '[EN] [BRAK NAZWY]',
                    },
                    date: {
                        label: '[EN] Data wystawienia',
                        filler: '[EN] [BRAK DATY]',
                    },
                    content: {
                        label: '[EN] Treść',
                        filler: '[EN] [BRAK TREŚCI]',
                    },
                    isContentVisible: {
                        label: '[EN] Treść widoczna',
                    },
                    rating: {
                        label: '[EN] Ocena'
                    },
                    status: {
                        label: '[EN] Status'
                    },
                    actions: {
                        label: '[EN] Akcje'
                    }
                }
            },
            delivery: {
                title: '[EN] Dostawa',
                fields: {
                    deliveryCost: '[EN] Koszt dostawy',
                    deliveryTime: '[EN] Czas dostawy w dniach (0-365)'
                },
                fromErp: '[EN] Pole zmapowane z Erp',
            },
        },
        variants: {
            title: '[EN] Warianty',
            selectAttribute: '[EN] Wybierz atrybuty',
            selectedAttribute: '[EN] Wybrano: ',
            varianceAttributes: '[EN] Atrybuty wariantujące',
            tooltips: {
                remove: '[EN] Odłącz wariant',
                edit: '[EN] Edytuj'
            },
            chooseProductsGuide: 'Wybierz warianty oraz produkt wzorcowy.',
            noAttributes: '[EN] Brak atrybutów',
            buttons: {
                create: 'UTWÓRZ',
                next: 'DALEJ',
                prev: 'WRÓĆ',
                delete: '[EN] ODŁĄCZ ZAZNACZONE ARTYKUŁY',
                add: '[EN] PRZYPISZ ARTYKUŁY',
            },
            noAttributesAlert: '[EN] Nie znaleziono przypisanych atrybutów/cech dla danej kategorii. Przypisz atrybuty/cechy dla wybranej kategorii, aby kontynuwać tworzenie artykułu zbiorczego.',
            empty: '[EN] Brak produktów do wyświetlenia',
            summaryProductSteps: {
                chooseCategory: '[EN] Wybierz kategorię, aby wyszukać przypisane do niej atrybuty wariantujace.',
                chooseAttributes: '[EN] Wybierz atrybuty wariantujace.',
                chooseProducts: '[EN] Wybierz warianty produktu głownego',
                searchInAllCategories: 'Wyszukuj we wszystkich kategoriach'
            },
            headers: {
                photo: '[EN] Zdjęcie',
                name: '[EN] Nazwa artykułu',
                catalogueIndex: '[EN] Indeks katalogowy',
                tradeIndex: '[EN] Indeks handlowy',
                actions: '[EN] Akcje',
            },
            deleteModal: {
                header: '[EN] Odłączanie artykułu wariantującego',
                body: '[EN] Czy jestes pewien, że chcesz odłączyć artykuł wariantujący?',
                confirm: '[EN] ODŁĄCZ',
                cancel: '[EN] ANULUJ'
            },
            deleteManyModal: {
                header: '[EN] Odłączanie artykułów wariantujących',
                body: '[EN] Czy jestes pewien, że chcesz odłączyć wybrane artykuł wariantujący?'
            },
            fallbacks: {
                name: '[EN] [BRAK NAZWY]',
                catalogueIndex: '[EN] [BRAK INDEKSU KATALOGOWEGO]',
                tradeIndex: '[EN] [BRAK INDEKSU HANDLOWEGO]',
            },
        },
    },
    table: {
        buttons: {
            activate: '[EN] Aktywuj',
            deactivate: '[EN] Dezaktywuj',
            distinguish: '[EN] Wyróżnij',
            undistinguish: '[EN] Wyłącz wyróżenie',
        },
        headers: {
            photo: 'Photo',
            name: 'Product name',
            catalogueIndex: 'Catalogue index',
            tradeIndex: 'Trade index',
            actions: 'Actions',
            category: '[EN] Kategoria dla cech',
            type: 'Typ',
            templateVariant: 'Artykuł wzorcowy'
        },
        types: {
            set: 'Zbiorczy',
            variant: 'Wariant',
            basic: 'Zwykły'
        },
        deleteModal: {
            header: 'Product removing',
            body: 'Are you sure you want to remove product',
            confirm: 'REMOVE',
            cancel: 'CANCEL'
        },
        emptyLogoAlt: 'replacement logo',
        emptyTable: 'No products matching provided search criteria have been found.',
        fallbacks: {
            name: '[EMPTY NAME]',
            catalogueIndex: '[EMPTY CATALOGUE INDEX]',
            tradeIndex: '[EMPTY TRADE INDEX]',
        },
    },
    modalSearch: {
        header: 'Articles selection',
        buttons: {
            confirm: 'ADD CHOSEN ARTICLES',
            cancel: 'CANCEL',
        }
    },
    settings: {
        tabSelectorTitle: '[EN] Strona Główna > Artykuły',
        tabs : {
            tab: '[EN] Zakładka'
        },
        inputs: {
            tags: '[EN] Wybierz artykuły według tagów',
            auto: '[EN] Automatycznie',
            manual: '[EN] Ręcznie',
            name: '[EN] Nazwa',
            enabled: '[EN] Aktywna',
            tagFallback: '[EN] Wybierz tag',
            showSelected: '[EN] Pokaż zaznaczone'
        },
        actions: {
            save: '[EN] ZAPISZ ZMIANY'
        }
    },
    categoryAssign: {
        title: '[EN] Przypisywanie do kategorii',
        showSelected: '[EN] Pokaż zaznaczone',
        categoryTreeLabels: {
            erpCategories: 'Kategorie ERP',
            shopCategories: 'Kategorie Sklepowe'
        },
        generalCategoryLabel: '[PL] Nazwa kategorii ogólnej',
        dropdownSelector: {
            label: 'Widok kategorii',
            options: {
                all: 'Wszystkie',
                active: 'Aktywne'
            }
        },
        buttons: {
            add: 'PRZYPISZ KATEGORIE DLA WYBRANYCH PRODUKTÓW',
            remove: 'ODŁĄCZ PRODUKTY Z KATEGORII',
        },
        addModal: 'Wybierz katogorię do której mają zostać przypisane produkty',
        setModal: {
            header: 'Zmiana kategorii dla cech',
            body: 'Czy chcesz zmienić kategorię dla cech dla wskazanych artykułów?'
        },
        removeModal: {
            header: 'Odłączanie produktów z kategorii',
            body: 'Czy na pewno chcesz odłączyć produkty z wybranej kategorii?',
        },
        categoriesSection: {
            title: 'Przypisane kategorie',
            categoryForFeatures: 'Kategoria dla cech',
            empty: 'Brak przypisanych kategorii',
            changeModal: {
                body: 'Czy jesteś pewien że chcesz zmienić kategorię dla cech w wybranym produkcie?',
                header: 'Zmiana kategorii dla cech',
                cancel: 'ANULUJ',
                confirm: 'ZMIEŃ'
            }
        }
    },

};

export default products;