import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { Row, Button } from 'reactstrap';

const propTypes = {
    onBack: PropTypes.func,
    onSave: PropTypes.func,
    isShowingOnlyIcons: PropTypes.bool,
    isBackButtonVisible: PropTypes.bool,
    isBackButtonDisabled: PropTypes.bool,
    isSaveButtonVisible: PropTypes.bool,
    isSaveButtonDisabled: PropTypes.bool,
};

/**
 * This component is designed to be a part of form, therefore to abide to DRY, it's connected to intl
 */
const SaveAndBackButtonsSection = ({ onBack, onSave, isShowingOnlyIcons = false, isBackButtonVisible = true, isBackButtonDisabled = false, isSaveButtonVisible = true, isSaveButtonDisabled = false }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.saveAndBackButtonsSection;

    const handleOnBack = () => {
        onBack();
    };

    const handleOnSave = () => {
        onSave();
    };

    return (
        <Row className="d-flex flex-row justify-content-between m-2 mt-2">
            {
                isBackButtonVisible &&
                    <Button type="button" onClick={handleOnBack} color="danger" disabled={isBackButtonDisabled}>
                        <i className={clsx("fa fa-remove", !isShowingOnlyIcons && "mr-2")}/>
                        {
                            !isShowingOnlyIcons && <span>{translations.back}</span>
                        }
                    </Button>
            }
            {
                isSaveButtonVisible &&
                    <Button type="button" onClick={handleOnSave} color="success" disabled={isSaveButtonDisabled}>
                        <i className={clsx("fa fa-save", !isShowingOnlyIcons && "mr-2")}/>
                        {
                            !isShowingOnlyIcons && <span>{translations.save}</span>
                        }
                    </Button>
            }
        </Row>
    );
};

SaveAndBackButtonsSection.propTypes = propTypes;

export default withRouter(SaveAndBackButtonsSection);