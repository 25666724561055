import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { Col, Row } from 'reactstrap';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import FormFieldWithCalendarAndTimePicker from '../../../../components/formFields/FormFieldWithCalendarAndTimePicker';
import FormFieldActiveInLanguages from '../../../../components/formFields/FormFieldActiveInLanguages';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldSwitchPill from '../../../../components/formFields/FormFieldSwitchPill';
import FormFieldWithRange from '../../../../components/formFields/FormFieldWithRange';
import FormFieldTranslationQuill from '../../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldColorPicker from '../../../../components/formFields/FormFieldColorPicker';
import FormFieldWithCheckbox from '../../../../components/formFields/FormFieldWithCheckbox';

import styles from './index.module.scss';

const propTypes = {
    props: PropTypes.object,
    isSectionHeightController: PropTypes.bool,
    isNumberOfTilesController: PropTypes.bool,
};

const HomePageSectionMainData = ({
    utils,
    form,
    isSectionHeightController = true,
    isNumberOfTilesController = false,
    isSectionTitleController = true,
}) => {
    const languages = utils.allLanguages();
    const translations = useIntl().messages.homePage.mainFormData;

    return (
        <React.Fragment>
            <Row className='mt-3'>
                <Col>
                    <FormFieldTextOrNumber required label={translations.name} name='name' />
                </Col>
                <Col>
                    <FormFieldTextOrNumber label={translations.type} disabled name='type' />
                </Col>
            </Row>

            <Row className='mt-3'>
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="card mb-0">
                        <div className="card-header">{translations.desktopSettings}</div>
                        <div className="card-body">
                            <span className='d-flex align-items-center mr-3'>
                                <FormFieldSwitchPill label={translations.desktopActive} name='desktopActive' />
                            </span>

                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldTextOrNumber className='my-2' label={translations.marginTop} name='desktopSettings.marginTop' type='number' min={0} defaultValue={0} symbol={translations.pxLabel} />
                            </div>
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldTextOrNumber className='my-2' label={translations.marginBottom} name='desktopSettings.marginBottom' type='number' min={0} defaultValue={0} symbol={translations.pxLabel} />
                            </div>

                            {isSectionHeightController &&
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldTextOrNumber className='my-2' label={translations.desktopHeight} name='desktopSettings.height' type='number' min={1} defaultValue={500} symbol={translations.pxLabel} />
                            </div>}

                            {isNumberOfTilesController &&
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldWithRange label={translations.numberOfTiles} name='numberOfTiles.desktop' type='number' min={5} max={6} step={0.5} initialValue={6} />
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="card mb-0">
                        <div className="card-header">{translations.tabletSettings}</div>
                        <div className="card-body">
                            <span className='d-flex align-items-center mr-3'>
                                <FormFieldSwitchPill label={translations.tabletActive} name='tabletActive' />
                            </span>

                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldTextOrNumber className='my-2' label={translations.marginTop} name='tabletSettings.marginTop' type='number' min={0} defaultValue={0} symbol={translations.pxLabel} />
                            </div>
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldTextOrNumber className='my-2' label={translations.marginBottom} name='tabletSettings.marginBottom' type='number' min={0} defaultValue={0} symbol={translations.pxLabel} />
                            </div>
                            {isSectionHeightController &&
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldTextOrNumber className='my-2' label={translations.tabletHeight} name='tabletSettings.height' type='number' min={1} defaultValue={300} symbol={translations.pxLabel} />
                            </div>}

                            {isNumberOfTilesController &&
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldWithRange label={translations.numberOfTiles} name='numberOfTiles.tablet' type='number' min={0.5} max={3} step={0.5} initialValue={3} />
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="card mb-0">
                        <div className="card-header">{translations.mobileSettings}</div>
                        <div className="card-body">
                            <span className='d-flex align-items-center mr-3'>
                                <FormFieldSwitchPill  label={translations.mobileActive} name='mobileActive' />
                            </span>

                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldTextOrNumber className='my-2' label={translations.marginTop} name='mobileSettings.marginTop' type='number' min={0} defaultValue={0} symbol={translations.pxLabel} />
                            </div>
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldTextOrNumber className='my-2' label={translations.marginBottom} name='mobileSettings.marginBottom' type='number' min={0} defaultValue={0} symbol={translations.pxLabel} />
                            </div>
                            {isSectionHeightController &&
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldTextOrNumber className='my-2' label={translations.mobileHeight} name='mobileSettings.height' type='number' min={1} defaultValue={220} symbol={translations.pxLabel} />
                            </div>}

                            {isNumberOfTilesController &&
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldWithRange label={translations.numberOfTiles} name='numberOfTiles.mobile' type='number' min={0.5} max={1} step={0.5} initialValue={1} />
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="card mb-0">
                        <div className="card-header">{translations.globalSettings}</div>
                        <div className="card-body">
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldColorPicker
                                    button={translations.backgroundColorButton}
                                    label={translations.backgroundColor}
                                    name='globalSettings.backgroundColor'
                                />
                            </div>
                            <div className={clsx('mt-2 d-flex flex-column', styles.textFieldContainer)}>
                                <FormFieldWithCheckbox
                                    name='globalSettings.fullWidth'
                                    label={translations.fullWidth}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Row>

            <Row className='mt-3'>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card mb-0">
                        <div className="card-header">{translations.activeInLanguages}</div>
                        <div className="card-body">
                            <FormFieldActiveInLanguages name='activeInLanguages' languages={languages} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card mb-0">
                        <div className="card-header">Aktywność w czasie</div>
                        <div className="card-body">
                            <FormFieldWithCalendarAndTimePicker label={translations.dateFrom} name='activeFrom' />
                            <FormFieldWithCalendarAndTimePicker label={translations.dateTo} name='activeTo'  />
                        </div>
                    </div>
                </div>
            </Row>

            {isSectionTitleController &&
                <Row className='mt-3'>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card mb-0">
                            <div className="card-header">{translations.sectionTitle}</div>
                            <div className="card-body">
                                <div className='d-flex align-items-center'>
                                    <FormFieldSwitchPill label={translations.enableTitle} name='enableTitle' />
                                </div>
                                <FormFieldTranslationQuill
                                    className="mt-5"
                                    form={form}
                                    utils={utils}
                                    propertyName={'title'}
                                />
                            </div>
                        </div>
                    </div>
                </Row>
            }

        </React.Fragment>
    );
};

HomePageSectionMainData.propTypes = propTypes;

export default withFormUtils(HomePageSectionMainData);