import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getIntegrationInPost, updateIntegrationInPost } from '../../../redux/methods/integrations';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';

import { Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';

const propTypes = {
    getIntegrationInPost: PropTypes.func,
    updateIntegrationInPost: PropTypes.func,
};

const InPost = ({ getIntegrationInPost, updateIntegrationInPost }) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.inpost;

    const [ data ] = useFetch(getIntegrationInPost);

    return data ? (
        <ContainerWithCard title={translations.title} iconclassName='fa fa-cogs'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateIntegrationInPost}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>

								<div className='my-2'><FormFieldSwitchPill label={translations.labels.enabled} name='enabled' /></div>

								<div className='row'>
									<div className='col-12 col-xl-6'>
										<div className='card'>
											<div className='card-header'>{translations.headers.urls}</div>
											<div className='card-body'>
												<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.apiUrl} name='apiUrl' /></div>
												<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.acceptLanguage} name='acceptLanguage' /></div>
												<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.organizationId} name='organizationId' /></div>
												<div className='mt-3'><FormFieldTextOrNumber type='password' label={translations.labels.token} name='token' /></div>
											</div>
										</div>
									</div>
									<div className='col-12 col-xl-6'>
										<div className='card'>
											<div className='card-header'>{translations.headers.sender}</div>
											<div className='card-body'>
												<div className='row'>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.defaultSenderFirstName} name='defaultSenderFirstName' /></div>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.defaultSenderLastName} name='defaultSenderLastName' /></div>
												</div>
												<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.defaultSenderCompanyName} name='defaultSenderCompanyName' /></div>
												<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.defaultSenderAddress} name='defaultSenderAddress' /></div>
												<div className='row'>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.defaultSenderEmail} name='defaultSenderEmail' /></div>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.defaultSenderPhone} name='defaultSenderPhone' /></div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='card'>
									<div className='card-header'>{translations.headers.customAttributes}</div>
									<div className='card-body'>
										<div className='row'>
											<div className='mt-3 col-4'><FormFieldTextOrNumber label={translations.labels.defaultCustomAttributesDropoffPoint} name='defaultCustomAttributesDropoffPoint' /></div>
											<div className='mt-3 col-4'><FormFieldWithDropdown label={translations.labels.defaultServiceType} name='defaultServiceType' options={data.serviceTypeOptions} /></div>
											<div className='mt-3 col-4'><FormFieldWithDropdown label={translations.labels.defaultCustomAttributesSendingMethod} name='defaultCustomAttributesSendingMethod' options={data.sendingMethodOptions} /></div>
										</div>
									</div>
								</div>

								<div className='card'>
									<div className='card-header'>{translations.headers.shipment}</div>
									<div className='card-body'>
										<div className='row'>
											<div className='mt-3 col-3'><FormFieldTextOrNumber type="number" inputClassName="form-control text-right" label={translations.labels.defaultShipmentLength} name='defaultShipmentLength' /></div>
											<div className='mt-3 col-3'><FormFieldTextOrNumber type="number" inputClassName="form-control text-right" label={translations.labels.defaultShipmentHeight} name='defaultShipmentHeight' /></div>
											<div className='mt-3 col-3'><FormFieldTextOrNumber type="number" inputClassName="form-control text-right" label={translations.labels.defaultShipmentWidth} name='defaultShipmentWidth' /></div>
											<div className='mt-3 col-3'><FormFieldTextOrNumber type="number" inputClassName="form-control text-right" label={translations.labels.defaultShipmentWeightAmount} name='defaultShipmentWeightAmount' /></div>
										</div>
										<div className='row'>
											<div className='mt-3 col-4'><FormFieldSwitchPill thin='true' label={translations.labels.defaultShipmentIsNonStandard} name='defaultShipmentIsNonStandard' /></div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className='col-8'>
										<div className='card'>
											<div className='card-header'>{translations.headers.additionalService}</div>
											<div className='card-body'>
												<div className='row'>
													<div className='col-4'>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceCod} name='defaultAdditionalServiceCod' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceInsurance} name='defaultAdditionalServiceInsurance' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceSms} name='defaultAdditionalServiceSms' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceEmail} name='defaultAdditionalServiceEmail' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceSaturday} name='defaultAdditionalServiceSaturday' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceDor1720} name='defaultAdditionalServiceDor1720' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceRod} name='defaultAdditionalServiceRod' /></div>
													</div>
													<div className='col-4'>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceForhour9} name='defaultAdditionalServiceForhour9' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceForhour10} name='defaultAdditionalServiceForhour10' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceForhour11} name='defaultAdditionalServiceForhour11' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceForhour12} name='defaultAdditionalServiceForhour12' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceForhour13} name='defaultAdditionalServiceForhour13' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceForhour14} name='defaultAdditionalServiceForhour14' /></div>
													</div>
													<div className='col-4'>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceForhour15} name='defaultAdditionalServiceForhour15' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceForhour16} name='defaultAdditionalServiceForhour16' /></div>
														<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceForhour17} name='defaultAdditionalServiceForhour17' /></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='col-4'>
										<div className='card'>
											<div className='card-header'>{translations.headers.cod}</div>
											<div className='card-body'>
												<div className='row'>
													<div className='mt-3 col-6'><FormFieldTextOrNumber type="number" inputClassName="form-control text-right" label={translations.labels.defaultCodAmount} name='defaultCodAmount' /></div>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.defaultCodCurrency} name='defaultCodCurrency' /></div>
												</div>
											</div>
										</div>
										<div className='card'>
											<div className='card-header'>{translations.headers.insurance}</div>
											<div className='card-body'>
												<div className='row'>
													<div className='mt-3 col-6'><FormFieldTextOrNumber type="number" inputClassName="form-control text-right" label={translations.labels.defaultInsuranceAmount} name='defaultInsuranceAmount' /></div>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.defaultInsuranceCurrency} name='defaultInsuranceCurrency' /></div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='card'>
									<div className='card-header'>{translations.headers.other}</div>
									<div className='card-body'>
										<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.defaultMpk} name='defaultMpk' /></div>
										<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.defaultComments} name='defaultComments' /></div>
									</div>
								</div>

                                <SectionEditFormButtons formReset={form.reset}/>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconclassName='fa fa-facebook'/>;
};

const mapDispatch = {
    getIntegrationInPost,
    updateIntegrationInPost,
};

InPost.propTypes = propTypes;

export default connect(null, mapDispatch)(InPost);