import appGlobal from './appGlobal';
import appSidebar from './appSidebar';
import appAside from './appAside';
import endOfSession from './endOfSession';

const defaultLayout = {
    appGlobal,
    appSidebar,
    appAside,
    endOfSession,
};

export default defaultLayout;