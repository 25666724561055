import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Table, Row, ButtonGroup, Button, Input, UncontrolledTooltip } from "reactstrap";

import styles from "./index.module.scss";

const propTypes = {
    tabs: PropTypes.object,
    translations: PropTypes.object,
    changeTabOrder: PropTypes.func,
    updateProductPageTabs: PropTypes.func,
};

const ProductTabsTable = ({
    tabs,
    translations,
    changeTabOrder,
    updateProductPageTabs
}) => {
    const changeOrderHandler = (id, delta) => () => {
        changeTabOrder(id, delta);
    };

    const handleChangeDefaultTab = (tabType) => () => {
        const objectToSend = {
            ...tabs,
            defaultTab: tabType,
        };

        updateProductPageTabs(objectToSend);
    };

    const handleActiveSwitch = (selectedTab, isActive) => () => {
        const modifiedArray = tabs.tabs.map(initialTab => {
            if(initialTab.tabType === selectedTab.tabType) {
                return {
                    ...selectedTab,
                    visible: !isActive
                };
            }
            else {
                return initialTab;
            }
        });

        const objectToSend = {
            ...tabs,
            tabs: modifiedArray
        };

        updateProductPageTabs(objectToSend);
    };

    return tabs && tabs.tabs.length > 0 ? (
        <Table responsive hover size="sm">
            <thead>
                <tr>
                    <th>
                        {translations.columnNames.order}
                    </th>
                    <th>
                        {translations.columnNames.name}
                    </th>
                    <th>
                        {translations.columnNames.active}
                    </th>
                    <th>
                        {translations.columnNames.default.label}
                        <UncontrolledTooltip placement='bottom' target='productTabsTable-default'>
                            {translations.columnNames.default.tooltip}
                        </UncontrolledTooltip>
                        <i id='productTabsTable-default' className="fa fa-question-circle text-primary ml-1"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    tabs.tabs.map((tab, index) => {

                        return (
                            <tr key={`tab-${tab.tabType}`}>
                                <td className={clsx(styles.cell)}>
                                    <ButtonGroup vertical>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            disabled={index === 0}
                                            onClick={changeOrderHandler(tab.tabType, -1)}
                                        >
                                            <i className="cil-arrow-thick-top"></i>
                                        </Button>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            disabled={index === tabs.tabs.length - 1}
                                            onClick={changeOrderHandler(tab.tabType, 1)}
                                        >
                                            <i className="cil-arrow-thick-bottom"></i>
                                        </Button>
                                    </ButtonGroup>
                                </td>
                                <td className={clsx(styles.cell)}>
                                    <span>{tab.label}</span>
                                </td>
                                <td className={clsx(styles.cell)}>
                                    <label className="switch switch-pill switch-success m-0">
                                        <input
                                            type="checkbox"
                                            className="switch-input"
                                            checked={tab.visible}
                                            onChange={handleActiveSwitch(tab, tab.visible)}
                                        />
                                        <span
                                            className={clsx("switch-slider", !tab.visible &&  "bg-danger border-danger")}
                                        ></span>
                                    </label>
                                </td>
                                <td className={clsx(styles.cell)}>
                                    {
                                        tab.visible && (tab.tabType === 2 || tab.tabType === 1)
                                        && <Input className={styles.radioInput} type='radio' name='defaultTab' checked={tab.tabType === tabs.defaultTab} onChange={handleChangeDefaultTab(tab.tabType)} />
                                    }
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    ) : (
        <Row className="d-flex justify-content-center mt-4 mb-4">
            {translations.emptyTable}
        </Row>
    );
};

ProductTabsTable.propTypes = propTypes;

export default ProductTabsTable;
