import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Button } from 'reactstrap';
import ListSelectionModal from '../../../components/modals/ListSelectionModal';

import { getSelectedFonts } from '../../../redux/methods/fonts';

import { stringOrNumberPropTypes } from '../../../propTypes';

import { translateListToFontFamily, FontListItem } from '../../../utils/fonts';

const propTypes = {
    fonts: PropTypes.arrayOf(PropTypes.string),
    getSelectedFonts: PropTypes.func,
    selectedFont: PropTypes.shape({
        id: stringOrNumberPropTypes,
        label: PropTypes.string
    }),
    setSelectedFont: PropTypes.func
};

const FontSelector = ({
    fonts,
    getSelectedFonts,
    selectedFont,
    setSelectedFont,
    fontConfig,
    additionalFonts = []
}) => {
    const intl = useIntl();
    const translations = intl.messages.fonts.selector;

    const history = useHistory();
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const openModal = () => setIsModalOpen(true);

    useEffect(() => {
        if(!fonts) {
            getSelectedFonts();
        }
    }, [fonts, getSelectedFonts]);

    const handleOnItemClick = (item) => async () => {
        await setSelectedFont(item);
        setIsModalOpen(false);
    };

    const handleRedirectToFonts = () => {
        history.push('/extras/fonts');
    };

    const fontsToDisplay = [...new Set(fonts.map(font => font.toLowerCase())) ];

    return (
        <React.Fragment>
            <Button
                onClick={openModal}
                color='warning'
            >
                {selectedFont
                    ? (typeof(selectedFont) === 'object' ? (selectedFont.label + (selectedFont.variant ? (' - ' + selectedFont.variant) : '')) : selectedFont)
                    :  translations.select
                }
            </Button>
            {
                fontsToDisplay &&
                    <ListSelectionModal
                        listItem={FontListItem}
                        isOpen={isModalOpen}
                        setIsOpen={setIsModalOpen}
                        items={[...translateListToFontFamily(fontsToDisplay, fontConfig), ...additionalFonts]}
                        header={{
                            title: translations.select,
                        }}
                        onItemClick={handleOnItemClick}
                        buttons={{
                            confirm: {
                                label: translations.addFonts,
                                color: 'primary'
                            }
                        }}
                        onConfirm={handleRedirectToFonts}
                        searchPlaceholder={translations.searchPlaceholder}
                    />
            }
        </React.Fragment>
    );
};

FontSelector.propTypes = propTypes;

const mapDispatch = {
    getSelectedFonts
};

const mapStateToProps = state => ({
    fonts: state.fonts.fonts,
    fontConfig: state.fonts.fontConfig,
});

export default connect(mapStateToProps, mapDispatch)(FontSelector);