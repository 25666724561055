import React from "react";
import PropTypes from "prop-types";

import { useIntl } from 'react-intl';
import clsx from "clsx";

import { Table, Input, Row } from "reactstrap";

import TableActionCell from "../TableActionCell";

import { translatedFeaturesAttributesListItemPropTypes } from '../../../propTypes';

import styles from "./index.module.scss";

const propTypes = {
    features: PropTypes.arrayOf(translatedFeaturesAttributesListItemPropTypes),
    checkedCheckboxList: PropTypes.arrayOf( PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    columnNames: PropTypes.shape({
        featureName: PropTypes.string,
        featureType: PropTypes.string,
        featurePresentation: PropTypes.string,
        featureActions: PropTypes.string,
    }),
    tooltipTranslations: PropTypes.shape({
        remove: PropTypes.string,
        edit: PropTypes.string,
    }),
    checkboxAction: PropTypes.func.isRequired,
    removeAction: PropTypes.func.isRequired,
    emptyTranslation: PropTypes.string,
    deleteModalTranslations: PropTypes.shape({
        header: PropTypes.string,
        bodyStart: PropTypes.string,
        bodyEnd: PropTypes.string,
        confirm: PropTypes.string,
        cancel: PropTypes.string
    }).isRequired,
    editLinkGenerator: PropTypes.func.isRequired,
};

const FeaturesAssignedToCategoryTable = ({
    features, checkedCheckboxList, columnNames, checkboxAction, removeAction,
    emptyTranslation, tooltipTranslations, deleteModalTranslations, editLinkGenerator
}) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.tables;

    const isFeaturesListPresent = features && features.length > 0;

    const checkboxActionHandler = id => () => {
        checkboxAction(id);
    };
    const removeActionHandler = id => () => {
        removeAction(id);
    };

    return isFeaturesListPresent ? (
        <Row>
            <Table responsive hover size="sm">
                <thead>
                    <tr>
                        <th></th>
                        <th className={styles.columnFeatureName}>
                            {columnNames && columnNames.featureName}
                        </th>
                        <th className={styles.columnFeatureType}>
                            {columnNames && columnNames.featureType}
                        </th>
                        <th className={styles.columnFeaturePresentation}>
                            {columnNames && columnNames.featurePresentation}
                        </th>
                        <th className={styles.columnActions}>
                            {columnNames && columnNames.featureActions}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {features.map(feature => {
                        const isChecked = checkedCheckboxList.some(item => item === feature.id);
                        return (
                            <tr key={`feature-${feature.id}`}>
                                <th scope="row" className={clsx(styles.columnCheckbox, styles.cell)}>
                                    <Input
                                        checked={isChecked}
                                        id={`checkboxItem-${feature.id}`}
                                        type="checkbox"
                                        onChange={checkboxActionHandler(feature.id)}
                                        className={clsx(`position-static m-0 p-0`, styles.checkbox)}
                                    />
                                </th>
                                <td className={clsx(styles.columnFeatureName, styles.cell, !feature.translatedName && 'text-danger')}>
                                    {feature.translatedName || translations.nameFiller}
                                </td>
                                <td className={clsx(styles.columnFeatureType, styles.cell)}>
                                    {feature.attributeType}
                                </td>
                                <td className={clsx(styles.columnFeaturePresentation, styles.cell)}>
                                    {feature.attributePresentation}
                                </td>
                                <td className={clsx(styles.columnActions, styles.cell)}>
                                    <TableActionCell
                                        itemId={feature.id}
                                        actions={{
                                            edit: {
                                                tooltipTranslation: tooltipTranslations.edit,
                                                editLink: editLinkGenerator(feature.id),
                                            },
                                            delete: {
                                                tooltipTranslation: tooltipTranslations.remove,
                                                action: removeActionHandler(feature.id),
                                                modalTranslations: {
                                                    header: `${deleteModalTranslations.header} ${feature.translatedName}`,
                                                    body: `${deleteModalTranslations.bodyStart} ${feature.translatedName} ${deleteModalTranslations.bodyEnd}?`,
                                                    confirm: deleteModalTranslations.confirm,
                                                    cancel: deleteModalTranslations.cancel
                                                },
                                            },
                                        }}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Row>
    ) : (
        <Row className="d-flex justify-content-center mt-4 mb-4">
            {emptyTranslation}
        </Row>
    );
};

FeaturesAssignedToCategoryTable.propTypes = propTypes;

export default FeaturesAssignedToCategoryTable;
