const tile = {
    view: {
        info: `Uwaga!
            W przypadku zdefiniowania zbyt małej liczby wypełniaczy, na stronach wyszukiwania mogą powstać puste miejsca.
            Aby tego uniknąć zdefiniuj wypełniacze przynajmniej na jedną stronę wyszukiwania!`,
        title: 'Kafelek',
        settingsTabLabel: 'Ustawienia',
        fillersTabLabel: 'Reklamy uzupełniające',
        actions: {
            save: 'ZAPISZ ZMIANY',
        },
        labels: {
            name: 'Nazwa artykułu',
            count: 'Ilość artykułów na stronie',
            singleFillerCount: 'Ilość pojedynczych wypełniaczy na stronie wyszukiwania',
            doubleFillerCount: 'Ilość podwójnych wypełniaczy na stronie wyszukiwania',
            visibleTagAttributeOnProductImage: 'Atrybut typu Tag widoczny na zdjęciu produktu',
            manufacturerField: 'Informacje o producencie',
            showReviews: 'Pokaż oceny produktu',
            showQuantity: 'Pokaż ilość produktu',
            showAddToFavourites: 'Pokaż ikonę "Dodaj do ulubionych"',
            showAddToComparision: 'Pokaż ikonę "Dodaj do porównania"',
            showRelated: 'Pokaż ikonę "Powiązane"',
            showAddToCart: 'Pokaż przycisk "Dodaj do koszyka"',
            showQuantitySelection: 'Pokaż pola wyboru jednostki i ilości',
            showNetPrice: 'Pokaż cenę netto',
            imageTags: 'Prezentowane na zdjęciu atrybuty typu tag',
            imageTags2: 'Prezentowane na zdjęciu cechy typu tag',
            optionalFields: 'Widoczność informacji opcjonalnych',
            additionalFields: 'Widoczność pól dodatkowych',
            generalSettings: 'Ustawienia podstawowe'
        },
        manufacturerFields: {
            name: 'Nazwa',
            logo: 'Logo'
        },
        attributes: {
            id : 'Id',
            catalogueindex : 'Indeks katalogowy',
            tradeindex : 'Indeks handlowy',
            category : 'Kategoria',
            manufacturer : 'Producent',
            measure : 'Miara',
            quantity : 'Ilość',
            availability : 'Dostępność',
            logo : 'Logo producenta',
            ean : 'Ean',
            tax : 'Podatek',
            weight : 'Waga',
            kgo : 'Kgo',
            country : 'Kraj',
            pkwiu : 'Pkwiu',
            swwku : 'Swwku',
            distinguisher : 'Wyróżniacz',
            localisation : 'Lokalizacja',
            cncode : 'Kod CNC',
        },
        addButton: 'DODAJ',
        table: {
            headers: {
                image: 'Zdjęcie',
                name: 'Nazwa',
                active: 'Aktywność',
                actions: 'Akcje'
            },
            tooltips: {
                edit: 'Edytuj reklamę',
                remove: 'Usuń reklamę',
            },
            deleteModal: {
                header: 'Usuń reklamę',
                body: 'Czy jesteś pewien, że chcesz usunąć reklamę?',
                cancel: 'WRÓC',
                confirm: 'USUŃ',
            },
            emptyTable: 'Nie znaleziono reklam uzupełniających'
        },
        editModal: {
            header: 'Edytuj reklamę',
            inputs: {
                photo: 'Zdjęcie',
                file: {
                    upload: 'Dodaj zdjęcie',
                    remove: 'Usuń zdjęcie',
                    edit: 'Podmień zdjęcie',
                },
                alternativeText: 'Tekst alternatywny',
                header: 'Nazwa',
                description: 'Opis',
                link: 'Odnośnik',
                buttonLabel: 'Przycisk',
                urlType: 'Typ odnośnika',
                urlTypes: {
                    category: 'Kategoria',
                    product: 'Produkt',
                    outside: 'Zewnętrzny odnośnik'
                },
                double: 'Kafelek podwójny',
            },
            actions: {
                save: 'ZAPISZ',
                cancel: 'ANULUJ'
            }
        },
        additionalFields: {
            quantity: 'Dostępna ilość',
            measure: 'Rozmiar',
            promotiondateto: 'Okres promocji'
        },
    },
};

export default tile;