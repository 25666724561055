import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import useFetch from '../../hooks/useFetch';

import { selectStoreCurrentLanguage } from '../../redux/selectors/storeLocale';
import { getAdBanners, deleteAdBanner, updateAdBannerStatus } from '../../redux/methods/adBanners';
import { generateEditLinkForAdBanner, translateAdBanners } from '../../utils/adBanners';

import { Button } from 'reactstrap';

import ContainerWithCard from '../../components/ContainerWithCard';
import AdBannersTable from '../../components/tables/AdBannersTable';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object,
};

const AdBanners = ({
    history,
    forceRefetch,
    getAdBanners,
    deleteAdBanner,
    storeChosenLanguage,
    updateAdBannerStatus,
}) => {
    const intl = useIntl();
    const translations = intl.messages.adBanners;

    const [ adBanners ] = useFetch(getAdBanners, null, [], forceRefetch);
    const [ checkedAdBannersIds, setCheckedAdBannersIds ] = useState([]);

    const handleAddAdBanner = () => {
        history.push(generateEditLinkForAdBanner(-1));
    };

    return adBanners ? (
        <ContainerWithCard title={translations.view.title} iconClass='fa fa-wallpaper'>
            <AdBannersTable
                translations={translations.table}
                adBanners={translateAdBanners(adBanners, storeChosenLanguage)}
                removeAction={deleteAdBanner}
                history={history}
                generateEditLinkForAdBanner={generateEditLinkForAdBanner}
                checkedAdBannersIds={checkedAdBannersIds}
                setCheckedAdBannersIds={setCheckedAdBannersIds}
                updateAdBannerStatus={updateAdBannerStatus}
            />
            <aside className={styles.footer}>
                <Button
                    color='primary'
                    onClick={handleAddAdBanner}
                >
                    <i className='fa fa-plus mr-2'/>
                    <span>{translations.view.actions.create}</span>
                </Button>
            </aside>
        </ContainerWithCard>
    ) : null;
};

AdBanners.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.adBanners,
    storeChosenLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    getAdBanners,
    deleteAdBanner,
    updateAdBannerStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(AdBanners));