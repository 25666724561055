export const agreementIsRequiredEnum = {
    0: 'notRequired' ,
    1: 'required',
};

export const agreementIsActiveEnum = {
    0: 'inactive',
    1: 'active',
};

export const agreementIsModifiedEnum = {
    0: 'notModified',
    1: 'modified',
};

export const agreementStatusEnum = {
    0: 'agree',
    1: 'disagree',
    2: 'remove'
};