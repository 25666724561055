import React from 'react';
import PropTypes from 'prop-types';

import FormFieldTranslations from '../../../../components/formFields/FormFieldTranslations';
import FormFieldSwitchWithTwoLabels from '../../../../components/formFields/FormFieldSwitchWithTwoLabels';

const propTypes = {
    currentLanguage: PropTypes.string,
    form: PropTypes.object,
    propertyName: PropTypes.string,
    switchLabels: PropTypes.shape({
        true: PropTypes.string,
        false: PropTypes.string,
        main: PropTypes.string
    }),
    switchName: PropTypes.string,
    label: PropTypes.string
};

const UrlTypeController = ({
    form,
    label,
    currentLanguage,
    propertyName,
    switchLabels,
    switchName
}) => {

    return (
        <div className='my-3 ml-0'>
            <FormFieldTranslations
                form={form}
                propertyName={propertyName}
                label={label}
                currentIso={currentLanguage}
                modalHeader={label}
            />
            <div className='mt-3'>
                <FormFieldSwitchWithTwoLabels
                    name={switchName}
                    labels={switchLabels}
                />
            </div>
        </div>
    );
};

UrlTypeController.propTypes = propTypes;

export default UrlTypeController;