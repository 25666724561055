import { addEditData, getData, removeData } from '../generic';

import { cAPI } from '../../../utils/cAPI';

export const getAgreements =            () => dispatch => getData(dispatch, cAPI.APGetAgreements, null, 'agreements');
export const getAgreementTypes =        () => dispatch => getData(dispatch, cAPI.APGetAgreementTypes, null, 'agreements');
export const getAgreementBasicData =    id => dispatch => getData(dispatch, cAPI.APGetAgreementBasicData, id, 'agreements');
export const getAgreementDescriptions = id => dispatch => getData(dispatch, cAPI.APGetAgreementDescriptions, id, 'agreements');
export const getAgreementRegions =      id => dispatch => getData(dispatch, cAPI.APGetAgreementRegions, id, 'agreementsRegions');

export const editAgreementBasicData =   formData => dispatch => addEditData(dispatch, cAPI.APUpdateAgreementBasicData, formData, 'agreements');
export const editAgreementBasicDescription =   formData => dispatch => addEditData(dispatch, cAPI.APUpdateAgreementDescription, formData, 'agreements');
export const editAgreementRegions =     formData => dispatch => addEditData(dispatch, cAPI.APUpdateAgreementRegions, formData, 'agreementsRegions');

export const removeAgreement =          id => dispatch => removeData(dispatch, cAPI.APRemoveAgreement, id, 'agreements');

export const getUserAgreements = searchConfig => dispatch => getData(dispatch, cAPI.APGetUserAgreements, searchConfig, 'agreementsRegistry');
export const getAllUserAgreemetns = () => dispatch => getData(dispatch, cAPI.APGetAllUserAgreemetns);
export const getUserAgreementsByEmail = email => dispatch => getData(dispatch, cAPI.APGetUserAgreementsByEmail, email, 'userAgreements');

export const updateUserAgreement = formData => dispatch => addEditData(dispatch, cAPI.APUpdateUserAgreementByEmail, formData, ['userAgreements', 'agreementsRegistry']);
export const updateAgreementStatus =   formData => dispatch => addEditData(dispatch, cAPI.APUpdateAgreementStatus, formData, 'agreements');
