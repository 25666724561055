/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { getTranslationForIso } from '../../utils/components';
import { generateEditLinkForTemplate, generateShopPreviewLink } from '../../utils/visualSettings';
import { uploadVisualSettingsOwnCss, removeVisualSettingsOwnCss } from '../../redux/methods/visualSettings';
import LayoutTemplateController from './LayoutTemplateController';

import FileSender from '../FileSender';

import styles from './index.module.scss';
import getConfig from '../../getConfig';

const propTypes = {
    history: PropTypes.object,
    uploadVisualSettingsOwnCss: PropTypes.func,
    removeVisualSettingsOwnCss: PropTypes.func,
    layout: PropTypes.object,
    translations: PropTypes.object,
    onImageClick: PropTypes.func,
    onSubmit: PropTypes.func,
    selectedCustomCss: PropTypes.bool,
    selectedLayoutId: PropTypes.number,
    selectedTemplateId: PropTypes.number,
};

const LayoutController = ({
    history,
    uploadVisualSettingsOwnCss,
    removeVisualSettingsOwnCss,
    layout,
    translations,
    currentLanguage,
    onImageClick,
    onSubmit,
    selectedCustomCss,
    selectedLayoutId,
    selectedTemplateId,
}) => {
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const handleImageClick = () => {
        onImageClick({
            imageSource: layout.imageSource,
            imageAlternativeText: getTranslationForIso(layout.imageAlternativeText, currentLanguage),
            name: layout.name,
        });
    };

    const handleActivateShopCss = () => {
        onSubmit({
            shopCssEnabled: true,
            selectedLayoutId,
            selectedTemplateId
        });
    }

    const handleDownloadShopCss = () => {
        const cssUrl = layout.shopFile;
        window.open(`${apiUrl}${cssUrl}`);
    };

    const handleActivateUserCss = () => {
        onSubmit({
            shopCssEnabled: false,
            selectedLayoutId,
            selectedTemplateId
        });
    }

    const handleDownloadUserCss = () => {
        const cssUrl = layout.userFile;
        window.open(`${apiUrl}${cssUrl}`);
    };

    const handleUploadUserCss = formData => {
        uploadVisualSettingsOwnCss(layout.layoutId, formData);
    };

    const handleDeleteUserCss = () => {
        removeVisualSettingsOwnCss(layout.layoutId);
    };

    const handleSelectTemplate = (layoutTemplateId) => {
        onSubmit({
            shopCssEnabled: !selectedCustomCss,
            selectedLayoutId: layout.layoutId,
            selectedTemplateId: layoutTemplateId
        });
    }

    const handleOnPreview = (layoutTemplateId, type) => {
        //alert("Preview: " + layout.layoutId + "/" + layoutTemplateId + "/" + type);
        const previewUrl = generateShopPreviewLink(currentLanguage, layout.layoutId, layoutTemplateId, type);
        const shopPreviewWindow = window.open(previewUrl, '_blank', 'noopener, noreferrer');
        if (shopPreviewWindow) {
            shopPreviewWindow.opener = null;
        }
    };

    const handleEditAction = id => {
        history.push(generateEditLinkForTemplate(id));
    };

    return (
        <React.Fragment>
            <Card id={clsx(layout.layoutId === selectedLayoutId && styles.highlightBorderSelected)}>
                <CardHeader>Nazwa skórki: {layout.name}</CardHeader>
                <CardBody>
                    <Row>
                        <Col xl="6" lg="12">
                            <div className={styles.imageContainer}>
                                <img
                                    onClick={handleImageClick}
                                    src={`${apiUrl}${layout.imageSource}`}
                                    alt={getTranslationForIso(layout.imageAlternativeText, currentLanguage)}
                                    className={styles.image}
                                />
                            </div>
                            <h5>{translations.labels.systemCSSFile}:</h5>
                            <div className={clsx('w-100 my-3 p-3 d-flex flex-wrap justify-content-start', layout.layoutId === selectedLayoutId && !selectedCustomCss && styles.highlightSelected)}>
                                {layout.layoutId === selectedLayoutId &&
                                    <Button onClick={handleActivateShopCss} className='mx-md-1' color={selectedCustomCss ? 'primary' : 'success'} disabled={!selectedCustomCss} >
                                        <i className={clsx('fas fa-check-circle mr-2')}/>
                                        <span>{selectedCustomCss ? translations.buttons.activate : translations.buttons.active}</span>
                                    </Button>
                                }
                                <Button className='mx-md-1' color={'primary'} onClick={handleDownloadShopCss} >
                                    <i className={clsx('fa fa-download mr-2')}/>
                                    <span>{translations.buttons.downloadCSS}</span>
                                </Button>
                            </div>
                            <h5>{translations.labels.userCSSFile}:</h5>
                            <div className={clsx('w-100 my-3 p-3 d-flex flex-wrap justify-content-start', layout.layoutId === selectedLayoutId && selectedCustomCss && styles.highlightSelected)}>
                                {layout.layoutId === selectedLayoutId &&
                                    <Button onClick={handleActivateUserCss} className='m-1' color={selectedCustomCss ? 'success' : 'primary'} disabled={!(layout.userFile) || selectedCustomCss}>
                                        <i className={clsx('fa fa-check mr-2')}/>
                                        <span>{!selectedCustomCss ? translations.buttons.activate : translations.buttons.active}</span>
                                    </Button>
                                }
                                <Button className='m-1' color={'primary'} disabled={!(layout.userFile)} onClick={handleDownloadUserCss}>
                                    <i className={clsx('fa fa-download mr-2')}/>
                                    <span>{translations.buttons.downloadCSS}</span>
                                </Button>
                                <div className='m-1'>
                                    <FileSender
                                        buttons={{upload: translations.buttons.uploadCSS}}
                                        validateFormats={['css']}
                                        handleUpload={handleUploadUserCss}
                                        fileKey='Css'
                                    />
                                </div>
                                <Button className='m-1' color={'danger'} disabled={!(layout.userFile)} onClick={handleDeleteUserCss}>
                                    <i className={clsx('fa fa-remove mr-2')}/>
                                    <span>{translations.buttons.deleteCSS}</span>
                                </Button>
                            </div>
                            {layout.userFile &&
                                <div>
                                    {translations.labels.uploadedFileName}: <b>{layout.userFile}</b>
                                </div>
                            }
                        </Col>
                        <Col xl="6" lg="12">
                            <Card>
                                <CardHeader>{translations.templateSectionTitle}</CardHeader>
                                <CardBody>
                                    <div className='d-flex flex-row flex-wrap justify-content-center'>
                                    {
                                        layout.templates.map(template =>
                                            <LayoutTemplateController
                                                key={`${template.templateId}-template`}
                                                template={template}
                                                translations={translations}
                                                onPreviewClick={handleOnPreview}
                                                selectedTemplateId={selectedTemplateId}
                                                setSelectedTemplateId={handleSelectTemplate}
                                                editAction={handleEditAction}
                                                userFile={layout.userFile}
                                            />
                                        )
                                    }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

LayoutController.propTypes = propTypes;

const mapStateToProps = state => ({
});

const mapDispatch = {
    uploadVisualSettingsOwnCss,
    removeVisualSettingsOwnCss,
};

export default connect(mapStateToProps, mapDispatch)(withRouter(LayoutController));
