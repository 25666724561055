import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import FormFieldTranslations from '../../formFields/FormFieldTranslations';
import FormFieldWithDropdown from '../../formFields/FormFieldWithDropdown';
import FormFieldTextOrNumber from '../../formFields/FormFieldTextOrNumber';
//import FormFieldSwitchWithTwoLabels from '../../formFields/FormFieldSwitchWithTwoLabels';
import FormFieldSwitchPill from '../../formFields/FormFieldSwitchPill';
import FormFieldMultipleDropDown from '../../formFields/FormFieldMultipleDropDown';

import styles from './index.module.scss';
import clsx from 'clsx';
import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    isConnectedToERP: PropTypes.bool,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    translations: PropTypes.object,
    shippingForm: PropTypes.object
};

const ShippingFormFormEditionModal = ({ utils, isConnectedToERP, isOpen, setIsOpen, onSubmit, translations, shippingForm }) => {

    const config = {
        translations: ['name'],
        shipmentDiscounts: ['discounts'],
        shipmentVisibilityLevels: ['visibilityLevels']
    };

    const handleOnCancel = () => {
        setIsOpen(false);
    };

    const handleOnSubmit = (form) => {
        onSubmit(utils.generateFormToSubmitValues(form, config));
    };

    return shippingForm ? (
        <Modal size="xl" isOpen={isOpen}>
            <FinalForm
                initialValues={utils.generateFormInitialValues(shippingForm, config)}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values}) => {

                    const selectedShippingMethod = shippingForm.shippingMethodsDictionary.find(item => item.id === values.shippingMethodId);
                    const selectedProduct = shippingForm.productsDictionary.find(item => item.id === values.productId);
                    const selectedWfMagForm = shippingForm.wfMagFormsDictionary.find(item => item.id === values.wfMagFormId);

                    const paymentForms = shippingForm.paymentFormsDictionary;

                    return (
                        <div>
                            <ModalHeader>{translations.title}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleSubmit}>
                                    <React.Fragment>
                                        <div className='my-2'>
                                            <FormFieldTranslations
                                                label={translations.inputs.name}
                                                form={form}
                                                modalHeader={translations.nameModalHeader}
                                                propertyName='name'
                                                currentIso={utils.currentLanguage()}
                                                className='w-100'
                                            />
                                        </div>
                                        <Row>
                                            <Col className="col-6">
                                                <Card>
                                                    <CardHeader>Parametry</CardHeader>
                                                    <CardBody>
                                                        <div>
                                                            <FormFieldWithDropdown
                                                                name='shippingMethodId'
                                                                label={translations.inputs.shippingMethodId}
                                                                currentSelectionLabel={selectedShippingMethod?.label}
                                                                options={shippingForm.shippingMethodsDictionary}
                                                            />
                                                        </div>
                                                        <div className='my-2'>
                                                            <FormFieldWithDropdown
                                                                name='productId'
                                                                label={translations.inputs.productId}
                                                                currentSelectionLabel={selectedProduct?.label}
                                                                options={shippingForm.productsDictionary}
                                                            />
                                                        </div>
                                                        {isConnectedToERP && (
                                                            <div className='my-2'>
                                                                <FormFieldWithDropdown
                                                                    name='wfMagFormId'
                                                                    label={translations.inputs.wfMagFormId}
                                                                    currentSelectionLabel={selectedWfMagForm?.label}
                                                                    options={shippingForm.wfMagFormsDictionary}
                                                                />
                                                            </div>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col className="col-6">
                                                <Card>
                                                    <CardHeader>{translations.inputs.paymentFormIds}</CardHeader>
                                                    <CardBody>
                                                        <FormFieldMultipleDropDown
                                                            name='paymentFormIds'
                                                            items={paymentForms}
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-6">
                                                <Card>
                                                    <CardHeader>{translations.inputs.orderVisibleValueCaption}</CardHeader>
                                                    <CardBody>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="px-2">{translations.inputs.orderVisibleValueFrom}</th>
                                                                    <th className="px-2">{translations.inputs.orderVisibleValueTo}</th>
                                                                    <th className="px-2"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    shippingForm.visibilityLevels.map((visibilityLevel, idx) => {
                                                                        return (
                                                                            <tr key={idx}>
                                                                                <td className="px-2">
                                                                                    <FormFieldTextOrNumber
                                                                                        name={`visibilityLevels-${visibilityLevel.currencyId}-minimumOrderValue`}
                                                                                        type='number'
                                                                                        inputClassName='text-right'
                                                                                    />
                                                                                </td>
                                                                                <td className="px-2">
                                                                                    <FormFieldTextOrNumber
                                                                                        name={`visibilityLevels-${visibilityLevel.currencyId}-maximumOrderValue`}
                                                                                        type='number'
                                                                                        inputClassName='text-right'
                                                                                    />
                                                                                </td>
                                                                                <td className="px-2">{visibilityLevel.currencyShortName}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col className="col-6">
                                                <Card>
                                                    <CardHeader>{translations.inputs.orderFreeValueCaption}</CardHeader>
                                                    <CardBody>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="px-2"></th>
                                                                    <th className="px-2">Wartość brutto powyżej:</th>
                                                                    <th className="px-2"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    shippingForm.discounts.map((discount, idx) => {
                                                                        return (
                                                                            <tr key={idx}>
                                                                                <td className="px-2">
                                                                                    <FormFieldTextOrNumber type="hidden" name={`discounts-${discount.currencyId}-discountId`}/>
                                                                                    <FormFieldSwitchPill
                                                                                        name={`discounts-${discount.currencyId}-active`}
                                                                                        labels={translations.inputs.isFree}
                                                                                    />
                                                                                </td>
                                                                                <td className="px-2">
                                                                                    <FormFieldTextOrNumber
                                                                                        name={`discounts-${discount.currencyId}-amountLimit`}
                                                                                        disabled={!values[`discounts-${discount.currencyId}-active`]}
                                                                                        type='number'
                                                                                        inputClassName='text-right'
                                                                                    />
                                                                                </td>
                                                                                <td className="px-2">{discount.currencyShortName}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <div className={clsx("d-flex justify-content-between", styles.modalFooterButtons)}>
                                    <Button onClick={handleOnCancel} color='danger'>
                                        <i className="fa fa-remove mr-2"></i>
                                        <span>{translations.buttons.remove}</span>
                                    </Button>
                                    <Button onClick={handleSubmit} color='success'>
                                        <i className="fa fa-save mr-2"></i>
                                        <span>{translations.buttons.save}</span>
                                    </Button>
                                </div>
                            </ModalFooter>
                        </div>
                    );
                }}
            />
        </Modal>
    ) : null;
};

ShippingFormFormEditionModal.propTypes = propTypes;

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP
});

export default connect(mapStateToProps, null)(withFormUtils(ShippingFormFormEditionModal));