const editNameModal = {
    save: 'SAVE CHANGES',
    cancel: 'DISCARD CHANGES',
    labels: {
        name: '[EN] Nazwa',
        textColor: '[EN] Kolor tekstu',
        backgroundColor: '[EN] Kolor tła',
        button: '[EN] Wybierz kolor',
        mappingEnabled: '[EN] Mapowanie z Erp',
        mappingFieldId: '[EN] Pole z Erp',
        mappingValue: '[EN] Szukany tekst',
    }
};

export default editNameModal;