const accountSettings = {
    title: 'Ustawienia konta',
    personalDataTab: {
        title: 'Dane osobowe',
        inputs: {
            name: 'Imię',
            surname: 'Nazwisko',
            companyName: 'Nazwa firmy',
            nip: 'NIP'
        }
    },
    passwordsTab: {
        title: 'Hasła',
        inputs: {
            oldPassword: 'Aktualne hasło',
            newPassword: 'Nowe hasło',
            confirmNewPassword: 'Potwierdź nowe hasło'
        },
        errors: {
            newPasswordNotRepeatedCorrectly: 'Podane hasło i potwierdzenie hasła różnią się'
        }
    },
    emailTab: {
        title: 'E-mail',
        inputs: {
            email: 'Adres e-mail'
        },
        submitSucceeded: 'Mail potwierdzający zmianę został wysałny na Twój aktualny adres email. Zaloguj się, aby potwierdzić zmianę. Jeśli nie otrzymasz maila w przeciągu 2h - prosimy skontaktuj się z Administratorem.'
    }
};

export default accountSettings;