import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import CategorySelection from './CategorySelection';
import AttributeSelection from './AttributeSelection';
import VariantedProductSelection from './VariantedProductSelection';
import VariantTypeSelection from './VariantTypeSelection';
import VariantSettingsSelection from './VariantSettingsSelection';
import { Form as FinalForm } from 'react-final-form';

import { createSummaryProduct } from '../../../redux/methods/products';
import { generateProductEditLink } from '../../../utils/products';

import { Button } from 'reactstrap';

import styles from './index.module.scss';

const SummaryProductEdition = ({
    createSummaryProduct,
    isConnectedToERP,
    history,
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.variants;

    const [ currentStep, setCurrentStep ] = useState(0);
    const [ selectedCategory, setSelectedCategory ] = useState(null);
    const [ selectedAttributes, setSelectedAttributes ] = useState([]);
    const [ selectedProducts, setSelectedProducts ] = useState([]);
    const [ selectedTemplateVariant, setSelectedTemplateVariant ] = useState(null);

    const handleOnSubmit = (formData) => {
        const objectToSend = {
            ...formData,
            templateVariantId: selectedTemplateVariant,
            variantsIds: selectedProducts,
            attributesIds: selectedAttributes,
            settings: {
                displayUnavailableVariants: formData.displayUnavailableVariants,
                displayMinStateInfo: formData.displayMinStateInfo
            }
        };

        createSummaryProduct(objectToSend)
            .then(response => {
                if (typeof response === 'number') {
                    history.push(generateProductEditLink(response));
                }
            });
    };

    const handleNext = () => {
        setCurrentStep(prevState => prevState + 1);
    };

    const handlePrev = () => {
        setCurrentStep(prevState => prevState - 1);
    };

    const checkIfNextStepAllowed = (values) => {
        if (currentStep === 0 && !values.variantType) {
            return false;
        }
        else if (currentStep === 3 && selectedAttributes.length === 0) {
            return false;
        }
        else if (currentStep === 4 && (selectedProducts.length === 0 || !selectedTemplateVariant || !selectedProducts.includes(selectedTemplateVariant))) {
            return false;
        }
        else {
            return true;
        }
    };

    const renderProperStepSelection = (step, values) => {
        switch(step) {
            case 0: return (
                <VariantTypeSelection
                    values={values}
                    label={translations.summaryProductSteps.chooseType}
                    variantsTranslations={translations.variantsTypes}
                    defaultSelectTranslation={translations.defaultSelect}
                />
            );
            case 1: return (
                <VariantSettingsSelection
                    values={values}
                    settingsLabels={translations.settingsLabels}
                    label={translations.summaryProductSteps.chooseSettings}
                    isConnectedToERP={isConnectedToERP}
                />
            );
            case 2: return (
                <CategorySelection
                    label={translations.summaryProductSteps.chooseCategory}
                    buttonLabel={translations.summaryProductSteps.searchInAllCategories}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                />
            );
            case 3: return (
                <AttributeSelection
                    label={translations.summaryProductSteps.chooseAttributes}
                    translations={translations}
                    selectedAttributes={selectedAttributes}
                    setSelectedAttributes={setSelectedAttributes}
                    selectedCategory={selectedCategory}
                />
            );
            case 4: return (
                <VariantedProductSelection
                    guideTranslation={translations.chooseProductsGuide}
                    label={translations.summaryProductSteps.chooseProducts}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    selectedTemplateVariant={selectedTemplateVariant}
                    setSelectedTemplateVariant={setSelectedTemplateVariant}
                    selectedAttributes={selectedAttributes}
                />
            );
            default: return null;
        }
    };

    return (
        <div className='w-100 h-100'>
            <FinalForm
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, values }) => {
                    return (
                        <React.Fragment>
                            {
                                renderProperStepSelection(currentStep, values)
                            }
                            <aside className={styles.footer}>
                                { currentStep !== 0 &&
                                    <Button color="danger" onClick={handlePrev} className='mr-2'>
                                        <span>{translations.buttons.prev}</span>
                                    </Button>
                                }
                                <Button color="success" onClick={currentStep === 4 ? handleSubmit : handleNext} className='mr-2' disabled={!checkIfNextStepAllowed(values)}>
                                    <span>{currentStep === 4 ? translations.buttons.create : translations.buttons.next}</span>
                                </Button>
                            </aside>
                        </React.Fragment>
                    );
                }}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
});

const mapDispatch = {
    createSummaryProduct
};

export default connect(mapStateToProps, mapDispatch)(SummaryProductEdition);
