/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TableGenerator from '../../generators/TableGenerator';

import { productListItemPropTypes, stringOrNumberPropTypes } from '../../../propTypes';

import PhotoPlaceholder from '../../../assets/photo_placeholder.svg';

import styles from './index.module.scss';
import ArrowsBetweenStrings from '../../ArrowsBetweenStrings';
import getConfig from '../../../getConfig';


const propTypes = {
    translatedProductsList: PropTypes.arrayOf(productListItemPropTypes),
    checkedProductsId: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    setCheckedProductsId: PropTypes.func,
    checkedRowId: stringOrNumberPropTypes,
    setCheckedRowId: PropTypes.func,
};

const ProductsSynchronizationsTable = ({
    translatedProductsList,
    checkedProductsId,
    setCheckedProductsId,
    checkedRowId,
    setCheckedRowId,
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.table;

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const ImageCell = item => {
        return (
            <img
                className={styles.customPhotoCell}
                src={item.productImage ? `${apiUrl}${item.productImage}` : PhotoPlaceholder}
                alt={item.productImageAlternativeText ? item.productImageAlternativeText : translations.emptyLogoAlt}
            />
        );
    };

    const CategoryCell = item => {
        const categoryForFeatures = item.categoryForFeature || null;

        return categoryForFeatures ? (
            <ArrowsBetweenStrings arrayOfStrings={categoryForFeatures && categoryForFeatures.categories} keyToPass={item.id} />
        ) : null;
    };

    const defaultActionOnClick = (id) => () => {
        setCheckedRowId(id);
    };

    return (
        <div className={styles.table}>
            <TableGenerator
                checkedRowId={checkedRowId}
                checkedRowStyle={styles.highlightOnCheck}
                translations={translations}
                defaultAction={defaultActionOnClick}
                items={translatedProductsList}
                checkedItemsIds={checkedProductsId}
                setCheckedItemsIds={setCheckedProductsId}
                customIdSelector='productId'
                columns={[
                    {
                        type: 'checkbox',
                    },
                    {
                        header: translations.headers.photo,
                        customCell: ImageCell,
                        type: 'custom',
                        additionalStyle: styles.photoCell
                    },
                    {
                        header: translations.headers.name,
                        value: 'productTranslatedName'
                    },
                    {
                        header: translations.headers.catalogueIndex,
                        value: 'catalogueIndex'
                    },
                    {
                        header: translations.headers.tradeIndex,
                        value: 'tradeIndex'
                    },
                    {
                        header: translations.headers.category,
                        customCell: CategoryCell,
                        type: 'custom',
                    }
                ]}
            />
        </div>
    );
};

ProductsSynchronizationsTable.propTypes = propTypes;

export default ProductsSynchronizationsTable;