/* eslint-disable camelcase */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'react-redux';

import { getTranslationForIso } from '../../utils/components';
import { fullyFeaturedConfig, basicFontFormats } from '../../config';
import getConfig from '../../getConfig';
import MediaSelectionModal from '../../features/mediaLibrary/MediaSelectionModal';
import { withFormUtils } from '../../hoc/withFormUtils';

import { getGoogleFontsString, getGoogleFontsUrl } from '../../utils/fonts';

const propTypes = {
    props: PropTypes.object
};

const TinyMCEEditor = (
    props,
) => {
    const apiKey = getConfig().TINYMCE_API_KEY;
    const { value, name, className, generateCustomButtons, customSettings, customStyles, charControlHandler, fonts = [] } = props;
    const [isOpen, setIsOpen] = useState(false);

    const editorRef = useRef(null);

    const settings = customSettings ? customSettings : fullyFeaturedConfig;

    const handleOnChange = (event, editor) => {
        if (charControlHandler) {
            charControlHandler(editor);
        }
        props.onChange(event);
    };
    const addImageFromMediaLibrary = file => {
        const lang = props.utils.currentLanguage();
        file.map(item => {
            const html = getTranslationForIso(item.html, lang);
            editorRef.current.editor.insertContent(html, { format: 'raw'});
        });
        setIsOpen(false);
    };

    const generateCustomStylesObject = fonts => {
        const urlString = `@import url('${getGoogleFontsUrl(fonts)}); ${(customStyles ? customStyles + ";" : "")} .customVariable {color: #fff; background-color: #0275d8; padding: 3px; border-radius: 3px;};`;
        return ({
            content_style: urlString,
            fontsize_formats:
                '4px 5px 6px 7px 8px 9px 10px 11px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px '+
                '1vw 2vw 3vw 4vw 5vw 6vw 7vw 8vw 9vw 10vw 11vw 12vw 13vw 14vw 15vw 16vw '+
                '10% 20% 30% 40% 50% 60% 70% 80% 90% 100% 110% 120% 130% 140% 150% 160% 170% 180% 190% 200% 210% 220% 230% 240% 250% 260% 270% 280% 290% 300%',
            font_formats: basicFontFormats + getGoogleFontsString(fonts),
        });
    };

    return (
        <div className={className}>
            <Editor
                ref={editorRef}
                initialValue={value}
                name={name}
                onEditorChange={(event, editor) => handleOnChange(event, editor)}
                key={`${props.name}`}
                apiKey={apiKey}
                init={{
                    ...settings,
                    ...generateCustomStylesObject(fonts),
                    toolbar: `${'mediaLibrary'} | ${generateCustomButtons && 'myButton'} | ${settings.toolbar}`,
                    setup: editor => {
                        if (generateCustomButtons) {generateCustomButtons(editor);}
                        editor.ui.registry.addButton('mediaLibrary', {
                            icon: 'gallery',
                            onAction: function () {
                                setIsOpen(true);
                            }
                        });
                    },
                }}
            />
            <MediaSelectionModal defaultItemsChecked={[]} isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={addImageFromMediaLibrary} />
        </div>
    );
};

TinyMCEEditor.propTypes = propTypes;

const mapStateToProps = state => ({
    fonts: state.fonts.fonts,
});

export default withFormUtils(connect(mapStateToProps, null)(TinyMCEEditor));