import React from 'react';
import PropTypes from 'prop-types';

import SearchForm from '../../../../components/generators/SearchForm';

const propTypes = {
    handleSubmit: PropTypes.func,
    translations: PropTypes.object,
    searchConfig: PropTypes.object.isRequired,
};

const ProductStatsSearchForm = ({ handleSubmit, translations, searchConfig }) => {

    const onSubmit = formData => {
        handleSubmit(formData);
    };

    return (
        <SearchForm
            searchConfig={searchConfig}
            translations={{
                buttons: {
                    reset: translations.buttons.reset,
                    submit: translations.buttons.submit,
                }
            }}
            inputs={[
                {
                    label: translations.headers.name,
                    name: 'Name',
                    type: 'text'
                },
                {
                    label: translations.headers.catalogueIndex,
                    name: 'catalogueIndex',
                    type: 'text'
                },
            ]}
            performSearch={onSubmit}
        />
    );
};

ProductStatsSearchForm.propTypes = propTypes;

export default ProductStatsSearchForm;