import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import useFetch from '../../../hooks/useFetch';

import { getShippingFormsGeneral } from '../../../redux/methods/shippingForms';

import ShippingVariantsTable from '../../../components/tables/ShippingVariantsTable';
import ShippingWeightsTable from '../../../components/tables/ShippingWeightsTable';
import ShippingFormsTable from '../../../components/tables/ShippingFormsTable';

import { Button } from 'reactstrap';

const propTypes = {
    getShippingFormsGeneral: PropTypes.func,
};

const ShipmentMethodsSettings = ({getShippingFormsGeneral}) => {
    const intl = useIntl();
    const translations = intl.messages.shippingForms;

    const [ data ] = useFetch(getShippingFormsGeneral);

    const [shippingVariantId, setShippingVariantId] = useState(null);
    const [shippingVariantWeightId, setShippingVariantWeightId] = useState(null);

    useEffect(() => {
        if (typeof(data) !== 'undefined') {
            if (!data.shippingFormsUseVariants) {
                setShippingVariantId(1);
            }
            if (!data.shippingFormsUseWeights) {
                setShippingVariantWeightId(1);
            }
        }
    }, [data]);

    const nextLevelForVariantsRowAction = {
        tag: 'nextLevelForVariantsRowAction',
        icon: 'fa fa-table fa-lg',
        tooltip: data && data.shippingFormsUseWeights
            ? translations.tooltips.goToWeights
            : translations.tooltips.goToForms,
        disabled: () => false,
        execute: (item) => {
            setShippingVariantId(item.id);
            setShippingVariantWeightId(data.shippingFormsUseWeights ? null : item.shippingVariantWeightId);
        }
    };

    const nextLevelForWeightsRowAction = {
        tag: 'nextLevelForWeightsRowAction',
        icon: 'fa fa-table fa-lg',
        tooltip: translations.tooltips.goToForms,
        disabled: () => false,
        execute: (item) => {
            setShippingVariantWeightId(item.id);
        }
    };

    const handleBackToVariantsList = () => {
        setShippingVariantId(null);
        setShippingVariantWeightId(data.shippingFormsUseWeights ? null : 1);
    };

    const handleBackToWeightsList = () => {
        setShippingVariantWeightId(null);
    };

    return data ? (
        <div>
            <div className='m-2 ml-3'>
                <div className='ml-3'>
                    { data.shippingFormsUseVariants && shippingVariantId &&
                        <Button className='mr-2' color='primary' onClick={handleBackToVariantsList}>
                            <i className='fa fa-chevron-left mr-2'/>
                            <span>{translations.buttons.backToVariantsList}</span>
                        </Button>
                    }
                    { data.shippingFormsUseWeights && shippingVariantWeightId &&
                        <Button className='mr-2'  color='primary' onClick={handleBackToWeightsList}>
                            <i className='fa fa-chevron-left mr-2'/>
                            <span>{translations.buttons.backToWeightsList}</span>
                        </Button>
                    }
                </div>
            </div>
            {
                shippingVariantId !== null && shippingVariantWeightId !== null
                    ? <ShippingFormsTable shippingVariantId={shippingVariantId} shippingVariantWeightId={shippingVariantWeightId}/>
                    : (shippingVariantId !== null
                        ? <ShippingWeightsTable shippingVariantId={shippingVariantId} nextLevelForWeightsRowAction={nextLevelForWeightsRowAction}/>
                        : <ShippingVariantsTable nextLevelForVariantsRowAction={nextLevelForVariantsRowAction}/>
                    )
            }
        </div>
    ) : null;
};

const mapDispatch = {
    getShippingFormsGeneral
};

ShipmentMethodsSettings.propTypes = propTypes;

export default connect(null, mapDispatch)(ShipmentMethodsSettings);