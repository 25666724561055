import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectStoreAvailableIsoList } from '../../../../../redux/selectors/storeLocale';
import { getAttributeDictionaryValues, updateAttributeDictionaryValue, deleteAttributeDictionaryValue, updateAttributeDictionaryValueActivity } from '../../../../../redux/methods/attributes';

import AttributeDictionaryValuesTable from '../../../../../components/tables/AttributeDictionaryValuesTable';
import EditModal from '../../../../../components/EditNameModal';

import { Container, Button } from 'reactstrap';

import useFetch from '../../../../../hooks/useFetch';
import { createEmptyNameEditObject } from '../../../../../utils/locale';

import styles from './index.module.scss';

const propTypes = {
    attributeId: PropTypes.number,
    forceRefetch: PropTypes.bool,
    storeAvailableIsoList: PropTypes.arrayOf(PropTypes.string),
    getAttributeDictionaryValues: PropTypes.func,
    updateAttributeDictionaryValue: PropTypes.func,
    deleteAttributeDictionaryValue: PropTypes.func,
    updateAttributeDictionaryValueActivity: PropTypes.func
};

const DictionaryValues = ({
    attributeId, forceRefetch, storeAvailableIsoList,
    getAttributeDictionaryValues,
    updateAttributeDictionaryValue, deleteAttributeDictionaryValue, updateAttributeDictionaryValueActivity
}) => {
    const intl = useIntl();
    const translations = intl.messages.attributes.edit.tabs.values.dictionaryValues;

    const [ data ] = useFetch(getAttributeDictionaryValues, attributeId, [], forceRefetch);

    const [ dictionaryValueToEdit, setDictionaryValueToEdit ] = useState(null);

    const handleAddAction = () => {
        setDictionaryValueToEdit({
            attributeId: attributeId,
            attributeDictionaryId: null,
            name: createEmptyNameEditObject(storeAvailableIsoList)
        });
    };

    const handleEditAction = id => {
        setDictionaryValueToEdit(data.find(d => d.attributeDictionaryId === id));
    };

    const handleRemoveAction = id => {
        deleteAttributeDictionaryValue(id);
    };

    const handleUpdateActiveAction = data => {
        updateAttributeDictionaryValueActivity(data);
    };

    return data ? (
        <React.Fragment>
            <Container fluid>
                <main>
                    <AttributeDictionaryValuesTable
                        data={data}
                        columnNames={translations.columnNames}
                        tooltipTranslations={translations.actions}
                        deleteModalTranslations={translations.deleteModal}
                        emptyTranslations={translations.empty}
                        editAction={handleEditAction}
                        removeAction={handleRemoveAction}
                        updateActiveAction={handleUpdateActiveAction}
                    />
                </main>
                <aside className={styles.footer}>
                    <Button color="primary" onClick={handleAddAction}>
                        <i className="fa fa-plus mr-2"/>
                        <span>{translations.actions.addColor}</span>
                    </Button>
                </aside>
            </Container>
            {
                dictionaryValueToEdit && <EditModal
                    data={dictionaryValueToEdit}
                    header={translations.inputs.name}
                    isEditModalOpen={dictionaryValueToEdit ? true : false}
                    setIsEditModalOpen={setDictionaryValueToEdit}
                    onSubmit={updateAttributeDictionaryValue}
                />
            }
        </React.Fragment>
    ) : null;
};

DictionaryValues.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.attributesDictionaryValues,
    storeAvailableIsoList: selectStoreAvailableIsoList(state),
});

const mapDispatch = {
    getAttributeDictionaryValues,
    updateAttributeDictionaryValue,
    deleteAttributeDictionaryValue,
    updateAttributeDictionaryValueActivity
};

export default connect(mapStateToProps, mapDispatch)(DictionaryValues);