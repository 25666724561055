const locale = {
    availableLanguages: [
        {
            iso: 'en',
            name: 'English'
        },
        {
            iso: 'pl',
            name: 'Polish'
        }
    ]
};

export default locale;