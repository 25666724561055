import React from 'react';
import PropTypes from 'prop-types';

import { notificationsSearchConfig } from '../../../config/searchConfigs';

import SearchForm from '../../../components/generators/SearchForm';

const propTypes = {
    translations: PropTypes.object,
    onSubmit: PropTypes.func,
    moduleOptions: PropTypes.array,
    typeOptions: PropTypes.array,
    searchConfig: PropTypes.object.isRequired,
};

const NotificationsSearchForm = ({
    translations,
    onSubmit,
    moduleOptions = [],
    typeOptions = [],
    searchConfig,
}) => {
    return (
        <SearchForm
            searchConfig={searchConfig}
            defaultSearchConfig={{...notificationsSearchConfig}}
            translations={{
                buttons: {
                    submit: translations.buttons.search,
                    reset: translations.buttons.reset,
                }
            }}
            inputs={[
                {
                    type: 'text',
                    label: translations.inputs.details,
                    name: 'details'
                },
                {
                    type: 'dropdown',
                    label: translations.dropdowns.type,
                    name: 'typeId',
                    options: typeOptions,
                    defaultLabel: translations.dropdowns.defaultDropdownOption
                },
                {
                    type: 'dropdown',
                    label: translations.dropdowns.module,
                    name: 'moduleId',
                    options: moduleOptions,
                    defaultLabel: translations.dropdowns.defaultDropdownOption
                },
                {
                    type: 'checkbox',
                    label: translations.inputs.onlyIsError,
                    name: 'onlyIsError'
                }
            ]}
            performSearch={onSubmit}
        />
    );
};

NotificationsSearchForm.propTypes = propTypes;

export default (NotificationsSearchForm);