/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { editRegionName } from '../../../../redux/methods/regions';

import { Form as FinalForm, Field } from 'react-final-form';
import { Container, Form, Row, Input } from 'reactstrap';

import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';

import { stringOrNumberPropTypes } from '../../../../propTypes';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    data: PropTypes.shape({
        regionname: PropTypes.string,
        regionId: stringOrNumberPropTypes,
    }),
    editRegionName: PropTypes.func,
};

const RegionsEditName = ({ data, editRegionName }) => {
    const intl = useIntl();
    const translations = intl.messages.regions.edit.tabs.name;

    const handleOnSubmit = formData => {
        editRegionName({...formData, name: formData.name});
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    initialValues={data}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Container fluid className='p-3 px-0'>
                                        <React.Fragment>
                                            <span>{translations.label}</span>
                                            <Field name='name'>
                                                {
                                                    props =>
                                                        <Input
                                                            className='mt-1'
                                                            type='text'
                                                            name={props.input.name}
                                                            value={props.input.value}
                                                            onChange={props.input.onChange}
                                                        />
                                                }
                                            </Field>
                                        </React.Fragment>
                                    </Container>
                                </Row>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

RegionsEditName.propTypes = propTypes;

const mapDispatch = {
    editRegionName
};

export default withFormUtils(connect(null, mapDispatch)(RegionsEditName));