import React, { useState } from 'react';

import { Button } from 'reactstrap';

import ColorPicker from '../ColorPicker';

import styles from './index.module.scss';

const SimpleColorPicker = ({ value, onSelect }) => {
    const [ isColorPickerVisible, setIsColorPickerVisible ] = useState(false);

    const handleShowColorPicker = () => {
        setIsColorPickerVisible(true);
    };

    const handleHideColorPicker = () => {
        setIsColorPickerVisible(false);
    };

    const getContrast50 = (hexcolor) => {
        return hexcolor === 'transparent' ? 'black' : (parseInt(hexcolor.substring(1), 16) > 0xffffff/2) ? 'black':'white';
    };

    const getBackground = (hexcolor) => {
        if (hexcolor === 'transparent') {
            return {
                background: 'repeating-linear-gradient(45deg, #d0d0d0 0px, #d0d0d0 4px, #f0f0f0 4px, #f0f0f0 8px, #d0d0d0 8px)',
                color: 'black'
            };
        }
        return {
            background: value ? value : '#FFFFFF',
            color: getContrast50(value ?? '#FFFFFF')
        };
    };

    return (
        <div className={styles.buttonContainer}>
            <span>
                <Button style={getBackground(value)} type="button" onClick={handleShowColorPicker} title={value}>
                    <i className="fa fa-paint-brush" title={value}/>
                </Button>
                <Button style={{width: '24px', height: '24px', padding: '0', margin: '4px'}} color="danger" type="button" onClick={() => onSelect('transparent')}>
                    <i className="fa fa-times"/>
                </Button>
                {
                    isColorPickerVisible &&
                        <ColorPicker onAdd={onSelect} color={value} onClose={handleHideColorPicker}/>
                }
            </span>
        </div>
    );
};

export default SimpleColorPicker;