import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { updateProductDelivery, getProductDelivery } from '../../../../redux/methods/products';

import useFetch from '../../../../hooks/useFetch';
import { withFormUtils } from '../../../../hoc/withFormUtils';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';

import {  stringOrNumberPropTypes } from '../../../../propTypes';

const propTypes = {
    id: stringOrNumberPropTypes,
    updateProductDelivery: PropTypes.func,
    getProductDelivery: PropTypes.func,
    forceRefetch: PropTypes.bool,
    isConnectedToERP: PropTypes.bool,
};

const ProductEditDelivery = ({ id, forceRefetch, updateProductDelivery, getProductDelivery, isConnectedToERP }) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.delivery;

    const [ deliveryData ] = useFetch(getProductDelivery, { id: id }, [], forceRefetch);

    const handleOnSubmit = formData => {
        updateProductDelivery({
            ...formData,
            productId: parseInt(id)
        });
    };

    return deliveryData ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    initialValues={deliveryData}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='pt-3 px-0' fluid>
                                    <Row className='mt-2'>
                                        <FormFieldTextOrNumber
                                            label={translations.fields.deliveryCost}
                                            name='deliveryCost'
                                            type='number'
                                            min={0}
                                            step='0.01'
                                            parse={value => value ? parseFloat(value) : null}
                                            disabled={isConnectedToERP && values.deliveryCostIsSynchronized}
                                        />
                                    </Row>
                                    <Row>
                                        {isConnectedToERP && values.deliveryCostIsSynchronized &&
                                            <small className='text-danger'>{translations.fromErp}</small>
                                        }
                                    </Row>
                                    <Row className='mt-2'>
                                        <FormFieldTextOrNumber
                                            label={translations.fields.deliveryTime}
                                            name='deliveryTime'
                                            type='number'
                                            min={0}
                                            max={365}
                                            step='1'
                                            parse={value => value ? parseInt(value) : null}
                                            disabled={isConnectedToERP && values.deliveryTimeIsSynchronized}
                                        />
                                    </Row>
                                    <Row>
                                        {isConnectedToERP && values.deliveryTimeIsSynchronized &&
                                            <small className='text-danger'>{translations.fromErp}</small>
                                        }
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

ProductEditDelivery.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.productDelivery,
    isConnectedToERP: state.appInit.isConnectedToERP,
});

const mapDispatch = {
    getProductDelivery,
    updateProductDelivery,
};

export default withRouter(withFormUtils(connect(mapStateToProps, mapDispatch)(ProductEditDelivery)));