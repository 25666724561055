import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';

import styles from './index.module.scss';
import { stringOrNumberPropTypes } from '../../propTypes';

const propTypes = {
    label: PropTypes.string,
    currentSelectionLabel: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
            label: PropTypes.string,
            className: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ])
        })
    ),
    isDisabled: PropTypes.bool,
    setCheckedIds: PropTypes.func,
    checkedIds: PropTypes.oneOfType([PropTypes.arrayOf(stringOrNumberPropTypes), PropTypes.string]),
    classNames: PropTypes.string
};

const SearchableDropdownWithCheckboxes = ({ label, currentSelectionLabel, options, isDisabled, classNames, checkedIds, setCheckedIds }) => {
    const [ searchPhrase, setSearchPhrase ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);

    const onChangeSearchPhrase = e => {
        setSearchPhrase(e.target.value);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handlePerformSelection = (event, id) => {
        event.stopPropagation();
        setCheckedIds(id);
    };

    return (
        <div className={clsx(styles.container, classNames)}>
            <span>{label}</span>
            <Dropdown
                direction="down"
                className={styles.dropdown}
                isOpen={isOpen}
                toggle={handleToggle}
                disabled={isDisabled}
            >
                <DropdownToggle caret className={styles.dropdownToggler} disabled={isDisabled}>
                    {currentSelectionLabel}
                </DropdownToggle>
                <DropdownMenu className={styles.dropdownMenu}>
                    <input
                        className={styles.search}
                        value={searchPhrase}
                        onChange={onChangeSearchPhrase}
                        type="text"
                    />
                    {
                        options
                            .filter(option =>
                                option.id === null || (option.label && option.label.toLowerCase().includes(searchPhrase.toLowerCase()))
                            )
                            .map(option =>
                                <DropdownItem
                                    toggle={false}
                                    key={`selector-${label}-${option.id}`}
                                    className={styles.dropdownItem}
                                    onClick={(e) => handlePerformSelection(e, option.id)}
                                >
                                    <Input
                                        checked={Array.isArray(checkedIds) ? checkedIds.includes(option.id) : false}
                                        type='checkbox'
                                        onChange={(e) => handlePerformSelection(e, option.id)}
                                        className={'position-static m-0 p-0 mr-2'}
                                    />
                                    <span className={option.className}>{option.label}</span>
                                </DropdownItem>
                            )
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

SearchableDropdownWithCheckboxes.propTypes = propTypes;

export default SearchableDropdownWithCheckboxes;