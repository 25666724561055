export const translateIso = iso => {
    const upperCaseIso = iso?.toUpperCase();

    switch (upperCaseIso) {
        case 'EN': return 'GB';
        default: return upperCaseIso;
    }
};

export const getLanguageName = (availableLanguages, iso) =>
    availableLanguages.find(languageObject => languageObject.iso === iso)?.name
;

export const translateLanguageOptionsList = languageOptionsList =>
    languageOptionsList.map(
        language => ({
            id: language.id,
            iso: language.iso.toLowerCase()
        })
    )
;

export const addIdsToAvailableLanguagesTranslations = (availableLanguagesTranslations, availableLanguages) => {
    return availableLanguagesTranslations.map(languageTranslationObject => ({
        ...languageTranslationObject,
        id: availableLanguages.find(languageObject => languageObject.iso === languageTranslationObject.iso)?.id
    }));
};

export const createEmptyNameEditObject = (availableLanguages) => {
    const translations = [];
        for(const iso in availableLanguages)
            {translations.push({iso: availableLanguages[iso], value: null});}
    return translations;
};