import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';

import EditStatusControl from '../../EditStatusControl';

import { dropdownStatusPropTypes } from '../../../propTypes';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    statuses: PropTypes.arrayOf(dropdownStatusPropTypes),
    selectedStatus: dropdownStatusPropTypes,
    forcedColor: PropTypes.string,
    disabled: PropTypes.bool,
};

const FormFieldStatus = ({ name, label, statuses, selectedStatus, forcedColor, disabled }) => {
    return (
        <Field name={name}>
            {
                props =>
                    <EditStatusControl
                        selectedStatus={selectedStatus}
                        selectedStatusId={props.input.value}
                        setSelection={props.input.onChange}
                        statuses={statuses}
                        label={label}
                        forcedColor={forcedColor}
                        disabled={disabled}
                    />
            }
        </Field>
    );
};

FormFieldStatus.propTypes = propTypes;

export default FormFieldStatus;