import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getAdBanner } from '../../redux/methods/adBanners';

import useFetch from '../../hooks/useFetch';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';

import AdBannersEditBasic from '../../features/adBanners/editTabs/AdBannersEditBasic';
import AdBannersEditCategories from '../../features/adBanners/editTabs/AdBannersEditCategories';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getAdBanner: PropTypes.func,
};

const AdBannersEdit = ({ location, getAdBanner, forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.adBanners.edit;
    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);
    const chosenAdBannerId = query.get('adBannerID');

    const [ data ] = useFetch(getAdBanner, chosenAdBannerId, [], forceRefetch);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'AdBannerEditTab-basic':
                return <AdBannersEditBasic data={data} adBannerId={chosenAdBannerId}/>;
            case 'AdBannerEditTab-regions':
                return <AdBannersEditCategories adBannerId={chosenAdBannerId}/>;
            default:
                return null;
        }
    };

    return chosenAdBannerId ? (
        data ? (
            <SettingsTabulator
                title={{
                    label: data.name || translations.fallbackName,
                    iconClassName: 'fa fa-edit text-primary ml-3',
                    isFilled: data.name ? false : true,
                }}
                tabulatorHeader={{
                    label: translations.tabSelectorTitle,
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: 'AdBannerEditTab-basic',
                        header:  {
                            label: translations.tabs.basic.title,
                            iconClassName: 'fa fa-tasks'
                        }
                    },
                    {
                        id: 'AdBannerEditTab-regions',
                        header:  {
                            label: translations.tabs.categories.title,
                            iconClassName: 'fa fa-flag'
                        },
                        disabled: chosenAdBannerId < 0
                    },
                ]}
            />
        ) : null
    ) : (
        <Redirect to='/adBanners'/>
    );
};

AdBannersEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.adBanners,
});

const mapDispatch = {
    getAdBanner,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(AdBannersEdit));