import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Modal from '../Modal';

const DynamicConfirmationModal = ({ afterConfirm, header, caption }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.confirmationModal;

    const [ isOpen, setIsOpen ] = useState(true);

    const handleAfterConfirm = () => {
        setIsOpen(false);
        afterConfirm();
    };

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className='modal-danger'
            header={(header === null ? translations.header : header)}
            body={(caption === null ? translations.caption : caption)}
            buttons={{
                confirm: {
                    color: 'danger',
                    label: translations.confirm
                },
                cancel: {
                    color: 'secondary',
                    label: translations.cancel
                }
            }}
            onConfirm={handleAfterConfirm}
        />
    );
};

export default DynamicConfirmationModal;