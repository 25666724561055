import React from 'react';
import PropTypes from 'prop-types';

import { generateDropdownOptionsForCurrencies } from '../../utils/components';

import SearchForm from '../generators/SearchForm';

import { currencyPropTypes,  statusConfigPropTypes, orderAttributesConfigPropTypes } from '../../propTypes';

const propTypes = {
    onSubmit: PropTypes.func,
    translations: PropTypes.shape({
        ordersSearch: PropTypes.shape({
            number: PropTypes.string,
            contrahentName: PropTypes.string,
            dateFrom: PropTypes.string,
            dateTo: PropTypes.string,
            currency: PropTypes.string,
            status: PropTypes.string,
            defaultDropdownOption: PropTypes.string,
            attribute: PropTypes.string,
        }),
        searchButton: PropTypes.string,
        resetButton: PropTypes.string,
    }),
    currencies: PropTypes.arrayOf(currencyPropTypes),
    storeCurrentLanguage: PropTypes.string,
    statusOptions: statusConfigPropTypes,
    attributesOptions: orderAttributesConfigPropTypes,
    searchConfig: PropTypes.object,
};

const OrdersSearchForm = ({ onSubmit, translations, storeCurrentLanguage, currencies, statusOptions, attributesOptions, searchConfig }) => {
    return (
        <SearchForm
            searchConfig={searchConfig}
            translations={{
                buttons: {
                    submit: translations.searchButton,
                    reset: translations.resetButton,
                }
            }}
            inputs={[
                {
                    type: 'text',
                    label: translations.ordersSearch.number,
                    name: 'orderNumber',
                },
                {
                    type: 'text',
                    label: translations.ordersSearch.contrahentName,
                    name: 'contrahentName',
                },
                {
                    type: 'text',
                    label: translations.ordersSearch.contrahentEmail,
                    name: 'email',
                },
                {
                    label: translations.ordersSearch.dateFrom,
                    name: 'dateFrom',
                    type: 'date',
                    storeCurrentLanguage: storeCurrentLanguage,
                },
                {
                    label: translations.ordersSearch.dateTo,
                    name: 'dateTo',
                    type: 'date',
                    storeCurrentLanguage: storeCurrentLanguage,
                    minDateDependentField: 'dateFrom',
                },
                {
                    type: 'dropdown',
                    label: translations.ordersSearch.currency,
                    name: 'currencyId',
                    options: generateDropdownOptionsForCurrencies(currencies, translations.ordersSearch.defaultDropdownOption),
                    defaultLabel: translations.ordersSearch.defaultDropdownOption
                },
                {
                    type: 'dropdown',
                    label: translations.ordersSearch.status,
                    name: 'orderStatusId',
                    options: statusOptions,
                    defaultLabel: translations.ordersSearch.defaultDropdownOption
                },
                {
                    type: 'dropdown',
                    label: translations.ordersSearch.attribute,
                    name: 'orderAttributeId',
                    options: attributesOptions,
                    defaultLabel: translations.ordersSearch.defaultDropdownOption
                },
            ]}
            performSearch={onSubmit}
        />
    );
};

OrdersSearchForm.propTypes = propTypes;

export default (OrdersSearchForm);