import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { getMediaFolderContent, deleteMediaFiles, getSearchMediaFiles } from '../../redux/methods/mediaLibrary';

import Paginator from '../../components/Paginator';
import ContainerWithCard from '../../components/ContainerWithCard';
import { Button, ButtonGroup, Col, Row, Breadcrumb, BreadcrumbItem, Alert } from 'reactstrap';
import MediaList from './MediaList';
import useFetch from '../../hooks/useFetch';
import useSearchConfig from '../../hooks/useSearchConfig';
import { mediaLibrarySearchConfig } from '../../config/searchConfigs';
import MediaSearchForm from '../../features/mediaLibrary/MediaSearchForm';
import getConfig from '../../getConfig';

const propTypes = {
    getMediaFolderContent: PropTypes.func,
    forceRefetch: PropTypes.bool,
    deleteMediaFiles: PropTypes.func,
    getSearchMediaFiles: PropTypes.func,
};

const initialState = {
    folders: [],
    files: [],
    breadcumps: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'setFileName': return {
            ...state,
            files: state.files.map(file => {
                if(file.id === action.payload.id) {
                    return {
                        ...file,
                        fileName: action.payload.fileName,
                    };
                } else {
                    return file;
                }
            })
        };
        case 'setFiles': return { ...state, files: action.payload };
        case 'setFile': return {
            ...state,
            files: state.files.map(file => {
                if(file.id === action.payload.id) {
                    return {
                        ...action.payload.file,
                        percentage: null,
                    };
                } else {
                    return file;
                }
            })
        };
        case 'addFile': return { ...state, files: [ ...state.files, { percentage: 1, ...action.payload } ]};
        case 'setFolders': return { ...state, folders: action.payload};
        case 'changePercentageById': return {
            ...state,
            files: state.files.map(file => {
                if(file.id === action.payload.id) {
                    return {
                        ...file,
                        percentage: action.payload.percentage,
                    };
                } else {
                    return file;
                }
            })
        };
        case 'setBreadcumps': return { ...state, breadcumps: action.payload };

        default: throw new Error();
    }
};

const MediaLibrary = ({ getMediaFolderContent, forceRefetch, deleteMediaFiles, getSearchMediaFiles }) => {
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;
    const translations = useIntl().messages.mediaLibrary;

    const [ mediaLibraryConfig, changePage, changeConfig ] = useSearchConfig(mediaLibrarySearchConfig);
    const [ media, pages ] = useFetch(mediaLibraryConfig.fileName !== "" || mediaLibraryConfig.mediaType !== 1 ? getSearchMediaFiles : getMediaFolderContent, mediaLibraryConfig, [mediaLibraryConfig], forceRefetch);

    const [editMode, setEditMode] = useState(false);
    const [checked, setChecked] = useState([]);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        if(media) {
            dispatch({ type: 'setFiles', payload: media.rows2 || media || [] });
            dispatch({ type: 'setFolders', payload: media.rows1 || [] });
            dispatch({ type: 'setBreadcumps', payload: media.rows3 || [] });
        }
    }, [media]);

    const toggleMode = () => {
        setEditMode(prev => !prev);
        setChecked([]);
    };

    const handleSelectAll = () => {
        setChecked(state.files.map(({ id }) => id));
    };

    const handleUnselectAll = () => {
        setChecked([]);
    };

    const refreshMedia = () => {
        changeConfig(mediaLibraryConfig);
    };

    const handleRemoveChecked = async () => {
        await deleteMediaFiles({ fileIds: checked });
        refreshMedia();
        setChecked([]);
    };

    const fileInputRef = React.createRef(null);

    const handleFileUpload = async e => {
        setSending(true);
        const {files} = e.target;

        const mappedFiles = [];

        for(const file of files) {
            const tempId =  uuidv4();

            const obj = { id: tempId, image: URL.createObjectURL(file) };

            dispatch({ type: 'addFile', payload: obj });

            mappedFiles.push({ ...obj, file: file });
        }

        for(const file of mappedFiles) {
            const data = new FormData();
            data.append('fileContent', file.file);

            if(mediaLibraryConfig.folderId) {
                data.append('folderId', mediaLibraryConfig.folderId);
            }

            const config = {
                onUploadProgress: function(progressEvent) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                    dispatch({ type: 'changePercentageById', payload: { id: file.id, percentage: percentCompleted }});
                },
                withCredentials: true
            };

            try {
                await axios.post(
                    `${apiUrl}/Admin/Media/File/Create`,
                    data,
                    config,
                );
            } catch (error) {
                dispatch({ type: 'changePercentageById', payload: { id: file.id, percentage: null }});
            }
            dispatch({ type: 'changePercentageById', payload: { id: file.id, percentage: null }});
        }
        changeConfig(mediaLibraryConfig);
        setSending(false);
    };

    const handleOnFileSendClick = () => {
        fileInputRef.current.click();
    };

    const handleChangeFolder = id => {
        changeConfig({ ...mediaLibraryConfig, folderId: id});
    };

    const handleBreadcumpClick = id => () => {
        changeConfig({ ...mediaLibrarySearchConfig, folderId: id });
    };

    const changeFileName = (id, fileName) => {
        dispatch({ type: 'setFileName', payload: { id, fileName } });
    };

    return media ? (
        <ContainerWithCard title={translations.title} iconClass='cil-media-play' >
            <Col>
                <Row className='mb-3'>
                    <MediaSearchForm
                        disabled={editMode || sending}
                        performSearch={changeConfig}
                        searchConfig={mediaLibraryConfig}
                    />
                </Row>
                {!mediaLibraryConfig.folderId?.startsWith('Virtual') && (
                    <Row className='my-3 d-flex flex-column flex-md-row'>
                        <input multiple type='file' className='d-none' ref={fileInputRef} onInput={handleFileUpload} />
                        <Button
                            disabled={editMode || sending || mediaLibraryConfig.fileName !== "" || mediaLibraryConfig.mediaType !== 1}
                            onClick={handleOnFileSendClick}
                            className='mr-md-1'
                            color='success'>
                                {translations.buttons.add}
                        </Button>
                        <Button disabled={sending} onClick={toggleMode} className='mr-md-1' color='primary'>
                            {translations.buttons.mode[editMode ? 'normal' : 'edit']}
                        </Button>
                        <ButtonGroup className='mt-3 mt-md-0'>
                            <Button onClick={handleSelectAll} disabled={!editMode || sending} >{translations.buttons.selectAll}</Button>
                            <Button onClick={handleUnselectAll} disabled={!editMode || sending} >{translations.buttons.unselectAll}</Button>
                        </ButtonGroup>
                        <Button className='ml-md-1' onClick={handleRemoveChecked} disabled={!editMode || checked.length === 0 || sending} color='danger'>
                            {translations.buttons.deleteAll}
                        </Button>
                    </Row>
                )}
                <Alert color="warning" isOpen={mediaLibraryConfig.folderId && mediaLibraryConfig.folderId.startsWith('Virtual')}>
                    {translations.blueFolderWarning}
                </Alert>
                <Row>
                    {state.breadcumps.length > 0 &&
                        <div className='w-100'>
                            <Breadcrumb>
                            <BreadcrumbItem>
                                <Button disabled={mediaLibraryConfig.folderId === null} color="link" onClick={handleBreadcumpClick(null)}>
                                    {translations.firstBreadcump}
                                </Button>
                            </BreadcrumbItem>
                            {state.breadcumps.map(breadcump =>
                                <BreadcrumbItem key={breadcump.id} active={false}>
                                    <Button disabled={mediaLibraryConfig.folderId === breadcump.id} color="link" onClick={handleBreadcumpClick(breadcump.id)}>
                                        {breadcump.name}
                                    </Button>
                                </BreadcrumbItem>
                            )}
                            </Breadcrumb>
                        </div>
                    }
                    <MediaList
                        editMode={editMode}
                        checked={checked}
                        setChecked={setChecked}
                        files={state.files}
                        folders={state.folders}
                        changeFolder={handleChangeFolder}
                        searchInput={mediaLibrarySearchConfig.fileName}
                        folderId={mediaLibraryConfig.folderId}
                        refreshMedia={refreshMedia}
                        changeFileName={changeFileName}
                    />
                </Row>
                {
                    pages > 1 &&
                        <Paginator
                            totalPages={pages}
                            currentPage={mediaLibraryConfig.curentPage + 1}
                            onPageChange={changePage}
                        />
                }
            </Col>
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

MediaLibrary.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.mediaLibrary,
});

const mapDispatch = {
    getMediaFolderContent,
    deleteMediaFiles,
    getSearchMediaFiles,
};

export default connect(mapStateToProps, mapDispatch)(MediaLibrary);