import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { Field } from 'react-final-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import TextInputWithFlags from '../../TextInputWithFlags';

import { firstLetterToUpperCase, getIsoFromTranslation } from '../../../utils/components';

import styles from './index.module.scss';

const propTypes = {
    propertyName: PropTypes.string,
    label: PropTypes.string,
    modalHeader: PropTypes.string,
    currentIso: PropTypes.string,
    form: PropTypes.object,
    type: PropTypes.string,
    withRightMargin: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

const FormFieldTranslations = ({ form, modalHeader, propertyName, currentIso, label, type = 'text', withRightMargin = true, disabled = false, className }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.editFormModalWithTextInputFlags;

    const [ isOpen, setIsOpen ] = useState(false);

    const findTranslationInputs = (initialValues, propertyName) => {
        if (propertyName.includes('.')) {
            const prefix = propertyName.substring(0, propertyName.indexOf('.'));
            const suffix = propertyName.substring(propertyName.indexOf('.') + 1);
            return findTranslationInputs(initialValues[prefix], suffix);
        }
        else {
            return Object.keys(initialValues)
                .reduce((acc, curr) => curr.includes(propertyName + '-') ? { ...acc, [curr]: initialValues[curr] } : acc, {});
        }
    };

    const translationsInputs = findTranslationInputs(form.getState().initialValues, propertyName);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const fieldName = `${propertyName}-${firstLetterToUpperCase(currentIso)}-translations`;
    const fieldKey = `${propertyName.replaceAll('.','_')}-${firstLetterToUpperCase(currentIso)}-translations`;

    const availableIsoList = Object.keys(translationsInputs).map(key => getIsoFromTranslation(key));

    return (
        <React.Fragment>
            <Field key={fieldKey} name={fieldName}>
                {
                    props =>
                        <TextInputWithFlags
                            disabled={disabled}
                            id={fieldKey}
                            label={label}
                            className={clsx('w-100', withRightMargin && 'mr-md-3', className)}
                            chosenIso={currentIso}
                            inputProps={props.input}
                            isoList={availableIsoList}
                            onClickAdditionalFlags={toggleModal}
                            type={type}
                        />
                }
            </Field>
            <Modal isOpen={!disabled && isOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>{modalHeader}</ModalHeader>
                <ModalBody className='p-3'>
                    <div className={clsx(styles.formWrapper)}>
                    {
                        Object.keys(translationsInputs)
                            .map(key => {
                                const iso = getIsoFromTranslation(key);
                                const fieldName = `${propertyName}-${firstLetterToUpperCase(iso)}-translations`;
                                return (
                                    <Field key={key} name={fieldName}>
                                        {
                                            props =>
                                                <TextInputWithFlags
                                                    id={key}
                                                    label={label}
                                                    className='w-100 py-2 px-2'
                                                    chosenIso={iso}
                                                    inputProps={props.input}
                                                    type={type}
                                                />
                                        }
                                    </Field>
                                );
                            })
                    }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={toggleModal} >
                        {translations.save}
                    </Button>
                    <Button color='secondary' onClick={toggleModal} >
                        {translations.cancel}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

FormFieldTranslations.propTypes = propTypes;

export default FormFieldTranslations;