import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Dropdown from '../../../../components/Dropdown';
import { Alert } from 'reactstrap';

import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';
import { getTranslationForIso, handleCheckboxAction } from '../../../../utils/components';
import { attributesFeaturesSearchConfig } from '../../../../config/searchConfigs';
import { performAttributeSearch } from '../../../../redux/methods/attributes';
import useFetch from '../../../../hooks/useFetch';

import styles from './index.module.scss';

const propTypes = {
    selectedAttributes: PropTypes.array,
    setSelectedAttributes: PropTypes.func,
    currentLanguage: PropTypes.string,
    selectedCategory: PropTypes.number,
    performAttributeSearch: PropTypes.func,
};

const AttrbiuteSelection = ({
    selectedAttributes,
    setSelectedAttributes,
    currentLanguage,
    selectedCategory,
    performAttributeSearch,
    translations,
    label
}) => {

    const [ attributes ] = useFetch(
        performAttributeSearch,
        {...attributesFeaturesSearchConfig, attributeAffiliation: null, categoryId: selectedCategory },
        [ selectedCategory ]
    );

    const generateAttrbiutesDropdownOptions = (attributes) => {
        return attributes.map(attribute => ({
            id: attribute.id,
            label: getTranslationForIso(attribute.name, currentLanguage)
        }));
    };
    const handleSelection = (id) => {
        handleCheckboxAction(id, selectedAttributes, setSelectedAttributes);
    };
    const attributesOptions = attributes ? generateAttrbiutesDropdownOptions(attributes) : [];

    const generateSelectionLabel = () => {
        if(attributesOptions.length === 0) {
            return translations.noAttributes;
        }
        if (selectedAttributes.length === 0) {
            return translations.selectAttribute;
        }
        else if (selectedAttributes.length === 1) {
            return attributesOptions.find(attribute => attribute.id === selectedAttributes[0]).label;
        }
        else {
            return `${translations.selectedAttribute} ${selectedAttributes.length} / ${attributes.length}`;
        }
    };
    return attributes ? (
        <div className={clsx('mb-3', styles.dropdown)}>
            <span className='mb-3'><strong>{label}</strong></span>
            <Alert isOpen={attributes.length === 0} color='danger'>
                {translations.noAttributesAlert}
            </Alert>
            <Dropdown
                className='mt-3'
                options={attributesOptions}
                checkedIds={selectedAttributes}
                performSelection={handleSelection}
                currentSelectionLabel={generateSelectionLabel()}
                withCheckboxes
            />
        </div>
    ) : null;
};

AttrbiuteSelection.propTypes = propTypes;

const mapStateToProps = state => ({
    currentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    performAttributeSearch
};

export default connect(mapStateToProps, mapDispatch)(AttrbiuteSelection);