import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Modal from '../Modal';

const propTypes = {
    setIsMainModalOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
};

const WarningModal = ({
    setIsMainModalOpen,
    isOpen,
    setIsOpen,
}) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.warningModal;

    const handleOnConfirm = () => {
        setIsOpen(false);
        setIsMainModalOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            header={translations.header}
            body={
                <span>{translations.body}</span>
            }
            buttons={{
                confirm: {
                    label: translations.confirm,
                    color: 'danger',
                },
                cancel: {
                    label: translations.cancel,
                    color: 'success'
                }
            }}
            onConfirm={handleOnConfirm}
        />
    );
};

WarningModal.propTypes = propTypes;

export default WarningModal;

