import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Spinner } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    isLoading: PropTypes.bool
};

const DefaultSpinner = ({ isLoading }) => {
    return (
        <div className={clsx(styles.container, isLoading && styles.containerVisible)}>
            <Spinner />
        </div>
    );
};

DefaultSpinner.propTypes = propTypes;

export default DefaultSpinner;