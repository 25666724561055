import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDiscounts, removeDiscount } from '../../redux/methods/discounts';
import useFetch from '../../hooks/useFetch';
import useSearchConfig from '../../hooks/useSearchConfig';

import { Container, Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';

import Paginator from '../../components/Paginator';
import DiscountsTable from '../../components/tables/DiscountsTable';
import DiscountsSearchForm from '../../features/discounts/DiscountsSearchForm';

import { discountsSearchConfig } from '../../config/searchConfigs';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object,
    addDiscount: PropTypes.func,
    removeDiscount: PropTypes.func,
    forceRefetch: PropTypes.bool,
    getDiscounts: PropTypes.func,
};

const Discounts = ({ history, getDiscounts, removeDiscount, forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.view;

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(discountsSearchConfig);
    const [ discounts, pages ] = useFetch(getDiscounts, searchConfig, [searchConfig], forceRefetch);

    const handleRemoveAction = discountId => {
        removeDiscount(discountId);
        changeSearchConfig({ curentPage: 0 });
    };

    const handleAddAction = () => {
        history.push(`/discounts/edit?discountID=-1`);
    };

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    return discounts ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody className='position-relative'>
                                <main>
                                    <DiscountsSearchForm changeSearchConfig={changeSearchConfig} searchConfig={searchConfig} />
                                    <DiscountsTable
                                        performPageSizeSelect={handleChangePageSize}
                                        pageSize={searchConfig.pageSize}
                                        currentPage={searchConfig.curentPage + 1}
                                        discounts={discounts}
                                        headers={translations.table.headers}
                                        fillers={translations.table.fillers}
                                        translations={translations.table.translations}
                                        removeHandler={handleRemoveAction}
                                        history={history}
                                    />
                                    {
                                        pages > 1 &&
                                            <Paginator
                                                totalPages={pages}
                                                currentPage={searchConfig.curentPage + 1}
                                                onPageChange={changePage}
                                            />
                                    }
                                </main>
                                <aside className={styles.footer}>
                                    <Button color='primary' onClick={handleAddAction}>
                                        <i className='fa fa-plus mr-2'/>
                                        <span>{translations.actions.addDiscount}</span>
                                    </Button>
                                </aside>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ) : null;
};

Discounts.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.discounts,
});

const mapDispatch = {
    removeDiscount,
    getDiscounts,
};

export default connect(mapStateToProps, mapDispatch)(Discounts);