/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getInspectletSettings, updateInspectletSettings } from '../../../redux/methods/integrations';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldTextarea from '../../../components/formFields/FormFieldTextarea';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';

import { Container, Form, Alert } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';

import styles from './index.module.scss';

const propTypes = {
    getInspectletSettings: PropTypes.func,
    updateInspectletSettings: PropTypes.func
};

const Inspectlet = ({ getInspectletSettings, updateInspectletSettings }) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.inspectlet;

    const [ data ] = useFetch(getInspectletSettings);

    const isScriptValid = script => {
        return script ? (script.slice(0, 7) === '<script' && script.slice(-9) === '</script>') : false;
    };

    return data ? (
        <ContainerWithCard title={translations.title} iconClass='fal fa-user-chart'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateInspectletSettings}
                render={({ handleSubmit, form, values }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid className='p-0'>
                                <Alert color="info" isOpen={!isScriptValid(values.script)}>
                                    {translations.scriptFormatAlert}
                                </Alert>
                                <div className='mt-3'>
                                    <FormFieldSwitchPill
                                        name='enabled'
                                        label={translations.labels.enabled}
                                    />
                                </div>
                                <div className='mt-3'>
                                    <FormFieldTextarea
                                        className={styles.textareaContainer}
                                        inputClassName={styles.textarea}
                                        label={translations.labels.script}
                                        name='script'
                                    />
                                </div>
                                <SectionEditFormButtons formReset={form.reset} isSaveButtonDisabled={!isScriptValid(values.script)} />
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconClass='fal fa-user-chart'/>;
};

const mapDispatch = {
    getInspectletSettings,
    updateInspectletSettings
};

Inspectlet.propTypes = propTypes;

export default connect(null, mapDispatch)(Inspectlet);