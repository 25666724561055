const colorEditionModal = {
    header: 'Color edition',
    inputs: {
        name: 'Color name'
    },
    controls: {
        save: 'SAVE CHANGES',
        cancel: 'DISCARD CHANGES'
    }
};

export default colorEditionModal;