import { cAPI } from '../../../utils/cAPI';
import { getData, addEditData, removeData } from '../generic';
import { setOrderStatuses } from '../../../features/appInit/slice';

export const getMetatags = ({ tag, pageid }) => dispatch => getData(dispatch, cAPI.APGetMetatags, { tag, pageid });
export const updateMetatags = ({ tag, pageid, formData }) => dispatch => addEditData(dispatch, cAPI.APUpdateMetatags, { tag, pageid, formData} );

export const getGeneralSettings = () => dispatch => getData(dispatch, cAPI.APGetGeneralSettings);
export const updateGeneralSettings = formData => dispatch => addEditData(dispatch, cAPI.APUpdateGeneralSettings, formData);

export const getSearchSettings = () => dispatch => getData(dispatch, cAPI.APGetSearchSettings);
export const updateSearchSettings = formData => dispatch => addEditData(dispatch, cAPI.APUpdateSearchSettings, formData);

export const getTechnicalBreak = () => dispatch => getData(dispatch, cAPI.APGetTechnicalBreak);
export const updateTechnicalBreak = formData => dispatch => addEditData(dispatch, cAPI.APUpdateTechnicalBreak, formData);

export const removeTechnicalBreakBackground = () => dispatch => removeData(dispatch, cAPI.APRemoveTechnicalBreakBackground);
export const updateTechnicalBreakBackground = formData => dispatch => addEditData(dispatch, cAPI.APUpdateTechnicalBreakBackground, formData, 'technicalBreak');
export const getTechnicalBreakBackground = () => dispatch => getData(dispatch, cAPI.APGetTechnicalBreakBackground, null, 'technicalBreak');

export const getNotFound = () => dispatch => getData(dispatch, cAPI.APGetNotFound);
export const updateNotFound = formData => dispatch => addEditData(dispatch, cAPI.APUpdateNotFound, formData);
export const removeNotFoundImage = () => dispatch => removeData(dispatch, cAPI.APRemoveNotFoundImage, null, 'notFound');
export const updateNotFoundImage = formData => dispatch => addEditData(dispatch, cAPI.APUpdateNotFoundImage, formData, 'notFound');
export const getNotFoundImage = () => dispatch => getData(dispatch, cAPI.APGetNotFoundImage, null, 'notFound');

export const getCookies = () => dispatch => getData(dispatch, cAPI.APGetCookies, null, 'cookies');
export const getCookieById = id => dispatch => getData(dispatch, cAPI.APGetCookieById, id, 'cookies');
export const updateCookie = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUpdateCookie, { formData, id }, 'cookies');
export const updateCookieIsActive = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUpdateCookieIsActive, { formData, id }, 'cookies');

export const getTaxRates = () => dispatch => getData(dispatch, cAPI.APGetTaxRates);

export const getOrderStatuses = () => async dispatch => {
    const result = await getData(dispatch, cAPI.APGetOrderStatuses, null, 'orderStatuses');

    if(result && result.payload && !result.payload.errors && !result.error) {
        dispatch(setOrderStatuses({ orderStatuses: result.payload }));
    }

    return result;
};
export const getOrderStatus = formData => dispatch => getData(dispatch, cAPI.APGetOrderStatus, formData, 'orderStatuses');
export const updateOrderStatus = formData => dispatch => addEditData(dispatch, cAPI.APUpdateOrderStatus, formData, 'orderStatuses');
export const addEditOrderStatus = formData => dispatch => addEditData(dispatch, cAPI.APAddEditOrderStatus, formData, 'orderStatuses');
export const deleteOrderStatus = (id) => dispatch => removeData(dispatch, cAPI.APDeleteOrderStatus, id, 'orderStatuses');

export const getLoginAndRegistrationSettings = () => dispatch => getData(dispatch, cAPI.APGetLoginAndRegistrationSettings);
export const updateLoginAndRegistrationSettings = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdateLoginAndRegistrationSettings, formData);

export const getCookiesTranslations = () => dispatch => getData(dispatch, cAPI.APGetCookiesTranslations);
export const updateCookiesTranslations = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdateCookiesTranslations, formData);
