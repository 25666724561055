import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'reactstrap';

const propTypes = {
    children: PropTypes.node.isRequired,
    placement: PropTypes.oneOf([
        'auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'right', 'right-start',
        'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end',
    ]),
    target: PropTypes.string.isRequired,
    tooltipContent: PropTypes.string,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    /**
     * expose more Tooltip proptypes as needed (https://reactstrap.github.io/components/tooltips/)
     */
};

const TooltipWrapper = ({ children, placement, target, tooltipContent, isOpen, setIsOpen }) => {
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <React.Fragment>
            {children}
            <Tooltip
                trigger="hover"
                isOpen={isOpen}
                placement={placement}
                target={target}
                toggle={handleToggle}
            >
                {tooltipContent}
            </Tooltip>
        </React.Fragment>
    );
};

TooltipWrapper.propTypes = propTypes;

export default TooltipWrapper;