import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createNewCategory, clearNewlyCreatedCategoryId, removeCategory } from '../../../redux/methods/categories';
import { selectCategoryLevel } from '../../../redux/selectors/categories';

import { Button } from 'reactstrap';

import TooltipWrapper from '../../../components/TooltipWrapper';
import CategoryBreadcrumbs from '../CategoryBreadcrumbs';
import TreeItem from '../../../components/TreeItem';
import Modal from '../../../components/modals/Modal';

import { flattenedCategoryTreeItemPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    categoryTreeLevel: PropTypes.arrayOf(flattenedCategoryTreeItemPropTypes),
    newlyCreatedCategoryId: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    storeChosenLanguage: PropTypes.string,
    storeDefaultLanguage: PropTypes.string.isRequired,
    createNewCategory: PropTypes.func,
    clearNewlyCreatedCategoryId: PropTypes.func,
    removeCategory: PropTypes.func,
    isERPCategoriesInUsage: PropTypes.bool,
};

const FlatCategoryList = ({
    location, history, categoryTreeLevel, newlyCreatedCategoryId, storeChosenLanguage,
    storeDefaultLanguage, createNewCategory, clearNewlyCreatedCategoryId, removeCategory,
    isERPCategoriesInUsage,
}) => {
    const intl = useIntl();
    const translations = intl.messages.categories.view;

    const [ isAdditionModalOpen, setIsAdditionModalOpen ] = useState(false);
    const [ isAddBtnTooltipOpen, setIsAddBtnTooltipOpen ] = useState(false);

    const query = new URLSearchParams(location.search);
    const chosenCategory = query.get('catID');

    const isCategoryTreeLevelPresent = categoryTreeLevel && categoryTreeLevel.length > 0;

    const handleOpenAdditionModal = () => {
        setIsAdditionModalOpen(true);
    };

    const handleCreateNewCategory = () => {
        createNewCategory(chosenCategory);
    };

    const handleRemoveCategory = categoryId => () => {
        removeCategory(categoryId);
    };

    useEffect(() => {
        if (newlyCreatedCategoryId) {
            clearNewlyCreatedCategoryId();
            history.push(`/Categories/Edit?catID=${newlyCreatedCategoryId}`);
        }
    }, [ newlyCreatedCategoryId, clearNewlyCreatedCategoryId, history ]);

    return (
        <div className={styles.container}>
            <div className={styles.borderWrapper}>
                <header className={styles.header}>
                    { chosenCategory && <CategoryBreadcrumbs /> }
                </header>
                <main className={clsx(styles.main, !isCategoryTreeLevelPresent && styles.mainFallback)}>
                    {
                        isCategoryTreeLevelPresent ? (
                            <ul className={styles.list}>
                                {
                                    categoryTreeLevel.map(category => {
                                        const currentLanguage = storeChosenLanguage || storeDefaultLanguage;
                                        const treeItemLabel = category.name.find(item => item.iso.toLowerCase() === currentLanguage.toLowerCase()).value;
                                        const modalTranslations = {
                                            header: `${translations.deleteModal.header} ${treeItemLabel}`,
                                            body: `${translations.deleteModal.bodyStart} ${treeItemLabel} ${translations.deleteModal.bodyEnd}`,
                                            cancel: translations.deleteModal.cancel,
                                            confirm: translations.deleteModal.confirm
                                        };

                                        return (
                                            <li
                                                key={`categoryTreeItem-${category.id}`}
                                                className={styles.listItem}
                                            >
                                                <TreeItem
                                                    id={category.id}
                                                    type="link"
                                                    label={treeItemLabel}
                                                    labelLink={`/categories?catID=${category.id}`}
                                                    editLink={`/categories/edit?catID=${category.id}`}
                                                    deleteAction={!isERPCategoriesInUsage ? handleRemoveCategory(category.id) : null}
                                                    modalTranslations={modalTranslations}
                                                    tooltipTranslations={{
                                                        edit: translations.actions.edit,
                                                        delete: translations.actions.delete
                                                    }}
                                                />
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        ) : translations.emptyCategoryLevel
                    }
                </main>
            </div>
            <aside className={styles.controls}>
                <TooltipWrapper
                    placement='top'
                    target='addRootButton'
                    tooltipContent={translations.actions.addTooltip}
                    isOpen={isERPCategoriesInUsage && isAddBtnTooltipOpen}
                    setIsOpen={setIsAddBtnTooltipOpen}
                >
                    <div id={'addRootButton'}>
                        <Button color="primary" onClick={handleOpenAdditionModal} disabled={isERPCategoriesInUsage}>
                            <i className="fa fa-plus mr-2"/>
                            <span>{chosenCategory ? translations.actions.addSub : translations.actions.addRoot}</span>
                        </Button>
                    </div>
                </TooltipWrapper>
            </aside>
            <Modal
                isOpen={isAdditionModalOpen}
                setIsOpen={setIsAdditionModalOpen}
                header={translations.additionModal.header}
                body={translations.additionModal.body}
                buttons={{
                    confirm: {
                        label: translations.additionModal.confirm,
                        color: 'primary'
                    },
                    cancel: {
                        label: translations.additionModal.cancel,
                        color: 'secondary'
                    }
                }}
                onConfirm={handleCreateNewCategory}
            />
        </div>
    );
};

FlatCategoryList.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => ({
    categoryTreeLevel: selectCategoryLevel(ownProps.location.search)(state),
    storeChosenLanguage: state.persisted.storeLocale.storeChosenLanguage,
    storeDefaultLanguage: state.persisted.storeLocale.storeDefaultLanguage,
    newlyCreatedCategoryId: state.categories.newlyCreatedCategoryId,
});

const mapDispatch = {
    createNewCategory,
    clearNewlyCreatedCategoryId,
    removeCategory,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(FlatCategoryList));