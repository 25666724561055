import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVisualSettingsLogoFile, removeVisualSettingsLogoFile, uploadVisualSettingsLogoFile } from '../../../../redux/methods/visualSettings';

import FileSender from '../../../../components/FileSender';
import useFetch from '../../../../hooks/useFetch';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getVisualSettingsLogoFile: PropTypes.func,
    removeVisualSettingsLogoFile: PropTypes.func,
    uploadVisualSettingsLogoFile: PropTypes.func,
    translations: PropTypes.object,
};

const FileSenderLogo = ({ getVisualSettingsLogoFile, forceRefetch, removeVisualSettingsLogoFile, uploadVisualSettingsLogoFile, translations }) => {
    const [ image ] = useFetch(getVisualSettingsLogoFile, null, [], forceRefetch);

    return image ? (
        <FileSender
            id='logo'
            fileKey='LogoFIle'
            validateFormats={['png', 'jpg', 'jpeg', 'svg', 'webp']}
            handleRemove={removeVisualSettingsLogoFile}
            handleUpload={uploadVisualSettingsLogoFile}
            withPreview
            buttons={translations.fileSender}
            label={translations.labels.logo}
            photo={image.logoFile}
            maxSize={1}
            preferHeight='100px'
            preferWidth='100px'
            graphicTypeToEnableGuide='tile'
        />
    ) : null;
};

FileSenderLogo.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.visualSettingsLogo,
});

const mapDispatch = {
    getVisualSettingsLogoFile,
    removeVisualSettingsLogoFile,
    uploadVisualSettingsLogoFile,
};

export default connect(mapStateToProps, mapDispatch)(FileSenderLogo);