import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getPredefinedSearch, deletePredefinedSearch } from '../../redux/methods/predefinedSearch';

import useFetch from '../../hooks/useFetch';
import { generateEditLinkForPredefinedSearch } from '../../utils/predefinedSearch';

import ContainerWithCard from '../../components/ContainerWithCard';
import PredefinedSearchTable from '../../components/tables/PredefinedSearchTable';
import { Button } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getPredefinedSearch: PropTypes.func,
    deletePredefinedSearch: PropTypes.func,
};

const PredefinedSearch = ({ forceRefetch, getPredefinedSearch, deletePredefinedSearch }) => {
    const history = useHistory();

    const intl = useIntl();
    const translations = intl.messages.predefinedSearch;

    const [ predefinedSearch ] = useFetch(getPredefinedSearch, null, [], forceRefetch);

    const pushToCreate = () => {
        history.push(generateEditLinkForPredefinedSearch(-1));
    };

    return predefinedSearch ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search' >
            <PredefinedSearchTable predefinedSearch={predefinedSearch} removeAction={deletePredefinedSearch} />
            <aside className={styles.footer}>
                <Button color='primary' onClick={pushToCreate}>
                    <i className='fa fa-plus mr-2'/>
                    <span>{translations.actions.add}</span>
                </Button>
            </aside>
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

PredefinedSearch.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.predefinedSearch,
});

const mapDispatch = {
    getPredefinedSearch,
    deletePredefinedSearch,
};

export default connect(mapStateToProps, mapDispatch)(PredefinedSearch);