import React from 'react';

import { AppAsideToggler, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { Nav } from 'reactstrap';

import HeaderUserController from '../../../../features/auth/HeaderUserController';
import StoreLanguageDropdown from '../../../../features/storeLocale/StoreLanguageDropdown';
import EndOfSessionWarner from '../../../../features/sessionTimeout/EndOfSessionWarner';
import HeaderNotificationsController from '../../../../features/notifications/HeaderNotificationsController';
import Toast from '../../../Toast';

import logo from '../../../../assets/logo/connectico-logo.png';
import logoFull from '../../../../assets/logo/connectico-logo-full.png';
import ShopRedirectButton from '../../../ShopRedirectButton';
import ErpSyncButton from '../../../ErpSyncButton';
import RefreshButton from './RefreshButton';
import LastSyncDate from '../../../../features/global/LastSyncDate';

const DefaultHeader = () => {

    return (
        <React.Fragment>
            <AppSidebarToggler className="d-lg-none" display="md" mobile/>
            <AppNavbarBrand
                full={{ src: logoFull, width: 140, alt: 'Connectico Logo' }}
                minimized={{ src: logo, width: 100, alt: 'Connectico Logo' }}
            />
            <AppSidebarToggler className="d-md-down-none" display="lg" />
            <Nav className="d-md-down-none" navbar>

            </Nav>
            <Nav className="ml-auto d-flex align-items-end" navbar>
                <Toast />
                <EndOfSessionWarner />
                <RefreshButton />
                <LastSyncDate className='d-none d-xl-flex' />
                <HeaderNotificationsController />
                <ErpSyncButton className="d-none d-lg-flex ml-2" />
                <ShopRedirectButton className="d-none d-lg-block mx-2"/>
                <StoreLanguageDropdown  className="d-none d-lg-block"/>
                <HeaderUserController />
            </Nav>
            <AppAsideToggler className="d-md-down-none" />
            <AppAsideToggler className="d-lg-none" mobile />
        </React.Fragment>
    );
};

export default DefaultHeader;