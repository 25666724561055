/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { connect } from 'react-redux';

import { Table, Input, Row } from "reactstrap";

import ArrowsBetweenStrings from "../../ArrowsBetweenStrings";
import TableActionCell from "../TableActionCell";

import styles from "./index.module.scss";
import { selectStoreCurrentLanguage } from "../../../redux/selectors/storeLocale";

const propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        categoryList: PropTypes.arrayOf(PropTypes.string),
    })),
    checkedCheckboxList: PropTypes.arrayOf(PropTypes.number),
    columnNames: PropTypes.shape({
        categories: PropTypes.string,
        actions: PropTypes.string,
    }),
    removeAction: PropTypes.func.isRequired,
    checkboxAction: PropTypes.func.isRequired,
    tooltipTranslation: PropTypes.string,
    deleteModalTranslations: PropTypes.shape({
        header: PropTypes.string,
        body: PropTypes.string,
        confirm: PropTypes.string,
        cancel: PropTypes.string
    }).isRequired,
    emptyTranslation: PropTypes.string,
    isGreenOnHover: PropTypes.bool,
};

const CategoriesTable = ({
    categories,
    checkedCheckboxList,
    columnNames,
    removeAction,
    checkboxAction,
    tooltipTranslation,
    deleteModalTranslations,
    emptyTranslation,
    isGreenOnHover,
}) => {
    const removeActionHandler = (id) => () => {
        removeAction(id);
    };
    const checkboxActionHandler = (id) => () => {
        checkboxAction(id);
    };
    const isCategoriesListPresent = categories && categories.length > 0;
    return isCategoriesListPresent ? (
        <Table responsive hover size="sm">
            <thead>
                <tr>
                    <th className={styles.columnCheckbox}></th>
                    <th className={styles.columnCategories}>{columnNames && columnNames.categories}</th>
                    <th className={styles.columnActions}>{columnNames && columnNames.actions}</th>
                </tr>
            </thead>
            <tbody>
                {categories.map(category => {

                    const isChecked = checkedCheckboxList && checkedCheckboxList.some(item => item === category.categoryId);

                    return (
                        <tr key={`category-${category.categoryId}`} className={isGreenOnHover && styles.greenBackground}>
                            <th scope="row" className={clsx(styles.columnCheckbox, styles.cell)}>
                                <Input
                                    checked={isChecked}
                                    id={`category-checkbox-${category.categoryId}`}
                                    type="checkbox"
                                    onChange={checkboxActionHandler(category.categoryId)}
                                    className="position-static m-0 p-0"
                                />
                            </th>
                            <td className={clsx(styles.columnCategories, styles.cell)}>
                                <span>
                                    <ArrowsBetweenStrings
                                        arrayOfStrings={category.categories}
                                        keyToPass={`category-${category.categoryId}`}
                                    />
                                </span>
                            </td>
                            <td className={clsx(styles.columnActions, styles.cell)}>
                                <TableActionCell
                                    itemId={category.categoryId}
                                    actions={{
                                        delete: {
                                            action: removeActionHandler(category.categoryId),
                                            tooltipTranslation: tooltipTranslation,
                                            modalTranslations: {
                                                header: `${deleteModalTranslations.header}?`,
                                                body: `${deleteModalTranslations.bodyStart} ${deleteModalTranslations.bodyEnd}?`,
                                                confirm: deleteModalTranslations.confirm,
                                                cancel: deleteModalTranslations.cancel
                                            },
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    ) : (
        <Row className="d-flex justify-content-center mt-4 mb-4">
            {emptyTranslation}
        </Row>
    );
};

CategoriesTable.propTypes = propTypes;

const mapStateToProps = state => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
};

export default connect(mapStateToProps, mapDispatch)(CategoriesTable);