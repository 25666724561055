import { cAPI, handleCAPIErrorsSubtle } from '../../../utils/cAPI';
import { getData, addEditData } from '../generic';
import { setIsSuccess } from '../../../features/toast/slice';
import { setIsLoading, setIsLoaded } from '../../../features/loaders/slice';
import { setForceRefetchByTarget } from '../../../features/refetchers/slice';
import { setFonts } from '../../../features/fonts/slice';

export const getGoogleFonts = () => dispatch => getData(dispatch, cAPI.APGetGoogleFonts, null);

export const updateSelectedFonts = formData => dispatch => addEditData(dispatch, cAPI.APUpdateSelectedFonts, formData, 'fonts');

export const getSelectedFonts = () => async dispatch => {
    dispatch(setIsSuccess({ isSuccess: false }));
    dispatch(setIsLoading());

    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APGetSelectedFonts();
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        dispatch(setForceRefetchByTarget({ target: 'fonts', forceRefetch: false }));

        const { fonts } = payLoad;

        dispatch(setFonts({ fonts: fonts }));
        dispatch(setIsLoaded());

        return { payload: fonts };
    } else if (errorToHandle) {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};
