import React from "react";
import PropTypes from "prop-types";
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { Row } from "reactstrap";

import TableGenerator from "../../generators/TableGenerator";
import { stringOrNumberPropTypes } from "../../../propTypes";
import { generateEditLinkForHomePageEditSection } from "../../../utils/homePage";

import { sectionTypes } from '../../../config';

const propTypes = {
    sections: PropTypes.arrayOf(PropTypes.shape({
        id: stringOrNumberPropTypes,
        name: PropTypes.string,
        type: PropTypes.oneOf([ ...sectionTypes.map(({ label }) => label) ]),
        mobileActive: PropTypes.bool,
        desktopActive: PropTypes.bool,
        tabletActive: PropTypes.bool,
        activeFrom: PropTypes.string,
        activeTo: PropTypes.string,
        activeInLanguages: stringOrNumberPropTypes,
    })),
    handleDelete: PropTypes.func,
    device: PropTypes.oneOf(['Desktop', 'Mobile', 'Tablet']).isRequired,
    changeOrder: PropTypes.func,
    changeActive: PropTypes.func,
    history: PropTypes.object,
    sectionLocation: PropTypes.object,
    handleCopy: PropTypes.func
};

const HomePageSectionsTable = ({
        sections = [],
        handleDelete,
        device,
        changeOrder,
        changeActive,
        sectionTranslation,
        history,
        sectionLocation,
        affiliationObjectId,
        handleCopy
    }) => {

    const translations = useIntl().messages.homePage.table;

    const { headers } = translations;

    const deleteAction = id => item => {
        handleDelete({ id, type: item.type });
    };

    const handleIncreaseOrder = (id, index, item) => {
        changeOrder({ id, device, order: index + 1, type: item.type });
    };

    const handleDecreaseOrder = (id, index, item) => {
        changeOrder({ id: id, device: device, order: index - 1, type: item.type });
    };

    const handleChangeActive = (id, status, item) => {
        changeActive({ id, isActive: status, device: device, type: item.type });
    };

    const handleDefaultAction = (id, item) => () => {
        history.push(generateEditLinkForHomePageEditSection(id, item, sectionLocation, affiliationObjectId));
    };

    const editAction = (id, item) => () => {
        history.push(generateEditLinkForHomePageEditSection(id, item, sectionLocation, affiliationObjectId));
    };

    const copyAction = (oldId, item) => async () => {
        const id = await handleCopy({ id: oldId, type: item.type });

        if(id && typeof id === 'number') {
            history.push(generateEditLinkForHomePageEditSection(id, item, sectionLocation, affiliationObjectId));
        }
    };

    return sections && sections.length > 0 ? (
        <TableGenerator
            defaultAction={handleDefaultAction}
            items={sections}
            translations={translations.translations}
            expectedActions={['edit', 'delete', 'copy']}
            actions={{ deleteAction, editAction, copyAction }}
            columns={[
                {
                    header: headers.order,
                    type: 'order',
                    increaseOrder: handleIncreaseOrder,
                    decreaseOrder: handleDecreaseOrder,
                },
                {
                    header: headers.name,
                    value: 'name',
                },
                {
                    header: headers.type,
                    value: 'type',
                    customTranslation: sectionTranslation
                },
                {
                    header: device === 'Desktop' ? headers.desktopActive: (device === 'Tablet' ? headers.tabletActive : headers.mobileActive),
                    value: 'active',
                    type: 'switchWithAction',
                    onSwitch: handleChangeActive
                },
                {
                    header: headers.activeFrom,
                    value: 'activeFrom',
                    type: 'date',
                    withTime: false,
                },
                {
                    header: headers.activeTo,
                    value: 'activeTo',
                    type: 'date',
                    withTime: false,
                },
                {
                    header: headers.activeInLanguages,
                    value: 'activeInLanguages',
                },
                {
                    header: headers.actions,
                    type: 'actions'
                }
            ]}
        />
    ) : (
        <Row className="d-flex justify-content-center mt-4 mb-4">
            {translations.emptyTable}
        </Row>
    );
};

HomePageSectionsTable.propTypes = propTypes;

export default withRouter(HomePageSectionsTable);
