import { getData, addEditData } from '../generic';
import { cAPI } from '../../../utils/cAPI';

export const getEmailTemplates = () => dispatch => getData(dispatch, cAPI.APGetEmailTemplates, null, 'emailTemplates');
export const getEmailTemplateTables = id => dispatch => getData(dispatch, cAPI.APGetEmailTemplateTables, id, 'emailTemplateTables');
export const getEmailTemplate = id => dispatch => getData(dispatch, cAPI.APGetEmailTemplate, id, 'emailTemplates');
export const getEmailTemplateTable = formData => dispatch => getData(dispatch, cAPI.APGetEmailTemplateTable, formData, 'emailTemplateTables');

export const updateEmailTemplate = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUpdateEmailTemplate, { id, formData }, 'emailTemplates');
export const updateEmailTemplateTable = (templateId, tableId, formData) => dispatch => addEditData(dispatch, cAPI.APUpdateEmailTemplateTable, { templateId, tableId, formData }, 'emailTemplates');

export const getEmailSettings = () => dispatch => getData(dispatch, cAPI.APGetEmailSettings, null, 'emailSettings');
export const getEmailSetting = id => dispatch => getData(dispatch, cAPI.APGetEmailSetting, id, 'emailSettings');
export const updateEmailSetting = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdateEmailSetting, formData, 'emailSettings');
export const deleteEmailSetting = id => dispatch => addEditData(dispatch, cAPI.APDeleteEmailSetting, id, 'emailSettings');
export const setIsDefault = id => dispatch => addEditData(dispatch, cAPI.APUpdateEmailSettingIsDefault, id, 'emailSettings');

export const sendTestEmail = (formData) => dispatch => addEditData(dispatch, cAPI.APSendTestEmail, formData, null, false, false, false, true);