const users = {
    assigner: {
        header: '[EN] Przypisywanie grup',
        buttons: {
            cancel: {
                label: '[EN] Anuluj'
            },
            confirm: {
                label: '[EN] Przypisz',
            },
        },
        toAssign: {
            header:{
                title: '[EN] Grupy do wybrania',
                text: '[EN] Kliknij aby przenieść grupę na drugą liste',
            },
            searchPlaceholder: '[EN] Wyszukaj...',
            tooltip: '[EN] Kliknij aby przenieść grupę na drugą liste',
        },
        assigned: {
            header: {
                title: '[EN] Grupy do przypisania',
                text: '[EN] Kliknij aby przenieść grupę na pierwszą liste',
            },
            searchPlaceholder: '[EN] Wyszukaj...',
            tooltip: '[EN] Kliknij aby przenieść grupę na pierwszą liste',
        },
    }
};

export default users;