import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { addEditAdBanner, uploadAdBannerImage } from '../../../../redux/methods/adBanners';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import Tabulator from '../../../../components/Tabulator';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldActiveInLanguages from '../../../../components/formFields/FormFieldActiveInLanguages';
import FormFieldWithCalendarAndTimePicker from '../../../../components/formFields/FormFieldWithCalendarAndTimePicker';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldSwitchPill from '../../../../components/formFields/FormFieldSwitchPill';
import FileSender from './FileSender';
import FormFieldTranslations from '../../../../components/formFields/FormFieldTranslations';

import { formUtilsHocPropTypes } from '../../../../propTypes';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    getCurrencies: PropTypes.func,
    addEditAdBanner: PropTypes.func,
    utils: formUtilsHocPropTypes.isRequired,
};

const AdBannerEditBasic = ({
    data,
    addEditAdBanner,
    utils,
    uploadAdBannerImage,
    adBannerId
}) => {
    const history = useHistory();

    const intl = useIntl();
    const translations = intl.messages.adBanners.edit.tabs.basic;
    const languages = utils.allLanguages();

    const [ desktopFile, setDesktopFile ] = useState(null);
    const [ mobileFile, setMobileFile ] = useState(null);

    const config = { translations: ['desktopAlternativeText', 'mobileAlternativeText'], date: ['activeFrom', 'activeTo'] };

    const handleOnSubmit = async formData => {
        const objectToSend = {
            ...formData,
            advertisementId: adBannerId > 0 ? adBannerId : null,
        };
        const response = await addEditAdBanner(utils.generateFormToSubmitValues(objectToSend, config));

        if(desktopFile && desktopFile.generatedFile) {
            uploadAdBannerImage(response, desktopFile.generatedFile);
        }

        if(mobileFile && mobileFile.generatedFile) {
            uploadAdBannerImage(response, mobileFile.generatedFile);
        }

        if(typeof response === 'number') {
            history.replace(`/AdBanners/edit?AdBannerID=${response}`);
        }
        return response;
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={utils.generateFormInitialValues(data, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='px-0' fluid>
                                    <Row className='my-2'>
                                        <FormFieldSwitchPill
                                            name='isActive'
                                            label={translations.inputs.active}
                                        />
                                    </Row>
                                    <Row className='my-2'>
                                        <FormFieldSwitchPill
                                            name='showOnAllCategories'
                                            label={translations.inputs.showOnAllCategories}
                                        />
                                    </Row>
                                    <Tabulator
                                        listOfElements={[
                                            {
                                                label: translations.desktopImage,
                                                id: 'firstImage-adBanner',
                                                component: (
                                                    <React.Fragment>
                                                        <FileSender
                                                            section={data}
                                                            translations={translations}
                                                            sectionId={adBannerId}
                                                            isDesktopImage={true}
                                                            setFile={setDesktopFile}
                                                            fileType={1}
                                                        />
                                                        <FormFieldTranslations
                                                            form={form}
                                                            propertyName='desktopAlternativeText'
                                                            label={translations.inputs.alternativeText}
                                                            modalHeader={translations.inputs.alternativeText}
                                                            currentIso={utils.currentLanguage()}
                                                        />
                                                    </React.Fragment>
                                                )
                                            },
                                            {
                                                label: translations.mobileImage,
                                                id: 'secondImage-adBanner',
                                                component: (
                                                    <React.Fragment>
                                                        <FileSender
                                                            section={data}
                                                            translations={translations}
                                                            sectionId={adBannerId}
                                                            isDesktopImage={false}
                                                            setFile={setMobileFile}
                                                            fileType={2}
                                                        />
                                                        <FormFieldTranslations
                                                            form={form}
                                                            propertyName='mobileAlternativeText'
                                                            label={translations.inputs.alternativeText}
                                                            modalHeader={translations.inputs.alternativeText}
                                                            currentIso={utils.currentLanguage()}
                                                        />
                                                    </React.Fragment>
                                                )
                                            }
                                        ]}
                                    />
                                    <Row className='my-3'>
                                        <FormFieldTextOrNumber
                                            required
                                            name='name'
                                            label={translations.inputs.name}
                                            className='w-100 mt-3 mt-md-0'
                                        />
                                    </Row>
                                    <Row>
                                        <FormFieldTextOrNumber
                                            name='link'
                                            label={translations.inputs.link}
                                            className='w-100 mt-3 mt-md-0'
                                        />
                                    </Row>
                                    <Row className='my-4'>
                                        <FormFieldWithCalendarAndTimePicker label={translations.inputs.dateFrom} name='activeFrom' />
                                        <FormFieldWithCalendarAndTimePicker label={translations.inputs.dateTo} name='activeTo'  />
                                    </Row>
                                    <Row>
                                        <FormFieldActiveInLanguages label={translations.inputs.activeInLanguages}  name='activeInLanguages' languages={languages} />
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

AdBannerEditBasic.propTypes = propTypes;

const mapDispatch = {
    addEditAdBanner,
    uploadAdBannerImage,
};

export default withFormUtils(connect(null, mapDispatch)(AdBannerEditBasic));