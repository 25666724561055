const list = {
    settings: {
        header: 'Prezentacja artykułów > Lista',
        button: {
            save: 'ZAPISZ'
        },
        labels: {
            generalSettings: 'Ustawienia podstawowe',
            optionalFields: 'Widoczność informacji opcjonalnych',
            additionalFields: 'Widoczność pól dodatkowych'
        },
        inputs: {
            productsQuantity: 'Ilość artykułów w liście',
            visibleTagAttributeOnProductImage: 'Atrybut typu Tag widoczny na zdjęciu produktu',
            manufacturerField: 'Informacje o producencie',
            showReviews: 'Pokaż oceny produktu',
            showQuantity: 'Pokaż ilość produktu',
            showAddToFavourites: 'Pokaż ikonę "Dodaj do ulubionych"',
            showAddToComparision: 'Pokaż ikonę "Dodaj do porównania"',
            showRelated: 'Pokaż ikonę "Powiązane"',
            showAddToCart: 'Pokaż przycisk "Dodaj do koszyka"',
            showQuantitySelection: 'Pokaż pola wyboru jednostki i ilości',
            showNetPrice: 'Pokaż cenę netto',
            imageTags: 'Prezentowane na zdjęciu atrybuty typu tag',
            imageTags2: 'Prezentowane na zdjęciu cechy typu tag'
        },
        additionalFields: {
            quantity: 'Dostępna ilość',
            measure: 'Rozmiar',
            promotiondateto: 'Okres promocji'
        },
        manufacturerFields: {
            name: 'Nazwa',
            logo: 'Logo'
        }

    }
};

export default list;