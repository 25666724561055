const mapLeaflet = {
    buttons: {
        setMarkerAtYourLocation: 'Ustaw znacznik w swojej lokalizacji',
        deleteMarker: 'USUŃ'
    },
    inputs: {
        markerName: 'Nazwa znacznika',
        markerContent: 'Treść znacznika',
        googleMapsRedirect: 'Czy pokazać przekierowanie do Google Maps?'
    }
};

export default mapLeaflet;