import React from 'react';
import PropTypes from 'prop-types';

import TableGenerator from '../../generators/TableGenerator';

import { countryPropTypes } from '../../../propTypes';

const propTypes = {
    countries: PropTypes.arrayOf(countryPropTypes),
    headerLabels: PropTypes.shape({
        name: PropTypes.string,
        shortName: PropTypes.string,
        regions: PropTypes.string,
        status: PropTypes.string,
        actions: PropTypes.string,
    }).isRequired,
    translations: PropTypes.shape({
        tooltips: PropTypes.shape({
            edit: PropTypes.string
        }),
        emptyTable: PropTypes.string
    }),
    editAction: PropTypes.func,
    nameFiller: PropTypes.string,
};

const CountriesTable = ({ countries, headerLabels, translations, editClickAction, updateActiveAction, nameFiller }) => {

    const editAction = (itemId, country) => () => {
        editClickAction(country);
    };

    const handleUpdateActive = (id, active) => {
        updateActiveAction({
            countryId: id,
            active: active
        });
    };

    return (
        <TableGenerator
            items={countries}
            expectedActions={['edit']}
            actions={{editAction}}
            defaultAction={editAction}
            translations={translations}
            columns={[
                {
                    header: headerLabels.name,
                    value: 'name',
                    filler: nameFiller
                },
                {
                    header: headerLabels.iso,
                    value: 'iso',
                },
                {
                    header: headerLabels.active,
                    value: 'active',
                    type: 'switchWithAction',
                    onSwitch: handleUpdateActive
                },
                {
                    header: headerLabels.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

CountriesTable.propTypes = propTypes;

export default CountriesTable;