import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Field } from 'react-final-form';

import CalendarPicker from '../../CalendarPicker';

import styles from './index.module.scss';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    storeCurrentLanguage: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
};

const FormFieldWithCalendarPicker = ({ name, label, storeCurrentLanguage, minDate, maxDate, className, isDisabled }) => {
    return (
        <Field name={name}>
            {
                props =>
                    <span className={clsx(styles.calendarInput, className)}>
                        <span>{label}</span>
                        <CalendarPicker
                            name={props.input[name]}
                            minDateLimit={minDate}
                            maxDateLimit={maxDate}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            locale={storeCurrentLanguage}
                            isDisabled={isDisabled}
                        />
                    </span>
            }
        </Field>
    );
};

FormFieldWithCalendarPicker.propTypes = propTypes;

export default FormFieldWithCalendarPicker;