import React from 'react';
import PropTypes from 'prop-types';

import { displayTextOrNumberInCell } from '../../../../../utils/components';

import { stringOrNumberPropTypes } from '../../../../../propTypes';

const propTypes = {
    value: PropTypes.oneOfType([
        stringOrNumberPropTypes,
        PropTypes.shape({
            filename: PropTypes.string,
            href: PropTypes.string,
            created: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.instanceOf(Date)
            ])
        }),
        PropTypes.arrayOf(stringOrNumberPropTypes)
    ]),
    className: PropTypes.string,
    additionalValue: stringOrNumberPropTypes,
    onClickAction: PropTypes.func,
};

const TextOrNumberCell = ({ value, additionalValue, className, onClickAction }) => {
    return (
        <td className={className} onClick={onClickAction}>
            {
                displayTextOrNumberInCell(value, additionalValue)
            }
        </td>
    );
};

TextOrNumberCell.propTypes = propTypes;

export default TextOrNumberCell;
