const fonts = {
    title: 'Ustawienia czcionek',
    table: {
        headers: {
            name: 'Nazwa',
            actions: 'Akcje',
        },
        tooltips: {
            remove: 'Usuń czcionkę',
        },
        emptyTable: 'Nie znaleziono przypisanych czcionek',
        deleteModal: {
            header: 'Usuń czcionkę',
            body: 'Czy napewno chcesz usunąć czcionkę?',
            confirm: 'USUŃ',
            cancel: 'WRÓĆ'
        }
    },
    addFonts: 'Dodaj czcionki',
    assigner: {
        header: 'Dodaj czcionki',
        buttons: {
            cancel: 'Anuluj',
            confirm: 'Zapisz'
        },
        toAssign: {
            header: {
                title: 'Dostępne czcionki',
            },
            tooltip: 'Wybierz czcionkę',
            searchPlaceholder: 'Wyszukaj',
        },
        assigned: {
            header: {
                title: 'Wybrane czcionki',
            },
            tooltip: 'Usuń czcionkę',
            searchPlaceholder: 'Wyszukaj',
        }
    },
    polishFonts: 'Czcionki z polskimi znakami',
    allFonts: 'Wszystkie czcionki',
    selector: {
        select: 'Wybierz czcionkę',
        addFonts: 'Dodaj więcej czcionek',
        searchPlaceholder: 'Wyszukaj'
    }
};

export default fonts;