import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getSearchSettings, updateSearchSettings } from '../../../redux/methods/extrasSettings';
import { getAttributeDictionaryValues } from '../../../redux/methods/attributes';

import useFetch from '../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Col, Row, Form, Alert } from 'reactstrap';

import SectionAsideButtons from '../../../components/SectionAsideButtons';
import FormFieldTranslations from '../../../components/formFields/FormFieldTranslations';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';
import FormFieldWithCheckbox from '../../../components/formFields/FormFieldWithCheckbox';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import FormFieldWithRange from '../../../components/formFields/FormFieldWithRange';
import ContainerWithCard from '../../../components/ContainerWithCard';

import { withFormUtils } from '../../../hoc/withFormUtils';
import { generateDropdownOptionsForTags } from '../../../utils/components';

import { formUtilsHocPropTypes } from '../../../propTypes';

import styles from './index.module.scss';
import clsx from 'clsx';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getSearchSettings: PropTypes.func,
    updateSearchSettings: PropTypes.func,
    getAttributeDictionaryValues: PropTypes.func,
};

const SearchEngine = ({ utils, getSearchSettings, updateSearchSettings, getAttributeDictionaryValues }) => {
    const intl = useIntl();
    const translations = intl.messages.searchEngine;

    const config = { translations: ['searchPlaceholder', 'noProductsText'] };

    const handleOnSubmit = formData => {
        updateSearchSettings(utils.generateFormToSubmitValues(formData, config));
    };

    const tagId = 1;
    const [ tags ] = useFetch(getAttributeDictionaryValues, tagId,);
    const [ data ] = useFetch(getSearchSettings);

    const typeOptions = [
        {
            id: 'List',
            label: translations.typeOptions.list,
        },
        {
            id: 'Tile',
            label: translations.typeOptions.tile,
        },
    ];

    const tagsOptions = tags && generateDropdownOptionsForTags(tags, utils.currentLanguage(), translations.nullOpt);

    const sortDirectionOptions = [
        { id: 'null:Score', label: translations.sortDirectionOptions.score },
        { id: 'Asc:ProductName', label: translations.sortDirectionOptions.productNameAsc },
        { id: 'Desc:ProductName', label: translations.sortDirectionOptions.productNameDesc },
        { id: 'Asc:ProductGrossPrice', label: translations.sortDirectionOptions.productGrossPriceAsc },
        { id: 'Desc:ProductGrossPrice', label: translations.sortDirectionOptions.productGrossPriceDesc },
    ];

    return tags && data ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search'>
            <FinalForm
                onSubmit={handleOnSubmit}
                initialValues={utils.generateFormInitialValues(data, config)}
                render={({ handleSubmit, form, values }) => {
                    const currentTag = tagsOptions.find(tag => tag.id === values.tagId) || tagsOptions[0];
                    const currentSortDirection = sortDirectionOptions.find(sd => sd.id === values.defaultSortDirection) || sortDirectionOptions[0];

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Col>
                                <Row className='mt-2'>
                                    <FormFieldTranslations
                                        form={form}
                                        modalHeader={translations.inputs.searchPlaceholder}
                                        propertyName='searchPlaceholder'
                                        currentIso={utils.currentLanguage()}
                                        label={translations.inputs.searchPlaceholder}
                                        withRightMargin={false}
                                    />
                                </Row>
                                <Row className='mt-2'>
                                    <FormFieldTextOrNumber
                                        label={translations.inputs.minChar}
                                        name='minChar'
                                        type='number'
                                        min={3}
                                        className='w-100'
                                    />
                                </Row>
                                <Row className='my-2'>
                                    <FormFieldTextOrNumber
                                        label={translations.inputs.hintsCount}
                                        name='hintsCount'
                                        type='number'
                                        className='w-100'
                                        max={15}
                                        min={1}
                                    />
                                </Row>
                                <Row><span>{translations.searchCriteria}</span></Row>
                                <Row className={clsx(styles.section, styles.sectionWithRange)}>
                                    <Alert color='info' className='w-100'>
                                        <small>{translations.help.range}</small>
                                        <hr />
                                        <small>{translations.help.disable}</small>
                                        <hr/>
                                        <small>{translations.help.description}</small>
                                    </Alert>
                                    {
                                        Object.keys(data.searchCriteria)
                                            .map(key =>
                                                <div className={styles.range} key={key}>
                                                    <FormFieldWithRange
                                                        name={`searchCriteria.${key}`}
                                                        label={translations.checkboxes[key] || key}
                                                        min={0}
                                                        max={10}
                                                        step={0.1}
                                                    />
                                                </div>
                                            )
                                    }
                                </Row>
                                <Row className='mt-2'><span>{translations.options}</span></Row>
                                <Row className={styles.section}>
                                    <Col>
                                        <Row className='my-2'>
                                            <FormFieldWithDropdown
                                                name='type'
                                                currentSelectionLabel={typeOptions.find(opt => opt.id === values.type).label}
                                                label={translations.inputs.type}
                                                options={typeOptions}
                                            />
                                        </Row>
                                        {
                                            Object.keys(data.options)
                                                .map(key =>
                                                    <Row key={key}>
                                                        <FormFieldWithCheckbox
                                                            name={`options.${key}`}
                                                            label={translations.checkboxes[key] || key}
                                                        />
                                                    </Row>
                                                )
                                        }
                                    </Col>
                                </Row>
                                <Row className='w-100 mt-2'>
                                    <FormFieldWithDropdown
                                        label={translations.inputs.tags}
                                        options={tagsOptions}
                                        name='tagId'
                                        currentSelectionLabel={currentTag.label}
                                    />
                                </Row>
                                <Row className='w-100 mt-2'>
                                    <FormFieldWithDropdown
                                        label={translations.inputs.sortDirection}
                                        options={sortDirectionOptions}
                                        name='defaultSortDirection'
                                        currentSelectionLabel={currentSortDirection.label}
                                    />
                                </Row>
                                <Row className='mt-2'>
                                    <FormFieldTranslations
                                        form={form}
                                        modalHeader={translations.inputs.noProductsText}
                                        propertyName='noProductsText'
                                        currentIso={utils.currentLanguage()}
                                        label={translations.inputs.noProductsText}
                                        withRightMargin={false}
                                    />
                                </Row>
                            </Col>
                            <SectionAsideButtons
                                buttons={{
                                    add: {
                                        color: 'success',
                                        label: translations.button,
                                        action: handleSubmit,
                                        iconClass: 'fa fa-save mr-2',
                                    }
                                }}
                            />
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

SearchEngine.propTypes = propTypes;

const mapDispatch = {
    getAttributeDictionaryValues,
    getSearchSettings,
    updateSearchSettings,
};

export default withFormUtils(connect(null, mapDispatch)(SearchEngine));