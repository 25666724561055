import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getFooterSettingsNewsletter, updateFooterSettingsNewsletter, updateFooterImage } from '../../../../../redux/methods/menu';

import useFetch from '../../../../../hooks/useFetch';
import { withFormUtils } from '../../../../../hoc/withFormUtils';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import FormFieldWithCheckbox from '../../../../../components/formFields/FormFieldWithCheckbox';
import FormFieldTranslationsQuill from '../../../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldColorPicker from '../../../../../components/formFields/FormFieldColorPicker';
import SectionEditFormButtons from '../../../../../components/SectionEditFormButtons';

import { formUtilsHocPropTypes } from '../../../../../propTypes';
import Tabulator from '../../../../../components/Tabulator';
import FormFieldTranslations from '../../../../../components/formFields/FormFieldTranslations';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getFooterSettingsNewsletter: PropTypes.func,
    updateFooterSettingsNewsletter: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const FooterSettings = ({
    utils,
    getFooterSettingsNewsletter,
    updateFooterSettingsNewsletter,
    forceRefetch
}) => {
    const intl = useIntl();
    const translations = intl.messages.menuSettings.footer.sections.newsletter;

    const config = {
        translations: [
            'newsletterContentMobile',
            'newsletterContentTablet',
            'newsletterContentDesktop',
            'newsletterLink',
            'newsletterButtonContent',
            'newsletterContentUnderButtonMobile',
            'newsletterContentUnderButtonTablet',
            'newsletterContentUnderButtonDesktop'
        ]
    };

    const handleOnSubmit = async form => {
        await updateFooterSettingsNewsletter(utils.generateFormToSubmitValues(form, config));
    };

    const [ settings ] = useFetch(getFooterSettingsNewsletter, null, [], forceRefetch);

    return settings ? (
        <Container className='px-3' fluid>
            <FinalForm
                initialValues={utils.generateFormInitialValues(settings, config)}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row style={{marginBottom: '1.5em'}}>
                                <Col lg="6">
                                    <Card style={{height: '100%'}}>
                                        <CardHeader>{translations.inputs.showCaption}</CardHeader>
                                        <CardBody>
                                            <Col>
                                                <FormFieldWithCheckbox
                                                    name='showNewsletter'
                                                    label={translations.inputs.showNewsletter}
                                                />
                                            </Col>

                                            <Col className='mt-2'>
                                                <FormFieldTranslations
                                                    form={form}
                                                    modalHeader={translations.modalHeader}
                                                    propertyName='newsletterLink'
                                                    currentIso={utils.currentLanguage()}
                                                    label={translations.inputs.newsletterLink}
                                                    className='w-100'
                                                />
                                            </Col>

                                            <Col className='mt-2'>
                                                <FormFieldTranslations
                                                    form={form}
                                                    modalHeader={translations.modalHeader2}
                                                    propertyName='newsletterButtonContent'
                                                    currentIso={utils.currentLanguage()}
                                                    label={translations.inputs.newsletterButtonContent}
                                                    className='w-100'
                                                />
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="6">
                                    <Card style={{height: '100%'}}>
                                        <CardHeader>{translations.inputs.newsletterColors}</CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xl="6">
                                                    <FormFieldColorPicker
                                                        name='newsletterBackgroundColor'
                                                        label={translations.inputs.backgroundColor}
                                                        button={translations.inputs.colorButton}
                                                    />
                                                </Col>
                                                <Col xl="6">
                                                    <FormFieldColorPicker
                                                        name='newsletterForegroundColor'
                                                        label={translations.inputs.fontColor}
                                                        button={translations.inputs.colorButton}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card>
                                <CardHeader>{translations.inputs.newsletterContentUnderButton}</CardHeader>
                                <CardBody>
                                    <Tabulator
                                        listOfElements={[
                                            {
                                                label: 'Mobile',
                                                id: 'newsletterContentUnderButton-mobile',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='newsletterContentUnderButtonMobile'
                                                    />
                                            },
                                            {
                                                label: 'Tablet',
                                                id: 'newsletterContentUnderButton-tablet',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='newsletterContentUnderButtonTablet'
                                                    />
                                            },
                                            {
                                                label: 'Desktop',
                                                id: 'newsletterContentUnderButton-desktop',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='newsletterContentUnderButtonDesktop'
                                                    />
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>{translations.inputs.newsletterContent}</CardHeader>
                                <CardBody>
                                    <Tabulator
                                        listOfElements={[
                                            {
                                                label: 'Mobile',
                                                id: 'newsletterContent-mobile',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='newsletterContentMobile'
                                                    />
                                            },
                                            {
                                                label: 'Tablet',
                                                id: 'newsletterContent-tablet',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='newsletterContentTablet'
                                                    />
                                            },
                                            {
                                                label: 'Desktop',
                                                id: 'newsletterContent-desktop',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='newsletterContentDesktop'
                                                    />
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                            <SectionEditFormButtons className='px-2' formReset={form.reset} />
                        </Form>
                    );
                }}
            />
        </Container>
    ) : null;
};

FooterSettings.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.bottomMenu,
});

const mapDispatch = {
    getFooterSettingsNewsletter,
    updateFooterSettingsNewsletter,
    updateFooterImage
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(FooterSettings));