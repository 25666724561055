import React from 'react';
import PropTypes from 'prop-types';

import { productVariantsTypes } from '../../../../config';
import { generateNonIdsOptionsForDropdown } from '../../../../utils/components';

import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';

import styles from './index.module.scss';

const propTypes = {
    label: PropTypes.string,
    variantsTranslations: PropTypes.shape({
        size: PropTypes.string,
        color: PropTypes.string,
    }),
    defaultSelectTranslation: PropTypes.string,
};

const VariantTypeSelection = ({
    label,
    variantsTranslations,
    defaultSelectTranslation,
    values,
}) => {
    const variantsTypesDropdownOptions = generateNonIdsOptionsForDropdown(productVariantsTypes, variantsTranslations);
    const currentSelectionLabel = variantsTypesDropdownOptions.find(type => type.id === values.variantType);
    return (
        <div className={styles.dropdown}>
            <span className='mb-3'><strong>{label}</strong></span>
            <FormFieldWithDropdown
                name='variantType'
                options={variantsTypesDropdownOptions}
                currentSelectionLabel={currentSelectionLabel ? currentSelectionLabel.label : defaultSelectTranslation}
            />
        </div>
    );
};

VariantTypeSelection.propTypes = propTypes;

export default VariantTypeSelection;