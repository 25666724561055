/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getVisualSettingsLogo, updateVisualSettingsLogo } from '../../../redux/methods/visualSettings';

import { Container, Row } from 'reactstrap';

import ContainerWithCard from '../../../components/ContainerWithCard';

import { withFormUtils } from '../../../hoc/withFormUtils';
import { formUtilsHocPropTypes } from '../../../propTypes';
import FileSenderProduct from './FileSenderProduct';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getVisualSettingsLogo: PropTypes.func,
    updateVisualSettingsLogo: PropTypes.func,
};

const PlaceholderImages = () => {
    const intl = useIntl();
    const translations = intl.messages.visualSettings.placeholderImages;

    return (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search'>
            <Container fluid>
                <Row>
                    <div class="col-md-12 col-lg-6 m-0 p-1">
                        <h3>{translations.product}</h3>
                        <FileSenderProduct translations={translations} />
                    </div>
                </Row>
            </Container>
        </ContainerWithCard>
    );
};

PlaceholderImages.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.visualSettingsLogo,
});

const mapDispatch = {
    getVisualSettingsLogo,
    updateVisualSettingsLogo,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(PlaceholderImages));