import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { withFormUtils } from '../../../hoc/withFormUtils';
import useFetch from '../../../hooks/useFetch';
import { getCategoryTree } from '../../../redux/methods/categories';
import { handleCheckboxAction, generateNonIdsOptionsForDropdown, getTranslationForIso, goBackToGeneralSection, generateDropDownOptionsForEnum } from '../../../utils/components';
import { getUpperMenuSection, updateUpperMenuSection, addMenuSection, uploadMenuImage } from '../../../redux/methods/menu';
import { getCustomPages } from '../../../redux/methods/customPages';
import { getManufacturers } from '../../../redux/methods/manufacturers';
import { getPredefinedSearch } from '../../../redux/methods/predefinedSearch';
import { selectTranslatedFlattenedCategoryTree } from '../../../redux/selectors/categories';

import { upperMenuSectionTypes, upperMenuSectionTypesForSubSection } from '../../../config';

import { presentationTypeEnum } from '../../../enums/menu';

import CategoryTypeController from '../../../features/menu/editControllers/CategoryTypeController';
import UrlTypeController from '../../../features/menu/editControllers/UrlTypeController';

import { Label, Container, Form, Row, Alert } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import ContainerWithCard from '../../../components/ContainerWithCard';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import FormFieldTranslations from '../../../components/formFields/FormFieldTranslations';

import { formUtilsHocPropTypes, flattenedTranslatedCategoryTreeItemPropTypes } from '../../../propTypes';

import styles from './index.module.scss';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';

import Dropdown from '../../../components/Dropdown';

import FormFieldWithCheckbox from '../../../components/formFields/FormFieldWithCheckbox';

import FileSender from './FileSenderMenu';

import { translateManufacturers } from '../../../utils/manufacturers';

const propTypes = {
    utils: formUtilsHocPropTypes,
    categoryTree: PropTypes.arrayOf(flattenedTranslatedCategoryTreeItemPropTypes),
    getCategoryTree: PropTypes.func,
    location: PropTypes.object,
    getUpperMenuSection: PropTypes.func,
    updateUpperMenuSection: PropTypes.func,
    getManufacturers: PropTypes.func,
    uploadMenuImage: PropTypes.func
};

const MenuEdit = ({
    utils,
    categoryTree,
    getCategoryTree,
    location,
    getUpperMenuSection,
    updateUpperMenuSection,
    getCustomPages,
    getManufacturers,
    addMenuSection,
    getPredefinedSearch,
    history,
    flattenedCategoryTree,
    uploadMenuImage
}) => {
    const intl = useIntl();
    const translations = intl.messages.menuSettings.menu.edit;

    const query = new URLSearchParams(location.search);
    const chosenSectionId = query.get('secID');
    const chosenParentSectionId = chosenSectionId < 0 ? -chosenSectionId : null;
    const menuId = chosenSectionId ? parseInt(chosenSectionId) : null;

    const [ isSubSectionInCreation, setIsSubSectionInCreation ] = useState(false);

    useEffect(() => {
        if(chosenParentSectionId) {
            setIsSubSectionInCreation(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [ selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [ imageFile, setImageFile ] = useState(null);
    const [ isCaptionAlertOpen, setIsCaptionAlertOpen ] = useState(null);

    const [ manufacturers ] = useFetch(getManufacturers);
    const [ customPages ] = useFetch(getCustomPages);
    const [ predefinedSearchList ] = useFetch(getPredefinedSearch);
    const [ section ] = useFetch(getUpperMenuSection, !isSubSectionInCreation ? chosenSectionId : 0);
    const [ selectedManufacturers, setSelectedManufacturers ] = useState([]);

    const config = { translations: ['caption', 'url', 'tag'] };

    const translationsPresentation = translations.presentation;

    const positioningOptions = generateDropDownOptionsForEnum(presentationTypeEnum, translationsPresentation, false);

    useEffect(() => {
        if(!categoryTree || categoryTree.length < 1) {
            getCategoryTree();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (section && section[0].manufacturerId) {
            setSelectedManufacturers(section[0].manufacturerId);
        }
    }, [section]);

    const generateDropdownOptions = items => {
        if(items) {
            return items.map(item => ({
                id: item.id,
                name: typeof item.name === 'object'
                    ? getTranslationForIso(item.name, utils.currentLanguage())
                    : item.name
            }));
        }
        return [];
    };

    const checkIsProperCaptionLength = caption => {
        return (caption.map(({ value }) => value).filter(value => value.length !== 0).length === 0) ||
            !!caption.map(({ value }) => value).find(value => value.length > 25);
    };

    const getStrucutreToSubmit = form => {
        const formToSend = utils.generateFormToSubmitValues(form, config);

        const basicFormStrucutre = {
            caption: formToSend.caption,
            statusBottom: formToSend.statusBottom ? 1 : 0,
            statusTop: formToSend.statusTop ? 1 : 0,
            redirectFromHomePage: formToSend.redirectFromHomePage,
            columnLayout: formToSend.columnLayout,
            image: formToSend.image,
            imagePresentation: formToSend.imagePresentation
        };

        if (form.type === 'manufacturers')
        {
            return {
                ...basicFormStrucutre,
                manufacturerId: selectedManufacturers.length === 0 ? [-1] : selectedManufacturers
            };
        } else if(form.type === 'container') {
            return basicFormStrucutre;
        }
        else if (form.type === 'url') {
            return {
                ...basicFormStrucutre,
                url: formToSend.url,
                openInNewCard: formToSend.openInNewCard
            };
        }
        else if (form.type === 'customPage') {
            return {
                ...basicFormStrucutre,
                customPageId: formToSend.customPageId,
            };
        }
        else if (form.type === 'category') {
            return {
                ...basicFormStrucutre,
                categoryId: selectedCategoryId
            };
        }
        else if (form.type === 'search') {
            return {
                ...basicFormStrucutre,
                searchId: formToSend.searchId
            };
        }
    };

    const handleOnSubmit = async form => {
        const formToSend = getStrucutreToSubmit(form);

        if(checkIsProperCaptionLength(formToSend.caption)) {
            setIsCaptionAlertOpen(true);

            return null;
        }
        else {
            let response;

            const preventRefetch = !!(imageFile && imageFile.generatedFile);

            if(isSubSectionInCreation) {
                const objectToSend = {
                    ...formToSend,
                    menuId: isSubSectionInCreation && parseInt(chosenParentSectionId)
                };

                response = await addMenuSection(objectToSend, preventRefetch);
            }
            else {
                const objectToSend = {
                    ...formToSend,
                    id: menuId
                };

                response = await updateUpperMenuSection(objectToSend, preventRefetch);
            }

            if(imageFile && imageFile.generatedFile) {
                await uploadMenuImage(response, imageFile.generatedFile);
            }

            goBackToGeneralSection(history);
        }
    };

    const customPagesDropdownOptions = generateDropdownOptions(customPages);
    const predefinedSearchDropdownOptions = generateDropdownOptions(predefinedSearchList);

    const handleManufacturerSelection = id => {
        handleCheckboxAction(id, selectedManufacturers, setSelectedManufacturers);
    };

    const handleAllManufacturersToggle = () => {
        if (selectedManufacturers.length === 1 && selectedManufacturers[0] === -1) {
            setSelectedManufacturers([]);
        } else {
            setSelectedManufacturers([-1]);
        }
    };

    const pickControllerForType = (type, form, values, currentSelectionCustomPageLabel, currentSelectionPredefinedSearchLabel) => {
        switch(type) {
            case 'manufacturers': {
                const allManufacturersChecked = selectedManufacturers.length === 1 && selectedManufacturers[0] === -1;
                const manufacturerSelectionLabel = selectedManufacturers.length === 0 || !manufacturers
                                    ? ''
                                    : translateManufacturers(manufacturers, utils)
                                        .filter(m => selectedManufacturers.includes(m.id))
                                        .map(m => m.name)
                                        .reduce((prev, curr) => prev + (prev ? ', ' : '') + curr, '');

                return (
                    <div className='mt-3'>
                        <Label style={{display: 'flex', alignItems: 'center'}}>
                            <label className="switch switch-pill switch-success m-0">
                                <input type="checkbox" onChange={handleAllManufacturersToggle} className="switch-input" checked={allManufacturersChecked} />
                                <span className={clsx("switch-slider", !allManufacturersChecked &&  "bg-danger border-danger")} ></span>
                            </label>
                            <span className="ml-3 font-weight-bolder">Prezentuj w menu wszystkich producentów</span>
                        </Label>
                        <Label>Wybierz producentów, którzy mają być prezentowani w menu:</Label>
                        <Dropdown
                            withCheckboxes={true}
                            options={manufacturers ? translateManufacturers(manufacturers, utils) : []}
                            performSelection={handleManufacturerSelection}
                            checkedIds={selectedManufacturers}
                            currentSelectionLabel={manufacturerSelectionLabel}
                            isDisabled={allManufacturersChecked}
                        />
                    </div>
                );
            }
            case 'category': return (
                <CategoryTypeController
                    flattenedCategoryTree={flattenedCategoryTree}
                    initialySelectedId={values.categoryId}
                    selectedCategoryId={selectedCategoryId}
                    setSelectedCategoryId={setSelectedCategoryId}
                    selectCategoryLabel={translations.inputs.selectCategoryLabel}
                    showAll={true}
                />
            );
            case 'url': return (
                <UrlTypeController
                    form={form}
                    propertyName='url'
                    currentLanguage={utils.currentLanguage()}
                    label={translations.inputs.urlLabel}
                    switchName='openInNewCard'
                    switchLabels={translations.inputs.isOpeningInNewTabLabels}
                />
            );
            case 'customPage': return (
                <FormFieldWithDropdown
                    name='customPageId'
                    currentSelectionLabel={currentSelectionCustomPageLabel || translations.inputs.selectDropdownLabel}
                    label={translations.inputs.customPage}
                    options={customPagesDropdownOptions}
                />
            );
            case 'search': return (
                <FormFieldWithDropdown
                    name='searchId'
                    currentSelectionLabel={currentSelectionPredefinedSearchLabel || translations.inputs.selectDropdownLabel}
                    label={translations.inputs.predefinedSearch}
                    options={predefinedSearchDropdownOptions}
                />
            );
            default: return null;
        }
    };

    const getInitialStructureForType = section => {
        if(!section.type || section.type === 'container') {
            return {
                ...section,
                enabled: section.status === 1,
            };
        }
        else {
            return section;
        }
    };

    return categoryTree && section ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-caret-up'>
            <Alert
                color='danger'
                isOpen={isCaptionAlertOpen}
            >
                {translations.captionAlert}
            </Alert>

            <FinalForm
                keepDirtyOnReinitialize
                initialValues={utils.generateFormInitialValues(getInitialStructureForType(isSubSectionInCreation ? { imagePresentation: 'Contain' } : section[0]), config)}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values }) => {
                    const { type } = values;

                    const currentSelectionLabel = translations.typeOptions[type];

                    const currentSelectionCustomPageLabel =
                        customPagesDropdownOptions && customPagesDropdownOptions.length > 0
                            ? values.customPageId ?
                                customPagesDropdownOptions.find(customPage => customPage.id === values.customPageId).name : translations.inputs.emptyCustomPageOptionLabel
                            : translations.inputs.emptyCustomPageOptionLabel
                    ;

                    const currentPredefinedSearchLabel =
                        predefinedSearchDropdownOptions && predefinedSearchDropdownOptions.length > 0
                            ? values.searchId ?
                                predefinedSearchDropdownOptions.find(search => search.id === values.searchId).name : translations.inputs.emptyCustomPageOptionLabel
                            : translations.inputs.emptyCustomPageOptionLabel
                    ;

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid className={styles.formContainer}>
                                <Row>
                                    <FormFieldSwitchPill
                                        name='statusTop'
                                        label={translations.inputs.statusTop}
                                    />
                                    <FormFieldSwitchPill
                                        name='statusBottom'
                                        label={translations.inputs.statusBottom}
                                        className='ml-3'
                                    />
                                </Row>
                                <Row className='my-3'>
                                    <FormFieldWithDropdown
                                        className={styles.sectionTypeDropdown}
                                        name='type'
                                        isDisabled={!isSubSectionInCreation && section[0].type !== 'container'}
                                        currentSelectionLabel={currentSelectionLabel || translations.typeOptions.selectTypeLabel}
                                        options={generateNonIdsOptionsForDropdown(isSubSectionInCreation ?
                                            upperMenuSectionTypesForSubSection :
                                            upperMenuSectionTypes, translations.typeOptions)}
                                        label={translations.inputs.type}
                                    />
                                </Row>
                                {
                                    !isSubSectionInCreation && !values.parentId &&
                                    <>
                                        <Row className='mb-2'>
                                            <FileSender
                                                menuId={menuId}
                                                translations={translations}
                                                setFile={setImageFile}
                                                image={values.imageSource}
                                            />
                                        </Row>
                                        <Row className='mb-3'>
                                            <FormFieldWithDropdown
                                                label={translations.inputs.presentationLabel}
                                                name='imagePresentation'
                                                options={positioningOptions}
                                            />
                                        </Row>
                                    </>
                                }
                                {
                                    type !== 'container' &&
                                    <Row className='mb-3'>
                                        <FormFieldWithCheckbox
                                            name='redirectFromHomePage'
                                            label={translations.inputs.redirectFromHomePage}
                                        />
                                    </Row>
                                }
                                {
                                    type === 'container' &&
                                    <Row className='mb-3'>
                                        <FormFieldWithCheckbox
                                            name='columnLayout'
                                            label={translations.inputs.columnLayout}
                                        />
                                    </Row>
                                }
                                <Row>
                                    <div className={styles.nameInput}>
                                        <FormFieldTranslations
                                            form={form}
                                            propertyName={'caption'}
                                            label={translations.inputs.name}
                                            currentIso={utils.currentLanguage()}
                                            modalHeader={translations.inputs.name}
                                        />
                                    </div>
                                </Row>
                                <Row className='mt-5'>
                                    {
                                        pickControllerForType(type, form, values, currentSelectionCustomPageLabel, currentPredefinedSearchLabel)
                                    }
                                </Row>

                                <SectionEditFormButtons formReset={form.reset} isSaveButtonDisabled={!type} />
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconClass='fa fa-caret-up' />;
};

MenuEdit.propTypes = propTypes;

const mapStateToProps = state => ({
    categoryTree: state.categories.categoryTree,
    flattenedCategoryTree: selectTranslatedFlattenedCategoryTree(state)
});

const mapDispatch = {
    getCategoryTree,
    getUpperMenuSection,
    updateUpperMenuSection,
    getCustomPages,
    addMenuSection,
    getPredefinedSearch,
    getManufacturers,
    uploadMenuImage
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(MenuEdit));