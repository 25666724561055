import React from 'react';
import PropTypes from 'prop-types';

import { generateDisplayedPages } from '../../utils/shared';

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func
};

const Paginator = ({ totalPages, currentPage, onPageChange }) => {
    const handleOnPageChange = page => () => {
        onPageChange(page);
    };

    return (
        <Pagination>
            <PaginationItem disabled={currentPage === 1}>
                <PaginationLink first onClick={handleOnPageChange(0)}/>
            </PaginationItem>
            <PaginationItem disabled={currentPage === 1}>
                <PaginationLink previous onClick={handleOnPageChange(currentPage - 2)}/>
            </PaginationItem>
            {
                generateDisplayedPages(totalPages, currentPage).map(page =>
                    <PaginationItem key={`page-${page}`} active={page === currentPage}>
                        <PaginationLink onClick={handleOnPageChange(page-1)}>
                            {page}
                        </PaginationLink>
                    </PaginationItem>
                )
            }
            <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink next onClick={handleOnPageChange(currentPage)}/>
            </PaginationItem>
            <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink last onClick={handleOnPageChange(totalPages-1)}/>
            </PaginationItem>
        </Pagination>
    );
};

Paginator.propTypes = propTypes;

export default Paginator;