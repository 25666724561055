import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Row } from 'reactstrap';

import FormFieldTranslations from '../FormFieldTranslations';
import FormFieldTextOrNumber from '../FormFieldTextOrNumber';
import FormFieldWithDropdown from '../FormFieldWithDropdown';
import { withLanguage } from '../../../hoc/withLanguage';

import { getButtonsSimpleList } from '../../../redux/methods/visualSettings';
import useFetch from '../../../hooks/useFetch';

const propTypes = {
    form: PropTypes.object.isRequired,
    names: PropTypes.shape({
        button: PropTypes.string.isRequired,
        buttonLink: PropTypes.string,
        buttonId: PropTypes.string.isRequired,
    }),
    currentLanguage: PropTypes.string,
};

const FormFieldDefiningButton = ({
    form,
    names = {
        button: 'button',
        buttonColor: 'buttonColor',
        buttonFontColor: 'buttonFontColor',
        buttonLink: 'buttonLink',
        buttonIsTransparent: 'buttonIsTransparent',
        buttonId: 'buttonId'
    },
    currentLanguage,
    getButtonsSimpleList,
    customStructureName,
}) => {
    const translations = useIntl().messages.sharedComponents.definingButton;
    const [ buttonsList ] = useFetch(getButtonsSimpleList);

    const { values } = form.getState();
    const currentSelectionLabel =
        buttonsList && [{ id: 0, name: translations.none }, ...buttonsList].find(button => button.id === (customStructureName ? values[`${customStructureName}`].buttonId : values.buttonId));

    return buttonsList ? (
        <React.Fragment>
            <Row className='mt-2 px-3'>
                <FormFieldTranslations
                    form={form}
                    modalHeader={translations.button}
                    propertyName={names.button}
                    currentIso={currentLanguage}
                    label={translations.button}
                    withRightMargin={false}
                />
            </Row>
            <Row className='mt-2 px-3'>
                <FormFieldWithDropdown
                    label={translations.selectButton}
                    options={[{ id: 0, name: translations.none }, ...buttonsList]}
                    name={names.buttonId}
                    currentSelectionLabel={currentSelectionLabel ? currentSelectionLabel.name : 'Wybierz'}
                />
            </Row>
            {names.buttonLink && <Row className='mt-2 px-3'>
                <FormFieldTextOrNumber
                    label={translations.link}
                    name={names.buttonLink}
                    className='w-100'
                />
                <small>{translations.linkHelper}</small>
            </Row>}
        </React.Fragment>
    ) : null;
};

FormFieldDefiningButton.propTypes = propTypes;

const mapDispatch = {
    getButtonsSimpleList
};

export default withLanguage(connect(null, mapDispatch)(FormFieldDefiningButton));