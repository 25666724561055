import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeSectionPhotoWithTextImage } from '../../../../../redux/methods/homePage';

import FileSender from '../../../../../components/FileSender';

import { stringOrNumberPropTypes } from '../../../../../propTypes';

const propTypes = {
    forceRefetch: PropTypes.bool,
    removeSectionPhotoWithTextImage: PropTypes.func,
    translations: PropTypes.object,
    sectionId: stringOrNumberPropTypes,
};

const FileSenderPromotedSections = ({
    sectionId,
    removeSectionPhotoWithTextImage,
    translations,
    setFile,
    section,
}) => {

    const handleRemoveImage = () => {
        removeSectionPhotoWithTextImage(sectionId);
    };

    return (
        <FileSender
            validateFormats={['jpg', 'png', 'jpeg', 'gif', 'mp4', 'webm', 'webp']}
            buttons={translations.inputs.file}
            photo={section.imageSource}
            setFile={setFile}
            withPreview
            fileKey='Image'
            handleRemove={handleRemoveImage}
            graphicTypeToEnableGuide='productCard'
        />
    );
};

FileSenderPromotedSections.propTypes = propTypes;

const mapDispatch = {
    removeSectionPhotoWithTextImage,
};

export default connect(null, mapDispatch)(FileSenderPromotedSections);