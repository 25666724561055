import ExtrasGeneralSettings from '../../views/extrasSettings/ExtrasGeneralSettings';

export const extrasRoutes = {
    ROUTE_EXTRAS_GENERAL: {
        id: 'ROUTE_EXTRAS_GENERAL',
        path: '/extras/general',
        type: 'extras',
        exact: true,
        icon: 'cil-settings',
        component: ExtrasGeneralSettings,
    }
};