import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'reactstrap';

import { findConfigForBadge, convertBoolToNumericValue, findConfigForBadgeByLabel } from '../../../../../utils/components';
import { stringOrNumberPropTypes, statusConfigPropTypes } from '../../../../../propTypes';

const propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.bool, stringOrNumberPropTypes
    ]),
    badgeConfig: statusConfigPropTypes,
    className: PropTypes.string,
    onClickAction: PropTypes.func,
};

const BadgeCell = ({
    value,
    badgeConfig,
    className,
    onClickAction,
}) => {

    const selectedConfig =
        typeof value === 'boolean'
            ? findConfigForBadge(badgeConfig, convertBoolToNumericValue(value))
            : !isNaN(value)
                ? findConfigForBadge(badgeConfig, value)
                : typeof value === 'string' && findConfigForBadgeByLabel(badgeConfig, value)
    ;

    return (
        <td className={className} onClick={onClickAction}>
            <Badge color={selectedConfig.color} style={{ backgroundColor: selectedConfig.color}} pill>
                {selectedConfig.label}
            </Badge>
        </td>
    );
};

BadgeCell.propTypes = propTypes;

export default BadgeCell;