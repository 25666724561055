const searchEngine = {
    inputs: {
        searchPlaceholder: '[EN] Tekst w wyszukiwarce',
        minChar: '[EN] Minimalna liczba znaków do włączenia wyszukiwania',
        hintsCount: '[EN] Maksymalna liczba podpowiedzi',
        tags: '[EN] Pokazywany tag dla braku wyników',
        noProductsText: '[EN] Osteżenie o braku wyników',
        type: '[EN] Typ wyświetlania podpowiedzi'
    },
    typeOptions: {
        list: '[EN] Lista',
        tile: '[EN] Kafelki'
    },
    help: {
        range: '[EN] Suwakami ustawiamy moc danego kryterium.',
        disable: '[EN] Jeśli moc danego kryterium wynosi 0, zostaje on wyłączony przy wyszukiwaniu.',
        description: '[EN] Jeśli moc "Opisu" jest większa niż 0, użytkownik decyduje czy chce wyszukiwać po opisie poprzez zaznaczenie checkboxa w wyszukiwarce'
    },
    title: '[EN] Ustawienia wyszukiwania',
    button: '[EN] ZAPISZ ZMIANY',
    searchCriteria: '[EN] Kryteria wyszukiwania',
    options: '[EN] Opcje',
    checkboxes: {
        manufacturer: '[EN] Producent',
        catalogueindex: '[EN] Indeks katalogowy',
        tradeindex: '[EN] Indeks handlowy',
        ean: '[EN] Kod EAN',
        pkwiu: '[EN] Kod PKWIU',
        swwku: '[EN] Kod SWWKU',
        distinguisher: '[EN] Wyróżnik',
        name: '[EN] Nazwa',
        cnCode: '[EN] Kod CN',
        description: '[EN] Opis',
        resetPhraseAfterSearchResultShown: '[EN] Resetuj frazę po pokazaniu wyników wyszukiwania',
        resetCategoryAfterPhraseChange: '[EN] Resetuj kategorie po zmianie frazy',
        resetFiltersAfterCategoryChange: '[EN] Resetuj filtry po zmianie kategorii',
        resetSearchAfterAddProductToCart: '[EN] Resetuj wyszukiwanie po dodaniu produktu do koszyka',
        resetCategoryAfterFilterChange: '[EN] Resetuj kategorię po zmianie filtrów',
        resetPhraseAfterFilterChange: '[EN] Resetuj frazę po zmianie filtrów',
        searchWhileSettingFilter: '[EN] Wyszukuj podczas ustawiania filtrów',
        showSuggestionsForCurrentCategory: 'Pokazuj sugestie dla aktualnej kategorii'
    },
    nullOpt: 'None'
};

export default searchEngine;