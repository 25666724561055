const products = {
    view: {
        title: 'Lista artykułów',
        summaryProductModalLabel: 'Tworzenie artykułu zbiorczego',
        paginator: {
            previous: 'Poprzednia',
            next: 'Następna'
        },
        actions: {
            addProduct: 'DODAJ ARTYKUŁ',
            defineFillers: 'Aktywuj reklamy uzupełniające',
            addSummaryProduct: 'DODAJ ARTYKUŁ ZBIORCZY',
        },
        modal: {
            body: 'Wybierz kategorię dla której ma zostać utworzony produkt',
        },
        showCategoryTreeSelect: 'Pokaż filtr kategorii',
        hideCategoryTreeSelect: 'Ukryj filtr kategorii',
        categoryTree: {
            categoryTreeLabels: {
                erpCategories: 'Kategorie ERP',
                shopCategories: 'Kategorie Sklepowe'
            },
            generalCategoryLabel: 'Nazwa kategorii ogólnej',
            dropdownSelector: {
                label: 'Widok kategorii',
                options: {
                    all: 'Wszystkie',
                    active: 'Aktywne'
                }
            },
        },
    },
    searchForm: {
        productName: {
            label: 'Nazwa artykułu:',
            placeholder: ''
        },
        catalogueIndex: {
            label: 'Indeks katalogowy:',
            placeholder: ''
        },
        tradeIndex: {
            label: 'Indeks handlowy:',
            placeholder: ''
        },
        ean: {
            label: 'Kod kreskowy:',
            placeholder: ''
        },
        status: {
            label: 'Aktywność',
        },
        warning: {
            label: 'Zawierające uwagi'
        },
        promotion: {
            label: 'Będące w promocji'
        },
        active: 'Aktywne',
        inactive: 'Nieaktywne',
        action: 'SZUKAJ',
        reset: 'RESETUJ',
        defaultSearchLabel: 'Wszystkie',
        productType: 'Typ produktu',
        inSubcategories: 'Szukaj w podkategoriach',
        types: {
            defaultType: 'Wszystkie',
            None: 'Zwykłe',
            Set: 'Produkty zbiorcze',
            Variant: 'Warianty'
        }
    },
    edit: {
        tabSelectorTitle: 'Sekcje',
        tabs: {
            basic: {
                statusLabels: {
                    unpublished: 'Nieaktywny',
                    published: 'Aktywny',
                },
                title: 'Dane podstawowe',
                modalHeader: 'Nazwa artykułu',
                anyLabel: 'Dowolna',
                quantityLabel: 'Stan artykułu',
                buttons: {
                    seeDetails: 'Przejdź do artykułu',
                    defineFillers: 'Aktywuj reklamy uzupełniające',
                    disconnect: 'Odłącz'
                },
                types: {
                    Set: 'Zbiorczy',
                    Variant: 'Wariant',
                    None: 'Zwykły'
                },
                inputs: {
                    status: 'Status',
                    statusErp: 'Status Erp',
                    productName: 'Nazwa główna',
                    nameCD: 'Nazwa CD',
                    nameFromERP: 'Nazwa z Erp',
                    syncErp: 'Synchronizuj nazwy z Erp',
                    isMasked: 'Wymuś stan maskowany',
                    maskedQuantity: 'Ilość maskowana',
                    booked: 'Ilość zarezerowana',
                    quantity: 'Ilość na stanie',
                    minQuantity: 'Ilość minimalna',
                    catalogueIndex: 'Indeks katalogowy',
                    tradeIndex: 'Indeks handlowy',
                    mainCategory: 'Kategoria dla cech *',
                    manufacturer: 'Producent *',
                    pkwiu: 'PKWiU',
                    swwku: 'SWW/KU',
                    localisation: 'Lokalizacja',
                    distinguish: 'Wyróżnik',
                    cnCode: 'Kod CN',
                    weight: 'Waga',
                    weightUnit: 'Nazwa jednostki wagi',
                    ean: 'Kod EAN',
                    kgo: 'Stawka KGO',
                    measure: 'Typ miary *',
                    mainMeasureUnit: 'Główna jednostka miary',
                    country: 'Kraj pochodzenia',
                    select: 'Wybierz',
                    manufacturerIndex: 'Indeks producenta',
                    originalName: 'Nazwa oryginalna',
                    includeMinQuantity: 'Wymuś ilość minimalną',
                    externalShortDescriptionSync: 'Zewnętrzna synchronizacja krótkiego opisu',
                    externalShortDescription: 'Zewnętrzny krótki opis',
                    externalLongDescriptionSync: 'Zewnętrzna synchronizacja długiego opisu',
                    externalLongDescription: 'Zewnętrzny długi opis',
                    includeReservations: 'Odlicz rezerwacje',
                    isAddToCartHidden: 'Zablokuj dodawanie artykułu do koszyka',
                    externalId: 'Identyfikator zewnętrzny',
                    height: 'Wysokość',
                    width: 'Szerokość',
                    depth: 'Głębokość',
                    sizeUnit: 'Jednostka wielkości'
                },
                explanations: {
                    isMaskedTrue: '* artykuł będzie posiadał stałą ilość maskowaną',
                    isMaskedFalse: '* artykuł nie będzie posiadał stałej ilości maskowanej',
                    isMaskedNull: '* o tym czy artykuł będzie posiadał stałą ilość maskowaną zdecydują ustawienia globalne (sekcja Ustawienia -> Artykuł -> Stan artykułu)',

                    includeMinQuantityTrue: '* artykuł będzie posiadał stałą ilość minimalną',
                    includeMinQuantityFalse: '* artykuł nie będzie posiadał stałej ilości minimalnej',
                    includeMinQuantityNull: '* o tym czy artykuł będzie posiadał stałą ilość minimalną zdecydują ustawienia globalne (sekcja Ustawienia -> Artykuł -> Stan artykułu)',

                    includeReservationsTrue: '* od stanu artykułu będą odliczane rezerwacje',
                    includeReservationsFalse: '* od stanu artykułu nie będą odliczane rezerwacje',
                    includeReservationsNull: '* o tym czy od stanu artykułu będą odliczane rezerwacje zdecydują ustawienia globalne (sekcja Ustawienia -> Artykuł -> Stan artykułu)',

                    leaveNull: '* Pozostawienie powyższego pola pustego będzie powodować pobieranie wartości z ustawień globalnych (sekcja Ustawienia -> Artykuł -> Stan artykułu)'
                },
                isPromotionInfo: 'Artykuł jest w promocji',
                dateCreated: 'Data utworzenia',
                type: 'Typ',
                parentProduct: 'Artykuł nadrzędny',
                deleteOneModal: {
                    header: 'Odłączanie artykułu',
                    body: 'Czy jestes pewien, że chcesz odłączyć artykuł?'
                }
            },
            description: {
                title: 'Opis',
                headers: {
                    short: 'Opis skrócony',
                    long: 'Opis pełny'
                },
                buttons: {
                    save: 'ZAPISZ ZMIANY',
                    cancel: 'ANULUJ ZMIANY',
                }
            },
            photos: {
                title: 'Zdjęcia',
                addPhotoButton: 'DODAJ ZDJĘCIE',
                deletePhotoButton: 'USUŃ ZAZNACZONE ZDJĘCIA',
                cancel: 'ANULUJ ZMIANY',
                save: 'ZAPISZ ZMIANY',
                isDefaultText: 'Tak',
                isExternalTranslations: {
                    isExternal: 'Tak',
                    isNotExternal: 'Nie'
                },
                emptyAlternativeText: '[BRAK TŁUMACZENIA]',
                emptyAlternativeName: '[BRAK NAZWY]',
                emptyTranslation: 'Brak zdjęc do wyświetlenia',
                imageAlignmentTranslations: {
                    horizontal: 'Poziomo',
                    vertical: 'Pionowo',
                },
                alignmentText: {
                    default: 'Domyślne',
                    modified: 'Zmodyfikowane',
                },
                columnNames: {
                    active: 'Aktywne',
                    order: 'Kolejność',
                    photo: 'Zdjęcie',
                    name: 'Nazwa',
                    alternativeText: 'Tekst alternatywny',
                    isDefault: 'Domyślne',
                    isDefaultDouble: 'Domyślne podwójny kafelek',
                    isExternal: 'Pochodzi z ERP',
                    alignment: 'Wyrównanie',
                    actions: 'Akcje',
                },
                deleteModalTranslations: {
                    header: 'Usuwanie zdjęć',
                    body: 'Czy napewno chcesz usunąć zaznaczone zdjęcia?'
                },
                deleteSingleModalTranslations: {
                    header: 'Usuwanie zdjęcia',
                    body: 'Czy napewno chcesz usunąć zdjęcie?',
                    confirm: 'USUŃ',
                    cancel: 'ANULUJ',
                },
                tooltipTranslations: {
                    remove: 'Usuń zdjęcie',
                    edit: 'Edytuj zdjęcie',
                    showDetails: 'Pokaż przybliżenie',
                },
                editModal: {
                    buttons: {
                        confirm: 'ZAPISZ ZMIANY',
                        cancel: 'ANULUJ'
                    },
                    checkbox: 'Zdjęcie domyślne artykułu',
                    header: 'Edytuj zdjęcie',
                    inputWithFlagsLabel: 'Tekst alternatywny',
                    inputNameLabel: 'Nazwa',
                    horizontalAlignmentLabel: 'Wyrównanie w poziomie',
                    verticalAlignmentLabel: 'Wyrównanie w pionie',
                    tabTileName: 'Kafelek (podgląd)',
                    tabBannerName: 'Kafelek podwójny (podgląd)',
                    dropdown: {
                        center: 'Do środka',
                        bottom: 'Do dołu',
                        top: 'Do góry',
                        left: 'Do lewej',
                        right: 'Do prawej',
                    }
                }
            },
            metaTags: {
                title: 'Meta-tagi',
            },
            featuresAndAttributes: {
                title: 'Cechy / atrybuty',
                valuesTable: {
                    headers: {
                        name: 'Nazwa',
                        value: 'Wartość',
                        actions: 'Akcje'
                    },
                },
                editAttributes: 'Edytuj atrybuty produktu',
                editFeatures: 'Edytuj cechy produktu',
                labels: {
                    type: 'Typ atrybutu'
                },
                placeholders: {
                    type: 'Dowlony',
                    selectAttributes: 'Wybierz atrybuty',
                    selectFeatures: 'Wybierz cechy',
                    empty: 'Brak',
                    selected: 'Wybrano:'
                },
                tooltips: {
                    edit: 'Edit value',
                    add: 'Assign value',
                    remove: 'Unassign value'
                },
                attributeType: {
                    placeholder: 'Dowolny',
                    tag: 'Tag',
                    dictionary: 'Słownik',
                    int: 'Zakres liczbowy',
                    decimal: 'Zakres zmiennoprzecinkowy',
                    dateTime: 'Zakres dat',
                    color: 'Kolor',
                    boolean: 'Jedna z wartości: tak/nie',
                    string: 'Tekst'
                },
            },
            prices: {
                title: 'Ceny',
                basePrice: 'Cena domyślna',
                promoPrice: 'Cena promocyjna (zmiana trybu zeruje formularz ceny promocyjnej)',
                nonBaseOption: 'Brak ceny odniesienia',
                showAllCurrencies: 'Pokaż wszystkie waluty',
                showOnlyActiveCurrencies: 'Pokaż tylko aktywne waluty',
                inputs: {
                    netPrice: 'Cena netto',
                    grossPrice: 'Cena brutto',
                    tax: 'Stawka VAT',
                    discountRate: 'Przecena',
                    exchangeRate: 'Kurs wymiany',
                    dateFrom: 'Ważna od',
                    dateTo: 'Ważna do',
                    afterPromo: 'Cena brutto po promocji',
                    isPriceHidden: 'Ukryj cenę produktu'
                },
                checkboxes: {
                    undefined: 'Niezdefiniowana',
                    precentageDiscount: 'Rabat wyrażony w % w stosunku do ceny domyślnej',
                    definedPrice: 'Kwota zdefiniowana bezpośrednio',
                    exchangeRateTable: 'Cena przeliczana według tabeli kursów',
                    constantRate: 'Cena przeliczona według stałego kursu'
                }
            },
            measures: {
                title: 'Jednostki i miary',
                emptyTable: 'Brak jednostek',
                nullOpt: 'Brak',
                headers: {
                    name: {
                        label: 'Nazwa',
                        filler: '[BRAK JEDNOSTKI]'
                    },
                    measureType: {
                        label: 'Typ',
                        filler: '[BRAK TYPU]'
                    },
                    rate: {
                        label: 'Przelicznik',
                        filler: '[BRAK PRZELICZNIKA]'
                    }
                },
                measureTypes: {
                    storage: 'Magazynowania',
                    sales: 'Sprzedaży',
                    purchases: 'Zakupu',
                    reference: 'Referencyjna',
                    additional: 'Dodatkowa'
                },
                tooltips: {
                    edit: 'Edytuj jednostkę miary artykułu',
                    remove: 'Usuń jednostkę miary artykułu'
                },
                buttons: {
                    create: 'Dodaj'
                },
                editModal: {
                    title: 'Edycja jednostki miary artykułu',
                    buttons: {
                        save: 'ZAPISZ',
                        cancel: 'WRÓĆ'
                    },
                    inputs: {
                        measure: 'Jednostka miary',
                        rate: 'Przelicznik',
                        measureType: 'Typ'
                    }
                },
                deleteModal:{
                    header: 'Usuń jednostkę miary artykułu',
                    body: 'Czy jesteś pewien, że chcesz usunąć tę jednostkę miary artykułu?',
                    confirm: 'Usuń',
                    cancel: 'Wróć'
                }
            },
            additionalCategories: {
                title: 'Kategorie dodatkowe',
                emptyTranslation: 'Nie znaleziono dodatkowych kategorii przypisanych do produktu',
                columnNames: {
                    categories: 'Nazwa kategorii',
                    actions: 'Akcje',
                },
                buttons: {
                    delete: 'ODŁĄCZ ZAZNACZONE KATEGORIE',
                    add: 'PRZYPISZ KATEGORIE',
                    cancel: 'ANULUJ ZMIANY',
                },
                tooltipTranslation: 'Odłącz kategorie',
                deleteModalTranslations: {
                    header: 'Odłączanie kategorii',
                    body: 'Czy napewno chcesz odłączyć wszystkie zaznaczone kategorie od wybranego produktu?',
                },
                deleteSingleModalTranslations: {
                    header: 'Odłączanie kategorii',
                    bodyStart: 'Czy chcesz odłączyć kategorię',
                    bodyEnd: 'od wybranego produktu',
                    confirm: 'ODŁĄCZ',
                    cancel: 'ANULUJ'
                },
                categorySelectionModal: {
                    body: 'Wybierz kategorię która ma zostać przypisana do wybranego produktu',
                }
            },
            alternativeProducts: {
                title: 'Zamienniki',
                labels: {
                    isTwoWay: 'Dwustronna operacja'
                },
                tooltips: {
                    remove: 'Odłącz produkt',
                    edit: 'Edytuj',
                    isTwoWay: 'Przypisanie zamiennika artykułu A do artykułu B spowoduje, że artykuł B zostanie automatycznie zamiennikiem artykułu A.',
                    showDetails: 'Pokaż w sklepie',
                },
                empty: 'Brak produktów do wyświetlenia',
                headers: {
                    photo: 'Zdjęcie',
                    name: 'Nazwa artykułu',
                    catalogueIndex: 'Indeks katalogowy',
                    tradeIndex: 'Indeks handlowy',
                    actions: 'Akcje',
                },
                deleteModal: {
                    header: 'Odłączanie zamiennika',
                    body: 'Czy jestes pewien, że chcesz odłączyć zamiennik?',
                    confirm: 'ODŁĄCZ',
                    cancel: 'ANULUJ'
                },
                deleteManyModal: {
                    header: 'Odłączanie zamienników',
                    body: 'Czy jestes pewien, że chcesz odłączyć wybrane zamienniki?'
                },
                buttons: {
                    delete: 'ODŁĄCZ ZAZNACZONE ARTYKUŁY',
                    add: 'PRZYPISZ ARTYKUŁY',
                },
                fallbacks: {
                    name: '[BRAK NAZWY]',
                    catalogueIndex: '[BRAK INDEKSU KATALOGOWEGO]',
                    tradeIndex: '[BRAK INDEKSU HANDLOWEGO]',
                },
            },
            relatedProducts: {
                title: 'Artykuł powiązane',
                labels: {
                    isTwoWay: 'Dwustronna operacja'
                },
                tooltips: {
                    remove: 'Odłącz produkt',
                    edit: 'Edytuj',
                    isTwoWay: 'Powiązanie artykułu A z artykułem B spowoduje, że artykuł B zostanie automatycznie powiązany z artykułem A.'
                },
                empty: 'Brak produktów do wyświetlenia',
                headers: {
                    photo: 'Zdjęcie',
                    name: 'Nazwa artykułu',
                    catalogueIndex: 'Indeks katalogowy',
                    tradeIndex: 'Indeks handlowy',
                    actions: 'Akcje'
                },
                deleteModal: {
                    header: 'Odłączanie artykułu powiązanego',
                    body: 'Czy jestes pewien, że chcesz odłączyć artykuł powiązany?',
                    confirm: 'ODŁĄCZ',
                    cancel: 'ANULUJ'
                },
                deleteManyModal: {
                    header: 'Odłączanie artykułów powiązanych',
                    body: 'Czy jestes pewien, że chcesz odłączyć wybrane artykuł powiązane?'
                },
                buttons: {
                    delete: 'ODŁĄCZ ZAZNACZONE ARTYKUŁY',
                    add: 'PRZYPISZ ARTYKUŁY',
                },
                fallbacks: {
                    name: '[BRAK NAZWY]',
                    catalogueIndex: '[BRAK INDEKSU KATALOGOWEGO]',
                    tradeIndex: '[BRAK INDEKSU HANDLOWEGO]',
                },
            },
            opinions: {
                title: 'Opinie',
                removeTooltip: 'Usuń opinie',
                emptyTable: 'Brak opinii',
                deleteModal: {
                    header: 'Usuwanie opinii',
                    body: 'Czy na pewno chcesz usunąć opinię?',
                    confirm: 'USUŃ',
                    cancel: 'ANULUJ'
                },
                statuses: {
                    accepted: 'Zaakceptowana',
                    deleted: 'Usunięta',
                    notChecked: 'Niesprawdzona',
                },
                inputs: {
                    name: {
                        label: 'Nazwa',
                        filler: '[BRAK NAZWY]',
                    },
                    date: {
                        label: 'Data wystawienia',
                        filler: '[BRAK DATY]',
                    },
                    content: {
                        label: 'Treść',
                        filler: '[BRAK TREŚCI]',
                    },
                    isContentVisible: {
                        label: 'Treść widoczna',
                    },
                    rating: {
                        label: 'Ocena'
                    },
                    status: {
                        label: 'Status'
                    },
                    actions: {
                        label: 'Akcje'
                    }
                },
            },
            delivery: {
                title: 'Dostawa',
                fields: {
                    deliveryCost: 'Koszt dostawy',
                    deliveryTime: 'Czas dostawy w dniach (0-365)'
                },
                fromErp: 'Pole zmapowane z Erp',
            },
            variants: {
                title: 'Warianty',
                selectAttribute: 'Wybierz atrybuty',
                defaultSelect: 'Wybierz',
                selectedAttribute: 'Wybrano: ',
                variantSettingsHeadline: 'Ustawienia wariantów',
                variantsTypes: {
                    size: 'Rozmiar',
                    color: 'Kolor',
                },
                settingsLabels: {
                    dispplayUnavailableVariants: 'Pokazuj artykuły niedostępne',
                    displayMinStateInfo: ' Wyświetlaj informacje o stanie minimalnym (Ostatnie sztuki!).'
                },
                varianceAttributes: 'Atrybuty wariantujące',
                tooltips: {
                    remove: 'Odłącz wariant',
                    edit: 'Edytuj'
                },
                noAttributes: 'Brak atrybutów',
                chooseProductsGuide: 'Wybierz warianty oraz produkt wzorcowy.',
                noAttributesAlert: 'Nie znaleziono przypisanych atrybutów/cech dla danej kategorii. Przypisz atrybuty/cechy dla wybranej kategorii, aby kontynuwać tworzenie artykułu zbiorczego.',
                empty: 'Brak produktów do wyświetlenia',
                buttons: {
                    create: 'UTWÓRZ',
                    next: 'DALEJ',
                    prev: 'WRÓĆ',
                    delete: 'ODŁĄCZ ZAZNACZONE ARTYKUŁY',
                    add: 'PRZYPISZ ARTYKUŁY',
                },
                summaryProductSteps: {
                    chooseCategory: 'Wybierz kategorię, aby wyszukać przypisane do niej atrybuty wariantujace.',
                    chooseAttributes: 'Wybierz atrybuty wariantujace.',
                    chooseProducts: 'Wybierz warianty produktu głownego,',
                    chooseType: 'Wybierz typ wariantu',
                    chooseSettings: 'Wybierz ustawienia wyświetlania wariantów',
                    searchInAllCategories: 'Wyszukuj we wszystkich kategoriach'
                },
                headers: {
                    photo: 'Zdjęcie',
                    name: 'Nazwa artykułu',
                    catalogueIndex: 'Indeks katalogowy',
                    tradeIndex: 'Indeks handlowy',
                    actions: 'Akcje',
                },
                deleteModal: {
                    header: 'Odłączanie artykułu wariantującego',
                    body: 'Czy jestes pewien, że chcesz odłączyć artykuł wariantujący?',
                    confirm: 'ODŁĄCZ',
                    cancel: 'ANULUJ'
                },
                deleteManyModal: {
                    header: 'Odłączanie artykułów wariantujących',
                    body: 'Czy jestes pewien, że chcesz odłączyć wybrane artykuł wariantujący?'
                },
                fallbacks: {
                    name: '[BRAK NAZWY]',
                    catalogueIndex: '[BRAK INDEKSU KATALOGOWEGO]',
                    tradeIndex: '[BRAK INDEKSU HANDLOWEGO]',
                },
            }
        }
    },
    table: {
        buttons: {
            activate: 'Aktywuj',
            deactivate: 'Dezaktywuj',
            undistinguish: 'Wyłącz wyróżenie',
        },
        headers: {
            photo: 'Zdjęcie',
            name: 'Nazwa artykułu',
            catalogueIndex: 'Indeks katalogowy',
            tradeIndex: 'Indeks handlowy',
            actions: 'Akcje',
            category: 'Kategoria dla cech',
            active: 'Aktywna',
            type: 'Typ',
            templateVariant: 'Artykuł wzorcowy',
            price: 'Cena'
        },
        types: {
            Set: 'Zbiorczy',
            Variant: 'Wariant',
            None: 'Zwykły'
        },
        deleteModal: {
            header: 'Usuwanie artykułu',
            body: 'Czy jestes pewien, że chcesz usunąć artykuł?',
            confirm: 'USUŃ',
            cancel: 'ANULUJ'
        },
        tooltips: {
            edit: 'Edytuj artykuł',
            copy: 'Kopiuj produkt',
            showDetails: 'Pokaż szczegóły',
            remove: 'Usuń artykuł',
            add: 'DODAJ ARTYKUŁ',
            distinguish: 'Wyróżnienie produktu pozwoli Ci wyświetlić go w sklepie jako podwójny kafelek.'
        },
        emptyLogoAlt: 'logo zastępcze',
        emptyTable: 'Nie znaleziono żadnych artykułów',
        fallbacks: {
            name: '[BRAK NAZWY]',
            catalogueIndex: '[BRAK INDEKSU KATALOGOWEGO]',
            tradeIndex: '[BRAK INDEKSU HANDLOWEGO]',
        },
    },
    modalSearch: {
        header: 'Wybór artykułów',
        buttons: {
            confirm: 'DODAJ WYBRANE ARTYKUŁY',
            cancel: 'ANULUJ',
        }
    },
    settings: {
        tabSelectorTitle: 'Artykuły',
        tabs : {
            tab: 'Zakładka',
            settings: 'Ustawienia'
        },
        inputs: {
            tags: 'Wybierz sekcje według tagów',
            autoManualCaption: 'Określ sposób w jaki będą wybierane artykuły na zakładkę:',
            auto: 'Automatycznie na podstawie Tagu',
            manual: 'Ręcznie na podstawie wskazanych artykułów',
            name: 'Nazwa',
            enabled: 'Aktywna',
            tagFallback: 'Wybierz tag',
            showSelected: 'Pokaż tylko wybrane artykuły'
        },
        actions: {
            save: 'ZAPISZ ZMIANY'
        }
    },
    categoryAssign: {
        title: 'Przypisywanie do kategorii',
        showSelected: 'Pokaż zaznaczone',
        categoryTreeLabels: {
            erpCategories: 'Kategorie ERP',
            shopCategories: 'Kategorie Sklepowe'
        },
        generalCategoryLabel: '[PL] Nazwa kategorii ogólnej',
        dropdownSelector: {
            label: 'Widok kategorii',
            options: {
                all: 'Wszystkie',
                active: 'Aktywne'
            }
        },
        buttons: {
            add: 'PRZYPISZ KATEGORIE DLA WYBRANYCH PRODUKTÓW',
            remove: 'ODŁĄCZ PRODUKTY Z KATEGORII',
        },
        addModal: 'Wybierz katogorię do której mają zostać przypisane produkty',
        setModal: {
            header: 'Zmiana kategorii dla cech',
            body: 'Czy chcesz zmienić kategorię dla cech dla wskazanych artykułów?'
        },
        removeModal: {
            header: 'Odłączanie produktów z kategorii',
            body: 'Czy na pewno chcesz odłączyć produkty z wybranej kategorii?',
        },
        categoriesSection: {
            title: 'Przypisane kategorie',
            categoryForFeatures: 'Kategoria dla cech',
            empty: 'Brak przypisanych kategorii',
            changeModal: {
                body: 'Czy jesteś pewien że chcesz zmienić kategorię dla cech w wybranym produkcie?',
                header: 'Zmiana kategorii dla cech',
                cancel: 'ANULUJ',
                confirm: 'ZMIEŃ'
            }
        }
    },
    comparison: {
        labels: {
            additionalFields: 'Widoczność pól dodatkowych',
            save: 'Zapisz'
        }
    }
};

export default products;