import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import useFetch from '../../../hooks/useFetch';

import { Button } from 'reactstrap';
import { getGoogleFonts, getSelectedFonts, updateSelectedFonts } from '../../../redux/methods/fonts';

import AssignerDynamicList from '../../../components/AssignerDynamicList';
import ContainerWithCard from '../../../components/ContainerWithCard';

import { translateFontFamilyToList, translateListToFontFamily, FontListItem } from '../../../utils/fonts';

const propTypes = {
    translations: PropTypes.object,
    getGoogleFonts: PropTypes.func,
    getSelectedFonts: PropTypes.func,
    updateSelectedFonts: PropTypes.func,
};

const Fonts = ({
    getGoogleFonts,
    updateSelectedFonts,
    forceRefetch,
    selectedFonts,
    getSelectedFonts,
    fontConfig
}) => {
    const intl = useIntl();
    const translations = intl.messages.fonts;

    const [ fonts ] = useFetch(getGoogleFonts);

    const [ isOnlyExtLatinFonts, setIsOnlyExtLatinFonts ] = useState(true);

    useEffect(() => {
        if(forceRefetch) {
            getSelectedFonts();
        }
    }, [forceRefetch, getSelectedFonts]);

    const handleConfirm = (values) => {
        updateSelectedFonts({ fonts: values });
    };

    return fonts && selectedFonts ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-font'>
            <AssignerDynamicList
                listItem={FontListItem}
                lists={{
                    left: translateFontFamilyToList(isOnlyExtLatinFonts ? fonts.filter(font => font.subsets.includes('latin-ext')) : fonts),
                    right: translateListToFontFamily(selectedFonts, fontConfig),
                    customHeaderLeft: (
                        <Button
                            onClick={() => setIsOnlyExtLatinFonts(!isOnlyExtLatinFonts)}
                            color='warning'
                        >
                            {
                                isOnlyExtLatinFonts ? translations.polishFonts : translations.allFonts
                            }
                    </Button>
                    )
                }}
                isOpen={true}
                onConfirm={handleConfirm}
                isLoading={false}
                translations={{
                    header: translations.assigner.header,
                    buttons: {
                        cancel: {
                            color: 'danger',
                            label: translations.assigner.buttons.cancel,
                        },
                        confirm: {
                            color: 'success',
                            label: translations.assigner.buttons.confirm,
                        },
                    },
                    toAssign: translations.assigner.toAssign,
                    assigned: translations.assigner.assigned,
                }}
            />
        </ContainerWithCard>
    ) : null;
};

Fonts.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.fonts,
    selectedFonts: state.fonts.fonts,
    fontConfig: state.fonts.fontConfig,
});

const mapDispatch = {
    getGoogleFonts,
    getSelectedFonts,
    updateSelectedFonts
};

export default connect(mapStateToProps, mapDispatch)(Fonts);