import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, Row } from 'reactstrap';
import clsx from 'clsx';

const propTypes = {
    customButtons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            labelTrue: PropTypes.string,
            labelFalse: PropTypes.string,
            isDisabled: PropTypes.bool,
            color: PropTypes.string,
            action: PropTypes.func,
            type: PropTypes.oneOf(['selector'])
        })
    ).isRequired,
};

const CustomButtons = ({
    customButtons
}) => {

    const leftButtons = customButtons.filter(cb => (cb.location ?? 'right') === 'left');
    const rightButtons = customButtons.filter(cb => (cb.location ?? 'right') === 'right');

    const leftButtonsPresent = leftButtons.length > 0;
    const rightButtonsPresent = rightButtons.length > 0;

    const renderButton = (button, index) => {
        switch(button.type) {
            case 'selector':
                return (
                    <ButtonGroup className='ml-2' key={`${index}-customButton-group-tableGenerator`}>
                        <Button
                            disabled={button.isDisabled}
                            color={button.colorTrue || 'primary'}
                            onClick={button.action(true)}
                        >
                            {button.labelTrue}
                        </Button>
                        <Button
                            disabled={button.isDisabled}
                            color={button.colorFalse || 'secondary'}
                            onClick={button.action(false)}
                        >
                            {button.labelFalse}
                        </Button>
                    </ButtonGroup>
                );
            default:
                return (
                    <Button
                        disabled={button.isDisabled}
                        key={`${index}-customButton-tableGenerator`}
                        className='mx-2'
                        color={button.color || 'primary'}
                        onClick={button.action}
                    >
                        {button.label}
                    </Button>
                );
        }
    };

    return customButtons.length > 0 ? (
        <Row>
        {leftButtonsPresent && (
            <aside className={clsx('my-3 d-flex justify-content-start', rightButtonsPresent ? 'col-6' : 'col-12')}>
                {leftButtons.map((button, index) => renderButton(button,index))}
            </aside>
        )}
        {rightButtonsPresent && (
            <aside className={clsx('my-3 d-flex justify-content-start', rightButtonsPresent ? 'col-6' : 'col-12')}>
                {rightButtons.map((button, index) => renderButton(button,index))}
            </aside>
        )}
        </Row>
    ) : null;
};

CustomButtons.propTypes= propTypes;

export default CustomButtons;