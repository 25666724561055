import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getOpinions, editOpinions } from '../../../redux/methods/report';
import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';
import useFetch from '../../../hooks/useFetch';

import ContainerWithCard from '../../../components/ContainerWithCard';
import OpinionsForApprovalTable from '../../../components/tables/OpinionsForApprovalTable';
import { getTranslationsFromArray } from '../../../utils/getTranslationsFromArray';

const propTypes = {
    getOpinions: PropTypes.func,
    forceRefetch: PropTypes.bool,
    lang: PropTypes.string,
};

const Opinions = ({ getOpinions, forceRefetch, lang, editOpinions }) => {
    const intl = useIntl();
    const translations = intl.messages.notifications;

    const [ opinions ] = useFetch(getOpinions, null, [], forceRefetch);

    const basicStructureOfRequest = {
        productReviewsIds: [],
        isContentVisible: null,
        isNameVisible: null,
        accepted: null,
        deleted: null
    };

    const handleRemove = id => {
        editOpinions({
            ...basicStructureOfRequest,
            deleted: true,
            productReviewsIds: [id]
        });
    };

    const handleSelectedAction = (isAccepting, ids) => {
        if(isAccepting) {
            editOpinions({
                ...basicStructureOfRequest,
                accepted: true,
                productReviewsIds: ids
            });
        } else {
            editOpinions({
                ...basicStructureOfRequest,
                deleted: true,
                productReviewsIds: ids
            });
        }
    };

    const translateOpinions = opinions => {
        return opinions.map(opinion => ({
            ...opinion,
            productName: getTranslationsFromArray(opinion.productName, lang)
        }));
    };

    const handleAcceptAction = id => {
        editOpinions({
            ...basicStructureOfRequest,
            accepted: true,
            productReviewsIds: [id]
        });
    };

    return opinions ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-bell'>
            <OpinionsForApprovalTable
                opinions={translateOpinions(opinions)}
                removeAction={handleRemove}
                handleSelectedAction={handleSelectedAction}
                handleAcceptAction={handleAcceptAction}
            />

        </ContainerWithCard>
    ) : <ContainerWithCard/>;
};

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.opinions,
    lang: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
    getOpinions,
    editOpinions,
};

Opinions.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatch)(Opinions);