import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';

import { connect } from 'react-redux';
import { selectIsAuthenticated } from '../../redux/selectors/auth';
import { verifyUser } from '../../redux/methods/auth';

import FullViewSpinnerWrapper from '../../components/loaders/FullViewSpinnerWrapper';
import AuthRouter from '../AuthRouter';
import MainRouter from '../MainRouter';

const propTypes = {
    afterVerification: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    verifyUser: PropTypes.func
};

const RootRouter = ({ afterVerification, isAuthenticated, verifyUser }) => {
    useEffect(() => {
        verifyUser();
    }, []); // eslint-disable-line

    return (
        <FullViewSpinnerWrapper isLoading={!afterVerification}>
            <Router>
                {
                    isAuthenticated ? (
                        <MainRouter />
                    ) : (
                        <AuthRouter />
                    )
                }
            </Router>
        </FullViewSpinnerWrapper>
    );
};

RootRouter.propTypes = propTypes;

const mapStateToProps = state => ({
    afterVerification: state.auth.afterVerification,
    isAuthenticated: selectIsAuthenticated(state)
});

const mapDispatch = {
    verifyUser
};

export default connect(mapStateToProps, mapDispatch)(RootRouter);