const card = {
    view: {
        title: 'Prezentacja artykułów > Karta artykułu',
        actions: {
            save: 'ZAPISZ ZMIANY',
        },
        headerLabels: {
            isVisible: 'Widoczny',
            name: 'Nazwa parametru',
        },
        tabs: {
            settings: 'Ustawienia',
            tabs: 'Zakładki',
        },
        table: {
            columnNames: {
                order: 'Kolejność',
                name: 'Nazwa',
                active: 'Aktywna',
                default: {
                    label: 'Domyślna',
                    tooltip: 'Jako domyślną można ustawić przewidzianą, aktywną zakładkę'
                }
            },
            labels: {
                availability: 'Dostępność',
                catalogueindex: 'Indeks katalogowy',
                category: 'Kategoria',
                cncode: 'Kod CN',
                country: 'Kraj pochodzenia',
                distinguisher: 'Wyróżnik',
                ean: 'Kod kreskowy',
                id: 'Identyfikator',
                kgo: 'KGO',
                localisation: 'Lokalizacja',
                manufacturerLogo: 'Logo producenta',
                manufacturerName: 'Producent',
                name: 'Nazwa',
                pkwiu: 'PKWiU',
                quantity: 'Ilość',
                swwku: 'SWW/KU',
                tax: 'Podatek',
                tradeindex: 'Indeks handlowy',
                weight: 'Waga',
                measure: 'Jednostka',
                manufacturerindex: 'Indeks producenta',
                originalname: 'Nazwa oryginalna',
                externalid: 'Zewnętrzne id',
                deliverycost: 'Koszt dostawy',
                deliverytime: 'Termin dostawy',
                field1: 'Pole dodatkowe 1',
                field2: 'Pole dodatkowe 2',
                field3: 'Pole dodatkowe 3',
                field4: 'Pole dodatkowe 4',
                field5: 'Pole dodatkowe 5',
                field6: 'Pole dodatkowe 6',
                field7: 'Pole dodatkowe 7',
                field8: 'Pole dodatkowe 8',
                field9: 'Pole dodatkowe 9',
                field10: 'Pole dodatkowe 10',
                comments: 'Komentarze'
            }
        }
    },
};

export default card;