import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { Input, FormFeedback } from 'reactstrap';

const propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    validate: PropTypes.func
};

const LoginField = ({ name, type, autoComplete, placeholder, disabled, validate} ) => {
    return (
        <Field name={name} validate={validate}>
            {
                props => {
                    const isError = (props.meta.error || props.meta.submitError) && props.meta.touched;

                    return (
                        <React.Fragment>
                            <Input
                                name={name}
                                type={type}
                                autoComplete={autoComplete}
                                value={props.input.value}
                                invalid={isError ? true : false}
                                onChange={props.input.onChange}
                                placeholder={placeholder}
                                disabled={disabled}
                            />
                            { isError && <FormFeedback>{props.meta.error || props.meta.submitError}</FormFeedback>}
                        </React.Fragment>
                    );
                }
            }
        </Field>
    );
};

LoginField.propTypes = propTypes;

export default LoginField;