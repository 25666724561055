import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { generateFormToSubmitValues, generateFormInitialValues } from '../../utils/components';
import useFetch from '../../hooks/useFetch';
import { nestedButtonsGenerator } from '../../utils/emails';

import { withFormUtils } from '../../hoc/withFormUtils';
import { Form as FinalForm } from "react-final-form";
import { Form, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row } from "reactstrap";
import FormFieldTranslations from '../formFields/FormFieldTranslations';
import FormFieldTranslationsQuill from '../formFields/FormFieldTranslationsQuill';
import WarningModal from '../modals/WarningModal';

import { formUtilsHocPropTypes, stringOrNumberPropTypes } from '../../propTypes';
import FormFieldWithCheckbox from "../formFields/FormFieldWithCheckbox";
import FormFieldEmailList from "../formFields/FormFieldEmailList";
import FormFieldWithDropdown from "../formFields/FormFieldWithDropdown";

const propTypes = {
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    utils: formUtilsHocPropTypes,
    translations: PropTypes.shape({
        inputs: PropTypes.shape({
            file: PropTypes.shape({
                upload: PropTypes.string,
                remove: PropTypes.string,
                edit: PropTypes.string,
            }),
            alternativeText: PropTypes.string,
            dateTo: PropTypes.string,
            dateFrom: PropTypes.string,
        }),
        actions: PropTypes.shape({
            save: PropTypes.string,
            calncel: PropTypes.string,
        })
    }),
    modalHeader: PropTypes.string,
    getEmailTemplate: PropTypes.func,
    templateId: stringOrNumberPropTypes,
};

const EditEmailTemplateModal =  ({
    translations,
    isModalOpen,
    setIsModalOpen,
    onSubmit,
    utils,
    getEmailTemplate,
    templateId,
    modalHeader,
}) => {
    const isFormChanged = useRef();
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);

    const [ template ] = useFetch(getEmailTemplate, templateId, [templateId]);

    const config = {
        translations: ['content', 'title'],
    };

    const handleSubmit = form => {
        onSubmit(templateId, generateFormToSubmitValues(form, config, utils.allLanguages()));
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const toggleModal = () => {
        if (isFormChanged.current) {
            setIsWarningModalOpen(true);
        }
        else {
            setIsModalOpen(!isModalOpen);
        }
    };

    return template ? (
        <Modal isOpen={isModalOpen} toggle={toggleModal} size='full'>
            <ModalHeader toggle={toggleModal}>{modalHeader}</ModalHeader>
            <FinalForm
                onSubmit={handleSubmit}
                initialValues={generateFormInitialValues(template.emailTemplate, config, utils.allLanguages())}
                render={({ handleSubmit , form, pristine }) => {
                    isFormChanged.current = !pristine;

                    const handleCustomButtonGenerator = (editor) => {
                        nestedButtonsGenerator(editor, template.params, utils.currentLanguage(), translations.insertValueButton);
                    };

                    return (
                        <Form onSubmit={handleSubmit} className='w-100 p-0'>
                            <ModalBody>
                                <Row>
                                    <div className="col-6">
                                        <div className="card">
                                            <div className="card-header">{translations.sections.general}</div>
                                            <div className="card-body">
                                                <FormFieldTranslations
                                                    form={form}
                                                    modalHeader={translations.inputs.title}
                                                    propertyName='title'
                                                    currentIso={utils.currentLanguage()}
                                                    label={translations.inputs.title}
                                                />
                                                <FormFieldWithCheckbox
                                                    className="m-2"
                                                    name='sendToAdmin'
                                                    label={translations.inputs.sendToAdmin}
                                                />
                                                <FormFieldWithDropdown
                                                    label={translations.inputs.account}
                                                    name='account'
                                                    options={template.accounts}
                                                    currentSelectionLabel={template.emailTemplate.account}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card">
                                            <div className="card-header">{translations.sections.additionalEmails}</div>
                                            <div className="card-body">
                                                <FormFieldEmailList
                                                    name='additionalEmails'
                                                    label={translations.inputs.additionalEmails}
                                                    addButtonText={translations.inputs.addButtonText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <div>
                                    <FormFieldTranslationsQuill
                                        form={form}
                                        utils={utils}
                                        propertyName="content"
                                        header={translations.inputs.content}
                                        tinyMCEButtonGenerator={handleCustomButtonGenerator}
                                    />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" type="submit">
                                    {translations.actions.save}
                                </Button>
                                <Button color="secondary" type="button" onClick={handleCancel}>
                                    {translations.actions.cancel}
                                </Button>
                            </ModalFooter>
                            <WarningModal
                                setIsOpen={setIsWarningModalOpen}
                                isOpen={isWarningModalOpen}
                                setIsMainModalOpen={setIsModalOpen}
                            />
                        </Form>
                    );
                }}
            />
        </Modal>
    ) : (
        null
    );
};

EditEmailTemplateModal.propTypes = propTypes;

export default withFormUtils(EditEmailTemplateModal);