import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import useFetch from '../../../hooks/useFetch';
import { getEmailTemplateTable, updateEmailTemplateTable } from '../../../redux/methods/emails';

import { Container, Card, CardBody, CardHeader } from 'reactstrap';

import styles from './index.module.scss';

import EmailTemplatesTablesHeaderStyleTable from '../../../components/tables/EmailTemplatesTablesHeaderStyleTable';
import EmailTemplatesTablesColumnsStyleTable from '../../../components/tables/EmailTemplatesTablesColumnsStyleTable';
import EmailTemplatesTablesPreviewTable from '../../../components/tables/EmailTemplatesTablesPreviewTable';
import SaveAndBackButtonsSection from '../../../components/SaveAndBackButtonsSection';

const propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getEmailTemplateTable: PropTypes.func
};

const EmailTableDefinition = ({ history, location, getEmailTemplateTable, updateEmailTemplateTable }) => {
    const intl = useIntl();
    const translations = intl.messages.emails.emailTemplateTable;

    /**
    * Deconstruct query params
    */
    const query = new URLSearchParams(location.search);
    const chosenTemplateId = parseInt(query.get('templateId'));
    const chosenTableId = parseInt(query.get('tableId'));

    const [ tableStyle, setTableStyle ] = useState(null);

    const [ tableStyleResult ] = useFetch(getEmailTemplateTable, {templateId: chosenTemplateId, tableId: chosenTableId});

    const [ refreshPreview, setRefreshPreview ] = useState(0);
    const [ refreshColumns, setRefreshColumns ] = useState(0);

    useEffect(() => {
        if (tableStyleResult) {
            setTableStyle(tableStyleResult);
        }
    }, [tableStyleResult]);

    const draggableColumns = {
        items: tableStyle ? tableStyle.columnsStyle : [],
        setItems: sortFunc => {
            tableStyle.columnsStyle = sortFunc(tableStyle.columnsStyle);
            setTableStyle(tableStyle);
            setRefreshColumns(refreshColumns+1);
        }
    };

    const fonts = [
        { id: 'Tahoma', label: 'Tahoma' },
        { id: 'Verdana', label: 'Verdana' },
        { id: 'Arial', label: 'Arial' }
    ];

    const horizontalAlignments = [
        { id: '',  label: translations.horizontalAlignments.undefined },
        { id: 'Left', label: translations.horizontalAlignments.left },
        { id: 'Center', label: translations.horizontalAlignments.center },
        { id: 'Right', label: translations.horizontalAlignments.right },
    ];

    const verticalAlignments = [
        { id: '',  label: translations.verticalAlignments.undefined },
        { id: 'Top', label: translations.verticalAlignments.top },
        { id: 'Center', label: translations.verticalAlignments.center },
        { id: 'Bottom', label: translations.verticalAlignments.bottom },
    ];

    const handleHeaderChange = (id, value, _, fieldName) => {
        tableStyle.headerStyle[fieldName] = value;

        setTableStyle(tableStyle);
        setRefreshPreview(refreshPreview + 1);
    };

    const handleColumnChange = (id, value, _, fieldName) => {
        const c = tableStyle.columnsStyle.find(c => c.id === id);
        c[fieldName] = value;
        setTableStyle(tableStyle);
        setRefreshPreview(refreshPreview + 1);
    };

    const onBack = () => {
        history.push(`/emailSettings/templates/tables?templateId=${chosenTemplateId}`);
    };

    const onSave = () => {
        updateEmailTemplateTable(
            chosenTemplateId,
            chosenTableId,
            {
                templateId:chosenTemplateId,
                tableId: chosenTableId,
                header: tableStyle.headerStyle,
                columns: draggableColumns.items.map((element, idx) => {
                    element.orderIndex = idx;
                    return element;
                })
            }
        );
    };

    return tableStyle && tableStyle.columnsStyle ?  (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Card>
                    <CardHeader>
                        {translations.viewTableHeader}
                    </CardHeader>
                    <CardBody>
                        <EmailTemplatesTablesHeaderStyleTable
                            items={[tableStyle.headerStyle]}
                            fonts={fonts}
                            verticalAlignments={verticalAlignments}
                            horizontalAlignments={horizontalAlignments}
                            translations={translations}
                            handleHeaderChange={handleHeaderChange}
                        />
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        {translations.viewColumnsHeader}
                    </CardHeader>
                    <CardBody>
                        <EmailTemplatesTablesColumnsStyleTable
                            draggableColumns={draggableColumns}
                            fonts={fonts}
                            verticalAlignments={verticalAlignments}
                            horizontalAlignments={horizontalAlignments}
                            translations={translations}
                            handleColumnChange={handleColumnChange}
                            refresh={refreshColumns}
                        />
                    </CardBody>
                </Card>
                <Card className='stickyCard_EmailTemplatesTablesPreviewTable'>
                    <CardHeader>
                        {translations.previewHeader}
                    </CardHeader>
                    <CardBody>
                        <EmailTemplatesTablesPreviewTable
                            headerDef={tableStyle.headerStyle}
                            columnsDef={tableStyle.columnsStyle}
                            refresh={refreshPreview}
                        />
                        <SaveAndBackButtonsSection
                            onBack={onBack}
                            onSave={onSave}
                        />
                    </CardBody>
                </Card>
            </div>
        </Container>
    ) : null;
};

EmailTableDefinition.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.emailTemplates,
    isLoading: state.loaders.global,
});

const mapDispatch = {
    getEmailTemplateTable,
    updateEmailTemplateTable
};

export default connect(mapStateToProps, mapDispatch)(EmailTableDefinition);