import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getAllTagAttributes, getManufacturerFields, getProductPresentationTile, updateProductPresentationTile, getProductPresentationCart, updateProductPresentationCart } from '../../../redux/methods/products';

import { handleCheckboxAction } from '../../../utils/components';
import useFetch from '../../../hooks/useFetch';

import { Container, Form, TabContent } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import SectionEditFormButtons from '../../../components/SectionEditFormButtons';

import { itemCountTile } from '../../../config';

import styles from './index.module.scss';

import ProductTileDeviceSettings from '../ProductTileDeviceSettings';

import TabulatorNav from '../../../components/TabulatorNav';

const propTypes = {
    getAllTagAttributes: PropTypes.func,
    getProductPresentationTile: PropTypes.func,
    updateProductPresentationTile: PropTypes.func,
    translations: PropTypes.object,
    getManufacturerFields: PropTypes.func,
    getProductPresentationCart: PropTypes.func,
    updateProductPresentationCart: PropTypes.func
};

const ProductTileSettings = ({
    getAllTagAttributes,
    getProductPresentationTile,
    updateProductPresentationTile,
    translations,
    getManufacturerFields,
    getProductPresentationCart,
    updateProductPresentationCart
}) => {
    const intl = useIntl();

    const translationsAdditionalFields = intl.messages.card.view;

    const sortByOrder = (device, fields) => fields.sort((a,b) => (a.parameters.tile[device].order < b.parameters.tile[device].order) ? -1 : 1);

    const [ tilePresentation ] = useFetch(getProductPresentationTile);
    const [ additionalFields ] = useFetch(getProductPresentationCart, 'tile', []);
    const [ allTagAttributes ] = useFetch(getAllTagAttributes);
    const [ manufacturerFields ] = useFetch(getManufacturerFields);

    const [ activeTab, setActiveTab ] = useState(0);

    const [ additionalFieldsToEditDesktop, setAdditionalFieldsToEditDesktop ] = useState([]);
    const [ selectedAdditionalFieldIdsDesktop, setSelectedAdditionalFieldIdsDesktop ] = useState([]);
    const [ selectedAdditionalField2IdsDesktop, setSelectedAdditionalField2IdsDesktop ] = useState([]);
    const [ selectedManufacturerFieldIdsDesktop, setSelectedManufacturerFieldIdsDesktop ] = useState([]);

    const [ additionalFieldsToEditTablet, setAdditionalFieldsToEditTablet ] = useState([]);
    const [ selectedAdditionalFieldIdsTablet, setSelectedAdditionalFieldIdsTablet ] = useState([]);
    const [ selectedAdditionalField2IdsTablet, setSelectedAdditionalField2IdsTablet ] = useState([]);
    const [ selectedManufacturerFieldIdsTablet, setSelectedManufacturerFieldIdsTablet ] = useState([]);

    const [ additionalFieldsToEditMobile, setAdditionalFieldsToEditMobile ] = useState([]);
    const [ selectedAdditionalFieldIdsMobile, setSelectedAdditionalFieldIdsMobile ] = useState([]);
    const [ selectedAdditionalField2IdsMobile, setSelectedAdditionalField2IdsMobile ] = useState([]);
    const [ selectedManufacturerFieldIdsMobile, setSelectedManufacturerFieldIdsMobile ] = useState([]);

    const handleOnSubmit = formData => {
        const updateObject = {
            desktop: {
                ...formData.desktop,
                additionalField: selectedAdditionalFieldIdsDesktop,
                additionalField2: selectedAdditionalField2IdsDesktop,
                manufacturerField: selectedManufacturerFieldIdsDesktop,
                itemCount: parseInt(formData.desktop.itemCount)
            },
            tablet: {
                ...formData.tablet,
                additionalField: selectedAdditionalFieldIdsTablet,
                additionalField2: selectedAdditionalField2IdsTablet,
                manufacturerField: selectedManufacturerFieldIdsTablet,
                itemCount: parseInt(formData.tablet.itemCount)
            },
            mobile: {
                ...formData.mobile,
                additionalField: selectedAdditionalFieldIdsMobile,
                additionalField2: selectedAdditionalField2IdsMobile,
                manufacturerField: selectedManufacturerFieldIdsMobile,
                itemCount: parseInt(formData.mobile.itemCount)
            }
        };

        updateProductPresentationTile(updateObject);

        const extractTileParams = (tag, device, list) => {
            const entry = list.find(p => p.tag === tag);
            return {
                show: entry.parameters.tile[device].show,
                order: list.indexOf(entry)
            };
        };

        const generatedArray = additionalFieldsToEditDesktop.map(param => ({
            ...param,
            parameters: {
                ...param.parameters,
                tile: {
                    desktop: extractTileParams(param.tag, 'desktop', additionalFieldsToEditDesktop),
                    tablet: extractTileParams(param.tag, 'tablet', additionalFieldsToEditTablet),
                    mobile: extractTileParams(param.tag, 'mobile', additionalFieldsToEditMobile),
                }
            }
        }));

        updateProductPresentationCart({
            updateMain: false,
            updateTab: false,
            updateList: false,
            updateTile: true,
            updateComparison: false,
            fields: generatedArray,
        });
    };

    const handleAdditionalFieldsSelectionDesktop = id => handleCheckboxAction(id, selectedAdditionalFieldIdsDesktop, setSelectedAdditionalFieldIdsDesktop);
    const handleAdditionalFieldsSelectionTablet = id => handleCheckboxAction(id, selectedAdditionalFieldIdsTablet, setSelectedAdditionalFieldIdsTablet);
    const handleAdditionalFieldsSelectionMobile = id => handleCheckboxAction(id, selectedAdditionalFieldIdsMobile, setSelectedAdditionalFieldIdsMobile);

    const handleAdditionalFields2SelectionDesktop = id => handleCheckboxAction(id, selectedAdditionalField2IdsDesktop, setSelectedAdditionalField2IdsDesktop);
    const handleAdditionalFields2SelectionTablet = id => handleCheckboxAction(id, selectedAdditionalField2IdsTablet, setSelectedAdditionalField2IdsTablet);
    const handleAdditionalFields2SelectionMobile = id => handleCheckboxAction(id, selectedAdditionalField2IdsMobile, setSelectedAdditionalField2IdsMobile);

    const handleManufacturerFieldSelectionDesktop = id => handleCheckboxAction(id, selectedManufacturerFieldIdsDesktop, setSelectedManufacturerFieldIdsDesktop);
    const handleManufacturerFieldSelectionTablet = id => handleCheckboxAction(id, selectedManufacturerFieldIdsTablet, setSelectedManufacturerFieldIdsTablet);
    const handleManufacturerFieldSelectionMobile = id => handleCheckboxAction(id, selectedManufacturerFieldIdsMobile, setSelectedManufacturerFieldIdsMobile);

    useEffect(() => {
        if(tilePresentation) {
            if (tilePresentation.desktop && tilePresentation.desktop.manufacturerField) {
                setSelectedAdditionalFieldIdsDesktop(tilePresentation.desktop.additionalField);
                setSelectedAdditionalField2IdsDesktop(tilePresentation.desktop.additionalField2);
                setSelectedManufacturerFieldIdsDesktop(tilePresentation.desktop.manufacturerField);
            }
            if (tilePresentation.tablet && tilePresentation.tablet.manufacturerField) {
                setSelectedAdditionalFieldIdsTablet(tilePresentation.tablet.additionalField);
                setSelectedAdditionalField2IdsTablet(tilePresentation.tablet.additionalField2);
                setSelectedManufacturerFieldIdsTablet(tilePresentation.tablet.manufacturerField);
            }
            if (tilePresentation.mobile && tilePresentation.mobile.manufacturerField) {
                setSelectedAdditionalFieldIdsMobile(tilePresentation.mobile.additionalField);
                setSelectedAdditionalField2IdsMobile(tilePresentation.mobile.additionalField2);
                setSelectedManufacturerFieldIdsMobile(tilePresentation.mobile.manufacturerField);
            }
        }
    }, [tilePresentation]);

    useEffect(() => {
        if(additionalFields){
            const { fields } = additionalFields;

            setAdditionalFieldsToEditDesktop(sortByOrder('desktop', [...(JSON.parse(JSON.stringify(fields)))]));
            setAdditionalFieldsToEditTablet(sortByOrder('tablet', [...(JSON.parse(JSON.stringify(fields)))]));
            setAdditionalFieldsToEditMobile(sortByOrder('mobile', [...(JSON.parse(JSON.stringify(fields)))]));
        }

        return () => {
            setAdditionalFieldsToEditDesktop([]);
            setAdditionalFieldsToEditTablet([]);
            setAdditionalFieldsToEditMobile([]);
        };
    }, [additionalFields]);

    const allTagAttributesOptions = allTagAttributes && allTagAttributes.attributes && [ ...Object.keys(allTagAttributes.attributes).map(key => ({
        id: key,
        label: allTagAttributes.attributes[key]
    }))];

    const allTagAttributes2Options = allTagAttributes && allTagAttributes.features && [ ...Object.keys(allTagAttributes.features).map(key => ({
        id: key,
        label: allTagAttributes.features[key]
    }))];

    const numbersDropdownOptions = [
        { id: 0, label: "0" },
        { id: 1, label: "1" },
        { id: 2, label: "2" },
        { id: 3, label: "3" },
    ];

    const additionalFieldSelectionLabel = selectedAdditionalFieldIds => selectedAdditionalFieldIds.length === 0
        ? '-- brak --'
        : allTagAttributesOptions
            .filter(m => selectedAdditionalFieldIds.includes(m.id))
            .map(m => m.label)
            .reduce((prev,curr) => prev + (prev ? ', ' : '') + curr, '');

    const additionalField2SelectionLabel = selectedAdditionalField2Ids => selectedAdditionalField2Ids.length === 0
    ? '-- brak --'
    : allTagAttributes2Options
        .filter(m => selectedAdditionalField2Ids.includes(m.id))
        .map(m => m.label)
        .reduce((prev,curr) => prev + (prev ? ', ' : '') + curr, '');

    const manufacturerSelectionLabel = selectedManufacturerFieldIds => selectedManufacturerFieldIds.length === 0
        ? '-- brak --'
        : manufacturerFields
            .filter(m => selectedManufacturerFieldIds.includes(m))
            .map(m => translations.manufacturerFields[m])
            .reduce((prev,curr) => prev + (prev ? ', ' : '') + curr);

    const count = itemCount => itemCountTile.find(item => item.id === itemCount);
    const singleFillerCount = singleFillerCount => numbersDropdownOptions.find(item => item.id === singleFillerCount);
    const doubleFillerCount = doubleFillerCount => numbersDropdownOptions.find(item => item.id === doubleFillerCount);

    return tilePresentation && allTagAttributes && manufacturerFields ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={{
                    desktop: {
                        ...tilePresentation.desktop,
                        name: true,
                        itemCount: tilePresentation.desktop.itemCount || itemCountTile[0].id,
                        singleFillerCount: tilePresentation.desktop.singleFillerCount || numbersDropdownOptions[0].id,
                        doubleFillerCount: tilePresentation.desktop.doubleFillerCount || numbersDropdownOptions[0].id
                    },
                    tablet: {
                        ...tilePresentation.tablet,
                        name: true,
                        itemCount: tilePresentation.tablet.itemCount || itemCountTile[0].id,
                        singleFillerCount: tilePresentation.tablet.singleFillerCount || numbersDropdownOptions[0].id,
                        doubleFillerCount: tilePresentation.tablet.doubleFillerCount || numbersDropdownOptions[0].id
                    },
                    mobile: {
                        ...tilePresentation.mobile,
                        name: true,
                        itemCount: tilePresentation.mobile.itemCount || itemCountTile[0].id,
                        singleFillerCount: tilePresentation.mobile.singleFillerCount || numbersDropdownOptions[0].id,
                        doubleFillerCount: tilePresentation.mobile.doubleFillerCount || numbersDropdownOptions[0].id
                    },
                }}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values }) => {

                    return (
                        <Form onSubmit={handleSubmit}>
                            <TabulatorNav
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                tabsList={[
                                    {iso: 0, label: 'Desktop'},
                                    {iso: 1, label: 'Tablet'},
                                    {iso: 2, label: 'Mobile'},
                                ]}
                                withoutFlag={true}
                            />
                            <TabContent>
                                <div style={{padding: '10px', display: activeTab === 0 ? 'block' : 'none'}}>
                                    <ProductTileDeviceSettings
                                        prefix="desktop"
                                        translations={translations}
                                        translationsAdditionalFields={translationsAdditionalFields}
                                        numbersDropdownOptions={numbersDropdownOptions}
                                        manufacturerFields={manufacturerFields}

                                        selectedAdditionalFieldIds={selectedAdditionalFieldIdsDesktop}
                                        additionalFieldSelectionLabel={additionalFieldSelectionLabel(selectedAdditionalFieldIdsDesktop)}
                                        handleAdditionalFieldsSelection={handleAdditionalFieldsSelectionDesktop}

                                        selectedAdditionalField2Ids={selectedAdditionalField2IdsDesktop}
                                        additionalField2SelectionLabel={additionalField2SelectionLabel(selectedAdditionalField2IdsDesktop)}
                                        handleAdditionalFields2Selection={handleAdditionalFields2SelectionDesktop}

                                        selectedManufacturerFieldIds={selectedManufacturerFieldIdsDesktop}
                                        manufacturerSelectionLabel={manufacturerSelectionLabel(selectedManufacturerFieldIdsDesktop)}
                                        handleManufacturerFieldSelection={handleManufacturerFieldSelectionDesktop}

                                        count={count(values.desktop.itemCount)}
                                        singleFillerCount={singleFillerCount(values.desktop.singleFillerCount)}
                                        doubleFillerCount={doubleFillerCount(values.desktop.doubleFillerCount)}
                                        allTagAttributesOptions={allTagAttributesOptions}
                                        allTagAttributes2Options={allTagAttributes2Options}
                                        additionalFieldsToEdit={additionalFieldsToEditDesktop}
                                        setAdditionalFieldsToEdit={setAdditionalFieldsToEditDesktop}
                                        form={form} values={values}
                                    />
                                </div>
                                <div style={{padding: '10px', display: activeTab === 1 ? 'block' : 'none'}}>
                                    <ProductTileDeviceSettings
                                        prefix="tablet"
                                        translations={translations}
                                        translationsAdditionalFields={translationsAdditionalFields}
                                        numbersDropdownOptions={numbersDropdownOptions}
                                        manufacturerFields={manufacturerFields}

                                        selectedAdditionalFieldIds={selectedAdditionalFieldIdsTablet}
                                        additionalFieldSelectionLabel={additionalFieldSelectionLabel(selectedAdditionalFieldIdsTablet)}
                                        handleAdditionalFieldsSelection={handleAdditionalFieldsSelectionTablet}

                                        selectedAdditionalField2Ids={selectedAdditionalField2IdsTablet}
                                        additionalField2SelectionLabel={additionalField2SelectionLabel(selectedAdditionalField2IdsTablet)}
                                        handleAdditionalFields2Selection={handleAdditionalFields2SelectionTablet}

                                        selectedManufacturerFieldIds={selectedManufacturerFieldIdsTablet}
                                        manufacturerSelectionLabel={manufacturerSelectionLabel(selectedManufacturerFieldIdsTablet)}
                                        handleManufacturerFieldSelection={handleManufacturerFieldSelectionTablet}

                                        count={count(values.tablet.itemCount)}
                                        singleFillerCount={singleFillerCount(values.tablet.singleFillerCount)}
                                        doubleFillerCount={doubleFillerCount(values.tablet.doubleFillerCount)}
                                        allTagAttributesOptions={allTagAttributesOptions}
                                        allTagAttributes2Options={allTagAttributes2Options}
                                        additionalFieldsToEdit={additionalFieldsToEditTablet}
                                        setAdditionalFieldsToEdit={setAdditionalFieldsToEditTablet}
                                        form={form} values={values}
                                    />
                                </div>
                                <div style={{padding: '10px', display: activeTab === 2 ? 'block' : 'none'}}>
                                    <ProductTileDeviceSettings
                                        prefix="mobile"
                                        translations={translations}
                                        translationsAdditionalFields={translationsAdditionalFields}
                                        numbersDropdownOptions={numbersDropdownOptions}
                                        manufacturerFields={manufacturerFields}

                                        selectedAdditionalFieldIds={selectedAdditionalFieldIdsMobile}
                                        additionalFieldSelectionLabel={additionalFieldSelectionLabel(selectedAdditionalFieldIdsMobile)}
                                        handleAdditionalFieldsSelection={handleAdditionalFieldsSelectionMobile}

                                        selectedAdditionalField2Ids={selectedAdditionalField2IdsMobile}
                                        additionalField2SelectionLabel={additionalField2SelectionLabel(selectedAdditionalField2IdsMobile)}
                                        handleAdditionalFields2Selection={handleAdditionalFields2SelectionMobile}

                                        selectedManufacturerFieldIds={selectedManufacturerFieldIdsMobile}
                                        manufacturerSelectionLabel={manufacturerSelectionLabel(selectedManufacturerFieldIdsMobile)}
                                        handleManufacturerFieldSelection={handleManufacturerFieldSelectionMobile}

                                        count={count(values.mobile.itemCount)}
                                        singleFillerCount={singleFillerCount(values.mobile.singleFillerCount)}
                                        doubleFillerCount={doubleFillerCount(values.mobile.doubleFillerCount)}
                                        allTagAttributesOptions={allTagAttributesOptions}
                                        allTagAttributes2Options={allTagAttributes2Options}
                                        additionalFieldsToEdit={additionalFieldsToEditMobile}
                                        setAdditionalFieldsToEdit={setAdditionalFieldsToEditMobile}
                                        form={form} values={values}
                                    />
                                </div>
                            </TabContent>
                            <SectionEditFormButtons formReset={form.reset}/>
                        </Form>
                    );
                }}
            />
        </Container>
    ) : null;
};

ProductTileSettings.propTypes = propTypes;

const mapDispatch = {
    getAllTagAttributes,
    getProductPresentationTile,
    updateProductPresentationTile,
    getManufacturerFields,
    getProductPresentationCart,
    updateProductPresentationCart
};

export default connect(null, mapDispatch)(ProductTileSettings);