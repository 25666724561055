import { useState, useEffect } from 'react';

const useActiveLabel = (leftList, rightList) => {
    const [ activeLabel, setActiveLabel ] = useState(null);

    useEffect(() => {
        const isLeftListPresent = leftList && leftList.length > 0;
        const isRightListPresent = rightList && rightList.length > 0;

        const isListsPresentAndActiveLabelNotSelected = !activeLabel && leftList && rightList;

        if(isListsPresentAndActiveLabelNotSelected){
            setActiveLabel(!isLeftListPresent && isRightListPresent ? 'right' : 'left');
        }
    }, [activeLabel, leftList, rightList]);

    return [ activeLabel, setActiveLabel ];
};

export default useActiveLabel;