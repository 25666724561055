import React from 'react';
import PropTypes from 'prop-types';

import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    translations: PropTypes.object,
    items: PropTypes.array,
    onClick: PropTypes.func,
};

const ProductSearchTable = ({
    translations,
    items,
    onClick,
    columnSortedByDescendingOrder,
    performSort,
    setColumnSortedByDescendingOrder,
    currentPage,
    pageSize,
    performPageSizeSelect,
}) => {
    const defaultAction = (id) => () => {
        onClick(id);
    };

    const onClickSortHandler = (columnName) => () => {
        setColumnSortedByDescendingOrder(prevState => prevState === columnName ? null : columnName);

        performSort(columnSortedByDescendingOrder === columnName ? 'Desc' : 'Asc');
    };

    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            pageSize={pageSize}
            currentPage={currentPage}
            defaultAction={defaultAction}
            items={items}
            onClickSortHandler={onClickSortHandler}
            translations={translations}
            columnSortedByDescendingOrder={columnSortedByDescendingOrder}
            columns={[
                {
                    header: translations.headers.name,
                    value: 'name'
                },
                {
                    header: translations.headers.visitNumber,
                    value: 'numberOfVisits',
                    isSortable: true,
                    sortValue: 'numberOfVisits'

                },
                {
                    header: translations.headers.catalogueIndex,
                    value: 'catalogueIndex'
                },
                {
                    //empty object added for a better UI - useful in non-action tables
                }
            ]}
        />
    );
};

ProductSearchTable.propTypes = propTypes;

export default ProductSearchTable;