import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Field } from 'react-final-form';
import { Label } from 'reactstrap';

import Dropdown from '../../Dropdown';

import { stringOrNumberPropTypes } from '../../../propTypes';

const propTypes = {
    name: PropTypes.string,
    label: stringOrNumberPropTypes,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: stringOrNumberPropTypes,
        label: stringOrNumberPropTypes,
    })),
    currentSelectionLabel: stringOrNumberPropTypes,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    mutator: PropTypes.func,
};

const FormFieldWithDropdown = ({ name, label, options, currentSelectionLabel, className, isDisabled = false, mutator, withSearch = false }) => {
    return (
        <Field
            name={name}
            parse={value => {
                if(mutator){
                    mutator();
                }

                return value;
            }}
            allowNull
        >
            {
                props =>
                    <span className={clsx(className)}>
                        <Label>{label}</Label>
                        <Dropdown
                            name={props.input[name]}
                            options={options}
                            currentSelectionLabel={currentSelectionLabel || options?.find(opt => opt.id === props.input.value)?.label || 'Wybierz'}
                            performSelection={props.input.onChange}
                            isDisabled={isDisabled}
                            withSearch={withSearch}
                        />
                        {(props.meta.error || props.meta.submitError) && props.meta.touched && (<small className='text-danger'>{props.meta.error || props.meta.submitError}</small>)}
                    </span>
            }
        </Field>
    );
};

FormFieldWithDropdown.propTypes = propTypes;

export default FormFieldWithDropdown;