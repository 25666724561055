import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getProductAttributes, updateProductAttributes } from '../../../../redux/methods/products';
import useFetch from '../../../../hooks/useFetch';

import { Container } from 'reactstrap';

import AttributeAssigner from './AttributeAssigner';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';

import { attributeAffiliationNumericEnum } from '../../../../enums/attributes';

import { stringOrNumberPropTypes } from '../../../../propTypes';

const propTypes = {
    id: stringOrNumberPropTypes,
    getProductAttributes: PropTypes.func,
    updateProductAttributes: PropTypes.func,
};

const ProductEditAttributes = ({
    id,
    getProductAttributes,
    updateProductAttributes
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.featuresAndAttributes;
    const [ attributes ] = useFetch(getProductAttributes, id);
    const [ editedAttributes, setEditedAttributes ] = useState([]);

    const handleUpdateAttributes = () => {
        updateProductAttributes(editedAttributes);
    };

    const extractedAttributes = attributes && attributes.filter(attribute => attribute.attributeAffiliationId === attributeAffiliationNumericEnum.attribute);
    const extractedFeatures = attributes && attributes.filter(attribute => attribute.attributeAffiliationId === attributeAffiliationNumericEnum.feature);

    return extractedAttributes && extractedFeatures ? (
        <Container fluid>
            <div>
                {
                    extractedAttributes.length > 0
                    && <React.Fragment>
                        <h5>{translations.editAttributes}</h5>
                        <div className='d-flex flex-row justify-content-start flex-wrap'>
                            {
                                extractedAttributes.map(attribute => (
                                    <AttributeAssigner
                                        productId={id}
                                        editedAttributes={editedAttributes}
                                        setEditedAttributes={setEditedAttributes}
                                        key={`${attribute.attributeId}--attributeType`}
                                        attribute={attribute}
                                        selectAttributeTranslation={translations.placeholders.selectAttributes}
                                        emptyTranslation={translations.placeholders.empty}
                                        selectedTranslation={translations.placeholders.selected}
                                    />
                                ))
                            }
                        </div>
                    </React.Fragment>
                }
            </div>
            <div className='mt-3' >
                {
                    extractedFeatures.length > 0
                    && <React.Fragment>
                        <h5>{translations.editFeatures}</h5>
                        <div className='d-flex flex-row flex-wrap'>
                            {
                                extractedFeatures.map(feature => (
                                    <AttributeAssigner
                                        productId={id}
                                        editedAttributes={editedAttributes}
                                        setEditedAttributes={setEditedAttributes}
                                        key={`${feature.attributeId}--attributeType`}
                                        attribute={feature}
                                        selectAttributeTranslation={translations.placeholders.selectFeatures}
                                        emptyTranslation={translations.placeholders.empty}
                                    />
                                ))
                            }
                        </div>
                    </React.Fragment>
                }
            </div>
            <SectionEditFormButtons
                onSubmit={handleUpdateAttributes}
            />
        </Container>
    ) : null;

};

ProductEditAttributes.propTypes = propTypes;

const mapDispatch = {
    getProductAttributes,
    updateProductAttributes
};

export default connect(null, mapDispatch)(ProductEditAttributes);