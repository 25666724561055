import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { getTranslationForIso } from '../../../../../utils/components';
import { getButtonsList } from '../../../../../redux/methods/visualSettings';

import { withFormUtils } from '../../../../../hoc/withFormUtils';
import { Form as FinalForm } from "react-final-form";
import { Form, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Tabulator from "../../../../../components/Tabulator";
import WarningModal from '../../../../../components/modals/WarningModal';
import ImageTileElement from './ImageTileElement';
import EditionFields from './EditionFields';
import FormFieldTextOrNumber from '../../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldWithCalendarAndTimePicker from '../../../../../components/formFields/FormFieldWithCalendarAndTimePicker';

import { formUtilsHocPropTypes, stringOrNumberPropTypes, homePageBannerImagePropTypes } from '../../../../../propTypes';
import DefaultSpinner from "../../../../../components/loaders/DefaultSpinner";

const propTypes = {
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    utils: formUtilsHocPropTypes,
    handleRemove: PropTypes.func,
    handleUpload: PropTypes.func,
    bannerId: stringOrNumberPropTypes,
    translations: PropTypes.shape({
        inputs: PropTypes.shape({
            file: PropTypes.shape({
                upload: PropTypes.string,
                remove: PropTypes.string,
                edit: PropTypes.string,
            }),
            alternativeText: PropTypes.string,
            dateTo: PropTypes.string,
            dateFrom: PropTypes.string,
        }),
        actions: PropTypes.shape({
            save: PropTypes.string,
            calncel: PropTypes.string,
        }),
    }),
    slide: homePageBannerImagePropTypes,
};

const EditBannerImageModal =  ({
    translations,
    slide,
    isModalOpen,
    setIsModalOpen,
    onSubmit,
    handleRemove,
    handleUpload,
    bannerId,
    utils,
    isLoading,
    buttons,
    getButtonsList
}) => {

    const config = {
        translations: [
            'desktopContent', 'desktopButton', 'desktopImageAlternativeText',
            'tabletContent', 'tabletButton', 'tabletImageAlternativeText',
            'mobileContent', 'mobileButton', 'mobileImageAlternativeText'],
    };

    useEffect(() => {
        if(!buttons || buttons.length === 0) {
            getButtonsList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFormChanged = useRef();
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);

    const toggleModal = () => {
        if (isFormChanged.current) {
            setIsWarningModalOpen(true);
        }
        else {
            setIsModalOpen(!isModalOpen);
        }
    };

    const handleSubmit = formData => {

        const convertedData = utils.generateFormToSubmitValues(formData, config);
        const objectToSend = {
            ...convertedData,
            desktop: {
                ...formData.desktop,
                device: 'desktop',
                content: convertedData.desktopContent,
                button: convertedData.desktopButton,
                imageAlternativeText: convertedData.desktopImageAlternativeText
            },
            tablet: {
                ...formData.tablet,
                device: 'tablet',
                content: convertedData.tabletContent,
                button: convertedData.tabletButton,
                imageAlternativeText: convertedData.tabletImageAlternativeText
            },
            mobile: {
                ...formData.mobile,
                device: 'mobile',
                content: convertedData.mobileContent,
                button: convertedData.mobileButton,
                imageAlternativeText: convertedData.mobileImageAlternativeText
            }
        };

        onSubmit(objectToSend);
        setIsModalOpen(!isModalOpen);
    };

    const uploadFileHandler = (data) => {
        handleUpload(bannerId, slide.bannerImageId, data);
    };

    const removeFileHandler = () => {
        handleRemove(bannerId, slide.bannerImageId);
    };

    const getInitialStructure = section => {
        return ({
            ...section,
            desktopContent: section.desktop ? section.desktop.content : [],
            desktopButton: section.desktop ? section.desktop.button : [],
            tabletContent: section.tablet ? section.tablet.content : [],
            tabletButton: section.tablet ? section.tablet.button : [],
            mobileContent: section.mobile ? section.mobile.content : [],
            mobileButton: section.mobile ? section.mobile.button : [],
        });
    };

    return (
        <Modal isOpen={isModalOpen} toggle={toggleModal} size='xl'>
            <ModalHeader toggle={toggleModal}>{translations.editModal.header}</ModalHeader>
            <FinalForm
                onSubmit={handleSubmit}
                initialValues={utils.generateFormInitialValues(getInitialStructure(slide), config)}
                render={({ handleSubmit , form, pristine, values }) => {
                    isFormChanged.current = !pristine;

                    return (
                        <Form onSubmit={handleSubmit} className='w-100 p-0'>
                            <ModalBody>
                                <div className='my-3 d-flex flex-wrap'>
                                    <FormFieldWithCalendarAndTimePicker label={translations.inputs.dateFrom} name='dateFrom' />
                                    <FormFieldWithCalendarAndTimePicker label={translations.inputs.dateTo} name='dateTo'  />
                                </div>
                                <FormFieldTextOrNumber
                                    className='d-block mb-3'
                                    label={translations.inputs.link}
                                    name='link'
                                />
                                <Tabulator
                                    listOfElements={[
                                        {
                                            label: translations.inputs.desktopImage,
                                            id: `${slide.bannerImageId}-DesktopTile`,
                                            component: (
                                                <React.Fragment>
                                                    <ImageTileElement
                                                        imageUrl={slide.desktop.imageSource}
                                                        buttonsTranslations={translations.inputs.file}
                                                        uploadFileHandler={uploadFileHandler}
                                                        removeFileHandler={removeFileHandler}
                                                        imageAlternativeText={getTranslationForIso(slide.imageAlternativeText, utils.currentLanguage())}
                                                        fileKey='Image'
                                                        fileType='desktop'
                                                    />
                                                    <EditionFields
                                                        form={form}
                                                        values={values}
                                                        translations={translations}
                                                        utils={utils}
                                                        name={'desktop'}
                                                        buttons={buttons}
                                                        currentLanguage={utils.currentLanguage()}
                                                    />
                                                </React.Fragment>
                                            )
                                        },
                                        {
                                            label: translations.inputs.tabletImage,
                                            id: `${slide.bannerImageId}-TabletTile`,
                                            component: (
                                                <React.Fragment>
                                                    <ImageTileElement
                                                        imageUrl={slide.tablet.imageSource}
                                                        buttonsTranslations={translations.inputs.file}
                                                        uploadFileHandler={uploadFileHandler}
                                                        removeFileHandler={removeFileHandler}
                                                        imageAlternativeText={getTranslationForIso(slide.imageAlternativeText, utils.currentLanguage())}
                                                        fileKey='Image'
                                                        fileType='tablet'
                                                    />
                                                    <EditionFields
                                                        form={form}
                                                        values={values}
                                                        translations={translations}
                                                        utils={utils}
                                                        name={'tablet'}
                                                        buttons={buttons}
                                                        currentLanguage={utils.currentLanguage()}
                                                    />
                                                </React.Fragment>
                                            )
                                        },
                                        {
                                            label: translations.inputs.mobileImage,
                                            id: `${slide.bannerImageId}-MobileTile`,
                                            component: (
                                                <React.Fragment>
                                                    <ImageTileElement
                                                        imageUrl={slide.mobile.imageSource}
                                                        buttonsTranslations={translations.inputs.file}
                                                        uploadFileHandler={uploadFileHandler}
                                                        removeFileHandler={removeFileHandler}
                                                        imageAlternativeText={getTranslationForIso(slide.imageAlternativeText, utils.currentLanguage())}
                                                        fileKey='Image'
                                                        fileType='mobile'
                                                    />
                                                    <EditionFields
                                                        form={form}
                                                        values={values}
                                                        translations={translations}
                                                        utils={utils}
                                                        name={'mobile'}
                                                        buttons={buttons}
                                                        currentLanguage={utils.currentLanguage()}
                                                    />
                                                </React.Fragment>
                                            )
                                        },
                                    ]}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <DefaultSpinner isLoading={isLoading} />
                                <Button color="primary" type="submit">
                                    {translations.actions.save}
                                </Button>
                                <Button color="secondary" type="button" onClick={toggleModal}>
                                    {translations.actions.cancel}
                                </Button>
                            </ModalFooter>
                            <WarningModal
                                setIsOpen={setIsWarningModalOpen}
                                isOpen={isWarningModalOpen}
                                setIsMainModalOpen={setIsModalOpen}
                            />
                        </Form>
                    );
                }}
            />
        </Modal>
    );
};

EditBannerImageModal.propTypes = propTypes;

const mapStateToProps = state => ({
    isLoading: state.loaders.global,
    buttons: state.visualSettings.buttons,
});

const mapDispatch = {
    getButtonsList,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(EditBannerImageModal));
