const locale = {
    availableLanguages: [
        {
            iso: 'en',
            name: 'Angielski'
        },
        {
            iso: 'pl',
            name: 'Polski'
        },
        {
            iso: 'ru',
            name: 'Rosyjski'
        },
        {
            iso: 'ua',
            name: 'Ukraiński'
        },
        {
            iso: 'de',
            name: 'Niemiecki'
        },
        {
            iso: 'cz',
            name: 'Czeski'
        },
        {
            iso: 'it',
            name: 'Włoski'
        },
        {
            iso: 'tr',
            name: 'Turecki'
        },
        {
            iso: 'es',
            name: 'Hiszpański'
        }
    ]
};

export default locale;