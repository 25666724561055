import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { translateIso } from '../../utils/locale';

import { Label, Input } from 'reactstrap';

import TooltipWrapper from '../TooltipWrapper';

import { inputProps } from '../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    chosenIso: PropTypes.string.isRequired,
    isoList: PropTypes.arrayOf(PropTypes.string),
    onClickAdditionalFlags: PropTypes.func,
    inputProps: inputProps,
    type: PropTypes.string,
    disabled: PropTypes.bool,
};

const TextInputWithFlags = ({ id, label, chosenIso, isoList, onClickAdditionalFlags, inputProps, className, type = 'text', disabled }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.textInputWithFlags;

    const [isButtonTooltipOpen, setIsButtonTooltipOpen] = useState(false);

    const translatedChosenIso = translateIso(chosenIso).toLowerCase();
    const translatedIsoList = isoList ? isoList.map(iso => translateIso(iso).toLowerCase()) : null;
    const notSelectedFlags = translatedIsoList ? translatedIsoList.filter(iso => iso !== translatedChosenIso) : null;
    const isMoreThanFiveFlags = isoList ? isoList.length > 5 : null;

    const handleOnClick = () => {
        onClickAdditionalFlags();
    };

    return (
        <div className={clsx(styles.root, className)}>
            <Label for={inputProps.id}>
                <span>{label}</span>
                <i
                    className={clsx(
                        "flag-icon",
                        `flag-icon-${translatedChosenIso}`,
                        styles.flag
                    )}
                />
                {
                    notSelectedFlags && notSelectedFlags.length > 0
                        ? (
                            <TooltipWrapper
                                target={`flagsButton-${id}`}
                                placement="top"
                                isOpen={isButtonTooltipOpen}
                                setIsOpen={setIsButtonTooltipOpen}
                                tooltipContent={`${translations.tooltipText} ${isoList.length-1}`}
                            >
                                <button
                                    disabled={disabled}
                                    id={`flagsButton-${id}`}
                                    className={styles.button}
                                    onClick={handleOnClick}
                                    type="button"
                                >
                                    {
                                        isMoreThanFiveFlags
                                            ? (
                                                <i className={clsx("icon", "icon-globe")} />
                                            ) : (
                                                <React.Fragment>
                                                    <span>(</span>
                                                    {
                                                        notSelectedFlags.map(iso =>
                                                            <i
                                                                key={`iso-${iso}`}
                                                                className={clsx(
                                                                    "flag-icon",
                                                                    `flag-icon-${iso}`,
                                                                    styles.flag,
                                                                    styles.toCheck
                                                                )}
                                                            />
                                                        )
                                                    }
                                                    <span>)</span>
                                                </React.Fragment>
                                            )
                                    }
                                </button>
                            </TooltipWrapper>
                        ) : null
                }
            </Label>
            <Input {...inputProps} type={type} disabled={disabled} />
        </div>
    );
};

TextInputWithFlags.propTypes = propTypes;

export default TextInputWithFlags;