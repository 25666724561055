import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../components/modals/Modal';

import SummaryProductEdition from '../SummaryProductEdition';

import styles from './index.module.scss';

const propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
};

const SummaryProductEditionModal = ({
    isOpen,
    setIsOpen,
    history,
    modalLabel,
}) => {
    return (
        <Modal
            className={styles.modal}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            header={modalLabel}
            body={<SummaryProductEdition history={history} /> }
            buttons={{}}
        />
    );
};

SummaryProductEditionModal.propTypes = propTypes;

export default SummaryProductEditionModal;