import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { UncontrolledTooltip } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    labels: PropTypes.shape({
        main: PropTypes.string,
        left: PropTypes.string,
        right: PropTypes.string,
    }).isRequired,
    activeLabel: PropTypes.oneOf(['left', 'right']),
    isDisabled: PropTypes.bool,
    changeActiveLabel: PropTypes.func,
    className: PropTypes.string,
    tooltip: PropTypes.string,
};

const SwitchWithLabels = ({ labels, activeLabel, isDisabled, changeActiveLabel, className, tooltip }) => {
    const handleChange = () => {
        changeActiveLabel(activeLabel === 'left' ? 'right' : 'left');
    };

    const inActiveClassNames = 'text-muted font-weight-light';
    const activeClassNames = 'text-primary font-weight-bold';
    const isRightLabelActive = activeLabel === 'right';

    return (
        <React.Fragment>
            { isDisabled &&
                <UncontrolledTooltip placement="top" target='switchMode'>
                    {tooltip}
                </UncontrolledTooltip>
            }
            <div className={clsx(styles.container, className)}>
                <span className='mr-3'>{labels.main}</span>
                <div className={styles.switch}>
                    <span className={clsx(isRightLabelActive ? inActiveClassNames : activeClassNames)}>{labels.left}</span>
                    <label className='switch switch-pill switch-primary mx-3' id='switchMode'>
                        <input type='checkbox' className='switch-input' onChange={handleChange} disabled={isDisabled} checked={isRightLabelActive}/>
                        <span
                            className={clsx('switch-slider bg-primary border-primary')}
                        ></span>
                    </label>
                    <span className={clsx(!isRightLabelActive ? inActiveClassNames : activeClassNames)}>{labels.right}</span>
                </div>
            </div>
        </React.Fragment>
    );
};

SwitchWithLabels.propTypes = propTypes;

export default SwitchWithLabels;