/* eslint-disable */
import React from 'react';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import ProductTileSettings from '../../../features/products/ProductTileSettings';
import ProductTileFiller from '../../../features/products/ProductTileFiller';
import SettingsTabulator from '../../../components/layouts/SettingsTabulator';

const Tile = ({ location }) => {
    const intl = useIntl();
    const translations = intl.messages.tile.view;

    const query = new URLSearchParams(location.search);
    const activeTab = query.get('tab');

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'productTile-settings':
                return <ProductTileSettings translations={translations} />;
            case 'productTile-fillers':
                return <ProductTileFiller translations={translations} />;
            default:
                return null;
        }
    };

    return (
        <SettingsTabulator
            customActiveTabId={activeTab && 'productTile-fillers'}
            title={{
                label: null,
                iconClassName: null,
            }}
            tabulatorHeader={{
                label: translations.title,
                iconClassName: 'fa fa-th'
            }}
            refreshTabComponent={refreshTabComponent}
            tabs={[
                {
                    id: 'productTile-settings',
                    header: {
                        label: translations.settingsTabLabel,
                        iconClassName: 'fa fa-cog'
                    }
                },
                {
                    id: 'productTile-fillers',
                    header: {
                        label: translations.fillersTabLabel,
                        iconClassName: 'fa fa-th'
                    }
                }
            ]}
        />
    );
};

export default withRouter(Tile);