const definingButton = {
    isTransparent: '[EN] Przycisk bez wypełnienia',
    button: '[EN] Napis na przycisku',
    colorPicker: '[EN] Wybierz',
    fontColorButton: '[EN] Kolor czcionki',
    backgroundColorButton: '[EN] Kolor tła',
    borderColorButton: '[EN] Kolor obramowania',
    link: '[EN] Odnośnik przycisku',
    linkHelper: '[EN] Aby link był dostępny, musisz podać treść przycisku.',
};

export default definingButton;