import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getTranslationForIso, goBackToGeneralSection } from '../../../../utils/components';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Input, Row, Col } from 'reactstrap';
import { withFormUtils } from '../../../../hoc/withFormUtils';
import FormFieldTranslations from '../../../../components/formFields/FormFieldTranslations';
import FormFieldTranslationsQuill from '../../../../components/formFields/FormFieldTranslationsQuill';
import CategoryTypeController from '../../../menu/editControllers/CategoryTypeController';
import AttributeAssigner from '../../../../features/products/editTabs/ProductEditAttributes/AttributeAssigner';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import Tabulator from '../../../../components/Tabulator';

import getConfig from '../../../../getConfig';

import styles from './index.module.scss';

const propTypes = {
    currentLanguage: PropTypes.string,
    form: PropTypes.object,
    searchStringName: PropTypes.string,
    translations: PropTypes.object,
};

const PredefinedSearchEditBasic = ({
    translations,
    flattenedCategoryTree,
    filters,
    utils,
    predefinedSearchBasicData,
    onSubmit,
    history
}) => {
    const [ selectedSearchCategoryId, setSelectedSearchCategoryId] = useState(null);
    const [ editedFilters, setEditedFilters ] = useState([]);
    const currentLanguage = utils.currentLanguage();

    const shopUrl = getConfig().NEXT_SHOP_EXTERNAL_URL;
    const allowLettersNumbersUnderscore = new RegExp(/^[a-zA-Z_0-9]+$/);

    const convertFilterToAssigner = (filter) => {
        return ({
            ...filter,
            attributeName: getTranslationForIso(filter.name, currentLanguage),
            attributeNameExtension: filter.extension,
            dictionaryValues: filter.dictionaryValues
                ? filter.dictionaryValues.map(value => ({
                    ...value,
                    name: getTranslationForIso(value.name, currentLanguage)
                }))
                : null
        });
    };

    const config = { translations: ['content', 'searchText'] };

    const getStrucutreToSubmit = form => {
        const formToSend = utils.generateFormToSubmitValues(form, config);

        const generatedSelectedFilters = editedFilters.map(({ attributeId, dictionaryValues, bigIntValue, decimalValue, dateTimeValue }) => {
            if(dictionaryValues.length > 0 || bigIntValue || decimalValue || dateTimeValue) {
                return ({
                    attributeId,
                    attributeValueIds: dictionaryValues,
                    bigIntValue,
                    decimalValue,
                    dateTimeValue
                });
            }
            else {
                return null;
            }
        }).filter(value => value);

        return {
            name: formToSend.name,
            slug: formToSend.slug,
            content: formToSend.content,
            searchId: formToSend.searchId ? formToSend.searchId : null,
            criteria: {
                ...utils.generateFormToSubmitValues(form, { translations: ['searchText'] }),
                categoryId: selectedSearchCategoryId,
                productSearchAttributeValues: generatedSelectedFilters,
            },
        };
    };

    const getInitialStructureForType = section => {

        return ({
            ...section,
            searchText: section.searchCriteria ? section.searchCriteria.searchText : []
        });
    };

    const handleOnSubmit = data => {
        const objectToSend = getStrucutreToSubmit(data);

        onSubmit(objectToSend).then(id => id && goBackToGeneralSection(history));
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={utils.generateFormInitialValues(getInitialStructureForType(predefinedSearchBasicData), config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className="px-0" fluid>

                                    <Tabulator
                                        listOfElements={[
                                            {
                                                label: translations.generalTab,
                                                id: 'predefinedSearch-generalTab',
                                                component: (
                                                    <React.Fragment>
                                                        <Row>
                                                            <Col>
                                                                <div className='my-3'>
                                                                    <FormFieldTextOrNumber
                                                                        inputClassName='w-100'
                                                                        name='name'
                                                                        label={translations.inputs.name}
                                                                        required={true}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className='my-3'>
                                                                    <FormFieldTextOrNumber
                                                                        name='slug'
                                                                        label={translations.inputs.slug}
                                                                    />
                                                                    <Input className='mt-2'value={`${shopUrl}/pl/search/${values.slug || ''}`} disabled/>
                                                                    <span className={clsx('d-none mt-2 text-danger', (values.slug === null || !allowLettersNumbersUnderscore.test(values.slug)) && 'd-block' )}>{translations.messages.slugAlert}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <div>
                                                            <FormFieldTranslationsQuill
                                                                form={form}
                                                                utils={utils}
                                                                propertyName="content"
                                                                header={translations.inputs.content}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            },
                                            {
                                                label: translations.filtersTab,
                                                id: 'predefinedSearch-filtersTab',
                                                component: (
                                                    <React.Fragment>
                                                        <div className={styles.nameInput}>
                                                            <FormFieldTranslations
                                                                form={form}
                                                                propertyName={'searchText'}
                                                                label={translations.inputs.searchStringLabel}
                                                                currentIso={currentLanguage}
                                                                modalHeader={translations.inputs.searchStringLabel}
                                                            />
                                                        </div>
                                                        <div>
                                                            <CategoryTypeController
                                                                flattenedCategoryTree={flattenedCategoryTree}
                                                                currentLanguage={currentLanguage}
                                                                selectCategoryLabel={translations.inputs.searchCategoryLabel}
                                                                setSelectedCategoryId={setSelectedSearchCategoryId}
                                                                initialySelectedId={predefinedSearchBasicData.searchCriteria ? predefinedSearchBasicData.searchCriteria.categoryId : null}
                                                                selectedCategoryId={selectedSearchCategoryId}
                                                                showAll={true}
                                                            />
                                                        </div>
                                                        <div>
                                                            <span>{translations.inputs.filterBy}</span>
                                                            <div className={styles.filterAssignerContainer}>
                                                                {
                                                                    filters.map((filter, index) => (
                                                                        <AttributeAssigner
                                                                            selectedFiltersValues={predefinedSearchBasicData.searchCriteria ? predefinedSearchBasicData.searchCriteria.productSearchAttributeValues : []}
                                                                            key={`${index}-searchType-filter`}
                                                                            attribute={convertFilterToAssigner(filter)}
                                                                            editedAttributes={editedFilters}
                                                                            setEditedAttributes={setEditedFilters}
                                                                            selectAttributeTranslation={translations.inputs.selectFilter}
                                                                            emptyTranslation={translations.inputs.selectFilter}
                                                                        />
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            },
                                        ]}
                                    />
                                    <SectionEditFormButtons isSaveButtonDisabled={values.slug === null || !allowLettersNumbersUnderscore.test(values.slug)} formReset={form.reset} />
                                </Container>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

PredefinedSearchEditBasic.propTypes = propTypes;

export default withFormUtils(PredefinedSearchEditBasic);