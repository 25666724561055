import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Button, ButtonGroup } from 'reactstrap';
import { stringOrNumberPropTypes } from '../../../../../propTypes';

const propTypes = {
    id: stringOrNumberPropTypes,
    index: PropTypes.number,
    items: PropTypes.array,
    className: PropTypes.string,
    increaseOrder: PropTypes.func,
    decreaseOrder: PropTypes.func,
};

const OrderCell = ({ id, index, items, className, increaseOrder, decreaseOrder, item }) => {
    const handleIncreaseOrder = id => () => {
        increaseOrder(id, index, item);
    };

    const handleDecreaseOrder = id => () => {
        decreaseOrder(id, index, item);
    };

    return (
        <td className={clsx(className)}>
            <ButtonGroup vertical>
                <Button
                    color="primary"
                    size="sm"
                    disabled={index === 0}
                    onClick={handleDecreaseOrder(id)}
                >
                    <i className="cil-arrow-thick-top"></i>
                </Button>
                <Button
                    color="primary"
                    size="sm"
                    disabled={index === items.length - 1}
                    onClick={handleIncreaseOrder(id)}
                >
                    <i className="cil-arrow-thick-bottom"></i>
                </Button>
            </ButtonGroup>
        </td>
    );
};

OrderCell.propTypes = propTypes;

export default OrderCell;