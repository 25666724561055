import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { Button } from 'reactstrap';

import Modal from '../modals/Modal';

import styles from './index.module.scss';

const propTypes = {
    content: PropTypes.node,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
};

const PreviewModalController = ({
    content,
}) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.previewModal;

    const [ isOpen, setIsOpen ] = useState(false);

    const toggleModal = () => setIsOpen(!isOpen);

    return (
        <aside className={clsx('mt-3', styles.footer)}>
            <Button
                id='preview_button'
                onClick={toggleModal}
                color='warning'
            >
                <i className={clsx('fa fa-search mr-2')}/>
                <span>{translations.preview}</span>
            </Button>
            <Modal
                className={styles.modal}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                header={translations.header}
                body={content}
                buttons={{
                    cancel: {
                        label: translations.cancel,
                        color: 'secondary'
                    }
                }}
            />
        </aside>
    );
};

PreviewModalController.propTypes = propTypes;

export default PreviewModalController;