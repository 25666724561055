export const identifiersEnum = {
    externalId: 'ExternalId',
    catalogueIndex: 'CatalogueIndex',
    ean: 'Ean'
};

export const columnSeparatorsEnum = {
    comma: 'Comma',
    semicolon: 'Semicolon',
    pipe: 'Pipe'
};