import React from 'react';

import PropTypes from 'prop-types';

import { Field } from 'react-final-form';

import { FormGroup } from 'reactstrap';

import { Controlled as CodeMirror } from 'react-codemirror2';

import "./index.css";

require('codemirror/mode/css/css');

require('codemirror/mode/htmlmixed/htmlmixed');

const propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    inputClassName: PropTypes.string
};

const FormFieldCodeMirror = ({
    label,
    name,
    placeholder,
    disabled,
    className,
    height = "200px",
    inputClassName,
    options
}) => {
    return (
        <div className={className}>
            <span>{label}</span>

            <Field name={name}>
                {
                    props => {
                        const isError = (props.meta.error || props.meta.submitError) && props.meta.touched;

                        return (
                            <React.Fragment>
                                <FormGroup>
                                    <CodeMirror
                                        className={inputClassName}
                                        height={height}
                                        name={props.input.name}
                                        value={props.input.value}
                                        onBeforeChange={(_editor, _data, value) => {
                                            props.input.onChange({ target: { value } });
                                        }}
                                        placeholder={placeholder}
                                        disabled={disabled}
                                        options={options}
                                    />
                                </FormGroup>

                                {isError && <p className='text-warning'>{props.meta.error || props.meta.submitError}</p>}
                            </React.Fragment>
                        );
                    }
                }
            </Field>
        </div>
    );
};

FormFieldCodeMirror.propTypes = propTypes;

export default FormFieldCodeMirror;