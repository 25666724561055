import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';
import Erp from '../../features/synchronizations/tabs/Erp';
import Settings from '../../features/synchronizations/tabs/Settings';
import Authentication from '../../features/synchronizations/tabs/Authentication';

const propTypes = {
    isConnectedToERP: PropTypes.bool,
};

const Synchronizations = ({ isConnectedToERP }) => {
    const intl = useIntl();
    const translations = intl.messages.synchronizations;

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'synchronizations-erp':
                return <Erp />;
            case 'synchronizations-authentication':
                return <Authentication />;
            case 'synchronizations-settings':
                return <Settings />;
            default:
                return null;
        }
    };

    return (
        <SettingsTabulator
            title={{
                label: translations.titles.main,
                iconClassName: 'fa fa-edit text-primary ml-3',
            }}
            tabulatorHeader={{iconClassName: 'fa fa-align-justify'}}
            refreshTabComponent={refreshTabComponent}
            tabs={[
                {
                    id: 'synchronizations-erp',
                    header:  {
                        label: translations.titles.erp,
                        iconClassName: 'fa fa-superpowers'
                    }
                },
                {
                    id: 'synchronizations-authentication',
                    header:  {
                        label: translations.titles.authentication,
                        iconClassName: 'fa fa-eye'
                    }
                },
                isConnectedToERP ? {
                    id: 'synchronizations-settings',
                    header:  {
                        label: translations.titles.settings,
                        iconClassName: 'fa fa-cogs'
                    }
                } : null
            ]}
        />
    );
};

Synchronizations.propTypes = propTypes;

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
});

export default connect(mapStateToProps)(Synchronizations);