import { createSelector } from '@reduxjs/toolkit';

const selectAvailableLanguages = state => state.locale.availableLanguages;
const selectChosenLanguage = state => state.persisted.persistedLocale.persistedChosenLanguage;

export const selectChosenLanguageId = createSelector(
    selectAvailableLanguages,
    selectChosenLanguage,
    (availableLanguages, chosenLanguage) => {
        const matchingLanguage = availableLanguages.find(language => language.iso === chosenLanguage);

        return matchingLanguage && matchingLanguage.id;
    }
);

export const selectChosenLanguageObject = createSelector(
    selectAvailableLanguages,
    selectChosenLanguage,
    (availableLanguages, chosenLanguage) => {
        const matchingLanguage = availableLanguages.find(language => language.iso === chosenLanguage);

        return {
            iso: matchingLanguage?.iso,
            id: matchingLanguage?.id
        };
    }
);