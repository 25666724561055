import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { connect } from 'react-redux';

import { Table, Row } from "reactstrap";

import TableActionCell from "../TableActionCell";

import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';

import styles from "./index.module.scss";

import { getTranslationForIso } from '../../../utils/shared';

import { getDictionaryMappingFields } from '../../../redux/methods/attributes';

import useFetch from "../../../hooks/useFetch";

import { withFormUtils } from '../../../hoc/withFormUtils';

import { getTranslationsFromArray } from "../../../utils/getTranslationsFromArray";
import SwitchWithAction from "../../SwitchWithAction";

const propTypes = {
    storeCurrentLanguage: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    columnNames: PropTypes.shape({
        name: PropTypes.string,
        actions: PropTypes.string,
    }),
    tooltipTranslations: PropTypes.shape({
        remove: PropTypes.string,
        edit: PropTypes.string
    }),
    deleteModalTranslations: PropTypes.shape({
        header: PropTypes.string,
        body: PropTypes.string,
        confirm: PropTypes.string,
        cancel: PropTypes.string
    }).isRequired,
    emptyTranslations: PropTypes.shape({
        name: PropTypes.string,
        table: PropTypes.string
    }),
    editAction: PropTypes.func,
    removeAction: PropTypes.func,
    getDictionaryMappingFields: PropTypes.func,
    isConnectedToERP: PropTypes.bool
};

const AttributeDictionaryValuesTable = ({
    utils,
    data, storeCurrentLanguage, columnNames, tooltipTranslations,
    deleteModalTranslations, emptyTranslations, editAction, removeAction, updateActiveAction, isConnectedToERP,
    getDictionaryMappingFields
}) => {
    const [dictionaryMappingFields] = useFetch(getDictionaryMappingFields);

    const handleRemoveAction = id => () => {
        removeAction(id);
    };

    const handleEditAction = id => () => {
        editAction(id);
    };

    const handleUpdateActive = (id, active) => {
        updateActiveAction({
            attributeValueId: id,
            active: active
        });
    };

    return data && data.length > 0 && dictionaryMappingFields ? (
        <Table responsive hover size="sm">
            <thead>
                <tr>
                    <th className={styles.columnName}>{columnNames && columnNames.name}</th>
                    <th className={styles.columnColor}>{columnNames && columnNames.textColor}</th>
                    <th className={styles.columnColor}>{columnNames && columnNames.backgroundColor}</th>
                    <th className={styles.columnColor}>{columnNames && columnNames.borderColor}</th>
                    <th className={styles.columnActive}>{columnNames && columnNames.active}</th>
                    {isConnectedToERP && <th className={styles.columnMapping}>{columnNames && columnNames.mappingField}</th>}
                    {isConnectedToERP && <th className={styles.columnMapping}>{columnNames && columnNames.mappingValue}</th>}
                    <th className={styles.columnActions}>{columnNames && columnNames.actions}</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map(dictionaryValue => {

                        const mappingFieldInfo = dictionaryMappingFields && dictionaryMappingFields.find(item => parseInt(item.id) === dictionaryValue.mappingFieldId);
                        const mappingFieldName = mappingFieldInfo ? getTranslationsFromArray(mappingFieldInfo.translations, utils.currentLanguage()) : null;

                        return (
                        <tr key={`attributeDictionaryValue-${dictionaryValue.attributeDictionaryId}`}>
                            <td className={clsx(styles.columnName, styles.cell, !getTranslationForIso(dictionaryValue.name, storeCurrentLanguage) && 'text-danger')}>
                                { getTranslationForIso(dictionaryValue.name, storeCurrentLanguage) || emptyTranslations.name }
                            </td>
                            <td className={clsx(styles.columnColor, styles.cell)}>
                                <div
                                    style={{ background: dictionaryValue.textColor || null }}
                                    className={clsx(styles.preview, !dictionaryValue.textColor && styles.previewEmpty)}
                                />
                            </td>
                            <td className={clsx(styles.columnColor, styles.cell)}>
                                <div
                                    style={{ background: dictionaryValue.backgroundColor || null }}
                                    className={clsx(styles.preview, !dictionaryValue.backgroundColor && styles.previewEmpty)}
                                />
                            </td>
                            <td className={clsx(styles.columnColor, styles.cell)}>
                                <div
                                    style={{ background: dictionaryValue.borderColor || null }}
                                    className={clsx(styles.preview, !dictionaryValue.borderColor && styles.previewEmpty)}
                                />
                            </td>
                            <td className={clsx(styles.columnActive, styles.cell)}>
                                <SwitchWithAction
                                    id={dictionaryValue.attributeDictionaryId}
                                    value={dictionaryValue.active}
                                    onSwitch={handleUpdateActive}
                                />
                            </td>
                            {isConnectedToERP && <td>{mappingFieldName}</td>}
                            {isConnectedToERP && <td>{dictionaryValue.mappingValue}</td>}
                            <td className={clsx(styles.columnActions, styles.cell)}>
                                <TableActionCell
                                    itemId={dictionaryValue.attributeDictionaryId}
                                    actions={{
                                        edit: {
                                            tooltipTranslation: tooltipTranslations.edit,
                                            action: handleEditAction(dictionaryValue.attributeDictionaryId)
                                        },
                                        delete: {
                                            action: handleRemoveAction(dictionaryValue.attributeDictionaryId),
                                            tooltipTranslation: tooltipTranslations.remove,
                                            modalTranslations: {
                                                header: `${deleteModalTranslations.header} ${getTranslationForIso(dictionaryValue.name, storeCurrentLanguage) || emptyTranslations.name}`,
                                                body: `${deleteModalTranslations.body} ${getTranslationForIso(dictionaryValue.name, storeCurrentLanguage) || emptyTranslations.name}?`,
                                                cancel: deleteModalTranslations.cancel,
                                                confirm: deleteModalTranslations.confirm
                                            },
                                        },
                                    }}
                                />
                            </td>
                        </tr>);
                    })
                }
            </tbody>
        </Table>
    ) : (
        <Row className="d-flex justify-content-center mt-4 mb-4">
            {emptyTranslations.table}
        </Row>
    );
};

AttributeDictionaryValuesTable.propTypes = propTypes;

const mapStateToProps = (state) => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    getDictionaryMappingFields,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(AttributeDictionaryValuesTable));