import React from 'react';
import PropTypes from 'prop-types';

import { FormattedDate, FormattedTime } from 'react-intl';
import Modal from '../Modal';
import { Badge } from 'reactstrap';

import { notificationsModulesColorsConfig } from '../../../config';

import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    translations: PropTypes.object,
    item: PropTypes.object,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    handleSubmit: PropTypes.func,
};

const NotificationModal = ({
    translations,
    isOpen,
    setIsOpen,
    handleSubmit,
    notification,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            header={notification.type}
            body={
                <div className='d-flex flex-column'>
                    <span className='font-weight-bolder my-2'>{notification.details}</span>
                    <span className='my-2'>{notification.description}</span>
                    <div className='my-2'>
                        <h4><Badge color={notificationsModulesColorsConfig[notification.moduleId]}>{notification.module}</Badge></h4>
                    </div>
                    <span className='my-1'>
                        <span className='mr-1'><FormattedTime value={notification.date}/></span>
                        <FormattedDate value={notification.date} day='2-digit' month='2-digit' year='numeric'/>
                    </span>
                </div>
            }
            buttons={{
                confirm: {
                    label: translations.buttons.check,
                    color: 'primary',
                            },
                cancel: {
                    label: translations.buttons.cancel,
                    color: 'secondary'
                }
            }}
            onConfirm={handleSubmit}
        />
    );
};

NotificationModal.propTypes = propTypes;

export default withFormUtils(NotificationModal);