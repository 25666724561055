import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getGoogleSiteMap, updateGoogleSiteMap, generateGoogleSiteMap } from '../../../redux/methods/seo';

import { Alert, Button, Form, Row } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldWithCheckbox from '../../../components/formFields/FormFieldWithCheckbox';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';
import useFetch from '../../../hooks/useFetch';
import ContainerWithCard from '../../../components/ContainerWithCard';
import SuccessSaveToastWithoutRedux from '../../../components/SuccessSaveToastWithoutRedux';
import clsx from 'clsx';

const propTypes = {
    getGoogleSiteMap: PropTypes.func,
    updateGoogleSiteMap: PropTypes.func,
    forceRefetch: PropTypes.bool,
    generateGoogleSiteMap: PropTypes.func,
};

const GoogleSiteMap = ({ getGoogleSiteMap, updateGoogleSiteMap, forceRefetch, generateGoogleSiteMap }) => {
    const translations = useIntl().messages.seo.googleSiteMap;

    const [isSuccess, setIsSuccess] = useState(false);
    const [ isCopied, setIsCopied ] = useState(false);
    const [ isAlertOpen, setIsAlertOpen ] = useState(false);

    const [ googleSiteMapData ] = useFetch(getGoogleSiteMap, null, [], forceRefetch);

    const handleOnSubmit = formData => {
        updateGoogleSiteMap({
            ...formData,
            time: formData.time ? parseInt(formData.time) : 0,
            location: formData.location || '',
        });
    };

    const handleGenerate = async () => {
        await generateGoogleSiteMap();

        setIsSuccess(true);
        setIsSuccess(false);
    };

    const locationRef = useRef(null);

    const handleCopyToClipboard = () => {
        locationRef.current.select();
        document.execCommand('copy');
        setIsCopied(true);
        setIsAlertOpen(true);

        setTimeout(() => {
            setIsAlertOpen(false);
        }, 3000);
    };

    const handleHideAlert = () => {
        setIsAlertOpen(false);
    };

    return googleSiteMapData ? (
        <ContainerWithCard title={translations.title} iconClass='nav-icon cil-sitemap'>
            {
                isCopied &&
                    <Alert
                        className={clsx('fixed-top', 'mt-5 w-50 mx-auto')}
                        color='primary'
                        isOpen={isAlertOpen}
                        toggle={handleHideAlert}
                    >
                        <span>{translations.copy}</span>
                    </Alert>
            }
            <SuccessSaveToastWithoutRedux isSuccess={isSuccess} text={translations.generatedInfo} />
            <FinalForm
                onSubmit={handleOnSubmit}
                initialValues={googleSiteMapData}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <FormFieldWithCheckbox
                                    label={translations.generateAutomatically}
                                    name='generateAutomatically'
                                />
                            </Row>
                            <Row className='mt-3'>
                                <div onClick={handleCopyToClipboard} className='w-100'>
                                    <FormFieldTextOrNumber
                                        label={translations.location}
                                        name='location'
                                        readOnly
                                        className='w-100'
                                        innerRef={locationRef}
                                    />
                                </div>
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldTextOrNumber
                                    label={translations.time}
                                    name='time'
                                    type='number'
                                    min={1}
                                    max={100}
                                />
                            </Row>
                            <Row className='mt-3'>
                                <Button outline type="button" onClick={handleGenerate} color="primary">
                                    {translations.generateButton}
                                </Button>
                            </Row>
                            <SectionEditFormButtons formReset={form.reset}/>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

GoogleSiteMap.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.seoGoogleSiteMap,
});

const mapDispatch = {
    getGoogleSiteMap,
    updateGoogleSiteMap,
    generateGoogleSiteMap,
};

export default connect(mapStateToProps, mapDispatch)(GoogleSiteMap);