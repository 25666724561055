import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { Input } from 'reactstrap';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number']),
    max: PropTypes.number,
    min: PropTypes.number,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
};

const TextOrNumberField = ({ name, label, placeholder, max, min, required = false, type = 'text' }) => {
    return (
        <span className='m-1'>
            <span>{label}</span>
            <Field name={name}>
                {
                    props =>
                        <Input
                            type={type}
                            name={props.input.name}
                            placeholder={placeholder}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            required={required}
                            min={min}
                            max={max}
                        />
                }
            </Field>
        </span>
    );
};

TextOrNumberField.propTypes = propTypes;

export default TextOrNumberField;