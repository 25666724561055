import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from "react-router-dom";

import { generateEditLinkForPredefinedSearch } from '../../../utils/predefinedSearch';

import { predefineSearchPropTypes } from '../../../propTypes';

import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    predefinedSearch: PropTypes.arrayOf(predefineSearchPropTypes),
    removeAction: PropTypes.func,
};

const PredefinedSearchTable = ({ predefinedSearch, removeAction }) => {
    const intl = useIntl();
    const translations = intl.messages.predefinedSearch.table;

    const history = useHistory();

    const deleteAction = (id) => () => {
        removeAction(id);
    };

    const handleDefaultAction = (id) => () => {
        history.push(generateEditLinkForPredefinedSearch(id));
    };

    return (
        <TableGenerator
            items={predefinedSearch}
            editLink={generateEditLinkForPredefinedSearch}
            actions={{deleteAction}}
            defaultAction={handleDefaultAction}
            expectedActions={['edit', 'delete']}
            translations={translations}
            columns={[
                {
                    header: translations.headerLabels.name,
                    value: 'name',
                    filler: translations.emptyPlaceholder
                },
                {
                    header: translations.headerLabels.slug,
                    value: 'slug',
                    filler: translations.emptyPlaceholder
                },
                {
                    header: translations.headerLabels.actions,
                    type: 'actions'
                },
            ]}
        />
    );
};

PredefinedSearchTable.propTypes = propTypes;

export default PredefinedSearchTable;