const tile = {
    view: {
        info: `[EN] Uwaga!
            W przypadku zdefiniowania zbyt małej liczby wypełniaczy, na stronach wyszukiwania mogą powstać puste miejsca.
            Aby tego uniknąć zdefiniuj wypełniacze przynajmniej na jedną stronę wyszukiwania!`,
        title: '[EN] Kafelek',
        settingsTabLabel: '[EN] Ustawienia',
        fillersTabLabel: '[EN] Reklamy uzupełniające',
        actions: {
            save: '[EN] ZAPISZ ZMIANY',
        },
        labels: {
            name: '[EN] Nazwa artykułu',
            count: '[EN] Ilość artykułów na stronie',
            singleFillerCount: '[EN] Ilość pojedynczych wypełniaczy na stronie wyszukiwania',
            doubleFillerCount: '[EN] Ilość podwójnych wypełniaczy na stronie wyszukiwania',
            visibleTagAttributeOnProductImage: '[EN] Atrybut typu Tag widoczny na zdjęciu produktu',
            manufacturerField: '[EN] Informacje o producencie',
            showReviews: '[EN] Pokaż oceny produktu',
            showQuantity: '[EN] Pokaż ilość produktu',
            showAddToFavourites: 'Pokaż ikonę "Dodaj do ulubionych"',
            showAddToComparision: 'Pokaż ikonę "Dodaj do porównania"',
            showRelated: 'Pokaż ikonę "Powiązane"',
            showAddToCart: 'Pokaż przycisk "Dodaj do koszyka"',
            showQuantitySelection: 'Pokaż pola wyboru jednostki i ilości',
            showNetPrice: 'Pokaż cenę netto'
        },
        attributes: {
            id : '[EN] Id',
            catalogueindex : '[EN] Indeks katalogowy',
            tradeindex : '[EN] Indeks handlowy',
            category : '[EN] Kategoria',
            manufacturer : '[EN] Producent',
            measure : '[EN] Miara',
            quantity : '[EN] Ilość',
            availability : '[EN] Dostępność',
            logo : '[EN] Logo producenta',
            ean : '[EN] Ean',
            tax : '[EN] Podatek',
            weight : '[EN] Waga',
            kgo : '[EN] Kgo',
            country : '[EN] Kraj',
            pkwiu : '[EN] Pkwiu',
            swwku : '[EN] Swwku',
            distinguisher : '[EN] Wyróżniacz',
            localisation : '[EN] Lokalizacja',
            cncode : '[EN] Kod CNC',
        },
        addButton: '[EN] DODAJ',
        table: {
            headers: {
                image: '[EN] Zdjęcie',
                name: '[EN] Nazwa',
                active: '[EN] Aktywność',
                actions: '[EN] Akcje'
            },
            tooltips: {
                edit: '[EN] Edytuj reklamę',
                remove: '[EN] Usuń reklamę',
            },
            deleteModal: {
                header: '[EN] Usuń reklamę',
                body: '[EN] Czy jesteś pewien, że chcesz usunąć reklamę?',
                cancel: '[EN] WRÓC',
                confirm: '[EN] USUŃ',
            },
            emptyTable: '[EN] Nie znaleziono reklam uzupełniających'
        },
        editModal: {
            header: '[EN] Edytuj reklamę',
            inputs: {
                photo: '[EN] Zdjęcie',
                file: {
                    upload: '[EN] Dodaj zdjęcie',
                    remove: '[EN] Usuń zdjęcie',
                    edit: '[EN] Podmień zdjęcie',
                },
                alternativeText: '[EN] Tekst alternatywny',
                header: '[EN] Nazwa',
                description: '[EN] Opis',
                link: '[EN] Odnośnik',
                buttonLabel: '[EN] Przycisk',
                urlType: '[EN] Typ odnośnika',
                urlTypes: {
                    category: '[EN] Kategoria',
                    product: '[EN] Produkt',
                    outside: '[EN] Zewnętrzny odnośnik'
                },
                double: '[EN] Kafelek podwójny',
            },
            actions: {
                save: '[EN] ZAPISZ',
                cancel: '[EN] ANULUJ'
            }
        },
        additionalFields: {
            quantity: '[EN] Dostępna ilość',
            measure: '[EN] Rozmiar',
            promotiondateto: '[EN] Okres promocji'
        },
    },
};

export default tile;