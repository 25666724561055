import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useFetch from '../../../../hooks/useFetch';
import { getProductPageTabs, updateProductPageTabs, moveProductPageTab } from '../../../../redux/methods/products';

import { Container } from 'reactstrap';
import ProductTabsTable from '../../../../components/tables/ProductTabsTable';

const propTypes = {
    translations: PropTypes.object,
    getProductPageTabs: PropTypes.func,
    updateProductPageTabs: PropTypes.func,
    moveProductPageTab: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const ProductTabsSettingsSection = ({
    translations,
    getProductPageTabs,
    updateProductPageTabs,
    moveProductPageTab,
    forceRefetch,
}) => {

    const [ tabs ] = useFetch(getProductPageTabs, null, [], forceRefetch);
    return (
        <Container>
            <ProductTabsTable
                translations={translations.table}
                tabs={tabs}
                updateProductPageTabs={updateProductPageTabs}
                changeTabOrder={moveProductPageTab}
            />
        </Container>
    );
};

ProductTabsSettingsSection.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.productPageTabs,
});

const mapDispatch = {
    getProductPageTabs,
    updateProductPageTabs,
    moveProductPageTab,
};

export default connect(mapStateToProps, mapDispatch)(ProductTabsSettingsSection);