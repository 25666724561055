import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { CardBody, CardHeader, Button, Card } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    translations: PropTypes.shape({
        title: PropTypes.string,
        bodyTitle: PropTypes.string,
        bodyFooter: PropTypes.string,
        buttonLabel: PropTypes.string,
    }),
    onClick: PropTypes.func,
    timeoutCounter: PropTypes.number,
};

const SessionTimeoutCard = ({
    translations,
    timeoutCounter,
    onClick,
}) => {
    const adjustSingleNumericNumber = number => number < 10 ? `0${number}` : number;

    return (
        <Card className={styles.card}>
            <CardHeader className='bg-primary'>
                <span>{translations.title}</span>
            </CardHeader>
            <CardBody className='d-flex flex-column text-center'>
                <span>{translations.bodyTitle}</span>
                <span className={clsx('font-weight-bold', styles.counter)}>0:{adjustSingleNumericNumber(timeoutCounter)}</span>
                <span>{translations.bodyFooter}</span>
            </CardBody>
            <Button
                onClick={onClick}
                color='primary'
                size='lg'
            >
                {translations.buttonLabel}
            </Button>
        </Card>
    );
};

SessionTimeoutCard.propTypes = propTypes;

export default SessionTimeoutCard;