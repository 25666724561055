const features = {
    view: {
        title: 'Lista cech',
        paginator: {
            previous: 'Poprzednia',
            next: 'Następna'
        },
        actions: {
            addFeature: 'DODAJ CECHĘ'
        },
        categorySelectionModal: {
            body: 'Wybierz kategorię dla której ma być dodana nowa cecha',
        }
    },
    searchForm: {
        phrase: {
            label: 'Nazwa cechy:',
            placeholder: ''
        },
        category: {
            label: 'Kategoria:',
            placeholder: 'dowolna'
        },
        type: {
            label: 'Typ cechy:',
            placeholder: 'dowolny'
        },
        presentation: {
            label: 'Sposób prezentacji:',
            placeholder: 'dowolny'
        },
        filters: {
            attributeType: {
                placeholder: 'dowolny',
                tag: 'Tag',
                dictionary: 'Słownik',
                int: 'Zakres liczbowy',
                decimal: 'Zakres zmiennoprzecinkowy',
                dateTime: 'Zakres dat',
                color: 'Kolor',
                boolean: 'Jedna z wartości: tak/nie',
                string: 'Tekst'
            },
            attributePresentation: {
                placeholder: 'dowolny',
                tag: 'Chmura tagów',
                color: 'Kolor',
                simpleDictionary: 'Słownik prosty',
                searchableDictionary: 'Słownik rozszerzony',
                range: 'Zakres',
                boolean: 'Jedna z wartości: tak/nie',
                string: 'Tekst'
            }
        },
        action: 'SZUKAJ',
        reset: 'RESETUJ',
    },
    table: {
        headers: {
            name: 'Nazwa cechy',
            categories: 'Kategoria',
            type: 'Typ cechy',
            presentation: 'Sposób prezentacji',
            actions: 'Akcje'
        },
        deleteModal: {
            header: 'Cecha:',
            body: 'Czy jestes pewien, że chcesz usunąć cechę',
            confirm: 'USUŃ',
            cancel: 'WRÓĆ'
        },
        tooltips: {
            edit: 'Edytuj cechę',
            remove: 'Usuń cechę',
            add: 'Dodaj cechę'
        },
        empty: 'Nie znaleziono żadnych cech',
        nameFiller: '[BRAK NAZWY]',
        errorModalText: 'Wystąpił błąd podczas usuwania cechy'
    },
    edit: {
        nameFiller: '[BRAK NAZWY]',
        tabSelectorTitle: 'Sekcje',
        tabs: {
            basic: {
                title: 'Dane podstawowe',
                inputs: {
                    name: 'Nazwa cechy',
                    type: 'Typ cechy',
                    presentationType: 'Prezentacja cechy',
                    isRequired: 'Jest wymagana',
                    isRequiredTooltip: 'Wartość tej cechy musi zostać określona dla wszystkich produktów w katalogu produktów',
                    isFilter: 'Jest filtrem',
                    isFilterTooltip: 'Ta cecha zostanie wyeksponowana w sklepie jako filtr, po którym użytkownicy mogą wyszukiwać produkty',
                    isMultiselect: 'Możliwość wielokrotnego wyboru',
                    isMultiselectTooltip: 'Każdy produkt może mieć więcej określonych wartości dla tej cechy niż jedna'
                },
                editModalHeader: 'Nazwa cechy'
            },
            categories: {
                title: 'Przypisane kategorie',
                table: {
                    columnNames: {
                        categories: 'Przypisana kategoria',
                        actions: 'Akcje',
                    },
                    tooltip: 'Odłącz kategorię',
                    placeholder: 'Nie znaleziono kategorii przypisanych do wybranej cechy',
                    deleteModal: {
                        header: 'Odłącz kategorię',
                        bodyStart: 'Czy chcesz odłączyć kategorię',
                        bodyEnd: 'od wybranej cechy',
                        confirm: 'ODŁĄCZ',
                        cancel: 'WRÓĆ'
                    }
                },
                controls: {
                    add: 'PRZYPISZ KATEGORIĘ',
                    remove: 'ODŁĄCZ WYBRANE KATEGORIE'
                },
                additionModal: {
                    header: 'Przypisz wybraną kategorię',
                    body: 'Czy przypisać wybraną kategorię do cechy',
                    confirm: 'PRZYPISZ',
                    cancel: 'WRÓĆ'
                },
                deletionModal: {
                    header: 'Odłącz wybrane kategorię',
                    body: 'Czy odłączyć wybrane kategorie od cechy',
                },
                categorySelectionModal: {
                    header: 'Wybór kategorii',
                    body: 'Wybierz kategorię, która ma być przypisana do obecnej cechy',
                    emptySelectionLabel: 'Wybierz kategorię',
                    confirm: 'WYBIERZ',
                    cancel: 'WRÓĆ'
                },
                nameFiller: '[BRAK NAZWY]',
                targetCategoryError: 'Wybrana kategoria już jest przypisana do obecnej cechy, wybierz inną kategorię.'
            },
            values: {
                title: 'Wartości',
                colors: {
                    columnNames: {
                        color: 'Kolor',
                        name: 'Nazwa',
                        actions: 'Akcje',
                    },
                    actions: {
                        edit: 'Edytuj kolor',
                        remove: 'Usuń kolor',
                        addColor: 'DODAJ KOLOR'
                    },
                    deleteModal: {
                        header: 'Kolor:',
                        body: 'Czy jestes pewien, że chcesz usunąć kolor',
                        confirm: 'USUŃ',
                        cancel: 'WRÓĆ'
                    },
                    additionModal: {
                        header: 'Dodaj nowy kolor',
                        body: 'Czy chcesz utworzyć nowy kolor?',
                        confirm: 'DODAJ',
                        cancel: 'WRÓĆ'
                    },
                    empty: {
                        table: 'Nie znaleziono żadnych kolorów dla cechy',
                        name: '[BRAK NAZWY]'
                    }
                },
                dictionaryValues: {
                    columnNames: {
                        name: 'Nazwa',
                        actions: 'Akcje',
                    },
                    actions: {
                        edit: 'Edytuj wartość',
                        remove: 'Usuń wartość',
                        addColor: 'DODAJ WARTOŚĆ'
                    },
                    deleteModal: {
                        header: 'Wartość:',
                        body: 'Czy jestes pewien, że chcesz usunąć wartość',
                        confirm: 'USUŃ',
                        cancel: 'WRÓĆ'
                    },
                    additionModal: {
                        header: 'Dodaj nową wartość',
                        body: 'Czy chcesz utworzyć nową wartość?',
                        confirm: 'DODAJ',
                        cancel: 'WRÓĆ'
                    },
                    empty: {
                        table: 'Nie znaleziono żadnych wartości dla cechy',
                        name: '[BRAK NAZWY]'
                    },
                    inputs: {
                        name: 'Nazwa wartości'
                    }
                },
                dateRange: {
                    startDateLabel: 'Data minimalna',
                    endDateLabel: 'Data maksymalna'
                },
                range: {
                    minLabel: 'Limit dolny',
                    maxLabel: 'Limit górny',
                    precisionLabel: 'Precyzja'
                }
            },
            settings: {
                title: 'Ustawienia',
                inputs: {
                    isExtended: 'Jest permanentnie rozwinięty',
                    isExtendedTooltip: 'Parametr określający, czy atrybut znajdujący się w menu bocznym domyślnie będzie będzie rozwinięty',
                    searchPlaceholder: 'Placeholder frazy wyszukiwania',
                    foldedDictionaryItemsVisible: 'Liczba rozwiniętych wartości filtra'
                },
                empty: 'Nie można zmienić żadnych ustawień dla obecnego typu cechy',
                editModalHeader: 'Placeholdery frazy wyszukiwania'
            }
        }
    }
};

export default features;