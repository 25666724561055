import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getShipments, deleteShipment, updateShipmentStatus } from '../../../../redux/methods/products';
import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';

import { useIntl } from 'react-intl';

import ServiceTable from '../../../../components/tables/ServiceTable';
import useFetch from '../../../../hooks/useFetch';
import { translateProductName } from '../../../../utils/products';
import Paginator from '../../../../components/Paginator';
import { useHistory } from 'react-router-dom';

const propTypes = {
    props: PropTypes.object,
};

const Table = ({
    forceRefetch,
    getShipments,
    deleteShipment,
    updateShipmentStatus,
    searchConfig,
    changeSearchConfig,
    storeCurrentLanguage,
    changePage
}) => {
    const intl = useIntl();

    const history = useHistory();

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    const [ shipments, pages ] = useFetch(getShipments, searchConfig, [searchConfig], forceRefetch);

    const translatedShipments = shipments && translateProductName(shipments, storeCurrentLanguage);

    const editShipment = (id) => {
        if (id) {
            history.push(`/shipmentServices/list/edit?productID=${id}`);
        }
    };

    return shipments ? (
        <React.Fragment>
            <ServiceTable
                translations={intl.messages.services.shipments.table}
                performPageSizeSelect={handleChangePageSize}
                pageSize={searchConfig.pageSize}
                currentPage={searchConfig.curentPage + 1}
                services={translatedShipments}
                history={history}
                performDeleteAction={deleteShipment}
                performEditAction={editShipment}
                updateAction={updateShipmentStatus}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={searchConfig.curentPage + 1}
                        onPageChange={changePage}
                    />
            }
        </React.Fragment>
    ) : null;
};

Table.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.shipments,
    storeCurrentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
    getShipments,
    deleteShipment,
    updateShipmentStatus
};

export default connect(mapStateToProps, mapDispatch)(Table);