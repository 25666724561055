import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProductReviews, updateProductReview } from '../../../../redux/methods/products';

import OpinionsTable from '../../../../components/tables/OpinionsTable';

import { Container} from 'reactstrap';

import { stringOrNumberPropTypes } from '../../../../propTypes';

import useFetch from '../../../../hooks/useFetch';
import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    id: stringOrNumberPropTypes,
    forceRefetch: PropTypes.bool,
    getProductReviews: PropTypes.func,
    updateProductReview: PropTypes.func,
};

const ProductEditOpinions = ({ id, forceRefetch, getProductReviews, updateProductReview }) => {
    const [ opinions ] = useFetch(getProductReviews, id, [], forceRefetch);

    const basicStructureOfRequest = {
        productReviewsIds: [],
        isContentVisible: null,
        isNameVisible: null,
        accepted: null,
        deleted: null
    };

    const handleRemove = reviewId => {
        updateProductReview({
            ...basicStructureOfRequest,
            productReviewsIds: [reviewId],
            deleted: true,
            accepted: false,
        });
    };

    const handleSwitch = (reviewId, value) => {
        updateProductReview({
            ...basicStructureOfRequest,
            productReviewsIds: [reviewId],
            isContentVisible: value
        });
    };

    return opinions ? (
        <React.Fragment>
            <Container fluid>
                <OpinionsTable
                    opinions={opinions}
                    productId={id}
                    removeAction={handleRemove}
                    switchAction={handleSwitch}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

ProductEditOpinions.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.productReviews,
});

const mapDispatch = {
    updateProductReview,
    getProductReviews,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ProductEditOpinions));