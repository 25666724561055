import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateDiscountBasic } from '../../../../redux/methods/discounts';
import { getCurrencies } from '../../../../redux/methods/currencies';
import useFetch from '../../../../hooks/useFetch';

import { generateDropdownOptionsForCurrencies, generateDropdownOptions, generateStatuses } from '../../../../utils/discounts';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row, Col } from 'reactstrap';

import { getKeyByValue } from '../../../../utils/components';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';
import FormFieldWithCalendarPicker from '../../../../components/formFields/FormFieldWithCalendarPicker';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldStatus from '../../../../components/formFields/FormFieldStatus';
import FormFieldSwitchPill from '../../../../components/formFields/FormFieldSwitchPill';

import { discountTypeEnum, discountRangeEnum, discountStatusEnum } from '../../../../enums/discounts';
import { discountPropTypes, formUtilsHocPropTypes } from '../../../../propTypes';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    discountToEdit: discountPropTypes,
    getCurrencies: PropTypes.func,
    updateDiscountBasic: PropTypes.func,
    utils: formUtilsHocPropTypes.isRequired,
};

const DiscountEditBasic = ({
    discountToEdit,
    getCurrencies,
    updateDiscountBasic,
    utils,
}) => {

    const history = useHistory();

    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.basic;

    const [ currencies ] = useFetch(getCurrencies);

    const handleOnSubmit = async formData => {
        const response = await updateDiscountBasic(discountToEdit.discountId, formData);
        if(typeof response === 'number') {
            history.replace(`/discounts/edit?discountID=${response}`);
        }
        return response;
    };

    const statuses = generateStatuses(discountStatusEnum, translations.status);

    return currencies ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={discountToEdit}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {

                        const { values } = form.getState();

                        const actualCurrency = currencies && currencies.find(currency => currency.id === values.currencyId);
                        const selectedStatus = statuses.find(status => status.id === values.discountStatusId);

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='px-0' fluid>
                                    <Row>
                                        <Col>
                                            <FormFieldStatus
                                                name='discountStatusId'
                                                statuses={statuses}
                                                selectedStatus={selectedStatus}
                                                label={translations.inputs.status}
                                            />
                                        </Col>
                                        <Col>
                                            <FormFieldSwitchPill
                                                utils={utils}
                                                name='forPromotions'
                                                label={translations.inputs.forPromotions}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldTextOrNumber
                                            name='name'
                                            label={translations.inputs.name}
                                            className='w-100 mr-md-3'
                                        />
                                        <FormFieldTextOrNumber
                                            name='code'
                                            label={translations.inputs.code}
                                            className='w-100 mt-3 mt-md-0'
                                        />
                                    </Row>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldTextOrNumber
                                            type='number'
                                            min={0}
                                            name='priority'
                                            label={translations.inputs.priority}
                                            required={true}
                                            className='w-100 mr-md-3'
                                            tooltipText={translations.priorityTooltip}
                                        />
                                        <FormFieldWithDropdown
                                            name='currencyId'
                                            currentSelectionLabel={actualCurrency && actualCurrency.name}
                                            options={generateDropdownOptionsForCurrencies(currencies.filter(c => c.active))}
                                            label={translations.inputs.currency}
                                            className='w-100 mt-3 mt-md-0'
                                        />
                                    </Row>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldWithDropdown
                                            name='discountTypeId'
                                            currentSelectionLabel={translations.type[getKeyByValue(discountTypeEnum, values.discountTypeId)]}
                                            options={generateDropdownOptions(discountTypeEnum, null, translations.type)}
                                            label={translations.inputs.type}
                                            className='w-100 mr-md-3'
                                        />
                                        <FormFieldWithDropdown
                                            name='discountRangeId'
                                            currentSelectionLabel={translations.range[getKeyByValue(discountRangeEnum, values.discountRangeId)]}
                                            options={generateDropdownOptions(discountRangeEnum, null, translations.range)}
                                            label={translations.inputs.range}
                                            className='w-100 mt-3 mt-md-0'
                                        />
                                    </Row>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldWithCalendarPicker
                                            label={translations.inputs.validFrom}
                                            name='validFrom'
                                            storeCurrentLanguage={utils.currentLanguage()}
                                            className='w-100 mr-md-3'
                                        />
                                        <FormFieldWithCalendarPicker
                                            label={translations.inputs.validTo}
                                            name='validTo'
                                            storeCurrentLanguage={utils.currentLanguage()}
                                            minDate={new Date(values.validFrom)}
                                            className='w-100 mt-3 mt-md-0'
                                        />
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

DiscountEditBasic.propTypes = propTypes;

const mapStateToProps = (state) => ({
    discountToEdit: state.discounts.discountToEdit.data
});

const mapDispatch = {
    updateDiscountBasic,
    getCurrencies,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(DiscountEditBasic));