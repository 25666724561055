import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Button } from 'reactstrap';

import styles from './index.module.scss';

const ErpSyncButton = ({ isConnectedToERP, className }) => {
    const intl = useIntl();
    const translations = intl.messages.defaultLayout.appAside.erpConnection;
    const history = useHistory();

    const handleOnClick = () => {
        history.push('/synchronizations');
    };

    return (
        <Button className={className} onClick={handleOnClick} color={isConnectedToERP ? 'success' : 'secondary'}>
            <i className={clsx("cil-cast mr-2", styles.icon)} />
            <span>{isConnectedToERP ? translations.active : translations.inactive}</span>
        </Button>
    );
};

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
});

export default connect(mapStateToProps)(ErpSyncButton);