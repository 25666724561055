const report = {
    opinions: {
        tooltips: {
            remove: '[EN] Usuń opinie',
            edit: '[EN] Przejdź do produktu',
            accept: '[EN] Akceptuj opinie',
        },
        emptyTable: '[EN] Brak opinii do zatwierdzenia',
        deleteModal: {
            header: '[EN] Usuwanie opinii',
            body: '[EN] Czy na pewno chcesz usunąć opinie?',
            confirm: '[EN] USUŃ',
            cancel: '[EN] ANULUJ',
        },
        buttons: {
            accept: '[EN] Akceptuj zaznaczone',
            remove: '[EN] Usuń zaznaczone',
        },
        headers: {
            userName: '[EN] Nazwa użytkownika',
            name: '[EN] Nazwa produktu',
            date: '[EN] Data wystawienia',
            content: '[EN] Treść',
            rating: '[EN] Ocena',
            actions: '[EN] Akcje',
        },
    }
};

export default report;