import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { makeIconClassNameForActionName } from '../../../utils/components';

import TooltipWrapper from '../../TooltipWrapper';

import styles from './index.module.scss';

import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    item: PropTypes.object,
    itemId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    actions: PropTypes.shape({
        delete: PropTypes.shape({
            modalTranslations: PropTypes.shape({
                header: PropTypes.string,
                body: PropTypes.string,
                confirm: PropTypes.string,
                cancel: PropTypes.string
            }).isRequired,
            tooltipTranslation: PropTypes.string,
            action: PropTypes.func.isRequired,
        }),
        edit: PropTypes.shape({
            tooltipTranslation: PropTypes.string,
            editLink: PropTypes.string,
            action: PropTypes.func,
        }),
        showDetails: PropTypes.shape({
            tooltipTranslation: PropTypes.string,
            action: PropTypes.func.isRequired,
        }),
        print: PropTypes.shape({
            tooltipTranslation: PropTypes.string,
            action: PropTypes.func.isRequired,
        }),
        accept: PropTypes.shape({
            tooltipTranslation: PropTypes.string,
            action: PropTypes.func.isRequired,
        }),
    }),
    keyToPass: PropTypes.string,
    checkIfActionIsDisabled: PropTypes.func
};

const TableActionCell = ({ utils, item, itemId,  actions, keyToPass, checkIfActionIsDisabled }) => {

    const [ openTooltips, setOpenTooltips ] = useState([]);

    const handleTooltipToggle = actionName => () => {
        const isOpen = openTooltips.indexOf(actionName) > -1;
        if (isOpen) {
            setOpenTooltips(openTooltips.filter(item => item !== actionName));
        } else {
            setOpenTooltips(openTooltips.concat(actionName));
        }
    };

    /* Generate array of array where array[0] = actiionName e.g "delete" , array[1] = action props e.g tooltipTranslation/actionFunc */
    const arrayActionsFromObject = actions && Array.from(Object.entries(actions));

    /* Only for delete/showDetails actions. Edit action is generate in other (Link) component where it has assigned icon*/

    /* action[0] = actionName, action[1] = other props e.g action() func */
    const handleOnClick = (action) => () => {
        if(action[0] === "delete") {
            utils.confirm(
                () => actions.delete.action(action[1].item),
                actions.delete.modalTranslations.header,
                actions.delete.modalTranslations.body
            );
        }
        else {
            action[1].action();
        }
    };

    const handleCheckIfActionIsDisabled = (action) => {
        if (checkIfActionIsDisabled === null || (typeof(checkIfActionIsDisabled) !== 'function')) {
            return false;
        }
        return checkIfActionIsDisabled(action, item);
    };

    const checkIfTooltipIsOpen = id => openTooltips.indexOf(id) !== -1 ? true : false;
    return (
        <React.Fragment>
            <span className={styles.container}>
            {arrayActionsFromObject && arrayActionsFromObject.map(action => {
                return (
                    <TooltipWrapper
                        key={`action-${action[0]}-${itemId}`}
                        target={`${action[0]}-${itemId}-${keyToPass}`}
                        placement="top"
                        isOpen={checkIfTooltipIsOpen(action[0])}
                        setIsOpen={handleTooltipToggle(action[0])}
                        tooltipContent={action[1].tooltipTranslation}
                    >
                        {
                            action[0].startsWith('user') ?
                            (
                                <button disabled={action[1].disabled()} className={styles.item} onClick={handleOnClick(action)} id={`${action[0]}-${itemId}-${keyToPass}`}>
                                    <i className={clsx(action[1].icon, styles[`${action[1].tag}`])}/>
                                </button>
                            )
                            : (
                                action[0] === "edit" ? (
                                    action[1].action ? (
                                        <button disabled={handleCheckIfActionIsDisabled(action[0])} className={styles.item} onClick={action[1].action} id={`${action[0]}-${itemId}-${keyToPass}`}>
                                            <i className={clsx(makeIconClassNameForActionName(action[0]), styles[`${action[0]}`])}/>
                                        </button>
                                    ) : (
                                        <Link disabled={handleCheckIfActionIsDisabled(action[0])} className={styles.item} to={action[1].editLink} id={`${action[0]}-${itemId}-${keyToPass}`}>
                                            <i className={clsx(makeIconClassNameForActionName(action[0]), styles[`${action[0]}`])}/>
                                        </Link>
                                    )
                                ) : (
                                    <button disabled={handleCheckIfActionIsDisabled(action[0])} className={styles.item} onClick={handleOnClick(action)} id={`${action[0]}-${itemId}-${keyToPass}`}>
                                        <i className={clsx(makeIconClassNameForActionName(action[0]), styles[`${action[0]}`])}/>
                                    </button>
                                )
                            )
                        }
                    </TooltipWrapper>
                );
            })}

            </span>
        </React.Fragment>
    );
};

TableActionCell.propTypes = propTypes;

export default withFormUtils(TableActionCell);