import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import useFetch from '../../../hooks/useFetch';
import useSearchConfig from '../../../hooks/useSearchConfig';
import { getMeasures, updateMeasure, deleteMeasure } from '../../../redux/methods/products';

import { measuresSearchConfig } from '../../../config/searchConfigs';

import ContainerWithCard from '../../../components/ContainerWithCard';
import UnitsTableSection from '../../../features/units/sections/UnitsTableSection';

const propTypes = {
    isShopUnitsActive: PropTypes.bool,
    forceRefetch: PropTypes.bool,
    getMeasures: PropTypes.func,
    updateMeasure: PropTypes.func,
    deleteMeasure: PropTypes.func,
};

const Units = ({
    forceRefetch,
    getMeasures,
    updateMeasure,
    deleteMeasure
}) => {
    const intl = useIntl();
    const translations = intl.messages.units;

    const [ searchConfig ] = useSearchConfig(measuresSearchConfig);
    const [ units ] = useFetch(getMeasures, searchConfig, [ searchConfig ], forceRefetch);

    return (
        <ContainerWithCard title={translations.title} iconClass='fa fa-balance-scale'>
            <UnitsTableSection
                    translations={translations}
                    units={units}
                    updateMeasure={updateMeasure}
                    deleteMeasure={deleteMeasure}
            />
        </ContainerWithCard>
    );
};

Units.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.measures,
});

const mapDispatch = {
    getMeasures,
    updateMeasure,
    deleteMeasure,
};

export default connect(mapStateToProps, mapDispatch)(Units);