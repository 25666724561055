/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removePromoSectionImage } from '../../../../../redux/methods/homePage';

import FileSender from '../../../../../components/FileSender';

import { stringOrNumberPropTypes } from '../../../../../propTypes';

const propTypes = {
    setFile: PropTypes.func,
    removePromoSectionImage: PropTypes.func,
    translations: PropTypes.object,
    sectionId: stringOrNumberPropTypes,
    section: PropTypes.object
};

const FileSenderPromotedSections = ({
    sectionId,
    removePromoSectionImage,
    translations,
    setFile,
    section
}) => {
    const handleRemoveImage = () => {
        removePromoSectionImage(sectionId);
    };

    return (
        <FileSender
            validateFormats={['jpg', 'png', 'jpeg', 'gif', 'mp4', 'webm', 'webp']}
            buttons={translations.inputs.file}
            photo={section.imageSource}
            withPreview
            fileKey='Image'
            handleRemove={handleRemoveImage}
            setFile={setFile}
            graphicTypeToEnableGuide='tile'
        />
    );
};

FileSenderPromotedSections.propTypes = propTypes;

const mapDispatch = {
    removePromoSectionImage,
};

export default connect(null, mapDispatch)(FileSenderPromotedSections);