import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form } from 'reactstrap';

import SectionAsideButtons from '../../../../../components/SectionAsideButtons';
import HomePageSectionMainData from '../../HomePageSectionMainData';

import { withFormUtils } from '../../../../../hoc/withFormUtils';

import styles from './index.module.scss';

import { getReturnButtonProps, onSubmit } from '../..';

const propTypes = {
    addUpdatePageSection: PropTypes.func,
    section: PropTypes.object,
};

const TabSectionParams = ({ utils, section, addUpdatePageSection, sectionLocation }) => {
    const history = useHistory();
    const intl = useIntl();
    const translations = intl.messages.homePage.promoSections.sections;

    const config = { translations: ['title'], date: ['activeFrom', 'activeTo'] };

    const handleOnSubmit = async formData => {
        const response = await addUpdatePageSection(utils.generateFormToSubmitValues(formData, config));

        return onSubmit(section, sectionLocation, history, response);
    };

    return section ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <FinalForm
                    initialValues={utils.generateFormInitialValues(section, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <HomePageSectionMainData isSectionHeightController={false} isNumberOfTilesController={true} form={form} />
                                <SectionAsideButtons
                                    buttons={{
                                        add: {
                                            color: 'success',
                                            label: translations.button,
                                            action: handleSubmit,
                                            iconClass: 'fa fa-save mr-2',
                                        },
                                        remove: getReturnButtonProps(sectionLocation, section, history, translations)
                                    }}
                                />
                            </Form>
                        );
                    }}
                />
            </div>
        </Container>
    ) : null;
};

TabSectionParams.propTypes = propTypes;

export default withFormUtils(TabSectionParams);