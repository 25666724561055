import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getSynchronizationsAuth, synchronizationAuthGenerateToken } from '../../../../redux/methods/synchronizations';

import { Form as FinalForm } from 'react-final-form';
import { Button, Container, Form, Row } from 'reactstrap';

import useFetch from '../../../../hooks/useFetch';
import FormFieldTextWithCopy from '../../../../components/formFields/FormFieldTextWithCopy';

const propTypes = {
    getSynchronizationsAuth: PropTypes.func,
    synchronizationAuthGenerateToken: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const Authentication = ({ getSynchronizationsAuth, synchronizationAuthGenerateToken, forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.synchronizations.authentication;

    const [ authData ] = useFetch(getSynchronizationsAuth, null, [], forceRefetch);

    return authData ? (
        <Container fluid>
            <FinalForm
                initialValues={authData}
                onSubmit={() => {}}
                render={() => {
                    return (
                        <Form>
                            <Row className='mb-3'>
                                <FormFieldTextWithCopy
                                    name='shopId'
                                    label={translations.shopId}
                                    className='mr-3'
                                />
                            </Row>
                            <Row className='mb-3'>
                                <FormFieldTextWithCopy
                                    name='synchroApiToken'
                                    label={translations.synchroApiToken}
                                    className='mr-3'
                                />
                            </Row>
                        </Form>
                    );
                }}
            />
            <Row>
                <Button onClick={synchronizationAuthGenerateToken} color='primary'>{translations.generate}</Button>
            </Row>
        </Container>
    ) : null;
};

Authentication.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.generateToken,
});

const mapDispatch = {
    getSynchronizationsAuth,
    synchronizationAuthGenerateToken,
};

export default connect(mapStateToProps, mapDispatch)(Authentication);