import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CategoryTree from '../CategorySelect/CategoryTree';
import styles from './index.module.scss';

import { generateNonIdsOptionsForDropdown } from '../../utils/components';

import Dropdown from '../Dropdown';

const CategoryTreeBasic = ({
    categories,
    handleSelectionChanged,
    selectedCategoryId,
    translations,
    notRewind = false,
    scroll = true,
    searchConfig,
    withDropdown = true,
    withFilter = false,
}) => {
    const [ membersRefresh, setMembersRefresh ] = useState(0);
    const [ members, setMembers ] = useState(categories);
    const [ selectedCategoriesRank, setSelectedCategoriesRank ] = useState('all');
    const [ currentSelected, setCurrentSelected ] = useState(1);

    const categoriesRankOptions = generateNonIdsOptionsForDropdown(['all', 'general'], translations.dropdownSelector.options);

    useEffect(() => {
        if(categories && categories.length > 0) {
            if(selectedCategoriesRank === 'general') {
                handleSelectionChanged(null);
                setMembers([]);
            }
            else {
                setMembers(categories);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategoriesRank]);

    const refresh = () => {
        setMembers(members);
        setMembersRefresh(membersRefresh+1);
    };

    const mapCategories = (callBack) => {
        const mapper = (member, parents, callBack) => {
            if (Array.isArray(member)) {
                for(const idx in member) {
                    mapper(member[idx], parents, callBack);
                }
            }
            else {
                callBack(member, parents);
                if (member.children && member.children.length) {
                    mapper(member.children, parents.concat(member), callBack);
                }
            }
        };
        mapper(members, [], callBack);
    };

    const handleExpand = (id) => {
        mapCategories(category => {
            if (parseInt(category.id) === id) {
                category.expanded = !category.expanded;
            }
        });
        refresh();
    };

    const handleSelect = (id) => {
        mapCategories(category => {
            category.selected = parseInt(category.id) === id;
        });
        handleSelectionChanged(parseInt(id));
        refresh();
    };

    if (selectedCategoryId !== null && notRewind === false && scroll){
        const el = document.getElementById("categoryTreeItem-"+selectedCategoryId);
        if (el) {
            el.scrollIntoView();
        }
    }

    const dropdownOptions = [
        {
            id: 0,
            label: translations.dropdownSelector.options.all
        },
        {
            id: 1,
            label: translations.dropdownSelector.options.active
        }
    ];

    const filterActive = (members) => {
        const fildered =  members.filter(n => n.active);

        return fildered.map(item => ({
            ...item,
            children: item.children && item.children.length > 0 ? filterActive(item.children) : item.children
        }));
    };

    useEffect(() => {
        if (searchConfig) {
            mapCategories((category) => {
                category.expanded = false;
                category.selected = false;
            });
            mapCategories((category, parents) => {
                if (parseInt(category.id) === searchConfig.categoryId) {
                    parents.map((parent) => { parent.expanded = true; });
                    category.selected = true;
                    refresh();
                }
            });
        }
    }, [searchConfig]);

    return members ? (
        <div>
            <div className={clsx('m-2 ml-3', styles.categoryTreeBox)}>
                {withDropdown &&
                    <>
                        <span className={styles.controlsView}>
                            <span>{translations.dropdownSelector.label}</span>
                            <Dropdown
                                options={categoriesRankOptions}
                                currentSelectionLabel={translations.dropdownSelector.options[selectedCategoriesRank]}
                                performSelection={setSelectedCategoriesRank}
                            />
                        </span>
                        <div className={clsx('mt-3', selectedCategoriesRank !== 'general' && 'd-none' )}>
                            <span className={styles.generalCategoryLabel}>{translations.generalCategoryLabel}</span>
                        </div>
                    </>
                }
                {withFilter && <span>
                    <span>{translations.dropdownSelector.label}</span>
                    <Dropdown
                        options={dropdownOptions}
                        currentSelectionLabel={dropdownOptions.find(item => item.id === currentSelected ).label}
                        performSelection={setCurrentSelected}
                    />
                </span>}
                <CategoryTree
                    members={currentSelected ? filterActive(members) : members}
                    membersRefresh={membersRefresh}
                    handleExpand={handleExpand}
                    handleSelect={handleSelect} />
            </div>
        </div>
    ) : null;
};

CategoryTreeBasic.propTypes = {
    categories: PropTypes.arrayOf(Object),
    handleSelectionChanged: PropTypes.func,
    handleSelectionConfirmed: PropTypes.func
};

export default CategoryTreeBasic;