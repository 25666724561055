const general = {
    settings: {
        tabs: {
            articles: {
                title: "Articles",
                button: {
                    save: 'SAVE'
                },
                inputs: {
                    showOnlyAvailable: 'Only show items that are available',
                    showAvailabilityFilter: 'Show availability filter',
                    displayNetPrice: 'Show net price',
                    canOrderUnavailable: 'Allow ordering of out of stock items',
                    canOrderOverStock: 'Allow over stock ordering',
                    displayPrice: 'Display price before promotion.',
                    displayPercentageDiscount: 'Display discount percentage.',
                    displayDate: 'Display promotion duration.',
                    infiniteScroll: 'Load new products while scrolling.',
                    displayCurrency: "Don't display currencies.",
                    defaultView: 'Default View',
                    isListCondensed: 'Condensed layout',
                    views: 'Active views',
                    list: 'List',
                    tile: 'Tiles',
                    typeOfUnitPresentation: 'Unit display way',
                    typeOfUnitPresentationOptions: {
                        name: 'Name',
                        shortcut: 'Abbreviation',
                    },
                    emptyLabel: 'None',
                    defaultCurrencyId: 'Default currency',
                    defaultLanguageId: 'Default language'
                }
            },
            categories: {
                title: 'Categories',
                settingsTitle: 'Category settings',
                searchAfterCategoryTreeClick: 'Search for products while browsing the category tree',
                showProductsFromSubcategories: 'Show products contained in subcategories',
                showCategoryTreeOnSearchPage: 'Show category tree on product list',
                showOnlyOneCategoryExpanded: 'Show only one branch expanded in the category tree',
                showOnlySubTreeOfSelectedCategory: 'Only show the children of the selected category',
                showSubCategoriesTilesAboweSearchResult: 'Show subcategory tiles above search results',
                showPictogramsInCategoryMenu: 'Show pictograms in category menu',
                halfSize: 'Photo',
                pictogram: 'Piktogram',
                presentationType: 'The type of image displayed on the tile'
            }
        }
    }
};

export default general;