import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getTechnicalBreak, updateTechnicalBreak, updateTechnicalBreakBackground } from '../../../redux/methods/extrasSettings';

import useFetch from '../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Col, Row, Form, Alert } from 'reactstrap';

import SectionAsideButtons from '../../../components/SectionAsideButtons';
import FormFieldWithCalendarAndTimePicker from '../../../components/formFields/FormFieldWithCalendarAndTimePicker';
import FormFiedSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import FormFieldTranslationsQuill from '../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldColorPicker from '../../../components/formFields/FormFieldColorPicker';
import ContainerWithCard from '../../../components/ContainerWithCard';

import { withFormUtils } from '../../../hoc/withFormUtils';

import { formUtilsHocPropTypes } from '../../../propTypes';
import FileSender from './FileSender';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getTechnicalBreak: PropTypes.func,
    updateTechnicalBreak: PropTypes.func,
};

const TechnicalBreak = ({ utils, getTechnicalBreak, updateTechnicalBreak, updateTechnicalBreakBackground }) => {
    const intl = useIntl();
    const translations = intl.messages.technicalBreak;

    const [ technicalBreakData ] = useFetch(getTechnicalBreak);
    const [ imageFile, setImageFile ] = useState(null);

    const config = { translations: ['content'], date: ['startDate', 'endDate'] };

    const handleOnSubmit = formData => {
        updateTechnicalBreak(utils.generateFormToSubmitValues(formData, config));

        if(imageFile && imageFile.generatedFile) {
            updateTechnicalBreakBackground(imageFile.generatedFile);
        }
    };

    return technicalBreakData ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search'>
            <FinalForm
                onSubmit={handleOnSubmit}
                initialValues={utils.generateFormInitialValues(technicalBreakData, config)}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Alert color="info" className='mt-2'>
                                {translations.info}
                            </Alert>
                            <Row className='mb-3'>
                                <span className='mr-2'>{translations.labels.isActive}</span>
                                <FormFiedSwitchPill name='isActive'/>
                            </Row>
                            <Row>
                                <Col>
                                    <FormFieldWithCalendarAndTimePicker name='startDate' label={translations.labels.startDate}/>
                                </Col>
                                <Col>
                                    <FormFieldWithCalendarAndTimePicker name='endDate' label={translations.labels.endDate}/>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldColorPicker
                                    name='backgroundColor'
                                    button={translations.labels.backgroundButton}
                                    label={translations.labels.backgroundColor}
                                />
                            </Row>
                            <Row className='mt-3'>
                                <FileSender translations={translations} setFile={setImageFile} />
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldTranslationsQuill
                                    form={form}
                                    utils={utils}
                                    propertyName='content'
                                    header={translations.labels.content}
                                />
                            </Row>
                            <SectionAsideButtons
                                buttons={{
                                    add: {
                                        color: 'success',
                                        label: translations.button,
                                        action: handleSubmit,
                                        iconClass: 'fa fa-save mr-2',
                                    }
                                }}
                            />
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

TechnicalBreak.propTypes = propTypes;

const mapDispatch = {
    getTechnicalBreak,
    updateTechnicalBreak,
    updateTechnicalBreakBackground,
};

export default withFormUtils(connect(null, mapDispatch)(TechnicalBreak));