import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCookieById, updateCookie } from '../../../redux/methods/extrasSettings';

import { useHistory } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';

import ContainerWithCard from '../../../components/ContainerWithCard';
import { Form as FinalForm } from 'react-final-form';
import { Row, Form } from 'reactstrap';

import SectionAsideButtons from '../../../components/SectionAsideButtons';
import FormFieldTranslationsQuill from '../../../components/formFields/FormFieldTranslationsQuill';

import { withFormUtils } from '../../../hoc/withFormUtils';
import FormFieldTranslations from '../../../components/formFields/FormFieldTranslations';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';

const propTypes = {
    utils: PropTypes.object,
    getCookieById: PropTypes.func,
    updateCookie: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const CookiesAgreementsEdit = ({ location, utils, getCookieById, updateCookie, forceRefetch }) => {
    const intl = useIntl();
    const history = useHistory();
    const translations = intl.messages.cookiesAgreements.edit;
    const config = { translations: ['header', 'shortContent', 'longContent'] };

    const query = new URLSearchParams(location.search);
    const chosenAgreementId = query.get('agreementID');
    const [ cookieData ] = useFetch(getCookieById, chosenAgreementId, [], forceRefetch);

    const handleOnSubmit = formData => {
        updateCookie(chosenAgreementId, utils.generateFormToSubmitValues(formData, config));
        history.push("/extras/cookiesAgreements");
    };

    return cookieData ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-cookies'>
            <FinalForm
                onSubmit={handleOnSubmit}
                initialValues={utils.generateFormInitialValues(cookieData, config)}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row className='mt-3'>
                                <FormFieldSwitchPill
                                    name='isActive'
                                    label={translations.labels.isActive}
                                />
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldTranslations
                                    form={form}
                                    propertyName='header'
                                    currentIso={utils.currentLanguage()}
                                    label={translations.labels.header}
                                    modalHeader={translations.labels.header}
                                />
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldTranslationsQuill
                                    form={form}
                                    utils={utils}
                                    propertyName='shortContent'
                                    header={translations.labels.shortContent}
                                />
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldTranslationsQuill
                                    form={form}
                                    utils={utils}
                                    propertyName='longContent'
                                    header={translations.labels.longContent}
                                />
                            </Row>
                            <SectionAsideButtons
                                buttons={{
                                    add: {
                                        color: 'success',
                                        label: translations.button,
                                        action: handleSubmit,
                                        iconClass: 'fa fa-save mr-2',
                                    }
                                }}
                            />
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

CookiesAgreementsEdit.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.cookies,
});

const mapDispatch = {
    getCookieById,
    updateCookie,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(CookiesAgreementsEdit));