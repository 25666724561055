/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { generateOtherServiceEditLink } from '../../../utils/services';

import TableGenerator from '../../generators/TableGenerator';

import { otherServicesListItemPropTypes, stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

import { UncontrolledTooltip } from 'reactstrap';

const propTypes = {
    services: PropTypes.arrayOf(otherServicesListItemPropTypes),
    history: PropTypes.object,
    removeAction: PropTypes.func,
    checkedServicesIds: PropTypes.arrayOf(stringOrNumberPropTypes),
    setCheckedServicesIds: PropTypes.func,
};

const OtherServicesTable = ({
    services,
    history,
    removeAction,
    checkedServicesIds,
    setCheckedServicesIds,
    updateAction,
    currentPage,
    pageSize,
    performPageSizeSelect
}) => {
    const intl = useIntl();
    const translations = intl.messages.services.list.table;

    const defaultActionHandler = id => () => {
        history.push(generateOtherServiceEditLink(id));
    };

    const deleteAction = id => () => {
        removeAction(id);
    };

    const WarningCell = item => {
        return item.warning ? <>
            <UncontrolledTooltip placement='top' target={`item-warning-${item.productId}-id`}>
                {item.warning}
            </UncontrolledTooltip>
            <i id={`item-warning-${item.productId}-id`} className="fa fa-exclamation text-warning"></i>
        </> : <span></span>;
    };

    const handleUpdateStatus = (id, status) => {
        const objectToSend = {
            productId: [ id ],
            status: status ? 1 : 0,
        };
        updateAction(objectToSend);
    };

    const handleUpdateSelected = (isActivated) => async () => {
        const objectToSend = {
            productId: checkedServicesIds,
            status: isActivated ? 1 : 0,
        };

        await updateAction(objectToSend);
        setCheckedServicesIds([]);
    };

    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            pageSize={pageSize}
            currentPage={currentPage}
            customButtons={[
                {
                    labelTrue: translations.buttons.activate,
                    labelFalse: translations.buttons.deactivate,
                    action: handleUpdateSelected,
                    type: 'selector',
                    isDisabled: checkedServicesIds.length < 1,
                },
            ]}
            editLink={generateOtherServiceEditLink}
            expectedActions={['edit', 'delete']}
            items={services}
            actions={{ deleteAction }}
            translations={translations}
            defaultAction={defaultActionHandler}
            customIdSelector={'productId'}
            checkedItemsIds={checkedServicesIds}
            setCheckedItemsIds={setCheckedServicesIds}
            columns={[
                {
                    type: 'custom',
                    customCell: WarningCell,
                    header: '',
                },
                {
                    type: 'checkbox'
                },
                {
                    header: translations.headers.lp,
                    value: 'lp'
                },
                {
                    header: translations.headers.name,
                    value: 'productTranslatedName',
                    additionalStyle: styles.nameCell,
                    filler: translations.fallbacks.name
                },
                {
                    header: translations.headers.catalogueIndex,
                    value: 'catalogueIndex',
                    filler: translations.fallbacks.catalogueIndex,
                },
                {
                    header: translations.headers.tradeIndex,
                    value: 'tradeIndex',
                    filler: translations.fallbacks.tradeIndex
                },
                {
                    header: translations.headers.active,
                    type: 'switchWithAction',
                    onSwitch: handleUpdateStatus,
                    value: 'status',
                },
                {
                    header: translations.headers.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

OtherServicesTable.propTypes = propTypes;

export default OtherServicesTable;