import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';

import SearchableDropdownWithCheckboxes from '../../../../SearchableDropdownWithCheckboxes';

import { stringOrNumberPropTypes } from '../../../../../propTypes';
import { withManufacturers } from '../../../../../hoc/withManufacturers';

import { translateManufacturers } from '../../../../../utils/manufacturers';

import { withFormUtils } from '../../../../../hoc/withFormUtils';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: stringOrNumberPropTypes,
        label: PropTypes.string,
        className: PropTypes.string,
    })),
    defaultLabel: PropTypes.string,
};

const ManufacturerSelectField = ({
    name,
    manufacturers,
    utils
}) => {
    const handleChange = (id, change, values) => {
        if(!values.includes(id)) {
            return change([...values, id]);
        } else {
            return change(values.filter(val => val !== id));
        }
    };

    const translatedManufacturers = translateManufacturers(manufacturers, utils);

    const options = translatedManufacturers.map(manufacturer => ({
        id: manufacturer.id,
        label: manufacturer.name,
    }));

    return (
        <Field name={name}>
            {
                props => {
                    const currentSelectionLabel = props.input.value === null || props.input.value === '' || (Array.isArray(props.input.value) && (props.input.value.length === 0))
                        ? 'Wybierz producentów'
                        : (
                            props.input.value.length <= 3
                                ? translatedManufacturers
                                    .filter(manufacturer => props.input.value.includes(manufacturer.id))
                                    .map(manufacturer => manufacturer.name)
                                    .join(', ')
                                : `Wybrano: ${props.input.value.length}`
                        );

                    return (
                        <span className='m-1'>
                            <SearchableDropdownWithCheckboxes
                                name={name}
                                options={options}
                                currentSelectionLabel={currentSelectionLabel}
                                setCheckedIds={(id) => handleChange(id, props.input.onChange, props.input.value)}
                                label='Producenci'
                                checkedIds={props.input.value}
                            />
                        </span>
                    );
                }
            }
        </Field>
    );
};

ManufacturerSelectField.propTypes = propTypes;

export default withFormUtils(withManufacturers(ManufacturerSelectField));