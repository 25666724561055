import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeNotFoundImage, getNotFoundImage } from '../../../../redux/methods/extrasSettings';

import FileSender from '../../../../components/FileSender';
import useFetch from '../../../../hooks/useFetch';

import { standardImageExtensions } from '../../../../config';

const propTypes = {
    forceRefetch: PropTypes.bool,
    removeNotFoundImage: PropTypes.func,
    updateNotFoundImage: PropTypes.func,
    getNotFoundImage: PropTypes.func,
    translations: PropTypes.object,
};

const FileSenderFeat = ({ removeNotFoundImage, setFile, getNotFoundImage, translations }) => {
    const [ image ] = useFetch(getNotFoundImage);

    return image ? (
        <FileSender
            fileKey='ImageFile'
            validateFormats={standardImageExtensions}
            handleRemove={removeNotFoundImage}
            withPreview
            buttons={translations.fileSender.buttons}
            label={translations.fileSender.label}
            photo={image.imageFile || null}
            maxSize={1}
            setFile={setFile}
            graphicTypeToEnableGuide='banner'
        />
    ) : null;
};

FileSenderFeat.propTypes = propTypes;

const mapDispatch = {
    removeNotFoundImage,
    getNotFoundImage,
};

export default connect(null, mapDispatch)(FileSenderFeat);