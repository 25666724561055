import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Button, Row, Col } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    buttons: PropTypes.shape({
        remove: PropTypes.shape({
            location: PropTypes.oneOf['left','right'],
            label: PropTypes.string,
            isDisabled: PropTypes.bool,
            action: PropTypes.func,
            color: PropTypes.string,
            iconClass: PropTypes.string,
        }),
        add: PropTypes.shape({
            location: PropTypes.oneOf['left','right'],
            label: PropTypes.string,
            isDisabled: PropTypes.bool,
            action: PropTypes.func,
            color: PropTypes.string,
            iconClass: PropTypes.string,
        }),
    }),
};

const SectionAsideButtons = ({ buttons }) => {

    let transposedButtons = buttons;

    // Jakub Stęplowski: for backward compatibility
    if (!Array.isArray(buttons))
    {
        if (buttons.remove && !buttons.remove.location) {
            buttons.remove.location = 'left';
        }
        if (buttons.add && !buttons.add.location) {
            buttons.add.location = 'right';
        }

        transposedButtons = buttons.remove && buttons.add ? [ buttons.remove, buttons.add ] : buttons.remove ? [ buttons.remove ] : [ buttons.add ];
    }

    return (
        <Row>
            <Col className="col-6">
                <aside className={clsx('mt-3', styles.footerLeftButtons)}>
                    {transposedButtons.filter(button => button.location === 'left').map((button, idx) => (
                        <Button
                            key={idx}
                            className="mr-1"
                            outline
                            type='button'
                            onClick={button.action}
                            color={button.color || 'danger'}
                            disabled={button.isDisabled}>
                                <i className={clsx(button.iconClass || 'fa fa-remove mr-2')}/>
                                <span>{button.label}</span>
                        </Button>
                    ))}
                </aside>
            </Col>
            <Col className="col-6">
                <aside className={clsx('mt-3', styles.footerRightButtons)}>
                    {transposedButtons.filter(button => button.location === 'right').map((button, idx) => (
                        <Button
                            key={idx}
                            className="mr-1"
                            color={button.color || 'primary'}
                            onClick={button.action}
                            disabled={button.isDisabled}>
                                <i className={clsx(button.iconClass || 'fa fa-plus mr-2')}/>
                                <span>{button.label}</span>
                        </Button>
                    ))}
                </aside>
            </Col>
        </Row>
    );
};

SectionAsideButtons.propTypes = propTypes;

export default SectionAsideButtons;