import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getDiscountAttributes, updateDiscountAttributes } from '../../../../redux/methods/discounts';
import useFetch from '../../../../hooks/useFetch';

import { Container } from 'reactstrap';

import AttributeAssigner from './AttributeAssigner';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';

import { stringOrNumberPropTypes } from '../../../../propTypes';

const propTypes = {
    discountId: stringOrNumberPropTypes,
    getDiscountAttributes: PropTypes.func,
    updateDiscountAttributes: PropTypes.func,
};

const DiscountEditAttributes = ({
    discountId,
    getDiscountAttributes,
    updateDiscountAttributes
}) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.attributes;

    const [ attributes ] = useFetch(getDiscountAttributes, discountId);
    const [ editedAttributes, setEditedAttributes ] = useState([]);

    const handleUpdateAttributes = () => {
        updateDiscountAttributes(discountId, editedAttributes);
    };

    return attributes ? (
        <Container fluid>
            <div>
                {
                    attributes.length > 0
                    && <React.Fragment>
                        <h5>{translations.editAttributes}</h5>
                        <div className='d-flex flex-row justify-content-start flex-wrap'>
                            {
                                attributes.map(attribute => (
                                    <AttributeAssigner
                                        discountId={discountId}
                                        editedAttributes={editedAttributes}
                                        setEditedAttributes={setEditedAttributes}
                                        key={`${attribute.attributeId}--attributeType`}
                                        attribute={attribute}
                                        selectAttributeTranslation={translations.placeholders.selectAttributes}
                                        emptyTranslation={translations.placeholders.empty}
                                        selectedTranslation={translations.placeholders.selected}
                                    />
                                ))
                            }
                        </div>
                    </React.Fragment>
                }
            </div>
            <SectionEditFormButtons
                onSubmit={handleUpdateAttributes}
            />
        </Container>
    ) : null;

};

DiscountEditAttributes.propTypes = propTypes;

const mapStateToProps = (state) => ({
    discountId: state.discounts.discountToEdit.data.discountId
});

const mapDispatch = {
    getDiscountAttributes,
    updateDiscountAttributes
};

export default connect(mapStateToProps, mapDispatch)(DiscountEditAttributes);