const manufacturers = {
    view: {
        title: 'Manufacturers list',
        actions: {
            addManufacturer: 'ADD MANUFACTURER'
        },
        deleteModal: {
            header: 'Manufacturer removal',
            body: 'Are you sure that you want to remove manufacturer?',
            cancel: 'CANCEL',
            confirm: 'REMOVE',
        },
        additionModal: {
            header: 'Add new manufacturer',
            body: 'Are you sure you want to create new manufacturer?',
            confirm: 'ADD',
            cancel: 'CANCEL'
        },
        errorModal: {
            cancel: 'CANCEL',
            header: 'Error occured while trying to remove manufacturer.',
        },
        manufacturersSearch: {
            name: 'Nazwa'
        },
        searchButton: 'SEARCH',
        resetButton: 'RESET'
    },
    table: {
        nameFiller: '[NO NAME]',
        emptyLogoAlt: 'placeholder logo',
        empty: 'No manufacturers found',
        headers: {
            logo: 'Logo',
            name: 'Name',
            actions: 'Actions',
            showInFilters: '[EN] Pokaż w filtrach',
        },
        deleteModal: {
            header: 'Manufacturer',
            body: 'Are you sure that you want to remove manufacturer',
            cancel: 'CANCEL',
            confirm: 'REMOVE'
        },
        tooltips: {
            remove: 'Remove manufacturer',
            edit: 'Edit manufacturer'
        }
    },
    edit: {
        fallbackName: '[NO NAME]',
        tabSelectorTitle: 'Sections',
        tabs: {
            basicData: {
                modalHeader: 'Name of custom page',
                title: 'Basic data',
                labels: {
                    name: 'Name',
                    showInFilters: {
                        active: '[EN] Widoczna',
                        inactive: '[EN] Niewidoczna',
                        label: '[EN] Widoczna w filtrach'
                    },
                },
                addLogoButton: 'Add logo'
            }
        }
    }
};

export default manufacturers;