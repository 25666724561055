export const generateEditLinkForRegion = id => `/regions/edit?regionID=${id}`;

export const filterCountriesList = (countries, searchPhrase) => countries.filter(country => country.name.toLowerCase().includes(searchPhrase.toLowerCase()));

export const generateRegionsDropdownOptions = (regions, emptySelectionLabel) => {
    const generatedOptions = [
        {
            id: null,
            label: emptySelectionLabel
        },
        ...regions.map(region => ({
            id: region.id,
            label: region.name,
        }))
    ];

    return emptySelectionLabel ? generatedOptions : generatedOptions.slice(1);
};