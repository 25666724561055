/**
 * To avoid cross-imports all of the shared utils CAN NOT be imported directly into a component
 */

export const naturalNumericInputValidator = input => {
    const regEx = /^[0-9]+$/;

    return input === '' ? true : regEx.test(input);
};

export const decimalNumberInputValidator = input => {
    const regEx = /^[0-9.,]+$/;
    const dotsCommasCount = input.split('').reduce((result, item) => item === '.' || item === ',' ? result + 1 : result, 0);

    return input === '' ? true : regEx.test(input) && dotsCommasCount < 2;
};

export const getTranslationForIso = (translations, iso) => {
    const areTranslationsPresent = translations && translations.length > 0 && !translations.some(translation => translation.iso === null);

    if (areTranslationsPresent) {
        const foundTranslationObject = translations.find(translation => translation && translation.iso && translation.iso.toLowerCase() === iso.toLowerCase());

        return foundTranslationObject ? foundTranslationObject.value : null;
    } else {
        return null;
    }
};

export const generateDisplayedPages = (totalPages, currentPage) => {
    const totalPagesArray = Array.from(Array(totalPages), (_, i) => i + 1);

    if (totalPages <= 5) {
        return totalPagesArray;
    } else {
        if (currentPage <= 3) {
            return totalPagesArray.slice(0, 5);
        } else if (currentPage >= totalPages - 2) {
            return totalPagesArray.slice(totalPages - 5);
        } else {
            return totalPagesArray.slice(currentPage - 3, currentPage + 2);
        }
    }
};

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};