export const defaultContactFormOptions = [
    'firstName',
    'surname',
    'companyName',
    'postalCode',
    'country',
    'nip',
    'phoneNumber',
    'email',
    // 'content',
    'customField'
];