import DashboardGeneral from '../../views/dashboard/DashboardGeneral';
import DashboardClients from '../../views/dashboard/DashboardClients';
import DashboardOrders from '../../views/dashboard/DashboardOrders';
import DashboardParcels from '../../views/dashboard/DashboardParcels';
import DashboardStats from '../../views/dashboard/DashboardStats';
import Notifications from '../../views/Notifications';
import Opinions from '../../views/Report/Opinions';
import Discounts from '../../views/Discounts';
import DiscountsEdit from '../../views/DiscountsEdit';
import AdBanners from '../../views/AdBanners';
import AdBannersEdit from '../../views/AdBannersEdit';
import TechnicalBreak from '../../views/extrasSettings/TechnicalBreak';
import OrdersEdit from '../../views/OrdersEdit';
import OrdersDetails from '../../views/OrdersDetails';

export const tradeRoutes = {
    // Panel handlu
    ROUTE_TRADE_DASHBOARD: {
        id: 'ROUTE_TRADE_DASHBOARD',
        path: '#',
        type: 'trade',
        exact: true,
        icon: 'fal fa-chart-line',
        children: [
            {
                id: 'ROUTE_TRADE_DASHBOARD_GENERAL',
                path: '/dashboard/general',
                exact: true,
                component: DashboardGeneral,
                icon: 'fal fa-user-chart'
            },
            {
                id: 'ROUTE_TRADE_DASHBOARD_ORDERS',
                path: '/dashboard/orders',
                exact: true,
                component: DashboardOrders,
                icon: 'fal fa-tasks'
            },
            {
                id: 'ROUTE_TRADE_DASHBOARD_PARCELS',
                path: '/dashboard/parcels',
                exact: true,
                component: DashboardParcels,
                icon: 'fal fa-truck-loading'
            },
            {
                id: 'ROUTE_TRADE_DASHBOARD_CLIENTS',
                path: '/dashboard/clients',
                exact: true,
                component: DashboardClients,
                icon: 'fal fa-users'
            },
            {
                id: 'ROUTE_TRADE_DASHBOARD_STATS',
                path: '/dashboard/stats',
                exact: true,
                component: DashboardStats,
                icon: 'fal fa-chart-area'
            },
        ]
    },

    ROUTE_ORDERS_EDIT: {
        id: 'ROUTE_ORDERS_EDIT',
        path: '/dashboard/orders/edit',
        component: OrdersEdit,
    },

    ROUTE_ORDERS_DETAILS: {
        id: 'ROUTE_ORDERS_DETAILS',
        path: '/dashboard/orders/details',
        component: OrdersDetails,
    },

    // Zdarzenia
    ROUTE_TRADE_EVENTS: {
        id: 'ROUTE_TRADE_EVENTS',
        path: '#',
        type: 'trade',
        exact: true,
        icon: 'cil-bell',
        children: [
            {
                id: 'ROUTE_TRADE_EVENTS_OPINIONS',
                path: '/report/opinions',
                exact: true,
                component: Opinions,
                icon: 'fal fa-comment'
            },
            {
                id: 'ROUTE_TRADE_EVENTS_NOTIFICATIONS',
                path: '/notifications',
                type: 'main',
                exact: true,
                icon: 'fal fa-exclamation-circle',
                component: Notifications,
            },
        ]
    },

    // Akcje
    ROUTE_TRADE_ACTIONS: {
        id: 'ROUTE_TRADE_ACTIONS',
        path: '#',
        type: 'trade',
        exact: true,
        icon: 'fal fa-megaphone',
        children: [
            {
                id: 'ROUTE_TRADE_ACTIONS_DISCOUNTS',
                path: '/discounts',
                exact: true,
                component: Discounts,
                icon: 'cil-money',
            },
            {
                id: 'ROUTE_TRADE_ACTIONS_ADBANNERS',
                path: '/adBanners',
                exact: true,
                component: AdBanners,
                icon: 'fal fa-ad',
            },
            {
                id: 'ROUTE_TRADE_ACTIONS_TECHNICAL_BREAK',
                path: '/extras/technicalBreak',
                exact: true,
                component: TechnicalBreak,
                icon: 'fal fa-pause-circle'
            },
        ]
    },

    ROUTE_TRADE_ACTIONS_DISCOUNTS_EDIT: {
        id: 'ROUTE_TRADE_ACTIONS_DISCOUNTS_EDIT',
        path: '/discounts/edit',
        component: DiscountsEdit
    },

    ROUTE_TRADE_ACTIONS_ADBANNERS_EDIT: {
        id: 'ROUTE_TRADE_ACTIONS_ADBANNERS_EDIT',
        path: '/adBanners/edit',
        component: AdBannersEdit,
    },
};