import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { getGeneralSettings, updateGeneralSettings } from '../../../../../redux/methods/products';

import useFetch from '../../../../../hooks/useFetch';

import { typeOfUnitPresentationOptions } from '../../../../../config';

import FormFieldWithDropdown from '../../../../../components/formFields/FormFieldWithDropdown';

import { Container, Card, CardBody, CardHeader, Row, Form, Button, Label, Table, Col } from 'reactstrap';
import { Form as FinalForm, Field } from 'react-final-form';

import styles from './index.module.scss';
import SearchableCurrenciesDropdown from '../../../../../features/currencies/SearchableCurrenciesDropdown';
import SearchableLanguagesDropdown from '../../../../../features/languages/SearchableLanguagesDropdown';

import ArticleColumnSelectField from '../../../../../components/generators/SearchForm/fields/ArticleColumnSelectField';

const propTypes = {
    getGeneralSettings: PropTypes.func,
    updateGeneralSettings: PropTypes.func
};

const ArticleGeneralSection = ({
    getGeneralSettings,
    updateGeneralSettings
}) => {
    const intl = useIntl();
    const translations = intl.messages.general.settings.tabs.articles;

    const [ generalSettings ] = useFetch(getGeneralSettings);

    const handleOnSubmit = (formData) => {
        const objectToSend = {
            ...formData,
            defaultView: !formData.listViewEnabled ? 'tile' : !formData.tileViewEnabled ? 'list' : formData.defaultView,
        };
        updateGeneralSettings(objectToSend);
    };

    const typeOfUnitPresentationDropdownOptions = typeOfUnitPresentationOptions.map(unit => ({
        id: unit,
        label: translations.inputs.typeOfUnitPresentationOptions[unit.toLowerCase()]
    }));

    return generalSettings ? (
        <Container fluid>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={generalSettings}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form }) => {
                    const { values } = form.getState();
                    const currentTypeOfUnitPresentationLabel = typeOfUnitPresentationDropdownOptions.find(unit => unit.id === values.typeOfUnitPresentation).label;
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>
                                <Row className='mb-3 d-flex' >
                                    <Col className='p-0' sm='12' md='6'>
                                        <Field name='defaultCurrencyId' >
                                            {
                                                props =>
                                                    <SearchableCurrenciesDropdown
                                                        currencyId={props.input.value}
                                                        emptySelectionLabel={translations.inputs.emptyLabel}
                                                        label={translations.inputs.defaultCurrencyId}
                                                        setCurrencyId={props.input.onChange}
                                                        onlyActive
                                                        withNullOption={false}
                                                    />
                                            }
                                        </Field>
                                    </Col>
                                    <Col className='p-0 pl-md-3 mt-2 mt-md-0' sm='12' md='6'>
                                        <Field name='defaultLanguageId' >
                                            {
                                                props =>
                                                    <SearchableLanguagesDropdown
                                                        languageId={props.input.value}
                                                        emptySelectionLabel={translations.inputs.emptyLabel}
                                                        label={translations.inputs.defaultLanguageId}
                                                        setLanguageId={props.input.onChange}
                                                        onlyActive
                                                        withNullOption={false}
                                                    />
                                            }
                                        </Field>
                                    </Col>
                                </Row>
                                <Row className='flex-md-nowrap mt-3'>
                                    <Label className='d-inline'>
                                        <Field
                                            name='showOnlyAvailable'
                                            component='input'
                                            type='checkbox'
                                        />
                                        &nbsp;{translations.inputs.showOnlyAvailable}
                                    </Label>
                                </Row>
                                <Row className='flex-md-nowrap mt-2'>
                                    <Label className='d-inline'>
                                        <Field
                                            name='canOrderUnavailable'
                                            component='input'
                                            type='checkbox'
                                        />
                                        &nbsp;{translations.inputs.canOrderUnavailable}
                                    </Label>
                                </Row>
                                <Row className='flex-md-nowrap mt-2'>
                                    <Label className='d-inline'>
                                        <Field
                                            name='canOrderOverStock'
                                            component='input'
                                            type='checkbox'
                                        />
                                        &nbsp;{translations.inputs.canOrderOverStock}
                                    </Label>
                                </Row>
                                <Row className='flex-md-nowrap mt-2'>
                                    <Label className='d-inline'>
                                        <Field
                                            name='showAvailabilityFilter'
                                            component='input'
                                            type='checkbox'
                                        />
                                        &nbsp;{translations.inputs.showAvailabilityFilter}
                                    </Label>
                                </Row>
                                <Row className='flex-md-nowrap mt-2'>
                                    <Label className='d-inline'>
                                        <Field
                                            name='displayNetPrice'
                                            component='input'
                                            type='checkbox'
                                        />
                                        &nbsp;{translations.inputs.displayNetPrice}
                                    </Label>
                                </Row>
                                <Row className='flex-md-nowrap mt-2'>
                                    <Label className='d-inline'>
                                        <Field
                                            name='displayPreviousPrice'
                                            component='input'
                                            type='checkbox'
                                        />
                                        &nbsp;{translations.inputs.displayPrice}
                                    </Label>
                                </Row>
                                <Row className='flex-md-nowrap mt-2'>
                                    <div className={clsx('pl-3 mb-2', values.displayPreviousPrice ? 'd-block' : 'd-none')}>
                                        <Label className='d-inline'>
                                            <Field
                                                name='displayPreviousPricePercent'
                                                component='input'
                                                type='checkbox'
                                            />
                                            &nbsp;{translations.inputs.displayPercentageDiscount}
                                        </Label>
                                    </div>
                                </Row>
                                <Row className='flex-md-nowrap mt-2'>
                                    <Label className='d-inline'>
                                        <Field
                                            name='displayPromotionDateTo'
                                            component='input'
                                            type='checkbox'
                                        />
                                        &nbsp;{translations.inputs.displayDate}
                                    </Label>
                                </Row>
                                <Row className='flex-md-nowrap mt-2'>
                                    <Label className='d-inline'>
                                        <Field
                                            name='infiniteScroll'
                                            component='input'
                                            type='checkbox'
                                        />
                                        &nbsp;{translations.inputs.infiniteScroll}
                                    </Label>
                                </Row>
                                <Row className='flex-md-nowrap mt-2'>
                                    <Label className='d-inline'>
                                        <Field
                                            name='hideIfOneCurrency'
                                            component='input'
                                            type='checkbox'
                                        />
                                        &nbsp;{translations.inputs.displayCurrency}
                                    </Label>
                                </Row>
                                <Row className='mt-3'>
                                    <FormFieldWithDropdown
                                        options={typeOfUnitPresentationDropdownOptions}
                                        label={translations.inputs.typeOfUnitPresentation}
                                        name='typeOfUnitPresentation'
                                        currentSelectionLabel={currentTypeOfUnitPresentationLabel}
                                    />
                                </Row>
                                <Row className='mt-3'>
                                    <ArticleColumnSelectField
                                        name='adminArticleColumns'
                                    />
                                </Row>

                                <Row className="mt-3">
                                    <Col lg={6}>
                                        <Card>
                                            <CardHeader>Desktop</CardHeader>
                                            <CardBody>
                                                <Row className={clsx('flex-md-nowrap mt-3 flex-column', styles.table)}>
                                                    <Table borderless>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>{translations.inputs.views}</th>
                                                                {values.desktopListViewEnabled && values.desktopTileViewEnabled && (
                                                                    <th>{translations.inputs.defaultView}</th>
                                                                )}
                                                                {values.desktopListViewEnabled && (
                                                                    <th>{translations.inputs.isListCondensed}</th>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope='row'>{translations.inputs.list}</th>
                                                                <td>
                                                                    <center>
                                                                        <Field
                                                                            name='desktopListViewEnabled'
                                                                            component='input'
                                                                            type='checkbox'
                                                                        />
                                                                    </center>
                                                                </td>
                                                                {values.desktopListViewEnabled && values.desktopTileViewEnabled && (
                                                                    <td>
                                                                        <center>
                                                                            <Field
                                                                                name='desktopDefaultView'
                                                                                component='input'
                                                                                type='radio'
                                                                                value='list'
                                                                                initialValue={false}
                                                                            />
                                                                        </center>
                                                                    </td>
                                                                )}
                                                                {values.desktopListViewEnabled && (
                                                                    <td>
                                                                        <center>
                                                                            <Field
                                                                                name='isListCondensed'
                                                                                component='input'
                                                                                type='checkbox'
                                                                            />
                                                                        </center>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                            <tr>
                                                                <th scope='row'>{translations.inputs.tile}</th>
                                                                <td>
                                                                    <center>
                                                                        <Field
                                                                            name='desktopTileViewEnabled'
                                                                            component='input'
                                                                            type='checkbox'
                                                                        />
                                                                    </center>
                                                                </td>
                                                                {values.desktopListViewEnabled && values.desktopTileViewEnabled && (
                                                                    <td>
                                                                        <center>
                                                                            <Field
                                                                                name='desktopDefaultView'
                                                                                component='input'
                                                                                type='radio'
                                                                                value='tile'
                                                                                initialValue={false}
                                                                            />
                                                                        </center>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                                {
                                                    values.desktopTileViewEnabled &&
                                                    <Row>
                                                        <FormFieldWithDropdown
                                                            label="Liczba kafelków w wierszu"
                                                            name="desktopTileAmount"
                                                            options={[{ id: 3 }, { id: 4 }]}
                                                            currentSelectionLabel={values.desktopTileAmount}
                                                        />
                                                    </Row>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={6}>
                                        <Card>
                                            <CardHeader>Tablet</CardHeader>
                                            <CardBody>
                                                <Row className={clsx('flex-md-nowrap mt-3 flex-column', styles.table)}>
                                                    <Table borderless>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>{translations.inputs.views}</th>
                                                                {values.tabletListViewEnabled && values.tabletTileViewEnabled && (
                                                                    <th>{translations.inputs.defaultView}</th>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope='row'>{translations.inputs.list}</th>
                                                                <td>
                                                                    <center>
                                                                        <Field
                                                                            name='tabletListViewEnabled'
                                                                            component='input'
                                                                            type='checkbox'
                                                                        />
                                                                    </center>
                                                                </td>
                                                                {values.tabletListViewEnabled && values.tabletTileViewEnabled && (
                                                                    <td>
                                                                        <center>
                                                                            <Field
                                                                                name='tabletDefaultView'
                                                                                component='input'
                                                                                type='radio'
                                                                                value='list'
                                                                                initialValue={false}
                                                                            />
                                                                        </center>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                            <tr>
                                                                <th scope='row'>{translations.inputs.tile}</th>
                                                                <td>
                                                                    <center>
                                                                        <Field
                                                                            name='tabletTileViewEnabled'
                                                                            component='input'
                                                                            type='checkbox'
                                                                        />
                                                                    </center>
                                                                </td>
                                                                {values.tabletListViewEnabled && values.tabletTileViewEnabled && (
                                                                    <td>
                                                                        <center>
                                                                            <Field
                                                                                name='tabletDefaultView'
                                                                                component='input'
                                                                                type='radio'
                                                                                value='tile'
                                                                                initialValue={false}
                                                                            />
                                                                        </center>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                                {
                                                    values.tabletTileViewEnabled &&
                                                    <Row>
                                                        <FormFieldWithDropdown
                                                            label="Liczba kafelków w wierszu"
                                                            name="tabletTileAmount"
                                                            options={[{ id: 2 }, { id: 3 }]}
                                                            currentSelectionLabel={values.tabletTileAmount}
                                                        />
                                                    </Row>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <aside className={styles.footer}>
                                    <Button color='success' onClick={form.submit}>
                                        <i className="fa fa-save mr-2"/>
                                        <span>{translations.button.save}</span>
                                    </Button>
                                </aside>
                            </Container>
                        </Form>
                    );
                }}
            />
        </Container>
    ) : null;
};

ArticleGeneralSection.propTypes = propTypes;

const mapDispatch = {
    getGeneralSettings,
    updateGeneralSettings
};

export default connect(null, mapDispatch)(ArticleGeneralSection);