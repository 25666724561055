import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import { getShippingFormsGeneral, updateShippingFormsGeneral } from '../../../redux/methods/shippingForms';

import useFetch from '../../../hooks/useFetch';

import ContainerWithCard from '../../../components/ContainerWithCard';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';

const propTypes = {
    getShippingFormsGeneral: PropTypes.func,
    updateShippingFormsGeneral: PropTypes.func,
};

const GeneralSettings = ({getShippingFormsGeneral, updateShippingFormsGeneral}) => {
    const intl = useIntl();
    const translations = intl.messages.shippingForms;

    const [ data ] = useFetch(getShippingFormsGeneral);

    return (
        <ContainerWithCard title={translations.title} iconClass='fa fa-cogs'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateShippingFormsGeneral}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>

                                <div className='my-2'><FormFieldSwitchPill label={translations.shippingFormsUseVariants.label} name='shippingFormsUseVariants' />
                                    <div className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.shippingFormsUseVariants.explanation}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='my-2'><FormFieldSwitchPill label={translations.shippingFormsUseWeights.label} name='shippingFormsUseWeights' />
                                    <div className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.shippingFormsUseWeights.explanation}</small>
                                        </div>
                                    </div>
                                </div>

                                <SectionEditFormButtons hideCancelButton={true} formReset={form.reset}/>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    );
};

const mapDispatch = {
    getShippingFormsGeneral,
    updateShippingFormsGeneral
};

GeneralSettings.propTypes = propTypes;

export default withRouter(connect(null, mapDispatch)(GeneralSettings));