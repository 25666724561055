import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getLanguages } from '../../../redux/methods/languages';
import { generateLanguagesDropdownOptions } from '../../../utils/languages';
import useFetch from '../../../hooks/useFetch';

import SearchableDropdown from '../../../components/SearchableDropdown';

import { stringOrNumberPropTypes } from '../../../propTypes';

const propTypes = {
    label: PropTypes.string,
    emptySelectionLabel: PropTypes.string,
    languageId: stringOrNumberPropTypes,
    setLanguageId: PropTypes.func,
    getLanguages: PropTypes.func,
};

const SearchableLanguagesDropdown = ({
    label,
    emptySelectionLabel,
    languageId,
    setLanguageId,
    getLanguages,
    onlyActive = false,
    withNullOption = true,
}) => {
    const [ languages ] = useFetch(getLanguages);

    const currentSelection = languages && languages.find(
        language => parseInt(language.id) === parseInt(languageId)
    );

    const activLanguages = languages && languages.filter(item => item.isActive);

    const generateLanguagesWithNullOpt = () => [
        {
            id: null,
            label: emptySelectionLabel,
        },
        ...generateLanguagesDropdownOptions(onlyActive ? activLanguages : languages)
    ];

    const typeOptions = languages && (withNullOption ? generateLanguagesWithNullOpt() : generateLanguagesDropdownOptions(onlyActive ? activLanguages : languages));

    return languages ? (
        <SearchableDropdown
            label={label}
            options={typeOptions}
            currentSelectionLabel={
                (currentSelection && currentSelection.name) ||
                emptySelectionLabel
            }
            performSelection={setLanguageId}
        />
    ): null;
};

SearchableLanguagesDropdown.propTypes = propTypes;

const mapDispatch = {
    getLanguages
};

export default connect(null,mapDispatch)(SearchableLanguagesDropdown);
