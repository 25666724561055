import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { ListGroup as List, ListGroupItem, ListGroupItemHeading, ListGroupItemText, UncontrolledTooltip } from 'reactstrap';

import { stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const listItemPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    label: PropTypes.string,
});

const propTypes = {
    header: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
    }),
    counter: PropTypes.shape({
        left: PropTypes.number,
        right: PropTypes.number,
    }),
    searchInput: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
    }),
    items: PropTypes.arrayOf(listItemPropTypes),
    tooltip: PropTypes.string,
    onItemClick: PropTypes.func,
    color: PropTypes.string,
};

const ListGroup = ({ header, counter, searchInput, items, tooltip, onItemClick, color, listItem, customHeader }) => {
    return (
        <List>
            <ListGroupItem active className={clsx(color === 'danger' && 'bg-danger border-danger')}>
                <ListGroupItemHeading className='d-flex justify-content-between'>
                    <span>{header.title}</span>
                    <span className='h6'>
                        {counter && `${counter.left}/${counter.right}`}
                    </span>
                </ListGroupItemHeading>
                <ListGroupItemText>
                    {header.text}
                </ListGroupItemText>
                {
                    customHeader
                }
            </ListGroupItem>
            <ListGroupItem
                value={searchInput.value}
                tag='input'
                onChange={searchInput.onChange}
                placeholder={searchInput.placeholder}
            />
            <div className={styles.scrollable}>
                {
                    items.map(item =>
                        <React.Fragment key={`item-${item.id.toString().replace(/\s+/g, '-').toLowerCase()}`}>
                            <ListGroupItem
                                className={clsx('w-100 text-left', styles.listItem)}
                                tag='button'
                                onClick={onItemClick(item)}
                                id={`item-${item.id.toString().replace(/\s+/g, '-').toLowerCase()}-id`}
                            >
                                { listItem ? listItem(item) : <span>{item.label}</span> }
                            </ListGroupItem>
                            {
                                (item.defaultTemplates?.length > 0 || tooltip) &&
                                    <UncontrolledTooltip placement='top' target={`item-${item.id.toString().replace(/\s+/g, '-').toLowerCase()}-id`}>
                                        {
                                            item.defaultTemplates?.length > 0
                                                ? `Czcionka domyślna dla szablonów: ${item.defaultTemplates.join(', ')}`
                                                : tooltip
                                        }
                                    </UncontrolledTooltip>
                            }
                        </React.Fragment>
                    )
                }
            </div>
        </List>
    );
};

ListGroup.propTypes = propTypes;

export default ListGroup;