import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { motion } from 'framer-motion';

import { Card, CardBody, CardHeader } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    isExpanded: PropTypes.bool,
    toggleIsExpanded: PropTypes.func,
    tabulatorHeader: PropTypes.shape({
        label: PropTypes.string,
        iconClassName: PropTypes.string
    }).isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
            header:  PropTypes.shape({
                label: PropTypes.string,
                iconClassName: PropTypes.string
            }).isRequired,
            component: PropTypes.node.isRequired,
            disabled: PropTypes.bool,
        })
    ).isRequired,
    setSelectedTab: PropTypes.func,
    className: PropTypes.string,
};

const SettingsTabulatorNavMobile = ({ tabulatorHeader, tabs, className, selectedTab, setSelectedTab, isExpanded, toggleIsExpanded }) => {
    const framerTabList = {
        open: {
            height: 'auto',
        },
        closed: {
            height: 0
        }
    };

    const framerTabButtonIcon = {
        visible: {
            opacity: .7,
            x: 0,
        },
        hidden: {
            opacity: 0,
            x: -50
        }
    };

    const handleTabSelection = tab => () => {
        if (selectedTab.id !== tab.id) {
            setSelectedTab(tab);
        }
    };

    return (
        <Card className={className}>
            <CardHeader>
                <span className={styles.tabHeader}>
                    <span className={styles.tabHeaderLabel}>
                        {/** display icon only when it's className is present */}
                        { tabulatorHeader.iconClassName && <i className={tabulatorHeader.iconClassName}/> }
                        <span className='ml-2'>{tabulatorHeader.label}</span>
                    </span>
                    <button className={styles.tabHeaderButton} onClick={toggleIsExpanded}>
                        <i className={clsx('fa fa-lg', isExpanded ? 'fa-minus' : 'fa-plus')}/>
                    </button>
                </span>
            </CardHeader>
            <CardBody className="p-0">
                <motion.ul
                    className={styles.tabList}
                    initial={true}
                    animate={isExpanded ? "open" : "closed"}
                    variants={framerTabList}
                >
                    {
                        tabs.map(tab => {
                            const isSelected = tab.id === selectedTab.id;

                            return (
                                <li className={styles.tabListItem} key={tab.id}>
                                    <button
                                        disabled={tab.disabled}
                                        className={styles.tabButton}
                                        onClick={handleTabSelection(tab)}
                                    >
                                        <motion.i
                                            className={clsx('fa fa-arrow-right mr-2 ml-2', styles.tabButtonIcon)}
                                            initial={false}
                                            animate={isSelected ? "visible" : "hidden"}
                                            variants={framerTabButtonIcon}
                                        />
                                        <span className={clsx(styles.tabButtonLabel, isSelected && styles.tabButtonLabelSelected)}>
                                            {tab.header.label}
                                        </span>
                                    </button>
                                </li>
                            );
                        })
                    }
                </motion.ul>
            </CardBody>
        </Card>
    );
};

SettingsTabulatorNavMobile.propTypes = propTypes;

export default SettingsTabulatorNavMobile;