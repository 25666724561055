import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { getUserAgreements } from '../../../redux/methods/agreements';
import useFetch from '../../../hooks/useFetch';
import useSearchConfig from '../../../hooks/useSearchConfig';

import ContainerWithCard from '../../../components/ContainerWithCard';
import AgreementRegisterSearchForm from '../../../components/AgreementRegisterSearchForm';
import { CSVLink } from "react-csv";
import { Row, Button } from 'reactstrap';

import Paginator from '../../../components/Paginator';
import AgreementsRegisterTable from '../../../components/tables/AgreementsRegisterTable';
import UserAgreementsModal from '../../../features/agreements/UserAgreementsModal';

import { userAgreementsSearchConfig } from '../../../config/searchConfigs';

import styles from './index.module.scss';

const propTypes = {
    getUserAgreements: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const AgreementsRegister = ({ getUserAgreements, forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.agreements.register;

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(userAgreementsSearchConfig);

    const [ agreements, pages ] = useFetch(getUserAgreements, searchConfig, [searchConfig], forceRefetch);

    const [ isOpen, setIsOpen ] = useState(false);
    const [ emailToShow, setEmailToShow ] = useState(null);

    const handleShow = id => {
        const agreement = agreements.find(a => a.id === id);
        setEmailToShow(agreement.email);
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setEmailToShow(null);
    };

    return (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search'>
            <AgreementRegisterSearchForm searchConfig={searchConfig} performAgreementSearch={changeSearchConfig} />
            {agreements && <AgreementsRegisterTable agreements={agreements} handleShow={handleShow} />}
            {emailToShow && <UserAgreementsModal isOpen={isOpen} setIsOpen={handleCloseModal} emailToShow={emailToShow} />}
            <Row className='d-flex justify-content-between w-100 align-items-end'>
                {
                    pages > 1 &&
                        <Paginator
                            totalPages={pages}
                            currentPage={searchConfig.curentPage + 1}
                            onPageChange={changePage}
                        />
                }
                {
                        <Button color='primary'>
                            <CSVLink
                                data={[]}
                                filename={`${translations.fileName}.csv`}
                                target='_blank'
                                className={styles.downloadLink}
                            >
                                {translations.downloadButton}
                            </CSVLink>
                        </Button>
                }
            </Row>
        </ContainerWithCard>
    );
};

AgreementsRegister.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.agreementsRegistry,
});

const mapDispatch = {
    getUserAgreements,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(AgreementsRegister));