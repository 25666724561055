export const generateEditLinkForCountry = id => `/countries/edit?countryID=${id}`;

export const filterCountries = (countries, name, iso) => {
	const checkData = data => data && data.length > 0;

	return countries.filter(country => {
		const nameCheck = checkData(name) ? country.name.toLowerCase().includes(name) : true;
		const isoCheck = checkData(iso) ? country.iso.includes(iso) : true;
		return nameCheck && isoCheck ? country : null;
	});
};

export const translateCountries = (countries, iso) => {
	return countries.map(country => {
		const foundTranslations = country.translations && country.translations.find(translation => translation.iso.toLowerCase() === iso.toLowerCase());
		return {
			...country,
			name: foundTranslations && foundTranslations.value,
		};
	});
};

export const generateFormInputs = (country, labelText) => {
	const { translations } = country;
	return translations.map(translation => ({
		id: `${translation.iso}-id`,
		name: translation.iso,
		label: `${labelText} [${translation.iso.toUpperCase()}]`,
		chosenIso: translation.iso,
		initValue: translation.value,
	}));
};

export const generateTranslationsToSend = (translations, availableIsoList) => {
	const generatedTranslationList = availableIsoList.map(iso => ({
		iso: iso,
		value: translations[iso] || '',
	}));

	return generatedTranslationList;
};

export const generateFilteredAndTranslatedCountriesList = (countries, name, iso, chosenLanguage, defaultLanguage ) => {
	const language = chosenLanguage || defaultLanguage;

	const filteredCountries = filterCountries(countries, name, iso);
	const translatedCountries = translateCountries(filteredCountries, language);

	return [...translatedCountries];
};