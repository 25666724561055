import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';

import { Label } from 'reactstrap';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
};

const CheckboxField = ({ name, label }) => {
    return (
        <div className='mx-3 mt-3 d-flex justify-content-center align-items-center'>
            <Field
                name={name}
                component='input'
                type='checkbox'
                id={name}
            />
            <Label for={name} className='pl-2 mb-0'>{label}</Label>
        </div>
    );
};

CheckboxField.propTypes = propTypes;

export default CheckboxField;