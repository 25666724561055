import React from 'react';
import PropTypes from 'prop-types';

import { generateEditLinkForRegion } from '../../../utils/regions';

import TableGenerator from '../../generators/TableGenerator';

import { regionPropTypes, stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    regions: PropTypes.arrayOf(regionPropTypes),
        translations: PropTypes.shape({
            deleteModal: PropTypes.shape({
            header: PropTypes.string,
            body: PropTypes.string,
            confirm: PropTypes.string,
            cancel: PropTypes.string,
        }).isRequired,
        tooltips: PropTypes.shape({
            delete: PropTypes.string,
            edit: PropTypes.string,
        }).isRequired,
    }),
    headers: PropTypes.shape({
        name: PropTypes.string,
        actions: PropTypes.string,
    }),
    removeAction: PropTypes.func,
    nameFiller: PropTypes.string,
    withEditAction: PropTypes.bool,
    withCheckbox: PropTypes.bool,
    setCheckedCheckboxIds: PropTypes.func,
    checkedCheckboxIds: PropTypes.arrayOf(stringOrNumberPropTypes),
    isGreenOnHover: PropTypes.bool,
    history: PropTypes.object
};

const RegionsTable = ({
    regions, translations, headers, removeAction, nameFiller,
    withEditAction = true, setCheckedCheckboxIds, withCheckbox = false, checkedCheckboxIds, isGreenOnHover, history
}) => {
    const deleteAction = id => () => {
        removeAction(id);
    };

    const defaultAction = id => () => {
        if (typeof(history) !== 'undefined') {
            history.push(generateEditLinkForRegion(id));
        }
    };

    return (
        <TableGenerator
            items={regions}
            editLink={generateEditLinkForRegion}
            expectedActions={[withEditAction && 'edit', 'delete']}
            defaultAction={defaultAction}
            actions={{deleteAction}}
            translations={translations}
            setCheckedItemsIds={setCheckedCheckboxIds}
            checkedItemsIds={checkedCheckboxIds}
            additionalRowStyle={isGreenOnHover && styles.greenBackground}
            columns={[
                withCheckbox && {type: 'checkbox'},
                {
                    header: headers.name,
                    value: 'name',
                    filler: nameFiller,
                },
                {
                    header: headers.countries,
                    value: 'numberOfCountries'
                },
                {
                    header: headers.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

RegionsTable.propTypes = propTypes;

export default RegionsTable;