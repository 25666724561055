import { languages } from './storeLocale';

const customPages = {
    view: {
        title: 'Custom pages list',
        actions: {
            addCustomPage: "ADD CUSTOM PAGE"
        },
        buttons: {
            activate: 'Aktywuj',
            deactivate: 'Deaktywuj'
        },
        headerLabels: {
            name: 'Name',
            slug: 'Slug',
            status: 'Status',
            featured: 'Featured',
            actions: 'Actions',
        },
        fillers: {
            name: '[NO NAME]',
            slug: '[NO SLUG]'
        },
        tooltips: {
            remove: 'Remove page',
            edit: 'Edit page'
        },
        statusLabels: {
            published: 'Published',
            unpublished: 'Unpublished'
        },
        featuredLabels: {
            featured: 'Featured',
            notFeatured: 'Not featured'
        },
        emptyTable: 'No custom pages to display',
        deleteModal: {
            header: 'Custom page removal',
            body: 'Are you sure that you want to remove custom page?',
            cancel: 'CANCEL',
            confirm: 'REMOVE',
        },
    },
    edit: {
        fallbackName: '[NO NAME]',
        tabSelectorTitle: 'Sections',
        tabs: {
            basicData: {
                modalHeader: 'Name of custom page',
                title: 'Basic data',
                labels: {
                    name: 'Name',
                    slug: 'Slug',
                    featured: 'Featured',
                    status: 'Status',
                    content: 'Content',
                    availableInLanguages: 'Custom page available in languages',
                    slugAlert: '[EN] Należy wypełnić adres strony. Może on zawierać tylko litery, liczby i znak podkreślenia.',
                    backgroundColor: '[EN] Kolor tła',
                    backgroundColorButton: '[EN] Wybierz'
                },
                statusLabels: {
                    published: 'Published',
                    unpublished: 'Unpublished'
                },
                featuredLabels: {
                    featured: 'Featured',
                    notFeatured: 'Not featured'
                },
                languages: languages,
            },
            metatags: {
                title: 'Meta tags'
            },
        }
    }
};

export default customPages;