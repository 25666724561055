import { setCategoryTreeView, setNewlyCreatedCategoryId, setCategoryDisplayMode, setExpandedCategories } from '../../../features/categories/slice';
import { setIsLoading, setIsLoaded } from '../../../features/loaders/slice';
import { setForceRefetchByTarget } from '../../../features/refetchers/slice';
import { setError } from '../../../features/errors/slice';
import { setIsSuccess } from '../../../features/toast/slice';

import { refetchCategoryTree } from './shareable';

import { handleCAPIErrorsSubtle, cAPI } from '../../../utils/cAPI';

import { getData, addEditData, removeData } from '../generic';

export const getErpCategories = () => dispatch => getData(dispatch, cAPI.APGetErpCategories);
export const getCeneoCategories = () => dispatch => getData(dispatch, cAPI.APGetCeneoCategories);

export const getCategoryTree = () => async dispatch => {
    dispatch(setIsLoading());
    dispatch(setIsSuccess({ isSuccess: false }));
    await refetchCategoryTree(dispatch);
    dispatch(setForceRefetchByTarget({ target: 'categories', forceRefetch: false }));
    dispatch(setIsLoaded());
};

export const expandCategories = expandedCategories => (dispatch) => {
    dispatch(setExpandedCategories({ expandedCategories }));
};

export const handleSetCategoryTreeView = displayMode => dispatch =>
    dispatch(setCategoryTreeView({ categoryTreeView: displayMode }))
;

export const updateCategoryBasicData = (formData) => async dispatch => {
    dispatch(setIsLoading());

    const { error, httpError, unhandledError, statusCode } = await cAPI.APUpdateCategoryBasicData(formData);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (errorToHandle) {
        dispatch(setIsLoaded());
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    } else {
        await refetchCategoryTree(dispatch);
        dispatch(setIsSuccess({ isSuccess: true }));
        dispatch(setForceRefetchByTarget({ target: 'categories', forceRefetch: true }));
        dispatch(setIsLoaded());
    }
};

export const createNewCategory = parentCategoryId => async dispatch => {
    dispatch(setIsLoading());

    const updateObject = {
        categoryId: parentCategoryId || null
    };

    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APCreateCategory(updateObject);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { id } = payLoad;
        await refetchCategoryTree(dispatch);
        dispatch(setIsLoaded());
        dispatch(setNewlyCreatedCategoryId({ newlyCreatedCategoryId: id }));
    } else if (errorToHandle) {
        dispatch(setIsLoaded());
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
    dispatch(setIsLoaded());
};

export const clearNewlyCreatedCategoryId = () => dispatch => dispatch(setNewlyCreatedCategoryId({ newlyCreatedCategoryId: null }));

export const removeCategory = categoryId => async dispatch => {
    dispatch(setIsLoading());

    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APDeleteCategoryById(categoryId);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { errors } = payLoad;

        if (errors && errors.form) {
            const { message } = errors.form;
            dispatch(setIsLoaded());
            dispatch(setError({ error: message }));
        } else {
            await refetchCategoryTree(dispatch);
            dispatch(setIsLoaded());
        }
    } else if (errorToHandle) {
        dispatch(setIsLoaded());
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
    dispatch(setIsLoaded());
};

export const getCategoryAssignedFeatures = searchConfig => async dispatch => {
    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APGetAttributesSearchResult(searchConfig);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        dispatch(setForceRefetchByTarget({ target: 'categories', forceRefetch: false }));
        const { rows } = payLoad;

        return { payload: rows };
    } else if (errorToHandle) {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};

export const getCategoryBasicData = id => dispatch => getData(dispatch, cAPI.APGetCategoryDetails, id, 'categoriesBasic');

export const deleteCategoryFeatures = (categoryId, attributeIdList) => async dispatch => {
    dispatch(setIsLoading());

    const updateObject = {
        categoryId: categoryId,
        attributeId: attributeIdList
    };

    const { payLoad, error, httpError, unhandledError, statusCode} = await cAPI.APDeleteCategoryAttributes(updateObject);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { errors } = payLoad;
        dispatch(setForceRefetchByTarget({ target: 'categories', forceRefetch: true }));

        if (errors && errors.form) {
            const { message } = errors.form;
            dispatch(setIsLoaded());
            dispatch(setError({ error: message }));
        } else {
            dispatch(setIsLoaded());
        }
    } else if (errorToHandle) {
        dispatch(setIsLoaded());
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};

export const moveCategory = moveObject => async dispatch => {
    dispatch(setIsLoading());

    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APUpdateCategoryLocation(moveObject);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { errors } = payLoad;

        if (errors && errors.form) {
            const { message } = errors.form;
            dispatch(setError({ error: message }));
        }

        await refetchCategoryTree(dispatch);
    } else if (errorToHandle) {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }

    dispatch(setIsLoaded());
};

export const getCategoryImage = id => dispatch => getData(dispatch, cAPI.APGetCategoryImage, id, 'categoriesImage');

export const uploadCategoryImage = (id, type, formData) => dispatch => addEditData(dispatch, cAPI.APUploadCategoryImage, { id, type, formData }, 'categories');
export const removeCategoryImage = (id, type) => dispatch => removeData(dispatch, cAPI.APRemoveCategoryImage, { id, type }, 'categoriesImage');

export const updateCategoryStatus = formData => dispatch => addEditData(dispatch, cAPI.APUpdateCategoryStatus, formData, 'categories');
export const changeCategoryDisplayMode = value => dispatch => dispatch(setCategoryDisplayMode({ categoryDisplayMode: value }));

export const getCategoriesSettings = () => dispatch => getData(dispatch, cAPI.APGetCategoriesSettings);
export const updateCategoriesSettings = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdateCategoriesSettings, formData);

export const getCategoryFormFields = (id) => dispatch => getData(dispatch, cAPI.APGetCategoryFormFields, id, 'categoryFormFields');
export const getCategoryFormField = (formData) => dispatch => getData(dispatch, cAPI.APGetCategoryFormField, formData, 'categoryFormFields');

export const addEditCategoryFormField = (formData) => dispatch => addEditData(dispatch, cAPI.APAddEditCategoryFormField, formData, 'categoryFormFields');
export const deleteCategoryFormField = (formData) => dispatch => removeData(dispatch, cAPI.APDeleteCategoryFormField, formData, 'categoryFormFields');