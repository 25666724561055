import { getTranslationForIso,  } from '../shared';

export const generateEditLinkForCustomPage = id => `/customPages/edit?customPageID=${id}`;

export const generateCustomPageListWithTranslatedName = (pageList, language) => {
    return pageList.map(page => ({
        ...page,
        translatedName: getTranslationForIso(page.name, language),
    }));
};

export const pickBadgeColor = (status) => {
    switch(status){
        case 'published': return 'success';
        case 'unpublished': return 'danger';
        default: return null;
    }
};

export const pickFeaturedBadgeColor = (isFeatured) => {
    switch(isFeatured) {
        case 'notFeatured': return 'danger';
        case 'featured': return 'success';
        default: return null;
    }
};

export const generateStatusesConfig = (statusEnum, translations) => {
    return Object.keys(statusEnum).map(key => ({
        id: parseInt(key),
        label: translations[statusEnum[key].toLowerCase()],
        color: pickBadgeColor(statusEnum[key])
    }));
};

export const generateFeaturedStatuses = (featuredEnum, translations) => {
    return Object.keys(featuredEnum).map(key => ({
        id: parseInt(key),
        label: translations[featuredEnum[key]],
        color: pickFeaturedBadgeColor(featuredEnum[key])
    }));
};