const taxRates = {
    title: 'Stawki VAT',
    table: {
        emptyTable: 'Brak stawek VAT',
        headerLabels: {
            name: 'Nazwa',
            code: 'Kod',
            amount: 'Stawka',
        }
    }
};

export default taxRates;