import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import TableGenerator from '../../../../components/generators/TableGenerator';
import OrderDetails from '../../../../components/OrderDetails';

const propTypes = {
    selectedOrdersList: PropTypes.array,
    serviceProperties: PropTypes.object,
    changePropertyValueHandler: PropTypes.func
};

const PackagePropertyTable = ({ selectedOrdersList, serviceProperties, changePropertyValueHandler}) => {
    const intl = useIntl();
    const translations = intl.messages.orders.sendPackages.selectedOrdersTable;

    const handleChange = (id, value, item, columnName) => {
        changePropertyValueHandler(id, value, item, columnName);
    };

    return (
        <TableGenerator
            items={selectedOrdersList}
            translations={translations}
            customIdSelector='id'
            columns={[
                {
                    header: translations.headers.date,
                    type: 'date',
                    value: 'date'
                },
                {
                    header: translations.headers.number,
                    value: 'number'
                },
                {
                    header: translations.headers.totalGrossPrice,
                    value: 'totalGrossPrice'
                },
                serviceProperties.showService && {
                    header: translations.headers.serviceId,
                    className: 'columnServiceId',
                    value: 'paramServiceId',
                    type: 'dropDown',
                    dictionary: serviceProperties.services,
                    onChange: handleChange
                },
                serviceProperties.showType && {
                    header: translations.headers.types,
                    className: 'columnType',
                    value: 'paramType',
                    type: 'dropDown',
                    dictionary: serviceProperties.types,
                    onChange: handleChange
                },
                {
                    header: translations.headers.dimension1,
                    className: 'columnDimension',
                    value: 'paramDimension1',
                    type: 'input',
                    inputType: 'number',
                    inputTextAlignment: 'text-right',
                    onChange: handleChange
                },
                {
                    header: translations.headers.dimension2,
                    className: 'columnDimension',
                    value: 'paramDimension2',
                    type: 'input',
                    inputType: 'number',
                    inputTextAlignment: 'text-right',
                    onChange: handleChange
                },
                {
                    header: translations.headers.dimension3,
                    className: 'columnDimension',
                    value: 'paramDimension3',
                    type: 'input',
                    inputType: 'number',
                    inputTextAlignment: 'text-right',
                    onChange: handleChange
                },
                {
                    header: translations.headers.weight,
                    className: 'columnWeight',
                    value: 'paramWeight',
                    type: 'input',
                    inputType: 'number',
                    inputTextAlignment: 'text-right',
                    onChange: handleChange
                },
                serviceProperties.showValue && {
                    header: translations.headers.value,
                    className: 'columnValue',
                    value: 'paramValue',
                    type: 'input',
                    inputType: 'number',
                    inputTextAlignment: 'text-right',
                    onChange: handleChange
                },
                serviceProperties.showNonStandardSwitch && {
                    header: translations.headers.nonStandard,
                    value: 'paramNonStandard',
                    type: 'switchWithAction',
                    onSwitch: handleChange,
                },
                serviceProperties.showDescription && {
                    header: translations.headers.description,
                    className: 'columnDescription',
                    value: 'paramDescription',
                    type: 'input',
                    inputType: 'text',
                    inputTextAlignment: 'text-left',
                    onChange: handleChange
                }
            ]}
            detailsComponent={OrderDetails}
        />
    );
};

PackagePropertyTable.propTypes = propTypes;

export default PackagePropertyTable;