/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { useCycle } from 'framer-motion';

import { Container, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Sticky from 'react-sticky-el';

import SettingsTabulatorNavDesktop from './navs/SettingsTabulatorNavDesktop';
import SettingsTabulatorNavMobile from './navs/SettingsTabulatorNavMobile';

import { stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

import { connect } from 'react-redux';

import { setDefaultTab, setCurrentTab } from '../../../redux/methods/settingTabs';

const propTypes = {
    title: PropTypes.shape({
        label: PropTypes.string,
        iconClassName: PropTypes.string,
        isFilled: PropTypes.bool
    }).isRequired,
    tabulatorHeader: PropTypes.shape({
        label: PropTypes.string,
        iconClassName: PropTypes.string
    }).isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: stringOrNumberPropTypes,
            header:  PropTypes.shape({
                label: PropTypes.string,
                iconClassName: PropTypes.string
            }).isRequired,
            component: PropTypes.node.isRequired,
            disabled: PropTypes.bool,
            onClick: PropTypes.func,
        })
    ).isRequired,
    customActiveTab: stringOrNumberPropTypes,
    setDefaultTab: PropTypes.func,
    setCurrentTab: PropTypes.func,
};

const SettingsTabulator = ({ title, tabulatorHeader, refreshTabComponent, tabs, customActiveTabId, settingTabs, setDefaultTab, setCurrentTab }) => {
    const history = useHistory();

    // For maintain conditional tabs (not always visible)
    const filteredTabs = tabs.filter(el => el != null);

    const initialActiveTab = customActiveTabId ? filteredTabs.find(tab => tab.id === customActiveTabId) : filteredTabs[0];

    if (!(history.location.pathname in settingTabs)) {
        setDefaultTab(history.location.pathname, initialActiveTab);
    }

    const selectedTab = settingTabs[history.location.pathname];

    const [ isExpanded, toggleIsExpanded ] = useCycle(false, true);

    const handleTabSelection = tab => {
        if (selectedTab.id !== tab.id) {
            setCurrentTab(history.location.pathname, tab);
        }
    };

    const getTabComponent = tab => {
        return typeof(refreshTabComponent) === 'function'
            ? refreshTabComponent(tab.id) ?? tab.component()
            : tab.component();
    };

    return selectedTab ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <header className={styles.header}>
                        <h1>
                            {/** display icon only when it's className is present */}
                            { title.iconClassName && <i className={title.iconClassName}/> }
                            <span className={clsx(styles.headerLabel, title.isFilled && 'text-danger')}>{title.label}</span>
                        </h1>
                    </header>
                </Row>
                <Row className={clsx(styles.row, "flex-sm-nowrap")}>
                    <Sticky
                        className={clsx(styles.nav, "d-none d-sm-block")}
                        stickyClassName={styles.sticky}
                        topOffset={-55}
                    >
                        <SettingsTabulatorNavDesktop
                            tabs={filteredTabs}
                            selectedTab={selectedTab}
                            setSelectedTab={handleTabSelection}
                            tabulatorHeader={tabulatorHeader}
                        />
                    </Sticky>
                    <Col className='d-sm-none col-12'>
                        <SettingsTabulatorNavMobile
                            isExpanded={isExpanded}
                            toggleIsExpanded={toggleIsExpanded}
                            tabs={filteredTabs}
                            selectedTab={selectedTab}
                            setSelectedTab={handleTabSelection}
                            tabulatorHeader={tabulatorHeader}
                        />
                    </Col>
                    <Col className={styles.content}>
                        <Card>
                            <CardHeader>
                                { selectedTab.header.iconClassName && <i className={selectedTab.header.iconClassName}/> }
                                <span>{selectedTab.header.label}</span>
                            </CardHeader>
                            <CardBody>
                                {getTabComponent(selectedTab)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ) : null;
};

SettingsTabulator.propTypes = propTypes;

const mapStateToProps = state => ({
    settingTabs: state.settingTabs.tabs
});

const mapDispatch = {
    setDefaultTab,
    setCurrentTab
};

export default connect(mapStateToProps, mapDispatch)(SettingsTabulator);