/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { handleCheckboxAction } from '../../../utils/components';
import TableGenerator from '../../generators/TableGenerator';
import { Input } from 'reactstrap';

import { productListItemPropTypes } from '../../../propTypes';

import PhotoPlaceholder from '../../../assets/photo_placeholder.svg';

import styles from './index.module.scss';
import getConfig from '../../../getConfig';


const propTypes = {
    translatedProductsList: PropTypes.arrayOf(productListItemPropTypes),
    checkedProductsId: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    setCheckedProductsId: PropTypes.func,
    photoStyle: PropTypes.string,
    actionOnClick: PropTypes.func,
    isDisabled: PropTypes.bool,
};

const ProductsWithoutActionsTable = ({
    translatedProductsList,
    checkedProductsId,
    setCheckedProductsId,
    photoCellStyle,
    photoStyle,
    isDisabled,
    selectedTemplateVariant,
    setSelectedTemplateVariant,
    isVariantTemplateSelection,
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.table;

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const ImageCell = item => {
        return (
            <img
                className={photoStyle ? photoStyle : styles.photo}
                src={item.productImage ? `${apiUrl}${item.productImage}` : PhotoPlaceholder}
                alt={item.productImageAlternativeText ? item.productImageAlternativeText : translations.emptyLogoAlt}
            />
        );
    };

    const handleChangeTemplateVariant = productId => () => {
        setSelectedTemplateVariant(productId);
    };

    const VariantTempleteSelectionCell = item => {
        return (
            <Input
                className={styles.radioInput}
                type='radio' name='templateVariant'
                disabled={!checkedProductsId.includes(item.productId)}
                checked={item.productId === selectedTemplateVariant}
                onClick={handleChangeTemplateVariant(item.productId)}
            />
        );
    };

    const defaultActionOnClick = (id) => () => {
        if (!isDisabled) {
            handleCheckboxAction(id, checkedProductsId, setCheckedProductsId);
        }
        else {
            return null;
        }
    };

    return (
        <TableGenerator
            isCheckboxDisabled={isDisabled}
            translations={translations}
            defaultAction={defaultActionOnClick}
            items={translatedProductsList}
            checkedItemsIds={checkedProductsId}
            setCheckedItemsIds={setCheckedProductsId}
            customIdSelector='productId'
            highlightOnSelectStyle={styles.highlightOnSelect}
            columns={[
                {
                    type: 'checkbox',
                },
                {
                    header: translations.headers.photo,
                    customCell: ImageCell,
                    type: 'custom',
                    additionalStyle: photoCellStyle ? photoCellStyle : styles.photoCell
                },
                {
                    header: translations.headers.name,
                    value: 'productTranslatedName'
                },
                isVariantTemplateSelection &&
                {
                    header: translations.headers.templateVariant,
                    type: 'custom',
                    customCell: VariantTempleteSelectionCell
                },
                {
                    header: translations.headers.catalogueIndex,
                    value: 'catalogueIndex'
                },
                {
                    header: translations.headers.tradeIndex,
                    value: 'tradeIndex'
                },
            ]}
        />
    );
};

ProductsWithoutActionsTable.propTypes = propTypes;

export default ProductsWithoutActionsTable;