import React from 'react';
import PropTypes from 'prop-types';

import TableGenerator from '../../generators/TableGenerator';

import styles from './index.module.scss';

const propTypes = {
    translations: PropTypes.object,
    notificationsList: PropTypes.array,
    deleteAction: PropTypes.func,
};

const NotificationsTable = ({
    translations,
    notificationsList,
    deleteAction,
    setColumnSortedByDescendingOrder,
    performSort,
    columnSortedByDescendingOrder,
    currentPage,
    pageSize,
    moduleConfig,
    performPageSizeSelect,
    checkedCheckboxIds,
    setCheckedCheckboxIds
}) => {

    const onClickSortHandler = (columnName) => () => {
        setColumnSortedByDescendingOrder(prevState => prevState === columnName ? null : columnName);

        performSort(columnSortedByDescendingOrder === columnName ? 'Desc' : 'Asc');
    };

    const handleDelete = () => {
        deleteAction(null)();
    };

    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            currentPage={currentPage}
            pageSize={pageSize}
            onClickSortHandler={onClickSortHandler}
            items={notificationsList}
            checkedItemsIds={checkedCheckboxIds}
            setCheckedItemsIds={setCheckedCheckboxIds}
            customButtons={[
                {
                    label: translations.actions.ignore,
                    action: handleDelete,
                    isDisabled: checkedCheckboxIds.length < 1,
                }
            ]}
            actions={{deleteAction}}
            expectedActions={['delete']}
            translations={translations}
            columnSortedByDescendingOrder={columnSortedByDescendingOrder}
            additionalRowStyle={styles.rowDisabled}
            columns={[
                {type: 'checkbox'},
                {
                    header: translations.headers.index,
                    type: 'index',
                },
                {
                    header: translations.headers.date,
                    value: 'date',
                    type: 'date',
                    isSortable: true,
                    sortValue: 'date',
                    withTime: true
                },
                {
                    header: translations.headers.moduleName,
                    value: 'moduleId',
                    type: 'badge',
                    config: moduleConfig
                },
                {
                    header: translations.headers.errorName,
                    value: 'type',
                },
                {
                    header: translations.headers.description,
                    value: 'description',
                },
                {
                    header: translations.headers.details,
                    value: 'details',
                },
                {
                    header: translations.headers.actions,
                    type: 'actions',
                },
            ]}
        />
    );
};

NotificationsTable.propTypes = propTypes;

export default NotificationsTable;
