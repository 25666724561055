import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { forcedLogout } from '../../../redux/methods/auth';

const propTypes = {
    error: PropTypes.string,
    errorAdditionalData: PropTypes.object,
    header: PropTypes.string,
    buttonLabel: PropTypes.string,
    closeErrorModal: PropTypes.func,
    forcedLogout: PropTypes.func,
};

const ErrorModal = ({ header, error, errorAdditionalData, errorForceLogout, buttonLabel, closeErrorModal, forcedLogout }) => {

    const handleCloseErrorModal = async () => {
        if (errorForceLogout) {
            await forcedLogout();
        }

        closeErrorModal();
    };

    return (
        <Modal
            isOpen={error ? true : false}
            toggle={handleCloseErrorModal}
            className="modal-danger">
            <ModalHeader toggle={handleCloseErrorModal}>{header}</ModalHeader>
            <ModalBody>
                {error}
                {errorAdditionalData &&
                    <div>
                        <br/>
                        {(errorAdditionalData.errors.map((e,idx) =>
                            <div key={idx}>
                                <b>{e.message}</b>
                                {e.response.length > 0 &&
                                <ul>
                                    {(
                                        e.response.filter(r => r !== null).map((r,idx2) =>
                                            <li key={idx+"_"+idx2}>{r}</li>)
                                    )}
                                </ul>
                                }
                            </div>)
                        )}
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={handleCloseErrorModal}>{buttonLabel}</Button>
            </ModalFooter>
        </Modal>
    );
};

ErrorModal.propTypes = propTypes;

const mapDispatch = {
    forcedLogout
};

export default connect(null, mapDispatch)(ErrorModal);