import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getCategoryTree, getCeneoCategories } from '../../../redux/methods/categories';
import {
    updateCeneoSettings,
    getCeneoSettings,
    deleteCeneoMapping,
    addCeneoMapping,
    generateCeneoXML,
    updateCeneoCategories,
} from '../../../redux/methods/integrations';
import useFetch from '../../../hooks/useFetch';

import CategoryMappingSection from '../../../features/integrations/ceneo/editTabs/CategoryMappingSection';
import SettingsSection from '../../../features/integrations/ceneo/editTabs/SettingsSection';
import { withFormUtils } from '../../../hoc/withFormUtils';

import SettingsTabulator from '../../../components/layouts/SettingsTabulator';

import { formUtilsHocPropTypes } from '../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getCategoryTree: PropTypes.func,
    getCeneoMappings: PropTypes.func,
    deleteCeneoMapping: PropTypes.func,
    addCeneoMapping: PropTypes.func,
    updateCeneoSettings: PropTypes.func,
    getCeneoSettings: PropTypes.func,
    forceRefetch: PropTypes.bool,
    forceRefetchMappings: PropTypes.bool,
    isLoading: PropTypes.bool
};

const Ceneo = ({
    utils,
    getCategoryTree,
    categoryTree,
    updateCeneoSettings,
    getCeneoSettings,
    forceRefetch,
    deleteCeneoMapping,
    addCeneoMapping,
    generateCeneoXML,
    updateCeneoCategories,
    getCeneoCategories,
}) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.ceneo;

    useEffect(() => {
        if(!categoryTree || categoryTree.length < 1) {
            getCategoryTree();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [ ceneoCategories ] = useFetch(getCeneoCategories);
    const [ settings ] = useFetch(getCeneoSettings, null, [], forceRefetch);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'Ceneo-settings':
                return <SettingsSection
                    utils={utils}
                    translations={translations}
                    settings={settings}
                    onSubmit={updateCeneoSettings}
                    generateCeneoXML={generateCeneoXML}
                    updateCeneoCategories={updateCeneoCategories}
                    ceneoCategories={ceneoCategories ? ceneoCategories.categoryTree : null}
                />;
            case 'Ceneo-categoryMapping':
                return <CategoryMappingSection
                    translations={translations}
                    currentLanguage={utils.currentLanguage()}
                    categoryTree={categoryTree}
                    addAction={addCeneoMapping}
                    deleteAction={deleteCeneoMapping}
                    ceneoCategories={ceneoCategories}
                />;
            default:
                return null;
        }
    };

    return (
        <SettingsTabulator
                title={{
                    label: translations.title
                }}
                tabulatorHeader={{
                    label: translations.title,
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: `Ceneo-settings`,
                        header: {
                            label: translations.tabs.settings,
                            iconClassName: 'fa fa-wrench'
                        }
                    },
                    {
                        id: `Ceneo-categoryMapping`,
                        header: {
                            label: translations.tabs.categoryMapping,
                            iconClassName: 'fas fa-sitemap'
                        }
                    },
                ]}
        />
    );
};

Ceneo.propTypes = propTypes;

const mapStateToProps = state => ({
    categoryTree: state.categories.categoryTree,
    forceRefetch: state.refetchers.ceneoSettings
});

const mapDispatch = {
    getCategoryTree,
    getCeneoSettings,
    updateCeneoSettings,
    deleteCeneoMapping,
    addCeneoMapping,
    generateCeneoXML,
    updateCeneoCategories,
    getCeneoCategories
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(Ceneo));