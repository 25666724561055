import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import useFetch from '../../../hooks/useFetch';

import { updateProductMeasure, getMeasures, getProductMeasure, deleteProductMeasure } from '../../../redux/methods/products';
import { measuresSearchConfig } from '../../../config/searchConfigs';

import { generateMeasuresDropdownOptions } from '../../../utils/products';

import { generateTypes } from '../../../utils/units';

import TableGenerator from '../../generators/TableGenerator';

import { stringOrNumberPropTypes } from '../../../propTypes';

import { measureTypeEnum } from '../../../enums/units';

import styles from './index.module.scss';

import { withFormUtils } from '../../../hoc/withFormUtils';

import ProductMeasureEditionModal from '../../../components/modals/ProductMeasureEditionModal';

const propTypes = {
    productMeasures: PropTypes.arrayOf(PropTypes.shape({
        productMeasureId: stringOrNumberPropTypes,
        productId: stringOrNumberPropTypes,
        measureId: stringOrNumberPropTypes,
        measureTypeId: PropTypes.number,
        rate: PropTypes.number,
        measureName: stringOrNumberPropTypes
    })),
    updateProductMeasure: PropTypes.func,
    id: PropTypes.number,
    deleteProductMeasure: PropTypes.func
};

const ProductMeasuresTable = ({
    productMeasures,
    updateProductMeasure,
    getMeasures,
    getProductMeasure,
    id,
    deleteProductMeasure
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.measures;

    const { headers, editModal, buttons } = translations;

    const typeConfig = generateTypes(measureTypeEnum, translations.measureTypes);

    const [ measures ] = useFetch(getMeasures, measuresSearchConfig);

    const measuresOptions = measures && generateMeasuresDropdownOptions(measures, translations.nullOpt);

    const [ isEditModalOpen, setIsEditModalOpen ] = useState(false);
    const [ editModalItem, setEditModalItem ] = useState(null);

    const editAction = id => () => {
        const item = productMeasures.find(m => m.productMeasureId === id);

        if (item) {
            setEditModalItem(item);
            setIsEditModalOpen(true);
        }
    };

    const handleEditAction = form => {
        const objectToSend = {
            ...form,
            productMeasureId: form.productMeasureId
        };

        updateProductMeasure(objectToSend);
        setIsEditModalOpen(false);
    };

    const handleAddAction = async () => {
        const item = await getProductMeasure({id: 0, productId: id });

        if (item && item.payload && !item.payload.errors && !item.error) {
            setEditModalItem(item.payload);
            setIsEditModalOpen(true);
        }
    };

    const deleteAction = id => () => {
        return deleteProductMeasure(id);
    };

    return measuresOptions ? (
        <>
            <TableGenerator
                items={productMeasures}
                expectedActions={['edit', 'delete']}
                actions={{editAction, deleteAction}}
                defaultAction={editAction}
                translations={translations}
                customIdSelector='productMeasureId'
                columns={[
                    {
                        header: headers.name.label,
                        value: 'measureName',
                        filler: headers.name.filler,
                    },
                    {
                        header: headers.measureType.label,
                        value: 'measureTypeId',
                        type: 'badge',
                        config: typeConfig
                    },
                    {
                        header: headers.rate.label,
                        value: 'rate',
                        filler: headers.rate.filler
                    },
                    {
                        type: 'actions'
                    }
                ]}
            />
            <aside className={styles.footer}>
                <Button color='primary' onClick={handleAddAction}>
                    <i className='fa fa-plus mr-2'/>
                    <span>{buttons.create}</span>
                </Button>
            </aside>
            <ProductMeasureEditionModal
                isOpen={isEditModalOpen}
                setIsOpen={setIsEditModalOpen}
                translations={editModal}
                productMeasure={editModalItem}
                onSubmit={handleEditAction}
                typeConfig={typeConfig}
                measuresOptions={measuresOptions}
            />
        </>
    ) : null;
};

ProductMeasuresTable.propTypes = propTypes;

const mapDispatch = {
    updateProductMeasure,
    getMeasures,
    getProductMeasure,
    deleteProductMeasure
};

export default withFormUtils(connect(null, mapDispatch)(ProductMeasuresTable));