import React from 'react';
import PropTypes from 'prop-types';

import { generateRegionsDropdownOptions } from '../../utils/regions';

import SearchableDropdown from '../SearchableDropdown';

import { regionPropTypes } from '../../propTypes';

const propTypes = {
    label: PropTypes.string,
    regionId: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    regions: PropTypes.arrayOf(regionPropTypes),
    setRegionId: PropTypes.func,
};

const SearchableRegionsDropdown = ({ label, regionId, regions, setRegionId }) => {
    const currentSelection = regions.find(category => parseInt(category.id) === parseInt(regionId));
    return (
        <SearchableDropdown
            label={label}
            options={generateRegionsDropdownOptions(regions)}
            currentSelectionLabel={(currentSelection && currentSelection.name)}
            performSelection={setRegionId}
        />
    );
};

SearchableRegionsDropdown.propTypes = propTypes;

export default SearchableRegionsDropdown;