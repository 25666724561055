import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../components/modals/Modal';

import SendPackage from '../SendPackage';

import styles from './index.module.scss';

const propTypes = {
    selectedOrders: PropTypes.arrayOf(Object),
    isOpen: PropTypes.bool,
    closeSendPackageModal: PropTypes.func
};

const SendPackageModal = ({
    selectedOrders,
    isOpen,
    closeSendPackageModal,
    modalLabel
}) => {
    return (
        <Modal
            className={styles.modal}
            isOpen={isOpen}
            setIsOpen={closeSendPackageModal}
            header={modalLabel}
            body={<SendPackage closeSendPackageModal={closeSendPackageModal} selectedOrders={selectedOrders} /> }
            buttons={{}}
        />
    );
};

SendPackageModal.propTypes = propTypes;

export default SendPackageModal;