import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getVisualSettingsPlaceholderImageProduct,
    removeVisualSettingsPlaceholderImageProduct,
    uploadVisualSettingsPlaceholderImageProduct
} from '../../../../redux/methods/visualSettings';

import FileSender from '../../../../components/FileSender';
import useFetch from '../../../../hooks/useFetch';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getVisualSettingsLogoFile: PropTypes.func,
    removeVisualSettingsLogoFile: PropTypes.func,
    uploadVisualSettingsLogoFile: PropTypes.func,
    translations: PropTypes.object,
};

const FileSenderProduct = ({
    getVisualSettingsPlaceholderImageProduct,
    removeVisualSettingsPlaceholderImageProduct,
    uploadVisualSettingsPlaceholderImageProduct,
    forceRefetch,
    translations
}) => {
    const [ image ] = useFetch(getVisualSettingsPlaceholderImageProduct, null, [], forceRefetch);

    return image ? (
        <FileSender
            id='product'
            validateFormats={['png', 'jpg', 'jpeg']}
            handleRemove={removeVisualSettingsPlaceholderImageProduct}
            handleUpload={uploadVisualSettingsPlaceholderImageProduct}
            withPreview
            buttons={translations.fileSender}
            label={translations.labels.product}
            photo={image.image}
            maxSize={1}
            preferHeight='500px'
            preferWidth='400px'
            graphicTypeToEnableGuide='tile'
        />
    ) : null;
};

FileSenderProduct.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.visualSettingsPlaceholderImageProduct,
});

const mapDispatch = {
    getVisualSettingsPlaceholderImageProduct,
    removeVisualSettingsPlaceholderImageProduct,
    uploadVisualSettingsPlaceholderImageProduct,
};

export default connect(mapStateToProps, mapDispatch)(FileSenderProduct);