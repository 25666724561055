import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { getCatalogSettings, editCatalogSettings } from '../../redux/methods/catalog';
import useFetch from '../../hooks/useFetch';

import FormFieldWithCheckbox from '../../components/formFields/FormFieldWithCheckbox';

import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import styles from './index.module.scss';

const propTypes = {
    getCatalogSettings: PropTypes.func,
    editCatalogSettings: PropTypes.func,
};

const Catalog = ({ getCatalogSettings, editCatalogSettings }) => {
    const intl = useIntl();
    const translations = intl.messages.catalog;

    const [ initialValues ] = useFetch(getCatalogSettings);

    return initialValues ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody>
                                <FinalForm
                                    mutators={{
                                        setToFalse: (args, state, { changeValue }) => {
                                            changeValue(state, 'isPriceDisable', () => false);
                                        }
                                    }}
                                    initialValues={initialValues}
                                    onSubmit={editCatalogSettings}
                                    render={({ handleSubmit, form, values }) => {
                                        return (
                                            <Form onSubmit={handleSubmit}>
                                                <Container fluid>
                                                    <Row className='flex-md-nowrap mt-3' id='isCatalog'>
                                                        <FormFieldWithCheckbox
                                                            name='isCatalog'
                                                            label='Włącz tryb katalogu'
                                                            mutator={form.mutators.setToFalse}
                                                        />
                                                    </Row>
                                                    <Row className='flex-md-nowrap mt-3'>
                                                        <FormFieldWithCheckbox
                                                            name='isPriceDisable'
                                                            label='Wyłącz widoczność cen'
                                                            isDisabled={!values.isCatalog}
                                                        />
                                                    </Row>
                                                    <aside className={styles.footer}>
                                                        <Button color='success' onClick={form.submit}>
                                                            <i className="fa fa-save mr-2"/>
                                                            <span>{translations.action}</span>
                                                        </Button>
                                                    </aside>
                                                </Container>
                                            </Form>
                                        );
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ) : null;
};

Catalog.propTypes = propTypes;

const mapDispatch = {
    getCatalogSettings,
    editCatalogSettings,
};

export default connect(null, mapDispatch)(Catalog);