const orderDetailsConfig = [
    [
        {key: 'number'},
        {key: 'date', isDate: true},
        {key: 'totalGrossPrice'},
        {key: 'orderStatus'},
        {key: 'attributes'},
        {key: 'paymentMethod'},
        {key: 'invoiceNumber'},
        {key: 'invoice', isLink: true, isExternal: true},
        {key: 'returnNumber'},
    ],
    [
        {key: 'contrahentName'},
        {key: 'contrahentNumber'},
        {key: 'phone'},
        {key: 'email'},
    ],
    [
        {key: 'shipmentAddress'},
        {key: 'receivingPerson'},
        {key: 'shippmentMethod'},
        {key: 'packageNumber', isLink: true},
    ],
    [
        {key: 'totalWeight'},
        {key: 'label'},
        {key: 'orderStatusChangeDate', isDate: true},
        {key: 'ean'},
        {key: 'attachement', isLink: true},
    ],
    [
        {key: 'comment'},
    ],
];

export default orderDetailsConfig;