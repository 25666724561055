import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    user: null,
    afterVerification: false,
    isUnauthorized: false,
    isLoading: {
        login: false,
        register: false,
    }
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, action) {
            const { user } = action.payload;

            state.user = user;
        },
        setAfterVerification(state, action) {
            const { afterVerification } = action.payload;

            state.afterVerification = afterVerification;
        },
        setIsUnauthorized(state, action) {
            const { isUnauthorized } = action.payload;

            state.isUnauthorized = isUnauthorized;
        },
        setLoadingByTarget(state, action) {
            const { target, isLoading } = action.payload;

            state.isLoading[target] = isLoading;
        },
    }
});

export const { setUser, setAfterVerification, setIsUnauthorized, setLoadingByTarget } = auth.actions;

export default auth.reducer;