/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Tooltip } from 'reactstrap';

const propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    className: PropTypes.string,
    onSwitch: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    tooltipKey: PropTypes.string,
    columnName: PropTypes.string,
    fromFalseToTrueEnabled: PropTypes.bool,
    fromTrueToFalseEnabled: PropTypes.bool,
    onClickAction: PropTypes.func
};

const SwitchWithActionCell = ({ value, className, onSwitch, id, item, disabled, tooltip, tooltipKey, columnName, onClickAction, fromFalseToTrueEnabled = true, fromTrueToFalseEnabled = true}) => {
    const numericConfig = {
        0: false,
        1: true,
    };

    const [ isTooltipOpen, setIsTooltipOpen ] = useState(false);

    const [ isRefreshNeeded, setIsRefreshNeeded ] = useState(false);
    const [ currentValue, setCurrentValue ] = useState(typeof value === 'boolean' ? value : numericConfig[value]);

    const handleClick = (e) => {
        if (e.target.tagName === 'TD' && typeof(onClickAction) === 'function')
            onClickAction();
    }

    const handleSwitch = () => {
        if ((currentValue === false && fromFalseToTrueEnabled === false) ||
            (currentValue === true && fromTrueToFalseEnabled === false)) {
            return;
        }

        onSwitch(id, !currentValue, item, columnName);

        setCurrentValue(!currentValue);
        setIsRefreshNeeded(!isRefreshNeeded);
    };

    return (
        <td className={className} onClick={handleClick}>
            {tooltip &&
                <Tooltip placement="top" isOpen={isTooltipOpen} target={`switchPillTooltip-${tooltipKey}`} toggle={() => setIsTooltipOpen(!isTooltipOpen)}>
                    {tooltip}
                </Tooltip>
            }
            <label className="switch switch-pill switch-success m-0" id={`switchPillTooltip-${tooltipKey}`}>
                <input
                    disabled={disabled}
                    type="checkbox"
                    value={currentValue}
                    onChange={handleSwitch}
                    className="switch-input"
                    checked={currentValue}
                />
                <span
                    className={clsx("switch-slider", !currentValue && "bg-danger border-danger")}
                ></span>
            </label>
        </td>
    );
};

SwitchWithActionCell.propTypes = propTypes;

export default SwitchWithActionCell;