import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import SearchForm from '../../../components/generators/SearchForm';

import { mediaLibrarySearchConfig } from '../../../config/searchConfigs';

const propTypes = {
    performSearch: PropTypes.func,
    searchConfig: PropTypes.object,
    disabled: PropTypes.bool
};

const MediaSearchForm = ({ performSearch, searchConfig, disabled }) => {
    const translations = useIntl().messages.mediaLibrary.searchForm;

    const filtersType = [
        {
            id: 1,
            label: translations.filters.all,
        },
        {
            id: 2,
            label: translations.filters.images,
        },
        {
            id: 3,
            label: translations.filters.video,
        },
        {
            id: 4,
            label: translations.filters.other,
        }
    ];

    return (
        <SearchForm
            searchConfig={searchConfig}
            defaultSearchConfig={{...mediaLibrarySearchConfig}}
            translations={{
                buttons: translations.buttons
            }}
            inputs={[
                {
                    label: translations.fileName,
                    name: 'fileName',
                    type: 'text'
                },
                {
                    label: translations.mediaType,
                    name: 'mediaType',
                    type: 'dropdown',
                    options: filtersType
                }
            ]}
            performSearch={performSearch}
            disabled={disabled}
        />
    );
};

MediaSearchForm.propTypes = propTypes;

export default MediaSearchForm;