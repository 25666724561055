import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getPhotoNameOptionsForDropdown, getPhotoName, updatePhotoName } from '../../../redux/methods/seo';
import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';

import useFetch from '../../../hooks/useFetch';

import { Form, Row } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import ContainerWithCard from '../../../components/ContainerWithCard';
import { getTranslationsFromArray } from '../../../utils/getTranslationsFromArray';

const propTypes = {
    getPhotoNameOptionsForDropdown: PropTypes.func,
    getPhotoName: PropTypes.func,
    updatePhotoName: PropTypes.func,
    forceRefetch: PropTypes.bool,
    storeCurrentLanguage: PropTypes.string
};

const PhotoNames = ({ getPhotoNameOptionsForDropdown, getPhotoName, updatePhotoName, forceRefetch, storeCurrentLanguage }) => {
    const translations = useIntl().messages.seo.photoNames;

    const [ dropdownOptions ] = useFetch(getPhotoNameOptionsForDropdown);
    const [ photoNameData ] = useFetch(getPhotoName, null, [], forceRefetch);

    const handleOnSubmit = formData => {
        updatePhotoName(formData);
    };

    const translatedDropdownOptions = dropdownOptions && dropdownOptions.map(opt => ({
        id: opt.id, label: getTranslationsFromArray(opt.translations, storeCurrentLanguage)
    }));

    return translatedDropdownOptions && photoNameData ? (
        <ContainerWithCard title={translations.title} iconClass='nav-icon cil-image'>
            <FinalForm
                initialValues={photoNameData}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values }) => {
                    const currAlternativeText = translatedDropdownOptions.find(opt => opt.id === values.alternativeText);
                    const currTitle = translatedDropdownOptions.find(opt => opt.id === values.title);

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <FormFieldWithDropdown
                                    label={translations.alternativeText}
                                    options={translatedDropdownOptions}
                                    currentSelectionLabel={currAlternativeText ? currAlternativeText.label : ''}
                                    name='alternativeText'
                                />
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldWithDropdown
                                    label={translations.name}
                                    options={translatedDropdownOptions}
                                    currentSelectionLabel={currTitle ? currTitle.label : ''}
                                    name='title'
                                />
                            </Row>
                            <SectionEditFormButtons formReset={form.reset}/>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

PhotoNames.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.seoPhotoName,
    storeCurrentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
    getPhotoNameOptionsForDropdown,
    getPhotoName,
    updatePhotoName
};

export default connect(mapStateToProps, mapDispatch)(PhotoNames);