const mediaSelectionModal = {
    info: {
        label: 'Aby edytować pliki przejdź do',
        link: 'biblioteki mediów',
    },
    breadcumpsRoot: '...',
    buttons: {
        confirm: 'WYBIERZ',
        cancel: 'ANULUJ',
    },
    header: 'Wybieranie plików',
};

export default mediaSelectionModal;