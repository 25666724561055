import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCurrencyBasicData } from '../../redux/methods/currencies';

import useFetch from '../../hooks/useFetch';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';
import CurrencyEditRegions from '../../features/currencies/editTabs/CurrencyEditRegions';
import CurrencyEditBasic from '../../features/currencies/editTabs/CurrencyEditBasic';

const propTypes = {
    location: PropTypes.object,
    getCurrencyBasicData: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const CurrenciesEdit = ({ location, getCurrencyBasicData, forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.currencies.edit;
    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);
    const chosenCurrencyId = query.get('currencyID');
    const [ data ] = useFetch(getCurrencyBasicData, chosenCurrencyId, [], forceRefetch);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'currencyEditTab-basic':
                return <CurrencyEditBasic data={data} id={chosenCurrencyId}/>;
            case 'currencyEditTab-regions':
                return <CurrencyEditRegions currencyId={chosenCurrencyId}/>;
            default:
                return null;
        }
    };

    return chosenCurrencyId ? (
        data ? (
            <SettingsTabulator
                title={{
                    label: data.name || translations.fallbackName,
                    iconClassName: 'fa fa-edit text-primary ml-3',
                    isFilled: data.name ? false : true,
                }}
                tabulatorHeader={{
                    label: translations.tabSelectorTitle,
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: 'currencyEditTab-basic',
                        header:  {
                            label: translations.tabs.basic.title,
                            iconClassName: 'fa fa-tasks'
                        }
                    },
                    {
                        id: 'currencyEditTab-regions',
                        header:  {
                            label: translations.tabs.regions.title,
                            iconClassName: 'fa fa-flag'
                        }
                    },
                ]}
            />
        ) : null
    ) : (
        <Redirect to='/currencies'/>
    );
};

CurrenciesEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.currencies,
});

const mapDispatch = {
    getCurrencyBasicData,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(CurrenciesEdit));