import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { selectStoreCurrentLanguage } from '../../../../../redux/selectors/storeLocale';
import useFetch from '../../../../../hooks/useFetch';
import {
    getAnimatedBannerImages,
    deleteAnimatedBannerImage,
    updateAnimatedBannerImage,
    updateAnimatedBannerImageSettings,
    moveAnimatedBannerImage,
    uploadAnimatedBannerImage,
    deleteAnimatedBannerImageFile,
} from '../../../../../redux/methods/homePage';

import { animatedBannerImageObjectConfig } from '../../../../../config';

import { Container } from 'reactstrap';
import AnimatedBannerImagesTable from '../../../../../components/tables/AnimatedBannerImagesTable';
import EditAnimatedBannerImageModal from '../EditAnimatedBannerImageModal';
import SectionAsideButtons from '../../../../../components/SectionAsideButtons';

import AnimatedBannerImageSettings from "../../../../../features/homePage/sections/AnimatedBannerSection/AnimatedBannerImageSettings";

import { stringOrNumberPropTypes } from '../../../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    currentLanguage: PropTypes.string,
    deleteAnimatedBannerImage: PropTypes.func,
    uploadAnimatedBannerImage: PropTypes.func,
    deleteAnimatedBannerImageFile: PropTypes.func,
    animatedBannerId: stringOrNumberPropTypes,
    moveAnimatedBannerImage: PropTypes.func,
    bannerLocation: PropTypes.string,
    getAnimatedBannerImages: PropTypes.func,
    updateAnimatedBannerImage: PropTypes.func,
    updateAnimatedBannerImageSettings: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const AnimatedBannerImagesEditionSection = ({
    section,
    currentLanguage,
    deleteAnimatedBannerImage,
    uploadAnimatedBannerImage,
    deleteAnimatedBannerImageFile,
    animatedBannerId,
    moveAnimatedBannerImage,
    getAnimatedBannerImages,
    updateAnimatedBannerImage,
    updateAnimatedBannerImageSettings,
    forceRefetch
}) => {
    const intl = useIntl();
    const translations = intl.messages.animatedBanner.settings;

    const [ slideToEdit, setSlideToEdit ] = useState(null);
    const [ settingsToEdit, setSettingsToEdit ] = useState(null);

    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ slides ] = useFetch(getAnimatedBannerImages, animatedBannerId, [], forceRefetch);

    const handleEditClick = slide => {
        setSlideToEdit(slide);
        setIsModalOpen(true);
    };

    useEffect(() => {
        //way to update slide photo after upload in edition modal
        if(slides && slideToEdit) {
            setSlideToEdit(slides.find(slide => slide.animatedBannerImageId === slideToEdit.animatedBannerImageId));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slides]);

    const handleRemove = (bannerId, id) => {
        deleteAnimatedBannerImage(bannerId, id);
    };

    const handleAddSlide = () => {
        const objectToSend = {
            ...animatedBannerImageObjectConfig,
            animatedBannerId: animatedBannerId
        };

        updateAnimatedBannerImage(animatedBannerId, objectToSend, true)
            .then((newlyCreatedId) => {
                if (!forceRefetch && slides) {
                    setSlideToEdit({
                        ...objectToSend,
                        animatedBannerImageId: newlyCreatedId
                    });
                    setIsModalOpen(true);
                }
            });
    };

    const handleEditImage = (slide, device, settings, width, height) => {
        setSettingsToEdit({slide, device, settings, width, height});
    };

    const handleEditImageCancel = () => {
        setSettingsToEdit(null);
    };

    const handleEditImageSave = (slide, device, aspectRatio, settings) => {
        switch(device)
        {
            case 'Desktop': {
                slide.desktop.slidesettings = settings;
                updateAnimatedBannerImageSettings(slide.animatedBannerId, slide.animatedBannerImageId, 'desktop', { aspectRatio: aspectRatio, settings: settings } );
                break;
            }
            case 'Tablet': {
                slide.tablet.slidesettings = settings;
                updateAnimatedBannerImageSettings(slide.animatedBannerId, slide.animatedBannerImageId, 'tablet', { aspectRatio: aspectRatio, settings: settings } );
                break;
            }
            case 'Mobile':
            default: {
                slide.mobile.slidesettings = settings;
                updateAnimatedBannerImageSettings(slide.animatedBannerId, slide.animatedBannerImageId, 'mobile', { aspectRatio: aspectRatio, settings: settings } );
                break;
            }
        }
        setSettingsToEdit(null);
    };

    const handleSubmit = form => {
        updateAnimatedBannerImage(animatedBannerId, form);
    };

    return (
        <React.Fragment>
            {slides ?
                (
                    settingsToEdit ? (
                        <AnimatedBannerImageSettings
                            settingsToEdit={settingsToEdit}
                            onCancel={handleEditImageCancel}
                            onSave={handleEditImageSave}
                        />
                    ) : (
                        <Container fluid className={clsx('mb-6', styles.fullScreenOnMobile)}>
                            <div className='animated fadeIn'>
                                <AnimatedBannerImagesTable
                                    section={section}
                                    slides={slides}
                                    translations={translations}
                                    removeAction={handleRemove}
                                    editAction={handleEditClick}
                                    editImageAction={handleEditImage}
                                    changeSlideOrder={moveAnimatedBannerImage}
                                    updateBannerImage={updateAnimatedBannerImage}
                                    currentLanguage={currentLanguage}
                                />
                                <SectionAsideButtons
                                    buttons={{
                                        add: {
                                            color: 'primary',
                                            label: translations.actions.add,
                                            action: handleAddSlide,
                                            iconClass: 'fa fa-plus mr-2',
                                            isDisabled: animatedBannerId < 1
                                        }
                                    }}
                                />
                                <div className='w-100 d-flex justify-content-end my-2'>
                                    {animatedBannerId < 1  && <span>{translations.inputs.saveAlert}</span>}
                                </div>
                            </div>
                        </Container>
                    )
                )
            : null}

            {slideToEdit &&
                <EditAnimatedBannerImageModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    onSubmit={handleSubmit}
                    slide={slideToEdit}
                    translations={translations}
                    handleUpload={uploadAnimatedBannerImage}
                    handleRemove={deleteAnimatedBannerImageFile}
                    animatedBannerId={animatedBannerId}
                />
            }
        </React.Fragment>
    );
};

AnimatedBannerImagesEditionSection.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.homePageAnimatedBannerImages,
    currentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
    getAnimatedBannerImages,
    deleteAnimatedBannerImage,
    updateAnimatedBannerImage,
    updateAnimatedBannerImageSettings,
    moveAnimatedBannerImage,
    uploadAnimatedBannerImage,
    deleteAnimatedBannerImageFile
};

export default connect(mapStateToProps, mapDispatch)(AnimatedBannerImagesEditionSection);