import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'reactstrap';

import { dropDownPropTypes } from '../../../../../propTypes';

const propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dictionary: dropDownPropTypes,
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    tooltipKey: PropTypes.string,
    columnName: PropTypes.string,
};

const DropDownCell = ({ value, dictionary, className, onChange, id, item, disabled, tooltip, tooltipKey, columnName }) => {
    const [ isTooltipOpen, setIsTooltipOpen ] = useState(false);

    const handleChange = e => {
        onChange(id, e.target.value, item, columnName);
    };

    return (
        <td className={className}>
        {
            tooltip &&
            <Tooltip placement="top" isOpen={isTooltipOpen} target={`inputTooltip-${tooltipKey}`} toggle={() => setIsTooltipOpen(!isTooltipOpen)}>
                {tooltip}
            </Tooltip>
        }
            <label id={`inputTooltip-${tooltipKey}`}>
                <select
                    disabled={disabled}
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={value}>
                    {dictionary.map(
                        dict =>
                            <option key={`${item.id}-${dict.id}`} value={dict.id}>
                                {dict.label}
                            </option>
                    )}
                </select>
            </label>
        </td>
    );
};

DropDownCell.propTypes = propTypes;

export default DropDownCell;