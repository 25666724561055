import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getOrder } from '../../redux/methods/orders';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';
import OrdersEditDetails from '../../features/orders/editTabs/OrderEditDetails';

import useFetch from '../../hooks/useFetch';

const propTypes = {
    location: PropTypes.object,
    getOrder: PropTypes.func,
};

const OrdersEdit = ({
    location,
    getOrder,
    forceRefetch,
}) => {
    const intl = useIntl();
    const translations = intl.messages.orders.edit;
    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);
    const chosenOrderId = query.get('orderID');

    const [ data ] = useFetch(getOrder, chosenOrderId, [], forceRefetch);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'orderEditTab-basic':
                return <OrdersEditDetails order={data.order} id={chosenOrderId}/>;
            default:
                return null;
        }
    };

    return chosenOrderId ? (
        data ? (
            <SettingsTabulator
                title={{
                    label: data.number || translations.fallbackName,
                    iconClassName: 'fa fa-edit text-primary ml-3',
                    isFilled: data.number ? false : true,
                }}
                tabulatorHeader={{
                    label: translations.tabSelectorTitle,
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: 'orderEditTab-basic',
                        header:  {
                            label: translations.tabs.details,
                            iconClassName: 'fa fa-tasks'
                        }
                    }
                ]}
            />
        ) : null
    ) : (
        <Redirect to='/orders'/>
    );
};

OrdersEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.orders,
});

const mapDispatch = {
    getOrder,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(OrdersEdit));