import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { translateIso } from "../../utils/locale";

import { Nav, NavItem, NavLink } from 'reactstrap';

import styles from './index.module.scss';
import { stringOrNumberPropTypes } from '../../propTypes';

const propTypes = {
    tabsList: PropTypes.arrayOf(PropTypes.shape({
        iso: stringOrNumberPropTypes,
        label: PropTypes.string
    })),
    activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setActiveTab: PropTypes.func,
    withoutFlag: PropTypes.bool
};

const TabulatorNav = ({ tabsList, activeTab, setActiveTab, withoutFlag }) => {
    const handleSetActiveTab = iso => () => {
        setActiveTab(iso);
    };

    return (
        <Nav tabs>
            {tabsList.map(element => {
                return (
                    <NavItem key={`${element.iso}TabulatorNavItem`}>
                        <NavLink
                            className={clsx({active: activeTab === element.iso}, styles.container)}
                            onClick={handleSetActiveTab(element.iso)}
                        >
                            <span>
                                {
                                    !withoutFlag && <i className={clsx(`flag-icon flag-icon-${translateIso(element.iso).toLowerCase()}`, styles.flag)}/>
                                }
                                <span className={!withoutFlag ? styles.name : null}>{element.label}</span>
                            </span>
                        </NavLink>
                    </NavItem>
                );
            })}
        </Nav>
    );
};

TabulatorNav.propTypes = propTypes;

export default TabulatorNav;
