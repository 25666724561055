import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withFormUtils } from '../../../../hoc/withFormUtils';
import { getNumberOfVisits } from '../../../../redux/methods/stats';
import useFetch from '../../../../hooks/useFetch';
import useSearchConfig from '../../../../hooks/useSearchConfig';
import { generateFirstDayOfMonthDate, generateLastYearDate, generateTodayStartOfDay } from '../../../../utils/date';

import { numberOfVisitsSearchConfig } from '../../../../config/searchConfigs';

import Tabulator from "../../../../components/Tabulator";
import LineChart from '../../../../components/LineChart';
import ChartDatePicker from '../../../../components/ChartDatePicker';

import { dateRangeTypes } from '../../../../config';

import { formUtilsHocPropTypes } from '../../../../propTypes';

const propTypes = {
    translations: PropTypes.object,
    utils: formUtilsHocPropTypes,
    getNumberOfVisits: PropTypes.func,
};

const ChartSection = ({
    translations,
    utils,
    getNumberOfVisits,
}) => {
    // eslint-disable-next-line no-unused-vars
    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(numberOfVisitsSearchConfig);
    const stats = useFetch(getNumberOfVisits, searchConfig, [ searchConfig ]);

    const [ dateRangeType, setDateRangeType ] = useState(translations.dateRangeTypes[dateRangeTypes[0]]);

    const transaltedDateRangeTypeOptions = dateRangeTypes.map(option => translations.dateRangeTypes[option]);

    const config = { date: ['dateTo', 'dateFrom'] };

    const performCustomRangeSelection = form => {
        const dates = utils.generateFormToSubmitValues(form, config);

        changeSearchConfig(
            {
                ...searchConfig,
                searchByUserTime: form.searchByUserTime,
                dateTo: dates.dateTo,
                dateFrom: dates.dateFrom
            }
        );
    };

    useEffect(() => {
        if (dateRangeType === translations.dateRangeTypes['day']) {
            return changeSearchConfig({...searchConfig, dateFrom: generateTodayStartOfDay(), dateTo: new Date() });
        }
        else if (dateRangeType === translations.dateRangeTypes['month']) {
            return changeSearchConfig({ ...searchConfig, dateFrom: generateFirstDayOfMonthDate(), dateTo: new Date() });
        }
        else if (dateRangeType === translations.dateRangeTypes['year']) {
            return changeSearchConfig({ ...searchConfig, dateFrom: generateLastYearDate(), dateTo: new Date() });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dateRangeType ]);

    const options =
    {
        scales: {
            yAxes: [{
                ticks: {
                    precision: 0,
                    beginAtZero: true
                }
            }]
        }
    };

    return stats[0] ? (
        <div className='w-100 h-100'>
            <div className='w-100 d-flex justify-content-end'>
                <ChartDatePicker
                    className='d-flex flex-column align-items-end'
                    currentSelection={dateRangeType}
                    performSelect={setDateRangeType}
                    options={transaltedDateRangeTypeOptions}
                    customRangeTranslation={translations.dateRangeTypes['custom']}
                    translations={translations}
                    utils={utils}
                    onSubmit={performCustomRangeSelection}
                    finalFormConfig={config}
                    searchConfig={searchConfig}
                />
            </div>
            <Tabulator
                listOfElements={[
                    {
                        label: translations.labels.numberOfVisits,
                        id: 'numberOfVisits-chart',
                        component: <LineChart
                                        label={translations.labels.numberOfVisits}
                                        data={stats[0]}
                                        emptyDataTranslation={translations.emptyData}
                                        options={options}
                                    />
                    },
                ]}
            />
        </div>
    ) : null;
};

ChartSection.propTypes = propTypes;

const mapDispatch = {
    getNumberOfVisits
};

export default withFormUtils(connect(null, mapDispatch)(ChartSection));