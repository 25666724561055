import React, { useState } from 'react';
import PropTypes from 'prop-types';

//import returnIcon from '../../../../assets/return.png';
import folderIcon from '../../../../assets/folder.jpg';
import virtualFolderIcon from '../../../../assets/virtualfolder.jpg';

import styles from './index.module.scss';
import { Tooltip } from 'reactstrap';

const propTypes = {
    item: PropTypes.object,
    editMode: PropTypes.bool,
    changeFolder: PropTypes.func,
};

const FolderItem = ({ item, editMode, changeFolder }) => {
    const getOpacity = () => editMode ? 0.5 : 1;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const style = {
        backgroundImage: item.forReturn
            ? `transparent`
            : (item.id.startsWith('Virtual')
                ? `url(${virtualFolderIcon})`
                : `url(${folderIcon})`),
        opacity: getOpacity(),
        backgroundSize: 'cover',
    };

    const cutText = text => text && text.length > 25 ? `${text.slice(0, 25)}...` : text;

    const handleClick = () => {
        if(editMode) {
            return null;
        } else {
            changeFolder(item.id);
        }
    };

    const generateId = item.id && item.id.toLowerCase().replaceAll('/', '');

    return (
        <>
            {item.id &&
                <Tooltip placement="right" isOpen={tooltipOpen} target={`tooltip-folder-${generateId}`} toggle={toggle}>
                    {item.name}
                </Tooltip>
            }
            <div id={`tooltip-folder-${generateId}`} onClick={handleClick} className={styles.container}>
                <div style={style} className={styles.item}>
                    {item.forReturn && <span><i className="fal fa-folder-tree"></i></span>}
                </div>
                {!item.forReturn && <span>{cutText(item.name)}</span>}
            </div>
        </>
    );
};

FolderItem.propTypes = propTypes;

export default FolderItem;