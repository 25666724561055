import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getIntegrationAPaczka, updateIntegrationAPaczka } from '../../../redux/methods/integrations';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';

import { Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';

const propTypes = {
    getIntegrationAPaczka: PropTypes.func,
    updateIntegrationAPaczka: PropTypes.func,
};

const APaczka = ({ getIntegrationAPaczka, updateIntegrationAPaczka }) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.apaczka;

    const [ data ] = useFetch(getIntegrationAPaczka);

    return data ? (
        <ContainerWithCard title={translations.title} iconclassName='fa fa-cogs'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateIntegrationAPaczka}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>

								<div className='my-2'><FormFieldSwitchPill label={translations.labels.enabled} name='enabled' /></div>

								<div className='row'>
									<div className='col-12 col-xl-6'>
										<div className='card'>
											<div className='card-header'>{translations.headers.urls}</div>
											<div className='card-body'>
												<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.apiUrl} name='apiUrl' /></div>
												<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.appId} name='appId' /></div>
												<div className='mt-3'><FormFieldTextOrNumber type='password' label={translations.labels.appSecret} name='appSecret' /></div>
											</div>
										</div>
									</div>
									<div className='col-12 col-xl-6'>
										<div className='card'>
											<div className='card-header'>{translations.headers.senderAddress}</div>
											<div className='card-body'>
												<div className='row'>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.pickupName} name='pickupName' /></div>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.pickupContactPerson} name='pickupContactPerson' /></div>
												</div>
												<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.pickupLine1} name='pickupLine1' /></div>
												<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.pickupLine2} name='pickupLine2' /></div>
												<div className='row'>
													<div className='mt-3 col-3'><FormFieldTextOrNumber label={translations.labels.pickupPostalCode} name='pickupPostalCode' /></div>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.pickupCity} name='pickupCity' /></div>
													<div className='mt-3 col-3'><FormFieldTextOrNumber label={translations.labels.pickupCountryCode} name='pickupCountryCode' /></div>
												</div>
												<div className='row'>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.pickupEmail} name='pickupEmail' /></div>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.pickupPhone} name='pickupPhone' /></div>
												</div>
												<div className='row'>
													<div className='mt-3 col-6'><FormFieldSwitchPill label={translations.labels.pickupIsResidential} name='pickupIsResidential' /></div>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.pickupForeignAddressId} name='pickupForeignAddressId' /></div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='row'>
									<div className='col-12 col-xl-6'>
										<div className='card'>
											<div className='card-header'>{translations.headers.defaultPickup}</div>
											<div className='card-body'>
												<div className='row'>
													<div className='mt-3 col-6'><FormFieldTextOrNumber label={translations.labels.defaultPickupType} name='defaultPickupType' /></div>
													<div className='mt-3 col-6'><FormFieldTextOrNumber type="date" label={translations.labels.defaultPickupDate} name='defaultPickupDate' /></div>
												</div>
												<div className='row'>
													<div className='mt-3 col-6'><FormFieldTextOrNumber type="time" label={translations.labels.defaultPickupHoursFrom} name='defaultPickupHoursFrom' /></div>
													<div className='mt-3 col-6'><FormFieldTextOrNumber type="time" label={translations.labels.defaultPickupHoursTo} name='defaultPickupHoursTo' /></div>
												</div>
											</div>
										</div>
										<div className='card'>
											<div className='card-header'>{translations.headers.defaultOptions}</div>
											<div className='card-body'>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultOptionSmsNotification} name='defaultOptionSmsNotification' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultOptionRod} name='defaultOptionRod' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultOptionDelivaryOnSaturday} name='defaultOptionDelivaryOnSaturday' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultOptionDelivaryInHours} name='defaultOptionDelivaryInHours' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultOptionBeCarefull} name='defaultOptionBeCarefull' /></div>
											</div>
										</div>
										<div className='card'>
											<div className='card-header'>{translations.headers.defaultCod}</div>
											<div className='card-body'>
												<div className='mt-3'><FormFieldTextOrNumber type="number" inputClassName="form-control text-right" label={translations.labels.defaultCodAmount} name='defaultCodAmount' /></div>
												<div className='mt-3'><FormFieldTextOrNumber label={translations.labels.defaultCodBankaccount} name='defaultCodBankaccount' /></div>
											</div>
										</div>
									</div>
									<div className='col-12 col-xl-6'>
										<div className='card'>
											<div className='card-header'>{translations.headers.defaultNotifications}</div>
											<div className='card-body'>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationNewReceiverEmail} name='defaultNotificationNewReceiverEmail' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationNewReceiverSms} name='defaultNotificationNewReceiverSms' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationNewSenderEmail} name='defaultNotificationNewSenderEmail' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationSentReceiverEmail} name='defaultNotificationSentReceiverEmail' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationSentReceiverSms} name='defaultNotificationSentReceiverSms' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationSentSenderEmail} name='defaultNotificationSentSenderEmail' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationExceptionReceiverEmail} name='defaultNotificationExceptionReceiverEmail' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationExceptionReceiverSms} name='defaultNotificationExceptionReceiverSms' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationExceptionSenderEmail} name='defaultNotificationExceptionSenderEmail' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationDeliveredReceiverEmail} name='defaultNotificationDeliveredReceiverEmail' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationDeliveredReceiverSms} name='defaultNotificationDeliveredReceiverSms' /></div>
												<div className='mt-3'><FormFieldSwitchPill thin='true' label={translations.labels.defaultNotificationDeliveredSenderEmail} name='defaultNotificationDeliveredSenderEmail' /></div>
											</div>
										</div>
										<div className='card'>
											<div className='card-header'>{translations.headers.defaultShipment}</div>
											<div className='card-body'>
												<div className='row'>
														<div className='mt-3 col-4'><FormFieldTextOrNumber label={translations.labels.defaultShipmentDimension1} name='defaultShipmentDimension1' /></div>
														<div className='mt-3 col-4'><FormFieldTextOrNumber label={translations.labels.defaultShipmentDimension2} name='defaultShipmentDimension2' /></div>
														<div className='mt-3 col-4'><FormFieldTextOrNumber label={translations.labels.defaultShipmentDimension3} name='defaultShipmentDimension3' /></div>

														<div className='mt-3 col-4'><FormFieldTextOrNumber label={translations.labels.defaultShipmentWeight} name='defaultShipmentWeight' /></div>
														<div className='mt-3 col-4'><FormFieldSwitchPill thin='true' label={translations.labels.defaultShipmentIsNstd} name='defaultShipmentIsNstd' /></div>
														<div className='mt-3 col-4'><FormFieldTextOrNumber label={translations.labels.defaultShipmentContentDescription} name='defaultShipmentContentDescription' /></div>

														<div className='mt-3 col-6'><FormFieldWithDropdown label={translations.labels.defaultShipmentServiceId} name='defaultServiceId' options={data.serviceIdOptions} /></div>
														<div className='mt-3 col-6'><FormFieldWithDropdown label={translations.labels.defaultShipmentTypeCode} name='defaultShipmentTypeCode'options={data.shipmentTypeCodeOptions} /></div>
												</div>
											</div>
										</div>
									</div>
								</div>

                                <SectionEditFormButtons formReset={form.reset}/>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconclassName='fa fa-facebook'/>;
};

const mapDispatch = {
    getIntegrationAPaczka,
    updateIntegrationAPaczka,
};

APaczka.propTypes = propTypes;

export default connect(null, mapDispatch)(APaczka);