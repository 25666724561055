export const waproMagAdditionalFields = [
    {
        id: 1,
        label: 'Field 1'
    },
    {
        id: 2,
        label: 'Field 2'
    },
    {
        id: 3,
        label: 'Field 3'
    },
    {
        id: 4,
        label: 'Field 4'
    },
    {
        id: 5,
        label: 'Field 5'
    },
    {
        id: 6,
        label: 'Field 6'
    },
    {
        id: 7,
        label: 'Field 7'
    },
    {
        id: 8,
        label: 'Field 8'
    },
    {
        id: 9,
        label: 'Field 9'
    },
    {
        id: 10,
        label: 'Field 10'
    },
];

export const unitPresentationTypes = [
    'Storage', 'Sales', 'Reference'
];