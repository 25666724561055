import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { getAgreements, removeAgreement, updateAgreementStatus } from '../../../redux/methods/agreements';
import useFetch from '../../../hooks/useFetch';

import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';

import AgreementsTable from '../../../components/tables/AgreementsTable';

import styles from './index.module.scss';

const propTypes = {
    getAgreements: PropTypes.func,
    history: PropTypes.object,
    removeAgreement: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const Agreements = ({ getAgreements, history, removeAgreement, forceRefetch, updateAgreementStatus }) => {
    const intl = useIntl();
    const translations = intl.messages.agreements.view;

    const [ agreements ] = useFetch(getAgreements, null, [], forceRefetch);
    const [ checkedAgreementsIds, setCheckedAgreementsIds ] = useState([]);

    const handleAddAgreement = () => {
        history.push(`/agreements/list/edit?agreementID=0`);
    };

    const handleRemoveAction = (id) => {
        removeAgreement(id);
    };

    return agreements ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody>
                                <main>
                                    <AgreementsTable
                                        agreements={agreements}
                                        history={history}
                                        headerLabels={translations.headerLabels}
                                        agreementIsActiveText={translations.agreementIsActiveText}
                                        agreementIsRequiredText={translations.agreementIsRequiredText}
                                        translations={translations}
                                        removeAction={handleRemoveAction}
                                        emptyPlaceholders={translations.emptyPlaceholders}
                                        checkedAgreementsIds={checkedAgreementsIds}
                                        setCheckedAgreementsIds={setCheckedAgreementsIds}
                                        updateAction={updateAgreementStatus}
                                    />
                                </main>
                                <aside className={styles.footer}>
                                    <Button color='primary' onClick={handleAddAgreement}>
                                        <i className='fa fa-plus mr-2'/>
                                        <span>{translations.actions.addAgreement}</span>
                                    </Button>
                                </aside>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ) : null;
};

Agreements.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.agreements,
});

const mapDispatch = {
    getAgreements,
    removeAgreement,
    updateAgreementStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Agreements));