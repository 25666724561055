import React from 'react';
import { connect } from 'react-redux';
import useFetch from '../hooks/useFetch';
import { getManufacturers } from '../redux/methods/manufacturers';

export const withManufacturers = Component => {
    const ManufacturersHook = ({ getManufacturers, ...props }) => {
        const [manufacturers] = useFetch(getManufacturers);

        return manufacturers ? <Component manufacturers={manufacturers} {...props} /> : null;
    };

    const mapDispatch = {
        getManufacturers,
    };

    return connect(null, mapDispatch)(ManufacturersHook);
};