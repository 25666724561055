const searchEngine = {
    inputs: {
        searchPlaceholder: 'Tekst w wyszukiwarce',
        minChar: 'Minimalna liczba znaków do włączenia wyszukiwania',
        hintsCount: 'Maksymalna liczba podpowiedzi',
        tags: 'Pokazywany tag dla braku wyników',
        sortDirection: 'Domyślne sortowanie wyników wyszukiwania',
        noProductsText: 'Ostrzeżenie o braku wyników',
        type: 'Typ wyświetlania podpowiedzi'
    },
    typeOptions: {
        list: 'Lista',
        tile: 'Kafelki'
    },
    sortDirectionOptions: {
        score: 'trafność',
        productNameAsc: 'nazwa produktu rosnąco',
        productNameDesc: 'nazwa produktu malejąco',
        productGrossPriceAsc: 'cena brutto rosnąco',
        productGrossPriceDesc: 'cena brutto malejąco'
    },
    help: {
        range: 'Suwakami ustawiamy moc danego kryterium.',
        disable: 'Jeśli moc danego kryterium wynosi 0, zostaje on wyłączony przy wyszukiwaniu.',
        description: 'Jeśli moc "Opisu" jest większa niż 0, użytkownik decyduje czy chce wyszukiwać po opisie poprzez zaznaczenie checkboxa w wyszukiwarce'
    },
    title: 'Ustawienia wyszukiwania',
    button: 'ZAPISZ ZMIANY',
    searchCriteria: 'Kryteria wyszukiwania',
    options: 'Opcje',
    checkboxes: {
        manufacturer: 'Producent',
        catalogueindex: 'Indeks katalogowy',
        tradeindex: 'Indeks handlowy',
        ean: 'Kod EAN',
        pkwiu: 'Kod PKWIU',
        swwku: 'Kod SWWKU',
        distinguisher: 'Wyróżnik',
        name: 'Nazwa',
        cnCode: 'Kod CN',
        description: 'Opis',
        longDescription: 'Długi opis',
        resetPhraseAfterSearchResultShown: 'Resetuj frazę po pokazaniu wyników wyszukiwania',
        resetCategoryAfterPhraseChange: 'Resetuj kategorie po zmianie frazy',
        resetFiltersAfterCategoryChange: 'Resetuj filtry po zmianie kategorii',
        resetSearchAfterAddProductToCart: 'Resetuj wyszukiwanie po dodaniu produktu do koszyka',
        resetCategoryAfterFilterChange: 'Resetuj kategorię po zmianie filtrów',
        resetPhraseAfterFilterChange: 'Resetuj frazę po zmianie filtrów',
        searchWhileSettingFilter: 'Wyszukuj podczas ustawiania filtrów',
        showSuggestionsForCurrentCategory: 'Pokazuj sugestie dla aktualnej kategorii'
    },
    nullOpt: 'Brak'
};

export default searchEngine;