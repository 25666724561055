const orders = {
    greetings: {
        title: 'Edycja treści podziękowań po złożeniu zamówienia',
        summaryContent: 'Treść podziękowań po złożeniu zamówienia',
        button: 'Zapisz',
    },
    view: {
        title: "Lista zamówień",
        headerLabels: {
            contrahentName: 'Nazwa kontrahenta',
            orderIndex: 'Lp.',
            number: 'Numer',
            status: 'Status',
            preferredDocument: 'Preferowany dokument',
            date: 'Data',
            actions: 'Akcje',
            currency: 'Waluta',
            grossValue: 'Kwota brutto',
            netValue: 'Kwota netto',
            checkbox: 'Zaznacz',
            attributes: 'Atrybuty',
            attachment: 'Załącznik',
            email: 'E-mail',
            statusDescription: 'Opis statusów',
            packageNumber: 'Nr przesyłki',
            packageService: 'Serwis przesyłki',
            packageStatus: 'Status przesyłki'
        },
        tooltips: {
            showDetails: 'Zobacz szczegóły',
            edit: 'Edytuj zamówienie',
            print: 'Drukuj'
        },
        sortableColumns: {
            Date: 'Data',
            ContrahentName: 'Nazwa kontrahenta',
            TotalGrossPrice: 'Wartość brutto',
            OrderStatus: 'Status zamówienia',
        },
        ordersSearch: {
            number: 'Numer',
            contrahentName: 'Nazwa kontrahenta',
            dateFrom: 'Od',
            dateTo: 'Do',
            currency: 'Waluta',
            status: 'Status',
            defaultDropdownOption: 'Dowolny',
            attribute: 'Atrybut',
            contrahentEmail: 'E-mail'
        },
        searchButton: 'SZUKAJ',
        resetButton: 'RESETUJ',
		packagesButton: 'Nadaj paczki',
        downloadButton: 'Pobierz zaznaczone zamówienia',
        emptyTable: 'Brak zamówień do wyświetlenia',
        sendPackagesModalLabel: 'Nadaj paczki'
    },
    edit: {
        inputs: {
            status: 'Status zamówienia',
            shipmentDate: 'Data wysyłki',
            invoiceNumber: 'Numer paragonu/faktury',
            comment: 'Komentarz',
        },
        tabs: {
            details: 'Szczegóły zamówienia'
        },
    },
    details: {
        headers: {
            index: 'Lp.',
            image: 'Zdjęcie',
            name: 'Nazwa',
            id: 'ID',
            amount: 'Ilość',
            realized: 'Ilość zrealizowana',
            grossPrice: 'Cena brutto',
            grossValue: 'Wartość brutto',
            discountName: 'Kupon rabatowy',
            actions: 'Akcje'
        },
        emptyLogoAlt: 'Logo zastępcze',
        emptyTable: 'Brak produktów do wyświetlenia.',
        sectionHeader: 'Zamówienie numer',
        orderDetails: {
            number: 'Numer',
            date: 'Data złożenia',
            totalGrossPrice: 'Wartość zamówienia brutto',
            orderStatus: 'Status zamówienia',
            paymentMethod: 'Forma płatności',
            invoiceNumber: 'Numer rachunku',
            invoice: 'Pobierz rachunek',
            returnNumber: 'Numer zwrotu',
            contrahentName: 'Nazwa kontrahenta',
            contrahentNumber: 'Numer kontrahenta',
            totalWeight: 'Waga zamówienia',
            shippingMethod: 'Sposób wysyłki',
            shipmentAddressName: 'Imię (wysyłka)',
            shipmentAddressSurname: 'Nazwisko (wysyłka)',
            shipmentAddressAddress: 'Ulica',
            shipmentAddressZipCode: 'Kod pocztowy',
            shipmentAddressCity: 'Miasto',
            shipmentAddressCountry: 'Kraj',
            shipmentAddressVoivodeship: 'Województwo',
            shipmentAddressPhoneNumber: 'Numer tel.',
            shipmentAddressEmail: 'E-mail',
            orderStatusChangeDate: 'Ostatnia zmiana statusu zamówienia',
            ean: 'Kod kreskowy',
            packageNumber: 'Numer przesyłki',
            comment: 'Uwagi',
            attributes: 'Atrybuty',
            receivingPerson: 'Odbiorca',
            shipmentAddress: 'Adres dostawy',
            attachement: 'Załącznik',
        },
        tooltips: {
            edit: 'Edytuj ilość zrealizowaną'
        },
        editModal: {
            alertMessage: {
                notANumber: 'Wprowadzona wartość nie jest liczbą',
                wrongValue: 'Ilość zrealizowana musi się mieścić w zakresie od 0 do '
            },
            modalHeader: 'Edytuj ilość zrealizowaną',
            buttons: {
                confirm: 'Zapisz',
                cancel: 'Anuluj'
            },
            labels: {
                realized: 'Ilość zrealizowana'
            }
        }
    },
	sendPackages: {
		selectedOrdersTable: {
            headers: {
                date: 'Data',
                number: 'Numer',
                totalGrossPrice: 'Kwota brutto',
                serviceId: 'Usługa',
                dimension1: 'Długość [cm]',
                dimension2: 'Wysokość [cm]',
                dimension3: 'Szerokość [cm]',
                weight: 'Waga [kg]',
                value: 'Wartość',
                nonStandard: 'Niestandardowa',
                types: 'Rodzaj przesyłki',
                description: 'Opis',
            }
        },
        steps: {
			chooseService: 'Wybierz operatora paczek',
			setProperties: 'Ustaw parametry paczek'
        },
        buttons:{
            prev: 'Wstecz',
            next: 'Dalej',
            send: 'Wyślij'
        },
        noServices: 'Nie zdefiniowanego żadnego operatora nadawania paczek. Przejdź do modułu integracji i zdefiniuj przynajmniej jedną integrację z operatorem paczek.',
        none: 'Brak',
        selectService: 'Wybierz operatora paczek',
        sendPackagesErrors: 'Podczas nadawania paczek wystąpiły błędy'
	},
    orderDetails: {
        generalDetails: {
            caption: 'Szczegóły zamówienia',
            fieldId: 'Identyfikator',
            fieldNumber: 'Numer',
            fieldPaymentMethod: 'Metoda płatności',
            fieldShippingMethod: 'Metoda wysyłki',
            fieldOrderStatus: 'Status',
            fieldOrderStatusErp: 'Status w ERP',
            fieldErpNumber: 'Numer w ERP',
            fieldDiscountName: 'Kupon rabatowy'
        },
        puchaserDetails: {
            caption: 'Dane zamawiającego',
            fieldCompanyNamePersonName: 'Nazwa firmy / imię i nazwisko',
            fieldNip: 'Nip',
            fieldAddress: 'Adres',
            fieldZipCode: 'Kod pocztowy',
            fieldCity: 'Miasto',
            fieldCountry: 'Kraj'
        },
        odbiorOsobisty: {
            caption: 'Dane punktu odbioru',
            fieldAddress: 'Adres punktu odbioru'
        },
        odbiorWPunkcie: {
            caption: 'Dane punktu odbioru',
            fieldName: 'Nazwa punktu odbioru',
            fieldType: 'Typ punktu odbioru',
            fieldStreet: 'Adres punktu odbioru',
            fieldZipCode: 'Kod pocztowy',
            fieldCity: 'Miasto',
            fieldPhone: 'Nr telefonu',
            fieldOptions: 'Opcje'
        },
        inPost: {
            caption: 'Dane paczkomatu',
            fieldCode: 'Kod punktu odbioru',
            fieldAddress: 'Adres punktu odbioru',
            fieldPhoneNumber: 'Nr telefonu do kontaktu'
        },
        wysylkaNaAdres: {
            caption: 'Adres wysyłki',
            fieldNameSurname: 'Imię i nazwisko',
            fieldAddress: 'Ulica i nr domu',
            fieldZipCodeCity: 'Kod pocztowy, miasto',
            fieldPhoneNumber: 'Nr telefonu',
            fieldEmail: 'e-mail'
        },
        status: {
            caption: 'Status zamówienia',
            fieldPlacingDate: 'Data złożenia',
            fieldFulfillmentDate: 'Data przygotowania',
            fieldShipmentDate: 'Data wysyłki',
            fieldInvoiceNumber: 'Nr faktury',
            fieldPackageNumber: 'Nr przesyłki',
            fieldReturnNumber: 'Nr zwrotu',
            statusChangeDate: 'Data zmiany statusu',
            erpStatusChangeDate: 'Data zmiany statusu w ERP',
            statusChangeMessage: 'Informacja o zmianie statusu'
        },
        prices: {
            caption: 'Wartość zamówienia',
            fieldCurrency: 'Waluta',
            fieldTotalNetPrice: 'Cena netto',
            fieldTotalGrossPrice: 'Cena brutto',
            fieldTotalWeight: 'Waga całkowita'
        },
        additionalInfo: 'Uwagi kupującego'
    }
};

export default orders;