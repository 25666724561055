import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import SearchForm from '../generators/SearchForm';

const propTypes = {
    performSearch: PropTypes.func,
    storeCurrentLanguage: PropTypes.string,
    searchConfig: PropTypes.object,
};

const CodeUsagesSearchForm = ({ performSearch, storeCurrentLanguage, searchConfig }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.usages.search;

    const { code, name, surname, email, date, orderNumber } = translations.inputs;

    return (
        <SearchForm
            searchConfig={searchConfig}
            translations={{
                buttons: translations.buttons,
            }}
            inputs={[
                {
                    label: code.label,
                    name: 'code',
                    type: 'text'
                },
                {
                    label: name.label,
                    name: 'name',
                    type: 'text'
                },
                {
                    label: surname.label,
                    name: 'surname',
                    type: 'text'
                },
                {
                    label: email.label,
                    name: 'email',
                    type: 'text'
                },
                {
                    label: date.label,
                    name: 'date',
                    type: 'date',
                    storeCurrentLanguage: storeCurrentLanguage,
                },
                {
                    label: orderNumber.label,
                    name: 'orderNumber',
                    type: 'text'
                },
            ]}
            performSearch={performSearch}
        />
    );
};

CodeUsagesSearchForm.propTypes = propTypes;

export default CodeUsagesSearchForm;