import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    addTranslation: PropTypes.string,
    handleOpenModal: PropTypes.func,
};

const HomePageFooterButtons = ({ handleOpenModal, addTranslation }) => {
    return <aside className={styles.footer}>
        <Button color="primary" onClick={handleOpenModal}>
            <i className="fa fa-plus mr-2"/>
            <span>{addTranslation}</span>
        </Button>
    </aside>;
};

HomePageFooterButtons.propTypes = propTypes;

export default HomePageFooterButtons;