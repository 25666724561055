/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import FileSender from '../../../../../../components/FileSender';

import styles from './index.module.scss';
import getConfig from '../../../../../../getConfig';


const propTypes = {
    type: PropTypes.string,
    values: PropTypes.objectOf(PropTypes.string),
    imageUrl: PropTypes.string,
};

const ImageTileElement = ({
    imageUrl,
    buttonsTranslations,
    uploadFileHandler,
    removeFileHandler,
    imageAlternativeText,
    fileKey,
    fileType
}) => {
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    return (
        <React.Fragment>
            <div className={clsx(styles.imageContainer)}>
                <img src={`${apiUrl}${imageUrl}`} alt={imageAlternativeText} className={styles.image} />
            </div>
            <div className='d-flex justify-content-end'>
                <FileSender
                    validateFormats={['jpg', 'png', 'jpeg', 'gif', 'mp4', 'webm', 'webp']}
                    buttons={buttonsTranslations}
                    handleUpload={uploadFileHandler}
                    handleRemove={removeFileHandler}
                    photo={imageUrl}
                    fileKey={fileKey}
                    fileType={fileType}
                    graphicTypeToEnableGuide='banner'
                />
            </div>
        </React.Fragment>
    );
};

ImageTileElement.propTypes = propTypes;

export default ImageTileElement;