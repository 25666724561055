import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectChosenLanguageId } from '../../../redux/selectors/locale';
import { recoverPasswordRequest } from '../../../redux/methods/auth';
import { reinitializeLocale } from '../../../redux/methods/locale';

import { Form as FinalForm } from 'react-final-form';
import { Button, Col, Form, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import FormField from '../sharedItems/FormField';

import styles from './index.module.scss';

const propTypes = {
    isLoading: PropTypes.bool,
    chosenLanguageId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    reinitializeLocale: PropTypes.func.isRequired
};

const ResetPasswordForm = ({ isLoading, chosenLanguageId, reinitializeLocale, recoverPasswordRequest }) => {
    const intl = useIntl();
    const translations = intl.messages.auth.resetPassword;

    useEffect(() => {
        if (!chosenLanguageId) {
            reinitializeLocale();
        }
    }, [ chosenLanguageId, reinitializeLocale ]);

    const onSubmit = formData => recoverPasswordRequest(formData);

    return (
        <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit, submitError, submitSucceeded }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <h1>{translations.title}</h1>
                        <p className="text-muted">{translations.subTitle}</p>
                        <span className={clsx('d-none mt-2 mb-3 text-success', submitSucceeded && 'd-block')}>{translations.submitSucceeded}</span>
                        <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="icon-envelope"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <FormField
                                type="email"
                                autoComplete="email"
                                name="email"
                                placeholder={translations.email}
                            />
                        </InputGroup>
                        <Row>
                            <Col xs="6">
                                <Button
                                    disabled={isLoading}
                                    type="submit"
                                    color="primary"
                                    className="px-4"
                                >
                                    {translations.send}
                                </Button>
                            </Col>
                            <Col xs="6" className="text-right">
                                <Button color="link" type="button" className="px-0">
                                    <Link to={'/login'}>{translations.backToLogin}</Link>
                                </Button>
                            </Col>
                            { submitError && <span className={styles.submitError}>{submitError}</span> }
                        </Row>
                    </Form>
                );
            }}
        />
    );
};

ResetPasswordForm.propTypes = propTypes;

const mapStateToProps = state => ({
    isLoading: state.auth.isLoading.login,
    chosenLanguageId: selectChosenLanguageId(state)
});

const mapDispatch = {
    recoverPasswordRequest,
    reinitializeLocale
};

export default connect(mapStateToProps, mapDispatch)(ResetPasswordForm);