import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { Table, Input, Row, Col, Button } from 'reactstrap';

import SortableCellHeader from './cells/SortableCellHeader';
import CustomButtons from './CustomButtons';
import PageSizeChanger from '../../PageSizeChanger';
import TableRow from './TableRow';

import arrayMove from './arrayMove';
import { dropDownPropTypes, statusConfigPropTypes, expectedActionsPropTypes, deleteModalPropTypes, stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';
import { useIntl } from 'react-intl';
import SaveDataToast from '../../SaveDataToast';

const propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                header: PropTypes.string,
                value: PropTypes.string,
                dictionary: dropDownPropTypes,
                sortValue: PropTypes.string,
                filler: PropTypes.string,
                config: statusConfigPropTypes,
                type: PropTypes.oneOf([
                    'checkbox', 'index', 'badge',
                    'date', 'actions', 'custom',
                    'switchWithAction', 'rating',
                    'order', 'dropDown', 'input'
                ]),
                inputType: PropTypes.oneOf([
                    'color','date','datetime-local',
                    'email','month','number',
                    'password','range','tel',
                    'text','time','url','week']),
                isSortable: PropTypes.bool,
                additionalStyle: PropTypes.string,
                specificOnClickAction: PropTypes.func,
                keyToPass: PropTypes.string,
                customTranslation: PropTypes.object
            }),
            PropTypes.bool
        ])
    ).isRequired,
    customButtons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            labelTrue: PropTypes.string,
            labelFalse: PropTypes.string,
            isDisabled: PropTypes.bool,
            color: PropTypes.string,
            action: PropTypes.func,
            type: PropTypes.oneOf(['selector'])
        })
    ),
    columnSortedByDescendingOrder: PropTypes.string,
    expectedActions: expectedActionsPropTypes,
    editLink: PropTypes.oneOfType([
        PropTypes.string, PropTypes.func
    ]),
    defaultAction: PropTypes.func,
    actions: PropTypes.shape({
        showDetailsAction: PropTypes.func,
        printAction: PropTypes.func,
        editAction: PropTypes.func,
        deleteAction: PropTypes.func,
        acceptAction: PropTypes.func,
    }),
    translations: PropTypes.shape({
        tooltips: PropTypes.shape({
            edit: PropTypes.string,
            remove: PropTypes.string,
            print: PropTypes.string,
            showDetails: PropTypes.string,
            accept: PropTypes.string,
        }),
        deleteModal: deleteModalPropTypes,
        emptyTable: PropTypes.string,
    }),
    onClickSortHandler: PropTypes.func,
    checkedItemsIds: PropTypes.arrayOf(
        stringOrNumberPropTypes,
    ),
    setCheckedItemsIds: PropTypes.func,
    additionalRowStyle: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    isCheckboxDisabled: PropTypes.bool,
    checkedRowId: stringOrNumberPropTypes,
    checkedRowStyle: PropTypes.string,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    onSaveTable: PropTypes.func,
    draggableData: PropTypes.shape({
        items: PropTypes.array.isRequired,
        setItems: PropTypes.func.isRequired,
    }),
    checkIfActionIsDisabled: PropTypes.func
};

const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement(props => <TableRow {...props} />);

const TableGenerator = ({
    items = [],
    currentPage = 1,
    pageSize = 10,
    columns,
    actions,
    translations,
    defaultAction,
    columnSortedByDescendingOrder,
    onClickSortHandler,
    expectedActions,
    checkedItemsIds,
    setCheckedItemsIds,
    additionalRowStyle,
    customIdSelector,
    editLink,
    isCheckboxDisabled,
    highlightOnSelectStyle,
    checkedRowId,
    checkedRowStyle,
    customButtons = [],
    performPageSizeSelect,
    pageSizeOptions,
    draggable = false,
    onSaveTable,
    draggableData,
    detailsComponent,
    checkIfActionIsDisabled
}) => {
    const idSelector = customIdSelector || 'id';
    const isMobile = useMediaQuery({ query: '(max-width: 893px)' });
    const defaultTranslations = useIntl().messages.sharedComponents.tables;

    const [selectedRowId, setSelectedRowId] = useState(null);
    const [itemsDragAndDrop, setItemsDragAndDrop] = useState(items);
    const [isEdited, setIsEdited] = useState(false);

    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        if(draggableData) {
            draggableData.setItems(oldItems => arrayMove(oldItems, oldIndex, newIndex));
            setItemsDragAndDrop(oldItems => arrayMove(oldItems, oldIndex, newIndex));
        } else {
            setItemsDragAndDrop(oldItems => arrayMove(oldItems, oldIndex, newIndex));
            setIsEdited(true);
        }
    }, [draggableData]);

    const [isAllCheckboxesSelected, setIsAllCheckboxesSelected] = useState(
        checkedItemsIds
            ? items.filter(item => !checkedItemsIds.includes(item)).length === 0
            : false
    );

    const handleCheckboxAction = id => () => {
        const pushedIdList = [...checkedItemsIds, id];
        const poppedIdList = checkedItemsIds.filter(item => item !== id);

        setCheckedItemsIds(checkedItemsIds.includes(id) ? poppedIdList : pushedIdList);
    };

    const handleSelectAllAction = () => {
        const itemsIds = draggableData ? draggableData.items.map(item => item[idSelector]) : itemsDragAndDrop.map(item => item[idSelector]);

        const pushedIdList = [...checkedItemsIds, ...itemsIds.filter(item => !checkedItemsIds.includes(item))];
        const poppedIdList = checkedItemsIds.filter(item => !itemsIds.includes(item));

        setCheckedItemsIds(isAllCheckboxesSelected ? poppedIdList : pushedIdList);
        setIsAllCheckboxesSelected(!isAllCheckboxesSelected);
    };

    const handleDeselectAll = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setCheckedItemsIds([]);
        setIsAllCheckboxesSelected(false);
    };

    const handleSelectRow = (itemId) => () => {
        setSelectedRowId(selectedRowId === itemId ? null : itemId);
    };

    const onClickActionHandler = (column, itemId, item) => {
        return (
            !isMobile
                ? column.specificOnClickAction
                    ? column.specificOnClickAction(itemId, item)
                    : defaultAction
                        ? defaultAction(itemId, item)
                        : handleSelectRow(itemId)
                : handleSelectRow(itemId)
        );
    };

    const handleSaveAction = () => {
        setIsEdited(false);
        const withNewOrder = itemsDragAndDrop.map((item, index) => ({ ...item, order: index }));

        onSaveTable(withNewOrder);
    };

    const properList = draggableData ? draggableData.items : itemsDragAndDrop;

    useEffect(() => {
        if (checkedItemsIds) {
            setIsAllCheckboxesSelected(items.filter(item => !checkedItemsIds.includes(item[idSelector])).length === 0);
        }
    }, [items, checkedItemsIds]);

    useEffect(() => {
        setItemsDragAndDrop(items);
    }, [items]);

    return properList.length > 0 ? (
        <React.Fragment>
            <SaveDataToast isEdited={isEdited} handleSubmit={handleSaveAction} />

            <Row>
                <Col className="col-xs-12 col-sm-12 col-md-6">
                    {
                        performPageSizeSelect &&
                            <PageSizeChanger
                                performSelect={performPageSizeSelect}
                                currentPageSize={pageSize}
                                options={pageSizeOptions}
                                className='pt-3 pb-1'
                            />
                    }
                </Col>
                <Col className="col-xs-12 col-sm-12 col-md-6 text-right">
                    { setCheckedItemsIds &&
                        <div className="pt-3">
                            {checkedItemsIds.length > 0 &&
                                <span>
                                    &nbsp;&nbsp;
                                    Ilość zaznaczonych wierszy: <b>{checkedItemsIds.length}</b>
                                    &nbsp;&nbsp;
                                    <a href="" onClick={handleDeselectAll}>wyczyść</a>
                                </span>
                            }
                        </div>
                    }
                </Col>
            </Row>

            <Table responsive hover size='sm'>
                <thead>
                    <tr>
                        {
                            draggable && <th className={styles.header}></th>
                        }
                        {
                            columns.flatMap((column, index) => column &&
                                <th key={`${column.header}--tableHeader--${index}`} className={styles.header}>
                                    {
                                        column.type === 'checkbox'
                                            ? <Input
                                                id={`checkboxItem-${column.type}`}
                                                type='checkbox'
                                                onChange={handleSelectAllAction}
                                                className='position-static m-0 p-0'
                                                disabled={isCheckboxDisabled}
                                                checked={isAllCheckboxesSelected}
                                                value={isAllCheckboxesSelected}
                                            />
                                            : column.isSortable
                                                ? <SortableCellHeader
                                                    sortValue={column.sortValue}
                                                    columnSortedByDescendingOrder={columnSortedByDescendingOrder}
                                                    sortAction={onClickSortHandler}
                                                    header={column.header}
                                                    className={styles.sortableCell}
                                                />
                                                : <span>{column.header}</span>
                                    }
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <SortableCont
                    onSortEnd={onSortEnd}
                    axis="y"
                    lockAxis="y"
                    lockToContainerEdges={true}
                    lockOffset={["30%", "50%"]}
                    useDragHandle={true}
                >
                    {
                        properList.map((item, index) => {
                            const isChecked = checkedItemsIds && checkedItemsIds.some(checkedItem => checkedItem === item[idSelector]);
                            const itemIndex = currentPage * pageSize + (index + 1) - pageSize;
                            return (
                                <SortableItem
                                    key={`${item[idSelector] ? item[idSelector] : index}--generatedRow${index}`}
                                    className={clsx(
                                        styles.row,
                                        additionalRowStyle,
                                        highlightOnSelectStyle && isChecked ? highlightOnSelectStyle : null,
                                        checkedRowId === item[idSelector] && checkedRowStyle,
                                    )}
                                    index={index}
                                    adjustedIndex={index}
                                    columns={columns}
                                    item={item}
                                    idSelector={idSelector}
                                    items={properList}
                                    onClickActionHandler={onClickActionHandler}
                                    itemIndex={itemIndex}
                                    actions={actions}
                                    expectedActions={expectedActions}
                                    translations={translations}
                                    editLink={editLink}
                                    isChecked={isChecked}
                                    handleCheckboxAction={handleCheckboxAction}
                                    isCheckboxDisabled={isCheckboxDisabled}
                                    draggable={draggable}
                                    selectedRowId={selectedRowId}
                                    detailsComponent={detailsComponent}
                                    checkIfActionIsDisabled={checkIfActionIsDisabled}
                                />
                            );
                        })
                    }
                </SortableCont>
            </Table>
            <CustomButtons customButtons={customButtons} />
            {draggable && onSaveTable && <Button color='success' onClick={handleSaveAction}>
                <i className='fa fa-save mr-2'/>
                {defaultTranslations.saveTable}
            </Button>}
        </React.Fragment>
    ) : (
        <Row className="d-flex justify-content-center align-items-center mt-4 mb-4">
            {translations.emptyTable}
        </Row>
    );
};

TableGenerator.propTypes = propTypes;

export default TableGenerator;