const itemCountTile = [
    {
        id: 30,
        label: '30'
    },
    {
        id: 60,
        label: '60'
    },
    {
        id: 90,
        label: '90'
    }
];

export default itemCountTile;