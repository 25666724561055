import { adminRoutes } from '../routes/_adminRoutes';

const appBreadcrumbRoutes = appSidebardTranslations => {
    const routes = Object.values(adminRoutes);
    const mainRoutes = routes
        .filter(route => route.path !== '#')
        .map(route => ({
            name: appSidebardTranslations.positions[route.id],
            path: route.path,
            exact: route.exact
        }));

    const subRoutes = routes
        .filter(route => route.path === '#')
        .map(item => {
            return item.children.map(child => ({
                name: appSidebardTranslations.positions[child.id],
                path: child.path,
                exact: child.exact
            }));
        })
        .flat(1);

    return [...mainRoutes, ...subRoutes];
};

export default appBreadcrumbRoutes;