/**
 * return date yyyy-MM-ddThh:mm
 */
export const generateLocalStringFromUTCForPicker = utcDateString => {
    const newDate = utcDateString ? new Date(utcDateString) : null;
    if(newDate === null){
        return null;
    }

    const addZeroIfRequired = item => item > 9 ? item : `0${item}`;

    const year = newDate.getFullYear();
    const month = addZeroIfRequired(newDate.getMonth() + 1);
    const day = addZeroIfRequired(newDate.getDate());
    const hours = addZeroIfRequired(newDate.getHours());
    const minutes = addZeroIfRequired(newDate.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const generateZuluDateStringFromLocal = localDateString => {
    return localDateString ? new Date(localDateString).toISOString() : null;
};

export const generateTodayStartOfDay = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return today.toISOString();
};

export const generateFirstDayOfMonthDate = () => {
    const today = new Date();
    today.setDate(1);
    today.setHours(0, 0, 0, 0);

    return today.toISOString();
};

export const generateLastYearDate = () => {
    const today = new Date();
    const lastYearDate = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0);

    return lastYearDate.toISOString();
};