import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { withFormUtils } from '../../../hoc/withFormUtils';
import { Row, Col } from 'reactstrap';
import ContainerWithCard from '../../../components/ContainerWithCard';
import CategoryTreeSection from '../../../features/products/categoryAssignSections/CategoryTreeSection';
import ProductListSection from '../../../features/products/categoryAssignSections/ProductListSection';
import AssignedCategoriesSection from '../../../features/products/categoryAssignSections/AssignedCategoriesSection';

const propTypes = {
    getCategoryTree: PropTypes.func,
    currentLanguage: PropTypes.string
};

const CategoryAssign = ({ utils }) => {
    const intl = useIntl();
    const translations = intl.messages.products.categoryAssign;

    return (
        <ContainerWithCard title={translations.title} iconClass='cil-blur' >
            <Row className='w-100'>
                <Col xs='12' xl='3'>
                    <CategoryTreeSection
                        translations={translations}
                        currentLanguage={utils.currentLanguage()}
                        showTwoTrees
                    />
                </Col>
                <Col xs='12' xl='7'>
                    <ProductListSection />
                </Col>
                <Col xs='12' xl='2'>
                    <AssignedCategoriesSection />
                </Col>
            </Row>
        </ContainerWithCard>
    );
};

CategoryAssign.propTypes = propTypes;

export default withFormUtils(CategoryAssign);