import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Modal from '../Modal';
import CategorySelect from '../../CategorySelect';
import Dropdown from '../../Dropdown';

import { selectIsCategoryTreePresent } from '../../../redux/selectors/categories';
import { getCategoryTree } from '../../../redux/methods/categories';
import { selectStoreCurrentLanguage, selectStoreAvailableIsoList } from '../../../redux/selectors/storeLocale';

import { generateNonIdsOptionsForDropdown } from '../../../utils/components';

import styles from './index.module.scss';

const propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    caption: PropTypes.string,
    categorySelect: PropTypes.func,
    isCategoryTreePresent: PropTypes.bool,
    getCategoryTree: PropTypes.func,
    categoryTree: PropTypes.arrayOf(Object),
    currentLanguage: PropTypes.string,
    allLanguages: PropTypes.array
};

const CategorySelectModal = ({
    isOpen,
    setIsOpen,
    caption,
    categorySelect,
    isCategoryTreePresent,
    getCategoryTree,
    categoryTree,
    currentLanguage,
    allLanguages,
    showAll = false
}) => {

    const intl = useIntl();
    const translations = intl.messages.sharedComponents.categorySelectModal;

    const categoryAllName = allLanguages.map(lang => { return { iso: lang, value: 'Wszystko'}; });
    const categoryTreeWithAll = showAll ? [{
        category: {
            id: 0,
            depth: 0,
            metatags: null,
            name: categoryAllName,
            orderIndex: 0,
            parentStatus: null,
            quantity: null,
            status: 1,
            statusErp: null
        },
        subItems: []
    }].concat(categoryTree) : categoryTree;

    const [ categoriesToDisplay, setCategoriesToDisplay ] = useState(categoryTreeWithAll);
    const [ categoriesDisplayOption, setCategoriesToDisplayOption ] = useState('all');

    const categoriesDisplayOptions = generateNonIdsOptionsForDropdown(['all', 'activeOnly'], translations.dropdownSelector.options);

    useEffect(() => {
        if (!isCategoryTreePresent) {
            getCategoryTree();
        }
    }, []); // eslint-disable-line

    const filterActive = (categories) => {
        const fildered =  categories.filter(({ category }) => category.status === 1);

        return fildered.map(item => ({
            ...item,
            subItems: item.subItems && item.subItems.length > 0 ? filterActive(item.subItems) : item.subItems
        }));
    };

    useEffect(() => {
        if(categoryTree) {
            if(categoriesDisplayOption === 'activeOnly') {
                setCategoriesToDisplay(filterActive(categoryTreeWithAll));
            }
            else {
                setCategoriesToDisplay(categoryTreeWithAll);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ categoryTree, categoriesDisplayOption ]);

    const getTranslation = (node) => {
        for(const n in node){
            if (node[n].iso.toLowerCase() === currentLanguage){
                return node[n].value;
            }
        }
        return null;
    };

    const prepareCategories = (nodes, path, level) => {
        return nodes.map(n => {
            const translatedName = getTranslation(n.category.name);
            return {
                id: n.category.id,
                name: translatedName,
                expanded: false,
                selected: false,
                level: level,
                path: (path === '' ? '' : path + ' => ') + translatedName,
                children: n.subItems && Array.isArray(n.subItems) && n.subItems.length > 0
                    ? prepareCategories(n.subItems, (path === '' ? '' : path + ' => ') + translatedName, level + 1)
                    : null
            };
        });
    };

    let selectedCategoryId = null;
    let selectedCategory = null;

    const handleCategoryChanged = (id, member) => {
        selectedCategoryId = id;
        selectedCategory = member;
    };

    const handleCategorySelect = (id, member) => {
        if (id !== null){
            categorySelect(id, member);
            setIsOpen(false);
        }
    };

    const handleCategorySelectConfirm = () => {
        if (selectedCategoryId !== null){
            categorySelect(selectedCategoryId, selectedCategory);
            setIsOpen(false);
        }
    };

    const preparedCategories = isCategoryTreePresent && prepareCategories(categoriesToDisplay, '', 0);

    return isCategoryTreePresent ? (
        <Modal
            className={clsx('text-center modal-lg')}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            header={translations.header}
            onConfirm={handleCategorySelectConfirm}
            body={
                <React.Fragment>
                    <div className={styles.controlsView}>
                        <span>{translations.dropdownSelector.label}</span>
                            <Dropdown
                                options={categoriesDisplayOptions}
                                currentSelectionLabel={translations.dropdownSelector.options[categoriesDisplayOption]}
                                performSelection={setCategoriesToDisplayOption}
                            />
                    </div>
                    <span>{(caption || translations.caption)}</span>
                    <div className={clsx('mx-auto my-3')}>
                        <CategorySelect
                            categories={preparedCategories}
                            handleSelectionChanged={handleCategoryChanged}
                            handleSelectionConfirmed={handleCategorySelect}/>
                    </div>
                </React.Fragment>
            }
            buttons={{
                confirm: {
                    label: translations.selectButtonLabel,
                    color: 'primary'
                },
                cancel: {
                    label: translations.cancelButtonLabel,
                    color: 'secondary'
                }
            }}
        />
    ) : null;
};

CategorySelectModal.propTypes = propTypes;

const mapStateToProps = state => ({
    categoryTree: state.categories.categoryTree,
    currentLanguage: selectStoreCurrentLanguage(state),
    allLanguages: selectStoreAvailableIsoList(state),
    isCategoryTreePresent: selectIsCategoryTreePresent(state)
});

const mapDispatch = {
    getCategoryTree
};

export default connect(mapStateToProps, mapDispatch)(CategorySelectModal);