import React from 'react';

import PaymentFormsTable from '../../../components/tables/PaymentFormsTable';

const PaymentMethodsSettings = () => {
    return (
        <PaymentFormsTable/>
    );
};

export default PaymentMethodsSettings;