import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getIntegrationRecaptcha, updateIntegrationRecaptcha } from '../../../redux/methods/integrations';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';

import { Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';

const propTypes = {
    getIntegrationRecaptcha: PropTypes.func,
    updateIntegrationRecaptcha: PropTypes.func,
};

const Recaptcha = ({ getIntegrationRecaptcha, updateIntegrationRecaptcha }) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.recaptcha;

    const [ data ] = useFetch(getIntegrationRecaptcha);

    return data ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-google'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateIntegrationRecaptcha}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>
                                <div className='my-2'><FormFieldSwitchPill label={translations.labels.enabled} name='enabled' /></div>
                                <div className='row'>
                                    <div className='col-12 col-xl-6'>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.siteKey} name='siteKey' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.secretKey} name='secretKey' /></div>
                                    </div>
                                </div>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconClass='fa fa-google'/>;
};

const mapDispatch = {
    getIntegrationRecaptcha,
    updateIntegrationRecaptcha,
};

Recaptcha.propTypes = propTypes;

export default connect(null, mapDispatch)(Recaptcha);