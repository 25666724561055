import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    defaultLanguage: 'pl',
    isFetched: false,
    availableLanguages: []
};

const locale = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setDefaultLanguage(state, action) {
            const { defaultLanguage } = action.payload;

            state.defaultLanguage = defaultLanguage;
        },
        setIsFetched(state, action) {
            const { isFetched } = action.payload;

            state.isFetched = isFetched;
        },
        setAvailableLanguages(state, action) {
            const { availableLanguages } = action.payload;

            state.availableLanguages = availableLanguages;
        }
    }
});

export const { setDefaultLanguage, setIsFetched, setAvailableLanguages } = locale.actions;

export default locale.reducer;