import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Container, Button, Row } from 'reactstrap';

import { getCategoryFormFields, deleteCategoryFormField } from '../../../../redux/methods/categories';
import useFetch from '../../../../hooks/useFetch';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import TableGenerator from '../../../../components/generators/TableGenerator';
import EditModal from './EditModal';

const propTypes = {
    getCategoryFormFields:  PropTypes.func,
    deleteCategoryFormField:  PropTypes.func
};

const CategoryEditFormFields = ({
    categoryId,
    getCategoryFormFields,
    deleteCategoryFormField,
    forceRefetch
}) => {
    const intl = useIntl();
    const translations = (intl).messages.categories.edit.tabs.formfields;

    const [ selectedId, setSelectedId ] = useState(null);

    const [ formFields ] = useFetch(getCategoryFormFields, categoryId, [], forceRefetch);

    const deleteAction = id => () => {
        deleteCategoryFormField({categoryId, id});
    };

    const editAction = id => () => {
        setSelectedId(id);
    };

    const addAction = () => {
        setSelectedId(-1);
    };

    const IsRequiredCell = item => (
        item.isRequired ? <i className="fa fa-check"/> : null
    );

    return formFields ? (
        <Container fluid>
            <TableGenerator
                items={formFields}
                actions={{deleteAction, editAction}}
                expectedActions={["delete", "edit"]}
                translations={{
                    tooltips: translations.tooltips,
                    emptyTable: translations.emptyTable,
                    deleteModal: translations.deleteModal,
                }}
                columns={[
                    {
                        header: translations.columns.name,
                        value: 'name',
                    },
                    {
                        header: translations.columns.isRequired,
                        value: 'isRequired',
                        type: 'custom',
                        customCell: IsRequiredCell
                    },
                    {
                        header: translations.columns.type,
                        value: 'type',
                    },
                    {
                        header: translations.columns.minimum,
                        value: 'minimum',
                    },
                    {
                        header: translations.columns.maximum,
                        value: 'maximum',
                    },
                    {
                        header: translations.columns.actions,
                        type: 'actions'
                    },
                ]}
            />
            <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button onClick={addAction} color="primary">
                    <i className="fa fa-plus mr-2"/>
                    <span>{translations.controls.add}</span>
                </Button>
            </Row>
            {selectedId && <EditModal
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                selectedCategoryId={categoryId}
                translations={translations}
            />}
        </Container>
    ) : null;
};

CategoryEditFormFields.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.categoryFormFields,
});

const mapDispatch = {
    getCategoryFormFields,
    deleteCategoryFormField
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(CategoryEditFormFields));