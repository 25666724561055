import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useFetch from '../../../hooks/useFetch';
import { getFillers, getFiller, addEditFiller, deleteFiller, uploadFillerImage, deleteFillerImage } from '../../../redux/methods/products';
import { withFormUtils } from '../../../hoc/withFormUtils';
import { productTileFillerEmptyObject } from '../../../config';

import ProductTileFillerEditionModal from '../ProductTileFillerEditionModal';

import { Container, Button } from 'reactstrap';
import ProductTileFillersTable from '../../../components/tables/ProductTileFillersTable';

const propTypes = {
    translations: PropTypes.object,
};

const ProductTileFiller = ({
    translations,
    getFillers,
    addEditFiller,
    deleteFiller,
    forceRefetch,
    utils,
    uploadFillerImage,
    deleteFillerImage,
    getFiller,
    forceRefetchEditedFiller
}) => {
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ selectedFillerId, setSelectedFillerId ] = useState(null);

    const [ fillers ] = useFetch(getFillers, null, [], forceRefetch);

    const onEditAction = (id) => {
        setSelectedFillerId(id);
        setIsModalOpen(true);
    };

    const addFiller = () => {
        addEditFiller(productTileFillerEmptyObject).then(newId => setSelectedFillerId(newId));
        setIsModalOpen(true);
    };

    const handleOnSubmit = (form) => {
        const objectToSend = {
            ...form,
            fillerId: form.id,
        };
        addEditFiller(objectToSend);
    };

    const handleImageUpload = (id, formData) => {
        uploadFillerImage(id, formData);
    };

    const handleOnSwitch = (id, isActive, item) => {
        const objectToSend = {
            ...item,
            fillerId: id,
            isActive: isActive,
        };
        addEditFiller(objectToSend);
    };

    return fillers ? (
        <Container fluid>
            <ProductTileFillersTable
                translations={translations.table}
                items={fillers}
                onEditAction={onEditAction}
                removeAction={deleteFiller}
                currentLanguage={utils.currentLanguage()}
                handleOnSwitch={handleOnSwitch}
            />
            <div className='w-100 d-flex justify-content-end'>
                <Button color="primary" onClick={addFiller}>
                    <i className="fa fa-plus mr-2" />
                    <span>{translations.addButton}</span>
                </Button>
            </div>
            {
                selectedFillerId &&
                    <ProductTileFillerEditionModal
                        fillerId={selectedFillerId}
                        translations={translations.editModal}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        utils={utils}
                        onSubmit={handleOnSubmit}
                        handleUpload={handleImageUpload}
                        handleRemove={deleteFillerImage}
                        getFiller={getFiller}
                        forceRefetch={forceRefetchEditedFiller}
                    />
            }
        </Container>
    ) : null;
};

ProductTileFiller.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.fillers,
    forceRefetchEditedFiller: state.refetchers.fillerToEdit,
});

const mapDispatch = {
    getFillers,
    addEditFiller,
    deleteFiller,
    uploadFillerImage,
    deleteFillerImage,
    getFiller,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ProductTileFiller));