import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { selectIsCategoryTreePresent } from '../../redux/selectors/categories';
import { getCategoryTree } from '../../redux/methods/categories';
import { getTranslationForIso } from '../../utils/shared';
import { getProductBasic } from '../../redux/methods/products';
import { selectStoreCurrentLanguage } from '../../redux/selectors/storeLocale';
import useFetch from '../../hooks/useFetch';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';
import MetatagsSection from '../../views/extrasSettings/MetatagsSection';
import ProductEditBasicSection from '../../features/products/editTabs/ProductEditBasicSection';
import ProductEditAdditionalCategories from '../../features/products/editTabs/ProductEditAdditionalCategories';
import ProductEditDescription from '../../features/products/editTabs/ProductEditDescription';
import ProductEditAttributes from '../../features/products/editTabs/ProductEditAttributes';
import ProductEditPricesSection from '../../features/products/editTabs/ProductEditPricesSection';

import { productsSearchConfigPropTypes } from '../../propTypes';

const propTypes = {
    location: PropTypes.object,
    searchConfig: productsSearchConfigPropTypes,
    isProductListPresent: PropTypes.bool,
    isCategoriesListPresent: PropTypes.bool,
    getCategoryTree: PropTypes.func,
    getProductBasic: PropTypes.func,
};

const OtherServicesEdit = ({
    location, isCategoriesListPresent, getCategoryTree, getProductBasic, forceRefetch,
    storeCurrentLanguage,
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit;

    const query = new URLSearchParams(location.search);
    const chosenProductId = query.get('serviceID');

    const [ data ] = useFetch(getProductBasic, chosenProductId, [ chosenProductId ], forceRefetch);

    useEffect(() => {
        if (!isCategoriesListPresent) {
            getCategoryTree();
        }
    }, []); // eslint-disable-line

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case "productEditTab-basic":
                return <ProductEditBasicSection id={chosenProductId} data={data} isService={data.productTypeId === 4} />;
            case "productEditTab-description":
                return <ProductEditDescription productId={chosenProductId}/>;
            case "productEditTab-metaTags":
                return <MetatagsSection forcedTag="ProductPage" forcedPageId={chosenProductId}/>;
            case "productEditTab-featuresAndAttributes":
                return <ProductEditAttributes id={chosenProductId} />;
            case "productEditTab-prices":
                return <ProductEditPricesSection id={chosenProductId} />;
            case "productEditTab-additionalCategories":
                return <ProductEditAdditionalCategories id={chosenProductId}/>;
            default:
                return null;
        }
    };

    return chosenProductId
        ? (
            data && isCategoriesListPresent ? (
                <SettingsTabulator
                    title={{
                        label: getTranslationForIso(data.name, storeCurrentLanguage),
                        iconClassName: "fa fa-edit text-primary ml-3"
                    }}
                    tabulatorHeader={{
                        label: translations.tabSelectorTitle,
                        iconClassName: "fa fa-align-justify"
                    }}
                    refreshTabComponent={refreshTabComponent}
                    tabs={[
                        {
                            id: "productEditTab-basic",
                            header:  {
                                label: translations.tabs.basic.title,
                                iconClassName: "fa fa-tasks"
                            }
                        },
                        {
                            id: "productEditTab-description",
                            header:  {
                                label: translations.tabs.description.title,
                                iconClassName: "fa fa-search"
                            }
                        },
                        {
                            id: "productEditTab-metaTags",
                            header:  {
                                label: translations.tabs.metaTags.title,
                                iconClassName: "fa fa-code"
                            }
                        },
                        {
                            id: "productEditTab-featuresAndAttributes",
                            header:  {
                                label: translations.tabs.featuresAndAttributes.title,
                                iconClassName: "fa fa-tags"
                            }
                        },
                        {
                            id: "productEditTab-prices",
                            header:  {
                                label: translations.tabs.prices.title,
                                iconClassName: "fa fa-money"
                            }
                        },
                        {
                            id: "productEditTab-additionalCategories",
                            header:  {
                                label: translations.tabs.additionalCategories.title,
                                iconClassName: "fa fa-clone"
                            }
                        }
                    ]}
                />
            ) : null
        ) : (
            <Redirect to="/otherServices/list"/>
        );
};

OtherServicesEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    isCategoriesListPresent: selectIsCategoryTreePresent(state),
    forceRefetch: state.refetchers.services,
    storeCurrentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
    getCategoryTree,
    getProductBasic
};

export default withRouter(connect(mapStateToProps, mapDispatch)(OtherServicesEdit));