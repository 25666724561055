const endOfSessionModal = {
    view: {
        title: 'Nastąpiło automatyczne wylogowanie',
        message: 'Zostałeś automatycznie wylogowany z powodu braku aktywnowści w panelu administracyjnym.',
        buttonLabel: 'ZALOGUJ SIĘ PONOWNIE'
    },
    modal: {
        title: 'Koniec sesji',
        bodyTitle: 'Twoja sesja zakończy się za',
        bodyFooter: 'Kliknij w przycisk, aby przedłużyć sesję.',
        buttonLabel: 'PRZEDŁUŻ SESJĘ',
        timeRemainingLabels: {
            firstStep: '5 minut do końca sesji',
            secondStep: '2 minuty do końca sesji'
        }
    }
};

export default endOfSessionModal;