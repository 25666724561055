const menuSettings = {
    menu: {
        title: 'Pozycje menu',
        tree: {
            activePositionsExceededAlert: 'Maksymalna liczba (10) aktywnych pozycji menu została osiągnięta.',
            upperMenu: 'Menu górne',
            bottomMenu: 'Menu dolne',
            nameFiller: 'BRAK NAZWY',
            sectionStatusTooltip: 'Sekcja widoczna w menu górnym sklepu',
            deleteModal: {
                header: 'Usuń sekcję',
                body: 'Czy napewno chcesz usunąć sekcję?',
                confirm: 'Usuń',
                cancel: 'Wróc'
            },
            actions: {
                delete: 'Usuń',
                edit: 'Edytuj',
                addSub: 'Dodaj podsekcję',
                addRoot: 'Dodaj sekcję'
            },
            warningModal: {
                header: 'Aktywuj sekcję',
                body: 'Aktywowanie sekcji spowoduje aktywację jej podsekcji. Czy chcesz kontynuować?',
                confirm: 'AKTYWUJ',
                cancel: 'WRÓĆ'
            }
        },
        edit: {
            captionAlert: 'Nazwa pozycji menu we wszystkich językach nie może pozostać pusta oraz nie może zawierać więcej niż 25 znaków. Popraw nazwę i zapisz zmiany.',
            title: 'Ustawienia sekcji',
            inputs: {
                enabled: 'Sekcja widoczna',
                type: 'Typ sekcji',
                name: 'Nazwa sekcji',
                selectCategoryLabel: 'Wybierz kategorię',
                urlLabel: 'Url',
                isOpeningInNewTabLabels: {
                    true: 'Otwórz w nowej karcie',
                    false: 'Otwórz w tej samej karcie'
                },
                customPage: 'Wybierz stronę własną',
                contactPageContent: 'Treść strony',
                selectCsutomPageLabel: 'Wybierz stronę własną',
                emptyCustomPageOptionLabel: 'Wybierz',
                statusTop: 'Aktywność w menu górnym',
                statusBottom: 'Aktywność w menu dolnym',
                redirectFromHomePage: 'Przekieruj ze strony głównej',
                columnLayout: 'Układ kolumnowy',
                file: {
                    edit: 'PODMIEŃ ZDJĘCIE',
                    remove: 'USUŃ ZDJĘCIE',
                    upload: 'PRZEŚLIJ ZDJĘCIE'
                },
                presentationLabel: 'Prezentacja zdjęcia'
            },
            typeOptions: {
                manufacturers: 'Lista producentów',
                category: 'Kategoria',
                container: 'Kontener',
                url: 'Link',
                customPage: 'Strona własna',
                selectTypeLabel: 'Wybierz typ sekcji',
                search: 'Wyszukiwanie'
            },
            presentation: {
                cover: 'Wypełnienie obszaru',
                contain: 'Dopasowanie do obszaru'
            }
        }
    },
    footer: {
        title: 'Menu dolne',
        sections: {
            photoWithText: {
                title: 'Zdjęcie z tekstem',
                inputs: {
                    showCaption: 'Widoczność sekcji',
                    showPhotoWithText: 'Pokazuj zdjęcie z tekstem',
                    leftContent: 'Treść prezentowana na tle zdjęcia',
                    fileSender: {
                        caption: 'Zdjęcie prezentowane jako tło sekcji',
                        upload: 'PRZEŚLIJ',
                        remove: 'USUŃ',
                        edit: 'EDYTUJ',
                    }
                }
            },
            newsletter: {
                title: 'Newsletter',
                inputs: {
                    showCaption: 'Widoczność sekcji',
                    showNewsletter: 'Pokazuj newsletter',
                    newsletterLink: 'Link do newslettera',
                    newsletterButtonContent: 'Treść przycisku',
                    newsletterContentUnderButton: 'Treść pod przyciskiem',
                    newsletterContent: 'Treść w sekcji newslettera',
                    newsletterColors: 'Kolory',
                    fontColor: 'Kolor czcionki',
                    backgroundColor: 'Kolor tła',
                    colorButton: 'Wybierz',
                },
                modalHeader: 'Link do newslettera',
                modalHeader2: 'Treść przycisku'
            },
            footer: {
                title: 'Stopka',
                inputs: {
                    systemFooter: 'Stopka systemowa',
                    customFooter: 'Stopka własna',

                    showCaption: 'Widoczność sekcji',
                    showMenu: 'Pokazuj menu',
                    menuOnLeft: 'Pokazuj menu po lewej stronie',
                    showSocialMedia: 'Pokazuj linki do social mediów',
                    showContactInfo: 'Pokazuj dane kontantowe',
                    showaAditionalInfo: 'Pokazuj dodatkowe informacje',

                    colorsCaption: 'Kolory',
                    socialMediaCaption: 'Linki do serwisów social media',
                    additionalInfoCaption: 'Dodatkowe informacje',
                    contactCaption: 'Dane kontaktowe',

                    backgroundColor: 'Kolor tła',
                    fontColor: 'Kolor czcionki',
                    customFooterContent: 'Treść stopki własnej',
                    colorButton: 'Wybierz',
                    footer: 'Stopka',

                    socialMedia: {
                        facebook: 'Facebook',
                        instagram: 'Instagram',
                        youTube: 'YouTube',
                        pinterest: 'Pinterest',
                        twitter: 'Twitter',
                        linkedIn: 'LinkedIn'
                    },
                },
            },
        },
    }
};

export default menuSettings;