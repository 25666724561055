import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectCategoryBreadcrumbsArray } from '../../../redux/selectors/categories';

import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    categoryBreadcrumbsArray: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired,
    location: PropTypes.object.isRequired
};

const CategoryBreadrumbs = ({ categoryBreadcrumbsArray }) => {
    const intl = useIntl();
    const rootLabel = intl.messages.categories.view.categoryBreadcrumbsRoot;

    return (
        <Breadcrumb className={styles.container}>
            <BreadcrumbItem>
                <Link to="/categories">{rootLabel}</Link>
            </BreadcrumbItem>
            {
                categoryBreadcrumbsArray.map((breadcrumb, i) => {
                    const isActive = i === categoryBreadcrumbsArray.length - 1 ? true : false;

                    return (
                        <BreadcrumbItem
                            key={`categoryBreadcrumb-${breadcrumb.id}`}
                            active={isActive}
                        >
                            { isActive ? breadcrumb.label : <Link to={`/categories?catID=${breadcrumb.id}`}>{breadcrumb.label}</Link>}
                        </BreadcrumbItem>
                    );
                })
            }
        </Breadcrumb>
    );
};

CategoryBreadrumbs.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => ({
    categoryBreadcrumbsArray: selectCategoryBreadcrumbsArray(ownProps.location.search)(state)
});

const mapDispatch = {

};

export default withRouter(connect(mapStateToProps, mapDispatch)(CategoryBreadrumbs));