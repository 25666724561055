const synchronizations = {
    titles: {
        main: 'Synchronizacje',
        erp: 'Erp',
        settings: 'Ustawienia',
        authentication: 'Autentykacja'
    },
    erp: {
        wapro: 'WAPRO Mag',
        modal: {
            header: 'Włączanie synchronizacji',
            body: 'Włączenie synchronizacji z ERP usunie twoje dotychczasowe zmiany. Czy chcesz kontynuować?',
            confirm: 'Włącz',
            cancel: 'Anuluj',
        }
    },
    settings: {
        labelsCategories: {
            main: 'Synchronizacja kategorii',
            false: 'Kategorie z ERP',
            true: 'Kategorie sklepowe',
        },
        labelsUnits: {
            main: 'Jednostki i miary',
            false: 'Jednostki z ERP',
            true: 'Jednostki Sklepowe',
        },
        unitPresentation: 'Typ jednostki prezentowanej w sklepie',
        labelsCategoriesLocation: {
            main: 'Synchronizacja położenia kategorii',
            false: 'Dla nowych kategorii',
            true: 'Zawsze',
            tooltip: 'Przy ustawieniu "Zawsze", synchronizowana jest zmiana położenia kategorii w węźle i pomiędzy węzłami dla nowych i obecnych kategorii. W przeciwnym razie położenie aktualnych kategorii nie ulega zmianie przy synchronizacji.'
        },
        unitPresentationTypes: {
            storage: 'Magazynowania',
            sales: 'Sprzedaży',
            reference: 'Referencyjna'
        },
        labelsPromotions: {
            main: 'Używane promocje',
            true: 'Promocje z WAPRO',
            false: 'Promocje sklepowe',
        },
        reserveItemsOnOrders: 'Rezerwuj pozycje na zamówieniach',
        addFlatCategoriesToDefaultCategory: 'Dodawaj kategorie płaskie do domyślnej kategorii'
    },
    authentication: {
        synchroApiToken: 'Token do synchronizacji z API',
        shopId: 'Id sklepu',
        generate: 'Generuj nowy token',
    },
};

export default synchronizations;