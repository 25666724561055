import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCurrencies, addCurrency, removeCurrency, updateCurrencyStatus } from '../../redux/methods/currencies';
import { generateEditLinkForCurrency } from '../../utils/currencies';
import useFetch from '../../hooks/useFetch';
import useAddFetch from '../../hooks/useAddFetch';

import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';

import CurrenciesTable from '../../components/tables/CurrenciesTable';

import styles from './index.module.scss';

const propTypes = {
    getCurrencies: PropTypes.func,
    addCurrency: PropTypes.func,
    removeCurrency: PropTypes.func,
    history: PropTypes.object.isRequired,
    forceRefetch: PropTypes.bool,
};

const Currencies = ({ getCurrencies, addCurrency, removeCurrency, history, forceRefetch, updateCurrencyStatus }) => {
    const intl = useIntl();
    const translations = intl.messages.currencies.view;

    const [ currencies ] = useFetch(getCurrencies, null, [], forceRefetch);
    const [ id, add ] = useAddFetch(addCurrency);

    const [ checkedCurrenciesIds, setCheckedCurrenciesIds ] = useState([]);

    useEffect(() => {
        if (id) {
            history.push(generateEditLinkForCurrency(id));
        }
    }, [history, id]);

    const handleAddCurrency = () => {
        add();
    };

    const handleRemoveCurrency = id => {
        removeCurrency(id);
    };

    return currencies ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody>
                                <main>
                                    <CurrenciesTable
                                        currencies={currencies}
                                        headerLabels={translations.headerLabels}
                                        currencyIsActiveText={translations.currencyIsActiveText}
                                        translations={translations}
                                        removeAction={handleRemoveCurrency}
                                        emptyPlaceholders={translations.emptyPlaceholders}
                                        history={history}
                                        updateAction={updateCurrencyStatus}
                                        checkedCurrenciesIds={checkedCurrenciesIds}
                                        setCheckedCurrenciesIds={setCheckedCurrenciesIds}
                                    />
                                </main>
                                <aside className={styles.footer}>
                                    <Button color='primary' onClick={handleAddCurrency}>
                                        <i className='fa fa-plus mr-2' />
                                        <span>{translations.actions.addCurrency}</span>
                                    </Button>
                                </aside>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ) : null;
};

Currencies.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.currencies,
});

const mapDispatch = {
    getCurrencies,
    addCurrency,
    removeCurrency,
    updateCurrencyStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Currencies));