export const pickBagdeColor = status => {
    switch(status){
        case 'storage': return 'success';
        case 'sales': return 'danger';
        case 'purchases': return 'primary';
        case 'reference': return 'warning';
        case 'additional': return 'secondary';
        default: return null;
    }
};

export const generateTypes = (typeEnums, translations) => {
    const generatedTypes = Object.keys(typeEnums).map(key => ({
        id: typeEnums[key],
        label: translations[key],
        color: pickBagdeColor(key),
        labelForBadge: translations[key]
    }));

    return generatedTypes;
};