import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object
};

const SessionTimeout= () => {
    const intl = useIntl();
    const translations = intl.messages.defaultLayout.endOfSession.view;

    const handleOnClick = () => {
        window.open('/login', '_top');
    };

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="10">
                        <Card>
                            <CardHeader className='bg-primary text-center' tag='h4'>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody className='d-flex flex-column align-items-center'>
                                <span className={styles.message}>{translations.message}</span>
                                <hr className={styles.divider}/>
                                <Button
                                    size='lg'
                                    color='primary'
                                    onClick={handleOnClick}
                                >
                                    {translations.buttonLabel}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

SessionTimeout.propTypes = propTypes;

export default withRouter(SessionTimeout);