import clsx from "clsx";

import { actionsEnum } from '../../enums/shareable';
import { generateLocalStringFromUTCForPicker, generateZuluDateStringFromLocal } from "../date";
import { getTranslationForIso } from '../shared';

export { generateDropdownOptionsForCurrencies } from "../discounts";
export { getTranslationForIso } from '../shared';
import getConfig from '../../getConfig';

export const detectProperPath = url => {
    const isAbsoluteUrl = url.startsWith('http');

    if(isAbsoluteUrl) {
        return url;
    } else {
        return `${getConfig().BACKEND_EXTERNAL_URL}${url}`;
    }
};

export const findConfigForBadge = (statusConfig, pageStatus) =>
    statusConfig.find(config =>
        config.id === pageStatus
    )
;

export const findConfigForBadgeByLabel = (statusConfig, pageStatus) =>
    statusConfig.find(config =>
        config.labelForBadge === pageStatus
    )
;

export const goBackToGeneralSection = (history) => {
    const generalSectionRoute = history.location.pathname.split('/');
    if(generalSectionRoute.length <= 3) {
        history.push(`/${generalSectionRoute[1]}`);
    }
    else {
        history.push(`/${generalSectionRoute[1]}/${generalSectionRoute[2]}`);
    }
};

export const filterItemsList = (itemsList, searchPhrase, labelName = 'label', getNameFunction = null) =>
    itemsList.filter(item => (getNameFunction ? getNameFunction(item) : item[labelName])?.toLowerCase().includes(searchPhrase.toLowerCase()));

export const pickActionForType = (type, actionsToCall) => {
    const { editAction, deleteAction, printAction, showDetailsAction } = actionsToCall;
    switch(type) {
        case actionsEnum.edit: return editAction;
        case actionsEnum.showDetails: return showDetailsAction;
        case actionsEnum.print: return printAction;
        case actionsEnum.delete: return deleteAction;
        default: return null;
    }
};

export const generateDropdownActions = (expectedActions, actionsToCall) => {
    return expectedActions.map(actionType => ({
        type: actionType,
        action: pickActionForType(actionType, actionsToCall),
    }));
};

export const makeIconClassNameForActionName = (actionName) => {
    switch(actionName){
        case actionsEnum.delete: return clsx("fa fa-trash-o fa-lg");
        case actionsEnum.edit: return clsx("fa fa-edit fa-lg");
        case actionsEnum.showDetails: return clsx("fa fa-eye fa-lg");
        case actionsEnum.print: return clsx("fa fa-print fa-lg");
        case actionsEnum.accept: return clsx("fa fa-check fa-lg");
        case actionsEnum.copy: return clsx("fa fa-copy fa-lg");
        default: return;
    }
};

export const pickActionWithParams = (type, actionsToCall, param, param2) => {
    const { editAction, deleteAction, printAction, showDetailsAction, acceptAction, copyAction } = actionsToCall;
    switch(type) {
        case actionsEnum.edit: return editAction(param, param2);
        case actionsEnum.showDetails: return showDetailsAction(param, param2);
        case actionsEnum.print: return printAction(param, param2);
        case actionsEnum.delete: return deleteAction(param, param2);
        case actionsEnum.accept: return acceptAction(param, param2);
        case actionsEnum.copy: return copyAction(param, param2);
        default: return null;
    }
};

export const generateCellActions = ({ expectedActions, editLink, translations, actions, item }, itemId ) => {
    let userActionCounter = 0;
    const { editAction } = { ...actions };
    const generatedActions = expectedActions.map(action => {
        if (typeof(action) === 'object') {
            const result = {};
            result[`user${userActionCounter++}`] = {
                icon: action.icon,
                tooltipTranslation: action.tooltip,
                disabled: () => { return action.disabled(item); },
                action: () => { action.execute(item); }
            };
            return result;
        } else {
            switch (action) {
                case actionsEnum.edit:
                {
                    const link = editLink && editLink(itemId, item);
                    if (link) {
                        return {
                            edit: {
                                action: editAction && pickActionWithParams(actionsEnum.edit, actions, itemId, item),
                                editLink: link,
                                tooltipTranslation: translations.tooltips.edit,
                            }
                        };
                    } else {
                        return {
                            edit: {
                                action: editAction && pickActionWithParams(actionsEnum.edit, actions, itemId, item),
                                tooltipTranslation: translations.tooltips.edit,
                            }
                        };
                    }
                }
                case actionsEnum.delete:
                    return {
                        delete: {
                            action: pickActionWithParams(actionsEnum.delete, actions, itemId, item),
                            tooltipTranslation: translations.tooltips.remove,
                            modalTranslations: translations.deleteModal,
                            item: item,
                        }
                    };
                case actionsEnum.print:
                    return {
                        print: {
                            action: pickActionWithParams(actionsEnum.print, actions, itemId, item),
                            tooltipTranslation: translations.tooltips.print,
                        }
                    };
                case actionsEnum.showDetails:
                    return {
                        showDetails: {
                            action: pickActionWithParams(actionsEnum.showDetails, actions, itemId, item),
                            tooltipTranslation: translations.tooltips.showDetails,
                        }
                    };
                case actionsEnum.accept:
                    return {
                        accept: {
                            action: pickActionWithParams(actionsEnum.accept, actions, itemId, item),
                            tooltipTranslation: translations.tooltips.accept,
                        }
                    };
                case actionsEnum.copy:
                    return {
                        copy: {
                            action: pickActionWithParams(actionsEnum.copy, actions, itemId, item),
                            tooltipTranslation: translations.tooltips.copy,
                        }
                    };
                default:
                    return null;
            }
        }
    });

    return generatedActions.reduce((object, item) => ({
        ...object,
        ...item
    }), {});
};

export const convertBoolToNumericValue = (bool) => {
    return bool ? 1 : 0;
};

export const filterItemWithIds = (items, ids) => {
    return items.filter(item => ids.includes(item.id));
};

export const firstLetterToLowerCase = (string) => `${string.charAt(0).toLowerCase()}${string.slice(1)}`;

export const firstLetterToUpperCase = (string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};

export const convertDate = dateToConvert => new Date(Date.parse(dateToConvert)).toDateString();

export const convertToLocaleDate = dateToConvert => new Date(dateToConvert).toLocaleDateString();

export const convertToLocaleDateTime = dateToConvert => dateToConvert ? new Date(dateToConvert).toLocaleString() : null;

export const convertToLocaleTime = dateToConvert => new Date(dateToConvert).toLocaleTimeString();

export const handleCheckboxAction = (idToCheck, checkedTab, setCheckedTab) => {
    const newCheckedTab = checkedTab.includes(idToCheck)
        ? checkedTab.filter(item => item !== idToCheck)
        : [...checkedTab, idToCheck]
    ;
    setCheckedTab(newCheckedTab);
};

export const generateTranslationKey = (propName, iso) => `${propName}-${firstLetterToUpperCase(iso)}-translations`;

export const generateFormInitialValues = (data, { translations, imageAlignments, date, shipmentDiscounts, shipmentVisibilityLevels }, availableIsos) => {
    const noModifiedKeys =
        Object.keys(data).reduce((acc, cur) =>
            (translations && translations.includes(cur)) ||
            (imageAlignments && imageAlignments.includes(cur)) ||
            (date && date.includes(cur)) ||
            (shipmentDiscounts && shipmentDiscounts.includes(cur)) ||
            (shipmentVisibilityLevels && shipmentVisibilityLevels.includes(cur))
                ? acc
                : { ...acc, [cur]: data[cur]},
            {});

    const translationsGeneratedFields = translations && translations.reduce((acc, propName) => ({
        ...acc,
        ...availableIsos.reduce((acc, iso) => {
            const foundedTranslation = data[propName] && data[propName].find(translation => translation.iso !== null && translation.iso.toLowerCase() === iso.toLowerCase());

            return {
                ...acc,
                [generateTranslationKey(propName, iso)]: foundedTranslation ? foundedTranslation.value : ''
            };
        }, {})
    }), {});

    const imageAlignmentsGeneratedFields = imageAlignments && imageAlignments.reduce((acc, propName) => ({
        ...acc,
        ...data[propName].reduce((acc, el) => {
            return {
                ...acc,
                [el.type.toLowerCase()+'-horizontalAlignment']: el.horizontalAlignment,
                [el.type.toLowerCase()+'-verticalAlignment']: el.verticalAlignment
            };
        }, {})
    }), {});

    const dateGeneratedFields = date && date.reduce((acc, propName) => ({
        ...acc,
        [propName]: data[propName] ? generateLocalStringFromUTCForPicker(data[propName]) : null,
    }), {});

    const shipmentDiscountsGeneratedFields = shipmentDiscounts && shipmentDiscounts.reduce((acc, propName) => ({
        ...acc,
        ...data[propName].reduce((acc, el) => {
            return {
                ...acc,
                [propName + '-' + el.currencyId + '-discountId']: el.discountId,
                [propName + '-' + el.currencyId + '-active']: el.active,
                [propName + '-' + el.currencyId + '-amountLimit']: el.amountLimit,
            };
        }, {})
    }), {});

    const shipmentVisibilityLevelsGeneratedFields = shipmentVisibilityLevels && shipmentVisibilityLevels.reduce((acc, propName) => ({
        ...acc,
        ...data[propName].reduce((acc, el) => {
            return {
                ...acc,
                [propName + '-' + el.currencyId + '-minimumOrderValue']: el.minimumOrderValue,
                [propName + '-' + el.currencyId + '-maximumOrderValue']: el.maximumOrderValue,
            };
        }, {})
    }), {});

    return {...noModifiedKeys, ...translationsGeneratedFields, ...imageAlignmentsGeneratedFields, ...dateGeneratedFields, ...shipmentDiscountsGeneratedFields, ...shipmentVisibilityLevelsGeneratedFields };
};

export const generateFormToSubmitValues = (data, { translations, imageAlignments, dateInputs, date, shipmentDiscounts, shipmentVisibilityLevels }, availableIsos) => {

    const noModifiedKeys = Object.keys(data).reduce((acc, currentKey) => {
        const translationsIncludesCurrentKey = translations && translations.includes(currentKey.slice(0, currentKey.indexOf('-')));
        const imageAlignmentsIncludesCurrentKey = imageAlignments && ['banner','tile'].includes(currentKey.slice(0, currentKey.indexOf('-')));
        const dateInputsIncludesCurrentKey = dateInputs && dateInputs.includes(currentKey);
        const dateZulu = date && date.includes(currentKey);
        const shipmentDiscountsIncludesCurrentKey = shipmentDiscounts && shipmentDiscounts.includes(currentKey.slice(0, currentKey.indexOf('-')));
        const shipmentVisibilityLevelsIncludesCurrentKey = shipmentVisibilityLevels && shipmentVisibilityLevels.includes(currentKey.slice(0, currentKey.indexOf('-')));

        return (
            translationsIncludesCurrentKey ||
            imageAlignmentsIncludesCurrentKey ||
            dateInputsIncludesCurrentKey ||
            dateZulu ||
            shipmentDiscountsIncludesCurrentKey ||
            shipmentVisibilityLevelsIncludesCurrentKey
                ? acc
                : { ...acc, [currentKey]: data[currentKey]});
    }, {});

    const translationsProps = translations && translations.reduce((acc, propName) => ({
        ...acc,
        [propName]: availableIsos.map(iso => {
            const foundField = data[generateTranslationKey(propName, iso)];

            return { iso: firstLetterToUpperCase(iso), value: foundField ? foundField : '' };
        })
    }), {});

    const alignmentsProps = imageAlignments && imageAlignments.reduce((acc, propName) => ({
        ...acc,
        [propName]: [
            {
                type: "Tile",
                horizontalAlignment: data['tile-horizontalAlignment'],
                verticalAlignment: data['tile-verticalAlignment']
            },
            {
                type: "Banner",
                horizontalAlignment: data['banner-horizontalAlignment'],
                verticalAlignment: data['banner-verticalAlignment']
            }
        ]
    }), {});

    const dates = dateInputs && dateInputs.reduce((acc, propName) => ({ ...acc, [propName]: data[propName] ? convertDate(data[propName]) : null }), {});

    const datesZulu = date && date.reduce((acc, propName) => ({ ...acc, [propName]: data[propName] ? generateZuluDateStringFromLocal(data[propName]) : null }), {});

    const discounts = shipmentDiscounts && shipmentDiscounts.reduce((acc, propName) => {

        const availableCurrencies = [...new Set(
            Object.keys(data)
                .filter(key => key.startsWith(propName+'-'))
                .map(key => key.substr(propName.length + 1))
                .map(key => key.substr(0,key.indexOf('-')))
                .map(key => parseInt(key)))
            ];

        return ({
            ...acc,
            [propName]: availableCurrencies.reduce((acc, currencyId) => {
                return acc.concat([{
                    currencyId: currencyId,
                    discountId: data[`${propName}-${currencyId}-discountId`],
                    active: data[`${propName}-${currencyId}-active`],
                    amountLimit: data[`${propName}-${currencyId}-amountLimit`]
                }]);
            }, [])
        });
    }, {});

    const visibilityLevels = shipmentVisibilityLevels && shipmentVisibilityLevels.reduce((acc, propName) => {

        const availableCurrencies = [...new Set(
            Object.keys(data)
                .filter(key => key.startsWith(propName+'-'))
                .map(key => key.substr(propName.length + 1))
                .map(key => key.substr(0,key.indexOf('-')))
                .map(key => parseInt(key)))
            ];

        return ({
            ...acc,
            [propName]: availableCurrencies.reduce((acc, currencyId) => {
                return acc.concat([{
                    currencyId: currencyId,
                    minimumOrderValue: data[`${propName}-${currencyId}-minimumOrderValue`],
                    maximumOrderValue: data[`${propName}-${currencyId}-maximumOrderValue`],
                }]);
            }, [])
        });
    }, {});

    return {...noModifiedKeys, ...translationsProps, ...alignmentsProps, ...dates, ...datesZulu, ...discounts, ...visibilityLevels };
};

export const getIsoFromTranslation = item => item.slice(item.indexOf('-') + 1, item.lastIndexOf('-'));

export const getTranslationFromEnum = (translations, config) => (value) => translations[config[value]];

export const generateDropDownOptionsForEnum = (enumType, translation, addEmptyElement = true) => {
    const array = addEmptyElement ? [{ id: null, label: translation.placeholder }] : [];
    for(const prop in enumType){
        array.push({ id: enumType[prop], label: translation[prop]});
    }
    return array;
};

export const translateEnumValue = (enumType, translation, value) => {
    for(const prop in enumType){
        if (enumType[prop] === value){
            return translation[prop];
        }
    }
};

export const isValueDecimal = value => value % 1 !== 0;

export const displayTextOrNumberInCell = (value, additionalValue) => {
    if(Array.isArray(value)) {
        return value.join(', ');
    }
    return isNaN(value) || !isValueDecimal(value)
        ? [value, additionalValue].join(' ')
            : isValueDecimal(value)
                // eslint-disable-next-line no-undefined
                && [value.toLocaleString(undefined, {'minimumFractionDigits':2, 'maximumFractionDigits':2}), additionalValue].join(' ');
};

export const convertToMs = (value) => parseFloat(value) * 60000;

export const generateNonIdsOptionsForDropdown = (fields, translation) => {
    return fields.map(field => ({
        id: field,
        label: translation ? translation[field] : field,
    }));
};

export const generateDropdownOptionsForTags = (tags, iso, emptyTranslation) => {
    const emptyElement = emptyTranslation ? { id: null, label: emptyTranslation } : {};
    return ([
        emptyElement,
        ...tags.map(tag => ({
            id: tag.attributeDictionaryId,
            label: getTranslationForIso(tag.name, iso)
        }))
    ].filter(tag => tag.label));
};

export const generateDropdownOptionsForTagsTab = (tags, iso) => {
    return tags.map(tag => ({
        id: tag.attributeDictionaryId,
        label: getTranslationForIso(tag.name, iso)
    }));
};

export const targetTranslatedContent = (name, iso = 'Pl') => {
    return `${name}-${firstLetterToUpperCase(iso)}-translations`;
};