import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedDate, FormattedTime  } from 'react-intl';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createNotificationToken, getLatestNotifications, getNotificationDetails, greyOutNotification, setIsDropdownOpen, deleteNotification } from '../../../redux/methods/notifications';

import { notificationsModulesColorsConfig, notificationRefetchTimeoutConfig } from '../../../config';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';
import NotificationModal from '../../../components/modals/NotificationModal';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object,
    createNotificationToken: PropTypes.func,
    getLatestNotifications: PropTypes.func,
    getNotificationDetails: PropTypes.func,
    greyOutNotification: PropTypes.func,
    forceRefetch: PropTypes.bool,
    isDropdownOpen: PropTypes.bool,
    setIsDropdownOpen: PropTypes.func,
    deleteNotification: PropTypes.func,
};

const HeaderNotificationsController = ({
    history,
    createNotificationToken,
    getLatestNotifications,
    getNotificationDetails,
    greyOutNotification,
    forceRefetch,
    isDropdownOpen,
    setIsDropdownOpen,
    deleteNotification,
}) => {
    const intl = useIntl();
    const translations = intl.messages.notifications.headerController;

    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ selectedNotification, setSelectedNotification ] = useState(null);
    const [ notifications, setNotifications ] = useState([]);
    const [ notificationDetails, setNotificationDetails ] = useState(null);
    const [ tokenRefetch, setTokenRefetch ] = useState(false);
    const [ initialFetching, setInitialFetching ] = useState(true);

    useEffect(() => {
        const token = sessionStorage.getItem('notificationToken');
        const tokenExpiration = sessionStorage.getItem('tokenExpiration');
        const isTokenValid = Date.parse(tokenExpiration) > Date.parse(new Date());

        //check if token exist and if it's valid, if not generate token
        if ((token && isTokenValid && initialFetching) || (token && isTokenValid && !initialFetching && forceRefetch)) {
            getLatestNotifications(token)
                .then(result => {
                    setNotifications(result.payload);
                    setInitialFetching(false);
                });
        }
        else if (!token || !isTokenValid) {
            createNotificationToken()
                .then(result => {
                    sessionStorage.setItem('notificationToken', result.token);
                    sessionStorage.setItem('tokenExpiration', result.expiration);
                    setTokenRefetch(!tokenRefetch);
                });
        }

        //set Timeout to refetch notifications
        const tokenRefetchTimeout = setTimeout(() => {
            setTokenRefetch(!tokenRefetch);
        }, notificationRefetchTimeoutConfig);

        return () => {
            clearTimeout(tokenRefetchTimeout);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ forceRefetch, tokenRefetch ]);

    useEffect(() => {
        if(selectedNotification) {
            getNotificationDetails(selectedNotification)
                .then(details => setNotificationDetails(details.payload));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ selectedNotification ]);

    const toggleIsOpen = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleNotificationsRedirect = () => {
        setIsModalOpen(false);
        history.push('/notifications');
    };

    const handleSelectNotification = (id) => () => {
        setSelectedNotification(id);
        greyOutNotification(id);
        setIsModalOpen(true);
    };

    const handleRemove = (e, id) => {
        e.stopPropagation();
        deleteNotification(id);
    };

    const countUnreadNotifications = notifications => {
        return notifications.filter(notification => !notification.grayedOut).length;
    };

    const moduleRedirectHandler = () => {
        setIsModalOpen(false);
        history.push(notificationDetails.actionUrl);
    };

    const NotificationItem = ({
        item
    }) => {
        return (
            <div className={styles.dropdownItemContainer}>
                <div
                    className='d-flex flex-column justify-content-between w-100 h-100'
                    onClick={handleSelectNotification(item.id)}
                >
                    <Badge pill color={notificationsModulesColorsConfig[item.moduleId]} className={styles.moduleBadge}>{item.module}</Badge>
                    <span className='mr-3'>{item.simpleDescription}</span>
                    <span className={'my-1'}>
                        <span className='mr-1'>
                            <FormattedTime value={item.date} />
                        </span>
                        <FormattedDate value={item.date} day='2-digit' month='2-digit' year='numeric' />
                    </span>
                </div>
                {
                    !item.grayedOut
                        &&  <div onClick={e => handleRemove(e, item.id)}>
                                <i className={clsx('fa fa-times', styles.removeIcon)} />
                            </div>
                }
            </div>
        );
    };

    return (
        <div className={clsx(styles.container)}>
            <Dropdown isOpen={isDropdownOpen} toggle={toggleIsOpen}>
                <DropdownToggle className={styles.dropdownToggle}>
                    {
                        countUnreadNotifications(notifications) > 0 && (
                            <div className={styles.numberContainer}>
                                <span className={styles.number}>{countUnreadNotifications(notifications)}</span>
                            </div>
                            )
                    }
                    <i className={clsx("fa fa-bell", styles.icon)} />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>{translations.unreadNotes}{countUnreadNotifications(notifications)}</DropdownItem>
                    {
                        notifications.map((notification, i) => {
                            return (
                                <DropdownItem
                                    key={`notificationItem-${i}`}
                                    className={clsx(notification.grayedOut && styles.readedItem)}
                                >
                                    <NotificationItem item={notification} />
                                </DropdownItem>
                            );
                        })
                    }
                    <DropdownItem className='font-weight-bolder' onClick={handleNotificationsRedirect}>
                        {translations.displayAll}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            {
                notificationDetails &&
                    <NotificationModal
                        translations={translations}
                        isOpen={isModalOpen}
                        setIsOpen={setIsModalOpen}
                        notification={notificationDetails}
                        handleSubmit={moduleRedirectHandler}
                />
            }
        </div>
    );
};

HeaderNotificationsController.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.notifications,
    isDropdownOpen: state.notifications.isNotificationDropdownOpen
});

const mapDispatch = {
    createNotificationToken,
    getLatestNotifications,
    getNotificationDetails,
    greyOutNotification,
    setIsDropdownOpen,
    deleteNotification
};

export default withRouter(connect(mapStateToProps, mapDispatch)(HeaderNotificationsController));