const mediaLibrary = {
    title: 'Biblioteka mediów',
    search: 'Wyszukaj po nazwie pliku...',
    firstBreadcump: '...',
    blueFolderWarning: 'W tym folderze nie można umieszczać plików przesłanych za pośrednictwem biblioteki mediów. Prezentowane są tutaj pliki przesłane przy pomocy formularzy znajdujących się w innych częściach panelu administracyjnego.',
    buttons: {
        add: 'DODAJ PLIKI',
        mode: {
            edit: 'WŁĄCZ TRYB ZAZNACZANIA',
            normal: 'WŁĄCZ TRYB NORMALNY',
        },
        selectAll: 'Zaznacz wszystkie',
        unselectAll: 'Odznacz wszystkie',
        deleteAll: 'USUŃ ZAZNACZONE',
        clear: 'Wyczyść',
        search: 'Szukaj',
    },
    modal: {
        header: 'Szczegóły załączonego pliku',
        date: 'Data wysłania na serwer',
        author: 'Kto wgrał',
        type: 'Typ pliku',
        size: 'Wielkość',
        resolution: 'Wymiary',
        oryginalName: 'Nazwa oryginalna',
        folder: 'Ścieżka',
        remove: 'USUŃ NA ZAWSZE',
        form: {
            name: 'Nazwa',
            alternativeText: 'Tekst alternatywny',
            title: 'Tytuł',
            description: 'Opis',
            save: 'ZAPISZ',
            html: 'Html',
            link: 'Link do pliku',
        },
    },
    searchForm: {
        filters: {
            all: 'Wszystkie',
            images: 'Obrazki',
            video: 'Filmy',
            other: 'Inne',
        },
        fileName: 'Nazwa pliku',
        mediaType: 'Typ pliku',
        buttons: {
            submit: 'SZUKAJ',
            reset: 'RESETUJ'
        }
    }
};

export default mediaLibrary;