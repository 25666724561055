import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';

import DateTimePicker from 'react-datetime-picker';

import styles from './index.module.scss';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
};

const FormFieldWithCalendarAndTimePicker = ({ name, label, isDisabled }) => {
    return (
        <Field name={name}>
            {
                props => {
                    return <span className={styles.calendarInput}>
                        <span>{label}</span>
                        <DateTimePicker
                            value={props.input.value ? new Date(props.input.value) : null}
                            onChange={props.input.onChange}
                            disabled={isDisabled}
                            clearIcon={<i className="fa fa-remove"/>}
                            calendarIcon={<i className="fa fa-calendar"/>}
                            nextLabel={<i className="fa fa-step-forward fa-lg"/>}
                            next2Label={<i className="fa fa-arrow-right fa-lg"/>}
                            prevLabel={<i className="fa fa-step-backward fa-lg"/>}
                            prev2Label={<i className="fa fa-arrow-left fa-lg"/>}
                            disableClock
                        />
                    </span>;}
            }
        </Field>
    );
};

FormFieldWithCalendarAndTimePicker.propTypes = propTypes;

export default FormFieldWithCalendarAndTimePicker;