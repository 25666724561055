import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateDiscountProducts } from '../../../../../redux/methods/discounts';

import { generateTranslatedProducts } from '../../../../../utils/discounts';

import ProductWithDeleteActionTable from '../../../../../components/tables/ProductWithDeleteActionTable';
import ProductSearchForm from '../../../../../components/ProductSearchForm';
import ProductSearchModal from '../../../../../features/products/ProductSearchModal';
import SectionAsideButtons from '../../../../../components/SectionAsideButtons';
import Paginator from '../../../../../components/Paginator';

import { stringOrNumberPropTypes, productTablePropTypes } from '../../../../../propTypes';

import { withFormUtils } from '../../../../../hoc/withFormUtils';

const propTypes = {
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    discountsProducts: PropTypes.arrayOf(productTablePropTypes),
    updateDiscountProducts: PropTypes.func,
    discountToEditId: stringOrNumberPropTypes,
    isActiveLabelLeft: PropTypes.bool,
    categoriesIsPresent: PropTypes.bool,
    storeChosenLanguage: PropTypes.string,
    storeDefaultLanguage: PropTypes.string,
    changePage: PropTypes.func,
    changeSearchConfig: PropTypes.func,
    productsSearchConfig: PropTypes.object,
};

const ProductsSection = ({ utils,
    totalPages, currentPage, discountsProducts, updateDiscountProducts, discountToEditId, isActiveLabelLeft, categoriesIsPresent,
    changePage, changeSearchConfig, storeChosenLanguage, storeDefaultLanguage, productsSearchConfig,
}) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.categoriesProducts;

    const [checkedProductsIds, setCheckedProductsIds] = useState([]);
    const [checkedProductToAssignIds, setCheckedProductToAssignIds] = useState([]);

    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [isSearchServiceModalOpen, setIsSearchServiceModalOpen] = useState(false);
    const [isSearchShipmentModalOpen, setIsSearchShipmentModalOpen] = useState(false);

    useEffect(() => {
        return () => {
            setCheckedProductsIds([]);
            setCheckedProductToAssignIds([]);
            setIsSearchModalOpen(false);
            setIsSearchServiceModalOpen(false);
            setIsSearchShipmentModalOpen(false);
        };
    }, [discountsProducts]);

    const handleRemoveSingleProducts = id => {
        updateDiscountProducts(discountToEditId, [id], true, isActiveLabelLeft);
        changePage(0);
    };

    const handleAssignProducts = () => {
        updateDiscountProducts(discountToEditId, checkedProductToAssignIds, false, isActiveLabelLeft);
    };

    const handleOpenProductsModal = () => {
        setIsSearchModalOpen(true);
    };

    const handleOpenServiceModal = () => {
        setIsSearchServiceModalOpen(true);
    };

    const handleOpenShipmentModal = () => {
        setIsSearchShipmentModalOpen(true);
    };

    const handleOpenUnassignModal = () => {
        utils.confirm(
            () => {
                updateDiscountProducts(discountToEditId, checkedProductsIds, true, isActiveLabelLeft);
                changePage(0);
            },
            translations.deleteModalProducts.header,
            translations.deleteModalProducts.body
        );
    };

    const productsSectionButtons = [
        {
            location: 'left',
            label: translations.buttons.products.remove,
            action: handleOpenUnassignModal,
            isDisabled: checkedProductsIds.length === 0
        },
        {
            location: 'right',
            label: translations.buttons.products.addShipment,
            action: handleOpenShipmentModal,
            isDisabled: isActiveLabelLeft && !categoriesIsPresent
        },
        {
            location: 'right',
            label: translations.buttons.products.addService,
            action: handleOpenServiceModal,
            isDisabled: isActiveLabelLeft && !categoriesIsPresent
        },
        {
            location: 'right',
            label: translations.buttons.products.addArticle,
            action: handleOpenProductsModal,
            isDisabled: isActiveLabelLeft && !categoriesIsPresent
        }
    ];

    const language = storeChosenLanguage || storeDefaultLanguage;
    const translatedProducts = generateTranslatedProducts(discountsProducts, language);

    return (
        <React.Fragment>
            <span className={clsx('d-block mb-2 font-weight-bold', isActiveLabelLeft ? 'text-danger' : 'text-success')}>
                    {isActiveLabelLeft ? translations.headers.products.unassign : translations.headers.products.assign}
            </span>
            <ProductSearchForm
                performProductSearch={changeSearchConfig}
                searchConfig={productsSearchConfig}
                additionalDefaultSearchConfig={{ discountId: discountToEditId }}
            />
            <ProductWithDeleteActionTable
                translatedProductsList={translatedProducts}
                checkedProductsId={checkedProductsIds}
                setCheckedProductsId={setCheckedProductsIds}
                removeAction={handleRemoveSingleProducts}
                fillers={translations.fallbacks}
                isRedOnHover={isActiveLabelLeft}
                deleteModal={translations.deleteModalProduct}
                tooltipTranslation={translations.tooltipTranslation}
            />
            {
                totalPages > 1 &&
                    <Paginator
                        totalPages={totalPages}
                        currentPage={currentPage + 1}
                        onPageChange={changePage}
                    />
            }
            <SectionAsideButtons
                buttons={productsSectionButtons}
            />

            <ProductSearchModal
                isOpen={isSearchModalOpen}
                setIsOpen={setIsSearchModalOpen}
                checkedProductsId={checkedProductToAssignIds}
                setCheckedProductsId={setCheckedProductToAssignIds}
                onConfirm={handleAssignProducts}
                additionalSearchConfig={{ productTypeId: 1 }}
            />

            <ProductSearchModal
                isOpen={isSearchServiceModalOpen}
                setIsOpen={setIsSearchServiceModalOpen}
                checkedProductsId={checkedProductToAssignIds}
                setCheckedProductsId={setCheckedProductToAssignIds}
                onConfirm={handleAssignProducts}
                additionalSearchConfig={{ productTypeId: 4 }}
            />

            <ProductSearchModal
                isOpen={isSearchShipmentModalOpen}
                setIsOpen={setIsSearchShipmentModalOpen}
                checkedProductsId={checkedProductToAssignIds}
                setCheckedProductsId={setCheckedProductToAssignIds}
                onConfirm={handleAssignProducts}
                additionalSearchConfig={{ productTypeId: 2 }}
            />
        </React.Fragment>
    );
};

ProductsSection.propTypes = propTypes;

const mapStateToProps = state => ({
    discountToEditId: state.discounts.discountToEdit.id,
    storeChosenLanguage: state.persisted.storeLocale.storeChosenLanguage,
    storeDefaultLanguage: state.persisted.storeLocale.storeDefaultLanguage,
});

const mapDispatch = {
    updateDiscountProducts,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ProductsSection));
