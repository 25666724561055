import React from 'react';
import PropTypes from 'prop-types';

import Rating from '@material-ui/lab/Rating';

const propTypes = {
    value: PropTypes.number,
    className: PropTypes.string,
};

const RatingCell = ({ value, className }) => {
    return (
        <td className={className}>
            <Rating name="read-only" value={value} readOnly />
        </td>
    );
};

RatingCell.propTypes = propTypes;

export default RatingCell;