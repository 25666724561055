import React from 'react';

import clsx from 'clsx';
import { useIntl } from 'react-intl';

import LanguageDropdown from '../../../../../features/locale/LanguageDropdown';
import StoreLanguageDropdown from '../../../../../features/storeLocale/StoreLanguageDropdown';
import ShopRedirectButton from '../../../../ShopRedirectButton';

import styles from './index.module.scss';
import ErpSyncButton from '../../../../ErpSyncButton';
import LastSyncDate from '../../../../../features/global/LastSyncDate';

const SettingsTab = () => {
    const intl = useIntl();
    const translations = intl.messages.defaultLayout.appAside.tabs.settings;

    return (
        <React.Fragment>
            <ShopRedirectButton className="d-lg-none my-2"/>
            <ErpSyncButton className="d-lg-none my-2" />
            <LastSyncDate className='d-flex d-xl-none my-2' />
            <h6>{translations.title}</h6>
            <div className={clsx("aside-options", styles.option, styles.controls)}>
                <small><b>{translations.language.label}</b></small>
                <LanguageDropdown isAuthorized/>
            </div>

            <div className={clsx("aside-options d-lg-none", styles.option, styles.controls)}>
                <small><b>{translations.language.storeLabel}</b></small>
                <StoreLanguageDropdown />
            </div>
        </React.Fragment>
    );
};

export default SettingsTab;