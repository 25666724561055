const features = {
    view: {
        title: 'Features list',
        paginator: {
            previous: 'Previous',
            next: 'Next'
        },
        actions: {
            addFeature: 'ADD FEATURE'
        },
        categorySelectionModal: {
            body: 'Select category to which new feature should be assigned',
        }
    },
    searchForm: {
        phrase: {
            label: 'Feature name:',
            placeholder: ''
        },
        category: {
            label: 'Category:',
            placeholder: 'any'
        },
        type: {
            label: 'Feature type:',
            placeholder: 'any'
        },
        presentation: {
            label: 'Presentation:',
            placeholder: 'any'
        },
        filters: {
            attributeType: {
                placeholder: 'any',
                tag: 'Tag',
                dictionary: 'Dictionary',
                int: 'Number range',
                decimal: 'Decimal range',
                dateTime: 'Date range',
                color: 'Color'
            },
            attributePresentation: {
                placeholder: 'any',
                tag: 'Chmura tagów',
                color: 'Color',
                simpleDictionary: 'Simple dictionary',
                searchableDictionary: 'Searchable dictionary',
                range: 'Range'
            }
        },
        action: 'SEARCH',
        reset: 'RESET',
    },
    table: {
        headers: {
            name: 'Feature name',
            categories: 'Categories',
            type: 'Feature type',
            presentation: 'Presentation',
            actions: 'Actions'
        },
        deleteModal: {
            header: 'Feature:',
            body: 'Are you sure you want to remove feature',
            confirm: 'REMOVE',
            cancel: 'CANCEL'
        },
        tooltips: {
            edit: 'Edit feature',
            remove: 'Remove feature',
            add: 'ADD FEATURE'
        },
        empty: 'No features matching provided search criteria have been found.',
        nameFiller: '[NO NAME]',
        errorModalText: 'Error occured while trying to remove feature'
    },
    edit: {
        nameFiller: '[NO NAME]',
        tabSelectorTitle: 'Sections',
        tabs: {
            basic: {
                title: 'Basic data',
                inputs: {
                    name: 'Feature name',
                    type: 'Feature type',
                    presentationType: 'Feature presentation',
                    isRequired: 'Is required',
                    isRequiredTooltip: 'The value for this feature needs to be set for all the products in the product catalogue',
                    isFilter: 'Is a filter',
                    isFilterTooltip: 'This feature will be exposed in the store as a product search filter',
                    isMultiselect: 'Is multiselectable',
                    isMultiselectTooltip: 'Each product can have multiple values of this feature assigned to itself'
                },
                editModalHeader: 'Feature name'
            },
            categories: {
                title: 'Assigned categories',
                table: {
                    columnNames: {
                        categories: 'Assigned category',
                        actions: 'Actions',
                    },
                    tooltip: 'Unassign category',
                    placeholder: 'No assigned categories found for selected feature',
                    deleteModal: {
                        header: 'Unassign category',
                        bodyStart: 'Are you sure you want to unassign category',
                        bodyEnd: 'from selected feature?',
                        confirm: 'UNASSIGN',
                        cancel: 'CANCEL'
                    }
                },
                controls: {
                    add: 'ASSIGN CATEGORY',
                    remove: 'UNASSIGN SELECTED CATEGORIES'
                },
                additionModal: {
                    header: 'Assign selected category',
                    body: 'Are you sure you want to assign selected category to feature',
                    confirm: 'ASSIGN',
                    cancel: 'CANCEL'
                },
                deletionModal: {
                    header: 'Unassign selected categories',
                    body: 'Do you want to unassign all of the selected categories from feature',
                },
                categorySelectionModal: {
                    header: 'Category selection',
                    body: 'Select category which should be assigned to this feature',
                    emptySelectionLabel: 'Select category',
                    confirm: 'SELECT',
                    cancel: 'CANCEL'
                },
                nameFiller: '[NO NAME]',
                targetCategoryError: 'Selected category is already assigned to this feature, please select other category.'
            },
            values: {
                title: 'Values',
                colors: {
                    columnNames: {
                        color: 'Color',
                        name: 'Name',
                        actions: 'Actions',
                    },
                    actions: {
                        edit: 'Edit color',
                        remove: 'Remove color',
                        addColor: 'ADD COLOR'
                    },
                    deleteModal: {
                        header: 'Color:',
                        body: 'Are you sure you want to remove color',
                        confirm: 'REMOVE',
                        cancel: 'CANCEL'
                    },
                    additionModal: {
                        header: 'Add new color',
                        body: 'Are you sure you want to create new color?',
                        confirm: 'ADD',
                        cancel: 'CANCEL'
                    },
                    empty: {
                        table: 'No colors found for selected feature',
                        name: '[NO NAME]'
                    }
                },
                dictionaryValues: {
                    columnNames: {
                        name: 'Name',
                        actions: 'Actions',
                    },
                    actions: {
                        edit: 'Edit value',
                        remove: 'Remove value',
                        addColor: 'ADD Value'
                    },
                    deleteModal: {
                        header: 'Value:',
                        body: 'Are you sure you want to remove value',
                        confirm: 'REMOVE',
                        cancel: 'CANCEL'
                    },
                    additionModal: {
                        header: 'Add new value',
                        body: 'Are you sure you want to create new value?',
                        confirm: 'ADD',
                        cancel: 'CANCEL'
                    },
                    empty: {
                        table: 'No values found for selected feature',
                        name: '[NO NAME]'
                    },
                    inputs: {
                        name: 'Value name'
                    }
                },
                dateRange: {
                    startDateLabel: 'Minimum date',
                    endDateLabel: 'Maximum date'
                },
                range: {
                    minLabel: 'Lower limit',
                    maxLabel: 'Upper limit',
                    precisionLabel: 'Precision'
                }
            },
            settings: {
                title: 'Settings',
                inputs: {
                    isExtended: 'Is permanently extended',
                    isExtendedTooltip: 'Should a filter rendered on the store search page be permanently extended?',
                    searchPlaceholder: 'Search phrase placeholder',
                    foldedDictionaryItemsVisible: 'Number of unfolded filter values'
                },
                empty: 'No settings can be changed for current feature type',
                editModalHeader: 'Search phrase placeholders'
            }
        }
    }
};

export default features;