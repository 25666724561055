import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TableGenerator from '../../generators/TableGenerator';

import styles from './index.module.scss';
import { stringOrNumberPropTypes } from '../../../propTypes';
import { Badge } from 'reactstrap';

import { withFormUtils } from '../../../hoc/withFormUtils';

import { generateShopProductLink } from '../../../utils/products';

const propTypes = {
    opinions: PropTypes.arrayOf(PropTypes.shape({
        productReviewId: stringOrNumberPropTypes,
        productId: stringOrNumberPropTypes,
        name: PropTypes.string,
        date: PropTypes.string,
        content: PropTypes.string,
        isContentVisible: PropTypes.bool,
        rating: PropTypes.number,
    })),
    productId: stringOrNumberPropTypes,
    removeAction: PropTypes.func,
    switchAction: PropTypes.func,
};

const OpinionsTable = ({ opinions, productId, removeAction, switchAction, utils }) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.opinions;

    const { name, date, content, isContentVisible, rating, actions, status } = translations.inputs;

    const deleteAction = id => () => {
        removeAction(id);
    };

    const handleSwitch = (id, value) => {
        switchAction(id, value);
    };

    const handleRedirectToProduct = () => () => {
        window.open(generateShopProductLink(productId, utils.currentLanguage()), '_blank', 'noopener, noreferrer');
    };

    const StatusCell = (item) => {
        if(item.accepted) {
            return (
                <Badge color="success" pill>{translations.statuses.accepted}</Badge>
            );
        }

        if(item.deleted) {
            return (
                <Badge color="danger" pill>{translations.statuses.deleted}</Badge>
            );
        }

        return (
            <Badge color="secondary" pill>{translations.statuses.notChecked}</Badge>
        );
    };

    return (
        <TableGenerator
            items={opinions}
            actions={{ deleteAction }}
            defaultAction={ handleRedirectToProduct }
            expectedActions={['delete']}
            translations={{
                tooltips: {
                    remove: translations.removeTooltip,
                },
                emptyTable: translations.emptyTable,
                deleteModal: translations.deleteModal,
            }}
            columns={[
                {
                    header: name.label,
                    value: 'name',
                    filler: name.filler
                },
                {
                    header: date.label,
                    value: 'date',
                    filler: date.filler,
                    type: 'date'
                },
                {
                    header: content.label,
                    value: 'content',
                    filler: content.filler,
                    additionalStyle: styles.content,
                },
                {
                    header: isContentVisible.label,
                    value: 'isContentVisible',
                    type: 'switchWithAction',
                    onSwitch: handleSwitch,
                },
                {
                    header: status.label,
                    value: 'status',
                    type: 'custom',
                    customCell: StatusCell,
                },
                {
                    header: rating.label,
                    value: 'rating',
                    type: 'rating',
                },
                {
                    header: actions.label,
                    type: 'actions'
                },
            ]}
        />
    );
};

OpinionsTable.propTypes = propTypes;

export default withFormUtils(OpinionsTable);