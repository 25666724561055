import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getLanguages, updateLanguagesStatus } from '../../redux/methods/languages';

import ContainerWithCard from '../../components/ContainerWithCard';
import LanguagesTable from '../../components/tables/LanguagesTable';
import useFetch from '../../hooks/useFetch';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getLanguages: PropTypes.func,
    updateLanguagesStatus: PropTypes.func,
    currentLanguage: PropTypes.string,
};

const Languages = ({ getLanguages, updateLanguagesStatus, forceRefetch, currentLanguage }) => {
    const intl = useIntl();
    const translations = intl.messages.languages;

    const [checkedCheckboxIds, setCheckedCheckboxIds] = useState([]);

    const [ languages ] = useFetch(getLanguages, null, [], forceRefetch);

    const handleChangeStatus = (id, status) => {
        if(id) {
            updateLanguagesStatus({
                languagesId: [id],
                isActive: status,
            });
        } else {
            updateLanguagesStatus({
                languagesId: checkedCheckboxIds,
                isActive: status,
            });
        }

        setCheckedCheckboxIds([]);
    };

    return languages ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-language'>
            <LanguagesTable
                languages={languages}
                checkedCheckboxIds={checkedCheckboxIds}
                setCheckedCheckboxIds={setCheckedCheckboxIds}
                changeStatus={handleChangeStatus}
                currentLanguage={currentLanguage}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

Languages.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.languages,
    currentLanguage: state.persisted.persistedLocale.persistedChosenLanguage,
});

const mapDispatch = {
    getLanguages,
    updateLanguagesStatus
};

export default connect(mapStateToProps, mapDispatch)(Languages);