import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { ListGroup as List, ListGroupItem, ListGroupItemHeading, ListGroupItemText, UncontrolledTooltip } from 'reactstrap';

import { countryPropTypes } from '../../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    header: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
    }),
    counter: PropTypes.shape({
        left: PropTypes.number,
        right: PropTypes.number,
    }),
    searchInput: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
    }),
    countries: PropTypes.arrayOf(countryPropTypes),
    tooltipCountry: PropTypes.string,
    onItemClick: PropTypes.func,
    color: PropTypes.string,
};

const ListGroup = ({ header, counter, searchInput, countries, tooltipCountry, onItemClick, color }) => {
    return (
        <List>
            <ListGroupItem active className={clsx(color === 'danger' && 'bg-danger border-danger')}>
                <ListGroupItemHeading className='d-flex justify-content-between'>
                    <span>{header.title}</span>
                    <span className='h6'>
                        {`${counter.left}/${counter.right}`}
                    </span>
                </ListGroupItemHeading>
                <ListGroupItemText>
                    {header.text}
                </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem
                value={searchInput.value}
                tag='input'
                onChange={searchInput.onChange}
                placeholder={searchInput.placeholder}
            />
            <div className={styles.scrollable}>
                {
                    countries.map(country =>
                        <React.Fragment key={`country-${country.id}`}>
                            <ListGroupItem
                                className={clsx('w-100 text-left', styles.listItem)}
                                tag='button'
                                onClick={onItemClick(country)}
                                id={`country-${country.id}-id`}
                            >
                                <span>{country.name}</span>
                            </ListGroupItem>
                            <UncontrolledTooltip placement='top' target={`country-${country.id}-id`}>
                                {tooltipCountry}
                            </UncontrolledTooltip>
                        </React.Fragment>
                    )
                }
            </div>
        </List>
    );
};

ListGroup.propTypes = propTypes;

export default ListGroup;