import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import { getCategoriesSettings, updateCategoriesSettings } from '../../../../../redux/methods/categories';
import useFetch from '../../../../../hooks/useFetch';

import SectionEditFormButtons from '../../../../../components/SectionEditFormButtons';
import FormFieldWithCheckbox from '../../../../../components/formFields/FormFieldWithCheckbox';
import FormFieldWithDropdown from '../../../../../components/formFields/FormFieldWithDropdown';
import FormFieldTextOrNumber from '../../../../../components/formFields/FormFieldTextOrNumber';

import { withFormUtils } from '../../../../../hoc/withFormUtils';

const propTypes = {
    getCategoriesSettings:  PropTypes.func,
    updateCategoriesSettings: PropTypes.func
};

const CategoryGeneralSection = ({
    getCategoriesSettings,
    updateCategoriesSettings
}) => {
    const intl = useIntl();
    const translations = intl.messages.general.settings.tabs.categories;

    const handleOnSubmit = formData => {
        updateCategoriesSettings(formData);
    };

    const [ settings ] = useFetch(getCategoriesSettings);

    const imageTypeOptions = [
        {id: 'halfsize', name: translations.halfSize},
        {id: 'pictogram', name: translations.pictogram}
    ];

    return settings ? (
        <Container fluid>
            <FinalForm
               mutators={{
                    setToFalse: (args, state, { changeValue }) => {
                        changeValue(state, 'showOnlySubcategoriesAboveSearchResult', () => false);
                    },
                    setToFalse2: (args, state, { changeValue }) => {
                        changeValue(state, 'inheritPictogramsInCategoryMenu', () => false);
                    }
                }}
                initialValues={settings}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container className='p-3 px-0' fluid>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='searchAfterCategoryTreeClick'
                                        label={translations.searchAfterCategoryTreeClick}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='showProductsFromSubcategories'
                                        label={translations.showProductsFromSubcategories}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='showCategoryTreeOnSearchPage'
                                        label={translations.showCategoryTreeOnSearchPage}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='showOnlyOneCategoryExpanded'
                                        label={translations.showOnlyOneCategoryExpanded}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='showOnlySubTreeOfSelectedCategory'
                                        label={translations.showOnlySubTreeOfSelectedCategory}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='showSubCategoriesTilesAboweSearchResult'
                                        label={translations.showSubCategoriesTilesAboweSearchResult}
                                        mutator={form.mutators.setToFalse}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='showOnlySubcategoriesAboveSearchResult'
                                        label={translations.showOnlySubcategoriesAboveSearchResult}
                                        isDisabled={!values.showSubCategoriesTilesAboweSearchResult}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithDropdown
                                        label={translations.presentationType}
                                        name='showSubCategoriesTilesAboweSearchResultImageType'
                                        options={imageTypeOptions}
                                        currentSelectionLabel={imageTypeOptions.find(o => o.id === values.showSubCategoriesTilesAboweSearchResultImageType)?.name}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldTextOrNumber
                                        type='number'
                                        label={translations.numberOfColumnsInCategoryMenu}
                                        name='numberOfColumnsInCategoryMenu'
                                        min={1}
                                        max={10}
                                        required={true}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='showPictogramsInCategoryMenu'
                                        label={translations.showPictogramsInCategoryMenu}
                                        mutator={form.mutators.setToFalse2}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='inheritPictogramsInCategoryMenu'
                                        label={translations.inheritPictogramsInCategoryMenu}
                                        isDisabled={!values.showPictogramsInCategoryMenu}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='categoryMenuAnimation'
                                        label={translations.categoryMenuAnimation}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldTextOrNumber
                                        type='number'
                                        label={translations.searchResultCategoryTilesAmount}
                                        name='searchResultCategoryTilesAmount'
                                        min={1}
                                        max={10}
                                        required={true}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <FormFieldWithCheckbox
                                        name='showDescriptionAtBottom'
                                        label={translations.showDescriptionAtBottom}
                                    />
                                </Row>
                            </Container>
                            <SectionEditFormButtons hideCancelButton />
                        </Form>
                    );
                }}
            />
        </Container>
    ) : null;
};

CategoryGeneralSection.propTypes = propTypes;

const mapDispatch = {
    getCategoriesSettings,
    updateCategoriesSettings
};

export default withFormUtils(connect(null, mapDispatch)(CategoryGeneralSection));