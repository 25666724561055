import React from 'react';
import { connect } from 'react-redux';
import { selectStoreAvailableIsoList, selectStoreCurrentLanguage } from '../redux/selectors/storeLocale';

export const withLanguage = Component => {
    const WithLanguages = ({ allLanguages, currentLanguage, ...props }) => {
        return <Component allLanguages={allLanguages} currentLanguage={currentLanguage} {...props} />;
    };

    const mapStateToProps = state => ({
        allLanguages: selectStoreAvailableIsoList(state),
        currentLanguage: selectStoreCurrentLanguage(state),
    });

    return connect(mapStateToProps)(WithLanguages);
};