import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectChosenLanguageObject } from '../../../redux/selectors/locale';
import { changeChosenLanguage, changeChosenLanguageAuthorized } from '../../../redux/methods/locale';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactCountryFlag from 'react-country-flag';

import { languageObject } from '../../../translations';
import { translateIso, getLanguageName , addIdsToAvailableLanguagesTranslations } from '../../../utils/locale';

import styles from './index.module.scss';

const propTypes = {
    isAuthorized: PropTypes.bool,
    noBorder: PropTypes.bool,
    availableLanguages: PropTypes.arrayOf(PropTypes.object),
    chosenLanguageObject: PropTypes.shape({
        iso: PropTypes.oneOf([ ...Object.keys(languageObject) ]).isRequired,
        id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired
    }).isRequired,
    changeChosenLanguage: PropTypes.func,
    changeChosenLanguageAuthorized: PropTypes.func,
};

const LanguageDropdown = ({ isAuthorized, availableLanguages, chosenLanguageObject, noBorder, changeChosenLanguage, changeChosenLanguageAuthorized }) => {
    const intl = useIntl();
    const translations = addIdsToAvailableLanguagesTranslations(intl.messages.locale.availableLanguages, availableLanguages);

    const [ isOpen, setIsOpen ] = useState(false);

    const toggleIsOpen = () => setIsOpen(!isOpen);

    const handleLanguageSelection = languageObjectSelection => () => {
        if (chosenLanguageObject.iso !== languageObjectSelection.iso) {
            if (isAuthorized) {
                changeChosenLanguageAuthorized(languageObjectSelection);
            } else {
                changeChosenLanguage(languageObjectSelection.iso);
            }
        }
    };

    const FlagItem = ({ iso, name, isSelected }) =>
        <span className={clsx(styles.flagItem, isSelected && styles.flagItemSelected)}>
            <ReactCountryFlag
                className={styles.flagItemFlag}
                svg
                countryCode={iso}
            />
            <span className={styles.flagItemText}>{name}</span>
            { isSelected && <i className={`cui-check icons font-1xl ${styles.flagItemCheck}`}/> }
        </span>
    ;

    return (
        <Dropdown isOpen={isOpen} toggle={toggleIsOpen}>
            <DropdownToggle className={clsx(styles.dropdownToggle, noBorder && styles.dropdownToggleNoBorder)} caret>
                <FlagItem
                    iso={translateIso(chosenLanguageObject?.iso?.toUpperCase())}
                    name={getLanguageName(translations, chosenLanguageObject.iso)}
                />
            </DropdownToggle>
            <DropdownMenu>
                {
                    translations.map((languageObject, i) => {
                        const isSelected = languageObject?.iso?.toUpperCase() === chosenLanguageObject?.iso?.toUpperCase();
                        return (
                            <DropdownItem
                                className={clsx(isSelected && styles.flagItemWrapper)}
                                key={`langItem-${i}`}
                                onClick={handleLanguageSelection(languageObject)}
                            >
                                <FlagItem
                                    isSelected={isSelected}
                                    iso={translateIso(languageObject.iso.toUpperCase())}
                                    name={languageObject.name}
                                />
                            </DropdownItem>
                        );
                    })
                }
            </DropdownMenu>
        </Dropdown>
    );
};

LanguageDropdown.propTypes = propTypes;

const mapStateToProps = state => ({
    chosenLanguageObject: selectChosenLanguageObject(state),
    availableLanguages: state.locale.availableLanguages
});

const mapDispatch = {
    changeChosenLanguage,
    changeChosenLanguageAuthorized
};

export default connect(mapStateToProps, mapDispatch)(LanguageDropdown);