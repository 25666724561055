import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProducts, deleteProduct, getFillers, updateProductStatus, createProductDuplicate } from '../../../../redux/methods/products';
import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';

import ProductTable from '../../../../components/tables/ProductTable';
import useFetch from '../../../../hooks/useFetch';
import { generateShopProductLink, translateProductName } from '../../../../utils/products';
import Paginator from '../../../../components/Paginator';
import { withFormUtils } from '../../../../hoc/withFormUtils';
import { withArticleColumns } from '../../../../hoc/withArticleColumns';
import { useHistory } from 'react-router-dom';

const propTypes = {
    props: PropTypes.object
};

const Table = ({
    forceRefetch,
    getProducts,
    deleteProduct,
    getFillers,
    updateProductStatus,
    isFillersDefined,
    searchConfig,
    changeSearchConfig,
    storeCurrentLanguage,
    changePage,
    utils,
    createProductDuplicate,
    articleColumns,
    adminArticleColumns
}) => {
    const [ checkedProductsIds, setCheckedProductsIds ] = useState([]);

    const history = useHistory();

    useEffect(() => {
        if(!isFillersDefined) {
            getFillers();
        }
    }, [getFillers, isFillersDefined]);

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    const [columnSortedByDescendingOrder, setColumnSortedByDescendingOrder ] = useState(null);

    const handlePerformSort = (columnName, direction) => {
        changeSearchConfig({ orderByColumnName: columnName, orderDirection: direction, pageSize: searchConfig.pageSize });
    };

    const showProductActionHandler = id => {
        const shopProductWindow = window.open(generateShopProductLink(id, utils.currentLanguage()), '_blank', 'noopener, noreferrer');
        if (shopProductWindow) {
            shopProductWindow.opener = null;
        }
    };

    const [ products, pages ] = useFetch(getProducts, searchConfig, [searchConfig], forceRefetch);

    const translatedProducts = products && translateProductName(products, storeCurrentLanguage);

    return products ? (
        <React.Fragment>
            <ProductTable
                copyProduct={createProductDuplicate}
                performPageSizeSelect={handleChangePageSize}
                pageSize={searchConfig.pageSize}
                currentPage={searchConfig.curentPage + 1}
                products={translatedProducts}
                history={history}
                removeAction={deleteProduct}
                showProduct={showProductActionHandler}
                checkedProductsIds={checkedProductsIds}
                setCheckedProductsIds={setCheckedProductsIds}
                updateAction={updateProductStatus}
                performSort={handlePerformSort}
                setColumnSortedByDescendingOrder={setColumnSortedByDescendingOrder}
                columnSortedByDescendingOrder={columnSortedByDescendingOrder}
                articleColumns={articleColumns}
                adminArticleColumns={adminArticleColumns}
                utils={utils}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={searchConfig.curentPage + 1}
                        onPageChange={changePage}
                    />
            }
        </React.Fragment>
    ) : null;
};

Table.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.products,
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
    isFillersDefined: state.products.isFillersDefined,
    adminArticleColumns: state.appInit.adminArticleColumns
});

const mapDispatch = {
    getProducts,
    deleteProduct,
    getFillers,
    updateProductStatus,
    createProductDuplicate,
};

export default withArticleColumns(withFormUtils(connect(mapStateToProps, mapDispatch)(Table)));