import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { connect } from 'react-redux';

import { getProducts } from '../../../redux/methods/products';
import useFetch from '../../../hooks/useFetch';
import useSearchConfig from '../../../hooks/useSearchConfig';
import { translateProductName } from '../../../utils/products';

import { Label } from 'reactstrap';
import { Field } from 'react-final-form';
import ProductsWithoutActionsTable from '../../../components/tables/ProductsWithoutActionsTable';
import ProductSearchForm from '../../../components/ProductSearchForm';
import Paginator from '../../../components/Paginator';
import PageSizeChanger from '../../../components/PageSizeChanger';

import { stringOrNumberPropTypes } from '../../../propTypes';
import { productsSearchConfig } from '../../../config/searchConfigs';

import styles from './index.module.scss';

const propTypes = {
    checkedProductsId: PropTypes.arrayOf(stringOrNumberPropTypes),
    setCheckedProductId: PropTypes.func,
    getProducts: PropTypes.func,
    currentLanguage: PropTypes.string,
    translations: PropTypes.shape({
        inputs: PropTypes.shape({
            name: PropTypes.string,
            showSelected: PropTypes.string
        })
    }),
    isDisabled: PropTypes.bool,
    form: PropTypes.object
};

const TabProductAssignerManual = ({
    checkedProductsId = [3, 5],
    setCheckedProductsId,
    getProducts,
    currentLanguage,
    translations,
    isDisabled,
    additionalSearchConfig = {}
}) => {
    const [ filterChecked, setFilterChecked ] = useState(false);
    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig({ ...productsSearchConfig, ...additionalSearchConfig });
    const [ products, pages ] = useFetch(getProducts, searchConfig, [searchConfig]);

    const translatedProducts = products && translateProductName(products, currentLanguage);

    const handleFilterChecked = () => {
        changeSearchConfig({ curentPage: 0, productId: !filterChecked ? checkedProductsId : null });
        setFilterChecked(!filterChecked);
    };

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    return products ? (
        <div className='w-100'>

            <ProductSearchForm
                performProductSearch={changeSearchConfig}
                searchConfig={searchConfig}
                additionalDefaultSearchConfig={filterChecked ? { productId: checkedProductsId, ...additionalSearchConfig } : { ...additionalSearchConfig }}
            >
                <div className='mx-3 mt-3 d-flex justify-content-center align-items-center'>
                    <Field
                        name="showSelected"
                        component='input'
                        type='checkbox'
                        id="showSelected"
                        checked={filterChecked}
                        onChange={handleFilterChecked}
                        disabled={isDisabled}
                    />
                    <Label for="showSelected" className='pl-2 mb-0'>{translations.inputs.showSelected}</Label>
                </div>
            </ProductSearchForm>
            <div className={clsx(styles.container, translatedProducts.length > 0 && 'mb-3')}>
                <PageSizeChanger
                    performSelect={handleChangePageSize}
                    className='pt-3 pb-1'
                    currentPageSize={searchConfig.pageSize}
                />
                <ProductsWithoutActionsTable
                    checkedProductsId={checkedProductsId}
                    setCheckedProductsId={setCheckedProductsId}
                    translatedProductsList={translatedProducts}
                    photoCellStyle={styles.customPhotoCell}
                    isDisabled={isDisabled}
                />
            </div>
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={searchConfig.curentPage + 1}
                        onPageChange={changePage}
                    />
            }
        </div>
    ) : null;
};

TabProductAssignerManual.propTypes = propTypes;

const mapDispatch = {
    getProducts
};

export default connect(null, mapDispatch)(TabProductAssignerManual);
