import React from 'react';
import PropTypes from "prop-types";

import clsx from 'clsx';

import styles from './index.module.scss';

const propTypes = {
    isCellSortedByDescendingOrder: PropTypes.bool,
    onClickAction: PropTypes.func
};

const Sorter = ({ isCellSortedByDescendingOrder, onClickAction }) => {
    return (
        <div onClick={onClickAction} className={styles.container}>
            <i
                className={clsx(
                    `fa fa-long-arrow-${isCellSortedByDescendingOrder ? 'down' : 'up'}`,
                    isCellSortedByDescendingOrder ? styles.descIcon : styles.ascIcon
                )}
            />
        </div>
    );
};

Sorter.propTypes = propTypes;

export default Sorter;
