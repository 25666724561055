import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Container, Input, Row } from 'reactstrap';

import { promoPriceTypesEnum } from '../../../../../../enums/products';

import PriceEditor from '../PriceEditor';
import TypeSelector from '../TypeSelector';
import FormFieldWithCalendarPicker from '../../../../../../components/formFields/FormFieldWithCalendarPicker';

import { withFormUtils } from '../../../../../../hoc/withFormUtils';

import { productPricesTranslationsPropTypes, productPricePropTypes, stringOrNumberPropTypes, formUtilsHocPropTypes } from '../../../../../../propTypes';
import FormFieldTextOrNumber from '../../../../../../components/formFields/FormFieldTextOrNumber';

const propTypes = {
    translations: productPricesTranslationsPropTypes,
    price: productPricePropTypes,
    updatePriceHandler: PropTypes.func,
    basePriceId: stringOrNumberPropTypes,
    utils: formUtilsHocPropTypes.isRequired,
    disabled: PropTypes.bool
};

const PromoPriceEdit = ({ index, values, form, utils, promoPrice, calculateNetPrice, basePriceIndex, disabled }) => {
    const translations = useIntl().messages.products.edit.tabs.prices;

    const calculatePromoPrice = (grossPrice, promo) => Math.round((grossPrice * ((100 - promo) / 100)) * 100) / 100;
    const [promoValue, setPromoValue] = useState('');

    useEffect(() => {
        if(typeof promoPrice.priceRate === 'number' && values.prices[basePriceIndex].grossPrice > 0) {
            const promoPrice1 = calculatePromoPrice(values.prices[basePriceIndex].grossPrice, promoPrice.priceRate);

            setPromoValue(promoPrice1);
        } else {
            setPromoValue(values.prices[basePriceIndex].grossPrice);
        }

    }, [basePriceIndex, promoPrice, values.prices]);

    const getPromotionType = () => {
        if(promoPrice.priceRate) {
            return promoPriceTypesEnum.precentageDiscount;
        }

        if(promoPrice.netPrice) {
            return promoPriceTypesEnum.definedPrice;
        }

        return promoPriceTypesEnum.undefined;
    };

    const [ promoPriceType, setPromoPriceType ] = useState(getPromotionType());

    const handleChangePromoType = id => {
        form.change(`prices[${index}].priceRate`, null);
        form.change(`prices[${index}].netPrice`, 0);
        form.change(`prices[${index}].grossPrice`, 0);

        setPromoPriceType(id);
    };

    return (
        <Container fluid>
            <Row className='mt-3 font-weight-bold'>
                <span>{translations.promoPrice}</span>
            </Row>
            <Row className='mt-3 ml-3 d-flex flex-column'>
                <TypeSelector
                    type={promoPriceType}
                    setType={handleChangePromoType}
                    translations={translations.checkboxes}
                    config={promoPriceTypesEnum}
                    disabled={disabled}
                />
            </Row>
                {
                    promoPriceType === promoPriceTypesEnum.precentageDiscount && (
                        <Row className='my-3'>
                            <FormFieldTextOrNumber
                                name={`prices[${index}].priceRate`}
                                symbol='%'
                                label={translations.inputs.discountRate}
                                type='number'
                                step='0.01'
                                min={0}
                                max={100}
                                parse={value => value ? (parseFloat(value) > 100 ? 100 : parseFloat(value)) : 0}
                                disabled={disabled}
                            />
                            <div className='ml-3'>
                                <span>{translations.inputs.afterPromo}</span>
                                <Input className='w-100' disabled value={promoValue}/>
                            </div>
                        </Row>
                    )
                }
                {
                    promoPriceType === promoPriceTypesEnum.definedPrice && (
                        <Row className='my-3'>
                            <PriceEditor max={`prices[${basePriceIndex}].grossPrice`} calculateNetPrice={calculateNetPrice} values={values}
                                         index={index} form={form} disabled={disabled} />
                        </Row>
                    )
                }
            {
                promoPriceType !== promoPriceTypesEnum.undefined
                    && <Row className='my-3'>
                        <FormFieldWithCalendarPicker
                            name={`prices[${index}].dateFrom`}
                            label={translations.inputs.dateFrom}
                            storeCurrentLanguage={utils.currentLanguage()}
                            isDisabled={disabled}
                        />
                        <FormFieldWithCalendarPicker
                            name={`prices[${index}].dateTo`}
                            label={translations.inputs.dateTo}
                            storeCurrentLanguage={utils.currentLanguage()}
                            isDisabled={disabled}
                        />
                    </Row>
            }
        </Container>
    );
};

PromoPriceEdit.propTypes = propTypes;

export default withFormUtils(PromoPriceEdit);