import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { getCookies, updateCookieIsActive } from '../../../redux/methods/extrasSettings';

import TableGenerator from '../../generators/TableGenerator';

import { generateEditLinkForCookiesAgreement } from '../../../utils/cookies';
import { cookiesAgreementPropTypes } from '../../../propTypes';

import useFetch from '../../../hooks/useFetch';

import { getTranslationsFromArray } from '../../../utils/getTranslationsFromArray';

import { connect } from 'react-redux';

import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    cookiesAgreements: PropTypes.arrayOf(cookiesAgreementPropTypes),
    changeStatus: PropTypes.func,
};

const CookiesAgreementsTable = ({ getCookies, updateCookieIsActive, forceRefetch, utils }) => {
    const translations = useIntl().messages.cookiesAgreements.table;
    const history = useHistory();

    const [ cookiesAgreements ] = useFetch(getCookies, null, [], forceRefetch);

    const translateCookies = cookies => {
        return cookies.map(cookie => ({
            ...cookie,
            header: getTranslationsFromArray(cookie.header, utils.currentLanguage()),
            type: getTranslationsFromArray(cookie.type, utils.currentLanguage())
        }));
    };

    const handleDefaultAction = (id) => () => {
        history.push(generateEditLinkForCookiesAgreement(id));
    };

    const handleSwitch = (id, status) => {
        updateCookieIsActive(id, { isActive: status });
    };

    return cookiesAgreements ? (
        <TableGenerator
            items={translateCookies(cookiesAgreements)}
            editLink={generateEditLinkForCookiesAgreement}
            defaultAction={handleDefaultAction}
            expectedActions={['edit']}
            translations={translations}
            columns={[
                {
                    header: translations.headerLabels.header,
                    value: 'header',
                    filler: translations.emptyPlaceholders.header
                },
                {
                    header: translations.headerLabels.type,
                    value: 'type'
                },
                {
                    header: translations.headerLabels.isActive,
                    value: 'isActive',
                    type: 'switchWithAction',
                    onSwitch: handleSwitch,
                },
                {
                    header: translations.headerLabels.actions,
                    type: 'actions'
                },
            ]}
        />
    ) : null;
};

CookiesAgreementsTable.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.cookies,
});

const mapDispatch = {
    getCookies,
    updateCookieIsActive,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(CookiesAgreementsTable));