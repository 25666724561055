/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDiscountUsers, getDiscountGroups } from '../../../../redux/methods/discounts';
import useFetch from '../../../../hooks/useFetch';
import useSearchConfig from '../../../../hooks/useSearchConfig';
import useActiveLabel from '../../../../hooks/useActiveLabel';

import { Container } from 'reactstrap';

import SwitchWithLabels from '../../../../components/SwitchWithLabels';
import GroupsSection from './GroupsSection';
import UsersSection from './UsersSection';

import { stringOrNumberPropTypes } from '../../../../propTypes';
import { discountsUsersSearchConfig, discountsGroupsSearchConfig } from '../../../../config/searchConfigs';

import styles from './index.module.scss';

const propTypes = {
    discountToEditId: stringOrNumberPropTypes,
    getDiscountUsers: PropTypes.func,
    getDiscountGroups: PropTypes.func,
    usersForceRefetch: PropTypes.bool,
    groupsForceRefetch: PropTypes.bool,
};

const DiscountEditGroupsUsers = ({ getDiscountUsers, discountToEditId, getDiscountGroups, usersForceRefetch, groupsForceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.groupsUsers;

    const [ groupsSearchConfig, changeGroupsPage ] = useSearchConfig({ ...discountsGroupsSearchConfig, discountId: discountToEditId });
    const [ usersSearchConfig, changeUsersPage ] = useSearchConfig({ ...discountsUsersSearchConfig, discountId: discountToEditId });

    const [ groups, groupsPages ] = useFetch(getDiscountGroups, groupsSearchConfig, [groupsSearchConfig], groupsForceRefetch);
    const [ users, usersPages ] = useFetch(getDiscountUsers, usersSearchConfig, [usersSearchConfig], usersForceRefetch);

    const [ activeLabel, setActiveLabel ] = useActiveLabel(groups, users);

    const isActiveLabelLeft = activeLabel === 'left';
    const isUsersPresent = users && users.length > 0;
    const isGroupsPresent = groups && groups.length > 0;

    return users && groups && activeLabel ? (
        <React.Fragment>
            <Container fluid>
                <header className={styles.controls}>
                    <SwitchWithLabels
                        labels={{
                            left: translations.switch.left,
                            right: translations.switch.right,
                            main: translations.switch.main,
                        }}
                        activeLabel={activeLabel}
                        changeActiveLabel={setActiveLabel}
                        isDisabled={isGroupsPresent || isUsersPresent}
                        className={clsx(styles.switch)}
                        tooltip={translations.switch.tooltip}
                    />
                </header>
                {groups && isActiveLabelLeft &&
                    <GroupsSection
                        discountGroups={groups}
                        pages={groupsPages}
                        currentPage={groupsSearchConfig.curentPage}
                        setCurrentPage={changeGroupsPage}
                    />
                }
                <UsersSection
                    isActiveLabelLeft={isActiveLabelLeft}
                    discountUsers={users}
                    groupsIsPresent={isGroupsPresent}
                    pages={usersPages}
                    currentPage={usersSearchConfig.curentPage}
                    setCurrentPage={changeUsersPage}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

DiscountEditGroupsUsers.propTypes = propTypes;

const mapStateToProps = (state) => ({
    discountToEditId: state.discounts.discountToEdit.data.discountId,
    usersForceRefetch: state.refetchers.discountUsers,
    groupsForceRefetch: state.refetchers.discountGroups,
});

const mapDispatch = {
    getDiscountUsers,
    getDiscountGroups,
};

export default connect(mapStateToProps, mapDispatch)(DiscountEditGroupsUsers);