const editNameModal = {
    save: 'ZAPISZ ZMIANY',
    cancel: 'ANULUJ ZMIANY',
    labels: {
        name: 'Nazwa',
        textColor: 'Kolor tekstu',
        backgroundColor: 'Kolor tła',
        borderColor: 'Kolor ramki',
        button: 'Wybierz kolor',
        mappingEnabled: 'Mapowanie z Erp',
        mappingFieldId: 'Pole z Erp',
        mappingValue: 'Szukany tekst',
    }
};

export default editNameModal;