import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import { updateUserData } from '../../../redux/methods/users';

import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';

import styles from './index.module.scss';

const propTypes = {
    translations: PropTypes.object,
};

const PersonalDataSection = ({
    translations,
    user,
    updateUserData,
}) => {

    const handleOnSubmit = formData => {
        updateUserData(formData);
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={user}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container fluid className='mt-3 px-0'>
                                    <Row className='mt-2'>
                                        <FormFieldTextOrNumber
                                            name='firstName'
                                            type='text'
                                            label={translations.inputs.name}
                                            required={true}
                                            className={styles.input}
                                        />
                                    </Row>
                                    <Row className='mt-2'>
                                        <FormFieldTextOrNumber
                                            name='lastName'
                                            type='text'
                                            label={translations.inputs.surname}
                                            className={styles.input}
                                        />
                                    </Row>
                                    <Row className='mt-2'>
                                        <FormFieldTextOrNumber
                                            name='companyName'
                                            type='text'
                                            label={translations.inputs.companyName}
                                            required={true}
                                            className={styles.input}
                                        />
                                    </Row>
                                    <Row className='mt-2'>
                                        <FormFieldTextOrNumber
                                            name='nip'
                                            type='text'
                                            label={translations.inputs.nip}
                                            required={true}
                                            className={styles.input}
                                        />
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

PersonalDataSection.propTypes = propTypes;

const mapDispatch = {
    updateUserData,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatch)(PersonalDataSection);