import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    agreements: false,
    agreementsDescriptions: false,
    agreementsRegions: false,

    attributes: false,
    attributesCategories: false,
    attributesColorValues: false,
    attributesDictionaryValues: false,
    attributesEdit: false,
    attributesSettings: false,

    categories: false,
    categoriesBasic: false,
    categoriesMetatags: false,
    categoriesImage: false,

    countries: false,
    currencies: false,
    currenciesRegions: false,
    customPages: false,
    popups: false,

    discountGroups: false,
    discountUsers: false,
    discounts: false,
    discountsCategories: false,
    discountsCodes: false,
    discountsCountries: false,
    discountsProducts: false,
    discountsRegions: false,

    exchangeRates: false,
    manufacturers: false,
    orders: false,

    products: false,
    shipments: false,
    payments: false,
    productsBasic: false,
    productsDescriptions: false,
    productsImages: false,
    productsMetatags: false,
    productsAttributes: false,
    productsPrices: false,
    productReviews: false,
    productPageTabs: false,
    productsAlternative: false,
    productsRelated: false,
    productsAdditionalCategories: false,
    fillers: false,
    fillerToEdit: false,

    sessionTimeout: false,

    regionCountries: false,
    regions: false,

    homePageProducts: false,
    homePageBanner: false,
    homePageAnimatedBanner: false,
    homePageAnimatedBannerImages: false,
    homePagePromoSections: false,
    homePageSections: false,
    homePageSectionPhotoText: false,

    emailTemplates: false,
    emailTemplateTables: false,

    visualSettingsCss: false,
    promotionBar: false,
    promotionBarSlides: false,
    visualSettingsLogo: false,
    visualSettingsFavicon: false,
    visualSettingsParams: false,

    productsCategoryAssign: false,

    ceneoSettings: false,
    ceneoMappings: false,

    upperMenu: false,
    bottomMenu: false,

    technicalBreak: false,
    notFound: false,

    notifications: false,
    notificationsList: false,
    userAgreements: false,
    agreementsRegistry: false,

    measures: false,
    productMeasures: false,

    predefinedSearch: false,
    predefinedSearchMetatags: false,

    homePageDoubleImage: false,
    homePageManufacturers: false,

    seoPhotoName: false,
    seoGoogleSiteMap: false,

    footerMain: false,
    cookies: false,
    buttons: false,
    mediaLibrary: false,
    mediaLibraryFile: false,
    mediaLibraryFileEdit: false,
    opinions: false,
    generateToken: false,
    productPrice: false,

    adBanners: false,
    adBannersCategories: false,
    translations: false,
    languages: false,
    productsDeliveryGlobal: false,
    productDelivery: false,

    users: false,
    user: false,

    visualSettingsPlaceholderImageProduct: false,

    sectionProducts: false,
    sectionCategories: false,

    emailSettings: false,

    shippingGeneral: false,
    shippingVariants: false,
    shippingWeights: false,
    shippingForms: false,

    paymentForms: false,

    services: false,

    orderStatuses: false,

    categoryFormFields: false,

    payUIntegration: false,

    p24Integration: false,

    productDescriptionImport: false
};

const refetchers = createSlice({
    name: 'refetchers',
    initialState,
    reducers: {
        setForceRefetchByTarget(state, action) {
            const { target, forceRefetch } = action.payload;
            state[target] = forceRefetch;
        },
    },
});

export const { setForceRefetchByTarget } = refetchers.actions;

export default refetchers.reducer;
