import React from 'react';
import PropTypes from 'prop-types';

import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    translations: PropTypes.object,
    isSearchNumberTable: PropTypes.bool,
    items: PropTypes.array,
};

const PhraseSearchTable = ({
    translations,
    isSearchNumberTable,
    items,
    currentPage,
    pageSize,
    performPageSizeSelect,
}) => {
    const removeSecondsFromDate = items => items.map(item => ({ ...item, date: item.date.substring(0, item.date.length - 3) }));
    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            pageSize={pageSize}
            currentPage={currentPage}
            items={isSearchNumberTable ? items : removeSecondsFromDate(items)}
            translations={translations}
            columns={[
                {
                    header: translations.headers.phrase,
                    value: 'phrase'
                },
                {
                    header: translations.headers[ isSearchNumberTable ? 'numberOfSearches' : 'date' ],
                    value: isSearchNumberTable ? 'numberOfSearches' : 'date'
                },
                {
                    //empty object added for a better UI - useful in non-action tables
                }
            ]}
        />
    );
};

PhraseSearchTable.propTypes = propTypes;

export default PhraseSearchTable;