import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { getMediaFile, deleteMediaFiles } from '../../../../../redux/methods/mediaLibrary';

import { Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import DefaultSpinner from '../../../../../components/loaders/DefaultSpinner';
import EditForm from './EditForm';

import styles from './index.module.scss';
import { connect } from 'react-redux';
import { mediaLibraryFileSearchConfig } from '../../../../../config/searchConfigs';
import { stringOrNumberPropTypes } from '../../../../../propTypes';
import getConfig from '../../../../../getConfig';

const propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    editedId: stringOrNumberPropTypes,
    setEditedId: PropTypes.func,
    getMediaFile: PropTypes.func,
    searchInput: PropTypes.string,
    folderId: stringOrNumberPropTypes,
    forceRefetch: PropTypes.bool,
    deleteMediaFiles: PropTypes.func,
    refreshMedia: PropTypes.func,
    changeFileName: PropTypes.func,
};

const EditModal = ({ isOpen, setIsOpen, editedId, setEditedId, getMediaFile, searchInput, folderId, forceRefetch, deleteMediaFiles, refreshMedia, changeFileName }) => {
    const translations = useIntl().messages.mediaLibrary.modal;
    const [isLoading, setIsLoading] = useState(true);
    const [fileData, setFileData] = useState(null);

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    useEffect(() => {
        if(editedId) {
            const asyncFunc = async () => {
                const { payload } = await getMediaFile({ ...mediaLibraryFileSearchConfig, fileId: editedId, folderId: folderId });
                setFileData(payload);
                return;
            };

            setIsLoading(true);
            asyncFunc();
            setIsLoading(false);
        }
    }, []); //eslint-disable-line

    useEffect(() => {
        if(editedId && forceRefetch) {
            const asyncFunc = async () => {
                const { payload } = await getMediaFile({ ...mediaLibraryFileSearchConfig, fileId: editedId, folderId: folderId });
                setFileData(payload);
                return;
            };

            setIsLoading(true);
            asyncFunc();
            setIsLoading(false);
        }
    }, [editedId, forceRefetch, getMediaFile]); //eslint-disable-line

    function handleToggle() {
        setIsOpen(false);
        setEditedId(null);
    }

    const handleByType = (type) => async () => {
        setIsLoading(true);
        const { payload } = await getMediaFile({
            ...mediaLibraryFileSearchConfig,
            fileId: editedId,
            mode: type,
            folderId: folderId,
            fileName: searchInput || null
        });
        setFileData(payload);
        setEditedId(payload.id);
        setIsLoading(false);
        return;
    };

    async function handleRemove() {
        await deleteMediaFiles({ fileIds: [editedId] });
        refreshMedia();
    }

    const externalBtn =
        <ButtonGroup>
            <Button onClick={handleByType('Back')}>&larr;</Button>
            <Button onClick={handleByType('Next')}>&rarr;</Button>
            <Button onClick={handleToggle}>x</Button>
        </ButtonGroup>;

    const isVirtual = fileData?.folderId?.startsWith('Virtual');

    return (
        <Modal size='lg' isOpen={isOpen} style={{ maxWidth: '1900px', width: '90%', margin: '10px auto' }}>
            <ModalHeader tag='div' close={externalBtn}  className='d-flex w-100 justify-content-between'>
                <span>{translations.header}</span>
            </ModalHeader>
            {fileData && <ModalBody>
                <Row>
                    <Col className='mr-5'>
                        <div className={styles.filePreview} style={{ backgroundImage: `url(${apiUrl}${fileData.previewSource})` }} />
                    </Col>
                    <Col className='d-flex flex-column justify-content-between'>
                        <Row className='d-flex flex-column ml-3 mb-3'>
                            <span>{translations.date}: {fileData.created}</span>
                            <span>{translations.author}: {fileData.userName}</span>
                            <span>{translations.type}: {fileData.type}</span>
                            <span>{translations.size}: {fileData.size}</span>
                            <span>{translations.resolution}: {fileData.widthHeight}</span>
                            <span>{translations.oryginalName}: {fileData.fileName}</span>
                            <span>{translations.folder}: {fileData.folder}</span>
                        </Row>
                        <Row className='d-flex flex-column ml-3 mb-5 w-100'>
                            <EditForm isVirtual={isVirtual} changeFileName={changeFileName} fileData={fileData} />
                        </Row>
                    </Col>
                </Row>
            </ModalBody>}
            <ModalFooter>
                <Button color='danger' disabled={isVirtual} onClick={handleRemove}>{translations.remove}</Button>
            </ModalFooter>
            <DefaultSpinner isLoading={isLoading} />
        </Modal>
    );
};

EditModal.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.mediaLibraryFileEdit,
});

const mapDispatch = {
    getMediaFile,
    deleteMediaFiles,
};

export default connect(mapStateToProps, mapDispatch)(EditModal);