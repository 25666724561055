/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Dropdown as ReactstrapDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';

import { makeIconClassNameForActionName } from '../../utils/components';

import { stringOrNumberPropTypes } from '../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            action: PropTypes.func
        })
    ),
    item: PropTypes.object,
    itemId: stringOrNumberPropTypes,
    translations: PropTypes.shape({
        edit: PropTypes.string,
        showDetails: PropTypes.string,
        print: PropTypes.string,
        delete: PropTypes.string,
    }),
    dropdownLabel: PropTypes.string,
    checkIfActionIsDisabled: PropTypes.func
};

const ActionsDropdown = ({
    actions,
    item,
    itemId,
    translations,
    dropdownLabel,
    checkIfActionIsDisabled
}) => {
    const [ isOpen, setIsOpen ] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handlePerformSelection = (action) => () => {
        action(itemId);
    };

    return (
            <ReactstrapDropdown
                direction='down'
                isOpen={isOpen}
                toggle={handleToggle}
            >
                <DropdownToggle caret>
                    {dropdownLabel}
                </DropdownToggle>
                <DropdownMenu>
                    {
                        actions.map(action =>
                            <DropdownItem key={`selector-${action.type}`} onClick={handlePerformSelection(action.action)}>
                                <div className={styles.item}>
                                    <span className={styles.label}>{translations[action.type]}</span>
                                    <i className={clsx(makeIconClassNameForActionName(action.type), styles[`${action.type}`])} />
                                </div>
                            </DropdownItem>
                        )
                    }
                </DropdownMenu>
            </ReactstrapDropdown>
    );
};

ActionsDropdown.propTypes = propTypes;

export default ActionsDropdown;