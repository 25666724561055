import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'reactstrap';

import FormFieldWithRange from '../../../../components/formFields/FormFieldWithRange';

import styles from './index.module.scss';

const propTypes = {
    type: PropTypes.oneOf(['tile', 'list', 'banner', 'productCard']),
    labels: PropTypes.shape({
        desktop: PropTypes.string,
        tablet: PropTypes.string,
        mobile: PropTypes.string
    }),
    header: PropTypes.string,
};

const GraphicsTypeSection = ({
    type,
    labels,
    header
}) => {

    return (
        <div className={styles.typeSection}>
            <Label className={styles.label}>{header}</Label>
                <FormFieldWithRange
                    name={`${type}.desktop`}
                    label={labels.desktop}
                    min={0}
                    max={100}
                    initialValue={50}
                />
                <FormFieldWithRange
                    name={`${type}.tablet`}
                    label={labels.tablet}
                    min={1}
                    max={100}
                    initialValue={50}
                />
                <FormFieldWithRange
                    name={`${type}.mobile`}
                    label={labels.mobile}
                    min={1}
                    max={100}
                    initialValue={50}
                />
        </div>
    );
};

GraphicsTypeSection.propTypes = propTypes;

export default GraphicsTypeSection;