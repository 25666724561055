import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';
import { getProducts, setAssignerSearchConfig, setAssignCategories, deleteCategoryForProducts, addCategoryForProducts, updateProductsCategoryForFeatures } from '../../../../redux/methods/products';

import useFetch from '../../../../hooks/useFetch';
import { translateProductName } from '../../../../utils/products';

import ProductsSynchronizationsTable from '../../../../components/tables/ProductsSynchronizationsTable';
import PageSizeChanger from '../../../../components/PageSizeChanger';
import ProductSearchForm from '../../../../components/ProductSearchForm';
import Paginator from '../../../../components/Paginator';
import SectionAsideButtons from '../../../../components/SectionAsideButtons';
import DefaultSpinner from '../../../../components/loaders/DefaultSpinner';
import { Label, Input, Badge } from 'reactstrap';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import { stringOrNumberPropTypes } from '../../../../propTypes';

const propTypes = {
    getProducts: PropTypes.func,
    currentLanguage: PropTypes.string,
    forceRefetch: PropTypes.bool,
    setAssignerSearchConfig: PropTypes.func,
    setAssignCategories: PropTypes.func,
    assignCategoriesId: stringOrNumberPropTypes,
    isGlobalLoading: PropTypes.bool,
    deleteCategoryForProducts: PropTypes.func,
    addCategoryForProducts: PropTypes.func,
    updateProductsCategoryForFeatures: PropTypes.func
};

const ProductListSection = ({
    getProducts,
    currentLanguage,
    forceRefetch,
    searchConfig,
    setAssignerSearchConfig,
    setAssignCategories,
    assignCategoriesId,
    isGlobalLoading,
    deleteCategoryForProducts,
    addCategoryForProducts,
    utils,
    updateProductsCategoryForFeatures
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.categoryAssign;
    const [ products, pages ] = useFetch(getProducts, {...searchConfig, loadCategories: true }, [searchConfig], forceRefetch);

    const [ checkedProductsId, setCheckedProductsId ] = useState([]);

    const handleFilterChecked = e => {
        setAssignerSearchConfig({ productId: e.target.checked ? checkedProductsId : null, curentPage: 0 });
    };

    const handleCheckRow = id => {
        setAssignCategories(products.find(product => id === product.id));
    };

    const handleChangePageSize = size => {
        setAssignerSearchConfig({ pageSize: size });
    };

    const changePage = page => {
        setAssignerSearchConfig({ curentPage: page });
    };

    const translatedProducts = products && translateProductName(products, currentLanguage, true);

    const addProduct = async categoryId => {
        await addCategoryForProducts({ categoryId: categoryId, productId: checkedProductsId });
        setCheckedProductsId([]);
        utils.confirm(
            () => updateProductsCategoryForFeatures({ categoryForAttributesId: categoryId, productId: checkedProductsId }),
            translations.setModal.header,
            translations.setModal.body
        );
    };

    const handleAdd = () => {
        utils.selectCategory(
            categoryId => addProduct(categoryId),
            translations.addModal
        );
    };

    const removeProduct = async () => {
        await deleteCategoryForProducts({ categoryId: searchConfig.categoryId, productId: checkedProductsId });
        setCheckedProductsId([]);
    };

    const handleRemove = () => {
        utils.confirm(
            removeProduct,
            translations.removeModal.header,
            translations.removeModal.body
        );
    };

    const buttons = {
        add: {
            action: handleAdd,
            color: 'success',
            isDisabled: checkedProductsId.length === 0,
            label: translations.buttons.add
        },
        remove: {
            action: handleRemove,
            color: 'danger',
            isDisabled: checkedProductsId.length === 0 || searchConfig.categoryTypeId === 2 || searchConfig.categoryId === null,
            label: translations.buttons.remove
        }
    };

    return products ? (
        <React.Fragment>
            <Label>
                <Input
                    type='checkbox'
                    checked={Array.isArray(searchConfig.productId)}
                    onChange={handleFilterChecked}
                    className='position-static m-0 p-0'
                    />
                <span className='ml-3'>{translations.showSelected}</span>
            </Label>
            <Badge className='d-block p-3'>Produktów zaznaczonych do przypisania: {checkedProductsId.length}</Badge>
            <ProductSearchForm performProductSearch={setAssignerSearchConfig} searchConfig={searchConfig} canSearchInSubcategories />
            <PageSizeChanger
                performSelect={handleChangePageSize}
                className='pt-3 pb-1'
                currentPageSize={searchConfig.pageSize}
            />
            <ProductsSynchronizationsTable
                checkedRowId={assignCategoriesId}
                setCheckedRowId={handleCheckRow}
                checkedProductsId={checkedProductsId}
                setCheckedProductsId={setCheckedProductsId}
                translatedProductsList={translatedProducts}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={searchConfig.curentPage + 1}
                        onPageChange={changePage}
                    />
            }
            <SectionAsideButtons
                buttons={buttons}
            />
        </React.Fragment>
    ) : <DefaultSpinner isLoading={!isGlobalLoading} color='black' />;
};

const mapStateToProps = state => ({
    currentLanguage: selectStoreCurrentLanguage(state),
    forceRefetch: state.refetchers.productsCategoryAssign,
    searchConfig: state.products.categoryAssigner.searchConfig,
    assignCategoriesId: state.products.categoryAssigner.assignCategories.id,
    isGlobalLoading: state.loaders.global
});

const mapDispatch = {
    getProducts,
    setAssignerSearchConfig,
    setAssignCategories,
    deleteCategoryForProducts,
    addCategoryForProducts,
    updateProductsCategoryForFeatures
};

ProductListSection.propTypes = propTypes;

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ProductListSection));