import React from 'react';
import PropTypes from 'prop-types';

import { Row, Alert } from 'reactstrap';
import ModalWithFinalForm from '../ModalWithFinalForm';
import FormFieldTextOrNumber from '../../formFields/FormFieldTextOrNumber';
import FormFieldWithCheckbox from '../../formFields/FormFieldWithCheckbox';

const propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    translations: PropTypes.object,
    unit: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        shortName: PropTypes.string,
        isDecimal: PropTypes.bool,
    })
};

const UnitsEditionModal = ({
    isOpen,
    setIsOpen,
    onSubmit,
    translations,
    unit,
    isAlertOpen,
    setIsAlertOpen,
}) => {
    const handleHideAlert = () => {
        setIsAlertOpen(false);
    };

    return (
        <ModalWithFinalForm
            isOpen={isOpen}
            initialValues={unit}
            setIsOpen={setIsOpen}
            onConfirm={onSubmit}
            header={unit ? translations.modalHeaderEdit : translations.modalHeaderCreate}
            buttons={{
                confirm: {
                    color: 'success',
                    label: translations.buttons.confirm,
                },
                cancel: {
                    label: translations.buttons.cancel,
                    color: 'danger'
                }
            }}
            modalBody={
                <React.Fragment>
                    <Alert
                        color='danger'
                        isOpen={isAlertOpen}
                        toggle={handleHideAlert}
                    >
                        <span>{translations.validationAlert}</span>
                    </Alert>
                    <Row className='my-2'>
                        <FormFieldTextOrNumber
                            type='text'
                            name='name'
                            label={translations.labels.name}
                        />
                    </Row>
                    <Row className='my-2'>
                        <FormFieldTextOrNumber
                            type='text'
                            name='shortName'
                            label={translations.labels.shortName}
                        />
                    </Row>
                    <Row className='my-2'>
                        <FormFieldWithCheckbox
                            name='isDecimal'
                            label={translations.labels.isDecimal.label}
                        />
                    </Row>
                </React.Fragment>
            }
        />
    );
};

UnitsEditionModal.propTypes = propTypes;

export default UnitsEditionModal;