import React from 'react';
import { useIntl } from 'react-intl';

import ProductCardSettingsSection from '../../../features/products/editTabs/ProductCardSettingsSection';
import ProductTabsSettingsSection from '../../../features/products/editTabs/ProductTabsSettingsSection';

import SettingsTabulator from '../../../components/layouts/SettingsTabulator';

const Card = () => {
    const intl = useIntl();
    const translations = intl.messages.card.view;

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'productTabs-section':
                return <ProductTabsSettingsSection translations={translations} />;
            case 'productSettings-section':
                return <ProductCardSettingsSection translations={translations} />;
            default:
                return null;
        }
    };

    return (
        <SettingsTabulator
            title={{
                label: null,
                iconClassName: null
            }}
            tabulatorHeader={{
                label: translations.title,
                iconClassName: 'fa fa-columns'
            }}
            refreshTabComponent={refreshTabComponent}
            tabs={[
                {
                    id: 'productTabs-section',
                    header: {
                        label: translations.tabs.tabs,
                        iconClassName: 'fa fa-columns'
                    }
                },
                {
                    id: 'productSettings-section',
                    header: {
                        label: translations.tabs.settings,
                        iconClassName: 'fa fa-cog'
                    }
                }
            ]}
        />
    );
};

export default Card;