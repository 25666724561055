import React from 'react';
import PropTypes from 'prop-types';

import FileSender from '../../../../../components/FileSender';

import { stringOrNumberPropTypes } from '../../../../../propTypes';

const propTypes = {
    getPromoSectionImage: PropTypes.func,
    deleteDoubleImageSectionImage: PropTypes.func,
    translations: PropTypes.object,
    sectionId: stringOrNumberPropTypes,
    section: PropTypes.object,
    setFile: PropTypes.func
};

const FileSenderAdBanners = ({
    translations,
    isDesktopImage,
    setFile,
    section,
    fileType
}) => {
    const image = section[isDesktopImage ? 'desktopImageSource' : 'mobileImageSource'];

    return (
        <React.Fragment>
            <FileSender
                validateFormats={['jpg', 'png', 'jpeg', 'gif', 'mp4', 'webm', 'webp']}
                buttons={translations.inputs.file}
                photo={image}
                withPreview
                fileKey='Image'
                fileType={fileType}
                setFile={setFile}
                graphicTypeToEnableGuide={fileType === 1 ? 'banner' : 'productCard'}
            />
        </React.Fragment>
    );
};

FileSenderAdBanners.propTypes = propTypes;

export default FileSenderAdBanners;