import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getIntegrationGoogleSearchConsole, updateIntegrationGoogleSearchConsole } from '../../../redux/methods/integrations';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';

import { Container, Form, Alert, Row, Col } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';

const propTypes = {
    getIntegrationGoogleSearchConsole: PropTypes.func,
    updateIntegrationGoogleSearchConsole: PropTypes.func,
};

const GoogleSearchConsole = ({ getIntegrationGoogleSearchConsole, updateIntegrationGoogleSearchConsole }) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.googleSearchConsole;

    const [ data ] = useFetch(getIntegrationGoogleSearchConsole);

    return data ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-google'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateIntegrationGoogleSearchConsole}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container>
                                <Row className='my-2 w-100'>
                                    <Col>
                                        <FormFieldSwitchPill label={translations.labels.enabled} name='enabled' />
                                    </Col>
                                </Row>
                                <Row className='my-3 w-100'>
                                    <Col>
                                       <Alert>
                                        W polu <b>Klucz</b> należy wpisać wartość &apos;<b>content</b>&apos; widniejącą w tagu &apos;<b>meta</b>&apos; po wybraniu w Google search console sposobu weryfikacji o nazwie &apos;<b>Tag HTML</b>&apos;. Poniżej zaznaczono przykładową wartość:<br/><br/>
                                        &lt;meta name=&ldquo;google-site-verification&rdquo; content=&ldquo;<b>zW_R3y_kLOyRWjRP0GKWQxuJUMR_V0WVDKhUesAQAK3</b>&rdquo; /&gt;
                                       </Alert>
                                    </Col>
                                </Row>
                                <Row className='my-2 w-100'>
                                    <Col>
                                       <FormFieldTextOrNumber label={translations.labels.key} name='key' />
                                    </Col>
                                </Row>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconClass='fa fa-google'/>;
};

const mapDispatch = {
    getIntegrationGoogleSearchConsole,
    updateIntegrationGoogleSearchConsole,
};

GoogleSearchConsole.propTypes = propTypes;

export default connect(null, mapDispatch)(GoogleSearchConsole);