import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Alert } from 'reactstrap';
import TableGenerator from '../../generators/TableGenerator';

import styles from './index.module.scss';

const propTypes = {
    translations: PropTypes.shape({
        headerLabels: PropTypes.shape({
            categoryShop: PropTypes.string,
            categoryCeneo: PropTypes.string,
            actions: PropTypes.string,
        }).isRequired,
        tooltips: PropTypes.shape({
            remove: PropTypes.string
        }),
        emptyTable: PropTypes.string,
        nameFiller: PropTypes.string,
    }),
    removeAction: PropTypes.func,
    mappings: PropTypes.array,
};

const CeneoCategoriesTable = ({ mappings, translations, removeAction }) => {

    const [ isAlertOpen, setIsAlertOpen ] = useState(false);

    const handleHideAlert = () => {
        setIsAlertOpen(false);
    };

    const deleteAction = (id) => () => {
        removeAction(id);
    };

    const notSupportedMappingsIds =
        mappings.filter(mapping => mapping.status === 0)
            .map(mapping => mapping.id);

    useEffect(() => {
        if(notSupportedMappingsIds.length > 0) {
            setIsAlertOpen(true);
        }
    }, [notSupportedMappingsIds]);

    return (
        <React.Fragment>
            <Alert
                className={clsx('fixed-top', styles.alert)}
                color='danger'
                isOpen={isAlertOpen}
                toggle={handleHideAlert}
            >
                <span>{translations.unSupportedAlert}</span>
            </Alert>
            <TableGenerator
                items={mappings}
                expectedActions={['delete']}
                actions={{deleteAction}}
                translations={translations}
                //workaround to visualy mark unsupported items
                checkedItemsIds={notSupportedMappingsIds}
                highlightOnSelectStyle={styles.highlightOnSelectStyle}
                columns={[
                    {
                        header: translations.headerLabels.categoryShop,
                        value: 'categoryName',
                        filler: translations.nameFiller
                    },
                    {
                        header: translations.headerLabels.categoryCeneo,
                        value: 'ceneoCategoryName',
                        filler: translations.nameFiller
                    },
                    {
                        header: translations.headerLabels.actions,
                        type: 'actions'
                    }
                ]}
            />
        </React.Fragment>
    );
};

CeneoCategoriesTable.propTypes = propTypes;

export default CeneoCategoriesTable;