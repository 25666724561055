import React from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.node
};

const FullViewSpinnerWrapper = ({ isLoading, children }) => {
    return isLoading ? (
        <div className={styles.container}>
            <Spinner />
        </div>
    ) : (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

FullViewSpinnerWrapper.propTypes = propTypes;

export default FullViewSpinnerWrapper;