export const decimalOptionEnum = {
    true: 'decimal',
    false: 'integer'
};

export const measureTypeEnum = {
    storage: 1,
    sales: 2,
    purchases: 3,
    reference: 4,
    additional: 5
};