import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateOrderDetails } from '../../../../redux/methods/orders';

import { generateOrdersConfig, generateAvailableStatuses } from '../../../../utils/orders';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldWithCalendarPicker from '../../../../components/formFields/FormFieldWithCalendarPicker';
import FormFieldStatus from '../../../../components/formFields/FormFieldStatus';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';

import { orderConfigsPropTypes, formUtilsHocPropTypes, stringOrNumberPropTypes, orderDetailsPropTypes } from '../../../../propTypes';

import styles from './index.module.scss';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    updateOrderDetails: PropTypes.func,
    orderStatuses: orderConfigsPropTypes,
    isConnectedToERP: PropTypes.bool,
    id: stringOrNumberPropTypes,
    order: orderDetailsPropTypes,
};

const OrderDetails = ({ utils, updateOrderDetails, orderStatuses, isConnectedToERP, order, id }) => {
    const intl = useIntl();
    const translations = intl.messages.orders.edit;

    const handleOnSubmit = formData => {
        updateOrderDetails(id, formData);
    };

    const statusesConfig = generateOrdersConfig(orderStatuses, utils.currentLanguage());

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={order}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        const { values } = form.getState();
                        const selectedStatus = statusesConfig.find(status => status.id === values.orderStatusId);
                        const availableStatuses = generateAvailableStatuses(statusesConfig, order.nextStatuses, values.orderStatusId);

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='px-0' fluid>
                                    <Row className='flex-md-nowrap mt-3 d-inline-flex'>
                                        <FormFieldStatus
                                            name='orderStatusId'
                                            statuses={availableStatuses}
                                            selectedStatus={selectedStatus}
                                            label={translations.inputs.status}
                                            forcedColor={selectedStatus.color}
                                        />
                                    </Row>
                                    {
                                        !isConnectedToERP &&
                                            <Row className='flex-md-nowrap mt-3'>
                                                <FormFieldWithCalendarPicker
                                                    label={translations.inputs.shipmentDate}
                                                    name='shipmentDate'
                                                    storeCurrentLanguage={utils.currentLanguage()}
                                                    minDate={values.validFrom}
                                                    className='w-100 mt-3 mt-md-0 mr-md-3'
                                                />
                                                <FormFieldTextOrNumber
                                                    name='invoiceNumber'
                                                    label={translations.inputs.invoiceNumber}
                                                    className='w-100 mt-3 mt-md-0'
                                                />
                                            </Row>
                                    }
                                    <Row className={clsx('flex-md-nowrap mt-3', styles.commentRow)}>
                                        <FormFieldTextOrNumber
                                        type='textarea'
                                            name='comment'
                                            label={translations.inputs.comment}
                                            className='w-100 mt-3 mt-md-0 h-100'
                                            inputClassName='h-100'
                                        />
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

OrderDetails.propTypes = propTypes;

const mapStateToProps = (state) => ({
    orderStatuses: state.appInit.orderStatuses,
    isConnectedToERP: state.appInit.isConnectedToERP
});

const mapDispatch = {
    updateOrderDetails,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(OrderDetails));