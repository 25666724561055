import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { socialMediaOptions } from '../../../../../config';

import { getFooterSettingsMain, updateFooterSettingsMain } from '../../../../../redux/methods/menu';

import useFetch from '../../../../../hooks/useFetch';
import { withFormUtils } from '../../../../../hoc/withFormUtils';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import SectionEditFormButtons from '../../../../../components/SectionEditFormButtons';

import FormFieldColorPicker from '../../../../../components/formFields/FormFieldColorPicker';
import FormFieldWithCheckbox from '../../../../../components/formFields/FormFieldWithCheckbox';
import FormFieldTextOrNumber from '../../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldTranslationsQuill from '../../../../../components/formFields/FormFieldTranslationsQuill';
import Tabulator from '../../../../../components/Tabulator';

import { formUtilsHocPropTypes } from '../../../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getFooterSettingsMain: PropTypes.func,
    updateFooterSettingsMain: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const FooterSettings = ({
    utils,
    getFooterSettingsMain,
    updateFooterSettingsMain,
    forceRefetch
}) => {
    const intl = useIntl();
    const translations = intl.messages.menuSettings.footer.sections.footer;

    const config = { translations: [
        'additionalInfoMobile', 'additionalInfoTablet', 'additionalInfoDesktop',
        'contactMobile', 'contactTablet', 'contactDesktop', ] };

    const handleOnSubmit = async form => {
        await updateFooterSettingsMain(utils.generateFormToSubmitValues(form, config));
    };

    const [ settings ] = useFetch(getFooterSettingsMain, null, [], forceRefetch);

    return settings ? (
        <Container className='px-3' fluid>
            <FinalForm
                initialValues={utils.generateFormInitialValues(settings, config)}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>

                            <Row style={{marginBottom: '1.5em'}}>
                                <Col lg="6">
                                    <Card style={{height: '100%'}}>
                                        <CardHeader>{translations.inputs.showCaption}</CardHeader>
                                        <CardBody>
                                            <FormFieldWithCheckbox name='showMenu' label={translations.inputs.showMenu} />
                                            <FormFieldWithCheckbox name='menuOnLeft' label={translations.inputs.menuOnLeft} />
                                            <FormFieldWithCheckbox name='showSocialMedia' label={translations.inputs.showSocialMedia} />
                                            <FormFieldWithCheckbox name='showContactInfo' label={translations.inputs.showContactInfo} />
                                            <FormFieldWithCheckbox name='showAditionalInfo' label={translations.inputs.showaAditionalInfo} />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="6">
                                    <Card style={{height: '100%'}}>
                                        <CardHeader>{translations.inputs.colorsCaption}</CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col lg="6">
                                                    <FormFieldColorPicker
                                                        name='footerBackgroundColor'
                                                        label={translations.inputs.backgroundColor}
                                                        button={translations.inputs.colorButton}
                                                    />
                                                </Col>
                                                <Col lg="6">
                                                    <FormFieldColorPicker
                                                        name='footerFontColor'
                                                        label={translations.inputs.fontColor}
                                                        button={translations.inputs.colorButton}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card>
                                <CardHeader>{translations.inputs.socialMediaCaption}</CardHeader>
                                <CardBody style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {socialMediaOptions.map(option => (
                                        <FormFieldTextOrNumber
                                            className={clsx('my-1', styles.textInput)}
                                            key={`${option}-socialMedia-linkField`}
                                            name={`socialMediaLinks.${option}`}
                                            type='text'
                                            label={translations.inputs.socialMedia[option]}
                                        />
                                    ))}
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>{translations.inputs.contactCaption}</CardHeader>
                                <CardBody>
                                    <Tabulator
                                        listOfElements={[
                                            {
                                                label: 'Mobile',
                                                id: 'contact-mobile',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='contactMobile'
                                                    />
                                            },
                                            {
                                                label: 'Tablet',
                                                id: 'contact-tablet',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='contactTablet'
                                                    />
                                            },
                                            {
                                                label: 'Desktop',
                                                id: 'contact-desktop',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='contactDesktop'
                                                    />
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>{translations.inputs.additionalInfoCaption}</CardHeader>
                                <CardBody>
                                    <Tabulator
                                        listOfElements={[
                                            {
                                                label: 'Mobile',
                                                id: 'additionalInfo-mobile',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='additionalInfoMobile'
                                                    />
                                            },
                                            {
                                                label: 'Tablet',
                                                id: 'additionalInfo-tablet',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='additionalInfoTablet'
                                                    />
                                            },
                                            {
                                                label: 'Desktop',
                                                id: 'additionalInfo-desktop',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='additionalInfoDesktop'
                                                    />
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>

                            <SectionEditFormButtons className='px-2' formReset={form.reset} />
                        </Form>
                    );
                }}
            />
        </Container>
    ) : null;
};

FooterSettings.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.bottomMenu,
});

const mapDispatch = {
    getFooterSettingsMain,
    updateFooterSettingsMain,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(FooterSettings));