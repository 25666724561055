const arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = (array, from, to) => {
    const newArray = array.slice();
    arrayMoveMutate(newArray, from, to);
    return newArray;
};

export default arrayMove;
