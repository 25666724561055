import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { deleteProductAdditionalCategories, addNewAdditionalCategory, getProductCategories } from '../../../../redux/methods/products';
import useFetch from '../../../../hooks/useFetch';

import { Container, Row, Button } from 'reactstrap';

import CategoriesTable from '../../../../components/tables/CategoriesTable';

import styles from './index.module.scss';
import { stringOrNumberPropTypes } from '../../../../propTypes';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    id: stringOrNumberPropTypes,
    productCategories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        categoryList: PropTypes.arrayOf(PropTypes.string),
    })),
    deleteProductAdditionalCategories: PropTypes.func,
    addNewAdditionalCategory: PropTypes.func,
    getProductCategories: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const ProductEditAdditionalCategories = ({
    utils, deleteProductAdditionalCategories, id, addNewAdditionalCategory, getProductCategories, forceRefetch,
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.additionalCategories;

    const [checkedCheckboxList, setCheckedCheckboxList] = useState([]);

    const [ categories ] = useFetch(getProductCategories, id, [], forceRefetch);

    useEffect(() => {
        setCheckedCheckboxList([]);
    }, [categories]);

    const handleResetCheckedCheckboxList = () => {
        setCheckedCheckboxList([]);
    };

    const handleCheckboxClick = id => {
        if(checkedCheckboxList.includes(id)){
            setCheckedCheckboxList(checkedCheckboxList.filter(item => item !== id));
        } else {
            setCheckedCheckboxList([...checkedCheckboxList, id]);
        }
    };

    const handleRemoveAction = categoryId => {
        deleteProductAdditionalCategories(id, [categoryId]);
    };

    const handleOpenDeleteModal = () => {
        if(checkedCheckboxList.length > 0){
            utils.confirm(
                () => deleteProductAdditionalCategories(id, checkedCheckboxList),
                translations.deleteModalTranslations.header,
                translations.deleteModalTranslations.body
            );
        }
    };

    const handleOpenCategorySelectModal = () => {
        utils.selectCategory(
            categoryId => addNewAdditionalCategory(id, categoryId),
            translations.categorySelectionModal.body
        );
    };

    return categories ? (
        <React.Fragment>
            <Container fluid>
                <CategoriesTable
                    categories={categories}
                    checkedCheckboxList={checkedCheckboxList}
                    columnNames={translations.columnNames}
                    tooltipTranslation={translations.tooltipTranslation}
                    deleteModalTranslations={translations.deleteSingleModalTranslations}
                    emptyTranslation= {translations.emptyTranslation}
                    checkboxAction={handleCheckboxClick}
                    removeAction={handleRemoveAction}
                />
                <aside className={styles.footer}>
                    <Button
                        outline
                        type="button"
                        onClick={handleOpenDeleteModal}
                        color="danger"
                        disabled={!(checkedCheckboxList.length > 0)}>
                        <i className="fa fa-remove mr-2"/>
                        <span>{translations.buttons.delete}</span>
                    </Button>
                    <Button color="primary" onClick={handleOpenCategorySelectModal}>
                        <i className="fa fa-plus mr-2"/>
                        <span>{translations.buttons.add}</span>
                    </Button>
                </aside>
                <Row className="d-flex flex-row align-items-start mt-5">
                    <Button outline type="button" onClick={handleResetCheckedCheckboxList} color="danger">
                        <i className="fa fa-remove mr-2"/>
                        <span>{translations.buttons.cancel}</span>
                    </Button>
                </Row>
            </Container>
        </React.Fragment>
    ) : null;
};

ProductEditAdditionalCategories.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.productsAdditionalCategories,
});

const mapDispatch = ({
    deleteProductAdditionalCategories,
    addNewAdditionalCategory,
    getProductCategories,
});

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ProductEditAdditionalCategories));

