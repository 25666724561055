import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import ContainerWithCard from '../../../components/ContainerWithCard';
import ButtonsTable from '../../../components/tables/ButtonsTable';
import { Button } from 'reactstrap';

import useFetch from '../../../hooks/useFetch';
import { generateEditLinkForButton } from '../../../utils/visualSettings';
import { getButtonsList, deleteButton } from '../../../redux/methods/visualSettings';

import styles from './index.module.scss';

const propTypes = {
    getButtonsList: PropTypes.func,
    forceRefetch: PropTypes.bool,
    history: PropTypes.object,
    deleteButton: PropTypes.func,
};

const Buttons = ({ getButtonsList, forceRefetch, history, deleteButton }) => {
    const intl = useIntl();
    const translations = intl.messages.visualSettings.buttons;

    const [ buttons ] = useFetch(getButtonsList, null, [], forceRefetch);

    const handleAddButton = () => {
        history.push(generateEditLinkForButton(0));
    };

    const handleDelete = (id) => () => {
        deleteButton(id);
    };

    return buttons ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-flickr'>
            <ButtonsTable
                history={history}
                translations={translations}
                headerLabels={translations.headerLabels}
                buttons={buttons}
                deleteAction={handleDelete}
            />
            <aside className={styles.footer}>
                <Button color='primary' onClick={handleAddButton}>
                    <i className='fa fa-plus mr-2'/>
                    <span>{translations.addButton}</span>
                </Button>
            </aside>
        </ContainerWithCard>
    ) : null;
};

Buttons.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.buttons,
});

const mapDispatch = {
    getButtonsList,
    deleteButton
};

export default connect(mapStateToProps, mapDispatch)(Buttons);