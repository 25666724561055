/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { productInOrderPropTypes } from '../../../propTypes';

import PhotoPlaceholder from '../../../assets/photo_placeholder.svg';
import styles from './index.module.scss';
import TableGenerator from '../../generators/TableGenerator';
import getConfig from '../../../getConfig';


const propTypes = {
    products: PropTypes.arrayOf(productInOrderPropTypes),
    clickAction: PropTypes.func,
};

const ProductsInOrderTable = ({ products, translations, onClickAction, onEditAction }) => {
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const handleOnClick = id => () => {
        onClickAction(id);
    };

    const editAction = id => () => {
        const productToEdit = products.find(p => p.id === id);
        onEditAction(productToEdit);
    };

    const ImageCell = item => (
        <img
            className={styles.image}
            src={item.productImage ? `${apiUrl}${item.productImage}` : PhotoPlaceholder}
            alt={item.productImageAlternativeText || translations.emptyLogoAlt}
        />
    );

    return (
        <TableGenerator
            items={products}
            translations={translations}
            defaultAction={handleOnClick}
            expectedActions={['edit']}
            actions={{editAction}}
            columns={[
                {
                    header: translations.headers.index,
                    value: 'index'
                },
                {
                    header: translations.headers.image,
                    type: 'custom',
                    customCell: ImageCell,
                    additionalStyle: styles.imageCell
                },
                {
                    header: translations.headers.name,
                    value: 'name',
                    additionalStyle: styles.nameCell
                },
                {
                    header: translations.headers.id,
                    value: 'id'
                },
                {
                    header: translations.headers.amount,
                    value: 'amount'
                },
                {
                    header: translations.headers.realized,
                    value: 'realized'
                },
                {
                    header: translations.headers.grossPrice,
                    value: 'grossPrice'
                },
                {
                    header: translations.headers.grossValue,
                    value: 'grossValue'
                },
                {
                    header: translations.headers.discountName,
                    value: 'discountName'
                },
                {
                    header: translations.headers.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

ProductsInOrderTable.propTypes = propTypes;

export default ProductsInOrderTable;