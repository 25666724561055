import React from 'react';
import PropTypes from 'prop-types';

import { stringOrNumberPropTypes } from '../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    sections: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: stringOrNumberPropTypes,
        link: PropTypes.string,
    }))),
};

const OrderDetails = ({ sections }) => {
    return (
        <div className={styles.sectionContainer}>
            {
                sections.map((section, index) => {
                    return <div key={`${section}${index}--OrderDetails`} className={styles.section}>
                        {section.map(line => {
                            return (
                                <span key={`${line.label}--OrderDetails-line`} className={styles.line}>
                                    <span>{line.label}:</span>
                                    {
                                        line.link && line.isExternal
                                            ? <a className={styles.value} href={line.link} target="_blank" rel="noreferrer">{line.value}</a>
                                        : line.link
                                            ? <a className={styles.value} href={line.link}>{line.value}</a>
                                        : <span className={styles.value}>{line.value}</span>
                                    }
                                </span>
                            )
                            ;
                        })}
                    </div>;
                })
            }
        </div>
    );
};

OrderDetails.propTypes = propTypes;

export default OrderDetails;