import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getProductDeliveryGlobalSettings, updateProductDeliveryGlobalSettings, getProductDeliveryErpFields, getProductDeliveryTemplateFields } from '../../../redux/methods/products';

import { Form, Row } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';
import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldWithCheckbox from '../../../components/formFields/FormFieldWithCheckbox';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';
import useFetch from '../../../hooks/useFetch';
import FormFieldTranslationsQuill from '../../../components/formFields/FormFieldTranslationsQuill';
import { withFormUtils } from '../../../hoc/withFormUtils';
import { nestedButtonsGenerator } from '../../../utils/emails';
import { Link } from 'react-router-dom';

const Delivery = ({
    getProductDeliveryTemplateFields,
    getProductDeliveryGlobalSettings,
    updateProductDeliveryGlobalSettings,
    getProductDeliveryErpFields,
    forceRefetch,
    isConnectedToERP,
    utils,
}) => {
    const intl = useIntl();
    const translations = intl.messages.delivery;

    const [ erpFields ] = useFetch(getProductDeliveryErpFields);
    const [ templateFields ] = useFetch(getProductDeliveryTemplateFields);
    const [ deliveryGlobalSettings ] = useFetch(getProductDeliveryGlobalSettings, null, [] , forceRefetch);

    const config = { translations: ['deliveryTabTemplate'] };

    const handleOnSubmit = formData => {
        updateProductDeliveryGlobalSettings(utils.generateFormToSubmitValues(formData, config));
    };

    const erpFieldsWithNullOpt = erpFields && [{ id: null, label: translations.nullOpt }, ...erpFields];

    return deliveryGlobalSettings && erpFieldsWithNullOpt && templateFields ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-truck'>
            <FinalForm
                initialValues={utils.generateFormInitialValues(deliveryGlobalSettings, config)}
                keepDirtyOnReinitialize
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form }) => {
                    const handleCustomButtonGenerator = (editor) => {
                        nestedButtonsGenerator(editor, templateFields, utils.currentLanguage(), translations.insertValueButton);
                    };

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row className='mt-2'>
                                <FormFieldTextOrNumber
                                    label={translations.fields.defaultDeliveryCost}
                                    name='defaultDeliveryCost'
                                    type='number'
                                    min={0}
                                    step='0.01'
                                    parse={value => value ? parseFloat(value) : null}
                                />
                            </Row>
                            {isConnectedToERP &&
                                <Row className='mt-2'>
                                    <FormFieldWithDropdown
                                        name='deliveryCostFieldFromErp'
                                        label={translations.fields.deliveryCostFieldFromErp}
                                        options={erpFieldsWithNullOpt}
                                    />
                                </Row>
                            }
                            <Row>
                                <small className='text-danger'>{translations.info.defaultDeliveryCost}</small>
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldTranslationsQuill
                                    form={form}
                                    utils={utils}
                                    propertyName="deliveryTabTemplate"
                                    header={translations.fields.deliveryTabTemplate}
                                    tinyMCEButtonGenerator={handleCustomButtonGenerator}
                                />
                            </Row>
                            <Row className='mb-3'>
                                <small className='text-danger'>{translations.info.deliveryTabTemplate} <Link to="/articlePresentation/card">{translations.info.deliveryTabTemplateLink}</Link></small>
                            </Row>
                            <Row className='mt-2'>
                                <FormFieldWithCheckbox
                                    name='showDeliveryTime'
                                    label={translations.fields.showDeliveryTime}
                                />
                            </Row>
                            <Row className='mt-2'>
                                <FormFieldTextOrNumber
                                    label={translations.fields.defaultDeliveryTime}
                                    name='defaultDeliveryTime'
                                    type='number'
                                    min={0}
                                    max={365}
                                    step='1'
                                    parse={value => value ? parseInt(value) : null}
                                />
                            </Row>
                            {isConnectedToERP &&
                                <Row className='mt-2'>
                                    <FormFieldWithDropdown
                                        name='deliveryTimeFieldFromErp'
                                        label={translations.fields.deliveryTimeFieldFromErp}
                                        options={erpFieldsWithNullOpt}
                                    />
                                </Row>
                            }
                            <Row>
                                <small className='text-danger'>{translations.info.defaultDeliveryTime}</small>
                            </Row>
                            <Row className='mt-2'>
                                <FormFieldWithCheckbox
                                    name='showDeliveryTimeInSummary'
                                    label={translations.fields.showDeliveryTimeInSummary}
                                />
                            </Row>
                            <SectionEditFormButtons formReset={form.reset}/>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.productsDeliveryGlobal,
    isConnectedToERP: state.appInit.isConnectedToERP,
});

const mapDispatch = {
    getProductDeliveryGlobalSettings,
    updateProductDeliveryGlobalSettings,
    getProductDeliveryErpFields,
    getProductDeliveryTemplateFields,
};

export default connect(mapStateToProps, mapDispatch)(withFormUtils(Delivery));