import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { cssGradientGenerator } from '../../utils/attributes';

import { Button } from 'reactstrap';

import ColorItem from './ColorItem';
import ColorPicker from '../ColorPicker';

import styles from './index.module.scss';

const propTypes = {
    colors: PropTypes.arrayOf(
        PropTypes.shape({
            orderIndex: PropTypes.number,
            value: PropTypes.string
        })
    ).isRequired,
    removeColor: PropTypes.func,
    addColor: PropTypes.func,
    updateColor: PropTypes.func
};

const ColorEditionController = ({ colors, addColor, removeColor, updateColor }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.colorEditionController;

    const [ isColorPickerVisible, setIsColorPickerVisible ] = useState(false);

    const generatedBackgroundColor = cssGradientGenerator(colors.map(color => color.value), -225);

    const handleShowColorPicker = () => {
        setIsColorPickerVisible(true);
    };

    const handleHideColorPicker = () => {
        setIsColorPickerVisible(false);
    };

    const handleAddColor = color => {
        const highestOrderIndex = colors.length === 0 ? -1 : Math.max(...colors.map(color => Number(color.orderIndex)));
        const colorToAdd = {
            value: color,
            orderIndex: highestOrderIndex + 1
        };

        addColor(colorToAdd);
    };

    return (
        <div className={styles.container}>
            <div
                style={{ background: generatedBackgroundColor }}
                className={clsx(styles.preview, colors.length === 0 && styles.previewEmpty)}
            />
            <h5>{translations.colorsHeader}</h5>
            <span className={clsx(colors && colors.length > 0 ? styles.colors : styles.placeholder)}>
                {
                    colors && colors.length > 0 ? (
                        colors.map(color =>
                            <ColorItem
                                key={`colorItem-${color.orderIndex}`}
                                color={color}
                                tooltipLabels={{
                                    edit: translations.actions.edit,
                                    remove: translations.actions.remove
                                }}
                                editAction={updateColor}
                                removeAction={removeColor}
                            />
                        )
                    ) : translations.emptyPlaceholder
                }
            </span>
            <span>
                <Button color="primary mt-2" type="button" onClick={handleShowColorPicker}>
                    <i className="fa fa-plus mr-2"/>
                    <span>{translations.actions.add}</span>
                </Button>
                {
                    isColorPickerVisible &&
                        <ColorPicker onAdd={handleAddColor} onClose={handleHideColorPicker}/>
                }
            </span>
        </div>
    );
};

ColorEditionController.propTypes = propTypes;

export default ColorEditionController;