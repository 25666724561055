import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { filterItemsList } from '../../../utils/components';

import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { Table, Input } from 'reactstrap';

import arrayMove from '../../generators/TableGenerator/arrayMove';
import styles from './index.module.scss';
import TableRow from './TableRow';

const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement(props => <TableRow {...props} />);

const propTypes = {
    parameters: PropTypes.arrayOf(PropTypes.shape({
        tag: PropTypes.string,
        name: PropTypes.string,
        showOnMain: PropTypes.bool,
        showOnTab: PropTypes.bool,
    })),
    setParameters: PropTypes.func,
    headerLabels: PropTypes.shape({
        name: PropTypes.string,
        isVisibleParameters: PropTypes.string,
        isVisibleCard: PropTypes.string,
    }).isRequired,
};

const ArticlePresentationCardTable = ({ device, type, parameters, setParameters, headerLabels, translations, setIsEdited, checkLimit = 0 }) => {

    const isCheckedAll = !parameters.find(param => param.parameters[type][device].show === false);
    const isCheckedLimitReached = checkLimit > 0 && parameters.filter(param => param.parameters[type][device].show).length >= checkLimit;

    const [ searchString, setSearchString ] = useState('');
    const handleChangeSearchString = e => setSearchString(e.target.value);
    const clearSearchString = () => setSearchString('');

    const getNameFunction = i => translations[i.name] || i.name;

    const filteredParameters = parameters?.length > 0 ? filterItemsList(parameters, searchString, null, getNameFunction) : [];

    const handleCheckboxAction = (tag, type) => () => () => {
        setIsEdited(true);

        const newParameters = parameters.map(param => {
            if (param.tag !== tag) {
                return param;
            }

            const newParam = { ...param };
            newParam.parameters[type][device].show = !newParam.parameters[type][device].show;
            return newParam;
        });

        setParameters(newParameters);
    };

    const handleCheckBoxAll = () => {
        setIsEdited(true);
        setParameters(parameters.map(param => {
            const newParam = { ...param };
            newParam.parameters[type][device].show = !isCheckedAll;
            return newParam;
        }));
    };

    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        setIsEdited(true);
        setParameters(oldItems => arrayMove(oldItems, oldIndex, newIndex));
    }, [setIsEdited, setParameters]);

    return (
        <>
            <div className="position-relative d-flex align-items-center justify-content-end mb-2">
                <Input
                    value={searchString}
                    type='text'
                    onChange={handleChangeSearchString}
                    placeholder="Wyszukaj"
                    className='p-2'
                />
                <div className={clsx(styles.clearButton, 'pr-2')}
                    style={{visibility: searchString === '' ? 'hidden' : 'visible'}}
                    onClick={clearSearchString}>
                    <i className="fa fa-remove" />
                </div>
            </div>
            <Table responsive hover size='sm'>
                <thead>
                    <tr>
                        <th className={styles.header}></th>
                        <th className={styles.columnName}>{headerLabels.name}</th>
                        <th className={styles.columnIsVisible}>
                            {checkLimit === 0 &&
                                <Input
                                    checked={isCheckedAll}
                                    type='checkbox'
                                    onChange={handleCheckBoxAll}
                                    className='position-static m-0 p-0 mr-2'
                                />
                            }
                            {headerLabels.isVisible}
                            {checkLimit !== 0 &&
                                <span> (max: {checkLimit})</span>
                            }
                        </th>
                    </tr>
                </thead>
                <SortableCont
                    key={type}
                    onSortEnd={onSortEnd}
                    axis="y"
                    lockAxis="y"
                    lockToContainerEdges={true}
                    lockOffset={["30%", "50%"]}
                    useDragHandle={true}
                >
                    {
                        filteredParameters.map((param, index) =>
                            <SortableItem
                                key={param.tag}
                                device={device}
                                type={type}
                                getNameFunction={getNameFunction}
                                param={param}
                                handleCheckboxAction={handleCheckboxAction}
                                index={index}
                                isCheckedLimitReached={isCheckedLimitReached}
                            />
                        )
                    }
                </SortableCont>
            </Table>
        </>
    );
};

ArticlePresentationCardTable.propTypes = propTypes;

export default ArticlePresentationCardTable;