import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getAgreementTypes, editAgreementBasicData } from '../../../../redux/methods/agreements';
import useFetch from '../../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row, Col } from 'reactstrap';

import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldIsActiveDropdown from '../../../../components/formFields/FormFieldIsActiveDropdown';
import FormFieldMultipleDropDown from '../../../../components/formFields/FormFieldMultipleDropDown';
import FormFieldTranslations from '../../../../components/formFields/FormFieldTranslations';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import { formUtilsHocPropTypes } from '../../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    history: PropTypes.object,
    basicData: PropTypes.shape({
        translations: PropTypes.translations,
        shortName: PropTypes.string,
        active: PropTypes.bool,
    }),
    getAgreementTypes: PropTypes.func,
    editAgreementBasicData: PropTypes.func,
};

const AgreementEditBasicData = ({  utils, history, basicData, getAgreementTypes, editAgreementBasicData }) => {

    const intl = useIntl();
    const translations = intl.messages.agreements.edit.tabs.basic;

    const config = { translations: ['translations'] };
    const [ agreementTypes ] = useFetch(getAgreementTypes);

    const handleOnSubmit = async formData => {
        const dataToSend = utils.generateFormToSubmitValues(formData, config);
        const agreementId = await editAgreementBasicData(dataToSend);
        history.push(`/agreements/list/edit?agreementID=${agreementId}`);
    };

    return agreementTypes ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={utils.generateFormInitialValues(basicData, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        //const { values } = form.getState();
                        //const currentAgreement = agreementTypes.find(agr => agr.agreementTypeId === values.agreementTypeId);

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container fluid className='mt-3 px-0'>
                                    <Row className='mb-3'>
                                        <Col className='mt-2'>
                                            <FormFieldIsActiveDropdown
                                                name='isActive'
                                                translations={translations.labels.status}
                                            />
                                        </Col>
                                        <Col className='mt-2'>
                                            <FormFieldIsActiveDropdown
                                                name='isRequired'
                                                translations={translations.labels.required}
                                            />
                                        </Col>
                                    </Row>

                                    <FormFieldTranslations
                                        form={form}
                                        modalHeader={translations.labels.name}
                                        propertyName='translations'
                                        currentIso={utils.currentLanguage()}
                                        label={translations.labels.name}
                                        className='w-100'
                                    />

                                    <div className='mt-3'>
                                        <FormFieldMultipleDropDown
                                            name='agreementTypesId'
                                            label={translations.labels.type}
                                            items={agreementTypes.map(({agreementTypeId, name}) => ({ id: agreementTypeId, name: name}))}
                                        />
                                    </div>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

AgreementEditBasicData.propTypes = propTypes;

const mapDispatch = {
    getAgreementTypes,
    editAgreementBasicData,
};

export default withFormUtils(connect(null, mapDispatch)(withRouter(AgreementEditBasicData)));