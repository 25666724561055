import React from 'react';
import PropTypes from 'prop-types';

import SearchForm from '../generators/SearchForm';

import { manufacturersSearchConfig } from '../../config/searchConfigs';

const propTypes = {
    onSubmit: PropTypes.func,
    translations: PropTypes.shape({
        manufacturersSearch: PropTypes.shape({
            name: PropTypes.string
        }),
        searchButton: PropTypes.string,
        resetButton: PropTypes.string,
    }),
    searchConfig: PropTypes.object
};

const ManufacturersSearchForm = ({ onSubmit, translations, searchConfig }) => {
    return (
        <SearchForm
            searchConfig={searchConfig}
            defaultSearchConfig={{...manufacturersSearchConfig}}
            translations={{
                buttons: {
                    submit: translations.searchButton,
                    reset: translations.resetButton,
                }
            }}
            inputs={[
                {
                    type: 'text',
                    label: translations.manufacturersSearch.name,
                    name: 'name',
                }
            ]}
            performSearch={onSubmit}
        />
    );
};

ManufacturersSearchForm.propTypes = propTypes;

export default ManufacturersSearchForm;