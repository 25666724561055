/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal as CoreUiModal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import FormFieldTextOrNumber from '../../formFields/FormFieldTextOrNumber';

import { formUtilsHocPropTypes } from '../../../propTypes';

import { withFormUtils } from '../../../hoc/withFormUtils';

import styles from './index.module.scss';
import clsx from 'clsx';

const propTypes = {
    utils: formUtilsHocPropTypes,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    translations: PropTypes.object,
    weight: PropTypes.object
};

const ShippingFormWeightEditionModal = ({ utils, isOpen, setIsOpen, onSubmit, translations, weight }) => {

    const handleOnCancel = () => {
        setIsOpen(false);
    };

    const handleOnSubmit = (form) => {
        onSubmit(form);
    };

    return weight ? (
        <CoreUiModal isOpen={isOpen}>
            <FinalForm
                initialValues={weight}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form }) => {
                    return (
                        <div>
                            <ModalHeader>{translations.title}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleSubmit}>
                                    <div>
                                        <div className='my-2'>
                                            <FormFieldTextOrNumber
                                                name='weightFrom'
                                                label={translations.inputs.weightFrom}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <div className={clsx("d-flex justify-content-between", styles.modalFooterButtons)}>
                                    <Button onClick={handleOnCancel} color='danger'>
                                        <i className="fa fa-remove mr-2"></i>
                                        <span>{translations.buttons.remove}</span>
                                    </Button>
                                    <Button onClick={handleSubmit} color='success'>
                                        <i className="fa fa-save mr-2"></i>
                                        <span>{translations.buttons.save}</span>
                                    </Button>
                                </div>
                            </ModalFooter>
                        </div>
                    );
                }}
            />
        </CoreUiModal>
    ) : null;
};

ShippingFormWeightEditionModal.propTypes = propTypes;

export default withFormUtils(ShippingFormWeightEditionModal);