import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import clsx from 'clsx';
import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';

import TableGenerator from '../../generators/TableGenerator';
import { Button, Label, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const propTypes = {
    draggableColumns: PropTypes.object,
    fonts: PropTypes.arrayOf(PropTypes.object),
    verticalAlignments: PropTypes.arrayOf(PropTypes.object),
    horizontalAlignments: PropTypes.arrayOf(PropTypes.object),
    handleColumnChange: PropTypes.func,
    refresh: PropTypes.number
};

const EmailTemplatesTablesColumnsStyleTable = ({ draggableColumns, fonts, verticalAlignments, horizontalAlignments, translations, handleColumnChange, refresh, storeCurrentLanguage, availableLanguages }) => {

    const [ itemToEdit, setItemToEdit ] = useState(null);
    const [ isDisplayNameModalOpen, setIsDisplayNameModalOpen ] = useState(false);

    const toggleDisplayNameModal = () => {
        setItemToEdit(null);
        setIsDisplayNameModalOpen(!isDisplayNameModalOpen);
    };

    const updateDisplayName = (iso, value) => {
        const entry = itemToEdit.displayName.find(d => d.iso.toLowerCase() === iso);
        const index = itemToEdit.displayName.indexOf(entry);
        if (index === -1) {
            itemToEdit.displayName.push({ iso: `${iso[0].toUpperCase()}${iso.slice(1)}`, value: value });
        } else {
            itemToEdit.displayName[index].value = value;
        }
        setItemToEdit(itemToEdit);
    };

    const saveDisplayName = () => {
        handleColumnChange(itemToEdit.id, itemToEdit.displayName, null, 'displayName');
        toggleDisplayNameModal();
    };

    const DisplayNameCell = (item) => {
        const value = item ? item.displayName.find(i => i.iso.toLowerCase() === storeCurrentLanguage)?.value : null;

        const onEdit = (item) => {

            const displayNameClone = item.displayName.reduce((acc,item) => [...acc, { iso: item.iso, value: item.value }], []);
            setItemToEdit({ id: item.id, displayName: displayNameClone, enabled: item.displayTag === null});
            setIsDisplayNameModalOpen(true);
        };

        return (item ?
            <InputGroup onClick={() => onEdit(item)} style={{ position: 'relative', top: '-4px'}}>
                <Input value={value} disabled style={{ cursor: 'pointer', backgroundColor: 'white', color:'Black'}} />
                <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-globe" aria-hidden="true" /></InputGroupText>
                </InputGroupAddon>
            </InputGroup> : null
        );
    };

    return (
        <div id={`EmailTemplatesTablesColumnsStyleTable-${refresh}`}>
        <TableGenerator
            translations={translations}
            idSelector='id'
            draggable={true}
            items={draggableColumns.items}
            draggableData={draggableColumns}
            columns={[
                {
                    header: translations.tableHeaders.name,
                    value: 'name'
                },
                {
                    header: translations.tableHeaders.displayName,
                    type: 'custom',
                    value: 'displayName',
                    customCell: DisplayNameCell
                },
                {
                    header: translations.tableHeaders.font,
                    type: 'dropDown',
                    value: 'font',
                    dictionary: fonts,
                    onChange: handleColumnChange,
                },
                {
                    header: translations.tableHeaders.fontColor,
                    type: 'input',
                    inputType: 'color',
                    value: 'fontColor',
                    onChange: handleColumnChange,
                },
                {
                    header: translations.tableHeaders.fontSize,
                    type: 'input',
                    inputType: 'number',
                    value: 'fontSize',
                    inputTextAlignment: 'text-right',
                    onChange: handleColumnChange,
                },
                {
                    header: translations.tableHeaders.backgroundColor,
                    type: 'input',
                    inputType: 'color',
                    value: 'backgroundColor',
                    onChange: handleColumnChange,
                },
                {
                    header: translations.tableHeaders.verticalAlignment,
                    type: 'dropDown',
                    value: 'verticalAlignment',
                    dictionary: verticalAlignments,
                    onChange: handleColumnChange,
                },
                {
                    header: translations.tableHeaders.horizontalAlignment,
                    type: 'dropDown',
                    value: 'horizontalAlignment',
                    dictionary: horizontalAlignments,
                    onChange: handleColumnChange,
                },
                {
                    header: translations.tableHeaders.isBold,
                    type: 'switchWithAction',
                    value: 'isBold',
                    onSwitch: handleColumnChange
                },
                {
                    header: translations.tableHeaders.isItalic,
                    type: 'switchWithAction',
                    value: 'isItalic',
                    onSwitch: handleColumnChange
                },
                {
                    header: translations.tableHeaders.isUnderline,
                    type: 'switchWithAction',
                    value: 'isUnderline',
                    onSwitch: handleColumnChange
                },
                {
                    header: translations.tableColumnHeaders.visible,
                    type: 'switchWithAction',
                    value: 'visible',
                    onSwitch: handleColumnChange
                }
            ]}
        />
        <Modal isOpen={isDisplayNameModalOpen}>
            <ModalHeader toggle={toggleDisplayNameModal}>{translations.displayNameModal.header}</ModalHeader>
            <ModalBody className='p-3'>
                <div>
                    {availableLanguages.map(al => {
                        const value = itemToEdit?.displayName.find(dn => dn.iso.toLowerCase() === al.iso)?.value;
                        return (
                            <div key={`displayName-${al.iso}`} className="mb-3">
                                <Label for={`displayName-${al.iso}-input`}>
                                    <span>Nazwa wyświetlana</span>&nbsp;
                                    <i className={clsx(`flag-icon`, `flag-icon-${(al.iso === 'en' ? 'gb' : al.iso)}`)} />
                                </Label>
                                <Input disabled={!itemToEdit?.enabled} id={`displayName-${al.iso}-input`} defaultValue={value} onChange={(e) => updateDisplayName(al.iso, e.target.value)} />
                            </div>
                        );
                    })}
                </div>
            </ModalBody>
            <ModalFooter>
                {itemToEdit?.enabled &&
                    <Button color='primary' onClick={saveDisplayName} >
                        {translations.displayNameModal.save}
                    </Button>
                }
                <Button color='secondary' onClick={toggleDisplayNameModal} >
                    {translations.displayNameModal.cancel}
                </Button>
            </ModalFooter>
        </Modal>
        </div>
    );
};

EmailTemplatesTablesColumnsStyleTable.propTypes = propTypes;

const mapStateToProps = (state) => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
    availableLanguages: state.locale.availableLanguages
});

const mapDispatch = {
};

export default connect(mapStateToProps, mapDispatch)(EmailTemplatesTablesColumnsStyleTable);
