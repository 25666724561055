import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Tabulator from '../../../../components/Tabulator';

import Manufacturers from './Manufacturers';
import MainSettings from './MainSettings';

import styles from './index.module.scss';

import SectionAsideButtons from '../../../../components/SectionAsideButtons';

import { getReturnButtonProps } from '..';

import { useHistory, } from 'react-router-dom';

const propTypes = {
    currentLanguage: PropTypes.string,
    addUpdatePageSection: PropTypes.func,
    section: PropTypes.object,
};

const ManufacturerSection = ({ section, sectionLocation }) => {
    const intl = useIntl();

    const history = useHistory();

    const translations = intl.messages.homePage.manufacturersSection;

    return (
        <div className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Tabulator
                    listOfElements={ section.id ? [
                        {
                            label: translations.main.title,
                            id: 'main-ManufacturersSection',
                            component: <MainSettings section={section} sectionLocation={sectionLocation} />
                        },
                        {
                            label: translations.manufacturers.title,
                            id: 'manufacturers-ManufacturersSection',
                            component: <Manufacturers section={section} sectionLocation={sectionLocation} />
                        }
                    ] : [
                        {
                            label: translations.main.title,
                            id: 'main-ManufacturersSection',
                            component: <MainSettings section={section} sectionLocation={sectionLocation} />
                        },
                    ]}
                />

                <SectionAsideButtons
                    buttons={{
                        remove: getReturnButtonProps(sectionLocation, section, history, translations)
                    }}
                />
            </div>
        </div>
    );
};

ManufacturerSection.propTypes = propTypes;

export default ManufacturerSection;