const appAside = {
    tabs: {
        settings: {
            title: "Settings",
            language: {
                label: "Change panel language",
                storeLabel: 'Change store language',
            }
        },
        redirectToStore: 'Przejdź do sklepu',
    },
    erpConnection: {
        active: '[EN] Połączony z ERP',
        inactive: '[EN] Brak połączenia z ERP',
    },
};

export default appAside;