import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { selectStoreAvailableIsoList, selectStoreCurrentLanguage } from '../../../../../redux/selectors/storeLocale';

import ColorEditionModal from '../../../../../components/modals/ColorEditionModal';

import { generateFormToSubmitValues } from "../../../../../utils/components";

const propTypes = {
    data: PropTypes.object,
    storeAvailableIsoList: PropTypes.arrayOf(PropTypes.string),
    storeCurrentLanguage: PropTypes.string,
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func
};

const ConnectedColorEditionModal = ({ data, storeAvailableIsoList, storeCurrentLanguage, closeModal, onSubmit }) => {

    const [ colors, setColors ] = useState(data.colors.reduce((acc,c,idx) => [...acc, {value: c, orderIndex: idx}], []));

    const addColor = color => {
        setColors(data.colors = [ ...colors, color ]);
    };

    const removeColor = orderIndex => {
        setColors(data.colors = colors.filter(color => color.orderIndex !== orderIndex));
    };

    const updateColor = (orderIndex, color) => {
        setColors(data.colors = [
            ...colors.filter(color => color.orderIndex !== orderIndex),
            {
                orderIndex: orderIndex,
                value: color
            }
        ].sort((a, b) => a.orderIndex - b.orderIndex));
    };

    const config = { translations: ['name'] };

    const handleOnSubmit = formData => {
        const data = generateFormToSubmitValues(formData, config, storeAvailableIsoList);
        data.colors = colors.reduce((acc,c) => [...acc, c.value], []);

        onSubmit(data);
    };

    return (
        <ColorEditionModal
            data={data}
            colors={colors}
            storeAvailableIsoList={storeAvailableIsoList}
            storeCurrentLanguage={storeCurrentLanguage}
            closeModal={closeModal}
            onSubmit={handleOnSubmit}
            addColor={addColor}
            removeColor={removeColor}
            updateColor={updateColor}
        />
    );
};

ConnectedColorEditionModal.propTypes = propTypes;

const mapStateToProps = state => ({
    storeAvailableIsoList: selectStoreAvailableIsoList(state),
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
};

export default connect(mapStateToProps, mapDispatch)(ConnectedColorEditionModal);