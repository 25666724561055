import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useFetch from '../../../hooks/useFetch';

import { generateOrdersConfig } from '../../../utils/orders';

import {
    getOrderStatuses,
    getOrderStatus,
    addEditOrderStatus,
    deleteOrderStatus
} from '../../../redux/methods/extrasSettings';

import styles from './index.module.scss';
import ContainerWithCard from '../../ContainerWithCard';
import TableGenerator from '../../generators/TableGenerator';
import OrderStatusEditionModal from '../../modals/OrderStatusEditionModal';

import { formUtilsHocPropTypes } from '../../../propTypes';
import { withFormUtils } from '../../../hoc/withFormUtils';

import { generateTranslatedOrderStatuses } from '../../../utils/orderStatuses';

import { Button } from 'reactstrap';

const propTypes = {
    utils: formUtilsHocPropTypes,
    isConnectedToERP: PropTypes.bool,
    forceRefetch: PropTypes.bool,
    getOrderStatuses: PropTypes.func,
    getOrderStatus: PropTypes.func,
    addEditOrderStatus: PropTypes.func,
    deleteOrderStatus: PropTypes.func
};

const OrderStatusesTable = ({
    utils,
    isConnectedToERP,
    forceRefetch,
    getOrderStatuses,
    getOrderStatus,
    addEditOrderStatus,
    deleteOrderStatus
}) => {
    const intl = useIntl();
    const translations = intl.messages.orderStatuses.formsTable;

    const { headers, editModal } = translations;

    const [ isEditModalOpen, setIsEditModalOpen ] = useState(false);
    const [ editModalItem, setEditModalItem ] = useState(null);

    const [ items ] = useFetch(getOrderStatuses, null, [], forceRefetch);

    const translatedItems = items && generateTranslatedOrderStatuses(items, utils.currentLanguage());

    const statusConfig = items && generateOrdersConfig(items, utils.currentLanguage());

    const addAction = async () => {
        const item = await getOrderStatus({ orderStatusId: -1});
        if (item && item.payload && !item.payload.errors && !item.error) {
            setEditModalItem(item.payload);
            setIsEditModalOpen(true);
        }
    };

    const editAction = id => async () => {
        const item = await getOrderStatus({ orderStatusId: id});
        if (item && item.payload && !item.payload.errors && !item.error) {
            setEditModalItem(item.payload);
            setIsEditModalOpen(true);
        }
    };

    const deleteAction = id => () => {
        deleteOrderStatus(id);
    };

    const handleAddEditAction = form => {
        addEditOrderStatus(form);
        setIsEditModalOpen(false);
    };

    return items ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-money'>
            <TableGenerator
                items={translatedItems}
                expectedActions={['edit', 'delete']}
                actions={{editAction, deleteAction}}
                defaultAction={editAction}
                translations={translations}
                checkIfActionIsDisabled={(action, item) => { return action === 'delete' && item.tag !== null; }}
                columns={[
                    {
                        header: headers.name,
                        value: 'id',
                        type: 'badge',
                        config: statusConfig,
                        additionalStyle: styles.statusBadge
                    },
                    isConnectedToERP &&
                    {
                        header: headers.erpOrderStatusName,
                        value: 'translatedDictionary'
                    },
                    {
                        header: headers.description,
                        value: 'translatedDescription'
                    },
                    {
                        header: headers.actions,
                        type: 'actions'
                    }
                ]}
            />
            <aside style={{ textAlign: 'right' }}>
                <Button color='primary' onClick={addAction}>
                    <i className='fa fa-plus mr-2' />
                    <span>{translations.tooltips.add}</span>
                </Button>
            </aside>
            <OrderStatusEditionModal
                isOpen={isEditModalOpen}
                setIsOpen={setIsEditModalOpen}
                translations={editModal}
                orderStatus={editModalItem}
                onSubmit={handleAddEditAction}
            />
        </ContainerWithCard>
    ) : null;
};

OrderStatusesTable.propTypes = propTypes;

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
    forceRefetch: state.refetchers.orderStatuses
});

const mapDispatch = {
    getOrderStatuses,
    getOrderStatus,
    addEditOrderStatus,
    deleteOrderStatus
};

export default withFormUtils(withRouter(connect(mapStateToProps, mapDispatch)(OrderStatusesTable)));