import React from 'react';

import { TabContent, TabPane } from 'reactstrap';

import SettingsTab from './SettingsTab';

const DefaultAside = () => {
    return (
        <React.Fragment>
            <TabContent activeTab={'settings'} className='h-100'>
                <TabPane tabId="settings" className="p-3">
                    <SettingsTab />
                </TabPane>
            </TabContent>
        </React.Fragment>
    );
};

export default DefaultAside;
