const appAside = {
    tabs: {
        settings: {
            title: "Ustawienia",
            language: {
                label: "Zmień język panelu",
                storeLabel: 'Zmień język sklepu',
            }
        },
        redirectToStore: 'Przejdź do sklepu',
    },
    erpConnection: {
        active: 'Połączony z ERP',
        inactive: 'Niepołączony z ERP',
    },
};

export default appAside;