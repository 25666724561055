import React from 'react';
import PropTypes from 'prop-types';

import { codesPropTypes } from '../../../propTypes';

import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    codes: PropTypes.arrayOf(codesPropTypes),
    headerLabels: PropTypes.shape({
        code: PropTypes.string,
        usages: PropTypes.string,
        usagesLeft: PropTypes.string,
    }).isRequired,
    emptyTableFiller: PropTypes.string,
};

const CodesTable = ({ codes, headerLabels, emptyTableFiller }) => {
    return (
        <TableGenerator
            items={codes}
            translations={{
                emptyTable: emptyTableFiller
            }}
            columns={[
                {
                    header: headerLabels.code,
                    value: 'code',
                },
                {
                    header: headerLabels.usages,
                    value: 'usages'
                },
                {
                    header: headerLabels.usagesLeft,
                    value: 'usagesLeft'
                }
            ]}
        />
    );
};

CodesTable.propTypes = propTypes;

export default CodesTable;