import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import useFetch from '../../../hooks/useFetch';

import { getProductPresentationCart, updateProductPresentationCart } from '../../../redux/methods/products';

import { Form, Container, Button, Card, CardBody, CardHeader, TabContent } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import ArticlePresentationCardTable from '../../../components/tables/ArticlePresentationCardTable';

import styles from './index.module.scss';

import TabulatorNav from '../../../components/TabulatorNav';
import SaveDataToast from '../../../components/SaveDataToast';

const propTypes = {
    getProductPresentationCart: PropTypes.func,
    updateProductPresentationCart: PropTypes.func,
};

const Comparison = ({
    getProductPresentationCart, updateProductPresentationCart
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.comparison;
    const translationsAdditionalFields = intl.messages.card.view;

    const [ activeTab, setActiveTab ] = useState(0);

    const [ data ] = useFetch(getProductPresentationCart, 'comparison', []);

    const [ additionalFieldsToEditDesktop, setAdditionalFieldsToEditDesktop] = useState([]);
    const [ additionalFieldsToEditTablet, setAdditionalFieldsToEditTablet] = useState([]);
    const [ additionalFieldsToEditMobile, setAdditionalFieldsToEditMobile] = useState([]);

    const [isEdited, setIsEdited] = useState(false);

    const handleOnSubmit = () => {

        const extractListParams = (tag, device, list) => {
            const entry = list.find(p => p.tag === tag);
            return {
                show: entry.parameters.comparison[device].show,
                order: list.indexOf(entry)
            };
        };

        const generatedArray = data.fields.map((param) => ({
            ...param,
            parameters: {
                ...param.parameters,
                comparison: {
                    desktop: extractListParams(param.tag, 'desktop', additionalFieldsToEditDesktop),
                    tablet: extractListParams(param.tag, 'tablet', additionalFieldsToEditTablet),
                    mobile: extractListParams(param.tag, 'mobile', additionalFieldsToEditMobile),
                }
            }
        }));

        updateProductPresentationCart({
            updateMain: false,
            updateTab: false,
            updateList: false,
            updateTile: false,
            updateComparison: true,
            fields: generatedArray,
        });

        setIsEdited(false);
    };

    const sortByOrder = (device, fields) => fields.sort((a,b) => (a.parameters.comparison[device].order < b.parameters.comparison[device].order) ? -1 : 1);

    useEffect(() => {
        if(data){
            const { fields } = data;

            setAdditionalFieldsToEditDesktop(sortByOrder('desktop', [...(JSON.parse(JSON.stringify(fields)))]));
            setAdditionalFieldsToEditTablet(sortByOrder('tablet', [...(JSON.parse(JSON.stringify(fields)))]));
            setAdditionalFieldsToEditMobile(sortByOrder('mobile', [...(JSON.parse(JSON.stringify(fields)))]));
        }

        return () => {
            setAdditionalFieldsToEditDesktop([]);
            setAdditionalFieldsToEditTablet([]);
            setAdditionalFieldsToEditMobile([]);
        };
    }, [data]);

    return additionalFieldsToEditDesktop && additionalFieldsToEditTablet && additionalFieldsToEditMobile ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <SaveDataToast isEdited={isEdited} handleSubmit={handleOnSubmit} />

            <div className='animated fadeIn'>
                <Card>
                    <CardHeader>
                        {translations.labels.additionalFields}
                    </CardHeader>
                    <CardBody>
                        <FinalForm
                            keepDirtyOnReinitialize
                            initialValues={{}}
                            onSubmit={handleOnSubmit}
                            render={({ handleSubmit, form }) => {
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Container fluid>

                                            <TabulatorNav
                                                setActiveTab={setActiveTab}
                                                activeTab={activeTab}
                                                tabsList={[
                                                    {iso: 0, label: 'Desktop'},
                                                    {iso: 1, label: 'Tablet'},
                                                    {iso: 2, label: 'Mobile'},
                                                ]}
                                                withoutFlag={true}
                                            />

                                            <TabContent>
                                                <div style={{padding: '10px', display: activeTab === 0 ? 'block' : 'none'}}>
                                                    <ArticlePresentationCardTable
                                                        type='comparison'
                                                        device='desktop'
                                                        parameters={additionalFieldsToEditDesktop}
                                                        setParameters={setAdditionalFieldsToEditDesktop}
                                                        headerLabels={translationsAdditionalFields.headerLabels}
                                                        translations={translationsAdditionalFields.table.labels}
                                                        setIsEdited={setIsEdited}
                                                    />
                                                </div>
                                                <div style={{padding: '10px', display: activeTab === 1 ? 'block' : 'none'}}>
                                                    <ArticlePresentationCardTable
                                                        type='comparison'
                                                        device='tablet'
                                                        parameters={additionalFieldsToEditTablet}
                                                        setParameters={setAdditionalFieldsToEditTablet}
                                                        headerLabels={translationsAdditionalFields.headerLabels}
                                                        translations={translationsAdditionalFields.table.labels}
                                                        setIsEdited={setIsEdited}
                                                    />
                                                </div>
                                                <div style={{padding: '10px', display: activeTab === 2 ? 'block' : 'none'}}>
                                                    <ArticlePresentationCardTable
                                                        type='comparison'
                                                        device='mobile'
                                                        parameters={additionalFieldsToEditMobile}
                                                        setParameters={setAdditionalFieldsToEditMobile}
                                                        headerLabels={translationsAdditionalFields.headerLabels}
                                                        translations={translationsAdditionalFields.table.labels}
                                                        setIsEdited={setIsEdited}
                                                    />
                                                </div>
                                            </TabContent>

                                            <aside className={styles.footer}>
                                                <Button color='success' onClick={form.submit}>
                                                    <i className="fa fa-save mr-2"/>
                                                    <span>{translations.labels.save}</span>
                                                </Button>
                                            </aside>

                                        </Container>
                                    </Form>
                                );
                            }}
                        />
                    </CardBody>
                </Card>
            </div>
        </Container>
    ) : null;
};

Comparison.propTypes = propTypes;

const mapDispatch = {
    getProductPresentationCart,
    updateProductPresentationCart
};

export default connect(null, mapDispatch)(Comparison);