export const ceneoAvailParams = {
    availableInOneDay: 1,
    availableInThreeDays: 3,
    availableInWeek: 7,
    availableInTwoWeeks: 14,
    onRequest: 90,
    checkInStore: 99,
    preSales: 110,
};

export const productIndexForCeneoOptions = ['Catalogue', 'Trade', 'Ean'];