import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import { useIntl } from 'react-intl';

import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import ChartSection from '../../../features/dashboard/sections/ChartSection';
import StatsSection from '../../../features/dashboard/sections/StatsSection';

import styles from './index.module.scss';

const DashboardStats = () => {
    const intl = useIntl();
    const translations = intl.messages.dashboard.stats;

    return (
        <Container fluid className={styles.fullScreenOnMobile}>
            <Card>
                <CardHeader>
                    <i className={clsx('fa fa-align-justify')}></i>
                    <span>{translations.title}</span>
                </CardHeader>
                <CardBody>
                    <Container fluid className={clsx('mb-3 h-100', styles.fullScreenOnMobile)}>
                        <Row className='mb-2'>
                            <Col sm={6}>
                                <Card className={styles.section}>
                                    <ChartSection translations={translations.chartSection} />
                                </Card>
                            </Col>
                            <Col sm={6}>
                                <Card className={styles.section}>
                                    <StatsSection translations={translations.statsSection} />
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </CardBody>
            </Card>
        </Container>
    );
};

export default withRouter(DashboardStats);