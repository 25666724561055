const visualSettings = {
    css: {
        title: '[EN] Ustawienia CSS',
        helper: [
            '- [EN] Zmiana css na własny możliwa dopiero po wgraniu pliku CSS dla wybranej skórki',
            '- [EN] Dla każdej skórki możesz mieć wgrany jeden plik CSS'
        ],
        switch: {
            labels: {
                false: '[EN] CSS WŁASNY',
                true: '[EN] CSS SKLEPOWY',
                main: '[EN] Wybór Css'
            },
            tooltip: '[EN] Aby przełączyć na css własny musisz mieć wgrany css dla wybranej skórki',
        },
        dropdown: {
            label: '[EN] Wybór skórki',
        },
        fileSender: {
            buttons: {
                edit: '[EN] PODMIEŃ',
                remove: '[EN] USUŃ',
                upload: '[EN] WGRAJ'
            },
            label: '[EN] Własny CSS'
        },
        download: {
            own: '[EN] POBIERZ WŁASNY CSS',
            shop: '[EN] POBIERZ SKLEPOWY CSS',
        },
    },
    layout: {
        title: '[EN] Ustawienia skórki',
        layoutSelectionLabel: '[EN] Aktywna skórka',
        templateSectionTitle: '[EN] Wybór wersji kolorystycznej',
        styleSwitchLabels: {
            main: '[EN] Styl',
            false: '[EN] Domyślny',
            true: '[EN] Własny'
        },
        fallbackName: '[EN] [BRAK NAZWY]',
        tabSelectorTitle: '[EN] Ustawienia szablonów',
        tabs: {
            params: "[EN] Parametry"
        },
        buttons: {
            downloadCSS: '[EN] POBIERZ PLIK CSS',
            preview: '[EN] PODGLĄD',
            save: '[EN] ZAPISZ ZMIANY',
            select: '[EN] WYBIERZ',
            selected: '[EN] WYBRANY',
            edit: '[EN] EDYTUJ',
            restoreColors: '[EN] PRZYWRÓĆ USTAWIENIA POCZĄTKOWE'
        },
        colorController: {
            name: '[EN] Kolor skórki',
            pickButton: '[EN] Wybierz kolor'
        },
        templateParamsSections: {
            colors: "[EN] Kolory",
            fonts: "[EN] Czcionki",
            fontSizes: "[EN] Wielkości czcionek"
        }
    },
    logo: {
        title: '[EN] Ustawienia logo',
        labels: {
            logo: '[EN] Logo',
            favicon: '[EN] Favicon',
            logoAlt: '[EN] Tekst alternatywny dla logo',
        },
        fileSender: {
            buttons: {
                edit: '[EN] PODMIEŃ',
                remove: '[EN] USUŃ',
                upload: '[EN] WGRAJ'
            },
        }
    },
    buttons: {
        title: '[EN] Przyciski',
        headerLabels: {
            name: '[EN] Nazwa',
            preview: '[EN] Podgląd',
            actions: '[EN] Akcje',
        },
        tooltips: {
            edit: '[EN] Edytuj',
            remove: '[EN] Usuń'
        },
        deleteModal: {
            header: '[EN] Usuń przycisk',
            body: '[EN] Czy napewno chcesz usunąć przycisk?',
            confirm: '[EN] USUŃ',
            cancel: '[EN] WRÓĆ',
        },
        emptyTable: '[EN] Brak wygenerowanych przycisków',
        addButton: '[EN] Dodaj przycisk',
        inputs: {
            name: '[EN] Nazwa',
            fontSize: '[EN] Rozmiar czcionki',
            fontColor: '[EN] Kolor czcionki',
            select: '[EN] Wybierz',
            font: '[EN] Cziconka',
            isFontBold: '[EN] Pogrubienie czcionki',
            iconPosition: '[EN] Pozycja ikony',
            buttonVerticalSize: '[EN] Wysokość przycisku',
            buttonHorizontalSize: '[EN] Szerokość przycisku',
            borderSize: '[EN] Szerokość obramowań',
            borderRadius: '[EN] Zaokrąglenie obramowań',
            borderColor: '[EN] Kolor obramowań',
            isTransparent: '[EN] Przeźroczystość tła',
            shadowVerticalPosition: '[EN] Położenie cienia wertykalne',
            shadowHorizontalPosition: '[EN] Położenie cienia horyzontalne',
            shadowBlurRadius: '[EN] Rozmycie cienia',
            shadowSpreadRadius: '[EN] Zwiększenie rozmiaru cienia',
            onHoverAnimation: '[EN] Animacja',
            right: '[EN] Prawa strona',
            left: '[EN] Lewa strona',
            previewButtonTranslation: '[EN] Twój przycisk',
            previewBackgroundColor: '[EN] Kolor tła podglądu',
            backgroundColor: '[EN] Kolor tła',
            animationTypes: {
                scaleUp: '[EN] Powiększenie',
                lightUp: '[EN] Rozświetlenie',
                switchColors: '[EN] Zmiana kolorów',
                roundUp: '[EN] Zaokrąglenie',
                none: '[EN] Brak'
            },
        },
        buttonContentInfo: '[EN] Treść przycisku definiowana jest w sekcji, w której można dodać przycisk.',
        iconsModal: {
            label: '[EN] Wybierz ikonę',
            confirm: '[EN] ZAPISZ',
            cancel: '[EN] WRÓĆ'
        }
    },
    placeholderImages: {
        title: '[EN] Zdjęcia zastępcze',
        fileSender: {
            upload: '[EN] PRZEŚLIJ',
            remove: '[EN] USUŃ',
            edit: '[EN] ZAMIEŃ',
        },
        labels: {
            product: '[EN] Produkty'
        }
    },
};

export default visualSettings;