import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    storeDefaultLanguage: 'pl',
    storeChosenLanguage: null,
    storeAvailableLanguages: []
};

const storeLocale = createSlice({
    name: 'storeLocale',
    initialState,
    reducers: {
        setStoreDefaultLanguage(state, action) {
            const { storeDefaultLanguage } = action.payload;

            state.storeDefaultLanguage = storeDefaultLanguage;
        },
        setStoreChosenLanguage(state, action) {
            const { storeChosenLanguage } = action.payload;

            state.storeChosenLanguage = storeChosenLanguage;
        },
        setStoreAvailableLanguages(state, action) {
            const { storeAvailableLanguages } = action.payload;

            state.storeAvailableLanguages = storeAvailableLanguages;
        }
    }
});

export const { setStoreDefaultLanguage, setStoreChosenLanguage, setStoreAvailableLanguages } = storeLocale.actions;

export default storeLocale.reducer;