import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Field } from 'react-final-form';

import SearchableDropdown from '../../SearchableDropdown';

import { stringOrNumberPropTypes } from '../../../propTypes';

const propTypes = {
    name: PropTypes.string,
    label: stringOrNumberPropTypes,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: stringOrNumberPropTypes,
        label: stringOrNumberPropTypes,
    })),
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    mutator: PropTypes.func,
};

const FormFieldWithSearchableDropdown = ({ name, label, options, className, isDisabled = false, mutator }) => {
    return (
        <Field
            name={name}
            parse={value => {
                if(mutator){
                    mutator(value);
                }

                return value;
            }}
        >
            {
                props =>
                    <span className={clsx(className)}>
                        <SearchableDropdown
                            label={label}
                            options={options}
                            performSelection={props.input.onChange}
                            currentSelectionLabel={options.find(o => o.id === props.input.value).label}
                            isDisabled={isDisabled}
                        />
                        {(props.meta.error || props.meta.submitError) && props.meta.touched && (<small className='text-danger'>{props.meta.error || props.meta.submitError}</small>)}
                    </span>
            }
        </Field>
    );
};

FormFieldWithSearchableDropdown.propTypes = propTypes;

export default FormFieldWithSearchableDropdown;