const fonts = {
    title: '[EN]Ustawienia czcionek',
    table: {
        headers: {
            name: '[EN] Nazwa',
            actions: '[EN] Akcje',
        },
        tooltips: {
            remove: '[EN] Usuń czcionkę',
        },
        emptyTable: '[EN] Nie znaleziono przypisanych czcionek',
        deleteModal: {
            header: '[EN] Usuń czcionkę',
            body: '[EN] Czy napewno chcesz usunąć czcionkę?',
            confirm: '[EN] USUŃ',
            cancel: '[EN] WRÓĆ'
        }
    },
    addFonts: '[EN] Dodaj czcionki',
    assigner: {
        header: '[EN] Dodaj czcionki',
        buttons: {
            cancel: '[EN] Anuluj',
            confirm: '[EN] Zapisz'
        },
        toAssign: {
            header: {
                title: '[EN] Dostępne czcionki',
            },
            tooltip: '[EN] Wybierz czcionkę',
            searchPlaceholder: '[EN] Wyszukaj',
        },
        assigned: {
            header: {
                title: '[EN] Wybrane czcionki',
            },
            tooltip: '[EN] Usuń czcionkę',
            searchPlaceholder: '[EN] Wyszukaj',
        }
    },
    polishFonts: '[EN] Czcionki z polskimi znakami',
    allFonts: '[EN] Wszystkie czcionki'
};

export default fonts;