import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { productsSearchConfig } from '../../../../config/searchConfigs';

import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';
import useFetch from '../../../../hooks/useFetch';
import useSearchConfig from '../../../../hooks/useSearchConfig';
import { getProducts } from '../../../../redux/methods/products';
import { translateProductName } from '../../../../utils/products';

import { Alert } from 'reactstrap';

import ProductsWithoutActionsTable from '../../../../components/tables/ProductsWithoutActionsTable';
import ProductSearchForm from '../../../../components/ProductSearchForm';
import Paginator from '../../../../components/Paginator';

const propTypes = {
    selectedProducts: PropTypes.array,
    setSelectedProducts: PropTypes.func,
    getProducts: PropTypes.func,
    currentLanguage: PropTypes.string,
};

const VariantedProductSelection = ({
    selectedProducts,
    setSelectedProducts,
    currentLanguage,
    getProducts,
    label,
    selectedTemplateVariant,
    setSelectedTemplateVariant,
    selectedAttributes,
    guideTranslation
}) => {
    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig({ ...productsSearchConfig, attributeId: selectedAttributes, productVariantType: 'None' });

    const [ products, pages ] = useFetch(getProducts, searchConfig, [searchConfig]);

    const translatedProducts = products && translateProductName(products, currentLanguage);

    return products ? (
        <div>
            <Alert
                color='info'
                isOpen={!selectedTemplateVariant || !selectedProducts.includes(selectedTemplateVariant)}
            >
                {guideTranslation}
            </Alert>
            <span><strong>{label}</strong></span>
            <div className='mt-3'>
                <ProductSearchForm
                    performProductSearch={changeSearchConfig}
                    searchConfig={searchConfig}
                />
                <div className={clsx(translatedProducts.length > 0 && 'mb-3')}>
                    <ProductsWithoutActionsTable
                        checkedProductsId={selectedProducts}
                        setCheckedProductsId={setSelectedProducts}
                        translatedProductsList={translatedProducts}
                        selectedTemplateVariant={selectedTemplateVariant}
                        setSelectedTemplateVariant={setSelectedTemplateVariant}
                        isVariantTemplateSelection
                    />
                </div>
                {
                    pages > 1 &&
                        <Paginator
                            totalPages={pages}
                            currentPage={searchConfig.curentPage + 1}
                            onPageChange={changePage}
                        />
                }
            </div>
        </div>
    ) : null;
};

VariantedProductSelection.propTypes = propTypes;

const mapStateToProps = state => ({
    currentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    getProducts
};

export default connect(mapStateToProps, mapDispatch)(VariantedProductSelection);