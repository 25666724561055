import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { Container } from 'reactstrap';

import AnimatedBannerSettings from '../../../../../components/AnimatedBannerSettings';

import { getAnchorCurrentLocation, getAnchorLocation } from '../../../../../utils/homePage';

import styles from './index.module.scss';

import getConfig from '../../../../../getConfig';

import { getFileFormat, isVideo, isImage } from '../../../../../utils/files';
import { withFormUtils } from '../../../../../hoc/withFormUtils';

import { getGoogleFontsUrl } from '../../../../../utils/fonts';

const propTypes = {
    history: PropTypes.object,
    onCancel: PropTypes.func,
    onSave: PropTypes.func
};

const AnimatedBannerImageSettings = ({utils, fonts, settingsToEdit, onCancel, onSave}) => {

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const [currentBlockSize, setCurrentBlockSize] = useState({width: 0, height: 0, x: 0, y: 0});
    const [refsLength, setRefsLength] = useState(0);

    const itemRefs = useRef([]);

    function updateRefsLength(delta) {
        const newLength = refsLength + delta;
        setRefsLength(newLength);
        itemRefs.current = itemRefs.current.splice(0, newLength);
        for(let i = 0; i < newLength; i++) {
            itemRefs.current[i] = itemRefs.current[i] || React.createRef();
        }
        itemRefs.current = itemRefs.current.map((item) => item || React.createRef());
    }

    const containerRef = useRef();
    const canvasRef = useRef();
    const szerokoscRef = useRef();
    const wysokoscRef = useRef();
    const pozycjaXRef = useRef();
    const pozycjaYRef = useRef();
    const aspectRatioRef = useRef();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [containerWidth, setContainerWidth] = useState(settingsToEdit.width);
    const [containerHeight, setContainerHeight] = useState(settingsToEdit.height);
    const [settings, setSettings] = useState(settingsToEdit.settings.elements);
    const [forceRefresh, setForceRefresh] = useState(0);
    const [showArrows, setShowArrows] = useState(false);

    const handleRefresh = () => {
        setForceRefresh(forceRefresh + 1);
    };

    const refreshInputs = (newSize) => {
        if (szerokoscRef) {
            szerokoscRef.current.value = parseFloat(newSize.width).toFixed(2);
        }

        if (wysokoscRef) {
            wysokoscRef.current.value = parseFloat(newSize.height).toFixed(2);
        }

        if (pozycjaXRef) {
            pozycjaXRef.current.value = parseFloat(newSize.x ?? newSize.left).toFixed(2);
        }

        if (pozycjaYRef) {
            pozycjaYRef.current.value = parseFloat(newSize.y ?? newSize.top).toFixed(2);
        }

        if (aspectRatioRef) {
            aspectRatioRef.current.value = 1;
        }
    };

    const modifyPosition = (idx, obj) => {

        setSettings(prevState => {
            const newState = prevState.slice(0);
            newState[idx].position = Object.assign(newState[idx].position, obj);
            return newState;
        });

        const newSize = {
            width: (obj.width ?? settings[idx]?.position.width ?? 0).toFixed(2),
            height: (obj.height ?? settings[idx]?.position.height ?? 0).toFixed(2),
            x: (obj.left ?? settings[idx]?.position.left ?? 0).toFixed(2),
            y: (obj.top ?? settings[idx]?.position.top ?? 0).toFixed(2)
        };

        setCurrentBlockSize({
            ...currentBlockSize,
            ...newSize
        });

        if (idx === selectedIndex) {
            refreshInputs(newSize);
        }

        return Object.assign(settings[idx].position, obj);
    };

    const modifyProperties = (idx, obj) => {
        setSettings(prevState => {
            const newState = prevState.slice(0);
            newState[idx].properties = Object.assign(newState[idx].properties, obj);
            return newState;
        });

        return Object.assign(settings[idx].properties, obj);
    };

    const resizeBlock = (idx, parentPosition) => {
        const { width, height } = containerRef.current.getBoundingClientRect();

        const pos = settings[idx].position;

        const anchorPosition = typeof(parentPosition) === 'undefined' || parentPosition === null
            ? { currentLeft: 0, currentTop: 0 }
            : getAnchorCurrentLocation(parentPosition, pos.parentAnchor);

        // from static pixels to percentage of current container dimensions
        const currentMaxWidth = pos.maxWidth ? 100.0 * pos.maxWidth / width : 100;
        const currentMinWidth = pos.minWidth ? 100.0 * pos.minWidth / width : 0;
        const currentMaxHeight = pos.maxHeight ? 100.0 * pos.maxHeight / height : 100;
        const currentMinHeight = pos.minHeight ? 100.0 * pos.minHeight / height : 0;

        // percentage of current container dimensions
        const currentWidth = Math.min(currentMaxWidth, Math.max(currentMinWidth, pos.width));
        const currentHeight =  Math.min(currentMaxHeight, Math.max(currentMinHeight, pos.height));

        // in pixels
        const newWidth = width * currentWidth / 100.0;
        const newHeight = pos.aspectRatio
            ? 1.0 * pos.aspectRatio * newWidth
            : height * currentHeight / 100.0;

        // in pixels
        const newLeft = width * pos.left / 100.0;
        const newTop = height * pos.top / 100.0;

        return modifyPosition(idx, {
            width: 100.0 * newWidth / width,
            height: 100.0 * newHeight / height,
            currentWidth: newWidth,
            currentHeight: newHeight,
            currentLeft: anchorPosition.currentLeft + newLeft - (pos.anchor === 'rt' || pos.anchor === 'rb' ? newWidth : 0),
            currentTop: anchorPosition.currentTop + newTop - (pos.anchor === 'lb' || pos.anchor === 'rb' ? newHeight : 0)
        });
    };

    const resizeBlocks = (parentIndex, parentPosition) => {
        for(let idx = 0; idx < settings.length; idx++) {
            if (settings[idx].position.parentIndex === parentIndex) {
                const newParentPosition = resizeBlock(idx, parentIndex === -1 ? null : parentPosition);
                resizeBlocks(idx, newParentPosition);
            }
        }

        if (selectedIndex > -1) {
            setCurrentBlockSize({
                ...currentBlockSize,
                width: settings[selectedIndex].position.width.toFixed(2),
                height: settings[selectedIndex].position.height.toFixed(2),
                x: settings[selectedIndex].position.left.toFixed(2),
                y: settings[selectedIndex].position.top.toFixed(2)
            });
        }
    };

    const fitText = (idx, span, divSize, textSize) => {

        span.style.fontSize = '5px';
        const fontSize = 5.0 * divSize() / textSize().width;
        span.style.fontSize = fontSize + 'px';

        modifyProperties(idx, { fontSize: fontSize });

        const { height } = containerRef.current.getBoundingClientRect();
        const textHeight = textSize().height;

        modifyPosition(idx, {
            height: (100.0 * (textHeight + 2 * (settings[idx].properties.paddingVertical + settings[idx].properties.borderWidth)) / height),
            currentHeight: textHeight + 2 * (settings[idx].properties.paddingVertical + settings[idx].properties.borderWidth)
        });
    };

    const fitTexts = () => {
        settings.forEach((item, idx) => {
            if (item.type !== 'text') {
                return;
            }
            if (itemRefs && itemRefs.current[idx] && itemRefs.current[idx].current) {
                fitText(
                    idx,
                    itemRefs.current[idx].current.getElementsByTagName("SPAN")[0],
                    () => settings[idx].position.currentWidth - 2 * (settings[idx].properties.paddingHorizontal + settings[idx].properties.borderWidth),
                    () => itemRefs.current[idx].current.getElementsByTagName("SPAN")[0].getBoundingClientRect()
                );
            }
        });
    };

    const performMouseDownMove = (e, idx) => {
        setSelectedIndex(idx);

        const pos = settings[idx].position;

        refreshInputs(pos);

        const { width, height } = containerRef.current.getBoundingClientRect();

        const maxL = width - itemRefs.current[idx].current.getBoundingClientRect().width;
        const maxT = height - itemRefs.current[idx].current.getBoundingClientRect().height;

        function onMouseMove(e) {
            modifyPosition(idx, {
                top: pos.top + (100.0 * e.movementY / height),
                left: pos.left + (100.0 * e.movementX / width),
                currentTop: Math.min(Math.max(0, pos.currentTop + e.movementY), maxT),
                currentLeft: Math.min(Math.max(0, pos.currentLeft + e.movementX), maxL),
            });
        }

        function onMouseUp() {
            containerRef.current.removeEventListener("mousemove", onMouseMove);
            containerRef.current.removeEventListener("mouseup", onMouseUp);

            const multiplier = {
                height: pos.anchor === 'lb' || pos.anchor === 'rb' ? 1 : 0,
                width: pos.anchor === 'rt' || pos.anchor === 'rb' ? 1 : 0
            };

            const calculateRelativePosition = (position) => {
                if (position.parentIndex === -1) {
                    return { left: 0, top: 0 };
                }

                const parentRelativePosition = calculateRelativePosition(settings[position.parentIndex].position);
                const itemRelativePosition = getAnchorLocation(settings[position.parentIndex].position, position.parentAnchor);

                return {
                    left: parentRelativePosition.left + itemRelativePosition.left,
                    top: parentRelativePosition.top + itemRelativePosition.top
                };
            };

            const parentTopLeft = calculateRelativePosition(pos);

            const parentCurrentOffset = {
                currentTop: parentTopLeft.top * containerHeight / 100.0,
                currentLeft: parentTopLeft.left * containerWidth / 100.0
            };

            const newPos = {
                top: 100.0 * (pos.currentTop - parentCurrentOffset.currentTop + multiplier.height * pos.currentHeight) / containerHeight,
                left: 100.0 * (pos.currentLeft - parentCurrentOffset.currentLeft + multiplier.width * pos.currentWidth) / containerWidth,
            };

            modifyPosition(idx, newPos);

            refreshInputs(settings[idx].position);

            handleRefresh();
        }

        containerRef.current.addEventListener("mousemove", onMouseMove);
        containerRef.current.addEventListener("mouseup", onMouseUp);

        e?.stopPropagation();
    };

    const performMouseDownResize = (e, idx) => {

        setSelectedIndex(idx);

        const pos = settings[idx].position;
        const previousWidth = pos.currentWidth;
        const previousHeight = pos.currentHeight;
        const aspectRatio = 1.0 * previousHeight / previousWidth;

        refreshInputs(pos);

        const { width, height } = containerRef.current.getBoundingClientRect();

        function onMouseMove(e) {

            const dx = 100.0 * e.movementX / width;
            const dy = 0;//100.0 * e.movementY / height;

            const newCurrentWidth = (width * pos.width / 100.0) + dx;
            const newCurrentHeight = (height * pos.height / 100.0) + dy;

            if (newCurrentWidth > (pos.maxWidth ?? 999999) ||
                newCurrentHeight > (pos.maxHeight ?? 999999) ||
                newCurrentWidth < (pos.minWidth ?? 0) ||
                newCurrentHeight < (pos.minHeight ?? 0)){
                    return;
                }

            modifyPosition(idx, {
                left: pos.anchor === 'rt' || pos.anchor === 'rb' ? pos.left + dx : pos.left,
                top: pos.anchor === 'lb' || pos.anchor === 'rb' ? pos.top + dy : pos.top,
                width: pos.width + dx,
                height: pos.height + dy,
                currentWidth: pos.currentWidth + e.movementX,
                currentHeight: pos.currentHeight + e.movementY
            });

            if (settings[idx].type === 'text') {
                fitText(
                    idx,
                    itemRefs.current[idx].current.getElementsByTagName("SPAN")[0],
                    () => itemRefs.current[idx].current.getBoundingClientRect().width - 2 * (settings[idx].properties.paddingHorizontal + settings[idx].properties.borderWidth),
                    () => itemRefs.current[idx].current.getElementsByTagName("SPAN")[0].getBoundingClientRect()
                );
            }

            refreshInputs(settings[idx].position);
        }

        function onMouseUp() {
            containerRef.current.removeEventListener("mousemove", onMouseMove);
            containerRef.current.removeEventListener("mouseup", onMouseUp);

            if (settings[idx].type === 'text') {
                modifyPosition(idx, {
                    top: pos.anchor === 'lb' || pos.anchor === 'rb'
                        ? pos.top + (100.0 * (pos.currentHeight - previousHeight) / containerHeight)
                        : pos.top,
                });
            } else {
                const finalHeight = aspectRatio * pos.currentWidth;
                const finalPercentHeight = 100.0 * finalHeight / containerHeight;

                modifyPosition(idx, {
                    top: pos.anchor === 'lb' || pos.anchor === 'rb'
                        ? pos.top + (100.0 * (pos.currentHeight - previousHeight) / containerHeight)
                        : pos.top,
                    height: finalPercentHeight,
                    currentHeight: finalHeight
                });
            }

            handleRefresh();
        }

        containerRef.current.addEventListener("mousemove", onMouseMove);
        containerRef.current.addEventListener("mouseup", onMouseUp);

        e?.stopPropagation();
    };

    const performSetSelectedIndex = (idx) => {
        setSelectedIndex(idx);
    };

    const createContainerStyle = () => {
        return {
            position: 'relative',
            outline: '1px solid black',
            width: `${settingsToEdit.width}`,
            height: `${settingsToEdit.height}px`,
            margin: '0 auto',
            overflow: 'hidden'
        };
    };

    const createContainerImageStyle = () => {
        return {
            visibility: 'visible',
            position: 'absolute',
            inset: '0px',
            boxSizing: 'border-box',
            padding: '0px',
            border: 'none',
            margin: 'auto',
            display: 'block',
            width: '0px',
            height: '0px',
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: '100%',
            maxHeight: '100%',
            objectFit: 'cover',
            userSelect: 'none'
        };
    };

    const createCanvasStyle = () => {
        return {
            visibility: 'visible',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            userSelect: 'none'
        };
    };

    const createDivStyle = (item, idx) => {

        const commonStyle = {
            position: 'absolute',
            outline: (selectedIndex === idx ? '1px dotted black' : 'none'),
            top: item.position.currentTop + 'px',
            left: item.position.currentLeft + 'px',
            width: item.position.currentWidth + 'px',
            height: item.position.currentHeight + 'px',
            opacity: item.properties.opacity,
            paddingLeft: item.properties.paddingHorizontal,
            paddingRight: item.properties.paddingHorizontal,
            paddingTop: item.properties.paddingVertical,
            paddingBottom: item.properties.paddingVertical,
            borderWidth: item.properties.borderWidth + 'px',
            borderStyle: item.properties.borderStyle,
            borderColor: item.properties.borderColor,
            borderRadius: item.properties.borderRadius + 'px',
            boxShadow: `${item.properties.boxShadowColor} ${item.properties.boxShadowOffsetX}px ${item.properties.boxShadowOffsetY}px ${item.properties.boxShadowBlurRadius}px ${item.properties.boxShadowSpreadRadius}px`,
        };

        switch(item.type){
            case 'image':
                return {
                    ...commonStyle,
                    background: `url(${item.properties.content}) center center / cover no-repeat`
                };
            default:
                return {
                    ...commonStyle,
                    backgroundColor: item.properties.backgroundColor,
                };
        }
    };

    const createSpanStyle = item => {
        return {
            position: 'absolute',
            display: 'inline-block',
            color: item.properties.color,
            opacity: item.properties.opacity,
            textAlign: item.properties.textAlign,
            fontFamily: item.properties.fontFamily,
            fontSize: item.properties.fontSize + 'px',
            fontWeight: item.properties.fontWeight,
            fontStyle: item.properties.fontStyle,
            whiteSpace: item.properties.whiteSpace,
            textShadow: `${item.properties.textShadowOffsetX}px ${item.properties.textShadowOffsetY}px ${item.properties.textShadowBlurRadius}px ${item.properties.textShadowColor}`,
            lineHeight: (item.position.currentHeight - 2 * item.properties.borderWidth - 2 * item.properties.paddingVertical) + 'px',
            userSelect: 'none'
        };
    };

    const createResizeStyle = (idx) => {
        return {
            display: (selectedIndex === idx ? 'block' : 'none'),
            position: 'absolute',
            right: '0',
            bottom: '0',
            width: '10px',
            height: '10px',
            background: 'linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(0,0,0,1) 50%)'
        };
    };

    const canvasArrow = (context, fromx, fromy, tox, toy) => {
        const headlen = 10;
        const dx = tox - fromx;
        const dy = toy - fromy;
        const angle = Math.atan2(dy, dx);

        context.beginPath();
        context.lineWidth = 2;
        context.strokeStyle = "#FFFFFF";
        context.fillStyle = "#FFFFFF";
        context.moveTo(fromx, fromy);
        context.arc(fromx, fromy, 5, 0, 2*Math.PI, false);
        context.fill();
        context.moveTo(fromx, fromy);
        context.lineTo(tox, toy);
        context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
        context.moveTo(tox, toy);
        context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
        context.stroke();
    };

    const draw = (showArrows) => {
        const canvas = canvasRef.current;
        if (canvas.getContext) {
            const ctx = canvas.getContext('2d');

            ctx.canvas.width  = containerWidth;
            ctx.canvas.height = containerHeight;

            ctx.clearRect(0, 0, containerWidth, containerHeight);

            if (showArrows) {
                settings.map((item) => {
                    if (item.position.parentIndex !== -1) {
                        const parentItem = settings[item.position.parentIndex];
                        const p1 = getAnchorCurrentLocation(item.position, item.position.anchor);
                        const p2 = getAnchorCurrentLocation(parentItem.position, item.position.parentAnchor);

                        canvasArrow(ctx, p1.currentLeft, p1.currentTop, p2.currentLeft, p2.currentTop);
                    } else {
                        const p1 = getAnchorCurrentLocation(item.position, item.position.anchor);
                        switch(item.position.anchor) {
                            case 'lt':
                                canvasArrow(ctx, p1.currentLeft, p1.currentTop, 0, 0);
                                break;
                            case 'rt':
                                canvasArrow(ctx, p1.currentLeft, p1.currentTop, containerWidth, 0);
                                break;
                            case 'lb':
                                canvasArrow(ctx, p1.currentLeft, p1.currentTop, 0, containerHeight);
                                break;
                            case 'rb':
                            default:
                                canvasArrow(ctx, p1.currentLeft, p1.currentTop, containerWidth, containerHeight);
                                break;
                        }
                    }
                });
            }
        }
    };

    const handleAdd = (type, file) => {

        const { width, height } = containerRef.current.getBoundingClientRect();

        const performAdd = (type, file, percentageWidth, percentageHeight, aspectRatio) => {
            setSettings(prevState => {
                const newState = prevState.slice(0);
                newState.push({
                    type: type,
                    position: {
                        parentIndex: -1,
                        parentAnchor: 'lt',
                        anchor: 'lt',

                        top: 100.0 * 10 / height,
                        left: 100.0 * 10 / width,
                        width: percentageWidth,
                        height: percentageHeight,

                        minWidth: null,
                        maxWidth: null,
                        minHeight: null,
                        maxHeight: null,

                        currentTop: 10,
                        currentLeft: 10,
                        currentWidth: width * percentageWidth / 100.0,
                        currentHeight: height * percentageHeight / 100.0,

                        aspectRatio: aspectRatio
                    },
                    properties: {
                        fontFamily: 'Verdana',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        fontSize: 16,
                        color: '#FFFFFF',
                        backgroundColor: 'transparent',
                        opacity: 1.0,
                        textAlign: 'center',
                        content: type === 'text' ? 'Nowo dodany tekst' : (type === 'image' ? file[0].path : ''),
                        whiteSpace: 'nowrap',
                        paddingHorizontal: type === 'text' ? 10 : 0,
                        paddingVertical: type === 'text' ? 10 : 0,
                        borderWidth: 0,
                        borderStyle: 'solid',
                        borderColor: '#000000',
                        borderRadius: 0,
                        boxShadow: ''
                    },
                    animation:{
                        in: null,
                        out: null
                    },
                    href: {
                        url: null
                    },
                    hover: {
                        type: null
                    }
                });
                return newState;
            });

            updateRefsLength(+1);
        };

        if (type === 'text') {
            performAdd(type, file, 50.0, 50.0);
        } else {
            const imgTag = new Image();
            imgTag.onload = function () {

                let w = this.width;
                let h = this.height;

                const aspectRatio = 1.0 * this.height / this.width;

                if (w > width || h > height) {
                    if ((1.0 * w / width) > (1.0 * h / height)) {
                        w = 0.9 * width;
                        h = h * (1.0 * 0.9 * width / this.width);
                    } else {
                        h = 0.9 * height;
                        w = w * (1.0 * 0.9 * height / this.height);
                    }
                }

                performAdd(type, file, 100.0 * w / width, 100.0 * h / height, aspectRatio);
            };
            imgTag.src = file[0].path;
        }
    };

    const handleDelete = idx => {
        const connectedItem = settings.find(s => s.position.parentIndex === idx);

        if (connectedItem) {
            utils.error('Do tego elementu przypięte są inne elementy - usuń to powiązanie lub usuń te elementy w pierwszej kolejności.');
            return;
        }

        setSelectedIndex(-1);

        setSettings(prevState => {
            const newState = prevState.slice(0);
            newState.splice(idx, 1);
            return newState;
        });

        updateRefsLength(-1);
    };

    const handleResize = () => {
        const cWidth = containerRef.current.getBoundingClientRect().width;
        const cHeight = containerRef.current.getBoundingClientRect().height;

        setContainerWidth(cWidth);
        setContainerHeight(cHeight);

        resizeBlocks(-1, null);
        fitTexts();

        aspectRatioRef.current.value = 1.0 * cHeight / cWidth;

        draw(showArrows);

        settingsToEdit.settings.aspectRatio = 1.0 * cHeight / cWidth;
    };

    const handleSave = () => {
        onSave(settingsToEdit.slide, settingsToEdit.device, settingsToEdit.settings.aspectRatio, settings);
    };

    const handleAfterEdit = (modifiedPosition) => {
        // zmiana anchor wpływa na pozycję stąd konieczna poprawka
        modifyPosition(selectedIndex, {
            left: modifiedPosition.left,
            top: modifiedPosition.top,
            currentLeft: modifiedPosition.currentLeft,
            currentTop: modifiedPosition.currentTop
        });

        handleRefresh();
    };

    const onSzerokoscChange = () => {
        if (selectedIndex < 0) {
            return;
        }

        const { width } = containerRef.current.getBoundingClientRect();

        const newWidth = Math.min(Math.max(0, parseFloat(szerokoscRef.current.value)), 100);
        const newCurrentWidth = width * (newWidth / 100.0);

        modifyPosition(selectedIndex, {
            width: newWidth,
            currentWidth: newCurrentWidth
        });

        handleRefresh();
    };

    const onWysokoscChange = () => {
        if (selectedIndex < 0) {
            return;
        }

        const { height } = containerRef.current.getBoundingClientRect();

        const newHeight = Math.min(Math.max(0, parseFloat(wysokoscRef.current.value)), 100);
        const newCurrentHeight = height * (newHeight / 100.0);

        modifyPosition(selectedIndex, {
            height: newHeight,
            currentHeight: newCurrentHeight
        });

        handleRefresh();
    };

    const onPozycjaXChange = () => {
        if (selectedIndex < 0) {
            return;
        }

        const maxL = containerWidth - itemRefs.current[selectedIndex].current.getBoundingClientRect().width;

        const newLeft = Math.min(Math.max(0, parseFloat(pozycjaXRef.current.value)), maxL);
        const newCurrentLeft = containerWidth * (newLeft / 100.0);

        modifyPosition(selectedIndex, {
            left: newLeft,
            currentLeft: newCurrentLeft,
        });

        handleRefresh();
    };

    const onPozycjaYChange = () => {
        if (selectedIndex < 0) {
            return;
        }

        const maxT = containerHeight - itemRefs.current[selectedIndex].current.getBoundingClientRect().height;

        const newTop = Math.min(Math.max(0, parseFloat(pozycjaYRef.current.value)), maxT);
        const newCurrentTop = containerHeight * (newTop / 100.0);

        modifyPosition(selectedIndex, {
            top: newTop,
            currentTop: newCurrentTop,
        });

        handleRefresh();
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleResize);

        const init = () => {
            updateRefsLength(settingsToEdit.settings.elements.length);
            setRefsLength(settingsToEdit.settings.elements.length);
            handleResize();
            handleRefresh();
        };

        const checkFontsLoaded = () => {
            if (document.fonts) {
                document.fonts.ready.then(init);
            } else {
                setTimeout(init, 500);
            }
        };

        checkFontsLoaded();

        return () => {
            document.removeEventListener('visibilitychange', handleResize);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        window.addEventListener('changeorientation', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('changeorientation', handleResize);
        };
    }, [containerWidth, containerHeight]);

    useEffect(() => {
        handleResize();
    }, [refsLength]);

    useEffect(() => {
        handleResize();
        draw(showArrows);
    }, [forceRefresh]);

    const fontsCss = `@import url('${getGoogleFontsUrl(fonts)});`;

    return (
        <Container fluid className={clsx('mb-3 h-100', styles.fullScreenOnMobile)}>
            <style>{fontsCss}</style>
            <div className="mb-2">
                <label className="mr-5">Pokaż powiązania elementów: <input type="checkbox" checked={showArrows} onClick={() => { setShowArrows(!showArrows); draw(!showArrows); }}/></label>
                Proporcje ekranu: <span className="mx-2"><input ref={aspectRatioRef} style={{textAlign: 'right', width: '60px'}} defaultValue={containerWidth / containerHeight}></input></span>
                <span style={{visibility: (selectedIndex > -1? 'visible' : 'hidden')}}>
                    Szerokość: <span className="mx-2"><input ref={szerokoscRef} style={{textAlign: 'right', width: '60px'}} defaultValue={currentBlockSize.width} onBlur={onSzerokoscChange}></input>%</span>
                    Wysokość: <span className="mx-2"><input ref={wysokoscRef} style={{textAlign: 'right', width: '60px'}} defaultValue={currentBlockSize.height} onBlur={onWysokoscChange}></input>%</span>
                    Pozycja X: <span className="mx-2"><input ref={pozycjaXRef} style={{textAlign: 'right', width: '60px'}} defaultValue={currentBlockSize.x} onBlur={onPozycjaXChange}></input>%</span>
                    Pozycja Y: <span className="mx-2"><input ref={pozycjaYRef} style={{textAlign: 'right', width: '60px'}} defaultValue={currentBlockSize.y} onBlur={onPozycjaYChange}></input>%</span>
                </span>
            </div>

            <div ref={containerRef} style={createContainerStyle()}>
                {isVideo(getFileFormat(settingsToEdit.settings.image.source)) &&
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={'loop'}
                        controls={false}
                        height={'100%'}
                        width={'100%'}
                        style={{objectFit: 'cover'}}
                    >
                        <source src={`${apiUrl}${settingsToEdit.settings.image.source.replaceAll('\\','/')}`} type={'video/'+getFileFormat(settingsToEdit.settings.image.source)} />
                    </video>
                }
                {isImage(getFileFormat(settingsToEdit.settings.image.source)) &&
                    <img src={`${apiUrl}${settingsToEdit.settings.image.source.replaceAll('\\','/')}`} style={createContainerImageStyle()} />
                }
                <canvas ref={canvasRef} style={createCanvasStyle()}/>
                {settings.map((item, i) =>
                    <div key={i} style={createDivStyle(item, i)} ref={itemRefs.current[i]} onMouseDown={(e) => performMouseDownMove(e, i)}>
                        {item.type === 'text' &&
                            <span className="x" style={createSpanStyle(item)}>{item.properties.content}</span>
                        }
                        <div style={createResizeStyle(i)} onMouseDown={(e) => performMouseDownResize(e, i)}></div>
                    </div>
                )}
            </div>

            <AnimatedBannerSettings
                settings={settings}
                selectedIndex={selectedIndex}
                modifyProperties={(idx,obj) => {modifyProperties(idx, obj); handleRefresh(); }}
                handleSelect={performSetSelectedIndex}
                handleAdd={handleAdd}
                handleDelete={handleDelete}
                handleCancel={onCancel}
                handleSave={handleSave}
                handleAfterEdit={handleAfterEdit}
            />

        </Container>
    );
};

AnimatedBannerImageSettings.propTypes = propTypes;

const mapStateToProps = state => ({
    fonts: state.fonts.fonts
});

export default withRouter(withFormUtils(connect(mapStateToProps, null)(AnimatedBannerImageSettings)));