import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getExchangeRates, removeExchangeRate } from '../../redux/methods/exchangeRates';
import useFetch from '../../hooks/useFetch';
import useSearchConfig from '../../hooks/useSearchConfig';

import { Container, Row, Col, Card, CardHeader, CardBody, Button, } from 'reactstrap';

import ExchnageRateSearchForm from '../../features/exchangeRates/ExchangeRateSearchForm';
import ExchangeRatesTable from '../../components/tables/ExchangeRatesTable';
import Paginator from '../../components/Paginator';

import { exchangeRatesSearchConfig } from '../../config/searchConfigs';

import styles from './index.module.scss';
import { generateEditLinkForExchangeRate } from '../../utils/exchangeRates';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getExchangeRates: PropTypes.func,
    removeExchangeRate: PropTypes.func,
    history: PropTypes.object
};

const ExchangeRates = ({ forceRefetch, getExchangeRates, removeExchangeRate, history }) => {
    const intl = useIntl();
    const translations = intl.messages.exchangeRates.view;

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(exchangeRatesSearchConfig);
    const [ exchangeRates, pages ] = useFetch(getExchangeRates, searchConfig, [searchConfig], forceRefetch);

    const handleAddExchangeRate = () => {
        // passing -1 as an id to history, deconsturcting it in edit section and passing this id in GET method is a new BE way of creating records
        history.push(generateEditLinkForExchangeRate(-1));
    };

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    return exchangeRates ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody>
                                <main>
                                    <ExchnageRateSearchForm onSubmit={changeSearchConfig} searchConfig={searchConfig} />
                                    <ExchangeRatesTable
                                        performPageSizeSelect={handleChangePageSize}
                                        pageSize={searchConfig.pageSize}
                                        currentPage={searchConfig.curentPage + 1}
                                        exchangeRates={exchangeRates}
                                        headerLabels={translations.headerLabels}
                                        translations={translations}
                                        removeAction={removeExchangeRate}
                                        history={history}
                                    />
                                    {pages > 1 && (
                                        <Paginator
                                            totalPages={pages}
                                            currentPage={searchConfig.curentPage + 1}
                                            onPageChange={changePage}
                                        />
                                    )}
                                </main>
                                <aside className={styles.footer}>
                                    <Button
                                        color="primary"
                                        onClick={handleAddExchangeRate}
                                    >
                                        <i className="fa fa-plus mr-2" />
                                        <span>
                                            {translations.actions.addCurrency}
                                        </span>
                                    </Button>
                                </aside>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ) : null;
};

ExchangeRates.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.exchangeRates,
});

const mapDispatch = {
    getExchangeRates,
    removeExchangeRate,
};

export default connect(mapStateToProps, mapDispatch)(ExchangeRates);