import { setIsFetched, setAvailableLanguages } from '../../../features/locale/slice';
import { setPersistedChosenLanguage } from '../../../features/persisted/persistedLocale/slice';

import { handleCAPIErrorsSubtle, cAPI } from '../../../utils/cAPI';
import { translateLanguageOptionsList } from '../../../utils/locale';
/**
 * Pre-authentication methods
 */
export const initializeLocale = (availableLanguages, browserLanguage, defaultLanguage, chosenLanguage) => async dispatch => {
    const languageToSet = availableLanguages.find(language => language === browserLanguage) || defaultLanguage;

    const result = await cAPI.APGetAvailableLanguages();
    const { payLoad, error, httpError, unhandledError, statusCode } = result;
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { languageOptionsList } = payLoad;
        const availableLanguages = translateLanguageOptionsList(languageOptionsList);

        dispatch(setAvailableLanguages({ availableLanguages }));
    } else if (errorToHandle) {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }

    if(!chosenLanguage){
        dispatch(setPersistedChosenLanguage({ persistedChosenLanguage: languageToSet }));
    }
    dispatch(setIsFetched({ isFetched: true }));
};

export const changeChosenLanguage = chosenLanguage => dispatch => {
    dispatch(setPersistedChosenLanguage({ persistedChosenLanguage: chosenLanguage }));
};

export const reinitializeLocale = () => async dispatch => {
    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APGetAvailableLanguages();
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { languageOptionsList } = payLoad;
        const availableLanguages = translateLanguageOptionsList(languageOptionsList);

        dispatch(setAvailableLanguages({ availableLanguages }));
    } else if (errorToHandle) {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};

/**
 * Post-auth methods
 */

export const changeChosenLanguageAuthorized = chosenLanguageObject => async dispatch => {
    const { id, iso } = chosenLanguageObject;
    const { error, httpError, unhandledError, statusCode } = await cAPI.APChangeChosenLanguage(id);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (!errorToHandle) {
        dispatch(setPersistedChosenLanguage({ persistedChosenLanguage: iso }));
    } else {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};