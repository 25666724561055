import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { editRegionCountries, getRegionCountries } from '../../../../redux/methods/regions';

import { Container, Button } from 'reactstrap';

import useFetch from '../../../../hooks/useFetch';

import CountriesRegionTable from '../../../../components/tables/CountriesRegionTable';
import CountryAssigner from '../../CountryAssigner';

import styles from './index.module.scss';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    editRegionCountries: PropTypes.func,
    getRegionCountries: PropTypes.func,
    isRegionCountriesRefetchRequired: PropTypes.bool,
};

const RegionsEditCountries = ({ utils, id, name, editRegionCountries, getRegionCountries, isRegionCountriesRefetchRequired }) => {
    const intl = useIntl();
    const translations = intl.messages.regions.edit.tabs.countries;

    const [ countriesAssignedToRegion ] = useFetch(getRegionCountries, id, [], isRegionCountriesRefetchRequired);

    const [checkedCountryIds, setCheckedCountryIds] = useState([]);
    const [isAdditionModalOpen, setIsAdditionModalOpen] = useState(false);
    const [countriesToAssign, setCountriesToAssign] = useState(countriesAssignedToRegion);

    useEffect(() => {
        setCheckedCountryIds([]);
        setIsAdditionModalOpen(false);
        setCountriesToAssign(countriesAssignedToRegion);
    }, [countriesAssignedToRegion]);

    const handleRemoveAction = countryId => {
        editRegionCountries(id, [countryId], 'delete');
    };

    const handleOpenDeleteModal = () => {
        utils.confirm(
            () => editRegionCountries(id, checkedCountryIds, 'delete'),
            translations.deleteManyModal.header,
            translations.deleteManyModal.body
        );
    };

    const handleRemoveFromAssignedList = countryToRemove => {
        setCountriesToAssign(countriesToAssign.filter(country => country.id !== countryToRemove.id));
    };

    const handleAddToAssignedList = countryToAssign => {
        setCountriesToAssign([...countriesToAssign, countryToAssign]);
    };

    const handleOpenAdditionModal = () => {
        setIsAdditionModalOpen(true);
    };

    const handleSubmit = () => {
        const extractIdsFromCountries = countries => countries.map(({ id }) => id);
        const updateType = countriesToAssign.length > 0 ? 'replace' : 'delete';

        const countryIdsToTransfer = extractIdsFromCountries(
            countriesToAssign.length > 0 ? countriesToAssign : countriesAssignedToRegion
        );

        editRegionCountries(id, countryIdsToTransfer, updateType);
    };

    return countriesAssignedToRegion && countriesToAssign ? (
        <React.Fragment>
            <Container fluid>
                <CountriesRegionTable
                    countries={countriesAssignedToRegion}
                    headerLabels={translations.headerLabels}
                    emptyIsoPlaceholder={translations.emptyIsoPlaceholder}
                    emptyNamePlaceholder={translations.emptyNamePlaceholder}
                    setCheckedCountriesIds={setCheckedCountryIds}
                    checkedCheckboxList={checkedCountryIds}
                    removeAction={handleRemoveAction}
                    translations={translations}
                />
                <aside className={styles.footer}>
                    <Button
                        outline
                        type='button'
                        onClick={handleOpenDeleteModal}
                        color='danger'
                        disabled={checkedCountryIds.length === 0}
                    >
                        <i className='fa fa-remove mr-2'/>
                        <span>{translations.buttons.delete}</span>
                    </Button>
                    <Button
                        color='primary'
                        onClick={handleOpenAdditionModal}
                    >
                        <i className='fa fa-plus mr-2'/>
                        <span>{translations.buttons.add}</span>
                    </Button>
                </aside>
            </Container>
            <CountryAssigner
                assignedCountries={countriesToAssign}
                removeFromAssignedList={handleRemoveFromAssignedList}
                addToAssignedList={handleAddToAssignedList}
                isOpen={isAdditionModalOpen}
                setIsOpen={setIsAdditionModalOpen}
                onSubmit={handleSubmit}
                header={`${translations.countryAssigner.header} ${name || translations.countryAssigner.nameFiller}`}
            />
        </React.Fragment>
    ) : null;
};

RegionsEditCountries.propTypes = propTypes;

const mapStateToProps = (state) => ({
    isRegionCountriesRefetchRequired: state.refetchers.regionCountries,
});

const mapDispatch = {
    editRegionCountries,
    getRegionCountries,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(RegionsEditCountries));