import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { useIntl, FormattedDate, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';

import { selectIsCategoryTreePresent, selectIsERPCategoriesIsUsage } from '../../redux/selectors/categories';
import { getCategoryTree, changeCategoryDisplayMode, expandCategories } from '../../redux/methods/categories';
import { generateNonIdsOptionsForDropdown } from '../../utils/components';

import { Container, Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import Dropdown from '../../components/Dropdown';

import FlatCategoryList from '../../features/categories/FlatCategoryList';
import CategoryTreeDND from '../../features/categories/CategoryTreeDND';

import styles from './index.module.scss';

const propTypes = {
    categoryTreeView: PropTypes.oneOf(['tree', 'flat']),
    isCategoryTreePresent: PropTypes.bool,
    getCategoryTree: PropTypes.func,
    expandCategories: PropTypes.func,
    lastSyncDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf('Date')
    ])
};

const Categories = ({
    isCategoryTreePresent,
    getCategoryTree,
    expandCategories,
    categoryDisplayMode,
    changeCategoryDisplayMode,
    forceRefetch,
    lastSyncDate,
    isERPCategoriesInUsage,
    categoryTree,
}) => {
    const intl = useIntl();
    const translations = intl.messages.categories.view;

    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

    const handleDisplayModeSelection = (categoryDisplayMode) => {
        changeCategoryDisplayMode(categoryDisplayMode);
    };

    useEffect(() => {
        if (!isCategoryTreePresent || forceRefetch) {
            getCategoryTree();
        }
    }, [forceRefetch]); // eslint-disable-line

    const displayModeOptions = generateNonIdsOptionsForDropdown(['all', 'selected'], translations.dropdownSelector.options);

    const handleExpandAll = () => {
        const reduceLevel = level => level.reduce((acc,l) => [
            ...acc,
            l.category.id,
            ...(l.subItems ? reduceLevel(l.subItems) : [])
        ], []);
        expandCategories(reduceLevel(categoryTree));
    };

    const handleCollapseAll = () => {
        expandCategories([]);
    };

    return isCategoryTreePresent ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card className='position-relative'>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody>
                                <header className={styles.controls}>
                                    {
                                        isDesktop &&

                                            <span className={styles.controlsView}>
                                                <span className={styles.controlsViewLabel}>{translations.dropdownSelector.label}</span>
                                                <Dropdown
                                                    options={displayModeOptions}
                                                    currentSelectionLabel={translations.dropdownSelector.options[categoryDisplayMode]}
                                                    performSelection={handleDisplayModeSelection}
                                                />
                                                <Button color="secondary" className="ml-2" onClick={handleExpandAll}>
                                                    <i className="fa fa-plus-square mr-2"/>
                                                    <span>{translations.expandAll}</span>
                                                </Button>
                                                <Button color="secondary" className="ml-2" onClick={handleCollapseAll}>
                                                    <i className="fa fa-minus-square mr-2"/>
                                                    <span>{translations.collapseAll}</span>
                                                </Button>
                                            </span>
                                    }
                                </header>
                                <div>
                                    <span>{translations.lastSyncDateLabel}</span>
                                    <strong>
                                        <span>
                                            <FormattedTime value={lastSyncDate} />
                                        </span>
                                        <span className='ml-1'>
                                            <FormattedDate value={lastSyncDate} day='2-digit' month='2-digit' year='numeric' />
                                        </span>
                                    </strong>
                                </div>
                                <main className={styles.main}>
                                    {
                                        isDesktop
                                            ? <CategoryTreeDND displayMode={categoryDisplayMode} isERPCategoriesInUsage={isERPCategoriesInUsage} />
                                            : <FlatCategoryList isERPCategoriesInUsage={isERPCategoriesInUsage} />
                                    }
                                </main>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ) : null;
};

Categories.propTypes = propTypes;

const mapStateToProps = state => ({
    isCategoryTreePresent: selectIsCategoryTreePresent(state),
    categoryDisplayMode: state.categories.categoryDisplayMode,
    forceRefetch: state.refetchers.categories,
    lastSyncDate: state.categories.lastSyncDate,
    isERPCategoriesInUsage: selectIsERPCategoriesIsUsage(state),
    categoryTree: state.categories.categoryTree
});

const mapDispatch = {
    getCategoryTree,
    changeCategoryDisplayMode,
    expandCategories
};

export default connect(mapStateToProps, mapDispatch)(Categories);