import { getTranslationForIso } from '../shared';
import { orderDetailsConfig, intlDateTimeConfig } from '../../config';

export const generateEditLinkForOrder = id => `/dashboard/orders/edit?orderID=${id}`;

export const generateOrdersConfig = (statuses, language) => {
    return statuses.map(status => ({
        id: status.id,
        label: getTranslationForIso(status.name, language),
        color: status.color ? status.color : null,
        description: status.description ? getTranslationForIso(status.description, language) : null
    }));
};

export const generateAvailableStatuses = ( orderStatuses, nextStatuses, actualStatusId) =>
    nextStatuses
        ? orderStatuses.filter(status =>
            nextStatuses.find(nextStatus =>
                status.id === nextStatus.id || status.id === actualStatusId
            )
        ) : []
;

export const createOrdersDropdownOptions = (statusConfig, translation) => {
    return [
        {
            id: null,
            label: translation
        },
        ...statusConfig
    ];
};

const generateFieldFromFields = order => {

    const {
            shipmentAddressAddress,
            shipmentAddressZipCode,
            shipmentAddressCity,
            shipmentAddressName,
            shipmentAddressSurname,
            totalGrossPrice,
            currency,
            attributes,
            contrahentName,
            contrahentNumber,
            phone,
            email
        } = order;

    const generatedShipmentAddress = shipmentAddressAddress && shipmentAddressZipCode && shipmentAddressCity ? `
        ${shipmentAddressAddress}, ${shipmentAddressZipCode} ${shipmentAddressCity}
    ` : null;

    const generatedReceivingPerson = shipmentAddressName && shipmentAddressSurname ? `
        ${shipmentAddressName} ${shipmentAddressSurname}
    ` : null;

    const generatedTotalGrossPrice = totalGrossPrice && currency ? `
        ${totalGrossPrice}${currency}
    ` : null;

    const generatedContrahentData = contrahentName && contrahentName && phone && email ? `
        ${contrahentName} ${contrahentNumber} ${phone} ${email}
    ` : null;

    return {
        ...order,
        receivingPerson: generatedReceivingPerson,
        shipmentAddress: generatedShipmentAddress,
        totalGrossPrice: generatedTotalGrossPrice,
        contrahentData: generatedContrahentData,
        attributes: attributes && attributes.join(', '),
    };
};

export const generateSections = (labels, order, additionalFields, language) => {
    const generatedFields = generateFieldFromFields(order.order);

    const mappedSections = orderDetailsConfig.map(section => {
        return section.map(field => {
            return ({
                value: field.isDate
                    ? Intl.DateTimeFormat(language, intlDateTimeConfig).format(new Date(generatedFields[field.key]))
                    : field.isLink && generatedFields[field.key] ? generatedFields[field.key].filename : generatedFields[field.key],
                label: labels[field.key],
                link: field.isLink && generatedFields[field.key] && generatedFields[field.key].href,
                isExternal: field.isLink && field.isExternal
            });
        });
    });

    const sectionsWithAddedAdditionalFields = mappedSections.map((array, index) => {
        return index !== mappedSections.length - 1 ?
            array : [...array, ...additionalFields.filter(item => item.value !== null)];
    });

    return sectionsWithAddedAdditionalFields.map(section =>
        section.filter(field => field.value)).filter(section => section.length > 0);
};

export const generateOrderListCSV = (orders) => {
    return orders.map((order, index) => ({
        index,
        'Date': order.date,
        'Order number': order.number,
        'Contrahent name': order.contrahentName,
        'Gross value': order.totalGrossPrice.toFixed(2),
        'Currency': order.currency,
        'Status': order.orderStatus,
        'Attributes': order.attributes.join(', '),
        'Attachment': order.attachement
    }));
};

export const generateOrderDetailsCSV = order => ([
    {
        'Number': order.number,
        'Date': order.date,
        'Status': order.orderStatus,
        'Last status change': order.orderStatusChangeDate,
        'Total gross price': order.totalGrossPrice,
        'Attributes': order.attributes,
        'Payment method': order.paymentMethod,
        'Invoice number': order.invoiceNumber,
        'Return number': order.returnNumber,
    },
    {
        'Contrahent name': order.contrahentName,
        'Contrahent number': order.contrahentNumber,
        'Contrahent email': order.email,
        'Contrahent phone': order.phone,
    },
    {
        'Shimpent Address': [order.shipmentAddressAddress, order.shipmentAddressZipCode, order.shipmentAddressCity].join(', '),
        'Receiving person': [order.shipmentAddressName, order.shipmentAddressSurname].join(' '),
        'Shipment method': order.shipmentMethod,
        'Package number': order.packageNumber,
    },
    {
        'Total weight': order.totalWeight,
        'Label': order.label,
        'EAN code': order.ean,
    },
    {
        'Comment': order.comment
    }
]);

export const generateTranslatedOrderAdditionalFields = (orderAdditionalFieldsCollection, orderAdditionalFields, iso) => {
    return orderAdditionalFields.map(field => ({
        id: field.id,
        label: getTranslationForIso(orderAdditionalFieldsCollection.find(fieldFromCollection => fieldFromCollection.id === field.id).name, iso),
        value: field.value
    }));
};
