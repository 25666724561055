import React, {
    useEffect,
    useState
} from 'react';

import PropTypes from 'prop-types';

import {
    withRouter,
    Redirect
} from 'react-router-dom';

import {
    recoverPasswordRequest
} from '../../redux/methods/auth';

import {
    changeUserStatus,
    changeUserEmail,
    changeUserFeatures,
    changeUserRole,
    getUserData,
    addUser
} from '../../redux/methods/users';

import {
    useIntl
} from 'react-intl';

import {
    connect
} from 'react-redux';

import useFetch from '../../hooks/useFetch';

import ContainerWithCard from '../../components/ContainerWithCard';

import {
    Button,
    Col, Form,
    Input,
    InputGroup,
    InputGroupAddon,
    Row,
    Container
} from 'reactstrap';

import styles from './index.module.scss';

import clsx from 'clsx';

import FormFieldTextOrNumber from '../../components/formFields/FormFieldTextOrNumber';

import SectionEditFormButtons from '../../components/SectionEditFormButtons';

import { Form as FinalForm } from 'react-final-form';

const propTypes = {
    location: PropTypes.object,
    getRegionBasicData: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const UserEdit = ({
    location,
    getUserData,
    forceRefetch,
    recoverPasswordRequest,
    changeUserStatus,
    changeUserEmail,
    changeUserFeatures,
    changeUserRole,
    addUser,
    history
}) => {
    const intl = useIntl();

    const translations = intl.messages.dashboard.clients.edit;

    const [email, setEmail] = useState('');

    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);

    const chosenUserId = query.get('userID');

    const isAdding = chosenUserId === '0';

    const [data] = useFetch(getUserData, chosenUserId, [chosenUserId], forceRefetch);

    const [features, setFeatures] = useState([]);

    const [role, setRole] = useState();

    useEffect(() => {
        if (data?.features) {
            setFeatures(data.features);
        }
        if (data?.role) {
            setRole(data.role);
        }
    }, [data]);

    const Labels = ({ label, value }) => {
        return value ? <div className={styles.row}><div>{label}:</div> <div>{value}</div></div> : null;
    };

    const handleChangeEmail = (e) => {
        e.preventDefault();

        changeUserEmail({ id: data.id, formData: { newEmail: email } });
    };

    const handleGerenatePassword = () => {
        recoverPasswordRequest({ email: data.email });
    };

    const handleChangeStatus = () => {
        changeUserStatus(data.id);
    };

    const handleChangeFeatures = e => {
        e.preventDefault();

        changeUserFeatures({ id: data.id, formData: { features } });
    };

    const handleChangeRole = e => {
        e.preventDefault();

        changeUserRole({ id: data.id, formData: { role } });
    };

    const hasAccount = data?.id > 0;

    const handleOnSubmit = async formData => {
        const result = await addUser(formData);

        const userId = parseInt(result);

        if(!userId) {
            return result;
        }

        history.push(`/panelUsers/edit?userID=${userId}`);
    };

    return (chosenUserId || chosenUserId === '0') ? (
        data ? (
            <ContainerWithCard title={translations.title} iconClass='fa fa-user'>
                {!isAdding ?
                    <>
                        <Row className='mt-3 mt-md-0'>
                            <Col className='col-12 col-md-6 mt-3 mt-md-0'>
                                <p><b>{translations.contractor}</b></p>

                                <Labels label={translations.nip} value={data.nip} />

                                <Labels label={translations.companyName} value={data.companyName} />

                                <Labels label={translations.firstName} value={data.firstName} />

                                <Labels label={translations.lastName} value={data.lastName} />

                                <Labels label={translations.role} value={data.role} />
                            </Col>

                            <Col className='col-12 col-md-6 mt-3 mt-md-0'>
                                <p><b>{translations.contact}</b></p>

                                <Labels label={translations.firstNameAndLastName} value={(data.firstName || data.lastName) ? `${data.firstName} ${data.lastName}` : null} />

                                <Labels label={translations.phoneNumber} value={data?.userAddress?.phoneNumber} />

                                <Labels label={translations.address} value={data?.userAddress?.address} />
                            </Col>
                        </Row>

                        <Row>
                            <Col className='mt-3'>
                                <p><b>{translations.place}</b></p>

                                <Labels label={translations.zipCode} value={data?.userAddress?.zipCode} />

                                <Labels label={translations.city} value={data?.userAddress?.city} />

                                <Labels label={translations.address} value={data?.userAddress?.address} />

                                <Labels label={translations.recipient} value={(data?.userAddress?.firstName || data?.userAddress?.lastName) ? `${data?.userAddress?.firstName} ${data?.userAddress?.lastName}` : null} />

                                <Labels label={translations.phoneNumber} value={data?.userAddress?.phoneNumber} />
                            </Col>
                        </Row>

                        <hr className='my-5' />
                    </> :
                    <Container fluid>
                        <FinalForm
                            keepDirtyOnReinitialize
                            initialValues={data}
                            onSubmit={handleOnSubmit}
                            render={({ handleSubmit, form }) => {

                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Container fluid className='mt-3 px-0'>
                                            <Row className='mt-2'>
                                                <FormFieldTextOrNumber
                                                    name='firstName'
                                                    type='text'
                                                    label={translations.inputs.name}
                                                    required={true}
                                                    className={styles.input}
                                                />
                                            </Row>
                                            <Row className='mt-2'>
                                                <FormFieldTextOrNumber
                                                    name='lastName'
                                                    type='text'
                                                    label={translations.inputs.surname}
                                                    className={styles.input}
                                                />
                                            </Row>
                                            <Row className='mt-2'>
                                                <FormFieldTextOrNumber
                                                    name='email'
                                                    type='email'
                                                    label={translations.inputs.email}
                                                    required={true}
                                                    className={styles.input}
                                                />
                                            </Row>
                                            <Row className='mt-2'>
                                                <FormFieldTextOrNumber
                                                    name='password'
                                                    type='password'
                                                    label={translations.inputs.password}
                                                    required={true}
                                                    className={styles.input}
                                                />
                                            </Row>
                                            <Row className='mt-2'>
                                                <FormFieldTextOrNumber
                                                    name='passwordRepeated'
                                                    type='password'
                                                    label={translations.inputs.passwordRepeated}
                                                    required={true}
                                                    className={styles.input}
                                                />
                                            </Row>
                                        </Container>
                                        <SectionEditFormButtons
                                            formReset={form.reset}
                                            goBack={() => history.back()}
                                        />
                                    </Form>
                                );
                            }}
                        />
                    </Container>}

                {hasAccount &&
                    <>
                        <Row className='mb-3'>
                            <Col>
                                <div className={clsx('d-flex align-items-center')}>
                                    {translations.active}

                                    <label className={clsx("switch switch-pill switch-success m-0 ml-1", styles.switchAdjust)}>
                                        <input
                                            type="checkbox"
                                            onClick={handleChangeStatus}
                                            className="switch-input"
                                            checked={data.isActive}
                                            onChange={() => null}
                                        />

                                        <span className={clsx("switch-slider", !data.isActive && "bg-danger border-danger")}></span>
                                    </label>
                                </div>
                            </Col>
                        </Row>

                        <Row className='mb-3'>
                            <Col className='col-12 col-md-6 mt-3 mt-md-0'>
                                <Button onClick={handleGerenatePassword}>{translations.generatePwd}</Button>
                            </Col>

                            <Col className='col-12 col-md-6 mt-3 mt-md-0'>
                                <Form onSubmit={handleChangeEmail}>
                                    <InputGroup>
                                        <Input value={email} onChange={e => setEmail(e.target.value)} required placeholder={translations.emailPlaceholder} type='email' />

                                        <InputGroupAddon addonType="append"><Button color="secondary">{translations.email}</Button></InputGroupAddon>
                                    </InputGroup>
                                </Form>
                            </Col>
                        </Row>

                        {data.role === 'Admin' &&
                            <Row className='mb-3'>
                                <Col className='col-12 col-md-6 mt-3 mt-md-0'>
                                    <Form onSubmit={handleChangeFeatures}>
                                        <InputGroup>
                                            <Input
                                                value={features}
                                                onChange={e => {
                                                    setFeatures([...e.target.selectedOptions].map(option => option.value));
                                                }}
                                                type='select'
                                                multiple
                                            >
                                                {data.allFeatures.map(feature => <option key={feature} value={feature}>{feature}</option>)}
                                            </Input>

                                            <InputGroupAddon addonType="append"><Button color="secondary">{translations.savePermissisons}</Button></InputGroupAddon>
                                        </InputGroup>
                                    </Form>
                                </Col>
                            </Row>}

                        <Row>
                            <Col className='col-12 col-md-6 mt-3 mt-md-0'>
                                <Form onSubmit={handleChangeRole}>
                                    <InputGroup>
                                        <Input
                                            value={role}
                                            onChange={e => {
                                                setRole(e.target.value);
                                            }}
                                            type='select'
                                        >
                                            {data.allRoles.map(role => <option key={role} value={role}>{role}</option>)}
                                        </Input>

                                        <InputGroupAddon addonType="append"><Button color="secondary">{translations.changeRole}</Button></InputGroupAddon>
                                    </InputGroup>
                                </Form>
                            </Col>
                        </Row>
                    </>
                }
            </ContainerWithCard>
        ) : null
    ) : (
        <Redirect to='/dashboard/clients' />
    );
};

UserEdit.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.user
});

const mapDispatch = {
    getUserData,
    recoverPasswordRequest,
    changeUserStatus,
    changeUserEmail,
    changeUserFeatures,
    changeUserRole,
    addUser
};

export default withRouter(connect(mapStateToProps, mapDispatch)(UserEdit));