import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getFrequentPhrases, getLastPhrases, getMostViewedProducts } from '../../../../redux/methods/stats';

import { frequentPhrasesSearchConfig, lastPhrasesSearchConfig, mostViewedProductsSearchConfig } from '../../../../config/searchConfigs';

import { withFormUtils } from '../../../../hoc/withFormUtils';
import Tabulator from "../../../../components/Tabulator";
import PhrasesStatsSubSection from './PhrasesStatsSubSection';
import ProductStatsSubSection from './ProductStatsSubSection';

import { formUtilsHocPropTypes } from '../../../../propTypes';

const propTypes = {
    translations: PropTypes.object,
    utils: formUtilsHocPropTypes,
    getFrequentPhrases: PropTypes.func,
    getLastPhrases: PropTypes.func,
};

const StatsSection = ({
    translations,
    utils,
    getFrequentPhrases,
    getLastPhrases,
    getMostViewedProducts,
}) => {

    return (
        <div className='w-100 h-100'>
            <Tabulator
                listOfElements={[
                    {
                        label: translations.labels.searchTerms,
                        id: 'searchTerms-table',
                        component: (
                            <PhrasesStatsSubSection
                                isSearchNumberTable={true}
                                translations={translations}
                                config={frequentPhrasesSearchConfig}
                                fetchMethod={getFrequentPhrases}
                                utils={utils}
                            />
                        )
                    },
                    {
                        label: translations.labels.lastPhrases,
                        id: 'lastPhrases-table',
                        component: (
                            <PhrasesStatsSubSection
                                translations={translations}
                                config={lastPhrasesSearchConfig}
                                fetchMethod={getLastPhrases}
                                utils={utils}
                            />
                        )
                    },
                    {
                        label: translations.labels.productSearch,
                        id: 'productSearch-table',
                        component: (
                            <ProductStatsSubSection
                                translations={translations}
                                config={mostViewedProductsSearchConfig}
                                fetchMethod={getMostViewedProducts}
                                utils={utils}
                            />
                        )
                    },
                ]}
            />
        </div>
    );
};

StatsSection.propTypes = propTypes;

const mapDispatch = {
    getFrequentPhrases,
    getLastPhrases,
    getMostViewedProducts,
};

export default withFormUtils(connect(null, mapDispatch)(StatsSection));