import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateDiscountParams, getDiscountParams } from '../../../../redux/methods/discounts';

import { generateInitialValuesForParameters } from '../../../../utils/discounts';
import useFetch from '../../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row, Col } from 'reactstrap';

import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import DiscountEditFieldGenerator from './DiscountEditFieldGenerator';

import { discountPropTypes, formUtilsHocPropTypes } from '../../../../propTypes';
import { discountTypeEnum, discountRangeEnum } from '../../../../enums/discounts';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    discountToEdit: discountPropTypes,
    discountToEditParameters: PropTypes.arrayOf(PropTypes.shape({
        paramType: PropTypes.string,
        value: PropTypes.number,
    })),
    updateDiscountParams: PropTypes.func,
    getDiscountParams: PropTypes.func
};

const DiscountEditParameters = ({ discountToEdit, updateDiscountParams, getDiscountParams }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.parameters;

    const [ discountToEditParams ] = useFetch(getDiscountParams, discountToEdit.discountId);

    const handleOnSubmit = formData => {
        updateDiscountParams(discountToEdit.discountId, formData);
    };

    const isDiscountTypePercentage =  discountToEdit.discountTypeId === discountTypeEnum.percentage;
    const isDiscountRangeSpecificProducts =  discountToEdit.discountRangeId === discountRangeEnum.specificProducts;

    return discountToEditParams ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={generateInitialValuesForParameters(discountToEditParams.discountParams)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='px-0' fluid>
                                    <Row>
                                        {!isDiscountTypePercentage &&
                                            <>
                                                <Col xs='12' md='6' className='p-2'>
                                                    <FormFieldTextOrNumber
                                                        type='number'
                                                        min={1}
                                                        name='DiscountAmount'
                                                        label={translations.fields.discountAmount.label}
                                                        tooltipText={translations.fields.discountAmount.tooltipText}
                                                    />
                                                </Col>
                                            </>
                                        }
                                        {isDiscountTypePercentage &&
                                            <>
                                                <Col xs='12' md='6' className='p-2'>
                                                    <FormFieldTextOrNumber
                                                        type='number'
                                                        min={1}
                                                        name='DiscountPercentage'
                                                        label={translations.fields.discountPercentage.label}
                                                        tooltipText={translations.fields.discountPercentage.tooltipText}
                                                    />
                                                </Col>
                                                <Col xs='12' md='6' className='p-2'>
                                                    <FormFieldTextOrNumber
                                                        type='number'
                                                        min={1}
                                                        name='DiscountPercentageAmountLimit'
                                                        step='0.01'
                                                        label={translations.fields.discountPercentageAmountLimit.label}
                                                        tooltipText={translations.fields.discountPercentageAmountLimit.tooltipText}
                                                    />
                                                </Col>
                                            </>
                                        }
                                        <DiscountEditFieldGenerator
                                            rangeId={discountToEdit.discountRangeId}
                                            isDiscountRangeSpecificProducts={isDiscountRangeSpecificProducts}
                                        />
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

DiscountEditParameters.propTypes = propTypes;

const mapStateToProps = (state) => ({
    discountToEdit: state.discounts.discountToEdit.data
});

const mapDispatch = {
    updateDiscountParams,
    getDiscountParams,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(DiscountEditParameters));