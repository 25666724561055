import React from 'react';
import PropTypes from 'prop-types';

import { generateEditLinkForCurrency } from '../../../utils/currencies';

import { UncontrolledTooltip } from 'reactstrap';

import TableGenerator from '../../generators/TableGenerator';

import { currencyPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    currencies: PropTypes.arrayOf(currencyPropTypes),
    currencyIsActiveText: PropTypes.shape({
        active: PropTypes.string,
        inactive: PropTypes.string,
    }).isRequired,
    translations: PropTypes.shape({
        deleteModal: PropTypes.shape({
            header: PropTypes.string,
            body: PropTypes.string,
            confirm: PropTypes.string,
            cancel: PropTypes.string,
        }),
        tooltips: PropTypes.shape({
            delete: PropTypes.string,
            edit: PropTypes.string,
        })
    }).isRequired,
    emptyPlaceholders: PropTypes.shape({
        name: PropTypes.string,
        shortName: PropTypes.string,
        table: PropTypes.string,
        regions: PropTypes.string,
    }).isRequired,
    headerLabels: PropTypes.shape({
        name: PropTypes.string,
        shortName: PropTypes.string,
        regions: PropTypes.string,
        status: PropTypes.string,
        actions: PropTypes.string,
    }).isRequired,
    removeAction: PropTypes.func.isRequired,
    history: PropTypes.object,
};

const CurrenciesTable = ({
    currencies,
    emptyPlaceholders,
    headerLabels,
    removeAction,
    translations,
    history,
    updateAction,
    checkedCurrenciesIds,
    setCheckedCurrenciesIds,
}) => {

    const deleteAction = (id) => () => {
        removeAction(id);
    };

    const handleDefaultAction = id => () => {
        history.push(generateEditLinkForCurrency(id));
    };

    const RegionCell = (item) => {
        return (
            item.regions.length > 0 ? (
                <React.Fragment>
                    <UncontrolledTooltip placement="top" target={`currencyRegions-${item.id}`}>
                        {item.regions.map(({ name }) => name).join(', ')}
                    </UncontrolledTooltip>
                    <span className={styles.cursor} id={`currencyRegions-${item.id}`}>{item.regions.length}</span>
                </React.Fragment>
            ) : (
                <React.Fragment>{emptyPlaceholders.regions}</React.Fragment>
            )
        );
    };

    const handleUpdateStatus = (id, status) => {
        const objectToSend = {
            currenciesId: [ id ],
            active: status,
        };
        updateAction(objectToSend);
    };

    const handleUpdateSelected = (status) => async () => {
        const objectToSend = {
            currenciesId: checkedCurrenciesIds,
            active: status,
        };

        await updateAction(objectToSend);
        setCheckedCurrenciesIds([]);
    };

    const isFromErpBadgeCofnig = [
        {
            id: 1,
            label: translations.statuses.erp,
            color: 'danger'
        },
        {
            id: 0,
            label: translations.statuses.shop,
            color: 'success'
        }
    ];

    return (
        <TableGenerator
            items={currencies}
            expectedActions={['edit', 'delete']}
            actions={{deleteAction}}
            editLink={generateEditLinkForCurrency}
            defaultAction={handleDefaultAction}
            translations={translations}
            checkedItemsIds={checkedCurrenciesIds}
            setCheckedItemsIds={setCheckedCurrenciesIds}
            customButtons={[
                {
                    labelTrue: translations.buttons.activate,
                    labelFalse: translations.buttons.deactivate,
                    action: handleUpdateSelected,
                    type: 'selector',
                    isDisabled: checkedCurrenciesIds.length < 1,
                },
            ]}
            columns={[
                {
                    type: 'checkbox'
                },
                {
                    header: headerLabels.isFromErp,
                    value: 'isFromErp',
                    type: 'badge',
                    config: isFromErpBadgeCofnig,
                },
                {
                    header: headerLabels.name,
                    value: 'name',
                    filler: emptyPlaceholders.name
                },
                {
                    header: headerLabels.shortName,
                    value: 'shortName',
                    filler: emptyPlaceholders.shortName,
                },
                {
                    header: headerLabels.isActive,
                    value: 'active',
                    type: 'switchWithAction',
                    onSwitch: handleUpdateStatus,
                },
                {
                    header: headerLabels.regions,
                    type: 'custom',
                    customCell: RegionCell
                },
                {
                    header: headerLabels.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

CurrenciesTable.propTypes = propTypes;

export default CurrenciesTable;