const units = {
    title: '[EN] Jednostki i miary',
    titleSettings: '[EN] Ustawienia',
    buttons: {
        add: '[EN] Dodaj jednostkę',
        confirm: '[EN] Zapisz',
        cancel: '[EN] Anuluj'
    },
    validationAlert: '[EN] Uzupełnij nazwę oraz skrót jednostki aby zapisać.',
    labels: {
        name: '[EN] Nazwa',
        shortName: '[EN] Skrót',
        isDecimal: {
            label: '[EN] Podzielna',
            decimal: '[EN] Podzielna',
            integer: '[EN] Niepodzielna'
        },
        mainUnit: '[EN] Jednotka odniesienia',
        converter: '[EN] Przelicznik',
        actions: '[EN] Akcje',
    },
    modalHeaderEdit: '[EN] Edycja jednostki',
    modalHeaderCreate: '[EN] Dodawanie jednostki',
    tooltips: {
        edit: '[EN] Edytuj',
        remove: '[EN] Usuń',
    },
    emptyTable: '[EN] Nie znaleziono jednostek',
    deleteModal: {
        header: '[EN] Usuwanie jednostki',
        body: '[EN] Czy napewno chcesz usunąć jednostkę?',
        cancel: '[EN] WRÓĆ',
        confirm: '[EN] USUŃ'
    },
};

export default units;