const endOfSessionModal = {
    view: {
        title: 'You have been automatically logged out',
        message: 'You have been automatically logged out due to inactivity in the administration panel.',
        buttonLabel: 'LOG IN AGAIN'
    },
    modal: {
        title: 'End of session',
        bodyTitle: 'Your session will end in',
        bodyFooter: 'Click the button to extend the session.',
        buttonLabel: 'EXTEND SESSION',
        timeRemainingLabels: {
            firstStep: '5 minutes to the end of session',
            secondStep: '2 minutes to the end of session'
        }
    }
};

export default endOfSessionModal;