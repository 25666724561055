import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { connect } from 'react-redux';
import { getAgreementDescriptions, editAgreementBasicDescription } from '../../../../redux/methods/agreements';
import useFetch from '../../../../hooks/useFetch';
import { withFormUtils } from '../../../../hoc/withFormUtils';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form } from 'reactstrap';

import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';

import { stringOrNumberPropTypes, formUtilsHocPropTypes } from '../../../../propTypes';
import FormFieldTranslationsQuill from '../../../../components/formFields/FormFieldTranslationsQuill';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    id: stringOrNumberPropTypes,
    getAgreementDescriptions: PropTypes.func,
    editAgreementBasicDescription: PropTypes.func,
};

const AgreementEditDescriptions = ({ utils, getAgreementDescriptions, editAgreementBasicDescription, id }) => {
    const intl = useIntl();
    const translations = intl.messages.agreements.edit.tabs.descriptions;

    const [ descriptionsData ] = useFetch(getAgreementDescriptions, id);
    const config = { translations: ['description'] };

    const handleOnSubmit = formData => {
        editAgreementBasicDescription(utils.generateFormToSubmitValues(formData, config));
    };

    return descriptionsData ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    initialValues={utils.generateFormInitialValues(descriptionsData, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container fluid className='mt-3 px-0'>
                                    <FormFieldTranslationsQuill
                                        form={form}
                                        utils={utils}
                                        propertyName="description"
                                        header={translations.label}
                                    />
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

AgreementEditDescriptions.propTypes = propTypes;

const mapDispatch = {
    getAgreementDescriptions,
    editAgreementBasicDescription,
};

export default withFormUtils(connect(null, mapDispatch)(AgreementEditDescriptions));