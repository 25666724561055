import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import useFetch from '../../hooks/useFetch';

import { getOrder, updateOrderItemRealized } from '../../redux/methods/orders';

import { selectStoreCurrentLanguage } from '../../redux/selectors/storeLocale';

import { generateSections, generateTranslatedOrderAdditionalFields } from '../../utils/orders';

import { Container, Card, CardBody, CardHeader } from 'reactstrap';

import OrderDetailsSection from '../../components/OrderDetailsSecion';
import ProductsInOrderTable from '../../components/tables/ProductsInOrderTable';

import { stringOrNumberPropTypes, orderAdditionalFieldsPropTypes } from '../../propTypes';

import styles from './index.module.scss';

import OrderDetailsEdit from '../../components/modals/OrderDetailsEdit';

const propTypes = {
    orderId: stringOrNumberPropTypes,
    getOrder: PropTypes.func,
    updateOrderItemRealized: PropTypes.func,
    location: PropTypes.object,
    storeCurrentLanguage: PropTypes.string,
    orderAdditionalFieldsCollection: orderAdditionalFieldsPropTypes,
};

const OrdersDetails = ({ getOrder, updateOrderItemRealized, location, storeCurrentLanguage, orderAdditionalFieldsCollection, refetcher }) => {
    const intl = useIntl();
    const translations = intl.messages.orders.details;

    const query = new URLSearchParams(location.search);
    const chosenOrderId = query.get('orderID');

    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ orderItemToEdit, setOrderItemToEdit ] = useState(null);

    const [ order ] = useFetch(getOrder, chosenOrderId, [], refetcher);

    const translatedOrderAdditionalFields =
        order && order.orderAdditionalFields
        && generateTranslatedOrderAdditionalFields(orderAdditionalFieldsCollection, order.orderAdditionalFields, storeCurrentLanguage);

    const handleOnClick = (id) => {
        //TODO: pass proper redirect function to see product details when product details view will be ready
        console.info('click action', id);
    };

    const handleEditAction = (orderItem) => {
        setIsModalOpen(true);
        setOrderItemToEdit(orderItem);
    };

    const handleSubmit = (formData) => {
        updateOrderItemRealized({
            orderId: orderItemToEdit.orderId,
            orderItemId: orderItemToEdit.orderItemId,
            realized: formData.realized
        });

        setIsModalOpen(false);
        setOrderItemToEdit(null);
    };

    return chosenOrderId ? (
        order ? (
            <React.Fragment>
                <Container fluid className={styles.fullScreenOnMobile}>
                    <Card>
                        <CardHeader>
                            <header>
                                <h2>{translations.sectionHeader}: {order.number}</h2>
                            </header>
                        </CardHeader>
                        <CardBody>
                            <OrderDetailsSection
                                sections={generateSections(translations.orderDetails, order, translatedOrderAdditionalFields, storeCurrentLanguage)}
                            />
                            <ProductsInOrderTable
                                products={order.orderItems}
                                translations={translations}
                                onClickAction={handleOnClick}
                                onEditAction={handleEditAction}
                            />
                        </CardBody>
                    </Card>
                </Container>
                <OrderDetailsEdit
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    translations={translations.editModal}
                    orderItem={orderItemToEdit}
                    onSubmit={handleSubmit}
                />
            </React.Fragment>
        ) : null
    ) : (
        <Redirect to='/orders'/>
    );
};

OrdersDetails.propTypes = propTypes;

const mapStateToProps = (state) => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
    orderAdditionalFieldsCollection: state.appInit.orderAdditionalFields,
    refetcher: state.refetchers.orders
});

const mapDispatch = {
    getOrder,
    updateOrderItemRealized
};

export default withRouter(connect(mapStateToProps, mapDispatch)(OrdersDetails));