const languages = {
    title: 'Języki',
    table: {
        emptyTable: 'Brak języków',
        actions: {
            activate: 'Włącz zaznaczone',
            deactivate: 'Wyłącz zaznaczone',
        },
        name: 'Nazwa',
        iso: 'Iso',
        isActive: 'Aktywny',
    }
};

export default languages;