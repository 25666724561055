import { setDiscountToEditId, setDiscountToEditData } from '../../../features/discounts/slice';
import { setError } from '../../../features/errors/slice';
import { setIsLoading, setIsLoaded } from '../../../features/loaders/slice';

import { refetchDiscountBasic, refetchDiscountParameters } from './sharable';
import { handleCAPIErrorsSubtle, cAPI } from '../../../utils/cAPI';
import { generateDiscountParamsToSend } from '../../../utils/discounts';
import { convertDate } from '../../../utils/components';
import { getData, addEditData, removeData } from '../generic';
import { setIsSuccess } from '../../../features/toast/slice';

export const getDiscounts = searchConfig => dispatch => getData(dispatch, cAPI.APGetDiscountsSearchResult, searchConfig, 'discounts');
export const getDiscountProducts = searchConfig =>  dispatch => getData(dispatch, cAPI.APGetDiscountProductsSearchResult, searchConfig, 'discountsProducts');
export const getDiscountCategories = searchConfig =>  dispatch => getData(dispatch, cAPI.APGetDiscountCategoriesSearchResult, searchConfig, 'discountsCategories');
export const getDiscountRegions = searchConfig =>  dispatch => getData(dispatch, cAPI.APGetDiscountRegionsSearchResult, searchConfig, 'discountsRegions');
export const getDiscountCountries = searchConfig =>  dispatch => getData(dispatch, cAPI.APGetDiscountCountriesSearchResult, searchConfig, 'discountsCountries');
export const getDiscountCodes = searchConfig =>  dispatch => getData(dispatch, cAPI.APGetDiscountCodesSearchResult, searchConfig, 'discountsCodes');
export const getDiscountGroups = searchConfig =>  dispatch => getData(dispatch, cAPI.APGetDiscountGroupsSearchResult, searchConfig, 'discountGroups');
export const getDiscountUsers = searchConfig =>  dispatch => getData(dispatch, cAPI.APGetDiscountUsersSearchResult, searchConfig, 'discountUsers');
export const getDiscountUsages = searchConfig =>  dispatch => getData(dispatch, cAPI.APGetDiscountCodesUsages, searchConfig);
export const getDiscountParams = searchConfig =>  dispatch => getData(dispatch, cAPI.APGetDiscountParams, searchConfig, 'discountParams');
export const getDiscountAttributes = searchConfig =>  dispatch => getData(dispatch, cAPI.APGetDiscountAttributes, searchConfig, 'discountAtributes');
export const addDiscount = () => dispatch => addEditData(dispatch, cAPI.APAddDiscount);
export const removeDiscount = discountId => dispatch => removeData(dispatch, cAPI.APDeleteDiscount, discountId, 'discounts');
export const generateCodes = formData => dispatch => addEditData(dispatch, cAPI.APGenerateDiscountCodes, formData, 'discountsCodes');

export const getDiscountToEdit = discountId => async dispatch => {
    dispatch(setIsSuccess({ isSuccess: false }));
    dispatch(setIsLoading());

    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APGetDiscount(discountId);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        dispatch(setDiscountToEditId({ id: payLoad.discountId}));
        dispatch(setDiscountToEditData({ data: payLoad }));
        dispatch(setIsLoaded());
    } else if (errorToHandle) {
        dispatch(setIsLoaded());
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};

export const clearDiscountToEdit = () => dispatch => {
    dispatch(setDiscountToEditData({ data: null }));
    dispatch(setDiscountToEditId({ id: null }));
};

export const updateDiscountBasic = (discountId, data) => async dispatch => {
    dispatch(setIsLoading());

    const updateObject = {
        ...data,
        discountId: discountId,
        canBeCombined: false,
        name: data.name || '',
        code: data.code || null,
        priority: parseInt(data.priority),
        validFrom: data.validFrom ? convertDate(data.validFrom) : null,
        validTo: data.validTo ? convertDate(data.validTo) : null,
    };

    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APUpdateDiscountBasic(updateObject);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { errors } = payLoad;

        if (errors) {
            dispatch(setIsLoaded());
            return Object.keys(errors).reduce((acc, curr) => ({ ...acc, [curr]: errors[curr].message }), {} );
        } else {
            dispatch(setIsSuccess({ isSuccess: true }));
            dispatch(refetchDiscountBasic(payLoad.id));
            dispatch(setIsLoaded());

            return payLoad.id;
        }
    } else if (errorToHandle) {
        dispatch(setIsLoaded());
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};

export const updateDiscountParams = (discountId, data) => async dispatch => {
    dispatch(setIsLoading());

    const updateObject = {
        discountId: discountId,
        discountParams: generateDiscountParamsToSend(data),
    };

    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APUpdateDiscountParams(updateObject);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { errors } = payLoad;

        if (errors && errors.form) {
            const { message } = errors.form;

            dispatch(setError({ error: message }));
            dispatch(setIsLoaded());
        } else {
            dispatch(setIsSuccess({ isSuccess: true }));
            dispatch(await refetchDiscountParameters(discountId));
            dispatch(setIsLoaded());
        }
    } else if (errorToHandle) {
        dispatch(setIsLoaded());
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};

export const updateDiscountAttributes = (discountId, formData) => dispatch => addEditData(dispatch, cAPI.APUpdateDiscountAttributes, {discountId, formData});

export const updateDiscountCategories = (discountId, categoryIds, isRemoveAction = false) => dispatch => {
    const updateObject = {
        discountId: discountId,
        categoryIds: categoryIds,
        delete: isRemoveAction,
    };

    addEditData(dispatch, cAPI.APUpdateDiscountCategories, updateObject, ['discountsCategories', 'discountsProducts']);
};

export const updateDiscountProducts = (discountId, productIds, isRemoveAction = false, isExcludeAction = false) => dispatch => {
    const updateObject = {
        discountId: discountId,
        productIds: productIds,
        delete: isRemoveAction,
        exclude: isExcludeAction
    };

    addEditData(dispatch, cAPI.APUpdateDiscountProducts, updateObject, 'discountsProducts');
};

export const updateDiscountCountries = (discountId, countryIds, exclude, action) => dispatch => {
    const updateObject = {
        discountId: discountId,
        countryIds: countryIds,
        exclude: exclude,
        action: action,
    };

    addEditData(dispatch, cAPI.APUpdateDiscountCountries, updateObject, 'discountsCountries');
};

export const updateDiscountRegions = (discountId, regionIds, isRemoveAction = false) => dispatch => {
    const updateObject = {
        discountId: discountId,
        regionIds: regionIds,
        delete: isRemoveAction,
    };

    addEditData(dispatch, cAPI.APUpdateDiscountRegions, updateObject, ['discountsCountries', 'discountsRegions']);
};

export const updateDiscountGroups = (discountId, groupsIds, isRemoveAction = false) => dispatch => {
    const updateObject = {
        discountId: discountId,
        groupIds: groupsIds,
        delete: isRemoveAction,
    };

    addEditData(dispatch, cAPI.APUpdateDiscountGroups, updateObject, ['discountGroups', 'discountUsers']);
};

export const updateDiscountUsers = (discountId, usersIds, exclude, isRemoveAction = false) => dispatch => {
    const updateObject = {
        discountId: discountId,
        userIds: usersIds,
        exclude: exclude,
        delete: isRemoveAction,
    };

    addEditData(dispatch, cAPI.APUpdateDiscountUsers, updateObject, 'discountUsers');
};