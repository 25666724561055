const predefinedSearch = {
    title: 'Wyszukiwanie predefiniowane',
    actions: {
        add: 'DODAJ WYSZUKIWANIE'
    },
    table: {
        emptyPlaceholder: '[BRAK NAZWY]',
        headerLabels: {
            name: 'Nazwa',
            slug: 'Slug',
            actions: 'Akcje',
        },
        deleteModal: {
            header: 'Usuwanie wyszukiwania predefiniowanego',
            body: 'Czy na pewno chcesz usunąć to wyszukiwanie?',
            confirm: 'USUŃ',
            cancel: 'ANULUJ',
        },
        tooltips: {
            remove: 'Usuń',
            edit: 'Edytuj',
        },
    },
    edit: {
        fallbackName: '[BRAK NAZWY]',
        tabs: {
            basic: 'Ustawienia główne',
            metaTags: 'Meta tagi',
        },
        basic: {
            generalTab: 'Ogólne',
            filtersTab: 'Filtry',
            inputs: {
                searchCategoryLabel: 'Wyszukuj w kategorii',
                searchStringLabel: 'Wyszukuj następującego fragmentu w nazwie artykułu: ',
                selectFilter: 'Wybierz filtry',
                emptyFilterTranslation: 'Brak',
                filterBy: 'Filtruj wyniki po: ',
                name: 'Nazwa predefiniowanego filtrowana artykułów',
                slug: 'Adres strony predefiniowanego filtrowana artykułów',
                content: 'Treść pokazywana nad wynikami wyszukiwania'
            },
            messages: {
                slugAlert: 'Należy wypełnić adres strony. Może on zawierać tylko litery, liczby i znak podkreślenia.'
            }
        }
    }
};

export default predefinedSearch;