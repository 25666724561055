import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getAgreementBasicData } from '../../../redux/methods/agreements';

import useFetch from '../../../hooks/useFetch';

import SettingsTabulator from '../../../components/layouts/SettingsTabulator';
import AgreementEditBasicData from '../../../features/agreements/editTabs/AgreementEditBasicData';
import AgreementEditDescriptions from '../../../features/agreements/editTabs/AgreementEditDescriptions';

const propTypes = {
    location: PropTypes.object,
    getAgreementBasicData: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const AgreementsEdit = ({ location, getAgreementBasicData, forceRefetch }) => {

    const intl = useIntl();
    const translations = intl.messages.agreements.edit;
    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);
    const chosenAgreementId = query.get('agreementID');
    const [ basicData ] = useFetch(getAgreementBasicData, chosenAgreementId, [chosenAgreementId], forceRefetch);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'agreementEditTab-basic':
                return <AgreementEditBasicData basicData={basicData}/>;
            case 'agreementEditTab-descriptions':
                return <AgreementEditDescriptions id={chosenAgreementId}/>;
            default:
                return null;
        }
    };

    return chosenAgreementId ? (
        basicData ? (
            <React.Fragment>
                <SettingsTabulator
                    title={{
                        label: basicData.name || translations.fallbackName,
                        iconClassName: 'fa fa-edit text-primary ml-3',
                        isFilled: basicData.name ? false : true,
                    }}
                    tabulatorHeader={{
                        label: translations.tabSelectorTitle,
                        iconClassName: 'fa fa-align-justify'
                    }}
                    refreshTabComponent={refreshTabComponent}
                    tabs={[
                        {
                            id: 'agreementEditTab-basic',
                            header:  {
                                label: translations.tabs.basic.title,
                                iconClassName: 'fa fa-tasks'
                            }
                        },
                        {
                            id: 'agreementEditTab-descriptions',
                            header:  {
                                label: translations.tabs.descriptions.title,
                                iconClassName: 'fa fa-search'
                            }
                        },
                    ]}
                />
            </React.Fragment>
        ) : null
    ) : (
        <Redirect to='/agreements/list'/>
    );
};

AgreementsEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.agreements,
});

const mapDispatch = {
    getAgreementBasicData,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(AgreementsEdit));