import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectChosenLanguageId } from '../../../redux/selectors/locale';
import { recoverPasswordPerform } from '../../../redux/methods/auth';
import { reinitializeLocale } from '../../../redux/methods/locale';

import { Form as FinalForm } from 'react-final-form';
import { Button, Col, Form, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import FormField from '../sharedItems/FormField';

import styles from './index.module.scss';

const propTypes = {
    isLoading: PropTypes.bool,
    chosenLanguageId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    reinitializeLocale: PropTypes.func.isRequired,
    recoverPasswordPerform: PropTypes.func,
};

const SetNewPasswordForm = ({ isLoading, chosenLanguageId, reinitializeLocale, recoverPasswordPerform }) => {
    const intl = useIntl();
    const translations = intl.messages.auth.resetPassword;

    useEffect(() => {
        if (!chosenLanguageId) {
            reinitializeLocale();
        }
    }, [ chosenLanguageId, reinitializeLocale ]);

    const onSubmit = formData => recoverPasswordPerform(formData);

    return (
        <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit, submitError, values }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <h1>{translations.setNewPassword.title}</h1>
                        <p className="text-muted">{translations.setNewPassword.subTitle}</p>
                        <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="icon-lock"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <FormField
                                type="password"
                                name="newPassword"
                                placeholder={translations.newPassword}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="icon-lock"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <FormField
                                type="password"
                                name="newPasswordRepeated"
                                placeholder={translations.newPasswordRepeated}
                            />
                        </InputGroup>
                        <Row>
                            <Col xs="6">
                                <Button
                                    disabled={isLoading || !values.newPassword || !values.newPasswordRepeated || values.newPassword !== values.newPasswordRepeated}
                                    type="submit"
                                    color="primary"
                                    className="px-4"
                                >
                                    {translations.send}
                                </Button>
                            </Col>
                            { submitError && <span className={styles.submitError}>{submitError}</span> }
                        </Row>
                    </Form>
                );
            }}
        />
    );
};

SetNewPasswordForm.propTypes = propTypes;

const mapStateToProps = state => ({
    isLoading: state.auth.isLoading.login,
    chosenLanguageId: selectChosenLanguageId(state)
});

const mapDispatch = {
    recoverPasswordPerform,
    reinitializeLocale
};

export default connect(mapStateToProps, mapDispatch)(SetNewPasswordForm);