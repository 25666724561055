import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    discountToEdit: {
        parameters: [],
        id: null,
        data: null,
    }
};

const discounts = createSlice({
    name: 'discounts',
    initialState,
    reducers: {
        setDiscountToEditParameters(state, action) {
            const { parameters } = action.payload;
            state.discountToEdit.parameters = parameters;
        },
        setDiscountToEditId(state, action) {
            const { id } = action.payload;
            state.discountToEdit.id = id;
        },
        setDiscountToEditData(state, action) {
            const { data } = action.payload;
            state.discountToEdit.id = data?.discountId;
            state.discountToEdit.data = data;
        },
    }
});

export const { setDiscountToEditParameters, setDiscountToEditId, setDiscountToEditData } = discounts.actions;

export default discounts.reducer;