const urljoin = require("url-join");
const Profiler = require('./profiler');
const profiler = Profiler();

const { performRequest, generateRequestObject, generateSearchPath } = require("./src/utils");
const { generateFormDataRequest } = require("./src/customRequests/index");

const ConnecticoApi = ({ baseUrl }) => {
    /**
    * Wrapper of fetch function,
    * returns envelope from BE or throws an error if !response.ok
    */
    const fetchFromApi = async ({ path, httpMethod, jsonBody, customRequest, cookie, token, }) => {
        const start = Date.now();
        const perfLabel = `${httpMethod} ${path}`;

        console.debug(`fetching ${perfLabel} ..., cookie: ${cookie}, token: ${token}`);

        const establishRequestTemplate = () => {
            if (customRequest) {
                return customRequest;
            } else {
                return generateRequestObject({ httpMethod, jsonBody, cookie, token });
            }
        };

        const request = establishRequestTemplate();

        const resultEnvelopeTemplate = {
            payLoad: null,
            error: null,
            httpError: null,
            unhandledError: null,
            statusCode: null
        };

        const fetchConfigObject = {
            url: urljoin(baseUrl, path),
            request: request,
            resultEnvelopeTemplate: resultEnvelopeTemplate
        };

        const outcome = await performRequest(fetchConfigObject);

        const end = Date.now();
        const elapsed = end - start; // time in milliseconds

        console.debug(`${perfLabel} took ${elapsed}ms`);
        //console.debug(`fetch result`, outcome);

        profiler.record(perfLabel, elapsed);

        return outcome;
    };

    const exposedFetch = async fetchTemplate => { return await fetchFromApi(fetchTemplate); };

    const httpFetchWithCookie = async (cookie, httpMethod, path) => { return await fetchFromApi({ path, httpMethod, cookie}); };
	const httpSendJsonData = async (formData, httpMethod, path) => { return await fetchFromApi({ path, httpMethod, jsonBody: formData}); };
    const httpSendCustomData = async (formData, httpMethod, path) => { return await fetchFromApi({ path, httpMethod, jsonBody: null, customRequest: generateFormDataRequest(formData, httpMethod)}); };
    const httpGetSearch = async ( searchObject, searchParamName, path, cookie = null ) => { const encodedQuery = generateSearchPath(searchParamName, searchObject); return await fetchFromApi({ path: `${path}?${encodedQuery}`, httpMethod: 'GET', cookie: cookie}); };
    const httpFetch = async ( httpMethod, path ) => { return await fetchFromApi({ path, httpMethod}); };

    return {
        exposedFetch,

        APGetMetatags: 						            async ({ tag, pageid }) => await httpFetch('GET', typeof(pageid) === 'undefined' ? `/Admin/MetaTags/${tag}` : `/Admin/MetaTags/${tag}/${pageid}`),
        APUpdateMetatags: 				                async ({ tag, pageid, formData }) => await httpSendJsonData(formData, 'PUT', typeof(pageid) === 'undefined' ? `/Admin/MetaTags/${tag}` : `/Admin/MetaTags/${tag}/${pageid}`),

        APGetLatestNotifications: 				        async (token) => { return await fetchFromApi({ path: `/Admin/Notification`, httpMethod: 'GET', token: token}); },

        APGetAttributesSearchResult:                    async formData => await httpGetSearch(formData, 'getAttributesRequest', '/Admin/Attribute/'),
        APGetDiscountCategoriesSearchResult:            async formData => await httpGetSearch(formData, 'getDiscountCategoriesRequest', '/Admin/Discount/Categories'),
        APGetDiscountCodesSearchResult:                 async formData => await httpGetSearch(formData, 'getDiscountCodesRequest', '/Admin/Discount/Codes'),
        APGetDiscountCodesUsages:                       async formData => await httpGetSearch(formData, 'getDiscountCodeUsagesRequest', '/Admin/Discount/CodeUsages'),
        APGetDiscountCountriesSearchResult:             async formData => await httpGetSearch(formData, 'getDiscountCountriesRequest', '/Admin/Discount/Countries'),
        APGetDiscountGroupsSearchResult:                async formData => await httpGetSearch(formData, 'getDiscountGroupsRequest', '/Admin/Discount/Groups'),
        APGetDiscountProductsSearchResult:              async formData => await httpGetSearch(formData, 'getDiscountProductsRequest', '/Admin/Discount/Products'),
        APGetDiscountRegionsSearchResult:               async formData => await httpGetSearch(formData, 'getDiscountRegionsRequest', '/Admin/Discount/Regions'),
        APGetDiscountsSearchResult:                     async formData => await httpGetSearch(formData, 'getDiscountsRequest', '/Admin/Discount/'),
        APGetDiscountUsersSearchResult:                 async formData => await httpGetSearch(formData, 'getDiscountUsersRequest', '/Admin/Discount/Users'),
        APGetExchangeRates:                             async formData => await httpGetSearch(formData, 'getExchangeratesRequest','Admin/Exchangerate'),
        APGetFrequentPhrases:                           async formData => await httpGetSearch(formData, 'getFrequentPhrasesRequest', '/Admin/Statistic/FrequentPhrases'),
        APGetGroups:                                    async formData => await httpGetSearch(formData, 'getAdminGroupsRequest', '/Admin/User/Group'),
        APGetLastPhrases:                               async formData => await httpGetSearch(formData, 'getLastPhrasesRequest', '/Admin/Statistic/LastPhrases'),
        APGetMeasuresSearchResult:                      async formData => await httpGetSearch(formData, 'getMeasuresRequest', '/Admin/Measure'),
        APGetMediaFile:                                 async formData => await httpGetSearch(formData, 'getFileRequest', '/Admin/Media/File'),
        APGetMediaFolderContent:                        async formData => await httpGetSearch(formData, 'getFolderContentRequest', '/Admin/Media/Folder/Content'),
        APGetMostViewedProducts:                        async formData => await httpGetSearch(formData, 'getMostViewedProductsRequest', '/Admin/Statistic/MostViewedProducts'),
        APGetNotificationsList:                         async formData => await httpGetSearch(formData, 'getNotificationsRequest', '/Admin/Notification/Search'),
        APGetNumberOfVisits:                            async formData => await httpGetSearch(formData, 'getNumberOfVisitsRequest', '/Admin/Statistic/NumberOfVisits'),
        APGetOrdersSearchResult:                        async formData => await httpGetSearch(formData, 'getOrdersRequest', '/Admin/Order/'),
        APGetProductsSearchResult:                      async formData => await httpGetSearch(formData, 'getProductsRequest', '/Admin/Product/'),
        APGetServicesSearchResult:                      async formData => await httpGetSearch(formData, 'getServicesRequest', '/Admin/Service/'),
        APGetSearchMediaFiles:                          async formData => await httpGetSearch(formData, 'getFilesRequest', '/Admin/Media/File/Search'),
        APGetUserAgreements:                            async formData => await httpGetSearch(formData, 'getUserAgreementsRequest', '/Admin/UserAgreement'),
        APGetUsers:                                     async formData => await httpGetSearch(formData, 'getAdminUsersRequest', '/Admin/User/'),
        APGetSectionProducts:                           async ({ id, type, formData }) => await httpGetSearch(formData, 'getSectionProductsRequest', `/Admin/MainPage/Sections/${type}/${id}/Products/`),
        APGetProductDeliveryGlobalSettings: 			async () => await httpFetch('GET', '/Admin/Product/Delivery/Settings'),
        APGetProductDeliveryTemplateFields: 			async () => await httpFetch('GET', '/Admin/Product/Delivery/TemplateFields'),
        APGetPhotoNameOptionsForDropdown: 			    async () => await httpFetch('GET', '/Admin/Seo/PhotoName/Options'),
        APGetLoginAndRegistrationSettings: 			    async () => await httpFetch('GET', '/Admin/Settings/LoginAndRegistration'),
        APGetCookiesTranslations: 			            async () => await httpFetch('GET', '/Admin/Settings/CookiesTranslations'),
        APGetOrderGreetings: 			                async () => await httpFetch('GET', '/Admin/Settings/Order'),
        APGetProductCarouselsGeneral: 				    async () => await httpFetch('GET', '/Admin/MainPage/ProductCarousels'),
        APGetDictionaryMappingFields: 				    async () => await httpFetch('GET', '/Admin/Attribute/DictionaryMappingFields'),
        APGetProductDeliveryErpFields: 				    async () => await httpFetch('GET', '/Admin/Product/Delivery/ErpFields'),
        APGetProductPresentationCart: 				    async type => await httpFetch('GET', `/Admin/Product/Page/GlobalFields/${type}`),
        APGetAllTagAttributes:       				    async () => await httpFetch('GET', '/Admin/Product/Presentation/AllTagAttributes'),
        APGetProductPresentationTile: 				    async () => await httpFetch('GET', '/Admin/Product/Presentation/Tile'),
        APGetVisualSettingsFaviconFile: 				async () => await httpFetch('GET', '/Admin/Settings/Logo/Favicon/File'),
        APGetVisualSettingsLogoFile: 				    async () => await httpFetch('GET', '/Admin/Settings/Logo/File'),
        APGetSynchronizationsSettings: 				    async () => await httpFetch('GET', '/Admin/Synchronizations/Settings'),
        APGetTechnicalBreakBackground: 				    async () => await httpFetch('GET', '/Admin/TechnicalBreak/Background'),
        APGetAvailableLanguages: 					    async () => await httpFetch('GET', '/Admin/Language'),
        APGetIntegrationAPaczka: 					    async () => await httpFetch('GET', '/Admin/Integrations/APaczka'),
        APGetIntegrationFacebook: 					    async () => await httpFetch('GET', '/Admin/Integrations/Facebook'),
        APGetIntegrationFurgonetka: 					async () => await httpFetch('GET', '/Admin/Integrations/Furgonetka'),
        APGetAdditionalFieldsTile: 					    async () => await httpFetch('GET', '/Admin/Product/Presentation/AdditionalFields'),
        APGetManufacturerFields: 					    async () => await httpFetch('GET', '/Admin/Product/Presentation/ManufacturerFields'),
        APGenerateGoogleSiteMap: 					    async () => await httpFetch('POST', '/Admin/Seo/GoogleSiteMap/Generate'),
        APGetCategoriesSettings: 					    async () => await httpFetch('GET', '/Admin/Settings/Category'),
        APGetVisualSettingsLogo: 					    async () => await httpFetch('GET', '/Admin/Settings/Logo'),
        APGetPromotionBarSlides: 					    async () => await httpFetch('GET', '/Admin/Settings/PromotionBar/Slides'),
        APGetQuantityPresentation: 					    async () => await httpFetch('GET', '/Admin/Settings/QuantityDisplay'),
        APGetArticleColumns: 					        async () => await httpFetch('GET', '/Admin/Product/Presentation/ArticleColumns'),
        APGetShippingFormsGeneral: 					    async () => await httpFetch('GET', '/Admin/ShippingForms/General'),
        APGetShippingFormsVariants: 					async () => await httpFetch('GET', '/Admin/ShippingForms/Variants'),
        APGetSynchronizationAuth: 					    async () => await httpFetch('GET', '/Admin/Synchronizations/Authorization/'),
        APGetSynchronizationsErp: 					    async () => await httpFetch('GET', '/Admin/Synchronizations/Erp'),
        APGetProductDescriptionImportSettings: 		    async () => await httpFetch('GET', '/Admin/Import/Product/Description/Settings'),
        APGetAgreementTypes: 						    async () => await httpFetch('GET', '/Admin/Agreement/Types'),
        APGetFullCategoryTree: 						    async () => await httpFetch('GET', '/Admin/Category'),
        APGetAnimatedBanners: 						    async () => await httpFetch('GET', '/Admin/AnimatedBanner'),
        APGetCeneoCategories: 						    async () => await httpFetch('GET', '/Admin/Category/3'),
        APGetEmailTemplates: 						    async () => await httpFetch('GET', '/Admin/EmailTemplate'),
        APGetGoogleAnalytics: 						    async () => await httpFetch('GET', '/Admin/Integrations/GoogleAnalytics/GetSettings'),
        APGetIntegrationInPost: 						async () => await httpFetch('GET', '/Admin/Integrations/InPost'),
        APGetIntegrationRecaptcha:  				    async () => await httpFetch('GET', '/Admin/Integrations/Recaptcha'),
        APGetIntegrationGoogleSearchConsole:		    async () => await httpFetch('GET', '/Admin/Integrations/GoogleSearchConsole'),
        APGetIntegrationP24: 						    async () => await httpFetch('GET', '/Admin/Integrations/P24'),
        APGetIntegrationPayU: 						    async () => await httpFetch('GET', '/Admin/Integrations/PayU'),
        APGetHomepageGeneralSettings: 				    async () => await httpFetch('GET', '/Admin/MainPage/General'),
        APGetFooterSettingsPhotoWithText: 				async () => await httpFetch('GET', '/Admin/Menu/Settings/Footer/PhotoWithText'),
        APGetFooterSettingsNewsletter: 					async () => await httpFetch('GET', '/Admin/Menu/Settings/Footer/Newsletter'),
        APGetFooterSettingsMain: 						async () => await httpFetch('GET', '/Admin/Menu/Settings/Footer/Main'),
        APGetPredefinedSearch: 						    async () => await httpFetch('GET', '/Admin/PredefinedSearch'),
        APGetProductPageTabs: 						    async () => await httpFetch('GET', '/Admin/Product/Page/Tabs'),
        APGetProductGeneralSettings:				    async () => await httpFetch('GET', '/Admin/Product/Presentation/General'),
        APGetListPresentation: 						    async () => await httpFetch('GET', '/Admin/Product/Presentation/List'),
        APGetButtonsSimpleList: 						async () => await httpFetch('GET', '/Admin/Settings/Button/SimpleList'),
        APGetCatalogSettings: 						    async () => await httpFetch('GET', '/Admin/Settings/Catalogue'),
        APGetVisualSettingsCss: 						async () => await httpFetch('GET', '/Admin/Settings/Css'),
        APGetGraphicsSettings: 						    async () => await httpFetch('GET', '/Admin/Settings/Graphics'),
        APGetSearchSettings: 						    async () => await httpFetch('GET', '/Admin/Settings/SearchEngine'),
        APGetGeneralSettings: 						    async () => await httpFetch('GET', '/Admin/Settings/General'),
        APGetShipmentServices: 						    async () => await httpFetch('GET', '/Admin/Shipments/Services'),
        APGetTechnicalBreak: 						    async () => await httpFetch('GET', '/Admin/TechnicalBreak'),
        APGetAllUserAgreemetns: 						async () => await httpFetch('GET', '/Admin/UserAgreement/all'),
        APGetAgreements: 							    async () => await httpFetch('GET', '/Admin/Agreement'),
        APGetCurrencies: 							    async () => await httpFetch('GET', '/Admin/Currency'),
        APGetCustomPages: 							    async () => await httpFetch('GET', '/Admin/Custompage'),
        APGetPopups: 							        async () => await httpFetch('GET', '/Admin/Popup'),
        APGetManufacturers: 							async (formData) => await httpGetSearch(formData, 'getManufacturersRequest', '/Admin/Manufacturer'),
        APGetErpCategories: 							async () => await httpFetch('GET', '/Admin/Category/2'),
        APGetCeneoSettings: 							async () => await httpFetch('GET', '/Admin/Integrations/Ceneo/GetCeneoSettings'),
        APGetCeneoMappings: 							async () => await httpFetch('GET', '/Admin/Integrations/Ceneo/Mappings'),
        APGetChatSettings: 							    async () => await httpFetch('GET', '/Admin/Integrations/Chat'),
        APGetInspectletSettings: 					    async () => await httpFetch('GET', '/Admin/Integrations/Inspectlet'),
        APGetFooterImage: 							    async () => await httpFetch('GET', '/Admin/Menu/Settings/Footer/Image'),
        APGetNotFoundImage: 							async () => await httpFetch('GET', '/Admin/NotFound/Image'),
        APGetGoogleSiteMap: 							async () => await httpFetch('GET', '/Admin/Seo/GoogleSiteMap'),
        APGetOwnCode: 							        async () => await httpFetch('GET', '/Admin/Seo/OwnCode'),
        APGetButtonsList: 							    async () => await httpFetch('GET', '/Admin/Settings/Button/FullList'),
        APGetEmailSettings: 							async () => await httpFetch('GET', '/Admin/Settings/Email'),
        APGetGoogleFonts: 							    async () => await httpFetch('GET', '/Admin/Settings/Font/All'),
        APGetSelectedFonts: 							async () => await httpFetch('GET', '/Admin/Settings/Font'),
        APGetPromotionBar: 							    async () => await httpFetch('GET', '/Admin/Settings/PromotionBar'),
        APGetTranslations: 							    async () => await httpFetch('GET', '/Admin/Translations'),
        APGetCountries:								    async () => await httpFetch('GET', '/Admin/Region/AllCountries'),
        APGetRegions:								    async () => await httpFetch('GET', '/Admin/Region/AllRegions'),
        APGetBanners:								    async () => await httpFetch('GET', '/Admin/Banner'),
        APGetAdBanners:								    async () => await httpFetch('GET', '/Admin/Ad'),
        APGetFilters:								    async () => await httpFetch('GET', '/Admin/Attribute/GetFilters'),
        APGetFillers:								    async () => await httpFetch('GET', '/Admin/Filler'),
        APGetFreshmail:								    async () => await httpFetch('GET', '/Admin/Integrations/FreshMail/GetSettings'),
        APGetLanguages:								    async () => await httpFetch('GET', '/Admin/Language/All'),
        APGetMenuTree:								    async () => await httpFetch('GET', '/Admin/Menu/GetTree'),
        APGetNotFound:								    async () => await httpFetch('GET', '/Admin/NotFound'),
        APGetPhotoName:								    async () => await httpFetch('GET', '/Admin/Seo/PhotoName'),
        APGetCookies:								    async () => await httpFetch('GET', '/Admin/Settings/Cookies'),
        APGetTaxRates:								    async () => await httpFetch('GET', '/Admin/TaxRate'),
        APGetOrderStatuses:								async () => await httpFetch('GET', '/Admin/Order/Statuses'),
        APAppInit:									    async () => await httpFetch('GET', '/Admin/InitProps/App'),
        APGetVisualSettingsPlaceholderImageProduct: 	async () => await httpFetch('GET', '/Admin/Settings/PlaceholderImage/Product'),
        APGetAssignedManufacturersBySectionId: 	        async id => await httpFetch('GET', `/Admin/MainPage/ManufacturerSection/${id}/Assigned`),
        APGetUnassignedManufacturersBySectionId:        async id => await httpFetch('GET', `/Admin/MainPage/ManufacturerSection/${id}/Unassigned`),
        APGetDoubleImageSectionImagesSource: 	        async id => await httpFetch('GET', `/Admin/MainPage/DoubleImageSection/${id}/Files`),
        APGetAdBannerAssignedCategories: 		        async id => await httpFetch('GET', `/Admin/Ad/${id}/Categories`),
        APGetAttributeDictionaryValues: 			    async id => await httpFetch('GET', `/Admin/Attribute/${id}/DictionaryValues`),
        APGetSectionPhotoWithTextImage: 			    async id => await httpFetch('GET', `/Admin/MainPage/PhotoWithText/${id}/Files`),
        APGetAgreementBasicData: 				        async id => await httpFetch('GET', `/Admin/Agreement/${id}/BasicData`),
        APGetAgreementDescriptions: 				    async id => await httpFetch('GET', `/Admin/Agreement/${id}/Description`),
        APGetAttributeBasicData: 				        async id => await httpFetch('GET', `/Admin/Attribute/${id}/BasicData`),
        APGetAttributeColorValues: 				        async id => await httpFetch('GET', `/Admin/Attribute/${id}/ColorValues`),
        APGetAttributeCategories: 				        async id => await httpFetch('GET', `/Admin/Attribute/Categories/${id}`),
        APGetEmailTemplateTables: 				        async id => await httpFetch('GET', `/Admin/EmailTemplate/Tables/${id}`),
        APGetNotificationDetails: 				        async id => await httpFetch('GET', `/Admin/Notification/${id}`),
        APGetPredefinedSearchBasic: 				    async id => await httpFetch('GET', `/Admin/PredefinedSearch/Basic/${id}`),
        APCreateProductDuplicate: 				        async id => await httpFetch('GET', `/Admin/Product/Duplicate/${id}`),
        APGetShipmentServiceParams: 				    async id => await httpFetch('GET', `/Admin/Shipments/Services/Params/${id}`),
        APGetShippingFormsVariant: 				        async id => await httpFetch('GET', `/Admin/ShippingForms/Variants/${id}`),
        APGetAnimatedBannerImages: 				        async id => await httpFetch('GET', `Admin/AnimatedBanner/Images/${id}`),
        APGetAgreementRegions: 					        async id => await httpFetch('GET', `/Admin/Agreement/${id}/Regions`),
        APGetAttributeSettings: 					    async id => await httpFetch('GET', `/Admin/Attribute/${id}/Settings`),
        APGetCategoryDetails: 					        async id => await httpFetch('GET', `/Admin/Category/Details/${id}`),
        APGetCurrenciesRegions: 					    async id => await httpFetch('GET', `/Admin/Currency/${id}/Regions`),
        APGetDiscountParams: 					        async id => await httpFetch('GET', `/Admin/Discount/${id}/Params`),
        APGetDiscountAttributes:                        async id => await httpFetch('GET', `/Admin/Discount/${id}/Attributes`),

        APGetProductAttributes: 					    async id => await httpFetch('GET', `/Admin/Product/${id}/Attributes`),
        APGetPromoSectionImage: 					    async id => await httpFetch('GET', `/Admin/PromoSection/${id}/File`),
        APGetRegionCountries: 					        async id => await httpFetch('GET', `/Admin/Region/${id}/Countries`),
        APGetTemplateParams: 					        async id => await httpFetch('GET', `/Admin/Settings/Css/Params/${id}`),
        APGetAnimatedBanner: 					        async id => await httpFetch('GET', `Admin/AnimatedBanner/${id}`),
        APGetAttributeById: 						    async id => await httpFetch('GET', `/Admin/Attribute/${id}/BasicData`),
        APGetCategoryImage: 						    async id => await httpFetch('GET', `/Admin/Category/Image/${id}`),

        APGetEmailTemplate: 						    async id => await httpFetch('GET', `/Admin/EmailTemplate/${id}`),
        APGetExchangeRate: 						        async id => await httpFetch('GET', `/Admin/Exchangerate/${id}`),
        APGetManufacturer: 						        async id => await httpFetch('GET', `/Admin/Manufacturer/${id}`),
        APGetMenuSection: 						        async id => await httpFetch('GET', `/Admin/Menu/${id}`),
        APGetProductPrices: 						    async id => await httpFetch('GET', `/Admin/Product/${id}/Prices`),
        APGetPromoSection: 						        async id => await httpFetch('GET', `/Admin/PromoSection/${id}`),
        APGetCookieById: 						        async id => await httpFetch('GET', `/Admin/Settings/Cookies/${id}`),
        APGetEmailSetting: 						        async id => await httpFetch('GET', `/Admin/Settings/Email/${id}`),
        APGetBannerImages: 						        async id => await httpFetch('GET', `Admin/Banner/Images/${id}`),
        APGetAdBanner: 							        async id => await httpFetch('GET', `/Admin/Ad/${id}`),
        APGetCurrency: 							        async id => await httpFetch('GET', `/Admin/Currency/${id}/BasicData`),
        APGetFiller: 							        async id => await httpFetch('GET', `/Admin/Filler/${id}`),
        APGetRegion: 							        async id => await httpFetch('GET', `/Admin/Region/${id}`),
        APGetButton: 							        async id => await httpFetch('GET', `/Admin/Settings/Button/${id}`),
        APGetBanner: 							        async id => await httpFetch('GET', `Admin/Banner/${id}`),
        APGetOrder: 							        async id => await httpFetch('GET', `/Admin/Order/${id}`),
        APGetProductReview: 						    async (prodId, reviewId) => await httpFetch('GET', `/Admin/ProductReview/${prodId}/${reviewId}`),
        APGetShippingFormsWeights: 				        async (shippingVariantId) => await httpFetch('GET', `/Admin/ShippingForms/Weights/${shippingVariantId}`),
        APGetProductDelivery: 					        async ({ id }) => await httpFetch('GET', `/Admin/Product/${id}/Delivery`),
        APGetSectionCategories: 					    async ({ id, type }) => await httpFetch('GET', `/Admin/MainPage/Sections/${type}/${id}/Categories`),
        APGetHomePageSectionById: 				        async ({ id, type }) => await httpFetch('GET', `/Admin/MainPage/Sections/${type}/${id}`),
        APGetAnimatedBannerImage: 				        async ({animatedBannerId, imageId}) => await httpFetch('GET', `Admin/AnimatedBanner/Images/${animatedBannerId}/${imageId}`),
        APGetBannerImage: 						        async ({bannerId, imageId}) => await httpFetch('GET', `Admin/Banner/Images/${bannerId}/${imageId}`),
        APGetHomePageSections: 					        async ({device, affiliation, affiliationObjectId }) => await httpFetch('GET', `/Admin/MainPage/Sections/${affiliation}/${device}${affiliationObjectId ? `/${affiliationObjectId}` : ''}`),
        APGetCustomPageBasicData: 				        async customPageId => await httpFetch('GET', `/Admin/Custompage/${customPageId}/BasicData`),
        APGetPopupBasicData: 				            async popupId => await httpFetch('GET', `/Admin/Popup/${popupId}/BasicData`),
        APGetDiscount: 							        async discountId => await httpFetch('GET', `/Admin/Discount/${discountId}`),
        APGetUserAgreementsByEmail: 				    async email => await httpFetch('GET', `/Admin/UserAgreement/${email}`),
        APGetEmailTemplateTable: 				        async formData => await httpFetch('GET', `/Admin/EmailTemplate/Tables/${formData.templateId}/${formData.tableId}`),
        APGetPaymentForm: 						        async formData => await httpFetch('GET', `/Admin/PaymentForms/${formData.paymentFormId}`),
        APGetPaymentForms: 						        async () => await httpFetch('GET', `/Admin/PaymentForms`),
        APGetProductImage: 						        async formData => await httpFetch('GET', `/Admin/Product/${formData.productId}/Images/${formData.imageId}`),
        APGetShippingFormsForm: 					    async formData => await httpFetch('GET', `/Admin/ShippingForms/Forms/${formData.shippingVariantId}/${formData.shippingVariantWeightId}/${formData.shippingFormId}`),
        APGetShippingFormsForms: 				        async formData => await httpFetch('GET', `/Admin/ShippingForms/Forms/${formData.shippingVariantId}/${formData.shippingVariantWeightId}`),
        APGetShippingFormsWeight: 				        async formData => await httpFetch('GET', `/Admin/ShippingForms/Weights/${formData.shippingVariantId}/${formData.shippingVariantWeightId}`),
        APGetOrderStatus:								async formData => await httpFetch('GET', `/Admin/Order/Statuses/${formData.orderStatusId}`),
        APGetProductReviews: 					        async id => await httpFetch('GET', id ? `/Admin/ProductReview/${id}` : `/Admin/ProductReview`),
        APGetProductCategories: 					    async id => await httpFetch('GET', `/Admin/Product/${id}/Categories`),
        APGetProductDescription: 				        async id => await httpFetch('GET', `/Admin/Product/${id}/Description`),
        APGetProductBasic: 						        async id => await httpFetch('GET', `/Admin/Product/${id}/Details`),
        APGetProductImages: 						    async id => await httpFetch('GET', `/Admin/Product/${id}/Images`),
        APGetProductMeasures: 						    async id => await httpFetch('GET', `/Admin/Product/${id}/Measures`),
        APGetProductMeasure: 						    async formData => await httpFetch('GET', `/Admin/Product/${formData.productId}/Measures/${formData.id}`),
        APGetProductDetails: 					        async id => await httpFetch('GET', `/Admin/Product/${id}`),
        APSynchronizationGenerateToken: 			    async () => await httpFetch('PUT', '/Admin/Synchronizations/Authorization/GenerateSynchroApiToken'),
        APUpdateCeneoCategories: 					    async () => await httpFetch('PUT', '/Admin/Integrations/Ceneo/UpdateCeneoCategories'),
        APGenerateCeneoXML: 						    async () => await httpFetch('PUT', '/Admin/Integrations/Ceneo/GenerateXml'),
        APSetDefaultShippingFormsVariant: 		        async id => await httpFetch('PUT', `/Admin/ShippingForms/Variants/${id}/Default`),
        APUpdateEmailSettingIsDefault: 			        async id => await httpFetch('PUT', `/Admin/Settings/Email/SetIsDefault/${id}`),
        APChangeUserStatus: 						    async id => await httpFetch('PUT', `/Admin/User/${id}/Status`),
        APMoveProductPageTab: 					        async ({ id, delta }) => await httpFetch('PUT', `/Admin/Product/Page/Tabs/Move/${id}/${delta}`),
        APChangeActiveHomePageSections: 			    async ({ id, device, isActive, type }) => await httpFetch('PUT', `/Admin/MainPage/Sections/Active/${device}/${type}/${id}/${isActive}`),
        APChangeOrderHomePageSections: 			        async ({ id, device, order, type }) => await httpFetch('PUT', `/Admin/MainPage/Sections/Order/${device}/${type}/${id}/${order}`),
        APCreateHomePageSectionDuplicate:			    async ({ id, type }) => await httpFetch('POST', `/Admin/MainPage/Sections/Duplicate/${type}/${id}`),
        APCreateNotificationToken: 					    async () => await httpFetch('POST', '/Admin/Notification/CreateToken'),
        APExtendSession: 							    async () => await httpFetch('POST', '/Admin/InitProps/App/KeepSession'),
        APVerifyCookie:								    async () => await httpFetch('POST', '/Admin/Authentication/Verify'),
        APAddDiscount:								    async () => await httpFetch('POST', '/Admin/Discount/Create'),
        APLogout:									    async () => await httpFetch('POST', '/Admin/Authentication/Logout'),
        APChangeChosenLanguage: 					    async id => await httpFetch('POST', `/Admin/Language/${id}`),
        APGreyOutNotification: 					        async id => await httpFetch('PATCH', `/Admin/Notification/${id}/GrayOut`),
        APIgnoreNotifications: 					        async formData => await httpSendJsonData(formData, 'PATCH', `/Admin/Notification/Ignore`),
        APDeleteAnimatedBannerImageFile: 				async ({ animatedBannerId, id }) => await httpFetch('DELETE', `/Admin/AnimatedBanner/Images/${animatedBannerId}/${id}/File`),
        APDeleteDoubleImageSectionImage: 				async ({ id, isFirstImage }) => await httpFetch('DELETE', `/Admin/MainPage/DoubleImageSection/${id}/File/${isFirstImage}`),
        APRemoveVisualSettingsLogoFile:					async () => await httpFetch('DELETE', `/Admin/Settings/Logo/File`),
        APDeleteShippingFormsWeight:					async formData => await httpFetch('DELETE', `/Admin/ShippingForms/Weights/${formData.shippingVariantId}/${formData.shippingVariantWeightId}`),
        APDeleteAttributeColorValue:					async id => await httpFetch('DELETE', `/Admin/Attribute/DeleteColorValue/${id}`),
        APRemoveVisualSettingsOwnCss:					async id => await httpFetch('DELETE', `/Admin/Settings/Css/User/${id}`),
        APDeleteShippingFormsVariant:					async id => await httpFetch('DELETE', `/Admin/ShippingForms/Variants/${id}`),
        APDeleteAnimatedBannerImage:					async ({ bannerId, id }) => await httpFetch('DELETE', `/Admin/AnimatedBanner/Images/Delete/${bannerId}/${id}`),
        APDeleteBannerImageFile:						async ({ bannerId, id }) => await httpFetch('DELETE', `/Admin/Banner/Images/${bannerId}/${id}/File`),
        APDeleteHomePageSections:						async ({ id, type }) => await httpFetch('DELETE', `/Admin/MainPage/Sections/${type}/${id}`),
        APDeleteShippingFormsForm:						async formData => await httpFetch('DELETE', `/Admin/ShippingForms/Forms/${formData.shippingVariantId}/${formData.shippingVariantWeightId}/${formData.shippingFormId}`),
        APDeletePredefinedSearch:						async id => await httpFetch('DELETE', `/Admin/PredefinedSearch/Delete/${id}`),
        APRemovePromoSectionImage:						async id => await httpFetch('DELETE', `/Admin/PromoSection/${id}/File`),
        APDeletePromotionBarSlide:						async id => await httpFetch('DELETE', `/Admin/Settings/PromotionBar/Slides/${id}`),
        APDeleteMenuImage:						        async id => await httpFetch('DELETE', `/Admin/Menu/${id}/File`),
        APDeleteFooterImage:							async () => await httpFetch('DELETE', `/Admin/Menu/Settings/Footer/Image`),
        APRemoveNotFoundImage:							async () => await httpFetch('DELETE', `/Admin/NotFound/Image`),
        APRemoveProductReview:							async ({ prodId, reviewId }) => await httpFetch('DELETE', `/Admin/ProductReview/${prodId}/${reviewId}`),
        APDeletePaymentForm:							async formData => await httpFetch('DELETE', `/Admin/PaymentForms/${formData.paymentFormId}`),
        APDeleteAttributeById:							async id => await httpFetch('DELETE', `/Admin/Attribute/Delete/${id}`),
        APRemoveCategoryImage:						    async formData => await httpFetch('DELETE', `/Admin/Category/${formData.id}/File/${formData.type}`),
        APDeleteCategoryById:							async id => await httpFetch('DELETE', `/Admin/Category/Delete/${id}`),
        APDeleteProductMeasure: 						async id => await httpFetch('DELETE', `/Admin/Product/Delete/Measure/${id}`),
        APRemoveExchangeRate:							async id => await httpFetch('DELETE', `/Admin/Exchangerate/Delete/${id}`),
        APDeleteFillerImage:							async id => await httpFetch('DELETE', `/Admin/Filler/${id}/File`),
        APDeleteCeneoMapping:							async id => await httpFetch('DELETE', `/Admin/Integrations/Ceneo/Mappings/${id}`),
        APRemoveManufacturer:							async id => await httpFetch('DELETE', `/Admin/Manufacturer/Delete/${id}`),
        APDeleteMenuSection:							async id => await httpFetch('DELETE', `/Admin/Menu/${id}`),
        APDeleteNotification:							async id => await httpFetch('DELETE', `/Admin/Notification/${id}/Delete`),
        APDeleteEmailSetting:							async id => await httpFetch('DELETE', `/Admin/Settings/Email/Delete/${id}`),
        APDeleteAnimatedBanner:							async id => await httpFetch('DELETE', `/Admin/AnimatedBanner/${id}`),
        APDeleteBannerImage:							async id => await httpFetch('DELETE', `/Admin/Banner/Images/Delete/${id}`),
        APDeleteCustomPage:								async id => await httpFetch('DELETE', `/Admin/Custompage/Delete/${id}`),
        APDeletePopup:									async id => await httpFetch('DELETE', `/Admin/Popup/Delete/${id}`),
        APDeleteDiscount:								async id => await httpFetch('DELETE', `/Admin/Discount/Delete/${id}`),
        APDeleteAdBanner:								async id => await httpFetch('DELETE', `/Admin/Ad/${id}`),
        APRemoveAgreement:								async id => await httpFetch('DELETE', `/Admin/Agreement/Delete/${id}`),
        APRemoveCurrency:								async id => await httpFetch('DELETE', `/Admin/Currency/Delete/${id}`),
        APDeleteMeasure:								async id => await httpFetch('DELETE', `/Admin/Measure/Delete/${id}`),
        APDeleteProduct:								async id => await httpFetch('DELETE', `/Admin/Product/Delete/${id}`),
        APDeleteService:								async id => await httpFetch('DELETE', `/Admin/Service/Delete/${id}`),
        APDeleteFiller:									async id => await httpFetch('DELETE', `/Admin/Filler/Delete/${id}`),
        APDeleteButton:									async id => await httpFetch('DELETE', `/Admin/Settings/Button/${id}`),
        APRemoveUser:									async id => await httpFetch('DELETE', `/Admin/User/${id}`),
        APAddUser:                                      async formData => await httpSendJsonData(formData, 'POST', '/Admin/User'),
        APDeleteBanner:									async id => await httpFetch('DELETE', `/Admin/Banner/${id}`),
        APRemoveRegion:									async id => await httpFetch('DELETE', `/Admin/Region/Delete/${id}`),
        APRemoveTechnicalBreakBackground: 				async () => await httpFetch('DELETE', `/Admin/TechnicalBreak/Background`),
        APDeleteAttributeDictionaryValue: 				async id => await httpFetch('DELETE', `/Admin/Attribute/DeleteDictionaryValue/${id}`),
        APRemoveVisualSettingsFaviconFile: 				async () => await httpFetch('DELETE', `/Admin/Settings/Logo/Favicon/File`),
        APRemoveSectionPhotoWithTextImage: 				async id => await httpFetch('DELETE', `/Admin/MainPage/PhotoWithText/${id}/File`),
        APRemoveVisualSettingsPlaceholderImageProduct: 	async () => await httpFetch('DELETE', `/Admin/Settings/PlaceholderImage/Product`),
        APRemoveCurrencyRegion:					        async ({ currencyId, regionIds }) => await httpSendJsonData(regionIds, 'DELETE', `/Admin/Currency/${currencyId}/Regions`),
        APUpdateShippingFormsGeneral: 			        async (formData) => await httpSendJsonData(formData, 'PUT', `/Admin/ShippingForms/General`),
        APChangeUserEmail: 						        async ({ id, formData }) => await httpSendJsonData(formData, 'PUT', `/Admin/User/${id}/Email`),
        APChangeUserFeatures: 					        async ({ id, formData }) => await httpSendJsonData(formData, 'PUT', `/Admin/User/${id}/Features`),
        APChangeUserRole: 						        async ({ id, formData }) => await httpSendJsonData(formData, 'PUT', `/Admin/User/${id}/Role`),
        APUpdateEmailTemplate: 					        async ({ id, formData }) => await httpSendJsonData(formData, 'PUT', `/Admin/EmailTemplate/${id}`),
        APUpdatePromoSection: 					        async ({ id, formData }) => await httpSendJsonData(formData, 'PUT', `/Admin/PromoSection/${id}`),
        APUpdateCookieIsActive: 				        async ({ id, formData }) => await httpSendJsonData(formData, 'PUT', `/Admin/Settings/Cookies/${id}/Active`),
        APUpdateCookie: 						        async ({ id, formData }) => await httpSendJsonData(formData, 'PUT', `/Admin/Settings/Cookies/${id}`),
        APAddDeleteSectionCategories: 			        async ({ id, type, formData }) => await httpSendJsonData(formData, 'PUT', `/Admin/MainPage/Sections/${type}/${id}/Categories/AddDelete`),
        APAddDeleteSectionProducts: 			        async ({ id, type, formData }) => await httpSendJsonData(formData, 'PUT', `/Admin/MainPage/Sections/${type}/${id}/Products/AddDelete`),
        APUpdateAnimatedBannerImage: 			        async ({animatedBannerId, formData}) => await httpSendJsonData(formData, 'PUT', `Admin/AnimatedBanner/Images/${animatedBannerId}`),
        APUpdateBannerImage: 					        async ({bannerId, formData}) => await httpSendJsonData(formData, 'PUT', `Admin/Banner/Images/${bannerId}`),
        APMoveAnimatedBannerImage: 				        async ({id, delta, formData}) => await httpSendJsonData(formData, 'PUT', `Admin/AnimatedBanner/Images/Move/${id}/${delta}`),
        APMoveBannerImage: 						        async ({id, delta, formData}) => await httpSendJsonData(formData, 'PUT', `Admin/Banner/Images/Move/${id}/${delta}`),
        APMovePromotionBarSlides: 				        async ({id, delta, formData}) => await httpSendJsonData(formData, 'PUT', `Admin/Settings/PromotionBar/Slides/Move/${id}/${delta}`),
        APUpdateAnimatedBanner: 				        async ({id, formData}) => await httpSendJsonData(formData, 'PUT', `Admin/AnimatedBanner/${id}`),
        APUpdateBanner:							        async ({id, formData}) => await httpSendJsonData(formData, 'PUT', `Admin/Banner/${id}`),
        APUpdateEmailTemplateTable: 			        async ({templateId, tableId, formData}) => await httpSendJsonData(formData, 'PUT', `/Admin/EmailTemplate/Tables/${templateId}/${tableId}`),
        APUpdateAnimatedBannerImageSettings: 	        async ({animatedBannerId, animatedBannerImageId, device, formData}) => await httpSendJsonData(formData, 'PUT', `Admin/AnimatedBanner/Images/${animatedBannerId}/${animatedBannerImageId}/${device}/settings`),
        APAddCurrencyRegion: 					        async ({ currencyId, regionId }) => await httpSendJsonData(regionId, 'POST', `/Admin/Currency/${currencyId}/Regions`),
        APChangePassword: 						        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Authentication/ChangePassword'),
        APUpdateAgreementBasicData: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Agreement/UpdateBasicData'),
        APUpdateAgreementDescription: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Agreement/UpdateDescription'),
        APUpdateAgreementRegions: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Agreement/UpdateRegions'),
        APUpdateAgreementStatus: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Agreement/UpdateStatus'),
        APUpdateAttributeAffiliation: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Attribute/UpdateAttributeAffiliation'),
        APUpdateAttributeBasicData: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Attribute/UpdateAttributeBasicData'),
        APUpdateAttributeDictionaryValueActivity:       async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Attribute/UpdateAttributeDictionaryValueActivity'),
        APUpdateAttributeColorValue: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Attribute/UpdateAttributeColorValue'),
        APUpdateAttributeSettings: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Attribute/UpdateAttributeSettings'),
        APUpdateCategoryBasicData: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Category/UpdateCategoryBasicData'),
        APUpdateCategoryLocation: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Category/UpdateCategoryLocation'),
        APUpdateCategoryStatus: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Category/UpdateCategoryStatus'),
        APUpdateDiscountBasic: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Discount/Update'),
        APUpdateDiscountCategories: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Discount/UpdateCategories'),
        APUpdateDiscountCountries: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Discount/UpdateCountries'),
        APUpdateDiscountGroups: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Discount/UpdateGroups'),
        APUpdateDiscountParams: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Discount/UpdateParams'),
        APUpdateDiscountProducts: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Discount/UpdateProducts'),
        APUpdateDiscountRegions: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Discount/UpdateRegions'),
        APUpdateDiscountUsers: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Discount/UpdateUsers'),
        APAddProductAdditionalCategory: 		        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/Add/AdditionalCategory'),
        APAddProductAlternative: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/Add/AlternativeProduct'),
        APAddProductRelated: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/Add/RelatedProduct'),
        APUpdateProductPhotoOrder: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/MoveProductImage'),
        APUpdateProductAttributes: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/UpdateProductAttributeValues'),
        APUpdateDiscountAttributes: 				    async ({discountId, formData}) => await httpSendJsonData(formData, 'PUT', `/Admin/Discount/${discountId}/Attributes`),
        APUpdateProductBasicData: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/UpdateProductBasicData'),
        APUpdateProductDescription: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/UpdateProductDescription'),
        APUpdateProductImageData: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/UpdateProductImage'),
        APUpdateProductPhotoFlag: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/UpdateProductImageFlags'),
        APUpdateCountryNames: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Region/Country/Update'),
        APUpdateCountryActive: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Region/Country/Update/Active'),
        APUpdateProductCarouselsGeneral: 		        async formData => await httpSendJsonData(formData, 'PUT', 'Admin/MainPage/ProductCarousels'),
        APAddAdBannerCategories: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Ad/AddCategories'),
        APAddEditAdBanner: 						        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Ad/AddEdit'),
        APUpdateAdBannerStatus: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Ad/Update'),
        APAddCategoryForProducts: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Category/Add/Product'),
        APUpdateIntegrationAPaczka: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/APaczka'),
        APUpdateCeneoSettings: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/Ceneo/UpdateCeneoSettings'),
        APUpdateIntegrationFacebook: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/Facebook'),
        APUpdateFreshmail: 						        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/FreshMail/UpdateSettings'),
        APUpdateIntegrationFurgonetka: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/Furgonetka'),
        APUpdateGoogleAnalytics: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/GoogleAnalytics/UpdateSettings'),
        APUpdateIntegrationInPost: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/InPost'),
        APUpdateIntegrationRecaptcha:			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/Recaptcha'),
        APUpdateIntegrationGoogleSearchConsole:	        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/GoogleSearchConsole'),
        APUpdateIntegrationP24: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/P24'),
        APUpdateIntegrationPayU: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Integrations/PayU'),
        APUpdateHomepageGeneralSettings: 				async formData => await httpSendJsonData(formData, 'PUT', '/Admin/MainPage/General'),
        APAddUpdatePageSection: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/MainPage/Sections'),
        APUpdateMediaFiles: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Media/File/Update'),
        APUpdateMenuSection: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Menu'),
        APUpdateFooterSettingsPhotoWithText: 			async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Menu/Settings/Footer/PhotoWithText'),
        APUpdateFooterSettingsNewsletter: 				async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Menu/Settings/Footer/Newsletter'),
        APUpdateFooterSettingsMain: 				    async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Menu/Settings/Footer/Main'),
        APUpdateNotFound: 						        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/NotFound'),
        APUpdateOrder:							        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Order/Update'),
        APUpdateOrderItemRealized:					    async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Order/Update/OrderItem/Realized'),
        APAddEditPaymentForm: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/PaymentForms'),
        APUpdateProductPresentationCart: 		        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/Page/GlobalFields'),
        APUpdateProductPagTabs: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/Page/Tabs'),
        APUpdateProductGeneralSettings: 		        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/Presentation/General'),
        APUpdateListPresentation: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/Presentation/List'),
        APUpdateProductPresentationTile: 		        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/Presentation/Tile'),
        APUpdateProductDelivery: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/UpdateProductDelivery'),
        APUpdateProductPrices: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/UpdateProductPrice'),
        APUpdateProductStatus: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/UpdateProductStatus'),
        APUpdateServiceStatus: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Service/UpdateServiceStatus'),
        APUpdateProductReview: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/ProductReview/Update'),
        APUpdateGoogleSiteMap: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Seo/GoogleSiteMap'),
        APUpdateOwnCode: 					            async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Seo/OwnCode'),
        APUpdatePhotoName: 						        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Seo/PhotoName'),
        APUpdateButton:							        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/Button'),
        APUpdateCatalogSettings: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/Catalogue'),
        APUpdateCategoriesSettings: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/Category'),
        APUpdateTemplateParams: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/Css/Params'),
        APUpdateVisualSettingsCss: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/Css'),
        APUpdateEmailSetting: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/Email'),
        APUpdateSelectedFonts: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/Font'),
        APUpdateGraphicsSettings: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/Graphics'),
        APUpdateVisualSettingsLogo: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/Logo'),
        APUpdatePromotionBarSlides: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/PromotionBar/Slides/Update'),
        APUpdatePromotionBar: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/PromotionBar'),
        APUpdateQuantityPresentation: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/QuantityDisplay'),
        APUpdateSearchSettings: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/SearchEngine'),
        APUpdateGeneralSettings: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/General'),
        APUpdateOrderGreetings:					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/Order'),
        APAddEditShippingFormsForm: 			        async formData => await httpSendJsonData(formData, 'PUT', `/Admin/ShippingForms/Forms/${formData.shippingVariantId}/${formData.shippingVariantWeightId}`),
        APAddEditShippingFormsVariant: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/ShippingForms/Variants'),
        APAddEditShippingFormsWeight: 			        async formData => await httpSendJsonData(formData, 'PUT', `/Admin/ShippingForms/Weights/${formData.shippingVariantId}`),
        APUpdateSynchronizationsErp: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Synchronizations/Erp'),
        APUpdateTechnicalBreak: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/TechnicalBreak'),
        APUpdateTranslations: 					        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Translations'),
        APUpdateUserData: 						        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/User/BasicData'),
        APUpdateUserAgreementByEmail: 			        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/UserAgreement'),
        APUpdateProductVariants: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Variant/AddProducts'),
        APUpdateVariantSettings: 				        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Variant/UpdateVariantSettings'),
        APUpdateChatSettings: 					        async formData => await httpSendJsonData(formData, 'PUT', 'Admin/Integrations/Chat'),
        APUpdateInspectletSettings: 					async formData => await httpSendJsonData(formData, 'PUT', 'Admin/Integrations/Inspectlet'),
        APUpdateAttributeDictionaryValue: 		        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Attribute/UpdateAttributeDictionaryValue'),
        APUpdateSynchronizationsSettings: 		        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Synchronizations/Settings'),
        APUpdateProductDescriptionImportSettings: 		async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Import/Product/Description/Settings'),
        APUpdateProductCategoryForFeatures: 	        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/UpdateProductCategoryForAttributes'),
        APUpdateProductsCategoryForFeatures: 	        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/UpdateProductsCategoryForAttributes'),
        APUpdateManufacturerShowInFilters: 		        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Manufacturer/UpdateManufacturerShowInFilters'),
        APUpdateLoginAndRegistrationSettings: 	        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/LoginAndRegistration'),
        APUpdateCookiesTranslations: 	                async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Settings/CookiesTranslations'),
        APUpdateProductDeliveryGlobalSettings: 	        async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/Delivery/Settings'),
        APUpdateOrderStatus:							async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Order/Statuses'),
        APAddEditOrderStatus:							async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Order/Statuses/AddEdit'),
        APDeleteOrderStatus:        	                async ( id )   => await httpFetch('DELETE', `/Admin/Order/Statuses/${id}`),
        APUpdateProductMeasure:							async formData => await httpSendJsonData(formData, 'PUT', '/Admin/Product/AddEdit/Measure'),
        APChangeEmailPerform: 					        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Authentication/ChangeEmail/Perform'),
        APChangeEmailRequest: 					        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Authentication/ChangeEmail/Request'),
        APLogin: 								        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Authentication/Login'),
        APRecoverPasswordPerform: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Authentication/RecoverPassword/Perform'),
        APRecoverPasswordRequest: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Authentication/RecoverPassword/Request'),
        APCreateAttribute: 						        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Attribute/Create'),
        APCreateCategory: 						        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Category/Create'),
        APEditCurrency:							        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Currency/AddEdit'),
        APAddCurrency:							        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Currency/AddEdit'),
        APUpdateCurrencyStatus: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Currency/Status'),
        APUpdateCustomPageBasicData: 			        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Custompage/UpdateCustomPageBasicData'),
        APUpdatePopupBasicData: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Popup/UpdatePopupBasicData'),
        APUpdateCustomPageStatus: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Custompage/UpdateCustompagesStatus'),
        APUpdatePopupStatus: 					        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Popup/UpdatePopupsStatus'),
        APGenerateDiscountCodes: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Discount/CreateCodes'),
        APAddProduct:							        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Product/Create'),
        APAddService:							        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Product/CreateService'),
        APAddRegion:							        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Region/AddEdit'),
        APEditRegionName: 						        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Region/AddEdit'),
        APRemoveRegionCountries: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Region/Countries/AddDelete'),
        APAddEditExchangeRate: 					        async formData => await httpSendJsonData(formData, 'POST', 'Admin/Exchangerate/AddEdit'),
        APAddAttributeCategory: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Attribute/AddCategory'),

        APAddEditFiller: 						        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Filler/AddEdit'),
        APAddCeneoMapping: 						        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Integrations/Ceneo/Mappings'),
        APUpdateLanguages: 						        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Language/Status'),
        APMoveManufacturersItem: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/MainPage/ManufacturerSection/MoveItem'),
        APUpdateMeasure: 						        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Measure/Update'),
        APAddMenuSection: 						        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Menu'),
        APAddEditPredefinedSearch: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/PredefinedSearch/AddEdit'),
        APSendTestEmail: 						        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Settings/Email/Test'),
        APSendShipments: 						        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Shipments/Send'),
        APCreateSummaryProduct: 				        async formData => await httpSendJsonData(formData, 'POST', '/Admin/Variant/Create'),
        APAddDeleteAssignedManufacturers: 		        async formData => await httpSendJsonData(formData, 'POST', '/Admin/MainPage/ManufacturerSection/AddDeleteItem'),
        APDeleteCategoryAttributes: 			        async formData => await httpSendJsonData(formData, 'DELETE', '/Admin/Category/Delete/Attribute'),
        APDeleteProductAlternative: 			        async formData => await httpSendJsonData(formData, 'DELETE', '/Admin/Product/Delete/AlternativeProduct'),
        APDeleteProductImages: 					        async formData => await httpSendJsonData(formData, 'DELETE', '/Admin/Product/Delete/Images'),
        APDeleteProductRelated: 				        async formData => await httpSendJsonData(formData, 'DELETE', '/Admin/Product/Delete/RelatedProduct'),
        APDeleteAdBannerCategories: 			        async formData => await httpSendJsonData(formData, 'DELETE', '/Admin/Ad/DeleteCategories'),
        APDeleteAttributeCategory: 				        async formData => await httpSendJsonData(formData, 'DELETE', '/Admin/Attribute/DeleteCategory'),
        APDeleteCategoryForProducts: 			        async formData => await httpSendJsonData(formData, 'DELETE', '/Admin/Category/Delete/Product'),
        APDeleteMediaFiles: 					        async formData => await httpSendJsonData(formData, 'DELETE', '/Admin/Media/File/Delete'),
        APDeleteProductVariants: 				        async formData => await httpSendJsonData(formData, 'DELETE', '/Admin/Variant/DeleteProducts'),
        APDeleteProductAdditionalCategories: 	        async formData => await httpSendJsonData(formData, 'DELETE', '/Admin/Product/Delete/AdditionalCategory'),
        APUpdateMenuSectionLocation: 			        async formData => await httpSendJsonData(formData, 'PATCH', '/Admin/Menu/UpdateLocation'),
        APUpdateMenuSectionStatus: 				        async formData => await httpSendJsonData(formData, 'PATCH', '/Admin/Menu/UpdateStatus'),
        APUpdateFooterImage:                            async formData => await httpSendCustomData(formData, 'PUT', `/Admin/Menu/Settings/Footer/Image`),
        APUpdateNotFoundImage:                          async formData => await httpSendCustomData(formData, 'PUT', `/Admin/NotFound/Image`),
        APUpdateTechnicalBreakBackground:               async formData => await httpSendCustomData(formData, 'PUT', `/Admin/TechnicalBreak/Background`),
        APUploadAdBannerImage:                          async ({ id, formData }) => await httpSendCustomData(formData, 'PUT', `/Admin/Ad/${id}/UploadImage`),
        APUploadAnimatedBannerImage:                    async ({ animatedBannerId, id, formData }) => await httpSendCustomData(formData, 'PUT', `/Admin/AnimatedBanner/Images/${animatedBannerId}/${id}/File`),
        APUploadBannerImage:                            async ({ bannerId, id, formData }) => await httpSendCustomData(formData, 'PUT', `/Admin/Banner/Images/${bannerId}/${id}/File`),
        APUploadCategoryImage:                          async ({ id, type, formData }) => await httpSendCustomData(formData, 'PUT', `/Admin/Category/${id}/File/${type}`),
        APUploadDoubleImageSectionImages:               async ({ id, formData }) => await httpSendCustomData(formData, 'PUT', `/Admin/MainPage/DoubleImageSection/${id}/File`),
        APUploadFillerImage:                            async ({ id, formData }) => await httpSendCustomData(formData, 'PUT', `/Admin/Filler/${id}/File`),
        APUploadPromoSectionImage:                      async ({ id, formData }) => await httpSendCustomData(formData, 'PUT', `/Admin/PromoSection/${id}/File`),
        APUploadSectionPhotoWithTextImage:              async ({ id, formData }) => await httpSendCustomData(formData, 'PUT', `/Admin/MainPage/PhotoWithText/${id}/File`),
        APUploadVisualSettingsFaviconFile:              async formData => await httpSendCustomData(formData, 'PUT', `/Admin/Settings/Logo/Favicon/File`),
        APUploadVisualSettingsLogoFile:                 async formData => await httpSendCustomData(formData, 'PUT', `/Admin/Settings/Logo/File`),
        APUploadVisualSettingsOwnCss:                   async ({ id, formData }) => await httpSendCustomData(formData, 'PUT', `/Admin/Settings/Css/User/${id}`),
        APUploadVisualSettingsPlaceholderImageProduct:  async formData => await httpSendCustomData(formData, 'PUT', `/Admin/Settings/PlaceholderImage/Product`),
        APUploadMenuImage:                              async ({ id, formData }) => await httpSendCustomData(formData, 'PUT', `/Admin/Menu/${id}/File`),
        APAddEditManufacturer:                          async formData => await httpSendCustomData(formData, 'POST', '/Admin/Manufacturer/AddEdit'),
        APUploadProductImage:                           async formData => await httpSendCustomData(formData, 'POST', '/Admin/Product/UploadProductImage'),
        APImportProductDescription:                     async formData => await httpSendCustomData(formData, 'POST', '/Admin/Import/Product/Description'),
        APGetCategoryFormFields: 					    async (id) => await httpFetch('GET', `/Admin/Category/${id}/FormFields`),
        APGetCategoryFormField: 					    async ({categoryId,id}) => await httpFetch('GET', `/Admin/Category/${categoryId}/FormFields/${id}`),
        APAddEditCategoryFormField:				        async formData => await httpSendJsonData(formData, 'POST', `/Admin/Category/${formData.categoryId}/FormFields`),
        APDeleteCategoryFormField:   			        async ({categoryId,id}) => await httpFetch('DELETE', `/Admin/Category/${categoryId}/FormFields/${id}`),
        createOrder:                                    async formData => await httpSendJsonData(formData, 'POST', '/Order/Create'),
        refreshCart:                                    async ()   =>   await httpFetch('GET',  '/Cart/Current'),
        shopLogout:						                async ()   =>   await httpFetch('POST', '/Authentication/Logout'),
        getCountriesAndVoivodeships:	                async ()   =>   await httpFetch('GET', '/Order/CountriesAndVoivodeships'),
        getUserData:					                async ( id ) => await httpFetch('GET', `/User/${id}`),
        getUserDataForAdmin:					        async ( id ) => await httpFetch('GET', `/Admin/User/${id}`),
        getOrderDetails:				                async ( id ) => await httpFetch('GET', `/Order/User/${id}`),
        setCurrentLanguage:				                async ( id ) => await httpFetch('POST', `/Language/${id}`),
        setCurrentCurrency:				                async ( id ) => await httpFetch('POST', `/Currency/${id}`),
        addProductToFavourites:			                async ( id ) => await httpFetch('POST', `/Favourites/Add/${id}`),
        removeProductFromFavourites :	                async ( id ) => await httpFetch('DELETE', `/Favourites/Remove/${id}`),
        removeUserAddress:				                async ( id ) => await httpFetch('DELETE', `/User/Address/Delete/${id}`),
        getLayout:                                      async ({ previewLayoutId, previewLayoutTemplateId, previewType }) => await httpFetch('GET', `/Layout/${previewLayoutId}/${previewLayoutTemplateId}/${previewType}`),
        addAllProductsFromCartToFavourites:	            async ({ cookie }) => await httpFetchWithCookie(cookie, 'POST', '/Cart/AddToFavourites'),
        countFavourites:	                            async ({ formData }) => await httpGetSearch(formData, 'countFavouritesRequest', '/Favourites/Count'),
        clearCart:							            async ({ cookie }) => await httpFetchWithCookie(cookie, 'DELETE', '/Cart/Clear'),
        getInitPropsCart:					            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/Cart'),
        getInitPropsCheckoutAuthorize:		            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/CheckoutAuthorize'),
        getSearchPageInitProps:				            async ({ cookie, searchConfig }) => await httpFetchWithCookie(cookie, 'GET', `/InitProps/GlobalSearch?searchText='${searchConfig.searchText ? encodeURIComponent(searchConfig.searchText) : ''}'`),
        getFavouritesInitProps:				            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/Favourites'),
        getComparisonInitProps:				            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/Comparison'),
        getLoginInitProps:				                async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/Login'),
        get404InitProps:				                async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/Error404'),
        serverGetCurrentCart:				            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/Cart/Current'),
        serverGetCurrentOrder:				            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/Order/Current'),
        serverGetInitPropsAddressBook:		            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/AddressBook'),
        serverGetInitPropsAddressBookAdd:	            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/AddressBook/Add'),
        serverGetInitPropsAddressBookBasic:	            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/AddressBook/Basic'),
        serverGetInitPropsAddressBookEdit:	            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/AddressBook/Edit'),
        serverGetInitPropsApp:				            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/App'),
        serverGetInitPropsErrorPage:		            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/ErrorPage'),
        serverGetInitPropsInactiveOrder:	            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/InactiveOrder'),
        serverGetInitPropsOrder:			            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/Order'),
        serverGetInitPropsOrderGreetings:	            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/OrderGreetings'),
        serverGetInitPropsOrderGuest:		            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/OrderGuestForm'),
        serverGetInitPropsOrderHistoryPage:	            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/OrderHistory'),
        serverGetInitPropsOrderPayment:		            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/OrderPayment'),
        serverGetInitPropsOrderShipment:	            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/OrderShipment'),
        serverGetInitPropsOrderSummary:		            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/OrderSummary'),
        serverGetInitPropsPasswordRecovery:	            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/PasswordRecovery'),
        serverGetInitPropsSignupConfirmationResend:	    async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/SignupConfirmationResend'),
        serverGetInitPropsProduct:			            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/Product'),
        serverGetInitPropsSetNewPassword:	            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/SetNewPassword'),
        serverGetInitPropsSignupPage:		            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/Registration'),
        serverGetInitPropsUserProfile:		            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/UserProfile'),
        serverGetInitPropsUserSettings:		            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/UserSettings'),
        serverGetLocale:					            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/Language'),
        serverGetMainPageInitProps:			            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/InitProps/MainPage'),
        serverGetPaymentMethods:			            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', 'Order/PaymentMethods'),
        serverGetShipmentMethods:			            async ({ cookie }) => await httpFetchWithCookie(cookie, 'GET', '/Order/ShippingMethods'),
        getProductData:						            async ({ id, cookie }) => await httpFetchWithCookie(cookie, 'GET', `/Product/${id}`),
        serverGetCustomPageInitProps:		            async ({ id, cookie }) => await httpFetchWithCookie(cookie, 'GET', `/CustomPage/${id}`),
        getCategorySearchPageInitProps:		            async ({ id, cookie }) => await httpFetchWithCookie(cookie, 'GET', `/InitProps/CategorySearch?categoryId=${id}`),
        getPredefineSearchPageInitProps:	            async ({ cookie, searchConfig }) => await httpFetchWithCookie(cookie, 'GET',  `/InitProps/PredefinedSearch?searchPageSlug=${searchConfig.searchPageSlug}${searchConfig.categoryId ? `&categoryId=${searchConfig.categoryId}` : ''}`),
        getFeaturedTabProducts:				            async ( formData ) => await httpGetSearch(formData, 'getProductsByAttributeValueRequest', '/Product/ByAttributeValue'),
        getProductAlternatives:				            async ( formData ) => await httpGetSearch(formData, 'getProductAlternativesRequest', '/Product/Alternatives'),
        getProductRelated:					            async ( formData ) => await httpGetSearch(formData, 'getProductRelatedRequest', '/Product/Related'),
        getProductRelatedOrders:			            async ( formData ) => await httpGetSearch(formData, 'getProductRelatedOrdersRequest', '/Product/RelatedOrders'),
        getProductsComparison:				            async ( formData ) => await httpGetSearch(formData, 'getProductComparisonRequest', '/Product/Comparison'),
        getReviews:							            async ( formData ) => await httpGetSearch(formData, 'getProductReviewsRequest', '/Product/Reviews'),
        getSearchResults:					            async ( formData ) => await httpGetSearch(formData, 'productSearchCriteria', '/Search'),
        getUserHistoryOrders:				            async ( formData ) => await httpGetSearch(formData, 'getUserOrdersRequest', '/Order/User'),
        askForPrice:						            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Product/AskForPrice'),
        addCartDiscountCode:				            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Cart/DiscountCode/Add'),
        addCartComments:				                async ( formData ) => await httpSendJsonData(formData, 'POST', '/Cart/Comments/Add'),
        addProductToCartWithQuantity:		            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Cart/Add'),
        addToNewsletter:					            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Newsletter'),
        addUserAddress:						            async ( formData ) => await httpSendJsonData(formData, 'POST', '/User/Address/Add'),
        askForProduct:						            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Product/AskForProduct'),
        changeBasicUserData:				            async ( formData ) => await httpSendJsonData(formData, 'PUT', '/User/Address/Main/Update'),
        changeUserEmail:					            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Authentication/ChangeEmail/Request'),
        changeUserPassword:					            async ( formData ) => await httpSendJsonData(formData, 'PUT', '/Authentication/ChangePassword/'),
        recoverPasswordPerform:				            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Authentication/RecoverPassword/Perform'),
        recoverPasswordRequest:				            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Authentication/RecoverPassword/Request'),
        resendSignupConfirmation: 				        async ( formData ) => await httpSendJsonData(formData, 'POST', '/Authentication/ResendSignupConfirmation'),
        registerNewUser:					            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Registration'),
        verifyUserEmail:			                    async ( token )    => await httpFetch('POST', `/Registration/${token}`),
        removeCartDiscountCode:				            async ( formData ) => await httpSendJsonData(formData, 'DELETE', '/Cart/DiscountCode/Remove'),
        removeProductFromCart:				            async ( formData ) => await httpSendJsonData(formData, 'DELETE', '/Cart/Remove'),
        sendContactForm:					            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Contact'),
        sendReview:							            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Product/SendReview'),
        shopLogin:							            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Authentication/LogIn'),
        updatePaymentAndShipmentDetails:	            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Order/UpdatePaymentAndShipmentDetails'),
        updatePaymentDetails:				            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Order/UpdatePaymentDetails'),
        updateProductInCart:				            async ( formData ) => await httpSendJsonData(formData, 'PUT', '/Cart/Update'),
        updateRecipientDetails:				            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Order/UpdateRecipientDetails'),
        updateShipmentDetails:				            async ( formData ) => await httpSendJsonData(formData, 'POST', '/Order/UpdateShipmentDetails'),
        editUserAddress:					            async ( formData, id ) => await httpSendJsonData(formData, 'PUT', `/User/Address/Update/${id}`),
    };
};

module.exports = ConnecticoApi;