const shippingForms = {
    title: 'Ustawienia ogólne',
    shippingFormsUseVariants: {
        label: 'Dziel formy dostawy na warianty zależne od regionu wysyłki',
        explanation: 'Zastosowanie takiego podziału pozwoli na definiowanie różnych zestawów dostępnych form dostawy w zależności od regiony (kraju), do którego ma nastąpić wysyłka'
    },
    shippingFormsUseWeights: {
        label: 'Dziel formy dostawy na warianty zależne od zakresów wagowych zamówień',
        explanation: 'Zastosowanie takiego podziału pozwoli na definiowanie różnych zestawów dostępnych form dostawy w zależności od sumarycznej wagi zamówionych produktów'
    },
    buttons: {
        backToVariantsList: 'Wróć do listy wariantów',
        backToWeightsList: 'Wróć do listy zakresów wagowych',
    },
    tooltips: {
        goToWeights: 'Przejdź do zakresów wagowych',
        goToForms: 'Przejdź do form dostawy'
    },
    formsTable: {
        title: 'Formy dostawy',
        headers:{
            id: 'Id',
            name: 'Nazwa formy dostawy',
            shippingMethod: 'Typ usługi',
            productName: 'Usługa dostawy',
            wfMagFormName: 'Forma dostawy z systemu ERP',
            paymentForms: 'Przypisane formy płatności',
            payment: 'Płatność',
            visibility: 'Widoczność',
            actions: 'Akcje'
        },
        tooltips: {
            edit: 'Edytuj',
            remove: 'Usuń'
        },
        buttons: {
            create: 'Dodaj',
            confirm: 'Zapisz',
            cancel: 'Anuluj'
        },
        editModal:{
            title: 'Edycja formy dostawy',
            inputs:{
                name: 'Nazwa formy dostawy',
                shippingMethodId: 'Typ usługi',
                productId: 'Usługa dostawy',
                wfMagFormId: 'Forma dostawy z systemu ERP',
                paymentFormIds: 'Powiązane formy płatności',
                isFree: {
                    true: 'Darmowa',
                    false: 'Płatna'
                },
                orderVisibleValueCaption: 'Widoczna dla zamówień o wartości brutto:',
                orderVisibleValueFrom: 'powyżej',
                orderVisibleValueTo: 'poniżej',
                orderFreeValueCaption: 'Darmowa dla zamówień o wartości brutto:',
            },
            buttons:{
                remove: 'WRÓĆ',
                save: 'ZAPISZ'
            },
            nameModalHeader: 'Edycja nazwy formy dostawy'
        },
        deleteModal:{
            header: 'Usuń formę dostawy',
            body: 'Czy jesteś pewien, że chcesz usunąć tę formę dostawy?',
            confirm: 'Usuń',
            cancel: 'Wróć'
        },
        errors:{
            productIdIsRequired: 'Wybranie wartości w polu "Usługa dostawy" jest wymagane',
            wfMagFormIdIsRequired: 'Wybranie wartości w polu "Forma dostawy z systemu ERP" jest wymagane'
        }
    },
    weightsTable: {
        title: 'Zakresy wagowe form dostawy',
        headers:{
            id: 'Id',
            weightFrom: 'Waga od',
            weightTo: 'Waga do',
            actions: 'Akcje'
        },
        tooltips: {
            edit: 'Edytuj',
            remove: 'Usuń'
        },
        buttons: {
            create: 'Dodaj',
            confirm: 'Zapisz',
            cancel: 'Anuluj'
        },
        editModal:{
            title: 'Edycja zakresu wagowego',
            inputs:{
                weightFrom: 'Waga od'
            },
            buttons:{
                remove: 'WRÓĆ',
                save: 'ZAPISZ'
            }
        },
        deleteModal:{
            header: 'Usuń zakres wagowy form dostawy',
            body: 'Jeśli usuniesz wybrany zakres wagowy, razem z nim zostaną usunięte wszystkie powiązane z nim formy dostawy',
            confirm: 'Usuń',
            cancel: 'Wróć'
        },
        errors:{
            weightFromIsRequired: 'Wypełnienie pola "Waga od" jest wymagane'
        }
    },
    variantsTable: {
        title: 'Warianty form dostawy',
        headers:{
            id: 'Id',
            name: 'Nazwa',
            isDefault: 'Domyślny',
            regions: 'Przypisane regiony',
            actions: 'Akcje'
        },
        tooltips: {
            edit: 'Edytuj',
            remove: 'Usuń'
        },
        buttons: {
            create: 'Dodaj',
            confirm: 'Zapisz',
            cancel: 'Anuluj'
        },
        editModal:{
            title: 'Edycja wariantu',
            inputs:{
                name: 'Nazwa',
                regionIds: 'Przypisane regiony'
            },
            buttons:{
                remove: 'WRÓĆ',
                save: 'ZAPISZ'
            }
        },
        deleteModal:{
            header: 'Usuń wariant form dostawy',
            body: 'Jeśli usuniesz wybrany wariant, razem z nim zostaną usunięte wszystkie powiązane z nim formy dostawy',
            confirm: 'Usuń',
            cancel: 'Wróć'
        },
        errors:{
            nameIsRequired: 'Wypełnienie pola "Nazwa" jest wymagane'
        }
    },

};

export default shippingForms;