import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { generateEditLinkForManufacturers } from '../../../utils/manufacturers';

import TableGenerator from '../../generators/TableGenerator';
import PhotoPlaceholder from '../../../assets/photo_placeholder.svg';

import { manufacturersForHomePageTablePropTypes } from '../../../propTypes';

import styles from './index.module.scss';
import getConfig from '../../../getConfig';

const propTypes = {
    manufacturersList: PropTypes.arrayOf(manufacturersForHomePageTablePropTypes),
    increaseOrder: PropTypes.func,
    decreaseOrder: PropTypes.func,
    removeHandler: PropTypes.func,
    checkedProductsIds: PropTypes.array,
    setCheckedProductsIds: PropTypes.func,
    isModal: PropTypes.bool,
};

const ManufacturersForHomePageTable = ({
    manufacturersList,
    increaseOrder,
    decreaseOrder,
    removeHandler,
    checkedProductsIds,
    setCheckedProductsIds,
    isModal = false,
}) => {
    const translations = useIntl().messages.homePage.manufacturersSection.manufacturers;
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const history = useHistory();

    const ImageCell = item => (
        <img
            className={styles.photo}
            src={item.logo ? `${apiUrl}${item.logo}` : PhotoPlaceholder}
            alt={item.name || translations.emptyLogoAlt}
        />
    );

    const handleRowClick = id => () => {
        history.push(`/manufacturers/edit?manufacturerID=${id}`);
    };

    const handleIncreaseOrder = (id, index, item) => {
        increaseOrder({ id : item.itemId, order: index + 1, type: item.type });
    };

    const handleDecreaseOrder = (id, index, item) => {
        decreaseOrder({ id: item.itemId, order: index - 1, type: item.type });
    };

    const deleteAction = id => () => {
        removeHandler(id);
    };

    return (
        <TableGenerator
            checkedItemsIds={checkedProductsIds}
            setCheckedItemsIds={setCheckedProductsIds}
            editLink={generateEditLinkForManufacturers}
            expectedActions={isModal ? ['edit'] : ['edit', 'delete']}
            actions={{ deleteAction }}
            items={manufacturersList}
            translations={translations}
            defaultAction={handleRowClick}
            customIdSelector='id'
            columns={[
                {
                    type: 'checkbox'
                },
                !isModal && {
                    header: translations.headers.order,
                    type: 'order',
                    increaseOrder: handleIncreaseOrder,
                    decreaseOrder: handleDecreaseOrder,
                    additionalStyle: styles.order,
                },
                {
                    header: translations.headers.logo,
                    type: 'custom',
                    customCell: ImageCell,
                    additionalStyle: styles.photoCell
                },
                {
                    header: translations.headers.name,
                    value: 'name',
                    additionalStyle: styles.nameCell,
                    filler: translations.nameFiller
                },
                {
                    header: translations.headers.actions,
                    type: 'actions'
                },
            ]}
        />
    );
};

ManufacturersForHomePageTable.propTypes = propTypes;

export default ManufacturersForHomePageTable;