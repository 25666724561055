const categories = {
    view: {
        expandAll: 'Rozwiń wszystko',
        collapseAll: 'Zwiń wszystko',
        title: 'Lista kategorii',
        lastSyncDateLabel: 'Data ostatniej synchronizacji: ',
        dropdownSelector: {
            label: 'Widok kategorii:',
            options: {
                all: 'Wszystkie',
                selected: 'Aktywne'
            }
        },
        categoryBreadcrumbsRoot: 'Główne',
        emptyCategoryLevel: 'Brak kategorii na wybranym poziomie.',
        deleteModal: {
            header: 'Kategoria:',
            bodyStart: 'Czy na pewno usunąć kategorię',
            bodyEnd: '(wraz z jej kategoriami podrzędnymi)?',
            confirm: 'USUŃ',
            cancel: 'WRÓĆ'
        },
        actions: {
            edit: 'Edytuj kategorię',
            delete: 'Usuń kategorię',
            addRoot: 'DODAJ KATEGORIĘ GŁÓWNĄ',
            addTooltip: 'Dodanie kategorii możliwe tylko w przypadku wyłączonej synchronizacji z systemem ERP',
            addSub: 'DODAJ KATEGORIĘ PODRZĘDNĄ'
        },
        errorModalText: 'Wystąpił błąd przy usuwaniu kategorii',
        additionModal: {
            header: 'Dodaj nową kategorię',
            body: 'Czy chcesz utworzyć nową kategorię?',
            confirm: 'DODAJ',
            cancel: 'WRÓĆ'
        },
        warningModal: {
            header: 'Aktywuj kategorię',
            body: 'Aktywowanie kategorii spowoduje aktywację jej podkategorii. Czy chcesz kontynuować?',
            confirm: 'AKTYWUJ',
            cancel: 'WRÓĆ'
        },
        dndAlert: 'Przenoszenie kategorii między gałęziami możliwe tylko przy wyłączonej synchronizacji ze systemem ERP.',
        nameFiller: '[BRAK NAZWY]',
        categoryStatusTooltip: 'Kategoria aktywna w sklepie',
    },
    edit: {
        nameFiller: '[BRAK NAZWY]',
        tabSelectorTitle: 'Sekcje',
        erpConnectionAlert: 'Sekcja dostępna tylko w przypadku wyłączonej integracji z systemem ERP',
        tabs: {
            formfields: {
                title: 'Pola dodatkowe formularzy zapytań o produkt/cenę',
                emptyTable: 'Brak zdefiniowanych pól',
                tooltips: {
                    remove: 'Usuń',
                    edit:'Edytuj'
                },
                columns: {
                    name: 'Nazwa pola',
                    isRequired: 'Pole wymagane',
                    actions: 'Akcje',
                    minimum: 'Minimalna ilość znaków',
                    maximum: 'Maksymalna ilość znaków',
                    type: 'Typ pola'
                },
                controls: {
                    add: 'Dodaj pole formularza'
                },
                addEditModal: {
                    header: 'Pole formularza',
                    fields: {
                        name: 'Nazwa pola',
                        isRequired: 'Pole jest wymagane',
                        type: 'Typ pola',
                        minimumText: 'Minimalna ilość znaków',
                        maximumText: 'Maksymalna ilość znaków'
                    },
                    buttons: {
                        remove: 'Anuluj',
                        save: 'Zapisz'
                    }
                },
                deleteModal: {
                    header: 'Pytanie',
                    body: 'Czy na pewno chcesz usunąć to pole formularza?'
                }
            },
            basic: {
                title: 'Dane główne',
                nameFieldLabel: 'Nazwa kategorii:',
                descriptionFieldLabel: 'Opis kategorii:',
                showDescription: 'Pokazuj opis kategorii',
                externalName: 'Nazwa kategorii w ERP',
                showDescriptionForSubcategories: 'Pokazuj opis dla subkategorii',
                showArticles: 'Pokazuj w menu artykuły zamiast zdjęcia',
                inputs: {
                    showSelected: 'Pokaż zaznaczone'
                },
                status: {
                    tooltip: 'Aktywuj kategorię nadrzędną aby móc edytować status kategorii',
                    label: 'Status:'
                },
                statusErp: 'Status w synchronizatorze:',
                labels: {
                    availableInLanguages: 'Kategoria dostępna w językach'
                },
                languages: {
                    Pl: 'Polski',
                    En: 'Angielski',
                    Ru: 'Rosyjski',
                    Ua: 'Ukraiński',
                    De: 'Niemiecki',
                    Cz: 'Czeski',
                    Tr: 'Turecki',
                    It: 'Włoski',
                    Es: 'Hiszpański'
                },
                syncErp: 'Synchronizuj nazwę z ERP',
                statusLabels: {
                    editing: "Nieaktywna",
                    published: "Aktywna"
                },
                images: {
                    title: 'Obrazki',
                    buttons: {
                        upload: 'PRZEŚLIJ ZDJĘCIE',
                        remove: 'USUŃ ZDJĘCIE',
                        edit: 'PODMIEŃ ZDJĘCIE'
                    },
                    label: 'Zdjęcie'
                },
                alignment: {
                    left: 'Lewo',
                    center: 'Środek',
                    right: 'Prawo',
                    top: 'Do góry',
                    bottom: 'Do dołu'
                },
                presentation: {
                    cover: 'Wypełnienie obszaru',
                    contain: 'Dopasowanie do obszaru'
                },

                horizontalAlignmentLabel: 'Wyrównanie w poziomie',
                verticalAlignmentLabel: 'Wyrównanie w pionie',
                presentationLabel: 'Prezentacja',

                fullSizeHeader: 'Pozycjonowanie zdjęcia kategorii (pełny obszar menu)',
                fullSizeDescription: 'To ustawienie dotyczy sytuacji, kiedy kategoria nie ma zdefiniowanego opisu ani nie ma podkategorii. W takim wypadku zdjęcie kategorii jest wyświetlane na całym obszarze menu.',

                halfSizeHeader: 'Pozycjonowanie zdjęcia kategorii (prawa strona obszaru menu)',
                halfSizeDescription: 'To ustawienie dotyczy sytuacji, kiedy kategoria ma zdefiniowany opis lub posiada podkategorie. W takim wypadku zdjęcie kategorii jest wyświetlane po prawej stronie obszaru menu.',

                pictogramHeader: 'Pozycjonowanie piktogramu kategorii',
                pictogramDescription: 'To ustawienie dotyczy prezentacji kategorii na kafelkach nad wynikami wyszukiwania i w menu',
                fileSender: {
                    buttons: {
                        edit: 'PODMIEŃ Z BIBLIOTEKI MEDIÓW',
                        remove: 'USUŃ Z BIBLIOTEKI MEDIÓW',
                        upload: 'WGRAJ Z BIBLIOTEKI MEDIÓW'
                    }
                },
                showDescriptionAtBottom: 'Pokazuj opis kategorii na dole strony zamiast na górze'
            },
            metatags: {
                title: 'Meta-tagi'
            },
            attributes: {
                title: 'Cechy',
                table: {
                    columnNames: {
                        featureName: 'Nazwa cechy',
                        featureType: 'Typ cechy',
                        featurePresentation: 'Sposób prezentacji',
                        featureActions: 'Akcje'
                    },
                    tooltip: {
                        edit: 'Edytuj cechę',
                        remove: 'Usuń cechę'
                    },
                    deleteModal: {
                        header: 'Przypisana cecha:',
                        bodyStart: 'Czy napewno usunąć cechę',
                        bodyEnd: 'z listy cech przypisanych do tej kategorii?',
                        confirm: 'USUŃ',
                        cancel: 'WRÓĆ'
                    },
                    placeholder: 'Nie znaleziono cech przypisanych do kategorii'
                },
                controls: {
                    add: 'STWÓRZ CECHĘ DLA DANEJ KATEGORII',
                    addTooltip: 'Stworzenie nowej cechy możliwe tylko w przypadku wyłączonej integracji z systemem ERP',
                    remove: 'USUŃ ZAZNACZONE CECHY'
                },
                additionModal: {
                    header: 'Dodaj nową cechę',
                    body: 'Czy chcesz dodać nową cechę przypisaną do kategorii',
                    confirm: 'DODAJ',
                    cancel: 'WRÓĆ'
                },
                deletionModal: {
                    header: 'Usuń wszystkie wybrane cechy',
                    body: 'Czy chcesz usunąć wszystkie wybrane cechy z kategorii',
                    confirm: 'USUŃ',
                    cancel: 'WRÓĆ'
                }
            }
        }
    },
    dndSearch: {
        nameFiller: '[BRAK NAZWY]',
    },
    search: {
        options: {
            dropdown: 'Lista',
            tree: 'Drzewo',
        },
        label: 'Sposób prezentacji:'
    }
};

export default categories;