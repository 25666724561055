import React, { useState } from 'react';
import clsx from 'clsx';

import FontSelector from '../../features/fonts/FontSelector';
import MediaSelectionModal from '../../features/mediaLibrary/MediaSelectionModal';

import { Row, Col } from "reactstrap";

import AnimatedBannerSettingsModal from './AnimatedBannerSettingsModal';
import SimpleColorPicker from '../SimpleColorPicker';

const AnimatedBannerSettings = ({settings, selectedIndex, modifyProperties, handleSelect, handleAdd, handleDelete, handleCancel, handleSave, handleAfterEdit}) => {

    const [mediaLibraryOpened, setMediaLibraryOpened] = useState(false);
    const [elementIdx, setElementIdx] = useState(null);
    const [elementToEdit, setElementToEdit] = useState(null);

    const createRowStyle = (idx) => {
        return {
            outline: (selectedIndex === idx ? '1px dotted black' : 'none'),
            backgroundColor: (selectedIndex === idx ? '#FFFFD0' : 'transparent')
        };
    };

    const performEdit = (idx) => {
        handleSelect(idx);

        setElementIdx(idx);
        setElementToEdit(settings[idx]);
    };

    const performSubmit = (editedElement) => {
        settings[elementIdx] = editedElement;
        handleAfterEdit(editedElement.position);
    };

    const additionalFonts = [
        { id: 'wingdings-regular', fontFamily: 'wingdings', label: 'Wingdings' },
        { id: 'andale-mono-regular', fontFamily: '"andale mono", monospace', label: 'Andale Mono' },
        { id: 'arial-regular', fontFamily: 'arial, helvetica, sans-serif', label: 'Arial' },
        { id: 'arial-black-regular', fontFamily: '"arial black", sans-serif', label: 'Arial Black' },
        { id: 'book-antiqua-regular', fontFamily: '"book antiqua", palatino, serif', label: 'Book Antiqua' },
        { id: 'brush-script-mt-regular', fontFamily: 'brush script mt', label: 'Brush Script MT' },
        { id: 'comic-sans-ms-regular', fontFamily: '"comic sans ms", sans-serif', label: 'Comic Sans MS' },
        { id: 'courier-new-regular', fontFamily: '"courier new", courier, monospace', label: 'Courier New' },
        { id: 'garamond-regular', fontFamily: 'garamond', label: 'Garamond' },
        { id: 'georgia-palatino-regular', fontFamily: 'georgia, palatino, serif', label: 'Georgia' },
        { id: 'helvetica-arial-regular', fontFamily: 'helvetica, arial, sans-serif', label: 'Helvetica' },
        { id: 'impact-regular', fontFamily: 'impact, sans-serif', label: 'Impact' },
        { id: 'symbol-regular', fontFamily: 'symbol', label: 'Symbol' },
        { id: 'tahoma-regular', fontFamily: 'tahoma, arial, helvetica, sans-serif', label: 'Tahoma' },
        { id: 'terminal-monaco-monospace-regular', fontFamily: 'terminal, monaco, monospace', label: 'Terminal' },
        { id: 'times-new-roman-times-regular', fontFamily: '"times new roman", times, serif', label: 'Times New Roman' },
        { id: 'trebuchet-ms-geneva-regular', fontFamily: '"trebuchet ms", geneva, sans-serif', label: 'Trebuchet MS' },
        { id: 'verdana-geneva-regular', fontFamily: 'verdana, geneva, sans-serif', label: 'Verdana' },
        { id: 'webdings-regular', fontFamily: 'webdings', label: 'Webdings' },
        { id: 'wingdings-regular', fontFamily: 'wingdings', label: 'Wingdings' }
    ];

    const createFontStyle = (id,label) => {
        let fVariant = 'normal';
        let fWeight = '400';

        const variant = id.substring(id.lastIndexOf('-')+1);

        if (variant === 'regular') {
            fVariant = 'normal';
            fWeight = '400';
        } else if (variant === 'italic') {
            fVariant = 'italic';
            fWeight = '400';
        } else {
            fVariant = variant.includes('italic') ? 'italic' : 'normal';
            fWeight = variant.substring(0,3);
        }

        return {
            fontFamily: label,
            fontWeight: fWeight,
            fontVariant: fVariant
        };
    };

    return (
        <div>
            <table style={{width: '100%', margin: '10px'}}>
                <thead>
                    <tr>
                        <th></th>
                        <th><center>Treść</center></th>
                        <th><center>Krój czcionki</center></th>
                        <th><center>Styl czcionki</center></th>
                        <th><center>Kolor czcionki</center></th>
                        <th><center>Kolor tła</center></th>
                        <th><center>Akcje</center></th>
                    </tr>
                </thead>
                <tbody>
                    {settings.map((item, i) =>
                        <React.Fragment key={i}>
                        <tr style={createRowStyle(i)}>
                            <td>
                                <center>
                                    <i className={clsx('fa fa-search')} style={{fontSize: '24px', color: (selectedIndex === i ? 'green' : '#E0E0E0')}} onClick={() => handleSelect(selectedIndex === i ? -1 : i)}/>
                                </center>
                            </td>
                            <td className="p-2">
                                <input disabled={item.type !== 'text'} className="form-control" type="text" style={{width: '100%'}} value={item.properties.content} onChange={(e) => modifyProperties(i, { content: e.target?.value ?? null })}/>
                            </td>
                            <td className="p-2">
                                {item.type === 'text' &&
                                    <center>
                                        <FontSelector
                                            selectedFont={item.properties.fontFamily}
                                            setSelectedFont={(e) => { modifyProperties(i, createFontStyle(e.id, e.label));}}
                                            additionalFonts={additionalFonts}
                                        />
                                    </center>
                                }
                            </td>
                            <td>
                                {item.type === 'text' &&
                                    <center>
                                        <button type="button" className={clsx("d-md mx-2 btn", (item.properties.fontWeight === 'bold' ? 'btn-primary' : 'btn-default'))} onClick={() => modifyProperties(i, { fontWeight: (item.properties.fontWeight === 'bold' ? 'normal' : 'bold') })}>
                                            <i className={clsx('fa fa-bold')} style={{fontSize: '20px', color: (item.properties.fontWeight === 'bold' ? 'black' : '#D0D0D0')}} ></i>
                                        </button>
                                        <button type="button" className={clsx("d-md mx-2 btn", (item.properties.fontStyle === 'italic' ? 'btn-primary' : 'btn-default'))} onClick={() => modifyProperties(i, { fontStyle: (item.properties.fontStyle === 'italic' ? 'normal' : 'italic') })}>
                                            <i className={clsx('fa fa-italic')} style={{fontSize: '20px', color: (item.properties.fontStyle === 'italic' ? 'black' : '#D0D0D0')}}></i>
                                        </button>
                                    </center>
                                }
                            </td>
                            <td>
                                {item.type === 'text' &&
                                    <center>
                                        <SimpleColorPicker value={item.properties.color} onSelect={(v) => modifyProperties(i, { color: v ?? null })}/>
                                    </center>
                                }
                            </td>
                            <td>
                                {item.type === 'text' &&
                                    <center>
                                        <SimpleColorPicker value={item.properties.backgroundColor} onSelect={(v) => modifyProperties(i, { backgroundColor: v ?? null })}/>
                                    </center>
                                }
                            </td>
                            <td>
                                <center>
                                    <i className={clsx('fa fa-edit')} style={{margin: '0px 5px', fontSize: '20px', color: 'lightgreen'}} onClick={() => { performEdit(i); }} />
                                    <i className={clsx('fa fa-trash')} style={{margin: '0px 5px',fontSize: '20px', color: 'red'}} onClick={() => handleDelete(i)}/>
                                </center>
                            </td>
                        </tr>
                        </React.Fragment>
                    )}
                </tbody>
            </table>

            <Row>
                <Col className="col-6">
                    <button type="button" className="d-none d-lg-block mx-2 btn btn-secondary" onClick={() => handleCancel()}>
                        <i className="mr-2 fa fa-ban"></i>
                        <span>Anuluj</span>
                    </button>
                </Col>
                <Col className="col-6" style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <button type="button" className="d-none d-lg-block mx-2 btn btn-success" onClick={() => handleSave()}>
                        <i className="mr-2 fa fa-save"></i>
                        <span>Zapisz zmiany</span>
                    </button>
                    <button type="button" className="d-none d-lg-block mx-2 btn btn-primary" onClick={() => handleAdd('text')}>
                        <i className="mr-2 fa fa-plus"></i>
                        <span>Dodaj tekst</span>
                    </button>
                    <button type="button" className="d-none d-lg-block mx-2 btn btn-primary" onClick={() => setMediaLibraryOpened(true)}>
                        <i className="mr-2 fa fa-plus"></i>
                        <span>Dodaj obrazek / svg</span>
                    </button>
                </Col>
            </Row>
            {
                elementToEdit &&
                <AnimatedBannerSettingsModal
                    isModalOpen={true}
                    setIsModalOpen={() => { setElementToEdit(null); }}
                    element={elementToEdit}
                    elements={settings}
                    onSubmit={performSubmit}
                />
            }
            <MediaSelectionModal defaultItemsChecked={[]} isOpen={mediaLibraryOpened} setIsOpen={setMediaLibraryOpened} onSubmit={(file) => { handleAdd('image', file); setMediaLibraryOpened(false); }} />
        </div>
    );
};

export default AnimatedBannerSettings;