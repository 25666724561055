import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Button, Form, Modal as CoreUiModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import FormFieldTranslations from '../../../../../components/formFields/FormFieldTranslations';
import FormFieldWithCheckbox from '../../../../../components/formFields/FormFieldWithCheckbox';
import FormFieldStatus from '../../../../../components/formFields/FormFieldStatus';
import FormFieldTextOrNumber from '../../../../../components/formFields/FormFieldTextOrNumber';

import { withFormUtils } from '../../../../../hoc/withFormUtils';
import { getCategoryFormField, addEditCategoryFormField } from '../../../../../redux/methods/categories';

import useFetch from '../../../../../hooks/useFetch';

import styles from './index.module.scss';
import clsx from 'clsx';

const EditModal = ({ utils, selectedId, setSelectedId, translations, selectedCategoryId, getCategoryFormField, addEditCategoryFormField }) => {

    const [ data ] = useFetch(getCategoryFormField, { categoryId: selectedCategoryId, id: selectedId });

    const config = { translations: ['name'] };

    const handleOnSubmit = (formData) => {
        addEditCategoryFormField(utils.generateFormToSubmitValues(formData, config));
        setSelectedId(null);
    };

    const handleOnCancel = () => {
        setSelectedId(null);
    };

    const types = [
        {
            id: 'text',
            label: 'Tekst',
            color: 'primary',
            labelForBadge: 'Tekst',
        },
        {
            id: 'number',
            label: 'Liczba',
            color: 'primary',
            labelForBadge: 'Liczba',
        }
    ];

    return data ? (
        <CoreUiModal className="wide" isOpen={true}>
            <FinalForm
                initialValues={utils.generateFormInitialValues(data, config)}
                onSubmit={ handleOnSubmit }
                render={({ handleSubmit, form, values }) => {

                    const selectedType = types.find(type => type.id === values.type);

                    return (
                        <div>
                            <ModalHeader>{translations.addEditModal.header}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleSubmit}>
                                    <div>
                                        <div className='my-2'>
                                            <FormFieldTranslations
                                                label={translations.addEditModal.fields.name}
                                                form={form}
                                                modalHeader={translations.addEditModal.fields.name}
                                                propertyName='name'
                                                currentIso={utils.currentLanguage()}
                                                className='w-100'
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <FormFieldWithCheckbox
                                                name='isRequired'
                                                label={translations.addEditModal.fields.isRequired}
                                            />
                                        </div>
                                        <div className='mt-5 mb-2'>
                                            <FormFieldStatus
                                                name='type'
                                                statuses={types}
                                                selectedStatus={selectedType}
                                                label={translations.addEditModal.fields.type}
                                                className='w-100'
                                            />
                                        </div>
                                        <Row>
                                            <Col className='my-2'>
                                                <FormFieldTextOrNumber
                                                    name='minimum'
                                                    label={translations.addEditModal.fields.minimumText}
                                                    className='w-100 mr-md-3'
                                                />
                                            </Col>
                                            <Col className='my-2'>
                                                <FormFieldTextOrNumber
                                                    name='maximum'
                                                    label={translations.addEditModal.fields.maximumText}
                                                    className='w-100 mr-md-3'
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <div className={clsx("d-flex justify-content-between", styles.modalFooterButtons)}>
                                    <Button onClick={handleOnCancel} color='danger'>
                                        <i className="fa fa-remove mr-2"></i>
                                        <span>{translations.addEditModal.buttons.remove}</span>
                                    </Button>
                                    <Button onClick={handleSubmit} color='success'>
                                        <i className="fa fa-save mr-2"></i>
                                        <span>{translations.addEditModal.buttons.save}</span>
                                    </Button>
                                </div>
                            </ModalFooter>
                        </div>
                    );
                }}
            />
        </CoreUiModal>
    ) : null;
};

const mapDispatch = {
    getCategoryFormField,
    addEditCategoryFormField
};

export default withFormUtils(connect(null, mapDispatch)(EditModal));