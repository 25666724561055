import getConfig from '../../getConfig';

const shopUrl = getConfig().NEXT_SHOP_EXTERNAL_URL;

export const generateEditLinkForTemplate = id => `/visualPresentation/layout/edit?templateID=${id}`;

export const generateShopPreviewLink = (currentLanguage, layoutId, layoutTemplateId, type) => {
    return `${shopUrl}/${currentLanguage}/?previewLayoutId=${layoutId}&previewLayoutTemplateId=${layoutTemplateId}&previewType=${type}`;
};

export const generateEditLinkForButton = id => `/visualPresentation/buttons/edit?buttonID=${id}`;