import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import clsx from 'clsx';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectTranslatedCurrentCategory, selectIsCategoryTreePresent, selectIsERPCategoriesIsUsage } from '../../redux/selectors/categories';
import { getCategoryTree, getCategoryBasicData } from '../../redux/methods/categories';
import { selectStoreCurrentLanguage } from '../../redux/selectors/storeLocale';
import useFetch from '../../hooks/useFetch';
import { getTranslationForIso } from '../../utils/shared';

import { Alert } from 'reactstrap';
import SettingsTabulator from '../../components/layouts/SettingsTabulator';
import CategoryEditBasicSection from '../../features/categories/editTabs/CategoryEditBasicSection';
import CategoryEditFormFields from '../../features/categories/editTabs/CategoryEditFormFields';

import MetatagsSection from '../../views/extrasSettings/MetatagsSection';
import CategoryEditAssignedFeaturesSection from '../../features/categories/editTabs/CategoryEditAssignedFeaturesSection';

import { flattenedTranslatedCategoryTreeItemPropTypes } from '../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    location: PropTypes.object,
    translatedCurrentCategory: flattenedTranslatedCategoryTreeItemPropTypes,
    isCategoryTreePresent: PropTypes.bool,
    isCategoryToEditInitialized: PropTypes.bool,
    getCategoryTree: PropTypes.func,
    initializeCategoryEditCache: PropTypes.func,
    isERPCategoriesInUsage: PropTypes.bool,
};

const CategoriesEdit = ({ location, translatedCurrentCategory, isCategoryTreePresent, storeCurrentLanguage,
    forceRefetch, getCategoryTree, getCategoryBasicData, isERPCategoriesInUsage }) => {
    const intl = useIntl();
    const translations = intl.messages.categories.edit;

    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);
    const chosenCategoryId = query.get('catID');

    const [ isAlertOpen, setIsAlertOpen ] = useState(false);

    const [ data ] = useFetch(getCategoryBasicData, chosenCategoryId, [ chosenCategoryId ], forceRefetch);

    const handleOpenAlert = () => {
        if(isERPCategoriesInUsage) {
            setIsAlertOpen(true);
            setTimeout(() => {
                setIsAlertOpen(false);
            }, 4000);
        }
        else {
            return null;
        }
    };

    useEffect(() => {
        if (!isCategoryTreePresent) {
            getCategoryTree();
        }
    }, []); // eslint-disable-line

    /**
     * If no category is selected for edition, redirect user back to category list
     */

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case "catEditTab-basic":
                return <CategoryEditBasicSection data={data} categoryId={parseInt(chosenCategoryId)} isERPCategoriesInUsage={isERPCategoriesInUsage}/>;
            case "catEditTab-metatags":
                return <MetatagsSection forcedTag="CategorySearch" forcedPageId={chosenCategoryId}/>;
            case "catEditTab-attributes":
                return <CategoryEditAssignedFeaturesSection categoryId={chosenCategoryId} isERPCategoriesInUsage={isERPCategoriesInUsage} />;
            case "catEditTab-formFields":
                return <CategoryEditFormFields categoryId={chosenCategoryId} />;
            default:
                return null;
        }
    };

    return (chosenCategoryId)
        ? (
            (data && isCategoryTreePresent) ? (
                <React.Fragment>
                    <Alert
                        className={clsx('fixed-top', styles.alert)}
                        color='danger'
                        isOpen={isAlertOpen}
                        toggle={() => setIsAlertOpen(false)}
                    >
                        <span>{translations.erpConnectionAlert}</span>
                    </Alert>
                    <SettingsTabulator
                        title={{
                            label: getTranslationForIso(data.name, storeCurrentLanguage),
                            isFilled: translatedCurrentCategory.name ? false : true,
                            iconClassName: "fa fa-edit text-primary ml-3"
                        }}
                        tabulatorHeader={{
                            label: translations.tabSelectorTitle,
                            iconClassName: "fa fa-align-justify"
                        }}
                        refreshTabComponent={refreshTabComponent}
                        tabs={[
                            {
                                id: "catEditTab-basic",
                                header:  {
                                    label: translations.tabs.basic.title,
                                    iconClassName: "fa fa-tasks"
                                }
                            },
                            {
                                id: "catEditTab-metatags",
                                header:  {
                                    label: translations.tabs.metatags.title,
                                    iconClassName: "fa fa-code"
                                }
                            },
                            {
                                id: "catEditTab-attributes",
                                header:  {
                                    label: translations.tabs.attributes.title,
                                    iconClassName: "fa fa-tags"
                                },
                                disabled: isERPCategoriesInUsage,
                                onClick: handleOpenAlert
                            },
                            {
                                id: "catEditTab-formFields",
                                header:  {
                                    label: translations.tabs.formfields.title,
                                    iconClassName: "fa fa-wpforms"
                                }
                            }
                        ]}
                    />
                </React.Fragment>
            ) : null
        ) : (
            <Redirect to="/categories"/>
        );
};

CategoriesEdit.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => ({
    translatedCurrentCategory: selectTranslatedCurrentCategory(ownProps.location.search)(state),
    isCategoryTreePresent: selectIsCategoryTreePresent(state),
    forceRefetch: state.refetchers.categories,
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
    isERPCategoriesInUsage: selectIsERPCategoriesIsUsage(state)
});

const mapDispatch = {
    getCategoryTree,
    getCategoryBasicData
};

export default withRouter(connect(mapStateToProps, mapDispatch)(CategoriesEdit));