const uploadFile = {
    buttons: {
        upload: '[EN] PRZEŚLIJ PLIK',
        remove: '[EN] USUŃ PLIK',
        edit: '[EN] PODMIEŃ PLIK',
        closeModal: '[EN] ZAMKNIJ',
    },
    errorHeader: '[EN] Wystąpił błąd podczas przesyłania pliku',
    errorBody: '[EN] Dopuszczalne rozszerzenia to ',
    errorBodySize: '[EN] Dopuszczalny rozmiar pliku to ',
    sizeLabel: '[EN] Maksymalny rozmiar pliku: ',
    extLabel: '[EN] Dopuszczalne rozszerzenia: ',
    preferWidth: '[EN] Preferowana szerokość: ',
    preferHeight: '[EN] Preferowana wysokość: ',
    deleteModal: {
        delete: '[EN] USUŃ',
        cancel: '[EN] WRÓĆ',
        body: '[EN] Czy napewno chcesz usunąć plik?',
        header: '[EN] Usuń plik'
    },
    graphicGuides: {
        default: '[EN] Preferowana szerokość zdjęcia to:',
    }
};

export default uploadFile;