/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import TableGenerator from '../../generators/TableGenerator';

import { productListItemPropTypes, stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    services: PropTypes.arrayOf(productListItemPropTypes),
    history: PropTypes.object,
    deleteAction: PropTypes.func,
    checkedServicesIds: PropTypes.arrayOf(stringOrNumberPropTypes),
    setCheckedServicesIds: PropTypes.func,
};

const ServiceTable = ({
    services,    
    performEditAction,
    performDeleteAction,
    updateAction,
    currentPage,
    pageSize,
    performPageSizeSelect,
    translations
}) => {
    const defaultActionHandler = id => () => {
        performEditAction(id);
    };

    const editAction = id => () => {
        performEditAction(id);
    };

    const deleteAction = id => () => {
        performDeleteAction(id);
    };

    const handleUpdateStatus = (id, status) => {
        const objectToSend = {
            productId: [ id ],
            status: status ? 1 : 0,
        };
        updateAction(objectToSend);
    };

    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            pageSize={pageSize}
            currentPage={currentPage}            
            expectedActions={['edit', 'delete']}
            items={services}
            actions={{ editAction, deleteAction }}
            translations={translations}
            defaultAction={defaultActionHandler}            
            customIdSelector={'productId'}
            columns={[
                {
                    header: translations.headers.name,
                    value: 'productTranslatedName',
                    additionalStyle: styles.nameCell,
                    filler: translations.fallbacks.name
                },
                {
                    header: translations.headers.active,
                    type: 'switchWithAction',
                    onSwitch: handleUpdateStatus,
                    value: 'status',
                },
                {
                    header: translations.headers.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

ServiceTable.propTypes = propTypes;

export default ServiceTable;