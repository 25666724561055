import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateDiscountGroups } from '../../../../../redux/methods/discounts';

import SectionAsideButtons from '../../../../../components/SectionAsideButtons';
import Paginator from '../../../../../components/Paginator';
import GroupsTable from '../../../../../components/tables/GroupsTable';
import GroupsAssigner from '../../../../groups/GroupsAssigner';

import { stringOrNumberPropTypes, groupPropTypes } from '../../../../../propTypes';

import { withFormUtils } from '../../../../../hoc/withFormUtils';

const propTypes = {
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    getDiscountGroups: PropTypes.func,
    updateDiscountGroups: PropTypes.func,
    discountToEditId: stringOrNumberPropTypes,
    groups: PropTypes.arrayOf(groupPropTypes),
    discountGroups: PropTypes.arrayOf(groupPropTypes),
    setCurrentPage: PropTypes.func,
};

const GroupsSection = ({ utils, totalPages, currentPage, setCurrentPage, discountToEditId, discountGroups, updateDiscountGroups }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.groupsUsers;

    const [checkedGroupsIds, setCheckedGroupsIds] = useState([]);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

    useEffect(() => {
        return () => {
            setCheckedGroupsIds([]);
            setIsAssignModalOpen(false);
        };
    }, [ discountGroups ]);

    const handleUnassignGroup = id => {
        setCurrentPage(0);
        updateDiscountGroups(discountToEditId, [id], true);
    };

    const handleAssignGroups = groupsToAssignIds => {
        setCurrentPage(0);
        updateDiscountGroups(discountToEditId, groupsToAssignIds, false);
    };

    const handleOpenAssignModal = () => {
        setIsAssignModalOpen(true);
    };

    const handleOpenUnassignModal = () => {
        utils.confirm(
            () => {
                updateDiscountGroups(discountToEditId, checkedGroupsIds, true);
                setCurrentPage(0);
            },
            translations.deleteModalGroups.header,
            translations.deleteModalGroups.body
        );
    };

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const toggleCheckboxes = isChecked => {
        if(isChecked){
            setCheckedGroupsIds([]);
        } else {
            setCheckedGroupsIds(discountGroups.map(({id}) => id));
        }
    };

    const groupsSectionButtons = {
        remove: {
            label: translations.buttons.groups.remove,
            action: handleOpenUnassignModal,
            isDisabled: checkedGroupsIds.length === 0
        },
        add: {
            label: translations.buttons.groups.add,
            action: handleOpenAssignModal,
        }
    };

    return (
        <React.Fragment>
            <span className='text-success font-weight-bold d-block mb-2 mt-3'>{translations.headers.regions}</span>
                <GroupsTable
                    setCheckedCheckboxIds={setCheckedGroupsIds}
                    checkedCheckboxIds={checkedGroupsIds}
                    translations={translations}
                    headers={translations.groupTable.headers}
                    isGreenOnHover={true}
                    removeAction={handleUnassignGroup}
                    withCheckbox={true}
                    groups={discountGroups}
                    toggleCheckboxes={toggleCheckboxes}
                />
            {
                totalPages > 1 &&
                    <Paginator
                        totalPages={totalPages}
                        currentPage={currentPage + 1}
                        onPageChange={handlePageChange}
                    />
            }
            <SectionAsideButtons
                buttons={groupsSectionButtons}
            />
            <hr className='border-danger d-block my-5'/>
            <GroupsAssigner
                isOpen={isAssignModalOpen}
                setIsOpen={setIsAssignModalOpen}
                onConfirm={handleAssignGroups}
            />
        </React.Fragment>
    );
};

GroupsSection.propTypes = propTypes;

const mapStateToProps = state => ({
    discountToEditId: state.discounts.discountToEdit.id,
});

const mapDispatch = {
    updateDiscountGroups,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(GroupsSection));