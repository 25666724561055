import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import CategorySelect from '../../CategorySelect';
import Modal from '../Modal';

const propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    categoryTree: PropTypes.array,
    currentLanguage: PropTypes.string,
    translations: PropTypes.object,
    addAction: PropTypes.func,
};

const CategoryMappingModal = ({
    isOpen,
    setIsOpen,
    categoryTree,
    currentLanguage,
    translations,
    externalCategories,
    addAction,
}) => {

    // eslint-disable-next-line no-unused-vars
    const [ selectedShopCategoryId, setSelectedShopCategoryId] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [ selectedToAssignCategoryId, setSelectedToAssignCategoryId ] = useState(null);

    const handleConfirm = () => {
        addAction(selectedShopCategoryId, selectedToAssignCategoryId);
    };

    const getTranslation = (node) => {
        for(const n in node){
            if (node[n].iso.toLowerCase() === currentLanguage){
                return node[n].value;
            }
        }
        return null;
    };

    const prepareCategories = (nodes, path, level) => {
        return nodes.map(n => {
            const translatedName = getTranslation(n.category.name);
            return {
                id: n.category.id,
                name: translatedName,
                expanded: false,
                selected: false,
                level: level,
                path: (path === '' ? '' : path + ' => ') + translatedName,
                children: n.subItems && Array.isArray(n.subItems) && n.subItems.length > 0
                    ? prepareCategories(n.subItems, (path === '' ? '' : path + ' => ') + translatedName, level + 1)
                    : null
            };
        });
    };

    return (
        <Modal
            className={clsx('text-center modal-xl')}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            header={translations.modalHeader}
            onConfirm={handleConfirm}
            body={
                <React.Fragment>
                    <div className='d-flex'>
                        <div className={clsx('mx-auto my-3')}>
                            <span>{translations.shopCategories}</span>
                            <CategorySelect
                                categories={prepareCategories(categoryTree, '', 0)}
                                handleSelectionChanged={setSelectedShopCategoryId}
                            />
                        </div>
                        <div className={clsx('mx-auto my-3')}>
                            <span>{translations.outsourceSystemCategories}</span>
                            <CategorySelect
                                categories={prepareCategories(externalCategories, '', 0)}
                                handleSelectionChanged={setSelectedToAssignCategoryId}
                            />
                        </div>
                    </div>
                </React.Fragment>
            }
            buttons={{
                confirm: {
                    label: translations.buttons.assignCategory,
                    color: 'primary'
                },
                cancel: {
                    label: translations.buttons.cancel,
                    color: 'secondary'
                }
            }}
        />
    );

};

CategoryMappingModal.propTypes = propTypes;

export default CategoryMappingModal;