const global = {
    lastSync: {
        label: 'Ostatnia synchronizacja: '
    },
    metaTags: 'Metatagi',
    codeEnabled: 'Kod aktywny',
    head: 'Nagłówek',
    footer: 'Stopka',
    scriptFormatAlert: 'Skrypt musi znajdować się pomiędzy znakami <script></script>'
};

export default global;