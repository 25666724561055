import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getTaxRates } from '../../../redux/methods/extrasSettings';

import useFetch from '../../../hooks/useFetch';

import ContainerWithCard from '../../../components/ContainerWithCard';

import { withFormUtils } from '../../../hoc/withFormUtils';
import TaxRatesTable from '../../../components/tables/TaxRatesTable';

const propTypes = {
    getTaxRates: PropTypes.func,
};

const TaxRates = ({ getTaxRates }) => {
    const intl = useIntl();
    const translations = intl.messages.taxRates;

    const [ taxRates ] = useFetch(getTaxRates);

    return taxRates ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-money'>
            <TaxRatesTable taxRates={taxRates}/>
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

TaxRates.propTypes = propTypes;

const mapDispatch = {
    getTaxRates
};

export default withFormUtils(connect(null, mapDispatch)(TaxRates));