import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import DefaultSpinner from '../../components/loaders/DefaultSpinner';
import Login from '../../views/Login';
import ResetPassword from '../../views/ResetPassword';
import SetNewPassword from '../../views/ResetPassword/SetNewPassword';

const propTypes = {
    isUnauthorized: PropTypes.bool,
};

const AuthRouter = ({ isUnauthorized }) => {
    return (
        <Suspense fallback={<DefaultSpinner isLoading={true}/>}>
            <Switch>
                <Route exact path="/login" name="Sign In Page" render={props => <Login {...props}/>}/>
                <Route exact path="/reset" name="Reset Password" render={props => <ResetPassword {...props}/>}/>
                <Route exact path='/newPassword' name="Set New Password" render={props => <SetNewPassword {...props} />} />
                <Redirect from={window.location.pathname} to={isUnauthorized ? "/unauthorized" : "/login?target="+encodeURIComponent(window.location.pathname + window.location.search)}/>
            </Switch>
        </Suspense>
    );
};

AuthRouter.propTypes = propTypes;

const mapStateToProps = state => ({
    isUnauthorized: state.auth.isUnauthorized
});

const mapDispatch = {

};

export default connect(mapStateToProps, mapDispatch)(AuthRouter);