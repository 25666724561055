/* eslint-disable */
import React from 'react';
import { useIntl } from 'react-intl';

import { withFormUtils } from '../../../hoc/withFormUtils';

import SettingsTabulator from '../../../components/layouts/SettingsTabulator';

import GoogleAnaliticsSection from '../../../features/integrations/google/editTabs/GoogleAnalyticsSection';

import { formUtilsHocPropTypes } from '../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes,
};

const Google = ({
    utils,
}) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.google;

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'Google-settings':
                return <GoogleAnaliticsSection translations={translations} />;
            default:
                return null;
        }
    };

    return (
        <SettingsTabulator
                title={{
                    label: translations.title
                }}
                tabulatorHeader={{
                    label: translations.title,
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: `Google-settings`,
                        header: {
                            label: translations.tabs.googleAnalytics,
                            iconClassName: 'fa fa-google'
                        }
                    },
                ]}
        />
    );
};

Google.propTypes = propTypes;

export default withFormUtils(Google);