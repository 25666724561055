import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { flattenedTranslatedCategoryTreeItemPropTypes } from '../../../../propTypes';

import styles from './index.module.scss';
import CategorySelectModal from '../../../../components/modals/CategorySelectModal';

const propTypes = {
    currentLanguage: PropTypes.string,
    selectCategoryLabel: PropTypes.string,
    setSelectedCategoryId: PropTypes.func,
    categoryTree: PropTypes.arrayOf(flattenedTranslatedCategoryTreeItemPropTypes)
};

const CategoryTypeController = ({
    selectCategoryLabel,
    setSelectedCategoryId,
    flattenedCategoryTree,
    selectedCategoryId,
    initialySelectedId,
    showAll = false
}) => {

    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const handleCategorySelection = id => {
        setSelectedCategoryId(id);
    };

    const openSelection = e => {
        e.preventDefault();

        setIsModalOpen(true);
    };

    const selectedLabel = selectedCategoryId === 0
        ? 'Wszystko'
        : flattenedCategoryTree
            ? flattenedCategoryTree.find(({ id }) => id === (selectedCategoryId ? selectedCategoryId : initialySelectedId))?.path
            : null;

    if(initialySelectedId !== null && typeof(initialySelectedId) !== 'undefined' && selectedCategoryId === null){
        setSelectedCategoryId(initialySelectedId);
    }

    return (
        <div className='my-3'>
            <button onClick={openSelection} className={styles.categorySelector}>
                <div>
                    <span>{selectedLabel || selectCategoryLabel}</span>
                    <i className="fa fa-search"></i>
                </div>
            </button>
            <CategorySelectModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                categorySelect={handleCategorySelection}
                showAll={showAll}
            />
        </div>
    );
};

CategoryTypeController.propTypes = propTypes;

export default CategoryTypeController;