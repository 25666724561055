import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getGroups } from '../../../redux/methods/groups';
import useFetch from '../../../hooks/useFetch';

import Assigner from '../../../components/Assigner';

import { groupsSearchConfig } from '../../../config/searchConfigs';

const propTypes = {
    getGroups: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onConfirm: PropTypes.func,
    isLoading: PropTypes.bool,
};

const GroupsAssigner = ({ getGroups, isOpen, setIsOpen, onConfirm, isLoading }) => {
    const intl = useIntl();
    const translations = intl.messages.groups.assigner;

    const [ groups ] = useFetch(getGroups, groupsSearchConfig);

    const mapList = list => list.map( ({ id, name }) => ({ id: id, label: name }));

    return groups ? (
        <Assigner
            lists={{
                left: mapList(groups),
                right: []
            }}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onConfirm={onConfirm}
            isLoading={isLoading}
            translations={{
                header: translations.header,
                buttons: {
                    cancel: {
                        color: 'danger',
                        label: translations.buttons.cancel.label
                    },
                    confirm: {
                        color: 'success',
                        label: translations.buttons.confirm.label
                    },
                },
                toAssign: translations.toAssign,
                assigned: translations.assigned,
            }}
        />
    ) : null;
};

GroupsAssigner.propTypes = propTypes;

const mapStateToProps = () => ({

});

const mapDispatch = {
    getGroups,
};

export default connect(mapStateToProps, mapDispatch)(GroupsAssigner);
