import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Label, Input, Button } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    emailList: PropTypes.arrayOf(PropTypes.string),
    setEmailList: PropTypes.func,
    emailLabel: PropTypes.string,
    buttonsLabels: PropTypes.shape({
        addEmail: PropTypes.string,
        removeEmail: PropTypes.string,
    })
};

const EmailListController = ({
    emailList,
    setEmailList,
    emailLabel,
    buttonsLabels
}) => {
    const handleSetEmail = (event, index) => {
        const listToEdit = [ ...emailList ];
        listToEdit[index] = event.target.value;

        setEmailList(listToEdit);
    };

    const handleAddEmail = () => {
        setEmailList(prevState => [...prevState, '']);
    };

    const handleRemoveEmail = (index) => () => {
        setEmailList(prevState => prevState.filter((elem, elemIndex) => elemIndex !== index));
    };

    return (
        <div className={clsx('my-3', styles.container)}>
            {
                emailList.map(((email, index) => (
                    <div key={`emailEditionField-${index}`} className={clsx('my-2')}>
                        <Label for={`emailField${index}`}>{emailLabel} {index + 1}</Label>
                        <div className='d-flex'>
                            <Input type="email" id={`emailField${index}`} value={email} onChange={event => handleSetEmail(event, index)} />
                            <Button
                                color='danger'
                                onClick={handleRemoveEmail(index)}
                            >
                                <span>{buttonsLabels.removeEmail}</span>
                            </Button>
                        </div>
                    </div>
                )))
            }
            <Button
                className='mt-3 ml-auto'
                color='primary'
                onClick={handleAddEmail}
            >
                <span>{buttonsLabels.addEmail}</span>
            </Button>
        </div>
    );
};

EmailListController.propTypes = propTypes;

export default EmailListController;