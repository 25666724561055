const mediaLibrary = {
    title: '[EN] Biblioteka mediów',
    search: '[EN] Wyszukaj po nazwie pliku...',
    firstBreadcump: '...',
    blueFolderWarning: 'W tym folderze nie można umieszczać plików przesłanych za pośrednictwem biblioteki mediów. Prezentowane są tutaj pliki przesłane przy pomocy formularzy znajdujących się w innych częściach panelu administracyjnego.',
    buttons: {
        add: '[EN] DODAJ PLIKI',
        mode: {
            edit: '[EN] WŁĄCZ TRYB ZAZNACZANIA',
            normal: '[EN] WŁĄCZ TRYB NORMALNY',
        },
        selectAll: '[EN] Zaznacz wszystkie',
        unselectAll: '[EN] Odznacz wszystkie',
        deleteAll: '[EN] USUŃ ZAZNACZONE',
    },
    modal: {
        header: '[EN] Szczegóły załączonego pliku',
        date: '[EN] Data wysłania na serwer',
        author: '[EN] Kto wgrał',
        type: '[EN] Typ pliku',
        size: '[EN] Wielkość',
        resolution: '[EN] Wymiary',
        oryginalName: '[EN] Nazwa oryginalna',
        folder: '[EN] Ścieżka',
        remove: '[EN] USUŃ NA ZAWSZE',
        form: {
            name: '[EN] Nazwa',
            alternativeText: '[EN] Tekst alternatywny',
            title: '[EN] Tytuł',
            description: '[EN] Opis',
            save: '[EN] ZAPISZ',
            html: '[EN] Html',
            link: '[EN] Link do pliku',
        },
    },
    searchForm: {
        filters: {
            all: '[EN] Wszystkie',
            images: '[EN] Obrazki',
            video: '[EN] Filmy',
            other: '[EN] Inne',
        },
        fileName: '[EN] Nazwa pliku',
        mediaType: '[EN] Typ pliku',
        buttons: {
            submit: '[EN] SZUKAJ',
            reset: '[EN] RESETUJ'
        }
    }
};

export default mediaLibrary;