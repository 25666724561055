const quantity = {
    view: {
        title: 'Prezentacja artykułów > Stan artykułów',
        actions: {
            save: 'ZAPISZ ZMIANY',
        },
        tooltip: {
            title: 'Zakresy',
            none: 'Brak',
            little: 'Mała ilość',
            medium: 'Średnia ilość',
            many: 'Duża ilość',
        },
        labels: {
            type: 'Typ prezentacji',
            little: 'Mała ilość od',
            medium: 'Średnia ilość od',
            many: 'Duża ilość od'
        },
        types: {
            quantity: 'Faktyczna ilość',
            none: 'Brak informacji o dostępności',
            available: 'Dostępny / niedostępny',
            graphic: 'Forma graficzna',
        },
        productTypeSetting: {
            header: 'Ustawienia typów produktów',
            productType: 'Typ produktu',
            includeMaskedQuantity: 'Wymuś stan maskowany',
            includeMinQuantity: 'Wymuś ilość minimalną',
            includeReservations: 'Odlicz rezerwacje',
            explanation: '* Wszystkie powyższe ustawienia mają zastosowanie tylko wtedy, gdy w określonym artykule nie zdefiniowano odpowiadających im wartości. Jeśli któremukolwiek z powyższych ustawień zdefiniowano w artykule odpowiadającą mu wartość (sekcja Artykuły - Lista - Edytuj artykuł - Dane podstawowe - Stan artykułu), ma ona pierwszeństwo. Dotyczy to zarówno pól liczbowych jak i przełączników.'
        }
    },
};

export default quantity;