import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    global: false
};

const loaders = createSlice({
    name: 'loaders',
    initialState,
    reducers: {
        setIsLoading(state){
            state.global = true;
        },
        setIsLoaded(state){
            state.global = false;
        }
    }
});

export const { setIsLoading, setIsLoaded } = loaders.actions;

export default loaders.reducer;