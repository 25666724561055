import React from 'react';
import PropTypes from 'prop-types';

import { coreUiColorsPropTypes } from '../../../propTypes';

import { Button, Modal as CoreUiModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const propTypes = {
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    header: PropTypes.string,
    body: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
    buttons: PropTypes.shape({
        confirm: PropTypes.shape({
            color: coreUiColorsPropTypes,
            label: PropTypes.string,
            disabled: PropTypes.bool
        }),
        cancel: PropTypes.shape({
            color: coreUiColorsPropTypes,
            label: PropTypes.string,
            disabled: PropTypes.bool
        }),
    }),
    onConfirm: PropTypes.func,
    setIsOpen: PropTypes.func,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl'])
};

const Modal = ({ isOpen, className, header, body, buttons, setIsOpen, onConfirm, size = 'md'}) => {
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <CoreUiModal
            size={size}
            isOpen={isOpen}
            toggle={handleToggle}
            className={className}
        >
            <ModalHeader toggle={handleToggle}>{header}</ModalHeader>
            <ModalBody>{body}</ModalBody>
            <ModalFooter>
                {buttons.confirm && <Button disabled={buttons.confirm.disabled} color={buttons.confirm.color} onClick={onConfirm}>{buttons.confirm.label}</Button>}
                {buttons.cancel && <Button disabled={buttons.cancel.disabled} color={buttons.cancel.color} onClick={handleToggle}>{buttons.cancel.label}</Button>}
            </ModalFooter>
        </CoreUiModal>
    );
};

Modal.propTypes = propTypes;

export default Modal;