import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { selectIsLoadingByTarget } from '../../../../redux/selectors/auth';

import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';

import BrandBanner from '../BrandBanner';
import LanguageDropdown from '../../../locale/LanguageDropdown';

import styles from './index.module.scss';

const propTypes = {
    target: PropTypes.oneOf(['login', 'register', 'resetPassword', 'setNewPassword']),
    isLoading: PropTypes.bool,
    children: PropTypes.node
};

const ViewTemplate = ({ children }) => {

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="10">
                        <CardGroup>
                            <Card className="p-sm-1 p-md-4">
                                <CardBody>
                                    {children}
                                </CardBody>
                                <span className={styles.languageDropdown}>
                                    <LanguageDropdown noBorder/>
                                </span>
                            </Card>
                            <Card className="text-white bg-primary d-md-down-none" style={{ width: '44%' }}>
                                <CardBody className="text-center d-flex justify-content-center align-items-center">
                                    <BrandBanner />
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

ViewTemplate.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => ({
    isLoading: selectIsLoadingByTarget(ownProps.target)(state)
});

const mapDispatch = {

};

export default connect(mapStateToProps, mapDispatch)(ViewTemplate);