const exchangeRates = {
    view: {
        title: 'Exchange rates list',
        actions: {
            addCurrency: 'ADD EXCHANGE RATE',
        },
        headerLabels: {
            primaryCurrency: 'Primary currency',
            secondaryCurrency: 'Secondary currency',
            exchangeRate: 'Exchange rate',
            dateFrom: 'Valid from',
            dateTo: 'Valid to',
            actions: 'Actions',
        },
        tooltips: {
            edit: 'Edit exchange rate',
            remove: 'Remove exchange rate',
        },
        deleteModal: {
            header: 'Exchange rate removal',
            body: 'Are you sure that you want to remove exchange rate?',
            cancel: 'CANCEL',
            confirm: 'REMOVE',
        },
    },
    searchForm: {
        currency: 'Primary currency:',
        dateFrom: 'Date from:',
        dateTo: 'Date to:',
        showActive: '[EN] Pokaż dla aktywnych walut',
        action: 'SEARCH',
        reset: 'RESET',
        currencyPlaceholder: 'any',
    },
    edit: {
        tabs: {
            basicData: {
                labels: {
                    rate: 'Exchange rate',
                    primaryCurrency: 'Primary currency',
                    secondaryCurrency: 'Secondary currency',
                    validFrom: 'Valid from',
                    validTo: 'Valid to',
                },
                title: 'Basic Data',
            },
            errorModalText: 'Error occured while editing exchange rate basic data',
            errorModalClose: 'CLOSE',
        }
    }
};

export default exchangeRates;
