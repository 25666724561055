import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { attributeTypeEnum, attributePresentationTypeEnum } from '../../../../enums/attributes';
import { generateDropDownOptionsForEnum } from '../../../../utils/components';

import SearchForm from '../../../../components/generators/SearchForm';

const propTypes = {
    performAttributeSearch: PropTypes.func,
    searchConfig: PropTypes.object.isRequired,
};

const AttributeSearchForm = ({ performAttributeSearch, searchConfig }) => {
    const intl = useIntl();
    const translations = intl.messages.attributes.searchForm;

    const onSubmit = formData => {
        performAttributeSearch(formData);
    };

    const typeOptions = generateDropDownOptionsForEnum(attributeTypeEnum, translations.filters.attributeType);
    const presentationOptions = generateDropDownOptionsForEnum(attributePresentationTypeEnum, translations.filters.attributePresentation);

    return (
        <SearchForm
            searchConfig={searchConfig}
            performSearch={onSubmit}
            translations={{
                buttons: {
                    submit: translations.action,
                    reset: translations.reset,
                }
            }}
            inputs={[
                {
                    type: 'text',
                    label: translations.phrase.label,
                    name: 'attributeName'
                },
                {
                    type: 'dropdown',
                    label: translations.type.label,
                    name: 'attributeType',
                    options: typeOptions,
                    defaultLabel: translations.type.placeholder
                },
                {
                    type: 'dropdown',
                    label: translations.presentation.label,
                    name: 'attributePresentation',
                    options: presentationOptions,
                    defaultLabel: translations.presentation.placeholder
                },
            ]}
        />
    );
};

AttributeSearchForm.propTypes = propTypes;

export default AttributeSearchForm;