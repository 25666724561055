import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import { changePassword } from '../../../redux/methods/auth';

import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';

import styles from './index.module.scss';

import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    translations: PropTypes.object
};

const PasswordsSection = ({
    translations,
    changePassword,
    utils
}) => {

    const handleOnSubmit = formData => {
        if(formData.newPassword !== formData.newPasswordRepeated) {
            utils.error(translations.errors.newPasswordNotRepeatedCorrectly);

            return;
        }

        return changePassword(formData);
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values, submitError }) => {

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container fluid className='mt-3 px-0'>
                                    <Row className='mt-2'>
                                        <FormFieldTextOrNumber
                                            name='oldPassword'
                                            type='password'
                                            label={translations.inputs.oldPassword}
                                            required={true}
                                            className={styles.input}
                                        />
                                    </Row>
                                    <Row className='mt-2'>
                                        <FormFieldTextOrNumber
                                            name='newPassword'
                                            type='password'
                                            label={translations.inputs.newPassword}
                                            className={styles.input}
                                            required={true}
                                        />
                                    </Row>
                                    <Row className='mt-2'>
                                        <FormFieldTextOrNumber
                                            name='newPasswordRepeated'
                                            type='password'
                                            label={translations.inputs.confirmNewPassword}
                                            required={true}
                                            className={styles.input}
                                        />
                                    </Row>
                                </Container>
                                { submitError && <span className={styles.submitError}>{submitError}</span> }
                                <SectionEditFormButtons formReset={form.reset} isSaveButtonDisabled={!values.oldPassword || !values.newPassword || !values.newPasswordRepeated} />
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

PasswordsSection.propTypes = propTypes;

const mapDispatch = {
    changePassword
};

export default withFormUtils(connect(null, mapDispatch)(PasswordsSection));