const generalSettings = {
    title: 'Ustawienia ogólne',
    addFreeShippingMethodToCart: {
        label: 'Dodawaj darmową formę dostawy do koszyka',
        explanation: 'Jeśli wybrana przez klienta forma dostawy jest darmowa - zostanie ona dodana do koszyka jako pozycja z kosztem 0.00. Wyłączenie tej opcji spowoduje, że darmowe formy dostawy nie pojawią się jako pozycje w koszyku.'
    },
    carouselsInfiniteScroll: {
        label: 'Stosuj niekończące się przewijanie w karuzelach',
        explanation: 'Po przewinięciu widoku karuzeli do ostatniego elementu, kliknięcie na strzałkę spowoduje pokazanie kolejnych elementów zaczynając od pierwszego.'
    },
    autoAddProductPromotionTag: {
        label: 'Automatycznie przypisuj do artykułów tag "Promocja"',
        explanation: 'Jeśli ustawienie jest włączone i dany artykuł posiada cenę promocyjną w jakiejkolwiek walucie, to zostanie do niego przypisany tag "Promocja". Przypisanie odbywa się raz codziennie przed północą czasu uniwersalnego lub przy zapisie cen na danym artykule.'
    },
    autoAddProductNewTag: {
        label: 'Automatycznie przypisuj do artykułów tag "Nowość"',
        explanation: 'Jeśli ustawienie jest włączone i ilośc dni jaka upłynęła od daty utworzenia artykułu jest mniejsza lub równa od ustawionej, to zostanie do niego przypisany tag "Nowość". Przypisanie odbywa się raz codziennie przed północą czasu uniwersalnego lub przy utworzeniu artykułu.'
    },
    productIsNewNumberOfDays: {
        label: 'Ilość dni'
    },
    disableOrderingAsGuest: {
        label: 'Wyłącz zamawianie jako gość',
        explanation: 'Jeśli ustawienie jest włączone, to nie będzie możliwe zamawianie jako gość. Będzie wymagane posiadanie konta.'
    },
    categoryMenuType: 'Typ menu kategorii',
    categoryMenuTypes: {
        type1: 'Typ 1',
        type2: 'Typ 2',
        type3: 'Typ 3',
        type4: 'Typ 4',
        type5: 'Typ 5'
    },
    notHideSubmenuAfterMouseLeave:  {
        label: 'Nie ukrywaj podmenu kategorii po zjechaniu z niego kursorem',
        explanation: 'Jeśli ustawienie jest włączone, to zamknięcie podmenu będzie następować jedynie przez naciśnięcie przycisku.'
    },
    preventAutoCheckAgreements: {
        label: 'Zablokuj automatyczne zaznaczanie zgód',
        explanation: 'Jeśli ustawienie jest włączone, to nie będzie możliwe automatyczne zaznaczanie zgód. Będzie wymagane ręczne zaznaczenie.'
    },
    forceCompanyRegistrationAndBuy: {
        label: 'Enforce company registration and purchases',
        explanation: 'If this setting is enabled, it will not be possible to register and make purchases as a private individual. Owning a company will be required.'
    },
    buttons: {
        save: 'Zapisz zmiany'
    }
};

export default generalSettings;