import { cAPI } from '../../../utils/cAPI';
import { addEditData, getData, removeData } from '../generic';

export const getRegions = () => dispatch => getData(dispatch, cAPI.APGetRegions, null, 'regions');
export const removeRegion = regionId => dispatch => removeData(dispatch, cAPI.APRemoveRegion, regionId, 'regions');
export const addRegion = () => dispatch => addEditData(dispatch, cAPI.APAddRegion, { name: '' });
export const getRegionBasicData = regionId => dispatch => getData(dispatch, cAPI.APGetRegion, regionId, 'regions');

export const editRegionName = formData =>  dispatch => addEditData(dispatch, cAPI.APEditRegionName, formData, 'regions');

export const editRegionCountries = (regionId, countriesIdList, type) => dispatch => {
    const formData = {
        regionId: regionId,
        countryId: countriesIdList,
        action: type,
    };

    addEditData(dispatch, cAPI.APRemoveRegionCountries, formData, 'regionCountries');
};

export const getRegionCountries = id => dispatch => getData(dispatch, cAPI.APGetRegionCountries, id, 'regionCountries');