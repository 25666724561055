import React from 'react';

import ViewTemplate from '../../../features/auth/sharedItems/ViewTemplate';
import SetNewPasswordForm from '../../../features/auth/SetNewPasswordForm';

const ResetPassword = () => {
    return (
        <ViewTemplate target="setNewPassword">
            <SetNewPasswordForm />
        </ViewTemplate>
    );
};

export default ResetPassword;