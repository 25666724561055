import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHomePageSections, deleteHomePageSections, changeOrderHomePageSections, changeActiveHomePageSections, createHomePageSectionDuplicate } from '../../../redux/methods/homePage';

import HomePageSectionsTable from '../../../components/tables/HomePageSectionsTable';
import useFetch from '../../../hooks/useFetch';

const propTypes = {
    device: PropTypes.oneOf(['Mobile', 'Desktop', 'Tablet']),
    getHomePageSections: PropTypes.func,
    deleteHomePageSections: PropTypes.func,
    changeOrderHomePageSections: PropTypes.func,
    changeActiveHomePageSections: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const HomePageSectionTable = ({
    device,
    sectionLocation,
    getHomePageSections,
    affiliation,
    affiliationObjectId,
    sectionTranslation,
    deleteHomePageSections,
    changeOrderHomePageSections,
    changeActiveHomePageSections,
    forceRefetch,
    createHomePageSectionDuplicate
}) => {
    const [ homePageSections ] = useFetch(getHomePageSections, { device, affiliation, affiliationObjectId }, [device], forceRefetch);

    return homePageSections ? <HomePageSectionsTable
        sectionLocation={sectionLocation}
        sectionTranslation={sectionTranslation}
        device={device}
        handleDelete={deleteHomePageSections}
        changeOrder={changeOrderHomePageSections}
        sections={homePageSections}
        changeActive={changeActiveHomePageSections}
        affiliationObjectId={affiliationObjectId}
        handleCopy={createHomePageSectionDuplicate}
    /> : null;
};

HomePageSectionTable.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.homePageSections
});

const mapDispatch = {
    getHomePageSections,
    deleteHomePageSections,
    changeOrderHomePageSections,
    changeActiveHomePageSections,
    createHomePageSectionDuplicate
};

export default connect(mapStateToProps, mapDispatch)(HomePageSectionTable);