import React from 'react';
import PropTypes from 'prop-types';

import MediaSelectionModal from '../MediaSelectionModal';
import FileSenderOpenModal from '../../../components/FileSenderOpenModal';

const propTypes = {
    checkedItems: PropTypes.array,
    setCheckedItems: PropTypes.func,
    setIsOpen: PropTypes.func,
    isOpen: PropTypes.bool,
};

const MediaSelectionModalWithButtons = ({ checkedItems = [], setCheckedItems, setIsOpen, isOpen, label, buttons }) => {
    const handleSet = items => {
        setCheckedItems(items);
        setIsOpen(false);
    };

    return (
        <>
            <FileSenderOpenModal
                handleRemove={() => setCheckedItems([])}
                withPreview
                photo={
                    checkedItems.length > 0
                        ? checkedItems.length > 1
                            ? 'many'
                            : checkedItems[0].thumbnailSource
                        : null
                }
                openModal={() => setIsOpen(true)}
                label={label}
                buttons={buttons}
            />
            <MediaSelectionModal defaultItemsChecked={checkedItems} onSubmit={handleSet} isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    );
};

MediaSelectionModalWithButtons.propTypes = propTypes;

export default MediaSelectionModalWithButtons;