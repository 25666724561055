import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import { changeEmailRequest } from '../../../redux/methods/auth';

import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';

import styles from './index.module.scss';

const propTypes = {
    translations: PropTypes.object,
};

const EmailSection = ({
    translations,
    changeEmailRequest,
}) => {

    const handleOnSubmit = formData => {
        changeEmailRequest(formData);
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, submitSucceeded }) => {

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container fluid className='mt-3 px-0'>
                                    <span className={clsx('d-none text-success my-3', submitSucceeded && 'd-block')}>{translations.submitSucceeded}</span>
                                    <Row className='mt-2'>
                                        <FormFieldTextOrNumber
                                            name='email'
                                            type='email'
                                            label={translations.inputs.email}
                                            required={true}
                                            className={styles.input}
                                        />
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

EmailSection.propTypes = propTypes;

const mapDispatch = {
    changeEmailRequest
};

export default connect(null, mapDispatch)(EmailSection);