import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useFetch from '../../../hooks/useFetch';

import { getRegions } from '../../../redux/methods/regions';

import {
    getShippingFormsVariants,
    getShippingFormsVariant,
    addEditShippingFormsVariant,
    deleteShippingFormsVariant,
    setDefaultShippingFormsVariant
} from '../../../redux/methods/shippingForms';

import ContainerWithCard from '../../../components/ContainerWithCard';
import TableGenerator from '../../generators/TableGenerator';
import ShippingFormVariantEditionModal from '../../../components/modals/ShippingFormVariantEditionModal';
import { Button } from 'reactstrap';

import styles from './index.module.scss';
import { formUtilsHocPropTypes } from '../../../propTypes';
import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getRegions: PropTypes.func,
    getShippingFormsVariants: PropTypes.func,
    getShippingFormsVariant: PropTypes.func,
    addEditShippingFormsVariant: PropTypes.func,
    deleteShippingFormsVariant: PropTypes.func,
    setDefaultShippingFormsVariant: PropTypes.func,
    nextLevelForVariantsRowAction: PropTypes.object,
    forceRefetch: PropTypes.bool
};

const ShippingVariantsTable = ({
    utils,
    getRegions,
    getShippingFormsVariants,
    getShippingFormsVariant,
    addEditShippingFormsVariant,
    deleteShippingFormsVariant,
    setDefaultShippingFormsVariant,
    nextLevelForVariantsRowAction,
    forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.shippingForms.variantsTable;

    const { headers, editModal } = translations;

    const [ isEditModalOpen, setIsEditModalOpen ] = useState(false);
    const [ editModalItem, setEditModalItem ] = useState(null);

    const [ regions ] = useFetch(getRegions);
    const [ items ] = useFetch(getShippingFormsVariants, null, [], forceRefetch);

    const editAction = id => async () => {
        const item = await getShippingFormsVariant(id);
        if (item && item.payload && !item.payload.errors && !item.error) {
            setEditModalItem(item.payload);
            setIsEditModalOpen(true);
        }
    };

    const deleteAction = id => () => {
        deleteShippingFormsVariant(id);
    };

    const handleEditAction = form => {
        const objectToSend = {...form, shippingVariantId: form.id};

        if (objectToSend.name.length === objectToSend.name.filter(n => !n.value).length) {
            utils.error(translations.errors.nameIsRequired);
            return;
        }
        else {
            addEditShippingFormsVariant(objectToSend);
            setIsEditModalOpen(false);
        }
    };

    const handleAddAction = () => {
        setEditModalItem({});
        setIsEditModalOpen(true);
    };

    const handleSwitch = (id) => setDefaultShippingFormsVariant(id);

    const handleCheckIfActionIsDisabled = (action, item) => {
        return (action == 'delete') && (item.id === 1);
    };

    return items ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-wallpaper'>
            <TableGenerator
                items={items}
                expectedActions={[nextLevelForVariantsRowAction, 'edit', 'delete']}
                actions={{editAction, deleteAction}}
                defaultAction={editAction}
                translations={translations}
                customIdSelector='id'
                columns={[
                    /*{
                        header: headers.id,
                        value: 'id',
                    },*/
                    {
                        header: headers.name,
                        value: 'name',
                    },
                    {
                        header: headers.regions,
                        value: 'regions',
                    },
                    {
                        header: headers.isDefault,
                        type: 'switchWithAction',
                        value: 'isDefault',
                        fromTrueToFalseEnabled: false,
                        onSwitch: handleSwitch
                    },
                    {
                        header: headers.actions,
                        type: 'actions'
                    }
                ]}
                checkIfActionIsDisabled={handleCheckIfActionIsDisabled}
            />
            <aside className={styles.footer}>
                <Button color='primary' onClick={handleAddAction}>
                    <i className='fa fa-plus mr-2'/>
                    <span>{translations.buttons.create}</span>
                </Button>
            </aside>
            <ShippingFormVariantEditionModal
                isOpen={isEditModalOpen}
                setIsOpen={setIsEditModalOpen}
                translations={editModal}
                regions={regions}
                variant={editModalItem}
                onSubmit={handleEditAction}
            />
        </ContainerWithCard>
    ) : null;
};

ShippingVariantsTable.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.shippingVariants,
});

const mapDispatch = {
    getRegions,
    getShippingFormsVariants,
    getShippingFormsVariant,
    addEditShippingFormsVariant,
    deleteShippingFormsVariant,
    setDefaultShippingFormsVariant
};

export default withFormUtils(withRouter(connect(mapStateToProps, mapDispatch)(ShippingVariantsTable)));