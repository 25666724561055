import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { productsSearchConfig } from '../../config/searchConfigs';

import SearchForm from '../generators/SearchForm';

const propTypes = {
    performProductSearch: PropTypes.func,
    searchConfig: PropTypes.object,
};

const ServiceSearchForm = ({ performProductSearch, searchConfig, additionalDefaultSearchConfig, children }) => {
    const intl = useIntl();
    const translations = intl.messages.services.searchForm;

    return (
        <SearchForm
            defaultSearchConfig={{...productsSearchConfig, ...additionalDefaultSearchConfig }}
            searchConfig={searchConfig}
            translations={{
                buttons: {
                    submit: translations.action,
                    reset: translations.reset,
                }
            }}
            inputs={[
                {
                    label: translations.serviceName.label,
                    name: 'serviceName',
                    type: 'text',
                }
            ]}
            performSearch={performProductSearch}
        >
            {children}
        </SearchForm>
    );
};

ServiceSearchForm.propTypes = propTypes;

export default ServiceSearchForm;