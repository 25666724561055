import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { Field } from 'react-final-form';
import { Input, Label, Row } from 'reactstrap';
import { translateIso } from '../../../utils/locale';
import { getKeyByValue } from '../../../utils/components';

import { languageNumericEnum } from '../../../enums/locale';

import styles from './index.module.scss';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
    isNumericHandling: PropTypes.bool,
};

const FormFieldActiveInLanguages = ({ name, languages, label, isNumericHandling }) => {
    const translations = useIntl().messages.storeLocale.languages;

    const handleChange = (value, onChange, lang, tab) => {
        if(value) {
            onChange([...tab, lang]);
        } else {
            onChange(tab.filter(item => item !== lang));
        }
    };

    return (
        <Field name={name}>
            {
                props =>
                <Row className='d-flex flex-column'>
                        <p>{label}</p>
                        {
                            languages.map(language =>
                                {
                                    const lang = isNumericHandling ? getKeyByValue(languageNumericEnum, language) : language;
                                    return (
                                        <div key={`${lang}--checkbox`} className='pl-4'>
                                            <Input
                                                name='languagesVisible'
                                                checked={props.input.value.includes(lang)}
                                                type='checkbox'
                                                id={lang}
                                                onChange={(e) => handleChange(e.target.checked, props.input.onChange, lang, props.input.value)}
                                            />
                                            <Label for={lang}>
                                                <i className={clsx('flag-icon', `flag-icon-${translateIso(language).toLowerCase()}`, styles.flag)} />
                                                {translations[language]}
                                            </Label>
                                        </div>
                                    );
                                }
                            )
                        }
                    </Row>
            }
        </Field>
    );
};

FormFieldActiveInLanguages.propTypes = propTypes;

export default FormFieldActiveInLanguages;