import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { selectStoreCurrentLanguage } from '../../redux/selectors/storeLocale';
import { translationPropTypes } from '../../propTypes';

const propTypes = {
    arrayOfStrings: PropTypes.arrayOf(PropTypes.arrayOf(translationPropTypes)),
    keyToPass: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const ArrowsBetweenStrings = ({ arrayOfStrings, keyToPass, storeCurrentLanguage }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.arrowsBetweenStrings;

    return (
        arrayOfStrings && arrayOfStrings.map((name, nameIndex) => {

            const translation = name.filter(translation => translation.iso.toLowerCase() === storeCurrentLanguage.toLowerCase());
            const translatedName = translation && translation.length > 0 ? translation[0].value : translations.fallback;

            return nameIndex !== arrayOfStrings.length - 1 ? (
                <React.Fragment key={`${keyToPass}-${nameIndex}`}>
                    <span className={clsx(!translation && 'text-danger')}>{translatedName}</span>
                    <i className='fa fa-long-arrow-right ml-1 mr-1' />
                </React.Fragment>
            ) : (
                <span key={`${keyToPass}-${nameIndex}`} className={clsx(!translation && 'text-danger')}>{translatedName}</span>
            );
        })
    );
};

ArrowsBetweenStrings.propTypes = propTypes;

const mapStateToProps = state => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
});

export default connect(mapStateToProps, null)(ArrowsBetweenStrings);