import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCustomPages, removeCustomPage, updateCustomPageStatus } from '../../redux/methods/customPages';

import { generateEditLinkForCustomPage, generateCustomPageListWithTranslatedName } from '../../utils/customPages';

import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';

import useFetch from '../../hooks/useFetch';
import CustomPageTable from '../../components/tables/CustomPageTable';
import { customPagePropTypes } from '../../propTypes';
import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object.isRequired,
    isFetched: PropTypes.bool,
    getCustomPages: PropTypes.func,
    removeCustomPage: PropTypes.func,
    pages: PropTypes.arrayOf(customPagePropTypes),
    forceRefetch: PropTypes.bool,
    storeChosenLanguage: PropTypes.string,
    storeDefaultLanguage: PropTypes.string,
};

const CustomPages = ({
    getCustomPages,
    removeCustomPage,
    history,
    forceRefetch,
    storeDefaultLanguage,
    storeChosenLanguage,
    updateCustomPageStatus,
}) => {
    const intl = useIntl();
    const translations = intl.messages.customPages.view;

    const [ pages ] = useFetch(getCustomPages, null, [], forceRefetch);

    const [ checkedCustomPagesIds, setCheckedCustomPagesIds ] = useState([]);

    const handleAddCustomPage = () => {
        history.push(generateEditLinkForCustomPage(-1));
    };

    const handleRemoveCustomPage = id => {
        removeCustomPage(id);
    };

    return pages ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody className='position-relative'>
                                <main>
                                    <CustomPageTable
                                        pages={generateCustomPageListWithTranslatedName(pages, storeChosenLanguage || storeDefaultLanguage)}
                                        headers={translations.headerLabels}
                                        fillers={translations.fillers}
                                        translations={translations}
                                        removeHandler={handleRemoveCustomPage}
                                        history={history}
                                        checkedCustomPagesIds={checkedCustomPagesIds}
                                        setCheckedCustomPagesIds={setCheckedCustomPagesIds}
                                        updateAction={updateCustomPageStatus}
                                    />
                                </main>
                                <aside className={styles.footer}>
                                    <Button color='primary' onClick={handleAddCustomPage}>
                                        <i className='fa fa-plus mr-2'/>
                                        <span>{translations.actions.addCustomPage}</span>
                                    </Button>
                                </aside>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ) : null;
};

CustomPages.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.customPages,
    storeChosenLanguage: state.persisted.storeLocale.storeChosenLanguage,
    storeDefaultLanguage: state.persisted.storeLocale.storeDefaultLanguage,
});

const mapDispatch = {
    getCustomPages,
    removeCustomPage,
    updateCustomPageStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(CustomPages));