import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectTranslatedCategoryName } from '../../../../redux/selectors/categories';
import { deleteCategoryFeatures, getCategoryAssignedFeatures } from '../../../../redux/methods/categories';
import useFetch from '../../../../hooks/useFetch';
import useSearchConfig from '../../../../hooks/useSearchConfig';

import { generateTranslatedFeaturesAttributesList } from '../../../../utils/attributes';

import { Container, Row, Button } from 'reactstrap';
import TooltipWrapper from '../../../../components/TooltipWrapper';
import FeaturesAssignedToCategoryTable from '../../../../components/tables/FeaturesAssignedToCategoryTable';
import { attributesFeaturesSearchConfig } from '../../../../config/searchConfigs';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    history: PropTypes.object.isRequired,
    categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    categoryName: PropTypes.string,
    deleteCategoryFeatures: PropTypes.func,
    storeDefaultLanguage: PropTypes.string,
    storeChosenLanguage: PropTypes.string,
    forceRefetch: PropTypes.bool,
    getCategoryAssignedFeatures: PropTypes.func,
    isERPCategoriesInUsage: PropTypes.bool,
};

const CategoryEditAssignedFeaturesSection = ({
    utils,
    history, categoryId, storeChosenLanguage, storeDefaultLanguage,
    deleteCategoryFeatures, getCategoryAssignedFeatures, forceRefetch,
    isERPCategoriesInUsage,
}) => {
    const intl = useIntl();
    const translations = intl.messages.categories.edit.tabs.attributes;

    const [ featureIdSelection, setFeatureIdSelection ] = useState([]);
    const [ searchConfig ] = useSearchConfig({ ...attributesFeaturesSearchConfig, categoryId: categoryId });
    const [ assignedFeatures ] = useFetch(getCategoryAssignedFeatures, searchConfig, [searchConfig], forceRefetch);
    const [ isTooltipOpen, setIsTooltipOpen ] = useState(false);
    const translatedAssignedFeatures = assignedFeatures ? generateTranslatedFeaturesAttributesList(assignedFeatures, storeChosenLanguage || storeDefaultLanguage) : [];

    const handleSelection = id => {
        const isFeatureSelected = featureIdSelection.indexOf(id) !== -1 ? true : false;

        setFeatureIdSelection(isFeatureSelected ? featureIdSelection.filter(featureId => featureId !== id) : [ ...featureIdSelection, id ]);
    };

    const editLinkGenerator = id => `/features/edit?featID=${id}`;

    const handleRemoveSingleFeature = categoryId => featureId => {
        deleteCategoryFeatures(categoryId, [ featureId ]);
    };

    const handleDeletionModalOpen = () => {
        utils.confirm(
            async () => await deleteCategoryFeatures(categoryId, featureIdSelection),
            translations.deletionModal.header,
            translations.deletionModal.body
        );
    };

    const handleAddNewFeature = () => {
        history.push(`/Features/Edit?featID=-2&catID=${categoryId}`);
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FeaturesAssignedToCategoryTable
                    features={translatedAssignedFeatures}
                    checkedCheckboxList={featureIdSelection}
                    columnNames={translations.table.columnNames}
                    tooltipTranslations={translations.table.tooltip}
                    emptyTranslation={translations.table.placeholder}
                    deleteModalTranslations={translations.table.deleteModal}
                    editLinkGenerator={editLinkGenerator}
                    checkboxAction={handleSelection}
                    removeAction={handleRemoveSingleFeature(categoryId)}
                />
                <Row className={clsx('d-flex flex-row mt-5', assignedFeatures && assignedFeatures.length > 0 ? 'justify-content-between' : 'justify-content-end')}>
                    {
                        assignedFeatures && assignedFeatures.length > 0 && (
                            <Button
                                outline
                                type="button"
                                disabled={!featureIdSelection || featureIdSelection.length === 0}
                                onClick={handleDeletionModalOpen}
                                color="danger"
                            >
                                <i className="fa fa-trash-o mr-2"/>
                                <span>{translations.controls.remove}</span>
                            </Button>
                        )
                    }
                    <TooltipWrapper
                        placement='top'
                        target='addNewFeatureButton'
                        tooltipContent={translations.controls.addTooltip}
                        isOpen={isERPCategoriesInUsage && isTooltipOpen}
                        setIsOpen={setIsTooltipOpen}
                    >
                        <div id={'addNewFeatureButton'}>
                            <Button onClick={handleAddNewFeature} color="primary" disabled={isERPCategoriesInUsage}>
                                    <i className="fa fa-plus mr-2"/>
                                    <span>{translations.controls.add}</span>
                            </Button>
                        </div>
                    </TooltipWrapper>
                </Row>
            </Container>
        </React.Fragment>
    );
};

CategoryEditAssignedFeaturesSection.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => ({
    categoryName: selectTranslatedCategoryName(ownProps.categoryId)(state),
    storeChosenLanguage: state.persisted.storeLocale.storeChosenLanguage,
    storeDefaultLanguage: state.persisted.storeLocale.storeDefaultLanguage,
    forceRefetch: state.refetchers.categories,
});

const mapDispatch = {
    deleteCategoryFeatures,
    getCategoryAssignedFeatures
};

export default withRouter(withFormUtils(connect(mapStateToProps, mapDispatch)(CategoryEditAssignedFeaturesSection)));