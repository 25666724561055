import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDiscountRegions, getDiscountCountries } from '../../../../redux/methods/discounts';
import useFetch from '../../../../hooks/useFetch';
import useSearchConfig from '../../../../hooks/useSearchConfig';
import useActiveLabel from '../../../../hooks/useActiveLabel';

import { Container } from 'reactstrap';

import SwitchWithLabels from '../../../../components/SwitchWithLabels';
import RegionsSection from './RegionsSection';
import CountriesSection from './CountriesSection';

import { stringOrNumberPropTypes } from '../../../../propTypes';
import { discountRegionsSearchConfig, discountCountriesSearchConfig } from '../../../../config/searchConfigs';

import styles from './index.module.scss';

const propTypes = {
    id: stringOrNumberPropTypes,
    getDiscountRegions: PropTypes.func,
    getDiscountCountries: PropTypes.func,
    countriesForceRefetch: PropTypes.bool,
    regionsForceRefetch: PropTypes.bool,
};

const DiscountEditRegionsCountries = ({
    id, getDiscountRegions,
    getDiscountCountries, countriesForceRefetch, regionsForceRefetch
}) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.regionsCountries;

    const [ regionsSearchConfig, changeRegionsPage ] = useSearchConfig({ ...discountRegionsSearchConfig, discountId: id });
    const [ countriesSearchConfig, changeCountriesPage ] = useSearchConfig({ ...discountCountriesSearchConfig, discountId: id });

    const [ regions, regionsPages ] = useFetch(getDiscountRegions, regionsSearchConfig, [regionsSearchConfig], regionsForceRefetch);
    const [ countries, countriesPages ] = useFetch(getDiscountCountries, countriesSearchConfig, [countriesSearchConfig], countriesForceRefetch);

    const regionsIsPresent = regions && regions.length > 0;
    const countriesIsPresent = countries && countries.length > 0;

    const [activeLabel, setActiveLabel] = useActiveLabel(regions, countries);

    const isActiveLabelLeft = activeLabel === 'left';

    return regions && countries && activeLabel ? (
        <React.Fragment>
            <Container fluid>
                <header className={styles.controls}>
                    <SwitchWithLabels
                        labels={{
                            left: translations.switch.left,
                            right: translations.switch.right,
                            main: translations.switch.main,
                        }}
                        activeLabel={activeLabel}
                        changeActiveLabel={setActiveLabel}
                        isDisabled={regionsIsPresent || countriesIsPresent}
                        className={clsx(styles.switch)}
                        tooltip={translations.switch.tooltip}
                    />
                </header>
                {regions && isActiveLabelLeft &&
                    <RegionsSection
                        discountRegions={regions}
                        pages={regionsPages}
                        changePage={changeRegionsPage}
                        currentPage={regionsSearchConfig.curentPage + 1}
                    />
                }
                <CountriesSection
                    isActiveLabelLeft={isActiveLabelLeft}
                    discountCountries={countries}
                    pages={countriesPages}
                    changePage={changeCountriesPage}
                    currentPage={countriesSearchConfig.curentPage + 1}
                    regionsIsPresent={regionsIsPresent}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

DiscountEditRegionsCountries.propTypes = propTypes;

const mapStateToProps = (state) => ({
    id: state.discounts.discountToEdit.data.discountId,
    regionsForceRefetch: state.refetchers.discountsRegions,
    countriesForceRefetch: state.refetchers.discountsCountries,
});

const mapDispatch = {
    getDiscountRegions,
    getDiscountCountries,
};

export default connect(mapStateToProps, mapDispatch)(DiscountEditRegionsCountries);