import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateCategoryBasicData, uploadCategoryImage } from '../../../../redux/methods/categories';

import { Form as FinalForm, Field } from 'react-final-form';
import { Card, CardHeader, CardBody, Alert, Container, Form, Row, Col, Input, Label, TabContent } from 'reactstrap';

import { generateStatusesConfig } from '../../../../utils/categories';

import FormFieldStatus from '../../../../components/formFields/FormFieldStatus';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldTranslations from '../../../../components/formFields/FormFieldTranslations';
import FormFieldTranslationsQuill from '../../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldWithCheckbox from '../../../../components/formFields/FormFieldWithCheckbox';
import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';
import FileSenderCategory from './FileSenderCategory';
import TooltipWrapper from '../../../../components/TooltipWrapper';
import FormFieldSwitchPill from '../../../../components/formFields/FormFieldSwitchPill';

import TabulatorNav from '../../../../components/TabulatorNav';

import TabProductAssignerManual from '../../../products/TabProductAssignerManual';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import { categoryStatusEnum } from '../../../../enums/categories';
import { formUtilsHocPropTypes, stringOrNumberPropTypes, translationPropTypes } from '../../../../propTypes';

import MediaSelectionModalWithButtons from '../../../../features/mediaLibrary/MediaSelectionModalWithButtons';

import clsx from 'clsx';

import { translateIso } from "../../../../utils/locale";

import styles from './index.module.scss';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    data: PropTypes.shape({
        categoryId: stringOrNumberPropTypes,
        status: PropTypes.number,
        name: PropTypes.arrayOf(translationPropTypes),
        description: PropTypes.arrayOf(translationPropTypes)
    }),
    updateCategoryBasicData: PropTypes.func,
    isERPCategoriesInUsage: PropTypes.bool,
    categoryId: stringOrNumberPropTypes,
    uploadCategoryImage: PropTypes.func
};

const CategoryEditBasicSection = ({
    utils,
    data,
    updateCategoryBasicData,
    isERPCategoriesInUsage,
    categoryId,
    uploadCategoryImage
}) => {
    const [ activeTab, setActiveTab ] = useState(0);

    const intl = useIntl();
    const translations = intl.messages.categories.edit.tabs.basic;

    const config = { translations: ['name', 'descriptionDesktop', 'descriptionTablet', 'descriptionMobile'] };

    const [ isTooltipOpen, setIsTooltipOpen ] = useState(false);

    const [ categoryImageFile, setCategoryImageFile ] = useState(null);
    const [ categoryImageFileFull, setCategoryImageFileFull ] = useState(null);
    const [ categoryImageFilePictogram, setCategoryImageFilePictogram ] = useState(null);

    const [ isSelectionModalOpenImage, setIsSelectionModalOpenImage ] = useState(false);
    const [ isSelectionModalOpenImageFull, setIsSelectionModalOpenImageFull ] = useState(false);
    const [ isSelectionModalOpenImagePictogram, setIsSelectionModalOpenImagePictogram ] = useState(false);

    const [ selectedImage, setSelectedImage ] = useState([]);
    const [ selectedImageFull, setSelectedImageFull ] = useState([]);
    const [ selectedImagePictogram, setSelectedImagePictogram ] = useState([]);

    const [ checkedProductsId, setCheckedProductsId ] = useState([]);

    useEffect(() => {
        if(data) {
            setCheckedProductsId(data.products ? data.products : []);

            if(data.imageSourceId && data.imageSourceId !== 0) {
                setSelectedImage([
                    {
                        id: data?.imageSourceId,
                        thumbnailSource: data?.imageSourceMediaLibrary
                    }
                ]);
            }

            if(data.imageSourceFullId && data.imageSourceFullId !== 0) {
                setSelectedImageFull([
                    {
                        id: data?.imageSourceFullId,
                        thumbnailSource: data?.imageSourceFullMediaLibrary
                    }
                ]);
            }

            if(data.imageSourcePictogramId && data.imageSourcePictogramId !== 0) {
                setSelectedImagePictogram([
                    {
                        id: data?.imageSourcePictogramId,
                        thumbnailSource: data?.imageSourcePictogramMediaLibrary
                    }
                ]);
            }
        }
    }, [data]);

    const statuses = generateStatusesConfig(categoryStatusEnum, translations.statusLabels);

    const handleOnSubmit = formData => {
        const formValuesToSubmit = {
            ...utils.generateFormToSubmitValues(formData, config),
            products: checkedProductsId,
            imageSourceId: selectedImage[0]?.id,
            imageSourceFullId: selectedImageFull[0]?.id,
            imageSourcePictogramId: selectedImagePictogram[0]?.id
        };

        updateCategoryBasicData(formValuesToSubmit);

        if(categoryImageFile && categoryImageFile.generatedFile) {
            uploadCategoryImage(categoryId, 'Half', categoryImageFile.generatedFile);
        }

        if(categoryImageFileFull && categoryImageFileFull.generatedFile) {
            uploadCategoryImage(categoryId, 'Full', categoryImageFileFull.generatedFile);
        }

        if(categoryImageFilePictogram && categoryImageFilePictogram.generatedFile) {
            uploadCategoryImage(categoryId, 'Pictogram', categoryImageFilePictogram.generatedFile);
        }
    };

    const handleToggleTooltip = () => {
        if (data.parentStatus === 0) {
            setIsTooltipOpen(!isTooltipOpen);
        }
    };

    const horizontalAlignOptions = [
        { id: 'left', name: translations.alignment.left },
        { id: 'center', name: translations.alignment.center },
        { id: 'right', name: translations.alignment.right }
    ];

    const verticalAlignOptions = [
        { id: 'top', name: translations.alignment.top },
        { id: 'center', name: translations.alignment.center },
        { id: 'bottom', name: translations.alignment.bottom }
    ];

    const positioningOptions = [
        { id: 'cover', name: translations.presentation.cover },
        { id: 'contain', name: translations.presentation.contain }
    ];

    return (
        <Container fluid>
            <FinalForm
                /** This weird key is here to ensure that the Form is remounted and reinitialised when needed */
                key={`categoryEditBasicSectionForm-${utils.currentLanguage()}`}
                initialValues={utils.generateFormInitialValues(data, config)}
                onSubmit={handleOnSubmit}
                mutators={{
                    setToFalse: (args, state, { changeValue }) => {
                        changeValue(state, 'showDescriptionAtBottom', () => false);
                    }
                }}
                render={({ handleSubmit, form }) => {
                    const { values } = form.getState();

                    const selectedStatus = statuses.find(status => status.id === values.status) || statuses[0];
                    const selectedStatusErp = statuses.find(status => status.id === values.statusErp) || statuses[0];

                    return (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <Container className='p-3 px-0' fluid>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <TooltipWrapper
                                            target={`statusDropdown-${data.categoryId}`}
                                            isOpen={isTooltipOpen}
                                            setIsOpen={handleToggleTooltip}
                                            tooltipContent={translations.status.tooltip}
                                        >
                                            <div id={`statusDropdown-${data.categoryId}`} >
                                                <FormFieldStatus
                                                    name='status'
                                                    selectedStatus={selectedStatus}
                                                    statuses={statuses}
                                                    label={translations.status.label}
                                                    disabled={data.parentStatus === 0}
                                                />
                                            </div>
                                        </TooltipWrapper>

                                        {isERPCategoriesInUsage &&
                                            <FormFieldStatus
                                                name='statusErp'
                                                selectedStatus={selectedStatusErp}
                                                statuses={statuses}
                                                label={translations.statusErp}
                                                disabled
                                            />
                                        }
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md>
                                            <Label>{translations.labels.availableInLanguages}: </Label>
                                            {
                                                utils.allLanguages()
                                                    .map(language =>
                                                        <div key={`${language}--checkbox`}>
                                                            <Field
                                                                name='languagesVisible'
                                                                value={language}
                                                                component='input'
                                                                type='checkbox'
                                                                id={language}
                                                            />
                                                            <Label for={language} className={'pl-2'}>
                                                                <i className={clsx('flag-icon', `flag-icon-${translateIso(language).toLowerCase()}`, styles.flag)} />
                                                                {translations.languages[language]}
                                                            </Label>
                                                        </div>
                                                    )
                                            }
                                        </Col>
                                    </Row>
                                    <Row className='my-3'>
                                        <FormFieldTranslations
                                            form={form}
                                            modalHeader={translations.nameFieldLabel}
                                            propertyName='name'
                                            currentIso={utils.currentLanguage()}
                                            label={translations.nameFieldLabel}
                                        />
                                    </Row>
                                    {
                                        isERPCategoriesInUsage &&
                                        <>
                                            <Row className='flex-md-nowrap'>
                                                <FormFieldSwitchPill
                                                    utils={utils}
                                                    name='externalNameSync'
                                                    label={translations.syncErp}
                                                />
                                            </Row>
                                            <Row className='my-3'>
                                                <div>
                                                    <Label>
                                                        {translations.externalName}
                                                    </Label>
                                                    <Input
                                                        value={data.externalName}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Row>
                                        </>
                                    }
                                    <Row className='my-3'>
                                        <Col>
                                            <Card>
                                                <CardHeader>{translations.fullSizeHeader}</CardHeader>
                                                <CardBody>
                                                    <Alert>{translations.fullSizeDescription}</Alert>
                                                    <Row className="mx-2 my-5">
                                                        <FileSenderCategory
                                                            categoryId={categoryId}
                                                            translations={translations}
                                                            setFile={setCategoryImageFileFull}
                                                            image={data.imageSourceFull}
                                                            type={"Full"}
                                                        />
                                                    </Row>
                                                    <Row className="mx-2 my-5">
                                                        <MediaSelectionModalWithButtons
                                                            checkedItems={selectedImageFull}
                                                            setCheckedItems={setSelectedImageFull}
                                                            isOpen={isSelectionModalOpenImageFull}
                                                            setIsOpen={setIsSelectionModalOpenImageFull}
                                                            buttons={translations.fileSender.buttons}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormFieldWithDropdown
                                                                label={translations.horizontalAlignmentLabel}
                                                                name='imageFullSizeHorizontalAlignment'
                                                                options={horizontalAlignOptions}
                                                                currentSelectionLabel={horizontalAlignOptions.find(o => o.id === values.imageFullSizeHorizontalAlignment)?.name}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <FormFieldWithDropdown
                                                                label={translations.verticalAlignmentLabel}
                                                                name='imageFullSizeVerticalAlignment'
                                                                options={verticalAlignOptions}
                                                                currentSelectionLabel={verticalAlignOptions.find(o => o.id === values.imageFullSizeVerticalAlignment)?.name}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <FormFieldWithDropdown
                                                                label={translations.presentationLabel}
                                                                name='imageFullSizePresentation'
                                                                options={positioningOptions}
                                                                currentSelectionLabel={positioningOptions.find(o => o.id === values.imageFullSizePresentation)?.name}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardHeader>{translations.halfSizeHeader}</CardHeader>
                                                <CardBody>
                                                    <Alert>{translations.halfSizeDescription}</Alert>
                                                    <Row className="mx-2 my-5">
                                                        <FileSenderCategory
                                                            categoryId={categoryId}
                                                            translations={translations}
                                                            setFile={setCategoryImageFile}
                                                            image={data.imageSource}
                                                            type={"Half"}
                                                        />
                                                    </Row>
                                                    <Row className="mx-2 my-5">
                                                        <MediaSelectionModalWithButtons
                                                            checkedItems={selectedImage}
                                                            setCheckedItems={setSelectedImage}
                                                            isOpen={isSelectionModalOpenImage}
                                                            setIsOpen={setIsSelectionModalOpenImage}
                                                            buttons={translations.fileSender.buttons}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormFieldWithDropdown
                                                                label={translations.horizontalAlignmentLabel}
                                                                name='imageHalfSizeHorizontalAlignment'
                                                                options={horizontalAlignOptions}
                                                                currentSelectionLabel={horizontalAlignOptions.find(o => o.id === values.imageHalfSizeHorizontalAlignment)?.name}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <FormFieldWithDropdown
                                                                label={translations.verticalAlignmentLabel}
                                                                name='imageHalfSizeVerticalAlignment'
                                                                options={verticalAlignOptions}
                                                                currentSelectionLabel={verticalAlignOptions.find(o => o.id === values.imageHalfSizeVerticalAlignment)?.name}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <FormFieldWithDropdown
                                                                label={translations.presentationLabel}
                                                                name='imageHalfSizePresentation'
                                                                options={positioningOptions}
                                                                currentSelectionLabel={positioningOptions.find(o => o.id === values.imageHalfSizePresentation)?.name}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardHeader>{translations.pictogramHeader}</CardHeader>
                                                <CardBody>
                                                    <Alert>{translations.pictogramDescription}</Alert>
                                                    <Row className="mx-2 my-5">
                                                        <FileSenderCategory
                                                            categoryId={categoryId}
                                                            translations={translations}
                                                            setFile={setCategoryImageFilePictogram}
                                                            image={data.imageSourcePictogram}
                                                            type={"Pictogram"}
                                                        />
                                                    </Row>
                                                    <Row className="mx-2 my-5">
                                                        <MediaSelectionModalWithButtons
                                                            checkedItems={selectedImagePictogram}
                                                            setCheckedItems={setSelectedImagePictogram}
                                                            isOpen={isSelectionModalOpenImagePictogram}
                                                            setIsOpen={setIsSelectionModalOpenImagePictogram}
                                                            buttons={translations.fileSender.buttons}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormFieldWithDropdown
                                                                label={translations.horizontalAlignmentLabel}
                                                                name='imagePictogramHorizontalAlignment'
                                                                options={horizontalAlignOptions}
                                                                currentSelectionLabel={horizontalAlignOptions.find(o => o.id === values.imagePictogramHorizontalAlignment)?.name}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <FormFieldWithDropdown
                                                                label={translations.verticalAlignmentLabel}
                                                                name='imagePictogramVerticalAlignment'
                                                                options={verticalAlignOptions}
                                                                currentSelectionLabel={verticalAlignOptions.find(o => o.id === values.imagePictogramVerticalAlignment)?.name}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <FormFieldWithDropdown
                                                                label={translations.presentationLabel}
                                                                name='imagePictogramPresentation'
                                                                options={positioningOptions}
                                                                currentSelectionLabel={positioningOptions.find(o => o.id === values.imagePictogramPresentation)?.name}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className='mt-6'>
                                        <FormFieldWithCheckbox
                                            name='showDescriptionOverProducts'
                                            label={translations.showDescription}
                                            mutator={form.mutators.setToFalse}
                                        />
                                    </Row>
                                    <Row>
                                        <FormFieldWithCheckbox
                                            name='showDescriptionAtBottom'
                                            label={translations.showDescriptionAtBottom}
                                            isDisabled={!values.showDescriptionOverProducts}
                                        />
                                    </Row>
                                    <Row className='mb-3'>
                                        <FormFieldWithCheckbox
                                            name='showDescriptionForSubcategories'
                                            label={translations.showDescriptionForSubcategories}
                                        />
                                    </Row>
                                    <Row>
                                        <TabulatorNav
                                            setActiveTab={setActiveTab}
                                            activeTab={activeTab}
                                            tabsList={[
                                                { iso: 0, label: translations.descriptionFieldLabel + ' - Desktop' },
                                                { iso: 1, label: translations.descriptionFieldLabel + ' - Tablet' },
                                                { iso: 2, label: translations.descriptionFieldLabel + ' - Mobile' },
                                            ]}
                                            withoutFlag={true}
                                        />
                                        <TabContent>
                                            <div style={{padding: '10px', display: activeTab === 0 ? 'block' : 'none'}}>
                                                <FormFieldTranslationsQuill
                                                    form={form}
                                                    propertyName='descriptionDesktop'
                                                    utils={utils}
                                                />
                                            </div>
                                            <div style={{padding: '10px', display: activeTab === 1 ? 'block' : 'none'}}>
                                                <FormFieldTranslationsQuill
                                                    form={form}
                                                    propertyName='descriptionTablet'
                                                    utils={utils}
                                                />
                                            </div>
                                            <div style={{padding: '10px', display: activeTab === 2 ? 'block' : 'none'}}>
                                                <FormFieldTranslationsQuill
                                                    form={form}
                                                    propertyName='descriptionMobile'
                                                    utils={utils}
                                                />
                                            </div>
                                        </TabContent>
                                    </Row>
                                </Container>
                            </Form>

                            <Container className='p-3 px-0' fluid>
                                <Row>
                                    <FormFieldWithCheckbox
                                        name='showArticles'
                                        label={translations.showArticles}
                                        className='mb-2'
                                    />
                                    {
                                        values.showArticles &&
                                        <TabProductAssignerManual
                                            translations={translations}
                                            currentLanguage={utils.currentLanguage()}
                                            checkedProductsId={checkedProductsId}
                                            setCheckedProductsId={setCheckedProductsId}
                                            additionalSearchConfig={{ categoryId: categoryId, inSubcategories: true }}
                                        />
                                    }
                                </Row>
                            </Container>

                            <SectionEditFormButtons formReset={form.reset} onSubmit={handleSubmit}/>
                        </>
                    );
                }}
            />
        </Container>
    );
};

CategoryEditBasicSection.propTypes = propTypes;

const mapDispatch = {
    updateCategoryBasicData,
    uploadCategoryImage
};

export default withFormUtils(connect(null, mapDispatch)(CategoryEditBasicSection));