import { cAPI } from '../../../utils/cAPI';
import { addEditData, getData } from '../generic';

export const getOrders = searchConfig => dispatch => getData(dispatch, cAPI.APGetOrdersSearchResult, searchConfig, 'orders');
export const getOrder = id => dispatch => getData(dispatch, cAPI.APGetOrder, id, 'orders');
export const updateOrderDetails = (orderId, formData) => dispatch => addEditData(dispatch, cAPI.APUpdateOrder, { orderId: orderId, ...formData }, 'orders');

export const getOrderGreetings = () => dispatch => getData(dispatch, cAPI.APGetOrderGreetings);
export const updateOrderGreetings = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdateOrderGreetings, formData);

export const updateOrderItemRealized = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdateOrderItemRealized, formData, 'orders');