import Categories from '../../views/Categories';
import CategoriesEdit from '../../views/CategoriesEdit';
import Features from '../../views/Features';
import FeaturesEdit from '../../views/FeaturesEdit';
import Attributes from '../../views/Attributes';
import AttributesEdit from '../../views/AttributesEdit';
import PaymentServicesList from '../../views/services/PaymentServicesList';
import ShipmentServicesList from '../../views/services/ShipmentServicesList';
import OtherServicesList from '../../views/services/OtherServicesList';
import Manufacturers from '../../views/Manufacturers';
import ManufacturersEdit from '../../views/ManufacturersEdit';
import List from '../../views/productPresentation/List';
import General from '../../views/productPresentation/General';
import Comparison from '../../views/productPresentation/Comparison';
import Tile from '../../views/productPresentation/Tile';
import Card from '../../views/productPresentation/Card';
import Quantity from '../../views/productPresentation/Quantity';
import SearchEngine from '../../views/extrasSettings/SearchEngine';
import Synchronizations from '../../views/Synchronizations';
import Units from '../../views/productPresentation/Units';
import ProductsList from '../../views/products/List';
import CategoryAssign from '../../views/products/CategoryAssign';
import ProductsEdit from '../../views/ProductsEdit';
import PaymentsEdit from '../../views/PaymentsEdit';
import ShipmentsEdit from '../../views/ShipmentsEdit';
import OtherServicesEdit from '../../views/OtherServicesEdit';
import OrderStatuses from '../../views/extrasSettings/OrderStatuses';
import OrderGreetings from '../../views/extrasSettings/OrderGreetings';
import GeneralSettings from '../../views/forms/GeneralSettings';
import ShipmentMethodsSettings from '../../views/forms/ShipmentMethodsSettings';
import PaymentMethodsSettings from '../../views/forms/PaymentMethodsSettings';
import ProductCardSections from '../../views/productCardSettings/ProductCardSections';
import Delivery from '../../views/productPresentation/Delivery';
import SectionsEdit from '../../views/homePageSettings/SectionsEdit';
import ProductsDescriptionsImport from '../../views/ProductsDescriptionsImport';

export const articlesAndOrdersRoutes = {
    // Synchronizacja z ERP
    ROUTE_SYNCHRONIZATIONS: {
        id: 'ROUTE_SYNCHRONIZATIONS',
        path: '/synchronizations',
        type: 'articlesAndOrders',
        exact: true,
        icon: 'cil-sync',
        component: Synchronizations
    },

    // Import opisów artykułów
    ROUTE_PRODUCTS_DESCRIPTIONS_IMPORT: {
        id: 'ROUTE_PRODUCTS_DESCRIPTIONS_IMPORT',
        path: '/productsdescriptionsimport',
        type: 'articlesAndOrders',
        exact: true,
        icon: 'fal fa-file-import',
        component: ProductsDescriptionsImport
    },

    // Asortyment
    ROUTE_ASSORTMENT: {
        id: 'ROUTE_ASSORTMENT',
        path: '#',
        type: 'articlesAndOrders',
        exact: true,
        icon: 'fal fa-inventory',
        children: [
            {
                id: 'ROUTE_ASSORTMENT_CATEGORIES',
                path: '/categories',
                type: 'main',
                icon: 'fal fa-folder-tree',
                exact: true,
                component: Categories
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCTS',
                path: '/products/list',
                exact: true,
                component: ProductsList,
                icon: 'cil-list'
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCTS_CATEGORY_ASSIGN',
                path: '/products/categoryAssign',
                component: CategoryAssign,
                icon: 'fal fa-network-wired'
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_TILE',
                path: '/articlePresentation/tile',
                component: Tile,
                icon: 'fal fa-bring-front'
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_LIST',
                path: '/articlePresentation/list',
                component: List,
                icon: 'fal fa-digital-tachograph'
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_CARD',
                path: '/articlePresentation/card',
                component: Card,
                icon: 'fal fa-sliders-h-square'
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_CARD_SECTIONS',
                path: '/articlePresentation/sections',
                exact: true,
                component: ProductCardSections,
                icon: 'fal fa-tasks-alt'
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_GENERAL',
                path: '/articlePresentation/general',
                component: General,
                icon: 'fal fa-ballot-check'
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_COMPARISON',
                path: '/articlePresentation/comparison',
                component: Comparison,
                icon: 'fal fa-ballot-check'
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_QUANTITY',
                path: '/articlePresentation/quantity',
                component: Quantity,
                icon: 'fal fa-cubes'
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_UNITS',
                path: '/articlePresentation/units',
                component: Units,
                icon: 'fal fa-ruler-combined'
            },
            {
                id: 'ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_DELIVERY',
                path: '/articlePresentation/delivery',
                component: Delivery,
                icon: 'fal fa-truck'
            },
            {
                id: 'ROUTE_ASSORTMENT_SHIPMENT_SERVICES',
                path: '/shipmentServices/list',
                exact: true,
                component: ShipmentServicesList,
                icon: 'fal fa-hand-receiving'
            },
            {
                id: 'ROUTE_ASSORTMENT_PAYMENT_SERVICES',
                path: '/paymentServices/list',
                exact: true,
                component: PaymentServicesList,
                icon: 'fal fa-handshake'
            },
            {
                id: 'ROUTE_ASSORTMENT_OTHER_SERVICES',
                path: '/otherServices/list',
                exact: true,
                component: OtherServicesList,
                icon: 'fal fa-people-carry'
            },
            {
                id: 'ROUTE_ASSORTMENT_SEARCH_ENGINE',
                path: '/extras/searchEngine',
                component: SearchEngine,
                icon: 'cil-search'
            },
            {
                id: 'ROUTE_ASSORTMENT_MANUFACTURERS',
                path: '/manufacturers',
                type: 'main',
                exact: true,
                icon: 'fal fa-industry-alt',
                component: Manufacturers
            },
            {
                id: 'ROUTE_ASSORTMENT_ATTRIBUTES',
                path: '/attributes',
                type: 'main',
                icon: 'fal fa-bookmark',
                exact: true,
                component: Attributes
            },
            {
                id: 'ROUTE_ASSORTMENT_FEATURES',
                path: '/features',
                type: 'main',
                icon: 'cil-color-palette',
                exact: true,
                component: Features
            },
        ]
    },

    ROUTE_ASSORTMENT_CATEGORIES_EDIT: {
        id: 'ROUTE_ASSORTMENT_CATEGORIES_EDIT',
        path: '/categories/edit',
        component: CategoriesEdit
    },

    ROUTE_ASSORTMENT_PRODUCTS_EDIT: {
        id: 'ROUTE_ASSORTMENT_PRODUCTS_EDIT',
        path: '/products/list/edit',
        exact: true,
        component: ProductsEdit
    },

    ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_CARD_SECTIONS_EDIT: {
        id: 'ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_CARD_SECTIONS_EDIT',
        path: '/articlePresentation/sections/edit',
        component: SectionsEdit,
    },

    ROUTE_ASSORTMENT_SHIPMENT_SERVICES_EDIT: {
        id: 'ROUTE_ASSORTMENT_SHIPMENT_SERVICES_EDIT',
        path: '/shipmentServices/list/edit',
        exact: true,
        component: ShipmentsEdit
    },

    ROUTE_ASSORTMENT_PAYMENT_SERVICES_EDIT: {
        id: 'ROUTE_ASSORTMENT_PAYMENT_SERVICES_EDIT',
        path: '/paymentServices/list/edit',
        exact: true,
        component: PaymentsEdit
    },

    ROUTE_ASSORTMENT_OTHER_SERVICES_EDIT: {
        id: 'ROUTE_ASSORTMENT_OTHER_SERVICES_EDIT',
        path: '/otherServices/list/edit',
        exact: true,
        component: OtherServicesEdit
    },

    ROUTE_ASSORTMENT_MANUFACTURERS_EDIT: {
        id: 'ROUTE_ASSORTMENT_MANUFACTURERS_EDIT',
        path: '/manufacturers/edit',
        component: ManufacturersEdit,
    },

    ROUTE_ASSORTMENT_ATTRIBUTES_EDIT: {
        id: 'ROUTE_ASSORTMENT_ATTRIBUTES_EDIT',
        path: '/attributes/edit',
        component: AttributesEdit
    },

    ROUTE_ASSORTMENT_FEATURES_EDIT: {
        id: 'ROUTE_ASSORTMENT_FEATURES_EDIT',
        path: '/features/edit',
        component: FeaturesEdit
    },

    ROUTE_ORDERS: {
        id: 'ROUTE_ORDERS',
        path: '#',
        type: 'articlesAndOrders',
        exact: true,
        icon: 'fal fa-dolly-flatbed-alt',
        children: [
            /*{
                id: 'ROUTE_ORDERS_GENERAL',
                path: '#',
                exact: true,
                component: null,
                icon: 'fal fa-shapes'
            },*/
            {
                id: 'ROUTE_ORDERS_SHIPPING_AND_PAYMENT_GENERAL',
                path: '/shipping/general',
                exact: true,
                component: GeneralSettings,
                icon: 'fal fa-money-check-edit-alt'
            },
            {
                id: 'ROUTE_ORDERS_SHIPPING_METHODS',
                path: '/shipping/list',
                exact: true,
                component: ShipmentMethodsSettings,
                icon: 'fal fa-pallet-alt'
            },
            {
                id: 'ROUTE_ORDERS_PAYMENT_METHODS',
                path: '/payment/list',
                exact: true,
                component: PaymentMethodsSettings,
                icon: 'fal fa-money-bill-alt'
            },
            {
                id: 'ROUTE_ORDERS_STATUSES',
                path: '/extras/orderStatuses',
                component: OrderStatuses,
                icon: 'fal fa-bells',
                exact: true,
            },
            {
                id: 'ROUTE_ORDERS_GREETINGS',
                path: '/extras/orderGreetings',
                exact: true,
                component: OrderGreetings,
                icon: 'fal fa-shapes'
            },
            /*{
                id: 'ROUTE_ORDERS_NOTIFYCATIONS',
                path: '#',
                exact: true,
                component: null,
                icon: 'fal fa-bell-on'
            },
            {
                id: 'ROUTE_ORDERS_CART',
                path: '#',
                exact: true,
                component: null,
                icon: 'fal fa-shopping-cart'
            },*/
        ]
    }
};