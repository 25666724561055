import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { getAssignedManufacturersBySectionId, addDeleteAssignedManufacturers, moveManufacturersItem } from '../../../../../redux/methods/homePage';

import useFetch from '../../../../../hooks/useFetch';

import ManufacturersForHomePageTable from '../../../../../components/tables/ManufacturersForHomePageTable';
import SectionAsideButtons from '../../../../../components/SectionAsideButtons';
import AssignManufacturersModal from './AssignManufacturersModal';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getAssignedManufacturersBySectionId: PropTypes.func,
    moveManufacturersItem: PropTypes.func,
    addDeleteAssignedManufacturers: PropTypes.func
};

const ManufacturersTable = ({ getAssignedManufacturersBySectionId, section, forceRefetch, moveManufacturersItem, addDeleteAssignedManufacturers }) => {

    const [ assignedManufacturers ] = useFetch(getAssignedManufacturersBySectionId, section.id, [], forceRefetch);
    const [ checkedProductsIds, setCheckedProductsIds ] = useState([]);
    const [ isOpen, setIsOpen ] = useState(false);

    const translations = useIntl().messages.homePage.manufacturersSection.manufacturers;

    const decreaseOrder = ({ id }) => {
        moveManufacturersItem({
            ManufacturerSectionItemId: id,
            Delta: -1
        });
    };

    const increaseOrder = ({ id }) => {
        moveManufacturersItem({
            ManufacturerSectionItemId: id,
            Delta: 1
        });
    };

    const removeHandler = id => {
        addDeleteAssignedManufacturers({
            ManufacturerSectionId: section.id,
            ManufacturerIds: [id],
            Delete: true
        });
    };

    const removeItems = () => {
        addDeleteAssignedManufacturers({
            ManufacturerSectionId: section.id,
            ManufacturerIds: checkedProductsIds,
            Delete: true
        });
    };

    const openModal = () => {
        setIsOpen(true);
    };

    return assignedManufacturers ? (
        <React.Fragment>
            <ManufacturersForHomePageTable
                checkedProductsIds={checkedProductsIds}
                setCheckedProductsIds={setCheckedProductsIds}
                removeHandler={removeHandler}
                decreaseOrder={decreaseOrder}
                increaseOrder={increaseOrder}
                manufacturersList={assignedManufacturers}
            />
            <SectionAsideButtons
                buttons={{
                    add: {
                        color: 'success',
                        label: translations.buttons.add,
                        action: openModal,
                        iconClass: 'fa fa-save mr-2'
                    },
                    remove: {
                        color: 'danger',
                        label: translations.buttons.remove,
                        action: removeItems,
                        isDisabled: checkedProductsIds.length === 0
                    }
                }}
            />
            {isOpen && <AssignManufacturersModal isOpen={isOpen} setIsOpen={setIsOpen} id={section.id} />}
        </React.Fragment>
    ) : null;
};

ManufacturersTable.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.homePageManufacturers
});

const mapDispatch = {
    getAssignedManufacturersBySectionId,
    addDeleteAssignedManufacturers,
    moveManufacturersItem
};

export default connect(mapStateToProps, mapDispatch)(ManufacturersTable);