import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isNotificationDropdownOpen: false,
};

const notifications = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setIsNotificationDropdownOpen(state, action) {
            const { isOpen } = action.payload;

            state.isNotificationDropdownOpen = isOpen;
        },
    }
});

export const { setIsNotificationDropdownOpen } = notifications.actions;

export default notifications.reducer;