import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';

import { getTranslationForIso } from '../../../utils/components';
import useFetch from '../../../hooks/useFetch';

import { productTileFillerUrlTypeEnum } from '../../../enums/products';

import { Form as FinalForm } from "react-final-form";
import { Form, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import FormFieldTextOrNumber from "../../../components/formFields/FormFieldTextOrNumber";
import FormFieldTranslations from '../../../components/formFields/FormFieldTranslations';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import WarningModal from '../../../components/modals/WarningModal';
import ImageTileElement from '../../homePage/bannerTabs/BannerImages/EditBannerImageModal/ImageTileElement';
import FormFieldWithCheckbox from "../../../components/formFields/FormFieldWithCheckbox";

import styles from './index.module.scss';

const propTypes = {
    translations: PropTypes.object,
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    handleUpload: PropTypes.func,
    handleRemove: PropTypes.func,
    fillerId: PropTypes.number,
};

const ProductTileFillerEditionModal =  ({
    translations,
    isModalOpen,
    setIsModalOpen,
    onSubmit,
    utils,
    handleUpload,
    handleRemove,
    fillerId,
    getFiller,
    forceRefetch,
}) => {
    const config = { translations: ['header', 'buttonLabel', 'description', 'imageAlternativeText'] };

    const isFormChanged = useRef();
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);

    const toggleModal = () => {
        if (isFormChanged.current) {
            setIsWarningModalOpen(true);
        }
        else {
            setIsModalOpen(!isModalOpen);
        }
    };

    const [ fillerToEdit ] = useFetch(getFiller, fillerId, [ fillerId ], forceRefetch);

    const handleSubmit = form => {
        onSubmit(utils.generateFormToSubmitValues(form, config));
        setIsModalOpen(!isModalOpen);
    };

    const uploadFileHandler = (id) => (data) => {
        handleUpload(id, data);
    };

    const removeFileHandler = () => {
        handleRemove(fillerId);
    };

    const urlTypeOptions = Object.keys(productTileFillerUrlTypeEnum).map(key => ({
        id: key,
        label: translations.inputs.urlTypes[productTileFillerUrlTypeEnum[key]]
    }));

    return (
        <Modal isOpen={isModalOpen} toggle={toggleModal} size='lg'>
            <ModalHeader toggle={toggleModal}>{translations.header}</ModalHeader>
            <FinalForm
                onSubmit={handleSubmit}
                initialValues={utils.generateFormInitialValues(fillerToEdit ? fillerToEdit : {}, config)}
                render={({ handleSubmit , form, values, pristine }) => {
                    isFormChanged.current = !pristine;
                    const currentUrlTypeOption = urlTypeOptions.find(option => option.id === values.fillerUrlTypeId);

                    return (
                        <Form onSubmit={handleSubmit} className='w-100 p-0'>
                            <ModalBody>
                                <ImageTileElement
                                    imageUrl={fillerToEdit ? fillerToEdit.image : null}
                                    buttonsTranslations={translations.inputs.file}
                                    uploadFileHandler={uploadFileHandler(fillerId)}
                                    removeFileHandler={removeFileHandler}
                                    imageAlternativeText={fillerToEdit ? getTranslationForIso(fillerToEdit.imageAlternativeText, utils.currentLanguage()) : null}
                                    fileKey='image'
                                />
                                <div className='mb-3'>
                                    <FormFieldWithCheckbox
                                        name='isDouble'
                                        label={translations.inputs.double}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <FormFieldTranslations
                                        form={form}
                                        modalHeader={translations.inputs.alternativeText}
                                        propertyName='imageAlternativeText'
                                        currentIso={utils.currentLanguage()}
                                        label={translations.inputs.alternativeText}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <FormFieldTranslations
                                        form={form}
                                        modalHeader={translations.inputs.header}
                                        propertyName='header'
                                        currentIso={utils.currentLanguage()}
                                        label={translations.inputs.header}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <FormFieldTranslations
                                        form={form}
                                        modalHeader={translations.inputs.description}
                                        propertyName='description'
                                        currentIso={utils.currentLanguage()}
                                        label={translations.inputs.description}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <FormFieldTranslations
                                        form={form}
                                        modalHeader={translations.inputs.buttonLabel}
                                        propertyName='buttonLabel'
                                        currentIso={utils.currentLanguage()}
                                        label={translations.inputs.buttonLabel}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <FormFieldTextOrNumber
                                        name='redirectUrl'
                                        label={translations.inputs.link}
                                    />
                                </div>
                                <div className={clsx('mb-3', styles.dropdown)}>
                                    <FormFieldWithDropdown
                                        name='fillerUrlTypeId'
                                        label={translations.inputs.urlType}
                                        options={urlTypeOptions}
                                        currentSelectionLabel={currentUrlTypeOption ? currentUrlTypeOption.label : 'Wybierz'}
                                    />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" type="submit">
                                    {translations.actions.save}
                                </Button>
                                <Button color="secondary" type="button" onClick={toggleModal}>
                                    {translations.actions.cancel}
                                </Button>
                            </ModalFooter>
                            <WarningModal
                                setIsOpen={setIsWarningModalOpen}
                                isOpen={isWarningModalOpen}
                                setIsMainModalOpen={setIsModalOpen}
                            />
                        </Form>
                    );
                }}
            />
        </Modal>
    );
};

ProductTileFillerEditionModal.propTypes = propTypes;

export default ProductTileFillerEditionModal;

