/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Dropdown from '../../../../components/Dropdown';
import { Alert } from 'reactstrap';

import { getShipmentServices } from '../../../../redux/methods/shipments';

import useFetch from '../../../../hooks/useFetch';

import styles from './index.module.scss';

const propTypes = {
    selectedService: PropTypes.number,
    setSelectedService: PropTypes.func,
    setNextStep: PropTypes.func,
    getShipmentServices: PropTypes.func,
};

const ServiceSelection = ({
    label,
	selectedService,
    setSelectedService,
    setNextStep,
    getShipmentServices,
	translations
}) => {

    const [ services ] = useFetch(getShipmentServices);

    if (services && services.services.length === 1){
        setSelectedService(services.services[0].id);
        setNextStep();
    }

    const generateShipmentServicesDropdownOptions = (services) => {
        return services.services.map(service => ({
            id: service.id,
            label: service.name
        }));
    };

    const handleSelection = (id) => {
        setSelectedService(id);
    };

    const servicesOptions = services ? generateShipmentServicesDropdownOptions(services) : [];

    const generateSelectionLabel = () => {
        if(servicesOptions.length === 0) {
            return translations.none;
        }

        if (selectedService === null) {
            return translations.selectService;
        }
        else {
            return servicesOptions.find(service => service.id === selectedService).label;
        }
    };

    return services ? (
        <div>
            <Alert isOpen={servicesOptions.length === 0} color='danger'>
                {translations.noServices}
            </Alert>
            <div className={clsx('mb-3', styles.dropdown)}>
                <strong>{label}</strong>
                <Dropdown
                    className='mt-3'
                    options={servicesOptions}
                    checkedIds={selectedService}
                    performSelection={handleSelection}
                    currentSelectionLabel={generateSelectionLabel()}
                />
            </div>
        </div>
    ) : null;
};

ServiceSelection.propTypes = propTypes;

const mapStateToProps = state => ({
});

const mapDispatch = {
    getShipmentServices
};

export default connect(mapStateToProps, mapDispatch)(ServiceSelection);