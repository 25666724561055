import { handleCAPIErrorsSubtle, cAPI } from '../../../utils/cAPI';
import { addEditData } from '../generic';
import { setUser, setAfterVerification, setLoadingByTarget, setIsUnauthorized } from '../../../features/auth/slice';
import { setIsLoading, setIsLoaded } from '../../../features/loaders/slice';

import { FORM_ERROR } from 'final-form';

export const verifyUser = () => async dispatch => {
    const { payLoad, error, httpError, unhandledError } = await cAPI.APVerifyCookie();

    if (payLoad) {
        const { user } = payLoad;
        dispatch(setUser({ user }));
        dispatch(setAfterVerification({ afterVerification: true }));
    } else {
        if (httpError) {
            switch (httpError.status) {
                case 401:
                    console.error('401 - unauthenticated');
                    break;
                case 403:
                    console.error('403 - unauthorized');
                    dispatch(setIsUnauthorized({ isUnauthorized: true }));
                    await cAPI.APLogout();
                    break;
                default: console.error(httpError.status);
            }
        } else {
            handleCAPIErrorsSubtle({ error, httpError, unhandledError }, dispatch);
        }
        dispatch(setAfterVerification({ afterVerification: true }));
    }
};

export const handleLogin = (formData, chosenLanguageId) => async dispatch => {
    dispatch(setLoadingByTarget({ target: 'login', isLoading: true }));

    const updatedFormData = {
        ...formData,
        languageId: chosenLanguageId
    };

    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APLogin(updatedFormData);
    const receivedError = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { user, errors } = payLoad;
        const areValidationErrorsPresent = !errors.username.isValid || !errors.password.isValid || !errors.form.isValid;

        if (user) {
            dispatch(setUser({ user: user }));
            dispatch(setLoadingByTarget({ target: 'login', isLoading: false }));
            return;
        } else if (areValidationErrorsPresent) {
            dispatch(setLoadingByTarget({ target: 'login', isLoading: false }));
            return {
                username: errors.username.message,
                password: errors.password.message,
                [FORM_ERROR]: errors.form.message
            };
        }
    } else if (receivedError) {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
        dispatch(setLoadingByTarget({ target: 'login', isLoading: false }));
    }
};

export const handleRegistration = formData => dispatch => {
    /**
     * Method serves only as a placeholder
     */
    dispatch(setLoadingByTarget({ target: 'login', isLoading: true }));

    if (formData) {
        if (!formData.username) {
            dispatch(setLoadingByTarget({ target: 'login', isLoading: false }));
            return {
                username: 'error'
            };
        }
        if (!formData.password) {
            dispatch(setLoadingByTarget({ target: 'login', isLoading: false }));
            return {
                password: 'error'
            };
        }
    } else {
        dispatch(setLoadingByTarget({ target: 'login', isLoading: false }));
        return {
            [FORM_ERROR]: 'test'
        };
    }
};

export const handleLogout = () => async dispatch => {
    const { error, unhandledError, httpError, statusCode } = await cAPI.APLogout();
    const receivedError = error || httpError || unhandledError || statusCode !== 200;

    if (receivedError) {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    } else {
        dispatch(setUser({ user: null }));
    }
};

export const forcedLogout = () => async dispatch => {
    dispatch(setIsLoading());
    await cAPI.APLogout();
    dispatch(setUser({ user: null }));
    dispatch(setIsLoaded());
};

export const recoverPasswordRequest = (formData) => dispatch => addEditData(dispatch, cAPI.APRecoverPasswordRequest, formData);
export const recoverPasswordPerform = (formData) => dispatch => addEditData(dispatch, cAPI.APRecoverPasswordPerform, formData);
export const changePassword = (formData) => dispatch => addEditData(dispatch, cAPI.APChangePassword, formData);
export const changeEmailRequest = (formData) => dispatch => addEditData(dispatch, cAPI.APChangeEmailRequest, formData);
export const changeEmailPerform = (formData) => dispatch => addEditData(dispatch, cAPI.APChangeEmailPerform, formData);