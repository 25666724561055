import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Alert } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    isSuccess: PropTypes.bool,
    showTimeInMs: PropTypes.number,
    text: PropTypes.string,
};

const SuccessSaveToastWithoutRedux = ({
    isSuccess,
    showTimeInMs = 4000,
    text,
}) => {
    const intl = useIntl();
    const translations = intl.messages.defaultLayout.appGlobal.editResult;

    const unmounted = useRef(false);
    const [ isOpen, setIsOpen ] = useState(false);

    const handleToggle = () => setIsOpen(false);
    useEffect(() => {
        unmounted.current = false;
        if (isSuccess && !unmounted.current) {
            setIsOpen(true);

            setTimeout(() => {
                handleToggle();
            }, showTimeInMs);
        }

        return () => { unmounted.current = true; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isSuccess ]);

    return (
        <Alert
            className={styles.alert}
            color='success'
            isOpen={isOpen}
            toggle={handleToggle}
        >
        {text || translations.success}
        </Alert>
    );
};

SuccessSaveToastWithoutRedux.propTypes = propTypes;

export default SuccessSaveToastWithoutRedux;