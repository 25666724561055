import { cAPI } from '../../../utils/cAPI';
import { addEditData, getData } from '../generic';

export const getPhotoNameOptionsForDropdown = () => dispatch => getData(dispatch, cAPI.APGetPhotoNameOptionsForDropdown);
export const getPhotoName = () => dispatch => getData(dispatch, cAPI.APGetPhotoName, null, 'seoPhotoName');
export const updatePhotoName = formData => dispatch => addEditData(dispatch, cAPI.APUpdatePhotoName, formData, 'seoPhotoName');

export const getGoogleSiteMap = () => dispatch => getData(dispatch, cAPI.APGetGoogleSiteMap, null, 'seoGoogleSiteMap');
export const generateGoogleSiteMap = () => dispatch => getData(dispatch, cAPI.APGenerateGoogleSiteMap);
export const updateGoogleSiteMap = formData => dispatch => addEditData(dispatch, cAPI.APUpdateGoogleSiteMap, formData, 'seoGoogleSiteMap');

export const getOwnCode = () => dispatch => getData(dispatch, cAPI.APGetOwnCode, null);
export const updateOwnCode = formData => dispatch => addEditData(dispatch, cAPI.APUpdateOwnCode, formData);
