import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { getKeyByValue, firstLetterToLowerCase } from '../../../../../utils/components';
import { generateProps } from '../../../../../utils/discounts';
import { discountRangeEnum } from '../../../../../enums/discounts';
import { Col } from 'reactstrap';

import FormFieldTextOrNumber from '../../../../../components/formFields/FormFieldTextOrNumber';

import { discountParams } from '../../../../../config';
import { stringOrNumberPropTypes } from '../../../../../propTypes';

const propTypes = {
    rangeId: stringOrNumberPropTypes,
    isDiscountRangeSpecificProducts: PropTypes.bool,
};

const DiscountEditFieldGenerator = ({ rangeId, isDiscountRangeSpecificProducts }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.parameters.fields;
    const rangeConfig = discountParams[getKeyByValue(discountRangeEnum, rangeId)];

    return (
        rangeConfig.map(component => (
            <Col xs='12' md='6' className='p-2' key={component.name}>
                <FormFieldTextOrNumber
                    type='number'
                    min={0}
                    {...generateProps(component, translations[firstLetterToLowerCase(component.name)], isDiscountRangeSpecificProducts)}
                />
            </Col>
        ))
    );
};

DiscountEditFieldGenerator.propTypes = propTypes;

export default DiscountEditFieldGenerator;