import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import useFetch from '../../hooks/useFetch';
import { getManufacturer } from '../../redux/methods/manufacturers';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';
import ManufacturerEditBasic from '../../features/manufacturers/editTabs/ManufacturerEditBasic';

import { withFormUtils } from '../../hoc/withFormUtils';

import { translateManufacturer } from '../../utils/manufacturers';

const propTypes = {
    location: PropTypes.object,
    getManufacturers: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const ManufacturersEdit = ({
    location,
    getManufacturer,
    forceRefetch,
    utils
}) => {
    const intl = useIntl();
    const translations = intl.messages.currencies.edit;
    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);
    const manufacturerId = query.get('manufacturerID');

    const [ manufacturer ] = useFetch(getManufacturer, manufacturerId, [], forceRefetch);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'currencyEditTab-basic':
                return <ManufacturerEditBasic manufacturerToEditId={manufacturer.id} />;
            default:
                return null;
        }
    };

    /**
     * If no manufacturer is selected for edition, redirect user back to manufacturers list
     */
    return manufacturerId ? (
        manufacturer && manufacturer.id ? (
            <SettingsTabulator
                title={{
                    label: translateManufacturer(manufacturer, utils).name || translations.fallbackName,
                    iconClassName: 'fa fa-edit text-primary ml-3',
                    isFilled: translateManufacturer(manufacturer, utils).name ? false : true,
                }}
                tabulatorHeader={{
                    label: translations.tabSelectorTitle,
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: 'currencyEditTab-basic',
                        header:  {
                            label: translations.tabs.basic.title,
                            iconClassName: 'fa fa-tasks'
                        }
                    },
                ]}
            />
        ) : null
    ) : (
        <Redirect to='/manufacturers'/>
    );
};

ManufacturersEdit.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.manufacturers,
});

const mapDispatch = {
    getManufacturer
};

export default withFormUtils(withRouter(connect(mapStateToProps, mapDispatch)(ManufacturersEdit)));