import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ButtonGroup, Button, Form, Alert } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';
import FormFieldWithCalendarAndTimePicker from '../formFields/FormFieldWithCalendarAndTimePicker';
import FormFieldSwitchWithTwoLabels from '../formFields/FormFieldSwitchWithTwoLabels';

const propTypes = {
    performSelect: PropTypes.func,
    options: PropTypes.array,
    className: PropTypes.string,
    currentSelection: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    submitCustomRange: PropTypes.func,
    dateFrom: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    dateTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    setDateFrom: PropTypes.func,
    setDateTo: PropTypes.func,
    locale: PropTypes.string,
    customRangeTranslation: PropTypes.string,
    setIsAlertOpen: PropTypes.func,
};

const ChartDatePicker = ({
    performSelect,
    options,
    className,
    currentSelection,
    customRangeTranslation,
    onSubmit,
    utils,
    finalFormConfig,
    translations,
    searchConfig,
}) => {
    const handleSelect = (option) => () => {
        performSelect(option);
    };

    const handleOnSubmit = form => {
        onSubmit(form);
    };

    return (
        <div className={className}>
            <ButtonGroup size='sm'>
                {
                    options.map(option =>
                        <Button
                            key={`${option}--pageSize`}
                            onClick={handleSelect(option)}
                            outline={currentSelection !== option ? true : false}
                            color='primary'
                        >
                            {option}
                        </Button>
                    )
                }
            </ButtonGroup>
            <div className={clsx(currentSelection !== customRangeTranslation && 'd-none')}>
                <FinalForm
                    keepDirtyOnReinitialize
                    onSubmit={handleOnSubmit}
                    initialValues={utils.generateFormInitialValues(searchConfig, finalFormConfig)}
                    render={({ handleSubmit, values }) => {
                        // eslint-disable-next-line no-unused-expressions
                        return (
                            <React.Fragment>
                                <Form onSubmit={handleSubmit} >
                                <FormFieldSwitchWithTwoLabels
                                    name='searchByUserTime'
                                    labels={{
                                        true: translations.labels.searchByUserTime,
                                        false: translations.labels.searchByAdminTime
                                    }}
                                />
                                <div className='d-md-flex'>
                                    <FormFieldWithCalendarAndTimePicker name='dateFrom' />
                                    <FormFieldWithCalendarAndTimePicker name='dateTo' />
                                    <Button
                                        disabled={values.dateFrom > values.dateTo}
                                        onClick={handleSubmit}
                                        color="primary"
                                    >
                                        <i className={clsx("fa fa-search")}/>
                                    </Button>
                                </div>
                                </Form>
                                <Alert color="danger" isOpen={values.dateFrom > values.dateTo}>
                                        {translations.incorrectDatesAlert}
                                </Alert>
                            </React.Fragment>
                        );
                    }}
                />
            </div>
        </div>
    );
};

ChartDatePicker.propTypes = propTypes;

export default ChartDatePicker;