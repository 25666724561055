import { additionalCurrencyPriceEnum, promoPriceTypesEnum } from '../../enums/products';
import getConfig from '../../getConfig';

const shopUrl = getConfig().NEXT_SHOP_EXTERNAL_URL;

export const generateTranslatedProductPhotoList = (photoList, iso) => {
    return photoList.map(photo => {
        const translation = photo.translations.find(translation => iso.toLowerCase() === (translation.iso && translation.iso.toLowerCase()));
        return {
            ...photo,
            translatedAlternativeText: translation && translation.value
        };
    });
};

export const generateTranslatedProductPhoto = (photo, iso) => {
    const translation = photo.translations.find(translation => iso.toLowerCase() === (translation.iso && translation.iso.toLowerCase()));
    return {
        ...photo,
        translatedAlternativeText: translation && translation.value
    };
};

export const checkIsDefaultAlignment = alignments => {
    const notDefaultAlignments = alignments.filter(({ verticalAlignment, horizontalAlignment }) =>
        verticalAlignment !== 'Center' || horizontalAlignment !== 'Center'
    );

    return notDefaultAlignments.length === 0;
};

export const generateTranslatedProductName = (translations, iso) => {
    const translatedName = translations.find(translation => translation.iso.toLowerCase() === iso.toLowerCase());
    return translatedName && translatedName.value;
};

export const translateProductName = (products, language, withCategoryForFeature = false) => {
    return products.map(product => {
        const categoryForFeature = withCategoryForFeature && product.categories &&
            product.categories.find(category => category.categoryId === product.categoryForAttributesId);

        return ({
            lp: product.lp,
            productId: product.id,
            productTranslatedName: generateTranslatedProductName(product.translations || product.name, language),
            productImage: product.productImage,
            catalogueIndex: product.catalogueIndex,
            tradeIndex: product.tradeIndex,
            categoryForFeature: categoryForFeature,
            status: product.status,
            variantType: product.productVariantType,
            warning: product.warning,
            isPromotion: product.isPromotion,
            promotionInfo: product.promotionInfo,
            priceInfo: product.priceInfo,
            manufacturerIndex: product.manufacturerIndex,
            ean: product.ean,
            distinguisher: product.distinguisher,
            field1: product.field1,
            field2: product.field2,
            field3: product.field3,
            field4: product.field4,
            field5: product.field5,
            field6: product.field6,
            field7: product.field7,
            field8: product.field8,
            field9: product.field9,
            field10: product.field10
        });
    });
};

export const generateMeasuresDropdownOptions = (options, anyLabelTranslation) => {
    const measuresOptions = options.map(option => ({
        id: parseInt(option.id),
        label: option.name
    }));

    return [
        {
            id: null,
            label: anyLabelTranslation
        },
        ...measuresOptions
    ];
};

export const generateProductEditLink = (id, item) => {
    const productTypeId = item?.productTypeId ?? 1;
    switch(productTypeId){
        default:
        case 1:
            return `/products/list/edit?productID=${id}`;
        case 2:
            return `/shipmentServices/list/edit?productID=${id}`;
        case 3:
            return `/paymentServices/list/edit?productID=${id}`;
        case 4:
            return `/otherServices/list/edit?serviceID=${id}`;
    }
};

export const generateInitialStateForAdditionalCurrencyPrice = (price) => {
    if (price.productPriceId && !price.priceRate && !price.netPrice) {
        return additionalCurrencyPriceEnum.exchangeRateTable;
    }
    else if (price.netPrice && !price.priceRate) {
        return additionalCurrencyPriceEnum.definedPrice;
    }
    else if (price.priceRate) {
        return additionalCurrencyPriceEnum.constantRate;
    }
};

export const generateInitialStateForPromoPrices = (price) => {
    if (price.priceRate) {
        return promoPriceTypesEnum.precentageDiscount;
    }
    else if (price.netPrice) {
        return promoPriceTypesEnum.definedPrice;
    }
    else {
        return promoPriceTypesEnum.undefined;
    }
};

export const generateCurrenciesOptionForTabs = (currencies, prices, onlyActiveCurrencies) => {
    const currenciesIds = [...new Set(prices.map(price => price.currencyId).filter(priceCurrency => onlyActiveCurrencies ?
        currencies.find(c => c.id === priceCurrency).active : true))];

    const tabList =  currenciesIds.map((priceCurrency) => ({
        iso: currencies.find(currency => currency.id === priceCurrency).id,
        label: currencies.find(currency => currency.id === priceCurrency).shortName,
    }));

    return tabList;
};

export const calculateGrossPrice = (netPrice, tax) => {
    const grossPrice = netPrice + netPrice * tax / 100;
    return grossPrice ? grossPrice.toFixed(2) : 0;
};

export const generateShopProductLink = (id, currentLanguage) => {
    return `${shopUrl}/${currentLanguage}/product/${id}/1`;
};

export const pickBadgeColor = (status) => {
    switch(status){
        case 'published': return 'success';
        case 'unpublished': return 'danger';
        default: return null;
    }
};

export const pickBadgeColorForType = (type) => {
    switch(type){
        case 'None': return 'secondary';
        case 'Set': return 'success';
        case 'Variant': return 'info';
        default: return null;
    }
};

export const generateStatusesConfig = (featuredEnum, translations) => {
    return Object.keys(featuredEnum).map(key => ({
        id: parseInt(key),
        label: translations[featuredEnum[key]],
        color: pickBadgeColor(featuredEnum[key])
    }));
};

export const generateTypeConfig = (typeList, translations) => typeList.map(type => ({
    id: type,
    labelForBadge: type,
    label: translations[type],
    color: pickBadgeColorForType(type)
}));