const auth = {
    login: {
        title: "Sign in",
        subTitle: "Sign in to Connectico",
        email: "Email*",
        password: "Password*",
        recovery: "Forgot password?",
        action: "Sign in",
    },
    sharedFiller: {
        title: "Welcome to Connectico",
        logoAltText: "Connectico Logo"
    },
    resetPassword: {
        send: '[EN] Wyślij',
        title: '[EN] Resetuj hasło',
        subTitle: '[EN] Na podany przez Ciebie adres email zostanie wysłane nowe hasło.',
        email: "Email*",
        backToLogin: '[EN] Wróć do logowania',
        submitSucceeded: '[EN] Link do zmiany hasła został wysłany na Twój adres email. Jeśli nie otrzymasz wiadomości w przeciągu godziny - prosimy skonktakuj się z administratorem.'
    }
};

export default auth;