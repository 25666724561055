import { sectionTypes } from "../../config";

const getLeftTopAnchorLocation = (position) => {
    switch (position.anchor) {
        case 'lt': return { left: position.left, top: position.top };
        case 'lb': return { left: position.left, top: position.top - position.height };
        case 'rt': return { left: position.left - position.width, top: position.top };
        case 'rb': return { left: position.left - position.width, top: position.top - position.height };
        default: return { left: 0, top: 0 };
    }
};

export const getAnchorCurrentLocation = (position, anchor) => {
    switch (anchor) {
        case 'lt': return { currentLeft: position.currentLeft, currentTop: position.currentTop };
        case 'lb': return { currentLeft: position.currentLeft, currentTop: position.currentTop + position.currentHeight };
        case 'rt': return { currentLeft: position.currentLeft + position.currentWidth, currentTop: position.currentTop };
        case 'rb': return { currentLeft: position.currentLeft + position.currentWidth, currentTop: position.currentTop + position.currentHeight };
        default: return { left: 0, top: 0 };
    }
};

export const getAnchorLocation = (position, anchor) => {
    const normalized = getLeftTopAnchorLocation(position);
    switch (anchor) {
        case 'lt': return { left: normalized.left, top: normalized.top };
        case 'lb': return { left: normalized.left, top: normalized.top + position.height };
        case 'rt': return { left: normalized.left + position.width, top: normalized.top };
        case 'rb': return { left: normalized.left + position.width, top: normalized.top + position.height };
        default: return { left: 0, top: 0 };
    }
};

export const generateEditLinkForHomePageEditSection = (id, item, location, affiliationObjectId) => {
    const locationPath = {
        mainPage: 'homePageSettings',
        productCard: 'articlePresentation',
        customPage: 'customPage',
        registrationSummary: 'registrationSummary',
        registrationConfirmation: 'registrationConfirmation',
        cart: 'cart'
    };

    const affiliationObjectIdParam = affiliationObjectId ? `&affiliationObjectId=${affiliationObjectId}` : '';

    if(item) {
        return `/${locationPath[location]}/sections/edit?sectionID=${id}&sectionType=${item.type}&location=${location}${affiliationObjectIdParam}`;
    }

    const foundSelectionType = sectionTypes.find(sec => sec.id === Math.abs(id));

    return `/${locationPath[location]}/sections/edit?sectionID=${id}&sectionType=${foundSelectionType && foundSelectionType.label}&location=${location}${affiliationObjectIdParam}`;
};