import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getAttributeBasicData } from '../../redux/methods/attributes';
import { getTranslationForIso } from '../../utils/shared';
import { selectStoreCurrentLanguage } from '../../redux/selectors/storeLocale';
import { attributeTypeEnum } from '../../enums/attributes';

import useFetch from '../../hooks/useFetch';
import SettingsTabulator from '../../components/layouts/SettingsTabulator';

import SharedEditBasicSection from '../../features/attributes/shared/SharedEditBasicSection';
import SharedAssignedCategoriesSection from '../../features/attributes/shared/SharedEditAssignedCategoriesSection';
import SharedEditValuesSection from '../../features/attributes/shared/SharedEditValuesSection';
import SharedEditSettingsSection from '../../features/attributes/shared/SharedEditSettingsSection';

const propTypes = {
    location: PropTypes.object,
    storeCurrentLanguage: PropTypes.string,
    getAttributeBasicData: PropTypes.func,
};

const FeaturesEdit = ({ location, storeCurrentLanguage, getAttributeBasicData }) => {
    const intl = useIntl();
    const translations = intl.messages.features.edit;

    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);
    const chosenAttributeId = parseInt(query.get('featID'));
    const chosenCategoryId = parseInt(query.get('catID'));

    const [ refetcher, setRefetcher ] = useState(0);
    const [ attributeId, setAttributeId ] = useState(chosenAttributeId);
    const [ categoryId ] = useState(chosenCategoryId);

    const [ data ] = useFetch(getAttributeBasicData, attributeId, [attributeId, refetcher]);

    const handleSetAttributeId = (id) => {
        setAttributeId(data.attributeId = id);
        setRefetcher(refetcher+1);
    };

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case "featEditTab-basic":
                return <SharedEditBasicSection data={data} categoryId={categoryId} handleAttributeSaved={handleSetAttributeId} />;
            case "featEditTab-categories":
                return <SharedAssignedCategoriesSection attributeId={chosenAttributeId} />;
            case "featEditTab-values":
                return <SharedEditValuesSection attributeId={attributeId} attributeType={data.attributeType} />;
            case "featEditTab-settings":
                return <SharedEditSettingsSection attributeId={attributeId} />;
            default:
                return null;
        }
    };

    /**
     * If no attribute is selected for edition, redirect user back to category list
     */
    return attributeId
        ? (
            data
                ? (
                    <SettingsTabulator
                        title={{
                            label: getTranslationForIso(data.name, storeCurrentLanguage) || translations.nameFiller,
                            isFilled: getTranslationForIso(data.name, storeCurrentLanguage) ? false : true,
                            iconClassName: "fa fa-edit text-primary ml-3"
                        }}
                        tabulatorHeader={{
                            label: translations.tabSelectorTitle,
                            iconClassName: "fa fa-align-justify"
                        }}
                        refreshTabComponent={refreshTabComponent}
                        tabs={[
                            {
                                id: "featEditTab-basic",
                                header:  {
                                    label: translations.tabs.basic.title,
                                    iconClassName: "fa fa-tasks"
                                }
                            },
                            attributeId !== null ?
                            {
                                id: "featEditTab-categories",
                                header:  {
                                    label: translations.tabs.categories.title,
                                    iconClassName: "cil-sitemap"
                                }
                            } : null,
                            attributeId > 0 && (
                                data.attributeType === attributeTypeEnum.tag ||
                                data.attributeType === attributeTypeEnum.dictionary ||
                                data.attributeType === attributeTypeEnum.color) ?
                            {
                                id: "featEditTab-values",
                                header:  {
                                    label: translations.tabs.values.title,
                                    iconClassName: "fa fa-pencil-square-o"
                                }
                            } : null,
                            attributeId > 0 ?
                            {
                                id: "featEditTab-settings",
                                header:  {
                                    label: translations.tabs.settings.title,
                                    iconClassName: "fa fa-cogs"
                                }
                            } : null,
                        ]}
                    />
                ) : null
        ) : (
            <Redirect to="/features"/>
        );
};

FeaturesEdit.propTypes = propTypes;

const mapStateToProps = state => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    getAttributeBasicData
};

export default withRouter(connect(mapStateToProps, mapDispatch)(FeaturesEdit));