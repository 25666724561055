/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { motion } from 'framer-motion';

import styles from './index.module.scss';

function isDescendant(older, younger) {
    return (
        !!older.children &&
        typeof older.children !== 'function' &&
        older.children.some(
            child => child === younger || isDescendant(child, younger)
        )
    );
}

// eslint-disable-next-line react/prefer-stateless-function
class CustomThemeNodeContentRenderer extends Component {
    render() {
        const {
            scaffoldBlockPxWidth,
            toggleChildrenVisibility,
            connectDragPreview,
            connectDragSource,
            isDragging,
            canDrop,
            canDrag,
            node,
            title,
            subtitle,
            draggedNode,
            path,
            treeIndex,
            isSearchMatch,
            isSearchFocus,
            icons,
            buttons,
            className,
            style,
            didDrop,
            lowerSiblingCounts,
            listIndex,
            swapFrom,
            swapLength,
            swapDepth,
            treeId, // Not needed, but preserved for other renderers
            isOver, // Not needed, but preserved for other renderers
            parentNode, // eslint-disable-line
            rowDirection,
            ...otherProps
        } = this.props;
        const nodeTitle = title || node.title;
        const nodeSubtitle = subtitle || node.subtitle;

        const isDraggedDescendant =
            draggedNode && isDescendant(draggedNode, node);
        const isLandingPadActive = !didDrop && isDragging;

        const framerExpander = {
            open: {
                rotate: 90,
            },
            closed: {
                rotate: 0,
            }
        };

        const MoveHandle =
            <span className={styles.moveHandle}>
                <i className={clsx('fa fa-arrows fa-lg', styles.moveHandleIcon)}/>
            </span>
        ;

        const nodeContent = connectDragPreview(
            <div
                className={
                    styles.row +
                    (isLandingPadActive ? ` ${styles.rowLandingPad}` : '') +
                    (isLandingPadActive && !canDrop
                        ? ` ${styles.rowCancelPad}`
                        : '') +
                    (isSearchMatch ? ` ${styles.rowSearchMatch}` : '') +
                    (isSearchFocus ? ` ${styles.rowSearchFocus}` : '') +
                    (className ? ` ${className}` : '')
                }
                style={{
                    opacity: isDraggedDescendant ? 0.5 : 1,
                    ...style,
                }}
            >
                {
                    canDrag
                        ? connectDragSource(MoveHandle, { dropEffect: 'copy' })
                        : MoveHandle
                }
                <div
                    className={
                        styles.rowContents +
                        (!canDrag ? ` ${styles.rowContentsDragDisabled}` : '')
                    }
                >
                    <div className={styles.rowLabel}>
                        <span
                            className={
                                styles.rowTitle +
                                (node.subtitle
                                    ? ` ${styles.rowTitleWithSubtitle}`
                                    : '')
                            }
                        >
                            {typeof nodeTitle === 'function'
                                ? nodeTitle({
                                    node,
                                    path,
                                    treeIndex,
                                })
                                : nodeTitle}
                        </span>

                        {nodeSubtitle && (
                            <span className={styles.rowSubtitle}>
                                {typeof nodeSubtitle === 'function'
                                    ? nodeSubtitle({
                                        node,
                                        path,
                                        treeIndex,
                                    })
                                    : nodeSubtitle}
                            </span>
                        )}
                    </div>

                    <div className={styles.rowToolbar}>
                        {buttons.map((btn, index) => (
                            <div
                                key={index} // eslint-disable-line react/no-array-index-key
                                className={styles.toolbarButton}
                            >
                                {btn}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );

        return (
            <div style={{ height: '100%' }} {...otherProps}>
                {toggleChildrenVisibility &&
                    node.children &&
                    (node.children.length > 0 ||
                        typeof node.children === 'function') && (
                        <div>
                            <button
                                type="button"
                                aria-label={node.expanded ? 'Collapse' : 'Expand'}
                                className={styles.expander}
                                style={{ left: -0.5 * scaffoldBlockPxWidth }}
                                onClick={() =>
                                    toggleChildrenVisibility({
                                        node,
                                        path,
                                        treeIndex,
                                    })
                                }
                            >
                                <motion.i
                                    className={clsx('cil-chevron-right', styles.icon)}
                                    initial={false}
                                    animate={node.expanded ? "open" : "closed"}
                                    variants={framerExpander}
                                />
                            </button>

                            {node.expanded && !isDragging && (
                                <div
                                    style={{ width: scaffoldBlockPxWidth }}
                                    className={styles.lineChildren}
                                />
                            )}
                        </div>
                    )}

                <div
                    className={
                        styles.rowWrapper +
                        (!canDrag ? ` ${styles.rowWrapperDragDisabled}` : '')
                    }
                >
                    {nodeContent}
                </div>
            </div>
        );
    }
}

CustomThemeNodeContentRenderer.defaultProps = {
    buttons: [],
    canDrag: false,
    canDrop: false,
    className: '',
    draggedNode: null,
    icons: [],
    isSearchFocus: false,
    isSearchMatch: false,
    parentNode: null,
    style: {},
    subtitle: null,
    swapDepth: null,
    swapFrom: null,
    swapLength: null,
    title: null,
    toggleChildrenVisibility: null,
};

CustomThemeNodeContentRenderer.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.node),
    canDrag: PropTypes.bool,
    className: PropTypes.string,
    icons: PropTypes.arrayOf(PropTypes.node),
    isSearchFocus: PropTypes.bool,
    isSearchMatch: PropTypes.bool,
    listIndex: PropTypes.number,
    lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number),
    node: PropTypes.shape({}).isRequired,
    path: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    scaffoldBlockPxWidth: PropTypes.number.isRequired,
    style: PropTypes.shape({}),
    subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    swapDepth: PropTypes.number,
    swapFrom: PropTypes.number,
    swapLength: PropTypes.number,
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    toggleChildrenVisibility: PropTypes.func,
    treeIndex: PropTypes.number.isRequired,
    treeId: PropTypes.string.isRequired,

    // Drag and drop API functions
    // Drag source
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    didDrop: PropTypes.bool.isRequired,
    draggedNode: PropTypes.shape({}),
    isDragging: PropTypes.bool.isRequired,
    parentNode: PropTypes.shape({}), // Needed for dndManager
    // Drop target
    canDrop: PropTypes.bool,
    isOver: PropTypes.bool.isRequired,
};

export default CustomThemeNodeContentRenderer;
