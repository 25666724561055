import { getTranslationForIso } from '../shared';

export const getTranslatedMenuTree = (menuElems, iso) => {

    const translateNameWithChildren = (sectionArr) => {
        return sectionArr.map(section => ({
            ...section,
            name: getTranslationForIso(section.name, iso),
            children: section.children.length > 0 ? translateNameWithChildren(section.children) : [],
        }));
    };

    return translateNameWithChildren(menuElems);

};

export const generateEditLinkForMenu = (id) => {
    return `menuPositions/edit?secID=${id}`;
};