export const upperMenuSectionTypes = [
    'manufacturers', 'category', 'url', 'customPage', 'container', 'search'
];

export const upperMenuSectionTypesForSubSection = [
    'category', 'url', 'customPage', 'search'
];

export const socialMediaOptions = [
    'facebook', 'twitter', 'instagram', 'youTube', 'pinterest', 'linkedIn'
];