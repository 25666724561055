import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { generateEditLinkForAdBanner } from '../../../utils/adBanners';

import TableGenerator from '../../generators/TableGenerator';
import PhotoPlaceholder from '../../../assets/photo_placeholder.svg';

import styles from './index.module.scss';
import getConfig from '../../../getConfig';

const propTypes = {
    increaseOrder: PropTypes.func,
    decreaseOrder: PropTypes.func,
    removeAction: PropTypes.func,
    checkedProductsIds: PropTypes.array,
    setCheckedProductsIds: PropTypes.func,
};

const AdBannersTable = ({
    adBanners,
    removeAction,
    checkedAdBannersIds,
    setCheckedAdBannersIds,
    translations,
    updateAdBannerStatus
}) => {
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const history = useHistory();

    const handleRowClick = id => () => {
        history.push(generateEditLinkForAdBanner(id));
    };

    const deleteAction = id => () => {
        removeAction(id);
    };

    const handleUpdateStatus = (id, status) => {
        const objectToSend = {
            advertisementId: id,
            isActive: status,
        };
        updateAdBannerStatus(objectToSend);
    };

    const handleUpdateSelected = (status) => async () => {
        const objectToSend = {
            advertisementId: checkedAdBannersIds,
            isActive: status,
        };

        await updateAdBannerStatus(objectToSend);
        setCheckedAdBannersIds([]);
    };

    const ImageCellDesktop = (item) => (
        <img
            className={styles.photo}
            src={item.desktopImageSource ? `${apiUrl}${item.desktopImageSource}` : PhotoPlaceholder}
            alt={item.name || translations.emptyLogoAlt}
        />
    );

    const ImageCellMobile = (item) => (
        <img
            className={styles.photo}
            src={item.mobileImageSource ? `${apiUrl}${item.mobileImageSource}` : PhotoPlaceholder}
            alt={item.name || translations.emptyLogoAlt}
        />
    );

    const CategoriesCell = item => {
        return (
            <ul className={styles.categoriesList} onClick={handleRowClick(item.id)}>
                {
                    item.showOnAllCategories
                        ? <span>{translations.showOnAllCategories}</span>
                        : item.categories && item.categories.map((categoryObject, i) =>
                            <li key={`feature-${item.id}-category-${i}`} className={styles.categoriesListItem}>
                                {categoryObject}
                            </li>
                        )
                }
            </ul>
        );
    };

    return (
        <TableGenerator
            editLink={generateEditLinkForAdBanner}
            expectedActions={['edit', 'delete']}
            actions={{ deleteAction }}
            items={adBanners}
            translations={translations}
            defaultAction={handleRowClick}
            customIdSelector='id'
            checkedItemsIds={checkedAdBannersIds}
            setCheckedItemsIds={setCheckedAdBannersIds}
            customButtons={[
                {
                    labelTrue: translations.actions.activate,
                    labelFalse: translations.actions.deactivate,
                    action: handleUpdateSelected,
                    type: 'selector',
                    isDisabled: checkedAdBannersIds.length < 1,
                },
            ]}
            columns={[
                {
                    type: 'checkbox'
                },
                {
                    header: translations.headers.name,
                    value: 'name',
                    additionalStyle: styles.nameCell,
                },
                {
                    header: translations.headers.desktopPhoto,
                    type: 'custom',
                    customCell: ImageCellDesktop,
                    additionalStyle: styles.photoCell
                },
                {
                    header: translations.headers.mobilePhoto,
                    type: 'custom',
                    customCell: ImageCellMobile,
                    additionalStyle: styles.photoCell
                },
                {
                    header: translations.headers.link,
                    value: 'link',
                },
                {
                    header: translations.headers.categories,
                    type: 'custom',
                    customCell: CategoriesCell,
                },
                {
                    header: translations.headers.activeInLanguges,
                    value: 'activeInLanguagesString',
                },
                {
                    header: translations.headers.activeFrom,
                    type: 'date',
                    value: 'activeFrom',
                    withTime: true,
                },
                {
                    header: translations.headers.activeTo,
                    type: 'date',
                    value: 'activeTo',
                    withTime: true,
                },
                {
                    header: translations.headers.isActive,
                    value: 'isActive',
                    type: 'switchWithAction',
                    onSwitch: handleUpdateStatus,
                },
                {
                    header: translations.headers.actions,
                    type: 'actions'
                },
            ]}
        />
    );
};

AdBannersTable.propTypes = propTypes;

export default AdBannersTable;