import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import useFetch from '../../../hooks/useFetch';
import { getEmailTemplates, getEmailTemplate, updateEmailTemplate } from '../../../redux/methods/emails';

import { Container, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import EmailTemplatesTable from '../../../components/tables/EmailTemplatesTable';
import EditEmailTemplateModal from '../../../components/EditEmailTemplateModal';

import styles from './index.module.scss';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getEmailTemplate: PropTypes.func,
    getEmailTemplates: PropTypes.func,
    updateEmailTemplate: PropTypes.func,
    isLoading: PropTypes.bool,
};

const EmailTemplates = ({
    forceRefetch,
    getEmailTemplates,
    getEmailTemplate,
    updateEmailTemplate,
    isLoading,
}) => {
    const intl = useIntl();
    const translations = intl.messages.emails.settings;

    const [ templateToEditId, setTemplateToEditId ] = useState('');
    const [ isEditModalOpen, setIsEditModalOpen ] = useState(false);
    const [ templates ] = useFetch(getEmailTemplates, null, [], forceRefetch);

    const editActionHandler = (id) => {
        setTemplateToEditId(id);
        setIsEditModalOpen(!isEditModalOpen);
    };

    return templates ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                {translations.emailTemplatesHeader}
                            </CardHeader>
                            <CardBody>
                                <main>
                                    <EmailTemplatesTable
                                        templates={templates}
                                        translations={translations.table}
                                        editClickAction={editActionHandler}
                                    />
                                </main>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            {
                templateToEditId
                    &&  <EditEmailTemplateModal
                            templateId={templateToEditId}
                            getEmailTemplate={getEmailTemplate}
                            translations={translations}
                            isModalOpen={isEditModalOpen}
                            setIsModalOpen={setIsEditModalOpen}
                            onSubmit={updateEmailTemplate}
                            modalHeader={templates.find(template => template.id === templateToEditId).name}
                            isLoading={isLoading}
                        />
            }
        </Container>
    ): null;
};

EmailTemplates.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.emailTemplates,
    isLoading: state.loaders.global,
});

const mapDispatch = {
    getEmailTemplates,
    getEmailTemplate,
    updateEmailTemplate
};

export default connect(mapStateToProps, mapDispatch)(EmailTemplates);