import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    history: PropTypes.object,
    templateId: PropTypes.number,
    tables: PropTypes.arrayOf(PropTypes.object)
};

const EmailTemplateTablesTable = ({ history, templateId, tables, translations }) => {

    const editAction = id => () => {
        history.push(`/emailSettings/templates/table?templateId=${templateId}&tableId=${id}`);
    };

    const { headerLabels } = translations;

    return (
        <TableGenerator
            items={tables}
            expectedActions={['edit']}
            actions={{editAction}}
            defaultAction={editAction}
            translations={translations}
            columns={[
                {
                    header: headerLabels.id,
                    type: 'index'
                },
                {
                    header: headerLabels.name,
                    value: 'name'
                },
                {
                    header: headerLabels.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

EmailTemplateTablesTable.propTypes = propTypes;

export default withRouter(EmailTemplateTablesTable);