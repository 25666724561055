import React from 'react';
import PropTypes from 'prop-types';

import { generateEditLinkForExchangeRate } from '../../../utils/exchangeRates';

import TableGenerator from '../../generators/TableGenerator';

import { exchangeRatePropTypes } from '../../../propTypes';

const propTypes = {
    headerLabels: PropTypes.shape({
        primaryCurrency: PropTypes.string,
        secondaryCurrency: PropTypes.string,
        exchangeRate: PropTypes.string,
        dateFrom: PropTypes.string,
        dateTo: PropTypes.string,
        actions: PropTypes.string,
    }).isRequired,
    exchangeRates: PropTypes.arrayOf(exchangeRatePropTypes).isRequired,
    removeAction: PropTypes.func.isRequired,
    translations: PropTypes.shape({
        tooltips: PropTypes.shape({
            delete: PropTypes.string,
            edit: PropTypes.string,
        }),
        deleteModal: PropTypes.shape({
            header: PropTypes.string,
            body: PropTypes.string,
            confirm: PropTypes.string,
            cancel: PropTypes.string,
        }).isRequired,
    }),
};

const ExchangeRatesTable = ({
    headerLabels,
    exchangeRates,
    translations,
    removeAction,
    history,
    currentPage,
    pageSize,
    performPageSizeSelect,
}) => {
    const deleteAction = (id) => () => {
        removeAction(id);
    };

    const defaultAction = id => () => {
        history.push(generateEditLinkForExchangeRate(id));
    };

    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            pageSize={pageSize}
            currentPage={currentPage}
            items={exchangeRates}
            actions={{deleteAction}}
            expectedActions={['edit', 'delete']}
            translations={translations}
            editLink={generateEditLinkForExchangeRate}
            defaultAction={defaultAction}
            columns={[
                {
                    header: headerLabels.primaryCurrency,
                    value: 'currency1'
                },
                {
                    header: headerLabels.secondaryCurrency,
                    value: 'currency2'
                },
                {
                    header: headerLabels.exchangeRate,
                    value: 'rate'
                },
                {
                    header: headerLabels.dateFrom,
                    value: 'dateFrom',
                    type: 'date'
                },
                {
                    header: headerLabels.dateTo,
                    value: 'dateTo',
                    type: 'date'
                },
                {
                    header: headerLabels.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

ExchangeRatesTable.propTypes = propTypes;

export default ExchangeRatesTable;