import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { generateNonIdsOptionsForDropdown } from '../../../../utils/components';

import { Container, Row, Form, Label } from 'reactstrap';
import { Form as FinalForm, Field } from 'react-final-form';
import SectionAsideButtons from '../../../../components/SectionAsideButtons';
import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import LayoutColorEditionController from '../../../../components/LayoutController/LayoutColorEditionController';

import styles from './index.module.scss';

const propTypes = {
    updatePromotionBar: PropTypes.func,
};

const PromotionBarParams = ({
    translations,
    params,
    updatePromotionBar,
}) => {
    const [ colors, setColors ] = useState({});

    const handleOnAddColor = (color, name) => {
        const colorPair = { [name]: color };
        setColors(prevState => ({ ...prevState, ...colorPair }));
    };

    const handleOnSubmit = (form) => {
        const objectToSend = {
            ...form,
            ...colors,
        };

        updatePromotionBar(objectToSend);
    };
    const availableOptionsToShowBar = ['showOnMainPage', 'showOnProductPage', 'showOnSearchPage', 'showOnCustomPage'];

    const locationTypes = ['BeforeMenu', 'AfterMenu'];
    const locationOptions = generateNonIdsOptionsForDropdown(locationTypes, translations.locations);

    return (
        <Container fluid className={clsx('mb-6', styles.fullScreenOnMobile)}>
            <div className='animated fadeIn'>
                <FinalForm
                    initialValues={params}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        const { values } = form.getState();
                        const currentSelectedLocation = locationOptions.find(location => location.id === values.location);

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Row className='mb-3'>
                                    <span className='mr-3 font-weight-bolder'>{translations.inputs.enabled}</span>
                                    <Field name='enabled' type='checkbox'>
                                        {
                                            props => (
                                                <label className="switch switch-pill switch-success m-0">
                                                    <input
                                                        type="checkbox"
                                                        name={props.input.name}
                                                        value={props.input.value}
                                                        onChange={props.input.onChange}
                                                        className="switch-input"
                                                        checked={values.enabled}
                                                    />
                                                    <span
                                                        className={clsx("switch-slider", !values.enabled &&  "bg-danger border-danger")}
                                                    ></span>
                                                </label>
                                            )
                                        }
                                    </Field>
                                </Row>
                                <Row className='mb-3'>
                                    <div className='mr-3'>
                                        <Field
                                            component='input'
                                            type='checkbox'
                                            name='autoplay'
                                            checked={true}
                                            disabled={true}
                                        />
                                        <Label className='pl-2'>{translations.inputs.autoPlay}</Label>
                                        <FormFieldTextOrNumber
                                            className='d-block'
                                            label={translations.inputs.autoPlayDuration}
                                            min={1}
                                            max={10}
                                            type='number'
                                            name='displayTime'
                                        />
                                    </div>
                                </Row>
                                <Row className='mb-3'>
                                    <div className={styles.dropdown}>
                                        <FormFieldWithDropdown
                                            label={translations.inputs.locationType}
                                            name='location'
                                            options={locationOptions}
                                            currentSelectionLabel={currentSelectedLocation? currentSelectedLocation.label : translations.inputs.locationFallback}
                                        />
                                    </div>
                                </Row>
                                <Row className='mb-3'>
                                    <div>
                                        <LayoutColorEditionController
                                            onAdd={handleOnAddColor}
                                            translations={translations.inputs.colorController}
                                            color={values.backgroundColor}
                                            name={translations.inputs.colorController.name}
                                            tag='backgroundColor'
                                        />
                                    </div>
                                </Row>
                                <Row className='d-flex flex-column'>
                                    <div className='mb-3'>
                                        <Field
                                            component='input'
                                            type='checkbox'
                                            name='showOnAll'
                                        />
                                        <Label className='pl-2'>{translations.inputs.showOnAll}</Label>
                                    </div>
                                    <span>{translations.inputs.choosenPages}</span>
                                        {
                                            availableOptionsToShowBar.map(page => (
                                                <div key={`pageToShowBar=${page}`}>
                                                    <Field
                                                        component='input'
                                                        type='checkbox'
                                                        name={page}
                                                        disabled={values.showOnAll}
                                                        />
                                                    <Label className='pl-2'>{translations.inputs[page]}</Label>
                                                </div>
                                            ))
                                        }
                                </Row>
                                <SectionAsideButtons
                                    buttons={{
                                        add: {
                                            color: 'success',
                                            label: translations.actions.save,
                                            action: handleSubmit,
                                            iconClass: 'fa fa-save mr-2',
                                        }
                                    }}
                                />
                            </Form>
                        );
                    }}
                />
            </div>
        </Container>
    );
};

PromotionBarParams.propTypes = propTypes;

export default PromotionBarParams;