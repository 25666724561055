import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import useFetch from '../../hooks/useFetch';
import useSearchConfig from '../../hooks/useSearchConfig';
import { getNotificationsList, ignoreNotifications } from '../../redux/methods/notifications';

import { notificationsSearchConfig } from '../../config/searchConfigs';
import { notificationsModulesColorsConfig } from '../../config';

import ContainerWithCard from '../../components/ContainerWithCard';
import NotificationsTable from '../../components/tables/NotifcationsTable';
import Paginator from '../../components/Paginator';
import NotificationsSearchForm from '../../features/notifications/NotificationsSearchForm';

const propTypes = {
    getIntegrationNotifications: PropTypes.func,
    updateIntegrationNotifications: PropTypes.func,
};

const Notifications = ({
    getNotificationsList,
    types,
    modules,
    ignoreNotifications,
    forceRefetch,
}) => {
    const intl = useIntl();
    const translations = intl.messages.notifications;

    const [checkedCheckboxIds, setCheckedCheckboxIds] = useState([]);

    const handleIgnore = id => () => {
        if(id) {
            ignoreNotifications({
                notificationsId: [id]
            });
        } else {
            ignoreNotifications({
                notificationsId: checkedCheckboxIds
            });
        }

        setCheckedCheckboxIds([]);
    };

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(notificationsSearchConfig);
    const [ notifications, pages ] = useFetch(getNotificationsList, searchConfig, [ searchConfig ], forceRefetch);
    const [columnSortedByDescendingOrder, setColumnSortedByDescendingOrder ] = useState(null);

    const handlePerformSort = (direction) => {
        changeSearchConfig({ ...searchConfig, orderDirection: direction });
    };

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    const generateDropdownOptions = items => items.map(item => ({
        id: item.key,
        label: item.value
    }));

    const generateModuleConfig = (statuses) => {
        return statuses.map(status => ({
            id: status.key,
            label: status.value,
            color: notificationsModulesColorsConfig[status.key]
        }));
    };

    return notifications && modules && types ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-bell'>
            <NotificationsSearchForm
                searchConfig={searchConfig}
                translations={translations}
                typeOptions={generateDropdownOptions(types)}
                moduleOptions={generateDropdownOptions(modules)}
                onSubmit={changeSearchConfig}
            />
            <NotificationsTable
                performPageSizeSelect={handleChangePageSize}
                pageSize={searchConfig.pageSize}
                currentPage={searchConfig.curentPage + 1}
                translations={translations.table}
                notificationsList={notifications}
                performSort={handlePerformSort}
                columnSortedByDescendingOrder={columnSortedByDescendingOrder}
                setColumnSortedByDescendingOrder={setColumnSortedByDescendingOrder}
                checkedCheckboxIds={checkedCheckboxIds}
                setCheckedCheckboxIds={setCheckedCheckboxIds}
                deleteAction={handleIgnore}
                moduleConfig={generateModuleConfig(modules)}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={searchConfig.curentPage + 1}
                        onPageChange={changePage}
                    />
            }
        </ContainerWithCard>
    ) : null;
};

const mapStateToProps = state => ({
    types: state.appInit.notifications.types,
    modules: state.appInit.notifications.modules,
    forceRefetch: state.refetchers.notificationsList,
});

const mapDispatch = {
    getNotificationsList,
    ignoreNotifications
};

Notifications.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatch)(Notifications);