import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { editCurrencyBasic } from '../../../../redux/methods/currencies';

import { Form as FinalForm, Field } from 'react-final-form';
import { Container, Form, Row, Input, Col } from 'reactstrap';

import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import CurrencyEditActiveDropdown from './CurrencyEditActiveDropdown';
import FormFieldSwitchWithTwoLabels from '../../../../components/formFields/FormFieldSwitchWithTwoLabels';

import { stringOrNumberPropTypes } from '../../../../propTypes';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    id: stringOrNumberPropTypes,
    data: PropTypes.shape({
        name: PropTypes.string,
        shortName: PropTypes.string,
        active: PropTypes.bool,
    }),
    editCurrencyBasic: PropTypes.func,
};

const CurrencyEditBasic = ({ id, data, editCurrencyBasic }) => {
    const intl = useIntl();
    const translations = intl.messages.currencies.edit.tabs.basic;

    const handleOnSubmit = formData => {
        editCurrencyBasic(formData);
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    initialValues={{ ...data, currencyId: Number.parseInt(id) }}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container fluid className='mt-3 px-0'>
                                    <Row className='mb-3'>
                                        <Field name='active'>
                                            {
                                                props =>
                                                    <CurrencyEditActiveDropdown
                                                        isActive={props.input.value}
                                                        setIsActive={props.input.onChange}
                                                    />
                                            }
                                        </Field>
                                    </Row>
                                    <Row className='my-3'>
                                        <FormFieldSwitchWithTwoLabels
                                            name='showSymbol'
                                            labels={translations.showSymbol}
                                            tooltip={translations.tooltip}
                                        />
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <span>{translations.labels.name}</span>
                                            <Field name='name'>
                                                {
                                                    props =>
                                                        <Input
                                                            className='mt-1'
                                                            type='text'
                                                            name={props.input.name}
                                                            value={props.input.value}
                                                            onChange={props.input.onChange}
                                                        />
                                                }
                                            </Field>
                                        </Col>
                                        <Col sm={12} md={6} className='mt-3 mt-md-0'>
                                            <span>{translations.labels.shortName}</span>
                                            <Field name='shortName'>
                                                {
                                                    props =>
                                                        <Input
                                                            className='mt-1'
                                                            type='text'
                                                            name={props.input.name}
                                                            value={props.input.value}
                                                            onChange={props.input.onChange}
                                                        />
                                                }
                                            </Field>
                                        </Col>
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

CurrencyEditBasic.propTypes = propTypes;

const mapDispatch = {
    editCurrencyBasic,
};

export default withFormUtils(connect(null, mapDispatch)(CurrencyEditBasic));