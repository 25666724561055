import React from 'react';
import PropTypes from 'prop-types';

import { generateEditLinkForCustomPage } from '../../../utils/customPages';

import { customPagePropTypes, deleteModalPropTypes, statusConfigPropTypes } from '../../../propTypes';

import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    pages: PropTypes.arrayOf(customPagePropTypes),
    headers: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        status: PropTypes.string,
        isFeatured: PropTypes.string,
        actions: PropTypes.string,
    }),
    fillers: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
    }),
    translations: PropTypes.shape({
        tooltips: PropTypes.shape({
            edit: PropTypes.string,
            remove: PropTypes.string,
        }),
        deleteModal: deleteModalPropTypes,
        emptyTable: PropTypes.string,
    }),
    removeHandler: PropTypes.func,
    statusConfig: statusConfigPropTypes,
    featuredConfig: statusConfigPropTypes,
    history: PropTypes.object,
};

const CustomPageTable = ({
    pages,
    headers,
    fillers,
    translations,
    removeHandler,
    history,
    checkedCustomPagesIds,
    setCheckedCustomPagesIds,
    updateAction,
}) => {
    const deleteAction = pageId => () => {
        removeHandler(pageId);
    };

    const handleDefaultAction = id => () => {
        history.push(generateEditLinkForCustomPage(id));
    };

    const handleUpdateStatus = (id, status) => {
        const objectToSend = {
            custompagesId: [ id ],
            status: status ? 1 : 0,
        };
        updateAction(objectToSend);
    };

    const handleUpdateFeatured = (id, status) => {
        const objectToSend = {
            custompagesId: [ id ],
            featured: status,
        };
        updateAction(objectToSend);
    };

    const handleUpdateSelected = (status) => async () => {
        const objectToSend = {
            custompagesId: checkedCustomPagesIds,
            status: status ? 1 : 0,
        };

        await updateAction(objectToSend);
        setCheckedCustomPagesIds([]);
    };

    const handleFeatureSelected = (status) => async () => {
        const objectToSend = {
            custompagesId: checkedCustomPagesIds,
            featured: status,
        };

        await updateAction(objectToSend);
        setCheckedCustomPagesIds([]);
    };

    return (
        <TableGenerator
            items={pages}
            expectedActions={['edit', 'delete']}
            editLink={generateEditLinkForCustomPage}
            defaultAction={handleDefaultAction}
            actions={{deleteAction}}
            checkedItemsIds={checkedCustomPagesIds}
            setCheckedItemsIds={setCheckedCustomPagesIds}
            translations={translations}
            customButtons={[
                {
                    location: 'left',
                    labelTrue: translations.buttons.activate,
                    labelFalse: translations.buttons.deactivate,
                    action: handleUpdateSelected,
                    type: 'selector',
                    isDisabled: checkedCustomPagesIds.length < 1,
                },
                {
                    location: 'left',
                    labelTrue: translations.buttons.feature,
                    labelFalse: translations.buttons.unfeature,
                    action: handleFeatureSelected,
                    type: 'selector',
                    isDisabled: checkedCustomPagesIds.length < 1,
                },
            ]}
            columns={[
                {
                    type: 'checkbox'
                },
                {
                    header: headers.name,
                    value: 'translatedName',
                    filler: fillers.name,
                },
                {
                    header: headers.slug,
                    value: 'slug',
                    filler: fillers.slug,
                },
                {
                    header: headers.status,
                    value: 'status',
                    type: 'switchWithAction',
                    onSwitch: handleUpdateStatus
                },
                {
                    header: headers.featured,
                    value: 'featured',
                    type: 'switchWithAction',
                    onSwitch: handleUpdateFeatured,
                },
                {
                    header: headers.actions,
                    type: 'actions',
                }
            ]}
        />
    );
};

CustomPageTable.propTypes = propTypes;

export default CustomPageTable;
