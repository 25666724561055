const promotionBar = {
    settings: {
        tabSelectorTitle: 'Pasek informacyjny',
        tabs: {
            parameters: 'Parametry',
            slides: 'Slajdy',
        },
        inputs: {
            enabled: 'Aktywny',
            autoPlay: 'Automatyczne przejście',
            autoPlayDuration: 'Czas wyświetlania slajdu',
            pauseOnHover: 'Zatrzymaj po najechaniu kursorem',
            locationType: 'Lokalizacja',
            locationFallback: 'Domyślna',
            showOnAll: 'Pokazuj na wszystkich stronach',
            showOnMainPage: 'Strona Główna',
            showOnProductPage: 'Strona Produktu',
            showOnSearchPage: 'Strona wyszukiwania',
            showOnCustomPage: 'Strony własne',
            link: 'Link',
            dateFrom: 'Aktywny od',
            dateTo: 'Aktywny do',
            content: 'Treść',
            hint: 'Podpowiedź',
            choosenPages: 'Wybrane strony:',
            availableInLanguages: 'Dostępny w jezykach: ',
            colorController: {
                pickButton: 'Wybierz kolor',
                name: 'Kolor tła'
            }
        },
        locations: {
            BeforeMenu: 'Nad menu',
            AfterMenu: 'Pod menu'
        },
        actions: {
            save: 'ZAPISZ ZMIANY',
            cancel: 'WRÓĆ',
            add: 'DODAJ SLAJD'
        },
        colorEditionLabels: {
            pickColor: 'Wybierz kolor',
            name: 'Kolor tła'
        },
        editModal: {
            header: 'Ustawienia slajdu',
            warning: 'Maksymnalna ilość znaków (100) przekroczona. Zmniejsz ilość znaków.'
        },
        languages: {
            Pl: 'Polski',
            En: 'Angielski',
            Ru: 'Rosyjski',
            Ua: 'Ukraiński',
            De: 'Niemiecki',
            Cz: 'Czeski',
            It: 'Włoski',
            Tr: 'Turecki',
            Es: 'Hiszpański'
        },
        table: {
            columnNames: {
                order: 'Kolejność',
                link: 'Link',
                activeInLanguage: 'Aktywy w językach',
                active: 'Aktywny',
                dates: 'Zakres aktywności',
                content: 'Treść',
                actions: 'Akcje'
            },
            tooltipTranslations: {
                edit: 'Edytuj',
                remove: 'Usuń',
            },
            deleteModalTranslations: {
                header: 'Usuń slajd',
                body: 'Czy napewno chcesz usunąć wybrany slajd?',
                confirm: 'Usuń',
                cancel: 'Wróć'
            },
            emptyTable: 'Brak slajdów do wyświetlenia'
        }
    }
};

export default promotionBar;