const technicalBreak = {
    title: 'Przerwa techniczna',
    button: 'ZAPISZ ZMIANY',
    labels: {
        isActive: 'Aktywna',
        startDate: 'Start przerwy',
        endDate: 'Koniec przerwy',
        backgroundColor: 'Kolor tła',
        backgroundButton: 'Wybierz kolor tła',
        content: 'Zawartość',
    },
    info: 'Własna strona przerwy technicznej będzie wyświetlana tylko w przypadku zdefiniowania jej treści oraz tła.',
    fileSender: {
        label: 'Tło obrazkowe',
        buttons: {
            edit: 'PODMIEŃ',
            remove: 'USUŃ',
            upload: 'WGRAJ'
        },
    },
};

export default technicalBreak;