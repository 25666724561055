import { createSelector } from '@reduxjs/toolkit';

import { getTreeFromFlatData } from 'react-sortable-tree/dist/index.cjs';

import {
    flattenCategoryTree, getSameLevelCategories, constructCategoryBreadcrumbs,
    getTranslatedCurrentCategory, translateFlattenedCategoryTree, generateCategoryPositionPathArray
} from '../../../utils/categories';

const selectCategoryTree = state => state.categories.categoryTree;
const selectExpandedCategories = state => state.categories.expandedCategories;
const selectStoreChosenLanguage = state => state.persisted.storeLocale.storeChosenLanguage;
const selectStoreDefaultLanguage = state => state.persisted.storeLocale.storeDefaultLanguage;
const selectNewlyAddedFeatureTargetCategoryId = state => state.attributes.newlyAddedFeature.targetCategoryId;
const selectTargetCategoryIdForFeature = state => state.attributes.attributeToEdit.cache.assignedCategories.targetCategoryIdForFeature;
const selectTargetCategoryIdForProduct = state => state.products.newlyAddedAddidionalCategoryId;
const selectCategoryDisplayMode = state => state.categories.categoryDisplayMode;
const selectIsConnectedToERP = state => state.appInit.isConnectedToERP;
const selectIsShopCategoryActive = state => state.appInit.isShopCategoryActive;

export const selectCategoryToEdit = categoryId => createSelector(
    selectCategoryTree,
    categoryTree => {
        const flatTree = flattenCategoryTree(categoryTree);

        return flatTree.find(category => parseInt(category.id) === parseInt(categoryId));
    }
);

export const selectTranslatedFlattenedCategoryTree = createSelector(
    selectCategoryTree,
    selectStoreChosenLanguage,
    selectStoreDefaultLanguage,
    (categoryTree, storeChosenLanguage, storeDefaultLanguage) => {
        const flatTree = flattenCategoryTree(categoryTree);
        const language = storeChosenLanguage || storeDefaultLanguage;

        return translateFlattenedCategoryTree(flatTree, language);
    }
);

export const selectTranslatedCategoryTreeDND = createSelector(
    selectCategoryTree,
    selectExpandedCategories,
    selectStoreChosenLanguage,
    selectStoreDefaultLanguage,
    selectCategoryDisplayMode,
    (categoryTree, expandedCategories, storeChosenLanguage, storeDefaultLanguage, displayMode) => {
        const flatTree = flattenCategoryTree(categoryTree, expandedCategories);
        const language = storeChosenLanguage || storeDefaultLanguage;

        const statusAdjustmentedFlatTree = flatTree.filter(category => category.status === 1);

        const translatedFlattenedCategoryTree = translateFlattenedCategoryTree(displayMode === 'selected' ? statusAdjustmentedFlatTree : flatTree, language);

        const getKey = node => node.id;
        const getParentKey = node => node.parent;

        return getTreeFromFlatData({
            flatData: translatedFlattenedCategoryTree,
            getKey: getKey,
            getParentKey: getParentKey,
            rootKey: null
        });
    }
);

export const selectTranslatedCurrentCategory = query => createSelector(
    selectCategoryTree,
    selectStoreDefaultLanguage,
    selectStoreChosenLanguage,
    (categoryTree, storeDefaultLanguage, storeChosenLanguage) => {
        const decodedQuery = new URLSearchParams(query);
        const categoryId = decodedQuery.get('catID');
        const shouldUtilRun = categoryId && categoryTree && categoryTree.length > 0;

        /**
         * if there's no categoryId or no categoryTree, don't run the util
         */
        return (shouldUtilRun) ? getTranslatedCurrentCategory(
            flattenCategoryTree(categoryTree),
            categoryId,
            storeChosenLanguage || storeDefaultLanguage
        ) : null;
    }
);

export const selectTranslatedCategoryName = categoryId => createSelector(
    selectCategoryTree,
    selectStoreDefaultLanguage,
    selectStoreChosenLanguage,
    (categoryTree, storeDefaultLanguage, storeChosenLanguage) =>
        getTranslatedCurrentCategory(
            flattenCategoryTree(categoryTree),
            categoryId,
            storeChosenLanguage || storeDefaultLanguage
        ).name
);

export const selectCategoryBreadcrumbsArray = query => createSelector(
    selectCategoryTree,
    selectStoreDefaultLanguage,
    selectStoreChosenLanguage,
    (categoryTree, storeDefaultLanguage, storeChosenLanguage) => {
        const decodedQuery = new URLSearchParams(query);
        const categoryId = decodedQuery.get('catID');

        return constructCategoryBreadcrumbs(
            flattenCategoryTree(categoryTree),
            categoryId,
            storeChosenLanguage || storeDefaultLanguage
        )([]);
    }
);

export const selectCategoryLevel = query => createSelector(
    selectCategoryTree,
    categoryTree => {
        const decodedQuery = new URLSearchParams(query);
        const categoryId = decodedQuery.get('catID');

        return getSameLevelCategories(flattenCategoryTree(categoryTree), categoryId);
    }
);

export const selectIsCategoryTreePresent = createSelector(
    selectCategoryTree,
    categoryTree => categoryTree && categoryTree.length > 0 ? true : false
);

export const selectFlattenedCategoryTree = createSelector(
    selectCategoryTree,
    categoryTree => flattenCategoryTree(categoryTree)
);

export const selectCategoryTreeIdList = createSelector(
    selectCategoryTree,
    categoryTree => flattenCategoryTree(categoryTree).map(category => category.id)
);

export const selectFeatureAdditionSelectedCategoryPathArray = createSelector(
    selectCategoryTree,
    selectNewlyAddedFeatureTargetCategoryId,
    selectStoreChosenLanguage,
    selectStoreDefaultLanguage,
    (categoryTree, newlyAddedFeatureTargetCategoryId, storeChosenLanguage, storeDefaultLanguage) => {
        const flatTree = flattenCategoryTree(categoryTree);
        const language = storeChosenLanguage || storeDefaultLanguage;
        const translatedFlatTree = translateFlattenedCategoryTree(flatTree, language);
        const shouldUtilRun = newlyAddedFeatureTargetCategoryId ? true : false;

        return shouldUtilRun ? generateCategoryPositionPathArray(translatedFlatTree, newlyAddedFeatureTargetCategoryId, language) : [];
    }
);

export const selectFeatureAssignedCategoriesSelectedCategoryPathArray = createSelector(
    selectCategoryTree,
    selectTargetCategoryIdForFeature,
    selectStoreChosenLanguage,
    selectStoreDefaultLanguage,
    (categoryTree, selectTargetCategoryIdForFeature, storeChosenLanguage, storeDefaultLanguage) => {
        const flatTree = flattenCategoryTree(categoryTree);
        const language = storeChosenLanguage || storeDefaultLanguage;
        const translatedFlatTree = translateFlattenedCategoryTree(flatTree, language);
        const shouldUtilRun = selectTargetCategoryIdForFeature ? true : false;

        return shouldUtilRun ? generateCategoryPositionPathArray(translatedFlatTree, selectTargetCategoryIdForFeature, language) : [];
    }
);

export const selectProductAdditionSelectedCategoryPathArray = createSelector(
    selectCategoryTree,
    selectTargetCategoryIdForProduct,
    selectStoreChosenLanguage,
    selectStoreDefaultLanguage,
    (categoryTree, productTargetCategoryId, storeChosenLanguage, storeDefaultLanguage) => {
        const flatTree = flattenCategoryTree(categoryTree);
        const language = storeChosenLanguage || storeDefaultLanguage;
        const translatedFlatTree = translateFlattenedCategoryTree(flatTree, language);
        const shouldUtilRun = productTargetCategoryId ? true : false;

        return shouldUtilRun ? generateCategoryPositionPathArray(translatedFlatTree, productTargetCategoryId, language) : [];
    }
);

export const selectIsERPCategoriesIsUsage = createSelector(
    selectIsConnectedToERP,
    selectIsShopCategoryActive,
    (isConnectedToERP, isShopCategoryActive) => {
        return !isShopCategoryActive && isConnectedToERP;
    }
);