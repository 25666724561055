import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import SearchForm from '../../../components/generators/SearchForm';
import { usersSearchConfig } from '../../../config/searchConfigs';

const propTypes = {
    changeSearchConfig: PropTypes.func,
    searchConfig: PropTypes.object.isRequired,
};

const UsersSearchForm = ({ changeSearchConfig, searchConfig }) => {
    const intl = useIntl();
    const translations = intl.messages.dashboard.clients.searchForm;

    return (
        <SearchForm
            searchConfig={searchConfig}
            translations={{
                buttons: {
                    submit: translations.action,
                    reset: translations.reset,
                }
            }}
            defaultSearchConfig={{ ...usersSearchConfig, pageSize: 10, countRows: true }}
            inputs={[
                {
                    label: translations.hasAccount,
                    name: 'hasAccount',
                    type: 'dropdown',
                    options: [
                        {
                            id: null,
                            label: translations.null,
                        },
                        {
                            id: true,
                            label: translations.true,
                        },
                        {
                            id: false,
                            label: translations.false,
                        },
                    ],
                    defaultLabel: translations.null,
                },
                {
                    label: translations.login,
                    name: 'login',
                    type: 'text'
                },
                {
                    label: translations.name,
                    name: 'name',
                    type: 'text'
                },
                {
                    label: translations.surname,
                    name: 'surname',
                    type: 'text'
                },
                {
                    label: translations.contractorInErp,
                    name: 'contractorInErp',
                    type: 'text'
                },
                {
                    label: translations.email,
                    name: 'email',
                    type: 'text'
                },
                {
                    label: translations.isActive,
                    name: 'isActive',
                    type: 'dropdown',
                    options: [
                        {
                            id: null,
                            label: translations.null,
                        },
                        {
                            id: true,
                            label: translations.true,
                        },
                        {
                            id: false,
                            label: translations.false,
                        },
                    ],
                    defaultLabel: translations.null,
                },
            ]}
            performSearch={changeSearchConfig}
        />
    );
};

UsersSearchForm.propTypes = propTypes;

export default UsersSearchForm;