import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Container, Row, Button } from 'reactstrap';

import {  getSectionCategories, addDeleteSectionCategories } from '../../../../redux/methods/homePage';

import CategoriesTable from '../../../../components/tables/CategoriesTable';
import useFetch from '../../../../hooks/useFetch';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    attributeId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    forceRefetch: PropTypes.bool,
    getAttributeCategories: PropTypes.func,
    addAttributeCategory: PropTypes.func,
    deleteAttributeCategory: PropTypes.func
};

const CategoryAssigner = ({
    utils,
    id,
    forceRefetch,
    getSectionCategories,
    addDeleteSectionCategories,
    type,
}) => {
    const intl = useIntl();
    const translations = intl.messages.features.edit.tabs.categories;

    const [ categoryIdSelection, setCategoryIdSelection ] = useState([]);

    const [ data ] = useFetch(getSectionCategories, ({ id, type }), [], forceRefetch);

    const handleUpdateSecitonCategories = (idToUpdate, isDeleting = true) => {
        const objectToSend = {
            categoryIds: Array.isArray(idToUpdate) ? idToUpdate : [idToUpdate],
            delete: isDeleting
        };

        addDeleteSectionCategories(({ id, type, formData: objectToSend }));

    };

    const handleSelection = categoryId => {
        setCategoryIdSelection(categoryIdSelection.indexOf(categoryId) !== -1
            ? categoryIdSelection.filter(id => id !== categoryId)
            : [ ...categoryIdSelection, categoryId ]);
    };

    const handleDeleteSectionCategory = categoryId => {
        handleUpdateSecitonCategories(categoryId);
    };

    const handleCategorySelectModalOpen = () => {
        utils.selectCategory(
            categoryId => handleUpdateSecitonCategories(categoryId, false),
            translations.categorySelectionModal.body
        );
    };

    const handleDeletionModalOpen = () => {
        utils.confirm(
            () => handleUpdateSecitonCategories(categoryIdSelection),
            translations.deletionModal.header,
            translations.deletionModal.body
        );
    };

    return data ? (
        <React.Fragment>
            <Container fluid>
                <CategoriesTable
                    categories={data}
                    checkedCheckboxList={categoryIdSelection}
                    columnNames={translations.table.columnNames}
                    tooltipTranslation={translations.table.tooltip}
                    emptyTranslation={translations.table.placeholder}
                    deleteModalTranslations={translations.table.deleteModal}
                    checkboxAction={handleSelection}
                    removeAction={handleDeleteSectionCategory}
                />
                <Row className={clsx('d-flex flex-row mt-5', data && data.length > 0 ? 'justify-content-between' : 'justify-content-end')}>
                    {
                        data && data.length > 0 && (
                            <Button
                                outline
                                type="button"
                                disabled={!categoryIdSelection || categoryIdSelection.length === 0}
                                onClick={handleDeletionModalOpen}
                                color="danger"
                            >
                                <i className="fa fa-trash-o mr-2"/>
                                <span>{translations.controls.remove}</span>
                            </Button>
                        )
                    }
                    <Button onClick={handleCategorySelectModalOpen} color="primary">
                        <i className="fa fa-plus mr-2"/>
                        <span>{translations.controls.add}</span>
                    </Button>
                </Row>
            </Container>
        </React.Fragment>
    ) : null;
};

CategoryAssigner.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.sectionCategories,
});

const mapDispatch = {
    getSectionCategories,
    addDeleteSectionCategories
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(CategoryAssigner));