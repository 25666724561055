const countries = {
    view: {
        title: 'Lista państw',
        actions: {
            addCountry: 'DODAJ PAŃSTWO',
        },
        headerLabels: {
            name: 'Nazwa',
            iso: 'Kod ISO',
            actions: 'Akcje',
            active: 'Aktywne'
        },
        tooltips: {
            edit: 'Edytuj państwo',
        },
        emptyTable: 'Brak krajów do wyświetlenia',
        labelText: 'Nazwa państwa:',
        modalHeader: 'Edycja tłumaczeń nazwy państwa',
        nameFiller: '[BRAK TŁUMACZENIA]',
    },
    searchForm: {
        name: {
            label: 'Nazwa państwa:',
            placeholder: '',
        },
        iso: {
            label: 'Kod ISO:',
            placeholder: '',
        },
        action: 'SZUKAJ',
        reset: 'RESETUJ',
    }
};

export default countries;