import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { productTypes } from '../../config';
import { productsSearchConfig } from '../../config/searchConfigs';

import SearchForm from '../generators/SearchForm';

const propTypes = {
    performProductSearch: PropTypes.func,
    searchConfig: PropTypes.object,
};

const ProductSearchForm = ({ performProductSearch, searchConfig, additionalDefaultSearchConfig, children, canSearchInSubcategories = false }) => {
    const intl = useIntl();
    const translations = intl.messages.products.searchForm;

    const generateTypesDropdownOptions = productTypes.map(type => ({ id: type, label: translations.types[type] }));
    const defaultTypeOption = { id: null, label: translations.types.defaultType };

    return (
        <SearchForm
            defaultSearchConfig={{...productsSearchConfig, ...additionalDefaultSearchConfig }}
            searchConfig={searchConfig}
            translations={{
                buttons: {
                    submit: translations.action,
                    reset: translations.reset
                }
            }}
            inputs={[
                {
                    label: translations.productName.label,
                    name: 'productName',
                    type: 'text'
                },
                {
                    label: translations.catalogueIndex.label,
                    name: 'catalogueIndex',
                    type: 'text'
                },
                {
                    label: translations.tradeIndex.label,
                    name: 'tradeIndex',
                    type: 'text'
                },
                {
                    label: translations.ean.label,
                    name: 'ean',
                    type: 'text'
                },
                {
                    type: 'manufacturerSelect',
                    name: 'manufacturerId'
                },
                {
                    type: 'tagSelect',
                    name: 'tagId'
                },
                {
                    label: translations.status.label,
                    name: 'status',
                    type: 'dropdown',
                    options: [
                        {
                            id: 1,
                            label: translations.active
                        },
                        {
                            id: 0,
                            label: translations.inactive
                        }
                    ],
                    defaultLabel: translations.defaultSearchLabel
                },
                {
                    label: translations.productType,
                    name: 'productVariantType',
                    type: 'dropdown',
                    options: [ defaultTypeOption,...generateTypesDropdownOptions ],
                    defaultLabel: translations.defaultSearchLabel
                },
                {
                    label: translations.warning.label,
                    name: 'onlyWithWarnings',
                    type: 'checkbox'
                },
                canSearchInSubcategories ?
                {
                    label: translations.inSubcategories,
                    name: 'inSubcategories',
                    type: 'checkbox'
                } : null,
                {
                    label: translations.promotion.label,
                    name: 'onlyWithPromotion',
                    type: 'checkbox'
                }
            ]}
            performSearch={performProductSearch}
        >
            {children}
        </SearchForm>
    );
};

ProductSearchForm.propTypes = propTypes;

export default ProductSearchForm;