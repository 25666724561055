const fetch = require('isomorphic-unfetch');

const getTimezoneOffset = () => {
    const date = new Date();

    return date.getTimezoneOffset();
};

const generateRequestObject = ({ cookie, httpMethod, jsonBody, token = null }) => {
    let requestObject;
    if (cookie && !token) {
        requestObject = {
            method: httpMethod,
            headers: {
                Accept: 'application/json, text/plain, text/html, */*',
                'Content-Type': 'application/json',
                cookie,
                xTimezoneOffset: getTimezoneOffset()
            },
            body: jsonBody && JSON.stringify(jsonBody),
            mode: 'cors',
            credentials: 'include',
        };
    } else {
        requestObject = {
            method: httpMethod,
            headers: {
                Accept: 'application/json, text/plain, text/html, */*',
                'Content-Type': 'application/json',
                xTimezoneOffset: getTimezoneOffset(),
                Authorization: `Bearer ${token}`,
            },
            body: jsonBody && JSON.stringify(jsonBody),
            mode: 'cors',
            credentials: !token ? 'include' : 'omit',
        };
    }

    return requestObject;
};

const generateSearchPath = (path, searchConfiguration) => {
    const singleSearchParam = JSON.stringify(searchConfiguration);
    const searchPath = path ? `${path}=${encodeURI(singleSearchParam)}` : `${encodeURI(singleSearchParam)}`;
    return searchPath;
};

const performRequest = async ({ url, request, resultEnvelopeTemplate }) => {
    const getResponseEnvelope = async response => {
        /**
         * Return json or a message from response depending on what type it is.
         * It prevents redundant errors considering invalid response type.
         */
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            return await response.json();
        } else {
            return {
                payLoad: null,
                error: `Unexpected result type: ${await response.text()}`,
                statusCode: response.status
            };
        }
    };

    try {
        const response = await fetch(url, request);

        /**
         * Standard result object from every request attempt
         */
        if (response.ok) {
            const result = await getResponseEnvelope(response);
            if(result && result.error && result.error.type === 'TechnicalBreak'){
                throw Error(JSON.stringify(result));
            }
            if (result.payLoad || result.error || result.statusCode) {
                return {
                    ...resultEnvelopeTemplate,
                    ...result,
                };
            } else {
                return {
                    ...resultEnvelopeTemplate,
                    error: `Unexpected result shape: ${JSON.stringify(result)}`,
                };
            }
        } else if (response.status !== 200) {
            const result = await getResponseEnvelope(response);

            if(result && result.error) {
                return {
                    ...resultEnvelopeTemplate,
                    ...result,
                };
            }
        }
    } catch (error) {
        return {
            ...resultEnvelopeTemplate,
            unhandledError: error,
        };
    }
};

module.exports = {
    performRequest,
    generateSearchPath,
    generateRequestObject,
};
