import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { FormGroup, Input, Label, UncontrolledTooltip  } from 'reactstrap';

import { inputProps } from '../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    label: PropTypes.string,
    inputProps: inputProps,
    tooltipText: PropTypes.string
};

const CheckboxWithTooltip = ({ label, inputProps, tooltipText }) => {
    return (
        <div className={styles.container}>
            <FormGroup check className="checkbox">
                <Input
                    {...inputProps}
                    className="form-check-input"
                    type="checkbox"
                    id={`checkboxWithTooltip-${inputProps.name}`}
                />
                <Label
                    check
                    className="form-check-label"
                    htmlFor={`checkboxWithTooltip-${inputProps.name}`}
                >
                    {label}
                </Label>
            </FormGroup>
            <span className={styles.tooltip}>
                <i
                    className={clsx('fa fa-question-circle fa-lg', styles.icon)}
                    id={`checkboxWithTooltipTooltip-${inputProps.name}`}
                />
                <UncontrolledTooltip placement="top" target={`checkboxWithTooltipTooltip-${inputProps.name}`}>
                    {tooltipText}
                </UncontrolledTooltip>
            </span>
        </div>
    );
};

CheckboxWithTooltip.propTypes = propTypes;

export default CheckboxWithTooltip;