import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCountries } from '../../../redux/methods/countries';
import { filterCountriesList } from '../../../utils/regions';
import useFetch from '../../../hooks/useFetch';

import { Container, Col, Row} from 'reactstrap';

import Modal from '../../../components/modals/Modal';

import ListGroup from './ListGroup';

import { countryPropTypes } from '../../../propTypes';

const propTypes = {
    assignedCountries: PropTypes.arrayOf(countryPropTypes),
    addToAssignedList: PropTypes.func,
    removeFromAssignedList: PropTypes.func,
    isOpen: PropTypes.func,
    setIsOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    header: PropTypes.string,
    getCountries: PropTypes.func,
};
export const CountryAssigner = ({
    assignedCountries, addToAssignedList, getCountries,
    removeFromAssignedList, isOpen, setIsOpen, onSubmit, header
}) => {
    const intl = useIntl();
    const translations = intl.messages.regions.countryAssigner;

    const [ countries ] = useFetch(getCountries);

    const [countriesSearch, setCountriesSearch] = useState('');
    const [countriesToAssignSearch, setCountriesToAssignSearch] = useState('');

    const handleChangeCountriesSearch = e => {
        setCountriesSearch(e.target.value);
    };

    const handleChangeCountriesToAssignSearch = e => {
        setCountriesToAssignSearch(e.target.value);
    };

    const handleAddToAssignedList = countryToAssign => () => {
        addToAssignedList(countryToAssign);
    };

    const handleRemoveFromAssignedList = country => () => {
        removeFromAssignedList(country);
    };

    const assignedCountriesIdList = assignedCountries.map(({ id }) => id);
    const unassignedCountries  = countries ? countries.filter(country => !(assignedCountriesIdList.includes(country.id))) : [];

    const filteredCountries = filterCountriesList(unassignedCountries, countriesSearch) || [];
    const filteredCountriesToAssign = filterCountriesList(assignedCountries, countriesToAssignSearch) || [];

    return countries ? (
        <Modal
            header={header}
            onConfirm={onSubmit}
            className='modal-lg'
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            buttons={{
                cancel: {
                    color: 'danger',
                    label: translations.buttons.cancel,
                },
                confirm: {
                    color: 'success',
                    label: translations.buttons.confirm,
                },
            }}
            body={
                <Container fluid>
                    <Row>
                        <Col xs='12' lg='6' className='mb-3 sm-lg-0'>
                            <ListGroup
                                header={{
                                    title: translations.lists.countries.title,
                                    text: translations.lists.countries.text,
                                }}
                                counter={{
                                    left: filteredCountries.length,
                                    right: unassignedCountries.length,
                                }}
                                searchInput={{
                                    value: countriesSearch,
                                    onChange: handleChangeCountriesSearch,
                                    placeholder: translations.searchPlaceholder,
                                }}
                                countries={filteredCountries}
                                tooltipCountry={translations.tooltipCountry}
                                onItemClick={handleAddToAssignedList}
                                color='danger'
                            />
                        </Col>
                        <Col xs='12' lg='6'>
                            <ListGroup
                                header={{
                                    title: translations.lists.countriesToAssign.title,
                                    text: translations.lists.countriesToAssign.text,
                                }}
                                counter={{
                                    left: filteredCountriesToAssign.length,
                                    right: assignedCountries.length,
                                }}
                                searchInput={{
                                    value: countriesToAssignSearch,
                                    onChange: handleChangeCountriesToAssignSearch,
                                    placeholder: translations.searchPlaceholder,
                                }}
                                countries={filteredCountriesToAssign}
                                tooltipCountry={translations.tooltipCountry}
                                onItemClick={handleRemoveFromAssignedList}
                            />
                        </Col>
                    </Row>
                </Container>
            }
        />
    ) : null;
};

CountryAssigner.protoTypes = propTypes;

const mapDispatchToProps = {
    getCountries,
};

export default connect(null, mapDispatchToProps)(CountryAssigner);
