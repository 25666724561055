const colorEditionController = {
    colorsHeader: 'Kolory składowe:',
    emptyPlaceholder: 'Brak kolorów składowych',
    actions: {
        add: 'DODAJ KOLOR SKŁADOWY',
        edit: 'Edytuj kolor składowy',
        remove: 'Usuń kolor składowy'
    }
};

export default colorEditionController;