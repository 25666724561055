import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

import useFetch from '../../../../hooks/useFetch';
import {
    getPromotionBarSlides,
    deletePromotionBarSlide,
    updatePromotionBarSlides,
    movePromotionBarSlides,
} from '../../../../redux/methods/homePage';
import { promotionBarSlideObjectConfig } from '../../../../config';

import { Container } from 'reactstrap';
import PromotionBarSlidesTable from '../../../../components/tables/PromotionBarSlidesTable';
import PromotionBarSlideEditModal from './PromotionBarSlideEditModal';
import SectionAsideButtons from '../../../../components/SectionAsideButtons';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import styles from './index.module.scss';

const propTypes = {
    translations: PropTypes.object,
    currentLanguage: PropTypes.string,
    deletePromotionBarSlide: PropTypes.func,
    movePromotionBarSlides: PropTypes.func,
    getPromotionBarSlides: PropTypes.func,
    updatePromotionBarSlides: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const PromotionBarSlides = ({
    translations,
    currentLanguage,
    deletePromotionBarSlide,
    movePromotionBarSlides,
    getPromotionBarSlides,
    updatePromotionBarSlides,
    forceRefetch,
    utils
}) => {
    const [ slideToEdit, setSlideToEdit ] = useState(null);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ slides ] = useFetch(getPromotionBarSlides, null, [], forceRefetch);

    const handleEditClick = slide => {
        setSlideToEdit(slide);
        setIsModalOpen(true);
    };

    const handleRemove = id => {
        deletePromotionBarSlide(id);
    };

    const handleAddSlide = () => {
        updatePromotionBarSlides({ ...promotionBarSlideObjectConfig, activeInLanguage: [ utils.currentLanguage() ] })
            .then(newlyCreatedId => {
                if(!forceRefetch && slides) {
                    setSlideToEdit({
                        ...promotionBarSlideObjectConfig,
                        promotionBarId: newlyCreatedId,
                        activeInLanguage: [ utils.currentLanguageId() ]
                    });
                    setIsModalOpen(true);
                }
            });
    };

    const handleSubmit = form => {
        updatePromotionBarSlides(form);
    };

    return slides ? (
        <Container fluid className={clsx('mb-6', styles.fullScreenOnMobile)}>
            <div className='animated fadeIn'>
                <PromotionBarSlidesTable
                    slides={slides}
                    translations={translations.table}
                    removeAction={handleRemove}
                    editAction={handleEditClick}
                    changeSlideOrder={movePromotionBarSlides}
                    updatePromotionBarSlides={updatePromotionBarSlides}
                    currentLanguage={currentLanguage}
                />
                <SectionAsideButtons
                    buttons={{
                        add: {
                            color: 'primary',
                            label: translations.actions.add,
                            action: handleAddSlide,
                            iconClass: 'fa fa-plus mr-2',
                        }
                    }}
                />
                {
                    slideToEdit
                        && <PromotionBarSlideEditModal
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                onSubmit={handleSubmit}
                                slide={slideToEdit}
                                translations={translations}
                            />
                }
            </div>
        </Container>
    ) : null;
};

PromotionBarSlides.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.promotionBarSlides,
});

const mapDispatch = {
    getPromotionBarSlides,
    deletePromotionBarSlide,
    updatePromotionBarSlides,
    movePromotionBarSlides,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(PromotionBarSlides));