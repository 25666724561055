import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProductPresentationCart, updateProductPresentationCart } from '../../../../redux/methods/products';

import useFetch from '../../../../hooks/useFetch';

import { Col, Container, Row, Label, Card, CardHeader, CardBody, TabContent } from 'reactstrap';

import ArticlePresentationCardTable from '../../../../components/tables/ArticlePresentationCardTable';
import SectionAsideButtons from '../../../../components/SectionAsideButtons';
import SaveDataToast from '../../../../components/SaveDataToast';

const propTypes = {
    getProductPresentationCart: PropTypes.func,
    updateProductPresentationCart: PropTypes.func,
    translations: PropTypes.object,
};

import TabulatorNav from '../../../../components/TabulatorNav';

const ProductCardSettings = ({ getProductPresentationCart, updateProductPresentationCart, translations }) => {

    const [ activeTab, setActiveTab ] = useState(0);

    const [tabParamsDesktop, setTabParamsDesktop] = useState([]);
    const [tabParamsTablet, setTabParamsTablet] = useState([]);
    const [tabParamsMobile, setTabParamsMobile] = useState([]);

    const [mainParamsDesktop, setMainParamsDesktop] = useState([]);
    const [mainParamsTablet, setMainParamsTablet] = useState([]);
    const [mainParamsMobile, setMainParamsMobile] = useState([]);

    const [isEdited, setIsEdited] = useState(false);

    const [ data ] = useFetch(getProductPresentationCart, 'productCard', []);

    const sortByOrder = (type,device,fields) => fields.sort((a,b) => (a.parameters[type][device].order < b.parameters[type][device].order) ? -1 : 1);

    useEffect(() => {
        if(data){
            const { fields } = data;

            setTabParamsDesktop(sortByOrder('tab', 'desktop', [...(JSON.parse(JSON.stringify(fields)))]));
            setTabParamsTablet(sortByOrder('tab', 'tablet', [...(JSON.parse(JSON.stringify(fields)))]));
            setTabParamsMobile(sortByOrder('tab', 'mobile', [...(JSON.parse(JSON.stringify(fields)))]));

            setMainParamsDesktop(sortByOrder('main', 'desktop', [...(JSON.parse(JSON.stringify(fields)))]));
            setMainParamsTablet(sortByOrder('main', 'tablet', [...(JSON.parse(JSON.stringify(fields)))]));
            setMainParamsMobile(sortByOrder('main', 'mobile', [...(JSON.parse(JSON.stringify(fields)))]));
        }

        return () => {
            setTabParamsDesktop([]);
            setTabParamsTablet([]);
            setTabParamsMobile([]);

            setMainParamsDesktop([]);
            setMainParamsTablet([]);
            setMainParamsMobile([]);

            setIsEdited(false);
        };
    }, [data]);

    const handleSubmit = async () => {

        const extractListParams = (tag, type, device, list) => {
            const entry = list.find(item => item.tag === tag);
            return {
                show: entry.parameters[type][device].show,
                order: list.indexOf(entry)
            };
        };

        const generatedArray = data.fields.map((param) => ({
            ...param,
            parameters: {
                ...param.parameters,
                tab: {
                    desktop: extractListParams(param.tag, 'tab', 'desktop', tabParamsDesktop),
                    tablet: extractListParams(param.tag, 'tab', 'tablet', tabParamsTablet),
                    mobile: extractListParams(param.tag, 'tab', 'mobile', tabParamsMobile)
                },
                main : {
                    desktop: extractListParams(param.tag, 'main', 'desktop', mainParamsDesktop),
                    tablet: extractListParams(param.tag, 'main', 'tablet', mainParamsTablet),
                    mobile: extractListParams(param.tag, 'main', 'mobile', mainParamsMobile)
                }
            }
        }));

        await updateProductPresentationCart({
            updateMain: true,
            updateTab: true,
            updateList: false,
            updateTile: false,
            updateComparison: false,
            fields: generatedArray,
        });

        setIsEdited(false);
    };

    const generateProperTabContent = (device, tabParams, setTabParams, mainParams, setMainParams) => (
        <Row>
            <Col>
                <Card>
                    <CardHeader><Label>Widoczność na zakładce parametry - {device}</Label></CardHeader>
                    <CardBody>
                        <ArticlePresentationCardTable
                            type='tab'
                            device={device}
                            parameters={tabParams}
                            setParameters={setTabParams}
                            headerLabels={translations.headerLabels}
                            translations={translations.table.labels}
                            setIsEdited={setIsEdited}
                        />
                    </CardBody>
                </Card>
            </Col>
            <Col>
                <Card>
                    <CardHeader><Label>Widoczność na karcie artykułu - {device}</Label></CardHeader>
                    <CardBody>
                        <ArticlePresentationCardTable
                            type='main'
                            device={device}
                            parameters={mainParams}
                            setParameters={setMainParams}
                            headerLabels={translations.headerLabels}
                            translations={translations.table.labels}
                            setIsEdited={setIsEdited}
                            checkLimit={8}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );

    return tabParamsDesktop && tabParamsTablet && tabParamsMobile && mainParamsDesktop && mainParamsTablet && mainParamsMobile? (
        <Container fluid>
            <SaveDataToast isEdited={isEdited} handleSubmit={handleSubmit} />

            <TabulatorNav
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                tabsList={[
                    {iso: 0, label: 'Desktop'},
                    {iso: 1, label: 'Tablet'},
                    {iso: 2, label: 'Mobile'},
                ]}
                withoutFlag={true}
            />

            <TabContent>
                <div style={{padding: '10px', display: activeTab === 0 ? 'block' : 'none'}}>
                    {generateProperTabContent('desktop', tabParamsDesktop, setTabParamsDesktop, mainParamsDesktop, setMainParamsDesktop)}
                </div>
                <div style={{padding: '10px', display: activeTab === 1 ? 'block' : 'none'}}>
                    {generateProperTabContent('tablet', tabParamsTablet, setTabParamsTablet, mainParamsTablet, setMainParamsTablet)}
                </div>
                <div style={{padding: '10px', display: activeTab === 2 ? 'block' : 'none'}}>
                    {generateProperTabContent('mobile', tabParamsMobile, setTabParamsMobile, mainParamsMobile, setMainParamsMobile)}
                </div>
            </TabContent>

            <SectionAsideButtons
                buttons={{
                    add: {
                        color: 'success',
                        label: translations.actions.save,
                        action: handleSubmit,
                        iconClass: 'fa fa-save mr-2',
                }
                }}
            />
        </Container>
    ) : null;
};

ProductCardSettings.propTypes = propTypes;

const mapDispatch = {
    getProductPresentationCart,
    updateProductPresentationCart
};

export default connect(null, mapDispatch)(ProductCardSettings);