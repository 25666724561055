import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useFetch from '../../../hooks/useFetch';

import {
    getShippingFormsForms,
    getShippingFormsForm,
    addEditShippingFormsForm,
    deleteShippingFormsForm
} from '../../../redux/methods/shippingForms';

import ContainerWithCard from '../../../components/ContainerWithCard';
import TableGenerator from '../../generators/TableGenerator';
import ShippingFormFormEditionModal from '../../../components/modals/ShippingFormFormEditionModal';
import { Button } from 'reactstrap';

import styles from './index.module.scss';
import { formUtilsHocPropTypes } from '../../../propTypes';
import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    utils: formUtilsHocPropTypes,
    isConnectedToERP: PropTypes.bool,
    getShippingFormsForms: PropTypes.func,
    getShippingFormsForm: PropTypes.func,
    addEditShippingFormsForm: PropTypes.func,
    deleteShippingFormsForm: PropTypes.func,
    shippingVariantId: PropTypes.number,
    shippingVariantWeightId: PropTypes.number,
    forceRefetch: PropTypes.bool
};

const ShippingFormsTable = ({
    utils,
    isConnectedToERP,
    getShippingFormsForms,
    getShippingFormsForm,
    addEditShippingFormsForm,
    deleteShippingFormsForm,
    shippingVariantId,
    shippingVariantWeightId,
    forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.shippingForms.formsTable;

    const { headers, editModal } = translations;

    const [ isEditModalOpen, setIsEditModalOpen ] = useState(false);
    const [ editModalItem, setEditModalItem ] = useState(null);

    const [ items ] = useFetch(getShippingFormsForms, {shippingVariantId, shippingVariantWeightId}, [], forceRefetch);

    const editAction = id => async () => {
        const item = await getShippingFormsForm({shippingVariantId, shippingVariantWeightId, shippingFormId: id});
        if (item && item.payload && !item.payload.errors && !item.error) {
            setEditModalItem(item.payload);
            setIsEditModalOpen(true);
        }
    };

    const deleteAction = id => async () => {
        await deleteShippingFormsForm({shippingVariantId, shippingVariantWeightId, shippingFormId: id});
    };

    const handleEditAction = form => {
        const objectToSend = {
            ...form,
            shippingVariantId,
            shippingFormId: form.id};

        if (!objectToSend.shippingVariantWeightId || !objectToSend.productId) {
            utils.error(translations.errors.productIdIsRequired);
            return;
        }
        else if (isConnectedToERP && !objectToSend.wfMagFormId) {
            utils.error(translations.errors.wfMagFormIdIsRequired);
            return;
        }
        else {
            addEditShippingFormsForm(objectToSend);
            setIsEditModalOpen(false);
        }
    };

    const handleAddAction = async () => {
        const item = await getShippingFormsForm({shippingVariantId, shippingVariantWeightId, shippingFormId: 0});
        if (item && item.payload && !item.payload.errors && !item.error) {
            setEditModalItem(item.payload);
            setIsEditModalOpen(true);
        }
    };

    return items ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-wallpaper'>
            <TableGenerator
                items={items}
                expectedActions={['edit', 'delete']}
                actions={{editAction, deleteAction}}
                defaultAction={editAction}
                translations={translations}
                columns={[
                    {
                        header: headers.name,
                        value: 'name',
                    },
                    {
                        header: headers.shippingMethod,
                        value: 'shippingMethod',
                    },
                    {
                        header: headers.productName,
                        value: 'productName',
                    },
                    isConnectedToERP && {
                        header: headers.wfMagFormName,
                        value: 'wfMagFormName',
                    },
                    {
                        header: headers.paymentForms,
                        value: 'paymentForms',
                    },
                    {
                        header: headers.payment,
                        value: 'payment',
                    },
                    {
                        header: headers.visibility,
                        value: 'visibility',
                    },
                    {
                        header: headers.actions,
                        type: 'actions'
                    }
                ]}
            />
            <aside className={styles.footer}>
                <Button color='primary' onClick={handleAddAction}>
                    <i className='fa fa-plus mr-2'/>
                    <span>{translations.buttons.create}</span>
                </Button>
            </aside>
            <ShippingFormFormEditionModal
                isOpen={isEditModalOpen}
                setIsOpen={setIsEditModalOpen}
                translations={editModal}
                shippingForm={editModalItem}
                onSubmit={handleEditAction}
            />
        </ContainerWithCard>
    ) : null;
};

ShippingFormsTable.propTypes = propTypes;

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
    forceRefetch: state.refetchers.shippingForms,
});

const mapDispatch = {
    getShippingFormsForms,
    getShippingFormsForm,
    addEditShippingFormsForm,
    deleteShippingFormsForm,
};

export default withFormUtils(withRouter(connect(mapStateToProps, mapDispatch)(ShippingFormsTable)));