import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Input, Button } from 'reactstrap';
import CategoryTree from './CategoryTree';
import styles from './index.module.scss';

const CategorySelect = ({categories, handleSelectionChanged, handleSelectionConfirmed, initialySelectedId = null}) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.categorySelectModal;

    const [ searchText, setSearchText ] = useState(null);
    const [ selectedCategoryId, setSelectedCategoryId ] = useState(initialySelectedId);
    const [ membersRefresh, setMembersRefresh ] = useState(0);
    const [ members, setMembers ] = useState(categories);

    useEffect(() => {
        setMembers(categories);
    }, [ categories ]);

    const refresh = () => {
        setMembers(members);
        setMembersRefresh(membersRefresh+1);
    };

    const setExpansionState = (member, state) => {
        if (Array.isArray(member)) {
            for(const idx in member) {
                setExpansionState(member[idx], state);
            }
        }
        else {
            member.expanded = state;
            if (member.children && member.children.length) {
                setExpansionState(member.children, state);
            }
        }
    };

    const expandAll = () => {
        setExpansionState(members, true);
        refresh();
    };

    const collapseAll = () => {
        setExpansionState(members, false);
        refresh();
    };

    const toggle = (member, id) => {
        if (Array.isArray(member)) {
            for(const idx in member) {
                toggle(member[idx], id);
            }
        }
        else {
            if (parseInt(member.id) === id) {
                member.expanded = !member.expanded;
            }
            else{
                if (member.children && member.children.length) {
                    toggle(member.children, id);
                }
            }
        }
    };

    const handleExpand = (id) => {
        toggle(members, parseInt(id));
        refresh();
    };

    const select = (member, id) => {
        if (Array.isArray(member)) {
            for(const idx in member) {
                select(member[idx], id);
            }
        }
        else {
            member.selected = parseInt(member.id) === id;
            if (member.children && member.children.length) {
                select(member.children, id);
            }
        }
    };

    useEffect(() => {
        select(members, initialySelectedId);
        handleSelectionChanged(parseInt(initialySelectedId));
        refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialySelectedId]);

    const handleSelect = (id) => {
        select(members, id);
        handleSelectionChanged(parseInt(id));
        refresh();
    };

    let searchedCategoryId = selectedCategoryId;
    let searchedCategoryPassed = false;
    let searchedCategoryFound = false;

    const searchTraverse = (member) => {
        if (Array.isArray(member)) {
            for(const idx in member){
                searchTraverse(member[idx]);
            }
        }
        else {
            if (searchedCategoryId !== null && searchedCategoryPassed === false && searchedCategoryFound === false) {
                if (parseInt(member.id) === searchedCategoryId) {
                    member.selected = false;
                    searchedCategoryPassed = true;
                }
            }
            else
            {
                if (member.name.toLowerCase().indexOf(searchText) > -1) {
                    if (searchedCategoryId === null) {
                        member.selected = true;
                        searchedCategoryFound = true;
                        searchedCategoryId = parseInt(member.id);
                    }
                    else if (searchedCategoryPassed === true && searchedCategoryFound === false) {
                        member.selected = true;
                        searchedCategoryFound = true;
                        searchedCategoryId = parseInt(member.id);
                    }
                    else {
                        member.selected = false;
                    }
                }
                else {
                    member.selected = false;
                }
            }
            if (member.children && member.children.length) {
                for(const idx in member.children) {
                    searchTraverse(member.children[idx]);
                }
            }
        }
    };

    const search = () => {
        searchedCategoryPassed = false;
        searchedCategoryFound = false;
        searchTraverse(members);

        // jeśli nie udało się znaleźć kolejnej pasującej kategorii a istniały wcześniejsze, pasujące kategorie
        // to należy zaznaczyć pierwszą z nich - realizowane jest to przez ponowne szukanie
        if (searchedCategoryId !== null && searchedCategoryFound === false){
            setSelectedCategoryId(searchedCategoryId = null);
            searchedCategoryPassed = false;
            searchedCategoryFound = false;
            searchTraverse(members);
        }

        setExpansionState(members, true);
        setSelectedCategoryId(searchedCategoryId);
        handleSelectionChanged(searchedCategoryId);
        refresh();
    };

    const searchKeyDown = e => {
        if (e.key === 'Enter') {
            if (e.ctrlKey === true){
                if (selectedCategoryId !== null) {
                    handleSelectionConfirmed(selectedCategoryId);
                }
            }
            else{
                search();
            }
        }
        else {
            searchedCategoryId = null;
        }
    };

    const searchOnChange = e => {
        setSearchText(e.target.value.toLowerCase());
    };

    if (selectedCategoryId !== null){
        const el = document.getElementById("categoryTreeItem-"+selectedCategoryId);
        if (el) {
            el.scrollIntoView();
        }
    }

    const maxHeight = parseInt(Math.floor(window.innerHeight * 0.5));

    return (
        <div>
            <div className="row m-2">
                <div className={`col-sm-12 col-md-9 d-flex p-2 ${styles.searchBar}`}>
                    <label className={`pt-1 pr-1 ${styles.searchBarLabel}`}>{translations.searchLabel}</label>
                    <Input autoFocus="autoFocus" onKeyDown={searchKeyDown} onChange={searchOnChange} className="form-control"></Input>
                    <Button onClick={search} className="btn btn-success ml-1">Szukaj</Button>
                </div>
                <div className="col-sm-12 col-md-3 text-right">
                    <span onClick={expandAll} className={styles.expandIcon}></span>
                    <span onClick={collapseAll} className={styles.collapseIcon}></span>
                </div>
            </div>
            <div className={`m-2 ${styles.categoryTreeBox}`} style={{maxHeight: maxHeight+'px'}}>
                <CategoryTree
                    members={members}
                    membersRefresh={membersRefresh}
                    handleExpand={handleExpand}
                    handleSelect={handleSelect} />
            </div>
        </div>
    );
};

CategorySelect.propTypes = {
    categories: PropTypes.arrayOf(Object),
    handleSelectionChanged: PropTypes.func,
    handleSelectionConfirmed: PropTypes.func
};

export default CategorySelect;