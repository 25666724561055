import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getSectionProducts, addDeleteSectionProducts } from '../../../../redux/methods/homePage';
import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';
import useFetch from '../../../../hooks/useFetch';
import useSearchConfig from '../../../../hooks/useSearchConfig';
import { translateProductName } from '../../../../utils/products';

import { Button, Container } from 'reactstrap';

import ProductWithDeleteActionTable from '../../../../components/tables/ProductWithDeleteActionTable';
import ProductSearchModal from '../../../products/ProductSearchModal';
import Paginator from '../../../../components/Paginator';

import { stringOrNumberPropTypes } from '../../../../propTypes';

import styles from './index.module.scss';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    getProducts: PropTypes.func,
    addProductRelatedProducts: PropTypes.func,
    id: stringOrNumberPropTypes,
    deleteProductRelatedProducts: PropTypes.func,
    storeCurrentLanguage: PropTypes.string,
    forceRefetch: PropTypes.bool,
};

const ProductAssigner = ({
    utils,
    getSectionProducts,
    addDeleteSectionProducts,
    forceRefetch,
    storeCurrentLanguage,
    id,
    type,
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.relatedProducts;

    const searchParams = {
        countRows: true,
        pageSize: 10,
        currentPage: 0,
        productName: null,
        catalogueIndex: null,
        tradeIndex: null
    };

    const [checkedProductsId, setCheckedProductsId] = useState([]);
    const [checkedProductsToAssignId, setCheckedProductsToAssignId] = useState([]);
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

    const [ searchConfig, changePage ] = useSearchConfig({ searchParams });

    const [ products, pages ] = useFetch(getSectionProducts, ({ id, type, formData: searchParams }), [], forceRefetch);

    const translatedProducts = products && translateProductName(products, storeCurrentLanguage);
    useEffect(() => {
        setCheckedProductsId([]);
        setCheckedProductsToAssignId([]);
        setIsSearchModalOpen(false);
    }, [products]);

    const handleUpdateSecitonProducts = (idToUpdate, isDeleting = true) => {
        const objectToSend = {
            productsIds: Array.isArray(idToUpdate) ? idToUpdate : [idToUpdate],
            delete: isDeleting
        };

        addDeleteSectionProducts(({ id, type, formData: objectToSend }));

    };

    const handleRemoveSingle = idToRemove => {
        handleUpdateSecitonProducts(idToRemove);
    };

    const handleOpenDeleteModal = () => {
        utils.confirm(
            () => handleUpdateSecitonProducts(checkedProductsId),
            translations.deleteManyModal.header,
            translations.deleteManyModal.body
        );
    };

    const handleOpenProductSearchModal = () => {
        setIsSearchModalOpen(true);
    };

    const handleAssignProducts = () => {
        handleUpdateSecitonProducts(checkedProductsToAssignId, false);
    };

    return products ? (
        <Container fluid>
            <ProductWithDeleteActionTable
                translatedProductsList={translatedProducts}
                checkedProductsId={checkedProductsId}
                setCheckedProductsId={setCheckedProductsId}
                removeAction={handleRemoveSingle}
                deleteModal={translations.deleteModal}
                tooltipTranslation={translations.tooltips}
                fillers={translations.fallbacks}
                emptyTable={translations.empty}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={searchConfig.curentPage + 1}
                        onPageChange={changePage}
                    />
            }
            <aside className={styles.footer}>
                <Button outline type='button' onClick={handleOpenDeleteModal} color='danger' disabled={!checkedProductsId.length > 0}>
                    <i className='fa fa-remove mr-2'/>
                    <span>{translations.buttons.delete}</span>
                </Button>
                <Button color='primary' onClick={handleOpenProductSearchModal}>
                    <i className='fa fa-plus mr-2'/>
                    <span>{translations.buttons.add}</span>
                </Button>
            </aside>
            <ProductSearchModal
                isOpen={isSearchModalOpen}
                setIsOpen={setIsSearchModalOpen}
                checkedProductsId={checkedProductsToAssignId}
                setCheckedProductsId={setCheckedProductsToAssignId}
                onConfirm={handleAssignProducts}
            />
        </Container>
    ) : null;
};

ProductAssigner.propTypes = propTypes;

const mapStateToProps = state => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
    forceRefetch: state.refetchers.sectionProducts,
});

const mapDispatch = {
    getSectionProducts,
    addDeleteSectionProducts
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ProductAssigner));
