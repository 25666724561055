import React from 'react';
import PropTypes from 'prop-types';
import CategoryTreeItem from '../CategoryTreeItem';

const CategoryTree = ({members, handleExpand, handleSelect}) => {
    return (
        members.map((member, i) => {
            return (
                <div key={`level-${member.level}-${i}`}>
                    <CategoryTreeItem member={member} handleExpand={handleExpand} handleSelect={handleSelect} />
                </div>
            );
        })
    );
};

CategoryTree.propTypes = {
    members: PropTypes.arrayOf(Object).isRequired,
    level: PropTypes.number,
    handleExpand: PropTypes.func,
    handleSelect: PropTypes.func
};

export default CategoryTree;