const currencies = {
    view: {
        title: 'Lista walut',
        actions: {
            addCurrency: 'DODAJ WALUTĘ',
        },
        buttons: {
            activate: 'Aktywuj',
            deactivate: 'Deaktywuj'
        },
        statuses: {
            erp: 'ERP',
            shop: 'Sklep'
        },
        headerLabels: {
            isFromErp: 'Pochodzenie',
            name: 'Nazwa',
            shortName: 'Skrót',
            isActive: 'Status',
            regions: 'Regiony',
            actions: 'Akcje',
        },
        emptyPlaceholders: {
            name: '[BRAK NAZWY]',
            shortName: '[BRAK SKRÓTU]',
            table: 'Nie znaleziono żadnych walut',
            regions: '[BRAK REGIONÓW]',
        },
        currencyIsActiveText:{
            active: 'Aktywna',
            inactive: 'Nieaktywny',
        },
        tooltips: {
            edit: 'Edytuj walutę',
            remove: 'Usuń walutę',
        },
        deleteModal: {
            header: 'Usuń walutę',
            body: 'Czy jesteś pewien, że chcesz usunąć walutę?',
            cancel: 'WRÓC',
            confirm: 'USUŃ',
        },
        errorModalText: 'Wystąpił błąd podczas usuwania waluty'
    },
    edit: {
        tabSelectorTitle: 'Sekcje',
        tabs: {
            basic: {
                title: 'Dane podstawowe',
                labels: {
                    name: 'Nazwa',
                    shortName: 'Skrócona nazwa',
                },
                dropdown: {
                    label: 'Status waluty:',
                    active: 'Aktywna',
                    inactive: 'Niekatywna',
                },
                showSymbol: {
                    main: 'Symbol',
                    true: 'Pokazuj symbol',
                    false: 'Pokazuj skróconą nazwę',
                },
                tooltip: 'W przypadku braku symbolu waluty, pokazana będzie skrocona nazwa',
            },
            regions: {
                title: 'Regiony',
                sectionButtons: {
                    add: 'PRZYPISZ REGION',
                    remove: 'ODŁĄCZ REGION'
                },
                assignModal: {
                    header: 'Przypisywanie regionu',
                    body: 'Wybierz region do przypisania: ',
                    cancel: 'WRÓĆ',
                    confirm: 'PRZYPISZ',
                },
                deleteManyModal: {
                    header: 'Odłączanie regionów',
                    body: 'Czy jesteś pewien, że chcesz odłączyć wybrane regiony od waluty?',
                },
                tables: {
                    deleteModal: {
                        header: 'Odłączanie regionu',
                        body: 'Czy jesteś pewien, że chcesz odłączyć region od wybranej waluty?',
                        cancel: 'WRÓC',
                        confirm: 'ODŁĄCZ',
                    },
                    headers: {
                        name: 'Nazwa',
                        actions: 'Akcje',
                    },
                    tooltip: 'Odłącz region',
                    fillers: {
                        name: '[BRAK NAZWY]',
                        table: 'Brak regionów do wyświetlenia'
                    }
                }
            }
        }
    }
};

export default currencies;