import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCategoryTree } from '../redux/methods/categories';
import { selectTranslatedFlattenedCategoryTree, selectIsCategoryTreePresent } from '../redux/selectors/categories';

export const withCategories = Component => {
    const CategoryTree = ({ flattenTranslatedCategoryTree, categoryTree, isCategoryTreePresent, getCategoryTree, ...props }) => {
        useEffect(() => {
            if (!isCategoryTreePresent) {
                getCategoryTree();
            }
        }, []); //eslint-disable-line

        return flattenTranslatedCategoryTree ? <Component categories={flattenTranslatedCategoryTree} notFlattenCategories={categoryTree} {...props} /> : null;
    };

    const mapStateToProps = state => ({
        flattenTranslatedCategoryTree: selectTranslatedFlattenedCategoryTree(state),
        isCategoryTreePresent: selectIsCategoryTreePresent(state),
        categoryTree: state.categories.categoryTree,
    });

    const mapDispatch = {
        getCategoryTree,
    };

    return connect(mapStateToProps, mapDispatch)(CategoryTree);
};