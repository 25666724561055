import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CategorySelect from '../../../../components/CategorySelect';
import { Button } from 'reactstrap';

import { selectIsCategoryTreePresent } from '../../../../redux/selectors/categories';
import { getCategoryTree } from '../../../../redux/methods/categories';
import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';

const propTypes = {
    setSelectedCategory: PropTypes.func,
    isCategoryTreePresent: PropTypes.bool,
    getCategoryTree: PropTypes.func,
    categoryTree: PropTypes.array,
    currentLanguage: PropTypes.string,
    selectedCategory: PropTypes.number,
};

const CategorySelection = ({
    setSelectedCategory,
    isCategoryTreePresent,
    getCategoryTree,
    categoryTree,
    currentLanguage,
    selectedCategory,
    label,
    buttonLabel,
}) => {
    useEffect(() => {
        if (!isCategoryTreePresent) {
            getCategoryTree();
        }
    }, []); // eslint-disable-line

    const getTranslation = (node) => {
        for(const n in node){
            if (node[n].iso.toLowerCase() === currentLanguage){
                return node[n].value;
            }
        }
        return null;
    };

    const prepareCategories = (nodes, path, level) => {
        return nodes.map(n => {
            const translatedName = getTranslation(n.category.name);
            return {
                id: n.category.id,
                name: translatedName,
                expanded: false,
                selected: false,
                level: level,
                path: (path === '' ? '' : path + ' => ') + translatedName,
                children: n.subItems && Array.isArray(n.subItems) && n.subItems.length > 0
                    ? prepareCategories(n.subItems, (path === '' ? '' : path + ' => ') + translatedName, level + 1)
                    : null
            };
        });
    };

    const handleCategoryChanged = (id) => {
        setSelectedCategory(id);
    };

    const handleSetEmptyCategory = () => {
        setSelectedCategory(null);
    };

    return (
        <div className='w-100 d-flex justify-content-center flex-column'>
            <span><strong>{label}</strong></span>
            <div>
                <Button
                    className='my-3'
                    color='info'
                    disabled={!selectedCategory}
                    onClick={handleSetEmptyCategory}
                >
                    { !selectedCategory && <i className={'fa fa-check mr-2'}/> }
                    <span>{buttonLabel}</span>
                </Button>
            </div>
            <CategorySelect
                initialySelectedId={selectedCategory}
                categories={prepareCategories(categoryTree, '', 0)}
                handleSelectionChanged={handleCategoryChanged}
            />
        </div>
    );
};

CategorySelection.propTypes = propTypes;

const mapStateToProps = state => ({
    categoryTree: state.categories.categoryTree,
    currentLanguage: selectStoreCurrentLanguage(state),
    isCategoryTreePresent: selectIsCategoryTreePresent(state)
});

const mapDispatch = {
    getCategoryTree
};

export default connect(mapStateToProps, mapDispatch)(CategorySelection);