const countries = {
    view: {
        title: 'Countries list',
        actions: {
            addCountry: 'ADD COUNTRY',
        },
        headerLabels: {
            name: 'Name',
            iso: 'ISO code',
            actions: 'Actions',
            active: 'Active'
        },
        tooltips: {
            edit: 'Edit country',
        },
        labelText: 'Country name:',
        modalHeader: 'Editing country name translations',
        nameFiller: '[NO TRANSLATION]',
        emptyTable: 'No countries to display',
    },
    searchForm: {
        name: {
            label: 'Country name:',
            placeholder: '',
        },
        iso: {
            label: 'ISO code:',
            placeholder: '',
        },
        action: 'SEARCH',
        reset: 'RESET',
    },
};

export default countries;