import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import styles from './index.module.scss';

import useFetch from '../../hooks/useFetch';

import { getOrder } from '../../redux/methods/orders';

import { convertToLocaleDateTime } from '../../utils/components';

const propTypes = {
    item: PropTypes.object,
    getOrder: PropTypes.func
};

const OrderDetails = ({ item, getOrder }) => {
    const intl = useIntl();
    const translations = intl.messages.orders.orderDetails;

    const [ order ] = useFetch(getOrder, item.id);

    return (order ?
        <div className={styles.orderDetailsContainer}>

            <div className={styles.orderDetailsContainerRow}>
                <fieldset className="col-4">
                    <label>{translations.generalDetails.caption}</label>
                    <table>
                        <tr>
                            <th>{translations.generalDetails.fieldId}</th>
                            <td>{order.order.id}</td>
                        </tr>
                        <tr>
                            <th>{translations.generalDetails.fieldNumber}</th>
                            <td>{order.order.number}</td>
                        </tr>
                        {
                            order.order.erpNumber &&
                            <tr>
                                <th>{translations.generalDetails.fieldErpNumber}</th>
                                <td>{order.order.erpNumber}</td>
                            </tr>
                        }
                        <tr>
                            <th>{translations.generalDetails.fieldPaymentMethod}</th>
                            <td>{order.order.paymentMethod}</td>
                        </tr>
                        <tr>
                            <th>{translations.generalDetails.fieldShippingMethod}</th>
                            <td>{order.order.shippingMethod}</td>
                        </tr>
                        <tr>
                            <th>{translations.generalDetails.fieldOrderStatus}</th>
                            <td>{order.order.orderStatus}</td>
                        </tr>
                        {
                            order.order.orderStatusErp &&
                            <tr>
                                <th>{translations.generalDetails.fieldOrderStatusErp}</th>
                                <td>{order.order.orderStatusErp}</td>
                            </tr>
                        }
                        <tr>
                            <th>{translations.generalDetails.fieldDiscountName}</th>
                            <td>{order.order.discountName}</td>
                        </tr>
                    </table>
                </fieldset>

                <fieldset className="col-4">
                    <label>{translations.puchaserDetails.caption}</label>
                    <table>
                        <tr>
                            <th>{translations.puchaserDetails.fieldCompanyNamePersonName}</th>
                            <td>{order.order.companyDetails.name} / {order.order.clientAddress.name} {order.order.clientAddress.surname}</td>
                        </tr>
                        <tr>
                            <th>{translations.puchaserDetails.fieldNip}</th>
                            <td>{order.order.companyDetails.nip}</td>
                        </tr>
                        <tr>
                            <th>{translations.puchaserDetails.fieldAddress}</th>
                            <td>{order.order.clientAddress.address}</td>
                        </tr>
                        <tr>
                            <th>{translations.puchaserDetails.fieldZipCode}</th>
                            <td>{order.order.clientAddress.zipCode}</td>
                        </tr>
                        <tr>
                            <th>{translations.puchaserDetails.fieldCity}</th>
                            <td>{order.order.clientAddress.city}</td>
                        </tr>
                        <tr>
                            <th>{translations.puchaserDetails.fieldCountry}</th>
                            <td>{order.order.clientAddress.countryId} </td>
                        </tr>
                    </table>
                </fieldset>

                {order.order.shippingDetails.shipmentMethodType === 'OdbiorOsobisty' &&
                    <fieldset className="col-4">
                        <label>{translations.odbiorOsobisty.caption}</label>
                        <table>
                            <tr>
                                <th>{translations.odbiorOsobisty.fieldAddress}</th>
                                <td>{order.order.shippingDetails.shipmentAddressId}</td>
                            </tr>
                        </table>
                    </fieldset>
                }

                {order.order.shippingDetails.shipmentMethodType === 'OdbiorWPunkcie' &&
                    <fieldset className="col-4">
                        <label>{translations.odbiorWPunkcie.caption}</label>
                        <table>
                            <tr>
                                <th>{translations.odbiorWPunkcie.fieldName}</th>
                                <td>{order.order.shippingDetails.name}</td>
                            </tr>
                            <tr>
                                <th>{translations.odbiorWPunkcie.fieldType}</th>
                                <td>{order.order.shippingDetails.type}</td>
                            </tr>
                            <tr>
                                <th>{translations.odbiorWPunkcie.fieldStreet}</th>
                                <td>{order.order.shippingDetails.street}</td>
                            </tr>
                            <tr>
                                <th>{translations.odbiorWPunkcie.fieldZipCode}</th>
                                <td>{order.order.shippingDetails.zipCode}</td>
                            </tr>
                            <tr>
                                <th>{translations.odbiorWPunkcie.fieldCity}</th>
                                <td>{order.order.shippingDetails.city}</td>
                            </tr>
                            <tr>
                                <th>{translations.odbiorWPunkcie.fieldPhone}</th>
                                <td>{order.order.shippingDetails.phone}</td>
                            </tr>
                            <tr>
                                <th>{translations.odbiorWPunkcie.fieldOptions}</th>
                                <td>
                                    {order.order.shippingDetails.kurier48 ? 'kurier48' : ''}
                                    {order.order.shippingDetails.ekspres24 ? 'ekspres24' : ''}
                                    {order.order.shippingDetails.paczkaEkstra24 ? 'paczkaEkstra24' : ''}
                                </td>
                            </tr>
                        </table>
                    </fieldset>
                }

                {order.order.shippingDetails.shipmentMethodType === 'InPost' &&
                    <fieldset className="col-4">
                        <label>{translations.inPost.caption}</label>
                        <table>
                            <tr>
                                <th>{translations.inPost.fieldCode}</th>
                                <td>{order.order.shippingDetails.parcelLocker.name}</td>
                            </tr>
                            <tr>
                                <th>{translations.inPost.fieldAddress}</th>
                                <td>{order.order.shippingDetails.parcelLocker.address.line1}<br/>{order.order.shippingDetails.parcelLocker.address.line2}</td>
                            </tr>
                            <tr>
                                <th>{translations.inPost.fieldPhoneNumber}</th>
                                <td>{order.order.shippingDetails.phoneNumber}</td>
                            </tr>
                        </table>
                    </fieldset>
                }

                {order.order.shippingDetails.shipmentMethodType === 'WysylkaNaAdres' &&
                    <fieldset className="col-4">
                        <label>{translations.wysylkaNaAdres.caption}</label>
                        <table>
                            <tr>
                                <th>{translations.wysylkaNaAdres.fieldNameSurname}</th>
                                <td>{order.order.shippingDetails.name} {order.order.shippingDetails.surname}</td>
                            </tr>
                            <tr>
                                <th>{translations.wysylkaNaAdres.fieldAddress}</th>
                                <td>{order.order.shippingDetails.address}</td>
                            </tr>
                            <tr>
                                <th>{translations.wysylkaNaAdres.fieldZipCodeCity}</th>
                                <td>{order.order.shippingDetails.zipCode} {order.order.shippingDetails.city}</td>
                            </tr>
                            <tr>
                                <th>{translations.wysylkaNaAdres.fieldPhoneNumber}</th>
                                <td>{order.order.shippingDetails.phoneNumber}</td>
                            </tr>
                            <tr>
                                <th>{translations.wysylkaNaAdres.fieldEmail}</th>
                                <td>{order.order.shippingDetails.email}</td>
                            </tr>
                        </table>
                    </fieldset>
                }

                <fieldset className="col-4">
                    <label>{translations.status.caption}</label>
                    <table>
                        <tr>
                            <th>{translations.status.fieldPlacingDate}</th>
                            <td>{convertToLocaleDateTime(order.order.placingDate)}</td>
                        </tr>
                        <tr>
                            <th>{translations.status.fieldFulfillmentDate}</th>
                            <td>{convertToLocaleDateTime(order.order.fulfillmentDate)}</td>
                        </tr>
                        <tr>
                            <th>{translations.status.fieldShipmentDate}</th>
                            <td>{convertToLocaleDateTime(order.order.shipmentDate)}</td>
                        </tr>
                        <tr>
                            <th>{translations.status.statusChangeDate}</th>
                            <td>{convertToLocaleDateTime(order.order.orderStatusChangeDate)}</td>
                        </tr>
                        {
                            order.order.erpOrderStatusChangeDate &&
                            <tr>
                                <th>{translations.status.erpStatusChangeDate}</th>
                                <td>{convertToLocaleDateTime(order.order.erpOrderStatusChangeDate)}</td>
                            </tr>
                        }
                        {
                            order.order.orderStatusChangeMessage &&
                            <tr>
                                <th>{translations.status.statusChangeMessage}</th>
                                <td>{order.order.orderStatusChangeMessage}</td>
                            </tr>
                        }
                        <tr>
                            <th>{translations.status.fieldInvoiceNumber}</th>
                            <td>{order.order.invoice?.href ?
                                <a href={order.order.invoice?.href} target="_blank" rel="noreferrer noopener">{order.order.invoiceNumber}</a>
                                : order.order.invoiceNumber}
                            </td>
                        </tr>
                        <tr>
                            <th>{translations.status.fieldPackageNumber}</th>
                            <td>{order.order.packageNumber}</td>
                        </tr>
                        <tr>
                            <th>{translations.status.fieldReturnNumber}</th>
                            <td>{order.order.returnNumber}</td>
                        </tr>
                    </table>
                </fieldset>
                <fieldset className="col-4">
                    <label>{translations.prices.caption}</label>
                    <table>
                        <tr>
                            <th>{translations.prices.fieldCurrency}</th>
                            <td>{order.order.currency}</td>
                        </tr>
                        <tr>
                            <th>{translations.prices.fieldTotalNetPrice}</th>
                            <td>{order.order.totalNetPrice.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th>{translations.prices.fieldTotalGrossPrice}</th>
                            <td>{order.order.totalGrossPrice.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th>{translations.prices.fieldTotalWeight}</th>
                            <td>{order.order.totalWeight} kg</td>
                        </tr>
                    </table>
                </fieldset>
            </div>
            {order.order.additionalInfo &&
                <div>
                    <label>{translations.additionalInfo}</label>
                    <div>{order.order.additionalInfo}</div>
                </div>}
        </div> : null
    );
};

OrderDetails.propTypes = propTypes;

const mapDispatch = {
    getOrder
};

export default connect(null, mapDispatch)(OrderDetails);