import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    isActive: PropTypes.bool,
    setIsActive: PropTypes.func,
};

const CurrencyEditActiveDropdown = ({ isActive, setIsActive }) => {
    const intl = useIntl();
    const translations = intl.messages.currencies.edit.tabs.basic.dropdown;
    const [ isOpen, setIsOpen ] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleSetIsActive = active => () => {
        setIsActive(active);
    };

    return (
        <div className={styles.container}>
            <span className="mr-2">{translations.label}</span>
            <Dropdown
                direction="down"
                className={clsx(styles.dropdown, 'mr-2')}
                isOpen={isOpen}
                toggle={handleToggle}
            >
                <DropdownToggle caret color={clsx(isActive ? 'success' : 'danger')}>
                    {isActive ? translations.active : translations.inactive}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={handleSetIsActive(true)}>
                        <span>{translations.active}</span>
                    </DropdownItem>
                    <DropdownItem onClick={handleSetIsActive(false)}>
                        <span>{translations.inactive}</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

CurrencyEditActiveDropdown.propTypes = propTypes;

export default CurrencyEditActiveDropdown;