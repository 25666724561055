/* eslint-disable */
import React from 'react';
import PropTypes from "prop-types";

import FormFieldTranslations from '../../../../../../components/formFields/FormFieldTranslations';

const propTypes = {
    translations: PropTypes.object,
    form: PropTypes.object,
    values: PropTypes.object
};

const EditionFields = ({
    translations,
    form,
    utils,
    name,
}) => {
    return (
        <React.Fragment>
            <div className='mb-3 mt-3'>
                <FormFieldTranslations
                    form={form}
                    modalHeader={translations.inputs.alternativeText}
                    propertyName={`${name}ImageAlternativeText`}
                    currentIso={utils.currentLanguage()}
                    label={translations.inputs.alternativeText}
                />
            </div>
        </React.Fragment>
    );
};

EditionFields.propTypes = propTypes;

export default EditionFields;