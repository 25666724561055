/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateGoogleAnalytics, getGoogleAnalytics } from '../../../../../redux/methods/integrations';
import useFetch from '../../../../../hooks/useFetch';

import { Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';
import SectionEditFormButtons from '../../../../../components/SectionEditFormButtons';
import FormFieldTextOrNumber from '../../../../../components/formFields/FormFieldTextOrNumber';

const propTypes = {
    translations: PropTypes.object,
    updateGoogleAnalytics: PropTypes.func,
    getGoogleAnalytics: PropTypes.func
};

const GoogleAnalyticsSection = ({
    translations,
    updateGoogleAnalytics,
    getGoogleAnalytics
}) => {
    const settings = useFetch(getGoogleAnalytics);

    const handleOnSubmit = form => {
        updateGoogleAnalytics(form);
    };

    return settings[0] ? (
        <FinalForm
            keepDirtyOnReinitialize
            initialValues={settings[0]}
            onSubmit={handleOnSubmit}
            render={({ handleSubmit, form }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <Container fluid>
                            <div className='mt-3'>
                                <FormFieldTextOrNumber
                                    label={translations.labels.googleAnalyticsTag}
                                    name='key'
                                />
                            </div>
                            <div className='mt-3'>
                                <FormFieldTextOrNumber
                                    label={translations.labels.gtmContainerId}
                                    name='gtmKey'
                                />
                            </div>
                            <SectionEditFormButtons formReset={form.reset}/>
                        </Container>
                    </Form>
                );
            }}
        />

    ) : null;
};

GoogleAnalyticsSection.propTypes = propTypes;

const mapDispatch = {
    getGoogleAnalytics,
    updateGoogleAnalytics
};

export default connect(null, mapDispatch)(GoogleAnalyticsSection);
