import React, { useState, useEffect } from 'react';
import useIsForceRefetchChanged from './useIsForceRefetchChanged';

const useFetch = (fetchHandler, searchConfig, dependencies = [], forceRefetch = false) => {
    const isMounted = React.useRef(true);

    const [ value, setValue ] = useState(null);
    const isForceRefetchChanged = useIsForceRefetchChanged(forceRefetch);

    const hydrateValues = async () => {
        const fetchedValues = await fetchHandler(searchConfig);
        if(isMounted.current){
            setValue(fetchedValues);
        }
    };

    useEffect(() => {
        isMounted.current = true;
        if(forceRefetch === true || !isForceRefetchChanged){
            hydrateValues();
        }
        return () => {
            isMounted.current = false;
            setValue(null);
        };
    }, [forceRefetch, ...dependencies]); //eslint-disable-line

    return value ? [ value.payload, value.pages ] : [];
};

export default useFetch;