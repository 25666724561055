import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteFooterImage } from '../../../../redux/methods/menu';

import FileSender from '../../../../components/FileSender';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getFooterImage: PropTypes.func,
    updateFooterImage: PropTypes.func,
    deleteFooterImage: PropTypes.func,
    translations: PropTypes.object,
};

const FileSenderPromotedSections = ({
    translations,
    deleteFooterImage,
    image,
    setFile,
}) => {
    const handleRemoveImage = () => {
        deleteFooterImage();
    };

    return (
        <React.Fragment>
            <FileSender
                setFile={setFile}
                validateFormats={['jpg', 'png', 'jpeg', 'webm', 'webp']}
                buttons={translations}
                photo={image}
                withPreview
                fileKey='FooterImageFile'
                handleRemove={handleRemoveImage}
                graphicTypeToEnableGuide='tile'
            />
        </React.Fragment>
    );
};

FileSenderPromotedSections.propTypes = propTypes;

const mapDispatch = {
    deleteFooterImage,
};

export default connect(null, mapDispatch)(FileSenderPromotedSections);