import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';

import { generateFormToSubmitValues, generateFormInitialValues, getKeyByValue } from '../../../../../utils/components';
import { translateIso } from '../../../../../utils/locale';
import { languageNumericEnum } from '../../../../../enums/locale';
import { contentAndLinkLimitedConfig } from '../../../../../config';

import { withFormUtils } from '../../../../../hoc/withFormUtils';
import { Form as FinalForm, Field } from "react-final-form";
import { Form, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Alert, TabContent } from "reactstrap";
import FormFieldWithCalendarPicker from '../../../../../components/formFields/FormFieldWithCalendarPicker';
import FormFieldTranslationsQuill from '../../../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldTranslations from '../../../../../components/formFields/FormFieldTranslations';
import WarningModal from '../../../../../components/modals/WarningModal';
import TabulatorNav from '../../../../../components/TabulatorNav';
import { formUtilsHocPropTypes, stringOrNumberPropTypes, homePageBannerImagePropTypes } from '../../../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    utils: formUtilsHocPropTypes,
    bannerId: stringOrNumberPropTypes,
    translations: PropTypes.shape({
        inputs: PropTypes.shape({
            content: PropTypes.string,
            dateTo: PropTypes.string,
            dateFrom: PropTypes.string,
        }),
        actions: {
            save: PropTypes.string,
            calncel: PropTypes.string,
        }
    }),
    slide: homePageBannerImagePropTypes,
};

const PromotionBarSlideEditModal =  ({
    translations,
    slide,
    isModalOpen,
    setIsModalOpen,
    onSubmit,
    utils,
}) => {
    const [ activeTab, setActiveTab ] = useState(0);

    const [ isMaxCharLimitExceeded, setIsMaxCharLimitExceeded ] = useState(false);
    const [ isMaxCharLimitExceededTablet, setIsMaxCharLimitExceededTablet ] = useState(false);
    const [ isMaxCharLimitExceededMobile, setIsMaxCharLimitExceededMobile ] = useState(false);

    const config = {
        translations: ['content', 'contentTablet', 'contentMobile', 'hint'],
    };

    const isFormChanged = useRef();
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);

    const toggleModal = () => {
        if (isFormChanged.current) {
            setIsWarningModalOpen(true);
        }
        else {
            setIsModalOpen(!isModalOpen);
        }
    };

    const handleSubmit = form => {
        onSubmit(generateFormToSubmitValues(form, config, utils.allLanguages()));
        setIsModalOpen(!isModalOpen);
    };

    const charControlHandler = editor => {
        const charNumber = editor.plugins.wordcount.body.getCharacterCount();

        switch(activeTab) {
            case 1:
                setIsMaxCharLimitExceededTablet(charNumber > 100);
                break;
            case 2:
                setIsMaxCharLimitExceededMobile(charNumber > 100);
                break;
            default:
                setIsMaxCharLimitExceeded(charNumber > 100);
                break;
        }
    };

    return (
        <Modal isOpen={isModalOpen} toggle={toggleModal} size='xl'>
            <ModalHeader toggle={toggleModal}>{translations.editModal.header}</ModalHeader>
            <FinalForm
                onSubmit={handleSubmit}
                initialValues={generateFormInitialValues(slide, config, utils.allLanguages())}
                render={({ handleSubmit, form, pristine }) => {
                    isFormChanged.current = !pristine;
                    const { values } = form.getState();

                    return (
                        <Form onSubmit={handleSubmit} className='w-100 p-0'>
                            <ModalBody>
                                <div className='mb-3'>
                                    <TabulatorNav
                                        setActiveTab={setActiveTab}
                                        activeTab={activeTab}
                                        tabsList={[
                                            {iso: 0, label: translations.inputs.content + ' - Desktop'},
                                            {iso: 1, label: translations.inputs.content + ' - Tablet'},
                                            {iso: 2, label: translations.inputs.content + ' - Mobile'},
                                        ]}
                                        withoutFlag={true}
                                    />
                                    <TabContent>
                                        <div style={{padding: '10px', display: activeTab === 0 ? 'block' : 'none'}}>
                                            <Alert color='danger' isOpen={isMaxCharLimitExceeded}>
                                                <span>{translations.editModal.warning}</span>
                                            </Alert>
                                            <FormFieldTranslationsQuill
                                                utils={utils}
                                                form={form}
                                                propertyName='content'
                                                customSettings={contentAndLinkLimitedConfig}
                                                charControlHandler={charControlHandler}
                                            />
                                        </div>
                                        <div style={{padding: '10px', display: activeTab === 1 ? 'block' : 'none'}}>
                                            <Alert color='danger' isOpen={isMaxCharLimitExceededTablet}>
                                                <span>{translations.editModal.warning}</span>
                                            </Alert>
                                            <FormFieldTranslationsQuill
                                                utils={utils}
                                                form={form}
                                                propertyName='contentTablet'
                                                customSettings={contentAndLinkLimitedConfig}
                                                charControlHandler={charControlHandler}
                                            />
                                        </div>
                                        <div style={{padding: '10px', display: activeTab === 2 ? 'block' : 'none'}}>
                                            <Alert color='danger' isOpen={isMaxCharLimitExceededMobile}>
                                                <span>{translations.editModal.warning}</span>
                                            </Alert>
                                            <FormFieldTranslationsQuill
                                                utils={utils}
                                                form={form}
                                                propertyName='contentMobile'
                                                customSettings={contentAndLinkLimitedConfig}
                                                charControlHandler={charControlHandler}
                                            />
                                        </div>
                                    </TabContent>
                                </div>
                                <div className='mb-3'>
                                    <FormFieldTranslations
                                        label={translations.inputs.hint}
                                        propertyName='hint'
                                        form={form}
                                        currentIso={utils.currentLanguage()}
                                        modalHeader={translations.inputs.hint}
                                        type='textarea'
                                    />
                                </div>
                                <div>
                                    <Label>{translations.inputs.availableInLanguages}: </Label>
                                    {
                                        utils.allLanguages().map(language =>
                                            <div key={`${language}--checkbox`}>
                                                <Field
                                                    name='activeInLanguage'
                                                    value={parseInt(getKeyByValue(languageNumericEnum, language))}
                                                    component='input'
                                                    type='checkbox'
                                                    id={language}
                                                />
                                                <Label for={language} className={'pl-2'}>
                                                    {translations.languages[language]}
                                                    <i className={clsx('flag-icon', `flag-icon-${translateIso(language).toLowerCase()}`, styles.flag)} />
                                                </Label>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={clsx('mt-3', styles.fieldContainer)}>
                                    <FormFieldWithCalendarPicker
                                        label={translations.inputs.dateFrom}
                                        name='dateFrom'
                                        maxDate={values.dateTo}
                                        className={styles.field}
                                        storeCurrentLanguage={utils.currentLanguage()}
                                    />
                                    <FormFieldWithCalendarPicker
                                        className={clsx('ml-3', styles.field)}
                                        label={translations.inputs.dateTo}
                                        name='dateTo'
                                        minDate={values.dateFrom}
                                        storeCurrentLanguage={utils.currentLanguage()}
                                    />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" type="submit" disabled={isMaxCharLimitExceeded}>
                                    {translations.actions.save}
                                </Button>
                                <Button color="secondary" type="button" onClick={toggleModal}>
                                    {translations.actions.cancel}
                                </Button>
                            </ModalFooter>
                            <WarningModal
                                setIsOpen={setIsWarningModalOpen}
                                isOpen={isWarningModalOpen}
                                setIsMainModalOpen={setIsModalOpen}
                            />
                        </Form>
                    );
                }}
            />
        </Modal>
    );
};

PromotionBarSlideEditModal.propTypes = propTypes;

export default withFormUtils(PromotionBarSlideEditModal);
