import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getIntegrationP24, updateIntegrationP24 } from '../../../redux/methods/integrations';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';

import { Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';

const propTypes = {
    getIntegrationP24: PropTypes.func,
    updateIntegrationP24: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const P24 = ({
    getIntegrationP24,
    updateIntegrationP24,
    forceRefetch
}) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.p24;

    const [ data ] = useFetch(getIntegrationP24, null, [], forceRefetch);

    return data ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-cogs'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateIntegrationP24}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>

                                <div className='my-2'><FormFieldSwitchPill label={translations.labels.enabled} name='enabled' /></div>

                                <div className='row'>
                                    <div className='col-12 col-xl-6'>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.merchantId} name='merchantId' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.posId} name='posId' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.crcKey} name='crcKey' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.reportKey} name='reportKey' /></div>
                                    </div>
                                    <div className='col-12 col-xl-6'>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.apiUrl} name='apiUrl' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.returnUrl} name='returnUrl' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.statusUrl} name='statusUrl' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.redirectUrl} name='redirectUrl' /></div>
                                    </div>
                                </div>

                                <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.sessionIdTemplate} name='sessionIdTemplate' /></div>
                                <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.descriptionTemplate} name='descriptionTemplate' /></div>
                                <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.transferLabelTemplate} name='transferLabelTemplate' /></div>

                                <SectionEditFormButtons formReset={form.reset}/>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconClass='fa fa-facebook'/>;
};

const mapDispatch = {
    getIntegrationP24,
    updateIntegrationP24
};

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.p24Integration
});

P24.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatch)(P24);