import Agreements from '../../views/agreements/Agreements';
import AgreementsEdit from '../../views/agreements/AgreementsEdit';
import AgreementsRegister from '../../views/agreements/AgreementsRegister';
import CookiesAgreements from '../../views/extrasSettings/CookiesAgreements';
import CookiesAgreementsEdit from '../../views/extrasSettings/CookiesAgreementsEdit';

export const rodoRoutes = {
    ROUTE_RODO_AGREEMENTS: {
        id: 'ROUTE_RODO_AGREEMENTS',
        path: '/agreements/list',
        type: 'rodo',
        component: Agreements,
        icon: 'cil-list',
        exact: true,
    },
    ROUTE_RODO_AGREEMENTS_EDIT: {
        id: 'ROUTE_RODO_AGREEMENTS_EDIT',
        exact: true,
        path: '/agreements/list/edit',
        component: AgreementsEdit,
    },

    ROUTE_RODO_AGREEMENTS_REGISTRY: {
        id: 'ROUTE_RODO_AGREEMENTS_REGISTRY',
        path: '/agreements/register',
        type: 'rodo',
        component: AgreementsRegister,
        icon: 'cil-columns',
        exact: true,
    },

    ROUTE_RODO_COOKIES_AGREEMENTS: {
        id: 'ROUTE_RODO_COOKIES_AGREEMENTS',
        path: '/extras/cookiesAgreements',
        type: 'rodo',
        component: CookiesAgreements,
        icon: 'fal fa-cookie-bite',
        exact: true,
    },
    ROUTE_RODO_COOKIES_AGREEMENTS_EDIT: {
        id: 'ROUTE_RODO_COOKIES_AGREEMENTS_EDIT',
        path: '/extras/cookiesAgreements/edit',
        component: CookiesAgreementsEdit,
        exact: true,
    },
};