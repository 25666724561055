import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import useSearchConfig from '../../../hooks/useSearchConfig';
import useAddFetch from '../../../hooks/useAddFetch';

import { addService } from '../../../redux/methods/products';

import { Container, Card, CardBody, CardHeader, Button } from 'reactstrap';

import ServiceSearchForm from '../../../components/ServiceSearchForm';

import { serviceSearchConfig } from '../../../config/searchConfigs';

import styles from './index.module.scss';

import Table from './Table';
import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    history: PropTypes.object,
    getProducts: PropTypes.func,
    addService: PropTypes.func,
    forceRefetch: PropTypes.bool,
    storeCurrentLanguage: PropTypes.string,
    deleteProduct: PropTypes.func,
};

const ShipmentServicesList = ({
    history,
    addService,
}) => {
    const intl = useIntl();
    const translations = intl.messages.services.shipments.view;

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(Object.assign(serviceSearchConfig, { productTypeId: 2 }));
    const [ id, add ] = useAddFetch(addService);

    useEffect(() => {
        if (id) {
            history.push(`/shipmentServices/list/edit?productID=${id}`);
        }
    }, [history, id]);

    const handleAddService = () => {
        add(2, null);
    };

    return (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className="animated fadeIn">
                <Card>
                    <CardHeader>
                        <i className={clsx('fa fa-align-justify')}></i>
                        <span>{translations.title}</span>
                    </CardHeader>
                    <CardBody className="position-relative">
                        <main>
                            <ServiceSearchForm
                                additionalDefaultSearchConfig={{ productTypeId: 3 }}
                                performProductSearch={changeSearchConfig}
                                searchConfig={searchConfig}
                            />

                            <Table
                                searchConfig={searchConfig}
                                changeSearchConfig={changeSearchConfig}
                                changePage={changePage}
                            />
                        </main>
                        <aside className={styles.footer}>
                            <Button color="primary" onClick={handleAddService}>
                                <i className="fa fa-plus mr-2"/>
                                <span>{translations.actions.addService}</span>
                            </Button>
                        </aside>
                    </CardBody>
                </Card>
            </div>
        </Container>
    );
};

ShipmentServicesList.propTypes = propTypes;

const mapDispatch = {
    addService
};

export default connect(null, mapDispatch)(withFormUtils(ShipmentServicesList));