import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { useIntl } from 'react-intl';

import { Form } from "react-final-form";
import { Form as ReactstrapForm, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import FormFieldColorPicker from '../../components/formFields/FormFieldColorPicker';
import FormFieldTranslations from '../../components/formFields/FormFieldTranslations';
import FormFieldWithCheckbox from '../../components/formFields/FormFieldWithCheckbox';
import FormFieldWithDropdown from '../../components/formFields/FormFieldWithDropdown';
import DefaultSpinner from '../../components/loaders/DefaultSpinner';
import WarningModal from '../modals/WarningModal';

import { withFormUtils } from '../../hoc/withFormUtils';
import FormFieldTextOrNumber from "../formFields/FormFieldTextOrNumber";
import useFetch from "../../hooks/useFetch";
import { connect } from "react-redux";
import { getDictionaryMappingFields } from '../../redux/methods/attributes';
import { getTranslationsFromArray } from "../../utils/getTranslationsFromArray";

const propTypes = {
    header: PropTypes.string,
    isEditModalOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    setIsEditModalOpen: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    getDictionaryMappingFields: PropTypes.func,
};

const EditNameModal = ({ data, header, utils, isEditModalOpen, isLoading, setIsEditModalOpen, onSubmit, getDictionaryMappingFields, isConnectedToERP }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.editNameModal;

    const isFormChanged = useRef();
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);

    const config = { translations: ['name'] };

    const toggleModal = () => {
        if (isFormChanged.current) {
            setIsWarningModalOpen(true);
        }
        else {
            setIsEditModalOpen(!isEditModalOpen);
        }
    };

    const handleSubmit = async formData => {
        await onSubmit(utils.generateFormToSubmitValues(formData, config));
        setIsEditModalOpen(false);
    };

    const [dictionaryMappingFields] = useFetch(getDictionaryMappingFields);

    const dropdownOptions = dictionaryMappingFields && dictionaryMappingFields.map(item => ({
        id: parseInt(item.id),
        label: getTranslationsFromArray(item.translations, utils.currentLanguage())
    }));

    return (
        <Modal isOpen={isEditModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
            <Form
                onSubmit={handleSubmit}
                initialValues={utils.generateFormInitialValues(data, config)}
                render={({ handleSubmit, form, pristine, values }) => {
                    isFormChanged.current = !pristine;
                    const selected = dropdownOptions && dropdownOptions.find(item => item.id === values.mappingFieldId);

                    return (
                        <ReactstrapForm onSubmit={handleSubmit}>
                            <ModalBody className="px-4">
                                {
                                    isConnectedToERP ? (
                                        <>
                                            <div className='my-3'>
                                                <FormFieldWithCheckbox
                                                    name='mappingEnabled'
                                                    label={translations.labels.mappingEnabled}
                                                />
                                            </div>
                                            {values.mappingEnabled &&
                                                <div className='my-3'>
                                                    {dropdownOptions
                                                        ? <div className='my-3'>
                                                            <FormFieldWithDropdown
                                                                label={translations.labels.mappingFieldId}
                                                                name='mappingFieldId'
                                                                options={dropdownOptions}
                                                                currentSelectionLabel={
                                                                    selected
                                                                        ? (selected.label || selected.id)
                                                                        : dropdownOptions[0].label || dropdownOptions[0].id
                                                                }
                                                            />
                                                        </div>
                                                        : <DefaultSpinner isLoading />
                                                    }
                                                    <FormFieldTextOrNumber
                                                        name='mappingValue'
                                                        label={translations.labels.mappingValue}
                                                        className='w-100 mt-3 mt-md-0'
                                                    />
                                                </div>
                                            }
                                        </>
                                    ) : null
                                }
                                <FormFieldTranslations
                                    form={form}
                                    label={translations.labels.name}
                                    propertyName='name'
                                    modalHeader={translations.labels.name}
                                    currentIso={utils.currentLanguage()}
                                />
                                <div className='my-3'>
                                    <FormFieldColorPicker
                                        name='textColor'
                                        button={translations.labels.button}
                                        label={translations.labels.textColor}
                                    />
                                </div>
                                <div className='my-3'>
                                    <FormFieldColorPicker
                                        name='backgroundColor'
                                        button={translations.labels.button}
                                        label={translations.labels.backgroundColor}
                                    />
                                </div>
                                <div className='my-3'>
                                    <FormFieldColorPicker
                                        name='borderColor'
                                        button={translations.labels.button}
                                        label={translations.labels.borderColor}
                                    />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" type="submit" disabled={isLoading}>
                                    {translations.save}
                                </Button>
                                <Button color="secondary" type="button" disabled={isLoading} onClick={toggleModal}>
                                    {translations.cancel}
                                </Button>
                            </ModalFooter>
                            <WarningModal
                                setIsOpen={setIsWarningModalOpen}
                                isOpen={isWarningModalOpen}
                                setIsMainModalOpen={setIsEditModalOpen}
                            />
                        </ReactstrapForm>
                    );
                }}
            />
        </Modal>
    );
};

EditNameModal.propTypes = propTypes;

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
});

const mapDispatch = {
    getDictionaryMappingFields,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(EditNameModal));