const emails = {
	view: {
		title: 'Serwery pocztowe',
		actions: {
			addServer: 'Dodaj serwer pocztowy'
		},
		columnHeaders:{
			senderdisplayname: 'Nazwa nadawcy',
			senderemail: 'Email nadawcy',
			isdefault: 'Konto domyślne',
			host: 'Host',
			port: 'Port',
			useimap: 'Konto IMAP',
			imapport: 'Port IMAP',
			securesocketoption: 'Zabezpieczenia',
			actions: 'Akcje'
		},
		emptyTable: 'Brak zdefiniowanych serwerów pocztowych',
		tooltips: {
			edit: 'Edytuj',
			remove: 'Usuń'
		},
		deleteModal: {
			header: 'Serwer pocztowy:',
			body: 'Czy na pewno usunąć wskazany serwer pocztowy?',
			confirm: 'USUŃ',
			cancel: 'ANULUJ'
		},
	},
	tableDefinitions: {
		viewHeader: 'Lista tabel przypisanych do szablonu e-mail',
		table:{
			headerLabels: {
				id: 'Id',
				name: 'Nazwa tabeli',
				actions: 'Akcje'
			},
			tooltips:{
				edit: 'Edytuj wygląd tabeli'
			}
		}
	},
    settings: {
        emailTemplatesHeader: 'Lista szablonów e-mail',
        table: {
            headerLabels: {
                index: 'Lp',
                name: 'Nazwa',
				account: 'Serwer pocztowy',
                edited: 'Stan',
                actions: 'Akcje'
            },
            nameFiller: '[BRAK NAZWY]',
			accountFiller: '[DOMYŚLNY]',
            emptyTable: 'Brak szablonów do wyświetlenia',
            tooltips: {
                edit: 'Edytuj'
            },
            isEdited: {
                edited: 'Edytowany',
                unedited: 'Nieedytowany'
            },
			tableDefinitionsActionTooltip: 'Przejdź do definicji wyglądu tabel'
        },
		sections: {
			general: 'Ustawienia podstawowe',
			additionalEmails: 'Dodatkowe adresy e-mail'
		},
        inputs: {
            sendToAdmin: 'Wysyłaj do administratora',
            addButtonText: 'Dodaj kolejny adres email',
            contnet: 'Treść',
            title: 'Temat'
        },
        actions: {
            save: 'ZAPISZ ZMIANY',
            cancel: 'ANULUJ'
        },
        editModal: {
            header: 'Edycja szablonu email'
        },
        insertValueButton: 'Wstaw wartość'
    },
    server: {
        title: 'Ustawienia serwera SMTP',
		smtpHeader: 'Ustawienia SMTP',
		imapHeader: 'Ustawienia IMAP',
		loginHeader: 'Ustawienia logowania',
        testMessageSectionLabel: 'Wiadomość testowa',
        wrongPasswordAlert: 'Podane hasła nie są jednakowe.',
        labels: {
            sendersEmail: 'Adres e-mail nadawcy',
            sendersNameToDisplay: 'Wyświetlana nazwa nadawcy',
            SMTPServerAddress: 'Adres serwera SMTP',
            SMTPPortNumber: 'Numer portu SMTP',
            IMAPServer: 'Serwer IMAP',
            IMAPPort: 'Port IMAP',
            useSSL: 'Użyj bezpiecznego połączenia SSL',
            useTLS: 'Użyj bezpiecznego połączenia TLS',
            userName: 'Nazwa użytkownika',
            password: 'Hasło',
            confirmPassword: 'Potwierdź hasło',
            websiteAdress: 'Adres strony (dla newslettera)',
            emailAddress: 'Adres e-mail',
			isDefault: 'Konto domyślne',
            imapSentFolderName: 'Folder IMAP dla wysłanych maili',
            secureSocketOptionLabel: 'Ustawienia bezpieczeństwa',
            sectureTokenOptions: {
                none: 'Brak',
                Auto: 'Automatyczne',
                sslOnConnect: 'SSL',
                startTls: 'TLS',
                startTlsWhenAvailable: 'TLS jeśli jest dostępne'
            },
			useAuthentication: 'Używaj autoryzacji'
        },
        actions: {
            sendTestEmail: 'Wyślij email testowy'
        },
		testEmailSended: 'E-mail testowy został wysłany',
		testEmailNotDefined: 'Nie podano testowego adresu e-mail'
    },
	emailTemplateTable: {
		viewTableHeader: 'Wygląd nagłówków tabeli',
		viewColumnsHeader: 'Kolumny tabeli',
		previewHeader: 'Podgląd wyglądu tabeli',
		tableHeaders:{
			name: 'Nazwa',
			displayName: 'Nazwa wyświetlana',
			font: 'Czcionka',
			fontColor: 'Kolor tekstu',
			fontSize: 'Rozmiar tekstu [px]',
			backgroundColor: 'Kolor tła',
			verticalAlignment: 'Wyr. pion',
			horizontalAlignment: 'Wyr. poz',
			isBold: 'Pogrubienie',
			isItalic: 'Kursywa',
			isUnderline: 'Podkreślenie'
		},
		tableColumnHeaders: {
			id: 'Id',
			name: 'Nazwa',
			font: 'Czcionka',
			fontColor: 'Kolor tekstu',
			fontSize: 'Rozmiar tekstu [px]',
			backgroundColor: 'Kolor tła',
			verticalAlignment: 'Wyr. pion',
			horizontalAlignment: 'Wyr. poz',
			isBold: 'Pogrubienie',
			isItalic: 'Kursywa',
			isUnderline: 'Podkreślenie',
			visible: 'Widoczna'
		},
		horizontalAlignments: {
			undefined: 'Niezdefiniowane',
			left: 'Lewo',
			center: 'Środek',
			right: 'Prawo'
		},
		verticalAlignments: {
			undefined: 'Niezdefiniowane',
			top: 'Góra',
			center: 'Środek',
			bottom: 'Dół'
		},
		displayNameModal: {
			header: 'Nazwa wyświetlana',
			save: 'Zapisz',
			cancel: 'Anuluj'
		}
	}
};

export default emails;