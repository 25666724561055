import React from 'react';
import { connect } from 'react-redux';

import useFetch from '../hooks/useFetch';

import { getAttributeDictionaryValues } from '../redux/methods/attributes';

export const withTags = Component => {
    const TagsHook = ({ getAttributeDictionaryValues, ...props }) => {
        const [tags] = useFetch(getAttributeDictionaryValues, 1);

        return tags ? <Component tags={tags} {...props} /> : null;
    };

    const mapDispatch = {
        getAttributeDictionaryValues,
    };

    return connect(null, mapDispatch)(TagsHook);
};