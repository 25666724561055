const categories = {
    view: {
        expandAll: 'Rozwiń wszystko',
        collapseAll: 'Zwiń wszystko',
        title: 'Category list',
        dropdownSelector: {
            label: 'Category view:',
            options: {
                all: '[EN] Wszystkie',
                selected: '[EN] Aktywne'
            }
        },
        categoryBreadcrumbsRoot: 'Root',
        emptyCategoryLevel: 'No categories present on this level.',
        deleteModal: {
            header: 'Category:',
            bodyStart: 'Are you sure you want to remove category',
            bodyEnd: '(along with contained subcategories)?',
            confirm: 'REMOVE',
            cancel: 'CANCEL'
        },
        actions: {
            edit: 'Edit category',
            delete: 'Remove category',
            addRoot: 'ADD ROOT CATEGORY',
            addTooltip: '[EN] Dodanie kategorii możliwe tylko w przypadku wyłączonej synchronizacji z systemem ERP',
            addSub: 'ADD SUBCATEGORY'
        },
        dndAlert: '[EN] Przenoszenie kategorii między gałęziami możliwe tylko przy wyłączonej synchronizacji ze systemem ERP.',
        errorModalText: 'Error occured while removing category',
        additionModal: {
            header: 'Add new category',
            body: 'Are you sure you want to create new category?',
            confirm: 'ADD',
            cancel: 'CANCEL'
        },
        warningModal: {
            header: '[EN] Aktywuj kategorię',
            body: '[EN] Aktywowanie kategorii spowoduje aktywację jej podkategorii. Czy chcesz kontynuować?',
            confirm: '[EN] AKTYWUJ',
            cancel: '[EN] WRÓĆ'
        },
        nameFiller: '[NO NAME]'
    },
    edit: {
        nameFiller: '[NO NAME]',
        tabSelectorTitle: 'Sections',
        tabs: {
            basic: {
                title: 'Master data',
                nameFieldLabel: 'Category Name:',
                descriptionFieldLabel: 'Category description:',
                showDescription: 'Show category description',
                externalName: 'Category name in ERP',
                showDescriptionForSubcategories: 'Show description for subcategories',
                showArticles: 'Show articles in menu instead of photos',
                inputs: {
                    showSelected: 'Show Selected'
                },
                status: {
                    tooltip: 'Activate the parent category to be able to edit the status of the category',
                    label: 'Status:'
                },
                statusErp: 'Status in synchronizer:',
                labels: {
                    availableInLanguages: 'Category available in languages'
                },
                languages: {
                    Pl: 'Polish',
                    En: 'English',
                    Ru: 'Russian',
                    Ua: 'Ukrainian'
                },
                syncErp: 'Synchronize name with ERP',
                statusLabels: {
                    editing: "Inactive",
                    published: "Active"
                },
                images: {
                    title: 'Pictures',
                    buttons: {
                        upload: 'UPLOAD PHOTO',
                        remove: 'DELETE PHOTO',
                        edit: 'SWAP PHOTO'
                    },
                    label: 'Photo'
                },
                alignment: {
                    left: 'Left',
                    center: 'Center',
                    right: 'Right',
                    top: 'Up',
                    bottom: 'Down'
                },
                presentation: {
                    cover: 'Area fill',
                    contain: 'Fit to area'
                },

                horizontalAlignmentLabel: 'Horizontal alignment',
                verticalAlignmentLabel: 'Vertical Alignment',
                presentationLabel: 'Presentation',

                fullSizeHeader: 'Positioning a category photo (full menu area)',
                fullSizeDescription: 'This setting applies when the category has no defined description and no subcategories. In this case, the category image is displayed over the entire menu area. ',

                halfSizeHeader: 'Positioning a category photo (right side of the menu area)',
                halfSizeDescription: 'This setting applies when the category has a defined description or has subcategories. In this case, the category image is displayed on the right side of the menu area. ',

                pictogramHeader: 'Category pictogram positioning',
                pictogramDescription: 'This setting applies to the presentation of categories in tiles above the search results and in the menu',
                fileSender: {
                    buttons: {
                        edit: 'SWIPE FROM THE MEDIA LIBRARY',
                        remove: 'REMOVE FROM THE MEDIA LIBRARY',
                        upload: 'PLAY FROM THE MEDIA LIBRARY'
                    }
                },
                showDescriptionAtBottom: 'Show category description at the bottom of the page instead of at the top'
            },
            metatags: {
                title: 'Meta tags'
            },
            attributes: {
                title: 'Features',
                table: {
                    columnNames: {
                        featureName: 'Feature name',
                        featureType: 'Feature type',
                        featurePresentation: 'Presentation',
                        featureActions: 'Actions'
                    },
                    tooltip: {
                        edit: 'Edit feature',
                        remove: 'Remove feature'
                    },
                    deleteModal: {
                        header: 'Assigned feature:',
                        bodyStart: 'Are you sure, you want to remove feature',
                        bodyEnd: 'from list of features assigned to this category?',
                        confirm: 'REMOVE',
                        cancel: 'CANCEL'
                    },
                    placeholder: 'There are no features assigned to this category'
                },
                controls: {
                    add: '[EN] STWÓRZ CECHĘ DLA DANEJ KATEGORII',
                    addTooltip: '[EN] Stworzenie nowej cechy możliwe tylko w przypadku wyłączonej integracji z systemem ERP',

                    remove: 'REMOVE SELECTED FEATURES'
                },
                additionModal: {
                    header: 'Add new feature',
                    body: 'Do you want to add new feature assigned to category',
                    confirm: 'ADD',
                    cancel: 'CANCEL'
                },
                deletionModal: {
                    header: 'Remove selected features',
                    body: 'Do you want to remove all of the selected features from category',
                    confirm: 'REMOVE',
                    cancel: 'CANCEL'
                }
            }
        }
    },
    dndSearch: {
        nameFiller: '[NO NAME]'
    },
    search: {
        options: {
            dropdown: 'List',
            tree: 'Tree'
        },
        label: 'Type of presentation:'
    }
};

export default categories;