import React from 'react';
import { useIntl } from 'react-intl';

import CookiesAgreementsBasic from '../../../components/CookiesAgreementsBasic';
import CookiesAgreementsTable from '../../../components/tables/CookiesAgreementsTable';

import SettingsTabulator from '../../../components/layouts/SettingsTabulator';

const CookiesAgreements = () => {
    const intl = useIntl();
    const translations = intl.messages.cookiesAgreements;

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case "featEditTab-basic":
                return <CookiesAgreementsBasic />;
            case "featEditTab-agreements":
                return <CookiesAgreementsTable />;
            default:
                return null;
        }
    };

    return (
        <SettingsTabulator
            title={{
                label: translations.title,
                isFilled: true,
                iconClassName: "fa fa-edit text-primary ml-3"
            }}
            tabulatorHeader={{
                label: 'tabulatorHeader',
                iconClassName: "fa fa-align-justify"
            }}
            refreshTabComponent={refreshTabComponent}
            tabs={[
                {
                    id: "featEditTab-basic",
                    header:  {
                        label: translations.tabs.basic,
                        iconClassName: "fa fa-tasks"
                    }
                },
                {
                    id: "featEditTab-agreements",
                    header:  {
                        label: translations.tabs.agreements,
                        iconClassName: "fa fa-tasks"
                    }
                },
            ]}
        />
    );
};

export default CookiesAgreements;