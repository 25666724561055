import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { filterItemsList } from '../../../utils/components';

import Modal from '../Modal';
import ListGroup from '../../Assigner/ListGroup';

import { coreUiColorsPropTypes, stringOrNumberPropTypes } from '../../../propTypes';

const propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onConfirm: PropTypes.func,
    header: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
    }),
    onItemClick: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: stringOrNumberPropTypes,
            label: PropTypes.string
        })
    ),
    listItem: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    buttons: PropTypes.shape({
        confirm: PropTypes.shape({
            color: coreUiColorsPropTypes,
            label: PropTypes.string,
            disabled: PropTypes.bool
        }),
        cancel: PropTypes.shape({
            color: coreUiColorsPropTypes,
            label: PropTypes.string,
            disabled: PropTypes.bool
        }),
    }),
    searchPlaceholder: PropTypes.string,
};

const ListSelectionModal = ({
    isOpen,
    setIsOpen,
    onConfirm,
    header,
    onItemClick,
    items,
    listItem,
    buttons,
    searchPlaceholder,
    modalHeader
}) => {
    const [ searchString, setSearchString ] = useState('');
    const handleChangeSearchString = e => setSearchString(e.target.value);

    const filteredItems = items?.length > 0 ? filterItemsList(items, searchString) : [];

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onConfirm={onConfirm}
            header={modalHeader}
            body={
                <ListGroup
                    listItem={listItem}
                    items={filteredItems}
                    header={header}
                    searchInput={{
                        value: searchString,
                        onChange: handleChangeSearchString,
                        placeholder: searchPlaceholder
                    }}
                    onItemClick={onItemClick}
                />
            }
            buttons={buttons}
        />
    );
};

ListSelectionModal.propTypes = propTypes;

export default ListSelectionModal;