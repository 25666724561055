import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Modal from '../../../components/modals/Modal';
import Dropdown from '../../../components/Dropdown';

import { stringOrNumberPropTypes } from '../../../propTypes';
import { generateEditLinkForHomePageEditSection } from '../../../utils/homePage';

import articles from '../../../assets/homePageSections/articles.png';
import articlesWithBookmarks from '../../../assets/homePageSections/articlesWithBookmarks.png';
import doubleImage from '../../../assets/homePageSections/doubleImage.png';
import photoWithText from '../../../assets/homePageSections/photoWithText.png';
import promoted from '../../../assets/homePageSections/promoted.png';
import sliderWithButton from '../../../assets/homePageSections/sliderWithButton.png';
import tiny from '../../../assets/homePageSections/tiny.png';
import manufacturers from '../../../assets/homePageSections/manufacturers.png';
import video from '../../../assets/homePageSections/video.png';
import animatedBanner from '../../../assets/homePageSections/animatedBanner.png';
import contactForm from '../../../assets/homePageSections/contactForm.png';
import map from '../../../assets/homePageSections/map.png';

import styles from './index.module.scss';

const propTypes = {
    translations: PropTypes.object,
    setIsOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    activeSectionTypes: PropTypes.arrayOf(PropTypes.shape({
        id: stringOrNumberPropTypes,
        label: PropTypes.string,
    })),
    currentSelectedTypeId: stringOrNumberPropTypes,
    setCurrentSelectedTypeId: PropTypes.func,
};

const HomePageCheckTypeModal = ({ isOpen, setIsOpen, translations, currentSelectedTypeId, setCurrentSelectedTypeId, activeSectionTypes, sectionLocation, affiliationObjectId }) => {
    const history = useHistory();

    const sectionImages = {
        1: sliderWithButton,
        2: articles,
        3: articlesWithBookmarks,
        4: photoWithText,
        5: tiny,
        6: promoted,
        7: doubleImage,
        8: manufacturers,
        9: video,
        10: animatedBanner,
        11: contactForm,
        12: map
    };

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            header={translations.additionModal.header}
            body={
                <React.Fragment>
                    <small>{translations.additionModal.body}</small>
                    <img className={styles.img} src={sectionImages[currentSelectedTypeId]} alt="Section preview"/>
                    <Dropdown
                        options={activeSectionTypes}
                        currentSelectionLabel={activeSectionTypes.find(item => item.id === currentSelectedTypeId).label}
                        performSelection={setCurrentSelectedTypeId}
                    />
                </React.Fragment>
            }
            buttons={{
                confirm: {
                    label: translations.additionModal.confirm,
                    color: 'primary'
                },
                cancel: {
                    label: translations.additionModal.cancel,
                    color: 'secondary'
                }
            }}
            onConfirm={() => history.push(generateEditLinkForHomePageEditSection(-Math.abs(currentSelectedTypeId), null, sectionLocation, affiliationObjectId))}
        />
    );
};

HomePageCheckTypeModal.propTypes = propTypes;

export default HomePageCheckTypeModal;