import React from 'react';

import OrderStatusesTable from '../../../components/tables/OrderStatusesTable';

const OrderStatuses = () => {

    return (
        <OrderStatusesTable />
    );
};

export default OrderStatuses;