import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import DefaultSpinner from '../loaders/DefaultSpinner';

import { Container, Card, CardBody, CardHeader } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    props: PropTypes.object
};

const ContainerWithCard = ({ children, title, iconClass }) => {
    return (
        <Container fluid className={styles.fullScreenOnMobile}>
                <Card>
                    <CardHeader>
                        <i className={clsx(iconClass)}></i>
                        {title}
                    </CardHeader>
                    <CardBody >
                        <Container fluid>
                            <div className='animated fadeIn'>
                                {children ? children : <DefaultSpinner isLoading/>}
                            </div>
                        </Container>
                    </CardBody>
                </Card>
        </Container>
    );
};

ContainerWithCard.propTypes = propTypes;

export default ContainerWithCard;