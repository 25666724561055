import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import { Form as FinalForm } from 'react-final-form';
import { Button, Form } from 'reactstrap';

import FormFieldWithCheckbox from '../formFields/FormFieldWithCheckbox';
import FormFieldTranslations from '../formFields/FormFieldTranslations';

import { formUtilsHocPropTypes, translationPropTypes } from '../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    className: PropTypes.string,
    utils: formUtilsHocPropTypes,
    markers: PropTypes.arrayOf(PropTypes.shape({
        position: PropTypes.arrayOf(PropTypes.number),
        name: PropTypes.arrayOf(translationPropTypes),
        content: PropTypes.arrayOf(translationPropTypes),
    })),
    setMarkers: PropTypes.func,
};

const MapLeaflet = ({
    utils,
    markers,
    setMarkers
}) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.mapLeaflet;

    const config = { translations: ['name', 'content'] };

    const handleAddMarkerAtLocation = () => {
        if('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const convertedLocation = [ position.coords.latitude.toFixed(2), position.coords.longitude.toFixed(2) ];

                setMarkers(prevState => ([...prevState, {  name: [], content: [], position: convertedLocation}]));
            });
        }
    };

    const AddMarkerHandler = () => {
        useMapEvents({
            dblclick(e) {
                setMarkers(prevState => ([...prevState, { name: [], content: [], position: [e.latlng.lat, e.latlng.lng]}]));
            }
        });
        return null;
    };

    const handleRemoveMarker = (markerToRemoveIndex) => () => {
        setMarkers(prevState => prevState.filter((marker, markerIndex) => markerIndex !== markerToRemoveIndex));
    };

    const DraggableMarker = ( { index, marker } ) => {
        const markerRef = useRef(null);
        const eventHandlers = useMemo(() => ({
            dragend() {
                const marker = markerRef.current;
                if(marker != null) {
                    const markersToEdit = [ ...markers ];
                    const latLng = marker.getLatLng();
                    markersToEdit[index].position = [latLng.lat, latLng.lng];

                    setMarkers(markersToEdit);
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }), []);

        const handleOnSubmit = (form) => {
            const formToSend = utils.generateFormToSubmitValues(form, config);
            const markersToEdit = [ ...markers ];
            markersToEdit[index] = formToSend;

            setMarkers(markersToEdit);
        };

        return (
            <Marker
                draggable={true}
                position={marker.position.map(element => parseFloat(element))}
                eventHandlers={eventHandlers}
                ref={markerRef}
            >
                <Popup minWidth={90}>
                <div>
                    <FinalForm
                        initialValues={utils.generateFormInitialValues(marker, config)}
                        onSubmit={handleOnSubmit}
                        render={({ handleSubmit, form }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <div>
                                        <FormFieldTranslations
                                            form={form}
                                            propertyName='name'
                                            label={translations.inputs.markerName}
                                            currentIso={utils.currentLanguage()}
                                            modalHeader={translations.inputs.markerName}
                                        />
                                        <div className='my-2'>
                                            <FormFieldTranslations
                                                form={form}
                                                propertyName='content'
                                                label={translations.inputs.markerContent}
                                                currentIso={utils.currentLanguage()}
                                                modalHeader={translations.inputs.markerContent}
                                                type='textarea'
                                            />
                                        </div>
                                        <FormFieldWithCheckbox
                                            name='isRedirectToGoogleMaps'
                                            label={translations.inputs.googleMapsRedirect}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <Button
                                            className=''
                                            color='danger'
                                            onClick={handleRemoveMarker(index)}
                                        >
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                        <Button
                                            onClick={handleSubmit}
                                            color="success"
                                        >
                                            <i className={clsx("fa fa-save")}/>
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    />
                </div>
                </Popup>
            </Marker>
        );
    };

    return (
        <div className={styles.container}>
            <MapContainer
                center={markers.length > 0 ? markers[0].position.map(element => parseFloat(element)) : [51.1, 17.04]}
                zoom={12}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {
                    markers.map((marker, index) => (
                        <DraggableMarker
                            key={`${index}-marker-leafletMap`}
                            marker={marker}
                            index={index}
                        />
                    ))
                }
                <AddMarkerHandler />
            </MapContainer>
            <Button
                className='my-2'
                onClick={handleAddMarkerAtLocation}
                color='primary'
            >
                <span>{translations.buttons.setMarkerAtYourLocation}</span>
            </Button>
        </div>
    );
};

MapLeaflet.propTypes = propTypes;

export default MapLeaflet;