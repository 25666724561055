import React, { useState } from 'react';

import CategorySelectModal from '../CategorySelectModal';

const DynamicCategorySelectModal = ({ categorySelect, caption }) => {
    const [ isOpen, setIsOpen ] = useState(true);

    return (
        <CategorySelectModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            caption={caption}
            categorySelect={categorySelect}
        />
    );
};

export default DynamicCategorySelectModal;