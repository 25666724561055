import React from 'react';
import { connect } from 'react-redux';
import useFetch from '../hooks/useFetch';
import { getArticleColumns } from '../redux/methods/products';

export const withArticleColumns = Component => {
    const ArticleColumnsHook = ({ getArticleColumns, ...props }) => {
        const [articleColumns] = useFetch(getArticleColumns);

        return articleColumns ? <Component articleColumns={articleColumns} {...props} /> : null;
    };

    const mapDispatch = {
        getArticleColumns
    };

    return connect(null, mapDispatch)(ArticleColumnsHook);
};