import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getUsers, changeUserStatus, removeUser } from '../../../redux/methods/users';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Container, Card, CardHeader, CardBody, Button } from 'reactstrap';

import styles from './index.module.scss';
import ClientsDashboardTable from '../../../components/tables/ClientsDashboardTable';
import { connect } from 'react-redux';
import { usersSearchConfig } from '../../../config/searchConfigs';
import useSearchConfig from '../../../hooks/useSearchConfig';
import useFetch from '../../../hooks/useFetch';
import { getTranslationsFromArray } from '../../../utils/getTranslationsFromArray';
import ContainerWithCard from '../../../components/ContainerWithCard';
import { withFormUtils } from '../../../hoc/withFormUtils';
import Paginator from '../../../components/Paginator';
import UsersSearchForm from '../../../features/users/UsersSearchForm';

const propTypes = {
    history: PropTypes.object
};

const DashboardClients = ({
    getUsers,
    forceRefetch,
    utils,
    changeUserStatus,
    removeUser,
    history
}) => {
    const intl = useIntl();
    const translations = intl.messages.dashboard.clients;

    const [searchConfig, changePage, changeSearchConfig] = useSearchConfig({ ...usersSearchConfig, pageSize: 10, countRows: true });
    const [users, pages] = useFetch(getUsers, searchConfig, [searchConfig], forceRefetch);

    const translateUsers = users?.map(user => ({
        ...user,
        contractorInErp: getTranslationsFromArray(user.contractorInErp, utils.currentLanguage()),
        accountType: getTranslationsFromArray(user.accountType, utils.currentLanguage()),
    }));

    const handleAddUser = () => history.push('/panelUsers/edit?userID=0');

    return users ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <Card>
                <CardHeader>
                    <i className={clsx('fa fa-align-justify')}></i>
                    <span>{translations.title}</span>
                </CardHeader>
                <CardBody>
                    <Container fluid className={clsx('mb-3 h-100', styles.fullScreenOnMobile)}>
                        <UsersSearchForm changeSearchConfig={changeSearchConfig} searchConfig={searchConfig} />
                        <ClientsDashboardTable
                            users={translateUsers}
                            isLoading={!translateUsers}
                            removeAction={removeUser}
                            changeUserStatus={changeUserStatus}
                        />
                        {
                            pages > 1 &&
                            <Paginator
                                totalPages={pages}
                                currentPage={searchConfig.curentPage + 1}
                                onPageChange={changePage}
                            />
                        }
                        <aside className={styles.footer}>
                            <Button color='primary' onClick={handleAddUser}>
                                <i className='fa fa-plus mr-2' />
                                <span>{translations.actions.addUser}</span>
                            </Button>
                        </aside>
                    </Container>
                </CardBody>
            </Card>
        </Container>
    ) : <ContainerWithCard />;
};

DashboardClients.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.users
});

const mapDispatch = {
    getUsers,
    changeUserStatus,
    removeUser
};

export default withRouter(withFormUtils(connect(mapStateToProps, mapDispatch)(DashboardClients)));