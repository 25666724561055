import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useIntl } from 'react-intl';
import clsx from 'clsx';

const propTypes = {
    className: PropTypes.string,
    lastSync: PropTypes.string,
    isConnectedToERP: PropTypes.bool,
};

const LastSyncDate = ({ className, lastSync, isConnectedToERP, lang }) => {
    const translations = useIntl().messages.global.lastSync;

    return isConnectedToERP && lastSync && <span className={clsx('text-muted', className)}>
        {translations.label}
        {new Intl.DateTimeFormat(lang, { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(lastSync))}
    </span>;
};

LastSyncDate.propTypes = propTypes;

const mapStateToProps = state => ({
    lastSync: state.appInit.lastSynchronizationDate,
    isConnectedToERP: state.appInit.isConnectedToERP,
    lang: state.persisted.persistedLocale.persistedChosenLanguage,
});

export default connect(mapStateToProps)(LastSyncDate);