import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { convertDate } from '../../../utils/components';

import { Form as FinalForm } from 'react-final-form';
import { Form, Button } from 'reactstrap';

import TextOrNumberField from './fields/TextOrNumberField';
import DateField from './fields/DateField';
import DropdownField from './fields/DropdownField';
import CheckboxField from './fields/CheckboxField';
import CategorySelectField from './fields/CategorySelectField';
import ManufacturerSelectField from './fields/ManufacturerSelectField';
import TagSelectField from './fields/TagSelectField';

import styles from './index.module.scss';

const propTypes = {
    performSearch: PropTypes.func,
    translations: PropTypes.shape({
        buttons: PropTypes.shape({
            submit: PropTypes.string,
            reset: PropTypes.string,
        }),
    }),
    inputs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
        type: PropTypes.oneOf(['text', 'number', 'dropdown', 'date', 'checkbox', 'categorySelect', 'manufacturerSelect', 'tagSelect']).isRequired,
        name: PropTypes.string,
        min: PropTypes.number,
        max: PropTypes.number,
        required: PropTypes.bool,
        storeCurrentLanguage: PropTypes.string,
        minDate: PropTypes.instanceOf(Date),
        maxDate: PropTypes.instanceOf(Date),
        minDateDependentField: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
            label: PropTypes.string,
            className: PropTypes.string,
        })),
    })).isRequired,
    searchConfig: PropTypes.object,
    disabled: PropTypes.bool,
};

const SearchFormGenerator = ({ performSearch, translations, inputs, children, searchConfig, disabled, defaultSearchConfig }) => {
    const onSubmit = formData => {
        const withConverterDates = Object.keys(formData)
            .reduce((acc, key) => {
                return formData[key] instanceof Date ? {...acc, [key]: convertDate(formData[key])} : {...acc, [key]: formData[key] };
            }, {});

        performSearch({ ...withConverterDates, curentPage: 0 });
    };

    const handleResetForm = reset => () => {
        reset();
        performSearch({ ...defaultSearchConfig, pageSize: searchConfig?.pageSize} || {});
    };

    return (
        <FinalForm
            initialValues={searchConfig}
            onSubmit={onSubmit}
            render={({ handleSubmit, form, values }) => {
                const areGivenValues = Object.keys(form.getState().values).length > 0;

                return (
                    <Form onSubmit={handleSubmit} className={clsx('p-3', styles.container)}>
                        {
                            inputs.filter(i => i != null).map(input => {
                                switch(input.type){
                                    case 'text':
                                    case 'number':
                                        return <TextOrNumberField key={input.name} {...input} />;
                                    case 'date':
                                        return <DateField key={input.name} {...input} minDate={new Date(values[input.minDateDependentField] || new Date(input.minDate))} />;
                                    case 'dropdown':
                                        return <DropdownField key={input.name} {...input} />;
                                    case 'checkbox':
                                        return <CheckboxField key={input.name} {...input} />;
                                    case 'categorySelect':
                                        return <CategorySelectField key={input.name} {...input} />;
                                    case 'manufacturerSelect':
                                        return <ManufacturerSelectField key={input.name} {...input} />;
                                    case 'tagSelect':
                                        return <TagSelectField key={input.name} {...input} />;
                                    default: return <TextOrNumberField key={input.name} {...input}/>;
                                }
                            })
                        }
                        {children}
                        <span className={clsx('m-1', styles.itemButton)}>
                            <Button
                                type='submit'
                                color='success'
                                className='mt-1'
                                disabled={disabled}
                            >
                                <i className='fa fa-search mr-2'/>
                                <span>{translations.buttons.submit}</span>
                            </Button>
                        </span>
                        <span className={clsx('m-1', styles.itemButton)}>
                            <Button
                                color='danger'
                                className='mt-1'
                                onClick={handleResetForm(form.reset)}
                                disabled={!areGivenValues || disabled}
                            >
                                <i className='fa fa-times mr-2'/>
                                <span>{translations.buttons.reset}</span>
                            </Button>
                        </span>
                    </Form>
                );
            }}
        />
    );
};

SearchFormGenerator.propTypes = propTypes;

export default (SearchFormGenerator);