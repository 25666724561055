import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import useFetch from '../../../hooks/useFetch';
import {
    getPromotionBar,
    updatePromotionBar,
} from '../../../redux/methods/homePage';

import { withFormUtils } from '../../../hoc/withFormUtils';
import SettingsTabulator from '../../../components/layouts/SettingsTabulator';
import PromotionBarParams from '../../../features/promotionBar/editTabs/PromotionBarParams';
import PromotionBarSlides from '../../../features/promotionBar/editTabs/PromotionBarSlides';

import { formUtilsHocPropTypes } from '../../../propTypes';
//import StatsSection from '../../../features/dashboard/sections/StatsSection';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getPromotionBar: PropTypes.func,
    updatePromotionBar: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const PromotionBar = ({
    getPromotionBar,
    updatePromotionBar,
    utils,
    forceRefetch
}) => {
    const intl = useIntl();
    const translations = intl.messages.promotionBar.settings;
    const [ promotionBarParams ] = useFetch(getPromotionBar, null, [], forceRefetch);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'promotionBarBanner-params-settings':
                return <PromotionBarParams translations={translations} params={promotionBarParams} updatePromotionBar={updatePromotionBar} />;
            case 'promotionBarBanner-images-settings':
                return <PromotionBarSlides translations={translations} currentLanguage={utils.currentLanguage()} />;
            default:
                return null;
        }
    };

    return promotionBarParams ? (
        <SettingsTabulator
                title={{
                    label: translations.tabSelectorTitle
                }}
                tabulatorHeader={{
                    label: translations.tabSelectorTitle,
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: `promotionBarBanner-params-settings`,
                        header: {
                            label: translations.tabs.parameters,
                            iconClassName: 'fa fa-wrench'
                        }
                    },
                    {
                        id: `promotionBarBanner-images-settings`,
                        header: {
                            label: translations.tabs.slides,
                            iconClassName: 'fa fa-image'
                        }
                    },
                ]
                }
        />
    ) : null;
};

PromotionBar.propTypes = propTypes;

const mapDispatch = {
    getPromotionBar,
    updatePromotionBar,
};

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.promotionBar
});

export default withFormUtils(connect(mapStateToProps, mapDispatch)(PromotionBar));