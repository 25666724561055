import { getTranslationForIso } from '../components';

export const generateManufacturersListForDropdown = (manufacturesList) => {
    return manufacturesList.map(manufacturer => ({
        label: manufacturer.name,
        id: manufacturer.id
    }));
};

export const generateManufacturersDropdownOptions = (manufacturersList, emptySelectionLabel) => {
    const generatedManufacturesList = manufacturersList.map(manufacturer => ({
        id: manufacturer.id,
        label: manufacturer.name,
    }));
    return emptySelectionLabel ? [ {id: null, label: emptySelectionLabel }, ...generatedManufacturesList ] : generatedManufacturesList;
};

export const generateEditLinkForManufacturers = id => `/manufacturers/edit?manufacturerID=${id}`;

export const translateManufacturer = (m, utils) => ({ ...m, name: getTranslationForIso(m.name, utils.currentLanguage()) });

export const translateManufacturers = (m, utils) => m?.map(m => translateManufacturer(m, utils));
