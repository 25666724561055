import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getOrderGreetings, updateOrderGreetings } from '../../../redux/methods/orders';

import useFetch from '../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Row, Form } from 'reactstrap';

import SectionAsideButtons from '../../../components/SectionAsideButtons';
import FormFieldTranslationsQuill from '../../../components/formFields/FormFieldTranslationsQuill';
import ContainerWithCard from '../../../components/ContainerWithCard';

import { withFormUtils } from '../../../hoc/withFormUtils';

const OrderGreetings = ({ utils, getOrderGreetings, updateOrderGreetings }) => {
    const intl = useIntl();
    const translations = intl.messages.orders.greetings;

    const [ data ] = useFetch(getOrderGreetings);

    const config = { translations: ['summaryContent'] };

    const handleOnSubmit = formData => {
        updateOrderGreetings(utils.generateFormToSubmitValues(formData, config));
    };

    return data ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search'>
            <FinalForm
                onSubmit={handleOnSubmit}
                initialValues={utils.generateFormInitialValues(data, config)}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row className='mt-3'>
                                <FormFieldTranslationsQuill
                                    form={form}
                                    utils={utils}
                                    propertyName='summaryContent'
                                    header={translations.summaryContent}
                                />
                            </Row>
                            <SectionAsideButtons
                                buttons={{
                                    add: {
                                        color: 'success',
                                        label: translations.button,
                                        action: handleSubmit,
                                        iconClass: 'fa fa-save mr-2',
                                    }
                                }}
                            />
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

const mapDispatch = {
    getOrderGreetings,
    updateOrderGreetings
};

export default withFormUtils(connect(null, mapDispatch)(OrderGreetings));