import { cAPI } from '../../../utils/cAPI';
import { getData, addEditData, removeData } from '../generic';
import { setPayUEnabled, setP24Enabled } from '../../../features/appInit/slice';

export const getIntegrationP24 = () => dispatch => getData(dispatch, cAPI.APGetIntegrationP24, 'p24Integration').then(result => {
    dispatch(setP24Enabled({ p24Enabled: result.payload.enabled }));

    return result;
});
export const updateIntegrationP24 = formData => dispatch => addEditData(dispatch, cAPI.APUpdateIntegrationP24, formData, 'p24Integration');

export const getIntegrationPayU = () => dispatch => getData(dispatch, cAPI.APGetIntegrationPayU, 'payUIntegration').then(result => {
    dispatch(setPayUEnabled({ payUEnabled: result.payload.enabled }));

    return result;
});
export const updateIntegrationPayU = formData => dispatch => addEditData(dispatch, cAPI.APUpdateIntegrationPayU, formData, 'payUIntegration');

export const getIntegrationInPost = () => dispatch => getData(dispatch, cAPI.APGetIntegrationInPost);
export const updateIntegrationInPost = formData => dispatch => addEditData(dispatch, cAPI.APUpdateIntegrationInPost, formData);

export const getIntegrationAPaczka = () => dispatch => getData(dispatch, cAPI.APGetIntegrationAPaczka);
export const updateIntegrationAPaczka = formData => dispatch => addEditData(dispatch, cAPI.APUpdateIntegrationAPaczka, formData);

export const getIntegrationFurgonetka = () => dispatch => getData(dispatch, cAPI.APGetIntegrationFurgonetka);
export const updateIntegrationFurgonetka = formData => dispatch => addEditData(dispatch, cAPI.APUpdateIntegrationFurgonetka, formData);

export const getIntegrationFacebook = () => dispatch => getData(dispatch, cAPI.APGetIntegrationFacebook);
export const updateIntegrationFacebook = formData => dispatch => addEditData(dispatch, cAPI.APUpdateIntegrationFacebook, formData);

export const getCeneoSettings = () => dispatch => getData(dispatch, cAPI.APGetCeneoSettings, 'ceneoSettings');
export const updateCeneoSettings = formData => dispatch => addEditData(dispatch, cAPI.APUpdateCeneoSettings, formData, 'ceneoSettings');

export const getCeneoMappings = () => dispatch => getData(dispatch, cAPI.APGetCeneoMappings, 'ceneoMappings');
export const deleteCeneoMapping = id => dispatch => removeData(dispatch, cAPI.APDeleteCeneoMapping, id, 'ceneoMappings');
export const addCeneoMapping = formData => dispatch => addEditData(dispatch, cAPI.APAddCeneoMapping, formData, 'ceneoMappings');
export const updateCeneoCategories = () => dispatch => addEditData(dispatch, cAPI.APUpdateCeneoCategories, null, 'ceneoSettings');
export const generateCeneoXML = () => dispatch => addEditData(dispatch, cAPI.APGenerateCeneoXML , null, 'ceneoSettings');

export const getGoogleAnalytics = () => dispatch => getData(dispatch, cAPI.APGetGoogleAnalytics);
export const updateGoogleAnalytics = formData => dispatch => addEditData(dispatch, cAPI.APUpdateGoogleAnalytics, formData);

export const getFreshmail = () => dispatch => getData(dispatch, cAPI.APGetFreshmail);
export const updateFreshmail = formData => dispatch => addEditData(dispatch, cAPI.APUpdateFreshmail, formData);

export const getChatSettings = () => dispatch => getData(dispatch, cAPI.APGetChatSettings);
export const updateChatSettings = formData => dispatch => addEditData(dispatch, cAPI.APUpdateChatSettings, formData);

export const getInspectletSettings = () => dispatch => getData(dispatch, cAPI.APGetInspectletSettings);
export const updateInspectletSettings = formData => dispatch => addEditData(dispatch, cAPI.APUpdateInspectletSettings, formData);

export const getIntegrationRecaptcha = () => dispatch => getData(dispatch, cAPI.APGetIntegrationRecaptcha);
export const updateIntegrationRecaptcha = formData => dispatch => addEditData(dispatch, cAPI.APUpdateIntegrationRecaptcha, formData);

export const getIntegrationGoogleSearchConsole = () => dispatch => getData(dispatch, cAPI.APGetIntegrationGoogleSearchConsole);
export const updateIntegrationGoogleSearchConsole = formData => dispatch => addEditData(dispatch, cAPI.APUpdateIntegrationGoogleSearchConsole, formData);