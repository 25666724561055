import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { filterItemsList } from '../../utils/components';

import { Container, Col, Row} from 'reactstrap';

import Modal from '../../components/modals/Modal';

import ListGroup from './ListGroup';

import { stringOrNumberPropTypes } from '../../propTypes';

const listItemPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    label: PropTypes.string,
});

const propTypes = {
    lists: PropTypes.shape({
        left: PropTypes.arrayOf(listItemPropTypes),
        right: PropTypes.arrayOf(listItemPropTypes),
    }),
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onConfirm: PropTypes.func,
    translations: PropTypes.shape({
        header: PropTypes.string,
        buttons: PropTypes.shape({
            cancel: PropTypes.shape({
                color: PropTypes.string,
                label: PropTypes.string,
            }),
            confirm: PropTypes.shape({
                color: PropTypes.string,
                label: PropTypes.string,
            }),
        }),
        toAssign: PropTypes.shape({
            header: PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string,
            }),
            searchPlaceholder: PropTypes.string,
            tooltip: PropTypes.string,
        }),
        assigned: PropTypes.shape({
            header: PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string,
            }),
            searchPlaceholder: PropTypes.string,
            tooltip: PropTypes.string,
        }),
    }),
};
export const Assigner = ({ lists, isOpen, setIsOpen, onConfirm, translations, listItem }) => {
    const [leftListSearchString, setLeftListSearchString] = useState('');
    const [rightListSearchString, setRightListSearchString] = useState('');

    const [rightList, setRightList] = useState(lists.right);

    const handleChangeLeftListSearchString = e => {
        setLeftListSearchString(e.target.value);
    };

    const handleChangeRightListSearchString = e => {
        setRightListSearchString(e.target.value);
    };

    const handleAddToRightList = itemToAdd => () => {
        setRightList([...rightList, itemToAdd]);
    };

    const handleRemoveFromRightList = itemToRemove => () => {
        setRightList(rightList.filter(item => item.id !== itemToRemove.id));
    };

    const handleConfirm = () => {
        onConfirm(rightList.map(({id}) => id));
        setRightList([]);
    };

    const rightIdList = rightList.map(({ id }) => id);
    const filteredLeftList  = lists.left ? lists.left.filter(item => !(rightIdList.includes(item.id))) : [];

    const filteredBySearchLeftList = filteredLeftList.length > 0 ? filterItemsList(filteredLeftList, leftListSearchString) : [];
    const filteredBySearchRightList = rightList.length > 0 ? filterItemsList(rightList, rightListSearchString) : [];

    return (
        <Modal
            header={translations.header}
            onConfirm={handleConfirm}
            className='modal-lg'
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            buttons={translations.buttons}
            body={
                <Container fluid>
                    <Row>
                        <Col xs='12' lg='6' className='mb-3 sm-lg-0'>
                            <ListGroup
                                listItem={listItem}
                                header={translations.toAssign.header}
                                counter={{
                                    left: filteredBySearchLeftList.length,
                                    right: lists.left.length,
                                }}
                                searchInput={{
                                    value: leftListSearchString,
                                    onChange: handleChangeLeftListSearchString,
                                    placeholder: translations.toAssign.searchPlaceholder,
                                }}
                                items={filteredBySearchLeftList}
                                tooltip={translations.toAssign.tooltip}
                                onItemClick={handleAddToRightList}
                                color='danger'
                            />
                        </Col>
                        <Col xs='12' lg='6'>
                            <ListGroup
                                listItem={listItem}
                                header={translations.assigned.header}
                                counter={{
                                    left: filteredBySearchRightList.length,
                                    right: rightList.length,
                                }}
                                searchInput={{
                                    value: rightListSearchString,
                                    onChange: handleChangeRightListSearchString,
                                    placeholder: translations.assigned.searchPlaceholder,
                                }}
                                items={filteredBySearchRightList}
                                tooltip={translations.assigned.tooltip}
                                onItemClick={handleRemoveFromRightList}
                            />
                        </Col>
                    </Row>
                </Container>
            }
        />
    );
};

Assigner.propTypes = propTypes;

export default Assigner;
