import React from 'react';
import PropTypes from 'prop-types';

import TableGenerator from '../../generators/TableGenerator';
import { decimalOptionEnum } from '../../../enums/units';

const propTypes = {
    removeAction: PropTypes.func,
    editClickAction: PropTypes.func,
    translations: PropTypes.object,
};

const UnitsTable = ({
    units, translations, removeAction, editClickAction
}) => {
    const deleteAction = id => () => {
        removeAction(id);
    };

    const editAction = (itemId, country) => () => {
        editClickAction(country);
    };

    const decimalOptionConfig = Object.keys(decimalOptionEnum).map(key => ({
        id: key === 'true' ? 1 : 0,
        label: translations.labels.isDecimal[decimalOptionEnum[key]],
        color: key === 'true' ? 'secondary' : 'info'
    }));

    return (
        <TableGenerator
            items={units}
            expectedActions={['edit', 'delete']}
            defaultAction={editAction}
            actions={{deleteAction, editAction}}
            translations={translations}
            columns={[
                {
                    header: translations.labels.name,
                    value: 'name',
                },
                {
                    header: translations.labels.shortName,
                    value: 'shortName'
                },
                {
                    header: translations.labels.isDecimal.label,
                    value: 'isDecimal',
                    type: 'badge',
                    config: decimalOptionConfig,
                },
                {
                    header: translations.labels.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

UnitsTable.propTypes = propTypes;

export default UnitsTable;