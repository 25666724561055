import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { performHideToast } from '../../features/toast/slice';

import { Alert } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    visible: PropTypes.bool,
    type: PropTypes.string,
    message: PropTypes.string,
    duration: PropTypes.number,
    performHideToast: PropTypes.func,
};

const Toast = ({ visible, type, message, duration, performHideToast }) => {
    const intl = useIntl();

    if (visible) {
        setTimeout(performHideToast, duration);
    }

    return (
        <Alert
            className={styles.alert}
            color={type || 'success'}
            isOpen={visible}
            toggle={performHideToast}>
            {visible && (message || intl.messages.defaultLayout.appGlobal.editResult.success)}
        </Alert>
    );
};

Toast.propTypes = propTypes;

const mapStateToProps = state => ({
    visible: state.toast.visible,
    type: state.toast.type,
    message: state.toast.message,
    duration: state.toast.duration
});

const mapDispatch = {
    performHideToast
};

export default connect(mapStateToProps, mapDispatch)(Toast);