import React from 'react';
import PropTypes from 'prop-types';

import useFetch from '../../../../hooks/useFetch';

import ColorSettings from './ColorSettings';
import DateTimeSettings from './DateTimeSettings';
import DecimalSettings from './DecimalSettings';
import IntSettings from './IntSettings';
import SearchableDictionarySettings from './SearchableDictionarySettings';
import SimpleDictionarySettings from './SimpleDictionarySettings';
import TagSettings from './TagSettings';

import { getAttributeSettings } from '../../../../redux/methods/attributes';

import { connect } from 'react-redux';

const propTypes = {
    attributeId: PropTypes.number,
    forceRefetch: PropTypes.bool,
    getAttributeSettings: PropTypes.func,
};

const SharedEditSettingsSection = ({ attributeId, forceRefetch, getAttributeSettings }) => {

    const [ data ] = useFetch(getAttributeSettings, attributeId, [], forceRefetch);

    return data ? (
        <React.Fragment>
                {
                    data.type === 'Color' &&
                    <ColorSettings data={data}/>
                }
                {
                    data.type === 'DateTime' &&
                    <DateTimeSettings data={data}/>
                }
                {
                    data.type === 'Decimal' &&
                    <DecimalSettings data={data}/>
                }
                {
                    data.type === 'Int' &&
                    <IntSettings data={data}/>
                }
                {
                    data.type === 'SearchableDictionary' &&
                    <SearchableDictionarySettings data={data}/>
                }
                {
                    data.type === 'SimpleDictionary' &&
                    <SimpleDictionarySettings data={data}/>
                }
                {
                    data.type === 'Tag' &&
                    <TagSettings data={data}/>
                }
        </React.Fragment>
    ) : null;
};

SharedEditSettingsSection.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.attributesSettings,
});

const mapDispatch = {
    getAttributeSettings
};

export default connect(mapStateToProps, mapDispatch)(SharedEditSettingsSection);