const agreements = {
    view: {
        title: 'Agreements list',
        actions: {
            addAgreement: 'ADD AGREEMENT',
        },
        buttons: {
            activate: 'Aktywuj',
            deactivate: 'Deaktywuj'
        },
        headerLabels: {
            name: 'Name',
            type: 'Localisation',
            isActive: 'Active',
            isRequired: 'Required',
            regions: 'Regions',
            actions: 'Actions',
        },
        emptyPlaceholders: {
            name: '[NO NAME]',
            type: '[NO TYPE]',
            regions: '[NO REGION]',
            table: 'No agreements found',
        },
        statuses:{
            active: 'Active',
            inactive: 'Inactive',
            required: 'Required',
            notRequired: 'Optional',
        },
        tooltips: {
            edit: 'Edit agreement',
            remove: 'Delete agreement'
        },
        deleteModal: {
            header: 'Agreement removal',
            body: 'Are you sure that you want to remove agreement?',
            cancel: 'CANCEL',
            confirm: 'REMOVE'
        },
    },
    edit: {
        tabSelectorTitle: '[EN] Sekcje',
        fallbackName: '[[EN] BRAK NAZWY]',
        tabs: {
            basic: {
                title: '[EN] Dane podstawowe',
                labels: {
                    name: '[EN] Nazwa',
                    type: '[EN] Lokalizacja zgody',
                    status: {
                        active: '[EN] AKTYWNA',
                        inactive: '[EN] NIEAKTYWNA',
                        label: '[EN] Aktywna',
                    },
                    required: {
                        active: '[EN] WYMAGANA',
                        inactive: '[EN] NIEWYMAGANA',
                        label: '[EN] Wymagana',
                    },
                }
            },
            descriptions: {
                title: '[EN] Opisy',
                label: '[EN] Opis',
            },
        }
    },
    register: {
        title: '[EN] Rejestr zgód',
        fileName: '[EN] rejestrZgod',
        downloadButton: '[EN] Pobierz CSV',
        search: {
            action: '[EN] SZUKAJ',
            reset: '[EN] RESETUJ',
            labels: {
                email: '[EN] Email',
                ip: '[EN] IP',
                onlyModified: '[EN] Czy modyfikowany'
            }
        },
        table: {
            emptyTable: '[EN]Brak danych w rejestrze',
            ipFiller: '[EN]Brak danych',
            tooltips: {
                showDetails: '[EN]Pokaż szczegóły',
            },
            statuses: {
                modified: '[EN]Modyfikowany',
                notModified: '[EN]Niemodyfikowany',
            },
            headerLabels: {
                email: '[EN]Email',
                modifiedDate: '[EN]Data modyfikacji',
                ip: '[EN]IP',
                isModified: '[EN]Modyfikowane',
                actions: '[EN]Akcje',
            }
        },
        modal: {
            header: '[EN] Lista zgód',
            headerConfirm: '[EN] Potwierdzenia usunięcia zgody',
            cancel: '[EN] WRÓĆ',
            confirm: '[EN] POTWIERDŹ',
            table: {
                statuses: {
                    agree: '[EN] Zgadza się',
                    disagree: '[EN] Nie zgadza się',
                    remove: '[EN] Usunięta na prośbę'
                },
                headerLabels: {
                    name: '[EN] Nazwa',
                    status: '[EN] Status',
                    modifiedDate: '[EN] Data modyfikacji',
                    comment: '[EN] Komentarz',
                    actions: '[EN] Akcje',
                },
                tooltips: {
                    remove: '[EN] Usuń zgode',
                },
                emptyTable: '[EN] Brak zgód',
            },
            info: '[EN] Aby zmienić status kliknij na wiersz',
            warning: '[EN] Możesz zmienić tylko status ZGADZA SIĘ na USUNIĘTO NA PROŚBĘ',
            warningConfirm: '[EN] Ta operacja jest niemożliwa do cofnięcia!',
            comment: '[EN] Komentarz',
        }
    }
};

export default agreements;