import ConnecticoAPI from '@connectico/connectico-api';

import { setCategoryTree } from '../../../features/categories/slice';

import { handleCAPIErrorsSubtle } from '../../../utils/cAPI';
import getConfig from '../../../getConfig';

const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

const cAPI = ConnecticoAPI({
    baseUrl: apiUrl
});

export const refetchCategoryTree = async dispatch => {
    /** Trigger a categoryTree re-fetch on succesful update */
    const { payLoad, error, httpError, unhandledError, statusCode} = await cAPI.APGetFullCategoryTree();
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        return await dispatch(setCategoryTree({ categoryTree: payLoad.categoryTree, lastSyncDate: payLoad.lastSynchronizationDate }));
    } else if (errorToHandle) {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};