const manufacturers = {
    view: {
        title: 'Lista producentów',
        actions: {
            addManufacturer: 'DODAJ PRODUCENTA',
        },
        deleteModal: {
            header: 'Usuń producenta',
            body: 'Czy jesteś pewien, że chcesz usunąć producenta?',
            cancel: 'WRÓC',
            confirm: 'USUŃ',
        },
        additionModal: {
            header: 'Dodaj nowego producenta',
            body: 'Czy chcesz utworzyć nowego producenta?',
            confirm: 'DODAJ',
            cancel: 'WRÓĆ'
        },
        errorModal: {
            cancel: 'WRÓĆ',
            header: 'Wystąpił błąd podczas usuwania producenta.',
        },
        manufacturersSearch: {
            name: 'Nazwa'
        },
        searchButton: 'SZUKAJ',
        resetButton: 'RESETUJ'
    },
    table: {
        nameFiller: '[BRAK NAZWY]',
        emptyLogoAlt: 'logo zastępcze',
        empty: 'Nie znaleziono żadnego producenta',
        headers: {
            logo: 'Logo',
            name: 'Nazwa',
            actions: 'Akcje',
            showInFilters: 'Pokaż w filtrach',
        },
        deleteModal: {
            header: 'Producent',
            body: 'Czy napewno chcesz usunąć producenta',
            cancel: 'WRÓĆ',
            confirm: 'USUŃ'
        },
        tooltips: {
            remove: 'Usuń producenta',
            edit: 'Edycja producenta'
        }
    },
    edit: {
        fallbackName: '[BRAK NAZWY]',
        tabSelectorTitle: 'Sekcje',
        tabs: {
            basicData: {
                modalHeader: 'Nazwa strony',
                title: 'Dane podstawowe',
                labels: {
                    name: 'Nazwa',
                    showInFilters: {
                        active: 'Widoczna',
                        inactive: 'Niewidoczna',
                        label: 'Widoczna w filtrach'
                    },
                },
                addLogoButton: 'Dodaj logo',
                nameRequired: 'Należy podać nazwę producenta'
            }
        }
    }

};

export default manufacturers;