/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabulator from "../../../../components/Tabulator";
import { connect } from 'react-redux';
import { getErpCategories } from '../../../../redux/methods/categories';
import { setAssignerSearchConfig } from '../../../../redux/methods/products';

import useFetch from '../../../../hooks/useFetch';

import { flattenedCategoryTreeItemPropTypes } from '../../../../propTypes';
import CategoryTreeBasic from '../../../../components/CategoryTreeBasic';
import { withCategories } from '../../../../hoc/withCategories';
import DefaultSpinner from '../../../../components/loaders/DefaultSpinner';

const propTypes = {
    translations: PropTypes.object,
    currentLanguage: PropTypes.string,
    categoryTree: PropTypes.arrayOf(flattenedCategoryTreeItemPropTypes),
    getErpCategories: PropTypes.func,
    setAssignerSearchConfig: PropTypes.func,
    setSearchConfig: PropTypes.func,
    showTwoTrees: PropTypes.bool,
};

const CategoryTreeSection = ({
    translations,
    currentLanguage,
    notFlattenCategories,
    getErpCategories,
    setAssignerSearchConfig,
    setSearchConfig,
    scroll,
    showTwoTrees,
    searchConfig,
    categoryAssing = false
}) => {
    const [ erpCategories ] = useFetch(getErpCategories);

    const [ shopCategoryId, setShopCategoryId ] = useState(null);
    const [ erpCategoryId, setErpCategoryId ] = useState(null);

    useEffect(() => {
        if(searchConfig && searchConfig.categoryId === null) {
            setShopCategoryId(null);
            setErpCategoryId(null);
        }
    }, [searchConfig]);

    const getTranslation = (node) => {
        for(const n in node){
            if (node[n].iso.toLowerCase() === currentLanguage){
                return node[n].value;
            }
        }
        return null;
    };

    const handleTabChange = id => {
        const tabEnums = {
            'tab-categoryTree-shoppCategories': 1,
            'tab-categoryTree-erpCategories': 2,
        };
        const categoryIdByTab = tabEnums[id] === 1 ? shopCategoryId : erpCategoryId;

        if(setSearchConfig){
            return;
        } else {
            setAssignerSearchConfig({ categoryTypeId: tabEnums[id], categoryId: categoryIdByTab, curentPage: 0 });
        }
    };

    const handleCategoryChange = type => id => {
        if(type === 'shop') {
            setShopCategoryId(id);
        } else if (type === 'erp') {
            setErpCategoryId(id);
        }

        if(setSearchConfig){
            setSearchConfig(id, type === 'shop' ? 1 : 2);
        } else {
            setAssignerSearchConfig({ categoryId: id });
        }
    };

    const prepareCategories = (nodes, path, level) => {
        return nodes.map(n => {
            const translatedName = getTranslation(n.category.name);
            return {
                id: n.category.id,
                active: n.category.status === 1,
                name: translatedName,
                expanded: false,
                selected: false,
                level: level,
                path: (path === '' ? '' : path + ' => ') + translatedName,
                children: n.subItems && Array.isArray(n.subItems) && n.subItems.length > 0
                    ? prepareCategories(n.subItems, (path === '' ? '' : path + ' => ') + translatedName, level + 1)
                    : null
            };
        });
    };

    const preparingCategories = notFlattenCategories && prepareCategories(notFlattenCategories, '', 0);

    const preparingErpCategories = erpCategories && erpCategories.categoryTree && prepareCategories(erpCategories.categoryTree, '', 0);

    return erpCategories && notFlattenCategories && notFlattenCategories.length > 0 ? (
        <div>
            {showTwoTrees ? (
                <Tabulator
                    onTabChange={handleTabChange}
                    listOfElements={[
                        {
                            label: translations.categoryTreeLabels.erpCategories,
                            id: 'tab-categoryTree-erpCategories',
                            component: <CategoryTreeBasic
                                            categories={preparingErpCategories}
                                            handleSelectionChanged={handleCategoryChange('erp')}
                                            selectedCategoryId={erpCategoryId}
                                            translations={translations}
                                            scroll={scroll}
                                            searchConfig={searchConfig}
                                            withDropdown={categoryAssing}
                                        />
                        },
                        {
                            label: translations.categoryTreeLabels.shopCategories,
                            id: 'tab-categoryTree-shoppCategories',
                            component: <CategoryTreeBasic
                                            categories={preparingCategories}
                                            handleSelectionChanged={handleCategoryChange('shop')}
                                            selectedCategoryId={shopCategoryId}
                                            translations={translations}
                                            scroll={scroll}
                                            searchConfig={searchConfig}
                                            withDropdown={categoryAssing}
                                            withFilter={true}
                                        />
                        },
                    ]}
                    defaultActiveTabId={searchConfig?.categoryTypeId === 1
                        ? 'tab-categoryTree-shoppCategories'
                        : 'tab-categoryTree-erpCategories'}
                />
            ) : (
                <CategoryTreeBasic
                    categories={preparingCategories}
                    handleSelectionChanged={handleCategoryChange('shop')}
                    selectedCategoryId={shopCategoryId}
                    translations={translations}
                    scroll={scroll}
                    searchConfig={searchConfig}
                    withDropdown={categoryAssing}
                    withFilter={true}
                />
            )}
        </div>
    ) : <DefaultSpinner isLoading />;
};

const mapDispatch = {
    getErpCategories,
    setAssignerSearchConfig,
};

CategoryTreeSection.propTypes = propTypes;

export default withCategories(connect(null, mapDispatch)(CategoryTreeSection));