import React from 'react';
import PropTypes from 'prop-types';
import { Button, Toast, ToastBody } from 'reactstrap';

import { useIntl } from 'react-intl';

import styles from './index.module.scss';

const propTypes = {
    isEdited: PropTypes.bool,
    handleSubmit: PropTypes.func,
};

const SaveDataToast = ({ isEdited, handleSubmit }) => {
    const translations = useIntl().messages.sharedComponents.saveDataToast;

    return isEdited
        ? <div className={styles.container}>
            <Toast style={{ minHeight: '60px', maxWidth: '1920px', width: '100%', marginRight: '0 !important' }} >
                <ToastBody>
                    <p className={styles.body}>
                        {translations.body}
                        <Button color='warning' onClick={handleSubmit}>
                            <i className='fa fa-save mr-2'/>
                            {translations.button}
                        </Button></p>
                </ToastBody>
            </Toast>
        </div>
        : null;
};

SaveDataToast.propTypes = propTypes;

export default SaveDataToast;