import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import clsx from 'clsx';

import { updateProductBasicData, deleteProductVariants } from '../../../../redux/methods/products';
import { generateCategoryDropdownOptions } from '../../../../utils/categories';
import { generateStatusesConfig, generateShopProductLink, generateTypeConfig, generateProductEditLink } from '../../../../utils/products';
import { getTranslationForIso, convertToLocaleDateTime } from '../../../../utils/components';
import { generateManufacturersDropdownOptions, translateManufacturers } from '../../../../utils/manufacturers';
import { selectTranslatedFlattenedCategoryTree } from '../../../../redux/selectors/categories';
import { getManufacturers } from '../../../../redux/methods/manufacturers';
import { getCountries } from '../../../../redux/methods/countries';

import useFetch from '../../../../hooks/useFetch';
import { withFormUtils } from '../../../../hoc/withFormUtils';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row, Col, Button, Card, CardHeader, CardBody, Alert } from 'reactstrap';

import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldTranslations from '../../../../components/formFields/FormFieldTranslations';
import FormFieldSwitchPill from '../../../../components/formFields/FormFieldSwitchPill';
import FormFieldSwitchPillWithNullState from '../../../../components/formFields/FormFieldSwitchPillWithNullState';
import FormFieldStatus from '../../../../components/formFields/FormFieldStatus';
import FormFieldWithCheckbox from '../../../../components/formFields/FormFieldWithCheckbox';
import FormFieldTextarea from '../../../../components/formFields/FormFieldTextarea';

import { productStatusEnum } from '../../../../enums/products';

import { formUtilsHocPropTypes, flattenedTranslatedCategoryTreeItemPropTypes } from '../../../../propTypes';

import { productTypes } from '../../../../config';

import { setForceRefetchByTarget } from '../../../../features/refetchers/slice';

import styles from './index.module.scss';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    updateProductBasicData: PropTypes.func,
    forceRefetch: PropTypes.bool,
    isConnectedToERP: PropTypes.bool,
    flatTranslatedCategoryTree: PropTypes.arrayOf(flattenedTranslatedCategoryTreeItemPropTypes),
    getManufacturers: PropTypes.func,
    isService: PropTypes.bool,
};

const ProductEditBasicSection = ({
    utils,
    data,
    updateProductBasicData,
    isConnectedToERP,
    flatTranslatedCategoryTree,
    getManufacturers,
    getCountries,
    isService,
    deleteProductVariants,
    setForceRefetchByTarget
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.basic;

    const config = { translations: ['name'] };
    const [ manufacturersList ] = useFetch(getManufacturers);
    const [ countries ] = useFetch(getCountries);

    const handleOnSubmit = formData => {
        updateProductBasicData(utils.generateFormToSubmitValues(formData, config));
    };

    const countryOptions = countries && countries.map(country => ({
        id: country.id,
        name: getTranslationForIso(country.translations, utils.currentLanguage())
    }));

    const showProductActionHandler = id => () => {
        const shopProductWindow = window.open(generateShopProductLink(id, utils.currentLanguage()), '_blank', 'noopener, noreferrer');
        if (shopProductWindow) {
            shopProductWindow.opener = null;
        }
    };

    const statuses = generateStatusesConfig(productStatusEnum, translations.statusLabels);

    const handleOpenDeleteModal = productId => () => {
        utils.confirm(
            async () => {
                const result = await deleteProductVariants({ productsIds: [data.productId], variantId: productId });

                if(!result) {
                    setForceRefetchByTarget({ target: 'products', forceRefetch: true });
                }

                return result;
            },
            translations.deleteOneModal.header,
            translations.deleteOneModal.body
        );
    };

    return data && manufacturersList && countries ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    initialValues={utils.generateFormInitialValues(data, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        const { values } = form.getState();

                        const translatedManufacturersList = translateManufacturers(manufacturersList, utils);

                        const selectedCategory = flatTranslatedCategoryTree.find(category => parseInt(category.id) === parseInt(values.categoryForAttributesId));
                        const selectedManufacturer = translatedManufacturersList.find(manufacture => parseInt(manufacture.id) === parseInt(values.manufacturerId));
                        const selectedCountry = countryOptions.find(country => parseInt(country.id) === parseInt(values.countryId));

                        const selectedStatus = statuses.find(status => status.id === values.status) || statuses[0];
                        const selectedStatusErp = statuses.find(status => status.id === values.statusErp) || statuses[0];

                        const typesConfig = generateTypeConfig(productTypes, translations.types);

                        const type = typesConfig.find(t => t.id === values.productVariantType);

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='pt-3 px-0' fluid>
                                    <Row className='my-3 flex-md-nowrap'>
                                        <FormFieldStatus
                                            label={translations.inputs.status}
                                            name='status'
                                            selectedStatus={selectedStatus}
                                            statuses={statuses}
                                            disabled={isConnectedToERP && values.statusErp === 0}
                                        />
                                        {isConnectedToERP &&
                                            <FormFieldStatus
                                                label={translations.inputs.statusErp}
                                                statuses={statuses}
                                                selectedStatus={selectedStatusErp}
                                                name='statusErp'
                                                disabled
                                            />
                                        }
                                        {!isService &&
                                            <Button color='primary' className={clsx('w-100 my-2', styles.redirectButton)} onClick={showProductActionHandler(data.productId)}>
                                                <span className='mr-2'>{translations.buttons.seeDetails}</span>
                                                <i className={"fa fa-eye fa-lg"} />
                                            </Button>
                                        }
                                    </Row>
                                    <Row className='w-100 mb-3'>
                                        <span>
                                            <b>{translations.dateCreated}:</b> <span>{convertToLocaleDateTime(values.dateCreated)}</span>
                                        </span>
                                    </Row>
                                    <Row className='w-100 mb-3'>
                                        <span>
                                            <b>{translations.type}:</b> <span>{type.label}</span>
                                        </span>
                                    </Row>
                                    {
                                        values.parentProductName &&
                                        <Row className='w-100 mb-3 flex-md-nowrap align-items-center'>
                                            <div>
                                                <b>{translations.parentProduct}:</b> <Link to={generateProductEditLink(values.parentProductId)}>{values.parentProductName}</Link>
                                            </div>
                                            <Button color='danger' className={clsx('ml-2', styles.disconnectButton)} onClick={handleOpenDeleteModal(values.parentProductId)}>
                                                <span className='mr-2'>{translations.buttons.disconnect}</span>
                                                <i className={"fa fa-unlink fa-lg"} />
                                            </Button>
                                        </Row>
                                    }
                                    { values.isPromotion &&
                                        <Row className='w-100 mb-3'>
                                            <Alert color="info" className=' w-100'>
                                                {translations.isPromotionInfo}
                                            </Alert>
                                        </Row>
                                    }
                                    <Row className='mb-3'>
                                        <FormFieldTranslations
                                            form={form}
                                            modalHeader={translations.modalHeader}
                                            propertyName='name'
                                            currentIso={utils.currentLanguage()}
                                            label={translations.inputs.productName}
                                            className='w-100'
                                        />
                                    </Row>
                                    <Row className='my-3'>
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.originalName}
                                            name='originalName'
                                            className='w-100'
                                        />
                                    </Row>
                                    {
                                        isConnectedToERP && (
                                            <React.Fragment>
                                                <Row className='mb-3'>
                                                    <FormFieldTextOrNumber
                                                        disabled={true}
                                                        label={translations.inputs.externalId}
                                                        name='externalId'
                                                        className='w-100'
                                                    />
                                                </Row>
                                                <Row className='flex-md-nowrap'>
                                                    <FormFieldSwitchPill
                                                        utils={utils}
                                                        name='externalNameSync'
                                                        label={translations.inputs.syncErp}
                                                    />
                                                </Row>
                                                <Row className='my-3'>
                                                    <FormFieldTextOrNumber
                                                        disabled={true}
                                                        label={translations.inputs.nameFromERP}
                                                        name='externalName'
                                                        className='w-100'
                                                    />
                                                </Row>
                                                <div className='flex-md-nowrap mt-3'>
                                                    <Row>
                                                        <FormFieldWithCheckbox
                                                            name='externalShortDescriptionSync'
                                                            label={translations.inputs.externalShortDescriptionSync}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <FormFieldTextarea
                                                            name='externalShortDescription'
                                                            label={translations.inputs.externalShortDescription}
                                                            className='w-100'
                                                            disabled
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <FormFieldWithCheckbox
                                                            name='externalLongDescriptionSync'
                                                            label={translations.inputs.externalLongDescriptionSync}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <FormFieldTextarea
                                                            name='externalLongDescription'
                                                            label={translations.inputs.externalLongDescription}
                                                            className='w-100'
                                                            disabled
                                                        />
                                                    </Row>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                    <Row className='flex-md-nowrap'>
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.catalogueIndex}
                                            name={'catalogueIndex'}
                                            className='w-100 mr-md-3'
                                        />
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.tradeIndex}
                                            name={'tradeIndex'}
                                            className='w-100 mt-3 mt-md-0'
                                        />
                                    </Row>
                                    <Row className='flex-md-nowrap my-3'>
                                        {!isService &&
                                            <FormFieldTextOrNumber
                                                label={translations.inputs.manufacturerIndex}
                                                name={'manufacturerIndex'}
                                                className='w-100 mr-md-3 mb-3 mb-md-0'
                                            />
                                        }
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.mainMeasureUnit}
                                            name={'measureName'}
                                            className='w-100 mt-3 mt-md-0'
                                            disabled
                                        />
                                    </Row>
                                    <Row className='flex-md-nowrap'>
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.height}
                                            name={'height'}
                                            className='w-100 mr-md-3'
                                            type='number'
                                        />
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.width}
                                            name={'width'}
                                            className='w-100 mr-md-3 mt-3 mt-md-0'
                                            type='number'
                                        />
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.depth}
                                            name={'depth'}
                                            className='w-100 mr-md-3 mt-3 mt-md-0'
                                            type='number'
                                        />
                                          <FormFieldTextOrNumber
                                            label={translations.inputs.sizeUnit}
                                            name={'sizeUnit'}
                                            className='w-100 mt-3 mt-md-0'
                                        />
                                    </Row>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldWithDropdown
                                            label={translations.inputs.mainCategory}
                                            name='categoryForAttributesId'
                                            options={generateCategoryDropdownOptions(flatTranslatedCategoryTree)}
                                            currentSelectionLabel={selectedCategory? selectedCategory.path : translations.inputs.select}
                                            className='w-100 mt-3 mt-md-0 mr-md-3'
                                            withSearch
                                        />
                                        {!isService &&
                                            <React.Fragment>
                                                <FormFieldWithDropdown
                                                    label={translations.inputs.manufacturer}
                                                    name='manufacturerId'
                                                    options={generateManufacturersDropdownOptions(translatedManufacturersList)}
                                                    currentSelectionLabel={selectedManufacturer ? selectedManufacturer.name : translations.inputs.select}
                                                    className='w-100 mt-3 mt-md-0'
                                                />
                                                <FormFieldWithDropdown
                                                    label={translations.inputs.country}
                                                    name='countryId'
                                                    options={countryOptions}
                                                    currentSelectionLabel={selectedCountry ? selectedCountry.name : translations.inputs.select}
                                                    className='w-100 mt-3 mt-md-0 ml-md-3'
                                                />
                                            </React.Fragment>
                                        }
                                    </Row>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.pkwiu}
                                            name={'pkwiu'}
                                            className='w-100 mr-md-3'
                                        />
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.swwku}
                                            name={'swwku'}
                                            className='w-100 mt-3 mt-md-0'
                                        />
                                    </Row>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.cnCode}
                                            name={'cnCode'}
                                            className='w-100 mr-md-3'
                                        />
                                        <FormFieldTextOrNumber
                                            label={translations.inputs.ean}
                                            name={'ean'}
                                            className='w-100 mr-md-3'
                                        />
                                        {!isService &&
                                            <FormFieldTextOrNumber
                                                label={translations.inputs.kgo}
                                                name={'kgo'}
                                                className='w-100 mt-3 mt-md-0'
                                                type='number'
                                            />
                                        }
                                    </Row>
                                    {!isService &&
                                        <Row className='flex-md-nowrap mt-3'>
                                            <FormFieldTextOrNumber
                                                label={translations.inputs.weight}
                                                name={'weight'}
                                                className='w-100 mr-md-3'
                                                type='number'
                                                step={0.01}
                                                min={0}
                                            />
                                            <FormFieldTextOrNumber
                                                label={translations.inputs.weightUnit}
                                                name={'weightUnit'}
                                                className='w-100 mt-3 mt-md-0'
                                                type='text'
                                            />
                                        </Row>
                                    }
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldSwitchPill
                                            form={form}
                                            name='isAddToCartHidden'
                                            label={translations.inputs.isAddToCartHidden}
                                        />
                                    </Row>
                                    {
                                        isConnectedToERP &&
                                            <Row className='flex-md-nowrap mt-3'>
                                                {!isService &&
                                                    <FormFieldTextOrNumber
                                                        label={translations.inputs.localisation}
                                                        name={'localisation'}
                                                        className='w-100 mr-md-3'
                                                    />
                                                }
                                                <FormFieldTextOrNumber
                                                    label={translations.inputs.distinguish}
                                                    name={'distinguish'}
                                                    className='w-100 mt-3 mt-md-0'
                                                />
                                            </Row>
                                    }
                                    {!isService &&
                                        <Row className="mt-3">
                                            <Col className="p-0">
                                                <Card>
                                                    <CardHeader>{translations.quantityLabel}</CardHeader>
                                                    <CardBody className="pl-5 pr-5">
                                                        <Row className='flex-md-nowrap'>
                                                            <FormFieldTextOrNumber
                                                                label={translations.inputs.quantity}
                                                                name='quantity'
                                                                className='w-100 mr-md-3'
                                                                type='number'
                                                            />
                                                            <FormFieldTextOrNumber
                                                                label={translations.inputs.booked}
                                                                name='reserved'
                                                                className='w-100 mt-3 mt-md-0'
                                                                type='number'
                                                                disabled
                                                            />
                                                        </Row>
                                                        <Row className='flex-md-nowrap mt-5'>
                                                            <div className="col-4 p-0">
                                                                <FormFieldSwitchPillWithNullState
                                                                    form={form}
                                                                    name='isMasked'
                                                                    label={translations.inputs.isMasked}
                                                                />
                                                                <div className="pt-2">
                                                                    {values.isMasked === true && (<sup>{translations.explanations.isMaskedTrue}</sup>)}
                                                                    {values.isMasked === false && (<sup>{translations.explanations.isMaskedFalse}</sup>)}
                                                                    {values.isMasked === null && (<sup>{translations.explanations.isMaskedNull}</sup>)}
                                                                </div>
                                                                {values.isMasked === true && (
                                                                    <div className="pt-2">
                                                                        <FormFieldTextOrNumber
                                                                            label={translations.inputs.maskedQuantity}
                                                                            name='maskedValue'
                                                                            className='w-100'
                                                                            type='number'
                                                                            min='0'
                                                                        />
                                                                        <sup>{translations.explanations.leaveNull}</sup>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-4 p-0 pl-3 pr-3">
                                                                <FormFieldSwitchPillWithNullState
                                                                    form={form}
                                                                    name='includeMinQuantity'
                                                                    label={translations.inputs.includeMinQuantity}
                                                                />
                                                                <div className="pt-2">
                                                                    {values.includeMinQuantity === true && (<sup>{translations.explanations.includeMinQuantityTrue}</sup>)}
                                                                    {values.includeMinQuantity === false && (<sup>{translations.explanations.includeMinQuantityFalse}</sup>)}
                                                                    {values.includeMinQuantity === null && (<sup>{translations.explanations.includeMinQuantityNull}</sup>)}
                                                                </div>
                                                                {values.includeMinQuantity === true && (
                                                                    <div className="pt-2">
                                                                        <FormFieldTextOrNumber
                                                                            label={translations.inputs.minQuantity}
                                                                            name='minQuantity'
                                                                            className='w-100'
                                                                            type='number'
                                                                            min='0'
                                                                        />
                                                                        <sup>{translations.explanations.leaveNull}</sup>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-4 p-0">
                                                                <FormFieldSwitchPillWithNullState
                                                                    form={form}
                                                                    name='includeReservations'
                                                                    label={translations.inputs.includeReservations}
                                                                />
                                                                <div className="pt-2">
                                                                    {values.includeReservations === true && (<sup>{translations.explanations.includeReservationsTrue}</sup>)}
                                                                    {values.includeReservations === false && (<sup>{translations.explanations.includeReservationsFalse}</sup>)}
                                                                    {values.includeReservations === null && (<sup>{translations.explanations.includeReservationsNull}</sup>)}
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    }
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

ProductEditBasicSection.propTypes = propTypes;

const mapStateToProps = (state) => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
    flatTranslatedCategoryTree: selectTranslatedFlattenedCategoryTree(state),
});

const mapDispatch = {
    updateProductBasicData,
    getManufacturers,
    getCountries,
    deleteProductVariants,
    setForceRefetchByTarget
};

export default withRouter(withFormUtils(connect(mapStateToProps, mapDispatch)(ProductEditBasicSection)));