import { setDiscountToEditParameters, setDiscountToEditData } from '../../../features/discounts/slice';

import { handleCAPIErrorsSubtle, cAPI } from '../../../utils/cAPI';

export const refetchDiscountBasic = discountId => async dispatch => {
    const { payLoad, error, httpError, unhandledError, statusCode} = await await cAPI.APGetDiscount(discountId);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;
    if (payLoad) {
    {
        return dispatch(setDiscountToEditData({ data: payLoad }));
    }
    } else if (errorToHandle){
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};

export const refetchDiscountParameters = discountId => async dispatch => {
    const { payLoad, error, httpError, unhandledError, statusCode} = await await cAPI.APGetDiscountParams(discountId);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;
    if (payLoad) {
        return dispatch(setDiscountToEditParameters({ parameters: payLoad.discountParams }));
    } else if (errorToHandle){
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};