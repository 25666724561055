import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getUserAgreementsByEmail, updateUserAgreement } from '../../../redux/methods/agreements';

import useFetch from '../../../hooks/useFetch';

import Modal from '../../../components/modals/Modal';
import ModalWithFinalForm from '../../../components/modals/ModalWithFinalForm';
import UserAgreementsTable from '../../../components/tables/UserAgreementsTable';

import { Alert } from 'reactstrap';

import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';
import DefaultSpinner from '../../../components/loaders/DefaultSpinner';

const propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    emailToShow: PropTypes.string,
    getUserAgreementsByEmail: PropTypes.func,
    forceRefetch: PropTypes.bool,
    updateUserAgreement: PropTypes.func,
};

const UserAgreementsModal = ({ isOpen, setIsOpen, emailToShow, getUserAgreementsByEmail, forceRefetch, updateUserAgreement }) => {
    const [ isWarningOpen, setIsWarningOpen ] = useState(false);
    const [ isCommentModalOpen, setIsCommentModalOpen ] = useState(false);
    const [ agreementIdToChange, setAgreementIdToChange ] = useState(null);

    useEffect(() => {
        setIsCommentModalOpen(false);
    }, []);

    const intl = useIntl();
    const translations = intl.messages.agreements.register.modal;

    const [ userAgreements ] = useFetch(getUserAgreementsByEmail, emailToShow, [], forceRefetch);

    const showCommentModal = id => {
        setAgreementIdToChange(id);
        setIsCommentModalOpen(true);
    };

    const removeAgreement = async formData => {
        const err = await updateUserAgreement({
            email: emailToShow,
            agreementId: agreementIdToChange,
            comment: formData.comment || '',
        });
        if(err) {
            return err;
        } else {
            setAgreementIdToChange(null);
            setIsCommentModalOpen(false);
        }
    };

    return (
        <React.Fragment>
            <Modal
                className='modal-xl'
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                buttons={{
                    cancel: {
                        color: 'secondary',
                        label: translations.cancel,
                    },
                }}
                header={translations.header}
                body={
                    <React.Fragment>
                        <Alert color='info'>
                            {translations.info}
                        </Alert>
                        <Alert color='danger' isOpen={isWarningOpen}>
                            {translations.warning}
                        </Alert>
                        {userAgreements
                            ? <UserAgreementsTable setIsWarningOpen={setIsWarningOpen} showCommentModal={showCommentModal} agreements={userAgreements} isLoading={false} />
                            : <DefaultSpinner isLoading/>
                        }
                    </React.Fragment>
                }
            />
            {isCommentModalOpen && <ModalWithFinalForm
                isOpen={isCommentModalOpen}
                setIsOpen={setIsCommentModalOpen}
                buttons={{
                    cancel: {
                        color: 'secondary',
                        label: translations.cancel,
                    },
                    confirm: {
                        color: 'danger',
                        label: translations.confirm,
                    }
                }}
                header={translations.headerConfirm}
                modalBody={
                    <React.Fragment>
                        <Alert color='danger'>
                            {translations.warningConfirm}
                        </Alert>
                        <FormFieldTextOrNumber name='comment' label={translations.comment} />
                    </React.Fragment>
                }
                onConfirm={removeAgreement}
            />}
        </React.Fragment>
    );
};

UserAgreementsModal.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.userAgreements,
});

const mapDispatch = {
    getUserAgreementsByEmail,
    updateUserAgreement,
};

export default connect(mapStateToProps, mapDispatch)(UserAgreementsModal);
