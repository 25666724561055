import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Field } from 'react-final-form';

import CategorySelectModal from '../../../../modals/CategorySelectModal';
import { Button, ButtonGroup } from 'reactstrap';
import { withCategories } from '../../../../../hoc/withCategories';

const propTypes = {
    name: PropTypes.string,
    categories: PropTypes.array,
};

const CategorySelectField = ({ name, categories }) => {
    const translations = useIntl().messages.sharedComponents.categorySelectModal;
    const [isOpen, setIsOpen] = useState(false);

    const handleClear = onChange => () => {
        onChange(null);
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    return (
        <Field name={name}>
            {
                props => {
                    const currentCategoryLabel = categories.find(category => category.id === props.input.value);

                    return (
                        <div className='d-flex flex-column m-1'>
                            <span>{translations.label}</span>
                            <ButtonGroup>
                                <Button style={{ minWidth: '100px' }} onClick={handleOpen}>
                                    {currentCategoryLabel ? currentCategoryLabel.name : translations.buttonPlaceholder}
                                </Button>
                                <Button onClick={handleClear(props.input.onChange)}>
                                    <i className='fa fa-times' />
                                </Button>
                            </ButtonGroup>
                            <CategorySelectModal isOpen={isOpen} setIsOpen={setIsOpen} categorySelect={props.input.onChange} />
                        </div>
                    );
                }
            }
        </Field>
    );
};

CategorySelectField.propTypes = propTypes;

export default withCategories(CategorySelectField);