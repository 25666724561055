/* eslint-disable */
export const joinTranslations = (parent,child) => {
	const result = [];
	child.forEach(node => {
		var parentName = parent === null || typeof(parent) === 'undefined'
			? null
			: parent.find(el => el.iso === node.iso)?.value ?? null;
		result.push({
			iso: node.iso,
			value: (parentName + ' / ' ?? '') + node.value
		});
	});
	return result;
}

export const flattenCategoryLevel = (categoryTree, expandedCategories = []) => {
    /**
     * flatten a single nested level, and process it to a flat structure
     */
    return categoryTree.reduce((acc, node) => {
        const baseNode = {
            id: node.id || (node.category && node.category.id),
            name: node.name || (node.category && node.category.name),
            path: node.path || (node.category && node.category.name),
            depth: node.depth || (node.category && node.category.depth) || 0,
            quantity: node.quantity || (node.category && node.category.quantity),
            status: node.status || (node.category && node.category.status) || 0,
            statusErp: (node.statusErp || node.statusErp === 0) ? node.statusErp : node.category?.statusErp,
            orderIndex: node.orderIndex || (node.category && node.category.orderIndex) || 0,
            metatags: node.metatags || (node.category && node.category.metatags),
            parent: node.parent || null,
            subItemsPresent: node.subItems || node.subItemsPresent ? true : false,
            parentStatus: node.parentStatus || (node.category && node.category.parentStatus) || 0,
            expanded: expandedCategories.includes(parseInt(node.id)),
        };

        const cache = [ baseNode ];
        if (node && node.subItems) {
            node.subItems.forEach(innerNode => {
                cache.push({
                    id: innerNode.category.id,
                    name: innerNode.category.name,
                    path: joinTranslations(baseNode.path, innerNode.category.name),
                    depth: baseNode.depth + 1,
                    quantity: innerNode.category.quantity,
                    status: innerNode.category.status,
                    statusErp: innerNode.category.statusErp,
                    orderIndex: innerNode.category.orderIndex,
                    metatags:  innerNode.category.metatags,
                    parent: node.id || node.category.id,
                    subItems: innerNode.subItems,
                    parentStatus: innerNode.category.parentStatus,
                    expanded: expandedCategories.includes(parseInt(innerNode.id))
                });
            });
        }
        return [...acc, ...cache];
    }, []);
};

export const flattenCategoryTree = (categoryTree, expandedCategories = []) => {
    /**
     * Repeat flattening until no subItem keys are left
     */
    const flattenedTree = flattenCategoryLevel(categoryTree, expandedCategories);
    const canTreeBeMoreFlat = flattenedTree.some(item => item.subItems);
    return canTreeBeMoreFlat ? flattenCategoryTree(flattenedTree, expandedCategories) : flattenedTree;
};

export const getSameLevelCategories = (flatCategoryTree, parentId) =>
    flatCategoryTree.filter(category => category.parent === (parseInt(parentId) || null))
;

export const constructCategoryBreadcrumb = (category, iso) => ({
    id: category.id,
    label: category.name.find(translation => translation.iso.toLowerCase() === iso.toLowerCase()).value,
    parent: category.parent
});

export const constructCategoryBreadcrumbs = (flatCategoryTree, endCategoryId, iso) => intermediateCategoryBreadcrumbs => {
    const endCategory = flatCategoryTree.find(category => parseInt(category.id) === parseInt(endCategoryId));

    if (intermediateCategoryBreadcrumbs.length === 0) {
        const endBreadcrumb = constructCategoryBreadcrumb(endCategory, iso);

        return constructCategoryBreadcrumbs(flatCategoryTree, endBreadcrumb.id, iso)([ endBreadcrumb ]);
    } else {
        const parentCategory = flatCategoryTree.find(category => parseInt(category.id) === parseInt(intermediateCategoryBreadcrumbs[0].parent));

        if (parentCategory) {
            const parentBreadcrumb = constructCategoryBreadcrumb(parentCategory, iso);

            return constructCategoryBreadcrumbs(flatCategoryTree, parentCategory.id, iso)([ parentBreadcrumb, ...intermediateCategoryBreadcrumbs ]);
        } else {
            return intermediateCategoryBreadcrumbs;
        }
    }
};

export const getTranslatedCurrentCategory = (flatCategoryTree, categoryId, iso) => {
    /**
     * find current category
     */
    const currentCategory = flatCategoryTree.find(category => parseInt(category.id) === parseInt(categoryId));
    /**
     * return category object with name value as translation for provided iso
     */

    return {
        ...currentCategory,
        name: currentCategory.name.find(translation => translation.iso.toLowerCase() === iso.toLowerCase()).value,
        path: currentCategory.path.find(translation => translation.iso.toLowerCase() === iso.toLowerCase()).value
    };
};

export const translateFlattenedCategoryTree = (flatCategoryTree, iso) => {
    return flatCategoryTree.map(category => ({
        ...category,
        name: category.name.find(translation => translation.iso.toLowerCase() === iso.toLowerCase()).value,
        path: category.path.find(translation => translation.iso.toLowerCase() === iso.toLowerCase()).value
    }));
};

export const generateCategoryDropdownOptions = (flatTranslatedCategoryTree, emptySelectionLabel) => {
    const generatedOptions = [
        {
            id: null,
            label: emptySelectionLabel
        },
        ...flatTranslatedCategoryTree.map(category => ({
            id: category.id,
            label: category.name,
            className: category.depth && category.depth > 0 ? `ml-${category.depth * 2}` : 'font-weight-bold'
        }))
    ];

    return emptySelectionLabel ? generatedOptions : generatedOptions.slice(1);
};

/**
 * Recursive function
 */
export const generateCategoryPositionPathArray = (flatTranslatedCategoryTree, categoryId, iso, previousGeneratedArray) => {
    const currentCategory = flatTranslatedCategoryTree.find(category => parseInt(category.id) === parseInt(categoryId));
    /**
     * If it's a first function call, there will be no previousGeneratedArray to spread
     */
    const nextGeneratedArray = !previousGeneratedArray ? [ currentCategory.name ] : [ currentCategory.name, ...previousGeneratedArray ];

    /**
     * If correntCategory has a parent, then recall the function with updated values, otherwise return array generated to this point
     */
    return currentCategory.parent
        ? generateCategoryPositionPathArray(flatTranslatedCategoryTree, currentCategory.parent, iso, nextGeneratedArray)
        : nextGeneratedArray
    ;
};

export const pickBadgeColor = (status) => {
    switch(status){
        case 'Editing': return 'danger';
        case 'Published': return 'success';
        default: return null;
    }
};

export const generateStatusesConfig = (statusEnum, translations) => {
    return Object.keys(statusEnum).map(key => ({
        id: parseInt(key),
        label: translations[statusEnum[key].toLowerCase()],
        color: pickBadgeColor(statusEnum[key])
    }));
};
