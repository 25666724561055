import { addEditData, getData, removeData } from '../generic';

import { cAPI } from '../../../utils/cAPI';

export const getAdBanners = () => dispatch => getData(dispatch, cAPI.APGetAdBanners, null, 'adBanners');
export const getAdBanner = (id) => dispatch => getData(dispatch, cAPI.APGetAdBanner, id);
export const getAdBannerAssignedCategories = (id) => dispatch => getData(dispatch, cAPI.APGetAdBannerAssignedCategories, id, 'adBannersCategories');

export const addEditAdBanner = (formData) => dispatch => addEditData(dispatch, cAPI.APAddEditAdBanner, formData);
export const updateAdBannerStatus = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdateAdBannerStatus, formData, 'adBanners');
export const addAdBannerCategories = (formData) => dispatch => addEditData(dispatch, cAPI.APAddAdBannerCategories, formData, 'adBannersCategories');
export const uploadAdBannerImage = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUploadAdBannerImage, { id, formData }, 'adBanners');

export const deleteAdBanner = (id) => dispatch => removeData(dispatch, cAPI.APDeleteAdBanner, id, 'adBanners');
export const deleteAdBannerCategories = (formData) => dispatch => removeData(dispatch, cAPI.APDeleteAdBannerCategories, formData, 'adBannersCategories');
