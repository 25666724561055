import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTaxRates } from '../../../redux/methods/extrasSettings';
import useFetch from '../../../hooks/useFetch';
import { Field } from 'react-final-form';
import { Label } from 'reactstrap';

import Dropdown from '../../Dropdown';

import { stringOrNumberPropTypes } from '../../../propTypes';
import clsx from 'clsx';

const propTypes = {
    name: PropTypes.string,
    label: stringOrNumberPropTypes,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: stringOrNumberPropTypes,
        label: stringOrNumberPropTypes,
    })),
    currentSelectionLabel: stringOrNumberPropTypes,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    mutator: PropTypes.func,
    getTaxRates: PropTypes.func,
};

const FormFieldWithTaxRatesDropdown = ({ getTaxRates, name, label, className, isDisabled = false, mutator  }) => {
    const [ taxRates ] = useFetch(getTaxRates);

    const options = taxRates && taxRates.map(taxRate => ({ id: taxRate.id, label: taxRate.name }));

    return taxRates || options ? (
        <Field
            name={name}
            parse={value => {
                if(mutator){
                    mutator(value);
                }

                return value;
            }}
        >
            {
                props =>
                    <div className={clsx(className)}>
                        <Label>{label}</Label>
                        <Dropdown
                            name={props.input[name]}
                            options={options}
                            currentSelectionLabel={options.find(opt => opt.id === props.input.value)?.label}
                            performSelection={props.input.onChange}
                            isDisabled={isDisabled}
                        />
                        {(props.meta.error || props.meta.submitError) && props.meta.touched && (<small className='text-danger'>{props.meta.error || props.meta.submitError}</small>)}
                    </div>
            }
        </Field>
    ) : null;
};

FormFieldWithTaxRatesDropdown.propTypes = propTypes;

const mapDispatch = {
    getTaxRates
};

export default connect(null, mapDispatch)(FormFieldWithTaxRatesDropdown);