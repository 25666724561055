import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { UncontrolledTooltip } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    labels: PropTypes.shape({
        main: PropTypes.string,
        false: PropTypes.string,
        true: PropTypes.string,
    }).isRequired,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    changeActiveLabel: PropTypes.func,
    className: PropTypes.string,
    tooltip: PropTypes.string,
};

const SwitchWithLabels = ({ labels, isChecked, isDisabled, changeActiveLabel, className, tooltip }) => {
    const handleChange = e => {
        changeActiveLabel(e.target.checked);
    };

    const inActiveClassNames = 'text-muted font-weight-light';
    const activeClassNames = 'text-primary font-weight-bold';

    return (
        <React.Fragment>
            { tooltip &&
                <UncontrolledTooltip placement="top" target='switchMode'>
                    {tooltip}
                </UncontrolledTooltip>
            }
            <div className={clsx(styles.container, className)}>
                <span className={clsx('mr-3', !labels.main && 'd-none')}>{labels.main}</span>
                <div className={styles.switch}>
                    <span className={clsx(isChecked ? inActiveClassNames : activeClassNames)}>{labels.false}</span>
                    <label className='switch switch-pill switch-primary mx-3' id='switchMode'>
                        <input type='checkbox' className='switch-input' onChange={handleChange} disabled={isDisabled} checked={isChecked}/>
                        <span className={clsx('switch-slider bg-primary border-primary')}></span>
                    </label>
                    <span className={clsx(!isChecked ? inActiveClassNames : activeClassNames)}>{labels.true}</span>
                </div>
            </div>
        </React.Fragment>
    );
};

SwitchWithLabels.propTypes = propTypes;

export default SwitchWithLabels;