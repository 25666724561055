import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { filterItemsList } from '../../utils/components';

import { Container, Col, Row, Button } from 'reactstrap';

import ListGroup from '../Assigner/ListGroup';

import { stringOrNumberPropTypes } from '../../propTypes';

const listItemPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    label: PropTypes.string,
});

const propTypes = {
    lists: PropTypes.shape({
        left: PropTypes.arrayOf(listItemPropTypes),
        right: PropTypes.arrayOf(listItemPropTypes),
    }),
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onConfirm: PropTypes.func,
    translations: PropTypes.shape({
        toAssign: PropTypes.shape({
            header: PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string,
            }),
            searchPlaceholder: PropTypes.string,
            tooltip: PropTypes.string,
        }),
        assigned: PropTypes.shape({
            header: PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string,
            }),
            searchPlaceholder: PropTypes.string,
            tooltip: PropTypes.string,
        }),
    })
};

export const AssignerDynamicList = ({ lists, onConfirm, translations, listItem }) => {
    const [leftListSearchString, setLeftListSearchString] = useState('');
    const [rightListSearchString, setRightListSearchString] = useState('');

    const [rightList, setRightList] = useState(lists.right);

    const handleChangeLeftListSearchString = e => {
        setLeftListSearchString(e.target.value);
    };

    const handleChangeRightListSearchString = e => {
        setRightListSearchString(e.target.value);
    };

    const handleAddToRightList = itemToAdd => () => {
        setRightList([...rightList, itemToAdd]);
    };

    const handleRemoveFromRightList = itemToRemove => () => {
        setRightList(rightList.filter(item => item.id !== itemToRemove.id));
    };

    const handleConfirm = () => {
        onConfirm(rightList.map(({id}) => id));
    };

    const rightIdList = rightList.map(({ id }) => id);
    const filteredLeftList  = lists.left ? lists.left.filter(item => !(rightIdList.includes(item.id))) : [];

    const filteredBySearchLeftList = filteredLeftList.length > 0 ? filterItemsList(filteredLeftList, leftListSearchString) : [];
    const filteredBySearchRightList = rightList.length > 0 ? filterItemsList(rightList, rightListSearchString) : [];
    return (
        <Container fluid>
            <Row>
                <Col xs='12' lg='6' className='mb-3 sm-lg-0'>
                    <ListGroup
                        listItem={listItem}
                        header={translations.toAssign.header}
                        counter={{
                            left: filteredBySearchLeftList.length,
                            right: lists.left.length,
                        }}
                        customHeader={lists.customHeaderLeft}
                        searchInput={{
                            value: leftListSearchString,
                            onChange: handleChangeLeftListSearchString,
                            placeholder: translations.toAssign.searchPlaceholder,
                        }}
                        items={filteredBySearchLeftList}
                        tooltip={translations.toAssign.tooltip}
                        onItemClick={handleAddToRightList}
                        color='danger'
                    />
                </Col>
                    <Col xs='12' lg='6'>
                        <ListGroup
                            listItem={listItem}
                            header={translations.assigned.header}
                            counter={{
                                left: filteredBySearchRightList.length,
                                right: rightList.length,
                            }}
                            customHeader={lists.customHeaderRight}
                            searchInput={{
                                value: rightListSearchString,
                                onChange: handleChangeRightListSearchString,
                                placeholder: translations.assigned.searchPlaceholder,
                            }}
                            items={filteredBySearchRightList}
                            tooltip={translations.assigned.tooltip}
                            onItemClick={handleRemoveFromRightList}
                        />
                    </Col>
                </Row>
                <div className='d-flex w-100 justify-content-end my-3'>
                    <Button
                        color='success'
                        onClick={handleConfirm}
                    >
                        <i className={('fa fa-save mr-2')}/>
                        <span>{translations.buttons.confirm.label}</span>
                    </Button>
                </div>
            </Container>
    );
};

AssignerDynamicList.propTypes = propTypes;

export default AssignerDynamicList;
