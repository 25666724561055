const adBanners = {
    view: {
        actions: {
            create: '[EN] Utwórz reklamę',
        },
        title: '[EN] Ustawienia reklam',
    },
    table: {
        headers: {
            desktopPhoto: '[EN] Zdjęcie desktop',
            mobilePhoto: '[EN] Zdjęcie mobile',
            link: '[EN] Odnośnik',
            name: '[EN] Nazwa',
            categories: '[EN] Przypisane kategorie',
            isActive: '[EN] Aktywność',
            activeInDates: '[EN] Zakres aktywności',
            activeInLanguges: '[EN] Aktywność w językach',
            actions: '[EN] Akcje',
            activeFrom: 'Aktywne od',
            activeTo: 'Aktywne do'
        },
        tooltips: {
            remove: '[EN] Usuń reklamę',
            edit: '[EN] Edytuj reklamę'
        },
        actions: {
            activate: '[EN] Aktywuj',
            deactivate: '[EN] Deaktywuj'
        },
        emptyTable: '[EN] Nie znaleziono reklam.',
        deleteModal: {
            header: '[EN] Usuń reklamę',
            body: '[EN] Czy jesteś pewien, że chcesz usunąć reklamę?',
            cancel: '[EN] WRÓC',
            confirm: '[EN] USUŃ',
        },
        emptyLogoAlt: '[EN] Brak zdjęcia',
    },
    edit: {
        fallbackName: '[EN] [BRAK NAZWY]',
        tabSelectorTitle: '[EN] Ustawienia reklam',
        tabs: {
            basic: {
                title: '[EN] Ustawienia podstawowe',
                desktopImage: '[EN] Zdjęcie desktop',
                mobileImage: '[EN] Zdjęcie mobile',
                inputs: {
                    name: '[EN] Nazwa',
                    link: '[EN] Odnośnik',
                    active: '[EN] Aktywność',
                    activeInLanguages: '[EN] Aktywność w językach',
                    desktopPhoto: '[EN] Zdjęcie desktop',
                    mobilePhoto: '[EN] Zdjęcie mobile',
                    alternativeText: '[EN] Tekst alternatywny',
                    dateFrom: '[EN] Aktywne od',
                    dateTo: '[EN] Aktywne do',
                    showOnAllCategories: 'Pokazuj we wszystkich kategoriach',
                    file: {
                        upload: '[EN] Prześlij zdjęcie',
                        remove: '[EN] Usuń zdjęcie',
                        edit: '[EN] Podmień zdjęcie',
                    }
                }
            },
            categories: {
                title: '[EN] Przypisane kategorie',
                table: {
                    columnNames: {
                        categories: '[EN] Przypisana kategoria',
                        actions: '[EN] Akcje',
                    },
                    tooltip: '[EN] Odłącz kategorię',
                    placeholder: '[EN] Nie znaleziono kategorii przypisanych do wybranej reklamy',
                    deleteModal: {
                        header: '[EN] Odłącz kategorię',
                        bodyStart: '[EN] Czy chcesz odłączyć kategorię',
                        bodyEnd: '[EN] od wybranej reklamy',
                        confirm: '[EN] ODŁĄCZ',
                        cancel: '[EN] WRÓĆ'
                    }
                },
                controls: {
                    add: '[EN] PRZYPISZ KATEGORIĘ',
                    remove: '[EN] ODŁĄCZ WYBRANE KATEGORIE'
                },
                additionModal: {
                    header: '[EN] Przypisz wybraną kategorię',
                    body: '[EN] Czy przypisać wybraną kategorię do reklamy',
                    confirm: '[EN] PRZYPISZ',
                    cancel: '[EN] WRÓĆ'
                },
                deletionModal: {
                    header: '[EN] Odłącz wybrane kategorię',
                    body: '[EN] Czy odłączyć wybrane kategorie od reklamy',
                },
                categorySelectionModal: {
                    header: '[EN] Wybór kategorii',
                    body: '[EN] Wybierz kategorię, która ma być przypisana do obecnej reklamy',
                    emptySelectionLabel: '[EN] Wybierz kategorię',
                    confirm: '[EN] WYBIERZ',
                    cancel: '[EN] WRÓĆ'
                },
                nameFiller: '[EN] [BRAK NAZWY]',
                targetCategoryError: '[EN] Wybrana kategoria już jest przypisana do obecnej reklamy, wybierz inną kategorię.'
            },
        },
        inputs: {
            name: '[EN] Nazwa',
            link: '[EN] Odnośnik',
            active: '[EN] Aktywność',
            activeInLanguages: '[EN] Aktywność w językach',
            desktopPhoto: '[EN] Zdjęcie desktop',
            mobilePhoto: '[EN] Zdjęcie mobile',
        },
        buttons: {
            assignCategories: '[EN] Przypisz kategorie',
            add: '[EN] Przypisz',
            remove: '[EN] Odłącz kategorię',
        }
    }
};

export default adBanners;