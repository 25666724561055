const regions = {
    view: {
        title: 'Regions list',
        actions: {
            addRegion: 'ADD REGION',
        }
    },
    errorModal: {
        cancel: 'CANCEL',
        header: 'Error occured',
    },
    additionModal: {
        header: 'Add new region',
        body: 'Are you sure you want to create new region?',
        confirm: 'ADD',
        cancel: 'CANCEL'
    },
    table: {
        nameFiller: '[NO NAME]',
        emptyTable: 'No regions found',
        headers: {
            name: 'Name',
            actions: 'Actions',
            countries: 'Assigned countries'
        },
        deleteModal: {
            header: 'Region removal',
            body: 'Are you sure that you want to remove region?',
            cancel: 'CANCEL',
            confirm: 'REMOVE'
        },
        tooltips: {
            remove: 'Remove region',
            edit: 'Edit region'
        },
    },
    edit: {
        tabSelectorTitle: 'Sections',
        fallbackName: '[NO NAME]',
        tabs: {
            name: {
                title: 'Region name',
                label: 'Region name',
            },
            countries: {
                title: 'Assigned countries',
                deleteModal: {
                    header: 'Unassign country',
                    body: 'Are you sure that you want to unassign select country from region?',
                    cancel: 'CANCEL',
                    confirm: 'UNASSIGN'
                },
                deleteManyModal: {
                    header: 'Unassign countries',
                    body: 'Are you sure you want to unassign selected countries?',
                },
                headerLabels: {
                    name: 'Name',
                    iso: 'ISO Code',
                    actions: 'Actions',
                },
                deleteTooltip: 'Unassign country',
                emptyTranslation: 'No assign countries',
                emptyIsoPlaceholder: '[NO ISO]',
                emptyNamePlaceholder: '[NO NAME]',
                buttons: {
                    delete: 'UNASSIGN SELECTED COUNTRIES',
                    add: 'ASSIGN COUNTRIES',
                },
                countryAssigner: {
                    header: 'Assigned countries management for region',
                    nameFiller: '[NO NAME]',
                }
            }
        }
    },
    countryAssigner: {
        tooltipCountry: 'Click to assign',
        tooltipCountryToAssign: 'Click to unassign',
        buttons: {
            cancel: 'CANCEL',
            confirm: 'SAVE CHANGES',
        },
        lists: {
            countries: {
                title: 'Unassigned countries',
                text: 'Click on a country to assign it to the region',
            },
            countriesToAssign: {
                title: 'Assigned countries',
                text: 'Click on a country to unassign it from the region',
            }
        },
        searchPlaceholder: 'Find country...',
    },
    regionManagementSection: {
        buttons: {
            remove: '[EN] ODŁĄCZ WYBRANE',
            add: '[EN] PRZYPISZ',
        },
        unassignSingleModal: {
            header: '[EN] Odłączanie regionu',
            body: '[EN] Czy na pewno chcesz odłączyć wybrany region?',
            confirm: '[EN] ODŁĄCZ',
            cancel: '[EN] ANULUJ',
        },
        unassignManyModal: {
            header: '[EN] Odłączanie regionów',
            body: '[EN] Czy na pewno chcesz odłączyć wybrane regiony?',
        },
        headers: {
            name: '[EN] Nazwa',
            acitions: '[EN] Akcje',
        },
        tooltip: '[EN] Odłącz region',
        nameFiller: '[EN] [BRAK NAZWY]',
        emptyTable: '[EN] Brak regionów do wyświetlenia',
        assignModal: {
            header: '[EN] Przypisywanie regionu',
            body: '[EN] Wybierz region do przypisania',
            confirm: '[EN] PRZYPISZ',
            cancel: '[EN] ANULUJ',

        }
    },
};

export default regions;