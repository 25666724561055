/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { generateEditLinkForManufacturers } from '../../../utils/manufacturers';

import TableGenerator from '../../generators/TableGenerator';
import PhotoPlaceholder from '../../../assets/photo_placeholder.svg';

import { productListItemPropTypes } from '../../../propTypes';

import styles from './index.module.scss';
import getConfig from '../../../getConfig';


const propTypes = {
    manufacturersList: PropTypes.arrayOf(productListItemPropTypes),
    history: PropTypes.object,
    removeHandler: PropTypes.func,
    switchHandler: PropTypes.func,
    withSwitch: PropTypes.bool,
    performPageSizeSelect: PropTypes.func
};

const ManufacturersTable = ({
    manufacturersList,
    history,
    removeHandler,
    switchHandler,
    withSwitch,
    performPageSizeSelect,
    currentPage,
    pageSize,
    setColumnSortedByDescendingOrder,
    performSort,
    columnSortedByDescendingOrder,
}) => {
    const intl = useIntl();
    const translations = intl.messages.manufacturers.table;
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const deleteAction = id => () => {
        removeHandler(id);
    };

    const ImageCell = item => (
        <img
            className={styles.photo}
            src={item.logo ? `${apiUrl}${item.logo}` : PhotoPlaceholder}
            alt={item.name || translations.emptyLogoAlt}
        />
    );

    const handleRowClick = id => () => {
        history.push(`/manufacturers/edit?manufacturerID=${id}`);
    };

    const handleSwitch = (id, value) => {
        switchHandler({ manufacturerId: id, showInFilters: value });
    };

    const onClickSortHandler = (columnName) => () => {
        setColumnSortedByDescendingOrder(prevState => prevState === columnName ? null : columnName);

        performSort(columnSortedByDescendingOrder === columnName ? 'Asc' : 'Desc');
    };

    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            currentPage={currentPage}
            pageSize={pageSize}
            editLink={generateEditLinkForManufacturers}
            columnSortedByDescendingOrder={columnSortedByDescendingOrder}
            onClickSortHandler={onClickSortHandler}
            expectedActions={['edit', 'delete']}
            items={manufacturersList}
            actions={{deleteAction}}
            translations={translations}
            defaultAction={handleRowClick}
            customIdSelector='id'
            columns={[
                {
                    header: translations.headers.logo,
                    type: 'custom',
                    customCell: ImageCell,
                    additionalStyle: styles.photoCell
                },
                {
                    header: translations.headers.name,
                    value: 'name',
                    additionalStyle: styles.nameCell,
                    filler: translations.nameFiller,
                    isSortable: true,
                    sortValue: 'name'
                },
                withSwitch && {
                    header: translations.headers.showInFilters,
                    value: 'showInFilters',
                    type: 'switchWithAction',
                    onSwitch: handleSwitch
                },
                {
                    header: translations.headers.actions,
                    type: 'actions'
                },
            ]}
        />
    );
};

ManufacturersTable.propTypes = propTypes;

export default ManufacturersTable;