export const generateEditLinkForCurrency = id => `/currencies/edit?currencyID=${id}`;

export const generateCurrenciesDropdownOptions = (currenciesList, emptySelectionLabel) => {
	const generatedCurrenciesList = currenciesList.map(currency => ({
		id: currency.id,
		label: currency.name,
	}));
	return emptySelectionLabel ? [{ id: null, label: emptySelectionLabel }, ...generatedCurrenciesList] : generatedCurrenciesList;
};

export const pickIsActiveColor = (isActive) => {
	switch(isActive) {
		case 'active': return 'success';
		case 'inactive': return 'danger';
		default: return null;
	}
};

export const generateIsActiveStatuses = (isActiveEnum, translations) => {
	return Object.keys(isActiveEnum).map(key => ({
		id: parseInt(key),
		label: translations[isActiveEnum[key]],
		color: pickIsActiveColor(isActiveEnum[key])
	}));
};