import React from 'react';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import styles from './index.module.scss';
import { UncontrolledTooltip } from 'reactstrap';

const RefreshButton = () => {
    const history = useHistory();
    const translations = useIntl().messages.defaultLayout.appGlobal;

    const handleClick = () => {
        history.go(0);
    };

    return (
        <React.Fragment>
            <button onClick={handleClick} id='refreshButton' className={styles.button}><FontAwesomeIcon icon={faRedo}/></button>
            <UncontrolledTooltip placement='bottom' target='refreshButton'>
                {translations.refresh}
            </UncontrolledTooltip>
        </React.Fragment>
    );
};

export default RefreshButton;