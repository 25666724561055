import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateDiscountCountries } from '../../../../../redux/methods/discounts';

import CountriesRegionTable from '../../../../../components/tables/CountriesRegionTable';
import SectionAsideButtons from '../../../../../components/SectionAsideButtons';
import Paginator from '../../../../../components/Paginator';
import CountryAssigner from '../../../../../features/regions/CountryAssigner';

import { stringOrNumberPropTypes } from '../../../../../propTypes';

import { withFormUtils } from '../../../../../hoc/withFormUtils';

const propTypes = {
    pages: PropTypes.number,
    currentPage: PropTypes.number,
    discountToEditId: stringOrNumberPropTypes,
    isActiveLabelLeft: PropTypes.bool,
    isLoading: PropTypes.bool,
    regionsIsPresent: PropTypes.bool,
    updateDiscountCountries: PropTypes.func,
    changePage: PropTypes.func,
};

const CountriesSection = ({ utils, pages, currentPage, discountToEditId, isActiveLabelLeft, discountCountries, regionsIsPresent, updateDiscountCountries, changePage }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.regionsCountries;

    const [checkedCountriesIds, setCheckedCountriesIds] = useState([]);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [countriesToAssign, setCountriesToAssign] = useState(discountCountries);

    useEffect(() => {
        setCountriesToAssign(discountCountries);

        return () => {
            setCheckedCountriesIds([]);
            setIsAssignModalOpen(false);
            setCountriesToAssign([]);
        };
    }, [discountCountries]);

    const handleRemoveFromAssignedList = countryToRemove => {
        setCountriesToAssign(countriesToAssign.filter(country => country.id !== countryToRemove.id));
    };

    const handleAddToAssignedList = countryToAssign => {
        setCountriesToAssign([...countriesToAssign, countryToAssign]);
    };

    const handleUnassignCountry = id => {
        updateDiscountCountries(discountToEditId, [id], isActiveLabelLeft, 'delete');
        changePage(0);
    };

    const handleAssignCountries = () => {
        const countriesToAssignIds = countriesToAssign.map(({id}) => id);
        updateDiscountCountries(discountToEditId, countriesToAssignIds, isActiveLabelLeft, 'replace');
    };

    const handleOpenAssignModal = () => {
        setIsAssignModalOpen(true);
    };

    const handleOpenUnassignModal = () => {
        utils.confirm(
            () => {
                updateDiscountCountries(discountToEditId, checkedCountriesIds, isActiveLabelLeft, 'delete');
                changePage(0);
            },
            translations.deleteModalCountries.header,
            translations.deleteModalCountries.body
        );
    };

    const sectionButtons = {
        remove: {
            label: translations.buttons.countries.remove,
            action: handleOpenUnassignModal,
            isDisabled: checkedCountriesIds.length === 0
        },
        add: {
            label: translations.buttons.countries.add,
            action: handleOpenAssignModal,
            isDisabled: isActiveLabelLeft && !regionsIsPresent
        }
    };

    return (
        <React.Fragment>
            <span className={clsx('d-block mb-2 font-weight-bold', isActiveLabelLeft ? 'text-danger' : 'text-success')}>
                    {isActiveLabelLeft ? translations.headers.countries.unassign : translations.headers.countries.assign}
            </span>
            <CountriesRegionTable
                checkedCheckboxList={checkedCountriesIds}
                setCheckedCountriesIds={setCheckedCountriesIds}
                countries={discountCountries}
                translations={translations.tables.countries}
                headerLabels={translations.tables.countries.headers}
                removeAction={handleUnassignCountry}
                isRedOnHover={isActiveLabelLeft}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={currentPage}
                        onPageChange={changePage}
                    />
            }
            <SectionAsideButtons
                buttons={sectionButtons}
            />
            <CountryAssigner
                isOpen={isAssignModalOpen}
                setIsOpen={setIsAssignModalOpen}
                assignedCountries={countriesToAssign}
                onSubmit={handleAssignCountries}
                removeFromAssignedList={handleRemoveFromAssignedList}
                addToAssignedList={handleAddToAssignedList}
                header={translations.countryAssignerHeader}
            />
        </React.Fragment>
    );
};

CountriesSection.propTypes = propTypes;

const mapStateToProps = state => ({
    discountToEditId: state.discounts.discountToEdit.id,
});

const mapDispatch = {
    updateDiscountCountries,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(CountriesSection));