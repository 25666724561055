const generateFormDataRequest = (formData, method = 'POST') => {
    return {
        method: method,
        body: formData,
        mode: 'cors',
        credentials: 'include',
    };
};

module.exports = {
    generateFormDataRequest,
};
