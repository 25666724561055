import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'reactstrap';

import { statusConfigPropTypes } from '../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    header: PropTypes.string,
    statuses: statusConfigPropTypes,
};

const StatusDescriptionSection = ({ statuses, header }) => {
    return (
        <Container fluid className='p-0 m-0'>
            <h2 className={styles.header}>{header}</h2>
            {
                statuses.map(status =>
                    <div key={`${status.label}--description`} className='py-2'>
                        <h6>{status.label}</h6>
                        <span>{status.description}</span>
                    </div>
                )
            }
        </Container>
    );
};

StatusDescriptionSection.propTypes = propTypes;

export default StatusDescriptionSection;