import { getData, removeData, addEditData } from '../generic';
import { cAPI } from '../../../utils/cAPI';

export const getUpperMenuTree = () => dispatch => getData(dispatch, cAPI.APGetMenuTree, null, 'upperMenu');
export const getUpperMenuSection = id => dispatch => getData(dispatch, cAPI.APGetMenuSection, id, 'upperMenu');
export const addMenuSection = (formData, preventRefetch) =>  dispatch => addEditData(dispatch, cAPI.APAddMenuSection, formData, !preventRefetch && 'upperMenu');
export const updateUpperMenuSectionLocation = formData =>  dispatch => addEditData(dispatch, cAPI.APUpdateMenuSectionLocation, formData, 'upperMenu');
export const updateUpperMenuSectionStatus = formData =>  dispatch => addEditData(dispatch, cAPI.APUpdateMenuSectionStatus, formData, 'upperMenu');
export const removeUpperMenuSection = id => dispatch => removeData(dispatch, cAPI.APDeleteMenuSection, id, 'upperMenu');
export const updateUpperMenuSection = (formData, preventRefetch) =>  dispatch => addEditData(dispatch, cAPI.APUpdateMenuSection, formData, !preventRefetch && 'upperMenu');
export const deleteMenuImage = id => dispatch => removeData(dispatch, cAPI.APDeleteMenuImage, id, 'upperMenu');
export const uploadMenuImage = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUploadMenuImage, { id, formData }, 'upperMenu');

export const getFooterSettingsPhotoWithText = () => dispatch => getData(dispatch, cAPI.APGetFooterSettingsPhotoWithText, null, 'bottomMenu');
export const getFooterSettingsNewsletter = () => dispatch => getData(dispatch, cAPI.APGetFooterSettingsNewsletter, null, 'bottomMenu');
export const getFooterSettingsMain = () => dispatch => getData(dispatch, cAPI.APGetFooterSettingsMain, null, 'bottomMenu');

export const updateFooterSettingsPhotoWithText = (formData, preventRefetch) =>  dispatch => addEditData(dispatch, cAPI.APUpdateFooterSettingsPhotoWithText, formData, !preventRefetch && 'bottomMenu');
export const updateFooterSettingsNewsletter = (formData, preventRefetch) =>  dispatch => addEditData(dispatch, cAPI.APUpdateFooterSettingsNewsletter, formData, !preventRefetch && 'bottomMenu');
export const updateFooterSettingsMain = (formData, preventRefetch) =>  dispatch => addEditData(dispatch, cAPI.APUpdateFooterSettingsMain, formData, !preventRefetch && 'bottomMenu');

export const getFooterImage = () => dispatch => getData(dispatch, cAPI.APGetFooterImage, null, 'footerMain');
export const updateFooterImage = formData => dispatch => addEditData(dispatch, cAPI.APUpdateFooterImage, formData, 'bottomMenu');
export const deleteFooterImage = () => dispatch => removeData(dispatch, cAPI.APDeleteFooterImage, null, 'footerMain');