import { cAPI } from '../../../utils/cAPI';
import { getData, addEditData, removeData } from '../generic';
import { setButtons } from '../../../features/visualSettings/slice';
import { setForceRefetchByTarget } from '../../../features/refetchers/slice';

export const getVisualSettingsCss = () => dispatch => getData(dispatch, cAPI.APGetVisualSettingsCss, null, 'visualSettingsCss');
export const updateVisualSettingsCss = formData => dispatch => {
    dispatch(setForceRefetchByTarget({ target: 'fonts', forceRefetch: true }));

    return addEditData(dispatch, cAPI.APUpdateVisualSettingsCss, formData, 'visualSettingsCss');
};
export const removeVisualSettingsOwnCss = id => dispatch => removeData(dispatch, cAPI.APRemoveVisualSettingsOwnCss, id, 'visualSettingsCss');
export const uploadVisualSettingsOwnCss = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUploadVisualSettingsOwnCss, { id: id, formData: formData }, 'visualSettingsCss');

export const getVisualSettingsLogo = () => dispatch => getData(dispatch, cAPI.APGetVisualSettingsLogo, null , 'visualSettingsLogo');
export const updateVisualSettingsLogo = formData => dispatch => addEditData(dispatch, cAPI.APUpdateVisualSettingsLogo, formData, 'visualSettingsLogo');

export const getVisualSettingsLogoFile = () => dispatch => getData(dispatch, cAPI.APGetVisualSettingsLogoFile, null, 'visualSettingsLogo');
export const removeVisualSettingsLogoFile = () => dispatch => removeData(dispatch, cAPI.APRemoveVisualSettingsLogoFile, null, 'visualSettingsLogo');
export const uploadVisualSettingsLogoFile = formData => dispatch => addEditData(dispatch, cAPI.APUploadVisualSettingsLogoFile, formData, 'visualSettingsLogo');

export const getVisualSettingsFaviconFile = () => dispatch => getData(dispatch, cAPI.APGetVisualSettingsFaviconFile, null, 'visualSettingsFavicon');
export const removeVisualSettingsFaviconFile = () => dispatch => removeData(dispatch, cAPI.APRemoveVisualSettingsFaviconFile, null, 'visualSettingsFavicon');
export const uploadVisualSettingsFaviconFile = formData => dispatch => addEditData(dispatch, cAPI.APUploadVisualSettingsFaviconFile, formData, 'visualSettingsFavicon');

export const getTemplateParams = id => dispatch => getData(dispatch, cAPI.APGetTemplateParams, id, 'visualSettingsParams');
export const updateTemplateParams = formData => dispatch => getData(dispatch, cAPI.APUpdateTemplateParams, formData, 'visualSettingsParams');

export const getButtonsList = () => dispatch => getData(dispatch, cAPI.APGetButtonsList, null, 'buttons').then(result => {
    dispatch(setButtons({ buttons: result.payload }));
    return result;
});
export const updateButton = formData => dispatch => addEditData(dispatch, cAPI.APUpdateButton, formData);
export const deleteButton = (id) => dispatch => removeData(dispatch, cAPI.APDeleteButton, id, 'buttons');
export const getButtonsSimpleList = () => dispatch => getData(dispatch, cAPI.APGetButtonsSimpleList);
export const getButton = (id) => dispatch => getData(dispatch, cAPI.APGetButton, id);

export const getGraphicsSettings = () => dispatch => getData(dispatch, cAPI.APGetGraphicsSettings);
export const updateGraphicsSettings = formData => dispatch => getData(dispatch, cAPI.APUpdateGraphicsSettings, formData);

export const getVisualSettingsPlaceholderImageProduct = () => dispatch => getData(dispatch, cAPI.APGetVisualSettingsPlaceholderImageProduct, null, 'visualSettingsPlaceholderImageProduct');
export const removeVisualSettingsPlaceholderImageProduct = () => dispatch => removeData(dispatch, cAPI.APRemoveVisualSettingsPlaceholderImageProduct, null, 'visualSettingsPlaceholderImageProduct');
export const uploadVisualSettingsPlaceholderImageProduct = formData => dispatch => addEditData(dispatch, cAPI.APUploadVisualSettingsPlaceholderImageProduct, formData, 'visualSettingsPlaceholderImageProduct');
