import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'reactstrap';

const propTypes = {
    text: PropTypes.string,
};

const EmptyTablePlaceholder = ({ text }) => {
    return (
        <Row className='d-flex justify-content-center align-items-center mt-4 mb-4'>
            {text}
        </Row>
    );
};

EmptyTablePlaceholder.propTypes = propTypes;

export default EmptyTablePlaceholder;