const cookiesAgreements = {
    title: 'Ciasteczka - zgody',

    tabs: {
        basic: 'Tłumaczenia',
        agreements: 'Lista zgód'
    },

    table: {
        tooltips: {
            edit: 'Edytuj zgode',
        },
        emptyTable: 'Brak zgód',
        headerLabels: {
            header: 'Nagłówek',
            type: 'Typ zgody',
            isActive: 'Aktywna',
            actions: 'Akcje',
        },
        emptyPlaceholders: {
            header: '[BRAK NAGŁÓWKA]'
        }
    },

    editBasic: {
        labels: {
            header: 'Nagłówek okna prośby o zgodę',
            button: 'Treść na przycisku potwierdzenia wyboru'
        }
    },

    edit: {
        title: 'Edycja zgody na ciasteczka',
        labels: {
            header: 'Nagłówek',
            isActive: 'Aktywna',
            shortContent: 'Treść króka',
            longContent: 'Treść długa',
        },
        button: 'ZAPISZ ZMIANY',
    },
};

export default cookiesAgreements;