import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getUpperMenuTree, addMenuSection } from '../../../redux/methods/menu';
import { getTranslatedMenuTree, generateEditLinkForMenu } from '../../../utils/menu';
import useFetch from '../../../hooks/useFetch';

import { Button } from 'reactstrap';
import MenuSectionsTreeDND from '../../../features/menu/MenuSectionsTreeDND';
import { withFormUtils } from '../../../hoc/withFormUtils';
import ContainerWithCard from '../../../components/ContainerWithCard';
import Tabulator from "../../../components/Tabulator";

import { formUtilsHocPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getUpperMenuTree: PropTypes.func,
    forceRefetch: PropTypes.bool,
    history: PropTypes.object,
    addMenuSection: PropTypes.func,
};

const MenuPositions = ({
    utils,
    getUpperMenuTree,
    forceRefetch,
    history,
}) => {
    const intl = useIntl();
    const translations = intl.messages.menuSettings.menu;

    const [activeTabId, setActiveTabId] = useState('UpperMenuTab');

    const editAction = id => generateEditLinkForMenu(id);

    const [ menuTree ] = useFetch(getUpperMenuTree, null, [], forceRefetch);

    const handleCreateNewSection = () => {
        history.push(editAction(0));
    };

    return menuTree ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-arrows-alt'>
            <Tabulator
                listOfElements={[
                    {
                        label: translations.tree.upperMenu,
                        id: 'UpperMenuTab',
                        component:
                            <MenuSectionsTreeDND
                                translations={translations.tree}
                                editAction={editAction}
                                menuTree={getTranslatedMenuTree(menuTree, utils.currentLanguage())}
                            />
                    },
                    {
                        label: translations.tree.bottomMenu,
                        id: 'BottomMenuTab',
                        component:
                            <MenuSectionsTreeDND
                                isFooterTree={true}
                                translations={translations.tree}
                                editAction={editAction}
                                menuTree={getTranslatedMenuTree(menuTree, utils.currentLanguage())}
                            />
                    },
                ]}
                onTabChange={id => setActiveTabId(id)}
                defaultActiveTabId={activeTabId}
            />
            <aside className={styles.controls}>
                <div id='addRootButton'>
                    <Button color="primary" onClick={handleCreateNewSection}>
                        <i className="fa fa-plus mr-2"/>
                        <span>{translations.tree.actions.addRoot}</span>
                    </Button>
                </div>
            </aside>
        </ContainerWithCard>
    ) : null ;
};

MenuPositions.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.upperMenu,
});

const mapDispatch = {
    getUpperMenuTree,
    addMenuSection,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(MenuPositions));