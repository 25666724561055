import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import TooltipWrapper from '../../TooltipWrapper';
import ColorPicker from '../../ColorPicker';

import styles from './index.module.scss';

const propTypes = {
    color: PropTypes.shape({
        value: PropTypes.string,
        orderIndex: PropTypes.number
    }),
    tooltipLabels: PropTypes.shape({
        edit: PropTypes.string,
        remove: PropTypes.string,
    }),
    editAction: PropTypes.func,
    removeAction: PropTypes.func
};

const ColorItem = ({ color, tooltipLabels, editAction, removeAction }) => {
    const [ isEditTooltipOpen, setIsEditTooltipOpen ] = useState(false);
    const [ isRemoveTooltipOpen, setIsRemoveTooltipOpen ] = useState(false);
    const [ isColorPickerVisible, setIsColorPickerVisible ] = useState(false);

    const handleTooltipToggle = type => {
        if (type === 'edit') {
            return setIsEditTooltipOpen;
        } else if (type === 'remove') {
            return setIsRemoveTooltipOpen;
        }
    };

    const handleShowColorPicker = () => {
        setIsColorPickerVisible(true);
    };

    const handleHideColorPicker = () => {
        setIsColorPickerVisible(false);
    };

    const handleEditAction = orderIndex => color => {
        editAction(orderIndex, color);
    };

    const handleRemoveAction = orderIndex => () => {
        removeAction(orderIndex);
    };

    return (
        <span className={styles.container}>
            <span className={styles.color} style={{ backgroundColor: color.value }}/>
            <span className={styles.label}>{color.value}</span>
            <span className={styles.actions}>
                <TooltipWrapper
                    target={`partialColorEdit-${color.orderIndex}`}
                    placement="top"
                    isOpen={isEditTooltipOpen}
                    setIsOpen={handleTooltipToggle('edit')}
                    tooltipContent={tooltipLabels.edit}
                >
                    <button
                        className={styles.actionsButton}
                        onClick={handleShowColorPicker}
                        id={`partialColorEdit-${color.orderIndex}`}
                        type="button"
                    >
                        <i className={clsx("fa fa-edit fa-lg", styles.iconEdit)}/>
                    </button>
                </TooltipWrapper>
                <TooltipWrapper
                    target={`partialColorRemove-${color.orderIndex}`}
                    placement="top"
                    isOpen={isRemoveTooltipOpen}
                    setIsOpen={handleTooltipToggle('remove')}
                    tooltipContent={tooltipLabels.remove}
                >
                    <button
                        className={styles.actionsButton}
                        onClick={handleRemoveAction(color.orderIndex)}
                        id={`partialColorRemove-${color.orderIndex}`}
                        type="button"
                    >
                        <i className={clsx("fa fa-trash-o fa-lg", styles.iconRemove)}/>
                    </button>
                </TooltipWrapper>
            </span>
            {
                isColorPickerVisible &&
                    <ColorPicker
                        isEdit
                        color={color.value}
                        onClose={handleHideColorPicker}
                        onAdd={handleEditAction(color.orderIndex)}
                    />
            }
        </span>
    );
};

ColorItem.propTypes = propTypes;

export default ColorItem;