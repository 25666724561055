const regions = {
    view: {
        title: 'Lista regionów',
        actions: {
            addRegion: 'DODAJ REGION',
        }
    },
    errorModal: {
        cancel: 'WRÓĆ',
        header: 'Wystąpił błąd',
    },
    additionModal: {
        header: 'Dodaj nowy region',
        body: 'Czy chcesz utworzyć nowy region?',
        confirm: 'DODAJ',
        cancel: 'WRÓĆ'
    },
    table: {
        nameFiller: '[BRAK NAZWY]',
        emptyTable: 'Nie znaleziono żadnego regionu',
        headers: {
            name: 'Nazwa',
            actions: 'Akcje',
            countries: 'Przypisanych krajów',
        },
        deleteModal: {
            header: 'Usuwanie regionu',
            body: 'Czy napewno chcesz usunąć region?',
            cancel: 'WRÓĆ',
            confirm: 'USUŃ'
        },
        tooltips: {
            remove: 'Usuń region',
            edit: 'Edycja region'
        },
    },
    edit: {
        tabSelectorTitle: 'Sekcje',
        fallbackName: '[BRAK NAZWY]',
        tabs: {
            name: {
                title: 'Nazwa regionu',
                label: 'Nazwa regionu',
            },
            countries: {
                title: 'Przypisane kraje',
                deleteModal: {
                    header: 'Odłączanie kraju',
                    body: 'Czy napewno chcesz odłączyć wybrany kraj od regionu?',
                    cancel: 'WRÓĆ',
                    confirm: 'ODŁĄCZ'
                },
                deleteManyModal: {
                    header: 'Odłączanie krajów',
                    body: 'Czy jestes pewien, że chcesz odłączyć wybrane kraje?',
                },
                headerLabels: {
                    name: 'Nazwa',
                    iso: 'Kod ISO',
                    actions: 'Akcje',
                },
                tooltips: {
                    remove: 'Odłącz kraj',
                },
                emptyTable: 'Nie znaleziono przypsianych krajów',
                emptyIsoPlaceholder: '[BRAK ISO]',
                emptyNamePlaceholder: '[BRAK NAZWY]',
                buttons: {
                    delete: 'ODŁĄCZ WYBRANE KRAJE',
                    add: 'PRZYPISZ KRAJE',
                },
                countryAssigner: {
                    header: 'Zarządzanie przypisanymi krajami dla regionu',
                    nameFiller: '[BRAK NAZWY]',
                }
            }
        }
    },
    countryAssigner: {
        tooltipCountry: 'Kliknij aby przypisać',
        tooltipCountryToAssign: 'Kliknij aby anulować przypisanie',
        buttons: {
            cancel: 'ANULUJ',
            confirm: 'ZAPISZ ZMIANY',
        },
        lists: {
            countries: {
                title: 'Nieprzypisane kraje',
                text: 'Kliknij kraj aby przypisać do regionu',
            },
            countriesToAssign: {
                title: 'Przypisane kraje',
                text: 'Kliknij kraj aby anulować przypisanie do regionu',
            }
        },
        searchPlaceholder: 'Wyszukaj kraj...',
    },
    regionManagementSection: {
        buttons: {
            remove: 'ODŁĄCZ WYBRANE',
            add: 'PRZYPISZ',
        },
        unassignSingleModal: {
            header: 'Odłączanie regionu',
            body: 'Czy na pewno chcesz odłączyć wybrany region?',
            confirm: 'ODŁĄCZ',
            cancel: 'ANULUJ',
        },
        unassignManyModal: {
            header: 'Odłączanie regionów',
            body: 'Czy na pewno chcesz odłączyć wybrane regiony?',
        },
        headers: {
            name: 'Nazwa',
            acitions: 'Akcje',
        },
        tooltip: 'Odłącz region',
        nameFiller: '[BRAK NAZWY]',
        emptyTable: 'Brak regionów do wyświetlenia',
        assignModal: {
            header: 'Przypisywanie regionu',
            body: 'Wybierz region do przypisania',
            confirm: 'PRZYPISZ',
            cancel: 'ANULUJ',

        }
    },
};

export default regions;