/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { Modal as CoreUiModal, ModalBody, ModalHeader } from 'reactstrap';

import styles from './index.module.scss'
import getConfig from '../../../getConfig';


const propTypes = {
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    header: PropTypes.string,
    setIsOpen: PropTypes.func,
};

const ImageModal = ({ isOpen, className, header, image, setIsOpen }) => {
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <CoreUiModal
            size='xl'
            isOpen={isOpen}
            toggle={handleToggle}
            className={className}
        >
            <ModalHeader toggle={handleToggle}>{image.name}</ModalHeader>
            <ModalBody>
                <div className={styles.imageContainer}>
                    <img className={styles.image} src={`${apiUrl}${image.imageSource}`} alt={image.imageAlternativeText} />
                </div>
            </ModalBody>
        </CoreUiModal>
    );
};

ImageModal.propTypes = propTypes;

export default ImageModal;