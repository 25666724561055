/* eslint-disable camelcase */
export const fullyFeaturedConfig = {
    height: 500,
    menubar: 'file edit view insert format tools table tc help',
    plugins: [
        'advlist emoticons autolink lists link image',
        'charmap print preview anchor help',
        'searchreplace visualblocks code',
        'insertdatetime media table paste imagetools wordcount'
    ],
    fontsize_formats: "8px 9px 10px 11px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px",
    toolbar:
        `undo redo | forecolor backcolor emoticons | formatselect | removeFormat bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright | bullist numlist outdent indent | link image | help`,
    extended_valid_elements:"i[id|class|style],em"
};

export const contentAndLinkLimitedConfig = {
    height: 500,
    menubar: 'file edit view tc help',
    plugins: [
        'advlist emoticons lists link',
        'charmap print preview anchor help',
        'searchreplace visualblocks code',
        'insertdatetime paste wordcount'
    ],
    toolbar:
        `undo redo | forecolor backcolor emoticons | removeFormat bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | link | fontselect | help`,
};

export const basicFontFormats = `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; 
    Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier;
    Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol;
    Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times;
    Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;`;