/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { Container, Row, Form, Label } from 'reactstrap';
import { Form as FinalForm, Field } from 'react-final-form';
import SectionAsideButtons from '../../../components/SectionAsideButtons';
import TabProductAssignerManual from '../TabProductAssignerManual';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import FormFieldTranslations from '../../../components/formFields/FormFieldTranslations';

import { dropdownStatusPropTypes, stringOrNumberPropTypes, translationPropTypes, formUtilsHocPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    tagOptions: PropTypes.arrayOf(dropdownStatusPropTypes),
    translations: PropTypes.object,
    currentLanguage: PropTypes.string,
    onSubmit: PropTypes.func,
    settings: PropTypes.shape({
        lp: PropTypes.number,
        enabled: PropTypes.bool,
        tagValueId: stringOrNumberPropTypes,
        name: PropTypes.arrayOf(translationPropTypes),
        products: PropTypes.arrayOf(stringOrNumberPropTypes)
    }),
    utils: formUtilsHocPropTypes
};

const TabProductsAssigner = ({
    tagOptions,
    translations,
    currentLanguage,
    onSubmit,
    settings,
    utils,
    section,
    sectionLocation,
    isFormChanged,
    setIsEdited
}) => {
    const [ checkedProductsId, setCheckedProductsId ] = useState([]);

    const history = useHistory();

    useEffect(() => {
        setCheckedProductsId(settings.products ? settings.products : []);
        setIsEdited(false);
    }, [settings]);

    const config = { translations: ['name'] };

    const generateUpdatedTabs = updatedTabObject => section.tabs.map(tab => tab.lp === updatedTabObject.lp ? updatedTabObject : tab);

    const handleOnSubmit = async formData => {
        const updatedTab = {
            ...utils.generateFormToSubmitValues(formData, config),
            products: checkedProductsId,
            tagValueId: formData.selectionType === 'manual' ? null : formData.tagValueId
        };

        const updatedObject = {
            ...section,
            tabs: generateUpdatedTabs(updatedTab)
        };

        const response = await onSubmit(updatedObject);

        if(typeof response === 'number') {
            history.push(`edit?sectionID=${response}&sectionType=${section.type}&location=${sectionLocation}`);

            return;
        }

        return response;
    };

    const handleSetCheckedProductsId = ids => {
        setCheckedProductsId(ids);

        setIsEdited(true);
    };

    return (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={{
                        ...utils.generateFormInitialValues(settings, config),
                        selectionType: !settings.tagValueId ? 'manual' : 'auto',
                    }}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values, pristine }) => {
                        isFormChanged.current = !pristine;

                        const currentSelectedTag = tagOptions.find(tag => tag.id === values.tagValueId);

                        return (
                            <React.Fragment>
                                <Form onSubmit={handleSubmit} className="mb-3">
                                    <Row className='mb-3 w-50'>
                                        <FormFieldTranslations
                                            form={form}
                                            modalHeader={translations.inputs.name}
                                            propertyName='name'
                                            currentIso={currentLanguage}
                                            label={translations.inputs.name}
                                        />
                                    </Row>

                                    <Row className='mb-3'>
                                        <span className='mr-3 font-weight-bolder'>{translations.inputs.enabled}</span>
                                        <Field name='enabled' type='checkbox'>
                                            {
                                                props => (
                                                    <label className="switch switch-pill switch-success m-0">
                                                        <input
                                                            type="checkbox"
                                                            name={props.input.name}
                                                            value={props.input.value}
                                                            onChange={props.input.onChange}
                                                            className="switch-input"
                                                            checked={values.enabled}
                                                        />
                                                        <span
                                                            className={clsx("switch-slider", !values.enabled &&  "bg-danger border-danger")}
                                                        ></span>
                                                    </label>
                                                )
                                            }
                                        </Field>
                                    </Row>
                                    <Row className='mb-3'>
                                        {translations.inputs.autoManualCaption}
                                    </Row>
                                    <Row>
                                        <div>
                                            <Field
                                                component='input'
                                                type='radio'
                                                value='auto'
                                                name='selectionType'
                                            />
                                            <Label className='pl-2'>{translations.inputs.auto}</Label>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div>
                                            <Field
                                                component='input'
                                                type='radio'
                                                value='manual'
                                                name='selectionType'
                                            />
                                            <Label className='pl-2'>{translations.inputs.manual}</Label>
                                        </div>
                                    </Row>
                                </Form>

                                {
                                    values.selectionType !== 'manual' &&
                                    (<div className={styles.dropdown}>
                                        <FormFieldWithDropdown
                                            label={translations.inputs.tag}
                                            name='tagValueId'
                                            options={tagOptions}
                                            currentSelectionLabel={currentSelectedTag ? currentSelectedTag.label : translations.inputs.tagFallback}
                                            isDisabled={values.selectionType === 'auto' ? false : true}
                                        />
                                    </div>)
                                }

                                {
                                    values.selectionType === 'manual' &&
                                    (<TabProductAssignerManual
                                        isDisabled={values.selectionType === 'manual' ? false : true}
                                        translations={translations}
                                        currentLanguage={currentLanguage}
                                        checkedProductsId={checkedProductsId}
                                        setCheckedProductsId={handleSetCheckedProductsId}
                                    />)
                                }

                                <SectionAsideButtons
                                    buttons={{
                                        add: {
                                            color: 'success',
                                            label: translations.actions.save,
                                            action: handleSubmit,
                                            iconClass: 'fa fa-save mr-2',
                                        }
                                    }}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </div>
        </Container>
    );
};

TabProductsAssigner.propTypes = propTypes;

export default TabProductsAssigner;