import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getCustomPageBasicData } from '../../redux/methods/customPages';
import { selectStoreCurrentLanguage } from '../../redux/selectors/storeLocale';

import { getTranslationForIso } from '../../utils/shared';
import useFetch from '../../hooks/useFetch';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';
import CustomPagesEditBasic from '../../features/customPages/editTabs/CustomPageEditBasic';
import CustomPagesEditSections from '../../features/customPages/editTabs/CustomPageEditSections';
import MetatagsSection from '../../views/extrasSettings/MetatagsSection';

const propTypes = {
    location: PropTypes.object,
    getCustomPageBasicData: PropTypes.func,
    storeCurrentLanguage: PropTypes.string,
};

const CustomPagesEdit = ({ location, getCustomPageBasicData, storeCurrentLanguage, forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.customPages.edit;
    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);
    const chosenCustompageId = query.get('customPageID');

    const [ custompageId, setCustompageId ] = useState(chosenCustompageId);

    const [ data ] = useFetch(getCustomPageBasicData, custompageId, [custompageId], forceRefetch );

    const handleSetCustompageId = (id) => {
        setCustompageId(data.custompageId = id);
    };

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'customPageEditTab-basic':
                return <CustomPagesEditBasic data={data} handleCustompageSaved={handleSetCustompageId}/>;
            case 'customPageEditTab-sections':
                return <CustomPagesEditSections id={custompageId}/>;
            case "customPageEditTab-metaTags":
                return <MetatagsSection forcedTag="CustomPage" forcedPageId={custompageId} />;
            default:
                return null;
        }
    };

    return custompageId
        ? (
            data ? (
                <SettingsTabulator
                    title={{
                        label: getTranslationForIso(data.name, storeCurrentLanguage) || translations.fallbackName,
                        iconClassName: 'fa fa-edit text-primary ml-3',
                        isFilled: getTranslationForIso(data.name, storeCurrentLanguage) ? false : true,
                    }}
                    tabulatorHeader={{
                        label: translations.tabSelectorTitle,
                        iconClassName: 'fa fa-align-justify'
                    }}
                    refreshTabComponent={refreshTabComponent}
                    tabs={[
                        {
                            id: 'customPageEditTab-basic',
                            header:  {
                                label: translations.tabs.basicData.title,
                                iconClassName: 'fa fa-tasks'
                            }
                        },
                        custompageId > 0 ? {
                            id: 'customPageEditTab-sections',
                            header:  {
                                label: translations.tabs.sections.title,
                                iconClassName: 'fa fa-desktop'
                            }
                        } : null,
                        custompageId > 0 ?
                        {
                            id: "customPageEditTab-metaTags",
                            header:  {
                                label: translations.tabs.metatags.title,
                                iconClassName: "fa fa-code"
                            }
                        } : null,
                    ]}
                />
            ) : null
        ) : (
            <Redirect to='/customPages'/>
        );
};

CustomPagesEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
    forceRefetch: state.refetchers.customPages,
});

const mapDispatch = {
    getCustomPageBasicData,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(CustomPagesEdit));