import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Field } from 'react-final-form';
import { Input, Label, Row } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }))
};

const FormFieldMultipleDropDown = ({ name, label, items }) => {

    const handleChange = (value, onChange, itemId, tab) => {
        if(value) {
            onChange([...tab, itemId]);
        } else {
            onChange(tab.filter(item => item !== itemId));
        }
    };

    return (
        <Field name={name}>
            {
                props =>
                <Row className={clsx("d-flex flex-column", styles.noMargins)}>
                        {label && (<p>{label}</p>)}
                        {
                            items.map(item =>
                                {
                                    return (
                                        <div key={`${name}--${item.id}--checkbox`} className='pl-4'>
                                            <Input
                                                id={`${name}--${item.id}--checkbox`}
                                                name={`${name}[]`}
                                                checked={props.input.value.includes(item.id)}
                                                type='checkbox'
                                                onChange={(e) => handleChange(e.target.checked, props.input.onChange, item.id, props.input.value)}
                                            />
                                            <Label for={`${name}--${item.id}--checkbox`}>
                                                {item.name}
                                            </Label>
                                        </div>
                                    );
                                }
                            )
                        }
                    </Row>
            }
        </Field>
    );
};

FormFieldMultipleDropDown.propTypes = propTypes;

export default FormFieldMultipleDropDown;