import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import { updateAttributeSettings } from '../../../../../redux/methods/attributes';
import FormFieldTranslations from '../../../../../components/formFields/FormFieldTranslations';
import CheckboxWithTooltip from '../../../../../components/CheckboxWithTooltip';
import SectionEditFormButtons from '../../../../../components/SectionEditFormButtons';

import { withFormUtils } from '../../../../../hoc/withFormUtils';
import { formUtilsHocPropTypes } from '../../../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    data: PropTypes.object,
    updateAttributeSettings: PropTypes.func
};

const SearchableDictionarySettings = ({ utils, data, updateAttributeSettings }) => {
    const intl = useIntl();
    const translations = intl.messages.attributes.edit.tabs.settings;

    const config = { translations: ['searchFieldPlaceholder'] };

    const handleOnSubmit = formData => {
        updateAttributeSettings(utils.generateFormToSubmitValues(formData, config));
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    /** This weird key is here to ensure that the Form is remounted and reinitialised when needed */
                    key={`${data.attributeId}-EditSettingsSectionForm-${utils.currentLanguage()}`}
                    initialValues={utils.generateFormInitialValues(data, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='p-3 px-0' fluid>
                                    <Row className="mb-3">
                                        <FormFieldTranslations
                                            form={form}
                                            modalHeader={translations.inputs.searchFieldPlaceholder}
                                            propertyName='searchFieldPlaceholder'
                                            currentIso={utils.currentLanguage()}
                                            label={translations.inputs.searchFieldPlaceholder}
                                        />
                                    </Row>
                                    <Row>
                                        <Field name="isExpanded" type="checkbox">
                                            {
                                                props =>
                                                    <CheckboxWithTooltip
                                                        label={translations.inputs.isExpanded}
                                                        tooltipText={translations.inputs.isExpandedTooltip}
                                                        inputProps={{ ...props.input }}
                                                    />
                                            }
                                        </Field>
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

SearchableDictionarySettings.propTypes = propTypes;

const mapDispatch = {
    updateAttributeSettings
};

export default withFormUtils(connect(null, mapDispatch)(SearchableDictionarySettings));
