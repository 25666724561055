const categorySelectModal = {
    header: 'Wybór kategorii',
    caption: 'Wybierz kategorię',
    searchLabel: 'Wyszukaj kategorię:',
    selectButtonLabel: 'Wybierz',
    cancelButtonLabel: 'Anuluj',
    buttonPlaceholder: 'Brak',
    label: 'Kategoria: ',
    dropdownSelector: {
        label: 'Widoczność kategorii',
        options: {
            all: 'Wszystkie',
            activeOnly: 'Tylko aktywne'
        }
    }
};

export default categorySelectModal;