export const notificationsModulesColorsConfig = {
    1: 'primary',
    2: 'secondary',
    3: 'info',
    4: 'success',
    5: 'danger',
    6: 'warning',
    7: 'info'
};

//15min
export const notificationRefetchTimeoutConfig = 15 * 60 * 1000;