import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal as CoreUiModal, ModalBody, ModalFooter, ModalHeader, Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';
import WarningModal from '../WarningModal';

import { coreUiColorsPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    isOpen: PropTypes.bool,
    header: PropTypes.string,
    buttons: PropTypes.shape({
        confirm: PropTypes.shape({
            color: coreUiColorsPropTypes,
            label: PropTypes.string,
            disabled: PropTypes.bool
        }).isRequired,
        cancel: PropTypes.shape({
            color: coreUiColorsPropTypes,
            label: PropTypes.string,
            disabled: PropTypes.bool
        }).isRequired,
    }).isRequired,
    onConfirm: PropTypes.func,
    setIsOpen: PropTypes.func,
    modalBody: PropTypes.node,
    isLoading: PropTypes.bool,
};

const ModalWithFinalForm = ({ isOpen, setIsOpen, header, onConfirm, buttons, modalBody, initialValues = {} }) => {
    //used useRef insted useState to prevent react x final-form warning described here https://github.com/final-form/react-final-form/issues/809
    const isFormChanged = useRef();
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);

    const handleOnSubmit = async formData => await onConfirm(formData);

    const handleToggle = () => {
        if (isFormChanged.current) {
            setIsWarningModalOpen(true);
        }
        else {
            setIsOpen(!isOpen);
        }
    };

    return (
        <CoreUiModal
            isOpen={isOpen}
            toggle={handleToggle}
        >
            <ModalHeader toggle={handleToggle}>{header}</ModalHeader>
            <div className={styles.container}>
                <FinalForm
                    initialValues={initialValues}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, pristine }) => {
                        isFormChanged.current = !pristine;

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='px-4 py-1'>
                                    <ModalBody>
                                        {modalBody}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button disabled={buttons.confirm.disabled} color={buttons.confirm.color} type='submit'>{buttons.confirm.label}</Button>
                                        <Button disabled={buttons.cancel.disabled} color={buttons.cancel.color} onClick={handleToggle}>{buttons.cancel.label}</Button>
                                    </ModalFooter>
                                </Container>
                                <WarningModal
                                    setIsOpen={setIsWarningModalOpen}
                                    isOpen={isWarningModalOpen}
                                    setIsMainModalOpen={setIsOpen}
                                />
                            </Form>
                        );
                    }}
                />
            </div>
        </CoreUiModal>
    );
};

ModalWithFinalForm.propTypes = propTypes;

export default ModalWithFinalForm;