import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const DynamicErrorModal = ({ error }) => {
    const intl = useIntl();
    const translations = intl.messages.defaultLayout.appGlobal.errorModal;

    const [ isOpen, setIsOpen ] = useState(true);

    const handleCloseErrorModal = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={handleCloseErrorModal}
            className="modal-danger">
            <ModalHeader toggle={handleCloseErrorModal}>{translations.header}</ModalHeader>
            <ModalBody>
                {error}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={handleCloseErrorModal}>{translations.close}</Button>
            </ModalFooter>
        </Modal>
    );
};

export default DynamicErrorModal;