import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { generateStatusesConfig } from '../../../utils/emails';

import { emailIsEditedEnum } from '../../../enums/emails';

import TableGenerator from '../../generators/TableGenerator';

import { stringOrNumberPropTypes } from '../../../propTypes';

const propTypes = {
    history: PropTypes.object,
    templates: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            isChanged: PropTypes.bool,
            id: stringOrNumberPropTypes
        })
    ),
    translations: PropTypes.shape({
        tooltips: PropTypes.shape({
            edit: PropTypes.string
        }),
        emptyTable: PropTypes.string,
        nameFiller: PropTypes.string,
        headerLabels: PropTypes.shape({
            index: PropTypes.string,
            name: PropTypes.string,
            edited: PropTypes.string,
            actions: PropTypes.string,
        }).isRequired,
    }),
    editClickAction: PropTypes.func,
};

const EmailTemplatesTable = ({ history, templates, translations, editClickAction }) => {
    const editActionDefault = (itemId) => () => {
        editClickAction(itemId);
    };

    const editAction = id => () => {
        editClickAction(id);
    };

    const { headerLabels } = translations;

    const tableDefinitionsAction = {
        tag: 'tableStyleDefinitions',
        icon: 'fa fa-table fa-lg',
        tooltip: translations.tableDefinitionsActionTooltip,
        disabled: (item) => !item.hasTables,
        execute: (item) => { history.push(`/emailSettings/templates/tables?templateId=${item.id}`); }
    };

    return (
        <TableGenerator
            items={templates}
            expectedActions={[tableDefinitionsAction, 'edit']}
            actions={{editAction}}
            defaultAction={editActionDefault}
            translations={translations}
            columns={[
                {
                    header: headerLabels.index,
                    type: 'index'
                },
                {
                    header: headerLabels.name,
                    value: 'name',
                    filler: translations.nameFiller
                },
                {
                    header: headerLabels.account,
                    value: 'account',
                    filler: translations.accountFiller
                },
                {
                    header: headerLabels.edited,
                    value: 'isChanged',
                    type: 'badge',
                    config: generateStatusesConfig(emailIsEditedEnum, translations.isEdited)
                },
                {
                    header: headerLabels.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

EmailTemplatesTable.propTypes = propTypes;

export default withRouter(EmailTemplatesTable);