import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'reactstrap';

import FormFieldWitCheckbox from '../../../../components/formFields/FormFieldWithCheckbox';

const propTypes = {
    label: PropTypes.string,
    settingsLabels: PropTypes.shape({
        displayUnavailableVariants: PropTypes.string,
        displayMinStateInfo: PropTypes.string,
    }),
    values: PropTypes.object,
    isConnectedToERP: PropTypes.bool,
};

const VariantSettingsSelection = ({
    label,
    settingsLabels,
    values,
    isConnectedToERP
}) => {

    return (
        <React.Fragment>
            <span className='mb-3'><strong>{label}</strong></span>
            <Row className='p-0 m-0 my-2'>
                <FormFieldWitCheckbox
                    name='settings.displayUnavailableVariants'
                    label={settingsLabels.dispplayUnavailableVariants}
                    isDisabled={values.variantType === 'color' || values.variantType === 'Color'}
                />
            </Row>
            <Row className='p-0 m-0 my-2'>
                <FormFieldWitCheckbox
                    name='settings.displayMinStateInfo'
                    label={settingsLabels.displayMinStateInfo}
                    isDisabled={!isConnectedToERP}
                />
            </Row>
        </React.Fragment>
    );
};

VariantSettingsSelection.propTypes = propTypes;

export default VariantSettingsSelection;