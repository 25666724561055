import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getPredefinedSearchBasic, addEditPredefinedSearch } from '../../redux/methods/predefinedSearch';
import { getCategoryTree } from '../../redux/methods/categories';
import { getFilters } from '../../redux/methods/attributes';
import { selectTranslatedFlattenedCategoryTree } from '../../redux/selectors/categories';

import useFetch from '../../hooks/useFetch';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';

import MetatagsSection from '../../views/extrasSettings/MetatagsSection';
import PredefinedSearchEditBasic from '../../features/predefinedSearch/editTabs/PredefinedSearchEditBasic';

const propTypes = {
    location: PropTypes.object,
    getPredefinedSearchBasic: PropTypes.func,
};

const PredefineSearchEdit = ({
    location,
    forceRefetch,
    getPredefinedSearchBasic,
    categoryTree,
    getFilters,
    getCategoryTree,
    addEditPredefinedSearch,
    history,
    flattenedCategoryTree
}) => {
    const intl = useIntl();
    const translations = intl.messages.predefinedSearch.edit;

    const query = new URLSearchParams(location.search);
    const predefinedSearchId = query.get('predefinedSearchID');

    const [ predefinedSearchBasics ] = useFetch(getPredefinedSearchBasic, predefinedSearchId, [], forceRefetch);
    const [ filters ] = useFetch(getFilters);

    useEffect(() => {
        if(!categoryTree || categoryTree.length < 1) {
            getCategoryTree();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'predefineSearchEditTab-basic':
                return <PredefinedSearchEditBasic
                    predefinedSearchBasicData={predefinedSearchBasics}
                    translations={translations.basic}
                    filters={filters}
                    flattenedCategoryTree={flattenedCategoryTree}
                    onSubmit={addEditPredefinedSearch}
                    history={history}
                />;
            case 'predefineSearchEditTab-metaTags':
                return <MetatagsSection forcedTag="PredefinedSearch" forcedPageId={predefinedSearchId} />;
            default:
                return null;
        }
    };

    return predefinedSearchId ? (
        predefinedSearchBasics && filters && categoryTree ? (
            <SettingsTabulator
                title={{
                    label: predefinedSearchBasics.name || translations.fallbackName,
                    iconClassName: 'fa fa-edit text-primary ml-3',
                    isFilled: predefinedSearchBasics.name ? false : true,
                }}
                tabulatorHeader={{
                    label: translations.tabSelectorTitle,
                    iconClassName: 'fa fa-search'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: 'predefineSearchEditTab-basic',
                        header:  {
                            label: translations.tabs.basic,
                            iconClassName: 'fa fa-tasks'
                        }
                    },
                    predefinedSearchId > -1 ? {
                        id: 'predefineSearchEditTab-metaTags',
                        header:  {
                            label: translations.tabs.metaTags,
                            iconClassName: 'fa fa-code'
                        }
                    } : null,
                ]}
            />
        ) : null
    ) : (
        <Redirect to='/predefinedSearch'/>
    );
};

PredefineSearchEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.predefinedSearch,
    categoryTree: state.categories.categoryTree,
    flattenedCategoryTree: selectTranslatedFlattenedCategoryTree(state),
});

const mapDispatch = {
    addEditPredefinedSearch,
    getPredefinedSearchBasic,
    getFilters,
    getCategoryTree
};

export default withRouter(connect(mapStateToProps, mapDispatch)(PredefineSearchEdit));