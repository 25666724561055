import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDiscountUsages } from '../../../../redux/methods/discounts';
import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';
import useFetch from '../../../../hooks/useFetch';
import useSearchConfig from '../../../../hooks/useSearchConfig';

import { Container } from 'reactstrap';

import CodesUsagesTable from '../../../../components/tables/CodesUsagesTable';
import Paginator from '../../../../components/Paginator';
import CodeUsagesSearchForm from '../../../../components/CodeUsagesSearchForm';

import { stringOrNumberPropTypes } from '../../../../propTypes';
import { discountUsagesSearchConfig } from '../../../../config/searchConfigs';

const propTypes = {
    id: stringOrNumberPropTypes,
    getDiscountUsages: PropTypes.func,
    storeCurrentLanguage: PropTypes.string,
};

const DiscountEditUsages = ({ id, getDiscountUsages, storeCurrentLanguage }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.usages;

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig({ ...discountUsagesSearchConfig, discountId: id });

    const [ usages, pages ] = useFetch(getDiscountUsages, searchConfig, [searchConfig]);

    return usages ? (
        <React.Fragment>
            <Container fluid>
                <CodeUsagesSearchForm searchConfig={searchConfig} performSearch={changeSearchConfig} storeCurrentLanguage={storeCurrentLanguage}/>
                <CodesUsagesTable
                    usages={usages}
                    emptyTableFiller={translations.emptyTableFiller}
                    headerLabels={translations.headerLabels}
                />
                {
                    pages > 1 && <Paginator
                            currentPage={searchConfig.curentPage + 1}
                            totalPages={pages}
                            onPageChange={changePage}
                        />
                }
            </Container>
        </React.Fragment>
    ) : null;
};

DiscountEditUsages.propTypes = propTypes;

const mapStateToProps = (state) => ({
    id: state.discounts.discountToEdit.data.discountId,
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    getDiscountUsages,
};

export default connect(mapStateToProps, mapDispatch)(DiscountEditUsages);