const notFound = {
    title: '[EN] Strona 404',
    button: '[EN] ZAPISZ ZMIANY',
    labels: {
        button: '[EN] Tekst na przycisku',
        content: '[EN] Tekst nad przyciskiem',
        mainColor: '[EN] Główny kolor',
        mainColorButton: '[EN] Wybierz kolor',
    },
    fileSender: {
        label: 'Obrazek',
        buttons: {
            edit: '[EN] PODMIEŃ',
            remove: '[EN] USUŃ',
            upload: '[EN] WGRAJ'
        },
    },
};

export default notFound;