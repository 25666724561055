import React from 'react';

import ViewTemplate from '../../features/auth/sharedItems/ViewTemplate';
import ResetPasswordForm from '../../features/auth/ResetPasswordForm';

const ResetPassword = () => {
    return (
        <ViewTemplate target="resetPassword">
            <ResetPasswordForm />
        </ViewTemplate>
    );
};

export default ResetPassword;