import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import ContainerWithCard from '../../../components/ContainerWithCard';

import { firstLetterToLowerCase } from '../../../utils/components';

import { sectionTypes } from '../../../config';

import HomePageSectionTable from '../../../features/homePage/HomePageSectionTable';
import HomePageFooterButtons from '../../../features/homePage/HomePageFooterButtons';
import HomePageCheckTypeModal from '../../../features/homePage/HomePageCheckTypeModal';
import { sectionAffiliationEnum } from '../../../enums/sections';
import clsx from 'clsx';

const propTypes = {
    sectionLocation: PropTypes.oneOf(['mainPage', 'productCard', 'customPage', 'registrationSummary', 'registrationConfirmation', 'cart']),
    affiliationObjectId : PropTypes.number
};

const SectionsList = ({ sectionLocation, affiliationObjectId = null }) => {

    const [ device, setDevice ] = useState('Desktop');

    const intl = useIntl();
    const translations = intl.messages.homePage.sections;

    const activeSectionTypes = sectionTypes.map(section => ({
        ...section,
        label: translations.sections[firstLetterToLowerCase(section.label)]
    }));

    const [isOpen, setIsOpen] = useState(false);
    const [currentSelectedTypeId, setCurrentSelectedTypeId] = useState(activeSectionTypes[0].id);

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleChangeDevice = (currentDevice) => {
        setDevice(currentDevice);
    };

    const title = device === 'Desktop' ? translations.titles.desktop : (device === 'Tablet' ? translations.titles.tablet : translations.titles.mobile);
    const iconClass = device === 'Desktop' ? 'fa fa-desktop' : (device === 'Tablet' ? 'fa fa-tablet' : 'fa fa-mobile');

    return (
        <React.Fragment>
            <div className="my-3 mx-3 px-3">
                <label><strong>{translations.sortButtons.caption}</strong></label>
                <button className={clsx("btn mx-2", device === 'Desktop' ? "btn-primary" : "btn-secondary")} onClick={() => { handleChangeDevice('Desktop'); }}>{translations.sortButtons.desktop}</button>
                <button className={clsx("btn mx-2", device === 'Tablet' ? "btn-primary" : "btn-secondary")} onClick={() => { handleChangeDevice('Tablet'); }}>{translations.sortButtons.tablet}</button>
                <button className={clsx("btn mx-2", device === 'Mobile' ? "btn-primary" : "btn-secondary")} onClick={() => { handleChangeDevice('Mobile'); }}>{translations.sortButtons.mobile}</button>
            </div>
            <ContainerWithCard title={title} iconClass={iconClass}>
                <HomePageSectionTable
                    device={device}
                    sectionTranslation={translations.sections}
                    sectionLocation={sectionLocation}
                    affiliation={sectionAffiliationEnum[sectionLocation]}
                    affiliationObjectId={affiliationObjectId}
                />
                <HomePageFooterButtons addTranslation={translations.actions.add} handleOpenModal={handleOpenModal}/>
                <HomePageCheckTypeModal
                    activeSectionTypes={activeSectionTypes}
                    currentSelectedTypeId={currentSelectedTypeId}
                    isOpen={isOpen}
                    setCurrentSelectedTypeId={setCurrentSelectedTypeId}
                    setIsOpen={setIsOpen}
                    translations={translations}
                    sectionLocation={sectionLocation}
                    affiliationObjectId={affiliationObjectId}
                />
            </ContainerWithCard>
        </React.Fragment>
    );
};

SectionsList.propTypes = propTypes;

export default SectionsList;