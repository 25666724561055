import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getLoginAndRegistrationSettings, updateLoginAndRegistrationSettings } from '../../../redux/methods/extrasSettings';

import useFetch from '../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Row, Form } from 'reactstrap';

import SectionAsideButtons from '../../../components/SectionAsideButtons';
import FormFieldTranslationsQuill from '../../../components/formFields/FormFieldTranslationsQuill';
import ContainerWithCard from '../../../components/ContainerWithCard';
import FormFieldColorPicker from '../../../components/formFields/FormFieldColorPicker';

import { withFormUtils } from '../../../hoc/withFormUtils';

import { formUtilsHocPropTypes } from '../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getLoginSettings: PropTypes.func,
    updateLoginSettings: PropTypes.func,
};

const LoginSettings = ({ utils, getLoginAndRegistrationSettings, updateLoginAndRegistrationSettings }) => {
    const intl = useIntl();
    const translations = intl.messages.loginSettings;

    const [ loginSettingsData ] = useFetch(getLoginAndRegistrationSettings);

    const config = { translations: ['content'] };

    const handleOnSubmit = formData => {
        updateLoginAndRegistrationSettings(utils.generateFormToSubmitValues(formData, config));
    };

    return loginSettingsData ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search'>
            <FinalForm
                onSubmit={handleOnSubmit}
                initialValues={utils.generateFormInitialValues(loginSettingsData, config)}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row className='my-3'>
                                <FormFieldColorPicker
                                    name="background"
                                    label={translations.labels.backgroundColor}
                                    button={translations.buttons.select}
                                />
                            </Row>
                            <Row className='my-3'>
                                <FormFieldColorPicker
                                    name="fontColor"
                                    label={translations.labels.fontColor}
                                    button={translations.buttons.select}
                                />
                            </Row>
                            <Row className='mt-3'>
                                <FormFieldTranslationsQuill
                                    form={form}
                                    utils={utils}
                                    propertyName='content'
                                    header={translations.labels.content}
                                />
                            </Row>
                            <SectionAsideButtons
                                buttons={{
                                    add: {
                                        color: 'success',
                                        label: translations.buttons.save,
                                        action: handleSubmit,
                                        iconClass: 'fa fa-save mr-2',
                                    }
                                }}
                            />
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

LoginSettings.propTypes = propTypes;

const mapDispatch = {
    getLoginAndRegistrationSettings,
    updateLoginAndRegistrationSettings,
};

export default withFormUtils(connect(null, mapDispatch)(LoginSettings));