const units = {
    title: 'Jednostki i miary',
    titleSettings: 'Ustawienia',
    buttons: {
        add: 'Dodaj jednostkę',
        confirm: 'Zapisz',
        cancel: 'Anuluj'
    },
    validationAlert: 'Uzupełnij nazwę oraz skrót jednostki aby zapisać.',
    labels: {
        name: 'Nazwa',
        shortName: 'Skrót',
        isDecimal: {
            label: 'Podzielna',
            decimal: 'Podzielna',
            integer: 'Niepodzielna'
        },
        mainUnit: 'Jednotka odniesienia',
        converter: 'Przelicznik',
        actions: 'Akcje',
    },
    modalHeaderEdit: 'Edycja jednostki',
    modalHeaderCreate: 'Dodawanie jednostki',
    tooltips: {
        edit: 'Edytuj',
        remove: 'Usuń',
    },
    emptyTable: 'Nie znaleziono jednostek',
    deleteModal: {
        header: 'Usuwanie jednostki',
        body: 'Czy napewno chcesz usunąć jednostkę?',
        cancel: 'WRÓĆ',
        confirm: 'USUŃ'
    },
};

export default units;