import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

const propTypes = {
    className: PropTypes.string,
    customCell: PropTypes.func,
    item: PropTypes.object,
    additionalStyle: PropTypes.string
};

const CustomCell = ({
    className,
    item,
    customCell,
    additionalStyle
}) => {
    return (
        <td className={clsx(className, additionalStyle)}>
            {
                customCell(item)
            }
        </td>
    );
};

CustomCell.propTypes = propTypes;

export default CustomCell;

