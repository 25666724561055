const users = {
    assigner: {
        header: 'Przypisywanie użytkownikó',
        buttons: {
            cancel: {
                label: 'Anuluj'
            },
            confirm: {
                label: 'Przypisz',
            },
        },
        toAssign: {
            header:{
                title: 'Użytkownicy do wybrania',
                text: 'Kliknij aby przenieść użytkownika na drugą liste',
            },
            searchPlaceholder: 'Wyszukaj...',
            tooltip: 'Kliknij aby przenieść użytkownika na drugą liste',
        },
        assigned: {
            header: {
                title: 'Użytkownicy do przypisania',
                text: 'Kliknij aby przenieść użytkownika na pierwszą liste',
            },
            searchPlaceholder: 'Wyszukaj...',
            tooltip: 'Kliknij aby przenieść użytkownika na pierwszą liste',
        },
    }
};

export default users;