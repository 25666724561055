import Currencies from '../../views/Currencies';
import ExchangeRates from '../../views/ExchangeRates';
import Countries from '../../views/Countries';
import Regions from '../../views/Regions';
import RegionsEdit from '../../views/RegionsEdit';
import CurrenciesEdit from '../../views/CurrenciesEdit';
import ExchangeRatesEdit from '../../views/ExchangeRateEdit';
import Translations from '../../views/Translations';
import Languages from '../../views/Languages';

export const languagesAndCountriesRoutes = {
    ROUTE_LANGUAGES_AND_COUNTRIES_LANGUAGES: {
        id: 'ROUTE_LANGUAGES_AND_COUNTRIES_LANGUAGES',
        path: '/languages',
        component: Languages,
        icon: 'cil-language',
        type: 'languagesAndCountries',
        exact: true,
    },

    ROUTE_LANGUAGES_AND_COUNTRIES_COUNTRIES: {
        id: 'ROUTE_LANGUAGES_AND_COUNTRIES_COUNTRIES',
        path: '/countries',
        type: 'languagesAndCountries',
        exact: true,
        icon: 'cil-institution',
        component: Countries
    },

    ROUTE_LANGUAGES_AND_COUNTRIES_CURRENCIES: {
        id: 'ROUTE_LANGUAGES_AND_COUNTRIES_CURRENCIES',
        path: '/currencies',
        type: 'languagesAndCountries',
        exact: true,
        icon: 'cil-euro',
        component: Currencies
    },
    ROUTE_LANGUAGES_AND_COUNTRIES_CURRENCIES_EDIT: {
        id: 'ROUTE_LANGUAGES_AND_COUNTRIES_CURRENCIES_EDIT',
        path: '/currencies/edit',
        component: CurrenciesEdit
    },

    ROUTE_LANGUAGES_AND_COUNTRIES_EXCHANGERATES: {
        id: 'ROUTE_LANGUAGES_AND_COUNTRIES_EXCHANGERATES',
        path: '/exchangeRates',
        type: 'languagesAndCountries',
        exact: true,
        icon: 'cil-balance-scale',
        component: ExchangeRates
    },
    ROUTE_LANGUAGES_AND_COUNTRIES_EXCHANGERATES_EDIT: {
        id: 'ROUTE_LANGUAGES_AND_COUNTRIES_EXCHANGERATES_EDIT',
        path: '/exchangeRates/edit',
        component: ExchangeRatesEdit,
    },

    ROUTE_LANGUAGES_AND_COUNTRIES_REGIONS: {
        id: 'ROUTE_LANGUAGES_AND_COUNTRIES_REGIONS',
        path: '/regions',
        type: 'languagesAndCountries',
        exact: true,
        icon: 'icon-globe',
        component: Regions
    },
    ROUTE_LANGUAGES_AND_COUNTRIES_REGIONS_EDIT: {
        id: 'ROUTE_LANGUAGES_AND_COUNTRIES_REGIONS_EDIT',
        path: '/regions/edit',
        component: RegionsEdit
    },

    ROUTE_LANGUAGES_AND_COUNTRIES_TRANSLATIONS: {
        id: 'ROUTE_LANGUAGES_AND_COUNTRIES_TRANSLATIONS',
        path: '/translations',
        component: Translations,
        icon: 'cil-calculator',
        type: 'languagesAndCountries',
        exact: true,
    },
};