const adBanners = {
    view: {
        actions: {
            create: 'Utwórz reklamę',
        },
        title: 'Ustawienia reklam',
    },
    table: {
        headers: {
            desktopPhoto: 'Zdjęcie desktop',
            mobilePhoto: 'Zdjęcie mobile',
            link: 'Odnośnik',
            name: 'Nazwa',
            categories: 'Przypisane kategorie',
            isActive: 'Aktywność',
            activeInDates: 'Zakres aktywności',
            activeInLanguges: 'Aktywność w językach',
            actions: 'Akcje',
            activeFrom: 'Aktywne od',
            activeTo: 'Aktywne do'
        },
        tooltips: {
            remove: 'Usuń reklamę',
            edit: 'Edytuj reklamę'
        },
        actions: {
            activate: 'Aktywuj',
            deactivate: 'Deaktywuj'
        },
        emptyTable: 'Nie znaleziono reklam.',
        deleteModal: {
            header: 'Usuń reklamę',
            body: 'Czy jesteś pewien, że chcesz usunąć reklamę?',
            cancel: 'WRÓC',
            confirm: 'USUŃ',
        },
        emptyLogoAlt: 'Brak zdjęcia',
        showOnAllCategories: 'Wszytskie kategorie'
    },
    edit: {
        fallbackName: '[BRAK NAZWY]',
        tabSelectorTitle: 'Ustawienia reklam',
        tabs: {
            basic: {
                title: 'Ustawienia podstawowe',
                desktopImage: 'Zdjęcie desktop',
                mobileImage: 'Zdjęcie mobile',
                inputs: {
                    name: 'Nazwa',
                    link: 'Odnośnik',
                    active: 'Aktywność',
                    activeInLanguages: 'Aktywność w językach',
                    desktopPhoto: 'Zdjęcie desktop',
                    mobilePhoto: 'Zdjęcie mobile',
                    alternativeText: 'Tekst alternatywny',
                    dateFrom: 'Aktywne od',
                    dateTo: 'Aktywne do',
                    showOnAllCategories: 'Pokazuj we wszystkich kategoriach',
                    file: {
                        upload: 'Prześlij zdjęcie',
                        remove: 'Usuń zdjęcie',
                        edit: 'Podmień zdjęcie',
                    }
                }
            },
            categories: {
                title: 'Przypisane kategorie',
                table: {
                    columnNames: {
                        categories: 'Przypisana kategoria',
                        actions: 'Akcje',
                    },
                    tooltip: 'Odłącz kategorię',
                    placeholder: 'Nie znaleziono kategorii przypisanych do wybranej reklamy',
                    deleteModal: {
                        header: 'Odłącz kategorię',
                        bodyStart: 'Czy chcesz odłączyć kategorię',
                        bodyEnd: 'od wybranej reklamy',
                        confirm: 'ODŁĄCZ',
                        cancel: 'WRÓĆ'
                    }
                },
                controls: {
                    add: 'PRZYPISZ KATEGORIĘ',
                    remove: 'ODŁĄCZ WYBRANE KATEGORIE'
                },
                additionModal: {
                    header: 'Przypisz wybraną kategorię',
                    body: 'Czy przypisać wybraną kategorię do reklamy',
                    confirm: 'PRZYPISZ',
                    cancel: 'WRÓĆ'
                },
                deletionModal: {
                    header: 'Odłącz wybrane kategorię',
                    body: 'Czy odłączyć wybrane kategorie od reklamy',
                },
                categorySelectionModal: {
                    header: 'Wybór kategorii',
                    body: 'Wybierz kategorię, która ma być przypisana do obecnej reklamy',
                    emptySelectionLabel: 'Wybierz kategorię',
                    confirm: 'WYBIERZ',
                    cancel: 'WRÓĆ'
                },
                nameFiller: '[BRAK NAZWY]',
                targetCategoryError: 'Wybrana kategoria już jest przypisana do obecnej reklamy, wybierz inną kategorię.'
            },
        },
        inputs: {
            name: 'Nazwa',
            link: 'Odnośnik',
            active: 'Aktywność',
            activeInLanguages: 'Aktywność w językach',
            desktopPhoto: 'Zdjęcie desktop',
            mobilePhoto: 'Zdjęcie mobile',
        },
        buttons: {
            assignCategories: 'Przypisz kategorie',
            add: 'Przypisz',
            remove: 'Odłącz kategorię',
        }
    }
};

export default adBanners;