import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDiscountProducts, getDiscountCategories } from '../../../../redux/methods/discounts';
import useFetch from '../../../../hooks/useFetch';
import useSearchConfig from '../../../../hooks/useSearchConfig';
import useActiveLabel from '../../../../hooks/useActiveLabel';

import { Container } from 'reactstrap';

import SwitchWithLabels from '../../../../components/SwitchWithLabels';
import CategoriesSection from './CategoriesSection';
import ProductsSection from './ProductsSection';

import styles from './index.module.scss';
import { discountCategoriesSearchConfig, discountProductsSearchConfig } from '../../../../config/searchConfigs';
import { stringOrNumberPropTypes } from '../../../../propTypes';

const propTypes = {
    id: stringOrNumberPropTypes,
    productsForceRefetch: PropTypes.bool,
    categoriesForceRefetch: PropTypes.bool,
    getDiscountProducts: PropTypes.func,
    getDiscountCategories: PropTypes.func,
};

const DiscountEditCategoriesProducts = ({ getDiscountProducts, getDiscountCategories, id, productsForceRefetch, categoriesForceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.categoriesProducts;

    const [ productsSearchConfig, changeProductsPage, changeProductsSearchConfig ] = useSearchConfig({ ...discountProductsSearchConfig, discountId: id });
    const [ categoriesSearchConfig, changeCategoriesPage ] = useSearchConfig({ ...discountCategoriesSearchConfig, discountId: id });

    const [ products, productsPages ] = useFetch(getDiscountProducts, productsSearchConfig, [productsSearchConfig], productsForceRefetch);
    const [ categories, categoriesPages ] = useFetch(getDiscountCategories, categoriesSearchConfig, [categoriesSearchConfig], categoriesForceRefetch);

    const [ activeLabel, setActiveLabel ] = useActiveLabel(categories, products);
    const isActiveLabelLeft = activeLabel === 'left';

    const categoriesIsPresent = categories && categories.length > 0;
    const productsIsPresent = products && products.length > 0;
    const isSearchFormEmpty = productsSearchConfig && !productsSearchConfig.catalogueIndex && !productsSearchConfig.tradeIndex && !productsSearchConfig.productName;

    return (categories || products) && activeLabel ? (
        <React.Fragment>
            <Container fluid>
                <header className={styles.controls}>
                    <SwitchWithLabels
                        labels={{
                            left: translations.switch.left,
                            right: translations.switch.right,
                            main: translations.switch.main,
                        }}
                        activeLabel={activeLabel}
                        changeActiveLabel={setActiveLabel}
                        isDisabled={categoriesIsPresent || productsIsPresent || !isSearchFormEmpty}
                        className={clsx(styles.switch)}
                        tooltip={translations.switch.tooltip}
                    />
                </header>
                {categories && isActiveLabelLeft &&
                    <CategoriesSection
                        discountCategories={categories}
                        totalPages={categoriesPages}
                        currentPage={categoriesSearchConfig.curentPage}
                        changePage={changeCategoriesPage}
                    />
                }
                {products && !isActiveLabelLeft &&
                    <ProductsSection
                        productsSearchConfig={productsSearchConfig}
                        isActiveLabelLeft={isActiveLabelLeft}
                        discountsProducts={products}
                        totalPages={productsPages}
                        currentPage={productsSearchConfig.curentPage}
                        changeSearchConfig={changeProductsSearchConfig}
                        changePage={changeProductsPage}
                        categoriesIsPresent={categoriesIsPresent}
                    />
                }
            </Container>
        </React.Fragment>
    ) : null;
};

DiscountEditCategoriesProducts.propTypes = propTypes;

const mapStateToProps = (state) => ({
    id: state.discounts.discountToEdit.data.discountId,
    productsForceRefetch: state.refetchers.discountsProducts,
    categoriesForceRefetch: state.refetchers.discountsCategories,
});

const mapDispatch = {
    getDiscountProducts,
    getDiscountCategories,
};

export default connect(mapStateToProps, mapDispatch)(DiscountEditCategoriesProducts);