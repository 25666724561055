import React from 'react';
import PropTypes from 'prop-types';

import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    fonts: PropTypes.arrayOf(PropTypes.object),
    verticalAlignments: PropTypes.arrayOf(PropTypes.object),
    horizontalAlignments: PropTypes.arrayOf(PropTypes.object),
    handleHeaderChange: PropTypes.func
};

const EmailTemplatesTablesHeaderStyleTable = ({ items, fonts, verticalAlignments, horizontalAlignments, translations, handleHeaderChange }) => {
    return (
        <TableGenerator
            items={items}
            translations={translations}
            idSelector='id'
            columns={[
                {
                    header: translations.tableHeaders.font,
                    type: 'dropDown',
                    value: 'font',
                    dictionary: fonts,
                    onChange: handleHeaderChange,
                },
                {
                    header: translations.tableHeaders.fontColor,
                    type: 'input',
                    inputType: 'color',
                    value: 'fontColor',
                    onChange: handleHeaderChange,
                },
                {
                    header: translations.tableHeaders.fontSize,
                    type: 'input',
                    inputType: 'number',
                    value: 'fontSize',
                    inputTextAlignment: 'text-right',
                    onChange: handleHeaderChange,
                },
                {
                    header: translations.tableHeaders.backgroundColor,
                    type: 'input',
                    inputType: 'color',
                    value: 'backgroundColor',
                    onChange: handleHeaderChange,
                },
                {
                    header: translations.tableHeaders.verticalAlignment,
                    type: 'dropDown',
                    value: 'verticalAlignment',
                    dictionary: verticalAlignments,
                    onChange: handleHeaderChange,
                },
                {
                    header: translations.tableHeaders.horizontalAlignment,
                    type: 'dropDown',
                    value: 'horizontalAlignment',
                    dictionary: horizontalAlignments,
                    onChange: handleHeaderChange,
                },
                {
                    header: translations.tableHeaders.isBold,
                    type: 'switchWithAction',
                    value: 'isBold',
                    onSwitch: handleHeaderChange
                },
                {
                    header: translations.tableHeaders.isItalic,
                    type: 'switchWithAction',
                    value: 'isItalic',
                    onSwitch: handleHeaderChange
                },
                {
                    header: translations.tableHeaders.isUnderline,
                    type: 'switchWithAction',
                    value: 'isUnderline',
                    onSwitch: handleHeaderChange
                }
            ]}
        />
    );
};

EmailTemplatesTablesHeaderStyleTable.propTypes = propTypes;

export default EmailTemplatesTablesHeaderStyleTable;