const attributes = {
    view: {
        title: 'Attributes list',
        paginator: {
            previous: 'Previous',
            next: 'Next'
        },
        actions: {
            addAttribute: 'ADD FEATURE'
        },
        additionModal: {
            header: 'Add new attribute',
            body: 'Are you sure you want to create new attribute?',
            confirm: 'ADD',
            cancel: 'CANCEL'
        }
    },
    searchForm: {
        phrase: {
            label: 'Attribute name:',
            placeholder: ''
        },
        type: {
            label: 'Attribute type:',
            placeholder: 'any'
        },
        presentation: {
            label: 'Presentation:',
            placeholder: 'any'
        },
        filters: {
            attributeType: {
                placeholder: 'any',
                tag: 'Tag',
                dictionary: 'Dictionary',
                int: 'Number range',
                decimal: 'Decimal range',
                dateTime: 'Date range',
                color: 'Color',
                boolean: 'Warunek logiczny',
            },
            attributePresentation: {
                placeholder: 'any',
                tag: 'Chmura tagów',
                color: 'Color',
                simpleDictionary: 'Simple dictionary',
                searchableDictionary: 'Searchable dictionary',
                range: 'Range',
                boolean: 'Checkbox',
            }
        },
        action: 'SEARCH',
        reset: 'RESET',
    },
    table: {
        headers: {
            name: 'Attribute name',
            type: 'Attribute type',
            presentation: 'Presentation',
            actions: 'Actions'
        },
        deleteModal: {
            header: 'Attribute:',
            body: 'Are you sure you want to remove attribute',
            confirm: 'REMOVE',
            cancel: 'CANCEL'
        },
        tooltips: {
            edit: 'Edit attribute',
            remove: 'Remove attribute',
            add: 'ADD ATTRIBUTE'
        },
        empty: 'No attributes matching provided search criteria have been found.',
        nameFiller: '[NO NAME]',
        errorModalText: 'Error occured while trying to remove attribute'
    },
    edit: {
        nameFiller: '[NO NAME]',
        tabSelectorTitle: 'Sections',
        tabs: {
            basic: {
                title: 'Basic data',
                inputs: {
                    name: 'Attribute name',
                    nameExtension: 'Attribute name extension',
                    type: 'Attribute type',
                    presentationType: 'Attribute presentation',
                    isRequired: 'Is required',
                    isRequiredTooltip: 'The value for this attribute needs to be set for all the products in the product catalogue',
                    isFilter: 'Is a filter',
                    isFilterTooltip: 'This attribute will be exposed in the store as a product search filter',
                    isMultiselect: 'Is multiselectable',
                    isMultiselectTooltip: 'Each product can have multiple values of this attribute assigned to itself'
                },
                editModalHeader: 'Attribute name',
                waproMagDropdown: {
                    label: '[EN] Dostępne pola dodatkowe',
                    defaultSelection: '[EN] Dowolne'
                },
                status: '[EN] Status:',
                statusLabels: {
                    editing: "[EN] Nieaktywny",
                    published: "[EN] Aktywny"
                }
            },
            values: {
                title: 'Values',
                colors: {
                    columnNames: {
                        color: 'Color',
                        name: 'Name',
                        actions: 'Actions',
                    },
                    actions: {
                        edit: 'Edit color',
                        remove: 'Remove color',
                        addColor: 'ADD COLOR'
                    },
                    deleteModal: {
                        header: 'Color:',
                        body: 'Are you sure you want to remove color',
                        confirm: 'REMOVE',
                        cancel: 'CANCEL'
                    },
                    additionModal: {
                        header: 'Add new color',
                        body: 'Are you sure you want to create new color?',
                        confirm: 'ADD',
                        cancel: 'CANCEL'
                    },
                    empty: {
                        table: 'No colors found for selected attribute',
                        name: '[NO NAME]'
                    }
                },
                dictionaryValues: {
                    columnNames: {
                        name: 'Name',
                        actions: 'Actions',
                        backgroundColor: '[EN] Kolor tła',
                        textColor: '[EN] Kolor tekstu',
                    },
                    actions: {
                        edit: 'Edit value',
                        remove: 'Remove value',
                        addColor: 'ADD Value'
                    },
                    deleteModal: {
                        header: 'Value:',
                        body: 'Are you sure you want to remove value',
                        confirm: 'REMOVE',
                        cancel: 'CANCEL'
                    },
                    additionModal: {
                        header: 'Add new value',
                        body: 'Are you sure you want to create new value?',
                        confirm: 'ADD',
                        cancel: 'CANCEL'
                    },
                    empty: {
                        table: 'No values found for selected attribute',
                        name: '[NO NAME]'
                    },
                    inputs: {
                        name: 'Value name'
                    }
                },
                dateRange: {
                    startDateLabel: 'Minimum date',
                    endDateLabel: 'Maximum date'
                },
                range: {
                    minLabel: 'Lower limit',
                    maxLabel: 'Upper limit',
                    precisionLabel: 'Precision'
                }
            },
            settings: {
                title: 'Settings',
                inputs: {
                    isExpanded: 'Is permanently extended',
                    isExpandedTooltip: 'Should a filter rendered on the store search page be permanently extended?',
                    foldedDictionaryItemsVisible: 'Number of unfolded filter values',
                    searchFieldPlaceholder: 'Search phrase placeholder',
                    minDateLabel: 'Minimum date',
                    maxDateLabel: 'Maximum date',
                    minNumberLabel: 'Lower limit',
                    maxNumberLabel: 'Upper limit',
                    precisionLabel: 'Precision',
                    startDateLabel: 'Minimum date',
                    endDateLabel: 'Maximum date'
                },
                empty: 'No settings can be changed for current attribute type',
                editModalHeader: 'Search phrase placeholders'
            }
        }
    }
};

export default attributes;