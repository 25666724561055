import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Button } from 'reactstrap';
import ColorPicker from '../../ColorPicker';

import styles from './index.module.scss';

const propTypes = {
    translations: PropTypes.shape({
        pickButton: PropTypes.string,
        name: PropTypes.string,
    }),
    isDisabled: PropTypes.bool,
    color: PropTypes.string,
    onAdd: PropTypes.func,
    name: PropTypes.string,
};

const LayoutColorEditionController = ({
    translations,
    isDisabled,
    color,
    onAdd,
    tag,
    name,
    isRestoreDefaultColorsRequired,
}) => {
    const [ isColorPickerVisible, setIsColorPickerVisible ] = useState(false);
    const [ colorToDisplay, setColorToDisplay ] = useState(color);

    const handleShowColorPicker = () => {
        setIsColorPickerVisible(true);
    };

    const handleHideColorPicker = () => {
        setIsColorPickerVisible(false);
    };

    const handleAddColor = color => {
        setColorToDisplay(color);

        onAdd(color, tag);
    };

    useEffect(() => {
        setColorToDisplay(color);
    }, [color, isRestoreDefaultColorsRequired]);

    return (
        <div>
            <span>{name}</span>
            <div className={styles.buttonContainer}>
                <div
                    style={{ background: colorToDisplay ? colorToDisplay : null}}
                    className={clsx(styles.preview, !colorToDisplay && styles.previewEmpty)}
                    title={colorToDisplay ? colorToDisplay : null}
                />
                <span>
                    <Button disabled={isDisabled} color="primary" type="button" onClick={handleShowColorPicker}>
                        <i className="fa fa-plus mr-2"/>
                        <span>{translations.pickButton}</span>
                    </Button>
                    {
                        isColorPickerVisible &&
                            <ColorPicker color={colorToDisplay} onAdd={handleAddColor} onClose={handleHideColorPicker}/>
                    }
                </span>
            </div>
        </div>
    );
};

LayoutColorEditionController.propTypes = propTypes;

export default LayoutColorEditionController;