import CustomPages from '../../views/CustomPages';
// import Popups from '../../views/Popups';
import CustomPagesEdit from '../../views/CustomPagesEdit';
// import PopupsEdit from '../../views/PopupsEdit';
import PredefinedSearch from '../../views/PredefinedSearch';
import PredefinedSearchEdit from '../../views/PredefinedSearchEdit';
import SectionsEdit from '../../views/homePageSettings/SectionsEdit';
import MenuPositions from '../../views/menuSettings/MenuPositions';
import FooterSettings from '../../views/menuSettings/FooterSettings';
import MenuEdit from '../../views/menuSettings/MenuEdit';

export const menuRoutes = {
    // Menu
    ROUTE_MENU_POSITIONS: {
        id: 'ROUTE_MENU_POSITIONS',
        path: '/menu/menuPositions',
        type: 'menu',
        exact: true,
        component: MenuPositions,
        icon: 'cil-clear-all'
    },
    ROUTE_MENU_CUSTOM_PAGES: {
        id: 'ROUTE_MENU_CUSTOM_PAGES',
        path: '/customPages',
        type: 'menu',
        exact: true,
        icon: 'fal fa-layer-plus',
        component: CustomPages
    },
    // ROUTE_MENU_POPUPS: {
    //     id: 'ROUTE_MENU_POPUPS',
    //     path: '/popups',
    //     type: 'menu',
    //     exact: true,
    //     icon: 'fal fa-window-maximize',
    //     component: Popups
    // },
    ROUTE_MENU_PREDEFINED_SEARCH: {
        id: 'ROUTE_MENU_PREDEFINED_SEARCH',
        path: '/predefinedSearch',
        type: 'menu',
        exact: true,
        icon: 'fal fa-search-plus',
        component: PredefinedSearch
    },
    /*
    ROUTE_MENU_SECTIONS: {
        id: 'ROUTE_MENU_SECTIONS',
        path: '/menu/sections',
        type: 'menu',
        exact: true,
        component: null,
        icon: 'fal fa-rectangle-wide'
    },
    */
    ROUTE_MENU_FOOTER: {
        id: 'ROUTE_MENU_FOOTER',
        path: '/menu/footerSettings',
        type: 'menu',
        exact: true,
        component: FooterSettings,
        icon: 'fal fa-sort-size-down-alt'
    },

    ROUTE_MENU_POSITIONS_EDIT: {
        id: 'ROUTE_MENU_POSITIONS_EDIT',
        path: '/menu/menuPositions/edit',
        component: MenuEdit,
    },

    ROUTE_MENU_CUSTOM_PAGES_EDIT: {
        id: 'ROUTE_MENU_CUSTOM_PAGES_EDIT',
        path: '/customPages/edit',
        component: CustomPagesEdit
    },

    ROUTE_MENU_CUSTOM_PAGES_SECTIONS_EDIT: {
        id: 'ROUTE_MENU_CUSTOM_PAGES_SECTIONS_EDIT',
        path: [`/customPage/sections/edit`],
        component: SectionsEdit
    },

    // ROUTE_MENU_POPUPS_EDIT: {
    //     id: 'ROUTE_MENU_POPUPS_EDIT',
    //     path: '/popups/edit',
    //     component: PopupsEdit
    // },

    // ROUTE_MENU_POPUPS_SECTIONS_EDIT: {
    //     id: 'ROUTE_MENU_POPUPS_SECTIONS_EDIT',
    //     path: ['/popups/sections/edit'],
    //     component: SectionsEdit
    // },

    ROUTE_MENU_PREDEFINED_SEARCH_EDIT: {
        id: 'ROUTE_MENU_PREDEFINED_SEARCH_EDIT',
        path: '/predefinedSearch/edit',
        component: PredefinedSearchEdit
    }
};