const sections = {
    productCardSections: {
        title: 'Sekcje w karcie produktu',
        table: {
            headers: {
                order: 'Kolejność',
                name: 'Nazwa',
                type: 'Typ sekcji',
                desktopActive: 'Aktywny na desktopie',
                mobileActive: 'Aktywny na telefonie',
                activeFrom: 'Aktwyna od',
                activeTo: 'Aktywna do',
                activeInLanguages: 'Aktywna w językach',
                actions: 'Akcje',
                categories: 'Przypisane kategorie'
            }
        }
    }
};

export default sections;