import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeCategoryImage } from '../../../../../redux/methods/categories';

import FileSender from '../../../../../components/FileSender';

import { stringOrNumberPropTypes } from '../../../../../propTypes';
import { standardImageExtensions } from '../../../../../config';

const propTypes = {
    removeCategoryImage: PropTypes.func,
    translations: PropTypes.object,
    categoryId: stringOrNumberPropTypes
};

const FileSenderCategory = ({
    removeCategoryImage,
    translations,
    categoryId,
    setFile,
    image,
    type
}) => {
    const handleRemove = () => {
        removeCategoryImage(categoryId, type);
    };

    return (
        <FileSender
            fileKey='Image'
            validateFormats={standardImageExtensions}
            handleRemove={handleRemove}
            withPreview
            buttons={translations.images.buttons}
            label={translations.images.label}
            photo={image}
            setFile={setFile}
        />
    );
};

FileSenderCategory.propTypes = propTypes;

const mapDispatch = {
    removeCategoryImage
};

export default connect(null, mapDispatch)(FileSenderCategory);