import { createSelector } from '@reduxjs/toolkit';

const selectIsLoading = state => state.auth.isLoading;
const selectUser = state => state.auth.user;

export const selectIsLoadingByTarget = target => createSelector(
    selectIsLoading,
    isLoading => isLoading[target]
);

export const selectIsAuthenticated = createSelector(
    selectUser,
    user => user ? true : false
);