import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { Button, Modal as CoreUiModal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import FormFieldTranslations from '../../formFields/FormFieldTranslations';
import FormFieldWithDropdown from '../../formFields/FormFieldWithDropdown';

import styles from './index.module.scss';
import clsx from 'clsx';
import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    isConnectedToERP: PropTypes.bool,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    translations: PropTypes.object,
    paymentForm: PropTypes.object,
    payUEnabled: PropTypes.bool,
    p24Enabled: PropTypes.bool
};

const PaymentFormEditionModal = ({
    utils,
    isConnectedToERP,
    isOpen,
    setIsOpen,
    onSubmit,
    translations,
    paymentForm,
    payUEnabled,
    p24Enabled
}) => {

    const config = { translations: ['name'] };

    const handleOnCancel = () => {
        setIsOpen(false);
    };

    const handleOnSubmit = (form) => {
        const formToSend = utils.generateFormToSubmitValues(form, config);

        if (formToSend.productId === 0) {
            formToSend.productId = null;
        }

        onSubmit(formToSend);
    };

    return paymentForm ? (
        <CoreUiModal className="wide" isOpen={isOpen}>
            <FinalForm
                initialValues={utils.generateFormInitialValues(paymentForm, config)}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values}) => {

                    const selectedPaymentMethod = paymentForm.paymentMethodsDictionary.find(item => item.id === values.paymentMethodId);
                    const selectedProduct = paymentForm.productsDictionary.find(item => item.id === values.productId);
                    const selectedWfMagForm = paymentForm.wfMagFormsDictionary.find(item => item.id === values.wfMagFormId);

                    return (
                        <div>
                            <ModalHeader>{translations.title}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleSubmit}>
                                    <div>
                                        <div className='my-2'>
                                            <FormFieldTranslations
                                                label={translations.inputs.name}
                                                form={form}
                                                modalHeader={translations.nameModalHeader}
                                                propertyName='name'
                                                currentIso={utils.currentLanguage()}
                                                className='w-100'
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <FormFieldWithDropdown
                                                name='paymentMethodId'
                                                label={translations.inputs.paymentMethodId}
                                                currentSelectionLabel={selectedPaymentMethod?.label}
                                                options={paymentForm.paymentMethodsDictionary}
                                            />
                                            {
                                                (selectedPaymentMethod?.tag == 'BlikPayU' && !payUEnabled) ?
                                                <small className='text-danger'>
                                                    {translations.blikInfo.label}
                                                    <Link to="/integrations/onlinePayments/payu">{translations.blikInfo.linkPayU}</Link>
                                                </small> :
                                                (selectedPaymentMethod?.tag == 'BlikP24' && !p24Enabled) ?
                                                <small className='text-danger'>
                                                    {translations.blikInfo.label}
                                                    <Link to="/integrations/onlinePayments/p24">{translations.blikInfo.linkP24}</Link>
                                                </small> : null
                                            }
                                        </div>
                                        <div className='my-2'>
                                            <FormFieldWithDropdown
                                                name='productId'
                                                label={translations.inputs.productId}
                                                currentSelectionLabel={selectedProduct?.label}
                                                options={paymentForm.productsDictionary}
                                            />
                                        </div>
                                        {isConnectedToERP && (
                                            <div className='my-2'>
                                                <FormFieldWithDropdown
                                                    name='wfMagFormId'
                                                    label={translations.inputs.wfMagFormId}
                                                    currentSelectionLabel={selectedWfMagForm?.label}
                                                    options={paymentForm.wfMagFormsDictionary}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <div className={clsx("d-flex justify-content-between", styles.modalFooterButtons)}>
                                    <Button onClick={handleOnCancel} color='danger'>
                                        <i className="fa fa-remove mr-2"></i>
                                        <span>{translations.buttons.remove}</span>
                                    </Button>
                                    <Button onClick={handleSubmit} color='success'>
                                        <i className="fa fa-save mr-2"></i>
                                        <span>{translations.buttons.save}</span>
                                    </Button>
                                </div>
                            </ModalFooter>
                        </div>
                    );
                }}
            />
        </CoreUiModal>
    ) : null;
};

PaymentFormEditionModal.propTypes = propTypes;

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
    payUEnabled: state.appInit.payUEnabled,
    p24Enabled: state.appInit.p24Enabled
});

export default connect(mapStateToProps, null)(withFormUtils(PaymentFormEditionModal));