import { setIsLoading, setIsLoaded } from '../../../features/loaders/slice';
import { setError, clearError } from '../../../features/errors/slice';
import { setForceRefetchByTarget } from '../../../features/refetchers/slice';
import { setIsSuccess } from '../../../features/toast/slice';

import { handleCAPIErrorsSubtle } from '../../../utils/cAPI';

export const closeErrorModal = () => dispatch => { dispatch(clearError()); };

export const getData = async (dispatch, apiCall, searchConfig, refetchTargets, loading = true) => {

    dispatch(setIsSuccess({ isSuccess: false }));
    if(loading) {
        dispatch(setIsLoading());
    }
    dispatch(setForceRefetchByTarget({ forceRefetch: true, target: 'sessionTimeout' }));

    const { payLoad, error, httpError, unhandledError, statusCode } = await apiCall(searchConfig);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { pages, rows } = payLoad;

        if (refetchTargets)
        {
            if (Array.isArray(refetchTargets)){
                for(const refetchTarget of refetchTargets) {
                    dispatch(setForceRefetchByTarget({ target: refetchTarget, forceRefetch: false }));
                }
            }
            else {
                dispatch(setForceRefetchByTarget({ target: refetchTargets, forceRefetch: false }));
            }
        }
        if(loading) {
            dispatch(setIsLoaded());
        }
        dispatch(setForceRefetchByTarget({ forceRefetch: false, target: 'sessionTimeout' }));

        return { pages: pages, payload: rows || payLoad };
    } else if (errorToHandle) {
        dispatch(setIsLoaded());
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
    else {
        if(loading) {
            dispatch(setIsLoaded());
        }

        dispatch(setForceRefetchByTarget({ forceRefetch: false, target: 'sessionTimeout' }));

        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};

export const addEditData = async (dispatch, apiCall, data, refetchTargets, keepLoadingIndicator = false, noLoader = false, returnWholePayload = false, noSuccess = false, handleRefetchOnError = false) => {

    if(!noLoader) {
        dispatch(setIsLoading());
    }

    dispatch(setForceRefetchByTarget({ forceRefetch: true, target: 'sessionTimeout' }));

    const { payLoad, error, httpError, unhandledError, statusCode } = await apiCall(data);

    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    const handleRefetch = () =>
    {
        if (refetchTargets)
        {
            if (Array.isArray(refetchTargets)){
                for(const refetchTarget of refetchTargets) {
                    dispatch(setForceRefetchByTarget({ target: refetchTarget, forceRefetch: true }));
                }
            }
            else {
                dispatch(setForceRefetchByTarget({ target: refetchTargets, forceRefetch: true }));
            }
        }
    };

    if (payLoad) {
        const { errors } = payLoad;

        if (errors && errors.form && errors.form.message) {
            const { message } = errors.form;

            dispatch(setError({ error: message }));
            dispatch(setIsLoaded());

            if(handleRefetchOnError){
                handleRefetch();
            }
        }
        else if (errors) {
            if (!keepLoadingIndicator) {
                dispatch(setIsLoaded());
            }

            return Object.keys(errors).reduce((acc, curr) => ({ ...acc, [curr]: errors[curr].message }), {} );
        }
        else {
            if (!keepLoadingIndicator) {
                dispatch(setIsLoaded());
            }

            if (!noSuccess) {
                await dispatch(setIsSuccess({ isSuccess: true }));
            }

            handleRefetch();

            return returnWholePayload ? payLoad : payLoad.id;
        }

        dispatch(setForceRefetchByTarget({ forceRefetch: false, target: 'sessionTimeout' }));
    } else if (errorToHandle) {
        dispatch(setIsLoaded());
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
    else {
        dispatch(setIsLoaded());
    }
};

export const removeData = async (dispatch, apiCall, id, refetchTargets, keepLoadingIndicator = false) => {
    dispatch(setIsLoading());
    dispatch(setForceRefetchByTarget({ forceRefetch: true, target: 'sessionTimeout' }));

    const { payLoad, error, httpError, unhandledError, statusCode } = await apiCall(id);
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const { errors } = payLoad;

        if (errors && errors.form) {
            const { message } = errors.form;
            dispatch(setError({ error: message }));
            dispatch(setIsLoaded());
        } else {
            if (!keepLoadingIndicator) {
                dispatch(setIsLoaded());
            }

            if (refetchTargets)
            {
                if (Array.isArray(refetchTargets)){
                    for(const refetchTarget in refetchTargets){
                        dispatch(setForceRefetchByTarget({ target: refetchTarget, forceRefetch: true }));
                    }
                }
                else {
                    dispatch(setForceRefetchByTarget({ target: refetchTargets, forceRefetch: true }));
                }
            }
        }
        dispatch(setForceRefetchByTarget({ forceRefetch: false, target: 'sessionTimeout' }));
    } else if (errorToHandle) {
        dispatch(setIsLoaded());
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
        dispatch(setForceRefetchByTarget({ forceRefetch: false, target: 'sessionTimeout' }));
    }
    else
    {
        dispatch(setIsLoaded());
    }
};