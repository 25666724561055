import ConnecticoAPI from '@connectico/connectico-api';
import getConfig from '../../getConfig';

import { setError } from '../../features/errors/slice';

export const handleCAPIErrorsSubtle = ({ error, httpError, unhandledError, statusCode }, dispatch) => {
    const receivedError = error || httpError || unhandledError;

    if ((receivedError instanceof TypeError) && (receivedError.message == 'Failed to fetch'))
    {
        if (dispatch)
        {
            dispatch(setError({
                error: 'Utracono połączenie z serwerem',
                forceLogout: true
            }));
        }
        else
        {
            throw Error('Utracono połączenie z serwerem');
        }
    }
    else if (statusCode === 401 || statusCode === 403)
    {
        if (dispatch)
        {
            dispatch(setError({
                error: 'Nastąpiło wylogowanie z serwera',
                forceLogout: true
            }));
        }
        else
        {
            throw Error('Nastąpiło wylogowanie z serwera');
        }
    }
    else if (receivedError && receivedError.message) {
        if (dispatch)
        {
            dispatch(setError({
                error: receivedError.message,
                forceLogout: false
            }));
        }
        else
        {
            throw Error(receivedError.message);
        }
    } else {
        console.log(receivedError);
    }
};

const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

export const cAPI = ConnecticoAPI({ baseUrl: apiUrl });