import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectTranslatedCategoryTreeDND } from '../../../redux/selectors/categories';
import { createNewCategory, clearNewlyCreatedCategoryId, removeCategory, moveCategory, updateCategoryStatus, expandCategories } from '../../../redux/methods/categories';

import { Button } from 'reactstrap';
import SortableTree from 'react-sortable-tree';

import Modal from '../../../components/modals/Modal';
import TableActionCell from '../../../components/tables/TableActionCell';
import TooltipWrapper from '../../../components/TooltipWrapper';

import { flattenedCategoryTreeItemPropTypes } from '../../../propTypes';

import theme from '../../../reactSortableTreeTheme';
import styles from './index.module.scss';

const propTypes = {
    translatedCategoryTreeDND: PropTypes.arrayOf(flattenedCategoryTreeItemPropTypes),
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    newlyCreatedCategoryId: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    error: PropTypes.string,
    createNewCategory: PropTypes.func,
    clearNewlyCreatedCategoryId: PropTypes.func,
    removeCategory: PropTypes.func,
    clearError: PropTypes.func,
    moveCategory: PropTypes.func,
    isERPCategoriesInUsage: PropTypes.bool,
};

const CategoryTreeDND = ({
    translatedCategoryTreeDND, history, newlyCreatedCategoryId, createNewCategory,
    clearNewlyCreatedCategoryId, removeCategory, moveCategory, updateCategoryStatus,
    expandCategories, isERPCategoriesInUsage
}) => {
    const intl = useIntl();
    const translations = intl.messages.categories.view;

    const [ localTree, setLocalTree ] = useState(translatedCategoryTreeDND);
    const [ chosenCategoryId, setChosenCategoryId ] = useState(null);
    const [ isAdditionModalOpen, setIsAdditionModalOpen ] = useState(false);
    const [ openTooltipId, setOpenTooltipId ] = useState(null);
    const [ openSwitchTooltipId, setOpenSwitchTooltipId ] = useState(null);
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);
    const [ categoryToActivationConfirm, setCategoryToActivationConfirm ] = useState(null);

    useEffect(() => {
        setLocalTree(translatedCategoryTreeDND);
    }, [ translatedCategoryTreeDND ]);

    useEffect(() => {
        /**
         * If new category was successfully created, then redirect user directly to it's edition
         */
        if (newlyCreatedCategoryId) {
            clearNewlyCreatedCategoryId();
            history.push(`/Categories/Edit?catID=${newlyCreatedCategoryId}`);
        }
    }, [ newlyCreatedCategoryId, clearNewlyCreatedCategoryId, history ]);

    const handleOpenAdditionModalSub = id => () => {
        setChosenCategoryId(id);
        setIsAdditionModalOpen(true);
    };

    const handleOpenAdditionModalRoot = () => {
        setChosenCategoryId(null);
        setIsAdditionModalOpen(true);
    };

    const handleCreateNewCategory = () => {
        createNewCategory(chosenCategoryId);
    };

    const handleRemoveCategory = categoryId => () => {
        removeCategory(categoryId);
    };

    const handleOnChange = treeData => {

        const reduceLevel = level => level.reduce((acc,l) => [
            ...acc,
            ...(l.expanded ? [ l.id ] : []),
            ...(l.children ? reduceLevel(l.children) : [])
        ], []);

        expandCategories(reduceLevel(treeData));

        setLocalTree(treeData);
    };

    const handleOnMoveNode = moveObject => {
        const selectedCategoryId = moveObject.node.id;
        const targetCategoryId = moveObject.nextParentNode ? moveObject.nextParentNode.id : null;
        const targetOrderIndex = targetCategoryId
            ? moveObject.nextParentNode.children.findIndex(node => node.id === selectedCategoryId)
            : moveObject.treeData.findIndex(node => node.id === selectedCategoryId)
        ;

        moveCategory({
            categoryId: selectedCategoryId,
            parentCategoryId: targetCategoryId,
            orderIndex: targetOrderIndex
        });
    };

    const toggleOpenTooltipId = id => isOpen => {
        setOpenTooltipId(isOpen ? id : null);
    };

    const toogleOpenSwitchTooltipId = id => isOpen => {
        setOpenSwitchTooltipId(isOpen ? id : null);
    };

    const handleActiveSwitch = (category) => () => {
        const statusToSend = category.status === 0 ? 1 : 0;

        if(category.status === 0 && category.children) {
            setCategoryToActivationConfirm(category);
            setIsWarningModalOpen(true);
        } else {
            updateCategoryStatus({ categoryId: category.id, status: statusToSend });
        }
    };

    const handleActivationConfirm = () => {
        updateCategoryStatus({ categoryId: categoryToActivationConfirm.id, status: 1});
        setCategoryToActivationConfirm(null);
        setIsWarningModalOpen(false);
    };

    const generateNodeProps = ({ node }) => {
        const isActive = node.status === 1;
        const isInactiveFromErp = (node.statusErp || node.statusErp === 0) && (node.statusErp !== 1 || !isActive);
        return {
            title: (
                <span className={clsx(styles.node, !isActive && styles.inactive, isInactiveFromErp && styles.inactiveFromErp)}>
                    <span className={clsx(!node.name && 'text-danger')}>{node.name || translations.nameFiller}</span>
                    <div className='d-flex align-items-center pr-2'>
                        {
                            !isERPCategoriesInUsage
                                && (
                                    <label className="switch switch-pill switch-success m-0">
                                        <input
                                            type="checkbox"
                                            className="switch-input"
                                            checked={isActive}
                                            onChange={handleActiveSwitch(node)}
                                            disabled={node.depth !== 0 && node.parentStatus === 0}
                                        />
                                        <TooltipWrapper
                                            placement='right'
                                            isOpen={openSwitchTooltipId === node.id}
                                            setIsOpen={toogleOpenSwitchTooltipId(node.id)}
                                            target={`categoryStatusSlider-${node.id}`}
                                            tooltipContent={translations.categoryStatusTooltip}
                                        >
                                            <span
                                                id={`categoryStatusSlider-${node.id}`}
                                                className={clsx("switch-slider", !isActive &&  "bg-danger border-danger")}
                                            />
                                        </TooltipWrapper>
                                    </label>
                                )
                        }
                        <TableActionCell
                            itemId={node.id}
                            actions={
                                !isERPCategoriesInUsage
                                    ? {
                                        delete: {
                                            modalTranslations: {
                                                header: `${translations.deleteModal.header} ${node.name}`,
                                                body: `${translations.deleteModal.bodyStart} ${node.name} ${translations.deleteModal.bodyEnd}`,
                                                confirm: translations.deleteModal.confirm,
                                                cancel: translations.deleteModal.cancel
                                            },
                                            tooltipTranslation: translations.actions.delete,
                                            action: handleRemoveCategory(node.id)
                                        },
                                        edit: {
                                            tooltipTranslation: translations.actions.edit,
                                            editLink: `/categories/edit?catID=${node.id}`,
                                        }
                                    }
                                    : {
                                        edit: {
                                            tooltipTranslation: translations.actions.edit,
                                            editLink: `/categories/edit?catID=${node.id}`,
                                        }
                                    }
                            }
                        />
                    </div>
                    {
                        !isERPCategoriesInUsage &&
                            <TooltipWrapper
                                placement="top"
                                target={`dndAddSubCategory-${node.id}`}
                                tooltipContent={`${translations.actions.addSub[0]}${translations.actions.addSub.slice(1).toLowerCase()}`}
                                isOpen={openTooltipId === node.id}
                                setIsOpen={toggleOpenTooltipId(node.id)}
                            >
                                <button
                                    type="button"
                                    className={styles.nodeButton}
                                    onClick={handleOpenAdditionModalSub(node.id)}
                                    id={`dndAddSubCategory-${node.id}`}
                                >
                                    <i className={clsx('fa fa-plus', styles.nodeButtonIcon)}/>
                                </button>
                            </TooltipWrapper>
                    }
                </span>
            ),
        };
    };

    return (
        <div className={styles.container}>
            <aside className={styles.controls}>
                <div id='addRootButton'>
                    <Button color="primary" onClick={handleOpenAdditionModalRoot}>
                        <i className="fa fa-plus mr-2"/>
                        <span>{translations.actions.addRoot}</span>
                    </Button>
                </div>
            </aside>
            <main className={styles.borderWrapper}>
                <SortableTree
                    treeData={localTree}
                    onChange={handleOnChange}
                    onMoveNode={handleOnMoveNode}
                    generateNodeProps={generateNodeProps}
                    theme={theme}
                />
            </main>
            <Modal
                isOpen={isAdditionModalOpen}
                setIsOpen={setIsAdditionModalOpen}
                header={translations.additionModal.header}
                body={translations.additionModal.body}
                buttons={{
                    confirm: {
                        label: translations.additionModal.confirm,
                        color: 'primary'
                    },
                    cancel: {
                        label: translations.additionModal.cancel,
                        color: 'secondary'
                    }
                }}
                onConfirm={handleCreateNewCategory}
            />
            <Modal
                isOpen={isWarningModalOpen}
                setIsOpen={setIsWarningModalOpen}
                header={translations.warningModal.header}
                body={translations.warningModal.body}
                buttons={{
                    confirm: {
                        label: translations.warningModal.confirm,
                        color: 'primary'
                    },
                    cancel: {
                        label: translations.warningModal.cancel,
                        color: 'secondary'
                    }
                }}
                onConfirm={handleActivationConfirm}
            />
        </div>
    );
};

CategoryTreeDND.propTypes = propTypes;

const mapStateToProps = state => ({
    translatedCategoryTreeDND: selectTranslatedCategoryTreeDND(state),
    newlyCreatedCategoryId: state.categories.newlyCreatedCategoryId,
});

const mapDispatch = {
    createNewCategory,
    clearNewlyCreatedCategoryId,
    removeCategory,
    moveCategory,
    updateCategoryStatus,
    expandCategories
};

export default withRouter(connect(mapStateToProps, mapDispatch)(CategoryTreeDND));