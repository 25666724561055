import { getTranslationForIso } from '../shared';

export const isEditedColorPicker = (isEdited) => {
    switch(isEdited) {
        case 'unedited': return 'danger';
        case 'edited': return 'success';
        default: return null;
    }
};

export const generateStatusesConfig = (statusEnum, translations) => {
    return Object.keys(statusEnum).map(key => ({
        id: parseInt(key),
        label: translations[statusEnum[key].toLowerCase()],
        color: isEditedColorPicker(statusEnum[key])
    }));
};

export const nestedButtonsGenerator = (editor, params, iso, buttonName) => {
    const generateButtonCategories = (params, iso) => {
        const extractedCategories = params.map(param => getTranslationForIso(param.category, iso));
        return [...new Set(extractedCategories)];
    };

    return (
				editor.ui.registry.addMenuButton('myButton', {
            text: buttonName,
            fetch: callback => {
                const items = generateButtonCategories(params, iso).map(category => ({
                    type: 'nestedmenuitem',
                    text: category,
                    getSubmenuItems: () => {
                        const subMenuItems = params.map(param => {
                            if(getTranslationForIso(param.category, iso) === category) {
                                return {
                                    type: 'menuitem',
                                    text: getTranslationForIso(param.name, iso),
                                    onAction: () => editor.insertContent(`<span class='customVariable mceNonEditable'>${param.value}</span>&nbsp;`, { format: 'raw'})
                                };
                            } else {
                                return null;
                            }
                        });

                        return subMenuItems.filter(item => item);
                    }
                }));
                callback(items);
            }
        })
    );
};
