import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { selectStoreCurrentLanguage } from '../../redux/selectors/storeLocale';

import { Button } from 'reactstrap';

import styles from './index.module.scss';
import getConfig from '../../getConfig';

const ShopRedirectButton = ({
    currentLanguage,
    className,
}) => {
    const intl = useIntl();
    const translations = intl.messages.defaultLayout.appAside.tabs;

    const handleOnClick = () => {
        const shopUrl = getConfig().NEXT_SHOP_EXTERNAL_URL;
        const shopWindow = window.open(`${shopUrl}/${currentLanguage}`, '_blank', 'noopener, noreferrer');
        if (shopWindow) {
            shopWindow.opener = null;
        }
    };

    return (
        <Button onClick={handleOnClick} color='success' className={clsx(className)}>
            <i className={clsx("cil-cart mr-2", styles.icon)} />
            <span>{translations.redirectToStore}</span>
        </Button>
    );
};

const mapStateToProps = state => ({
    currentLanguage: selectStoreCurrentLanguage(state)
});

export default connect(mapStateToProps, null)(ShopRedirectButton);