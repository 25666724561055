import React from "react";
import { SortableHandle } from "react-sortable-hoc";

import ActionsCell from '../cells/ActionsCell';
import CheckboxCell from '../cells/CheckboxCell';
import CustomCell from '../cells/CustomCell';
import SwitchWithActionCell from '../cells/SwitchWithActionCell';
import RatingCell from '../cells/RatingCell';
import OrderCell from '../cells/OrderCell';
import TextOrNumberCell from '../cells/TextOrNumberCell';
import BadgeCell from '../cells/BadgeCell';
import DateCell from '../cells/DateCell';
import InputCell from '../cells/InputCell';
import DropDownCell from '../cells/DropDownCell';

import styles from '../index.module.scss';
import { firstLetterToLowerCase } from "../../../../utils/components";
import clsx from "clsx";

const RowHandler = SortableHandle(() =>
    <td className={clsx(styles.cell, styles.drag)}>
        <i className="fa fa-arrows fa-lg" aria-hidden="true"></i>
    </td>
);

const Aux = props => props.children;

const TableRow = ({
    columns,
    item,
    idSelector,
    adjustedIndex,
    items,
    onClickActionHandler,
    itemIndex,
    actions,
    expectedActions,
    translations,
    editLink,
    isChecked,
    handleCheckboxAction,
    isCheckboxDisabled,
    draggable,
    selectedRowId,
    detailsComponent: Component,
    checkIfActionIsDisabled,
    className
}) => {
    const rowIsSelected = selectedRowId === (item[idSelector] ? item[idSelector] : itemIndex);

    return (
        <Aux>
            <tr className={className || styles.row}>
                {draggable && <RowHandler />}
                {
                    columns.map((column, columnIndex) => {
                        if (column) {
                            const itemValue = column.customTranslation ? column.customTranslation[firstLetterToLowerCase(item[column.value])] : item[column.value];
                            const itemAdditionalValue = item[column.additionalValue];

                            switch(column.type) {
                                case 'order':
                                    return (
                                        <OrderCell
                                            key={`${item[idSelector]}--orderCell`}
                                            className={clsx(styles.cell, column.additionalStyle)}
                                            onClickAction={onClickActionHandler(column, item[idSelector], item)}
                                            index={adjustedIndex}
                                            id={item[idSelector]}
                                            items={items}
                                            item={item}
                                            decreaseOrder={column.decreaseOrder}
                                            increaseOrder={column.increaseOrder}
                                        />
                                    );
                                case 'index':
                                    return (
                                        <TextOrNumberCell
                                            key={`${item[idSelector]}--indexCell`}
                                            value={itemIndex}
                                            className={clsx(styles.cell, column.additionalStyle)}
                                            onClickAction={onClickActionHandler(column, item[idSelector], item)}
                                        />
                                    );
                                case 'badge':
                                    return (
                                        <BadgeCell
                                            value={itemValue}
                                            badgeConfig={column.config}
                                            className={clsx(styles.cell, styles.badge, column.additionalStyle)}
                                            key={`${item[idSelector]}--${column.value}--badgeCell`}
                                            onClickAction={onClickActionHandler(column, item[idSelector], item)}
                                        />
                                    );
                                case 'date':
                                    return (
                                        <DateCell
                                            withTime={column.withTime}
                                            value={itemValue}
                                            className={clsx(styles.cell, styles.date, column.additionalStyle)}
                                            key={`${item[idSelector]}--${column.value}--dateCell`}
                                            onClickAction={onClickActionHandler(column, item[idSelector], item)}
                                        />
                                    );
                                case 'actions':
                                    return (
                                        <ActionsCell
                                            key={`${item[idSelector]}--actionCell`}
                                            className={clsx(styles.cell, column.additionalStyle)}
                                            actionCellType={column.actionCell}
                                            expectedActions={expectedActions}
                                            actions={actions}
                                            item={item}
                                            itemId={item[idSelector]}
                                            translations={translations}
                                            editLink={editLink}
                                            dropdownLabel={column.header}
                                            checkIfActionIsDisabled={checkIfActionIsDisabled}
                                        />
                                    );
                                case 'checkbox':
                                    return (
                                        <CheckboxCell
                                            isChecked={isChecked}
                                            itemId={item[idSelector]}
                                            checkboxAction={handleCheckboxAction}
                                            additionalStyle={column.additionalStyle}
                                            className={styles.cell}
                                            key={`${item[idSelector]}--checkbox`}
                                            disabled={isCheckboxDisabled}
                                        />
                                    );
                                case 'custom':
                                    return (
                                        <CustomCell
                                            className={styles.cell}
                                            customCell={column.customCell}
                                            item={item}
                                            key={`${item[idSelector]}--${column.header}--customCell`}
                                            additionalStyle={column.additionalStyle}
                                        />
                                    );
                                case 'switchWithAction':
                                    return (
                                        <SwitchWithActionCell
                                            disabled={column.disabled}
                                            tooltip={column.tooltip}
                                            item={item}
                                            value={itemValue}
                                            className={styles.cell}
                                            onSwitch={column.onSwitch}
                                            id={item[idSelector]}
                                            key={`${item[idSelector]}${columnIndex}--switchWithAction`}
                                            tooltipKey={`${item[idSelector]}${columnIndex}--switchWithAction`}
                                            columnName={column.value}
                                            fromFalseToTrueEnabled={(column.fromFalseToTrueEnabled ?? true)}
                                            fromTrueToFalseEnabled={(column.fromTrueToFalseEnabled ?? true)}
                                            onClickAction={onClickActionHandler(column, item[idSelector], item)}
                                        />
                                    );
                                case 'rating':
                                    return (
                                        <RatingCell
                                            value={itemValue}
                                            className={styles.cell}
                                            key={`${item[idSelector]}--rating`}
                                        />
                                    );
                                case 'input':
                                    return (
                                        <InputCell
                                            disabled={column.disabled}
                                            tooltip={column.tooltip}
                                            item={item}
                                            value={itemValue}
                                            className={styles.cell}
                                            onChange={column.onChange}
                                            id={item[idSelector]}
                                            key={`${item[idSelector]}${columnIndex}--input`}
                                            tooltipKey={`${item[idSelector]}${columnIndex}--input`}
                                            columnName={column.value}
                                            type={column.inputType}
                                            textAlignment={column.inputTextAlignment}
                                        />
                                    );
                                case 'dropDown':
                                    return (
                                        <DropDownCell
                                            disabled={column.disabled}
                                            tooltip={column.tooltip}
                                            item={item}
                                            value={itemValue}
                                            dictionary={column.dictionary}
                                            className={styles.cell}
                                            onChange={column.onChange}
                                            id={item[idSelector]}
                                            key={`${item[idSelector]}${columnIndex}--dropDown`}
                                            tooltipKey={`${item[idSelector]}${columnIndex}--dropDown`}
                                            columnName={column.value}
                                        />
                                    );
                                default:
                                    return (
                                        <TextOrNumberCell
                                            onClickAction={onClickActionHandler(column, item[idSelector], item, item.href)}
                                            value={ (itemValue || itemValue === 0) && itemValue.filename ? itemValue.filename : (itemValue === null ? column.filler : itemValue)}
                                            additionalValue={itemAdditionalValue}
                                            className={clsx(styles.cell, (itemValue === null) && 'text-danger', column.additionalStyle)}
                                            key={`${item[idSelector]}--${column.value}--textOrNumberCell`}
                                        />
                                    );
                            }
                        } else {
                            return null;
                        }
                    })
                }
            </tr>
            {rowIsSelected && Component &&
                <tr>
                    <td colSpan={columns.filter(column => column).length}>
                        <Component item={item}/>
                    </td>
                </tr>
            }
        </Aux>
    );
};

export default TableRow;