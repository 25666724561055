/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getQuantityPresentation, updateQuantityPresentation } from '../../../redux/methods/products';

import useFetch from '../../../hooks/useFetch';

import { Container, Card, CardHeader, CardBody, Form, Row, Col } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import GraphicInfo from './GraphicInfo';

import { quantityPresentationEnum } from '../../../enums/products';

import styles from './index.module.scss';
import CardFooter from 'reactstrap/lib/CardFooter';

const propTypes = {
    getQuantityPresentation: PropTypes.func,
    updateQuantityPresentation: PropTypes.func
};

const Quantity = ({ getQuantityPresentation, updateQuantityPresentation }) => {
    const intl = useIntl();
    const translations = intl.messages.quantity;

    const handleOnSubmit = formData => {

        let dataToSend = {
            type: formData.type,
            includeMinQuantity: formData.includeMinQuantity,
            minQuantity: formData.minQuantity,
            includeReservations: formData.includeReservations,
            isMasked: formData.isMasked,
            productTypeSettings: []
        };

        data.productTypeSettings.map(s => {
            dataToSend.productTypeSettings.push({
                id: s.id,
                name: s.name,
                includeMaskedQuantity: formData['productTypeSetting_' + s.id + '_includeMaskedQuantity'],
                maskedQuantityValue: formData['productTypeSetting_' + s.id + '_maskedQuantityValue'],
                includeMinQuantity: formData['productTypeSetting_' + s.id + '_includeMinQuantity'],
                minQuantityValue: formData['productTypeSetting_' + s.id + '_minQuantityValue'],
                includeReservations: formData['productTypeSetting_' + s.id + '_includeReservations']
            });
        });

        if (formData.type === 4) {
            dataToSend = {
                ...dataToSend,
                levelLittle: formData.levelLittle,
                levelMedium: formData.levelMedium,
                levelMany: formData.levelMany
            };
        }

        updateQuantityPresentation(dataToSend);
    };

    const [ data ] = useFetch(getQuantityPresentation, null, []);

    const typesDropdown = Object.keys(quantityPresentationEnum).map(key => ({
        id: parseInt(key),
        label: translations.view.types[quantityPresentationEnum[key]],
    }));

    const changeInt = onChange => e => {
        onChange(parseInt(e.target.value));
    };

    const placecholdersInitialValues = {
        ...data,
        levelLittle: (data && data.levelLittle) || 1,
        levelMedium: (data && data.levelMedium) || 5,
        levelMany: (data && data.levelMany) || 10,
        ...data?.productTypeSettings.reduce((acc,s) => {
            const result = {...acc, };
            result['productTypeSetting_' + s.id + '_includeMaskedQuantity'] = s.includeMaskedQuantity;
            result['productTypeSetting_' + s.id + '_maskedQuantityValue'] = s.maskedQuantityValue;
            result['productTypeSetting_' + s.id + '_includeMinQuantity'] = s.includeMinQuantity;
            result['productTypeSetting_' + s.id + '_minQuantityValue'] = s.minQuantityValue;
            result['productTypeSetting_' + s.id + '_includeReservations'] = s.includeReservations;
            return result;
        }, {})
    };

    return data ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                    <Card>
                        <CardHeader>
                            <i className={clsx('fa fa-align-justify')}></i>
                            <span>{translations.view.title}</span>
                        </CardHeader>
                        <CardBody>
                            <FinalForm
                                keepDirtyOnReinitialize
                                initialValues={placecholdersInitialValues}
                                onSubmit={ handleOnSubmit }
                                render={({ handleSubmit, form, values }) => {
                                    const type = typesDropdown.find(item => item.id === values.type);

                                    return (
                                        <Form onSubmit={handleSubmit}>
                                            <Container className='pt-3' fluid>
                                                <Card>
                                                    <CardHeader>
                                                        {translations.view.productTypeSetting.header}
                                                    </CardHeader>
                                                    <CardBody>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>{translations.view.productTypeSetting.productType}</th>
                                                                    <th colSpan="2" className="pl-3">{translations.view.productTypeSetting.includeMaskedQuantity}</th>
                                                                    <th colSpan="2" className="pl-3">{translations.view.productTypeSetting.includeMinQuantity}</th>
                                                                    <th className="pl-3">{translations.view.productTypeSetting.includeReservations}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.productTypeSettings.map(s => (
                                                                    <tr>
                                                                        <td>{s.name}</td>
                                                                        <td className="pt-3 pl-3">
                                                                            <FormFieldSwitchPill
                                                                                name={'productTypeSetting_' + s.id + '_includeMaskedQuantity'}
                                                                                className='mb-3 mt-md-0'
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <FormFieldTextOrNumber
                                                                                name={'productTypeSetting_' + s.id + '_maskedQuantityValue'}
                                                                                type='number'
                                                                                min="0"
                                                                                disabled={values['productTypeSetting_' + s.id + '_includeMaskedQuantity'] !== true}
                                                                            />
                                                                        </td>
                                                                        <td className="pt-3 pl-3">
                                                                            <FormFieldSwitchPill
                                                                                name={'productTypeSetting_' + s.id + '_includeMinQuantity'}
                                                                                className='mb-3 mt-md-0'
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <FormFieldTextOrNumber
                                                                                name={'productTypeSetting_' + s.id + '_minQuantityValue'}
                                                                                type='number'
                                                                                min="0"
                                                                                disabled={values['productTypeSetting_' + s.id + '_includeMinQuantity'] !== true}
                                                                            />
                                                                        </td>
                                                                        <td className="pt-3 pl-3">
                                                                            <FormFieldSwitchPill
                                                                                name={'productTypeSetting_' + s.id + '_includeReservations'}
                                                                                className='mb-3 mt-md-0'
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </CardBody>
                                                    <CardFooter>
                                                        <sup>{translations.view.productTypeSetting.explanation}</sup>
                                                    </CardFooter>
                                                </Card>
                                                <Card>
                                                    <CardHeader>
                                                        {translations.view.labels.type}
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className='w-50 mt-4'>
                                                            <FormFieldWithDropdown
                                                                currentSelectionLabel={type.label}
                                                                name='type'
                                                                label={translations.view.labels.type}
                                                                options={typesDropdown}
                                                            />
                                                        </div>
                                                        {
                                                            values.type === 4 && (
                                                                <React.Fragment>
                                                                    <GraphicInfo values={values} />
                                                                    <Row>
                                                                    <Col>
                                                                            <FormFieldTextOrNumber
                                                                                type='number'
                                                                                name='levelLittle'
                                                                                label={translations.view.labels.little}
                                                                                min={1}
                                                                                max={values.levelMedium - 1}
                                                                                onChange={changeInt}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <FormFieldTextOrNumber
                                                                                type='number'
                                                                                name='levelMedium'
                                                                                label={translations.view.labels.medium}
                                                                                min={values.levelLittle + 1}
                                                                                max={values.levelMany - 1}
                                                                                onChange={changeInt}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <FormFieldTextOrNumber
                                                                                type='number'
                                                                                name='levelMany'
                                                                                min={values.levelMedium + 1}
                                                                                label={translations.view.labels.many}
                                                                                onChange={changeInt}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </CardBody>
                                                </Card>
                                                <SectionEditFormButtons formReset={form.reset}/>
                                            </Container>
                                        </Form>
                                    );
                                }}
                            />
                        </CardBody>
                    </Card>
            </div>
        </Container>
    ) : null;
};

Quantity.propTypes = propTypes;

const mapDispatch = {
    getQuantityPresentation,
    updateQuantityPresentation
};

export default connect(null, mapDispatch)(Quantity);