import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import styles from './index.module.scss';

const propTypes = {
    type: PropTypes.string,
};

const ListElement = ({ type, imageUrl }) => {
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div
                    className={clsx("mb-1", styles.img, type === "tile" ? styles.imgTile : styles.imgBanner)}
                    style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
            </div>
        </React.Fragment>
    );
};

ListElement.propTypes = propTypes;

export default ListElement;