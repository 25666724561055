const notifications = {
    title: '[EN] Powiadomienia',
    table: {
        headers: {
            index: '[EN] Lp.',
            date: '[EN] Data',
            moduleName: '[EN] Moduł',
            errorName: '[EN] Typ',
            description: '[EN] Opis',
            details: '[EN] Szczegóły',
            actions: '[EN] Akcje'
        },
        deleteModal: {
            header: '[EN] Powiadomienie',
            body: '[EN] Czy chcesz usunąć powiadomienie?',
            cancel: '[EN] WRÓĆ',
            confirm: '[EN] USUŃ'
        },
        tooltips: {
            remove: '[EN] Usuń',
        },
        emptyTable: '[EN] Brak powiadomień',
        actions: {
            ignore: 'Usuń zaznaczone',
        }
    },
    buttons: {
        search: '[EN] SZUKAJ',
        reset: '[EN] RESETUJ',
    },
    dropdowns: {
        type: '[EN] Typ',
        module: '[EN] Moduł',
        defaultDropdownOption: '[EN] Wybierz'
    },
    inputs: {
        details: '[EN] Szczegóły',
        module: '[EN] Moduł',
        type: '[EN] Typ',
        onlyIsError: '[EN] Pokaż tylko błędy'
    },
    headerController: {
        unreadNotes: '[EN] Nieprzeczytane powiadomienia: ',
        displayAll: '[EN] Pokaż wszystkie',
        modalHeader: '[EN] Szczegóły powiadomienia',
        buttons: {
            check: '[EN] SPRAWDŹ',
            cancel: '[EN] WRÓĆ',
        }
    },
};

export default notifications;