import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTechnicalBreakBackground, removeTechnicalBreakBackground } from '../../../../redux/methods/extrasSettings';

import FileSender from '../../../../components/FileSender';
import useFetch from '../../../../hooks/useFetch';

import { standardImageExtensions } from '../../../../config';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getTechnicalBreakBackground: PropTypes.func,
    removeTechnicalBreakBackground: PropTypes.func,
    updateTechnicalBreakBackground: PropTypes.func,
    translations: PropTypes.object,
};

const TechnicalBreak = ({ getTechnicalBreakBackground, removeTechnicalBreakBackground, translations, setFile }) => {
    const [ backgroundImage ] = useFetch(getTechnicalBreakBackground);

    return backgroundImage ? (
        <FileSender
            fileKey='BackgroundFile'
            validateFormats={standardImageExtensions}
            handleRemove={removeTechnicalBreakBackground}
            withPreview
            buttons={translations.fileSender.buttons}
            label={translations.fileSender.label}
            photo={backgroundImage.backgroundFile}
            maxSize={1}
            setFile={setFile}
            graphicTypeToEnableGuide='banner'
        />
    ) : null;
};

TechnicalBreak.propTypes = propTypes;

const mapDispatch = {
    removeTechnicalBreakBackground,
    getTechnicalBreakBackground,
};

export default connect(null, mapDispatch)(TechnicalBreak);