/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { connect } from 'react-redux';

import { Table, Row } from "reactstrap";

import TableActionCell from "../TableActionCell";

import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';

import styles from "./index.module.scss";

import { getTranslationForIso } from '../../../utils/shared';

import { cssGradientGenerator } from '../../../utils/attributes';

const propTypes = {
    storeCurrentLanguage: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),

    columnNames: PropTypes.shape({
        color: PropTypes.string,
        name: PropTypes.string,
        actions: PropTypes.string,
    }),
    tooltipTranslations: PropTypes.shape({
        remove: PropTypes.string,
        edit: PropTypes.string
    }),
    deleteModalTranslations: PropTypes.shape({
        header: PropTypes.string,
        body: PropTypes.string,
        confirm: PropTypes.string,
        cancel: PropTypes.string
    }).isRequired,
    emptyTranslations: PropTypes.shape({
        name: PropTypes.string,
        table: PropTypes.string
    }),
    editAction: PropTypes.func,
    removeAction: PropTypes.func,
};

const AttributeColorValuesTable = ({
    data, storeCurrentLanguage, columnNames, tooltipTranslations,
    deleteModalTranslations, emptyTranslations, editAction, removeAction
}) => {
    const handleRemoveAction = id => () => {
        removeAction(id);
    };

    const handleEditAction = id => () => {
        editAction(id);
    };

    return data && data.length > 0 ? (
        <Table responsive hover size="sm">
            <thead>
                <tr>
                    <th className={styles.columnColor}>{columnNames && columnNames.color}</th>
                    <th className={styles.columnName}>{columnNames && columnNames.name}</th>
                    <th className={styles.columnActions}>{columnNames && columnNames.actions}</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map(colorValue => {
                        const generatedBackgroundColor = cssGradientGenerator(colorValue.colors, -225);

                        return (
                            <tr key={`attributeColorValue-${colorValue.attributeColorId}`}>
                                <th scope="row" className={clsx(styles.columnColor, styles.cell)}>
                                    <span
                                        style={{ background: generatedBackgroundColor }}
                                        className={clsx(styles.color, colorValue.colors.length === 0 && styles.colorEmpty)}
                                    />
                                </th>
                                <td className={clsx(styles.columnName, styles.cell, !getTranslationForIso(colorValue.name, storeCurrentLanguage) && 'text-danger')}>
                                    { getTranslationForIso(colorValue.name, storeCurrentLanguage) || emptyTranslations.name }
                                </td>
                                <td className={clsx(styles.columnActions, styles.cell)}>
                                    <TableActionCell
                                        itemId={colorValue.attributeColorId}
                                        actions={{
                                            edit: {
                                                tooltipTranslation: tooltipTranslations.edit,
                                                action: handleEditAction(colorValue.attributeColorId)
                                            },
                                            delete: {
                                                action: handleRemoveAction(colorValue.attributeColorId),
                                                tooltipTranslation: tooltipTranslations.remove,
                                                modalTranslations: {
                                                    header: `${deleteModalTranslations.header} ${getTranslationForIso(colorValue.name, storeCurrentLanguage) || emptyTranslations.name}`,
                                                    body: `${deleteModalTranslations.body} ${getTranslationForIso(colorValue.name, storeCurrentLanguage) || emptyTranslations.name}?`,
                                                    cancel: deleteModalTranslations.cancel,
                                                    confirm: deleteModalTranslations.confirm
                                                },
                                            },
                                        }}
                                    />
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    ) : (
        <Row className="d-flex justify-content-center mt-4 mb-4">
            {emptyTranslations.table}
        </Row>
    );
};

AttributeColorValuesTable.propTypes = propTypes;

const mapStateToProps = (state) => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
};

export default connect(mapStateToProps, mapDispatch)(AttributeColorValuesTable);