const languages = {
    title: '[EN] Języki',
    table: {
        emptyTable: '[EN] Brak języków',
        actions: {
            activate: '[EN] Włącz zaznaczone',
            deactivate: '[EN] Wyłącz zaznaczone',
        },
        name: '[EN] Nazwa',
        iso: '[EN] Iso',
        isActive: '[EN] Aktywny',
    }
};

export default languages;