import React, { useEffect, useState } from 'react';
import { useCycle } from 'framer-motion';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { TabContent, Container, Form, Row, Col, Button, Label } from 'reactstrap';

import useFetch from '../../../../hooks/useFetch';
import { getProductPrices, updateProductPrices } from '../../../../redux/methods/products';
import { getCurrencies } from '../../../../redux/methods/currencies';
import { generateCurrenciesOptionForTabs } from '../../../../utils/products';
import { getTaxRates } from '../../../../redux/methods/extrasSettings';

import { Form as FinalForm } from 'react-final-form';

import TabulatorNav from '../../../../components/TabulatorNav';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import BasePriceEdit from './priceEditForms/BasePriceEdit';

import { priceTypeEnum } from '../../../../enums/products';

import { stringOrNumberPropTypes } from '../../../../propTypes';
import FormFieldWithTaxRatesDropdown from '../../../../components/formFields/FormFieldWithTaxRatesDropdown';
import FormFieldSwitchPill from '../../../../components/formFields/FormFieldSwitchPill';
import PromoPriceEdit from './priceEditForms/PromoPriceEdit';
import { convertDate } from '../../../../utils/components';

const propTypes = {
    id: stringOrNumberPropTypes,
    getProductPrices: PropTypes.func,
    getCurrencies: PropTypes.func,
    updateProductPrices: PropTypes.func,
    productIsFromErp: PropTypes.bool
};

const ProductEditPricesSection = ({
    id,
    getProductPrices,
    updateProductPrices,
    getCurrencies,
    getTaxRates,
    forceRefetch,
    defaultCurrencyId,
    erpPromotions,
    isConnectedToERP,
    productIsFromErp
}) => {
    const translations = useIntl().messages.products.edit.tabs.prices;
    const [ productPrices ] = useFetch(getProductPrices, id, [], forceRefetch);
    const [ currencies ] = useFetch(getCurrencies);
    const [ activePriceTab, setActivePriceTab ] = useState(null);
    const [ taxRates ] = useFetch(getTaxRates);

    const basePriceIsDisabled = productIsFromErp && isConnectedToERP;

    useEffect(() => {
        if(productPrices && productPrices.prices && !activePriceTab) {
            setActivePriceTab(defaultCurrencyId || productPrices.prices[0].currencyId);
        }
    }, [activePriceTab, productPrices]);

    const handleOnSubmit = formData => {
        const withConvertetDates = {...formData, prices: formData.prices.map(price => ({
            ...price,
            dateFrom: price.dateFrom ? convertDate(price.dateFrom) : null,
            dateTo: price.dateTo ? convertDate(price.dateTo) : null,
        }))};

        const filterNullDiscounts = { ...withConvertetDates, prices: withConvertetDates.prices.filter(price => {
            const isDiscountPrice = !!price.basePriceId;

            if(isDiscountPrice && (!price.grossPrice && !price.priceRate)) {
                return false;
            } else {
                return true;
            }
        })};

        updateProductPrices([{ ...filterNullDiscounts, productId: parseInt(id) }]);
    };

    const getCurrentTaxRate = taxId => taxRates && taxRates.find(taxRate => taxRate.id === taxId).rate;

    const calculateNetPrice = (grossPrice, taxId) => {
        const taxRate = getCurrentTaxRate(taxId);

        const netPrice = grossPrice / ((taxRate / 100) + 1);

        return netPrice ? netPrice.toFixed(2) : 0;
    };

    const [showOnlyActiveCurrencies, toggleShowOnlyActiveCurrencies] = useCycle(true, false);

    const activeCurrencyExists = () => currencies.some(c => c.active);

    return productPrices && currencies && activeCurrencyExists() && activePriceTab && taxRates ? (
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={productPrices}
                    mutators={{
                        setNetPrice: ([index, newPrice], state, { changeValue }) => {
                            changeValue(state, `prices[${index}].netPrice`, () => parseFloat(newPrice));
                        },
                        changeAllNetPrice: ([vat], state, { changeValue }) => {
                            const { prices } = state.formState.values;

                            prices.map(
                                (price, index) => changeValue(state, `prices[${index}].netPrice`, () => parseFloat(calculateNetPrice(price.grossPrice, vat)))
                            );
                        },
                    }}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values }) => {
                        const actualPricesForCurrency = values.prices.filter(price => price.currencyId === parseInt(activePriceTab));

                        const basePriceIndex = values.prices.findIndex(price => price.currencyId === parseInt(activePriceTab) && price.priceTypeId === priceTypeEnum.normal);
                        const promoPriceIndex = values.prices.findIndex(price => price.currencyId === parseInt(activePriceTab) && price.priceTypeId === priceTypeEnum.promotional);

                        const basePrice = actualPricesForCurrency.find(price => price.priceTypeId === priceTypeEnum.normal);
                        const promoPrice = actualPricesForCurrency.find(price => price.priceTypeId === priceTypeEnum.promotional);

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Row className='flex-md-nowrap d-inline-flex w-100'>
                                    <div className='w-100'>
                                        <Row>
                                            <Col lg="4">
                                                <Label>&nbsp;</Label>
                                                <Button className='w-100' onClick={toggleShowOnlyActiveCurrencies} color="primary" id="toggler">
                                                    {showOnlyActiveCurrencies ? translations.showAllCurrencies : translations.showOnlyActiveCurrencies}
                                                </Button>
                                            </Col>
                                            <Col lg="4">
                                                <FormFieldWithTaxRatesDropdown
                                                    name='tax'
                                                    label={translations.inputs.tax}
                                                    className='mb-3'
                                                    mutator={value => form.mutators.changeAllNetPrice(value)}
                                                    parse={value => parseFloat(value)}
                                                    isDisabled={basePriceIsDisabled}
                                                />
                                            </Col>
                                            <Col lg="4">
                                                <Label>&nbsp;</Label>
                                                <FormFieldSwitchPill
                                                    className='mt-1'
                                                    label={translations.inputs.isPriceHidden}
                                                    name='isPriceHidden' />
                                            </Col>
                                        </Row>

                                        <TabulatorNav
                                            withoutFlag
                                            setActiveTab={setActivePriceTab}
                                            activeTab={activePriceTab}
                                            tabsList={generateCurrenciesOptionForTabs(currencies, values.prices, showOnlyActiveCurrencies)}
                                        />
                                        <TabContent className='p-3' key={`${basePrice.currencyId}`}>
                                            <BasePriceEdit calculateNetPrice={calculateNetPrice} index={basePriceIndex} form={form}
                                                           values={values} disabled={basePriceIsDisabled}/>
                                            <PromoPriceEdit
                                                calculateNetPrice={calculateNetPrice}
                                                index={promoPriceIndex}
                                                values={values}
                                                form={form}
                                                promoPrice={promoPrice}
                                                basePriceIndex={basePriceIndex}
                                                disabled={basePriceIsDisabled && erpPromotions}
                                            />
                                        </TabContent>
                                    </div>
                                </Row>
                                <SectionEditFormButtons formReset={form.reset} isSaveButtonDisabled={basePrice.netPrice === 0 && !basePrice.priceRate && !basePriceIsDisabled} />
                            </Form>
                        );
                    }}
                />
            </Container>
    ) : null;
};

ProductEditPricesSection.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.productPrice,
    defaultCurrencyId: state.appInit.defaultCurrencyId,
    erpPromotions: state.appInit.erpPromotions,
    isConnectedToERP: state.appInit.isConnectedToERP
});

const mapDispatch = {
    getProductPrices,
    getCurrencies,
    updateProductPrices,
    getTaxRates,
};

export default connect(mapStateToProps, mapDispatch)(ProductEditPricesSection);