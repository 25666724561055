import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal as CoreUiModal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import FormFieldTranslations from '../../formFields/FormFieldTranslations';
import FormFieldMultipleDropDown from '../../formFields/FormFieldMultipleDropDown';

import { formUtilsHocPropTypes } from '../../../propTypes';

import { withFormUtils } from '../../../hoc/withFormUtils';

import styles from './index.module.scss';
import clsx from 'clsx';

const propTypes = {
    utils: formUtilsHocPropTypes,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    translations: PropTypes.object,
    regions: PropTypes.arrayOf(PropTypes.object),
    variant: PropTypes.object
};

const ShippingFormVariantEditionModal = ({ utils, isOpen, setIsOpen, onSubmit, translations, regions, variant }) => {

    const config = { translations: ['name'] };

    const handleOnCancel = () => {
        setIsOpen(false);
    };

    const handleOnSubmit = (form) => {
        onSubmit(utils.generateFormToSubmitValues(form, config));
    };

    return regions && variant ? (
        <CoreUiModal isOpen={isOpen}>
            <FinalForm
                initialValues={utils.generateFormInitialValues(variant, config)}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form }) => {
                    return (
                        <div>
                        <ModalHeader>{translations.title}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleSubmit}>
                                    <div>
                                        <div className='my-2'>
                                            <FormFieldTranslations
                                                form={form}
                                                propertyName='name'
                                                label={translations.inputs.name}
                                                currentIso={utils.currentLanguage()}
                                                modalHeader={translations.inputs.name}
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <FormFieldMultipleDropDown
                                                name='regionIds'
                                                label={translations.inputs.regionIds}
                                                items={regions}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <div className={clsx("d-flex justify-content-between", styles.modalFooterButtons)}>
                                    <Button onClick={handleOnCancel} color='danger'>
                                        <i className="fa fa-remove mr-2"></i>
                                        <span>{translations.buttons.remove}</span>
                                    </Button>
                                    <Button onClick={handleSubmit} color='success'>
                                        <i className="fa fa-save mr-2"></i>
                                        <span>{translations.buttons.save}</span>
                                    </Button>
                                </div>
                            </ModalFooter>
                        </div>
                    );
                }}
            />
        </CoreUiModal>
    ) : null;
};

ShippingFormVariantEditionModal.propTypes = propTypes;

export default withFormUtils(ShippingFormVariantEditionModal);