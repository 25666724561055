const definingButton = {
    isTransparent: 'Przycisk bez wypełnienia',
    button: 'Napis na przycisku',
    colorPicker: 'Wybierz',
    fontColorButton: 'Kolor czcionki',
    backgroundColorButton: 'Kolor tła',
    borderColorButton: 'Kolor obramowania',
    link: 'Odnośnik przycisku',
    linkHelper: 'Aby link był dostępny, musisz podać treść przycisku.',
    selectButton: 'Wybierz przycisk',
    none: 'Brak',
};

export default definingButton;