import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getCurrencies } from '../../../redux/methods/currencies';
import { generateCurrenciesDropdownOptions } from '../../../utils/currencies';
import useFetch from '../../../hooks/useFetch';

import SearchableDropdown from '../../../components/SearchableDropdown';

import { stringOrNumberPropTypes } from '../../../propTypes';

const propTypes = {
    label: PropTypes.string,
    emptySelectionLabel: PropTypes.string,
    currencyId: stringOrNumberPropTypes,
    setCurrencyId: PropTypes.func,
    getCurrencies: PropTypes.func,
};

const SearchableCurrenciesDropdown = ({
    label,
    emptySelectionLabel,
    currencyId,
    setCurrencyId,
    getCurrencies,
    onlyActive = false,
    withNullOption = true,
}) => {
    const [ currencies ] = useFetch(getCurrencies);

    const currentSelection = currencies && currencies.find(
        currency => parseInt(currency.id) === parseInt(currencyId)
    );

    const activeCurrencies = currencies && currencies.filter(item => item.active);

    const generateCurrenciesWithNullOpt = () => [
        {
            id: null,
            label: emptySelectionLabel,
        },
        ...generateCurrenciesDropdownOptions(onlyActive ? activeCurrencies : currencies)
    ];

    const typeOptions = currencies && (withNullOption ? generateCurrenciesWithNullOpt() : generateCurrenciesDropdownOptions(onlyActive ? activeCurrencies : currencies));

    return currencies ? (
        <SearchableDropdown
            label={label}
            options={typeOptions}
            currentSelectionLabel={
                (currentSelection && currentSelection.name) ||
                emptySelectionLabel
            }
            performSelection={setCurrencyId}
        />
    ): null;
};

SearchableCurrenciesDropdown.propTypes = propTypes;

const mapStateToProps = () => ({

});

const mapDispatch = {
    getCurrencies
};

export default connect(mapStateToProps,mapDispatch)(SearchableCurrenciesDropdown);
