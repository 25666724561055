import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { appInit } from '../../redux/methods/appInit';
import { getSelectedFonts } from '../../redux/methods/fonts';

import { adminRoutes } from '../../routes/_adminRoutes';

import FullViewSpinnerWrapper from '../../components/loaders/FullViewSpinnerWrapper';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import SessionEnd from '../../views/SessionTimeout';

const propTypes = {
    isInitialized: PropTypes.bool,
    appInit: PropTypes.func,
};

const MainRouter = ({ isInitialized, appInit, getSelectedFonts }) => {
    const history = useHistory();

    useEffect(() => {
        appInit();
        getSelectedFonts();

        //implement Leaflet map stylesheets
        const $link =  document.createElement('link');
        document.head.appendChild($link);

        $link.rel = "stylesheet";
        $link.href = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.css";
        $link.integrity = "sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ==";
        $link.crossorigin = "";

        //implement all google font styles
        const $fonts =  document.createElement('link');
        document.head.appendChild($fonts);
        $fonts.rel = "stylesheet";
        $fonts.href = "https://pagecdn.io/lib/easyfonts/fonts.css";

    }, []); // eslint-disable-line

    const routes = Object.values(adminRoutes)
        .reduce((acc, item) => {
            if(item.path !== '#'){
                const RouteComponent = item.component;

                return [
                    ...acc,
                    <Route
                        key={item.id}
                        path={item.path}
                        exact={item.exact}
                        render={props => <RouteComponent {...props}/>}
                    />
                ];
            } else {
                const subRoutes = item.children.map(child => {
                    const RouteComponent = child.component;

                    return (
                        <Route
                            key={child.id}
                            path={child.path}
                            exact={child.exact}
                            render={props => <RouteComponent {...props}/>}
                        />
                    );
                });

                return [...acc, ...subRoutes];
            }
        } ,[]);

    const query = new URLSearchParams(history.location.search);
    const target = query.get('target') ?? '/dashboard/general';

    return (
        <FullViewSpinnerWrapper isLoading={!isInitialized}>
            <Switch>
                <Route exact path="/sessionEnd" name="End of Session" render={props => <SessionEnd {...props} />} />
                <DefaultLayout>
                    {routes}
                    {history.location.pathname === '/login' && <Redirect to={target}/>}
                </DefaultLayout>
            </Switch>
        </FullViewSpinnerWrapper>
    );
};

MainRouter.propTypes = propTypes;

const mapStateToProps = state => ({
    isInitialized: state.appInit.isInitialized
});

const mapDispatch = {
    appInit,
    getSelectedFonts,
};

export default connect(mapStateToProps, mapDispatch)(MainRouter);