import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { withFormUtils } from '../../../../hoc/withFormUtils';
import useFetch from '../../../../hooks/useFetch';
import { getTemplateParams, updateTemplateParams } from '../../../../redux/methods/visualSettings';

import SettingsTabulator from '../../../../components/layouts/SettingsTabulator';
import TemplateEditParams from '../../../../features/layouts/editTabs/TemplateEditParams';

import { formUtilsHocPropTypes } from '../../../../propTypes';

const propTypes = {
    getTemplateParams: PropTypes.func,
    location: PropTypes.object,
    utils: formUtilsHocPropTypes,
    forceRefetch: PropTypes.bool,
};

const TemplateEdit = ({
    getTemplateParams,
    location,
    utils,
    forceRefetch,
    updateTemplateParams
}) => {
    const intl = useIntl();
    const translations = intl.messages.visualSettings.layout;

    const query = new URLSearchParams(location.search);
    const templateId = query.get('templateID');

    const [ template ] = useFetch(getTemplateParams, templateId, [], forceRefetch);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'templateEditTab-params':
                return <TemplateEditParams
                    template={template}
                    translations={translations}
                    currentLanguage={utils.currentLanguage()}
                    onSubmit={updateTemplateParams}
                />;
            default:
                return null;
        }
    };

    return templateId ? (
        template && template.templateId ? (
            <SettingsTabulator
                title={{
                    label: template.templateName || translations.fallbackName,
                    iconClassName: 'fa fa-edit text-primary ml-3',
                    isFilled: template.templateName ? false : true,
                }}
                tabulatorHeader={{
                    label: translations.tabSelectorTitle,
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: 'templateEditTab-params',
                        header:  {
                            label: translations.tabs.params,
                            iconClassName: 'fa fa-tasks'
                        }
                    },
                ]}
            />
        ) : null
    ) : (
        <Redirect to='/visualPresentation/layout'/>
    );
};

TemplateEdit.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.visualSettingsParams
});

const mapDispatch = {
    getTemplateParams,
    updateTemplateParams,
};

export default withFormUtils(withRouter(connect(mapStateToProps, mapDispatch)(TemplateEdit)));
