import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { setForceRefetchByTarget } from '../../../features/refetchers/slice';
import { getEmailSettings, deleteEmailSetting, setIsDefault } from '../../../redux/methods/emails';

import useFetch from '../../../hooks/useFetch';

import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';

import SmtpServersTable from '../../../components/tables/SmtpServersTable';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object,
    getEmailSettings: PropTypes.func,
    deleteEmailSetting: PropTypes.func,
    setIsDefault: PropTypes.func,
    forceRefetch: PropTypes.bool,
    setForceRefetchByTarget: PropTypes.func
};

const Servers = ({
    history,
    getEmailSettings,
    deleteEmailSetting,
    setIsDefault,
    forceRefetch,
    setForceRefetchByTarget,
}) => {
    const intl = useIntl();
    const translations = intl.messages.emails.view;

    const [ servers ] = useFetch(getEmailSettings, null, [], forceRefetch);

    const handleAddServer = () => {
        history.push(`/emailSettings/servers/edit?accountId=-1`);
    };

    const handleDeleteAction = async (id) => {
        await deleteEmailSetting(id);
        setForceRefetchByTarget({ target: 'emailSettings', forceRefetch: true });
    };

    const handleUpdateIsDefaultAction = async (id,isDefault) => {
        await setIsDefault(id, isDefault);
        setForceRefetchByTarget({ target: 'emailSettings', forceRefetch: true });
    };

    return servers ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody>
                                <main>
                                    <SmtpServersTable
                                        serversList={servers}
                                        headers={translations.columnHeaders}
                                        translations={translations}
                                        onDeleteAction={handleDeleteAction}
                                        onUpdateIsDefaultAction={handleUpdateIsDefaultAction}
                                    />
                                </main>
                                <aside className={styles.footer}>
                                    <Button color='primary' onClick={handleAddServer}>
                                        <i className='fa fa-plus mr-2'/>
                                        <span>{translations.actions.addServer}</span>
                                    </Button>
                                </aside>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ) : null;
};

Servers.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.emailSettings
});

const mapDispatch = {
    getEmailSettings,
    deleteEmailSetting,
    setIsDefault,
    setForceRefetchByTarget
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Servers));