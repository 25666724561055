import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import FormFieldTranslations from '../../formFields/FormFieldTranslations';

import { Form as FinalForm } from 'react-final-form';
import { Form } from 'reactstrap';

import Modal from '../Modal';
import ColorEditionController from '../../ColorEditionController';
import WarningModal from '../WarningModal';

import { withFormUtils } from '../../../hoc/withFormUtils';
import { formUtilsHocPropTypes } from '../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    data: PropTypes.object,
    colors: PropTypes.arrayOf(PropTypes.object),
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    addColor: PropTypes.func,
    removeColor: PropTypes.func,
    updateColor: PropTypes.func
};

const ColorEditionModal = ({ utils, data, colors, closeModal, onSubmit, addColor, removeColor, updateColor }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.colorEditionModal;

    const config = { translations: ['name'] };

    const isFormChanged = useRef();
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);

    const handleToggle = () => {
        if (isFormChanged.current) {
            setIsWarningModalOpen(true);
        }
        else {
            closeModal();
        }
    };

    return (
        <React.Fragment>
            <FinalForm
                onSubmit={onSubmit}
                initialValues={utils.generateFormInitialValues(data, config)}
                render={({ handleSubmit, form, pristine }) => {
                    isFormChanged.current = !pristine;
                    return (
                        <Modal
                            isOpen={data ? true : false}
                            setIsOpen={handleToggle}
                            header={translations.header}
                            body={
                                <Form onSubmit={handleSubmit} className="w-100 p-0">
                                    <ColorEditionController
                                        colors={colors}
                                        addColor={addColor}
                                        removeColor={removeColor}
                                        updateColor={updateColor}
                                    />
                                    <FormFieldTranslations
                                        form={form}
                                        modalHeader={translations.inputs.name}
                                        propertyName='name'
                                        currentIso={utils.currentLanguage()}
                                        label={translations.inputs.name}
                                    />
                                    <WarningModal
                                        setIsOpen={setIsWarningModalOpen}
                                        isOpen={isWarningModalOpen}
                                        setIsMainModalOpen={closeModal}
                                    />
                                </Form>
                            }
                            buttons={{
                                confirm: {
                                    label: translations.controls.save,
                                    color: 'primary',
                                },
                                cancel: {
                                    label: translations.controls.cancel,
                                    color: 'secondary'
                                }
                            }}
                            onConfirm={handleSubmit}
                        />
                    );
                }}
            />
        </React.Fragment>
    );
};

ColorEditionModal.propTypes = propTypes;

export default withFormUtils(ColorEditionModal);