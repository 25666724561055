import { combineReducers } from '@reduxjs/toolkit';

import persistedLocaleReducer from './persistedLocale/slice';

import storeLocaleReducer from '../storeLocale/slice';

const persisted = combineReducers({
    persistedLocale: persistedLocaleReducer,
    storeLocale: storeLocaleReducer
});

export default persisted;