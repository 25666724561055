import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Field } from 'react-final-form';

import CalendarPicker from '../../../../CalendarPicker';

import styles from './index.module.scss';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    storeCurrentLanguage: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
};

const DateField = ({ name, label, storeCurrentLanguage, minDate, maxDate }) => {
    return (
        <Field name={name}>
            {
                props =>
                    <span className={clsx(styles.calendarInput, 'm-1')}>
                        <span>{label}</span>
                        <CalendarPicker
                            name={props.input[name]}
                            minDateLimit={minDate}
                            maxDateLimit={maxDate}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            locale={storeCurrentLanguage}
                        />
                    </span>
            }
        </Field>
    );
};

DateField.propTypes = propTypes;

export default DateField;