const delivery = {
    title: 'Dostawa',
    fields: {
        deliveryTabTemplate: 'Tekst w zakładce Dostawa na karcie produktu',
        defaultDeliveryCost: 'Domyślny koszt dostawy',
        deliveryCostFieldFromErp: 'Pobieraj informacje o koszcie dostawy z Erp',

        showDeliveryTime: 'Pokazuj informację o czasie dostawy',
        defaultDeliveryTime: 'Domyślna wartość czasu dostawy w dniach (0-365)',
        deliveryTimeFieldFromErp: 'Pobieraj informacje o czasie dostawy z Erp',

        showDeliveryTimeInSummary: 'Pokazuj w podsumowaniu koszyka informacje o czasie dostawy.',
    },
    info: {
        defaultDeliveryCost: 'Uwaga! Dla artykułów bez zdefiniowanego kosztu dostawy zostanie przyjęta wartość domyślna.',
        defaultDeliveryTime: 'Uwaga! Dla artykułu bez zdefiniowanego czasu dostawy zostanie przyjęta wartość domyślna.',
        deliveryTabTemplate: 'Aby było widoczne włącz zakładkę',
        deliveryTabTemplateLink: 'w tym miejscu',
    },
    insertValueButton: 'Wstaw wartość',
    nullOpt: 'Brak',
};

export default delivery;