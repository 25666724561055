/* eslint-disable */
import React from 'react';
import PropTypes from "prop-types";
import clsx from 'clsx';


import { targetTranslatedContent } from '../../../../../../utils/components';

import FormFieldTranslations from '../../../../../../components/formFields/FormFieldTranslations';
import FormFieldTranslationsQuill from '../../../../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldDefiningButton from '../../../../../../components/formFields/FormFieldDefiningButton';
import FormFieldWithDropdown from '../../../../../../components/formFields/FormFieldWithDropdown';
import PreviewModalController from "../../../../../../components/PreviewModalController";
import ButtonPreview from '../../../../../../components/ButtonPreview';

import styles from './index.module.scss';
import getConfig from '../../../../../../getConfig';

const propTypes = {
    translations: PropTypes.object,
    form: PropTypes.object,
    values: PropTypes.object,
};

const EditionFields = ({
    translations,
    form,
    utils,
    values,
    name,
    buttons,
    currentLanguage,
}) => {
    const horizontalAlignment = [
        {
            id: 'Left',
            label: translations.inputs.aligments.left
        },
        {
            id: 'Center',
            label: translations.inputs.aligments.center
        },
        {
            id: 'Right',
            label: translations.inputs.aligments.right
        },
    ];

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;
    const verticalAlignment = [
        {
            id: 'Top',
            label: translations.inputs.aligments.top
        },
        {
            id: 'Center',
            label: translations.inputs.aligments.center
        },
        {
            id: 'Bottom',
            label: translations.inputs.aligments.bottom
        },
    ];

    const getSelectedButtonStyles = (imageName, values) => buttons.find(button => button.id === values[`${imageName}`].buttonId);

    const horizontal = horizontalAlignment.find(opt => opt.id === values[name].horizontalAlignment);
    const vertical = verticalAlignment.find(opt => opt.id === values[name].verticalAlignment);
    return (
        <React.Fragment>
            <div className='mb-3 mt-3'>
                <FormFieldTranslations
                    form={form}
                    modalHeader={translations.inputs.alternativeText}
                    propertyName={`${name}ImageAlternativeText`}
                    currentIso={utils.currentLanguage()}
                    label={translations.inputs.alternativeText}
                />
            </div>
            <div className='mb-3'>
                <FormFieldTranslationsQuill
                    form={form}
                    utils={utils}
                    header={translations.inputs.content}
                    propertyName={`${name}Content`}
                />
            </div>
            <div className={clsx(styles.dropdown, 'mt-3')}>
                <FormFieldWithDropdown
                    currentSelectionLabel={horizontal ? horizontal.label : horizontalAlignment[0].label}
                    label={translations.inputs.horizontalAlignment}
                    name={`${name}.horizontalAlignment`}
                    options={horizontalAlignment}
                />
            </div>
            <div className={clsx(styles.dropdown, 'my-3')}>
                <FormFieldWithDropdown
                    currentSelectionLabel={vertical ? vertical.label : verticalAlignment[0].label}
                    label={translations.inputs.verticalAlignment}
                    name={`${name}.verticalAlignment`}
                    options={verticalAlignment}
                />
            </div>
            <div className='ml-2'>
                <FormFieldDefiningButton
                    form={form}
                    names={{
                        button: `${name}Button`,
                        buttonId: `${name}.buttonId`,
                        buttonLink: `${name}.buttonLink`
                    }}
                    customStructureName={name}
                />
            </div>
            <PreviewModalController
                content={(
                    <div
                        className={clsx(styles.container, name === 'mobile' && styles.mobileWidth)}
                        style={{ backgroundImage: `url(${apiUrl}${values[`${name}`].imageSource})` }}
                    >
                        <div className={styles.contentContainer}>
                            <div className='w-100 h-100' dangerouslySetInnerHTML={{ __html: values[targetTranslatedContent(`${name}Content`)] }}/>
                            {values[targetTranslatedContent(`${name}Button`)] && getSelectedButtonStyles(name, values) &&
                                <div style={{ textAlign: values[`${name}`].horizontalAlignment && values[`${name}`].horizontalAlignment.toLowerCase()}}>
                                    <ButtonPreview
                                        previewTranslation={values[targetTranslatedContent(`${name}Button`, currentLanguage)]}
                                        button={getSelectedButtonStyles(name, values).style}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                )}
            />
        </React.Fragment>
    );
};

EditionFields.propTypes = propTypes;

export default EditionFields;