import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Field } from 'react-final-form';
import { Button } from 'reactstrap';

import ColorPicker from '../../ColorPicker';

import styles from './index.module.scss';

const propTypes = {
    button: PropTypes.string,
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
    name: PropTypes.string,
};

const FormFieldColorPicker = ({ isDisabled, name, button, label }) => {
    const [ isColorPickerVisible, setIsColorPickerVisible ] = useState(false);

    const handleShowColorPicker = () => {
        setIsColorPickerVisible(true);
    };

    const handleHideColorPicker = () => {
        setIsColorPickerVisible(false);
    };

    const handleReset = (callback) => () => {
        callback(null);
    };

    return (
        <Field name={name}>
            {
                props =>
                    <div>
                        <span>{label}</span>
                        <div className={styles.buttonContainer}>
                            <div
                                style={{ background: props.input.value ? props.input.value : null}}
                                className={clsx('mb-2', styles.preview, !props.input.value && styles.previewEmpty)}
                                title={props.input.value ? props.input.value : null}
                            />
                            <span>
                                <Button className='mb-2' disabled={isDisabled} color="primary" type="button" onClick={handleShowColorPicker}>
                                    <i className="fa fa-plus mr-2"/>
                                    <span>{button}</span>
                                </Button>
                                <Button color="danger" type="button" className='ml-2 mb-2' onClick={handleReset(props.input.onChange)}>
                                    <i className="fa fa-times"/>
                                </Button>
                                {
                                    isColorPickerVisible &&
                                        <ColorPicker onAdd={props.input.onChange} color={props.input.value} onClose={handleHideColorPicker}/>
                                }
                            </span>
                        </div>
                    </div>
            }
        </Field>
    );
};

FormFieldColorPicker.propTypes = propTypes;

export default FormFieldColorPicker;