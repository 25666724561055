/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { generateProductEditLink, generateTypeConfig } from '../../../utils/products';
import { productTypes } from '../../../config';

import TableGenerator from '../../generators/TableGenerator';
import PhotoPlaceholder from '../../../assets/photo_placeholder.svg';

import { productListItemPropTypes, stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';
import { UncontrolledTooltip } from 'reactstrap';

import getConfig from '../../../getConfig';

import { productSortableColumnEnum } from '../../../enums/products';

import { firstLetterToLowerCase } from '../../../utils/components';

const propTypes = {
    products: PropTypes.arrayOf(productListItemPropTypes),
    history: PropTypes.object,
    removeAction: PropTypes.func,
    checkedProductsIds: PropTypes.arrayOf(stringOrNumberPropTypes),
    setCheckedProductsIds: PropTypes.func,
};

const ProductTable = ({
    products,
    history,
    removeAction,
    showProduct,
    checkedProductsIds,
    setCheckedProductsIds,
    updateAction,
    currentPage,
    pageSize,
    performPageSizeSelect,
    copyProduct,
    performSort,
    columnSortedByDescendingOrder,
    setColumnSortedByDescendingOrder,
    articleColumns,
    adminArticleColumns,
    utils
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.table;
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const defaultActionHandler = (id, item) => () => {
        history.push(generateProductEditLink(id, item));
    };

    const showDetailsAction = id => () => {
        showProduct(id);
    };

    const deleteAction = id => () => {
        removeAction(id);
    };

    const copyAction = productId => async () => {
        const response = await copyProduct(productId);
        const { payload: { id } } = response;
        if(id && typeof id === 'number') {
            history.push(generateProductEditLink(id));
        }
    };

    const ImageCell = item => (
        <img
            className={styles.photo}
            src={item.productImage ? `${apiUrl}${item.productImage}` : PhotoPlaceholder}
            alt={item.productImageAlternativeText || translations.emptyLogoAlt}
        />
    );

    const WarningCell = item => {
        return item.warning ? <>
            <UncontrolledTooltip placement='top' target={`item-warning-${item.productId}-id`}>
                {item.warning}
            </UncontrolledTooltip>
            <i id={`item-warning-${item.productId}-id`} className="fa fa-exclamation text-warning"></i>
        </> : <span></span>;
    };

    const PromotionCell = item => {
        return item.isPromotion ? <>
            <UncontrolledTooltip placement='top' target={`item-promotion-${item.productId}-id`}>
                {item.promotionInfo}
            </UncontrolledTooltip>
            <i id={`item-promotion-${item.productId}-id`} className="fa fa-percent text-success"></i>
        </> : <span></span>;
    };

    const handleUpdateStatus = (id, status) => {
        const objectToSend = {
            productId: [ id ],
            status: status ? 1 : 0,
        };
        updateAction(objectToSend);
    };

    const handleUpdateSelected = (isActivated) => async () => {
        const objectToSend = {
            productId: checkedProductsIds,
            status: isActivated ? 1 : 0,
        };

        await updateAction(objectToSend);
        setCheckedProductsIds([]);
    };

    const onClickSortHandler = (columnName) => () => {
        setColumnSortedByDescendingOrder(prevState => prevState === columnName ? null : columnName);

        performSort(productSortableColumnEnum[columnName], columnSortedByDescendingOrder === columnName ? 'Desc' : 'Asc');
    };

    const optionalColumns = [
        {
            header: translations.headers.catalogueIndex,
            value: 'catalogueIndex',
            filler: translations.fallbacks.catalogueIndex,
            isSortable: true,
            sortValue: 'catalogueIndex'
        },
        {
            header: translations.headers.tradeIndex,
            value: 'tradeIndex',
            filler: translations.fallbacks.tradeIndex,
            isSortable: true,
            sortValue: 'tradeIndex'
        }
    ];

    const columns = [
        {
            type: 'custom',
            customCell: WarningCell,
            header: '',
        },
        {
            type: 'custom',
            customCell: PromotionCell,
            header: '',
        },
        {
            type: 'checkbox'
        },
        {
            header: "Lp",
            value: 'lp'
        },
        {
            type: 'custom',
            customCell: ImageCell,
            additionalStyle: styles.photoCell
        },
        {
            header: translations.headers.name,
            value: 'productTranslatedName',
            additionalStyle: styles.nameCell,
            filler: translations.fallbacks.name,
            isSortable: true,
            sortValue: 'name'
        },
        {
            header: translations.headers.price,
            value: 'priceInfo'
        }
    ];

    const lastColumns = [
        {
            header: translations.headers.active,
            type: 'switchWithAction',
            onSwitch: handleUpdateStatus,
            value: 'status',
        },
        {
            header: translations.headers.type,
            value: 'variantType',
            type: 'badge',
            config: generateTypeConfig(productTypes, translations.types),
        },
        {
            header: translations.headers.actions,
            type: 'actions'
        }
    ];

    if(adminArticleColumns && adminArticleColumns.length) {
        const columnsToAdd = adminArticleColumns.map(articleColumn => {
            const value = firstLetterToLowerCase(articleColumn);

            let columnToAdd = optionalColumns.find(c => c.value === value);

            if(!columnToAdd) {
                columnToAdd = ({
                    header: articleColumns.find(c => c.id === articleColumn)?.translations.find(n => n.iso.toLowerCase() === utils.currentLanguage())?.value,
                    value: value
                });
            }

            return columnToAdd;
        });

        columns.push(...columnsToAdd);
    }

    columns.push(...lastColumns);

    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            pageSize={pageSize}
            currentPage={currentPage}
            customButtons={[
                {
                    labelTrue: translations.buttons.activate,
                    labelFalse: translations.buttons.deactivate,
                    action: handleUpdateSelected,
                    type: 'selector',
                    isDisabled: checkedProductsIds.length < 1,
                },
            ]}
            editLink={generateProductEditLink}
            expectedActions={['edit', 'showDetails', 'delete', 'copy']}
            items={products}
            columnSortedByDescendingOrder={columnSortedByDescendingOrder}
            onClickSortHandler={onClickSortHandler}
            actions={{showDetailsAction, deleteAction, copyAction}}
            translations={translations}
            defaultAction={defaultActionHandler}
            customIdSelector={'productId'}
            checkedItemsIds={checkedProductsIds}
            setCheckedItemsIds={setCheckedProductsIds}
            columns={columns}
        />
    );
};

ProductTable.propTypes = propTypes;

export default ProductTable;