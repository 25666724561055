import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useFetch from '../../../../../hooks/useFetch';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectStoreAvailableIsoList } from '../../../../../redux/selectors/storeLocale';
import { getAttributeColorValues, updateAttributeColorValue, deleteAttributeColorValue } from '../../../../../redux/methods/attributes';

import { Container, Button } from 'reactstrap';

import AttributeColorValuesTable from '../../../../../components/tables/AttributeColorValuesTable';
import ConnectedColorEditionModal from '../../modals/ConnectedColorEditionModal';

import { createEmptyNameEditObject } from '../../../../../utils/locale';

import styles from './index.module.scss';

const propTypes = {
    attributeId: PropTypes.number,
    forceRefetch: PropTypes.bool,
    storeAvailableIsoList: PropTypes.arrayOf(PropTypes.string),
    getAttributeColorValues: PropTypes.func,
    updateAttributeColorValue: PropTypes.func,
    deleteAttributeColorValue: PropTypes.func
};

const ColorValues = ({
    attributeId, forceRefetch, storeAvailableIsoList,
    getAttributeColorValues,
    updateAttributeColorValue, deleteAttributeColorValue
}) => {
    const intl = useIntl();
    const translations = intl.messages.attributes.edit.tabs.values.colors;

    const [ data ] = useFetch(getAttributeColorValues, attributeId, [], forceRefetch);

    const [ colorValueToEdit, setColorValueToEdit ] = useState(null);

    const handleAddAction = () => {
        setColorValueToEdit({
            attributeId: attributeId,
            attributeColorId: null,
            colors: [],
            name: createEmptyNameEditObject(storeAvailableIsoList)
        });
    };

    const handleEditAction = id => {
        setColorValueToEdit(data.find(d => d.attributeColorId === id));
    };

    const handleRemoveAction = id => {
        deleteAttributeColorValue(id);
    };

    const handleOnSubmit = formData => {
        updateAttributeColorValue(formData);
        setColorValueToEdit(null);
    };

    return data ? (
        <React.Fragment>
            <Container fluid>
                <main>
                    <AttributeColorValuesTable
                        data={data}
                        columnNames={translations.columnNames}
                        tooltipTranslations={translations.actions}
                        deleteModalTranslations={translations.deleteModal}
                        emptyTranslations={translations.empty}
                        editAction={handleEditAction}
                        removeAction={handleRemoveAction}
                    />
                </main>
                <aside className={styles.footer}>
                    <Button color="primary" onClick={handleAddAction}>
                        <i className="fa fa-plus mr-2"/>
                        <span>{translations.actions.addColor}</span>
                    </Button>
                </aside>
            </Container>
            {
                colorValueToEdit &&
                    <ConnectedColorEditionModal
                        data={colorValueToEdit}
                        closeModal={setColorValueToEdit}
                        onSubmit={handleOnSubmit}
                    />
            }
        </React.Fragment>
    ) : null;
};

ColorValues.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.attributesColorValues,
    storeAvailableIsoList: selectStoreAvailableIsoList(state),
});

const mapDispatch = {
    getAttributeColorValues,
    updateAttributeColorValue,
    deleteAttributeColorValue
};

export default connect(mapStateToProps, mapDispatch)(ColorValues);