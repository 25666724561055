const discountParams = {
    entireOrder: [
        {name: 'RequiredMinimumPurchaseAmount', step: '0.01'},
        {name: 'MaximumUsesOfDiscountPerUser'},
        {name: 'MaximumUsesOfDiscountPerShop'},
    ],
    shipmentMethod: [
        {name: 'RequiredMinimumPurchaseAmount', step: '0.01'},
        {name: 'MaximumUsesOfDiscountPerUser'},
        {name: 'MaximumUsesOfDiscountPerShop'},
    ],
    specificProducts: [
        {name: 'RequiredMinimumPurchaseAmount', step: '0.01'},
        {name: 'MaximumUsesOfDiscountPerUser'},
        {name: 'MaximumUsesOfDiscountPerShop'},
        {name: 'RequiredMinimumQuantityOfItems'},
        {name: 'MaximumUsesOfDiscountPerCart'},
    ],
    buyXGetYSingle: [
        {name: 'MaximumUsesOfDiscountPerUser'},
        {name: 'MaximumUsesOfDiscountPerShop'},
        {name :'RequiredMinimumQuantityOfItems'},
        {name: 'MaximumUsesOfDiscountPerCart'},
        {name: 'OfferedQuantity'},
        {name :'OfferedRepetitions'},
    ],
    buyXGetYGroup: [
        {name: 'MaximumUsesOfDiscountPerUser'},
        {name: 'MaximumUsesOfDiscountPerShop'},
        {name: 'RequiredMinimumQuantityOfItems'},
        {name: 'OfferedQuantity'},
        {name: 'OfferedRepetitions'},
    ],
    discountAmount: { name: 'DiscountAmount'},
    discountPercentage: { name: 'DiscountPercentage'},
    discountPercentageAmountLimit: {name: 'DiscountPercentageAmountLimit', step: '0.01'},
};

export default discountParams;