import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Container } from 'reactstrap';

import useFetch from '../../../hooks/useFetch';
import { getVisualSettingsCss, updateVisualSettingsCss } from '../../../redux/methods/visualSettings';

import LayoutController from '../../../components/LayoutController';
import ImageModal from '../../../components/modals/ImageModal';

import { withFormUtils } from '../../../hoc/withFormUtils';
import { formUtilsHocPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getLayouts: PropTypes.func,
    updateVisualSettingsCss: PropTypes.func,
};

const LayoutSettings = ({
    utils,
    getVisualSettingsCss,
    updateVisualSettingsCss,
    forceRefetch,
}) => {
    const intl = useIntl();
    const translations = intl.messages.visualSettings.layout;

    const [ layouts ] = useFetch(getVisualSettingsCss, null, [], forceRefetch);

    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ imageToDisplay, setImageToDispaly ] = useState(null);

    const [ selectedCustomCss, setSelectedCustomCss ] = useState(false);
    const [ selectedLayoutId, setSelectedLayoutId ] = useState(null);
    const [ selectedTemplateId, setSelectedTemplateId ] = useState(null);

    useEffect(() => {
        if (layouts) {
            const hasUserFileUploaded = layouts.layouts.find(l => l.layoutId === layouts.selectedId)?.userFile !== null;
            setSelectedCustomCss(!layouts.shopCssEnabled && hasUserFileUploaded);
            setSelectedLayoutId(layouts.selectedId);
            setSelectedTemplateId(layouts.selectedTemplateId);
        }
    }, [layouts]);

    const handleImageClick = (image) => {
        setImageToDispaly(image);
        setIsModalOpen(!isModalOpen);
    };

    return layouts && layouts.layouts ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeId d-flex justify-content-center'>
                <Container fluid className='w-100 p-0 m-0'>
                    {
                        layouts.layouts.map(layout => (
                            <LayoutController
                                key={`LayoutController-${layout.name}`}
                                onImageClick={handleImageClick}
                                layout={layout}
                                translations={translations}
                                onSubmit={updateVisualSettingsCss}
                                currentLanguage={utils.currentLanguage()}
                                selectedCustomCss={selectedCustomCss}
                                selectedLayoutId={selectedLayoutId}
                                selectedTemplateId={selectedTemplateId}
                            />
                        ))
                    }
                    {
                        imageToDisplay
                            &&  <ImageModal
                                    isOpen={isModalOpen}
                                    setIsOpen={setIsModalOpen}
                                    image={imageToDisplay}
                                />
                    }
                </Container>
            </div>
        </Container>
    ) : null;
};

LayoutSettings.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.visualSettingsCss,
});

const mapDispatch = {
    getVisualSettingsCss,
    updateVisualSettingsCss,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(LayoutSettings));