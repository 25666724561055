import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getHomePageSectionById, addUpdatePageSection } from '../../../redux/methods/homePage';

import useFetch from '../../../hooks/useFetch';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SettingsTabulator from '../../../components/layouts/SettingsTabulator';

import { Alert } from 'reactstrap';

import PromotedSection from '../../../features/homePage/sections/PromotedSection';
import DoubleImageSection from '../../../features/homePage/sections/DoubleImageSection';
import TinySection from '../../../features/homePage/sections/TinySection';
import ArticlesSection from '../../../features/homePage/sections/ArticlesSection';
import PhotoWithTextSection from '../../../features/homePage/sections/PhotoWithTextSection';
import ArticlesWithBookmarks from '../../../features/homePage/sections/ArticlesWithBookmarksSection';
import SliderWithButtonSection from '../../../features/homePage/sections/SliderWithButtonSection';
import ManufacturerSection from '../../../features/homePage/sections/ManufacturerSection';
import VideoSection from '../../../features/homePage/sections/VideoSection';
import AnimatedBannerSection from '../../../features/homePage/sections/AnimatedBannerSection';
import ContactFormSection from '../../../features/homePage/sections/ContactFormSection';
import MapSection from '../../../features/homePage/sections/MapSection';

import ProductsAssigner from '../../../features/sections/productCardSectionEditTabs/ProductsAssigner';
import CategoriesAssigner from '../../../features/sections/productCardSectionEditTabs/CategoriesAssigner';
import AnimatedBannerImagesEditionSection from '../../../features/homePage/bannerTabs/AnimatedBannerImages/AnimatedBannerImagesEditionSection';
import BannerImagesEditionSection from '../../../features/homePage/bannerTabs/BannerImages/BannerImagesEditionSection';

import { sectionAffiliationEnum } from '../../../enums/sections';

const propTypes = {
    getHomePageSectionById: PropTypes.func,
    addUpdatePageSection: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const SectionsEdit = ({ location, getHomePageSectionById, forceRefetch }) => {
    const translations = useIntl().messages.homePage.edit;

    const query = new URLSearchParams(location.search);

    const id = parseInt(query.get('sectionID'));

    const sectionType = query.get('sectionType');

    const sectionLocation = query.get('location');

    const sectionAffiliationObjectId = query.get('affiliationObjectId');

    const [ section ] = useFetch(getHomePageSectionById, { id, type: sectionType }, [id], id < 1 ? false : forceRefetch);

    const updatedSection = {
        ...section,
        affiliation: sectionAffiliationEnum[sectionLocation],
        affiliationObjectId: sectionAffiliationObjectId
    };

    const sectionTypes = {
        Promoted: <PromotedSection section={updatedSection} sectionLocation={sectionLocation} />,
        DoubleImage: <DoubleImageSection section={updatedSection} sectionLocation={sectionLocation} />,
        Tiny: <TinySection section={updatedSection} sectionLocation={sectionLocation} />,
        Articles: <ArticlesSection section={updatedSection} sectionLocation={sectionLocation}  />,
        PhotoWithText: <PhotoWithTextSection section={updatedSection} sectionLocation={sectionLocation} />,
        ArticlesWithBookmarks: <ArticlesWithBookmarks section={updatedSection} isProductCard={sectionLocation === 'productCard'} sectionLocation={sectionLocation} />,
        SliderWithButton: <SliderWithButtonSection section={updatedSection} sectionLocation={sectionLocation} />,
        Manufacturer: <ManufacturerSection section={updatedSection} sectionLocation={sectionLocation} />,
        Video: <VideoSection section={updatedSection} sectionLocation={sectionLocation} />,
        AnimatedBanner: <AnimatedBannerSection section={updatedSection} sectionLocation={sectionLocation} />,
        ContactForm: <ContactFormSection section={updatedSection} sectionLocation={sectionLocation} />,
        Map: <MapSection section={updatedSection} sectionLocation={sectionLocation} />
    };

    const sectionHasTabs = (sectionLocation === 'productCard') || (section?.type === 'SliderWithButton' || section?.type === 'AnimatedBanner');

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'sectionEdit':
                return <React.Fragment>
                    {sectionHasTabs && id < 0 &&
                        <Alert color="info" className='mt-2'>
                            {translations.information}
                        </Alert>
                    }
                    {sectionTypes[section.type]}
                </React.Fragment>;
            case 'bannerImages':
                return <BannerImagesEditionSection bannerId={id} />;
            case 'animatedBannerImages':
                return <AnimatedBannerImagesEditionSection section={section} animatedBannerId={id} />;
            case 'product assigner':
                return <ProductsAssigner id={id} type={sectionType} />;
            case 'category assigner':
                return <CategoriesAssigner id={id} type={sectionType} />;
            default:
                return null;
        }
    };

    return section ? (
        sectionHasTabs ?
            <SettingsTabulator
                title={translations.title}
                tabulatorHeader={{
                    iconClassName: 'fa fa-cog'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: 'sectionEdit',
                        header: {
                            label: translations.title,
                            iconClassName: 'fa fa-bookmark'
                        }
                    },
                    section.type === 'SliderWithButton' ? {
                        id: 'bannerImages',
                        header: {
                            label: translations.bannerImages,
                            iconClassName: 'fa fa-object-group'
                        },
                        disabled: id <= 0,
                    } : null,
                    section.type === 'AnimatedBanner' ? {
                        id: 'animatedBannerImages',
                        header: {
                            label: translations.animatedBannerImages,
                            iconClassName: 'fa fa-object-group'
                        },
                        disabled: id <= 0,
                    } : null,
                    sectionLocation === 'productCard' ? {
                        id: 'product assigner',
                        header: {
                            label: translations.productAssigner,
                            iconClassName: 'fa fa-clipboard'
                        },
                        disabled: id <= 0 || sectionLocation !== 'productCard'
                    } : null,
                    sectionLocation === 'productCard' ? {
                        id: 'category assigner',
                        header: {
                            label: translations.categoryAssigner,
                            iconClassName: 'fa fa-object-group'
                        },
                        disabled: id <= 0 || sectionLocation !== 'productCard'
                    } : null,
                ]}
            /> :
            <ContainerWithCard title={translations.title} iconClass='fa fa-bookmark' >
                {sectionTypes[section.type]}
            </ContainerWithCard>
    ) : null;
};

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.homePageSections
});

const mapDispatch = {
    getHomePageSectionById,
    addUpdatePageSection
};

SectionsEdit.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatch)(withRouter(SectionsEdit));