import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getProducts } from '../../../redux/methods/products';
import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';
import useFetch from '../../../hooks/useFetch';
import useSearchConfig from '../../../hooks/useSearchConfig';
import { translateProductName } from '../../../utils/products';

import Modal from '../../../components/modals/Modal';
import ProductsWithoutActionsTable from '../../../components/tables/ProductsWithoutActionsTable';
import ProductSearchForm from '../../../components/ProductSearchForm';
import Paginator from '../../../components/Paginator';

import { productsSearchConfigPropTypes, stringOrNumberPropTypes } from '../../../propTypes';
import { productsSearchConfig } from '../../../config/searchConfigs';

import styles from './index.module.scss';

const propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    checkedProductsId: PropTypes.arrayOf(stringOrNumberPropTypes),
    setCheckedProductId: PropTypes.func,
    onConfirm: PropTypes.func,
    isFetched: PropTypes.bool,
    getProducts: PropTypes.func,
    searchConfig: productsSearchConfigPropTypes,
    setCheckedProductsId: PropTypes.func,
    totalPage: PropTypes.number,
    currentPage: PropTypes.number,
    storeChosenLanguage: PropTypes.string,
    storeDefaultLanguage: PropTypes.string,
    storeCurrentLanguage: PropTypes.string
};

const ProductSearchModal = ({
    isOpen,
    setIsOpen,
    checkedProductsId,
    setCheckedProductsId,
    onConfirm,
    getProducts,
    storeCurrentLanguage,
    additionalSearchConfig = {},
    additionalBody
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.modalSearch;

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig({ ...productsSearchConfig, ...additionalSearchConfig });
    const [ products, pages ] = useFetch(getProducts, searchConfig, [searchConfig]);

    const handleSetIsOpen = () => {
        getProducts(searchConfig);
        setIsOpen();
    };

    const translatedProducts = products && translateProductName(products, storeCurrentLanguage);

    return products ? (
        <Modal
            className='modal-xl'
            isOpen={isOpen}
            setIsOpen={handleSetIsOpen}
            buttons={{
                confirm: {
                    color: 'success',
                    label: translations.buttons.confirm,
                    disabled: !(checkedProductsId.length > 0)
                },
                cancel: {
                    color: 'danger',
                    label: translations.buttons.cancel,
                },
            }}
            header={translations.header}
            body={
                <React.Fragment>
                    {
                        additionalBody
                    }
                    <ProductSearchForm
                        performProductSearch={changeSearchConfig}
                        searchConfig={searchConfig}
                        additionalDeafaultSearchConfig={additionalSearchConfig}
                    />
                    <div className={clsx(styles.container, translatedProducts.length > 0 && 'mb-3')}>
                        <ProductsWithoutActionsTable
                            checkedProductsId={checkedProductsId}
                            setCheckedProductsId={setCheckedProductsId}
                            translatedProductsList={translatedProducts}
                        />
                    </div>
                    {
                        pages > 1 &&
                            <Paginator
                                totalPages={pages}
                                currentPage={searchConfig.curentPage + 1}
                                onPageChange={changePage}
                            />
                    }
                </React.Fragment>
            }
            onConfirm={onConfirm}
        />
    ) : null;
};

ProductSearchModal.propTypes = propTypes;

const mapStateToProps = (state) => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
    getProducts
};

export default connect(mapStateToProps, mapDispatch)(ProductSearchModal);
