import MetatagsSection from '../../views/extrasSettings/MetatagsSection';
import GoogleSiteMap from '../../features/seo/GoogleSiteMap';
import PhotoNames from '../../features/seo/PhotoNames';
import OwnCode from '../../features/seo/OwnCode';

export const seoRoutes = {
    ROUTE_SEO_SITEMAP: {
        id: 'ROUTE_SEO_SITEMAP',
        path: '/seo/sitemap',
        type: 'seo',
        exact: true,
        icon: 'cil-sitemap',
        component: GoogleSiteMap
    },

    ROUTE_SEO_IMAGES: {
        id: 'ROUTE_SEO_IMAGES',
        path: '/seo/images',
        type: 'seo',
        exact: true,
        icon: 'cil-image',
        component: PhotoNames
    },
    /*
    ROUTE_SEO_ADWORDS: {
        id: 'ROUTE_SEO_ADWORDS',
        path: '/seo/adwords',
        type: 'seo',
        exact: true,
        icon: 'fab fa-google',
        component: null,
    },
    */
    ROUTE_SEO_METATAGS: {
        id: 'ROUTE_SEO_METATAGS',
        path: '#',
        type: 'seo',
        exact: true,
        icon: 'cil-code',
        children: [
            {
                id: 'ROUTE_SEO_METATAGS_CART',
                path: '/metaTags/cart',
                component: MetatagsSection,
                icon: 'fal fa-shopping-cart'
            },
            {
                id: 'ROUTE_SEO_METATAGS_ORDER_AUTHORIZE',
                path: '/metaTags/orderAuthorize',
                component: MetatagsSection,
                icon: 'fal fa-user-check'
            },
            {
                id: 'ROUTE_SEO_METATAGS_ORDER_GUEST',
                path: '/metaTags/orderGuest',
                component: MetatagsSection,
                icon: 'fal fa-house-return'
            },
            {
                id: 'ROUTE_SEO_METATAGS_ORDER_SHIPMENT',
                path: '/metaTags/orderShipment',
                component: MetatagsSection,
                icon: 'fal fa-truck'
            },
            {
                id: 'ROUTE_SEO_METATAGS_ORDER_PAYMENT',
                path: '/metaTags/orderPayment',
                component: MetatagsSection,
                icon: 'fal fa-money-check-alt'
            },
            {
                id: 'ROUTE_SEO_METATAGS_ORDER_SUMMARY',
                path: '/metaTags/orderSummary',
                component: MetatagsSection,
                icon: 'fal fa-money-check'
            },
            {
                id: 'ROUTE_SEO_METATAGS_ORDER_GREETINGS',
                path: '/metaTags/orderGreetings',
                component: MetatagsSection,
                icon: 'fal fa-handshake'
            },
            {
                id: 'ROUTE_SEO_METATAGS_ORDER_INACTIVE',
                path: '/metaTags/orderInactive',
                component: MetatagsSection,
                icon: 'fal fa-empty-set'
            },
            {
                id: 'ROUTE_SEO_METATAGS_FAVOURITES',
                path: '/metaTags/favourites',
                component: MetatagsSection,
                icon: 'fal fa-star'
            },
            {
                id: 'ROUTE_SEO_METATAGS_404',
                path: '/metaTags/error404',
                component: MetatagsSection,
                icon: 'fal fa-bug'
            },
            {
                id: 'ROUTE_SEO_METATAGS_REGISTRATION',
                path: '/metaTags/registration',
                component: MetatagsSection,
                icon: 'fal fa-user-plus'
            },
            {
                id: 'ROUTE_SEO_METATAGS_LOGIN',
                path: '/metaTags/login',
                component: MetatagsSection,
                icon: 'fal fa-laptop-house'
            },
            {
                id: 'ROUTE_SEO_METATAGS_PASSWORD_RECOVERY',
                path: '/metaTags/passwordRecovery',
                component: MetatagsSection,
                icon: 'fal fa-key'
            },
            {
                id: 'ROUTE_SEO_METATAGS_PASSWORD_SET_NEW_PASSWORD',
                path: '/metaTags/setNewPassword',
                component: MetatagsSection,
                icon: 'fal fa-keyboard'
            },
            {
                id: 'ROUTE_SEO_METATAGS_COMPARISION',
                path: '/metaTags/comparison',
                component: MetatagsSection,
                icon: 'fal fa-balance-scale-right'
            },
            {
                id: 'ROUTE_SEO_METATAGS_CONTACT',
                path: '/metaTags/contact',
                component: MetatagsSection,
                icon: 'fal fa-phone'
            },
            {
                id: 'ROUTE_SEO_METATAGS_USERPROFILE_ACCOUNT',
                path: '/metaTags/userProfileAccount',
                component: MetatagsSection,
                icon: 'fal fa-user'
            },
            {
                id: 'ROUTE_SEO_METATAGS_USERPROFILE_BASIC',
                path: '/metaTags/userProfileBasic',
                component: MetatagsSection,
                icon: 'fal fa-user'
            },
            {
                id: 'ROUTE_SEO_METATAGS_USERPROFILE_ADDRESSBOOK',
                path: '/metaTags/userProfileAddressBook',
                component: MetatagsSection,
                icon: 'fal fa-user'
            },
            {
                id: 'ROUTE_SEO_METATAGS_USERPROFILE_ORDERS',
                path: '/metaTags/userProfileOrders',
                component: MetatagsSection,
                icon: 'fal fa-user'
            },
            {
                id: 'ROUTE_SEO_METATAGS_USERPROFILE_SETTINGS',
                path: '/metaTags/userProfileSettings',
                component: MetatagsSection,
                icon: 'fal fa-user'
            },
            {
                id: 'ROUTE_SEO_METATAGS_GLOBAL_SEARCH',
                path: '/metaTags/globalSearch',
                component: MetatagsSection,
                icon: 'fal fa-search'
            }
        ]
    },

    ROUTE_SEO_OWNCODE: {
        id: 'ROUTE_SEO_OWNCODE',
        path: '/seo/owncode',
        type: 'seo',
        exact: true,
        icon: 'cil-code',
        component: OwnCode
    }
};