import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Input } from 'reactstrap';

import { stringOrNumberPropTypes } from '../../../../../propTypes';

const propTypes = {
    isChecked: PropTypes.bool,
    itemId: stringOrNumberPropTypes,
    checkboxAction: PropTypes.func,
    additionalStyle: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

const CheckboxCell = ({
    isChecked,
    itemId,
    checkboxAction,
    additionalStyle,
    className,
    disabled,
}) => {
    return (
        <td className={className}>
            <Input
                checked={isChecked}
                id={`checkboxItem-${itemId}`}
                type='checkbox'
                onChange={checkboxAction(itemId)}
                className={clsx('position-static m-0 p-0', additionalStyle)}
                disabled={disabled}
            />
        </td>
    );
};

CheckboxCell.propTypes = propTypes;

export default CheckboxCell;