import React from 'react';
import { firstLetterToUpperCase } from '../../utils/components';

const groupBy = (list, callBack) => {
    return list.reduce((acc, element) => {
        (acc[callBack(element)] = acc[callBack(element)] || []).push(element);
        return acc;
    }, {});
};

const convertToWeights = fonts => [...new Set(fonts.map(font => {
    const variant = font.substring(font.lastIndexOf('-')+1);
    if (variant === 'regular') { return '0,400'; }
    if (variant === 'italic') { return '1,400'; }
    if (variant.includes('italic')) { return '1,' + variant.substring(0,3); }
    return '0,' + variant.substring(0,3);
}))].sort();

export const extractVariant = font => font.includes('-') ? font.substring(font.lastIndexOf('-') + 1) : null;

export const convertToFontName = font => !font.includes('-') ? firstLetterToUpperCase(font) : font.substring(0, font.lastIndexOf('-')).split('-').map(item => firstLetterToUpperCase(item)).join(' ');
export const convertToFontNameLowered = font => (!font.includes('-') ? font : font.substring(0, font.lastIndexOf('-')).split('-').join(' ')).toLowerCase();

export const fontVariantToStr = variant => {
    if(!variant) {
        return null;
    }
    if (variant === 'regular') { return 'krój zwykły, grubość 400'; }
    if (variant === 'italic') { return 'kursywa, grubość 400'; }
    if (variant.includes('italic')) { return 'kursywa, grubość ' + variant.slice(0,3); }
    return 'krój zwykły, grubość '+variant.slice(0,3);
};

export const getGoogleFontsString = (fonts) => {
    return [...new Set(fonts.map(font => `${convertToFontName(font)}=${convertToFontNameLowered(font)}`))].join(';');
};

export const getGoogleFontsUrl = (fonts) => {

    const variantSuffixRx = /([1-9]00)?regular$|([1-9]00)?italic$|[1-9]00$/;

    const grouppedFonts = groupBy(fonts.filter(font => variantSuffixRx.test(font)), f => f.substring(0, f.lastIndexOf('-')));

    const fontFamilies = [];
    for(const fontName in grouppedFonts) {
        const familyName = fontName.split('-').map(string => firstLetterToUpperCase(string)).join('+');
        fontFamilies.push(`${familyName}:ital,wght@${convertToWeights(grouppedFonts[fontName]).join(';')}`);
    }

    return `https://fonts.googleapis.com/css2?${fontFamilies.map(family => `family=${family}`).join('&')}&display=swap`;
};

export const translateFontFamilyToObject = font => {
    return {
        id: font,
        label: convertToFontName(font),
        variant: fontVariantToStr(extractVariant(font))
    };
};

export const translateFontFamilyToList = list => list.reduce((acc, font) => [
    ...acc,
    ...font.variants.reduce((acc2, variant) => [ ...acc2, {
        id: font.family.split(' ').join('-').toLowerCase() + '-' + variant,
        label: font.family,
        variant: fontVariantToStr(variant),
        subsets: font.subsets
    }], [])
], []);

export const translateListToFontFamily = (list, fontConfig) => list.map(font => {
    const fontName = convertToFontName(font);
    return ({
        id: font,
        label: fontName,
        variant: fontVariantToStr(extractVariant(font)),
        defaultTemplates: fontConfig.map(config => config.defaultFont === fontName && config.template).filter(value => value)
    });
});

export const FontListItem = (item) => {
    const font = item.id.substring(0, item.id.lastIndexOf('-'));
    return (
        <>
            <span className={`font-${font}`}>
                {item.label}
                {
                    item.defaultTemplates?.length > 0 &&
                    <i className={"fa fa-exclamation-circle ml-2"}/>
                }
            </span>
            {item.variant && <small> - {item.variant}</small>}
        </>
    );
};