const paymentForms = {
    formsTable: {
        title: 'Formy płatności',
        headers:{
            id: 'Id',
            name: 'Nazwa formy płatności',
            paymentMethod: 'Typ usługi',
            productName: 'Usługa płatności',
            wfMagFormName: 'Forma płatności z systemu ERP',
            paymentForms: 'Przypisane formy płatności',
            payment: 'Płatność',
            visibility: 'Widoczność',
            actions: 'Akcje'
        },
        tooltips: {
            edit: 'Edytuj',
            remove: 'Usuń'
        },
        buttons: {
            create: 'Dodaj',
            confirm: 'Zapisz',
            cancel: 'Anuluj'
        },
        editModal:{
            title: 'Edycja formy płatności',
            inputs:{
                name: 'Nazwa formy płatności',
                paymentMethodId: 'Typ usługi',
                productId: 'Usługa płatności',
                wfMagFormId: 'Forma płatności z systemu ERP'
            },
            buttons:{
                remove: 'WRÓĆ',
                save: 'ZAPISZ'
            },
            nameModalHeader: 'Edycja nazwy formy dostawy',
            blikInfo: {
                label: 'Należy włączyć integrację z ',
                linkPayU: 'PayU',
                linkP24: 'Przelewy24'
            }
        },
        deleteModal:{
            header: 'Usuń formę płatności',
            body: 'Czy jesteś pewien, że chcesz usunąć tę formę płatności?',
            confirm: 'Usuń',
            cancel: 'Wróć'
        },
        errors:{
            productIdIsRequired: 'Wybranie wartości w polu "Usługa płatności" jest wymagane',
            wfMagFormIdIsRequired: 'Wybranie wartości w polu "Forma płatności z systemu ERP" jest wymagane'
        }
    }
};

export default paymentForms;