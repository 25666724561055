import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getIntegrationPayU, updateIntegrationPayU } from '../../../redux/methods/integrations';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';

import { Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';

const propTypes = {
    getIntegrationPayU: PropTypes.func,
    updateIntegrationPayU: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const PayU = ({
    getIntegrationPayU,
    updateIntegrationPayU,
    forceRefetch
}) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.payu;

    const [ data ] = useFetch(getIntegrationPayU, null, [], forceRefetch);

    return data ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-cogs'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateIntegrationPayU}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>
                                <div className='my-2'><FormFieldSwitchPill label={translations.labels.enabled} name='enabled' /></div>

                                <div className='row'>
                                    <div className='col-12 col-xl-6'>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.posId} name='posId' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.clientId} name='clientId' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.secondKeyMd5} name='secondKeyMd5' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.clientSecret} name='clientSecret' /></div>
                                    </div>
                                    <div className='col-12 col-xl-6'>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.authUrl} name='authUrl' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.apiUrl} name='apiUrl' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.statusUrl} name='statusUrl' /></div>
                                        <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.returnUrl} name='returnUrl' /></div>
                                    </div>
                                </div>

                                <div className='mt-3'><FormFieldTextOrNumber label={translations.labels.descriptionTemplate} name='descriptionTemplate' /></div>

                                <SectionEditFormButtons formReset={form.reset}/>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconClass='fa fa-facebook'/>;
};

const mapDispatch = {
    getIntegrationPayU,
    updateIntegrationPayU
};

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.payUIntegration
});

PayU.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatch)(PayU);