/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';


import { getTranslationForIso } from '../../../utils/components';

import PhotoPlaceholder from '../../../assets/photo_placeholder.svg';
import styles from './index.module.scss';
import TableGenerator from '../../generators/TableGenerator';
import getConfig from '../../../getConfig';

const propTypes = {
    translations: PropTypes.object,
    items: PropTypes.array,
};

const ProductTileFillersTable = ({
    translations,
    items,
    onEditAction,
    removeAction,
    currentLanguage,
    handleOnSwitch
}) => {
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const editAction = (value, item) => () => {
        onEditAction(item.id);
    };

    const deleteAction = id => () => {
        removeAction(id);
    };

    const ImageCell = item => (
        <img
            className={styles.image}
            src={item.image ? `${apiUrl}${item.image}` : PhotoPlaceholder}
            alt={item.translatedImageAlternativeText || translations.emptyLogoAlt}
        />
    );

    const translatedFillers = items.map(item => ({
        ...item,
        translatedHeader: getTranslationForIso(item.header, currentLanguage),
        translatedImageAlternativeText: getTranslationForIso(item.imageAlternativeText, currentLanguage)
    }));

    return (
        <TableGenerator
            items={translatedFillers}
            translations={translations}
            expectedActions={['edit', 'delete']}
            actions={{editAction, deleteAction}}
            defaultAction={editAction}
            columns={[
                {
                    header: translations.headers.image,
                    type: 'custom',
                    customCell: ImageCell,
                    additionalStyle: styles.imageCell
                },
                {
                    header: translations.headers.name,
                    value: 'translatedHeader',
                    additionalStyle: styles.nameCell
                },
                {
                    header: translations.headers.active,
                    value: 'isActive',
                    type: 'switchWithAction',
                    onSwitch: handleOnSwitch
                },
                {
                    header: translations.headers.actions,
                    type: 'actions',
                }
            ]}
        />
    );
};

ProductTileFillersTable.propTypes = propTypes;

export default ProductTileFillersTable;