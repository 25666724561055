import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MediaItem from './MediaItem';

import styles from './index.module.scss';
import EditModal from './MediaItem/EditModal';
import FolderItem from './FolderItem';
import { stringOrNumberPropTypes } from '../../../propTypes';

const propTypes = {
    editMode: PropTypes.bool,
    files: PropTypes.array,
    folders: PropTypes.array,
    checked: PropTypes.array,
    setChecked: PropTypes.func,
    changeFolder: PropTypes.func,
    searchInput: PropTypes.string,
    folderId: stringOrNumberPropTypes,
    refreshMedia: PropTypes.func,
    changeFileName: PropTypes.func,
    selectionMode: PropTypes.bool,
};

const MediaList = ({ editMode, files, folders, checked, setChecked, changeFolder, searchInput, folderId, refreshMedia, changeFileName, selectionMode = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [editedId, setEditedId] = useState(null);

    return (
        <div className={styles.container}>
            {folders.map((folder, index) =>
                <FolderItem
                    editMode={editMode}
                    setChecked={setChecked}
                    checked={checked?.includes(folder.id)}
                    key={`${folder.id}-index-${index}`}
                    item={folder}
                    changeFolder={changeFolder}
                />
            )}
            {files.map((file, index) =>
                <MediaItem
                    setEditedId={setEditedId}
                    setIsOpen={setIsOpen}
                    editMode={editMode || selectionMode}
                    setChecked={setChecked}
                    checked={selectionMode ? !!checked?.find(item => item.id === file.id)  : checked?.includes(file.id)}
                    item={file}
                    searchInput={searchInput}
                    key={`${file.id}-index-${index}`}
                    selectionMode={selectionMode}
                />
            )}
            {editedId && <EditModal
                changeFileName={changeFileName}
                refreshMedia={refreshMedia}
                folderId={folderId}
                searchInput={searchInput}
                setEditedId={setEditedId}
                editedId={editedId}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />}
        </div>
    );
};

MediaList.propTypes = propTypes;

export default MediaList;