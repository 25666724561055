import React from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

import IntlWrapper from './IntlWrapper';
import RootRouter from './routers/RootRouter';

import 'react-sortable-tree/style.css';
import './App.scss';

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <IntlWrapper>
                    <RootRouter />
                </IntlWrapper>
            </PersistGate>
        </Provider>
    );
};

export default App;
