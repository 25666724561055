import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    fonts: [],
    fontConfig: {}
};

const fonts = createSlice({
    name: 'fonts',
    initialState,
    reducers: {
        setFonts(state, action) {
            const { fonts } = action.payload;
            state.fonts = fonts;
        },
        setFontConfig(state, action) {
            const { fontConfig } = action.payload;
            state.fontConfig = fontConfig;
        }
    }
});

export const { setFonts, setFontConfig } = fonts.actions;

export default fonts.reducer;