import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getOrders } from '../../../redux/methods/orders';
import { getCurrencies } from '../../../redux/methods/currencies';
import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';
import { setForceRefetchByTarget } from '../../../features/refetchers/slice';
import useFetch from '../../../hooks/useFetch';
import useSearchConfig from '../../../hooks/useSearchConfig';
import { clearError } from '../../../features/errors/slice';
import { generateOrdersConfig, createOrdersDropdownOptions, generateOrderListCSV, generateEditLinkForOrder } from '../../../utils/orders';
import { filterItemWithIds } from '../../../utils/components';

import { Container, Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import { CSVLink } from "react-csv";

import Paginator from '../../../components/Paginator';
import OrdersTable from '../../../components/tables/OrdersTable';
import OrdersSearchForm from '../../../components/OrdersSearchForm';
import StatusDescriptionSection from '../../../components/StatusDescriptionSection';

import SendPackageModal from '../../../features/orders/SendPackageModal';
import { ordersSearchConfig } from '../../../config/searchConfigs';

import { formUtilsHocPropTypes, orderConfigsPropTypes } from '../../../propTypes';

import styles from './index.module.scss';
import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    getOrders: PropTypes.func,
    getCurrencies: PropTypes.func,
    orderStatuses: orderConfigsPropTypes,
    forceRefetch: PropTypes.bool,
    storeCurrentLanguage: PropTypes.string,
    orderAttributes: orderConfigsPropTypes,
    history: PropTypes.object,
    utils: formUtilsHocPropTypes,
    setForceRefetchByTarget: PropTypes.func
};

const DashboardOrders = ({
    getOrders,
    getCurrencies,
    orderStatuses,
    forceRefetch,
    storeCurrentLanguage,
    history,
    orderAttributes,
    utils,
    setForceRefetchByTarget,
    clearError
}) => {
    const intl = useIntl();
    const translations = intl.messages.orders.view;
    const [ currencies ] = useFetch(getCurrencies);
    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(ordersSearchConfig);
    const [ orders, pages ] = useFetch(getOrders, searchConfig, [searchConfig], forceRefetch);
    const [columnSortedByDescendingOrder, setColumnSortedByDescendingOrder ] = useState(null);
    const [checkedOrdersIds, setCheckedOrdersIds] = useState([]);
    const [ isSendPackagesModalOpen, setIsSendPackagesModalOpen ] = useState(false);

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    const handleShowDetails = id => {
        history.push(`/dashboard/orders/details?orderID=${id}`);
    };

    const handlePrintAction = id => {
        //TODO pass proper print method
        console.info('print', id);
    };

    const handleEditAction = id => {
        history.push(generateEditLinkForOrder(id));
    };

    const handlePerformSort = (columnName, direction) => {
        changeSearchConfig({ orderByColumnName: columnName, orderDirection: direction, pageSize: searchConfig.pageSize });
    };

    const attributesConfig = generateOrdersConfig(orderAttributes, storeCurrentLanguage);
    const statusConfig = generateOrdersConfig(orderStatuses, storeCurrentLanguage);

    const attributesDropdownOptions = createOrdersDropdownOptions(attributesConfig, translations.ordersSearch.defaultDropdownOption);
    const statusDropdownOptions = createOrdersDropdownOptions(statusConfig, translations.ordersSearch.defaultDropdownOption);

	const selectedOrders = orders && filterItemWithIds(orders, checkedOrdersIds);
    const ordersToDownload = orders && generateOrderListCSV(selectedOrders);

    const handleSendPackages = () => {
        const wrongStatuses = selectedOrders.filter(s =>
            s.orderStatusTag !== 'New' &&
            s.orderStatusTag !== 'Completing');

        if (wrongStatuses.length > 0) {
            const numbers = wrongStatuses.reduce((acc, item) => acc + (acc === '' ? '' : ', ') + item.number, '');
            utils.error('Następujące zamówienia znajdują się obecnie w stanie uniemożliwiającym nadanie: ' + numbers);
        }
        else {
            setIsSendPackagesModalOpen(!isSendPackagesModalOpen);
        }
    };

    const handleAfterSendPackages = async () => {
        setCheckedOrdersIds([]);
        clearError();
        await setForceRefetchByTarget({ target: 'orders', forceRefetch: true });
        setIsSendPackagesModalOpen(false);
    };

    return currencies && orders && orderStatuses.length > 0 ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody>
                                <main>
                                    <OrdersSearchForm
                                        onSubmit={changeSearchConfig}
                                        translations={translations}
                                        storeCurrentLanguage={storeCurrentLanguage}
                                        currencies={currencies}
                                        statusOptions={statusDropdownOptions}
                                        attributesOptions={attributesDropdownOptions}
                                        searchConfig={searchConfig}
                                    />
                                    <OrdersTable
                                        performPageSizeSelect={handleChangePageSize}
                                        pageSize={searchConfig.pageSize}
                                        currentPage={searchConfig.curentPage + 1}
                                        orders={orders}
                                        headers={translations.headerLabels}
                                        translations={translations}
                                        statusConfig={statusConfig}
                                        showDetailsAction={handleShowDetails}
                                        printAction={handlePrintAction}
                                        editAction={handleEditAction}
                                        performSort={handlePerformSort}
                                        setColumnSortedByDescendingOrder={setColumnSortedByDescendingOrder}
                                        columnSortedByDescendingOrder={columnSortedByDescendingOrder}
                                        checkedOrdersIds={checkedOrdersIds}
                                        setCheckedOrdersIds={setCheckedOrdersIds}
                                    />
                                    <Row className='d-flex justify-content-between w-100 align-items-end'>
                                        {
                                            pages > 1 &&
                                                <Paginator
                                                    totalPages={pages}
                                                    currentPage={searchConfig.curentPage + 1}
                                                    onPageChange={changePage}
                                                />
                                        }
                                        {
                                            orders.length > 0 &&
                                            <div>
                                                <Button color="primary" onClick={handleSendPackages} className='mr-2'>
                                                        <i className="fa fa-truck mr-2"/>
                                                        <span>{translations.packagesButton}</span>
                                                </Button>
                                                <Button color='primary'>
                                                    <CSVLink
                                                        data={ordersToDownload}
                                                        filename={`${translations.title}.csv`}
                                                        target='_blank'
                                                        className={styles.downloadLink}
                                                    >
                                                        {translations.downloadButton}
                                                    </CSVLink>
                                                </Button>
                                            </div>
                                        }
                                    </Row>
                                    <StatusDescriptionSection
                                        statuses={statusConfig}
                                        header={translations.headerLabels.statusDescription}
                                    />
                                </main>
                            </CardBody>
							<SendPackageModal
                                selectedOrders={selectedOrders}
                                modalLabel={translations.sendPackagesModalLabel}
                                isOpen={isSendPackagesModalOpen}
                                closeSendPackageModal={handleAfterSendPackages}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ) : null;
};

DashboardOrders.propTypes = propTypes;

const mapStateToProps = state => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
    orderStatuses: state.appInit.orderStatuses,
    forceRefetch: state.refetchers.orders,
    orderAttributes: state.appInit.orderAttributes,
});

const mapDispatch = {
    getOrders,
    getCurrencies,
    setForceRefetchByTarget,
    clearError
};

export default withFormUtils(withRouter(connect(mapStateToProps, mapDispatch)(DashboardOrders)));