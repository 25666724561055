import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getIntegrationFurgonetka, updateIntegrationFurgonetka } from '../../../redux/methods/integrations';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';

import { Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';

const propTypes = {
    getIntegrationFurgonetka: PropTypes.func,
    updateIntegrationFurgonetka: PropTypes.func,
};

const Furgonetka = ({ getIntegrationFurgonetka, updateIntegrationFurgonetka }) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.furgonetka;

    const [ data ] = useFetch(getIntegrationFurgonetka);

    return data ? (
        <ContainerWithCard title={translations.title} iconclassName='fa fa-cogs'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateIntegrationFurgonetka}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>
								<div className='my-2'><FormFieldSwitchPill label={translations.labels.enabled} name='enabled' /></div>
								<div className='row'>
									<div className='col-12 col-xl-6'>
										<div className='card'>
											<div className='card-header'>{translations.headers.urls}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.apiUrl} name='apiUrl' /></div>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.authUrl} name='authUrl' /></div>
											</div>
										</div>
										<div className='card'>
											<div className='card-header'>{translations.headers.ids}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.clientId} name='clientId' /></div>
												<div className='my-2'><FormFieldTextOrNumber type='password' label={translations.labels.clientSecreet} name='clientSecreet' /></div>
												<div className='row'>
													<div className='my-2 col-6'><FormFieldTextOrNumber label={translations.labels.userEmail} name='userEmail' /></div>
													<div className='my-2 col-6'><FormFieldTextOrNumber type='password' label={translations.labels.userPassword} name='userPassword' /></div>
												</div>
											</div>
										</div>
										<div className='card'>
											<div className='card-header'>{translations.headers.senderAddress}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.pickupName} tooltipText={translations.labels.pickupNameTooltip} name='pickupName' /></div>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.pickupCompany} tooltipText={translations.labels.pickupCompanyTooltip} name='pickupCompany' /></div>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.pickupStreet} name='pickupStreet' /></div>
												<div className='row'>
													<div className='my-2 col-6'><FormFieldTextOrNumber label={translations.labels.pickupPostcode} name='pickupPostcode' /></div>
													<div className='my-2 col-6'><FormFieldTextOrNumber label={translations.labels.pickupCity} name='pickupCity' /></div>
												</div>
												<div className='row'>
													<div className='my-2 col-6'><FormFieldTextOrNumber label={translations.labels.pickupCountryCode} name='pickupCountryCode' /></div>
													<div className='my-2 col-6'><FormFieldTextOrNumber label={translations.labels.pickupCounty} tooltipText={translations.labels.pickupCountyTooltip} name='pickupCounty' /></div>
												</div>
												<div className='row'>
													<div className='my-2 col-6'><FormFieldTextOrNumber label={translations.labels.pickupEmail} name='pickupEmail' /></div>
													<div className='my-2 col-6'><FormFieldTextOrNumber label={translations.labels.pickupPhone} name='pickupPhone' /></div>
												</div>
											</div>
										</div>
										<div className='card'>
											<div className='card-header'>{translations.headers.shipment}</div>
											<div className='card-body'>
												<div className='row'>
													<div className='my-2 col-4'><FormFieldTextOrNumber label={translations.labels.defaultShipmentWidth} name='defaultShipmentWidth' /></div>
													<div className='my-2 col-4'><FormFieldTextOrNumber label={translations.labels.defaultShipmentDepth} name='defaultShipmentDepth' /></div>
													<div className='my-2 col-4'><FormFieldTextOrNumber label={translations.labels.defaultShipmentHeight} name='defaultShipmentHeight' /></div>
												</div>
												<div className='row'>
													<div className='my-2 col-4'><FormFieldTextOrNumber label={translations.labels.defaultShipmentWeight} name='defaultShipmentWeight' /></div>
													<div className='my-2 col-4'><FormFieldTextOrNumber label={translations.labels.defaultShipmentValue} tooltipText={translations.labels.defaultShipmentValueTooltip} name='defaultShipmentValue' /></div>
												</div>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.defaultShipmentDescription} tooltipText={translations.labels.defaultShipmentDescriptionTooltip} name='defaultShipmentDescription' /></div>
												<div className='row'>
													<div className='mt-3 col-6'><FormFieldWithDropdown label={translations.labels.defaultShipmentServiceId} name='defaultShipmentServiceId' options={data.serviceOptions} /></div>
													<div className='mt-3 col-6'><FormFieldWithDropdown label={translations.labels.defaultShipmentType} name='defaultShipmentType'options={data.typeOptions} /></div>
												</div>
											</div>
										</div>
										<div className='card'>
											<div className='card-header'>{translations.headers.costCenter}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.defaultCostCenter} tooltipText={translations.labels.defaultCostCenterTooltip} name='defaultCostCenter' /></div>
											</div>
										</div>
										<div className='card'>
											<div className='card-header'>{translations.headers.cod}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.defaultCodAmount} name='defaultCodAmount' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultCodExpress} tooltipText={translations.labels.defaultCodExpressTooltip} name='defaultCodExpress' /></div>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.defaultCodIban} name='defaultCodIban' /></div>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.defaultCodName} name='defaultCodName' /></div>
											</div>
										</div>
									</div>
									<div className='col-12 col-xl-6'>

										<div className='card'>
											<div className='card-header'>{translations.headers.settings.general}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceAdditionalHandling} name='defaultAdditionalServiceAdditionalHandling' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceSaturdaySundayDelivery} name='defaultAdditionalServiceSaturdaySundayDelivery' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceWaitingTime} name='defaultAdditionalServiceWaitingTime' /></div>
											</div>
										</div>

										<div className='card'>
											<div className='card-header'>{translations.headers.settings.ups}</div>
											<div className='card-body'>
												{/*<div className='my-2'><FormFieldTextOrNumber label={translations.labels.pickupPoint} name='pickupPointUps' /></div>*/}
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceRod} name='defaultAdditionalUpsServiceRod' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServicePrivateShipping} name='defaultAdditionalUpsServicePrivateShipping' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceUpsSaver} name='defaultAdditionalUpsServiceUpsSaver' /></div>
											</div>
										</div>

										{/*<div className='card'>
											<div className='card-header'>{translations.headers.settings.inpost}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.pickupPoint} name='pickupPointInpost' /></div>
											</div>
										</div>*/}

										<div className='card'>
											<div className='card-header'>{translations.headers.settings.inpostkurier}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceRod} name='defaultAdditionalInpostKurierServiceRod' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceSaturdayDelivery} name='defaultAdditionalInpostKurierServiceSaturdayDelivery' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceSmsPredeliveryInformation} name='defaultAdditionalInpostKurierServiceSmsPredeliveryInformation' /></div>
											</div>
										</div>

										<div className='card'>
											<div className='card-header'>{translations.headers.settings.poczta}</div>
											<div className='card-body'>
												{/*<div className='my-2'><FormFieldTextOrNumber label={translations.labels.pickupPoint} name='pickupPointPoczta' /></div>*/}
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceRod} name='defaultAdditionalPocztaServiceRod' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceGuarantee1200} name='defaultAdditionalPocztaServiceGuarantee1200' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceValuableShipment} name='defaultAdditionalPocztaServiceValuableShipment' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceFragile} name='defaultAdditionalPocztaServiceFragile' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServicePersonalDelivery} name='defaultAdditionalPocztaServicePersonalDelivery' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServicePocztaKurier48} name='defaultAdditionalPocztaServicePocztaKurier48' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceRegisteredLetter} name='defaultAdditionalPocztaServiceRegisteredLetter' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceRegisteredCompanyLetter} name='defaultAdditionalPocztaServiceRegisteredCompanyLetter' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceRegisteredLetterInternational} name='defaultAdditionalPocztaServiceRegisteredLetterInternational' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServicePocztaGlobalexpres} name='defaultAdditionalPocztaServicePocztaGlobalexpres' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceDeliveryConfirmation} name='defaultAdditionalPocztaServiceDeliveryConfirmation' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServicePocztaExpres24} name='defaultAdditionalPocztaServicePocztaEkspres24' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalPocztaServiceGuarantee0800} name='defaultAdditionalPocztaServiceGuarantee0800' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalPocztaServiceDeliveryAfter1700} name='defaultAdditionalPocztaServiceDeliveryAfter1700' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalPocztaServiceCheckContent} name='defaultAdditionalPocztaServiceCheckContent' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalPocztaServiceDeliveryOnTime} name='defaultAdditionalPocztaServiceDeliveryOnTime' /></div>
											</div>
										</div>

										{/*<div className='card'>
											<div className='card-header'>{translations.headers.settings.ruch}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldTextOrNumber label={translations.labels.pickupPoint} name='pickupPointRuch' /></div>
											</div>
										</div>*/}

										<div className='card'>
											<div className='card-header'>{translations.headers.settings.dpd}</div>
											<div className='card-body'>
												{/*<div className='my-2'><FormFieldTextOrNumber label={translations.labels.pickupPoint} name='pickupPointDpd' /></div>*/}
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceRod} name='defaultAdditionalDpdServiceRod' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceCud} name='defaultAdditionalDpdServiceCud' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServicePrivateShipping} name='defaultAdditionalDpdServicePrivateShipping' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceGuarantee0930} name='defaultAdditionalDpdServiceGuarantee0930' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceGuarantee1200} name='defaultAdditionalDpdServiceGuarantee1200' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceSaturdayDelivery} name='defaultAdditionalDpdServiceSaturdayDelivery' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceDocumentsSupply} name='defaultAdditionalDpdServiceDocumentsSupply' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceGuaranteeNextDay} name='defaultAdditionalDpdServiceGuaranteeNextDay' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServicePersonalDelivery} name='defaultAdditionalDpdServicePersonalDelivery' /></div>
											</div>
										</div>

										<div className='card'>
											<div className='card-header'>{translations.headers.settings.dhl}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceDocumentsSupply} name='defaultAdditionalDhlServiceDocumentsSupply' /></div>
											</div>
										</div>

										<div className='card'>
											<div className='card-header'>{translations.headers.settings.fedex}</div>
											<div className='card-body'>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceRod} name='defaultAdditionalFedexServiceRod' /></div>
												<div className='my-2'><FormFieldSwitchPill thin='true' label={translations.labels.defaultAdditionalServiceFedexPriority} name='defaultAdditionalFedexServiceFedexPriority' /></div>
											</div>
										</div>
									</div>
								</div>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconclassName='fa fa-facebook'/>;
};

const mapDispatch = {
    getIntegrationFurgonetka,
    updateIntegrationFurgonetka,
};

Furgonetka.propTypes = propTypes;

export default connect(null, mapDispatch)(Furgonetka);