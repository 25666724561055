import { setStoreAvailableLanguages, setStoreDefaultLanguage } from '../../../features/storeLocale/slice';
import {
    setIsInitialized, setOrderStatuses, setOrderAttributes, setOrderAdditionalFields, setSessionTimeoutValue,
    setActivatedErpId, setIsShopCategoryActive, setIsConnectedToERP, setNotificationsTypesAndModules, setIsShopUnitsActive,
    setLastSynchronizationDate, setDefaultCurrencyId, setErpPromotions, setPayUEnabled, setP24Enabled, setAdminArticleColumns
} from '../../../features/appInit/slice';
import { addEditData } from '../generic';
import { setUser } from '../../../features/auth/slice';
import { setFontConfig } from '../../../features/fonts/slice';

import { handleCAPIErrorsSubtle, cAPI } from '../../../utils/cAPI';

export const appInit = () => async dispatch => {
    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APAppInit();
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if (payLoad) {
        const {
            languages, orderAttributes, orderStatuses, orderAdditionalFields,
            sessionLengthInMinutes, activatedErpId, isShopCategoryActive,
            types, modules, isShopUnitsActive, user, lastSynchronizationDate,
            fontConfig, defaultCurrencyId, erpPromotions, payUEnabled, p24Enabled,
            adminArticleColumns
        } = payLoad;

        dispatch(setStoreAvailableLanguages({ storeAvailableLanguages: languages.languageOptionsList }));
        dispatch(setStoreDefaultLanguage({ storeDefaultLanguage: 'pl' }));
        dispatch(setIsInitialized({ isInitialized: true }));
        dispatch(setOrderAttributes({ orderAttributes: orderAttributes }));
        dispatch(setOrderStatuses({ orderStatuses: orderStatuses }));
        dispatch(setOrderAdditionalFields({ orderAdditionalFields: orderAdditionalFields }));
        dispatch(setSessionTimeoutValue({ sessionLengthInMinutes: sessionLengthInMinutes }));
        dispatch(setActivatedErpId({ activatedErpId: activatedErpId }));
        dispatch(setIsConnectedToERP({ isConnectedToERP: !!activatedErpId }));
        dispatch(setIsShopCategoryActive({ isShopCategoryActive: isShopCategoryActive }));
        dispatch(setIsShopUnitsActive({ isShopUnitsActive: isShopUnitsActive }));
        dispatch(setNotificationsTypesAndModules({ types: types, modules: modules }));
        dispatch(setUser({ user: user }));
        dispatch(setLastSynchronizationDate({ lastSynchronizationDate }));
        dispatch(setFontConfig({ fontConfig }));
        dispatch(setDefaultCurrencyId({ defaultCurrencyId }));
        dispatch(setErpPromotions({ erpPromotions }));
        dispatch(setPayUEnabled({ payUEnabled }));
        dispatch(setP24Enabled({ p24Enabled }));
        dispatch(setAdminArticleColumns({ adminArticleColumns }));
    } else if (errorToHandle) {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};

export const extendSession = () => dispatch => addEditData(dispatch, cAPI.APExtendSession, null, 'appInit', false, false, false, true );