const banner = {
    tabSelectorTitle: '[EN] Strona Główna > Banner',
    settings: {
        inputs: {
            pauseOnHover: '[EN] Zatrzymaj gdy najedziesz kursorem',
            autoPlay: '[EN] Automatyczne przewijanie',
            enabled: '[EN] Aktywny',
            animationType: '[EN] Typ animacji',
            animationFallback: '[EN] Wybierz typ',
            link: '[EN] Odnośnik tła',
            dateTo: '[EN] Aktywny do',
            dateFrom: '[EN] Aktywny od',
            buttonLink: '[EN] Odnośnik przycisku',
            desktopImage: '[EN] Zdjęcie w widoku desktop',
            tabletImage: '[EN] Zdjęcie w widoku tablet',
            mobileImage: '[EN] Zdjęcie w widoku mobile',
            autoPlayDuration: '[EN] Czas wyświetlania zdjęcia (sek)',
            alternativeText: '[EN] Tekst alternatywny',
            conent: '[EN] Treść',
            verticalAlignment: '[EN] Ustawienie przycisku w pionie',
            file: {
                upload: '[EN] PRZEŚLIJ ZDJĘCIE',
                remove: '[EN] USUŃ WSZYSTKIE ZDJĘCIA',
                edit: '[EN] PODMIEŃ ZDJĘCIE',
            },
            aligments: {
                left: '[EN] Lewo',
                center: '[EN] Środek',
                right: '[EN] Prawo',
                top: '[EN] Góra',
                bottom: '[EN] Dół',
            },
        },
        animations: {
            fade: '[EN] Przenikanie',
            standard: '[EN] Slider'
        },
        actions: {
            save: '[EN] ZAPISZ ZMIANY',
            cancel: '[EN] ANULUJ',
            add: '[EN] DODAJ SLAJD'
        },
        editModal: {
            header: '[EN] Edytuj slajd'
        },
        location: '[EN] Lokalizacja'
    },
    tabs: {
        parameters: '[EN] Parametry',
        images: '[EN] Zdjęcia'
    },
    columnNames: {
        order: '[EN] Kolejność',
        desktopImage: '[EN] Obraz desktop',
        tabletImage: '[EN] Obraz tablet',
        mobileImage: '[EN] Obraz mobile',
        activeInLanguage: '[EN] Aktywne w językach',
        link: '[EN] Link',
        dates: '[EN] Okres aktywności',
        alternativeText: '[EN] Tekst alternatywny',
        actions: '[EN] Akcje',
        content: '[EN] Treść'
    },
    emptyTable: '[EN] Brak obrazów do wyświetlenia',
    tooltipTranslations: {
        edit: '[EN] Edytuj',
        remove: '[EN] Usuń'
    },
    deleteModalTranslations: {
        header: '[EN] Usuń slajd',
        body: '[EN] Czy chcesz usunąć wybrany slajd?',
        confirm: '[EN] Usuń',
        cancel: '[EN] Wróć'
    }
};

export default banner;