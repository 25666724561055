import React from 'react';
import PropTypes from 'prop-types';

import TableActionCell from '../../../../tables/TableActionCell';
import ActionsDropdown from '../../../../ActionsDropdown';
import { generateCellActions, generateDropdownActions } from '../../../../../utils/components';

import { expectedActionsPropTypes, deleteModalPropTypes, stringOrNumberPropTypes } from '../../../../../propTypes';

const propTypes = {
    className: PropTypes.string,
    actionCellType: PropTypes.string,
    expectedActions: expectedActionsPropTypes,
    actions: PropTypes.shape({
        showDetailsAction: PropTypes.func,
        printAction: PropTypes.func,
        editAction: PropTypes.func,
        deleteAction: PropTypes.func,
    }),
    translations: PropTypes.shape({
        tooltips: PropTypes.shape({
            edit: PropTypes.string,
            remove: PropTypes.string,
            print: PropTypes.string,
            showDetails: PropTypes.string,
        }),
        deleteModal: deleteModalPropTypes,
    }),
    dropdownLabel: PropTypes.string,
    itemId: stringOrNumberPropTypes,
    editLink: PropTypes.oneOfType([
        PropTypes.string, PropTypes.func
    ]),
};

const ActionsCell = ({
    className,
    actionCellType,
    expectedActions,
    actions,
    item,
    translations,
    dropdownLabel,
    itemId,
    editLink,
    checkIfActionIsDisabled
}) => {

    return (
        <td className={className}>
            {
                actionCellType === 'dropdown'
                    ? <ActionsDropdown
                        actions={generateDropdownActions(expectedActions, actions)}
                        item={item}
                        itemId={itemId}
                        translations={translations.tooltips}
                        dropdownLabel={dropdownLabel}
                        checkIfActionIsDisabled={checkIfActionIsDisabled}
                    />
                    : <TableActionCell
                        item={item}
                        itemId={itemId}
                        actions={generateCellActions({expectedActions, actions, translations, editLink, item}, itemId)}
                        checkIfActionIsDisabled={checkIfActionIsDisabled}
                    />
            }
        </td>
    );
};

ActionsCell.propTypes = propTypes;

export default ActionsCell;