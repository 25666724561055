const orders = {
    greetings: {
        title: 'Edycja treści podziękowań po złożeniu zamówienia',
        summaryContent: 'Treść podziękowań po złożeniu zamówienia',
        button: 'Zapisz',
    },
    view: {
        title: "Orders list",
        headerLabels: {
            contrahentName: 'Contrahent name',
            contrahentNumber: 'Contrahent number',
            number: 'Number',
            status: 'Status',
            preferredDocument: 'Preferowany dokument',
            data: 'Date',
            actions: 'Actions',
            currency: 'Currency',
            checkbox: 'Select',
            attributes: 'Attributes',
            attachment: 'Attachment',
            email: 'E-mail',
            statusDescription: 'Description of the statuses',
            orderIndex: 'Index',
            packageNumber: 'Nr przesyłki',
            packageService: 'Serwis przesyłki',
            packageStatus: 'Status przesyłki'
        },
        tooltips: {
            showDetails: 'See details',
            edit: 'Edit order',
            print: 'Print'
        },
        sortableColumns: {
            Date: 'Data',
            ContrahentName: 'Nazwa kontrahenta',
            TotalGrossPrice: 'Wartość brutto',
            OrderStatus: 'Status zamówienia',
        },
        ordersSearch: {
            status: 'Status',
            contrahentName: 'Contrahent name',
            number: 'Number:',
            dateFrom: 'Placed from:',
            dateTo: 'Placed to:',
            currency: 'Currency:',
            defaultDropdownOption: 'Default',
            attribute: 'Attribute',
            contrahentEmail: 'E-mail'
        },
        searchButton: 'SEARCH',
        resetButton: 'RESET',
		packagesButton: 'Send packages',
        downloadButton: 'Download selected orders',
        emptyTable: 'No orders to display',
        sendPackagesModalLabel: 'Nadaj paczki'
    },
    edit: {
        inputs: {
            status: 'Order status',
            shipmentDate: 'Shipment date',
            invoiceNumber: 'Invoice number',
            comment: 'Comment',
        },
        tabs: {
            details: 'Order details'
        },
    },
    details: {
        headers: {
            index: 'Index',
            image: 'Thumbnail',
            name: 'Name',
            id: 'ID',
            amount: 'Amount',
            realized: 'Realized amount',
            grossPrice: 'Gross Price',
            grossValue: 'Gross value',
            discountName: 'Kupon rabatowy'
        },
        emptyLogoAlt: 'Placeholder logo',
        emptyTable: 'No products to display.',
        sectionHeader: 'Order number',
        orderDetails: {
            number: 'Number',
            date: 'Date',
            totalGrossPrice: 'Gross order value',
            orderStatus: 'Order status',
            paymentMethod: 'Payment method',
            invoiceNumber: 'Invoice number',
            invoice: 'Download invoice',
            returnNumber: 'Return number',
            contrahentName: 'Contrahent name',
            contrahentNumber: 'Contrahent number',
            totalWeight: 'Total weight',
            shippingMethod: 'Shipping method',
            shipmentAddressName: 'Name',
            shipmentAddressSurname: 'Surname',
            shipmentAddressAddress: 'Street',
            shipmentAddressZipCode: 'Zip-code',
            shipmentAddressCity: 'City',
            shipmentAddressCountry: 'Country',
            shipmentAddressVoivodeship: 'State',
            shipmentAddressPhoneNumber: 'Phone',
            shipmentAddressEmail: 'E-mail',
            orderStatusChangeDate: 'Last order status change',
            ean: 'Barcode',
            packageNumber: 'Package number',
            comment: 'Comments',
            attributes: 'Attributes',
            attachement: 'Attachment',
        }
    },
	sendPackages: {
		selectedOrdersTable: {
            headers: {
                date: 'Data',
                number: 'Numer',
                totalGrossPrice: 'Kwota brutto',
                serviceId: 'Usługa',
                dimension1: 'Długość [cm]',
                dimension2: 'Wysokość [cm]',
                dimension3: 'Szerokość [cm]',
                weight: 'Waga [kg]',
                value: 'Wartość',
                nonStandard: 'Niestandardowa',
                types: 'Rodzaj przesyłki',
                description: 'Opis',
            }
        },
		steps: {
			chooseService: 'Wybierz operatora paczek',
			setProperties: 'Ustaw parametry paczek'
		},
        buttons:{
            prev: 'Wstecz',
            next: 'Dalej',
            send: 'Wyślij'
        },
        noServices: 'Nie zdefiniowanego żadnego operatora nadawania paczek. Przejdź do modułu integracji i zdefiniuj przynajmniej jedną integrację z operatorem paczek.',
        none: 'Brak',
        selectService: 'Wybierz operatora paczek',
        sendPackagesErrors: 'Podczas nadawania paczek wystąpiły błędy'
	},
    orderDetails: {
        generalDetails: {
            caption: 'Szczegóły zamówienia',
            fieldId: 'Identyfikator',
            fieldNumber: 'Numer',
            fieldPaymentMethod: 'Metoda płatności',
            fieldShippingMethod: 'Metoda wysyłki',
            fieldOrderStatus: 'Status',
            fieldOrderStatusErp: 'Status w ERP',
            fieldErpNumber: 'Numer w ERP',
            fieldDiscountName: 'Kupon rabatowy'
        },
        puchaserDetails: {
            caption: 'Dane zamawiającego',
            fieldCompanyNamePersonName: 'Nazwa firmy / imię i nazwisko',
            fieldNip: 'Nip',
            fieldAddress: 'Adres',
            fieldZipCode: 'Kod pocztowy',
            fieldCity: 'Miasto',
            fieldCountry: 'Kraj'
        },
        odbiorOsobisty: {
            caption: 'Dane punktu odbioru',
            fieldAddress: 'Adres punktu odbioru'
        },
        odbiorWPunkcie: {
            caption: 'Dane punktu odbioru',
            fieldName: 'Nazwa punktu odbioru',
            fieldType: 'Typ punktu odbioru',
            fieldStreet: 'Adres punktu odbioru',
            fieldZipCode: 'Kod pocztowy',
            fieldCity: 'Miasto',
            fieldPhone: 'Nr telefonu',
            fieldOptions: 'Opcje'
        },
        inPost: {
            caption: 'Dane paczkomatu',
            fieldCode: 'Kod punktu odbioru',
            fieldAddress: 'Adres punktu odbioru',
            fieldPhoneNumber: 'Nr telefonu do kontaktu'
        },
        wysylkaNaAdres: {
            caption: 'Adres wysyłki',
            fieldNameSurname: 'Imię i nazwisko',
            fieldAddress: 'Ulica i nr domu',
            fieldZipCodeCity: 'Kod pocztowy, miasto',
            fieldPhoneNumber: 'Nr telefonu',
            fieldEmail: 'e-mail'
        },
        status: {
            caption: 'Status zamówienia',
            fieldPlacingDate: 'Data złożenia',
            fieldFulfillmentDate: 'Data przygotowania',
            fieldShipmentDate: 'Data wysyłki',
            fieldInvoiceNumber: 'Nr faktury',
            fieldPackageNumber: 'Nr przesyłki',
            fieldReturnNumber: 'Nr zwrotu',
            statusChangeDate: 'Data zmiany statusu',
            erpStatusChangeDate: 'Data zmiany statusu w ERP',
            statusChangeMessage: 'Informacja o zmianie statusu'
        },
        prices: {
            caption: 'Wartość zamówienia',
            fieldCurrency: 'Waluta',
            fieldTotalNetPrice: 'Cena netto',
            fieldTotalGrossPrice: 'Cena brutto',
            fieldTotalWeight: 'Waga całkowita'
        },
        additionalInfo: 'Uwagi kupującego'
    }
};

export default orders;