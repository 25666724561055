import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import { Progress, Tooltip } from 'reactstrap';
import noImage from '../../../../assets/noImage.png';
import clsx from 'clsx';
import getConfig from '../../../../getConfig';

const propTypes = {
    item: PropTypes.object,
    checked: PropTypes.bool,
    editMode: PropTypes.bool,
    setChecked: PropTypes.func,
    setEditedId: PropTypes.func,
    setIsOpen: PropTypes.func,
};

const MediaItem = ({ item, checked, editMode, setChecked, setEditedId, setIsOpen, selectionMode }) => {
    const getOpacity = () => editMode ? ( checked ? 1 : 0.5) : 1;
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const cutText = text => text && text.length > 25 ? `${text.slice(0, 25)}...` : text;

    const style = {
        backgroundImage: item.thumbnailSource ? `url(${apiUrl}${item.thumbnailSource})` : item.image ? `url(${item.image})` : `url(${noImage})`,
        border: checked ? '3px solid #20A8D8' : 'none',
        opacity: getOpacity(),
    };

    const handleClick = () => {
        if(selectionMode && editMode) {
            setChecked(prev => checked ? prev.filter(i => i.id !== item.id) : [...prev, item]);
        } else if(editMode) {
            setChecked(prev => checked ? prev.filter(i => i !== item.id) : [...prev, item.id]);
        } else {
            setEditedId(item.id);
            setIsOpen(true);
        }
    };
    return (
            <div className={styles.wrapper}>
                <div id={`tooltip-filename-${item.id}`} onClick={handleClick} style={style} className={styles.item}>
                    <input className={clsx(styles.checkbox, editMode && styles.checkboxVisible)} type="checkbox" checked={checked} onChange={() => null} />
                    {item.percentage && <Progress striped color="success" value={item.percentage} />}
                </div>
                <span>{cutText(item.fileName)}</span>
                <Tooltip placement="right" isOpen={tooltipOpen} target={`tooltip-filename-${item.id}`} toggle={toggle}>
                    {item.folder || item.fileName}
                </Tooltip>
            </div>
    );
};

MediaItem.propTypes = propTypes;

export default MediaItem;