/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { dropdownStatusPropTypes } from '../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    selectedStatusId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedStatus: dropdownStatusPropTypes,
    setSelection: PropTypes.func,
    label: PropTypes.string,
    statuses: PropTypes.arrayOf(dropdownStatusPropTypes),
    forcedColor: PropTypes.string,
    disabled: PropTypes.bool,
};

const EditStatusControl = ({ selectedStatusId, selectedStatus, setSelection, label, statuses, forcedColor, disabled }) => {

    if (typeof(selectedStatus) === "undefined"){
        selectedStatus = statuses.find(s => s.id === selectedStatusId);
    }

    const [ isOpen, setIsOpen ] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleSetSelection = id => () => {
        setSelection(id);
    };

    return (
        <div className={styles.container}>
            <span className='mr-2'>{label}</span>
            <Dropdown
                disabled={disabled}
                direction='down'
                className={clsx(styles.dropdown, 'mr-2')}
                isOpen={isOpen}
                toggle={handleToggle}
            >
                <DropdownToggle
                    caret
                    color={clsx(!forcedColor && selectedStatus.color)}
                    style={ forcedColor && { backgroundColor: forcedColor }}
                >
                    {selectedStatus.label}
                </DropdownToggle>
                <DropdownMenu>
                    {
                        statuses.map(status =>
                            <DropdownItem key={`status-${status.id}`} onClick={handleSetSelection(status.id)}>
                                <span>{status.label}</span>
                            </DropdownItem>
                        )
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

EditStatusControl.propTypes = propTypes;

export default EditStatusControl;