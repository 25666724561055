import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectChosenLanguageId } from '../../../redux/selectors/locale';
import { handleLogin } from '../../../redux/methods/auth';
import { reinitializeLocale } from '../../../redux/methods/locale';

import { Form as FinalForm } from 'react-final-form';
import { Button, Col, Form, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import FormField from '../sharedItems/FormField';

import styles from './index.module.scss';

const propTypes = {
    isLoading: PropTypes.bool,
    handleLogin: PropTypes.func.isRequired,
    chosenLanguageId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    reinitializeLocale: PropTypes.func.isRequired,
};

const LoginForm = ({ isLoading, handleLogin, chosenLanguageId, reinitializeLocale }) => {

    const intl = useIntl();
    const translations = intl.messages.auth.login;

    useEffect(() => {
        if (!chosenLanguageId) {
            reinitializeLocale();
        }
    }, [ chosenLanguageId, reinitializeLocale ]);

    const onSubmit = formData => handleLogin(formData, chosenLanguageId);

    return (
        <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit, submitError }) => {
                return (
                    <Form onSubmit={handleSubmit} className='py-5'>
                        <h1>{translations.title}</h1>
                        <p className="text-muted">{translations.subTitle}</p>
                        <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="icon-user"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <FormField
                                type="email"
                                autoComplete="email"
                                name="username"
                                placeholder={translations.email}
                            />
                        </InputGroup>
                        <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="icon-lock"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <FormField
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                placeholder={translations.password}
                            />
                        </InputGroup>
                        <Row>
                            <Col xs="6">
                                <Button
                                    disabled={isLoading}
                                    type="submit"
                                    color="primary"
                                    className="px-4"
                                >
                                    {translations.action}
                                </Button>
                            </Col>
                            <Col xs="6" className="text-right">
                                <Button color="link" type="button" className="px-0">
                                    <Link to={'/reset'}>{translations.recovery}</Link>
                                </Button>
                            </Col>
                            { submitError && <span className={styles.submitError}>{submitError}</span> }
                        </Row>
                    </Form>
                );
            }}
        />
    );
};

LoginForm.propTypes = propTypes;

const mapStateToProps = state => ({
    isLoading: state.auth.isLoading.login,
    chosenLanguageId: selectChosenLanguageId(state)
});

const mapDispatch = {
    handleLogin,
    reinitializeLocale
};

export default connect(mapStateToProps, mapDispatch)(LoginForm);