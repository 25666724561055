import {
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowRight,
    faArrowLeft,
    faChevronLeft,
    faChevronRight,
    faChevronCircleLeft,
    faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons';

export const buttonIconsConfig = [
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowRight,
    faArrowLeft,
    faChevronLeft,
    faChevronRight,
    faChevronCircleLeft,
    faChevronCircleRight
];

export const onHoverAnimationButtonConfig = [
    {
        id: 1,
        label: 'scaleUp'
    },
    {
        id: 2,
        label: 'lightUp'
    },
    {
        id: 3,
        label: 'switchColors'
    },
    {
        id: 4,
        label: 'roundUp'
    }
];

export const graphicsTypeConfig = ['tile', 'list', 'banner', 'productCard'];

export const graphicsMaxSizesConfig = {
    tile: 400,
    doubleTile: 700,
    banner: 1920,
    list: 300,
    productCard: 1220,
    miniature: 150
};
