import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { Button, ButtonGroup, UncontrolledTooltip, Alert } from 'reactstrap';

import { graphicsMaxSizesConfig } from '../../config';

import ErrorModal from '../modals/ErrorModal';
import DeleteModal from '../modals/Modal';

import noImage from '../../assets/noImage.png';
import manyFiles from '../../assets/manyFiles.png';

import styles from './index.module.scss';
import { stringOrNumberPropTypes } from '../../propTypes';
import getConfig from '../../getConfig';

const propTypes = {
    handleUpload: PropTypes.func,
    handleRemove: PropTypes.func,
    photo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.oneOf(['many'])]),
    withPreview: PropTypes.bool,
    buttons: PropTypes.shape({
        upload: PropTypes.string,
        remove: PropTypes.string,
        edit: PropTypes.string,
    }),
    validateFormats: PropTypes.arrayOf(PropTypes.string),
    fileKey: PropTypes.string,
    label: PropTypes.string,
    id: stringOrNumberPropTypes,
    maxSize: PropTypes.number,
    preferWidth: PropTypes.string,
    preferHeight: PropTypes.string,
    forceRemoveCall: PropTypes.bool,
    graphicTypeToEnableGuide: PropTypes.string,
};

const FileSenderOpenModal = ({
    openModal,
    handleRemove,
    photo,
    withPreview = false,
    buttons,
    validateFormats = [],
    label,
    id,
    maxSize = null,
    preferWidth,
    preferHeight,
    graphicTypeToEnableGuide = null,
}) => {
    const [error, setError] = useState(null);
    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;
    const imageUrl = photo === 'many' ? manyFiles : photo ? `${apiUrl}${photo}` : null;

    const intl = useIntl();
    const translations = intl.messages.sharedComponents.uploadFile;

    const clearError = () => {
        setError(null);
    };

    const handleOnFileSendClick = () => {
        openModal(true);
    };

    const handleOnRemoveClick = () => {
        setIsDeleteModalOpen(true);
    };

    const handleRemoveConfirmation = () => {
        handleRemove();
        setIsDeleteModalOpen(false);
    };

    const isSomeHelpers = !!(maxSize || validateFormats.length > 0 || preferWidth || preferHeight);

    const generateGuideText = graphicTypeToEnableGuide => `${translations.graphicGuides.default} ${graphicsMaxSizesConfig[graphicTypeToEnableGuide]}px.`;
    return (
        <div>
            <Alert isOpen={graphicTypeToEnableGuide}>
                {generateGuideText(graphicTypeToEnableGuide)}
            </Alert>
            <div className={styles.container}>
            {label && <span className={clsx('d-flex align-items-center', withPreview && 'mr-2')}>{label}</span>}
                {withPreview && <div className={styles.wrapper}>
                    <img src={imageUrl || noImage} alt='' />
                </div>}
                {isSomeHelpers && <UncontrolledTooltip placement='top' target={`tooltip-${id}`}>
                    {maxSize && <p>{`${translations.sizeLabel}${maxSize}MB`}</p>}
                    {validateFormats && validateFormats.length > 0 && <p>{`${translations.extLabel}${validateFormats.join(' ')}`}</p>}
                    {preferWidth && <p>{`${translations.preferWidth}${preferWidth}`}</p>}
                    {preferHeight && <p>{`${translations.preferHeight}${preferHeight}`}</p>}
                </UncontrolledTooltip>}
                <div id={`tooltip-${id}`}>
                    <label htmlFor={id || 'button-upload'} className={clsx('d-flex justify-content-center')}>
                        {!imageUrl ? (
                            <Button color='primary' onClick={handleOnFileSendClick}>
                                <i className='fa fa-plus mr-2'/>
                                <span>{buttons?.upload || translations.buttons.upload}</span>
                            </Button>
                        ) : (
                            <ButtonGroup>
                                <Button color='primary' onClick={handleOnFileSendClick}>
                                    <i className='fa fa-edit mr-2'/>
                                    <span>{buttons?.edit || translations.buttons.edit}</span>
                                </Button>
                                {
                                    handleRemove &&
                                    <Button color='danger' onClick={handleOnRemoveClick}>
                                        <i className='fa fa-trash mr-2'/>
                                        <span>{buttons?.remove || translations.buttons.remove}</span>
                                    </Button>
                                }
                            </ButtonGroup>
                        )}
                    </label>
                </div>
                <ErrorModal
                    error={error}
                    buttonLabel={translations.buttons.closeModal}
                    header={translations.errorHeader}
                    closeErrorModal={clearError}
                />
                <DeleteModal
                    isOpen={isDeleteModalOpen}
                    setIsOpen={setIsDeleteModalOpen}
                    onConfirm={handleRemoveConfirmation}
                    buttons={{
                        confirm: {
                            color: 'danger',
                            label: translations.deleteModal.delete
                        },
                        cancel: {
                            label: translations.deleteModal.cancel
                        },
                    }}
                    body={translations.deleteModal.body}
                    header={translations.deleteModal.header}
                />
            </div>
        </div>
    );
};

FileSenderOpenModal.propTypes = propTypes;

export default FileSenderOpenModal;