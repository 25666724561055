import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateDiscountRegions } from '../../../../../redux/methods/discounts';
import { getRegions } from '../../../../../redux/methods/regions';
import useFetch from '../../../../../hooks/useFetch';

import SectionAsideButtons from '../../../../../components/SectionAsideButtons';
import Paginator from '../../../../../components/Paginator';
import RegionsTable from '../../../../../components/tables/RegionsTable';
import Modal from '../../../../../components/modals/Modal';
import SearchableRegionsDropdown from '../../../../../components/SearchableRegionsDropdown';

import { regionPropTypes, stringOrNumberPropTypes } from '../../../../../propTypes';

import { withFormUtils } from '../../../../../hoc/withFormUtils';

const propTypes = {
    pages: PropTypes.number,
    currentPage: PropTypes.number,
    updateDiscountRegions: PropTypes.func,
    discountRegions: PropTypes.arrayOf(regionPropTypes),
    changePage: PropTypes.func,
    getRegions: PropTypes.func,
    discountToEditId: stringOrNumberPropTypes
};

const RegionsSection = ({ utils, pages, currentPage, discountToEditId, discountRegions, updateDiscountRegions, changePage, getRegions }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.regionsCountries;

    const [ regions ] = useFetch(getRegions);

    const [checkedRegionsIds, setCheckedRegionsIds] = useState([]);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [regionId, setRegionId] = useState(null);

    useEffect(() => {
        return () => {
            setCheckedRegionsIds([]);
            setRegionId(null);
            setIsAssignModalOpen(false);
        };
    }, [discountRegions]);

    const handleUnassignRegion = id => {
        updateDiscountRegions(discountToEditId, [id], true);
        changePage();
    };

    const handleOpenAssignModal = () => {
        setIsAssignModalOpen(true);
    };

    const handleOpenUnassignModal = () => {
        utils.confirm(
            () => {
                updateDiscountRegions(discountToEditId, checkedRegionsIds, true);
                changePage();
            },
            translations.deleteModalCountries.header,
            translations.deleteModalCountries.body
        );
    };

    const handleAssignRegion = () => {
        updateDiscountRegions(discountToEditId, [regionId], false);
        changePage();
    };

    const categoriesSectionButtons = {
        remove: {
            label: translations.buttons.regions.remove,
            action: handleOpenUnassignModal,
            isDisabled: checkedRegionsIds.length === 0
        },
        add: {
            label: translations.buttons.regions.add,
            action: handleOpenAssignModal,
        }
    };

    return regions ? (
        <React.Fragment>
            <span className='text-success font-weight-bold d-block mb-2 mt-3'>{translations.headers.regions}</span>
            <RegionsTable
                regions={discountRegions}
                withEditAction={false}
                withCheckbox={true}
                checkedCheckboxIds={checkedRegionsIds}
                setCheckedCheckboxIds={setCheckedRegionsIds}
                headers={translations.tables.regions.headers}
                removeAction={handleUnassignRegion}
                translations={translations.tables.regions}
                fillers={translations.tables.regions.fillers}
                isGreenOnHover={true}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={currentPage}
                        onPageChange={changePage}
                    />
            }
            <SectionAsideButtons
                buttons={categoriesSectionButtons}
            />
            <hr className='border-danger d-block my-5'/>
            <Modal
                isOpen={isAssignModalOpen}
                setIsOpen={setIsAssignModalOpen}
                header={translations.assignModalRegions.header}
                body={
                    <React.Fragment>
                        <span>{translations.assignModalRegions.body}</span>
                        <SearchableRegionsDropdown
                            regions={regions}
                            setRegionId={setRegionId}
                            regionId={regionId}
                        />
                    </React.Fragment>
                }
                buttons={{
                    cancel: {
                        label: translations.assignModalRegions.cancel,
                        color: 'secondary',
                    },
                    confirm: {
                        label: translations.assignModalRegions.confirm,
                        color: 'primary',
                    }
                }}
                onConfirm={handleAssignRegion}
            />
        </React.Fragment>
    ) : null;
};

RegionsSection.propTypes = propTypes;

const mapStateToProps = state => ({
    discountToEditId: state.discounts.discountToEdit.id,
});

const mapDispatch = {
    updateDiscountRegions,
    getRegions,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(RegionsSection));