/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { Button, ButtonGroup, UncontrolledTooltip, Alert } from 'reactstrap';

import { graphicsMaxSizesConfig } from '../../config';

import ErrorModal from '../modals/ErrorModal';
import DeleteModal from '../modals/Modal';

import noImage from '../../assets/noImage.png';

import styles from './index.module.scss';
import { stringOrNumberPropTypes } from '../../propTypes';
import getConfig from '../../getConfig';


const propTypes = {
    handleUpload: PropTypes.func,
    handleRemove: PropTypes.func,
    photo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    withPreview: PropTypes.bool,
    buttons: PropTypes.shape({
        upload: PropTypes.string,
        remove: PropTypes.string,
        edit: PropTypes.string
    }),
    validateFormats: PropTypes.arrayOf(PropTypes.string),
    fileKey: PropTypes.string,
    label: PropTypes.string,
    id: stringOrNumberPropTypes,
    maxSize: PropTypes.number,
    preferWidth: PropTypes.string,
    preferHeight: PropTypes.string,
    forceRemoveCall: PropTypes.bool,
    graphicTypeToEnableGuide: PropTypes.string
};

const FileSender = ({
    handleUpload,
    handleRemove,
    forceRemoveCall = false,
    photo,
    withPreview = false,
    buttons,
    validateFormats = [],
    fileKey = 'image',
    label,
    id,
    maxSize = null,
    fileType,
    preferWidth,
    preferHeight,
    customFileKey,
    setFile,
    graphicTypeToEnableGuide
}) => {
    const [error, setError] = useState(null);
    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);

    const fileInputRef = React.createRef(null);

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;
    const imageUrl = photo ? `${apiUrl}${photo}` : null;

    const intl = useIntl();
    const translations = intl.messages.sharedComponents.uploadFile;

    const [ filePreview, setFilePreview ] = useState(imageUrl);

    const clearError = () => {
        setError(null);
    };

    useEffect(() => {
        if(fileInputRef && fileInputRef.current && fileInputRef.current.value){
            fileInputRef.current.value = null;
        }
    }, [fileInputRef]);

    useEffect(() => {
        setFilePreview(imageUrl);
    }, [photo]);

    const handleOnFileSendClick = () => {
        fileInputRef.current.click();
    };

    const isValidFormat = format => {
        const validate = validateFormats
            .map(format => format === 'jpg' ? 'jpeg' : format)
            .map(format => format === 'ico' ? 'x-icon' : format)
            .map(format => format === 'svg' ? 'svg+xml' : format);

        return !validate.includes(format.slice(format.indexOf('/') + 1));
    };

    const generateFile = file => {
        const data = new FormData();

        data.append(fileKey, file);

        if(fileType) {
            data.append('Type', fileType);
        }
        if(customFileKey) {
            data.append(customFileKey.name, customFileKey.param);
        }

        return data;
    };

    const checkSize = (maxSize, fileSize) => {
        return maxSize && fileSize / 1000000 > maxSize ? true : false;
    };

    const handleFileUpload = e => {
        const file = e.target.files[0];

        if (validateFormats.length > 0 && isValidFormat(file.type)){
            setError(`${translations.errorBody} ${validateFormats.join(', ')}`);
        }  else if (checkSize(maxSize, file.size)){
            setError(`${translations.errorBodySize} ${maxSize}MB`);
        } else {
            if(setFile) {
                setFilePreview(URL.createObjectURL(file));
                setFile({ generatedFile: generateFile(file), previewFile: URL.createObjectURL(file) });
            } else {
                handleUpload(generateFile(file));
            }
        }
    };

    const handleOnRemoveClick = () => {
        setIsDeleteModalOpen(true);
    };

    const handleRemoveConfirmation = () => {
        if(imageUrl || forceRemoveCall) {
            handleRemove();
        }
        if(setFile) {
            setFile({ generatedFile: null, previewFile: null });
        }
        setIsDeleteModalOpen(false);
        setFilePreview(null);
    };

    const isSomeHelpers = !!(maxSize || validateFormats || preferWidth || preferHeight);

    const generateGuideText = graphicTypeToEnableGuide => `${translations.graphicGuides.default} ${graphicsMaxSizesConfig[graphicTypeToEnableGuide]}px.`;
    return (
        <div>
            <Alert isOpen={!!graphicTypeToEnableGuide}>
                {generateGuideText(graphicTypeToEnableGuide)}
            </Alert>
            <div className={styles.container}>
            {label && <span className={clsx('d-flex align-items-center', withPreview && 'mr-2')}>{label}</span>}
                {withPreview && <div className={styles.wrapper}>
                    <img src={filePreview || noImage} alt='' />
                </div>}
                {isSomeHelpers && <UncontrolledTooltip placement='top' target={`tooltip-${id}`}>
                    {maxSize && <p>{`${translations.sizeLabel}${maxSize}MB`}</p>}
                    {validateFormats && validateFormats.length > 0 && <p>{`${translations.extLabel}${validateFormats.join(' ')}`}</p>}
                    {preferWidth && <p>{`${translations.preferWidth}${preferWidth}`}</p>}
                    {preferHeight && <p>{`${translations.preferHeight}${preferHeight}`}</p>}
                </UncontrolledTooltip>}
                <div id={`tooltip-${id}`}>
                    <input type='file' id={id || 'button-upload'} className='d-none' ref={fileInputRef} onInput={handleFileUpload} />
                    <label htmlFor={id || 'button-upload'} className={clsx('d-flex justify-content-center')} style={{marginBottom: '0'}}>
                        {!filePreview ? (
                            <Button className='mx-md-1' color='primary' onClick={handleOnFileSendClick}>
                                <i className='fa fa-plus mr-2'/>
                                <span>{buttons && (buttons.upload || translations.buttons.upload)}</span>
                            </Button>
                        ) : (
                            <ButtonGroup>
                                <Button className='mx-md-1' color='primary' onClick={handleOnFileSendClick}>
                                    <i className='fa fa-edit mr-2'/>
                                    <span>{buttons && (buttons.edit || translations.buttons.edit)}</span>
                                </Button>
                                {
                                    handleRemove &&
                                    <Button color='danger' onClick={handleOnRemoveClick}>
                                        <i className='fa fa-trash mr-2'/>
                                        <span>{buttons && (buttons.remove || translations.buttons.remove)}</span>
                                    </Button>
                                }
                            </ButtonGroup>
                        )}
                    </label>
                </div>
                <ErrorModal
                    error={error}
                    buttonLabel={translations.buttons.closeModal}
                    header={translations.errorHeader}
                    closeErrorModal={clearError}
                />
                <DeleteModal
                    isOpen={isDeleteModalOpen}
                    setIsOpen={setIsDeleteModalOpen}
                    onConfirm={handleRemoveConfirmation}
                    buttons={{
                        confirm: {
                            color: 'danger',
                            label: translations.deleteModal.delete
                        },
                        cancel: {
                            label: translations.deleteModal.cancel
                        },
                    }}
                    body={translations.deleteModal.body}
                    header={translations.deleteModal.header}
                />
            </div>
        </div>
    );
};

FileSender.propTypes = propTypes;

export default FileSender;