import React from "react";
import PropTypes from "prop-types";

//import clsx from "clsx";
//import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { getProductMeasures } from "../../../../redux/methods/products";

import ProductMeasuresTable from '../../../../components/tables/ProductMeasuresTable';

import useFetch from "../../../../hooks/useFetch";

import { Container } from "reactstrap";

import { stringOrNumberPropTypes } from "../../../../propTypes";

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    id: stringOrNumberPropTypes,
    getProductMeasures: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const ProductEditMeasuresSection = ({
    id,
    getProductMeasures,
    forceRefetch
}) => {
    const [ productMeasures ] = useFetch(getProductMeasures, id, [], forceRefetch);

    return productMeasures ? (
        <Container fluid>
            <ProductMeasuresTable
                productMeasures={productMeasures}
                id={id}
            />
        </Container>
    ) : null;
};

ProductEditMeasuresSection.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.productMeasures
});

const mapDispatch = {
    getProductMeasures
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ProductEditMeasuresSection));