import { getTranslationForIso } from '../shared';
import { attributeTypeEnum } from '../../enums/attributes';

//========================================================= przekombinowane, ale na razie zostawiam
export const getQuotientMultiplesInRange = (divisor, dividend) => {
    /**
     * Get the list of all the multiples of division result
     */
    const quotient = Math.floor(dividend/divisor);

    /**
     * If the gap between currentNumber and limit can't fit at least half of limit, skip it
     * This condition is here to prevent unwanted small gap at the end in favor of a slightly bigger one (max an increment*1.5)
     */
    const isLastMultiple = (currentNumber, increment, limit) => limit - currentNumber < 1.5 * increment;

    const multiplesFinder = condition => (collection, baseQuotient, limit) => {
            const updatedArray = [...collection, collection[collection.length-1] + baseQuotient];

            return condition(collection[collection.length - 1], baseQuotient, limit) ?
                [...collection, limit] : multiplesFinder(condition)(updatedArray, baseQuotient, limit);
    };

    return multiplesFinder(isLastMultiple)([quotient], quotient, dividend);
};

export const cssGradientGenerator = (colorList, angle) => {
    if (!colorList || (colorList && colorList.length === 0)) {
        return '#FFF';
    } else if (colorList.length === 1) {
        return (colorList[0]);
    }

    const gradientRanges = getQuotientMultiplesInRange(colorList.length, 100);

    const constructInnerCSS = (colorList, gradientRanges) => {
        const cssShards = colorList.map((color, i) =>
            i === 0 ? `${color} 0%, ${color} ${gradientRanges[i]}%` : `${color} ${gradientRanges[i-1]}%, ${color} ${gradientRanges[i]}%`
        );

        return cssShards.join(', ');
    };

    return `linear-gradient(${angle}deg, ${constructInnerCSS(colorList, gradientRanges)})`;
};

//========================================================= potrzebne
export const assingIconToFeatureAttributeType = type => {
    switch (type) {
        case 'Tag': return 'fa fa-tags';
        case 'Dictionary': return 'fa fa-align-justify';
        case 'Int': return 'fa fa-calculator';
        case 'Decimal': return 'fa fa-calculator';
        case 'DateTime': return 'fa fa-calendar';
        case 'Color': return 'fa fa-paint-brush';
        case 'Boolean': return 'fa fa-check';
        default: return null;
    }
};

export const generateTranslatedFeaturesAttributesList = (featuresList, iso) =>
    featuresList.map(feature => ({
            ...feature,
            translatedName: getTranslationForIso(feature.name, iso),
            translatedAttributeCategories: feature.categories.map(category => getTranslationForIso(category, iso))
        })
    );

export const generateEditLinkForAttribute = id => `/attributes/edit?attrID=${id}`;
export const generateEditLinkForFeature = id => `/features/edit?featID=${id}`;

/**
 * Pass needed shared utils which can be directly used in a component
 */

export const checkIfAttributeTypeIsMultiselectable = attributeType => {
    switch (attributeType) {
        case attributeTypeEnum.dictionary:
        case attributeTypeEnum.tag:
        case attributeTypeEnum.color: return true;
        default: return false;
    }
};

export const generateInitialLabelsForSelectedAttributes = (attribute, selectionTranslation, emptyAttributeTranslation) => {
    if (attribute.attributeIsMultiselect) {
        return selectionTranslation;
    }
    else {
        return attribute.dictionaryValues ? attribute.dictionaryValues.filter(value => value.selected === true).name || emptyAttributeTranslation : emptyAttributeTranslation;
    }
};

export const pickBadgeColor = (status) => {
    switch(status){
        case 'Editing': return 'danger';
        case 'Published': return 'success';
        default: return null;
    }
};

export const generateStatusesConfig = (statusEnum, translations) => {
    return Object.keys(statusEnum).map(key => ({
        id: parseInt(key),
        label: translations[statusEnum[key].toLowerCase()],
        color: pickBadgeColor(statusEnum[key])
    }));
};
