import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useFetch from '../../../hooks/useFetch';

import {
    getShippingFormsWeights,
    getShippingFormsWeight,
    addEditShippingFormsWeight,
    deleteShippingFormsWeight
} from '../../../redux/methods/shippingForms';

import ContainerWithCard from '../../../components/ContainerWithCard';
import TableGenerator from '../../generators/TableGenerator';
import ShippingFormWeightEditionModal from '../../../components/modals/ShippingFormWeightEditionModal';
import { Button } from 'reactstrap';

import styles from './index.module.scss';
import { formUtilsHocPropTypes } from '../../../propTypes';
import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getShippingFormsWeights: PropTypes.func,
    getShippingFormsWeight: PropTypes.func,
    addEditShippingFormsWeight: PropTypes.func,
    deleteShippingFormsWeight: PropTypes.func,
    shippingVariantId: PropTypes.number,
    nextLevelForWeightsRowAction: PropTypes.object,
    forceRefetch: PropTypes.bool
};

const ShippingWeightsTable = ({
    utils,
    getShippingFormsWeights,
    getShippingFormsWeight,
    addEditShippingFormsWeight,
    deleteShippingFormsWeight,
    shippingVariantId,
    nextLevelForWeightsRowAction,
    forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.shippingForms.weightsTable;

    const { headers, editModal } = translations;

    const [ isEditModalOpen, setIsEditModalOpen ] = useState(false);
    const [ editModalItem, setEditModalItem ] = useState(null);

    const [ items ] = useFetch(getShippingFormsWeights, shippingVariantId, [], forceRefetch);

    const editAction = shippingVariantWeightId => async () => {
        const item = await getShippingFormsWeight({shippingVariantId, shippingVariantWeightId});
        if (item && item.payload && !item.payload.errors && !item.error) {
            setEditModalItem(item.payload);
            setIsEditModalOpen(true);
        }
    };

    const deleteAction = shippingVariantWeightId => () => {
        deleteShippingFormsWeight({shippingVariantId, shippingVariantWeightId});
    };

    const handleEditAction = form => {
        const objectToSend = {...form, shippingVariantId, shippingVariantWeightId: form.id};

        if (!objectToSend.weightFrom) {
            utils.error(translations.errors.weightFromIsRequired);
            return;
        }
        else {
            addEditShippingFormsWeight(objectToSend);
            setIsEditModalOpen(false);
        }
    };

    const handleAddAction = () => {
        if (items.length == 0) {
            addEditShippingFormsWeight({
                shippingVariantWeightId: 0,
                shippingVariantId,
                weightFrom: 0});
        } else {
            setEditModalItem({ shippingVariantId });
            setIsEditModalOpen(true);
        }
    };

    const handleCheckIfActionIsDisabled = (action, item) => {
        return (action == 'edit' || action == 'delete') &&
               (item.weightFrom === 0);
    };

    return items ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-wallpaper'>
            <TableGenerator
                items={items}
                expectedActions={[nextLevelForWeightsRowAction, 'edit', 'delete']}
                actions={{editAction, deleteAction}}
                defaultAction={editAction}
                translations={translations}
                columns={[
                    /*{
                        header: headers.id,
                        value: 'id',
                    },*/
                    {
                        header: headers.weightFrom,
                        value: 'weightFrom'
                    },
                    {
                        header: headers.weightTo,
                        value: 'weightTo',
                    },
                    {
                        header: headers.actions,
                        type: 'actions'
                    }
                ]}
                checkIfActionIsDisabled={handleCheckIfActionIsDisabled}
            />
            <aside className={styles.footer}>
                <Button color='primary' onClick={handleAddAction}>
                    <i className='fa fa-plus mr-2'/>
                    <span>{translations.buttons.create}</span>
                </Button>
            </aside>
            <ShippingFormWeightEditionModal
                isOpen={isEditModalOpen}
                setIsOpen={setIsEditModalOpen}
                translations={editModal}
                weight={editModalItem}
                onSubmit={handleEditAction}
            />
        </ContainerWithCard>
    ) : null;
};

ShippingWeightsTable.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.shippingWeights,
});

const mapDispatch = {
    getShippingFormsWeights,
    getShippingFormsWeight,
    addEditShippingFormsWeight,
    deleteShippingFormsWeight
};

export default withFormUtils(withRouter(connect(mapStateToProps, mapDispatch)(ShippingWeightsTable)));