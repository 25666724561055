import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateAttributeBasicData } from '../../../../redux/methods/attributes';
import FormFieldTranslations from '../../../../components/formFields/FormFieldTranslations';
import { checkIfAttributeTypeIsMultiselectable, generateStatusesConfig } from '../../../../utils/attributes';

import { Form as FinalForm, Field } from 'react-final-form';
import { Col, Container, Form, Row } from 'reactstrap';

import CheckboxWithTooltip from '../../../../components/CheckboxWithTooltip';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import Dropdown from '../../../../components/Dropdown';

import { waproMagAdditionalFields } from '../../../../config';
import { erpIdsEnum } from '../../../../enums/synchronizations';

import { withFormUtils } from '../../../../hoc/withFormUtils';
import { formUtilsHocPropTypes, attributeToEditPropTypes, stringOrNumberPropTypes } from '../../../../propTypes';
import FormFieldWithSearchableDropdown from '../../../../components/formFields/FormFieldWithSearchableDropdown';
import FormFieldSwitchPillWithNullState from '../../../../components/formFields/FormFieldSwitchPillWithNullState';
import FormFieldStatus from '../../../../components/formFields/FormFieldStatus';
import { firstLetterToLowerCase, generateDropDownOptionsForEnum } from '../../../../utils/components';
import { attributePresentationTypeEnum, attributeTypeEnum, attributeStatusEnum } from '../../../../enums/attributes';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    data: attributeToEditPropTypes,
    categoryId: PropTypes.number,
    updateAttributeBasicData: PropTypes.func,
    handleAttributeSaved: PropTypes.func,
    activatedERPId: stringOrNumberPropTypes
};

const SharedEditBasicSection = ({
    utils,
    data,
    categoryId,
    updateAttributeBasicData,
    handleAttributeSaved,
    activatedERPId
}) => {
    const intl = useIntl();
    const translations = intl.messages['attributes'].edit.tabs.basic;
    const translationsType = intl.messages.attributes.searchForm.filters.attributeType;
    const translationsPresentations = intl.messages.attributes.searchForm.filters.attributePresentation;

    const isAttribute = data.attributeAffiliation === 'Attribute';

    const [ selectedWaproMagFieldId, setSelectedWaproMagFieldId ] = useState(1);

    const isItemMultiselectable = checkIfAttributeTypeIsMultiselectable(data.attributeType);

    const config = { translations: ['name', 'nameExtension'] };

    const handleOnSubmit = async formData => {
        const data = utils.generateFormToSubmitValues(formData, config);

        if (!isNaN(categoryId)) {
            data.categoryId = categoryId;
        }

        const objectToSend = {
            ...data,
            waproMagFieldId: data.isMappingWaproMag ? selectedWaproMagFieldId : null,
        };

        const attributeId = await updateAttributeBasicData(objectToSend);

        if (typeof(attributeId) !== 'undefined') {
            handleAttributeSaved(attributeId);
        }
    };

    const optionsTypes = generateDropDownOptionsForEnum(attributeTypeEnum, translationsType, false);

    const statuses = generateStatusesConfig(attributeStatusEnum, translations.statusLabels);

    const presentationsOptions = {
        int: [
            {
                id: attributePresentationTypeEnum.range,
                label: translationsPresentations.range
            }
        ],
        decimal: [
            {
                id: attributePresentationTypeEnum.range,
                label: translationsPresentations.range
            }
        ],
        dateTime: [
            {
                id: attributePresentationTypeEnum.range,
                label: translationsPresentations.range
            }
        ],
        tag: [
            {
                id: attributePresentationTypeEnum.tag,
                label: translationsPresentations.tag
            }
        ],
        dictionary: [
            {
                id: attributePresentationTypeEnum.simpleDictionary,
                label: translationsPresentations.simpleDictionary
            },
            {
                id: attributePresentationTypeEnum.searchableDictionary,
                label: translationsPresentations.searchableDictionary
            },
            {
                id: attributePresentationTypeEnum.tag,
                label: translationsPresentations.tag
            }
        ],
        color: [
            {
                id: attributePresentationTypeEnum.color,
                label: translationsPresentations.color
            }
        ],
        boolean: [
            {
                id: attributePresentationTypeEnum.boolean,
                label: translationsPresentations.boolean
            }
        ],
        string: [
            {
                id: attributePresentationTypeEnum.string,
                label: translationsPresentations.string
            }
        ]
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    /** This weird key is here to ensure that the Form is remounted and reinitialised when needed */
                    key={`${data.id}-EditBasicSectionForm-${utils.currentLanguage()}`}
                    mutators={{
                        setToFirstOption: (args, state, { changeValue }) => {
                            changeValue(state, 'attributePresentation', () => {
                                const newType = firstLetterToLowerCase(args[0]);

                                return presentationsOptions[newType][0].id;
                            });
                        },
                    }}
                    initialValues={utils.generateFormInitialValues(data, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values }) => {
                        const currentSelectedWaproMagFieldLabel = waproMagAdditionalFields.find(field => field.id === selectedWaproMagFieldId).label || translations.waproMagDropdown.defaultSelection;
                        const currentPresentationsOptions = presentationsOptions[firstLetterToLowerCase(values.attributeType)];
                        const selectedStatus = statuses.find(status => status.id === values.status) || statuses[0];

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container fluid className="pt-3 px-0">
                                    <Row className="mb-3">
                                        <FormFieldStatus
                                            name='status'
                                            selectedStatus={selectedStatus}
                                            statuses={statuses}
                                            label={translations.status}
                                        />
                                    </Row>
                                    <Row className="mb-3">
                                        <FormFieldTranslations
                                            form={form}
                                            modalHeader={translations.inputs.name}
                                            propertyName='name'
                                            currentIso={utils.currentLanguage()}
                                            label={translations.inputs.name}
                                        />
                                    </Row>
                                    <Row className="mb-3">
                                        <FormFieldTranslations
                                            form={form}
                                            modalHeader={translations.inputs.nameExtension}
                                            propertyName='nameExtension'
                                            currentIso={utils.currentLanguage()}
                                            label={translations.inputs.nameExtension}
                                        />
                                    </Row>
                                    <Row className="flex-md-nowrap my-3">
                                        <Col sm={12} md={6} className='m-0 p-0 pr-md-3'>
                                            <FormFieldWithSearchableDropdown
                                                label={translations.inputs.type}
                                                name='attributeType'
                                                options={optionsTypes}
                                                mutator={form.mutators.setToFirstOption}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} className='m-0 p-0 mt-3 mt-md-0'>
                                            <FormFieldWithSearchableDropdown
                                                label={translations.inputs.presentationType}
                                                name='attributePresentation'
                                                options={currentPresentationsOptions}
                                                isDisabled={currentPresentationsOptions.length === 1}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Field name="isRequired" type="checkbox">
                                            {
                                                props =>
                                                    <CheckboxWithTooltip
                                                        label={translations.inputs.isRequired}
                                                        tooltipText={translations.inputs.isRequiredTooltip}
                                                        inputProps={{ ...props.input }}
                                                    />
                                            }
                                        </Field>
                                    </Row>
                                    <Row className={clsx(isItemMultiselectable ? 'mb-2' : 'mb-3')}>
                                        <Field name="isFilter" type="checkbox">
                                            {
                                                props =>
                                                    <CheckboxWithTooltip
                                                        label={translations.inputs.isFilter}
                                                        tooltipText={translations.inputs.isFilterTooltip}
                                                        inputProps={{ ...props.input }}
                                                    />
                                            }
                                        </Field>
                                    </Row>
                                    {
                                        /**
                                         * This position can be set only for established types, hence the conditional render
                                         */
                                        isItemMultiselectable &&
                                            <Row className="mb-3">
                                                <Field name="isMultiselect" type="checkbox">
                                                    {
                                                        props =>
                                                            <CheckboxWithTooltip
                                                                label={translations.inputs.isMultiselect}
                                                                tooltipText={translations.inputs.isMultiselectTooltip}
                                                                inputProps={{ ...props.input }}
                                                            />
                                                    }
                                                </Field>
                                            </Row>
                                    }
                                    {
                                        //Renders only when system is synchronized with wapro-mag
                                        isAttribute && erpIdsEnum[activatedERPId] === 'wapro-mag'
                                            && (
                                                <React.Fragment>
                                                    <Row className='mb-2'>
                                                        <Field name="isMappingWaproMag" type="checkbox" checked={values.waproMagFieldId} >
                                                            {
                                                                props =>
                                                                    <CheckboxWithTooltip
                                                                        label={translations.inputs.isMappingWaproMag}
                                                                        tooltipText={translations.inputs.isMappingWaproMagTooltip}
                                                                        inputProps={{ ...props.input }}
                                                                    />
                                                            }
                                                        </Field>
                                                    </Row>
                                                    <Row className='mb-2'>
                                                        <div className='w-md-50'>
                                                            <span className='py-3'>{translations.waproMagDropdown.label}</span>
                                                            <Dropdown
                                                                isDisabled={!values.isMappingWaproMag}
                                                                options={waproMagAdditionalFields}
                                                                performSelection={setSelectedWaproMagFieldId}
                                                                currentSelectionLabel={currentSelectedWaproMagFieldLabel}
                                                            />
                                                        </div>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                    }
                                    <Row className="mb-2">
                                        <div className="w-100">
                                            <FormFieldSwitchPillWithNullState
                                                form={form}
                                                name='resetCategoryAfterChange'
                                                label={translations.inputs.resetCategoryAfterChange}
                                            />
                                        </div>
                                        <div className="pt-2">
                                            {values.resetCategoryAfterChange === true && (<sup>{translations.explanations.resetCategoryAfterChangeTrue}</sup>)}
                                            {values.resetCategoryAfterChange === false && (<sup>{translations.explanations.resetCategoryAfterChangeFalse}</sup>)}
                                            {values.resetCategoryAfterChange === null && (<sup>{translations.explanations.resetCategoryAfterChangeNull}</sup>)}
                                        </div>
                                    </Row>
                                    <Row className="mb-2">
                                        <div className="w-100">
                                            <FormFieldSwitchPillWithNullState
                                                form={form}
                                                name='resetPhraseAfterChange'
                                                label={translations.inputs.resetPhraseAfterChange}
                                            />
                                        </div>
                                        <div className="pt-2">
                                            {values.resetPhraseAfterChange === true && (<sup>{translations.explanations.resetPhraseAfterChangeTrue}</sup>)}
                                            {values.resetPhraseAfterChange === false && (<sup>{translations.explanations.resetPhraseAfterChangeFalse}</sup>)}
                                            {values.resetPhraseAfterChange === null && (<sup>{translations.explanations.resetPhraseAfterChangeNull}</sup>)}
                                        </div>
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

SharedEditBasicSection.propTypes = propTypes;

const mapStateToProps = state => ({
    activatedERPId: state.appInit.activatedErpId
});

const mapDispatch = {
    updateAttributeBasicData
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(SharedEditBasicSection));