import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateDiscountParams, getDiscountParams } from '../../../../redux/methods/discounts';

import { generateInitialValuesForParameters } from '../../../../utils/discounts';
import useFetch from '../../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row } from 'reactstrap';

import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';

import { discountPropTypes, formUtilsHocPropTypes } from '../../../../propTypes';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import LayoutColorEditionController from '../../../../components/LayoutController/LayoutColorEditionController';
import FormFieldSwitchPill from '../../../../components/formFields/FormFieldSwitchPill';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    discountToEdit: discountPropTypes,
    discountToEditParameters: PropTypes.arrayOf(PropTypes.shape({
        paramType: PropTypes.string,
        value: PropTypes.number,
    })),
    updateDiscountParams: PropTypes.func,
    getDiscountParams: PropTypes.func
};

const DiscountEditTagSettings = ({ discountToEdit, updateDiscountParams, getDiscountParams }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.tagSettings;

    const [ colors, setColors ] = useState({});

    const handleOnAddColor = (color, name) => {
        const colorPair = { [name]: color };
        setColors(prevState => ({ ...prevState, ...colorPair }));
    };

    const [ discountToEditParams ] = useFetch(getDiscountParams, discountToEdit.discountId);

    const handleOnSubmit = formData => {
        const objectToSend = {
            ...formData,
            ...colors,
        };

        updateDiscountParams(discountToEdit.discountId, objectToSend);
    };

    return discountToEditParams ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={generateInitialValuesForParameters(discountToEditParams.discountParams)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        const { values } = form.getState();

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='px-0' fluid>
                                    <Row className='mb-3'>
                                        <FormFieldSwitchPill
                                            name="TagEnabled"
                                            label={translations.inputs.enabled}
                                        />
                                    </Row>
                                    <Row className='mb-3'>
                                        <div>
                                            <LayoutColorEditionController
                                                onAdd={handleOnAddColor}
                                                translations={translations.inputs.backgroundColor}
                                                color={values.TagBackgroundColor}
                                                name={translations.inputs.backgroundColor.name}
                                                tag='TagBackgroundColor'
                                            />
                                        </div>
                                    </Row>
                                    <Row className='mb-3'>
                                        <div>
                                            <LayoutColorEditionController
                                                onAdd={handleOnAddColor}
                                                translations={translations.inputs.borderColor}
                                                color={values.TagBorderColor}
                                                name={translations.inputs.borderColor.name}
                                                tag='TagBorderColor'
                                            />
                                        </div>
                                    </Row>
                                    <Row className='mb-3'>
                                        <div>
                                            <LayoutColorEditionController
                                                onAdd={handleOnAddColor}
                                                translations={translations.inputs.textColor}
                                                color={values.TagTextColor}
                                                name={translations.inputs.textColor.name}
                                                tag='TagTextColor'
                                            />
                                        </div>
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

DiscountEditTagSettings.propTypes = propTypes;

const mapStateToProps = (state) => ({
    discountToEdit: state.discounts.discountToEdit.data
});

const mapDispatch = {
    updateDiscountParams,
    getDiscountParams,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(DiscountEditTagSettings));