import React from 'react';
import PropTypes from 'prop-types';

import SearchForm from '../../../../components/generators/SearchForm';

const propTypes = {
    handleSubmit: PropTypes.func,
    translations: PropTypes.object,
    currentLanguage: PropTypes.string,
    searchConfig: PropTypes.object.isRequired,
};

const PhraseStatsSearchForm = ({ handleSubmit, translations, currentLanguage, isSearchNumberTable, searchConfig }) => {

    const onSubmit = formData => {
        handleSubmit(formData);
    };

    return (
        <SearchForm
            searchConfig={searchConfig}
            translations={{
                buttons: {
                    reset: translations.buttons.reset,
                    submit: translations.buttons.submit,
                }
            }}
            inputs={
                !isSearchNumberTable ? [
                {
                    label: translations.headers.phrase,
                    name: 'phrase',
                    type: 'text'
                },
                {
                    label: translations.headers.dateFrom,
                    name: 'dateFrom',
                    type: 'date',
                    storeCurrentLanguage: currentLanguage,
                },
                {
                    label: translations.headers.dateTo,
                    name: 'dateTo',
                    type: 'date',
                    storeCurrentLanguage: currentLanguage,
                },
            ]
            :  [
                {
                    label: translations.headers.phrase,
                    name: 'phrase',
                    type: 'text'
                },
                {
                    label: translations.headers.minSearches,
                    name: 'minNumber',
                    type: 'number'
                }
            ]
        }
            performSearch={onSubmit}
        />
    );
};

PhraseStatsSearchForm.propTypes = propTypes;

export default PhraseStatsSearchForm;