import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Field } from 'react-final-form';

import styles from './index.module.scss';

const propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
	thin: PropTypes.bool,
};

const FormFieldSwitchPillWithNullState = ({ form, label, name, disabled, thin, className }) => {

    const performChange = (fieldName) => {
        const { values } = form.getState();

        if (values[fieldName] === false) {
            form.change(fieldName, null);
        }
        else if (values[fieldName] === true) {
            form.change(fieldName, false);
        }
        else {
            form.change(fieldName, true);
        }
    };

    return (
        <div className={clsx('d-flex align-items-center', className)}>
            <Field name={name} type='text'>
                {
                    props =>
                    {
                        return (
                            <label className={clsx("m-0", styles.switchWithNullState)}>
                                <input
                                    type="text"
                                    name={props.input.name}
                                    value={props.input.value}
                                    className="switch-input"
                                    disabled={disabled}
                                    onChange={props.input.onChange}
                                />
                                <span
                                    className={clsx(
                                        (props.input.value === true && styles.sliderTrue),
                                        (props.input.value === false && styles.sliderFalse),
                                        (props.input.value === "" && styles.sliderNotSet))}
                                    onClick={() => performChange(props.input.name)}
                                ></span>
                            </label>
                        );
                    }
                }
            </Field>
            {thin
                ? <span className='ml-3 font-weight-normal'>{label}</span>
                : <span className='ml-3 font-weight-bolder'>{label}</span>
            }
        </div>
    );
};

FormFieldSwitchPillWithNullState.propTypes = propTypes;

export default FormFieldSwitchPillWithNullState;