import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Input, Button, InputGroupAddon, InputGroup } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    emailList: PropTypes.arrayOf(PropTypes.string),
    setEmailList: PropTypes.func,
    addButtonText: PropTypes.string
};

const EmailListControllerToFinalForm = ({ emailList, setEmailList, addButtonText }) => {
    const handleSetEmail = (event, index) => {
        const listToEdit = [ ...emailList ];
        listToEdit[index] = event.target.value;

        setEmailList(listToEdit);
    };

    const handleAddEmail = () => {
        setEmailList([...emailList, '']);
    };

    const handleRemoveEmail = (index) => () => {
        setEmailList(emailList.filter((elem, elemIndex) => elemIndex !== index));
    };

    return (
        <div className={clsx('my-3', styles.container)}>
            {
                emailList && emailList.map(((email, index) => (
                    <div key={`emailEditionField-${index}`} className={clsx('my-2 w-100')}>
                        <div className='d-flex w-100'>
                            <InputGroup>
                                <Input type='email' value={email} onChange={event => handleSetEmail(event, index)}/>
                                <InputGroupAddon addonType='append' >
                                    <Button
                                        color='danger'
                                        onClick={handleRemoveEmail(index)}
                                    >
                                        <span><i className='fa fa-trash'/></span>
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </div>
                )))
            }
            <Button
                className='mt-3'
                color='primary'
                onClick={handleAddEmail}
            >
                <span>{addButtonText}</span>
            </Button>
        </div>
    );
};

EmailListControllerToFinalForm.propTypes = propTypes;

export default EmailListControllerToFinalForm;