import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { Container, Form, Row, Alert } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';
import FormFieldColorPicker from '../../../components/formFields/FormFieldColorPicker';
import FormFieldWithRange from '../../../components/formFields/FormFieldWithRange';
import FormFieldWithCheckbox from '../../../components/formFields/FormFieldWithCheckbox';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import ContainerWithCard from '../../../components/ContainerWithCard';
import ButtonPreview from '../../../components/ButtonPreview';
import IconSelectorModal from '../../../components/modals/IconSelectorModal';

import { generateNonIdsOptionsForDropdown } from '../../../utils/components';
import { getButton, updateButton } from '../../../redux/methods/visualSettings';
import { withFormUtils } from '../../../hoc/withFormUtils';
import useFetch from '../../../hooks/useFetch';
import { defaultFontsConfig, onHoverAnimationButtonConfig } from '../../../config';

import styles from './index.module.scss';

const propTypes = {
    getButton: PropTypes.func,
    updateButton: PropTypes.func,
    location: PropTypes.object,
    fonts: PropTypes.array
};

const ButtonsEdit = ({
    getButton,
    updateButton,
    location,
    fonts,
}) => {
    const intl = useIntl();
    const translations = intl.messages.visualSettings.buttons;

    const query = new URLSearchParams(location.search);
    const buttonId = query.get('buttonID');

    const [ button ] = useFetch(getButton, buttonId);
    const [ selectedIcon, setSelectedIcon ] = useState(null);

    useEffect(() => {
        if(button) {
            setSelectedIcon(button.style.icon);
        }
    }, [button]);

    const handleOnSubmit = formData => {
        const updatedData = {
            ...formData,
            id: parseInt(buttonId) === 0 ? null : parseInt(buttonId),
            style: {
                ...formData.style,
                icon: selectedIcon
            },
        };
        updateButton(updatedData);
    };

    const fontsDropdownOptions = generateNonIdsOptionsForDropdown([...fonts, ...defaultFontsConfig]);

    const iconPosiitionDropdownOptions = generateNonIdsOptionsForDropdown(['right', 'left'], translations.inputs);

    const onHoverDropdownOptions = [
        { id: 0, label: translations.inputs.animationTypes.none },
        ...onHoverAnimationButtonConfig.map(option => ({ ...option, label: translations.inputs.animationTypes[option.label] }))
    ];

    return button ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search'>
            <FinalForm
                initialValues={button}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values }) => {
                    const fontCurrentSelectionLabel = values.style.font || translations.inputs.select;
                    const onHoverCurrentSelectionLabel = onHoverDropdownOptions.find(option => option.id === values.style.onHoverAnimationId);
                    const iconPositionCurrentSelectionLabel = iconPosiitionDropdownOptions.find(option => option.id === values.style.iconPosition);
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>
                            <Alert color={'info'} isOpen={true}>
                                {translations.buttonContentInfo}
                            </Alert>
                                <Row className={styles.previewContainer}>
                                    <ButtonPreview
                                        button={{
                                            ...values.style,
                                            icon: selectedIcon,
                                            iconPosition: values.style.iconPosition ? values.style.iconPosition : 'right'
                                        }}
                                        displayInContainer
                                        previewTranslation={translations.inputs.previewButtonTranslation}
                                    />
                                </Row>
                                <Row>
                                    <FormFieldTextOrNumber
                                        name='name'
                                        label={translations.inputs.name}
                                        required
                                    />
                                </Row>
                                <Row className='mt-4'>
                                    <div className={clsx(styles.section)}>
                                        <FormFieldWithRange
                                            name='style.fontSize'
                                            label={translations.inputs.fontSize}
                                            min={8}
                                        />
                                        <FormFieldColorPicker
                                            name='style.fontColor'
                                            label={translations.inputs.fontColor}
                                            button={translations.inputs.select}
                                        />
                                        <FormFieldWithCheckbox
                                            name='style.isFontBold'
                                            label={translations.inputs.isFontBold}
                                        />
                                        <FormFieldWithDropdown
                                            name='style.font'
                                            label={translations.inputs.font}
                                            options={fontsDropdownOptions}
                                            currentSelectionLabel={fontCurrentSelectionLabel}
                                        />
                                    </div>
                                    <div className={clsx(styles.section)}>
                                        <FormFieldWithRange
                                            name='style.buttonVerticalSize'
                                            label={translations.inputs.buttonVerticalSize}
                                        />
                                        <FormFieldWithRange
                                            name='style.buttonHorizontalSize'
                                            label={translations.inputs.buttonHorizontalSize}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className={clsx(styles.section)}>
                                        <IconSelectorModal
                                            selectedIcon={selectedIcon}
                                            setSelectedIcon={setSelectedIcon}
                                        />
                                        <FormFieldWithDropdown
                                            name='style.iconPosition'
                                            label={translations.inputs.iconPosition}
                                            options={iconPosiitionDropdownOptions}
                                            currentSelectionLabel={iconPositionCurrentSelectionLabel ? iconPositionCurrentSelectionLabel.label : iconPosiitionDropdownOptions[0].label}
                                        />
                                    </div>
                                    <div className={clsx(styles.section)}>
                                        <FormFieldWithRange
                                            name='style.borderSize'
                                            label={translations.inputs.borderSize}
                                            max={20}
                                        />
                                        <FormFieldWithRange
                                            name='style.borderRadius'
                                            label={translations.inputs.borderRadius}
                                            max={50}
                                        />
                                        <FormFieldColorPicker
                                            name='style.borderColor'
                                            label={translations.inputs.borderColor}
                                            button={translations.inputs.select}
                                        />
                                        <FormFieldColorPicker
                                            name='style.backgroundColor'
                                            label={translations.inputs.backgroundColor}
                                            button={translations.inputs.select}
                                        />
                                        <FormFieldWithCheckbox
                                            name='style.isTransparent'
                                            label={translations.inputs.isTransparent}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className={clsx(styles.section)}>
                                        <FormFieldWithRange
                                            name='style.shadowVerticalPosition'
                                            label={translations.inputs.shadowVerticalPosition}
                                            min={-50}
                                            max={50}
                                            initialValue={0}
                                        />
                                        <FormFieldWithRange
                                            name='style.shadowHorizontalPosition'
                                            label={translations.inputs.shadowHorizontalPosition}
                                            min={-50}
                                            max={50}
                                            initialValue={0}
                                        />
                                        <FormFieldWithRange
                                            name='style.shadowBlurRadius'
                                            label={translations.inputs.shadowBlurRadius}
                                        />
                                        <FormFieldWithRange
                                            name='style.shadowSpreadRadius'
                                            label={translations.inputs.shadowSpreadRadius}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className={clsx(styles.section)} >
                                        <FormFieldWithDropdown
                                            name='style.onHoverAnimationId'
                                            label={translations.inputs.onHoverAnimation}
                                            options={onHoverDropdownOptions}
                                            currentSelectionLabel={onHoverCurrentSelectionLabel ? onHoverCurrentSelectionLabel.label : translations.inputs.select}
                                        />
                                        <FormFieldColorPicker
                                            name='style.previewBackgroundColor'
                                            label={translations.inputs.previewBackgroundColor}
                                            button={translations.inputs.select}
                                        />
                                    </div>
                                </Row>
                                <SectionEditFormButtons formReset={form.reset} />
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ): <ContainerWithCard />;
};

ButtonsEdit.propTypes = propTypes;

const mapStateToProps = state => ({
    fonts: state.fonts.fonts,
});

const mapDispatch = {
    getButton,
    updateButton
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ButtonsEdit));