import { adminRoutes } from '../routes/_adminRoutes';

const adminNavRoutes = (appSidebardTranslations, disableSectionsId = []/*, searchText = ''*/) => {
    const sections = Object.keys(appSidebardTranslations.sections);
    const routes = Object.values(adminRoutes);

    const isSectionDisabled = sectionId => !!disableSectionsId.find(section => section === sectionId);

    const sectionArrays = sections.map(section => {
        const sectionPositions = routes.filter(route => route.type === section);

        const filteredPositions = sectionPositions
            .filter(sectionPosition => sectionPosition.type === section)
            .map(sectionPosition => ({
                url: sectionPosition.path,
                icon: sectionPosition.icon,
                name: appSidebardTranslations.positions[sectionPosition.id],
                attributes: { disabled: isSectionDisabled(sectionPosition.id) },
                children: sectionPosition.children &&
                    sectionPosition.children
                        .map(subSection => ({
                            url: subSection.path,
                            icon: subSection.icon,
                            name: appSidebardTranslations.positions[subSection.id],
                            attributes: { disabled: isSectionDisabled(subSection.id) },
                        })
                )
            }));

        if (filteredPositions.length === 0) {
            return null;
        }

        return [
            {
                title: true,
                name: appSidebardTranslations.sections[section]
            },
            ...filteredPositions
        ];
    });

    return {
        items: sectionArrays.filter(arr => arr !== null).reduce((acc, array) => {
            return [ ...acc, ...array ];
        }, [])
    };
};

export default adminNavRoutes;