import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';
import { getCurrencies } from '../../../redux/methods/currencies';
import useFetch from '../../../hooks/useFetch';

import { generateDropdownOptionsForCurrencies } from '../../../utils/discounts';

import SearchForm from '../../../components/generators/SearchForm';

const propTypes = {
    storeCurrentLanguage: PropTypes.string,
    onSubmit: PropTypes.func,
    getCurrencies: PropTypes.func,
    searchConfig: PropTypes.object.isRequired,
};

const ExchnageRateSearchForm = ({ storeCurrentLanguage, onSubmit, getCurrencies, searchConfig }) => {
    const intl = useIntl();
    const translations = intl.messages.exchangeRates.searchForm;

    const [ currencies ] = useFetch(getCurrencies);

    return currencies ? (
        <SearchForm
            searchConfig={searchConfig}
            translations={{
                buttons: {
                    submit: translations.action,
                    reset: translations.reset,
                }
            }}
            inputs={[
                {
                    label: translations.showActive,
                    name: 'showActive',
                    type: 'checkbox'
                },
                {
                    type: 'dropdown',
                    label: translations.currency,
                    name: 'currencyId',
                    options: generateDropdownOptionsForCurrencies(currencies, translations.currencyPlaceholder),
                    defaultLabel: translations.currencyPlaceholder
                },
                {
                    label: translations.dateFrom,
                    name: 'dateFrom',
                    type: 'date',
                    storeCurrentLanguage: storeCurrentLanguage,
                },
                {
                    label: translations.dateTo,
                    name: 'dateTo',
                    type: 'date',
                    storeCurrentLanguage: storeCurrentLanguage,
                    minDateDependentField: 'dateFrom',
                },
            ]}
            performSearch={onSubmit}
        />
    ) : null;
};

ExchnageRateSearchForm.propTypes = propTypes;

const mapStateToProps = state => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    getCurrencies,
};

export default connect(mapStateToProps, mapDispatch)(ExchnageRateSearchForm);