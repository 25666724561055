/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import TableGenerator from '../../generators/TableGenerator';

import { generateProductEditLink, generateShopProductLink } from '../../../utils/products';

import { stringOrNumberPropTypes } from '../../../propTypes';

import PhotoPlaceholder from '../../../assets/photo_placeholder.svg';
import styles from './index.module.scss';
import { withFormUtils } from '../../../hoc/withFormUtils';
import getConfig from '../../../getConfig';


const propTypes = {
    translatedProductsList: PropTypes.arrayOf(PropTypes.shape({
        id: stringOrNumberPropTypes,
        translation: PropTypes.string,
        productImage: PropTypes.string,
        catalgueIndex: PropTypes.string,
        tradeIndex: PropTypes.string,
    })),
    checkedProductsId: PropTypes.arrayOf(stringOrNumberPropTypes),
    setCheckedProductsId: PropTypes.func,
    removeAction: PropTypes.func,
    fillers: PropTypes.shape({
        name: PropTypes.string,
        catalgueIndex: PropTypes.string,
        tradeIndex: PropTypes.string,
    }),
    isRedOnHover: PropTypes.bool,
    deleteModal: PropTypes.shape({
        header: PropTypes.string,
        body: PropTypes.string,
        confirm: PropTypes.string,
        cancel: PropTypes.string,
    }),
    tooltipTranslation: PropTypes.object,
    emptyTable: PropTypes.string,
};

const ProductWithDeleteActionTable = ({
    translatedProductsList, checkedProductsId, setCheckedProductsId, emptyTable,
    removeAction, fillers, isRedOnHover, deleteModal, tooltipTranslation, utils
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.alternativeProducts;

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const deleteAction = id => () => {
        removeAction(id);
    };

    const showDetailsAction = (id, item) => () => {
        const productTypeId = item.productTypeId ?? 1;
        switch(productTypeId){
            case 1: {
                const shopProductWindow = window.open(generateShopProductLink(id, utils.currentLanguage()), '_blank', 'noopener, noreferrer');
                if (shopProductWindow) {
                    shopProductWindow.opener = null;
                }
            }
            default: {
                utils.toast('Wyświetlenie podglądu usługi nie jest możliwe', 'warning');
            }
        }
    };

    const ImageCell = item => (
        <img
            className={styles.image}
            src={item.productImage ? `${apiUrl}${item.productImage}` : PhotoPlaceholder}
            alt={item.productImageAlternativeText || translations.emptyLogoAlt}
        />
    );

    return (
        <TableGenerator
            items={translatedProductsList}
            checkedItemsIds={checkedProductsId}
            setCheckedItemsIds={setCheckedProductsId}
            actions={{ deleteAction, showDetailsAction }}
            editLink={ generateProductEditLink }
            expectedActions={['delete', 'edit', 'showDetails']}
            translations={{
                tooltips: tooltipTranslation,
                deleteModal,
                emptyTable: emptyTable,
            }}
            additionalRowStyle={isRedOnHover && styles.redBackround}
            customIdSelector={'productId'}
            columns={[
                {
                    type: 'checkbox'
                },
                {
                    header: translations.headers.photo,
                    type: 'custom',
                    customCell: ImageCell,
                    additionalStyle: styles.imageCell
                },
                {
                    header: translations.headers.name,
                    value: 'productTranslatedName',
                    additionalStyle: styles.nameCell,
                    filler: fillers.name
                },
                {
                    header: translations.headers.catalogueIndex,
                    value: 'catalogueIndex',
                    filler: fillers.catalgueIndex
                },
                {
                    header: translations.headers.tradeIndex,
                    value: 'tradeIndex',
                    filler: fillers.tradeIndex
                },
                {
                    header: translations.headers.actions,
                    type: 'actions',
                    keyToPass: 'products'
                },
            ]}
        />
    );
};

ProductWithDeleteActionTable.propTypes = propTypes;

export default withFormUtils(ProductWithDeleteActionTable);