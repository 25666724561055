import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    products: {
        countRows: true,
        pageSize: 10,
        curentPage: 0,
        productName: null,
        catalogueIndex: null,
        tradeIndex: null,
        productId: null,
        categoryId: null,
        inSubcategories: false,
        categoryTypeId: null,
        status: null,
        ean: null,
        manufacturerId: [],
        variantId: null,
        productVariantType: null,
        onlyWithWarnings: false
    },
};

const searchConfigs = createSlice({
    name: 'searchConfigs',
    initialState,
    reducers: {
        setProducts(state, action) {
            state.products = { ...state.products, ...action.payload };
        },
    }
});

export const { setProducts } = searchConfigs.actions;

export default searchConfigs.reducer;