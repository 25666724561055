const integrations = {
	googleSearchConsole: {
        title: 'Integracje > Google search console',
		labels: {
			enabled: 'Integracja włączona',
			key: 'Klucz',
		}
    },
	recaptcha: {
        title: 'Integracje > reCaptcha',
		labels: {
			enabled: 'Integracja włączona',
			secretKey: 'Secret key',
			siteKey: 'Site key',
		}
    },
    p24: {
        title: 'Integracje > Przelewy24',
		labels: {
			enabled: 'Integracja włączona',
			merchantId: 'Merchant ID',
			posId: 'POS ID',
			apiUrl: 'API Url',
			returnUrl: 'Url powrotny',
			statusUrl: 'Url statusów',
			crcKey: 'Klucz CRC',
			reportKey: 'Klucz do raportów',
			redirectUrl: 'Url przekierowania',
			sessionIdTemplate: 'Szablon identyfikatora sesji',
			descriptionTemplate: 'Szablon opisu',
			transferLabelTemplate: 'Szablon opisu transferu?'
		}
    },
    payu: {
        title: 'Integracje > PayU',
		labels: {
			enabled: 'Integracja włączona',
			authUrl: 'Url autoryzacji',
			apiUrl: 'API Url',
			statusUrl: 'Url statusów',
			posId: 'POS ID',
			clientId: 'ID klienta',
			secondKeyMd5: 'Klucz MD5',
			clientSecret: 'Sekretny kod klienta',
			descriptionTemplate: 'Szablon opisu',
			returnUrl: 'Url powrotny'
		}
    },
    inpost: {
        title: 'Integracje > InPost',
		labels: {
			enabled: 'Integracja włączona',
			apiUrl: 'API Url',
			acceptLanguage: 'Język',
			organizationId: 'ID organizacji',
			token: 'Token',

			defaultSenderCompanyName: 'Nazwa firmy',
			defaultSenderEmail: 'Email',
			defaultSenderPhone: 'Nr telefonu',
			defaultSenderAddress: 'Adres',
			defaultSenderFirstName: 'Imię',
			defaultSenderLastName: 'Nazwisko',

			defaultCustomAttributesDropoffPoint: 'Punkt nadania (dropoff_point)',
			defaultServiceType: 'Usługa',
			defaultCustomAttributesSendingMethod: 'Rodzaj przesyłki',

			defaultShipmentLength: 'Długość [cm]',
			defaultShipmentHeight: 'Wysokość [cm]',
			defaultShipmentWidth: 'Szerokość [cm]',

			defaultShipmentWeightAmount: 'Waga [kg]',

			defaultShipmentIsNonStandard: 'Przesyłka niestandardowa',

			defaultCodAmount: 'Ilość',
			defaultCodCurrency: 'Waluta',

			defaultInsuranceAmount: 'Ilość',
			defaultInsuranceCurrency: 'Waluta',

			defaultAdditionalServiceCod: 'Cod',
			defaultAdditionalServiceInsurance: 'Ubezpieczenie',
			defaultAdditionalServiceSms: 'Sms',
			defaultAdditionalServiceEmail: 'Email',
			defaultAdditionalServiceSaturday: 'Sobota',
			defaultAdditionalServiceDor1720: 'Do drzwi 17-20',
			defaultAdditionalServiceForhour9: 'Do godziny 9',
			defaultAdditionalServiceForhour10: 'Do godziny 10',
			defaultAdditionalServiceForhour11: 'Do godziny 11',
			defaultAdditionalServiceForhour12: 'Do godziny 12',
			defaultAdditionalServiceForhour13: 'Do godziny 13',
			defaultAdditionalServiceForhour14: 'Do godziny 14',
			defaultAdditionalServiceForhour15: 'Do godziny 15',
			defaultAdditionalServiceForhour16: 'Do godziny 16',
			defaultAdditionalServiceForhour17: 'Do godziny 17',
			defaultAdditionalServiceRod: 'Rod',

			defaultMpk: 'Miejsce powstawania kosztów',
			defaultComments: 'Komentarz'
		},
		headers: {
			urls: 'Dane dostępowe do API',
			sender: 'Domyślne dane nadawcy',
			customAttributes: 'Dodatkowe atrybuty',
			shipment: 'Domyślne ustawienia paczki',
			cod: 'Cod',
			insurance: 'Ubezpieczenie',
			additionalService: 'Dodatkowe ustawienia',
			other: 'Inne',
		}
    },
    apaczka: {
        title: 'Integracje > APaczka',
		labels: {
			enabled: 'Integracja włączona',
			apiUrl: 'API Url',
			appId: 'API Id',
			appSecret: 'API Secret',
			pickupName: 'Imię i nazwisko',
			pickupCity: 'Miasto',
			pickupPostalCode: 'Kod pocztowy',
			pickupLine1: 'Adres - linia 1',
			pickupLine2: 'Adres - linia 2',
			pickupContactPerson: 'Osoba kontantowa',
			pickupEmail: 'Email',
			pickupPhone: 'Nr telefonu',
			pickupCountryCode: 'Kod kraju',
			pickupIsResidential: 'Is residential',
			pickupForeignAddressId: 'Foreign address id',

			defaultPickupType: 'Typ',
			defaultPickupDate: 'Data',
			defaultPickupHoursFrom: 'Godziny od',
			defaultPickupHoursTo: 'Godziny do',

			defaultOptionSmsNotification: 'Powiadomienia sms',
			defaultOptionRod: 'Rod',
			defaultOptionDelivaryOnSaturday: 'W sobotę',
			defaultOptionDelivaryInHours: 'W godzinach',
			defaultOptionBeCarefull: 'Ostrożnie',

			defaultNotificationNewReceiverEmail: 'Nowa - powiadamiaj odbiorcę emailem',
			defaultNotificationNewReceiverSms: 'Nowa - powiadamiaj odbiorcę smsem',
			defaultNotificationNewSenderEmail: 'Nowa - powiadamiaj nadawcę smsem',
			defaultNotificationSentReceiverEmail: 'Wysłana - powiadamiaj odbiorcę emailem',
			defaultNotificationSentReceiverSms: 'Wysłana - powiadamiaj odbiorcę smsem',
			defaultNotificationSentSenderEmail: 'Wysłana - powiadamiaj nadawcę smsem',
			defaultNotificationExceptionReceiverEmail: 'Błąd - powiadamiaj odbiorcę emailem',
			defaultNotificationExceptionReceiverSms: 'Błąd - powiadamiaj odbiorcę smsem',
			defaultNotificationExceptionSenderEmail: 'Błąd - powiadamiaj nadawcę smsem',
			defaultNotificationDeliveredReceiverEmail: 'Dostarczona - powiadamiaj odbiorcę emailem',
			defaultNotificationDeliveredReceiverSms: 'Dostarczona - powiadamiaj odbiorcę smsem',
			defaultNotificationDeliveredSenderEmail: 'Dostarczona - powiadamiaj nadawcę smsem',

			defaultCodAmount: 'Ilość',
			defaultCodBankaccount: 'Konto bankowe',

			defaultShipmentDimension1: 'Wymiar A',
			defaultShipmentDimension2: 'Wymiar B',
			defaultShipmentDimension3: 'Wymiar C',
			defaultShipmentWeight: 'Waga',
			defaultShipmentIsNstd: 'Is Nstd',
			defaultShipmentContentDescription: 'Opis zawartości',
			defaultShipmentServiceId: 'Usługa',
			defaultShipmentTypeCode: 'Rodzaj przesyłki',
		},
		headers: {
			urls: 'Dane dostępowe do API',
			senderAddress: 'Adres nadawcy',
			defaultPickup: 'Domyślne ustawienia nadania',
			defaultOptions: 'Domyślne opcje',
			defaultNotifications: 'Domyślne powiadomienia',
			defaultCod: 'Domyślne COD',
			defaultShipment: 'Domyślne ustawienia przesyłki'
		}
    },
    furgonetka: {
        title: 'Integracje > Furgonetka',
		labels: {
			enabled: 'Integracja włączona',

			apiUrl: 'API Url',
			authUrl: 'Auth Url',
			clientId: 'Client ID',
			clientSecreet: 'Client secreet',
			userEmail: 'Adres email logowania',
			userPassword: 'Hasło logowania',

			pickupName: 'Imię i nazwisko',
			pickupNameTooltip: 'Wymagane jeśli nie jest uzupełnione pole "Nazwa firmy", minimum 2 znaki dla imienia oraz 2 znaki dla nazwiska, całkowita długość pola minimum 6 znaków, maksymalnie 90 znaków. DHL - max 60 znaków, UPS - max 30 znaków',
			pickupCompany: 'Nazwa firmy',
			pickupCompanyTooltip: 'Wymagane jeśli nie jest uzupełnione pole "Imię i nazwisko", minimum 2 znaki, maksymalnie 80 znków. DHL - max 60 znaków, FedEx - max 150 znaków, UPS - max 30 znaków',
			pickupStreet: 'Ulica, numer domu i lokalu',
			pickupPostcode: 'Kod pocztowy',
			pickupCity: 'Miasto',
			pickupCountryCode: 'Kod kraju w formacie ISO 3166-1 alpha-2',
			pickupCounty: 'County',
			pickupCountyTooltip: 'Wymagane dla Irlandii (nazwa hrabstwa), Stanów Zjednoczonych (skrót stanu / kod ISO), Kanady (Kod pocztowy prowincji / kod ISO)',
			pickupEmail: 'E-mail',
			pickupPhone: 'Numer telefonu',

			pickupPoint: 'Punkt nadania',

			defaultShipmentServiceId: 'Usługa',
			defaultShipmentType: 'Rodzaj przesyłki',
			defaultShipmentWidth: 'Długość paczki w cm',
			defaultShipmentDepth: 'Szerokość paczki w cm',
			defaultShipmentHeight: 'Wysokość paczki w cm',
			defaultShipmentWeight: 'Waga paczki w kg',
			defaultShipmentValue: 'Wartość przesyłki w PLN',
			defaultShipmentValueTooltip: '(od podania tej wartości zależy ubezpieczenie przesyłki). W przypadku włączenia usługi COD wartość przesyłki nie może być mniejsza od wartości pobrania',
			defaultShipmentDescription: 'Opis zawartości paczki (od 2 do 100 znaków)',
			defaultShipmentDescriptionTooltip: 'DHL - wymagany (od 2 do 30 znaków) DPD - opcjonalny (jeżeli kraj odbiorcy "PL") FedEx - wymagany GLS - opcjonalny InPost - opcjonalny InPost Kurier - opcjonalny (jeżeli kraj odbiorcy "PL") Meest - opcjonalny Poczta Polska - opcjonalny Paczka w RUCHu - opcjonalny UPS - opcjonalny (jeżeli kraj odbiorcy "PL", od 2 do 50 znaków) Allegro - wymagany od 2 do 50 znaków - jak w UPS',

			defaultCodAmount: 'Wartość pobrania w PLN',
			defaultCodExpress: 'Usługa pobraniowa Express',
			defaultCodExpressTooltip: 'Kwota pobrania wysyłana jest nadawcy 1 dzień roboczy po dostarczeniu przesyłki. Usługa dostępna dla: DPD, FedEx, InPost Kurier',
			defaultCodIban: 'Numer rachunku bankowego.',
			defaultCodName: 'Właściciel rachunku bankowego.',

			defaultAdditionalServiceRod: 'Dokumenty zwrotne.',
			defaultAdditionalServiceCud: 'Usługa "Przesyłka zwrotna" polega na przyjęciu od Odbiorcy, przy wydawaniu mu Przesyłki, innej przesyłki adresowanej do Nadawcy Przesyłki pierwotnej (wyklucza się z usługą "Dokumenty zwrotne")',
			defaultAdditionalServicePrivateShipping: 'Doręczenie do osoby prywatnej.',
			defaultAdditionalServiceGuarantee0930: 'Doręczenie do godziny 9:30 / 10:30.',
			defaultAdditionalServiceGuarantee1200: 'Doręczenie do godziny 12:00.',
			defaultAdditionalServiceSaturdayDelivery: 'Doręczenie w sobotę. Wymagane jest, aby data nadania wypadała w piątek.',
			defaultAdditionalServiceAdditionalHandling: 'Element niestandardowy.',
			defaultAdditionalServiceSmsPredeliveryInformation: 'Awizacja SMS-owa doręczenia.',
			defaultAdditionalServiceDocumentsSupply: 'Usługa dostarczenia dokumentów przewozowych przez firmę kurierską.',
			defaultAdditionalServiceSaturdaySundayDelivery: 'Usługa "Paczka w Weekend" - doręczenie przesyłki do paczkomatu w sobotę lub niedzielę, przesyłka powinna być nadana od czwartku od godziny 20:00 do soboty do godziny 13:00.',
			defaultAdditionalServiceGuaranteeNextDay: 'Doręczenie w następnym dniu roboczym.',
			defaultAdditionalServiceFedexPriority: 'Usługa Fedex Priority - czas doręczenia nawet dwukrotnie szybszy od opcji Economy (do 3 dni roboczych). Dostępny dla przesyłek międzynarodowych.',
			defaultAdditionalServiceUpsSaver: 'UPS Express Saver.',
			defaultAdditionalServiceValuableShipment: 'Usługa "Przesyłka wartościowa".',
			defaultAdditionalServiceFragile: 'Usługa "Ostrożnie".',
			defaultAdditionalServicePersonalDelivery: 'Usługa "Doręczenie do rąk własnych" polega na zapewnieniu wydania Przesyłki wyłącznie Odbiorcy wskazanemu na Potwierdzeniu Nadania, którego tożsamość zostanie zweryfikowana przez Kuriera na podstawie dokumentu tożsamości.',
			defaultAdditionalServicePocztaKurier48: 'Usługa "Kurier48". Domyślna usługa dla przesyłek krajowych. Wyklucza się z usługami: Ekspres24, Polecony, Przesyłka firmowa polecona, Polecony zagraniczny, Global expres',
			defaultAdditionalServiceRegisteredLetter: 'Usługa "Polecony". Wyklucza się z usługami: Ekspres24, Kurier48, Przesyłka firmowa polecona, Polecony zagraniczny, Global expres',
			defaultAdditionalServiceRegisteredCompanyLetter: 'Usługa "Przesyłka firmowa polecona" polega na nadaniu przesyłki za potwierdzeniem nadania i doręczenia za pokwitowaniem odbioru. Może występować też pod nazwą "polecony ecommerce". Wyklucza się z usługami: Ekspres24, Kurier48, Polecony, Polecony zagraniczny, Global expres',
			defaultAdditionalServiceRegisteredLetterInternational: 'Usługa "Polecony Zagraniczny". Domyślna usługa dla międzynarodowych przesyłek typu "Koperta". Wyklucza się z usługami: Ekspres24, Kurier48, Polecony, Przesyłka firmowa polecona, Global expres',
			defaultAdditionalServicePocztaGlobalexpres: 'Usługa "Global Expres". Domyślna usługa dla międzynarodowych przesyłek typu "Przesyłka". Wyklucza się z usługami: Ekspres24, Kurier48, Polecony, Przesyłka firmowa polecona, Polecony zagraniczny',
			defaultAdditionalServiceDeliveryConfirmation: 'Usługa "Potwierdzenie odbioru przesyłki" polega na doręczeniu nadawcy formularza "Potwierdzenie odbioru" z pokwitowaniem odbioru przesyłki dokonanym przez odbiorcę. Występuje tylko w połączeniu z usługą Ekspres24.',
			defaultAdditionalServiceWaitingTime: 'Oczekiwanie kuriera na przesyłkę.',
			defaultAdditionalServicePocztaExpres24: 'Usługa "Ekspres24" gwarantuje, że przesyłki nadane w tej usłudze (adresowane do strefy A) zostaną doręczone już drugiego dnia po dniu nadania. Wyklucza się z usługami: Kurier48, Polecony, Przesyłka firmowa polecona, Polecony Zagraniczny, Global expres',
			defaultAdditionalPocztaServiceGuarantee0800: 'Doręczenie do godziny 8:00. Występuje tylko w połączeniu z usługą Ekspres24.',
			defaultAdditionalPocztaServiceDeliveryAfter1700: 'Doręczenie po godzinie 17:00. Występuje tylko w połączeniu z usługą Ekspres24.',
			defaultAdditionalPocztaServiceCheckContent: 'Usługa "Sprawdzenie zawartości". Występuje tylko w połączeniu z usługą Ekspres24.',
			defaultAdditionalPocztaServiceDeliveryOnTime: 'Doręczenie na wskazaną godzinę. Występuje tylko w połączeniu z usługą Ekspres24. Dostępny zakres: [13:00 - 20:00]',
			defaultCostCenter: 'Miejsce Powstania Kosztów',
			defaultCostCenterTooltip: 'wykorzystywane dla umów własnych DPD jako payerCostCenter. Maksymalna ilość znaków: 50',
		},
		headers: {
			settings: {
				general: 'Atrybuty ogólne',
				ups: 'Ustawienia dla UPS',
				inpost:'Ustawienia dla InPost',
				inpostkurier: 'Ustawienia dla InPost Kurier',
				poczta:'Ustawienia dla Poczta Polska',
				ruch:'Ustawienia dla Ruch',
				dpd:'Ustawienia dla DPD',
				dhl: 'Ustawienia dla DHL',
				fedex: 'Ustawienia dla FedEx'
			},
			urls: 'Lokalizacja API',
			ids: 'Dane dostępowe do API',
			senderAddress: 'Adres nadawcy',
			shipment: 'Domyślne ustawienia przesyłki',
			cod: 'Usługa pobraniowa',
			costCenter: 'Miejsce powstawania kosztów'
		}
    },
    facebook: {
        title: 'Integracje > Facebook',
        label: 'Kod do integracji'
    },
    freshmail: {
        title: 'Integracje > Freshmail',
        labels: {
            enable: 'Włącz integracje',
            apiKey: 'Klucz do API',
            secretApiKey: 'Sekretny klucz do API',
            listDropdown: 'Wybierz listę mailingową',
            dropdownSelectLabel: 'Wybierz'
        }
    },
    ceneo: {
        title: 'Ceneo',
        tabs: {
            settings: 'Ustawienia',
            categoryMapping: 'Mapowanie kategorii'
        },
        inputs: {
            enable: 'Włącz integracje z Ceneo',
            ceneoPath: 'Ścieżka do wklejenia w Ceneo (dostępne tylko kopiowanie zawartości)',
            generateXLMAuto: 'Generuj plik XML automatycznie',
            generateFileFrequency: 'Co ile godzin generować plik (1 - 168h)',
            lastGeneratedFileDate: 'Data ostatniego generowania pliku: ',
            useStockParam: 'Używaj parametru STOCK - stan magazynowy',
            generateMappedProductsFile: 'Generuj plik CENEO tylko z artykułami zmapowanymi'
        },
        pathCopiedAlert: 'Ścieżka Ceneo skopiowana do schowka',
        productIndexDropdown: {
            label: 'Indeks artykułu używany jako kod producenta Ceneo'
        },
        availableAvailParam: {
            label: 'Parametr AVAIL w Ceneo dla artykułów dostępnych w sklepie'
        },
        unavailableAvailParam: {
            label: 'Parametr AVAIL w Ceneo dla artykułów niedostępnych w sklepie'
        },
        defaultUnmappedCategory: {
            label: 'Domyślna kategoria Ceneo dla artykułów z niezmapowanych kategorii'
        },
        buttons: {
            updateCeneoCategories: 'Zaktualizuj kategorie Ceneo',
            preview: 'Podgląd pliku XML',
            generateXML: 'Generuj plik XML',
            save: 'ZAPISZ ZMIANY',
            cleanMapping: 'Wyczyść mapowanie',
            addMapping: 'Dodaj mapowanie',
            assignCategory: 'Przypisz',
            cancel: 'Anuluj',
            emptyCategory: 'Brak kategorii'
        },
        table: {
            headerLabels: {
                categoryShop: 'Kategoria sklep',
                categoryCeneo: 'Kategoria Ceneo',
                actions: "Akcje",
            },
            tooltips: {
                remove: 'Usuń kategorię'
            },
            emptyTable: 'Brak kateogrii do wyświetlenia',
            nameFiller: '[BRAK NAZWY]',
            deleteModal: {
                header: 'Usuń mapowanie kategorii',
                body: 'Czy napewno chcesz usunąć mapowanie kategorii?',
                confirm: 'Usuń',
                cancel: 'Wróć'
            },
            unSupportedAlert: 'UWAGA! Na Twojej liście mapowań znajdują się kategorie, które przestały być wspierane przez Ceneo. Mapowania te są zaznaczone na czerwono. Usuń mapowanie i dodaj nowe z aktualnymi kategoriami.'
        },
        modalHeader: 'Przypisz kategorie',
        outsourceSystemCategories: 'Kategorie Ceneo',
        shopCategories: 'Kategorie sklepowe',
        availParamsLabels: {
            availableInOneDay: 'Wysyłka w ciągu 24 godzin',
            availableInThreeDays: 'Wysyłka w ciągu 3 dni',
            availableInWeek: 'Wysyłka w ciągu tygodnia',
            availableInTwoWeeks: 'Wysyłka w ciągu 14 dni',
            onRequest: 'Towar na zamówienie',
            checkInStore: 'Sprawdź status w sklepie',
            preSales: 'Przedsprzedaż',
        },
        dropdownSelectLabel: 'Wybierz',
        productIndexOptions: {
            Catalogue: 'Indeks katalogowy',
            Trade: 'Indeks handlowy',
            Ean: 'Kod kreskowy',
        },
		categoriesNotImported: 'Nie zaimportowano żadnych kategorii Ceneo. Przeprowadzenie mapowania będzie możliwe po ich zaimportowaniu.'
    },
    google: {
        title: 'Google',
        tabs: {
            googleAnalytics: 'Google Analytics'
        },
        labels: {
            googleAnalyticsTag: 'Identyfikator pomiaru Google Analytics',
			gtmContainerId: 'Identyfikator kontenera GTM'
        }
    },
    chat: {
        title: 'Chat',
        labels: {
            enabled: 'Aktywny',
            script: 'Skrypt'
        },
        scriptFormatAlert: 'Skrypt musi znajdować się pomiędzy znakami <script></script>'
    },
    inspectlet: {
        title: 'Inspectlet',
        labels: {
            enabled: 'Aktywny',
            script: 'Skrypt'
        },
        scriptFormatAlert: 'Skrypt musi znajdować się pomiędzy znakami <script></script>'
    }
};

export default integrations;