import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useIntl } from 'react-intl';

import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import ChartSection from '../../../features/dashboard/sections/ChartSection';
import StatsSection from '../../../features/dashboard/sections/StatsSection';
import DashboardOrders from '../DashboardOrders';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object,
};

const DashboardGeneral = ({ history }) => {
    const intl = useIntl();
    const translations = intl.messages.dashboard.general;

    return (
        <Container fluid className={styles.fullScreenOnMobile}>
            <Card>
                <CardHeader>
                    <i className={clsx('fa fa-align-justify')}></i>
                    <span>{translations.title}</span>
                </CardHeader>
                <CardBody>
                    <Container fluid className={clsx('mb-3 h-100', styles.fullScreenOnMobile)}>
                        <Row className='mb-2'>
                            <Col sm={6}>
                                <div className={styles.section}>
                                    <ChartSection translations={translations.chartSection} />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className={styles.section}>
                                    <StatsSection translations={translations.statsSection} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className={styles.ordersSection}>
                        <DashboardOrders
                            history={history}
                        />
                    </div>
                </CardBody>
            </Card>
        </Container>
    );
};

DashboardGeneral.propTypes = propTypes;

export default withRouter(DashboardGeneral);