import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    tabs: []
};

const settingTabs = createSlice({
    name: 'settingTabs',
    initialState,
    reducers: {
        setDefaultValue(state, action){
            const { tag, value } = action.payload;

            if (!(tag in state.tabs))
            {
                const newTabs = [ ...state.tabs ];
                newTabs[tag] = value;
                state.tabs = newTabs;
            }
        },
        setCurrentValue(state, action){
            const { tag, value } = action.payload;

            const newTabs = [ ...state.tabs ];
            newTabs[tag] = value;
            state.tabs = newTabs;
        }
    }
});

export const { setDefaultValue, setCurrentValue } = settingTabs.actions;

export default settingTabs.reducer;