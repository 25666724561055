import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import Modal from '../Modal';
import { Button } from 'reactstrap';

import { buttonIconsConfig } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './index.module.scss';

const IconSelectorModal = ({
    selectedIcon,
    setSelectedIcon
}) => {
    const intl = useIntl();
    const translations = intl.messages.visualSettings.buttons.iconsModal;

    const [ isOpen, setIsOpen ] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);

    const handleSelect = (icon) => () => {
        setSelectedIcon(icon);
    };

    const deleteSelection = () => {
        setSelectedIcon(null);
    };

    return (
        <React.Fragment>
            <div>
                <Button
                    onClick={toggleModal}
                    color={'primary'}
                >
                    {translations.label}
                </Button>
                <Button
                    className='ml-2'
                    color='danger'
                    onClick={deleteSelection}
                >
                    <i className="fa fa-times" />
                </Button>
            </div>
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                header={translations.label}
                onConfirm={toggleModal}
                buttons={{
                    confirm: {
                        label: translations.confirm,
                        color: 'success'
                    },
                    cancel: {
                        label: translations.cancel,
                        color: 'danger'
                    }
                }}
                body={
                    <div className={styles.container}>
                        {
                            buttonIconsConfig.map((icon, index) => (
                                <div
                                    key={`${index}-${icon.iconName}`}
                                    className={clsx(styles.iconContainer, selectedIcon === icon.iconName && styles.selected)}
                                    onClick={handleSelect(icon.iconName)}
                                >
                                    <FontAwesomeIcon icon={icon} size={'lg'} />
                                </div>
                            ))
                        }
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default IconSelectorModal;