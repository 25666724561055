const auth = {
    login: {
        title: "Logowanie",
        subTitle: "Zaloguj się do Connectico",
        email: "Email*",
        password: "Hasło*",
        recovery: "Nie pamiętam hasła",
        action: "Zaloguj się",
    },
    sharedFiller: {
        title: "Witaj w Connectico",
        logoAltText: "Connectico Logo"
    },
    resetPassword: {
        send: 'Wyślij',
        title: 'Resetuj hasło',
        subTitle: 'Na podany przez Ciebie adres email zostanie wysłane nowe hasło.',
        email: "Email*",
        backToLogin: 'Wróć do logowania',
        submitSucceeded: 'Link do zmiany hasła został wysłany na Twój adres email. Jeśli nie otrzymasz wiadomości w przeciągu godziny - prosimy skonktakuj się z administratorem.',
        newPassword: 'Nowe hasło',
        newPasswordRepeated: 'Powtórz nowe hasło',
        setNewPassword: {
            title: 'Ustaw nowe hasło',
            subTitle: 'Pamiętaj, że hasło musi składać się z conajmniej 8 znaków.'
        }
    },
};

export default auth;