import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getAdBannerAssignedCategories, addAdBannerCategories, deleteAdBannerCategories } from '../../../../redux/methods/adBanners';

import { Container, Row, Button } from 'reactstrap';

import CategoriesTable from '../../../../components/tables/CategoriesTable';
import useFetch from '../../../../hooks/useFetch';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getAdBannerAssignedCategories: PropTypes.func,
    addAdBannerCategories: PropTypes.func,
    deleteAdBannerCategories: PropTypes.func,
};

const AdBannerEditCategories = ({
    utils, adBannerId, forceRefetch, getAdBannerAssignedCategories, addAdBannerCategories, deleteAdBannerCategories
}) => {
    const intl = useIntl();
    const translations = intl.messages.adBanners.edit.tabs.categories;

    const [ categoryIdSelection, setCategoryIdSelection ] = useState([]);

    const [ data ] = useFetch(getAdBannerAssignedCategories, adBannerId, [], forceRefetch);

    const handleSelection = categoryId => {
        setCategoryIdSelection(categoryIdSelection.indexOf(categoryId) !== -1
            ? categoryIdSelection.filter(id => id !== categoryId)
            : [ ...categoryIdSelection, categoryId ]);
    };

    const handleDeleteAttributeCategory = categoryId => {
        deleteAdBannerCategories({ advertisementId: adBannerId, categoryId: [categoryId]});
    };

    const handleCategorySelectModalOpen = () => {
        utils.selectCategory(
            categoryId => addAdBannerCategories({ advertisementId: adBannerId, categoryId: [categoryId]}),
            translations.categorySelectionModal.body
        );
    };

    const handleDeletionModalOpen = () => {
        utils.confirm(
            () => deleteAdBannerCategories({ advertisementId: adBannerId, categoryId: categoryIdSelection}),
            translations.deletionModal.header,
            translations.deletionModal.body
        );
    };

    return data ? (
        <React.Fragment>
            <Container fluid>
                <CategoriesTable
                    categories={data}
                    checkedCheckboxList={categoryIdSelection}
                    columnNames={translations.table.columnNames}
                    tooltipTranslation={translations.table.tooltip}
                    emptyTranslation={translations.table.placeholder}
                    deleteModalTranslations={translations.table.deleteModal}
                    checkboxAction={handleSelection}
                    removeAction={handleDeleteAttributeCategory}
                />
                <Row className={clsx('d-flex flex-row mt-5', data && data.length > 0 ? 'justify-content-between' : 'justify-content-end')}>
                    {
                        data && data.length > 0 && (
                            <Button
                                outline
                                type="button"
                                disabled={!categoryIdSelection || categoryIdSelection.length === 0}
                                onClick={handleDeletionModalOpen}
                                color="danger"
                            >
                                <i className="fa fa-trash-o mr-2"/>
                                <span>{translations.controls.remove}</span>
                            </Button>
                        )
                    }
                    <Button onClick={handleCategorySelectModalOpen} color="primary">
                        <i className="fa fa-plus mr-2"/>
                        <span>{translations.controls.add}</span>
                    </Button>
                </Row>
            </Container>
        </React.Fragment>
    ) : null;
};

AdBannerEditCategories.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.adBannersCategories,
});

const mapDispatch = {
    getAdBannerAssignedCategories, addAdBannerCategories, deleteAdBannerCategories
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(AdBannerEditCategories));