import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from 'reactstrap';
import UnitsTable from '../../../../components/tables/UnitsTable';
import UnitsEditionModal from '../../../../components/modals/UnitsEditionModal';

const propTypes = {
    translations: PropTypes.object,
    deleteMeasure: PropTypes.func,
    units: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            shortName: PropTypes.string,
            isDecimal: PropTypes.bool,
        })
    ),
    updateMeasure: PropTypes.func,
};

const UnitsTableSection = ({
    translations,
    deleteMeasure,
    units,
    updateMeasure
}) => {
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ unitToEdit, setUnitToEdit ] = useState(null);
    const [ isAlertOpen, setIsAlertOpen ] = useState(false);

    const handleEditClick = unit => {
        setUnitToEdit(unit);
        setIsModalOpen(true);
    };

    const handleUpdateMeasure = form => {
        const objectToSend = {
            ...form,
            measureId: form.id
        };

        if (!objectToSend.name || !objectToSend.shortName) {
            setIsAlertOpen(true);
        }
        else {
            updateMeasure(objectToSend);
            setIsModalOpen(false);
        }
    };

    const addUnitHandler = () => {
        setUnitToEdit(null);
        setIsModalOpen(true);
    };

    return units ? (
        <React.Fragment>
            <UnitsTable
                translations={translations}
                editClickAction={handleEditClick}
                removeAction={deleteMeasure}
                units={units}
            />
            <div className='w-100 d-flex justify-content-end my-2'>
                <Button onClick={addUnitHandler} color="success">
                    <i className={clsx("fa fa-plus", "mr-2")}/>
                    <span>{translations.buttons.add}</span>
                </Button>
            </div>
            <UnitsEditionModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                translations={translations}
                unit={unitToEdit}
                onSubmit={handleUpdateMeasure}
                isAlertOpen={isAlertOpen}
                setIsAlertOpen={setIsAlertOpen}
            />
        </React.Fragment>
    ) : null;
};

UnitsTableSection.propTypes = propTypes;

export default UnitsTableSection;