import React from 'react';
import PropTypes from 'prop-types';

import { FormattedDate, FormattedTime } from 'react-intl';

const propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    className: PropTypes.string,
    onClickAction: PropTypes.func,
};

const DateCell = ({
    value,
    className,
    onClickAction,
    withTime,
}) => {
    return (
        <td className={className} onClick={onClickAction}>
            {
                value
                    ? <span>
                        {
                            withTime
                            && <span className='mr-1'><FormattedTime value={value} /></span>
                        }
                        <FormattedDate
                            value={value}
                            day='2-digit'
                            month='2-digit'
                            year='numeric'
                        />
                    </span>
                    : null
            }
        </td>
    );
};

DateCell.propTypes = propTypes;

export default DateCell;