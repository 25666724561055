const colorEditionModal = {
    header: 'Edycja koloru',
    inputs: {
        name: 'Nazwa koloru'
    },
    controls: {
        save: 'ZAPISZ ZMIANY',
        cancel: 'ANULUJ ZMIANY'
    }
};

export default colorEditionModal;