export const generateTranslatedProductName = (translations, iso) => {
    const translatedName = translations.find(translation => translation.iso.toLowerCase() === iso.toLowerCase());
    return translatedName && translatedName.value;
};

export const translateServiceName = (services, language) => {
    return services.map(product => {
        return ({
            lp: product.lp,
            productId: product.id,
            productTranslatedName: generateTranslatedProductName(product.translations || product.name, language),
            catalogueIndex: product.catalogueIndex,
            tradeIndex: product.tradeIndex,
            status: product.status,
            warning: product.warning
        });
    });
};

export const generateOtherServiceEditLink = id => `/otherServices/list/edit?serviceID=${id}`;