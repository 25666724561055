import React from "react";
import PropTypes from "prop-types";

import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { Form as FinalForm } from 'react-final-form';

import { Container, Form, Row } from 'reactstrap';

import { getProductDescription, updateProductDescription } from '../../../../redux/methods/products';

import FormFieldTranslationsQuill from "../../../../components/formFields/FormFieldTranslationsQuill";
import SectionEditFormButtons from "../../../../components/SectionEditFormButtons";

import { formUtilsHocPropTypes, stringOrNumberPropTypes } from "../../../../propTypes";

import useFetch from "../../../../hooks/useFetch";
import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    productId: stringOrNumberPropTypes.isRequired,
    getProductDescription: PropTypes.func.isRequired,
    updateProductDescription: PropTypes.func.isRequired,
};

const ProductEditDescription = ({ utils, productId, getProductDescription, updateProductDescription }) => {

    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.description;

    const config = { translations: [ 'shortDescription', 'longDescription' ]};

    const [ description ] = useFetch(getProductDescription, productId, [productId]);

    const handleOnSubmit = formData => {
        updateProductDescription(utils.generateFormToSubmitValues(formData, config, utils.allLanguages()));
    };

    return description ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    initialValues={utils.generateFormInitialValues(description, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='pt-3 px-0' fluid>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldTranslationsQuill
                                            form={form}
                                            utils={utils}
                                            propertyName="shortDescription"
                                            header={translations.headers.short}
                                        />
                                    </Row>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldTranslationsQuill
                                            form={form}
                                            utils={utils}
                                            propertyName="longDescription"
                                            header={translations.headers.long}
                                        />
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

ProductEditDescription.propTypes = propTypes;

const mapDispatch = {
    getProductDescription,
    updateProductDescription
};

export default withFormUtils(connect(null, mapDispatch)(ProductEditDescription));