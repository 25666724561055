import { setNewlyAddedAddidionalCategoryId, setSearchConfig, setAssignCategories as setCategories, setIsFillersDefined } from '../../../features/products/slice';
import { setDefaultCurrencyId, setAdminArticleColumns } from '../../../features/appInit/slice';
import { setForceRefetchByTarget } from '../../../features/refetchers/slice';

import { cAPI } from '../../../utils/cAPI';

import { getData, addEditData, removeData } from '../generic';

export const addProduct = id => dispatch => addEditData(dispatch, cAPI.APAddProduct, { categoryId: id });
export const addService = (productTypeId, categoryId) => dispatch => addEditData(dispatch, cAPI.APAddService, { productTypeId, categoryId });
export const getProductBasic = id => async dispatch => await getData(dispatch, cAPI.APGetProductBasic, id, 'products');
export const getPaymentBasic = id => async dispatch => await getData(dispatch, cAPI.APGetProductBasic, id, 'payments');
export const getShipmentBasic = id => async dispatch => await getData(dispatch, cAPI.APGetProductBasic, id, 'shipments');
export const deleteProduct = id => async dispatch => await removeData(dispatch, cAPI.APDeleteProduct, id, 'products');
export const deletePayment = id => async dispatch => await removeData(dispatch, cAPI.APDeleteProduct, id, 'payments');
export const deleteShipment = id => async dispatch => await removeData(dispatch, cAPI.APDeleteProduct, id, 'shipments');
export const deleteProductMeasure = id => async dispatch => await removeData(dispatch, cAPI.APDeleteProductMeasure, id, 'productMeasures');
export const getProductCategories = id => dispatch => getData(dispatch, cAPI.APGetProductCategories, id, 'productsAdditionalCategories');
export const getProductImages = id => dispatch => getData(dispatch, cAPI.APGetProductImages, id, 'productsImages');
export const getProductImage = formData => dispatch => getData(dispatch, cAPI.APGetProductImage, formData, 'productsImages');
export const getProductMeasures = id => dispatch => getData(dispatch, cAPI.APGetProductMeasures, id, 'productMeasures');
export const getProductMeasure = formData => dispatch => getData(dispatch, cAPI.APGetProductMeasure, formData, 'productMeasures');
export const getProducts = searchConfig => dispatch => getData(dispatch, cAPI.APGetProductsSearchResult, searchConfig, ['products', 'productsRelated', 'productsAlternative', 'productsCategoryAssign']);
export const getPayments = searchConfig => dispatch => getData(dispatch, cAPI.APGetProductsSearchResult, searchConfig, ['payments']);
export const getShipments = searchConfig => dispatch => getData(dispatch, cAPI.APGetProductsSearchResult, searchConfig, ['shipments']);
export const getProductDescription = searchConfig => dispatch => getData(dispatch, cAPI.APGetProductDescription, searchConfig);
export const updateProductStatus = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductStatus, formData, 'products');
export const updatePaymentStatus = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductStatus, formData, 'payments');
export const updateShipmentStatus = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductStatus, formData, 'shipments');
export const updateProductMeasure = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductMeasure, formData, 'productMeasures');
export const addEditFiller = formData => dispatch => addEditData(dispatch, cAPI.APAddEditFiller, formData, 'fillers');
export const getFillers = () => dispatch => getData(dispatch, cAPI.APGetFillers, null, 'fillers')
    .then(result => {
        if (result) {
            dispatch(setIsFillersDefined({ isFillersDefined: result.payload.filter(filler => filler.isActive).length > 0 }));
        }

        return result;
    });
export const getFiller = (id) => dispatch => getData(dispatch, cAPI.APGetFiller, id, 'fillerToEdit');
export const deleteFiller = id => dispatch => removeData(dispatch, cAPI.APDeleteFiller, id, 'fillers');
export const deleteFillerImage = id => dispatch => removeData(dispatch, cAPI.APDeleteFillerImage, id, 'fillerToEdit');
export const uploadFillerImage = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUploadFillerImage, { id, formData }, 'fillerToEdit');

export const updateProductBasicData = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductBasicData, formData, 'products');
export const updatePaymentBasicData = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductBasicData, formData, 'payments');
export const updateShipmentBasicData = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductBasicData, formData, 'shipments');

export const uploadImage = formData => dispatch => addEditData(dispatch, cAPI.APUploadProductImage, formData, 'productsImages');
export const changeProductImageData = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductImageData, formData, 'productsImages');

export const getProductPageTabs = () => dispatch => getData(dispatch, cAPI.APGetProductPageTabs, null, 'productPageTabs');
export const updateProductPageTabs = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductPagTabs, formData, 'productPageTabs');
export const moveProductPageTab = (id, delta) => dispatch => addEditData(dispatch, cAPI.APMoveProductPageTab, { id, delta }, 'productPageTabs');

export const deleteProductImages = (productId, imagesIdList) => dispatch =>
    removeData(dispatch, cAPI.APDeleteProductImages, { productId: productId, productImageId: imagesIdList}, 'productsImages');

export const deleteProductAdditionalCategories = (productId, categoriesToDeleteId) => dispatch =>
    removeData(dispatch, cAPI.APDeleteProductAdditionalCategories, { productId: parseInt(productId), categoryId: categoriesToDeleteId }, 'productsAdditionalCategories');

export const handleSetNewlyAddedAdditionalCategoryId = id => dispatch => {
    dispatch(setNewlyAddedAddidionalCategoryId({ newlyAddedAddidionalCategoryId: id }));
};

export const addNewAdditionalCategory = (productId, categoryId) => dispatch => {
    const updateObject = {
        productId: parseInt(productId),
        categoryId: [categoryId]
    };

    addEditData(dispatch, cAPI.APAddProductAdditionalCategory, updateObject, 'productsAdditionalCategories');
};

export const updateProductDescription = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductDescription, formData);

export const addProductAlternativeProducts = (alternativeProductsIdList, productId, isTwoWayOperation) => dispatch => {
    const updateObject = {
        productId: productId,
        alternativeProductId: alternativeProductsIdList,
        twoWay: isTwoWayOperation,
    };

    addEditData(dispatch, cAPI.APAddProductAlternative, updateObject, 'productsAlternative');
};

export const deleteProductAlternativeProducts = (alterntiveProductIdList, productId, isTwoWayOperation) => dispatch => {
    const updateObject = {
        productId: productId,
        alternativeProductId: alterntiveProductIdList,
        twoWay: isTwoWayOperation
    };

    removeData(dispatch, cAPI.APDeleteProductAlternative, updateObject, 'productsAlternative');
};

export const addProductRelatedProducts = (relatedProductsIdList, productId, isTwoWayOperation) => dispatch => {
    const updateObject = {
        productId: productId,
        relatedProductId: relatedProductsIdList,
        twoWay: isTwoWayOperation,
    };

    addEditData(dispatch, cAPI.APAddProductRelated, updateObject, 'productsRelated');
};

export const deleteProductRelatedProducts = (relatedProductIdList, productId, isTwoWayOperation) => dispatch => {
    const updateObject = {
        productId: productId,
        relatedProductId: relatedProductIdList,
        twoWay: isTwoWayOperation,
    };

    removeData(dispatch, cAPI.APDeleteProductRelated, updateObject, 'productsRelated');
};

export const changeProductPhotoOrder = (productId, imageId, orderIndex) => dispatch => {
    const updateObject = {
        productId: productId,
        imageId: imageId,
        orderIndex: orderIndex,
    };

    addEditData(dispatch, cAPI.APUpdateProductPhotoOrder, updateObject, 'productsImages');
};

export const changeProductPhotoFlag = (productId, imageId, target, value) => dispatch => {
    const updateObject = {
        productId: parseInt(productId),
        imageId: parseInt(imageId),
        [target]: value,
    };

    addEditData(dispatch, cAPI.APUpdateProductPhotoFlag, updateObject, 'productsImages');
};

export const getMeasures = searchConfig => dispatch => getData(dispatch, cAPI.APGetMeasuresSearchResult, searchConfig, 'measures');
export const updateMeasure = formData => dispatch => addEditData(dispatch, cAPI.APUpdateMeasure, formData, 'measures');
export const deleteMeasure = id => dispatch => removeData(dispatch, cAPI.APDeleteMeasure, id, 'measures');
export const getProductAttributes = id => dispatch => getData(dispatch, cAPI.APGetProductAttributes, id);
export const updateProductAttributes = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductAttributes, formData);
export const getProductPrices = id => dispatch => getData(dispatch, cAPI.APGetProductPrices, id, 'productPrice');
export const updateProductPrices = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductPrices, formData, 'productPrice');

export const getListPresentation = () => dispatch => getData(dispatch, cAPI.APGetListPresentation);
export const updateListPresentation = formData => dispatch => addEditData(dispatch, cAPI.APUpdateListPresentation, formData);
export const getGeneralSettings = () => dispatch => getData(dispatch, cAPI.APGetProductGeneralSettings);
export const updateGeneralSettings = formData => dispatch => {
    const res = addEditData(dispatch, cAPI.APUpdateProductGeneralSettings, formData);

    dispatch(setDefaultCurrencyId({ defaultCurrencyId: formData.defaultCurrencyId }));
    dispatch(setAdminArticleColumns({ adminArticleColumns: formData.adminArticleColumns }));

    return res;
};
export const getAllTagAttributes = () => dispatch => getData(dispatch, cAPI.APGetAllTagAttributes);
export const getManufacturerFields = () => dispatch => getData(dispatch, cAPI.APGetManufacturerFields);
export const getProductPresentationTile = () => dispatch => getData(dispatch, cAPI.APGetProductPresentationTile);
export const getAdditionalFieldsTile = () => dispatch => getData(dispatch, cAPI.APGetAdditionalFieldsTile);
export const updateProductPresentationTile = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductPresentationTile, formData);

export const getProductPresentationCart = (type) => dispatch => getData(dispatch, cAPI.APGetProductPresentationCart, type);
export const updateProductPresentationCart = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductPresentationCart, formData);

export const getProductReviews = id => dispatch => getData(dispatch, cAPI.APGetProductReviews, id, 'productReviews');
export const updateProductReview = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductReview, formData, 'productReviews');
export const removeProductReview = (prodId, reviewId) => dispatch =>
    removeData(dispatch, cAPI.APRemoveProductReview, { prodId: prodId, reviewId: reviewId}, 'productReviews');

export const getQuantityPresentation = () => dispatch => getData(dispatch, cAPI.APGetQuantityPresentation);
export const updateQuantityPresentation = formData => dispatch => addEditData(dispatch, cAPI.APUpdateQuantityPresentation, formData);

export const setForceRefetch = () => dispatch => {
    dispatch(setForceRefetchByTarget({ target: 'productsCategoryAssign', forceRefetch: true }));
};

export const setAssignerSearchConfig = searchConfig => dispatch => {
    dispatch(setSearchConfig({ searchConfig: searchConfig }));
};

export const setAssignCategories = categories => dispatch => {
    dispatch(setCategories({ assignCategories: categories }));
};

export const addCategoryForProducts = formData => dispatch => addEditData(dispatch, cAPI.APAddCategoryForProducts, formData, 'productsCategoryAssign');
export const deleteCategoryForProducts = formData => async dispatch => await removeData(dispatch, cAPI.APDeleteCategoryForProducts, formData, 'productsCategoryAssign');
export const updateProductCategoryForFeatures = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductCategoryForFeatures, formData, 'productsCategoryAssign');
export const updateProductsCategoryForFeatures = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductsCategoryForFeatures, formData, 'productsCategoryAssign');

export const createSummaryProduct = formData => dispatch => addEditData(dispatch, cAPI.APCreateSummaryProduct, formData);
export const updateProductVariants = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductVariants, formData, 'productVariants');
export const deleteProductVariants = formData => dispatch => removeData(dispatch, cAPI.APDeleteProductVariants, formData, 'productVariants');
export const updateVariantSettings = formData => dispatch => addEditData(dispatch, cAPI.APUpdateVariantSettings, formData);

export const getProductDeliveryGlobalSettings = () => dispatch => getData(dispatch, cAPI.APGetProductDeliveryGlobalSettings, null, 'productsDeliveryGlobal');
export const updateProductDeliveryGlobalSettings = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductDeliveryGlobalSettings, formData, 'productsDeliveryGlobal');
export const getProductDeliveryErpFields = () => dispatch => getData(dispatch, cAPI.APGetProductDeliveryErpFields);
export const getProductDeliveryTemplateFields = () => dispatch => getData(dispatch, cAPI.APGetProductDeliveryTemplateFields);

export const getProductDelivery = id => dispatch => getData(dispatch, cAPI.APGetProductDelivery, id, 'productDelivery');
export const updateProductDelivery = formData => dispatch => addEditData(dispatch, cAPI.APUpdateProductDelivery, formData, 'productDelivery');

export const createProductDuplicate = id => dispatch => getData(dispatch, cAPI.APCreateProductDuplicate, id);

export const getArticleColumns = () => dispatch => getData(dispatch, cAPI.APGetArticleColumns);
