import React from 'react';
import PropTypes from 'prop-types';

import { orderSortableColumnEnum } from '../../../enums/orders';

import { orderPropTypes, statusConfigPropTypes, orderSortableColumnPropTypes } from '../../../propTypes';

import styles from './index.module.scss';
import TableGenerator from '../../generators/TableGenerator';
import OrderDetails from '../../OrderDetails';

const propTypes = {
    orders: PropTypes.arrayOf(orderPropTypes),
    headers: PropTypes.shape({
        number: PropTypes.string,
        contrahentName: PropTypes.string,
        date: PropTypes.string,
        status: PropTypes.string,
        actions: PropTypes.string,
        attributes: PropTypes.string,
        attachment: PropTypes.string,
    }).isRequired,
    translations: PropTypes.shape({
        showDetailsTooltip: PropTypes.string,
        emptyTable: PropTypes.string,
        actionTypes: PropTypes.shape({
            edit: PropTypes.string,
            showDetails: PropTypes.string,
            print: PropTypes.string,
            delete: PropTypes.string,
        }),
        actionsLabel: PropTypes.string,
    }).isRequired,
    statusConfig: statusConfigPropTypes,
    showDetailsAction: PropTypes.func,
    printAction: PropTypes.func,
    columnSortedByDescendingOrder: orderSortableColumnPropTypes,
    setColumnSortedByDescendingOrder: PropTypes.func,
    performSort: PropTypes.func,
    editAction: PropTypes.func,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
};

const OrdersTable = ({
    orders,
    headers,
    translations,
    statusConfig,
    showDetailsAction,
    printAction,
    performSort,
    columnSortedByDescendingOrder,
    setColumnSortedByDescendingOrder,
    editAction,
    checkedOrdersIds,
    setCheckedOrdersIds,
    currentPage,
    pageSize,
    performPageSizeSelect,
}) => {

    const onClickSortHandler = (columnName) => () => {
        setColumnSortedByDescendingOrder(prevState => prevState === columnName ? null : columnName);

        performSort(orderSortableColumnEnum[columnName], columnSortedByDescendingOrder === columnName ? 'Desc' : 'Asc');
    };

    const handleShowContrahentDataAction = id => () => {
        //TODO: add proper show contrahent details action
        console.info('show contrahent details', id);
    };

    const handleDownloadAttachment = (id, href) => () => {
        //TODO: add proper download attachment method
        console.info(`download attachment: ${href} from ${id} file`);
    };

    return (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            pageSize={pageSize}
            currentPage={currentPage}
            items={orders}
            columnSortedByDescendingOrder={columnSortedByDescendingOrder}
            onClickSortHandler={onClickSortHandler}
            translations={translations}
            expectedActions={['edit', 'showDetails']}
            actions={{showDetailsAction, printAction, editAction}}
            checkedItemsIds={checkedOrdersIds}
            setCheckedItemsIds={setCheckedOrdersIds}
            columns={[
                {
                    type: 'checkbox',
                },
                {
                    header: headers.orderIndex,
                    type: 'index'
                },
                {
                    header: headers.date,
                    value: 'date',
                    isSortable: true,
                    type: 'date',
                    sortValue: 'date'
                },
                {
                    header: headers.number,
                    value: 'number',
                    additionalStyle: styles.highlightedCell,
                },
                {
                    header: headers.preferredDocument,
                    value: 'preferredDocument',
                },
                {
                    header: headers.contrahentName,
                    value: 'contrahentName',
                    isSortable: true,
                    sortValue: 'contrahentName',
                    specificOnClickAction: handleShowContrahentDataAction
                },
                {
                    header: headers.email,
                    value: 'email',
                },
                {
                    header: headers.grossValue,
                    value: 'totalGrossPrice',
                    isSortable: true,
                    sortValue: 'totalGrossPrice',
                    additionalValue: 'currency',
                },
                {
                    header: headers.status,
                    isSortable: true,
                    sortValue: 'orderStatus',
                    value: 'orderStatusId',
                    type: 'badge',
                    config: statusConfig,
                    additionalStyle: styles.statusBadge,
                },
                {
                    header: headers.packageNumber,
                    value: 'packageNumber',
                },
                {
                    header: headers.packageService,
                    value: 'packageService',
                },
                {
                    header: headers.packageStatus,
                    value: 'packageStatus',
                },
                {
                    header: headers.attributes,
                    value: 'attributes',
                },
                {
                    header: headers.attachment,
                    value: 'attachement',
                    specificOnClickAction: handleDownloadAttachment
                },
                {
                    header: headers.actions,
                    type: 'actions',
                    actionCell: 'dropdown'
                }
            ]}
            detailsComponent={OrderDetails}
        />
    );
};

OrdersTable.propTypes = propTypes;

export default OrdersTable;