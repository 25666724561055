import { discountStatusEnum } from '../../enums/discounts';

export const generateEditLinkForDiscount = id => `/discounts/edit?discountID=${id}`;

export const getStatusId = status => {
    const foundKey = Object.keys(discountStatusEnum).find(key => discountStatusEnum[key] === status);
    return parseInt(foundKey);
};

export const generateInitialValues = searchConfig => {
    const numberStatusId = Number.isInteger(searchConfig.discountStatusId)
        ? searchConfig.discountStatusId : getStatusId(searchConfig.status)
    ;

    return {
        name: searchConfig.name,
        code: searchConfig.code,
        validFrom: searchConfig.validFrom && new Date(searchConfig.validFrom),
        validTo: searchConfig.validTo && new Date(searchConfig.validTo),
        discountTypeId: searchConfig.discountTypeId,
        discountRangeId: searchConfig.discountRangeId,
        discountStatusId: numberStatusId,
        currencyId: searchConfig.currencyId,
        priority: searchConfig.priority,
    };
};

const generateDropdownOptionsByType = type => (options, anyLabel, translations) => {
    const mapDropdownOptions = (options, translations) =>
        Object.keys(options).map(key => ({
            id: options[key],
            label: translations[key],
        }))
    ;

    const mapCurrenciesOptions = options =>
        options.map(currency => ({
            id: currency.id,
            label: currency.name,
        }))
    ;

    const mapStatusOptions = (options, translations) =>
        Object.keys(options).map(key => ({
            id: options[key],
            label: translations[key],
        }))
    ;

    const baselineOption ={
        id: null,
        label: anyLabel
    };

    switch (type) {
        case 'dropdown': return anyLabel ? [ baselineOption, ...mapDropdownOptions(options, translations) ] : mapDropdownOptions(options, translations);
        case 'currencies': return anyLabel ? [ baselineOption, ...mapCurrenciesOptions(options) ] : mapCurrenciesOptions(options);
        case 'status': return anyLabel ? [ baselineOption, ...mapStatusOptions(options, translations) ] : mapStatusOptions(options, translations);
        default: return [];
    }
};

export const generateDropdownOptions = generateDropdownOptionsByType('dropdown');
export const generateDropdownOptionsForCurrencies = generateDropdownOptionsByType('currencies');
export const generateDropdownOptionsForStatus = generateDropdownOptionsByType('status');

export const pickBagdeColor = status => {
    switch(status){
        case 'visible': return 'success';
        case 'created': return 'danger';
        case 'hidden': return 'primary';
        case 'utilized': return 'warning';
        case 'expired': return 'info';
        default: return null;
    }
};

export const generateStatuses = (statusEnums, translations) => {
    const generatedStatuses = Object.keys(statusEnums).map(key => ({
        id: statusEnums[key],
        label: translations[key],
        color: pickBagdeColor(key),
        labelForBadge: translations[key]
    }));

    return generatedStatuses;
};

export const generateInitialValuesForParameters = parameters =>
    parameters && parameters.reduce((acc, param) => ({
        ...acc,
        [param.paramType]: param.paramType === 'TagEnabled' ? param.value === 1 : param.stringValue ?? param.value
    }), {})
;

export const generateDiscountParamsToSend = parameters =>
    Object.keys(parameters).map(key => ({
        paramType: key,
        value: key === 'TagEnabled' ? (parameters[key] ? 1 : 0) : parseFloat(parameters[key]),
        stringValue: parameters[key]
    }))
;

export const generateProps = (component, translations, isDiscountRangeSpecificProducts) => ({
    name: component.name,
    step: component.step,
    label: translations.label,
    tooltipText: isDiscountRangeSpecificProducts ? translations.tooltipTextSpecificProducts : translations.tooltipText,
    symbol: translations.symbol,
});

export const generateTranslatedProducts = (products, language) =>
    products.map(({name, id, productTypeId, productImage, catalogueIndex, tradeIndex}) => {
        const foundTranslation = name.find(translation =>
            translation.iso.toLowerCase() === language.toLowerCase()
        );

        return {
            productId: id,
            productTypeId: productTypeId,
            productTranslatedName: foundTranslation && foundTranslation.value,
            productImage: productImage,
            catalogueIndex: catalogueIndex,
            tradeIndex: tradeIndex,
        };
    })
;

export const translateStatus = status => typeof status === 'string' ? status.toLowerCase() : status;