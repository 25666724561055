import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { generateAgreementsStatuses, pickIsModifiedColor } from '../../../utils/agreements';

import { agreementIsModifiedEnum } from '../../../enums/agreements';

import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    agreements: PropTypes.arrayOf(PropTypes.shape({
        email: PropTypes.string,
        modifiedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        ip: PropTypes.string,
        isModified: PropTypes.bool,
    })),
    handleShow: PropTypes.func,
};

const AgreementsRegisterTable = ({ agreements, handleShow }) => {
    const intl = useIntl();
    const translations = intl.messages.agreements.register.table;

    const handleDefaultAction = id => () => {
        handleShow(id);
    };

    const showDetailsAction = id => () => {
        handleShow(id);
    };

    const isModifiedStatuses = generateAgreementsStatuses(agreementIsModifiedEnum, translations.statuses, pickIsModifiedColor);

    return (
        <TableGenerator
            items={agreements}
            actions={{showDetailsAction}}
            defaultAction={handleDefaultAction}
            expectedActions={['showDetails']}
            customIdSelector='id'
            translations={{
                tooltips: translations.tooltips,
                emptyTable: translations.emptyTable,
            }}
            columns={[
                {
                    header: translations.headerLabels.email,
                    value: 'email',
                },
                {
                    header: translations.headerLabels.modifiedDate,
                    value: 'modifiedDate',
                    type: 'date'
                },
                {
                    header: translations.headerLabels.ip,
                    value: 'ip',
                    filler: translations.ipFiller
                },
                {
                    header: translations.headerLabels.isModified,
                    value: 'isModified',
                    type: 'badge',
                    config: isModifiedStatuses,
                },
                {
                    header: translations.headerLabels.actions,
                    type: 'actions'
                },
            ]}
        />
    );
};

AgreementsRegisterTable.propTypes = propTypes;

export default AgreementsRegisterTable;