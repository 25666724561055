import { cAPI } from '../../../utils/cAPI';
import { getData, addEditData, removeData } from '../generic';

export const getShippingFormsGeneral = () => async dispatch => await getData(dispatch, cAPI.APGetShippingFormsGeneral, null, 'shippingGeneral');
export const updateShippingFormsGeneral = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdateShippingFormsGeneral, formData, 'shippingGeneral');

export const getShippingFormsVariants = () => async dispatch => await getData(dispatch, cAPI.APGetShippingFormsVariants, null, 'shippingVariants');
export const getShippingFormsVariant = (id) => async dispatch => await getData(dispatch, cAPI.APGetShippingFormsVariant, id, 'shippingVariants');
export const addEditShippingFormsVariant = (formData) => async dispatch => await addEditData(dispatch, cAPI.APAddEditShippingFormsVariant, formData, 'shippingVariants');
export const deleteShippingFormsVariant = (id) => async dispatch => await removeData(dispatch, cAPI.APDeleteShippingFormsVariant, id, 'shippingVariants');
export const setDefaultShippingFormsVariant = (id) => async dispatch => await addEditData(dispatch, cAPI.APSetDefaultShippingFormsVariant, id, 'shippingVariants');

export const getShippingFormsWeights = (id) => async dispatch => await getData(dispatch, cAPI.APGetShippingFormsWeights, id, 'shippingWeights');
export const getShippingFormsWeight = (formData) => async dispatch => await getData(dispatch, cAPI.APGetShippingFormsWeight, formData, 'shippingWeights');
export const addEditShippingFormsWeight = (formData) => async dispatch => await addEditData(dispatch, cAPI.APAddEditShippingFormsWeight, formData, 'shippingWeights');
export const deleteShippingFormsWeight = (formData) => async dispatch => await removeData(dispatch, cAPI.APDeleteShippingFormsWeight, formData, 'shippingWeights');

export const getShippingFormsForms = (formData) => async dispatch => await getData(dispatch, cAPI.APGetShippingFormsForms, formData, 'shippingForms');
export const getShippingFormsForm = (formData) => async dispatch => await getData(dispatch, cAPI.APGetShippingFormsForm, formData, 'shippingForms');
export const addEditShippingFormsForm = (formData) => async dispatch => await addEditData(dispatch, cAPI.APAddEditShippingFormsForm, formData, 'shippingForms');
export const deleteShippingFormsForm = (formData) => async dispatch => await removeData(dispatch, cAPI.APDeleteShippingFormsForm, formData, 'shippingForms');
