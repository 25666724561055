import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Modal as CoreUiModal, ModalHeader, ModalBody, ModalFooter, Form, Alert } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import FormFieldTranslations from '../../formFields/FormFieldTranslations';
import FormFieldWithDropdown from '../../formFields/FormFieldWithDropdown';
import FormFieldColorPicker from '../../formFields/FormFieldColorPicker';

import styles from './index.module.scss';
import clsx from 'clsx';
import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    isConnectedToERP: PropTypes.bool,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    translations: PropTypes.object,
    orderStatus: PropTypes.object
};

const OrderStatusEditionModal = ({ utils, isConnectedToERP, isOpen, setIsOpen, onSubmit, translations, orderStatus }) => {

    const config = { translations: ['name', 'description'] };

    const handleOnCancel = () => {
        setIsOpen(false);
    };

    const handleOnSubmit = (form) => {
        const formToSend = utils.generateFormToSubmitValues(form, config);

        if (formToSend.dictionaryId === 0) {
            formToSend.dictionaryId = null;
        }

        onSubmit(formToSend);
    };

    const orderStatusTag = orderStatus?.tag?.toLowerCase();

    return orderStatus ? (
        <CoreUiModal className="wide" isOpen={isOpen}>
            <FinalForm
                initialValues={utils.generateFormInitialValues(orderStatus, config)}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values}) => {
                    const selectedErpOrderStatus = orderStatus.dictionaries.find(item => item.dictionaryId === values.dictionaryId);

                    return (
                        <div>
                            <ModalHeader>{translations.title}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleSubmit}>
                                    <div>
                                        {orderStatusTag &&
                                            <div className='my-2'>
                                                <Alert>
                                                    {translations.tags[orderStatusTag]}
                                                </Alert>
                                            </div>
                                        }
                                        <div className='my-2'>
                                            <FormFieldTranslations
                                                label={translations.inputs.name}
                                                form={form}
                                                modalHeader={translations.nameModalHeader}
                                                propertyName='name'
                                                currentIso={utils.currentLanguage()}
                                                className='w-100'
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <FormFieldTranslations
                                                label={translations.inputs.description}
                                                form={form}
                                                modalHeader={translations.descriptionModalHeader}
                                                propertyName='description'
                                                currentIso={utils.currentLanguage()}
                                                className='w-100'
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <FormFieldColorPicker
                                                button={translations.inputs.colorButton}
                                                label={translations.inputs.color}
                                                name='color'
                                            />
                                        </div>
                                        {isConnectedToERP && (
                                            <div className='my-2'>
                                                <FormFieldWithDropdown
                                                    name='dictionaryId'
                                                    label={translations.inputs.erpOrderStatusId}
                                                    currentSelectionLabel={selectedErpOrderStatus?.label}
                                                    options={orderStatus.dictionaries}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <div className={clsx("d-flex justify-content-between", styles.modalFooterButtons)}>
                                    <Button onClick={handleOnCancel} color='danger'>
                                        <i className="fa fa-remove mr-2"></i>
                                        <span>{translations.buttons.remove}</span>
                                    </Button>
                                    <Button onClick={handleSubmit} color='success'>
                                        <i className="fa fa-save mr-2"></i>
                                        <span>{translations.buttons.save}</span>
                                    </Button>
                                </div>
                            </ModalFooter>
                        </div>
                    );
                }}
            />
        </CoreUiModal>
    ) : null;
};

OrderStatusEditionModal.propTypes = propTypes;

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP
});

export default connect(mapStateToProps, null)(withFormUtils(OrderStatusEditionModal));