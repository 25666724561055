import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';
import {
    addUpdatePageSection,
    uploadDoubleImageSectionImage,
} from '../../../../redux/methods/homePage';

import { getButtonsList } from '../../../../redux/methods/visualSettings';
import { defaultHeightConfig } from '../../../../config';
import { targetTranslatedContent } from '../../../../utils/components';

import { Form as FinalForm } from 'react-final-form';
import { Form } from 'reactstrap';

import PreviewModalController from '../../../../components/PreviewModalController';
import SectionAsideButtons from '../../../../components/SectionAsideButtons';
import Tabulator from '../../../../components/Tabulator';
import ButtonPreview from '../../../../components/ButtonPreview';

import ImageEditFields from './ImageEditFields';
import HomePageSectionMainData from '../HomePageSectionMainData';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import styles from './index.module.scss';
import getConfig from '../../../../getConfig';

import { getReturnButtonProps, onSubmit } from '..';

const propTypes = {
    currentLanguage: PropTypes.string,
    addUpdatePageSection: PropTypes.func,
    section: PropTypes.object,
};

const DoubleImageSection = ({
    currentLanguage,
    utils,
    section,
    addUpdatePageSection,
    uploadDoubleImageSectionImage,
    getButtonsList,
    buttons,
    sectionLocation
}) => {
    const history = useHistory();
    const intl = useIntl();
    const translations = intl.messages.homePage.promoSections.sections;
    const translationsHomePage = intl.messages.homePage;

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const config = { translations: ['firstImageContent', 'firstImageButton', 'secondImageContent', 'secondImageButton', 'title'], date: ['activeFrom', 'activeTo'] };

    const [isMaxCharLimitExceeded, setIsMaxCharLimitExceeded] = useState(false);
    const [firstImageFile, setFirstImageFile] = useState(null);
    const [secondImageFile, setSecondImageFile] = useState(null);

    useEffect(() => {
        if (!buttons || buttons.length === 0) {
            getButtonsList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSubmit = async formData => {
        const convertedData = utils.generateFormToSubmitValues(formData, config);
        const objectToSend = {
            ...convertedData,
            firstImage: {
                ...formData.firstImage,
                content: convertedData.firstImageContent,
                button: convertedData.firstImageButton,
            },
            secondImage: {
                ...formData.secondImage,
                content: convertedData.secondImageContent,
                button: convertedData.secondImageButton,
            },
            height: convertedData.height ? convertedData.height : defaultHeightConfig,
        };

        const response = await addUpdatePageSection(
            objectToSend,
            /**the second argument conditions the execution of 'forceRefech' to prevent excessive refetch
            * refetch will be called in uploading methods below
            */
            !!(firstImageFile && firstImageFile.generatedFile) || (secondImageFile && secondImageFile.generatedFile)
        );

        if (firstImageFile && firstImageFile.generatedFile) {
            uploadDoubleImageSectionImage(response, firstImageFile.generatedFile);
        }

        if (secondImageFile && secondImageFile.generatedFile) {
            uploadDoubleImageSectionImage(response, secondImageFile.generatedFile);
        }

        return onSubmit(section, sectionLocation, history, response);
    };

    const getInitialStructure = section => {
        return ({
            ...section,
            firstImage: {
                ...section.firstImage,
                backgroundColor: section.firstImage ? section.firstImage.backgroundColor : null
            },
            secondImage: {
                ...section.secondImage,
                backgroundColor: section.secondImage ? section.secondImage.backgroundColor : null
            },
            firstImageContent: section.firstImage ? section.firstImage.content : [],
            firstImageButton: section.firstImage ? section.firstImage.button : [],
            secondImageContent: section.secondImage ? section.secondImage.content : [],
            secondImageButton: section.secondImage ? section.secondImage.button : [],
        });
    };

    const isContent = image => !!(image.name === 'firstImage' ? firstImageFile : secondImageFile) || image.imageSource || image[targetTranslatedContent('button', currentLanguage)] || image[targetTranslatedContent('content', currentLanguage)];
    const getSelectedButtonStyles = (imageName, values) => buttons.find(button => button.id === values[`${imageName}`].buttonId);

    return section ? (
        <div className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={utils.generateFormInitialValues(getInitialStructure(section), config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Tabulator
                                    listOfElements={[
                                        {
                                            label: translationsHomePage.tabs.basicData,
                                            id: 'searchTerms-table',
                                            component: <HomePageSectionMainData
                                                isSectionHeightController={true}
                                                isNumberOfTilesController={false}
                                                form={form} />
                                        },
                                        {
                                            label: translations.firstImageLabel,
                                            id: 'firstImage-DoubleImageSection',
                                            component: (
                                                <ImageEditFields
                                                    translations={translations}
                                                    utils={utils}
                                                    currentLanguage={currentLanguage}
                                                    form={form}
                                                    values={values}
                                                    section={section}
                                                    imageName={'firstImage'}
                                                    isMaxCharLimitExceeded={isMaxCharLimitExceeded}
                                                    setIsMaxCharLimitExceeded={setIsMaxCharLimitExceeded}
                                                    setFile={setFirstImageFile}
                                                />
                                            )
                                        },
                                        {
                                            label: translations.secondImageLabel,
                                            id: 'secondImage-DoubleImageSection',
                                            component: (
                                                <ImageEditFields
                                                    translations={translations}
                                                    utils={utils}
                                                    currentLanguage={currentLanguage}
                                                    form={form}
                                                    values={values}
                                                    section={section}
                                                    imageName={'secondImage'}
                                                    isMaxCharLimitExceeded={isMaxCharLimitExceeded}
                                                    setIsMaxCharLimitExceeded={setIsMaxCharLimitExceeded}
                                                    setFile={setSecondImageFile}
                                                />
                                            )
                                        }
                                    ]}
                                />
                                <PreviewModalController
                                    content={(
                                        <div className='d-flex justify-content-center'>
                                            {
                                                [{ ...values.firstImage, name: 'firstImage' }, { ...values.secondImage, name: 'secondImage' }].map((image, index) => (
                                                    isContent(image) && (
                                                        <div
                                                            className={clsx(styles.wrapper)}
                                                            key={`${index}--imageSectionDoubleImage`}
                                                            style={{
                                                                backgroundImage:
                                                                    image.name === 'firstImage'
                                                                        ? firstImageFile
                                                                            ? `url(${firstImageFile.previewFile})`
                                                                            : `url(${apiUrl}${image.imageSource})`
                                                                        : secondImageFile
                                                                            ? `url(${secondImageFile.previewFile})`
                                                                            : `url(${apiUrl}${image.imageSource})`,
                                                                padding: values.isFullWidth && 0,
                                                                margin: values.isFullWidth && 0
                                                            }}
                                                        >
                                                            <div className={styles.shade} style={{ backgroundColor: image.backgroundColor, opacity: parseFloat(image.opacity / 100) }} />
                                                            <div className={styles.label}>
                                                                {values[targetTranslatedContent(`${image.name}Content`, currentLanguage)] && <div dangerouslySetInnerHTML={{ __html: values[targetTranslatedContent(`${image.name}Content`, currentLanguage)] }} />}
                                                                {
                                                                    values[targetTranslatedContent(`${image.name}Button`, currentLanguage)] && getSelectedButtonStyles(image.name, values) &&
                                                                    <ButtonPreview
                                                                        previewTranslation={values[targetTranslatedContent(`${image.name}Button`, currentLanguage)]}
                                                                        button={getSelectedButtonStyles(image.name, values).style}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                ))
                                            }
                                        </div>
                                    )}
                                />
                                <SectionAsideButtons
                                    buttons={{
                                        add: {
                                            color: 'success',
                                            label: translations.button,
                                            action: handleSubmit,
                                            iconClass: 'fa fa-save mr-2',
                                            isDisabled: isMaxCharLimitExceeded
                                        },
                                        remove: getReturnButtonProps(sectionLocation, section, history, translations)
                                    }}
                                />
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    ) : null;
};

DoubleImageSection.propTypes = propTypes;

const mapStateToProps = state => ({
    currentLanguage: selectStoreCurrentLanguage(state),
    buttons: state.visualSettings.buttons,
});

const mapDispatch = {
    addUpdatePageSection,
    uploadDoubleImageSectionImage,
    getButtonsList
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(DoubleImageSection));