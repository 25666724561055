import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import SwitchWithTwoLabels from '../../SwitchWithTwoLabels';

const propTypes = {
    isDisabled: PropTypes.bool,
    tooltip: PropTypes.string,
    name: PropTypes.string,
    labels: PropTypes.shape({
        main: PropTypes.string,
        false: PropTypes.string,
        true: PropTypes.string,
    }).isRequired,
};

const FormFieldSwitchWithTwoLabels = ({ isDisabled, tooltip, name, labels }) => {
    return (
        <Field name={name} type='checkbox'>
            {
                props => (
                    <SwitchWithTwoLabels
                        isChecked={props.input.checked}
                        changeActiveLabel={props.input.onChange}
                        isDisabled={isDisabled}
                        tooltip={tooltip}
                        labels={labels}
                    />
                )
            }
        </Field>
    );
};

FormFieldSwitchWithTwoLabels.propTypes = propTypes;

export default FormFieldSwitchWithTwoLabels;