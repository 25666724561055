import React from 'react';
import PropTypes from 'prop-types';

import FormFieldTranslations from '../../formFields/FormFieldTranslations';
import FormFieldWithCheckbox from '../../formFields/FormFieldWithCheckbox';

import styles from './index.module.scss';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    form: PropTypes.object,
    customFieldName: PropTypes.string,
    currentIso: PropTypes.string,
    translations: PropTypes.object,
    isRequiredName: PropTypes.string,
    values: PropTypes.object
};

const CustomField = ({
    name, label, form, customFieldName, currentIso,
    translations, isRequiredName, values
}) => {
    return (
        <React.Fragment>
            <tr>
                <td>
                    <FormFieldWithCheckbox
                        name={name}
                        label={label}
                    />
                </td>
                <td>
                    <FormFieldWithCheckbox
                        isDisabled={!values[name]}
                        name={isRequiredName}
                        label={translations.isRequiredLabel}
                    />
                </td>
            </tr>
            <tr>
                <td colSpan="2" className={styles.textFieldContainer}>
                    <FormFieldTranslations
                        disabled={!values[name]}
                        form={form}
                        propertyName={customFieldName}
                        currentIso={currentIso}
                        label={translations.customFieldNameLabel}
                        modalHeader={translations.customFieldNameLabel}
                    />
                </td>
            </tr>
        </React.Fragment>
    );
};

CustomField.propTypes = propTypes;

export default CustomField;