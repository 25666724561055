import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getTranslations, updateTranslations } from '../../redux/methods/translations';

import { Card, CardBody, CardHeader, Form, Button, Collapse, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import FormFieldTranslationsNew from '../../components/formFields/FormFieldTranslationsNew';
import ContainerWithCard from '../../components/ContainerWithCard';

import { getTranslationsFromArray } from '../../utils/getTranslationsFromArray';
import useFetch from '../../hooks/useFetch';
import styles from './index.module.scss';

import clsx from 'clsx';

const propTypes = {
    getTranslations: PropTypes.func,
    updateTranslations: PropTypes.func,
    panelLang: PropTypes.string,
    forceRefetch: PropTypes.bool,
};

const Translations = ({ panelLang, getTranslations, updateTranslations, forceRefetch }) => {
    const intl = useIntl();
    const { translations } = intl.messages;

    const [ translationsData ] = useFetch(getTranslations, null, [], forceRefetch);

    const [ dataToShow, setDataToShow ] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState('');

    const toggle = (index) => () => {
        setIsOpen(prev => prev === index ? null : index);
    };

    const handleOnSubmit = formData => {
        updateTranslations(formData.initData);

    };

    const clear = () => {
        setValue('');
        setDataToShow(translationsData);
        setIsOpen(false);
    };

    const search = () => {
        if(value === '') {
            setDataToShow(translationsData);
            return;
        }

        const filteredCategories = translationsData
            .map(({ category, translations }) => {
                const values = category.map(({ value }) => value);

                const foundFields = Object
                    .keys(translations)
                    .reduce((prev, key) => {
                        const translationsIncludesValue = translations[key].some(item => item.value.toLowerCase().includes(value.toLowerCase()));

                        return translationsIncludesValue ? { ...prev, [key]: translations[key] } : prev;
                    }, {});

                const foundFieldsPresent = Object.keys(foundFields).length > 0;

                const categoryPassToValue = values.findIndex(item => item.toLowerCase().includes(value.toLowerCase())) > -1;

                return categoryPassToValue ? { category, translations } : { category, translations: foundFieldsPresent ? foundFields : {} };
            });

        setDataToShow(filteredCategories || {});
        setIsOpen(false);
    };

    useEffect(() => {
        if(translationsData) {
            setDataToShow(translationsData);
        }
    }, [translationsData]);

    return dataToShow && translationsData ? (
        <ContainerWithCard title={translations.header} iconClass='fa fa-language'>
            <InputGroup className='my-3'>
                <Input onKeyPress={e => e.key === 'Enter' ? search() : null} value={value} onChange={e => setValue(e.target.value)} placeholder={translations.buttons.search}/>
                <InputGroupAddon addonType='append' >
                    <Button color='success' onClick={search}>
                        <span>{translations.buttons.search}</span>
                    </Button>
                    <Button color='danger' onClick={clear}>
                        <span><i className='fa fa-trash'/></span>
                    </Button>
                </InputGroupAddon>
            </InputGroup>
            <FinalForm
                keepDirtyOnReinitialize
                onSubmit={handleOnSubmit}
                initialValues={{ initData: translationsData }}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            {
                                dataToShow.map((category, index) =>
                                Object.keys(category.translations).length > 0 ? <Card key={index}>
                                        <CardHeader className={styles.header} onClick={toggle(index)}>
                                            <i className={clsx('fas', isOpen === index ? 'fa-chevron-up' : 'fa-chevron-down')}></i>
                                            {getTranslationsFromArray(category.category, panelLang)}
                                        </CardHeader>
                                        <Collapse isOpen={isOpen === index}>
                                                <CardBody>
                                                    {
                                                        Object.keys(category.translations)
                                                            .map(key =>
                                                                <FormFieldTranslationsNew
                                                                    key={key}
                                                                    label={getTranslationsFromArray(category.translations[key], panelLang, 'defaultValue')}
                                                                    modalHeader={getTranslationsFromArray(category.translations[key], panelLang, 'defaultValue')}
                                                                    name={`initData.[${index}].translations.${key}`}
                                                                    translationObject={translationsData[index].translations[key]}
                                                                />
                                                            )
                                                    }
                                                </CardBody>
                                        </Collapse>
                                    </Card> : null
                                )
                            }
                            <aside className={styles.footer}>
                                <Button color='success' onClick={form.submit}>
                                    <i className="fa fa-save mr-2"/>
                                    <span>{translations.buttons.save}</span>
                                </Button>
                            </aside>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

Translations.propTypes = propTypes;

const mapStateToProps = state => ({
    panelLang: state.persisted.persistedLocale.persistedChosenLanguage,
    forceRefetch: state.refetchers.translations,
});

const mapDispatch = {
    getTranslations,
    updateTranslations,
};

export default connect(mapStateToProps, mapDispatch)(Translations);