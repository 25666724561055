import React, { useRef } from "react";

const AnimatedBannerSettingsModalPreview = ({item}) => {

    const ref = useRef();

    const createDivStyle = () => {
        const { width, height } = ref.current?.getBoundingClientRect() ?? {
            width: item.position.currentWidth,
            height: item.position.currentHeight };

        const commonStyle = {
            position: 'relative',
            width: (width + 2 * item.properties.borderWidth + 2 * item.properties.paddingHorizontal) + 'px',
            height: (height + 2 * item.properties.borderWidth + 2 * item.properties.paddingVertical) + 'px',
            margin: '50px 50px',
            opacity: item.properties.opacity,
            paddingLeft: item.properties.paddingHorizontal + 'px',
            paddingRight: item.properties.paddingHorizontal + 'px',
            paddingTop: item.properties.paddingVertical + 'px',
            paddingBottom: item.properties.paddingVertical + 'px',
            borderWidth: item.properties.borderWidth + 'px',
            borderStyle: item.properties.borderStyle,
            borderColor: item.properties.borderColor,
            borderRadius: item.properties.borderRadius + 'px',
            boxShadow: `${item.properties.boxShadowColor} ${item.properties.boxShadowOffsetX}px ${item.properties.boxShadowOffsetY}px ${item.properties.boxShadowBlurRadius}px ${item.properties.boxShadowSpreadRadius}px`
        };

        switch(item.type){
            case 'image':
                return {
                    ...commonStyle,
                    background: `url(${item.properties.content}) center center / cover no-repeat`
                };
            default:
                return {
                    ...commonStyle,
                    backgroundColor: item.properties.backgroundColor,
                };
        }
    };

    const createSpanStyle = () => {
        return {
            position: 'absolute',
            display: 'inline-block',
            color: item.properties.color,
            opacity: item.properties.opacity,
            textAlign: item.properties.textAlign,
            fontFamily: item.properties.fontFamily,
            fontSize: item.properties.fontSize + 'px',
            fontWeight: item.properties.fontWeight,
            fontStyle: item.properties.fontStyle,
            whiteSpace: item.properties.whiteSpace,
            textShadow: `${item.properties.textShadowOffsetX}px ${item.properties.textShadowOffsetY}px ${item.properties.textShadowBlurRadius}px ${item.properties.textShadowColor}`
        };
    };

    return (
        <div style={{overflow: 'hidden', margin: '10px auto'}}>
            <div style={createDivStyle()}>
                {item?.type === 'text' &&
                    <span ref={ref} style={createSpanStyle()}>
                        {item?.properties?.content}
                    </span>
                }
            </div>
        </div>
    );
};

export default AnimatedBannerSettingsModalPreview;