import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'reactstrap';

const propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    tooltipKey: PropTypes.string,
    columnName: PropTypes.string,
    type: PropTypes.oneOf(['color','date','datetime-local','email','month','number','password','range','tel','text','time','url','week']),
    textAlignment: PropTypes.oneOf(['text-left', 'text-center', 'text-right'])
};

const InputCell = ({ value, className, onChange, id, item, disabled, tooltip, tooltipKey, columnName, type = 'text', textAlignment = 'text-left' }) => {
    const [ isTooltipOpen, setIsTooltipOpen ] = useState(false);

    const handleChange = e => {
        onChange(id, e.target.value, item, columnName);
    };

    const style = type === 'color' ? {width: '43px'} : {};

    return (
        <td className={className}>
        {
            tooltip &&
            <Tooltip placement="top" isOpen={isTooltipOpen} target={`inputTooltip-${tooltipKey}`} toggle={() => setIsTooltipOpen(!isTooltipOpen)}>
                {tooltip}
            </Tooltip>
        }
            <label id={`inputTooltip-${tooltipKey}`}>
                <input
                    disabled={disabled}
                    type={type}
                    defaultValue={value}
                    onBlur={handleChange}
                    style={style}
                    className={`form-control ${textAlignment}`}
                />
            </label>
        </td>
    );
};

InputCell.propTypes = propTypes;

export default InputCell;