import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getRegions, addRegion, removeRegion } from '../../redux/methods/regions';

import { generateEditLinkForRegion } from '../../utils/regions';

import useFetch from '../../hooks/useFetch';
import useAddFetch from '../../hooks/useAddFetch';

import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';

import RegionsTable from '../../components/tables/RegionsTable';
import Modal from '../../components/modals/Modal';

import { regionPropTypes } from '../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object.isRequired,
    getRegions: PropTypes.func,
    regions: PropTypes.arrayOf(regionPropTypes),
    addRegion: PropTypes.func,
    removeRegion: PropTypes.func,
    isRefetchRequired: PropTypes.bool,
};

const Regions = ({
    getRegions,
    history,
    addRegion,
    removeRegion,
    isRefetchRequired,
}) => {
    const intl = useIntl();
    const translations = intl.messages.regions;

    const [isAdditionModalOpen, setIsAdditionModalOpen] = useState(false);

    const [ regions ] = useFetch(getRegions, null, [], isRefetchRequired);
    const [ id, add ] = useAddFetch(addRegion);

    useEffect(() => {
        if (id) {
            history.push(generateEditLinkForRegion(id));
        }
    }, [ history, id ]);

    const toggleAdditionModal = () => {
        setIsAdditionModalOpen(!isAdditionModalOpen);
    };

    const handleAddRegion = () => {
        add();
    };

    const handleRemoveRegion = id => {
        removeRegion(id);
    };

    return regions ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.view.title}</span>
                            </CardHeader>
                            <CardBody>
                                <main>
                                    <RegionsTable
                                        regions={regions}
                                        headers={translations.table.headers}
                                        nameFiller={translations.table.nameFiller}
                                        removeAction={handleRemoveRegion}
                                        translations={translations.table}
                                        history={history}
                                    />
                                </main>
                                <aside className={styles.footer}>
                                    <Button color='primary' onClick={toggleAdditionModal}>
                                        <i className='fa fa-plus mr-2'/>
                                        <span>{translations.view.actions.addRegion}</span>
                                    </Button>
                                </aside>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal
                isOpen={isAdditionModalOpen}
                setIsOpen={setIsAdditionModalOpen}
                header={translations.additionModal.header}
                body={translations.additionModal.body}
                buttons={{
                    confirm: {
                        label: translations.additionModal.confirm,
                        color: 'primary'
                    },
                    cancel: {
                        label: translations.additionModal.cancel,
                        color: 'secondary'
                    }
                }}
                onConfirm={handleAddRegion}
            />
        </Container>
    ) : null;
};

Regions.propTypes = propTypes;

const mapStateToProps = state => ({
    isRefetchRequired: state.refetchers.regions,
});

const mapDispatch = {
    getRegions,
    removeRegion,
    addRegion,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Regions));