export const onSubmit = (section, sectionLocation, history, response) => {
    if(typeof response === 'number') {
        let url = `edit?sectionID=${response}&sectionType=${section.type}&location=${sectionLocation}`;

        if(section.affiliationObjectId) {
            url += `&affiliationObjectId=${section.affiliationObjectId}`;
        }

        history.push(url);

        return;
    }

    return response;
};

export const getGoBack = (sectionLocation, section, history) => {
    const isCustomPageLocation = sectionLocation === 'customPage';

    const affiliationObjectId = isCustomPageLocation ? section.affiliationObjectId : null;

    const goBack = !affiliationObjectId ? null : () => history.push(`/customPages/edit?customPageID=${affiliationObjectId}`);

    return goBack;
};

export const getReturnButtonProps = (sectionLocation, section, history, translations) => {
    const isCustomPageLocation = sectionLocation === 'customPage';

    const affiliationObjectId = isCustomPageLocation ? section.affiliationObjectId : null;

    const returnButtonProps = !affiliationObjectId ? null : {
        action: () => history.push(`/customPages/edit?customPageID=${affiliationObjectId}`),
        label: translations.returnButton || translations.button.return
    };

    return returnButtonProps;
};

export const getHideCancelButton = (sectionLocation, section) => {
    const isCustomPageLocation = sectionLocation === 'customPage';

    const affiliationObjectId = isCustomPageLocation ? section.affiliationObjectId : null;

    return !affiliationObjectId;
};