const delivery = {
    title: '[EN] Dostawa',
    fields: {
        deliveryTabTemplate: '[EN] Tekst w zakładce Dostawa na karcie produktu',
        showDeliveryCostOnCard: '[EN] Pokazuj informację o kosztach dostawy artykułu',
        defaultDeliveryCost: '[EN] Domyślny koszt dostawy',
        deliveryCostFieldFromErp: '[EN] Pobieraj informacje o koszcie dostawy z Erp',

        showDeliveryTime: '[EN] Pokazuj informację o czasie dostawy',
        defaultDeliveryTime: '[EN] Domyślna wartość czasu dostawy w dniach (0-365)',
        deliveryTimeFiledFromErp: '[EN] Pobieraj informacje o czasie dostawy z Erp',

        showDeliveryTimeInSummary: '[EN] Pokazuj w podsumowaniu koszyka informacje o czasie dostawy.',
    },
    info: {
        defaultDeliveryCost: '[EN] Uwaga! Dla artykułów bez zdefiniowanego kosztu dostawy zostanie przyjęta wartość domyślna.',
        defaultDeliveryTime: '[EN] Uwaga! Dla artykułu bez zdefiniowanego czasu dostawy zostanie przyjęta wartość domyślna.',
        deliveryTabTemplate: '[EN] Aby było widoczne włącz zakładkę',
        deliveryTabTemplateLink: '[EN] w tym miejscu',
    },
    insertValueButton: '[EN] Wstaw wartość',
    nullOpt: '[EN] Brak',
};

export default delivery;