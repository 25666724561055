import { getData, addEditData, removeData } from '../generic';
import { cAPI, handleCAPIErrorsSubtle } from '../../../utils/cAPI';
import { setError } from '../../../features/errors/slice';
import { setIsNotificationDropdownOpen } from '../../../features/notifications/slice';

export const getNotificationsList = (searchConfig) => dispatch => getData(dispatch, cAPI.APGetNotificationsList, searchConfig, 'notificationsList');
export const getNotificationDetails = id =>  dispatch => getData(dispatch, cAPI.APGetNotificationDetails, id);
export const getLatestNotifications = (token) =>  dispatch => getData(dispatch, cAPI.APGetLatestNotifications, token, 'notifications');

export const greyOutNotification = id =>  dispatch => addEditData(dispatch, cAPI.APGreyOutNotification, id, 'notifications', false, true);
export const ignoreNotifications = formData =>  dispatch => addEditData(dispatch, cAPI.APIgnoreNotifications, formData, 'notificationsList');

export const createNotificationToken = () => async dispatch => {
    const { payLoad, error, httpError, unhandledError, statusCode } = await cAPI.APCreateNotificationToken();
    const errorToHandle = error || httpError || unhandledError || statusCode !== 200;

    if(payLoad) {
        const { errors } = payLoad;

        if (errors && errors.form) {
            const { message } = errors.form;
            dispatch(setError({ error: message }));
        }

        return payLoad;
    }
    else if (errorToHandle) {
        handleCAPIErrorsSubtle({ error, httpError, unhandledError, statusCode }, dispatch);
    }
};

export const setIsDropdownOpen = (isOpen) => dispatch => {
    dispatch(setIsNotificationDropdownOpen({ isOpen: isOpen }));
};

export const deleteNotification = id => dispatch => removeData(dispatch, cAPI.APDeleteNotification, id, 'notifications', false, true);