import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { editManufacturer, getManufacturer } from '../../../../redux/methods/manufacturers';
import useFetch from '../../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row, Button } from 'reactstrap';

import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldIsActiveDropdown from '../../../../components/formFields/FormFieldIsActiveDropdown';
import FormFieldTranslations from '../../../../components/formFields/FormFieldTranslations';

import LogoPlaceholder from '../../../../assets/logo_placeholder.svg';

import { stringOrNumberPropTypes, formUtilsHocPropTypes } from '../../../../propTypes';

import styles from './index.module.scss';

import { withFormUtils } from '../../../../hoc/withFormUtils';
import getConfig from '../../../../getConfig';

import { translateManufacturer } from '../../../../utils/manufacturers';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    editManufacturer: PropTypes.func,
    manufacturerToEditId: stringOrNumberPropTypes,
    forceRefetch: PropTypes.bool,
    getManufacturer: PropTypes.func,
    isConnectedToERP: PropTypes.bool,
};

const ManufacturerEditBasic = ({
    utils,
    manufacturerToEditId,
    editManufacturer,
    forceRefetch,
    getManufacturer,
    isConnectedToERP
}) => {
    const intl = useIntl();
    const translations = intl.messages.manufacturers.edit.tabs.basicData;
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;
    const fileInputRef = React.createRef(null);

    const [ manufacturer ] = useFetch(getManufacturer, manufacturerToEditId, [], forceRefetch);
    const [ imageFile, setImageFile ] = useState(null);

    useEffect(() => {
        if(fileInputRef && fileInputRef.current && fileInputRef.current.value){
            fileInputRef.current.value = null;
        }
    }, [fileInputRef]);

    const config = {
        translations: ['name']
    };

    const handleOnSubmit = formData => {
        let hasName = false;

        for (const key in formData) {
          if (formData[key] !== '' && key.startsWith('name')) {
            hasName = true;
            break;
          }
        }

        if (!hasName) {
            utils.error(translations.nameRequired);

            return;
        }

        const updatedDataObject = utils.generateFormToSubmitValues(formData, config);

        const updatedData = new FormData();

        updatedData.append("manufacturerid", manufacturer.id);
        updatedData.append("showinfilters", formData.showInFilters);
        updatedData.append("logo", imageFile);
        updatedData.append("name", JSON.stringify(updatedDataObject.name));

        console.log(updatedData);

        editManufacturer(updatedData);
    };

    const handleFileUpload = e => {
        setImageFile(e.target.files[0]);
    };

    const handleOnFileSendClick = () => {
        fileInputRef.current.click();
    };

    return manufacturer ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={utils.generateFormInitialValues(manufacturer, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        const { logo } = manufacturer;

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='px-0' fluid>
                                    {isConnectedToERP &&
                                        <Row className='flex-md-nowrap mt-3'>
                                            <FormFieldIsActiveDropdown
                                                name='showInFilters'
                                                translations={translations.labels.showInFilters}
                                            />
                                        </Row>
                                    }
                                    <Row className='flex-md-nowrap mt-3'>
                                        <FormFieldTranslations
                                            form={form}
                                            modalHeader={translations.labels.name}
                                            propertyName='name'
                                            currentIso={utils.currentLanguage()}
                                            label={translations.labels.name}
                                            className='w-md-75 mt-3 mt-md-0 ml-2'
                                        />
                                    </Row>
                                    <Row className={clsx('flex-md-nowrap mt-3', styles.imageRow)}>
                                        <img
                                            className={clsx('ml-2', logo || imageFile ? 'w-50' : 'w-md-50')}
                                            src={imageFile ? URL.createObjectURL(imageFile) : logo ? `${apiUrl}${logo}` : LogoPlaceholder}
                                            alt={logo ? translateManufacturer(manufacturer, utils).name : translations.emptyLogoAlt}
                                        />
                                    </Row>
                                    <Row>
                                        <div className='w-100 d-flex ml-2 mt-5'>
                                            <input type="file" id="button-upload" className="d-none" ref={fileInputRef} onInput={handleFileUpload} />
                                            <label htmlFor="button-upload" className={"d-flex justify-content-center"}>
                                                <Button color="primary" onClick={handleOnFileSendClick}>
                                                    <i className="fa fa-plus mr-2"/>
                                                    <span>{translations.addLogoButton}</span>
                                                </Button>
                                            </label>
                                        </div>
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

ManufacturerEditBasic.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.manufacturers,
    isConnectedToERP: state.appInit.isConnectedToERP
});

const mapDispatch = {
    editManufacturer,
    getManufacturer
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ManufacturerEditBasic));