import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import useFetch from '../../../hooks/useFetch';

import { getEmailSetting, updateEmailSetting, sendTestEmail } from '../../../redux/methods/emails';

import ContainerWithCard from '../../../components/ContainerWithCard';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';
import FormFieldWithCheckbox from '../../../components/formFields/FormFieldWithCheckbox';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';

import { Container, Form, Row, Alert, Button } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import { withFormUtils } from '../../../hoc/withFormUtils';

import styles from './index.module.scss';
import { getTranslationForIso } from '../../../utils/shared';
import { isUndefined } from 'lodash';

const propTypes = {
    location: PropTypes.object,
    getEmailSetting: PropTypes.func,
    updateEmailSetting: PropTypes.func,
};

const ServerSettings = ({
    location,
    getEmailSetting,
    updateEmailSetting,
    sendTestEmail,
    utils,
}) => {
    const intl = useIntl();
    const translations = intl.messages.emails.server;

    const query = new URLSearchParams(location.search);

    const [ isAlertOpen, setIsAlertOpen ] = useState(false);
    const chosenAccountId = parseInt(query.get('accountId'));

    const [ settings ] = useFetch(getEmailSetting, chosenAccountId);

    const handleOnSubmit = async (formData) => {

        if((formData.authenticationPassword || formData.confirmAuthenticationPassword) &&
           (formData.authenticationPassword !== formData.confirmAuthenticationPassword)) {
            setIsAlertOpen(true);
            window.scrollTo(0, 0);
        }
        else {
            await updateEmailSetting(formData);
            setIsAlertOpen(false);
        }
    };

    const secureSocketOptions = settings?.secureSocketOptionValues.map(option => ({
        id: option.id,
        label: getTranslationForIso(option.translations, utils.currentLanguage())
    }));

    const handleOnSubmitTestEmail = async (formData) => {
        if (!isUndefined(formData.testEmail) && formData.testEmail !== null && formData.testEmail.trim() !== '') {
            await sendTestEmail(formData);
            utils.toast(translations.testEmailSended);
        } else {
            utils.toast(translations.testEmailNotDefined,'error');
        }
    };

    return settings ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-cookies'>
            <Alert isOpen={isAlertOpen} color='danger'>
                {translations.wrongPasswordAlert}
            </Alert>

            <div className="ml-5 mr-5">
                <FinalForm
                    keepDirtyOnReinitialize
                    onSubmit={ handleOnSubmitTestEmail }
                    render={({ handleSubmit }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container fluid className='p-0 m-0'>
                                    <div className="card m-3">
                                        <div className="card-header">{translations.testMessageSectionLabel}</div>
                                        <div className="card-body" style={{backgroundColor: '#FFFFD0'}}>
                                            <Row className='p-0 m-0 my-2 d-flex align-items-end flex-wrap'>
                                                <FormFieldTextOrNumber
                                                    className={styles.input}
                                                    name='testEmail'
                                                    label={translations.labels.emailAddress}
                                                    type='email'
                                                />
                                                <Button
                                                    color='warning'
                                                    className='ml-sm-3'
                                                    type="submit">
                                                    {translations.actions.sendTestEmail}
                                                </Button>
                                            </Row>
                                        </div>
                                    </div>
                                </Container>
                            </Form>
                        );
                    }}
                />
            </div>

            <FinalForm
                keepDirtyOnReinitialize
                initialValues={settings}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values }) => {
                    const currentSecureSocketSelectionLabel = secureSocketOptions.find(option => option.id === values.secureSocketOption).label;
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid className='p-0 m-0'>
                                <Row className='p-0 m-0 my-2'>
                                    <div className='col-6'>
                                        <FormFieldTextOrNumber
                                            className={styles.input}
                                            name='senderDisplayName'
                                            label={translations.labels.sendersNameToDisplay}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <FormFieldTextOrNumber
                                            className={styles.input}
                                            name='senderEmail'
                                            label={translations.labels.sendersEmail}
                                            type='email'
                                        />
                                    </div>
                                </Row>

                                <div className="card m-3">
                                    <div className="card-header">{translations.smtpHeader}</div>
                                    <div className="card-body">
                                        <Row className='p-0 m-0 my-2'>
                                            <div className='col-6'>
                                                <FormFieldTextOrNumber
                                                    className={styles.input}
                                                    name='host'
                                                    label={translations.labels.SMTPServerAddress}
                                                />
                                            </div>
                                            <div className='col-6'>
                                                <FormFieldTextOrNumber
                                                    className={styles.input}
                                                    name='port'
                                                    label={translations.labels.SMTPPortNumber}
                                                    type='number'
                                                />
                                            </div>
                                        </Row>
                                    </div>
                                </div>

                                <div className="card m-3">
                                    <div className="card-header">{translations.imapHeader}</div>
                                    <div className="card-body">
                                        <Row className='p-0 m-0'>
                                            <FormFieldWithCheckbox
                                                name='useImap'
                                                label={translations.labels.IMAPServer}
                                            />
                                        </Row>
                                        <Row className='p-0 m-0 my-2'>
                                            <div className='col-6'>
                                                <FormFieldTextOrNumber
                                                    name='imapPort'
                                                    label={translations.labels.IMAPPort}
                                                    type={'number'}
                                                />
                                            </div>
                                            <div className='col-6'>
                                                <FormFieldTextOrNumber
                                                    className={styles.input}
                                                    name='imapSentFolderName'
                                                    label={translations.labels.imapSentFolderName}
                                                />
                                            </div>
                                        </Row>
                                    </div>
                                </div>

                                <div className="card m-3">
                                    <div className="card-header">{translations.loginHeader}</div>
                                    <div className="card-body">
                                        <Row className='p-0 m-0'>
                                            <FormFieldWithDropdown
                                                options={secureSocketOptions}
                                                currentSelectionLabel={currentSecureSocketSelectionLabel}
                                                name={'secureSocketOption'}
                                                label={translations.labels.secureSocketOptionLabel}
                                            />
                                        </Row>
                                        <Row className='p-0 m-0 mt-2'>
                                            <FormFieldWithCheckbox
                                                name='useAuthentication'
                                                label={translations.labels.useAuthentication}
                                            />
                                        </Row>
                                        <Row className='p-0 m-0 my-2'>
                                            <div className='col-4'>
                                                <FormFieldTextOrNumber
                                                    className={styles.input}
                                                    name='authenticationUser'
                                                    label={translations.labels.userName}
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <FormFieldTextOrNumber
                                                    className={styles.input}
                                                    name='authenticationPassword'
                                                    label={translations.labels.password}
                                                    type='password'
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <FormFieldTextOrNumber
                                                    className={styles.input}
                                                    name='confirmAuthenticationPassword'
                                                    label={translations.labels.confirmPassword}
                                                    type='password'
                                                />
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                                <div className="m-3">
                                    <div className="m-3">
                                        <SectionEditFormButtons formReset={form.reset} isSaveButtonDisabled={
                                            (values.id === 0 && (!values.authenticationPassword || !values.confirmAuthenticationPassword)) ||
                                            (values.id > 0 && ((values.authenticationPassword && !values.confirmAuthenticationPassword) || (!values.authenticationPassword && values.confirmAuthenticationPassword)))} />
                                    </div>
                                </div>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : null;
};

ServerSettings.propTypes = propTypes;

const mapDispatch = {
    getEmailSetting,
    updateEmailSetting,
    sendTestEmail,
};

export default withFormUtils(connect(null, mapDispatch)(ServerSettings));