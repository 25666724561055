import EmailTemplates from '../../views/emailSettings/EmailTemplates';
import EmailTableDefinition from '../../views/emailSettings/EmailTableDefinition';
import EmailTableDefinitions from '../../views/emailSettings/EmailTableDefinitions';
import ServerSettings from '../../views/emailSettings/ServerSettings';
import Servers from '../../views/emailSettings/Servers';

export const communicationRoutes = {
    ROUTE_COMMUNICATION_EMAIL: {
        id: 'ROUTE_COMMUNICATION_EMAIL',
        path: '#',
        type: 'communication',
        exact: true,
        icon: 'cil-envelope-closed',
        children: [
            {
                id: 'ROUTE_COMMUNICATION_EMAIL_SERVER',
                path: '/emailSettings/servers',
                exact: true,
                component: Servers,
                icon: 'fal fa-server'
            },
            {
                id: 'ROUTE_COMMUNICATION_EMAIL_TEMPLATES',
                path: '/emailSettings/templates',
                exact: true,
                component: EmailTemplates,
                icon: 'cil-description'
            },
        ]
    },
    ROUTE_COMMUNICATION_EMAIL_TABLE_DEFINITIONS: {
        id: 'ROUTE_COMMUNICATION_EMAIL_TABLE_DEFINITIONS',
        path: '/emailSettings/templates/tables',
        component: EmailTableDefinitions
    },
    ROUTE_COMMUNICATION_EMAIL_TABLE_DEFINITION: {
        id: 'ROUTE_COMMUNICATION_EMAIL_TABLE_DEFINITION',
        path: '/emailSettings/templates/table',
        component: EmailTableDefinition
    },
    ROUTE_COMMUNICATION_EMAIL_SERVER_EDIT: {
        id: 'ROUTE_COMMUNICATION_EMAIL_SERVER_EDIT',
        path: '/emailSettings/servers/edit',
        component: ServerSettings
    },

    // ROUTE_COMMUNICATION_SMS: {
    //     id: 'ROUTE_COMMUNICATION_SMS',
    //     path: '#',
    //     type: 'communication',
    //     exact: true,
    //     icon: 'cil-filter-frames',
    //     children: [
    //         {
    //             id: 'ROUTE_COMMUNICATION_SMS_GATE',
    //             path: '/smsSettings/gate',
    //             exact: true,
    //             component: Servers,
    //             icon: 'fal fa-window-frame-open'
    //         },
    //         {
    //             id: 'ROUTE_COMMUNICATION_SMS_TEMPLATES',
    //             path: '/smsSettings/templates',
    //             exact: true,
    //             component: EmailTemplates,
    //             icon: 'cil-description'
    //         },
    //     ]
    // }
};