import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    global: null,
    additionalData: null,
    forceLogout: false
};

const errors = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        setError(state, action) {
            const { error, additionalData, forceLogout } = action.payload;

            state.global = error;
            state.additionalData = additionalData;
            state.forceLogout = forceLogout || false;
        },
        clearError(state) {
            state.global = null;
            state.additionalData = null;
            state.forceLogout = false;
        }
    }
});

export const { setError, clearError } = errors.actions;

export default errors.reducer;