import Catalog from '../../views/Catalog';
import NotFound from '../../views/extrasSettings/NotFound';
import LoginSettings from '../../views/extrasSettings/LoginSettings';
import TaxRates from '../../views/extrasSettings/TaxRates';

export const globalSettingsRoutes = {
    ROUTE_GLOBAL_SETTINGS_CATALOGUE_MODE: {
        id: 'ROUTE_GLOBAL_SETTINGS_CATALOGUE_MODE',
        path: '/globalSettings/catalog',
        type: 'globalSettings',
        exact: true,
        icon: 'cil-book',
        component: Catalog,
    },
    ROUTE_GLOBAL_SETTINGS_404: {
        id: 'ROUTE_GLOBAL_SETTINGS_404',
        path: '/globalSettings/notFound',
        type: 'globalSettings',
        exact: true,
        icon: 'fal fa-bug',
        component: NotFound,
    },
    ROUTE_GLOBAL_SETTINGS_TAX_RATES: {
        id: 'ROUTE_GLOBAL_SETTINGS_TAX_RATES',
        path: '/globalSettings/taxRates',
        type: 'globalSettings',
        exact: true,
        icon: 'cil-money',
        component: TaxRates,
    },
    ROUTE_GLOBAL_SETTINGS_LOGIN: {
        id: 'ROUTE_GLOBAL_SETTINGS_LOGIN',
        path: '/globalSettings/login',
        type: 'globalSettings',
        exact: true,
        icon: 'cil-lock-locked',
        component: LoginSettings,
    },
};