const cookiesAgreements = {
    title: '[EN] Ciasteczka - zgody',
    table: {
        tooltips: {
            edit: '[EN] Edytuj zgode',
        },
        emptyTable: '[EN] Brak zgód',

        headerLabels: {
            header: '[EN] Nagłówek',
            isActive: '[EN] Aktywna',
            actions: '[EN] Akcje',
        },
        emptyPlaceholders: {
            header: '[EN] [BRAK NAGŁÓWKA]'
        }
    },
    edit: {
        title: '[EN] Edycja zgody na ciasteczka',
        labels: {
            header: '[EN] Nagłówek',
            isActive: '[EN] Aktywna',
            shortContent: '[EN] Treść króka',
            longContent: '[EN] Treść długa',
        },
        button: '[EN] ZAPISZ ZMIANY',
    },
};

export default cookiesAgreements;