/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';
import { addUpdatePageSection, uploadPromoSectionImage } from '../../../../redux/methods/homePage';
import { getButtonsList } from '../../../../redux/methods/visualSettings';


import { getTranslationForIso, targetTranslatedContent } from '../../../../utils/components';

import { Form as FinalForm } from 'react-final-form';
import { Form, Row } from 'reactstrap';

import SectionAsideButtons from '../../../../components/SectionAsideButtons';
import FileSender from './FileSender';
import FormFieldTranslationsQuill from '../../../../components/formFields/FormFieldTranslationsQuill';
import HomePageSectionMainData from '../HomePageSectionMainData';
import PreviewModalController from '../../../../components/PreviewModalController';
import ButtonPreview from '../../../../components/ButtonPreview';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import styles from './index.module.scss';
import FormFieldColorPicker from '../../../../components/formFields/FormFieldColorPicker';
import FormFieldWithCheckbox from '../../../../components/formFields/FormFieldWithCheckbox';
import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';
import FormFieldDefiningButton from '../../../../components/formFields/FormFieldDefiningButton';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import getConfig from '../../../../getConfig';

import Tabulator from '../../../../components/Tabulator';

import { getReturnButtonProps, onSubmit } from '..';

const propTypes = {
    currentLanguage: PropTypes.string,
    addUpdatePageSection: PropTypes.func,
    section: PropTypes.object
};

const PromotedSection = ({
    currentLanguage,
    utils,
    section,
    addUpdatePageSection,
    uploadPromoSectionImage,
    buttons,
    getButtonsList,
    sectionLocation
}) => {
    const history = useHistory();
    const intl = useIntl();
    const translations = intl.messages.homePage.promoSections.sections;
    const translationsHomePage = intl.messages.homePage;

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const config = { translations: ['content', 'button', 'title'], date: ['activeFrom', 'activeTo'] };

    const [ imageFile, setImageFile ] = useState(null);

    useEffect(() => {
        if(!buttons || buttons.length === 0) {
            getButtonsList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSubmit = async formData => {
        const response = await addUpdatePageSection(
            utils.generateFormToSubmitValues(formData, config),
            /**the second argument conditions the execution of 'forceRefech' to prevent excessive refetch
             * refetch will be called in uploading methods below
            */
            !!(imageFile && imageFile.generatedFile)
        );

        if(imageFile && imageFile.generatedFile) {
            await uploadPromoSectionImage(response, imageFile.generatedFile);
        }

        return onSubmit(section, sectionLocation, history, response);
    };

    const options = [
        {
            id: 'Left',
            label: translations.left
        },
        {
            id: 'Right',
            label: translations.right
        },
    ];

    const horizontalAlignment = [
        {
            id: 'Left',
            label: translations.left
        },
        {
            id: 'Center',
            label: translations.center
        },
        {
            id: 'Right',
            label: translations.right
        },
    ];

    const verticalAlignment = [
        {
            id: 'Top',
            label: translations.top
        },
        {
            id: 'Center',
            label: translations.center
        },
        {
            id: 'Bottom',
            label: translations.bottom
        },
    ];

    const isContent = section => !!imageFile || section.imageSource || section[targetTranslatedContent('button', currentLanguage)] || section[getTranslationForIso('content', currentLanguage)];

    return section ? (
        <div className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <FinalForm
                    initialValues={utils.generateFormInitialValues(section, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values }) => {
                        const selectedButton = buttons.find(button => button.id === values.buttonId);
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Tabulator
                                    listOfElements={[
                                        {
                                            label: translationsHomePage.tabs.basicData,
                                            id: 'searchTerms-table',
                                            component: <HomePageSectionMainData
                                                isSectionHeightController={true}
                                                isNumberOfTilesController={false}
                                                form={form} />
                                        },
                                        {
                                            label: translationsHomePage.tabs.settings,
                                            id: 'lastPhrases-table',
                                            component:
                                                <React.Fragment>
                                                    <Row className='mt-3 px-3'>
                                                        <FileSender
                                                            translations={translations}
                                                            sectionId={section.id}
                                                            setFile={setImageFile}
                                                            section={section}
                                                        />
                                                    </Row>
                                                    <Row className='mt-3 px-3'>
                                                        <FormFieldWithDropdown
                                                            currentSelectionLabel={horizontalAlignment.find(opt => opt.id === values.horizontalAlignment)?.label}
                                                            label={translations.inputs.horizontalAlignment}
                                                            name='horizontalAlignment'
                                                            options={horizontalAlignment}
                                                        />
                                                    </Row>
                                                    <Row className='my-3 px-3'>
                                                        <FormFieldWithDropdown
                                                            currentSelectionLabel={verticalAlignment.find(opt => opt.id === values.verticalAlignment)?.label}
                                                            label={translations.inputs.verticalAlignment}
                                                            name='verticalAlignment'
                                                            options={verticalAlignment}
                                                        />
                                                    </Row>
                                                    <Row className='mt-3 px-3'>
                                                        <FormFieldWithDropdown
                                                            currentSelectionLabel={options.find(opt => opt.id === values.photoLocation).label}
                                                            label={translations.inputs.photoLocation}
                                                            name='photoLocation'
                                                            options={options}
                                                        />
                                                    </Row>
                                                    <Row className='mt-2 px-3'>
                                                        <FormFieldTextOrNumber
                                                            label={translations.inputs.link}
                                                            name='link'
                                                            className='w-100'
                                                        />
                                                    </Row>
                                                    <Row className='mt-2 px-3'>
                                                        <FormFieldTranslationsQuill
                                                            form={form}
                                                            utils={utils}
                                                            propertyName="content"
                                                            header={translations.inputs.content}
                                                        />
                                                    </Row>
                                                    <FormFieldDefiningButton form={form} />
                                                    <PreviewModalController
                                                        content={(
                                                            <div
                                                                className={clsx(styles.container)}
                                                                style={{ backgroundColor: values.backgroundColor }}
                                                            >
                                                                <div className={clsx(styles.wrapper, values.photoLocation === 'Right' && styles.wrapperReverse)}>
                                                                    {
                                                                        (imageFile || values.imageSource) &&
                                                                            <img
                                                                                alt="preview"
                                                                                className={styles.image}
                                                                                src={
                                                                                    imageFile ? imageFile.previewFile : `${apiUrl}${values.imageSource}`
                                                                                }
                                                                            />
                                                                    }
                                                                    {
                                                                        isContent && <div className={styles.label}>
                                                                            {values[targetTranslatedContent('content', currentLanguage)]
                                                                                && <div
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: values[targetTranslatedContent('content', currentLanguage)]
                                                                                        }}
                                                                                    />
                                                                            }
                                                                            {
                                                                                values[targetTranslatedContent('button', currentLanguage)] && selectedButton &&
                                                                                <ButtonPreview
                                                                                    previewTranslation={values[targetTranslatedContent('button', currentLanguage)]}
                                                                                    button={selectedButton && selectedButton.style}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </React.Fragment>
                                        },
                                    ]}
                                />
                                <SectionAsideButtons
                                    buttons={{
                                        add: {
                                            color: 'success',
                                            label: translations.button,
                                            action: handleSubmit,
                                            iconClass: 'fa fa-save mr-2',
                                        },
                                        remove: getReturnButtonProps(sectionLocation, section, history, translations)
                                    }}
                                />
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    ) : null;
};

PromotedSection.propTypes = propTypes;

const mapStateToProps = state => ({
    currentLanguage: selectStoreCurrentLanguage(state),
    buttons: state.visualSettings.buttons
});

const mapDispatch = {
    addUpdatePageSection,
    uploadPromoSectionImage,
    getButtonsList
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(PromotedSection));