import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { updateCustomPageBasicData } from '../../../../redux/methods/customPages';
import { translateIso } from '../../../../utils/locale';
import { generateStatusesConfig, generateFeaturedStatuses } from '../../../../utils/customPages';
import { convertBoolToNumericValue } from '../../../../utils/components';

import { Form as FinalForm, Field  } from 'react-final-form';
import { Container, Form, Row, Col, Label, Input } from 'reactstrap';

import FormFieldTranslations from '../../../../components/formFields/FormFieldTranslations';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldStatus from '../../../../components/formFields/FormFieldStatus';
import FormFieldColorPicker from '../../../../components/formFields/FormFieldColorPicker';

import { customPageStatusEnum, customPageFeaturedEnum } from '../../../../enums/customPages';
import { formUtilsHocPropTypes } from '../../../../propTypes';

import styles from './index.module.scss';

import { withFormUtils } from '../../../../hoc/withFormUtils';
import getConfig from '../../../../getConfig';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    data: PropTypes.object.isRequired,
    handleCustompageSaved: PropTypes.func,
    updateCustomPageBasicData: PropTypes.func
};

const CustomPageEditBasic = ({ utils, data, handleCustompageSaved, updateCustomPageBasicData }) => {
    const intl = useIntl();
    const translations = intl.messages.customPages.edit.tabs.basicData;
    const shopUrl = getConfig().NEXT_SHOP_EXTERNAL_URL;
    const config = { translations: [ 'name', 'content' ] };

    const handleOnSubmit = async formData => {
        const custompageId = await updateCustomPageBasicData(utils.generateFormToSubmitValues(formData, config));
        if (typeof(custompageId) !== 'undefined') {
            handleCustompageSaved(custompageId);
        }
    };

    const statuses = generateStatusesConfig(customPageStatusEnum, translations.statusLabels);
    const featuredStatuses = generateFeaturedStatuses(customPageFeaturedEnum, translations.featuredLabels);
    const allowLettersNumbersUnderscore = new RegExp(/^[a-zA-Z_0-9]+$/);

    const checkNamesInLanguagesFilled = (values) => {
        const names = Object.keys(values).filter(key => key.includes('name'));
        const { languagesVisible }  = values;

        const filledNames =  names.filter(name => values[name]);

        const namesFilledInVisibleLanguages = languagesVisible.map(
            language => filledNames.find(name => name.includes(language)) ? language : 'empty'
        );

        return namesFilledInVisibleLanguages.includes('empty');
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={utils.generateFormInitialValues(data, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        const { values } = form.getState();
                        const selectedStatus = statuses.find(status => status.id === values.status);
                        const selectedFeaturedStatus = featuredStatuses.find(status => status.id === convertBoolToNumericValue(values.featured));

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='px-0' fluid>
                                    <Row className='flex-md-nowrap mt-3 d-inline-flex'>
                                        <Container className='px-0 my-1'>
                                            <FormFieldStatus
                                                name='status'
                                                statuses={statuses}
                                                selectedStatus={selectedStatus}
                                                label={translations.labels.status}
                                            />
                                        </Container>
                                        <Container className='px-0 mt-1 ml-md-2'>
                                            <FormFieldStatus
                                                name='featured'
                                                statuses={featuredStatuses}
                                                selectedStatus={selectedFeaturedStatus}
                                                label={translations.labels.featured}
                                            />
                                        </Container>
                                    </Row>
                                    <Row className='flex-md-nowrap mt-3'>
                                        <Col md>
                                            <div className='w-100'>
                                                <FormFieldTranslations
                                                    form={form}
                                                    modalHeader={translations.labels.name}
                                                    propertyName='name'
                                                    currentIso={utils.currentLanguage()}
                                                    label={translations.labels.name}
                                                />
                                                <span className={clsx('d-none mt-2 text-danger', checkNamesInLanguagesFilled(values) && 'd-block' )}>{translations.labels.nameAlert}</span>

                                                <div className='w-100 my-3'>
                                                    <FormFieldTextOrNumber
                                                        name='slug'
                                                        label={translations.labels.slug}
                                                    />
                                                    <Input className='mt-2'value={`${shopUrl}/pl/${values.slug || ''}`} disabled/>
                                                    <span className={clsx('d-none mt-2 text-danger', (values.slug === null || !allowLettersNumbersUnderscore.test(values.slug)) && 'd-block' )}>{translations.labels.slugAlert}</span>
                                                </div>

                                                <FormFieldColorPicker
                                                    button={translations.labels.backgroundColorButton}
                                                    label={translations.labels.backgroundColor}
                                                    name='backgroundColor'
                                                />
                                            </div>
                                        </Col>
                                        <Col md>
                                            <Label>{translations.labels.availableInLanguages}: </Label>
                                            {
                                                utils.allLanguages().map(language =>
                                                    <div key={`${language}--checkbox`}>
                                                        <Field
                                                            name='languagesVisible'
                                                            value={language}
                                                            component='input'
                                                            type='checkbox'
                                                            id={language}
                                                        />
                                                        <Label for={language} className={'pl-2'}>
                                                            <i className={clsx('flag-icon', `flag-icon-${translateIso(language).toLowerCase()}`, styles.flag)} />
                                                            {translations.languages[language]}
                                                        </Label>
                                                    </div>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset} isSaveButtonDisabled={values.slug === null || !allowLettersNumbersUnderscore.test(values.slug) || checkNamesInLanguagesFilled(values)} />
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

CustomPageEditBasic.propTypes = propTypes;

const mapDispatch = {
    updateCustomPageBasicData,
};

export default withFormUtils(connect(null, mapDispatch)(CustomPageEditBasic));