import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { defaultContactFormOptions } from '../../config';

import { withFormUtils } from '../../hoc/withFormUtils';

import CustomField from './CustomField';
import FormFieldWithCheckbox from '../formFields/FormFieldWithCheckbox';

import { formUtilsHocPropTypes } from '../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    form: PropTypes.object,
    fieldOptions: PropTypes.arrayOf(PropTypes.string),
    utils: formUtilsHocPropTypes,
    values: PropTypes.object
};

const ContactFormCreator = ({
    fieldOptions = defaultContactFormOptions,
    utils,
    form,
    values
}) => {
    const intl = useIntl();
    const translations = intl.messages.homePage.contactFormSection;

    return (
        <table className={styles.container}>
            <tbody>
            {
                fieldOptions.map((option, index) => {
                    if(option === 'customField') {
                        return <CustomField
                            key={`${option}-${index}-customField`}
                            name={option}
                            label={translations.fields.customField}
                            form={form}
                            customFieldName='content.customField.name'
                            currentIso={utils.currentLanguage()}
                            translations={translations}
                            isRequiredName='customFieldIsRequired'
                            values={values}
                        />;
                    }
                    else {
                        return (
                            <tr key={`${option}-${index}-contactFormField`}>
                                <td>
                                    <FormFieldWithCheckbox
                                        name={option}
                                        label={translations.fields[option]}
                                    />
                                </td>
                                <td>
                                    <FormFieldWithCheckbox
                                        name={`${option}IsRequired`}
                                        label={translations.isRequiredLabel}
                                        isDisabled={!values[option]}
                                    />
                                </td>
                            </tr>
                        );
                    }
                })
            }
            </tbody>
        </table>
    );
};

ContactFormCreator.propTypes = propTypes;

export default withFormUtils(ContactFormCreator);