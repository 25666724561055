import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { addUpdatePageSection } from '../../../../../redux/methods/homePage';

import { Form as FinalForm } from 'react-final-form';
import { Form } from 'reactstrap';
import { withFormUtils } from '../../../../../hoc/withFormUtils';
import HomePageSectionMainData from '../../HomePageSectionMainData';
import SectionAsideButtons from '../../../../../components/SectionAsideButtons';

import { onSubmit } from '../..';

const propTypes = {
    addUpdatePageSection: PropTypes.func,
};

const MainSettings = ({ utils, section, addUpdatePageSection, sectionLocation }) => {
    const translations = useIntl().messages.homePage.manufacturersSection.main;
    const history = useHistory();

    const config = { translations: ['title'], date: ['activeFrom', 'activeTo'] };

    const handleOnSubmit = async formData => {
        const response = await addUpdatePageSection({
            ...utils.generateFormToSubmitValues(formData, config),
            amountToShow: parseInt(formData.amountToShow)
        });

        return onSubmit(section, sectionLocation, history, response);
    };

    return (
        <FinalForm
            initialValues={utils.generateFormInitialValues(section, config)}
            onSubmit={handleOnSubmit}
            render={({ handleSubmit, form }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <HomePageSectionMainData
                            form={form}
                            isSectionHeightController={true}
                            isNumberOfTilesController={true}
                        />

                        <SectionAsideButtons
                            buttons={{
                                add: {
                                    color: 'success',
                                    label: translations.button,
                                    action: handleSubmit,
                                    iconClass: 'fa fa-save mr-2',
                                }
                            }}
                        />
                    </Form>
                );
            }}
        />
    );
};

MainSettings.propTypes = propTypes;

const mapDispatch = {
    addUpdatePageSection
};

export default withFormUtils(connect(null, mapDispatch)(MainSettings));