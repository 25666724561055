import React from 'react';
import PropTypes from 'prop-types';

import SectionsList from '../../../../features/sections/SectionsList';

const propTypes = {
    id: PropTypes.number
};

const CustomPageEditSections = ({ id }) => {
    return id ? (
        <SectionsList sectionLocation='customPage' affiliationObjectId={id}/>
    ) : null;
};

CustomPageEditSections.propTypes = propTypes;

export default CustomPageEditSections;