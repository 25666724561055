/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateDiscountUsers } from '../../../../../redux/methods/discounts';

import { handleCheckboxAction } from '../../../../../utils/components';

import SectionAsideButtons from '../../../../../components/SectionAsideButtons';
import Paginator from '../../../../../components/Paginator';
import UserAssigner from '../../../../users/UserAssigner';
import UsersTable from '../../../../../components/tables/UsersTable';

import { stringOrNumberPropTypes, userPropTypes } from '../../../../../propTypes';

import { withFormUtils } from '../../../../../hoc/withFormUtils';

const propTypes = {
    pages: PropTypes.number,
    currentPage: PropTypes.number,
    discountToEditId: stringOrNumberPropTypes,
    isActiveLabelLeft: PropTypes.bool,
    groupsIsPresent: PropTypes.bool,
    updateDiscountUsers: PropTypes.func,
    setCurrentPage: PropTypes.func,
    discountUsers: PropTypes.arrayOf(userPropTypes),
};

const UsersSection = ({ utils,
    pages, currentPage, discountToEditId, isActiveLabelLeft, discountUsers,
    groupsIsPresent, updateDiscountUsers, setCurrentPage
}) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.groupsUsers;

    const [checkedUsersIds, setCheckedUsersIds] = useState([]);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

    useEffect(() => {
        return () => {
            setCheckedUsersIds([]);
            setIsAssignModalOpen(false);
        };
    }, [discountUsers]);

    const handleCheckboxClick = id => {
        handleCheckboxAction(id, checkedUsersIds, setCheckedUsersIds);
    };

    const handleUnassignUser = id => {
        setCurrentPage(0);
        updateDiscountUsers(discountToEditId, [id], isActiveLabelLeft, true);
    };

    const handleAssignUsers = (usersToAssignIds) => {
        setCurrentPage(0);
        updateDiscountUsers(discountToEditId, usersToAssignIds, isActiveLabelLeft, false);
    };

    const handleOpenAssignModal = () => {
        setIsAssignModalOpen(true);
    };

    const handleOpenUnassignModal = () => {
        utils.confirm(
            () => {
                updateDiscountUsers(discountToEditId, checkedUsersIds, isActiveLabelLeft, true);
                setCurrentPage(0);
            },
            translations.deleteModalUsers.header,
            translations.deleteModalUsers.body
        );
    };

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const toggleCheckboxes = isChecked => {
        if(isChecked){
            setCheckedUsersIds([]);
        } else {
            setCheckedUsersIds(discountUsers.map(({id}) => id));
        }
    };

    const sectionButtons = {
        remove: {
            label: translations.buttons.users.remove,
            action: handleOpenUnassignModal,
            isDisabled: checkedUsersIds.length === 0
        },
        add: {
            label: translations.buttons.users.add,
            action: handleOpenAssignModal,
            isDisabled: isActiveLabelLeft && !groupsIsPresent
        }
    };

    return (
        <React.Fragment>
            <span className={clsx('d-block mb-2 font-weight-bold', isActiveLabelLeft ? 'text-danger' : 'text-success')}>
                    {isActiveLabelLeft ? translations.headers.users.unassign : translations.headers.users.assign}
            </span>
            <UsersTable
                checkboxAction={handleCheckboxClick}
                checkedCheckboxIds={checkedUsersIds}
                deleteModalText={translations.deleteModalUser}
                emptyTable={translations.userTable.emptyTable}
                headers={translations.userTable.headers}
                isRedOnHover={isActiveLabelLeft}
                removeAction={handleUnassignUser}
                tooltipText={{
                    delete: translations.userTable.tooltipText
                }}
                withCheckbox={true}
                users={discountUsers}
                toggleCheckboxes={toggleCheckboxes}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={currentPage + 1}
                        onPageChange={handlePageChange}
                    />
            }
            <SectionAsideButtons buttons={sectionButtons} />
            <UserAssigner
                isOpen={isAssignModalOpen}
                setIsOpen={setIsAssignModalOpen}
                onConfirm={handleAssignUsers}
            />
        </React.Fragment>
    );
};

UsersSection.propTypes = propTypes;

const mapStateToProps = state => ({
    discountToEditId: state.discounts.discountToEdit.id,
});

const mapDispatch = {
    updateDiscountUsers,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(UsersSection));