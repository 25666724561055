import React from 'react';
import PropTypes from 'prop-types';

import TableGenerator from '../../generators/TableGenerator';

import { usagesPropTypes } from '../../../propTypes';

const propTypes = {
    usages: PropTypes.arrayOf(usagesPropTypes),
    headerLabels: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        surname: PropTypes.string,
        email: PropTypes.string,
        date: PropTypes.string,
        orderNumber: PropTypes.string,
    }).isRequired,
    emptyTableFiller: PropTypes.string,
};

const CodesUsagesTable = ({ usages, headerLabels, emptyTableFiller }) => {
    return (
        <TableGenerator
            items={usages}
            translations={{
                emptyTable: emptyTableFiller
            }}
            columns={[
                {
                    header: headerLabels.code,
                    value: 'code'
                },
                {
                    header: headerLabels.name,
                    value: 'name'
                },
                {
                    header: headerLabels.surname,
                    value: 'surname'
                },
                {
                    header: headerLabels.email,
                    value: 'email'
                },
                {
                    header: headerLabels.date,
                    value: 'date',
                    type: 'date'
                },
                {
                    header: headerLabels.orderNumber,
                    value: 'orderNumber'
                },
            ]}
        />
    );
};

CodesUsagesTable.propTypes = propTypes;

export default CodesUsagesTable;