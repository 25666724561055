import { languages } from './storeLocale';

const popups = {
    view: {
        title: "Lista okienek",
        actions: {
            addPopup: "DODAJ OKIENKO"
        },
        buttons: {
            activate: 'Aktywuj',
            deactivate: 'Deaktywuj'
        },
        headerLabels: {
            name: 'Nazwa',
            status: 'Status',
            actions: 'Akcje',
        },
        fillers: {
            name: '[BRAK NAZWY]'
        },
        tooltips: {
            remove: 'Usuń okienko',
            edit: 'Edytuj okienko'
        },
        statusLabels: {
            published: 'Opublikowane',
            unpublished: 'Niepublikowane'
        },
        emptyTable: 'Nie znaleziono okienek popup.',
        deleteModal: {
            header: 'Usuń okienko',
            body: 'Czy jesteś pewien, że chcesz usunąć okienko?',
            cancel: 'WRÓC',
            confirm: 'USUŃ',
        }
    },
    edit: {
        fallbackName: '[BRAK NAZWY]',
        tabSelectorTitle: 'Sekcje',
        tabs: {
            basicData: {
                modalHeader: 'Nazwa okienka',
                title: 'Dane podstawowe',
                labels: {
                    name: 'Nazwa',
                    status: 'Status',
                    availableInLanguages: 'Okienko dostępne w językach',
                    nameAlert: 'Wszystkie nazwy w widocznych językach muszą być podane.',
                    backgroundColor: 'Kolor tła',
                    backgroundColorButton: 'Wybierz'
                },
                statusLabels: {
                    published: 'Opublikowana',
                    unpublished: 'Niepublikowana'
                },
                languages: languages
            },
            sections: {
                title: 'Sekcje okienta popup',
            }
        }
    }
};

export default popups;