import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getSynchronizationsSettings, updateSynchronizationsSettings } from '../../../../redux/methods/synchronizations';

import { unitPresentationTypes } from '../../../../config';

import { Form as FinalForm } from 'react-final-form';
import { Alert, Container, Form, Row } from 'reactstrap';

import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import FormFieldSwitchWithTwoLabels from '../../../../components/formFields/FormFieldSwitchWithTwoLabels';
import FormFieldSwitchPill from '../../../../components/formFields/FormFieldSwitchPill';
import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';

import useFetch from '../../../../hooks/useFetch';

const propTypes = {
    getSynchronizationsSettings: PropTypes.func,
    updateSynchronizationsSettings: PropTypes.func,
};

const Settings = ({ getSynchronizationsSettings, updateSynchronizationsSettings }) => {
    const intl = useIntl();
    const translations = intl.messages.synchronizations.settings;

    const [ data ] = useFetch(getSynchronizationsSettings);

    const unitPresentationTypesOptions = unitPresentationTypes.map(type => ({
        id: type,
        label: translations.unitPresentationTypes[type.toLowerCase()]
    }));

    return data ? (
        <Container fluid>
            <FinalForm
                initialValues={data}
                onSubmit={updateSynchronizationsSettings}
                render={({ handleSubmit, form, values }) => {
                    const currentUnitPresentationTypeLabel = unitPresentationTypesOptions.find(option => option.id === values.unitPresentation).label;
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row className='w-100'>
                                <Alert color="info" className='mt-2 w-100'>
                                    {translations.labelsPromotions.main}: {data.isPromotionFromErp ? translations.labelsPromotions.true : translations.labelsPromotions.false}
                                </Alert>
                            </Row>
                            <Row>
                                <FormFieldSwitchWithTwoLabels
                                    name='isShopCategoryActive'
                                    labels={translations.labelsCategories}
                                />
                            </Row>
                            <Row>
                                <FormFieldSwitchWithTwoLabels
                                    name='synchronizeCategoryLocation'
                                    labels={translations.labelsCategoriesLocation}
                                    isDisabled={values.isShopCategoryActive}
                                    tooltip={translations.labelsCategoriesLocation.tooltip}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <FormFieldSwitchPill
                                    name='addFlatCategoriesToDefaultCategory'
                                    label={translations.addFlatCategoriesToDefaultCategory}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <FormFieldSwitchPill
                                    name='reserveItemsOnOrders'
                                    label={translations.reserveItemsOnOrders}
                                />
                            </Row>
                            <Row className='mt-4'>
                                <FormFieldWithDropdown
                                    name='unitPresentation'
                                    label={translations.unitPresentation}
                                    isDisabled={values.isShopUnitsActive}
                                    options={unitPresentationTypesOptions}
                                    currentSelectionLabel={currentUnitPresentationTypeLabel}
                                />
                            </Row>
                            <SectionEditFormButtons formReset={form.reset}/>
                        </Form>
                    );
                }}
            />
        </Container>
    ): null;
};

Settings.propTypes = propTypes;

const mapDispatch = {
    getSynchronizationsSettings,
    updateSynchronizationsSettings,
};

export default connect(null, mapDispatch)(Settings);