import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import useFetch from '../../../hooks/useFetch';
import { getEmailTemplateTables } from '../../../redux/methods/emails';

import { Container, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import EmailTemplateTablesTable from '../../../components/tables/EmailTemplateTablesTable';
import SaveAndBackButtonsSection from '../../../components/SaveAndBackButtonsSection';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    forceRefetch: PropTypes.bool,
    getEmailTemplateTables: PropTypes.func
};

const EmailTableDefinitions = ({
    history,
    location,
    forceRefetch,
    getEmailTemplateTables
}) => {
    const intl = useIntl();
    const translations = intl.messages.emails.tableDefinitions;

    /**
    * Deconstruct query params
    */
    const query = new URLSearchParams(location.search);
    const chosenTemplateId = parseInt(query.get('templateId'));

    const [ tables ] = useFetch(getEmailTemplateTables, chosenTemplateId, [], forceRefetch);

    const onBack = () => {
        history.push(`/emailSettings/templates`);
    };

    return tables ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                {translations.viewHeader}
                            </CardHeader>
                            <CardBody>
                                <main>
                                    <EmailTemplateTablesTable
                                        templateId={chosenTemplateId}
                                        tables={tables}
                                        translations={translations.table}
                                    />
                                    <SaveAndBackButtonsSection
                                        onBack={onBack}
                                        isSaveButtonVisible={false}
                                    />
                                </main>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    ): null;
};

EmailTableDefinitions.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.emailTemplateTables,
    isLoading: state.loaders.global,
});

const mapDispatch = {
    getEmailTemplateTables
};

export default connect(mapStateToProps, mapDispatch)(EmailTableDefinitions);