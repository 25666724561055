const categorySelectModal = {
    header: 'Select category',
    caption: 'Select category',
    searchLabel: 'Search category:',
    selectButtonLabel: 'Select',
    cancelButtonLabel: 'Cancel',
    buttonPlaceholder: '[EN] Brak',
    label: '[EN] Kategoria: ',
    dropdownSelector: {
        label: 'Widoczność kategorii',
        options: {
            all: 'Wszystkie',
            activeOnly: 'Tylko aktywne'
        }
    }
};

export default categorySelectModal;