import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    deleteDoubleImageSectionImage,
} from '../../../../../redux/methods/homePage';

import FileSender from '../../../../../components/FileSender';

import { stringOrNumberPropTypes } from '../../../../../propTypes';

const propTypes = {
    getPromoSectionImage: PropTypes.func,
    deleteDoubleImageSectionImage: PropTypes.func,
    translations: PropTypes.object,
    sectionId: stringOrNumberPropTypes,
    section: PropTypes.object,
    setFile: PropTypes.func,
};

const FileSenderPromotedSections = ({
    sectionId,
    translations,
    isFirstImage,
    deleteDoubleImageSectionImage,
    setFile,
    section,
}) => {
    const handleRemoveImage = () => {
        deleteDoubleImageSectionImage(sectionId, isFirstImage);
    };

    const image = section[isFirstImage ? 'firstImage' : 'secondImage'] && section[isFirstImage ? 'firstImage' : 'secondImage'].imageSource;

    return (
        <React.Fragment>
            <FileSender
                validateFormats={['jpg', 'png', 'jpeg', 'gif', 'mp4', 'webm', 'webp']}
                buttons={translations.inputs.file}
                photo={image}
                withPreview
                fileKey='image'
                handleRemove={handleRemoveImage}
                customFileKey={{
                    param: isFirstImage,
                    name: 'firstimage'
                }}
                maxSize={10}
                setFile={setFile}
                graphicTypeToEnableGuide='productCard'
            />
        </React.Fragment>
    );
};

FileSenderPromotedSections.propTypes = propTypes;

const mapDispatch = {
    deleteDoubleImageSectionImage,
};

export default connect(null, mapDispatch)(FileSenderPromotedSections);