import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { withFormUtils } from '../../../hoc/withFormUtils';

import SettingsTabulator from '../../../components/layouts/SettingsTabulator';
import PhotoWithText from './Sections/PhotoWithText';
import Newsletter from './Sections/Newsletter';
import Footer from './Sections/Footer';

import { formUtilsHocPropTypes } from '../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getPromotionBar: PropTypes.func,
    updatePromotionBar: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const FooterSettings = () => {
    const intl = useIntl();
    const translations = intl.messages.menuSettings.footer;

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'footerSettings-PhotoWithText':
                return <PhotoWithText />;
            case 'footerSettings-Newsletter':
                return <Newsletter />;
            case 'footerSettings-Footer':
                return <Footer />;
            default:
                return null;
        }
    };

    return (
        <SettingsTabulator
                title={{
                    label: translations.title
                }}
                tabulatorHeader={{
                    label: 'translations.tabSelectorTitle',
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: `footerSettings-PhotoWithText`,
                        header: {
                            label: translations.sections.photoWithText.title,
                            iconClassName: 'nav-icon cil-image'
                        }
                    },
                    {
                        id: `footerSettings-Newsletter`,
                        header: {
                            label: translations.sections.newsletter.title,
                            iconClassName: 'nav-icon cil-envelope-closed'
                        }
                    },
                    {
                        id: `footerSettings-Footer`,
                        header: {
                            label: translations.sections.footer.title,
                            iconClassName: 'nav-icon fal fa-sort-size-down-alt'
                        }
                    },
                ]
                }
        />
    );
};

FooterSettings.propTypes = propTypes;

export default withFormUtils(FooterSettings);