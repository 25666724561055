import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useIntl } from 'react-intl';

import { Container, Card, CardHeader, CardBody } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object,
};

const DashboardParcels = () => {
    const intl = useIntl();
    const translations = intl.messages.dashboard.stats;

    return (
        <Container fluid className={styles.fullScreenOnMobile}>
            <Card>
                <CardHeader>
                    <i className={clsx('fa fa-align-justify')}></i>
                    <span>{translations.title}</span>
                </CardHeader>
                <CardBody>
                </CardBody>
            </Card>
        </Container>
    );
};

DashboardParcels.propTypes = propTypes;

export default withRouter(DashboardParcels);