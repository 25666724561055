const list = {
    settings: {
        header: 'Presentation of products > List',
        button: {
            save: 'SAVE'
        },
        inputs: {
            productsQuantity: 'Number of articles in the list',
            visibleTagAttributeOnProductImage: 'Atrybut typu Tag widoczny na zdjęciu produktu',
            manufacturerField: 'Manufacturer information',
            showReviews: 'Pokaż oceny produktu',
            showQuantity: 'Pokaż ilość produktu',
            showAddToFavourites: 'Pokaż ikonę "Dodaj do ulubionych"',
            showAddToComparision: 'Pokaż ikonę "Dodaj do porównania"',
            showRelated: 'Pokaż ikonę "Powiązane"',
            showAddToCart: 'Pokaż przycisk "Dodaj do koszyka"',
            showQuantitySelection: 'Pokaż pola wyboru jednostki i ilości',
            showNetPrice: 'Pokaż cenę netto'
        },
        additionalFields: {
            quantity: 'Dostępna ilość',
            measure: 'Rozmiar',
            promotiondateto: 'Okres promocji'
        },
        manufacturerFields: {
            name: 'Name',
            logo: 'Logo'
        }

    }
};

export default list;