const discounts = {
    view: {
        title: 'Lista kodów rabatowych',
        paginator: {
            previous: 'Poprzednia',
            next: 'Następna'
        },
        actions: {
            addDiscount: 'DODAJ KOD RABATOWY',
        },
        table: {
            headers: {
                status: 'Status',
                name: 'Nazwa',
                code: 'Kod',
                validFrom: 'Obowiązuje od',
                validTo: 'Obowiązuje do',
                currency: 'Waluta',
                type: 'Typ',
                range: 'Zakres',
                actions: 'Akcje',
            },
            fillers: {
                name: '[BRAK NAZWY]',
                code: '[BRAK KODU]',
                currency: '[BRAK WALUTY]',
                type: '[BRAK TYPU]',
                range: '[BRAK ZAKRESU]',
            },
            translations: {
                tooltips: {
                    edit: 'Edytuj rabat',
                    remove: 'Usuń rabat',
                },
                deleteModal: {
                    header: 'Usuwanie rabatu',
                    body: 'Czy napewno chcesz usunąć rabat?',
                    confirm: 'USUŃ',
                    cancel: 'ANULUJ',
                },
                emptyTable: 'Nie znaleziono rabatów',
                type: {
                    percentage: 'Procentowy',
                    amount: 'Ilościowy',
                },
                range: {
                    entireOrder: 'Całe zamówienie',
                    specificProducts: 'Określone produkty',
                    shipmentMethod: 'Metoda dostawy',
                    buyXGetYSingle: 'Kup X otrzymaj Y (pojedyncze produkty)',
                    buyXGetYGroup: 'Kup X otrzymaj Y (grupy produktów)',
                },
                statusLabels: {
                    created: 'Utworzony',
                    visible: 'Widoczny',
                    hidden: 'Ukryty',
                    utilized: 'Wykorzystany',
                    expired: 'Przedawniony'
                },
            }
        }
    },
    edit: {
        fallbackName: '[BRAK NAZWY]',
        tabSelectorTitle: 'Sekcje',
        tabs: {
            basic: {
                title: 'Dane podstawowe',
                priorityTooltip: 'Kody z wyższym priorytetem będą nakładane w pierwszej kolejności',
                inputs: {
                    status: 'Status kodu: ',
                    forPromotions: 'Rabatuj również produkty w promocji',
                    currency: 'Waluta',
                    type: 'Typ',
                    range: 'Zakres',
                    name: 'Nazwa kodu',
                    code: 'Kod',
                    validFrom: 'Obowiązuje od',
                    validTo: 'Obowiązuje do',
                    priority: 'Priorytet',
                },
                type: {
                    percentage: 'Procentowy',
                    amount: 'Ilościowy',
                },
                range: {
                    entireOrder: 'Całe zamówienie',
                    specificProducts: 'Określone produkty',
                    shipmentMethod: 'Metoda dostawy',
                    buyXGetYSingle: 'Kup X otrzymaj Y (pojedyncze produkty)',
                    buyXGetYGroup: 'Kup X otrzymaj Y (grupy produktów)',
                },
                status: {
                    created: 'Utworzony',
                    visible: 'Widoczny',
                    hidden: 'Ukryty',
                    utilized: 'Wykorzystany',
                    expired: 'Przedawniony'
                },
            },
            parameters: {
                title: 'Parametry',
                fields: {
                    discountPercentage: {
                        label: 'Procentowa wielkość rabatu',
                        tooltipText: 'Wpisz wartość pomiędzy 0 a 100.',
                        symbol: '%',
                    },
                    discountPercentageAmountLimit: {
                        label: 'Minimalna cena po rabacie',
                        tooltipText: 'Wyliczony rabat nie może przekroczyć poniższej kwoty ',
                    },
                    discountAmount: {
                        label: 'Kwota rabatu',
                        tooltipText: 'Wielkość rabatu w wybranej walucie'
                    },
                    requiredMinimumPurchaseAmount: {
                        label: 'Minimalna wartość brutto zamówienia',
                        tooltipText: 'Minimalna wartość brutto zamówienia, od której możliwe jest wykorzystanie kuponu',
                        tooltipTextSpecificProducts: 'Minimalna wartość brutto zamówienia, od której możliwe jest wykorzystanie kuponu:'
                    },
                    maximumUsesOfDiscountPerUser: {
                        label: 'Maksymalna ilość użyć na użytkownika',
                        tooltipText: `Pozostaw to pole puste jeśli nie chcesz
                            ograniczać ilości użyć kuponu na użytkownika. Wypełnienie
                            tego pola ograniczy dostępność kuponu wyłącznie do zalogowanych użytkowników`
                    },
                    maximumUsesOfDiscountPerShop: {
                        label: 'Maksymalna ilość użyć na sklep',
                        tooltipText: 'Pozostaw to pole puste jeśli nie chcesz ograniczać ilości użyć kuponu w sklepie',
                    },
                    requiredMinimumQuantityOfItems: {
                        label: 'Minimalna ilość zamawianego produktu',
                        tooltipText: 'Wprowadź liczbę nie mniejszą niż 1'
                    },
                    maximumUsesOfDiscountPerCart: {
                        label: 'Maksymalna ilość użyć na koszyk',
                        tooltipText: 'Pozostaw to pole puste jeśli nie chcesz ograniczać ilości użyć kuponu w koszyku',
                    },
                    offeredQuantity: {
                        label: 'Ilość sztuk otrzymujących rabat',
                        tooltipText: 'Wpisz liczbę pomiędzy 1 a polem "Minimalna ilość zamawianego produktu"'
                    },
                    offeredRepetitions: {
                        label: 'Maksymalna ilość powtórzeń',
                        tooltipText: `Jeśli zamawiana ilość produktu stanowi wielokrotność
                            Minimalnej ilości zamawianego produktu - rabat zostanie nałożony
                            określoną w tym polu ilość razy (o ile będzie to możliwe).
                            Pozostawienie tego pola pustego spowoduje nakładanie rabatu
                            maksymalną możliwą ilość razy (wynikającą z ilości zamawianego produktu)`
                    },
                }
            },
            categoriesProducts: {
                title: 'Kategorie / artykuły / usługi',
                switch: {
                    left: 'KATEGORIE',
                    right: 'ARTYKUŁY / USŁUGI',
                    main: 'Przełącz tryb:',
                    tooltip: 'Aby zmienić tryb musisz usunąć przypisane kategorie, artykuły, usługi oraz wyczyścić pola wyszukiwania.'
                },
                deleteModalCategories: {
                    header: 'Odłączanie kategorii',
                    body: 'Czy na pewno chcesz odłączyć zaznaczone kategorie od wybranego rabatu?',
                },
                deleteModalProducts: {
                    header: 'Odłączanie artykułów / usług',
                    body: 'Czy na pewno chcesz odłączyć zaznaczone artykuły / usługi od wybranego rabatu?',
                },
                deleteModalProduct: {
                    header: 'Odłączanie artykułu / usługi',
                    body: 'Czy na pewno chcesz odłączyć artykuł / usługę od wybranego rabatu?',
                    confirm: 'ODŁĄCZ',
                    cancel: 'WRÓĆ'
                },
                tooltipTranslation: 'Odłącz artykuł / usługę',
                headers: {
                    categories: 'PRZYPISANE KATEGORIE',
                    products: {
                        assign: 'PRZYPISANE ARTYKUŁY / USŁUGI',
                        unassign: 'WYŁĄCZONE ARTYKUŁY / USŁUGI'
                    },
                },
                table: {
                    columnNames: {
                        categories: 'Przypisana kategoria',
                        actions: 'Akcje',
                    },
                    tooltip: 'Odłącz kategorię',
                    placeholder: 'Brak kategorii do wyświetlenia',
                    deleteModal: {
                        header: 'Odłącz kategorię',
                        bodyStart: 'Czy chcesz odłączyć kategorię',
                        bodyEnd: 'od wybranego rabatu',
                        confirm: 'ODŁĄCZ',
                        cancel: 'WRÓĆ'
                    }
                },
                buttons: {
                    categories: {
                        add: 'PRZYPISZ KATEGORIĘ',
                        remove: 'ODŁĄCZ WYBRANE KATEGORIE',
                    },
                    products: {
                        addShipment: 'DOSTAWA',
                        addService: 'USŁUGA',
                        addArticle: 'ARTYKUŁ',
                        remove: 'ODŁĄCZ WYBRANE ARTYKUŁY / USŁUGI',
                    }
                },
                fallbacks: {
                    name: '[BRAK NAZWY]',
                    catalgueIndex: '[BRAK INDEKSU KATALOGOWEGO]',
                    tradeIndex: '[BRAK INDEKSU HANDLOWEGO]',
                },
                categoriesSection: {
                    selectModal: {
                        header: 'Wybór kategorii',
                        confirm: 'WYBIERZ',
                        cancel: 'WRÓĆ',
                        labels: {
                            dropdown: 'Wybierz kategorię do przypisania: ',
                            emptySelection: 'Brak wybranej kategorii',
                            tooltip: 'Wybierz kategorię do przypisania',
                            inputLabel: 'Wyszukaj kategorię: '
                        }
                    },
                    confirmModal: {
                        header: 'Przypisywanie kategorii',
                        body:'Czy napewno chcesz przypisać rabat do wybranej kategorii?',
                        confirm: 'PRZYPISZ',
                        cancel: 'WRÓĆ',
                    },
                }
            },
            regionsCountries: {
                title: 'Regiony / kraje',
                switch: {
                    left: 'REGIONY',
                    right: 'KRAJE',
                    main: 'Przełącz tryb:',
                    tooltip: 'Aby zmienić tryb musisz usunąć przypisane regiony i kraje.'
                },
                deleteModalCountries: {
                    header: 'Odłączanie krajów',
                    body: 'Czy na pewno chcesz odączyć zaznaczone kraje od wybranego kodu?',
                },
                deleteModalRegions: {
                    header: 'Odłączanie regionów',
                    body: 'Czy na pewno chcesz odączyć zaznaczone regiony od wybranego kodu?',
                },
                assignModalRegions: {
                    header: 'Przypisywanie regionu',
                    body: 'Wybierz region do przypisania:',
                    confirm: 'PRZYPISZ',
                    cancel: 'WRÓĆ'
                },
                headers: {
                    regions: 'PRZYPISANE REGIONY',
                    countries: {
                        assign: 'PRZYPISANE KRAJE',
                        unassign: 'WYŁĄCZONE KRAJE'
                    },
                },
                buttons: {
                    regions: {
                        add: 'PRZYPISZ REGION',
                        remove: 'ODŁĄCZ WYBRANE REGIONY',
                    },
                    countries: {
                        add: 'PRZYPISZ KRAJE',
                        remove: 'ODŁĄCZ WYBRANE KRAJE',
                    }
                },
                tables: {
                    countries: {
                        fillers: {
                            iso: '[BRAK ISO]',
                            name: '[BRAK NAZWY]',
                        },
                        deleteModal: {
                            header: 'Odłączanie kraju',
                            body: 'Czy na pewno chcesz odłączyć kraj od wybranego kodu?',
                            confirm: 'ODŁĄCZ',
                            cancel: 'WRÓĆ',
                        },
                        headers: {
                            name: 'Nazwa',
                            iso: 'Kod ISO',
                            actions: 'Akcje',
                        },
                        tooltips: {
                            remove: 'Odłącz kraj'
                        },
                        emptyTable: 'Brak krajów do wyświetlenia',
                    },
                    regions: {
                        fillers: {
                            name: '[BRAK NAZWY]',
                        },
                        deleteModal: {
                            header: 'Odłączanie regionu',
                            body: 'Czy na pewno chcesz odłączyć region od wybranego kodu?',
                            confirm: 'ODŁĄCZ',
                            cancel: 'WRÓĆ',
                        },
                        headers: {
                            name: 'Nazwa',
                            actions: 'Akcje',
                        },
                        tooltips: {
                            remove: 'Odłącz region'
                        },
                        table: 'Brak regionów do wyświetlenia',
                    }
                },
                countryAssignerHeader: 'Zarządzanie krajami przyłączonymi do kodu'
            },
            groupsUsers: {
                title: 'Grupy / użytkownicy',
                switch: {
                    left: 'GRUPY',
                    right: 'UŻYTKOWNICY',
                    main: 'Przełącz tryb:',
                    tooltip: 'Aby zmienić tryb musisz usunąć przypisane grupy i użytkowników.'
                },
                deleteModalGroups: {
                    header: 'Odłączanie grup',
                    body: 'Czy na pewno chcesz odączyć zaznaczone grupy od wybranego kodu?',
                },
                deleteModalUsers: {
                    header: 'Odłączanie użytkowników',
                    body: 'Czy na pewno chcesz odączyć zaznaczonych użytkowników od wybranego kodu?',
                },
                deleteModalGroup: {
                    header: 'Odłączanie grupy',
                    body: 'Czy na pewno chcesz odączyć grupę od wybranego kodu?',
                    confirm: 'ODŁĄCZ',
                    cancel: 'WRÓĆ'
                },
                userTable: {
                    headers: {
                        email: 'Email',
                        actions: 'Akcje',
                    },
                    tooltipText: 'Usuń użytkownika',
                    emptyTable: 'brak użytkowników do wyświetlenia'
                },
                groupTable: {
                    headers: {
                        name: 'Nazwa',
                        actions: 'Akcje'
                    },
                    tooltipText: 'Usuń grupę',
                    emptyTable: 'Brak grup do wyświetlenia'
                },
                deleteModalUser: {
                    header: 'Odłączanie użytkownika',
                    body: 'Czy na pewno chcesz odączyć użytkownika od wybranego kodu?',
                    confirm: 'ODŁĄCZ',
                    cancel: 'WRÓĆ'
                },
                headers: {
                    groups: 'PRZYPISANE GRUPY',
                    users: {
                        assign: 'PRZYPISANI UŻYTKOWNICY',
                        unassign: 'WYŁĄCZENI UŻYTKOWNICY'
                    },
                },
                buttons: {
                    groups: {
                        add: 'PRZYPISZ GRUPE',
                        remove: 'ODŁĄCZ WYBRANE GRUPY',
                    },
                    users: {
                        add: 'PRZYPISZ UŻYTKOWNIKÓW',
                        remove: 'ODŁĄCZ WYBRANYCH UŻYTKOWNIKÓW',
                    }
                },
                tables: {
                    users: {
                        deleteModal: {
                            header: 'Odłączanie użytkownika',
                            body: 'Czy na pewno chcesz odłączyć użytkownika od wybranego kodu?',
                            confirm: 'ODŁĄCZ',
                            cancel: 'WRÓĆ',
                        },
                        tooltip: 'Odłącz użytkownika'
                    },
                    groups: {
                        deleteModal: {
                            header: 'Odłączanie grupy',
                            body: 'Czy na pewno chcesz odłączyć grupę od wybranego kodu?',
                            confirm: 'ODŁĄCZ',
                            cancel: 'WRÓĆ',
                        },
                        tooltip: 'Odłącz grupe'
                    }
                },
            },
            attributes: {
                title: 'Atrybuty / Producenci',
                editAttributes: 'Edytuj atrybuty kuponu rabatowego',
                placeholders: {
                    selectAttributes: 'Wybierz atrybuty',
                    selected: 'Wybrano:'
                }
            },
            codes: {
                title: 'Kody',
                emptyTableFiller: 'Brak kodów do wyświetlenia',
                headerLabels: {
                    code: 'Kod',
                    usages: 'Użycia',
                    usagesLeft: 'Pozostałe użycia'
                },
                buttons: {
                    export: 'Eksportuj jako csv',
                    generate: 'Generuj kody',
                },
                generateModal: {
                    header: 'Generowanie kodów',
                    buttons: {
                        cancel: 'Anuluj',
                        confirm: 'Generuj'
                    },
                    inputs: {
                        howLongCode: 'Długość kodu',
                        howManyCodes: 'Ilość kodów',
                        howManyUsagesPerCode: 'Liczba użyć na kod',
                    }
                }
            },
            usages: {
                title: 'Użycia',
                emptyTableFiller: 'Brak użyć do wyświetlenia',
                headerLabels: {
                    code: 'Kod',
                    name: 'Imię',
                    surname: 'Nazwisko',
                    email: 'Email',
                    date: 'Data',
                    orderNumber: 'Numer zamówienia',
                },
                search: {
                    buttons: {
                        submit: 'SZUKAJ',
                        reset: 'RESETUJ',
                    },
                    inputs: {
                        code: {
                            label: 'Kod',
                        },
                        name: {
                            label: 'Imię',
                        },
                        surname: {
                            label: 'Nazwisko',
                        },
                        email: {
                            label: 'Email',
                        },
                        date: {
                            label: 'Data',
                        },
                        orderNumber: {
                            label: 'Numer zamówienia',
                        },
                    }
                }
            },
            tagSettings: {
                title: 'Ustawienia etykiet',
                inputs: {
                    enabled: 'Etykieta widoczna',
                    backgroundColor: {
                        name: 'Kolor tła',
                        pickButton: 'Wybierz'
                    },
                    borderColor: {
                        name: 'Kolor ramki',
                        pickButton: 'Wybierz'
                    },
                    textColor: {
                        name: 'Kolor tekstu',
                        pickButton: 'Wybierz'
                    }
                }
            }
        },
    },
    searchForm: {
        status: {
            label: 'Status:',
        },
        name: {
            label: 'Nazwa kodu:',
            placeholder: ''
        },
        code: {
            label: 'Kod:',
            placeholder: '',
        },
        validFrom: {
            label: 'Obowiązuje od:',
        },
        validTo: {
            label: 'Obowiązuje do:',
        },
        type: {
            label: 'Typ:',
        },
        range: {
            label: 'Zakres:',
        },
        currencies: {
            label: 'Waluta:',
        },
        dropdowns: {
            any: 'Dowolny',
            type: {
                percentage: 'Procentowy',
                amount: 'Ilościowy',
            },
            range: {
                entireOrder: 'Całe zamówienie',
                specificProducts: 'Określone produkty',
                shipmentMethod: 'Metoda dostawy',
                buyXGetYSingle: 'Kup X otrzymaj Y (pojedyncze produkty)',
                buyXGetYGroup: 'Kup X otrzymaj Y (grupy produktów)',
            },
            status: {
                created: 'Utworzony',
                visible: 'Widoczny',
                hidden: 'Ukryty',
                utilized: 'Wykorzystany',
                expired: 'Przedawniony'
            },
        },
        action: 'SZUKAJ',
        reset: 'RESETUJ',
    },
};

export default discounts;