const loginSettings = {
    title: 'Ustawienia logowania i rejestracji',
    labels: {
        content: 'Treść',
        backgroundColor: 'Kolor tła',
        fontColor: 'Kolor czcionki'
    },
    buttons: {
        save: 'Zapisz zmiany',
        select: 'Wybierz'
    }
};

export default loginSettings;