import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { initializeLocale } from './redux/methods/locale';

import { IntlProvider } from 'react-intl';
import { languageObject } from './translations';

const propTypes = {
    defaultLanguage: PropTypes.oneOf([ ...Object.keys(languageObject) ]).isRequired,
    chosenLanguage: PropTypes.oneOf([ ...Object.keys(languageObject) ]).isRequired,
    isFetched: PropTypes.bool,
    availableLanguages: PropTypes.array,
    children: PropTypes.node,
    initializeLocale: PropTypes.func.isRequired
};

const IntlWrapper = ({ defaultLanguage, chosenLanguage, isFetched, availableLanguages, children, initializeLocale }) => {

    useEffect(() => {
        const availableLanguageNames = availableLanguages.map(lang => lang.iso);
        const browserLanguage = navigator.language.split('-')[0];

        initializeLocale(availableLanguageNames, browserLanguage, defaultLanguage, chosenLanguage);
    }, []); // eslint-disable-line

    return isFetched? (
        <IntlProvider locale={chosenLanguage} messages={languageObject[chosenLanguage]}>
            {children}
        </IntlProvider>
    ) : null;
};

IntlWrapper.propTypes = propTypes;

const mapStateToProps = state => ({
    defaultLanguage: state.locale.defaultLanguage,
    chosenLanguage: state.persisted.persistedLocale.persistedChosenLanguage,
    isFetched: state.locale.isFetched,
    availableLanguages: state.locale.availableLanguages
});

const mapDispatch = {
    initializeLocale
};

export default connect(mapStateToProps, mapDispatch)(IntlWrapper);