const synchronizations = {
    titles: {
        main: '[EN] Synchronizacje',
        erp: '[EN] Erp',
        settings: '[EN] Ustawienia',
        authentication: '[EN] Autentykacja',
    },
    erp: {
        wapro: '[EN] WAPRO Mag',
        modal: {
            header: '[EN] Włączanie synchronizacji',
            body: '[EN] Włączenie synchronizacji z ERP usunie twoje dotychczasowe zmiany. Czy chcesz kontynuować?',
            confirm: '[EN] Włącz',
            cancel: '[EN] Anuluj',
        }
    },
    settings: {
        labelsCategories: {
            main: '[EN] Synchronizacja kategorii',
            false: '[EN] Kategorie z ERP',
            true: '[EN] Kategorie sklepowe',
        },
        labelsUnits: {
            main: '[EN] Jednostki i miary',
            false: '[EN] Jednostki z ERP',
            true: '[EN] Jednostki Sklepowe',
        },
        unitPresentation: '[EN] Typ jednostki prezentowanej w sklepie',
        labelsCategoriesLocation: {
            main: '[EN] Synchronizacja położenia kategorii',
            false: '[EN] Dla nowych kategorii',
            true: '[EN] Zawsze',
            tooltip: '[EN] Przy ustawieniu "Zawsze", synchronizowana jest zmiana położenia kategorii w węźle i pomiędzy węzłami dla nowych i obecnych kategorii. W przeciwnym razie położenie aktualnych kategorii nie ulega zmianie przy synchronizacji.'
        },
        unitPresentationTypes: {
            storage: '[EN] Magazynowania',
            sales: '[EN] Sprzedaży',
            reference: '[EN] Referencyjna'
        },
        labelsPromotions: {
            main: '[EN] Używane promocje',
            true: '[EN] Promocje z WAPRO',
            false: '[EN] Promocje sklepowe',
        },
        reserveItemsOnOrders: '[EN] Rezerwuj pozycje na zamówieniach',
        addFlatCategoriesToDefaultCategory: '[EN] Dodawaj kategorie płaskie do domyślnej kategorii'
    },
    authentication: {
        synchroApiToken: '[EN] Token do synchronizacji z API',
        shopId: '[EN] Id sklepu',
        generate: '[EN] Generuj nowy token',
    },
};

export default synchronizations;