import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';

const propTypes = {
    headerDef: PropTypes.object,
    columnsDef: PropTypes.arrayOf(PropTypes.object),
    refreshPreview: PropTypes.number
};

const EmailTemplatesTablesPreviewTable = ({ headerDef, columnsDef, refresh, storeCurrentLanguage }) => {
    const generateStyle = def => {
        let style = 'padding: 5px; border: 1px solid black;';

        switch(def.horizontalAlignment) {
            case 'Left':
                style += 'text-align: left;';
                break;
            case 'Right':
                style += 'text-align: right;';
                break;
            default:
                style += 'text-align: center;';
                break;
        }

        switch(def.verticalAlignment) {
            case 'Top':
                style += 'vertical-align: top;';
                break;
            case 'Bottom':
                style += 'vertical-align: bottom;';
                break;
            default:
                style += 'vertical-align: middle;';
                break;
        }

        style += `font-size: ${def.fontSize}px;`;
        style += `font-family: ${def.font};`;
        style += `color: ${def.fontColor};`;
        style += `background-color: ${def.backgroundColor};`;
        style += def.isBold ? 'font-weight: bold;' : 'font-weight: normal;';
        style += def.isItalic ? 'font-style: italic;' : 'font-style: normal;';
        style += def.isUnderline ? 'text-decoration: underline;' : 'text-decoration: none;';

        return style;
    };

    let html = '';

    html += `<table key="${refresh}" style="border: 1px solid black; border-collapse: collapse; border-spacing: 0;">`;
    html += '<thead>';
    html += '<tr>';

    columnsDef.filter(c => c.visible).forEach(item => {
        const displayName = item.displayName?.find(dn => dn.iso.toLowerCase() === storeCurrentLanguage)?.value ?? item.name;
        html += `<th style="${generateStyle(headerDef)}">${displayName}</th>`;
    });

    html += '</tr>';
    html += '</thead>';
    html += '<tbody>';
    html += '<tr>';

    columnsDef.filter(c => c.visible).forEach(item => {
        const displayName = item.displayName?.find(dn => dn.iso.toLowerCase() === storeCurrentLanguage)?.value ?? item.name;
        html += `<td style="${generateStyle(item)}">${displayName}</td>`;
    });

    html += '</tr>';
    html += '</tbody>';
    html += '</table>';

    return (
        <div style={{minHeight: '110px'}} dangerouslySetInnerHTML={{__html: html}} />
    );
};

EmailTemplatesTablesPreviewTable.propTypes = propTypes;

const mapStateToProps = (state) => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
};

export default connect(mapStateToProps, mapDispatch)(EmailTemplatesTablesPreviewTable);