const dashboard = {
    general: {
        title: 'Panel handlu',
        chartSection: {
            labels: {
                numberOfVisits: 'Ilość odwiedzin',
                searchByUserTime: 'Czas użytkownika' ,
                searchByAdminTime: 'Czas administratora',
            },
            dateRangeTypes: {
                day: 'Dzień',
                month: 'Miesiąc',
                year: 'Rok',
                custom: 'Zakres'
            },
            incorrectDatesAlert: 'Ustawione daty są nieprawidłowe',
            emptyData: 'Brak danych dla wybranego zakresu.'
        },
        statsSection: {
            labels: {
                searchTerms: 'Wyszukiwane frazy',
                productSearch: 'Najczęściej odwiedzane',
                lastPhrases: 'Ostatnio wyszukiwane',
            },
            headers: {
                phrase: 'Fraza',
                date: 'Data',
                numberOfSearches: 'Ilość wyszukiwań',
                name: 'Nazwa',
                visitNumber: 'Ilość odwiedzin',
                catalogueIndex: 'Indeks katalogowy',
                dateFrom: 'Data od',
                dateTo: 'Data do',
                minSearches: 'Minimalna ilość wyszukań'
            },
            buttons: {
                reset: 'RESETUJ',
                submit: 'SZUKAJ'
            },
        }
    },
    clients: {
        title: 'Panel handlu / klienci',
        table: {
            headers: {
                login: 'Login',
                name: 'Imię',
                surname: 'Nazwisko',
                contractorInErp: 'Kontrahent w Erp',
                accountType: 'Typ konta',
                isActive: 'Aktywny',
                registerDate: 'Data rejestracji',
                actions: 'Akcje',
            },
            emptyTable: 'Brak klientów',
            tooltips: {
                edit: 'Edycja',
                remove: 'Usuwanie',
            },
            deleteModal: {
                header: 'Usuwanie użytkownika',
                body: 'Czy na pewno chce usunąć użytkownika?',
                confirm: 'USUŃ',
                cancel: 'ANULUJ',
            }
        },
        searchForm: {
            hasAccount: 'Posiada konto',
            login: 'Login',
            name: 'Imię',
            surname: 'Nazwisko',
            contractorInErp: 'Kontrahent w Erp',
            email: 'Email',
            isActive: 'Aktywny',
            action: 'SZUKAJ',
            reset: 'RESETUJ',
            true: 'Tak',
            false: 'Nie',
            null: 'Dowolnie',
        },
        edit: {
            title: 'Dane użytkownika',
            contractor: 'Dane kontrahenta',
            nip: 'Nip',
            companyName: 'Nazwa firmy',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            role: 'Rola',
            contact: 'Dane kontaktowe',
            firstNameAndLastName: 'Imię i nazwisko',
            phoneNumber: 'Telefon',
            address: 'Adres',
            place: 'Miejsce dostawy',
            zipCode: 'Kod pocztowy',
            city: 'Miasto',
            recipient: 'Odbiorca',
            active: 'Aktywny',
            generatePwd: 'Wygeneruj nowe hasło',
            emailPlaceholder: 'Nowy email',
            email: 'Zmień email',
            confirmation: 'Wysłano maila!',
            savePermissisons: 'Zapisz uprawnienia',
            changeRole: 'Zmień rolę',
            inputs: {
                name: 'Imię',
                surname: 'Nazwisko',
                companyName: 'Nazwa firmy',
                nip: 'NIP',
                email: 'Email',
                password: 'Hasło',
                passwordRepeated: 'Powtórz hasło'
            }
        },
        actions: {
            addUser: 'Dodaj użytkownika'
        }
    },
    orders: {
        title: 'Panel handlu / zamówienia'
    },
    parcels: {
        title: 'Panel handlu / paczki'
    },
    stats: {
        title: 'Panel handlu / statystyki',
        chartSection: {
            labels: {
                numberOfVisits: 'Ilość odwiedzin',
                searchByUserTime: 'Czas użytkownika' ,
                searchByAdminTime: 'Czas administratora',
            },
            dateRangeTypes: {
                day: 'Dzień',
                month: 'Miesiąc',
                year: 'Rok',
                custom: 'Zakres'
            },
            incorrectDatesAlert: 'Ustawione daty są nieprawidłowe',
            emptyData: 'Brak danych dla wybranego zakresu.'
        },
        statsSection: {
            labels: {
                searchTerms: 'Wyszukiwane frazy',
                productSearch: 'Najczęściej odwiedzane',
                lastPhrases: 'Ostatnio wyszukiwane',
            },
            headers: {
                phrase: 'Fraza',
                date: 'Data',
                numberOfSearches: 'Ilość wyszukiwań',
                name: 'Nazwa',
                visitNumber: 'Ilość odwiedzin',
                catalogueIndex: 'Indeks katalogowy',
                dateFrom: 'Data od',
                dateTo: 'Data do',
                minSearches: 'Minimalna ilość wyszukań'
            },
            buttons: {
                reset: 'RESETUJ',
                submit: 'SZUKAJ'
            },
        }
    },
};

export default dashboard;