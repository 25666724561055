const translations = {
    header: 'Tłumaczenia',
    buttons: {
        save: 'ZAPISZ ZMIANY',
        clear: 'Wyczyść',
        search: 'Wyszukaj',
    },
    modalHeader: 'Edycja',
};

export default translations;