import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getUsers } from '../../../redux/methods/users';
import useFetch from '../../../hooks/useFetch';

import Assigner from '../../../components/Assigner';

import { usersSearchConfig } from '../../../config/searchConfigs';

const propTypes = {
    getUsers: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onConfirm: PropTypes.func,
    isLoading: PropTypes.bool,
};

const UserAssigner = ({ getUsers, isOpen, setIsOpen, onConfirm, isLoading }) => {
    const intl = useIntl();
    const translations = intl.messages.users.assigner;

    const [ users ] = useFetch(getUsers, usersSearchConfig);

    const mapList = list => list.map(({id, email}) => ({id: id, label: email}));

    return users && users.length > 0 ? (
        <Assigner
            lists={{
                left: mapList(users),
                right: [],
            }}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onConfirm={onConfirm}
            isLoading={isLoading}
            translations={{
                header: translations.header,
                buttons: {
                    cancel: {
                        color: 'danger',
                        label: translations.buttons.cancel.label
                    },
                    confirm: {
                        color: 'success',
                        label: translations.buttons.confirm.label
                    },
                },
                toAssign: translations.toAssign,
                assigned: translations.assigned,
            }}
        />
    ) : null;
};

UserAssigner.propTypes = propTypes;

const mapStateToProps = () => ({

});

const mapDispatch = {
    getUsers,
};

export default connect(mapStateToProps, mapDispatch)(UserAssigner);
