import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { connect } from 'react-redux';

import { Alert } from 'reactstrap';

import { removeUpperMenuSection, updateUpperMenuSectionLocation, updateUpperMenuSectionStatus } from '../../../redux/methods/menu';

import SortableTree from 'react-sortable-tree';
import Modal from '../../../components/modals/Modal';
import TableActionCell from '../../../components/tables/TableActionCell';
import TooltipWrapper from '../../../components/TooltipWrapper';

import { menuSectionPropTypes } from '../../../propTypes';

import theme from '../../../reactSortableTreeTheme';
import styles from './index.module.scss';

const propTypes = {
    updateUpperMenuSectionLocation: PropTypes.func,
    translations: PropTypes.object,
    editAction: PropTypes.func,
    menuTree: PropTypes.arrayOf(menuSectionPropTypes),
    removeUpperMenuSection: PropTypes.func,
    updateUpperMenuSectionStatus: PropTypes.func,
    history: PropTypes.object,
    title: PropTypes.string,
    isFooterTree: PropTypes.bool,
};

const MenuSectionsTreeDND = ({
    updateUpperMenuSectionLocation,
    translations,
    editAction,
    menuTree,
    removeUpperMenuSection,
    updateUpperMenuSectionStatus,
    history,
    isFooterTree,
}) => {

    const [ localTree, setLocalTree ] = useState([]);
    const [ openTooltipId, setOpenTooltipId ] = useState(null);
    const [ openSwitchTooltipId, setOpenSwitchTooltipId ] = useState(null);
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);
    const [ sectionToActivationConfirm, setSectionToActivationConfirm ] = useState(null);
    const [ activeUpperMenus, setActiveUpperMenus ] = useState(0);
    const [ isActivationDisabled, setIsActivationDisabled ] = useState(false);

    useEffect(() => {
        if(menuTree) {
            setActiveUpperMenus(menuTree.filter(({ statusTop }) => statusTop === 1).length);
        }

        if(activeUpperMenus >= 10) {
            setIsActivationDisabled(true);
        }
        else {
            setIsActivationDisabled(false);
        }
    }, [ menuTree, activeUpperMenus ]);

    useEffect(() => {
        const sortedArray = menuTree.sort((a, b) => a[isFooterTree ? 'orderIndexBottom' : 'orderIndexTop'] - b[isFooterTree ? 'orderIndexBottom' : 'orderIndexTop']);
        setLocalTree(sortedArray);
    }, [ menuTree, isFooterTree ]);

    const handleCreateSubSection = (id) => () => {
        history.push(editAction(`-${id}`));
    };

    const handleRemoveSection = sectionId => () => {
        removeUpperMenuSection(sectionId);
    };

    const handleOnChange = treeData => {
        setLocalTree(treeData);
    };

    const handleOnMoveNode = moveObject => {
        const selectedSectionId = moveObject.node.id;
        const targetSectionId = moveObject.nextParentNode ? moveObject.nextParentNode.id : null;
        const targetOrderIndex = targetSectionId
            ? moveObject.nextParentNode.children.findIndex(node => node.id === selectedSectionId)
            : moveObject.treeData.findIndex(node => node.id === selectedSectionId)
        ;
        updateUpperMenuSectionLocation(
            isFooterTree
                ?{
                    id: selectedSectionId,
                    parentId: targetSectionId,
                    orderIndexBottom: targetOrderIndex,
                    orderIndexTop: moveObject.node.orderIndexTop
                }
                :{
                    id: selectedSectionId,
                    parentId: targetSectionId,
                    orderIndexTop: targetOrderIndex,
                    orderIndexBottom: moveObject.node.orderIndexBottom
                }
        );
    };

    const toggleOpenTooltipId = id => isOpen => {
        setOpenTooltipId(isOpen ? id : null);
    };

    const toogleOpenSwitchTooltipId = id => isOpen => {
        setOpenSwitchTooltipId(isOpen ? id : null);
    };

    const handleActiveSwitch = (section) => () => {
        const statusToSend = section[isFooterTree ? 'statusBottom' : 'statusTop'] === 0 ? 1 : 0;

        if(isActivationDisabled && !isFooterTree && statusToSend === 1) {
            return null;
        }
        else if(section.status === 0 && section.children.length > 0) {
            setSectionToActivationConfirm(section);
            setIsWarningModalOpen(true);
        }
        else {
            updateUpperMenuSectionStatus(
                isFooterTree ? { menuId: section.id, statusBottom: statusToSend, statusTop: section.statusTop } : { menuId: section.id, statusTop: statusToSend, statusBottom: section.statusBottom }
            );
        }
    };

    const handleActivationConfirm = () => {
        updateUpperMenuSectionStatus(
            isFooterTree ? { menuId: sectionToActivationConfirm.id, statusBottom: 1} : { menuId: sectionToActivationConfirm.id, statusTop: 1}
        );
        setSectionToActivationConfirm(null);
        setIsWarningModalOpen(false);
    };

    const generateNodeProps = ({ node }) => {
        const isActive = node[isFooterTree ? 'statusBottom' : 'statusTop'] === 1;

        return {
            title: (
                <span className={clsx(styles.node, !isActive && styles.inactive)}>
                    <span className={clsx(!node.name && 'text-danger')}>{node.name || translations.nameFiller}</span>
                    <div className='d-flex align-items-center pr-2'>
                        <label className="switch switch-pill switch-success m-0">
                            <input
                                type="checkbox"
                                className="switch-input"
                                checked={isActive}
                                onChange={handleActiveSwitch(node)}
                                disabled={node.depth !== 0 && node.parentStatus === 0}
                            />
                            <TooltipWrapper
                                placement='right'
                                isOpen={openSwitchTooltipId === node.id}
                                setIsOpen={toogleOpenSwitchTooltipId(node.id)}
                                target={`SectionStatusSlider-${node.id}`}
                                tooltipContent={translations.sectionStatusTooltip}
                            >
                                <span
                                    id={`SectionStatusSlider-${node.id}`}
                                    className={clsx("switch-slider", !isActive &&  "bg-danger border-danger")}
                                />
                            </TooltipWrapper>
                        </label>
                        <TableActionCell
                            itemId={node.id}
                            actions={{
                                delete: {
                                    modalTranslations: translations.deleteModal,
                                    tooltipTranslation: translations.actions.delete,
                                    action: handleRemoveSection(node.id)
                                },
                                edit: {
                                    tooltipTranslation: translations.actions.edit,
                                    editLink: editAction(node.id),
                                }
                            }}
                        />
                    </div>
                    <TooltipWrapper
                        placement="top"
                        target={`dndAddSubSection-${node.id}`}
                        tooltipContent={translations.actions.addSub}
                        isOpen={openTooltipId === node.id}
                        setIsOpen={toggleOpenTooltipId(node.id)}
                    >
                        <button
                            type="button"
                            className={clsx(styles.nodeButton, !node.isContainer && 'd-none')}
                            onClick={handleCreateSubSection(node.id)}
                            id={`dndAddSubSection-${node.id}`}
                        >
                            <i className={clsx('fa fa-plus', styles.nodeButtonIcon)}/>
                        </button>
                    </TooltipWrapper>
                </span>
            ),
        };
    };

    return menuTree ? (
        <>
            <Alert
                color='danger'
                isOpen={!isFooterTree && isActivationDisabled}>
                {translations.activePositionsExceededAlert}
            </Alert>
            <div className={styles.container}>
                <SortableTree
                    treeData={localTree}
                    onChange={handleOnChange}
                    onMoveNode={handleOnMoveNode}
                    generateNodeProps={generateNodeProps}
                    theme={theme}
                />
                <Modal
                    isOpen={isWarningModalOpen}
                    setIsOpen={setIsWarningModalOpen}
                    header={translations.warningModal.header}
                    body={translations.warningModal.body}
                    buttons={{
                        confirm: {
                            label: translations.warningModal.confirm,
                            color: 'primary'
                        },
                        cancel: {
                            label: translations.warningModal.cancel,
                            color: 'secondary'
                        }
                    }}
                    onConfirm={handleActivationConfirm}
                />
            </div>
        </>
    ) : null;
};

MenuSectionsTreeDND.propTypes = propTypes;

const mapDispatch = {
    removeUpperMenuSection,
    updateUpperMenuSectionLocation,
    updateUpperMenuSectionStatus,
};

export default withRouter(connect(null, mapDispatch)(MenuSectionsTreeDND));