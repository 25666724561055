import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    history: PropTypes.object,
    serversList: PropTypes.arrayOf(Object),
    onDeleteAction: PropTypes.func,
    onUpdateIsDefaultAction: PropTypes.func,
};

const SmtpServersTable = ({
    history,
    serversList,
    headers,
    translations,
    onDeleteAction,
    onUpdateIsDefaultAction
}) => {
    const editAction = id => () => {
        history.push(`/emailSettings/servers/edit?accountId=${id}`);
    };

    const deleteAction = id => () => {
        onDeleteAction(id);
    };

    const updateIsDefault = (id, isDefault) => {
        onUpdateIsDefaultAction(id, isDefault);
    };

    return (
        <TableGenerator
            items={serversList}
            translations={translations}
            actions={{deleteAction, editAction}}
            expectedActions={['edit', 'delete']}
            customIdSelector='id'
            columns={[
                {
                    header: headers.senderdisplayname,
                    value: 'senderDisplayName'
                },
                {
                    header: headers.senderemail,
                    value: 'senderEmail'
                },
                {
                    header: headers.isdefault,
                    type: 'switchWithAction',
                    onSwitch: updateIsDefault,
                    value: 'isDefault',
                    fromTrueToFalseEnabled: false
                },
                {
                    header: headers.host,
                    value: 'host'
                },
                {
                    header: headers.port,
                    value: 'port'
                },
                {
                    header: headers.useimap,
                    value: 'useImap'
                },
                {
                    header: headers.imapport,
                    value: 'imapPort'
                },
                {
                    header: headers.securesocketoption,
                    value: 'secureSocketOption'
                },
                {
                    header: headers.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

SmtpServersTable.propTypes = propTypes;

export default withRouter(SmtpServersTable);