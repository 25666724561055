const notifications = {
    title: 'Powiadomienia',
    table: {
        headers: {
            index: 'Lp.',
            date: 'Data',
            moduleName: 'Moduł',
            errorName: 'Typ',
            description: 'Opis',
            details: 'Szczegóły',
            actions: 'Akcje'
        },
        deleteModal: {
            header: 'Powiadomienie',
            body: 'Czy chcesz usunąć powiadomienie?',
            cancel: 'WRÓĆ',
            confirm: 'USUŃ'
        },
        tooltips: {
            remove: 'Usuń',
        },
        emptyTable: 'Brak powiadomień',
        actions: {
            ignore: 'Usuń zaznaczone',
        }
    },
    buttons: {
        search: 'SZUKAJ',
        reset: 'RESETUJ',
    },
    dropdowns: {
        type: 'Typ',
        module: 'Moduł',
        defaultDropdownOption: 'Wybierz'
    },
    inputs: {
        details: 'Szczegóły',
        module: 'Moduł',
        type: 'Typ',
        onlyIsError: 'Pokaż tylko błędy'
    },
    headerController: {
        unreadNotes: 'Nieprzeczytane powiadomienia: ',
        displayAll: 'Pokaż wszystkie',
        modalHeader: 'Szczegóły powiadomienia',
        buttons: {
            check: 'SPRAWDŹ',
            cancel: 'WRÓĆ',
        }
    },
};

export default notifications;