import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';

const propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.number
    ),
    performSelect: PropTypes.func,
    className: PropTypes.string,
    currentPageSize: PropTypes.number,
};

const PageSizeChanger = ({ options=[10, 25, 50, 100], performSelect, className, currentPageSize }) => {
    const handleSelect = (option) => () => {
        performSelect(option);
    };

    return (
        <ButtonGroup size='sm' className={className}>
            {
                options.map(option =>
                    <Button
                        key={`${option}--pageSize`}
                        onClick={handleSelect(option)}
                        outline={currentPageSize !== option ? true : false}
                        color='primary'
                    >
                        {option}
                    </Button>
                )
            }
        </ButtonGroup>
    );
};

PageSizeChanger.propTypes = propTypes;

export default PageSizeChanger;