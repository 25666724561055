import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import PersonalDataSection from '../../features/accountSettings/PersonalDataSection';
import PasswordsSection from '../../features/accountSettings/PasswordsSection';
import EmailSection from '../../features/accountSettings/EmailSection';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';

const propTypes = {
    location: PropTypes.object,
    storeCurrentLanguage: PropTypes.string,
    forceRefetch: PropTypes.bool,
    isLoading: PropTypes.bool,
    getExchangeRate: PropTypes.func
};

const ExchangeRatesEdit = () => {
    const intl = useIntl();
    const translations = intl.messages.accountSettings;

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'accoutnSettings-personalData':
                return <PersonalDataSection translations={translations.personalDataTab} />;
            case 'accoutnSettings-passwords':
                return <PasswordsSection translations={translations.passwordsTab} />;
            case 'accoutnSettings-email':
                return <EmailSection translations={translations.emailTab} />;
            default:
                return null;
        }
    };

    return (
        <SettingsTabulator
            title={{
                iconClassName: null,
            }}
            tabulatorHeader={{
                label: translations.title,
                iconClassName: 'fa fa-align-justify'
            }}
            refreshTabComponent={refreshTabComponent}
            tabs={[
                {
                    id: 'accoutnSettings-personalData',
                    header:  {
                        label: translations.personalDataTab.title,
                        iconClassName: 'fa fa-tasks'
                    }
                },
                {
                    id: 'accoutnSettings-passwords',
                    header:  {
                        label: translations.passwordsTab.title,
                        iconClassName: 'fa fa-key'
                    }
                },
                {
                    id: 'accoutnSettings-email',
                    header:  {
                        label: translations.emailTab.title,
                        iconClassName: 'fa fa-envelope'
                    }
                },
            ]}
        />
    );
};

ExchangeRatesEdit.propTypes = propTypes;

const mapStateToProps = () => ({

});

const mapDispatch = {

};

export default connect(mapStateToProps, mapDispatch)(ExchangeRatesEdit);