import { useState } from 'react';

const useSearchConfig = (searchConfig, setSearchConfig) => {
    const [ config, setConfig ] = useState(searchConfig);

    const changeConfig = data => {
        setConfig({...config, ...data });

        if (typeof(setSearchConfig) === 'function') {
            setSearchConfig({...config, ...data });
        }
    };

    const changePage = page => {
        setConfig({...config, curentPage: page });

        if (typeof(setSearchConfig) === 'function') {
            setSearchConfig({...config, curentPage: page });
        }
    };

    return [ config, changePage, changeConfig ];
};

export default useSearchConfig;