import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { generateAgreementsStatuses, pickStatusColor } from '../../../utils/agreements';

import { agreementStatusEnum } from '../../../enums/agreements';

import TableGenerator from '../../generators/TableGenerator';
import DefaultSpinner from '../../loaders/DefaultSpinner';

const propTypes = {
    agreements: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.number,
        modifiedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        comment: PropTypes.string,
    })),
    handleShow: PropTypes.func,
    isLoading: PropTypes.bool,
};

const UserAgreementsTable = ({ agreements, isLoading, showCommentModal, setIsWarningOpen }) => {
    const intl = useIntl();
    const translations = intl.messages.agreements.register.modal.table;

    const handleChangeStatus = id => () => {
        const foundAgreement = agreements.find(agr => agr.id === id);

        if(foundAgreement && foundAgreement.status === 0) {
            showCommentModal(id);
        } else {
            setIsWarningOpen(true);
        }
    };

    const status = generateAgreementsStatuses(agreementStatusEnum, translations.statuses, pickStatusColor);

    return !isLoading ? (
        <TableGenerator
            items={agreements}
            defaultAction={handleChangeStatus}
            translations={{
                emptyTable: translations.emptyTable,
            }}
            columns={[
                {
                    header: translations.headerLabels.name,
                    value: 'name',
                },
                {
                    header: translations.headerLabels.status,
                    value: 'status',
                    type: 'badge',
                    config: status,
                },
                {
                    header: translations.headerLabels.modifiedDate,
                    value: 'modifiedDate',
                    type: 'date'
                },
                {
                    header: translations.headerLabels.comment,
                    value: 'comment',
                },
            ]}
        />
    ) : <DefaultSpinner /> ;
};

UserAgreementsTable.propTypes = propTypes;

export default UserAgreementsTable;