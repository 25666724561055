import React from 'react';
import PropTypes from 'prop-types';

import TableGenerator from '../../generators/TableGenerator';

import { groupPropTypes, stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    groups: PropTypes.arrayOf(groupPropTypes),
    translations: PropTypes.shape({
        deleteModalText: PropTypes.shape({
            header: PropTypes.string,
            body: PropTypes.string,
            confirm: PropTypes.string,
            cancel: PropTypes.string,
        }).isRequired,
        tooltipText: PropTypes.shape({
            delete: PropTypes.string,
        }).isRequired,
        emptyTable: PropTypes.string,
    }),

    headers: PropTypes.shape({
        email: PropTypes.string,
        actions: PropTypes.string,
    }),
    removeAction: PropTypes.func,
    withCheckbox: PropTypes.bool,
    setCheckedCheckboxIds: PropTypes.func,
    checkedCheckboxIds: PropTypes.arrayOf(stringOrNumberPropTypes),
    isGreenOnHover: PropTypes.bool,
    toggleCheckboxes: PropTypes.func,
};

const GroupsTable = ({
    groups, headers, removeAction, translations,
    setCheckedCheckboxIds, withCheckbox = false, checkedCheckboxIds, isGreenOnHover,
}) => {
    const deleteAction = id => () => {
        removeAction(id);
    };

    return (
        <TableGenerator
            items={groups}
            actions={{deleteAction}}
            additionalRowStyle={isGreenOnHover && styles.greenBackground}
            checkedItemsIds={checkedCheckboxIds}
            setCheckedItemsIds={setCheckedCheckboxIds}
            translations={{
                ...translations,
                tooltips: {
                    remove: translations.groupTable.tooltipText
                },
                deleteModal: translations.deleteModalGroup,
                emptyTable: translations.groupTable.emptyTable
            }}
            columns={[
                withCheckbox && {type: 'checkbox'},
                {
                    header: headers.name,
                    value: 'name'
                },
                {
                    header: headers.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

GroupsTable.propTypes = propTypes;

export default GroupsTable;