import React from 'react';
import PropTypes from 'prop-types';
import CategoryTree from '../CategoryTree';
import styles from './index.module.scss';

const CategoryTreeItem = ({member, handleExpand, handleSelect}) => {

    const hasChildren = member => member.children && member.children.length > 0;

    const handleExpandMember = e => {
        e.stopPropagation();
        handleExpand(member.id);
    };

    const handleSelectMember = e => {
        e.stopPropagation();
        handleSelect(member.id);
    };

    return (
            <div>
                <div id={`categoryTreeItem-${member.id}`} onClick={handleSelectMember} className={member.selected ? styles.categoryTreeItemBoxSelected : styles.categoryTreeItemBox}>
                    <div style={{marginLeft: (member.level * 12)+'px'}}>
                        {hasChildren(member) &&
                            <span onClick={handleExpandMember} className={styles.expandLink}>
                                {
                                    member.expanded
                                        ? (<i className="nav-icon icon-minus"></i>)
                                        : (<i className="nav-icon icon-plus"></i>)
                                }
                            </span>
                        }
                        {!hasChildren(member) &&
                            <span className={styles.expandFiller}/>
                        }
                        <span>
                            {member.name}
                            {member.selected ? (<small>{member.path}</small>) : null}
                        </span>
                    </div>
                </div>
                {hasChildren(member) && member.expanded &&
                    <CategoryTree members={member.children} handleExpand={handleExpand} handleSelect={handleSelect} />
                }
            </div>
    );
};

CategoryTreeItem.propTypes = {
    member: PropTypes.object,
    handleExpand: PropTypes.func,
    handleSelect: PropTypes.func
};

export default CategoryTreeItem;