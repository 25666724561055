import React from 'react';
import PropTypes from 'prop-types';

import useFetch from '../../../../../hooks/useFetch';
import useSearchConfig from '../../../../../hooks/useSearchConfig';

import PhraseSearchTable from '../../../../../components/tables/PhraseSearchTable';
import Paginator from '../../../../../components/Paginator';
import PhraseStatsSearchForm from '../../PhraseStatsSearchForm';

import { formUtilsHocPropTypes } from '../../../../../propTypes';

const propTypes = {
    translations: PropTypes.object,
    utils: formUtilsHocPropTypes,
    fetchMethod: PropTypes.func,
    config: PropTypes.object,
    isSearchNumberTable: PropTypes.bool,
};

const PhraseStatsSubSection = ({
    isSearchNumberTable,
    translations,
    config,
    fetchMethod,
    utils,
}) => {
    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(config);
    const result = useFetch(fetchMethod, searchConfig, [ searchConfig ]);

    const pages = result[1];
    const performSearch = form => {
        changeSearchConfig(
            {
                ...searchConfig,
                ...form,
            }
        );
    };

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    return result[0] ? (
        <div>
            <PhraseStatsSearchForm
                searchConfig={searchConfig}
                isSearchNumberTable={isSearchNumberTable}
                handleSubmit={performSearch}
                translations={translations}
                currentLanguage={utils.currentLanguage()}
            />
            <PhraseSearchTable
                performPageSizeSelect={handleChangePageSize}
                pageSize={searchConfig.pageSize}
                currentPage={searchConfig.curentPage + 1}
                isSearchNumberTable={isSearchNumberTable}
                translations={translations}
                items={result[0]}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={searchConfig.curentPage + 1}
                        onPageChange={changePage}
                    />
            }
        </div>
    ) : null;
};

PhraseStatsSubSection.propTypes = propTypes;

export default PhraseStatsSubSection;