import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCurrencies } from '../../../redux/methods/currencies';
import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';
import useFetch from '../../../hooks/useFetch';

import { generateDropdownOptions, generateDropdownOptionsForCurrencies, generateDropdownOptionsForStatus } from '../../../utils/discounts';

import SearchForm from '../../../components/generators/SearchForm';

import { discountTypeEnum, discountStatusEnum, discountRangeEnum } from '../../../enums/discounts';

const propTypes = {
    storeCurrentLanguage: PropTypes.string,
    getCurrencies: PropTypes.func,
    changeSearchConfig: PropTypes.func,
    searchConfig: PropTypes.object.isRequired,
};

const DiscountsSearchForm = ({ storeCurrentLanguage, getCurrencies, changeSearchConfig, searchConfig }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.searchForm;

    const [ currencies ] = useFetch(getCurrencies);

    return currencies ? (
        <SearchForm
            searchConfig={searchConfig}
            translations={{
                buttons: {
                    submit: translations.action,
                    reset: translations.reset,
                }
            }}
            inputs={[
                {
                    label: translations.status.label,
                    name: 'discountStatusId',
                    type: 'dropdown',
                    options: generateDropdownOptionsForStatus(discountStatusEnum, translations.dropdowns.any, translations.dropdowns.status),
                    defaultLabel: translations.dropdowns.any
                },
                {
                    label: translations.name.label,
                    name: 'name',
                    type: 'text'
                },
                {
                    label: translations.code.label,
                    name: 'code',
                    type: 'text'
                },
                {
                    label: translations.validFrom.label,
                    name: 'validFrom',
                    type: 'date',
                    storeCurrentLanguage: storeCurrentLanguage,
                },
                {
                    label: translations.validTo.label,
                    name: 'validTo',
                    type: 'date',
                    storeCurrentLanguage: storeCurrentLanguage,
                    minDateDependentField: 'validFrom',
                },
                {
                    type: 'dropdown',
                    label: translations.type.label,
                    name: 'discountTypeId',
                    options: generateDropdownOptions(discountTypeEnum, translations.dropdowns.any, translations.dropdowns.type ),
                    defaultLabel: translations.dropdowns.any
                },
                {
                    type: 'dropdown',
                    label: translations.range.label,
                    name: 'discountRangeId',
                    options: generateDropdownOptions(discountRangeEnum, translations.dropdowns.any, translations.dropdowns.range),
                    defaultLabel: translations.dropdowns.any
                },
                {
                    type: 'dropdown',
                    label: translations.currencies.label,
                    name: 'currencyId',
                    options: generateDropdownOptionsForCurrencies(currencies, translations.dropdowns.any),
                    defaultLabel: translations.dropdowns.any
                },
            ]}
            performSearch={changeSearchConfig}
        />
    ) : null;
};

DiscountsSearchForm.propTypes = propTypes;

const mapStateToProps = state => ({
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    getCurrencies,
};

export default connect(mapStateToProps, mapDispatch)(DiscountsSearchForm);