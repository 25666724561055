import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import useSearchConfig from '../../../hooks/useSearchConfig';
import useAddFetch from '../../../hooks/useAddFetch';

import { addProduct } from '../../../redux/methods/products';
import { setProductsSearchConfig } from '../../../redux/methods/searchConfigs';

import { Container, Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';

import ProductSearchForm from '../../../components/ProductSearchForm';

import styles from './index.module.scss';

import { withFormUtils } from '../../../hoc/withFormUtils';
import CategoryTreeSection from '../../../features/products/categoryAssignSections/CategoryTreeSection';
import SummaryProductEditionModal from '../../../features/products/SummaryProductEditionModal';

import Table from './Table';

const propTypes = {
    history: PropTypes.object,
    getProducts: PropTypes.func,
    addProduct: PropTypes.func,
    forceRefetch: PropTypes.bool,
    storeCurrentLanguage: PropTypes.string,
    deleteProduct: PropTypes.func,
};

const ProductsList = ({
    utils,
    history,
    addProduct,
    isConnectedToERP,
    isShopCategoryActive,
    productsSearchConfig,
    setProductsSearchConfig
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.view;

    const [ isSummaryProductEditionModalOpen, setIsSummaryProductEditionModalOpen ] = useState(false);
    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(productsSearchConfig, setProductsSearchConfig);
    const [ id, add ] = useAddFetch(addProduct);

    useEffect(() => {
        if (id) {
            history.push(`/products/list/edit?productID=${id}`);
        }
    }, [history, id]);

    const handleAddProduct = id => {
        add(id);
    };

    const handleOpenSelectCategoryModal = () => {
        utils.selectCategory(
            handleAddProduct,
            translations.modal.body);
    };

    const handleStartSummaryProductAddition = () => {
        setIsSummaryProductEditionModalOpen(!isSummaryProductEditionModalOpen);
    };

    const [categoryTreeIsOpen, setCategoryTreeIsOpen] = useState(true);
    const toggleCategoryTree = () => setCategoryTreeIsOpen(!categoryTreeIsOpen);

    const showTwoTrees = isConnectedToERP && isShopCategoryActive;
    return (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className="animated fadeIn">
                <Card>
                    <CardHeader>
                        <i className={clsx('fa fa-align-justify')}></i>
                        <span>{translations.title}</span>
                    </CardHeader>
                    <CardBody className="position-relative">
                        <main>
                            <ProductSearchForm
                                performProductSearch={changeSearchConfig}
                                searchConfig={searchConfig}
                                canSearchInSubcategories
                            />
                            <Button className='mt-3 mb-0' onClick={toggleCategoryTree} color="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                                {categoryTreeIsOpen ? translations.hideCategoryTreeSelect : translations.showCategoryTreeSelect}
                            </Button>
                            <Row>
                                {categoryTreeIsOpen && (
                                    <Col xs='12' xl='3'>
                                        <div className="pt-3">
                                            <CategoryTreeSection
                                                translations={translations.categoryTree}
                                                setSearchConfig={(categoryId, categoryTypeId) => changeSearchConfig({ ...searchConfig, categoryId, categoryTypeId })}
                                                currentLanguage={utils.currentLanguage()}
                                                scroll={false}
                                                showTwoTrees={showTwoTrees}
                                                searchConfig={searchConfig}
                                            />
                                        </div>
                                    </Col>
                                )}
                                <Col xs='12' xl={categoryTreeIsOpen ? '9' : '12'}>
                                    <Table
                                        searchConfig={searchConfig}
                                        changeSearchConfig={changeSearchConfig}
                                        changePage={changePage}
                                    />
                                </Col>
                            </Row>
                        </main>
                        <aside className={styles.footer}>
                            <Button color="primary" onClick={handleStartSummaryProductAddition} className='mr-2'>
                                <i className="fa fa-plus mr-2"/>
                                <span>{translations.actions.addSummaryProduct}</span>
                            </Button>
                            <Button color="primary" onClick={handleOpenSelectCategoryModal}>
                                <i className="fa fa-plus mr-2"/>
                                <span>{translations.actions.addProduct}</span>
                            </Button>
                        </aside>
                    </CardBody>
                    <SummaryProductEditionModal
                        modalLabel={translations.summaryProductModalLabel}
                        history={history}
                        isOpen={isSummaryProductEditionModalOpen}
                        setIsOpen={setIsSummaryProductEditionModalOpen}
                    />
                </Card>
            </div>
        </Container>
    );
};

ProductsList.propTypes = propTypes;

const mapDispatch = {
    addProduct,
    setProductsSearchConfig
};

const mapStateToProps = state => ({
    isConnectedToERP: state.appInit.isConnectedToERP,
    isShopCategoryActive: state.appInit.isShopCategoryActive,
    productsSearchConfig: state.searchConfigs.products
});

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ProductsList));