import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getTranslationForIso } from '../../utils/shared';
import { getPaymentBasic } from '../../redux/methods/products';
import { selectStoreCurrentLanguage } from '../../redux/selectors/storeLocale';
import useFetch from '../../hooks/useFetch';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';

import ServiceEditBasicSection from '../../features/products/editTabs/ServiceEditBasicSection';
import ProductEditDescription from '../../features/products/editTabs/ProductEditDescription';
import ProductEditPricesSection from '../../features/products/editTabs/ProductEditPricesSection';

import { productsSearchConfigPropTypes } from '../../propTypes';

const propTypes = {
    location: PropTypes.object,
    searchConfig: productsSearchConfigPropTypes,
    isProductListPresent: PropTypes.bool,
    getPaymentBasic: PropTypes.func,
};

const PaymentsEdit = ({
    location, getPaymentBasic, forceRefetch, storeCurrentLanguage
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit;

    const query = new URLSearchParams(location.search);
    const chosenProductId = query.get('productID');

    const [ data ] = useFetch(getPaymentBasic, chosenProductId, [ chosenProductId ], forceRefetch);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case "productEditTab-basic":
                return <ServiceEditBasicSection id={chosenProductId} data={data} productType={3} />;
            case "productEditTab-description":
                return <ProductEditDescription productId={chosenProductId} />;
            case "productEditTab-prices":
                return <ProductEditPricesSection id={chosenProductId} />;
            default:
                return null;
        }
    };

    return chosenProductId
        ? (
            data ? (
                <SettingsTabulator
                    title={{
                        label: getTranslationForIso(data.name, storeCurrentLanguage),
                        iconClassName: "fa fa-edit text-primary ml-3"
                    }}
                    tabulatorHeader={{
                        label: translations.tabSelectorTitle,
                        iconClassName: "fa fa-align-justify"
                    }}
                    refreshTabComponent={refreshTabComponent}
                    tabs={[
                        {
                            id: "productEditTab-basic",
                            header:  {
                                label: translations.tabs.basic.title,
                                iconClassName: "fa fa-tasks"
                            }
                        },
                        {
                            id: "productEditTab-description",
                            header:  {
                                label: translations.tabs.description.title,
                                iconClassName: "fa fa-search"
                            }
                        },
                        {
                            id: "productEditTab-prices",
                            header:  {
                                label: translations.tabs.prices.title,
                                iconClassName: "fa fa-money"
                            }
                        },
                    ]}
                />
            ) : null
        ) : (
            <Redirect to="/paymentServices/list"/>
        );
};

PaymentsEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.payments,
    storeCurrentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
    getPaymentBasic
};

export default withRouter(connect(mapStateToProps, mapDispatch)(PaymentsEdit));