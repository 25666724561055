import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { Table, Input } from 'reactstrap';

import TableActionCell from '../TableActionCell';
import EmptyTablePlaceholder from '../EmptyTablePlaceholder';

import { userPropTypes, stringOrNumberPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    users: PropTypes.arrayOf(userPropTypes),
    deleteModalText: PropTypes.shape({
        header: PropTypes.string,
        body: PropTypes.string,
        confirm: PropTypes.string,
        cancel: PropTypes.string,
    }).isRequired,
    headers: PropTypes.shape({
        email: PropTypes.string,
        actions: PropTypes.string,
    }),
    removeAction: PropTypes.func,
    tooltipText: PropTypes.shape({
        delete: PropTypes.string,
    }).isRequired,
    emptyTable: PropTypes.string,
    withCheckbox: PropTypes.bool,
    checkboxAction: PropTypes.func,
    checkedCheckboxIds: PropTypes.arrayOf(stringOrNumberPropTypes),
    isRedOnHover: PropTypes.bool,
    toggleCheckboxes: PropTypes.func,
};

const UsersTable = ({
    users, deleteModalText, headers, removeAction, tooltipText, emptyTable,
    checkboxAction, withCheckbox = false, checkedCheckboxIds, isRedOnHover,
    toggleCheckboxes,
}) => {
    const handleRemoveAction = id => () => {
        removeAction(id);
    };

    const handleCheckboxClick = id => () => {
        checkboxAction(id);
    };

    const handleToggleCheckboxes = () => {
        toggleCheckboxes(users.length === checkedCheckboxIds.length);
    };

    return users && users.length > 0 ? (
        <Table responsive hover size='sm'>
            <thead>
                <tr>
                    {withCheckbox &&
                        <th className={clsx(styles.columnCheckbox)}>
                            <Input
                                checked={users.length === checkedCheckboxIds.length}
                                type='checkbox'
                                onChange={handleToggleCheckboxes}
                                className='position-static m-0 p-0'
                            />
                        </th>
                    }
                    <th className={clsx(styles.columnEmail)}>
                        {headers && headers.email}
                    </th>
                    <th className={clsx(styles.columnActions)}>
                        {headers && headers.actions}
                    </th>
                </tr>
            </thead>
            <tbody>
                {users.map(user => {
                    const isChecked = checkedCheckboxIds && checkedCheckboxIds.some(item => item === user.id);

                    return (
                        <tr key={`${user.id}-UsersTable`} className={clsx(isRedOnHover && styles.redBackground)}>
                            {withCheckbox &&
                                <th scope='row' className={clsx(styles.columnCheckbox, styles.cell)}>
                                    <Input
                                        checked={isChecked}
                                        id={`user-checkbox-${user.id}`}
                                        type='checkbox'
                                        onChange={handleCheckboxClick(user.id)}
                                        className='position-static m-0 p-0'
                                    />
                                </th>
                            }
                            <td className={clsx(styles.columnEmail, styles.cell)}>
                                {user.email}
                            </td>
                            <td>
                                <TableActionCell
                                    className={clsx(styles.columnActions, styles.cell)}
                                    itemId={user.id}
                                    actions={{
                                        delete: {
                                            action: handleRemoveAction(user.id),
                                            tooltipTranslation: tooltipText.delete,
                                            modalTranslations: deleteModalText,
                                        },
                                    }}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    ) : (
        <EmptyTablePlaceholder text={emptyTable}/>
    );
};

UsersTable.propTypes = propTypes;

export default UsersTable;