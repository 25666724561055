import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { handleCheckboxAction } from '../../../../../utils/components';
import { generateInitialLabelsForSelectedAttributes } from '../../../../../utils/attributes';

import Dropdown from '../../../../../components/Dropdown';
import { Container } from 'reactstrap';

import { productAttributePropTypes, stringOrNumberPropTypes, productAttributeToUpdatePropTypes, formUtilsHocPropTypes } from '../../../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    attribute: productAttributePropTypes,
    setEditedAttributes: PropTypes.func,
    discountId: stringOrNumberPropTypes,
    editedAttributes: PropTypes.arrayOf(productAttributeToUpdatePropTypes),
    utils: formUtilsHocPropTypes,
    selectAttributeTranslation: PropTypes.string,
    emptyTranslation: PropTypes.string
};

const AttributeAssigner = ({
    attribute,
    setEditedAttributes,
    discountId,
    editedAttributes,
    selectAttributeTranslation,
    emptyTranslation,
    selectedFiltersValues,
    selectedTranslation,
}) => {

    const extractSelectedValuesIds = (values) => {
        const selectedValues = values.map(value => value.selected && value.id);
        return selectedValues.filter(value => value);
    };

    const extractSelectedFiltersIds = values => {
        const adjustedValues = values.find(value => value.attributeId === attribute.attributeId) || null;
        return adjustedValues ? adjustedValues.attributeValueIds : [];
    };

    const [ selectedAttributesValuesIds, setSelectedAttributesValuesIds ] = useState(
        discountId
            ? extractSelectedValuesIds(attribute.dictionaryValues ? attribute.dictionaryValues : [])
            : extractSelectedFiltersIds(selectedFiltersValues ? selectedFiltersValues : [])
    );

    const [ currentSelectionLabel, setCurrentSelectionLabel ] = useState(generateInitialLabelsForSelectedAttributes(attribute, selectAttributeTranslation, emptyTranslation));

    const handleSelection = (id) => {
        setSelectedAttributesValuesIds(selectedAttributesValuesIds);
        handleCheckboxAction(id, selectedAttributesValuesIds, setSelectedAttributesValuesIds);
    };

    const generateSelectionLabel = () => {
        if(selectedAttributesValuesIds.length === 0) {
            setCurrentSelectionLabel(selectAttributeTranslation);
        } else if (selectedAttributesValuesIds.length === 1) {
            setCurrentSelectionLabel(attribute.dictionaryValues.find(value => value.id === selectedAttributesValuesIds[0]).name);
        } else if (selectedAttributesValuesIds.length > 1) {
            setCurrentSelectionLabel(`${selectedTranslation} ${selectedAttributesValuesIds.length} / ${attribute.dictionaryValues.length}`);
        }
    };

    useEffect(() => {
        const updatedObject = {
            attributeId: attribute.attributeId,
            dictionaryValues: selectedAttributesValuesIds
        };

        const updatedAttributes = editedAttributes.map(editedAttribute => {
            if(editedAttribute.attributeId === updatedObject.attributeId) {
                return {
                    ...editedAttribute,
                    ...updatedObject,
                };
            }
            else {
                return editedAttribute;
            }
        });

        if (editedAttributes.length > 0) {
            setEditedAttributes(updatedAttributes);
        }
        else {
            setEditedAttributes(prevState => [...prevState, updatedObject]);
        }

        generateSelectionLabel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedAttributesValuesIds]);

    return attribute ? (
        <Container className={clsx('my-3', styles.container)} fluid>
            <span className={clsx(styles.label)}>{attribute.attributeName ?? 'Producent'} <span>{attribute.attributeNameExtension}</span></span>
            <Dropdown
                withCheckboxes={true}
                withColors={false}
                isDisabled={attribute.dictionaryValues.length < 1}
                options={attribute.dictionaryValues}
                currentSelectionLabel={currentSelectionLabel}
                performSelection={handleSelection}
                checkedIds={selectedAttributesValuesIds}
            />
        </Container>
    ) : null;

};

AttributeAssigner.propTypes = propTypes;

export default AttributeAssigner;
