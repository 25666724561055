const storeLocale = {
    languageDropdownSelector: {
        label: 'Język sklepu:'
    },
    languages : {
        Pl: 'Polski',
        En: 'Angielski',
        Ru: 'Rosyjski',
        Ua: 'Ukraiński',
        De: 'Niemiecki',
        Cz: 'Czeski',
        It: 'Włoski',
        Tr: 'Turecki',
        Es: 'Hiszpański'
    }
};

export const languages = {
    Pl: 'Polski',
    En: 'Angielski',
    Ru: 'Rosyjski',
    Ua: 'Ukraiński',
    De: 'Niemiecki',
    Cz: 'Czeski',
    It: 'Włoski',
    Tr: 'Turecki',
    Es: 'Hiszpański'
};

export default storeLocale;