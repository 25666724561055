export const usersSearchConfig = {
    countRows: false,
    pageSize: 0,
    curentPage: 0,
    userId: null,
    groupId: null,
    role: null,
    email: null,
    hasAccount: null,
    contractorInErp: null,
    isActive: null,
    user: null,
    name: null,
    surname: null,
    login: null,
};

export const groupsSearchConfig = {
    countRows: false,
    pageSize: 0,
    currentPage: 0,
    groupId: null,
};

export const discountsUsersSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    discountId: null,
    excluded: null,
};

export const discountsGroupsSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    discountId: null,
};

export const exchangeRatesSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    currencyId: null,
    dateFrom: null,
    dateTo: null,
    showActive: true,
};

export const ordersSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    currencyId: null,
    dateFrom: null,
    dateTo: null,
    orderNumber: null,
    contrahentName: null,
    contrahentNumber: null,
    orderStatusId: null,
    orderByColumnName: 'Date',
    orderDirection: 'Desc',
    email: null,
    orderAttributeId: null,
};

export const discountUsagesSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    discountId: null,
    date: null,
    code: null,
    orderNumber: null,
    email: null,
    name: null,
    surname: null
};

export const discountCodesSearchConfig ={
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    discountId: null,
    unusedOnly: true,
};

export const discountRegionsSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    discountId: null
};

export const discountCountriesSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    discountId: null,
    excluded: null
};

export const discountProductsSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    discountId: null,
    excluded: null,
    productName: null,
    catalogueIndex: null,
    tradeIndex: null
};

export const discountCategoriesSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    discountId: null
};

export const discountsSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    validFrom: null,
    validTo: null,
    currencyId: null,
    discountTypeId: null,
    discountRangeId: null
};

export const productsSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    productName: null,
    catalogueIndex: null,
    tradeIndex: null,
    productId: null,
    categoryId: null,
    inSubcategories: false,
    categoryTypeId: null,
    status: null,
    ean: null,
    manufacturerId: [],
    tagId: [],
    variantId: null,
    productVariantType: null,
    onlyWithWarnings: false,
    onlyWithPromotion: false,
    orderDirection: null,
    orderByColumnName: null
};

export const otherServicesSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    productId: null,
    categoryId: null,
    categoryTypeId: null,
    productName: null,
    catalogueIndex: null,
    tradeIndex: null    ,
    status: null,
    ean: null,
    onlyWithWarnings: false,
    loadCategories: false,
    attributeId: null,
    productTypeId: 4
};

export const serviceSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    productName: '',
    productId: null,
    productTypeId: null
};

export const attributesFeaturesSearchConfig = {
    countRows: true,
    pageSize: 20,
    curentPage: 0,
    attributeName: null,
    categoryId: null,
    attributeType: null,
    attributePresentation: null,
    attributeId: null,
};

export const measuresSearchConfig = {
    countRows: true,
    pageSize: 0,
    curentPage: 0,
    measureId: null
};

export const numberOfVisitsSearchConfig = {
    searchByUserTime: false,
    dateFrom: '2020-12-11T14:00Z',
    dateTo: '2020-12-15T17:00Z',
};

export const frequentPhrasesSearchConfig = {
    minNumber: null,
    phrase: '',
    countRows: true,
    pageSize: 5,
    curentPage: 0,
};

export const lastPhrasesSearchConfig = {
    searchByUserTime: false,
    phrase: '',
    countRows: true,
    pageSize: 5,
    curentPage: 0,
    dateFrom: null,
    dateTo: null,
};

export const mostViewedProductsSearchConfig = {
    minNumber: null,
    countRows: true,
    curentPage: 0,
    pageSize: 5,
    catalogueIndex: null,
    Name: null,
    OrderDirection: 'Desc'
};

export const notificationsSearchConfig = {
    countRows: true,
    curentPage: 0,
    pageSize: 10,
    orderDirection: "Desc",
    typeId: null,
    moduleId: null,
    details: null,
    onlyIsError: null
};

export const userAgreementsSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    ip: null,
    email: null,
    onlyModified: false
};

export const mediaLibrarySearchConfig = {
    countRows: true,
    pageSize: 30,
    curentPage: 0,
    folderId: null,
    fileName: "",
    mediaType: 1
};

export const mediaLibraryFileSearchConfig = {
    fileId: null,
    folderId: null,
    fileName: null,
    mode: "Current"
};

export const manufacturersSearchConfig = {
    countRows: true,
    pageSize: 10,
    curentPage: 0,
    name: null,
    orderDirection: "Asc"
};