const visualSettings = {
    css: {
        title: 'Ustawienia CSS',
        helper: [
            '- Zmiana css na własny możliwa dopiero po wgraniu pliku CSS',
        ],
        switch: {
            labels: {
                false: 'CSS WŁASNY',
                true: 'CSS SKLEPOWY',
                main: 'Wybór Css'
            },
            tooltip: 'Aby przełączyć na css własny musisz mieć wgrany css dla wybranej skórki',
        },
        dropdown: {
            label: 'Wybór skórki',
        },
        fileSender: {
            buttons: {
                edit: 'PODMIEŃ',
                remove: 'USUŃ',
                upload: 'WGRAJ'
            },
            label: 'Własny CSS'
        },
        download: {
            own: 'POBIERZ WŁASNY CSS',
            shop: 'POBIERZ SKLEPOWY CSS',
        },
        buttons: {
            save: 'ZAPISZ ZMIANY',
            preview: 'PODGLĄD'
        }
    },
    layout: {
        buttons: {
            downloadCSS: 'POBIERZ PLIK CSS',
            uploadCSS: 'WGRAJ PLIK CSS',
            deleteCSS: 'USUŃ PLIK CSS',
            active: 'AKTYWNY',
            activate: 'AKTYWUJ',
            select: 'WYBIERZ',
            selected: 'WYBRANY',
            edit: 'EDYTUJ',
            preview: 'PODGLĄD',
            save: 'ZAPISZ ZMIANY',
            restoreColors: 'PRZYWRÓĆ USTAWIENIA POCZĄTKOWE',
            systemCSS: 'Podejrzyj ten wariant kolorystyczny z wykorzystaniem pliku css systemowego',
            userCSS: 'Podejrzyj ten wariant kolorystyczny z wykorzystaniem pliku css użytkownika'
        },
        labels: {
            systemCSSFile: 'Plik CSS skórki',
            userCSSFile: 'Plik CSS użytkownika',
            uploadedFileName: 'Nazwa pliku użytkownika'
        },
        title: 'Ustawienia skórki',
        layoutSelectionLabel: 'Aktywna skórka',
        templateSectionTitle: 'Wybór wersji kolorystycznej',
        editedLabel: '(Edytowana)',
        styleSwitchLabels: {
            main: 'Styl',
            false: 'Domyślny',
            true: 'Własny'
        },
        fallbackName: '[BRAK NAZWY]',
        tabSelectorTitle: 'Ustawienia szablonów',
        templateParamsSections: {
            colors: "Kolory",
            fonts: "Czcionki",
            fontSizes: "Wielkości czcionek"
        },
        tabs: {
            params: "Parametry"
        },
        colorController: {
            name: 'Kolor skórki',
            pickButton: 'Wybierz kolor'
        },
        fontSelector: 'Czcionka skórki'
    },
    logo: {
        title: 'Ustawienia logo',
        labels: {
            logo: 'Logo',
            favicon: 'Favicon',
            logoAlt: 'Tekst alternatywny dla logo',
            secondLogo: 'Drugie logo',
            secondLogoAlt: 'Tekst alternatywny dla drugiego logo'
        },
        fileSender: {
            buttons: {
                edit: 'PODMIEŃ',
                remove: 'USUŃ',
                upload: 'WGRAJ'
            }
        }
    },
    buttons: {
        title: 'Przyciski',
        headerLabels: {
            name: 'Nazwa',
            preview: 'Podgląd',
            actions: 'Akcje'
        },
        tooltips: {
            edit: 'Edytuj',
            remove: 'Usuń'
        },
        deleteModal: {
            header: 'Usuń przycisk',
            body: 'Czy napewno chcesz usunąć przycisk?',
            confirm: 'USUŃ',
            cancel: 'WRÓĆ'
        },
        emptyTable: 'Brak wygenerowanych przycisków',
        addButton: 'Dodaj przycisk',
        inputs: {
            name: 'Nazwa',
            fontSize: 'Rozmiar czcionki',
            fontColor: 'Kolor czcionki',
            select: 'Wybierz',
            font: 'Cziconka',
            isFontBold: 'Pogrubienie czcionki',
            iconPosition: 'Pozycja ikony',
            buttonVerticalSize: 'Wysokość przycisku',
            buttonHorizontalSize: 'Szerokość przycisku',
            borderSize: 'Szerokość obramowań',
            borderRadius: 'Zaokrąglenie obramowań',
            borderColor: 'Kolor obramowań',
            isTransparent: 'Przeźroczystość tła',
            shadowVerticalPosition: 'Położenie cienia wertykalne',
            shadowHorizontalPosition: 'Położenie cienia horyzontalne',
            shadowBlurRadius: 'Rozmycie cienia',
            shadowSpreadRadius: 'Zwiększenie rozmiaru cienia',
            onHoverAnimation: 'Animacja',
            right: 'Prawa strona',
            left: 'Lewa strona',
            previewButtonTranslation: 'Twój przycisk',
            previewBackgroundColor: 'Kolor tła podglądu',
            backgroundColor: 'Kolor tła',
            animationTypes: {
                scaleUp: 'Powiększenie',
                lightUp: 'Rozświetlenie',
                switchColors: 'Zmiana kolorów',
                roundUp: 'Zaokrąglenie',
                none: 'Brak'
            },
        },
        buttonContentInfo: 'Treść przycisku definiowana jest w sekcji, w której można dodać przycisk.',
        iconsModal: {
            label: 'Wybierz ikonę',
            confirm: 'ZAPISZ',
            cancel: 'WRÓĆ'
        }
    },
    graphicsSettings: {
        title: 'Ustawienia grafik',
        guide: 'Ustaw jakość dla poszczególnych typów grafik',
        typeHeaders: {
            tile: 'Kafelek',
            list: 'Lista artykułów',
            banner: 'Banner',
            productCard: 'Karta produktu'
        },
        inputs: {
            desktop: 'Desktop',
            tablet: 'Tablet',
            mobile: 'Mobile'
        }
    },
    placeholderImages: {
        title: 'Zdjęcia zastępcze',
        fileSender: {
            upload: 'PRZEŚLIJ',
            remove: 'USUŃ',
            edit: 'ZAMIEŃ',
        },
        labels: {
            product: 'Produkty'
        }
    }
};

export default visualSettings;