import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { assingIconToFeatureAttributeType, generateEditLinkForAttribute } from '../../../../utils/attributes';

import TableGenerator from '../../../../components/generators/TableGenerator';
import { attributeTypeEnum } from '../../../../enums/attributes';
import { translateEnumValue } from '../../../../utils/components';

import { translatedFeaturesAttributesListItemPropTypes } from '../../../../propTypes';

const propTypes = {
    translatedAttributesList: PropTypes.arrayOf(translatedFeaturesAttributesListItemPropTypes),
    removeHandler: PropTypes.func,
    history: PropTypes.object,
};

const AttributeTable = ({
    translatedAttributesList,
    removeHandler,
    history,
    currentPage,
    pageSize,
    performPageSizeSelect,
}) => {
    const intl = useIntl();
    const translations = intl.messages.attributes.table;
    const attributeTypeTranslations = intl.messages.attributes.searchForm.filters.attributeType;
    const attributePresentationTranslations = intl.messages.attributes.searchForm.filters.attributePresentation;

    const deleteAction = attributeId => () => {
        removeHandler(attributeId);
    };

    const defaultActionHandler = id => () => {
        history.push(generateEditLinkForAttribute(id));
    };

    const TypeCell = attribute => {
        return (
            <React.Fragment>
                <i className={clsx(assingIconToFeatureAttributeType(attribute.attributeType), 'mr-1')}/>
                <span>{translateEnumValue(attributeTypeEnum, attributeTypeTranslations, attribute.attributeType)}</span>
            </React.Fragment>
        );
    };

    return  (
        <TableGenerator
            performPageSizeSelect={performPageSizeSelect}
            pageSize={pageSize}
            currentPage={currentPage}
            editLink={generateEditLinkForAttribute}
            items={translatedAttributesList}
            actions={{deleteAction}}
            translations={translations}
            defaultAction={defaultActionHandler}
            expectedActions={['edit', 'delete']}
            columns={[
                {
                    header: translations.headers.name,
                    value: 'translatedName',
                    filler: translations.nameFiller,
                },
                {
                    header: translations.headers.type,
                    type: 'custom',
                    customCell: TypeCell
                },
                {
                    header: translations.headers.presentation,
                    value: 'attributePresentation',
                    customTranslation: attributePresentationTranslations,
                },
                {
                    header: translations.headers.actions,
                    type: 'actions'
                },
            ]}
        />
    );
};

AttributeTable.propTypes = propTypes;

export default AttributeTable;