import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isInitialized: false,
    isConnectedToERP: false,
    orderStatuses: [],
    orderAttributes: [],
    sessionTimeoutValue: null,
    activatedErpId: null,
    isShopCategoryActive: null,
    isShopUnitsActive: null,
    notifications: {
        types: null,
        modules: null
    },
    lastSynchronizationDate: null,
    defaultCurrencyId: null,
    erpPromotions: false,
    payUEnabled: false,
    p24Enabled: false,
    adminArticleColumns: []
};

const appInit = createSlice({
    name: 'appInit',
    initialState,
    reducers: {
        setIsInitialized(state, action) {
            const { isInitialized } = action.payload;

            state.isInitialized = isInitialized;
        },
        setIsConnectedToERP(state, action) {
            const { isConnectedToERP } = action.payload;

            state.isConnectedToERP = isConnectedToERP;
        },
        setOrderStatuses(state, action) {
            const { orderStatuses } = action.payload;

            state.orderStatuses = orderStatuses;
        },
        setOrderAttributes(state, action) {
            const { orderAttributes } = action.payload;

            state.orderAttributes = orderAttributes;
        },
        setOrderAdditionalFields(state, action) {
            const { orderAdditionalFields } = action.payload;

            state.orderAdditionalFields = orderAdditionalFields;
        },
        setSessionTimeoutValue(state, action) {
            const { sessionLengthInMinutes } = action.payload;

            state.sessionTimeoutValue = sessionLengthInMinutes;
        },
        setActivatedErpId(state, action) {
            const { activatedErpId } = action.payload;

            state.activatedErpId = activatedErpId;
        },
        setIsShopCategoryActive(state, action) {
            const { isShopCategoryActive } = action.payload;

            state.isShopCategoryActive = isShopCategoryActive;
        },
        setIsShopUnitsActive(state, action) {
            const { isShopUnitsActive } = action.payload;

            state.isShopUnitsActive = isShopUnitsActive;
        },
        setNotificationsTypesAndModules(state, action) {
            const { types, modules } = action.payload;

            state.notifications.types = types;
            state.notifications.modules = modules;
        },
        setLastSynchronizationDate(state, action) {
            const { lastSynchronizationDate } = action.payload;

            state.lastSynchronizationDate = lastSynchronizationDate;
        },
        setDefaultCurrencyId(state, action) {
            const { defaultCurrencyId } = action.payload;

            state.defaultCurrencyId = defaultCurrencyId;
        },
        setErpPromotions(state, action) {
            const { erpPromotions } = action.payload;

            state.erpPromotions = erpPromotions;
        },
        setPayUEnabled(state, action) {
            const { payUEnabled } = action.payload;

            state.payUEnabled = payUEnabled;
        },
        setP24Enabled(state, action) {
            const { p24Enabled } = action.payload;

            state.p24Enabled = p24Enabled;
        },
        setAdminArticleColumns(state, action) {
            const { adminArticleColumns } = action.payload;

            state.adminArticleColumns = adminArticleColumns;
        }
    },
});

export const {
    setIsInitialized, setIsConnectedToERP, setOrderStatuses, setOrderAttributes, setOrderAdditionalFields, setSessionTimeoutValue,
    setActivatedErpId, setIsShopCategoryActive, setNotificationsTypesAndModules, setIsShopUnitsActive, setLastSynchronizationDate,
    setDefaultCurrencyId, setErpPromotions, setPayUEnabled, setP24Enabled, setAdminArticleColumns
} = appInit.actions;

export default appInit.reducer;