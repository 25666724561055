export const discountTypeEnum = {
    percentage: 1,
    amount: 2
};

export const discountRangeEnum = {
    //entireOrder: 1,
    specificProducts: 2,
    shipmentMethod: 3,
    buyXGetYSingle: 4,
    buyXGetYGroup: 5
};

export const discountStatusEnum = {
    created: 0,
    visible: 1,
    hidden: 2,
    utilized: 3,
    expired: 4
};