import React from 'react';

import { useIntl } from 'react-intl';

import logo from '../../../../assets/logo/connectico-logo-full.png';

import styles from './index.module.scss';

const BrandBanner = () => {
    const intl = useIntl();
    const translations = intl.messages.auth.sharedFiller;

    return (
        <div>
            <img
                className={styles.logo}
                alt={translations.logoAltText}
                src={logo}
            />
            <h2>{translations.title}</h2>
        </div>
    );
};

export default BrandBanner;