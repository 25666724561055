const shippingForms = {
    title: 'Ustawienia ogólne',
    shippingFormsUseVariants: {
        label: 'Dziel formy dostawy na warianty zależne od regionu wysyłki',
        explanation: 'Zastosowanie takiego podziału pozwoli na definiowanie różnych zestawów dostępnych form dostawy w zależności od regiony (kraju), do którego ma nastąpić wysyłka'
    },
    shippingFormsUseWeights: {
        label: 'Dziel formy dostawy na warianty zależne od zakresów wagowych zamówień',
        explanation: 'Zastosowanie takiego podziału pozwoli na definiowanie różnych zestawów dostępnych form dostawy w zależności od sumarycznej wagi zamówionych produktów'
    },
    formsTable: {
        title: 'Formy dostawy',
        headers:{
            id: 'Id',
            shippingMethodId: 'Typ',
            productName: 'Usługa',
            isFree: 'Bezpłatna',
            isFreeMinimumOrderValue: 'Bezpłatna od kwoty',
            orderValueFrom: 'Wartość zamówienia od',
            orderValueTo: 'Wartość zamówienia do',
            actions: 'Akcje'
        },
        tooltips: {
            edit: 'Edytuj',
            remove: 'Usuń'
        }
    },
    weightsTable: {
        title: 'Zakresy wagowe form dostawy',
        headers:{
            id: 'Id',
            weightFrom: 'Waga od',
            weightTo: 'Waga do',
            actions: 'Akcje'
        },
        tooltips: {
            edit: 'Edytuj',
            remove: 'Usuń'
        }
    },
    variantsTable: {
        title: 'Warianty form dostawy',
        headers:{
            id: 'Id',
            name: 'Nazwa',
            isDefault: 'Domyślny',
            actions: 'Akcje'
        },
        tooltips: {
            edit: 'Edytuj',
            remove: 'Usuń'
        }
    }
};

export default shippingForms;