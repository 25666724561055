const report = {
    opinions: {
        tooltips: {
            remove: 'Usuń opinie',
            edit: 'Przejdź do produktu',
            accept: 'Akceptuj opinie',
        },
        emptyTable: 'Brak opinii do zatwierdzenia',
        deleteModal: {
            header: 'Usuwanie opinii',
            body: 'Czy na pewno chcesz usunąć opinie?',
            confirm: 'USUŃ',
            cancel: 'ANULUJ',
        },
        buttons: {
            accept: 'Akceptuj zaznaczone',
            remove: 'Usuń zaznaczone',
        },
        headers: {
            userName: 'Nazwa użytkownika',
            name: 'Nazwa produktu',
            date: 'Data wystawienia',
            content: 'Treść',
            rating: 'Ocena',
            actions: 'Akcje',
        },
    }
};

export default report;