import React from 'react';

import ViewTemplate from '../../features/auth/sharedItems/ViewTemplate';
import LoginForm from '../../features/auth/LoginForm';

const Login = () => {

    return (
        <ViewTemplate target="login">
            <LoginForm/>
        </ViewTemplate>
    );
};

export default Login;