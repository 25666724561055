import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    buttons: [],
};

const visualSettings = createSlice({
    name: 'visualSettings',
    initialState,
    reducers: {
        setButtons(state, action) {
            const { buttons } = action.payload;
            state.buttons = buttons;
        },
    }
});

export const { setButtons } = visualSettings.actions;

export default visualSettings.reducer;