import { languages } from './storeLocale';

const customPages = {
    view: {
        title: "Lista stron",
        actions: {
            addCustomPage: "DODAJ STRONĘ"
        },
        buttons: {
            activate: 'Aktywuj',
            deactivate: 'Deaktywuj',
            feature: 'Wyróżnij',
            unfeature: 'Wyłącz wyróżnienie'
        },
        headerLabels: {
            name: 'Nazwa',
            slug: 'Adres strony',
            status: 'Status',
            featured: 'Wyróżniona',
            actions: 'Akcje',
        },
        fillers: {
            name: '[BRAK NAZWY]',
            slug: '[BRAK ADRESU STRONY]'
        },
        tooltips: {
            remove: 'Usuń stronę',
            edit: 'Edytuj stronę'
        },
        statusLabels: {
            published: 'Opublikowana',
            unpublished: 'Niepublikowana'
        },
        featuredLabels: {
            featured: 'Wyróżniona',
            notFeatured: 'Niewyróżniona'
        },
        emptyTable: 'Nie znaleziono stron własnych.',
        deleteModal: {
            header: 'Usuń stronę',
            body: 'Czy jesteś pewien, że chcesz usunąć stronę?',
            cancel: 'WRÓC',
            confirm: 'USUŃ',
        },
    },
    edit: {
        fallbackName: '[BRAK NAZWY]',
        tabSelectorTitle: 'Sekcje',
        tabs: {
            basicData: {
                modalHeader: 'Nazwa strony',
                title: 'Dane podstawowe',
                labels: {
                    name: 'Nazwa',
                    slug: 'Adres strony',
                    featured: 'Wyróżniona',
                    status: 'Status',
                    availableInLanguages: 'Strona dostępna w językach',
                    slugAlert: 'Należy wypełnić adres strony. Może on zawierać tylko litery, liczby i znak podkreślenia.',
                    nameAlert: 'Wszystkie nazwy w widocznych językach muszą być podane.',
                    backgroundColor: 'Kolor tła',
                    backgroundColorButton: 'Wybierz'
                },
                statusLabels: {
                    published: 'Opublikowana',
                    unpublished: 'Niepublikowana'
                },
                featuredLabels: {
                    featured: 'Wyróżniona',
                    notFeatured: 'Niewyróżniona'
                },
                languages: languages,
            },
            sections: {
                title: 'Sekcje strony własnej',
            },
            metatags: {
                title: 'Meta-tagi'
            },
        }
    }
};

export default customPages;