import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
// import { useIntl } from 'react-intl';

import { connect } from 'react-redux';
import { getMediaFolderContent, deleteMediaFiles, getSearchMediaFiles } from '../../../redux/methods/mediaLibrary';

import Paginator from '../../../components/Paginator';
import { Button, Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import MediaList from '../../../views/MediaLibrary/MediaList';
import useFetch from '../../../hooks/useFetch';
import useSearchConfig from '../../../hooks/useSearchConfig';
import { mediaLibrarySearchConfig } from '../../../config/searchConfigs';
import MediaSearchForm from '../../../features/mediaLibrary/MediaSearchForm';
import Modal from '../../../components/modals/Modal';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

const propTypes = {
    props: PropTypes.object,
};

const initialState = {
    folders: [],
    files: [],
    breadcumps: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'setFileName': return {
            ...state,
            files: state.files.map(file => {
                if(file.id === action.payload.id) {
                    return {
                        ...file,
                        fileName: action.payload.fileName,
                    };
                } else {
                    return file;
                }
            })
        };
        case 'setFiles': return { ...state, files: action.payload };
        case 'setFile': return {
            ...state,
            files: state.files.map(file => {
                if(file.id === action.payload.id) {
                    return {
                        ...action.payload.file,
                        percentage: null,
                    };
                } else {
                    return file;
                }
            })
        };
        case 'addFile': return { ...state, files: [ ...state.files, { percentage: 1, ...action.payload } ]};
        case 'setFolders': return { ...state, folders: action.payload};
        case 'changePercentageById': return {
            ...state,
            files: state.files.map(file => {
                if(file.id === action.payload.id) {
                    return {
                        ...file,
                        percentage: action.payload.percentage,
                    };
                } else {
                    return file;
                }
            })
        };
        case 'setBreadcumps': return { ...state, breadcumps: action.payload };

        default: throw new Error();
    }
};

const MediaSelectionModal = ({ isOpen, setIsOpen, getMediaFolderContent, getSearchMediaFiles, onSubmit, defaultItemsChecked }) => {
    const translations = useIntl().messages.sharedComponents.mediaSelectionModal;

    const [ mediaLibraryConfig, changePage, changeConfig ] = useSearchConfig(mediaLibrarySearchConfig);
    const [ checkedItems, setCheckedItems ] = useState(defaultItemsChecked || []);

    const [ media, pages ] = useFetch(
        mediaLibraryConfig.fileName !== "" || mediaLibraryConfig.mediaType !== 1 ? getSearchMediaFiles : getMediaFolderContent,
        mediaLibraryConfig,
        [mediaLibraryConfig]
    );

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        setCheckedItems(defaultItemsChecked);
    }, [defaultItemsChecked]);

    useEffect(() => {
        if(media) {
            dispatch({ type: 'setFiles', payload: media.rows2 || media || [] });
            dispatch({ type: 'setFolders', payload: media.rows1 || [] });
            dispatch({ type: 'setBreadcumps', payload: media.rows3 || [] });
        }
    }, [media]);

    const refreshMedia = () => {
        changeConfig(mediaLibraryConfig) ;
    };

    const handleChangeFolder = id => {
        changeConfig({ ...mediaLibraryConfig, folderId: id});
    };

    const handleBreadcumpClick = id => () => {
        changeConfig({ ...mediaLibrarySearchConfig, folderId: id });
    };

    const changeFileName = (id, fileName) => {
        dispatch({ type: 'setFileName', payload: { id, fileName } });
    };

    return isOpen ? (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            header={translations.header}
            size='lg'
            body={
                <Col>
                    <Row className='mb-3'>
                        <small className='text-danger'>{translations.info.label} <Link to="/media">{translations.info.link}</Link></small>
                    </Row>
                    <Row className='mb-3'>
                        <MediaSearchForm
                            performSearch={changeConfig}
                            searchConfig={mediaLibraryConfig}
                        />
                    </Row>
                    <Row>
                        {state.breadcumps.length > 0 &&
                            <div className='w-100'>
                                <Breadcrumb>
                                <BreadcrumbItem>
                                    <Button disabled={mediaLibraryConfig.folderId === null} color="link" onClick={handleBreadcumpClick(null)}>
                                        {translations.breadcumpsRoot}
                                    </Button>
                                </BreadcrumbItem>
                                {state.breadcumps.map(breadcump =>
                                    <BreadcrumbItem key={breadcump.id} active={false}>
                                        <Button disabled={mediaLibraryConfig.folderId === breadcump.id} color="link" onClick={handleBreadcumpClick(breadcump.id)}>
                                            {breadcump.name}
                                        </Button>
                                    </BreadcrumbItem>
                                )}
                                </Breadcrumb>
                            </div>
                        }
                        <MediaList
                            selectionMode
                            checked={checkedItems}
                            setChecked={setCheckedItems}
                            files={state.files}
                            folders={state.folders}
                            changeFolder={handleChangeFolder}
                            searchInput={mediaLibrarySearchConfig.fileName}
                            folderId={mediaLibraryConfig.folderId}
                            refreshMedia={refreshMedia}
                            changeFileName={changeFileName}
                        />
                    </Row>
                    {
                        pages > 1 &&
                            <Paginator
                                totalPages={pages}
                                currentPage={mediaLibraryConfig.curentPage + 1}
                                onPageChange={changePage}
                            />
                    }
                </Col>
            }
            buttons={{
                confirm: {
                    label: translations.buttons.confirm,
                    color: 'success'
                },
                cancel: {
                    label: translations.buttons.cancel,
                    color: 'danger'
                }
            }}
            onConfirm={() => onSubmit(checkedItems)}
        />
    ) : null;
};

MediaSelectionModal.propTypes = propTypes;

const mapDispatch = {
    getMediaFolderContent,
    deleteMediaFiles,
    getSearchMediaFiles,
};

export default connect(null, mapDispatch)(MediaSelectionModal);