import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getCookiesTranslations, updateCookiesTranslations } from '../../redux/methods/extrasSettings';

import { Form as FinalForm  } from 'react-final-form';
import { Container, Form } from 'reactstrap';

import FormFieldTranslations from '../../components/formFields/FormFieldTranslations';
import SectionEditFormButtons from '../../components/SectionEditFormButtons';

import { formUtilsHocPropTypes } from '../../propTypes';

import { withFormUtils } from '../../hoc/withFormUtils';

import useFetch from '../../hooks/useFetch';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    getCookiesTranslations: PropTypes.func,
    updateCookiesTranslations: PropTypes.func
};

const CookiesAgreementsBasic = ({ utils, getCookiesTranslations, updateCookiesTranslations }) => {
    const intl = useIntl();
    const translations = intl.messages.cookiesAgreements.editBasic;

    const [ cookiesTranslations ] = useFetch(getCookiesTranslations);

    const config = { translations: [ 'header', 'button' ] };

    const handleOnSubmit = async formData => {
        await updateCookiesTranslations(utils.generateFormToSubmitValues(formData, config));
    };

    return cookiesTranslations ? (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={utils.generateFormInitialValues(cookiesTranslations, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='px-0' fluid>
                                    <div className="my-2">
                                        <FormFieldTranslations
                                            form={form}
                                            modalHeader={translations.labels.header}
                                            propertyName='header'
                                            currentIso={utils.currentLanguage()}
                                            label={translations.labels.header}
                                        />
                                    </div>
                                    <div className="my-2">
                                        <FormFieldTranslations
                                            form={form}
                                            modalHeader={translations.labels.button}
                                            propertyName='button'
                                            currentIso={utils.currentLanguage()}
                                            label={translations.labels.button}
                                        />
                                    </div>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset} />
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    ) : null;
};

CookiesAgreementsBasic.propTypes = propTypes;

const mapDispatch = {
    getCookiesTranslations,
    updateCookiesTranslations
};

export default withFormUtils(connect(null, mapDispatch)(CookiesAgreementsBasic));