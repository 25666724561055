import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDiscountToEdit, clearDiscountToEdit } from '../../redux/methods/discounts';
import { selectIsCategoryTreePresent } from '../../redux/selectors/categories';
import { getCategoryTree } from '../../redux/methods/categories';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';
import DiscountEditBasic from '../../features/discounts/editTabs/DiscountEditBasic';
import DiscountEditParameters from '../../features/discounts/editTabs/DiscountEditParameters';
import DiscountEditCategoriesProducts from '../../features/discounts/editTabs/DiscountEditCategoriesProducts';
import DiscountEditRegionsCountries from '../../features/discounts/editTabs/DiscountEditRegionsCountries';
import DiscountEditCodes from '../../features/discounts/editTabs/DiscountEditCodes';
import DiscountEditGroupsUsers from '../../features/discounts/editTabs/DiscountEditGroupsUsers';
import DiscountEditUsages from '../../features/discounts/editTabs/DiscountEditUsages';
import DiscountEditAttributes from '../../features/discounts/editTabs/DiscountEditAttributes';
import DiscountEditTagSettings from '../../features/discounts/editTabs/DiscountEditTagSettings';

import { stringOrNumberPropTypes } from '../../propTypes';

const propTypes = {
    location: PropTypes.object,
    discountToEdit: PropTypes.shape({
        id: stringOrNumberPropTypes,
        data: PropTypes.object,
    }),
    getDiscountToEdit: PropTypes.func,
    clearDiscountToEdit: PropTypes.func,
    selectIsCategoryTreePresent: PropTypes.bool,
    getCategoryTree: PropTypes.func,
    isCategoryTreePresent: PropTypes.bool,
};

const DiscountsEdit = ({ location, discountToEdit, getDiscountToEdit, clearDiscountToEdit, isCategoryTreePresent, getCategoryTree }) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit;
    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);
    const chosenDiscountId = query.get('discountID');

    useEffect(() => {
        if (chosenDiscountId && (!discountToEdit.id || discountToEdit.id !== chosenDiscountId)) {
            getDiscountToEdit(chosenDiscountId);
        }

        if(!isCategoryTreePresent) {
            getCategoryTree();
        }

        return () => {
            /**
             * Cleanup on dismount to avoid obsolete data flashing
             */
            clearDiscountToEdit();
        };
    }, []); // eslint-disable-line

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'discountEditTab-basic':
                return <DiscountEditBasic />;
            case 'discountEditTab-parameters':
                return <DiscountEditParameters />;
            case 'discountEditTab-categoriesProducts':
                return <DiscountEditCategoriesProducts />;
            case 'discountEditTab-regionsCountries':
                return <DiscountEditRegionsCountries />;
            case 'discountEditTab-groupsUsers':
                return <DiscountEditGroupsUsers />;
            case 'discountEditTab-attributes':
                return <DiscountEditAttributes />;
            case 'discountEditTab-codes':
                return <DiscountEditCodes />;
            case 'discountEditTab-use':
                return <DiscountEditUsages />;
            case 'discountEditTab-tagSettings':
                return <DiscountEditTagSettings />;
            default:
                return null;
        }
    };

    return chosenDiscountId ? (
        discountToEdit.data && isCategoryTreePresent ? (
            <SettingsTabulator
                title={{
                    label: discountToEdit.data.name || translations.fallbackName,
                    iconClassName: 'fa fa-edit text-primary ml-3',
                    isFilled: discountToEdit.data.name ? false : true,
                }}
                tabulatorHeader={{
                    label: translations.tabSelectorTitle,
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: 'discountEditTab-basic',
                        header:  {
                            label: translations.tabs.basic.title,
                            iconClassName: 'fa fa-tasks'
                        }
                    },
                    chosenDiscountId > -1 ? {
                        id: 'discountEditTab-parameters',
                        header:  {
                            label: translations.tabs.parameters.title,
                            iconClassName: 'fa fa-inbox'
                        }
                    } : null,
                    chosenDiscountId > -1 ? {
                        id: 'discountEditTab-categoriesProducts',
                        header:  {
                            label: translations.tabs.categoriesProducts.title,
                            iconClassName: 'cil-sitemap'
                        }
                    } : null,
                    chosenDiscountId > -1 ? {
                        id: 'discountEditTab-regionsCountries',
                        header:  {
                            label: translations.tabs.regionsCountries.title,
                            iconClassName: 'icon-globe'
                        }
                    } : null,
                    chosenDiscountId > -1 ? {
                        id: 'discountEditTab-groupsUsers',
                        header:  {
                            label: translations.tabs.groupsUsers.title,
                            iconClassName: 'fa fa-group'
                        }
                    } : null,

                    chosenDiscountId > -1 ? {
                        id: 'discountEditTab-attributes',
                        header:  {
                            label: translations.tabs.attributes.title,
                            iconClassName: 'fa fa-inbox'
                        }
                    } : null,

                    chosenDiscountId > -1 ? {
                        id: 'discountEditTab-codes',
                        header:  {
                            label: translations.tabs.codes.title,
                            iconClassName: 'fa fa-book'
                        }
                    } : null,
                    chosenDiscountId > -1 ? {
                        id: 'discountEditTab-use',
                        header:  {
                            label: translations.tabs.usages.title,
                            iconClassName: 'fa fa-hand-o-right'
                        }
                    } : null,
                    chosenDiscountId > -1 ? {
                        id: 'discountEditTab-tagSettings',
                        header:  {
                            label: translations.tabs.tagSettings.title,
                            iconClassName: 'fa fa-tags'
                        }
                    } : null,
                ]}
            />
        ) : null
    ) : (
        <Redirect to='/discounts'/>
    );
};

DiscountsEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    discountToEdit: state.discounts.discountToEdit,
    isCategoryTreePresent: selectIsCategoryTreePresent(state),
});

const mapDispatch = {
    getDiscountToEdit,
    clearDiscountToEdit,
    getCategoryTree,
};

export default withRouter(connect(mapStateToProps, mapDispatch)(DiscountsEdit));