export const priceTypeEnum = {
    normal: 1,
    promotional: 2,
};

export const additionalCurrencyPriceEnum = {
    exchangeRateTable: 'EXCHANGE_RATE_TABLE',
    constantRate: 'CONSTANT_RATE',
    definedPrice: 'DEFINED_PRICE',
};

export const promoPriceTypesEnum = {
    undefined: 'UNDEFINED',
    precentageDiscount: 'PRECENTAGE_DISCOUNT',
    definedPrice: 'DEFINED_PRICE'
};

export const quantityPresentationEnum = {
    1: 'none',
    2: 'quantity',
    3: 'available',
    4: 'graphic',
};

export const productTileFillerUrlTypeEnum = {
    1: 'category',
    2: 'product',
    3: 'outside'
};

export const productStatusEnum = {
    0: 'unpublished',
    1: 'published',
};

export const productTypeEnum = {
    1: 'product',
    2: 'shipmentMethod',
    3: 'paymentMethod',
    4: 'shipmentForm'
};

export const productSortableColumnEnum = {
    catalogueIndex: 'CatalogueIndex',
    tradeIndex: 'TradeIndex',
    id: 'Id',
    name: 'Name'
};