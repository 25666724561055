import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPayments, deletePayment, updatePaymentStatus } from '../../../../redux/methods/products';
import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';

import { useIntl } from 'react-intl';

import ServiceTable from '../../../../components/tables/ServiceTable';
import useFetch from '../../../../hooks/useFetch';
import { translateProductName } from '../../../../utils/products';
import Paginator from '../../../../components/Paginator';
import { useHistory } from 'react-router-dom';

const propTypes = {
    props: PropTypes.object,
};

const Table = ({
    forceRefetch,
    getPayments,
    deletePayment,
    updatePaymentStatus,
    searchConfig,
    changeSearchConfig,
    storeCurrentLanguage,
    changePage
}) => {
    const intl = useIntl();

    const history = useHistory();

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    const [ payments, pages ] = useFetch(getPayments, searchConfig, [searchConfig], forceRefetch);

    const translatedPayments = payments && translateProductName(payments, storeCurrentLanguage);

    const editPayment = (id) => {
        if (id) {
            history.push(`/paymentServices/list/edit?productID=${id}`);
        }
    };

    return payments ? (
        <React.Fragment>
            <ServiceTable
                translations={intl.messages.services.payments.table}
                performPageSizeSelect={handleChangePageSize}
                pageSize={searchConfig.pageSize}
                currentPage={searchConfig.curentPage + 1}
                services={translatedPayments}
                history={history}
                performDeleteAction={deletePayment}
                performEditAction={editPayment}
                updateAction={updatePaymentStatus}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={searchConfig.curentPage + 1}
                        onPageChange={changePage}
                    />
            }
        </React.Fragment>
    ) : null;
};

Table.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.payments,
    storeCurrentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
    getPayments,
    deletePayment,
    updatePaymentStatus
};

export default connect(mapStateToProps, mapDispatch)(Table);