import { getData, addEditData, removeData } from '../generic';
import { cAPI } from '../../../utils/cAPI';

export const getProductCarouselGeneral = () => dispatch => getData(dispatch, cAPI.APGetProductCarouselsGeneral, null, 'homePageProducts');
export const updateProductCarouselGeneral = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdateProductCarouselsGeneral, formData, 'homePageProducts');

export const getPromoSection = id => dispatch => getData(dispatch, cAPI.APGetPromoSection, id, 'homePagePromoSections');
export const updatePromoSection = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUpdatePromoSection, { id, formData });

export const getPromoSectionImage = id => dispatch => getData(dispatch, cAPI.APGetPromoSectionImage, id, 'homePagePromoSections');
export const uploadPromoSectionImage = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUploadPromoSectionImage, { id, formData }, 'homePageSections');
export const removePromoSectionImage = id => dispatch => removeData(dispatch, cAPI.APRemovePromoSectionImage, id, 'homePagePromoSections');

export const getSectionPhotoWithTextImage = id => dispatch => getData(dispatch, cAPI.APGetSectionPhotoWithTextImage, id, 'homePageSectionPhotoText');
export const uploadSectionPhotoWithTextImage = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUploadSectionPhotoWithTextImage, { id, formData }, 'homePageSections');
export const removeSectionPhotoWithTextImage = id => dispatch => removeData(dispatch, cAPI.APRemoveSectionPhotoWithTextImage, id, 'homePageSectionPhotoText');

export const getBanners = () => dispatch => getData(dispatch, cAPI.APGetBanners, 'homePageBanner');
export const getBanner = (id) => dispatch => getData(dispatch, cAPI.APGetBanner, id, 'homePageBanner');
export const updateBanner = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUpdateBanner, { id, formData }, 'homePageBanner');
export const deleteBanner = id => dispatch => removeData(dispatch, cAPI.APDeleteBanner, id, 'homePageBanner');
export const getBannerImages = (id) => dispatch => getData(dispatch, cAPI.APGetBannerImages, id, 'homePageBanner');
export const getBannerImage = (bannerId, id) => dispatch => getData(dispatch, cAPI.APGetBannerImages, { bannerId, id }, 'homePageBanner');
export const updateBannerImage = (bannerId,formData, preventRefetch) => dispatch => addEditData(dispatch, cAPI.APUpdateBannerImage, {bannerId, formData }, !preventRefetch && 'homePageBanner');
export const moveBannerImage = (id, delta, formData) => dispatch => addEditData(dispatch, cAPI.APMoveBannerImage, { id, delta, formData }, 'homePageBanner');
export const deleteBannerImage = id => dispatch => removeData(dispatch, cAPI.APDeleteBannerImage, id, 'homePageBanner');
export const uploadBannerImage = (bannerId, id, formData) => dispatch => addEditData(dispatch, cAPI.APUploadBannerImage, { bannerId, id, formData }, 'homePageBanner');
export const deleteBannerImageFile = (bannerId, id) => dispatch => removeData(dispatch, cAPI.APDeleteBannerImageFile, { bannerId, id }, 'homePageBanner');

export const getAnimatedBanners = () => dispatch => getData(dispatch, cAPI.APGetAnimatedBanners, 'homePageAnimatedBanner');
export const getAnimatedBanner = (id) => dispatch => getData(dispatch, cAPI.APGetAnimatedBanner, id, 'homePageAnimatedBanner');
export const updateAnimatedBanner = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUpdateAnimatedBanner, { id, formData }, 'homePageAnimatedBanner');
export const deleteAnimatedBanner = id => dispatch => removeData(dispatch, cAPI.APDeleteAnimatedBanner, id, 'homePageAnimatedBanner');

export const getAnimatedBannerImages = (id) => dispatch => getData(dispatch, cAPI.APGetAnimatedBannerImages, id, 'homePageAnimatedBannerImages');
export const getAnimatedBannerImage = (animatedBannerId, id) => dispatch => getData(dispatch, cAPI.APGetAnimatedBannerImages, { animatedBannerId, id }, 'homePageAnimatedBannerImages');
export const updateAnimatedBannerImage = (animatedBannerId,formData, preventRefetch) => dispatch => addEditData(dispatch, cAPI.APUpdateAnimatedBannerImage, {animatedBannerId, formData }, !preventRefetch && 'homePageAnimatedBannerImages');
export const updateAnimatedBannerImageSettings = (animatedBannerId, animatedBannerImageId, device, formData, preventRefetch) => dispatch => addEditData(dispatch, cAPI.APUpdateAnimatedBannerImageSettings, {animatedBannerId, animatedBannerImageId, device, formData}, !preventRefetch && 'homePageAnimatedBannerImages');

export const moveAnimatedBannerImage = (id, delta, formData) => dispatch => addEditData(dispatch, cAPI.APMoveAnimatedBannerImage, { id, delta, formData }, 'homePageAnimatedBannerImages');
export const deleteAnimatedBannerImage = (bannerId, id) => dispatch => removeData(dispatch, cAPI.APDeleteAnimatedBannerImage, { bannerId, id }, 'homePageAnimatedBannerImages');
export const uploadAnimatedBannerImage = (animatedBannerId, id, formData) => dispatch => addEditData(dispatch, cAPI.APUploadAnimatedBannerImage, { animatedBannerId, id, formData }, 'homePageAnimatedBannerImages');
export const deleteAnimatedBannerImageFile = (animatedBannerId, id) => dispatch => removeData(dispatch, cAPI.APDeleteAnimatedBannerImageFile, { animatedBannerId, id }, 'homePageAnimatedBannerImages');

export const getPromotionBar = () => dispatch => getData(dispatch, cAPI.APGetPromotionBar, null, 'promotionBar');
export const updatePromotionBar = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdatePromotionBar, formData, 'promotionBar');

export const getPromotionBarSlides = () => dispatch => getData(dispatch, cAPI.APGetPromotionBarSlides, null, 'promotionBarSlides');
export const updatePromotionBarSlides = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdatePromotionBarSlides, formData, 'promotionBarSlides');
export const movePromotionBarSlides = (id, delta, formData) => dispatch => addEditData(dispatch, cAPI.APMovePromotionBarSlides, { id, delta, formData }, 'promotionBarSlides');
export const deletePromotionBarSlide = id => dispatch => removeData(dispatch, cAPI.APDeletePromotionBarSlide, id, 'promotionBarSlides');

export const getHomePageSections = ({device, affiliation, affiliationObjectId }) => dispatch => getData(dispatch, cAPI.APGetHomePageSections, { device, affiliation, affiliationObjectId }, 'homePageSections');
export const getHomepageGeneralSettings = () => dispatch => getData(dispatch, cAPI.APGetHomepageGeneralSettings);
export const updateHomepageGeneralSettings = (formData) => dispatch => addEditData(dispatch, cAPI.APUpdateHomepageGeneralSettings, formData);

export const deleteHomePageSections = ({id, type}) => dispatch => removeData(dispatch, cAPI.APDeleteHomePageSections, { id, type }, 'homePageSections');
export const changeOrderHomePageSections = ({ id, device, order, type }) => dispatch => addEditData(dispatch, cAPI.APChangeOrderHomePageSections, { id, device, order, type }, 'homePageSections');
export const changeActiveHomePageSections = ({ id, device, isActive, type }) => dispatch => addEditData(dispatch, cAPI.APChangeActiveHomePageSections, { id, device, isActive, type }, 'homePageSections');
export const getHomePageSectionById = id => dispatch => getData(dispatch, cAPI.APGetHomePageSectionById, id, 'homePageSections');
export const addUpdatePageSection = (formData, preventRefetch) => dispatch => addEditData(dispatch, cAPI.APAddUpdatePageSection, formData, !preventRefetch && 'homePageSections');
export const createHomePageSectionDuplicate = ({id, type}) => dispatch => addEditData(dispatch, cAPI.APCreateHomePageSectionDuplicate, { id, type });

export const deleteDoubleImageSectionImage = (id, isFirstImage) => dispatch => removeData(dispatch, cAPI.APDeleteDoubleImageSectionImage, { id, isFirstImage }, 'homePageDoubleImage');
export const uploadDoubleImageSectionImage = (id, formData) => dispatch => addEditData(dispatch, cAPI.APUploadDoubleImageSectionImages, { id, formData }, 'homePageSections');
export const getDoubleImageSectionImagesSource = (id) => dispatch => getData(dispatch, cAPI.APGetDoubleImageSectionImagesSource, id, 'homePageDoubleImage');

export const getAssignedManufacturersBySectionId = id => dispatch => getData(dispatch, cAPI.APGetAssignedManufacturersBySectionId, id, 'homePageManufacturers');
export const getUnassignedManufacturersBySectionId = id => dispatch => getData(dispatch, cAPI.APGetUnassignedManufacturersBySectionId, id, 'homePageManufacturers');
export const addDeleteAssignedManufacturers = formData => dispatch => addEditData(dispatch, cAPI.APAddDeleteAssignedManufacturers, formData, 'homePageManufacturers');
export const moveManufacturersItem = formData => dispatch => addEditData(dispatch, cAPI.APMoveManufacturersItem, formData, 'homePageManufacturers');

export const getSectionProducts = ({ id, type, formData }) => dispatch => getData(dispatch, cAPI.APGetSectionProducts, { id, type, formData }, 'sectionProducts');
export const addDeleteSectionProducts = ({ id, formData, type }) => dispatch => addEditData(dispatch, cAPI.APAddDeleteSectionProducts, { id, formData, type }, 'sectionProducts');

export const getSectionCategories = ({ id, type }) => dispatch => getData(dispatch, cAPI.APGetSectionCategories, { id, type }, 'sectionCategories');
export const addDeleteSectionCategories = ({ id, formData, type }) => dispatch => addEditData(dispatch, cAPI.APAddDeleteSectionCategories, { id, formData, type }, 'sectionCategories');