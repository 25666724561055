import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal as CoreUiModal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import FormFieldTextOrNumber from '../../formFields/FormFieldTextOrNumber';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';

import styles from './index.module.scss';
import clsx from 'clsx';
import { withFormUtils } from '../../../hoc/withFormUtils';

import { measureTypeEnum } from '../../../enums/units';

const propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    translations: PropTypes.object,
    productMeasure: PropTypes.object,
    typeConfig: PropTypes.array,
    measuresOptions: PropTypes.array
};

const ProductMeasureEditionModal = ({
    isOpen,
    setIsOpen,
    onSubmit,
    translations,
    productMeasure,
    typeConfig,
    measuresOptions
}) => {

    const handleOnCancel = () => {
        setIsOpen(false);
    };

    const handleOnSubmit = form => {
        onSubmit(form);
    };

    return productMeasure && measuresOptions ? (
        <CoreUiModal className="wide" isOpen={isOpen}>
            <FinalForm
                initialValues={productMeasure}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, values }) => {
                    const type = typeConfig.find(t => t.id === values.measureTypeId);
                    const selectedMeasure = measuresOptions.find(option => option.id === values.measureId);

                    return (
                        <div>
                            <ModalHeader>{translations.title}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={onSubmit}>
                                    <div>
                                        <div className='my-2'>
                                            <b>{translations.inputs.measureType}:</b> {type.label}
                                        </div>
                                        <div className='my-2'>
                                            <FormFieldWithDropdown
                                                label={translations.inputs.measure}
                                                name='measureId'
                                                options={measuresOptions}
                                                currentSelectionLabel={selectedMeasure.label}
                                                className='w-100'
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <FormFieldTextOrNumber
                                                label={translations.inputs.rate}
                                                name='rate'
                                                className='w-100'
                                                type='number'
                                                min={0}
                                                disabled={type.id === measureTypeEnum['storage']}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <div className={clsx("d-flex justify-content-between", styles.modalFooterButtons)}>
                                    <Button onClick={handleOnCancel} color='danger'>
                                        <i className="fa fa-remove mr-2"></i>
                                        <span>{translations.buttons.cancel}</span>
                                    </Button>
                                    <Button onClick={handleSubmit} color='success' disabled={!values.measureId}>
                                        <i className="fa fa-save mr-2"></i>
                                        <span>{translations.buttons.save}</span>
                                    </Button>
                                </div>
                            </ModalFooter>
                        </div>
                    );
                }}
            />
        </CoreUiModal>
    ) : null;
};

ProductMeasureEditionModal.propTypes = propTypes;

export default withFormUtils(ProductMeasureEditionModal);