import { cAPI } from '../../../utils/cAPI';
import { getData, addEditData } from '../generic';
import { setActivatedErpId, setIsShopCategoryActive, setIsConnectedToERP, setErpPromotions } from '../../../features/appInit/slice';

export const getSynchronizationsErp = () => dispatch => getData(dispatch, cAPI.APGetSynchronizationsErp);
export const updateSynchronizationsErp = formData => dispatch => {
    const res = addEditData(dispatch, cAPI.APUpdateSynchronizationsErp, formData);
    dispatch(setIsConnectedToERP({ isConnectedToERP: !!formData.activatedErpId }));
    dispatch(setActivatedErpId({ activatedErpId: formData.activatedErpId }));

    return res;
};

export const getSynchronizationsSettings = () => dispatch => getData(dispatch, cAPI.APGetSynchronizationsSettings)
    .then(result => {
        if(result){
            dispatch(setErpPromotions({ erpPromotions: result.payload.isPromotionFromErp }));
        }

        return result;
    });
export const updateSynchronizationsSettings = formData => dispatch => {
    const res = addEditData(dispatch, cAPI.APUpdateSynchronizationsSettings, formData);
    dispatch(setIsShopCategoryActive({ isShopCategoryActive: formData.isShopCategoryActive }));

    return res;
};

export const getSynchronizationsAuth = () => dispatch => getData(dispatch, cAPI.APGetSynchronizationAuth, null, 'generateToken');
export const synchronizationAuthGenerateToken = () => dispatch => addEditData(dispatch, cAPI.APSynchronizationGenerateToken, null, 'generateToken');