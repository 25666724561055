const seo = {
    tabSelectorTitle: '[EN] Pozycjonowanie',
    tabs: {
        siteMap: '[EN] Mapa strony',
        photoNames: '[EN] Nazwy zdjęć'
    },
    photoNames: {
        alternativeText: '[EN] Tekst alternatywny',
        title: '[EN] nazwa'
    },
    googleSiteMap: {
        generateAutomatically: '[EN] Generuj mapę strony automatycznie',
        location: '[EN] Lokalizacja mapy strony',
        time: '[EN] Co ile wykonać automatyczne generowanie (godziny)',
        generateButton: '[EN] Generuj mapę strony',
        generatedInfo : '[EN] Wygenerowno nową mapę strony',
        copy: 'Skopiowano do schowka'
    },
    ownCode: {
        title: '[EN] Własny kod',
        enabled: '[EN] Aktywny',
        head: '[EN] Nagłówek',
        footer: '[EN] Stopka',
        scriptFormatAlert: '[EN] Skrypt musi znajdować się pomiędzy znakami <script></script>'
    }
};

export default seo;