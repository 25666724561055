import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { defaultChartDataset } from '../../config';

import { Label } from 'reactstrap';

import { Line } from 'react-chartjs-2';

const propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.instanceOf('Date')
            ]),
            value: PropTypes.number
        })
    ),
    customSettings: PropTypes.object,
};

const LineChart = ({
    data,
    label,
    customSettings,
    className,
    emptyDataTranslation,
    options
}) => {
    const generatedLabels = data && data.map(element => element.label);
    const generatedValues = data && data.map(element => element.value);

    const settings = {
        labels: generatedLabels,
        datasets: [
            {   ...defaultChartDataset,
                ...customSettings,
                label: label,
                data: generatedValues
            }
        ]
    };

    return data && data.length > 0 ? (
        <div className={clsx('w-100 h-100', className)}>
            <Line
                data={settings}
                options={options}
            />
        </div>
    ) : (
        <div className='w-100 h-100 d-flex align-items-center justify-content-center my-4'>
            <Label>{emptyDataTranslation}</Label>
        </div>
    );
};

LineChart.propTypes = propTypes;

export default LineChart;