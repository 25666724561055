import React from 'react';
import PropTypes from 'prop-types';

import { Input, Label } from 'reactstrap';

import { productPricesTranslationsPropTypes } from '../../../../../../propTypes';

const propTypes = {
    translations: productPricesTranslationsPropTypes,
    type: PropTypes.string,
    setType: PropTypes.func,
    conflig: PropTypes.object,
    disabled: PropTypes.bool
};

const TypeSelector = ({ translations, type, setType, config, disabled }) => {
    return (
        <React.Fragment>
        {
            Object.keys(config).map(key => (
                <Label check key={key}>
                    <Input
                        type='radio'
                        checked={type === config[key]}
                        id={config[key]}
                        value={''}
                        onChange={e => setType(e.target.id)}
                        disabled={disabled}
                    />
                    <span>{translations[key]}</span>
                </Label>
            ))
        }
        </React.Fragment>
    );
};

TypeSelector.propTypes = propTypes;

export default TypeSelector;