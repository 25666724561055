import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { otherServicesSearchConfig } from '../../config/searchConfigs';

import SearchForm from '../generators/SearchForm';

const propTypes = {
    performServiceSearch: PropTypes.func,
    searchConfig: PropTypes.object,
};

const OtherServiceSearchForm = ({ performServiceSearch, searchConfig, additionalDefaultSearchConfig, children }) => {
    const intl = useIntl();
    const translations = intl.messages.services.list.searchForm;

    return (
        <SearchForm
            defaultSearchConfig={{...otherServicesSearchConfig, ...additionalDefaultSearchConfig }}
            searchConfig={searchConfig}
            translations={{
                buttons: {
                    submit: translations.action,
                    reset: translations.reset,
                }
            }}
            inputs={[
                {
                    label: translations.productName.label,
                    name: 'productName',
                    type: 'text',
                },
                {
                    label: translations.catalogueIndex.label,
                    name: 'catalogueIndex',
                    type: 'text',
                },
                {
                    label: translations.tradeIndex.label,
                    name: 'tradeIndex',
                    type: 'text',
                },
                {
                    label: translations.ean.label,
                    name: 'ean',
                    type: 'text',
                },
                {
                    label: translations.status.label,
                    name: 'status',
                    type: 'dropdown',
                    options: [
                        {
                            id: 1,
                            label: translations.active
                        },
                        {
                            id: 0,
                            label: translations.inactive
                        }
                    ],
                    defaultLabel: translations.defaultSearchLabel
                },
                {
                    label: translations.warning.label,
                    name: 'onlyWithWarnings',
                    type: 'checkbox',
                }
            ]}
            performSearch={performServiceSearch}
        >
            {children}
        </SearchForm>
    );
};

OtherServiceSearchForm.propTypes = propTypes;

export default OtherServiceSearchForm;