const notFound = {
    title: 'Strona 404',
    button: 'ZAPISZ ZMIANY',
    labels: {
        button: 'Tekst na przycisku',
        content: 'Tekst nad przyciskiem',
        mainColor: 'Główny kolor',
        mainColorButton: 'Wybierz kolor',
    },
    fileSender: {
        label: 'Obrazek',
        buttons: {
            edit: 'PODMIEŃ',
            remove: 'USUŃ',
            upload: 'WGRAJ'
        },
    },
};

export default notFound;