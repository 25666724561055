import { cAPI } from '../../../utils/cAPI';
import { getData, removeData, addEditData } from '../generic';

export const getCurrencies = () => dispatch => getData(dispatch, cAPI.APGetCurrencies, null, 'currencies');
export const addCurrency = () => dispatch => addEditData(dispatch, cAPI.APAddCurrency, { name: '', shortName: '' });
export const removeCurrency = id => dispatch => removeData(dispatch, cAPI.APRemoveCurrency, id, 'currencies');
export const removeCurrencyRegions = (currencyId, regionIds) => dispatch => removeData(dispatch, cAPI.APRemoveCurrencyRegion, { currencyId, regionIds }, 'currenciesRegions');
export const getCurrencyRegions = id =>  dispatch => getData(dispatch, cAPI.APGetCurrenciesRegions, id, 'currenciesRegions');
export const addCurrencyRegion = (currencyId, regionId) => dispatch => addEditData(dispatch, cAPI.APAddCurrencyRegion, { currencyId, regionId }, 'currenciesRegions');
export const editCurrencyBasic = data => dispatch => addEditData(dispatch, cAPI.APEditCurrency, data, 'currencies');
export const getCurrencyBasicData = id => dispatch => getData(dispatch, cAPI.APGetCurrency, id, 'currencies');
export const updateCurrencyStatus = data => dispatch => addEditData(dispatch, cAPI.APUpdateCurrencyStatus, data, 'currencies', false, false, false, false, true);
