export const attributeTypeEnum = {
    tag: 'Tag',
    dictionary: 'Dictionary',
    int: 'Int',
    decimal: 'Decimal',
    dateTime: 'DateTime',
    color: 'Color',
    boolean: 'Boolean',
    string: 'String'
};

export const attributePresentationTypeEnum = {
    tag: 'Tag',
    color: 'Color',
    simpleDictionary: 'SimpleDictionary',
    searchableDictionary: 'SearchableDictionary',
    range: 'Range',
    boolean: 'Boolean',
    string: 'String'
};

export const attributeSettingsTagEnum = {
    isPermanentlyExpanded: 'IsPermanentlyExpanded',
    dateTimeRangeMinimumLimit: 'DateTimeRangeMinimumLimit',
    dateTimeRangeMaximumLimit: 'DateTimeRangeMaximumLimit',
    intRangeMaximumLimit: 'IntRangeMaximumLimit',
    intRangeMinimumLimit: 'IntRangeMinimumLimit',
    decimalRangeMinimumLimit: 'DecimalRangeMinimumLimit',
    decimalRangeMaximumLimit: 'DecimalRangeMaximumLimit',
    decimalRangePrecision: 'DecimalRangePrecision',
    searchableDictionaryPlaceholderText: 'SearchableDictionaryPlaceholderText',
    dictionaryVisibleElementsCount: 'DictionaryVisibleElementsCount'
};

export const attributeSettingsTypeEnum = {
    tag: 'Tag',
    color: 'Color',
    simpleDictionary: 'SimpleDictionary',
    searchableDictionary: 'SearchableDictionary',
    int: 'Int',
    decimal: 'Decimal',
    dateTime: 'DateTime'
};

export const attributeAffiliationEnum = {
    attribute: 'Attribute',
    categoryFeature: 'CategoryFeature'
};

export const attributeNumericTypeEnum = {
    1: 'Tag',
    2: 'Dictionary',
    3: 'Int',
    4: 'Decimal',
    5: 'DateTime',
    6: 'Color',
    8: 'String'
};

export const attributeAffiliationNumericEnum = {
    attribute: 1,
    feature: 2,
};

export const attributeStatusEnum = {
    0: 'Editing',
    1: 'Published'
};