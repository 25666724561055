/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDiscountCodes, generateCodes } from '../../../../redux/methods/discounts';
import useFetch from '../../../../hooks/useFetch';
import useSearchConfig from '../../../../hooks/useSearchConfig';

import { Container, Row, Button } from 'reactstrap';
import { CSVLink  } from 'react-csv';

import CodesTable from '../../../../components/tables/CodesTable';
import ModalWithFinalForm from '../../../../components/modals/ModalWithFinalForm';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import Paginator from '../../../../components/Paginator';

import { codesPropTypes, stringOrNumberPropTypes } from '../../../../propTypes';

import { discountCodesSearchConfig } from '../../../../config/searchConfigs';

import styles from './index.module.scss';

const propTypes = {
    id: stringOrNumberPropTypes,
    codes: PropTypes.arrayOf(codesPropTypes),
    getDiscountCodes: PropTypes.func,
    generateCodes: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const DiscountEditCodes = ({ id, getDiscountCodes, generateCodes, forceRefetch }) => {
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const [ searchConfig, changePage ] = useSearchConfig({ ...discountCodesSearchConfig, discountId: id });

    const [ codesToExport ] = useFetch(getDiscountCodes, { ...searchConfig, countRows: false, pageSize: 0 });
    const [ codes, pages ] = useFetch(getDiscountCodes, searchConfig, [searchConfig], forceRefetch);

    useEffect(() => {
        return () => {
            setIsModalOpen(false);
        };
    }, [codes]);

    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.codes;

    const handleOpenModalAction = () => {
        setIsModalOpen(true);
    };

    const handleGenerateCodes = formData => {
        generateCodes({...formData, discountId: id });
    };

    return codesToExport && codes ? (
        <React.Fragment>
            <Container fluid>
                <CodesTable
                    codes={codes}
                    emptyTableFiller={translations.emptyTableFiller}
                    headerLabels={translations.headerLabels}
                />
                {
                    pages > 1 && <Paginator
                            currentPage={searchConfig.curentPage + 1}
                            totalPages={pages}
                            onPageChange={changePage}
                        />
                }
                <aside className={clsx('mt-3', styles.footer)}>
                    <CSVLink data={codesToExport} separator=';'>
                        <Button
                            outline
                            type='button'
                            color='success'
                        >
                            <i className='fa fa-table mr-2'/>
                            <span>{translations.buttons.export}</span>
                        </Button>
                    </CSVLink>
                    <Button
                        color='primary'
                        onClick={handleOpenModalAction}
                    >
                        <i className='fa fa-plus mr-2'/>
                        <span>{translations.buttons.generate}</span>
                    </Button>
                </aside>
            </Container>
            <ModalWithFinalForm
                buttons={{
                    cancel: {
                        label: translations.generateModal.buttons.cancel,
                    },
                    confirm: {
                        label: translations.generateModal.buttons.confirm,
                    }
                }}
                header={translations.generateModal.header}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                onConfirm={handleGenerateCodes}
                modalBody={
                    <React.Fragment>
                        <Row>
                            <FormFieldTextOrNumber
                                type='number'
                                min={1}
                                name='howLongCode'
                                label={translations.generateModal.inputs.howLongCode}
                                required={true}
                                className='w-100'
                            />
                        </Row>
                        <Row className='mt-3'>
                            <FormFieldTextOrNumber
                                type='number'
                                min={1}
                                name='howManyCodes'
                                label={translations.generateModal.inputs.howManyCodes}
                                required={true}
                                className='w-100'
                            />
                        </Row>
                        <Row className='mt-3'>
                            <FormFieldTextOrNumber
                                type='number'
                                min={1}
                                name='howManyUsagesPerCode'
                                label={translations.generateModal.inputs.howManyUsagesPerCode}
                                required={true}
                                className='w-100'
                            />
                        </Row>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    ) : null;
};

DiscountEditCodes.propTypes = propTypes;

const mapStateToProps = (state) => ({
    id: state.discounts.discountToEdit.data.discountId,
    forceRefetch: state.refetchers.discountsCodes,
});

const mapDispatch = {
    getDiscountCodes,
    generateCodes,
};

export default connect(mapStateToProps, mapDispatch)(DiscountEditCodes);