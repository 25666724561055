/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Form as FinalForm, Field } from "react-final-form";
import { Form, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";

import FormFieldTranslations from '../../../../../components/formFields/FormFieldTranslations';
import Tabulator from "../../../../../components/Tabulator";

import { formUtilsHocPropTypes } from "../../../../../propTypes";
import ListElement from "./ListElement";

import useFetch from "../../../../../hooks/useFetch";
import { withFormUtils } from '../../../../../hoc/withFormUtils';
import { generateFormInitialValues, generateFormToSubmitValues } from '../../../../../utils/components';

import { getProductImage, changeProductImageData } from "../../../../../redux/methods/products";
import getConfig from '../../../../../getConfig';


const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    productId: PropTypes.string.isRequired,
    imageId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool,
    closeEditModal: PropTypes.func,
    getProductImage: PropTypes.func,
    changeProductImageData: PropTypes.func,
};

const EditImageModal = ({
    utils,
    productId,
    imageId,
    isOpen,
    closeEditModal,
    getProductImage,
    changeProductImageData
}) => {
    const [ data ] = useFetch(getProductImage, { productId, imageId }, [ productId, imageId ]);

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;
    const imageUrl = data ? `${apiUrl}${data.source}` : null;
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.photos.editModal;

    const toggleEditModal = () => {
        closeEditModal();
    };

    const config = {
        translations: ['alternativeText'],
        imageAlignments: ['imageAlignment']
    };

    const onSubmit = formData => {
        changeProductImageData(generateFormToSubmitValues(formData, config, utils.allLanguages()));
        closeEditModal();
    };

    return data ? (
        <Modal isOpen={isOpen} toggle={toggleEditModal}>
            <ModalHeader toggle={toggleEditModal}>{translations.header}</ModalHeader>
            <FinalForm
                onSubmit={onSubmit}
                initialValues={generateFormInitialValues(data, config, utils.allLanguages())}
                render={({handleSubmit, form, values}) => {
                    return (
                        <Form onSubmit={handleSubmit} className="w-100 p-0">
                            <ModalBody>
                                <ListElement type="tile" values={values} imageUrl={imageUrl}/>
                                <div className='mt-3'>
                                    <FormFieldTranslations
                                        form={form}
                                        modalHeader={translations.inputWithFlagsLabel}
                                        propertyName='alternativeText'
                                        currentIso={utils.currentLanguage()}
                                        label={translations.inputWithFlagsLabel}
                                    />
                                    <span className="d-block mt-3">
                                        <span>{translations.inputNameLabel}</span>
                                        <Field name="originalName">
                                            {({ input }) =>
                                                <Input
                                                    type="text"
                                                    name={input.name}
                                                    value={input.value}
                                                    onChange={input.onChange}
                                                    className="mt-1"
                                                />
                                            }
                                        </Field>
                                    </span>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" type="submit">
                                    {translations.buttons.confirm}
                                </Button>
                                <Button color="secondary" type="button" onClick={toggleEditModal}>
                                    {translations.buttons.cancel}
                                </Button>
                            </ModalFooter>
                        </Form>
                    );
                }}
            />
        </Modal>
    ) : null;
};

EditImageModal.propTypes = propTypes;

const mapDispatch = {
    getProductImage,
    changeProductImageData
};

export default withFormUtils(connect(null, mapDispatch)(EditImageModal));