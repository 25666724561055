import React from 'react';
import PropTypes from 'prop-types';

import { generateEditLinkForAgreement, generateAgreementsStatuses, pickIsRequiredColor } from '../../../utils/agreements';

import { agreementIsRequiredEnum } from '../../../enums/agreements';

import { agreementPropTypes } from '../../../propTypes';

import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    agreements: PropTypes.arrayOf(agreementPropTypes),
    agreementIsActiveText: PropTypes.shape({
        active: PropTypes.string,
        inactive: PropTypes.string,
    }),
    agreementIsRequiredText: PropTypes.shape({
        required: PropTypes.string,
        optional: PropTypes.string,
    }),
    translations: PropTypes.shape({
        deleteModalText: PropTypes.shape({
            header: PropTypes.string,
            body: PropTypes.string,
            confirm: PropTypes.string,
            cancel: PropTypes.string,
        }),
        tooltipText: PropTypes.shape({
            deleteTooltip: PropTypes.string,
            editTooltip: PropTypes.string,
        }),
    }),
    headerLabels: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        isActive: PropTypes.string,
        isRequired: PropTypes.string,
        actions: PropTypes.string,
    }).isRequired,
    removeAction: PropTypes.func.isRequired,
    emptyPlaceholders: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        table: PropTypes.table,
    }),
    history: PropTypes.object
};

const AgreementsTable = ({
    agreements,
    headerLabels,
    removeAction,
    emptyPlaceholders,
    history,
    translations,
    updateAction,
    checkedAgreementsIds,
    setCheckedAgreementsIds
}) => {

    const deleteAction = (id) => () => {
        removeAction(id);
    };

    const handleDefaultAction = (id) => () => {
        history.push(generateEditLinkForAgreement(id));
    };

    const isRequiredStatuses = generateAgreementsStatuses(agreementIsRequiredEnum, translations.statuses, pickIsRequiredColor);

    const handleUpdateStatus = (id, status) => {
        const objectToSend = {
            agreementsId: [ id ],
            isActive: status,
        };
        updateAction(objectToSend);
    };

    const handleUpdateSelected = (status) => async () => {
        const objectToSend = {
            agreementsId: checkedAgreementsIds,
            isActive: status,
        };

        await updateAction(objectToSend);
        setCheckedAgreementsIds([]);
    };

    return (
        <TableGenerator
            items={agreements}
            editLink={generateEditLinkForAgreement}
            actions={{deleteAction}}
            defaultAction={handleDefaultAction}
            expectedActions={['edit', 'delete']}
            translations={translations}
            checkedItemsIds={checkedAgreementsIds}
            setCheckedItemsIds={setCheckedAgreementsIds}
            customButtons={[
                {
                    labelTrue: translations.buttons.activate,
                    labelFalse: translations.buttons.deactivate,
                    action: handleUpdateSelected,
                    type: 'selector',
                    isDisabled: checkedAgreementsIds.length < 1,
                },
            ]}
            columns={[
                {
                    type: 'checkbox'
                },
                {
                    header: headerLabels.name,
                    value: 'name',
                    filler: emptyPlaceholders.name
                },
                {
                    header: headerLabels.type,
                    value: 'agreementTypes',
                    filler: emptyPlaceholders.type
                },
                {
                    header: headerLabels.isActive,
                    value: 'isActive',
                    type: 'switchWithAction',
                    onSwitch: handleUpdateStatus,
                },
                {
                    header: headerLabels.isRequired,
                    value: 'isRequired',
                    type: 'badge',
                    config: isRequiredStatuses,
                },
                {
                    header: headerLabels.actions,
                    type: 'actions'
                },
            ]}
        />
    );
};

AgreementsTable.propTypes = propTypes;

export default AgreementsTable;