import React from 'react';
import PropTypes from 'prop-types';
import { updateMediaFiles } from '../../../../../../redux/methods/mediaLibrary';

import { Form as FinalForm } from 'react-final-form';
import { Button, Container, Form, Row } from 'reactstrap';
import FormFieldTextOrNumber from '../../../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldTranslations from '../../../../../../components/formFields/FormFieldTranslations';
import { withFormUtils } from '../../../../../../hoc/withFormUtils';
import FormFieldTextarea from '../../../../../../components/formFields/FormFieldTextarea';
import FormFieldTextWithCopy from '../../../../../../components/formFields/FormFieldTextWithCopy';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

const propTypes = {
    fileData: PropTypes.object,
    utils: PropTypes.object,
    updateMediaFiles: PropTypes.func,
    changeFileName: PropTypes.func,
    isVirtual: PropTypes.bool,
};

const EditForm = ({ utils, fileData, updateMediaFiles, changeFileName, isVirtual }) => {
    const translations = useIntl().messages.mediaLibrary.modal.form;

    const config = { translations: ['title', 'alternativeText'] };

    const handleOnSubmit = async formData => {
        await updateMediaFiles({ ...utils.generateFormToSubmitValues(formData, config), fileId: formData.id });
        changeFileName(fileData.id, formData.fileName);
    };

    const openInNewTab = url => () => {
        const win = window.open(url, '_blank');
        win.focus();
    };

    const htmlIndex = fileData.html.findIndex(html => html.iso.toLowerCase() === utils.currentLanguage());
    return (
        <FinalForm
            initialValues={utils.generateFormInitialValues(fileData, config)}
            onSubmit={handleOnSubmit}
            render={({ handleSubmit, form, values }) => {
                return (
                    <Container>
                        <Form onSubmit={handleSubmit}>
                            <Row className='mb-3'>
                                <FormFieldTextOrNumber
                                    name='fileName'
                                    label={translations.name}
                                    className='w-100 mr-3'
                                    disabled={isVirtual}
                                />
                            </Row>
                            <Row className='mb-3'>
                                <FormFieldTranslations
                                    form={form}
                                    label={translations.alternativeText}
                                    propertyName='alternativeText'
                                    modalHeader={translations.alternativeText}
                                    currentIso={utils.currentLanguage()}
                                    className='mr-3'
                                    disabled={isVirtual}
                                />
                            </Row>
                            <Row className='mb-3'>
                                <FormFieldTranslations
                                    form={form}
                                    label={translations.title}
                                    propertyName='title'
                                    modalHeader={translations.title}
                                    currentIso={utils.currentLanguage()}
                                    className='mr-3'
                                    disabled={isVirtual}
                                />
                            </Row>
                            <Row className='mb-3'>
                                <FormFieldTextarea
                                    name='description'
                                    label={translations.description}
                                    className='w-100 mr-3'
                                    disabled={isVirtual}
                                />
                            </Row>
                            <Row className='mb-3'>
                                <Button disabled={isVirtual} color='success'>{translations.save}</Button>
                            </Row>
                            <Row className='mb-3'>
                                <FormFieldTextWithCopy
                                    name='path'
                                    label={translations.link}
                                    className='mr-3'
                                    open={openInNewTab(values.path)}
                                />
                            </Row>
                            <Row className='mb-3'>
                                <FormFieldTextWithCopy
                                    name={`html[${htmlIndex}].value`}
                                    label={translations.html}
                                    className='mr-3'
                                />
                            </Row>
                        </Form>
                    </Container>
                );
            }}
        />
    );
};

EditForm.propTypes = propTypes;

const mapDispatch = {
    updateMediaFiles,
};

export default withFormUtils(connect(null, mapDispatch)(EditForm));