import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getFooterSettingsPhotoWithText, updateFooterSettingsPhotoWithText, getFooterImage, updateFooterImage } from '../../../../../redux/methods/menu';

import useFetch from '../../../../../hooks/useFetch';
import { withFormUtils } from '../../../../../hoc/withFormUtils';

import { Form as FinalForm } from 'react-final-form';
import { Row, Col, Container, Form, Card, CardBody, CardHeader } from 'reactstrap';
import FormFieldTranslationsQuill from '../../../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldWithCheckbox from '../../../../../components/formFields/FormFieldWithCheckbox';
import SectionEditFormButtons from '../../../../../components/SectionEditFormButtons';
import FileSender from '../../FileSender';

import { formUtilsHocPropTypes } from '../../../../../propTypes';
import Tabulator from '../../../../../components/Tabulator';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getFooterSettingsPhotoWithText: PropTypes.func,
    updateFooterSettingsPhotoWithText: PropTypes.func,
    getFooterImage: PropTypes.func,
    updateFooterImage: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const FooterSettings = ({
    utils,
    getFooterSettingsPhotoWithText,
    updateFooterSettingsPhotoWithText,
    getFooterImage,
    updateFooterImage,
    forceRefetch
}) => {
    const intl = useIntl();
    const translations = intl.messages.menuSettings.footer.sections.photoWithText;
    const [ imageFile, setImageFile ] = useState(null);

    const config = { translations: ['leftContentMobile', 'leftContentTablet', 'leftContentDesktop'] };

    const handleOnSubmit = async form => {
        await updateFooterSettingsPhotoWithText(utils.generateFormToSubmitValues(form, config), !!(imageFile && imageFile.generatedFile));

        if(imageFile && imageFile.generatedFile) {
            updateFooterImage(imageFile.generatedFile);
        }
    };

    const [ settings ] = useFetch(getFooterSettingsPhotoWithText, null, [], forceRefetch);
    const [ footerImageFile ] = useFetch(getFooterImage, null, [], forceRefetch);

    if (settings && footerImageFile) {
        settings.footerImageFile = footerImageFile.footerImageFile;
    }

    return settings ? (
        <Container className='px-3' fluid>
            <FinalForm
                initialValues={utils.generateFormInitialValues(settings, config)}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row style={{marginBottom: '1.5em'}}>
                                <Col lg="6">
                                    <Card style={{height: '100%'}}>
                                        <CardHeader>{translations.inputs.showCaption}</CardHeader>
                                        <CardBody>
                                            <FormFieldWithCheckbox name='showPhotoWithText' label={translations.inputs.showPhotoWithText} />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="6">
                                    <Card style={{height: '100%'}}>
                                        <CardHeader>{translations.inputs.fileSender.caption}</CardHeader>
                                        <CardBody>
                                            <FileSender
                                                translations={translations.inputs.fileSender}
                                                setFile={setImageFile}
                                                image={values.footerImageFile}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card>
                                <CardHeader>{translations.inputs.leftContent}</CardHeader>
                                <CardBody>
                                    <Tabulator
                                        listOfElements={[
                                            {
                                                label: 'Mobile',
                                                id: 'leftContent-mobile',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='leftContentMobile'
                                                    />
                                            },
                                            {
                                                label: 'Tablet',
                                                id: 'leftContent-tablet',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='leftContentTablet'
                                                    />
                                            },
                                            {
                                                label: 'Desktop',
                                                id: 'leftContent-desktop',
                                                component: <FormFieldTranslationsQuill
                                                        form={form}
                                                        utils={utils}
                                                        propertyName='leftContentDesktop'
                                                    />
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                            <SectionEditFormButtons className='px-2' formReset={form.reset} />
                        </Form>
                    );
                }}
            />
        </Container>
    ) : null;
};

FooterSettings.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.bottomMenu,
});

const mapDispatch = {
    getFooterSettingsPhotoWithText,
    updateFooterSettingsPhotoWithText,
    getFooterImage,
    updateFooterImage
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(FooterSettings));