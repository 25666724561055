const mapLeaflet = {
    buttons: {
        setMarkerAtYourLocation: '[EN] Ustaw znacznik w swojej lokalizacji',
        deleteMarker: '[EN] USUŃ'
    },
    inputs: {
        markerName: '[EN] Nazwa znacznika',
        markerContent: '[EN] Treść znacznika',
        googleMapsRedirect: '[EN] Czy pokazać przekierowanie do Google Maps?'
    }
};

export default mapLeaflet;