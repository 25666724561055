import { tradeRoutes } from './sections/trade';
import { articlesAndOrdersRoutes } from './sections/articlesAndOrders';
import { menuRoutes } from './sections/menu';
import { graphicsRoutes } from './sections/graphics';
import { communicationRoutes } from './sections/communication';
import { rodoRoutes } from './sections/rodo';
import { languagesAndCountriesRoutes } from './sections/languagesAndCountries';
import { integrationsRoutes } from './sections/integrations';
import { seoRoutes } from './sections/seo';
import { globalSettingsRoutes } from './sections/globalSettings';
import { panelUsersRoutes } from './sections/panelUsers';
import { extrasRoutes } from './sections/extras';

export const adminRoutes = {
    ...tradeRoutes,
    ...articlesAndOrdersRoutes,
    ...menuRoutes,
    ...graphicsRoutes,
    ...communicationRoutes,
    ...rodoRoutes,
    ...languagesAndCountriesRoutes,
    ...integrationsRoutes,
    ...seoRoutes,
    ...globalSettingsRoutes,
    ...panelUsersRoutes,
    ...extrasRoutes
};