/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getProducts, deleteProductVariants, updateProductVariants, updateVariantSettings } from '../../../../redux/methods/products';
import { productsSearchConfig } from '../../../../config/searchConfigs';
import { translateProductName } from '../../../../utils/products';

import { withFormUtils } from '../../../../hoc/withFormUtils';
import useSearchConfig from '../../../../hooks/useSearchConfig';
import useFetch from '../../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import ProductWithDeleteActionTable from '../../../../components/tables/ProductWithDeleteActionTable';
import ProductSearchModal from '../../ProductSearchModal';
import Paginator from '../../../../components/Paginator';
import VariantSettingsSelection from '../../SummaryProductEdition/VariantSettingsSelection';

import { Container, Button, Form } from 'reactstrap';

import styles from './index.module.scss';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';

const propTypes = {
    forceRefetch: PropTypes.bool,
    getProducts: PropTypes.func,
    deleteProductVariants: PropTypes.func,
    updateProductVariants: PropTypes.func,
    isConnectedToERP: PropTypes.bool,
    settings: PropTypes.object,
    updateVariantSettings: PropTypes.func
};

const ProductEditVariantsSection = ({
    utils,
    productId,
    forceRefetch,
    getProducts,
    deleteProductVariants,
    updateProductVariants,
    isConnectedToERP,
    settings,
    updateVariantSettings
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.variants;

    const [checkedProductsId, setCheckedProductsId] = useState([]);
    const [checkedProductsToAssignId, setCheckedProductsToAssignId] = useState([]);
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

    const [ searchConfig, changePage ] = useSearchConfig({ ...productsSearchConfig, variantId: productId, productVariantType: 'variant' });
    const [ products, pages ] = useFetch(getProducts, searchConfig, [searchConfig], forceRefetch);

    const translatedProducts = products && translateProductName(products, utils.currentLanguage());

    useEffect(() => {
        setCheckedProductsId([]);
        setCheckedProductsToAssignId([]);
        setIsSearchModalOpen(false);
    }, [products]);

    const handleOpenProductSearchModal = () => {
        setIsSearchModalOpen(true);
    };

    const handleOpenDeleteModal = () => {
        utils.confirm(
            () => deleteProductVariants({ productsIds: checkedProductsId, variantId: productId }),
            translations.deleteManyModal.header,
            translations.deleteManyModal.body
        );
    };

    const handleRemoveSingle = (id) => {
        const objectToSend = {
            variantId: productId,
            productsIds: [ id ]
        };

        deleteProductVariants(objectToSend);
    };

    const handleAssignProducts = () => {
        const objectToSend = {
            variantId: productId,
            productsIds: checkedProductsToAssignId
        };

        updateProductVariants(objectToSend);
    };

    const handleOnSubmit = (formData) => {
        const objectToSend = {
            ...formData.settings,
            variantId: productId
        };

        updateVariantSettings(objectToSend);
    };

    return products ? (
        <Container fluid>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={{
                    ...settings,
                    settings: {
                        displayUnavailableVariants: settings.displayUnavailableVariants,
                        displayMinStateInfo: settings.displayMinStateInfo
                    }
                }}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, values, form }) => {
                    return (
                        <Form onSubmit={handleSubmit} className='pb-3'>
                            <VariantSettingsSelection
                                label={translations.variantSettingsHeadline}
                                values={values}
                                isConnectedToERP={isConnectedToERP}
                                settingsLabels={translations.settingsLabels}
                            />
                            <ProductWithDeleteActionTable
                                translatedProductsList={translatedProducts}
                                checkedProductsId={checkedProductsId}
                                setCheckedProductsId={setCheckedProductsId}
                                removeAction={handleRemoveSingle}
                                deleteModal={translations.deleteModal}
                                tooltipTranslation={translations.tooltips}
                                fillers={translations.fallbacks}
                                emptyTable={translations.empty}
                            />
                            {
                                pages > 1 &&
                                    <Paginator
                                        totalPages={pages}
                                        currentPage={searchConfig.curentPage + 1}
                                        onPageChange={changePage}
                                    />
                            }
                            <div className={styles.footer}>
                                <Button outline type='button' onClick={handleOpenDeleteModal} color='danger' disabled={!checkedProductsId.length > 0}>
                                    <i className='fa fa-remove mr-2'/>
                                    <span>{translations.buttons.delete}</span>
                                </Button>
                                <Button color='primary' onClick={handleOpenProductSearchModal}>
                                    <i className='fa fa-plus mr-2'/>
                                    <span>{translations.buttons.add}</span>
                                </Button>
                            </div>
                            <SectionEditFormButtons formReset={form.reset} />
                        </Form>
                    );
                }}
            />
            <ProductSearchModal
                isOpen={isSearchModalOpen}
                setIsOpen={setIsSearchModalOpen}
                checkedProductsId={checkedProductsToAssignId}
                setCheckedProductsId={setCheckedProductsToAssignId}
                onConfirm={handleAssignProducts}
                additionalSearchConfig={{ attributeId: settings.variantAttributesIds, productVariantType: 'None' }}
            />
        </Container>
    ) : null;
};

ProductEditVariantsSection.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.productVariants,
    isConnectedToERP: state.appInit.isConnectedToERP
});

const mapDispatch = {
    getProducts,
    deleteProductVariants,
    updateProductVariants,
    updateVariantSettings
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ProductEditVariantsSection));

