import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Field } from 'react-final-form';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import styles from './index.module.scss';

const propTypes = {
    name: PropTypes.string,
    translations: PropTypes.shape({
        label: PropTypes.string,
        active: PropTypes.string,
        inactive: PropTypes.string,
    }).isRequired,
};

const FormFieldIsActiveDropdown = ({ name, translations }) => {
    const [ isOpen, setIsOpen ] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const changeIsActive = (active, onChange) => () => {
        onChange(active);
    };

    return (
        <Field name={name}>
            {
                props =>
                    <div className={styles.container}>
                        <span className="mr-2">{translations.label}</span>
                        <Dropdown
                            direction="down"
                            className={clsx(styles.dropdown, 'mr-2')}
                            isOpen={isOpen}
                            toggle={handleToggle}
                        >
                            <DropdownToggle caret color={clsx(props.input.value ? 'success' : 'danger')}>
                                {props.input.value ? translations.active : translations.inactive}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={changeIsActive(true, props.input.onChange)}>
                                    <span>{translations.active}</span>
                                </DropdownItem>
                                <DropdownItem onClick={changeIsActive(false, props.input.onChange)}>
                                    <span>{translations.inactive}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
            }
        </Field>
    );
};

FormFieldIsActiveDropdown.propTypes = propTypes;

export default FormFieldIsActiveDropdown;