import { createSlice } from '@reduxjs/toolkit';

import { productsSearchConfig } from '../../config/searchConfigs';

export const initialState = {
    newlyAddedAddidionalCategoryId: null,
    categoryAssigner: {
        searchConfig: {
            ...productsSearchConfig,
            categoryTypeId: 2
        },
        assignCategories: {
            categories: null ,
            categoryForAttributesId: null,
            id: null,
        },
    },
    isFillersDefined: false,
};

const products = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setNewlyAddedAddidionalCategoryId(state, action) {
            const { newlyAddedAddidionalCategoryId } = action.payload;

            state.newlyAddedAddidionalCategoryId = newlyAddedAddidionalCategoryId;
        },
        setSearchConfig(state, action) {
            const { searchConfig } = action.payload;

            state.categoryAssigner.searchConfig = { ...state.categoryAssigner.searchConfig, ...searchConfig };
        },
        setAssignCategories(state, action) {
            const { assignCategories } = action.payload;

            state.categoryAssigner.assignCategories = assignCategories;
        },
        setIsFillersDefined(state, action) {
            const { isFillersDefined } = action.payload;

            state.isFillersDefined = isFillersDefined;
        }
    }
});

export const { setNewlyAddedAddidionalCategoryId, setSearchConfig, setAssignCategories, setIsFillersDefined  } = products.actions;

export default products.reducer;