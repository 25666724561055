/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateDiscountCategories } from '../../../../../redux/methods/discounts';

import { handleCheckboxAction } from '../../../../../utils/components';

import CategoriesTable from '../../../../../components/tables/CategoriesTable';
import SectionAsideButtons from '../../../../../components/SectionAsideButtons';
import Paginator from '../../../../../components/Paginator';

import { stringOrNumberPropTypes } from '../../../../../propTypes';

import { withFormUtils } from '../../../../../hoc/withFormUtils';

const propTypes = {
    discountCategories: PropTypes.arrayOf(PropTypes.shape({
        categoryId: stringOrNumberPropTypes,
        categories: PropTypes.arrayOf(PropTypes.string),
    })),
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    updateDiscountCategories: PropTypes.func,
    discountToEditId: stringOrNumberPropTypes,
    changePage: PropTypes.func,
};

const CategoriesSection = ({
    utils, discountCategories, totalPages, currentPage,  changePage, updateDiscountCategories, discountToEditId
}) => {
    const intl = useIntl();
    const translations = intl.messages.discounts.edit.tabs.categoriesProducts;

    const [ checkedCategoriesIds, setCheckedCategoriesIds ] = useState([]);

    useEffect(() => {
        return () => {
            setCheckedCategoriesIds([]);
        };
    }, [discountCategories]);

    const handleCheckboxClick = id => {
        handleCheckboxAction(id, checkedCategoriesIds, setCheckedCategoriesIds);
    };

    const handleRemoveCategory = id => {
        updateDiscountCategories(discountToEditId, [id], true);
        changePage(0);
    };

    const handleSelectCategory = () => {
        utils.selectCategory(
            id => {
                updateDiscountCategories(discountToEditId, [id]);
                changePage(0);
            },
            translations.categoriesSection.selectModal.labels.dropdown
        );
    };

    const handleOpenUnassignModal = () => {
        utils.confirm(
            () => {
                updateDiscountCategories(discountToEditId, checkedCategoriesIds, true);
                changePage(0);
            },
            translations.deleteModalCategories.header,
            translations.deleteModalCategories.body
        );
    };

    const categoriesSectionButtons = {
        remove: {
            label: translations.buttons.categories.remove,
            action: handleOpenUnassignModal,
            isDisabled: checkedCategoriesIds.length === 0
        },
        add: {
            label: translations.buttons.categories.add,
            action: handleSelectCategory,
        }
    };

    return (
        <React.Fragment>
            <span className='text-success font-weight-bold d-block mb-2 mt-3'>{translations.headers.categories}</span>
            <CategoriesTable
                categories={discountCategories}
                checkedCheckboxList={checkedCategoriesIds}
                columnNames={translations.table.columnNames}
                tooltipTranslation={translations.table.tooltip}
                emptyTranslation={translations.table.placeholder}
                deleteModalTranslations={translations.table.deleteModal}
                checkboxAction={handleCheckboxClick}
                removeAction={handleRemoveCategory}
                isGreenOnHover={true}
            />
            {
                totalPages > 1 &&
                    <Paginator
                        totalPages={totalPages}
                        currentPage={currentPage + 1}
                        onPageChange={changePage}
                    />
            }
            <SectionAsideButtons
                buttons={categoriesSectionButtons}
            />
            <hr className='border-danger d-block my-5'/>
        </React.Fragment>
    );
};

CategoriesSection.propTypes = propTypes;

const mapStateToProps = state => ({
    discountToEditId: state.discounts.discountToEdit.id,
});

const mapDispatch = {
    updateDiscountCategories,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(CategoriesSection));
