import React from "react";
import { SortableHandle } from "react-sortable-hoc";

import CheckboxCell from '../../../generators/TableGenerator/cells/CheckboxCell';

import styles from '../index.module.scss';
import clsx from "clsx";

const RowHandler = SortableHandle(() =>
    <td className={clsx(styles.cell, styles.drag)}>
        <i className="fa fa-arrows fa-lg" aria-hidden="true"></i>
    </td>
);

const TableRow = ({
    device,
    type,
    getNameFunction,
    param,
    handleCheckboxAction,
    isCheckedLimitReached
}) => {
    return (
        <tr>
            <RowHandler />
            <td className={styles.columnName}>
                {getNameFunction(param)}
            </td>
            <CheckboxCell
                itemId={param.tag}
                isChecked={param.parameters[type][device].show}
                checkboxAction={handleCheckboxAction(param.tag, type)}
                className={styles.columnCheckbox}
                disabled={isCheckedLimitReached && !param.parameters[type][device].show}
            />
        </tr>
    );
};

export default TableRow;