export const generateEditLinkForAgreement = id => `/agreements/list/edit?agreementID=${id}`;

export const pickIsRequiredColor = (isRequired) => {
    switch(isRequired){
        case 'required': return 'success';
        case 'notRequired': return 'danger';
        default: return null;
    }
};

export const pickIsActiveColor = (isActive) => {
    switch(isActive){
        case 'active': return 'success';
        case 'inactive': return 'danger';
        default: return null;
    }
};

export const pickIsModifiedColor = (isModified) => {
    switch(isModified){
        case 'modified': return 'danger';
        case 'notModified': return 'secondary';
        default: return null;
    }
};

export const generateAgreementsStatuses = (agreementsPropertyEnum, translations, colorConfig) => {
    return Object.keys(agreementsPropertyEnum).map(key => ({
        id: parseInt(key),
        label: translations[agreementsPropertyEnum[key]],
        color: colorConfig(agreementsPropertyEnum[key])
    }));
};

export const pickStatusColor = status => {
    switch(status){
        case 'agree': return 'success';
        case 'disagree': return 'secondary';
        case 'remove': return 'danger';
        default: return null;
    }
};