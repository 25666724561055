import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getExchangeRate } from '../../redux/methods/exchangeRates';

import useFetch from '../../hooks/useFetch';

import ExchangeRateEditBasic from '../../features/exchangeRates/editTabs/ExchangeRateEditBasic';
import SettingsTabulator from '../../components/layouts/SettingsTabulator';
import DefaultSpinner from '../../components/loaders/DefaultSpinner';

const propTypes = {
    location: PropTypes.object,
    storeCurrentLanguage: PropTypes.string,
    forceRefetch: PropTypes.bool,
    isLoading: PropTypes.bool,
    getExchangeRate: PropTypes.func,
};

const ExchangeRatesEdit = ({ location, getExchangeRate, forceRefetch, isLoading }) => {
    const intl = useIntl();
    const translations = intl.messages.exchangeRates.edit;
    /**
     * Deconstruct query params
     */
    const query = new URLSearchParams(location.search);
    const chosenExchangeRateId = query.get('rateID');

    const [ exchangeRateToEdit ] = useFetch(getExchangeRate, chosenExchangeRateId , [], forceRefetch);

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'exchangeRateEditTab-basic':
                return <ExchangeRateEditBasic exchangeRate={exchangeRateToEdit} id={chosenExchangeRateId} />;
            default:
                return null;
        }
    };

    return chosenExchangeRateId ? (
        exchangeRateToEdit ? (
            <SettingsTabulator
                title={{
                    iconClassName: 'fa fa-edit text-primary ml-3',
                }}
                tabulatorHeader={{
                    label: translations.tabSelectorTitle,
                    iconClassName: 'fa fa-align-justify'
                }}
                refreshTabComponent={refreshTabComponent}
                tabs={[
                    {
                        id: 'exchangeRateEditTab-basic',
                        header:  {
                            label: translations.tabs.basicData.title,
                            iconClassName: 'fa fa-tasks'
                        }
                    },
                ]}
            />
        ) : (
            <DefaultSpinner isLoading={isLoading} />
        )
    ) : (
        <Redirect to='/exchangeRates'/>
    );
};

ExchangeRatesEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    forceRefetch: state.refetchers.exchangeRates,
    isLoading: state.loaders.exchangeRates
});

const mapDispatch = {
    getExchangeRate
};

export default withRouter(connect(mapStateToProps, mapDispatch)(ExchangeRatesEdit));