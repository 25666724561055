import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getHomepageGeneralSettings, updateHomepageGeneralSettings } from '../../../redux/methods/homePage';

import useFetch from '../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Row, Form } from 'reactstrap';

import ContainerWithCard from '../../../components/ContainerWithCard';
import FormFieldColorPicker from '../../../components/formFields/FormFieldColorPicker';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';

import { withFormUtils } from '../../../hoc/withFormUtils';

import { formUtilsHocPropTypes } from '../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getHomepageGeneralSettings: PropTypes.func,
    updateHomepageGeneralSettings: PropTypes.func
};

const HomePageGeneralSettings = ({ utils, getHomepageGeneralSettings, updateHomepageGeneralSettings }) => {
    const intl = useIntl();
    const translations = intl.messages.homePage.general;

    const [ generalData ] = useFetch(getHomepageGeneralSettings);

    const config = {  };

    const handleOnSubmit = formData => {
        updateHomepageGeneralSettings(utils.generateFormToSubmitValues(formData, config));
    };

    return generalData ? (
        <ContainerWithCard title={translations.title} iconClass='cil-cog'>
            <FinalForm
                onSubmit={handleOnSubmit}
                initialValues={utils.generateFormInitialValues(generalData, config)}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row className='my-3'>
                                <FormFieldColorPicker
                                    name="background"
                                    label={translations.labels.backgroundColor}
                                    button={translations.buttons.select}
                                />
                            </Row>
                            <SectionEditFormButtons formReset={form.reset}/>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : null;
};

HomePageGeneralSettings.propTypes = propTypes;

const mapDispatch = {
    getHomepageGeneralSettings,
    updateHomepageGeneralSettings
};

export default withFormUtils(connect(null, mapDispatch)(HomePageGeneralSettings));