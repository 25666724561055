import { cAPI } from '../../../utils/cAPI';
import { getData, removeData, addEditData } from '../generic';
import { clearError } from '../../../features/errors/slice';

export const getExchangeRates = searchConfig => dispatch => getData(dispatch, cAPI.APGetExchangeRates, searchConfig, 'exchangeRates');
export const getExchangeRate = id => dispatch => getData(dispatch, cAPI.APGetExchangeRate, id, 'exchangeRates');

export const editExchangeRate = formData => dispatch  => addEditData(dispatch, cAPI.APAddEditExchangeRate, formData);

export const removeExchangeRate = exchangeRateId => dispatch => removeData(dispatch, cAPI.APRemoveExchangeRate, exchangeRateId, 'exchangeRates');

export const clearErrorModal = () => dispatch => {
    dispatch(clearError());
};
