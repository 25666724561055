import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { onHoverAnimationButtonConfig, buttonIconsConfig } from '../../config';

import styles from './index.module.scss';

const propTypes = {
    button: PropTypes.shape({
        font: PropTypes.string,
        fontSize: PropTypes.number,
        fontColor: PropTypes.string,
        isFontBold: PropTypes.bool,
        icon: PropTypes.string,
        iconPosition: PropTypes.oneOf(['right', 'left']),
        buttonVerticalSize: PropTypes.number,
        buttonHorizontalSize: PropTypes.number,
        borderSize: PropTypes.number,
        borderRadius: PropTypes.number,
        borderColor: PropTypes.string,
        isTransparent: PropTypes.bool,
        shadowHorizontalPosition: PropTypes.number,
        shadowVerticalPosition: PropTypes.number,
        shadowBlurRadius: PropTypes.number,
        shadowSpreadRadius: PropTypes.number,
        onHoverAnimationId: PropTypes.number,
    })
};

const ButtonPreview = ({
    button,
    previewTranslation,
    displayInContainer,
}) => {
    const buttonStyles = {
        outline: 'none',
        fontSize: `${button.fontSize || 30}px`,
        color: button.fontColor,
        fontWeight: button.isFontBold ? 700 : 300,
        padding: `${button.buttonVerticalSize}px ${button.buttonHorizontalSize}px`,
        borderWidth: `${button.borderSize}px`,
        borderColor: button.borderColor,
        borderStyle: 'solid',
        borderRadius: `${button.borderRadius}px`,
        backgroundColor: button.isTransparent ? 'transparent' : `${button.backgroundColor}`,
        boxShadow: `${button.shadowVerticalPosition}px ${button.shadowHorizontalPosition}px ${button.shadowBlurRadius}px ${button.shadowSpreadRadius}px rgba(0, 0, 0, 1)`,
        transition: 'all 0.4s ease 0s',
        cursor: 'pointer'
    };

    const iconPositionsClass = {
        right: styles.iconPositionRight,
        left: styles.iconPositionLeft
    };

    const selectedAnimation = onHoverAnimationButtonConfig.find(option => option.id === button.onHoverAnimationId);
    const iconToDisplay = buttonIconsConfig.find(icon => icon.iconName === button.icon);

    return (
        <div className={displayInContainer && styles.container} style={{ background: button.previewBackgroundColor}}>
            <button
                style={buttonStyles}
                disabled
                className={
                    clsx(
                        button.font && `font-${button.font.toLowerCase()}`,
                        button.icon && (iconPositionsClass[button.iconPosition] || styles.iconPositionRight),
                        button.onHoverAnimationId && styles[selectedAnimation.label]
                    )
                }
                >
                <span>{previewTranslation}</span>
                { button.icon && <FontAwesomeIcon icon={iconToDisplay}/> }
            </button>
        </div>
    );
};

ButtonPreview.propTypes = propTypes;

export default ButtonPreview;