import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Field } from 'react-final-form';
import { Label } from 'reactstrap';

import { stringOrNumberPropTypes } from '../../../propTypes';

const propTypes = {
    name: PropTypes.string,
    label: stringOrNumberPropTypes,
    isDisabled: PropTypes.bool,
    mutator: PropTypes.func,
    className: PropTypes.string
};

const FormFieldWithCheckbox = ({
     name,
     label,
     isDisabled = false,
     mutator,
     id,
     className
}) => {
    return (
        <div id={id} className={clsx("form-check", className)}>
            <Field
                parse={value => {
                    if(mutator){
                        mutator();
                    }

                    return value;
                }}
                name={name}
                component='input'
                type='checkbox'
                disabled={isDisabled}
                id={name}
                className='form-check-input'
            />
            <Label for={name} className='form-check-label'>{label}</Label>
        </div>
    );
};

FormFieldWithCheckbox.propTypes = propTypes;

export default FormFieldWithCheckbox;