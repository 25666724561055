/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getFreshmail, updateFreshmail } from '../../../redux/methods/integrations';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';

import { Container, Form, Row } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';

import styles from './index.module.scss';

const propTypes = {
    getIntegrationFreshmail: PropTypes.func,
    updateIntegrationFreshmail: PropTypes.func,
};

const Freshmail = ({
    getFreshmail,
    updateFreshmail,
}) => {
    const intl = useIntl();
    const translations = intl.messages.integrations.freshmail;

    const [ settings ] = useFetch(getFreshmail);

    const handleOnSubmit = form => {
        updateFreshmail(form);
    };

    return settings ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-at'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={settings}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form, values }) => {
                    const generatedListOptions = settings.lists.map(list => ({
                        id: list.key,
                        label: list.value
                    }));

                    const currentSelectedList = generatedListOptions.find(list => list.id === values.selectedListId);
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>
                                <div className='mt-3'>
                                    <FormFieldSwitchPill
                                        label={translations.labels.enable}
                                        name={'enable'}
                                    />
                                </div>
																<div className='mt-3'>
                                    <FormFieldTextOrNumber
                                        className={styles.input}
                                        label={translations.labels.apiKey}
                                        name='apiKey'
                                    />
                                </div>
																<div className='mt-3'>
                                    <FormFieldTextOrNumber
                                        className={styles.input}
                                        label={translations.labels.secretApiKey}
                                        name='secretApiKey'
                                    />
                                </div>
																<div className='mt-3'>
                                    <FormFieldWithDropdown
                                        isDisabled={!values.enable}
                                        label={translations.labels.listDropdown}
                                        options={generatedListOptions}
                                        name='selectedListId'
                                        currentSelectionLabel={currentSelectedList ? currentSelectedList.label : translations.labels.dropdownSelectLabel}
                                    />
                                </div>
                                <SectionEditFormButtons formReset={form.reset} isSaveButtonDisabled={values.enable && !values.selectedListId} />
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : null;
};

const mapDispatch = {
    getFreshmail,
    updateFreshmail,
};

Freshmail.propTypes = propTypes;

export default connect(null, mapDispatch)(Freshmail);