import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { useIntl } from 'react-intl';
import clsx from "clsx";

import { Form, Field } from "react-final-form";
import {
    Form as ReactstrapForm,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import WarningModal from '../modals/WarningModal';

import TextInputWithFlags from "../TextInputWithFlags";

import styles from "./index.module.scss";

const propTypes = {
    header: PropTypes.string,
    isEditModalOpen: PropTypes.bool,
    setIsEditModalOpen: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formInputs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.name,
            label: PropTypes.string,
            chosenIso: PropTypes.string,
            initValue: PropTypes.string,
        })
    ).isRequired,
};

const EditFormModalWithTextInputFlags = ({ header, isEditModalOpen, setIsEditModalOpen, formInputs, onSubmit }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.editFormModalWithTextInputFlags;

    const isFormChanged = useRef();
    const [ isWarningModalOpen, setIsWarningModalOpen ] = useState(false);

    const handleSubmit = (formData) => {
        onSubmit(formData);
        setIsEditModalOpen(!isEditModalOpen);
    };

    const toggleModal = () => {
        if (isFormChanged.current) {
            setIsWarningModalOpen(true);
        }
        else {
            setIsEditModalOpen(!isEditModalOpen);
        }
    };

    const handleCancel = () => {
        setIsEditModalOpen(!isEditModalOpen);
    };

    const handleError = errors => errors ? console.error(errors) : null;
    const initValues = formInputs.reduce((acc, item) =>
        item && item.initValue ?
            { ...acc, [item.name]: item.initValue }
            : acc,
    {});

    return (
        <Modal isOpen={isEditModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
            <Form
                onSubmit={handleSubmit}
                initialValues={initValues}
                render={({ handleSubmit, submitError, pristine }) => {
                    isFormChanged.current = !pristine;

                    handleError(submitError);
                    return (
                        <ReactstrapForm onSubmit={handleSubmit}>
                            <ModalBody className="p-2 p-md-5">
                                {/* This div is require because only input place must be scrollable */}
                                <div className={clsx(styles.formWrapper)}>
                                    {formInputs.map((input, i) => {
                                        return (
                                            <Field name={input.name} key={input.name}>
                                                {props => (
                                                    <TextInputWithFlags
                                                        className={clsx(i !== 0 && "mt-4")}
                                                        /* textinputwithflags props*/
                                                        {...input}
                                                        /* react-final-form props */
                                                        inputProps={{
                                                            id: input.id,
                                                            name: props.input.name,
                                                            value: props.input.value,
                                                            onChange: props.input.onChange,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        );
                                    })}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" type="submit">
                                    {translations.save}
                                </Button>
                                <Button color="secondary" type="button" onClick={handleCancel}>
                                    {translations.cancel}
                                </Button>
                            </ModalFooter>
                            <WarningModal
                                setIsOpen={setIsWarningModalOpen}
                                isOpen={isWarningModalOpen}
                                setIsMainModalOpen={setIsEditModalOpen}
                            />
                        </ReactstrapForm>
                    );
                }}
            />
        </Modal>
    );
};

EditFormModalWithTextInputFlags.propTypes = propTypes;

export default EditFormModalWithTextInputFlags;