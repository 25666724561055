const orderStatuses = {
    formsTable: {
        title: "Statusy zamówień",
        headers: {
            name: 'Nazwa',
            description: 'Opis',
            erpOrderStatusName: 'Nazwa z ERP'
        },
        tooltips: {
            add: 'Dodaj status zamówienia',
            edit: 'Edytuj status zamówienia',
            remove: 'Usuń status zamówienia'
        },
        editModal:{
            title: 'Edycja statusu zamówienia',
            inputs:{
                name: 'Nazwa',
                description: 'Opis',
                erpOrderStatusId: 'Status zamówienia z systemu ERP',
                color: 'Kolor',
                colorButton: 'Wybierz',
            },
            buttons:{
                remove: 'WRÓĆ',
                save: 'ZAPISZ'
            },
            nameModalHeader: 'Edycja nazwy statusu zamówienia',
            descriptionModalHeader: 'Edycja opisu statusu zamówienia',
            tags: {
                paymenterror: 'Status zostanie ustawiony autonatycznie w przypadku, gdy płatność elektroniczna się nie powiedzie',
                new: 'Status zostanie ustawiony automatycznie dla nowego zamówienia, przed zainicjowaniem płatności elektronicznej',
                waitingforpayment: 'Status zostanie ustawiony automatycznie po zainicjowaniu płatności elektronicznej, ale przed jej zakończeniem',
                paymentrejected: 'Status zostanie ustawiony autonatycznie w przypadku, gdy użytkownik odrzuci płatność elektroniczną',
                completing: 'Status zostanie ustawiony automatycznie po pozytywnym zakończeniu płatności elektronicznej, bądź w przypadku wybrania płatności przy odbiorze',
                sent: 'Status SENT',
                rejected: 'Status REJECTED',
            }
        },
        deleteModal: {
            header: 'Usuń status zamówienia',
            body: 'Czy napewno chcesz usunąć wybrany status zamówienia?',
            confirm: 'Usuń',
            cancel: 'Wróć'
        }
    }
};

export default orderStatuses;