import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { FormGroup, Input } from 'reactstrap';

const propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
};

const FormFieldTextarea = ({ label, name, placeholder, disabled, className, rows = 5, inputClassName }) => {
    return (
        <div className={className}>
            <span>{label}</span>
            <Field name={name}>
                {
                    props => {
                        const isError = (props.meta.error || props.meta.submitError) && props.meta.touched;
                        return (
                            <React.Fragment>
                                <FormGroup>
                                    <Input
                                        className={inputClassName}
                                        type="textarea"
                                        rows={rows}
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                        placeholder={placeholder}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                                {isError && <p className='text-warning'>{props.meta.error || props.meta.submitError}</p>}
                            </React.Fragment>
                        );
                    }
                }
            </Field>
        </div>
    );
};

FormFieldTextarea.propTypes = propTypes;

export default FormFieldTextarea;