import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { Container, Form, Row, Input, InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';

import { updateAttributeSettings } from '../../../../../redux/methods/attributes';

import CheckboxWithTooltip from '../../../../../components/CheckboxWithTooltip';
import SectionEditFormButtons from '../../../../../components/SectionEditFormButtons';

import { withFormUtils } from '../../../../../hoc/withFormUtils';
import { formUtilsHocPropTypes } from '../../../../../propTypes';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    data: PropTypes.object,
    updateAttributeSettings: PropTypes.func
};

const IntSettings = ({ utils, data, updateAttributeSettings }) => {
    const intl = useIntl();
    const translations = intl.messages.attributes.edit.tabs.settings;

    const parse = function(v){
        const i = parseInt(v);
        return isNaN(i) ? null : i;
    };

    const [ minimumValue, setMinimumValue ] = useState(data.minimumValue);
    const [ maximumValue, setMaximumValue ] = useState(data.maximumValue);

    const handleSetMinimumValue = (e) => setMinimumValue(data.minimumValue = parse(e.target.value));
    const handleSetMaximumValue = (e) => setMaximumValue(data.maximumValue = parse(e.target.value));

    const handleOnSubmit = formData => {
        updateAttributeSettings(formData);
    };

    return (
        <React.Fragment>
            <Container fluid>
                <FinalForm
                    /** This weird key is here to ensure that the Form is remounted and reinitialised when needed */
                    key={`${data.attributeId}-EditSettingsSectionForm-${utils.currentLanguage()}`}
                    initialValues={{...data}}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='p-3 px-0' fluid>
                                    <Row>
                                        <div className="mx-3">
                                            <span>{translations.inputs.minNumberLabel}</span>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-calculator"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="number" value={minimumValue} onChange={handleSetMinimumValue} />
                                            </InputGroup>
                                        </div>
                                        <div className="mx-3">
                                            <span>{translations.inputs.maxNumberLabel}</span>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-calculator"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="number" value={maximumValue} onChange={handleSetMaximumValue}/>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Field name="isExpanded" type="checkbox">
                                            {
                                                props =>
                                                    <CheckboxWithTooltip
                                                        label={translations.inputs.isExpanded}
                                                        tooltipText={translations.inputs.isExpandedTooltip}
                                                        inputProps={{ ...props.input }}
                                                    />
                                            }
                                        </Field>
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset}/>
                            </Form>
                        );
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

IntSettings.propTypes = propTypes;

const mapDispatch = {
    updateAttributeSettings
};

export default withFormUtils(connect(null, mapDispatch)(IntSettings));
