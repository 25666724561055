import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormattedDate } from 'react-intl';

import { getTranslationForIso } from '../../../utils/components';
import { generateIsosFromNumericValues } from '../../../utils/storeLocale';
import { languageNumericEnum } from '../../../enums/locale';

import { Table, Row, ButtonGroup, Button } from "reactstrap";
import TableActionCell from "../TableActionCell";

import styles from "./index.module.scss";

const propTypes = {
    slides: PropTypes.arrayOf(PropTypes.object),
    translations: PropTypes.shape({
        columnNames: PropTypes.shape({
            alternativeText: PropTypes.string,
            actions: PropTypes.string,
        }),
        tooltipTranslations: PropTypes.shape({
            remove: PropTypes.string,
            edit: PropTypes.string,
        }).isRequired,
        emptyTable: PropTypes.string,
        deleteModalTranslations: PropTypes.shape({
            header: PropTypes.string,
            body: PropTypes.string,
            confirm: PropTypes.string,
            cancel: PropTypes.string
        }).isRequired,
    }),
    removeAction: PropTypes.func.isRequired,
    editAction: PropTypes.func.isRequired,
    changeSlideOrder: PropTypes.func,
};

const PromotionBarSlidesTable = ({
    slides,
    removeAction,
    translations,
    editAction,
    changeSlideOrder,
    updatePromotionBarSlides,
    currentLanguage,
}) => {
    const removeActionHandler = id => () => {
        removeAction(id);
    };
    const editActionHandler = id => () => {
        editAction(id);
    };

    const changeOrderHandler = (id, delta, slide) => () => {
        changeSlideOrder(id, delta, slide);
    };

    const handleActiveSwitch = (slide, isActive) => () => {
        const objectToSend = {
            ...slide,
            active: !isActive,
        };
        updatePromotionBarSlides(objectToSend);
    };

    return slides && slides.length > 0 ? (
        <Table responsive hover size="sm">
            <thead>
                <tr>
                    <th>
                        {translations.columnNames.order}
                    </th>
                    <th>
                        {translations.columnNames.content}
                    </th>
                    <th>
                        {translations.columnNames.activeInLanguage}
                    </th>
                    <th>
                        {translations.columnNames.active}
                    </th>
                    <th>
                        {translations.columnNames.dates}
                    </th>
                    <th className={styles.actionHeader}>
                        {translations.columnNames.actions}
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    slides.map((slide, index) => {

                        return (
                            <tr key={`slide-${slide.promotionBarId}`}>
                                <td className={clsx(styles.cell)}>
                                    <ButtonGroup vertical>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            disabled={index === 0}
                                            onClick={changeOrderHandler(slide.promotionBarId, -1, slide)}
                                        >
                                            <i className="cil-arrow-thick-top"></i>
                                        </Button>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            disabled={index === slides.length - 1}
                                            onClick={changeOrderHandler(slide.promotionBarId, 1, slide)}
                                        >
                                            <i className="cil-arrow-thick-bottom"></i>
                                        </Button>
                                    </ButtonGroup>
                                </td>
                                <td className={clsx(styles.cell, styles.contentCell)}>
                                    <div dangerouslySetInnerHTML={{ __html: getTranslationForIso(slide.content, currentLanguage) }} />
                                </td>
                                <td className={clsx(styles.cell)}>
                                    {generateIsosFromNumericValues(slide.activeInLanguage, languageNumericEnum)}
                                </td>
                                <td className={clsx(styles.cell)}>
                                    <label className="switch switch-pill switch-success m-0">
                                        <input
                                            type="checkbox"
                                            className="switch-input"
                                            checked={slide.active}
                                            onChange={handleActiveSwitch(slide, slide.active)}
                                        />
                                        <span
                                            className={clsx("switch-slider", !slide.active &&  "bg-danger border-danger")}
                                        ></span>
                                    </label>
                                </td>
                                <td className={clsx(styles.cell)}>
                                    {
                                        slide.dateFrom
                                            ? <FormattedDate value={slide.dateFrom} day='2-digit' month='2-digit' year='numeric' />
                                            : null
                                    }
                                    <span>-</span>
                                    {
                                        slide.dateTo
                                            ? <FormattedDate value={slide.dateTo} day='2-digit' month='2-digit' year='numeric' />
                                            : null
                                    }
                                </td>
                                <td className={clsx(styles.cell)}>
                                    <TableActionCell
                                        itemId={slide.productImageId}
                                        actions={{
                                            edit: {
                                                tooltipTranslation: translations.tooltipTranslations.edit,
                                                action: editActionHandler(slide),
                                            },
                                            delete: {
                                                action: removeActionHandler(slide.promotionBarId),
                                                modalTranslations: translations.deleteModalTranslations,
                                                tooltipTranslation: translations.tooltipTranslations.remove
                                            },
                                        }}
                                    />
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    ) : (
        <Row className="d-flex justify-content-center mt-4 mb-4">
            {translations.emptyTable}
        </Row>
    );
};

PromotionBarSlidesTable.propTypes = propTypes;

export default PromotionBarSlidesTable;
