import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    persistedChosenLanguage: null,
};

const persistedLocale = createSlice({
    name: 'persistedLocale',
    initialState,
    reducers: {
        setPersistedChosenLanguage(state, action) {
            const { persistedChosenLanguage } = action.payload;

            state.persistedChosenLanguage = persistedChosenLanguage;
        },
    }
});

export const { setPersistedChosenLanguage } = persistedLocale.actions;

export default persistedLocale.reducer;