export const itemCountOptions = [20, 40, 60];

export const typeOfUnitPresentationOptions = [
    'Name', 'Shortcut'
];

export const productTileFillerEmptyObject = {
    fillerId: null,
    isActive: false,
	fillerUrlTypeId: 1,
	redirectUrl: "",
	header: [
		{
			iso: 'Pl',
			value: null,
		},
		{
			iso: 'En',
			value: null,
		}
	],
	buttonLabel: [
		{
			iso: 'Pl',
			value: null,
		},
		{
			iso: 'En',
			value: null,
		}
	],
	description: [
		{
			iso: 'Pl',
			value: null,
		},
		{
			iso: 'En',
			value: null,
		}
	],
	imageAlternativeText: [
		{
			iso: 'Pl',
			value: null,
		},
		{
			iso: 'En',
			value: null,
		}
	]
};