import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { Field } from 'react-final-form';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import TextInputWithFlags from '../../TextInputWithFlags';

import styles from './index.module.scss';
import { withFormUtils } from '../../../hoc/withFormUtils';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    modalHeader: PropTypes.string,
    type: PropTypes.string,
    withRightMargin: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    translationObject: PropTypes.arrayOf(PropTypes.shape({
        iso: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
};

const FormFieldTranslationsNew = ({
    modalHeader,
    name,
    label,
    type = 'text',
    //withRightMargin = true,
    disabled = false,
    //className,
    utils,
    translationObject
}) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.editFormModalWithTextInputFlags;
    const currentIso = utils.currentLanguage();
    const [ isOpen, setIsOpen ] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const getIsoIndex = currentIso => translationObject.findIndex(({ iso }) => iso.toLowerCase() === currentIso.toLowerCase());

    const availableIsoList = translationObject.map(({ iso }) => iso);

    return (
        <React.Fragment>
            <Field key={`${name}`} name={`${name}[${getIsoIndex(currentIso.toLowerCase())}].value`}>
                {
                    props =>
                        <InputGroup style={{ position: 'relative', marginBottom: '5px'}}>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ textAlign: 'left', display: 'inline', minWidth: '30vw', maxWidth: '30vw', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    <span title={label}>{label}</span>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input id={name} {...props.input} type={type} />
                            <InputGroupAddon addonType="append" onClick={toggleModal}>
                                <InputGroupText><i className="fa fa-globe" aria-hidden="true" /></InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                        /*
                        <TextInputWithFlags
                            disabled={disabled}
                            id={`input${name.replace(/[\W_]+/g,"_")}`}
                            label={label}
                            className={clsx('w-100', withRightMargin && 'mr-md-3', className)}
                            chosenIso={currentIso}
                            inputProps={props.input}
                            isoList={availableIsoList}
                            onClickAdditionalFlags={toggleModal}
                            type={type}
                        />
                        */
                }
            </Field>
            <Modal isOpen={!disabled && isOpen} toggle={toggleModal}>
                <ModalHeader className={styles.header} toggle={toggleModal}>{modalHeader}</ModalHeader>
                <ModalBody className='p-3'>
                    <div className={clsx(styles.formWrapper)}>
                    {
                        availableIsoList
                            .map(item => {
                                return (
                                    <Field key={`${name}-${item}-modal`} name={`${name}[${getIsoIndex(item.toLowerCase())}].value`}>
                                        {
                                            props =>
                                                <TextInputWithFlags
                                                    id={`${item}-modal`}
                                                    label={label}
                                                    className='w-100 py-2 px-2'
                                                    chosenIso={item}
                                                    inputProps={props.input}
                                                    type={type}
                                                />
                                        }
                                    </Field>
                                );
                            })
                    }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={toggleModal} >
                        {translations.save}
                    </Button>
                    <Button color='secondary' onClick={toggleModal} >
                        {translations.cancel}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

FormFieldTranslationsNew.propTypes = propTypes;

export default withFormUtils(FormFieldTranslationsNew);