import Buttons from '../../views/visualSettings/Buttons';
import ButtonsEdit from '../../views/visualSettings/ButtonsEdit';
import GraphicsSettings from '../../views/visualSettings/GraphicsSettings';
import TemplateEdit from '../../views/visualSettings/LayoutSettings/TemplateEdit';
import Layout from '../../views/visualSettings/LayoutSettings';
import Logo from '../../views/visualSettings/Logo';
import Fonts from '../../views/extrasSettings/Fonts';
import PlaceholderImages from '../../views/visualSettings/PlaceholderImages';
import MediaLibrary from '../../views/MediaLibrary';
import PromotionBar from '../../views/extrasSettings/PromotionBar';
import HomePageSections from '../../views/homePageSettings/HomePageSections';
import CartPageSections from '../../views/cart/CartPageSections';
import HomePageGeneralSettings from '../../views/homePageSettings/HomePageGeneralSettings';

import SummaryPageSections from '../../views/registrationPageSettings/SummaryPageSections';
import ConfirmationPageSections from '../../views/registrationPageSettings/ConfirmationPageSections';

import MetatagsSection from '../../views/extrasSettings/MetatagsSection';
import SectionsEdit from '../../views/homePageSettings/SectionsEdit';

export const graphicsRoutes = {

    ROUTE_GRAPHICS_SETTINGS: {
        id: 'ROUTE_GRAPHICS_SETTINGS',
        path: '#',
        type: 'graphics',
        exact: true,
        icon: 'fal fa-pencil-paintbrush',
        children: [
            {
                id: 'ROUTE_GRAPHICS_SETTINGS_MEDIA_LIBRARY',
                path: '/media',
                exact: true,
                icon: 'fal fa-photo-video',
                component: MediaLibrary,
            },
            {
                id: 'ROUTE_GRAPHICS_SETTINGS_LAYOUT',
                path: '/visualPresentation/layout',
                exact: true,
                component: Layout,
                icon: 'fal fa-palette'
            },
            {
                id: 'ROUTE_GRAPHICS_SETTINGS_LOGO',
                path: '/visualPresentation/logo',
                component: Logo,
                icon: 'fal fa-sign'
            },
            {
                id: 'ROUTE_GRAPHICS_SETTINGS_PROMOTIONBAR',
                path: '/extras/promotionBar',
                component: PromotionBar,
                icon: 'fal fa-bullhorn'
            },
            {
                id: 'ROUTE_GRAPHICS_SETTINGS_PLACEHOLDER_IMAGES',
                path: '/visualPresentation/placeholderImages',
                component: PlaceholderImages,
                exact: true,
                icon: 'cil-image'
            },
            {
                id: 'ROUTE_GRAPHICS_SETTINGS_BUTTONS',
                path: '/visualPresentation/buttons',
                component: Buttons,
                exact: true,
                icon: 'cil-input'
            },
            {
                id: 'ROUTE_GRAPHICS_SETTINGS_IMAGE_QUALITY',
                path: '/visualPresentation/graphicsSettings',
                component: GraphicsSettings,
                exact: true,
                icon: 'cil-wallpaper'
            },
            {
                id: 'ROUTE_GRAPHICS_SETTINGS_FONTS',
                path: '/extras/fonts',
                component: Fonts,
                exact: true,
                icon: 'cil-paragraph'
            },
        ]
    },

    ROUTE_GRAPHICS_SETTINGS_LAYOUT_EDIT: {
        id: 'ROUTE_GRAPHICS_SETTINGS_LAYOUT_EDIT',
        path: '/visualPresentation/layout/edit',
        component: TemplateEdit
    },
    ROUTE_GRAPHICS_SETTINGS_BUTTONS_EDIT: {
        id: 'ROUTE_GRAPHICS_SETTINGS_BUTTONS_EDIT',
        path: '/visualPresentation/buttons/edit',
        component: ButtonsEdit,
        exact: true,
    },

    // Strona główna
    ROUTE_GRAPHICS_MAIN_PAGE: {
        id: 'ROUTE_GRAPHICS_MAIN_PAGE',
        path: '#',
        type: 'graphics',
        exact: true,
        icon: 'cil-home',
        children: [
            {
                id: 'ROUTE_GRAPHICS_MAIN_PAGE_GENERAL',
                path: '/homePageSettings/general',
                component: HomePageGeneralSettings,
                icon: 'cil-settings',
            },
            {
                id: 'ROUTE_GRAPHICS_MAIN_PAGE_SECTIONS',
                path: '/homePageSettings/sections',
                exact: true,
                component: HomePageSections,
                icon: 'cil-screen-desktop'
            },
            {
                id: 'ROUTE_GRAPHICS_MAIN_PAGE_METATAGS',
                path: '/homePageSettings/metaTags/mainPage',
                component: MetatagsSection,
                icon: 'cil-code',
            },
        ]
    },

    ROUTE_GRAPHICS_MAIN_PAGE_EDIT: {
        id: 'ROUTE_GRAPHICS_MAIN_PAGE_EDIT',
        path: [`/homePageSettings/sections/edit`],
        component: SectionsEdit,
    },

    // Rejestracja użytkownika
    ROUTE_GRAPHICS_REGISTRATION_PAGE: {
        id: 'ROUTE_GRAPHICS_REGISTRATION_PAGE',
        path: '#',
        type: 'graphics',
        exact: true,
        icon: 'nav-icon fal fa-user-plus',
        children: [
            {
                id: 'ROUTE_GRAPHICS_REGISTRATION_SUMMARY_PAGE_SECTIONS',
                path: '/registrationSummary/sections',
                exact: true,
                component: SummaryPageSections,
                icon: 'cil-screen-desktop'
            },
            {
                id: 'ROUTE_GRAPHICS_REGISTRATION_CONFIRMATION_PAGE_SECTIONS',
                path: '/registrationConfirmation/sections',
                exact: true,
                component: ConfirmationPageSections,
                icon: 'cil-screen-desktop'
            }
        ]
    },

    ROUTE_GRAPHICS_REGISTRATION_SUMMARY_PAGE_EDIT: {
        id: 'ROUTE_GRAPHICS_REGISTRATION_SUMMARY_PAGE_EDIT',
        path: [`/registrationSummary/sections/edit`],
        component: SectionsEdit,
    },

    ROUTE_GRAPHICS_REGISTRATION_CONFIRMATION_PAGE_EDIT: {
        id: 'ROUTE_GRAPHICS_REGISTRATION_CONFIRMATION_PAGE_EDIT',
        path: [`/registrationConfirmation/sections/edit`],
        component: SectionsEdit,
    },

    // Koszyk
    ROUTE_GRAPHICS_CART_PAGE: {
        id: 'ROUTE_GRAPHICS_CART_PAGE',
        path: '#',
        type: 'graphics',
        exact: true,
        icon: 'nav-icon fal fa-shopping-cart',
        children: [
            {
                id: 'ROUTE_GRAPHICS_CART_PAGE_SECTIONS',
                path: '/cart/sections',
                exact: true,
                component: CartPageSections,
                icon: 'cil-screen-desktop'
            }
        ]
    },

    ROUTE_GRAPHICS_CART_PAGE_EDIT: {
        id: 'ROUTE_GRAPHICS_CART_PAGE_EDIT',
        path: [`/cart/sections/edit`],
        component: SectionsEdit,
    },
};