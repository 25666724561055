import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { selectIsCategoryTreePresent } from '../../redux/selectors/categories';
import { getCategoryTree } from '../../redux/methods/categories';
import { getTranslationForIso } from '../../utils/shared';
import { getProductBasic } from '../../redux/methods/products';
import { selectStoreCurrentLanguage } from '../../redux/selectors/storeLocale';
import useFetch from '../../hooks/useFetch';

import SettingsTabulator from '../../components/layouts/SettingsTabulator';
import MetatagsSection from '../../views/extrasSettings/MetatagsSection';
import ProductEditBasicSection from '../../features/products/editTabs/ProductEditBasicSection';
import ProductEditImagesSection from '../../features/products/editTabs/ProductEditImagesSection';
import ProductEditAdditionalCategories from '../../features/products/editTabs/ProductEditAdditionalCategories';
import ProductEditDescription from '../../features/products/editTabs/ProductEditDescription';
import ProductEditAlternativeProducts from '../../features/products/editTabs/ProductEditAlternativeProducts';
import ProductEditAttributes from '../../features/products/editTabs/ProductEditAttributes';
import ProductEditPricesSection from '../../features/products/editTabs/ProductEditPricesSection';
import ProductEditOpinions from '../../features/products/editTabs/ProductEditOpinions';
import ProductEditVariantsSection from '../../features/products/editTabs/ProductEditVariantsSection';
import ProductEditMeasuresSection from '../../features/products/editTabs/ProductEditMeasuresSection';

import { productsSearchConfigPropTypes } from '../../propTypes';
import ProductEditRelatedProducts from '../../features/products/editTabs/ProductEditRelatedProducts';
import ProductEditDelivery from '../../features/products/editTabs/ProductEditDelivery';

const propTypes = {
    location: PropTypes.object,
    searchConfig: productsSearchConfigPropTypes,
    isProductListPresent: PropTypes.bool,
    isCategoriesListPresent: PropTypes.bool,
    getCategoryTree: PropTypes.func,
    getProductBasic: PropTypes.func,
};

const ProductsEdit = ({
    location, isCategoriesListPresent, getCategoryTree, getProductBasic, forceRefetch,
    storeCurrentLanguage,
}) => {
    const intl = useIntl();
    const translations = intl.messages.products.edit;

    const query = new URLSearchParams(location.search);
    const chosenProductId = query.get('productID');

    const [ data ] = useFetch(getProductBasic, chosenProductId, [ chosenProductId ], forceRefetch);

    useEffect(() => {
        if (!isCategoriesListPresent) {
            getCategoryTree();
        }
    }, []); // eslint-disable-line

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case "productEditTab-basic":
                return <ProductEditBasicSection id={chosenProductId} data={data} />;
            case "productEditTab-prices":
                return <ProductEditPricesSection id={chosenProductId} productIsFromErp={!!data.externalId} />;
            case "productEditTab-measures":
                return <ProductEditMeasuresSection id={chosenProductId} />;
            case "productEditTab-photos":
                return <ProductEditImagesSection productId={chosenProductId}/>;
            case "productEditTab-description":
                return <ProductEditDescription productId={chosenProductId}/>;
            case "productEditTab-metaTags":
                return <MetatagsSection forcedTag="ProductPage" forcedPageId={chosenProductId}/>;
            case "productEditTab-featuresAndAttributes":
                return <ProductEditAttributes id={chosenProductId} />;
            case "productEditTab-additionalCategories":
                return <ProductEditAdditionalCategories id={chosenProductId}/>;
            case "productEditTab-alternativeProducts":
                return <ProductEditAlternativeProducts id={chosenProductId}/>;
            case "productEditTab-relatedProducts":
                return <ProductEditRelatedProducts id={chosenProductId}/>;
            case "productEditTab-opinions":
                return <ProductEditOpinions id={chosenProductId}/>;
            case "productEditTab-variants":
                return <ProductEditVariantsSection
                    productId={chosenProductId}
                    settings={{
                        variantType: data.variantType,
                        variantAttributesIds: data.variantAttributesIds,
                        displayUnavailableVariants: data.displayUnavailableVariants,
                        displayMinStateInfo: data.displayMinStateInfo
                    }}
                />;
            case "productEditTab-delivery":
                return <ProductEditDelivery id={chosenProductId}/>;
            default:
                return null;
        }
    };

    return chosenProductId
        ? (
            data && isCategoriesListPresent ? (
                <SettingsTabulator
                    title={{
                        label: getTranslationForIso(data.name, storeCurrentLanguage),
                        iconClassName: "fa fa-edit text-primary ml-3"
                    }}
                    tabulatorHeader={{
                        label: translations.tabSelectorTitle,
                        iconClassName: "fa fa-align-justify"
                    }}
                    refreshTabComponent={refreshTabComponent}
                    tabs={[
                        {
                            id: "productEditTab-basic",
                            header:  {
                                label: translations.tabs.basic.title,
                                iconClassName: "fa fa-list-check"
                            }
                        },
                        {
                            id: "productEditTab-prices",
                            header:  {
                                label: translations.tabs.prices.title,
                                iconClassName: "fa fa-money"
                            }
                        },
                        {
                            id: "productEditTab-measures",
                            header:  {
                                label: translations.tabs.measures.title,
                                iconClassName: "fa fa-calculator"
                            }
                        },
                        {
                            id: "productEditTab-photos",
                            header:  {
                                label: translations.tabs.photos.title,
                                iconClassName: "fa fa-photo"
                            }
                        },
                        {
                            id: "productEditTab-description",
                            header:  {
                                label: translations.tabs.description.title,
                                iconClassName: "fa fa-search"
                            }
                        },
                        {
                            id: "productEditTab-metaTags",
                            header:  {
                                label: translations.tabs.metaTags.title,
                                iconClassName: "fa fa-code"
                            }
                        },
                        {
                            id: "productEditTab-featuresAndAttributes",
                            header:  {
                                label: translations.tabs.featuresAndAttributes.title,
                                iconClassName: "fa fa-tags"
                            }
                        },
                        {
                            id: "productEditTab-additionalCategories",
                            header:  {
                                label: translations.tabs.additionalCategories.title,
                                iconClassName: "fa fa-clone"
                            }
                        },
                        {
                            id: "productEditTab-alternativeProducts",
                            header:  {
                                label: translations.tabs.alternativeProducts.title,
                                iconClassName: "cil-sitemap"
                            }
                        },
                        {
                            id: "productEditTab-relatedProducts",
                            header:  {
                                label: translations.tabs.relatedProducts.title,
                                iconClassName: "cil-stream"
                            }
                        },
                        {
                            id: "productEditTab-opinions",
                            header:  {
                                label: translations.tabs.opinions.title,
                                iconClassName: "cil-comment-bubble"
                            }
                        },
                        data.variantType && {
                            id: "productEditTab-variants",
                            header:  {
                                label: translations.tabs.variants.title,
                                iconClassName: "cil-storage"
                            }
                        },
                        {
                            id: "productEditTab-delivery",
                            header:  {
                                label: translations.tabs.delivery.title,
                                iconClassName: "fa fa-truck"
                            }
                        },
                    ]}
                />
            ) : null
        ) : (
            <Redirect to="/products/list"/>
        );
};

ProductsEdit.propTypes = propTypes;

const mapStateToProps = (state) => ({
    isCategoriesListPresent: selectIsCategoryTreePresent(state),
    forceRefetch: state.refetchers.products,
    storeCurrentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
    getCategoryTree,
    getProductBasic
};

export default withRouter(connect(mapStateToProps, mapDispatch)(ProductsEdit));