import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Form, Alert } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import { graphicsTypeConfig } from '../../../config';

import { getGraphicsSettings, updateGraphicsSettings } from '../../../redux/methods/visualSettings';

import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import ContainerWithCard from '../../../components/ContainerWithCard';
import GraphicsTypeSection from './GraphicsTypeSection';

import { withFormUtils } from '../../../hoc/withFormUtils';
import useFetch from '../../../hooks/useFetch';

import styles from './index.module.scss';

const propTypes = {
    getGraphicsSettings: PropTypes.func,
    updateGraphicsSettings: PropTypes.func,
};

const GraphicsSettings = ({
    getGraphicsSettings,
    updateGraphicsSettings,
}) => {
    const intl = useIntl();
    const translations = intl.messages.visualSettings.graphicsSettings;

    const [ settings ] = useFetch(getGraphicsSettings);

    const handleOnSubmit = formData => {
        updateGraphicsSettings(formData);
    };

    return settings ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search'>
            <FinalForm
                initialValues={settings}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form }) => {

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Alert color={'info'} isOpen={true}>
                                {translations.guide}
                            </Alert>
                            <div className={styles.container}>
                                {
                                    graphicsTypeConfig.map((type, index) => (
                                        <GraphicsTypeSection
                                            key={`${index}-${type}-graphicsTypeSection`}
                                            type={type}
                                            labels={translations.inputs}
                                            header={translations.typeHeaders[type]}
                                        />
                                    ))
                                }
                            </div>
                            <SectionEditFormButtons formReset={form.reset} />
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ): <ContainerWithCard />;
};

GraphicsSettings.propTypes = propTypes;

const mapDispatch = {
    getGraphicsSettings,
    updateGraphicsSettings,
};

export default withFormUtils(connect(null, mapDispatch)(GraphicsSettings));