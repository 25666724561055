import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { Input } from 'reactstrap';
import clsx from 'clsx';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    step: PropTypes.number,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    initialValue: PropTypes.number,
};

const FormFieldWithRange = ({ name, label, max, min, step = '1', disabled, required, initialValue = 0 }) => {
    return (
        <Field name={name}>
            {
                props => {
                    return <React.Fragment>
                        <span className='d-flex justify-content-between w-100'>
                            <span className={clsx(props.input.value === 0 && 'text-danger font-italic')}>{label}</span>
                            <b className={clsx(props.input.value === 0 && 'text-danger font-italic')}>{props.input.value}</b></span>
                        <Input
                            type='range'
                            onChange={e => props.input.onChange(parseFloat(e.target.value))}
                            value={props.input.value ? props.input.value : initialValue}
                            min={min}
                            max={max}
                            step={step}
                            disabled={disabled}
                            required={required}
                        />
                    </React.Fragment>;
                }
            }
        </Field>
    );
};

FormFieldWithRange.propTypes = propTypes;

export default FormFieldWithRange;