const seo = {
    photoNames: {
        title: 'Nazwy zdjęć',
        alternativeText: 'Tekst alternatywny',
        name: 'Nazwa'
    },
    googleSiteMap: {
        title: 'Mapa strony',
        generateAutomatically: 'Generuj mapę strony automatycznie',
        location: 'Lokalizacja mapy strony',
        time: 'Co ile wykonać automatyczne generowanie (godziny)',
        generateButton: 'Generuj mapę strony',
        generatedInfo: 'Wygenerowno nową mapę strony',
        copy: 'Skopiowano do schowka'
    },
    ownCode: {
        title: 'Własny kod',
        enabled: 'Aktywny',
        head: 'Nagłówek',
        footer: 'Stopka',
        scriptFormatAlert: 'Skrypt musi znajdować się pomiędzy znakami <script></script>'
    }
};

export default seo;