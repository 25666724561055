const productsDescriptionsImport = {
    title: 'Import opisów artykułów',
    identifier: 'Identyfikator',
    separator: 'Separator',
    hasHeader: 'Posiada nagłówek',
    overrideExistingDescriptions: 'Nadpisuj',
    identifierColumnNumber: 'Numer kolumny z identyfikatorem',
    htmlDescriptionColumnNumber: 'Numer kolumny z opisem',
    hasQuote: 'Obsłużono separatory w polach',
    import: 'Importuj',
    identifiers:  {
        externalId: 'Identyfikator zewnętrzny',
        catalogueIndex: 'Indeks katalogowy',
        ean: 'EAN'
    },
    separators: {
        comma: ',',
        semicolon: ';',
        pipe: '|'
    }
};

export default productsDescriptionsImport;