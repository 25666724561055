import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectStoreCurrentLanguage } from '../../../../../redux/selectors/storeLocale';

import useFetch from '../../../../../hooks/useFetch';
import {
    getBannerImages,
    deleteBannerImage,
    updateBannerImage,
    moveBannerImage,
    uploadBannerImage,
    deleteBannerImageFile,
} from '../../../../../redux/methods/homePage';

import { bannerImageObjectConfig } from '../../../../../config';

import { Container } from 'reactstrap';
import BannerImagesTable from '../../../../../components/tables/BannerImagesTable';
import EditBannerImageModal from '../EditBannerImageModal';
import SectionAsideButtons from '../../../../../components/SectionAsideButtons';

import { stringOrNumberPropTypes } from '../../../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    currentLanguage: PropTypes.string,
    deleteBannerImage: PropTypes.func,
    uploadBannerImage: PropTypes.func,
    deleteBannerImageFile: PropTypes.func,
    bannerId: stringOrNumberPropTypes,
    moveBannerImage: PropTypes.func,
    bannerLocation: PropTypes.string,
    getBannerImages: PropTypes.func,
    updateBannerImage: PropTypes.func,
    forceRefetch: PropTypes.bool
};

const BannerImagesEditionSection = ({
    currentLanguage,
    deleteBannerImage,
    uploadBannerImage,
    deleteBannerImageFile,
    bannerId,
    moveBannerImage,
    getBannerImages,
    updateBannerImage,
    forceRefetch,
}) => {
    const intl = useIntl();
    const translations = intl.messages.banner.settings;

    const [ slideToEdit, setSlideToEdit ] = useState(null);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ slides ] = useFetch(getBannerImages, bannerId, [], forceRefetch);

    const handleEditClick = slide => {
        setSlideToEdit(slide);
        setIsModalOpen(true);
    };

    useEffect(() => {
        //way to update slide photo after upload in edition modal
        if(slides && slideToEdit) {
            setSlideToEdit(slides.find(slide => slide.bannerImageId === slideToEdit.bannerImageId));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[slides]);

    const handleRemove = id => {
        deleteBannerImage(id);
    };

    const handleAddSlide = () => {
        const objectToSend = {
            ...bannerImageObjectConfig,
            bannerid: bannerId
        };

        updateBannerImage(bannerId, objectToSend, true)
            .then((newlyCreatedId) => {
                if (!forceRefetch && slides) {
                    setSlideToEdit({
                        ...objectToSend,
                        bannerImageId: newlyCreatedId
                    });
                    setIsModalOpen(true);
                }
            });
    };

    const handleSubmit = form => {
        updateBannerImage(bannerId, form);
    };

    return (
        <React.Fragment>
            {slides ?
                <Container fluid className={clsx('mb-6', styles.fullScreenOnMobile)}>
                    <div className='animated fadeIn'>
                        <BannerImagesTable
                            slides={slides}
                            translations={translations}
                            removeAction={handleRemove}
                            editAction={handleEditClick}
                            changeSlideOrder={moveBannerImage}
                            updateBannerImage={updateBannerImage}
                            currentLanguage={currentLanguage}
                        />
                        <SectionAsideButtons
                            buttons={{
                                add: {
                                    color: 'primary',
                                    label: translations.actions.add,
                                    action: handleAddSlide,
                                    iconClass: 'fa fa-plus mr-2',
                                    isDisabled: bannerId < 1
                                }
                            }}
                        />
                        <div className='w-100 d-flex justify-content-end my-2'>
                            {bannerId < 1  && <span>{translations.inputs.saveAlert}</span>}
                        </div>
                    </div>
                </Container>
            : null}
                {
                    slideToEdit
                        && <EditBannerImageModal
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                onSubmit={handleSubmit}
                                slide={slideToEdit}
                                translations={translations}
                                handleUpload={uploadBannerImage}
                                handleRemove={deleteBannerImageFile}
                                bannerId={bannerId}
                            />
                }
        </React.Fragment>
    );
};

BannerImagesEditionSection.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.homePageBanner,
    currentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    getBannerImages,
    deleteBannerImage,
    updateBannerImage,
    moveBannerImage,
    uploadBannerImage,
    deleteBannerImageFile,
};

export default connect(mapStateToProps, mapDispatch)(BannerImagesEditionSection);