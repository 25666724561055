const translations = {
    header: '[EN] Tłumaczenia',
    buttons: {
        save: '[EN] ZAPISZ ZMIANY',
        clear: '[EN] Wyczyść',
        search: '[EN] Wyszukaj',
    },
    modalHeader: '[EN] Edycja',
};

export default translations;