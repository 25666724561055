/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

import { Table, Input, Row, ButtonGroup, Button } from "reactstrap";

import TableActionCell from "../TableActionCell";

import { photoWithTranslatedAlternativeTextPropTypes } from "../../../propTypes";

import styles from "./index.module.scss";
import getConfig from '../../../getConfig';


const propTypes = {
    photos: PropTypes.arrayOf(photoWithTranslatedAlternativeTextPropTypes),
    checkedCheckboxList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    columnNames: PropTypes.shape({
        photo: PropTypes.string,
        alternativeText: PropTypes.string,
        isDefault: PropTypes.string,
        alignment: PropTypes.string,
        horizontalAlignment: PropTypes.string,
        actions: PropTypes.string,
    }),
    tooltipTranslations: PropTypes.shape({
        remove: PropTypes.string,
        edit: PropTypes.string,
        showDetails: PropTypes.string,
    }).isRequired,
    emptyTranslation: PropTypes.string,
    isDefaultText: PropTypes.string,
    checkboxAction: PropTypes.func.isRequired,
    changeDefault: PropTypes.func.isRequired,
    showDetailsAction: PropTypes.func.isRequired,
    removeAction: PropTypes.func.isRequired,
    deleteModalTranslations: PropTypes.shape({
        header: PropTypes.string,
        body: PropTypes.string,
        confirm: PropTypes.string,
        cancel: PropTypes.string
    }).isRequired,
    editAction: PropTypes.func.isRequired,
    selectedDefaultPhotoId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    storeCurrentLanguage: PropTypes.string,
    defaultAlternativeTextForPhotoName: PropTypes.string,
    changeProductPhotoOrder: PropTypes.func,
    changeProductPhotoVisibility: PropTypes.func,
    imageAlignmentTranslations: PropTypes.shape({
        horizontal: PropTypes.string,
        vertical: PropTypes.string,
    }),
    alignmentText: PropTypes.shape({
        default: PropTypes.string,
        modified: PropTypes.string,
    }),
    isChangingDefaultAllowed: PropTypes.bool,
    isConnectedToERP: PropTypes.bool,
    isExternalTranslations: PropTypes.shape({
        isExternal: PropTypes.string,
        isNotExternal: PropTypes.string
    }),
};

const ProductPhotoTable = ({
    photos,
    checkedCheckboxList,
    columnNames,
    checkboxAction,
    changeDefault,
    removeAction,
    showDetailsAction,
    emptyTranslation,
    defaultAlternativeTextForPhoto,
    isDefaultText,
    deleteModalTranslations,
    tooltipTranslations,
    editAction,
    selectedDefaultPhotoId,
    defaultAlternativeTextForPhotoName,
    changeProductPhotoOrder,
    changeProductPhotoVisibility,
    isChangingDefaultAllowed,
    isConnectedToERP,
    isExternalTranslations
}) => {
    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;

    const checkboxActionHandler = id => () => {
        checkboxAction(id);
    };
    const handleChangeDefault = (id, type) => () => {
        changeDefault(id, type);
    };
    const showDetailsActionHandler = id => () => {
        showDetailsAction(id);
    };
    const removeActionHandler = id => () => {
        removeAction(id);
    };
    const editActionHandler = id => () => {
        editAction(id);
    };
    const changeVisibilityHandler = (id, isVisible) => () => {
        changeProductPhotoVisibility(id, isVisible);
    };
    const changeOrderHandler = (id, newOrder) => () => {
        changeProductPhotoOrder(id, newOrder);
    };

    return photos && photos.length > 0 ? (
        <Table responsive hover size="sm">
            <thead>
                <tr>
                    <th></th>
                    <th className={styles.columnOrder}>
                        {columnNames && columnNames.order}
                    </th>
                    <th className={styles.columnPhoto}>
                        {columnNames && columnNames.photo}
                    </th>
                    <th className={styles.columnActive}>
                        {columnNames && columnNames.active}
                    </th>
                    <th className={styles.columnName}>
                        {columnNames && columnNames.name}
                    </th>
                    <th className={styles.columnAlternativeText}>
                        {columnNames && columnNames.alternativeText}
                    </th>
                    <th className={styles.columnIsDefault}>
                        {columnNames && columnNames.isDefault}
                    </th>
                    {   isConnectedToERP &&
                        <th>
                            {columnNames && columnNames.isExternal}
                        </th>
                    }
                    <th className={styles.columnActions}>
                        {columnNames && columnNames.actions}
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    photos.map(photo => {
                        const isChecked = checkedCheckboxList.some(item => item === photo.productImageId);

                        return (
                            <tr key={`photo-${photo.productImageId}`}>
                                <th scope="row" className={clsx(styles.columnCheckbox, styles.cell)}>
                                    <Input
                                        checked={isChecked}
                                        id={`checkboxItem-${photo.productImageId}`}
                                        type="checkbox"
                                        onChange={checkboxActionHandler(photo.productImageId)}
                                        className="position-static m-0 p-0"
                                    />
                                </th>
                                <td className={clsx(styles.columnOrder, styles.cell)}>
                                    <ButtonGroup vertical>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            disabled={photo.orderIndex === 0}
                                            onClick={changeOrderHandler(photo.productImageId, photo.orderIndex - 1)}
                                        >
                                            <i className="cil-arrow-thick-top"></i>
                                        </Button>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            disabled={photo.orderIndex === photos.length - 1}
                                            onClick={changeOrderHandler(photo.productImageId, photo.orderIndex + 1)}
                                        >
                                            <i className="cil-arrow-thick-bottom"></i>
                                        </Button>
                                    </ButtonGroup>
                                </td>
                                <td className={clsx(styles.columnPhoto, styles.cell)}>
                                    <img className={styles.photo} src={`${apiUrl}${photo.source}`} alt={photo.alternativeText}/>
                                </td>
                                <td className={clsx(styles.columnActive, styles.cell)}>
                                    <label className="switch switch-pill switch-success m-0">
                                        <input
                                            disabled={(photo.isDefault && photo.isVisible)}
                                            type="checkbox"
                                            className="switch-input"
                                            checked={photo.isVisible}
                                            onChange={changeVisibilityHandler(photo.productImageId, !photo.isVisible)}
                                        />
                                        <span
                                            className={clsx("switch-slider", !photo.isVisible &&  "bg-danger border-danger")}
                                        ></span>
                                    </label>
                                </td>
                                <td className={clsx(styles.columnName, styles.cell, !photo.originalName && "text-danger")}>
                                    {photo.originalName || defaultAlternativeTextForPhotoName}
                                </td>
                                <td className={clsx(styles.columnAlternativeText, styles.cell, !(photo.alternativeText) && "text-danger")}>
                                    {photo.alternativeText || defaultAlternativeTextForPhoto}
                                </td>
                                <td className={clsx(styles.columnIsDefault, styles.cell)}>
                                    <Input
                                        disabled={!isChangingDefaultAllowed}
                                        checked={photo.productImageId === selectedDefaultPhotoId}
                                        id={`checkboxItem-${photo.productImageId}`}
                                        type="radio"
                                        onChange={handleChangeDefault(photo.productImageId)}
                                        className="position-static m-0 p-0"
                                    />
                                    <span className={clsx("ml-2", photo.productImageId !== selectedDefaultPhotoId && "text-secondary")}>{isDefaultText}</span>
                                </td>
                                {
                                    isConnectedToERP &&
                                    <td className={clsx(styles.cell)}>
                                        {photo.externalId ? isExternalTranslations.isExternal : isExternalTranslations.isNotExternal}
                                    </td>
                                }
                                <td className={clsx(styles.cell, styles.columnActions)}>
                                    <TableActionCell
                                        itemId={photo.productImageId}
                                        actions={{
                                            showDetails: {
                                                tooltipTranslation: tooltipTranslations.showDetails,
                                                action: showDetailsActionHandler(photo.productImageId)
                                            },
                                            edit: {
                                                tooltipTranslation: tooltipTranslations.edit,
                                                action: editActionHandler(photo.productImageId),
                                            },
                                            delete: {
                                                action: removeActionHandler(photo.productImageId),
                                                modalTranslations: deleteModalTranslations,
                                                tooltipTranslation: tooltipTranslations.remove
                                            },
                                        }}
                                    />
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    ) : (
        <Row className="d-flex justify-content-center mt-4 mb-4">
            {emptyTranslation}
        </Row>
    );
};

ProductPhotoTable.propTypes = propTypes;

export default ProductPhotoTable;
