import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { Input, InputGroup, InputGroupAddon, Button, Alert } from 'reactstrap';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number']),
    max: PropTypes.number,
    min: PropTypes.number,
    required: PropTypes.bool,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    step: PropTypes.string,
    symbol: PropTypes.string,
    disabled: PropTypes.bool,
    plaintext: PropTypes.bool,
    readOnly: PropTypes.bool,
    symbolLabel: PropTypes.string,
};

const FormFieldTextWithCopy = ({ name, label, className, open }) => {
    const translations = useIntl().messages.sharedComponents.formFieldTextWithCopy;

    const [ isCopied, setIsCopied ] = useState(false);
    const [ isAlertOpen, setIsAlertOpen ] = useState(false);
    const locationRef = useRef(null);

    const handleCopyToClipboard = () => {
        locationRef.current.select();
        document.execCommand('copy');
        setIsCopied(true);
        setIsAlertOpen(true);

        setTimeout(() => {
            setIsAlertOpen(false);
        }, 3000);
    };

    const handleHideAlert = () => {
        setIsAlertOpen(false);
    };

    return (
        <React.Fragment>
            {
                isCopied &&
                    <Alert
                        className={clsx('fixed-top', 'mt-5 w-50 mx-auto')}
                        color='primary'
                        isOpen={isAlertOpen}
                        toggle={handleHideAlert}
                    >
                        <span>{translations.success}</span>
                    </Alert>
            }
            <Field name={name}>
                {
                    props =>
                        <React.Fragment>
                            <span>{label}</span>
                            <InputGroup className={className}>
                                <Input innerRef={locationRef} defaultValue={props.input.value} readOnly />
                                <InputGroupAddon addonType="append">
                                    <Button onClick={handleCopyToClipboard} color='success'>{translations.copy}</Button>
                                    {open && <Button onClick={open} color='success'>{translations.open}</Button>}
                                </InputGroupAddon>
                            </InputGroup>
                        </React.Fragment>
                }
            </Field>
        </React.Fragment>
    );
};

FormFieldTextWithCopy.propTypes = propTypes;

export default FormFieldTextWithCopy;