const discounts = {
    view: {
        title: 'Discount codes list',
        paginator: {
            previous: 'Previous',
            next: 'Next'
        },
        actions: {
            addDiscount: 'ADD DISCOUNT CODE',
        },
        table: {
            headers: {
                status: 'Status',
                name: 'Name',
                code: 'Code',
                validFrom: 'Valid from',
                validTo: 'Valid to',
                currency: 'Currency',
                type: 'Type',
                range: 'Range',
                actions: 'Actions',
            },
            fillers: {
                name: '[NO NAME]',
                code: '[NO CODE]',
                currency: '[NO CURRENCY]',
                type: '[NO TYPE]',
                range: '[NO RANGE]',
            },
            translations: {
                tooltips: {
                    edit: 'Edit discount',
                    remove: 'Remove discount',
                },
                deleteModal: {
                    header: 'Removing discount',
                    body: 'Are you sure you want to remove discount?',
                    confirm: 'REMOVE',
                    cancel: 'CANCEL',
                },
                emptyTable: 'No discounts to display',
                type: {
                    percentage: 'Percentage',
                    amount: 'Amount',
                },
                range: {
                    entireOrder: 'Entire order',
                    specificProducts: 'Specific products',
                    shipmentMethod: 'Shipment method',
                    buyXGetYSingle: 'Buy X get Y (single products)',
                    buyXGetYGroup: 'Buy X get Y (group of products)',
                },
                statusLabels: {
                    created: 'Created',
                    visible: 'Visible',
                    hidden: 'Hidden',
                    utilized: 'Utilized',
                },
            }
        }
    },
    edit: {
        fallbackName: '[NO NAME]',
        tabSelectorTitle: 'Sections',
        tabs: {
            basic: {
                title: 'Basic data',
                priorityTooltip: 'Discounts with higher priority will be applied first',
                inputs: {
                    status: 'Code status: ',
                    currency: 'Currency',
                    type: 'Type',
                    range: 'Range',
                    name: 'Code name',
                    code: 'Code',
                    validFrom: 'Valid from',
                    validTo: 'Valid to',
                    priority: 'Priority',
                },
                type: {
                    percentage: 'Percentage',
                    amount: 'Amount',
                },
                range: {
                    entireOrder: 'Entire order',
                    specificProducts: 'Specific products',
                    shipmentMethod: 'Shipment method',
                    buyXGetYSingle: 'Buy X get Y (single products)',
                    buyXGetYGroup: 'Buy X get Y (group of products)',
                },
                status: {
                    created: 'Created',
                    visible: 'Visible',
                    hidden: 'Hidden',
                    utilized: 'Utilized',
                },
            },
            parameters: {
                title: 'Parameters',
                fields: {
                    discountPercentage: {
                        label: 'Percentage of discount',
                        tooltipText: 'Enter a value between 0 and 100',
                        symbol: '%',
                    },
                    discountPercentageAmountLimit: {
                        label: 'Minimal discounted price',
                        tooltipText: 'The calculated discount cannot exceed amount below',
                    },
                    discountAmount: {
                        label: 'Discount amount',
                        tooltipText: 'Discount amount is selected currency'
                    },
                    requiredMinimumPurchaseAmount: {
                        label: 'Minimum order value',
                        tooltipText: 'Minimum order value for a coupon to be usable',
                        tooltipTextSpecificProducts: 'Minimum product value for a coupon to be usable'
                    },
                    maximumUsesOfDiscountPerUser: {
                        label: 'Maximum amount to use per user',
                        tooltipText: `Leave this field empty if you don't want to
                            limit amount of coupon use per user. Filling
                            this field will limit coupon's availability to logged in users only`
                    },
                    maximumUsesOfDiscountPerShop: {
                        label: 'Maximum use per store',
                        tooltipText: 'Leave this field blank if you do not want to limit the amout of coupon uses',
                    },
                    requiredMinimumQuantityOfItems: {
                        label: 'Minimum amount of product ordered',
                        tooltipText: 'Enter a number bigger or equal to 1'
                    },
                    maximumUsesOfDiscountPerCart: {
                        label: 'Maximum amount to use per cart',
                        tooltipText: 'Leave this field blank if you do not want to limit the amount of coupon uses in a cart',
                    },
                    offeredQuantity: {
                        label: 'Number of items receiving a discount',
                        tooltipText: 'Enter a number between 1 and the field "Minimum amount of product ordered"'
                    },
                    offeredRepetitions: {
                        label: 'Maximum number of repetitions',
                        tooltipText: `If ordered quantity of the product is a multiple of minimum amount of products needed to
                            apply a discount, then the discount will be applied at maximum as many times as this field specifies.
                            Leaving this field blank will result in an unregulated approach, meaning that discount will be applied
                            as many times as it can.`
                    },
                },
            },
            categoriesProducts: {
                title: 'Categories / products',
                switch: {
                    left: 'CATEGORIES',
                    right: 'PRODUCTS',
                    main: 'Switch mode:',
                    tooltip: 'To switch the mode, you must remove assigned categories, products and clear search fields.',
                },
                deleteModalCategories: {
                    header: 'Unassign categories',
                    body: 'Are you sure that you want to unassign selected categories from discount?',
                },
                deleteModalProducts: {
                    header: 'Unassign products',
                    body: 'Are you sure that you want to unassign selected products from discount?',
                },
                deleteModalProduct: {
                    header: 'Unassign product',
                    body: 'Are you sure that you want to unassign product from discount?',
                    confirm: 'UNASSIGN',
                    cancel: 'CANCEL'
                },
                tooltipTranslation: 'Unassign product',
                headers: {
                    categories: 'ASSIGN CATEGORIES',
                    products: {
                        assign: 'ASSIGN PRODUCTS',
                        unassign: 'UNASSIGN PRODUCTS'
                    },
                },
                table: {
                    columnNames: {
                        categories: 'Assigned category',
                        actions: 'Actions',
                    },
                    tooltip: 'Unassign category',
                    placeholder: 'No categories to display',
                    deleteModal: {
                        header: 'Unassign category',
                        bodyStart: 'Are you sure you want to unassign category',
                        bodyEnd: 'from selected discount?',
                        confirm: 'UNASSIGN',
                        cancel: 'CANCEL'
                    }
                },
                buttons: {
                    categories: {
                        add: 'ASSIGN CATEGORY',
                        remove: 'UNASSIGN SELECTED CATEGORIES',
                    },
                    products: {
                        add: 'ASSIGN PRODUCT',
                        remove: 'UNASSIGN SELECTED PRODUCTS',
                    }
                },
                fallbacks: {
                    name: '[NO NAME]',
                    catalgueIndex: '[NO CATALOGUE INDEX]',
                    tradeIndex: '[NO TRADE INDEX]',
                },
                categoriesSection: {
                    selectModal: {
                        header: 'Select category',
                        confirm: 'SELECT',
                        cancel: 'CANCEL',
                        labels: {
                            dropdown: 'Select category to assign: ',
                            emptySelection: 'No selected category',
                            tooltip: 'Select category to assign',
                            inputLabel: 'Search category: '
                        }
                    },
                    confirmModal: {
                        header: 'Category assign',
                        body:'Are you sure that yuo want to assign category to selected discount?',
                        confirm: 'ASSIGN',
                        cancel: 'CANCEL',
                    },
                }
            },
            regionsCountries: {
                title: 'Regions / countries',
                switch: {
                    left: 'REGIONS',
                    right: 'COUNTRIES',
                    main: 'Change mode:',
                    tooltip: 'To switch the mode, you must remove assigned regions and countries.'
                },
                deleteModalCountries: {
                    header: 'Unassign countries',
                    body: 'Are you sure that yuo want to unassign selected countries from discount?',
                },
                deleteModalRegions: {
                    header: 'Unassign regions',
                    body: 'Are you sure that yuo want to unassign selected regions from discount?',
                },
                assignModalRegions: {
                    header: 'Assign region',
                    body: 'Select region to assign:',
                    confirm: 'ASSIGN',
                    cancel: 'CANCEL'
                },
                headers: {
                    regions: 'ASSIGNED REGIONS',
                    countries: {
                        assign: 'ASSIGNED COUNTRIES',
                        unassign: 'UNASSIGNED COUNTRIES'
                    },
                },
                buttons: {
                    regions: {
                        add: 'ASSIGN REGIONS',
                        remove: 'UNASSIGN SELECTED REGIONS',
                    },
                    countries: {
                        add: 'ASSIGN COUNTRIES',
                        remove: 'UNASSIGN SELECTED COUNTRIES',
                    }
                },
                tables: {
                    countries: {
                        fillers: {
                            table: 'No countries to display',
                            iso: '[NO ISO]',
                            name: '[NO NAME]',
                        },
                        deleteModal: {
                            header: 'Unassign country',
                            body: 'Are you sure that yuo want to unassign country from discount?',
                            confirm: 'UNASSIGN',
                            cancel: 'CANCEL',
                        },
                        headers: {
                            name: 'Name',
                            iso: 'ISO code',
                            actions: 'Actions',
                        },
                        tooltip: 'Unassign country'
                    },
                    regions: {
                        fillers: {
                            table: 'No regions to display',
                            name: '[NO NAME]',
                        },
                        deleteModal: {
                            header: 'Unassign region',
                            body: 'Are you sure that yuo want to unassign region from discount?',
                            confirm: 'UNASSIGN',
                            cancel: 'CANCEL',
                        },
                        headers: {
                            name: 'Name',
                            actions: 'Actions',
                        },
                        tooltip: 'Unassign region'
                    }
                },
                countryAssignerHeader: 'Management of countries attached to discount'
            },
            groupsUsers: {
                title: 'Grupy / użytkownicy',
                switch: {
                    left: 'GRUPY',
                    right: 'UŻYTKOWNICY',
                    main: 'Przełącz tryb:',
                    tooltip: 'Aby zmienić tryb musisz usunąć przypisane grupy i użytkowników.'
                },
                deleteModalGroups: {
                    header: 'Odłączanie grup',
                    body: 'Czy na pewno chcesz odączyć zaznaczone grupy od wybranego kodu?',
                },
                deleteModalUsers: {
                    header: 'Odłączanie użytkowników',
                    body: 'Czy na pewno chcesz odączyć zaznaczonych użytkowników od wybranego kodu?',
                },
                deleteModalGroup: {
                    header: 'Odłączanie grupy',
                    body: 'Czy na pewno chcesz odączyć grupę od wybranego kodu?',
                    confirm: 'ODŁĄCZ',
                    cancel: 'WRÓĆ'
                },
                userTable: {
                    headers: {
                        email: 'Email',
                        actions: 'Akcje',
                    },
                    tooltipText: 'Usuń użytkownika',
                    emptyTable: 'brak użytkowników do wyświetlenia'
                },
                groupTable: {
                    headers: {
                        name: 'Nazwa',
                        actions: 'Akcje'
                    },
                    tooltipText: 'Usuń grupę',
                    emptyTable: 'Brak grup do wyświetlenia'
                },
                deleteModalUser: {
                    header: 'Odłączanie użytkownika',
                    body: 'Czy na pewno chcesz odączyć użytkownika od wybranego kodu?',
                    confirm: 'ODŁĄCZ',
                    cancel: 'WRÓĆ'
                },
                headers: {
                    groups: 'PRZYPISANE GRUPY',
                    users: {
                        assign: 'PRZYPISANI UŻYTKOWNICY',
                        unassign: 'WYŁĄCZENI UŻYTKOWNICY'
                    },
                },
                buttons: {
                    groups: {
                        add: 'PRZYPISZ GRUPE',
                        remove: 'ODŁĄCZ WYBRANE GRUPY',
                    },
                    users: {
                        add: 'PRZYPISZ UŻYTKOWNIKÓW',
                        remove: 'ODŁĄCZ WYBRANYCH UŻYTKOWNIKÓW',
                    }
                },
                tables: {
                    users: {
                        deleteModal: {
                            header: 'Odłączanie użytkownika',
                            body: 'Czy na pewno chcesz odłączyć użytkownika od wybranego kodu?',
                            confirm: 'ODŁĄCZ',
                            cancel: 'WRÓĆ',
                        },
                        tooltip: 'Odłącz użytkownika'
                    },
                    groups: {
                        deleteModal: {
                            header: 'Odłączanie grupy',
                            body: 'Czy na pewno chcesz odłączyć grupę od wybranego kodu?',
                            confirm: 'ODŁĄCZ',
                            cancel: 'WRÓĆ',
                        },
                        tooltip: 'Odłącz grupe'
                    }
                },
            },
            codes: {
                title: 'Codes',
                emptyTableFiller: '[EN] Brak kodów do wyświetlenia',
                headerLabels: {
                    code: '[EN] Kod',
                    usages: '[EN] Użycia',
                    usagesLeft: '[EN] Pozostałe użycia'
                },
                buttons: {
                    export: '[EN] Eksportuj jako csv',
                    generate: '[EN] Generuj kody',
                },
                generateModal: {
                    header: '[EN] Generowanie kodów',
                    buttons: {
                        cancel: '[EN] Anuluj',
                        confirm: '[EN] Generuj'
                    },
                    inputs: {
                        howLongCode: '[EN] Długość kodu',
                        howManyCodes: '[EN] Ilość kodów',
                        howManyUsagesPerCode: '[EN] Liczba użyć na kod',
                    }
                }
            },
            usages: {
                title: 'Usages',
                emptyTableFiller: '[EN] Brak użyć do wyświetlenia',
                headerLabels: {
                    code: '[EN] Kod',
                    name: '[EN] Imię',
                    surname: '[EN] Nazwisko',
                    email: '[EN] Email',
                    date: '[EN] Data',
                    orderNumber: '[EN] Numer zamówienia',
                },
                search: {
                    buttons: {
                        submit: '[EN] SZUKAJ',
                        reset: '[EN] RESETUJ',
                    },
                    inputs: {
                        code: {
                            label: '[EN] Kod',
                        },
                        name: {
                            label: '[EN] Imię',
                        },
                        surname: {
                            label: '[EN] Nazwisko',
                        },
                        email: {
                            label: '[EN] Email',
                        },
                        date: {
                            label: '[EN] Data',
                        },
                        orderNumber: {
                            label: '[EN] Numer zamówienia',
                        },
                    }
                }
            },
        },
    },
    searchForm: {
        status: {
            label: 'Status:',
        },
        name: {
            label: 'Code name:',
            placeholder: ''
        },
        code: {
            label: 'Code:',
            placeholder: '',
        },
        validFrom: {
            label: 'Valid from:',
        },
        validTo: {
            label: 'Valid to:',
        },
        type: {
            label: 'Type:',
        },
        range: {
            label: 'Range:',
        },
        currencies: {
            label: 'Currency:',
        },
        dropdowns: {
            any: 'Any',
            type: {
                percentage: 'Percentage',
                amount: 'Amount',
            },
            range: {
                entireOrder: 'Entire order',
                specificProducts: 'Specific products',
                shipmentMethod: 'Shipment method',
                buyXGetYSingle: 'Buy X get Y (single products)',
                buyXGetYGroup: 'Buy X get Y (group of products)',
            },
            status: {
                created: 'Created',
                visible: 'Visible',
                hidden: 'Hidden',
                utilized: 'Utilized',
            },
        },
        action: 'SEARCH',
        reset: 'RESET',
    },
};

export default discounts;