import React from 'react';
import { useIntl } from 'react-intl';

import SettingsTabulator from '../../../components/layouts/SettingsTabulator';
import ArticleGeneralSection from './Sections/ArticleGeneralSection';
import CategoryGeneralSection from './Sections/CategoryGeneralSection';

const General = () => {
    const intl = useIntl();
    const translations = intl.messages.general.settings;

    const refreshTabComponent = tabulatorId => {
        switch(tabulatorId){
            case 'general-article':
                return <ArticleGeneralSection />;
            case 'general-category':
                return <CategoryGeneralSection />;
            default:
                return null;
        }
    };

    return (
        <SettingsTabulator
            title={{
                label: translations.title
            }}
            tabulatorHeader={{
                label: translations.title,
                iconClassName: 'fa fa-align-justify'
            }}
            refreshTabComponent={refreshTabComponent}
            tabs={[
                {
                    id: `general-article`,
                    header: {
                        label: translations.tabs.articles.title,
                        iconClassName: 'fa fa-cog'
                    }
                },
                {
                    id: `general-category`,
                    header: {
                        label: translations.tabs.categories.title,
                        iconClassName: 'fa fa-wrench'
                    }
                }
            ]}
        />
    );
};

export default General;