/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getVisualSettingsLogo, updateVisualSettingsLogo } from '../../../redux/methods/visualSettings';

import { Container, Form, Row } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import FormFieldTranslations from '../../../components/formFields/FormFieldTranslations';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import ContainerWithCard from '../../../components/ContainerWithCard';

import { withFormUtils } from '../../../hoc/withFormUtils';
import { formUtilsHocPropTypes } from '../../../propTypes';
import useFetch from '../../../hooks/useFetch';
import FileSenderLogo from './FileSenderLogo';

import MediaSelectionModalWithButtons from '../../../features/mediaLibrary/MediaSelectionModalWithButtons';

const propTypes = {
    utils: formUtilsHocPropTypes,
    getVisualSettingsLogo: PropTypes.func,
    updateVisualSettingsLogo: PropTypes.func
};

const LogoSettings = ({ utils, getVisualSettingsLogo, updateVisualSettingsLogo, forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.visualSettings.logo;

    const [ data ] = useFetch(getVisualSettingsLogo, null, [], forceRefetch);


    const [ isSelectionModalOpenLogo, setIsSelectionModalOpenLogo ] = useState(false);

    const [ isSelectionModalOpenSecondLogo, setIsSelectionModalOpenSecondLogo ] = useState(false);

    const [ isSelectionModalOpenFavicon, setIsSelectionModalOpenFavicon ] = useState(false);


    const [ selectedLogo, setSelectedLogo ] = useState([]);

    const [ selectedSecondLogo, setSelectedSecondLogo ] = useState([]);

    const [ selectedFavicon, setSelectedFavicon ] = useState([]);


    const config = { translations : ['logoAlt', 'secondLogoAlt'] };

    const handleOnSubmit = formData => {
        const objectToSend = {
            ...utils.generateFormToSubmitValues(formData, config),
            logoFileId: selectedLogo[0]?.id,
            secondLogoFileId: selectedSecondLogo[0]?.id,
            faviconFileId: selectedFavicon[0]?.id
        };

        updateVisualSettingsLogo(objectToSend);
    };

    useEffect(() => {
        if(data) {
            if(data.logoFileId && data.logoFileId !== 0) {
                setSelectedLogo([
                    {
                        id: data?.logoFileId,
                        thumbnailSource: data?.logoFileSource
                    }
                ]);
            }

            if(data.secondLogoFileId && data.secondLogoFileId !== 0) {
                setSelectedSecondLogo([
                    {
                        id: data?.secondLogoFileId,
                        thumbnailSource: data?.secondLogoFileSource
                    }
                ]);
            }

            if(data.faviconFileId && data.faviconFileId !== 0) {
                setSelectedFavicon([
                    {
                        id: data?.faviconFileId,
                        thumbnailSource: data?.faviconFileSource
                    }
                ]);
            }
        }
    }, [ data ]);

    return data ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-search'>
            <FinalForm
                initialValues={utils.generateFormInitialValues(data, config)}
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>
                                <Row>
                                    <div class="col-md-12 col-lg-4 m-0 p-2s">
                                        <h3>{translations.labels.favicon}</h3>
                                        <MediaSelectionModalWithButtons
                                            checkedItems={selectedFavicon}
                                            setCheckedItems={setSelectedFavicon}
                                            isOpen={isSelectionModalOpenFavicon}
                                            setIsOpen={setIsSelectionModalOpenFavicon}
                                            buttons={translations.fileSender.buttons}
                                        />
                                    </div>
                                    <div class="col-md-12 col-lg-4 m-0 p-2">
                                        <h3>{translations.labels.logo}</h3>
                                        <MediaSelectionModalWithButtons
                                            checkedItems={selectedLogo}
                                            setCheckedItems={setSelectedLogo}
                                            isOpen={isSelectionModalOpenLogo}
                                            setIsOpen={setIsSelectionModalOpenLogo}
                                            buttons={translations.fileSender.buttons}
                                        />
                                        <div class="mt-5">
                                            <FormFieldTranslations
                                                form={form}
                                                modalHeader={translations.labels.logoAlt}
                                                propertyName='logoAlt'
                                                currentIso={utils.currentLanguage()}
                                                label={translations.labels.logoAlt}
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-4 m-0 p-2">
                                        <h3>{translations.labels.secondLogo}</h3>
                                        <MediaSelectionModalWithButtons
                                            checkedItems={selectedSecondLogo}
                                            setCheckedItems={setSelectedSecondLogo}
                                            isOpen={isSelectionModalOpenSecondLogo}
                                            setIsOpen={setIsSelectionModalOpenSecondLogo}
                                            buttons={translations.fileSender.buttons}
                                        />
                                        <div class="mt-5">
                                            <FormFieldTranslations
                                                form={form}
                                                modalHeader={translations.labels.secondLogoAlt}
                                                propertyName='secondLogoAlt'
                                                currentIso={utils.currentLanguage()}
                                                label={translations.labels.secondLogoAlt}
                                            />
                                        </div>
                                    </div>
                                </Row>
                                <SectionEditFormButtons hideCancelButton />
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard />;
};

LogoSettings.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.visualSettingsLogo
});

const mapDispatch = {
    getVisualSettingsLogo,
    updateVisualSettingsLogo
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(LogoSettings));