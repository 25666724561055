import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    categoryTree: [],
    categoryTreeView: 'tree',
    expandedCategories: [],
    newlyCreatedCategoryId: null,
    categoryDisplayMode: 'all',
    lastSyncDate: null,
};

const categories = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategoryTree(state, action) {
            const { categoryTree, lastSyncDate } = action.payload;

            state.categoryTree = categoryTree;
            state.lastSyncDate = lastSyncDate;
        },
        setCategoryTreeView(state, action) {
            const { categoryTreeView } = action.payload;

            state.categoryTreeView = categoryTreeView;
        },
        setNewlyCreatedCategoryId(state, action) {
            const { newlyCreatedCategoryId } = action.payload;

            state.newlyCreatedCategoryId = newlyCreatedCategoryId;
        },
        setCategoryDisplayMode(state, action) {
            const { categoryDisplayMode } = action.payload;

            state.categoryDisplayMode = categoryDisplayMode;
        },
        setExpandedCategories(state, action){
            const { expandedCategories } = action.payload;
            state.expandedCategories = expandedCategories;
        },
    }
});

export const {
    setCategoryTree, setCategoryTreeView, setNewlyCreatedCategoryId, setEditCache,
    setCategoryDisplayMode, setExpandedCategories } = categories.actions;

export default categories.reducer;