const appGlobal = {
    errorModal: {
        close: "Close",
        header: "Error occured while performing operation"
    },
    editResult: {
        success: '[EN] Zmiany zostały zapisane!',
    },
    logout: '[EN] Wyloguj',
    refresh: '[EN] Odśwież widok',
};

export default appGlobal;