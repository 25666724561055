import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getServices, deleteService, updateServiceStatus } from '../../../../redux/methods/services';
import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';

import OtherServicesTable from '../../../../components/tables/OtherServicesTable';
import useFetch from '../../../../hooks/useFetch';
import { translateServiceName } from '../../../../utils/services';
import Paginator from '../../../../components/Paginator';
import { withFormUtils } from '../../../../hoc/withFormUtils';
import { useHistory } from 'react-router-dom';

const propTypes = {
    props: PropTypes.object,
};

const Table = ({
    forceRefetch,
    getServices,
    deleteService,
    updateServiceStatus,
    searchConfig,
    changeSearchConfig,
    storeCurrentLanguage,
    changePage
}) => {
    const history = useHistory();

    const [ checkedServicesIds, setCheckedServicesIds ] = useState([]);

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    const [ services, pages ] = useFetch(getServices, searchConfig, [searchConfig], forceRefetch);

    const translatedServices = services && translateServiceName(services, storeCurrentLanguage);

    return services ? (
        <React.Fragment>
            <OtherServicesTable
                performPageSizeSelect={handleChangePageSize}
                pageSize={searchConfig.pageSize}
                currentPage={searchConfig.curentPage + 1}
                services={translatedServices}
                history={history}
                checkedServicesIds={checkedServicesIds}
                setCheckedServicesIds={setCheckedServicesIds}
                removeAction={deleteService}
                updateAction={updateServiceStatus}
            />
            {
                pages > 1 &&
                    <Paginator
                        totalPages={pages}
                        currentPage={searchConfig.curentPage + 1}
                        onPageChange={changePage}
                    />
            }
        </React.Fragment>
    ) : null;
};

Table.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.services,
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    getServices,
    deleteService,
    updateServiceStatus
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(Table));