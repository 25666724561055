import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Container, Row } from 'reactstrap';

import PriceEditor from '../PriceEditor';

import { productPricesTranslationsPropTypes, productPricePropTypes } from '../../../../../../propTypes';

const propTypes = {
    translations: productPricesTranslationsPropTypes,
    isDefaultPrice: PropTypes.bool,
    price: productPricePropTypes,
    updatePriceHandler: PropTypes.func,
    disabled: PropTypes.bool
};

const BasePriceEdit = ({ values, index, form, calculateNetPrice, disabled }) => {
    const translations = useIntl().messages.products.edit.tabs.prices;

    return (
        <Container fluid>
            <Row className='mt-3 font-weight-bold'>
                <span>{translations.basePrice}</span>
            </Row>
            <Row>
                <PriceEditor calculateNetPrice={calculateNetPrice} values={values} index={index} form={form} disabled={disabled} />
            </Row>
        </Container>
    );
};

BasePriceEdit.propTypes = propTypes;

export default BasePriceEdit;