import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';

import Dropdown from '../../../../Dropdown';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        label: PropTypes.string,
        className: PropTypes.string,
    })),
    defaultLabel: PropTypes.string,
};

const DropdownField = ({ name, label, options, defaultLabel }) => {
    return (
        <Field name={name}>
            {
                props => {
                    const current = options.find(option => option.id === props.input.value);

                    return (
                        <span className='m-1'>
                            <span>{label}</span>
                            <Dropdown
                                name={name}
                                options={options}
                                currentSelectionLabel={current && current.label ? current.label : defaultLabel}
                                performSelection={props.input.onChange}
                            />
                        </span>
                    );
                }
            }
        </Field>
    );
};

DropdownField.propTypes = propTypes;

export default DropdownField;