import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { attributeTypeEnum } from '../../../../enums/attributes';

import ColorValues from './ColorValues';
import DictionaryValues from './DictionaryValues';

const propTypes = {
    attributeId: PropTypes.number,
    attributeType: PropTypes.string
};

const SharedEditValuesSection = ({ attributeId, attributeType }) => {
    switch (attributeType) {
        case attributeTypeEnum.color:
            return <ColorValues attributeId={attributeId}/>;
        case attributeTypeEnum.tag:
            return <DictionaryValues attributeId={attributeId}/>;
        case attributeTypeEnum.dictionary:
            return <DictionaryValues attributeId={attributeId}/>;
        default: return null;
    }
};

SharedEditValuesSection.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.products,
});

const mapDispatch = {
};

export default connect(mapStateToProps, mapDispatch)(SharedEditValuesSection);