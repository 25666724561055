import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { addCurrencyRegion, removeCurrencyRegions, getCurrencyRegions } from '../../../../redux/methods/currencies';
import { getRegions } from '../../../../redux/methods/regions';

import { handleCheckboxAction } from '../../../../utils/components';
import useFetch from '../../../../hooks/useFetch';

import SectionAsideButtons from '../../../../components/SectionAsideButtons';
import RegionsTable from '../../../../components/tables/RegionsTable';
import Modal from '../../../../components/modals/Modal';
import SearchableRegionsDropdown from '../../../../components/SearchableRegionsDropdown';

import { stringOrNumberPropTypes } from '../../../../propTypes';

import { withFormUtils } from '../../../../hoc/withFormUtils';

const propTypes = {
    currencyId: stringOrNumberPropTypes,
    addCurrencyRegion: PropTypes.func,
    removeCurrencyRegions: PropTypes.func,
    getRegions: PropTypes.func,
    forceRefetch: PropTypes.bool,
};

const CurrencyEditRegions = ({ utils,
    currencyId, addCurrencyRegion, removeCurrencyRegions,
    getRegions, getCurrencyRegions, forceRefetch,
}) => {
    const intl = useIntl();
    const translations = intl.messages.currencies.edit.tabs.regions;

    const [checkedRegionsIds, setCheckedRegionsIds] = useState([]);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [regionId, setRegionId] = useState(null);

    const [ regions ] = useFetch(getRegions);
    const [ currencyRegions ] = useFetch(getCurrencyRegions, currencyId, [], forceRefetch);

    useEffect(() => {
        return () => {
            setCheckedRegionsIds([]);
            setRegionId(null);
            setIsAssignModalOpen(false);
        };
    }, [currencyRegions]);

    const handleCheckboxClick = id => {
        handleCheckboxAction(id, checkedRegionsIds, setCheckedRegionsIds);
    };

    const handleUnassignRegion = id => {
        removeCurrencyRegions(currencyId, [id]);
    };

    const handleUnassignRegions = () => {
        removeCurrencyRegions(currencyId, checkedRegionsIds);
    };

    const handleOpenAssignModal = () => {
        setIsAssignModalOpen(true);
    };

    const handleOpenUnassignModal = () => {
        utils.confirm(
            () => handleUnassignRegions(),
            translations.deleteManyModal.header,
            translations.deleteManyModal.body
        );
    };

    const handleAssignRegion = () => {
        addCurrencyRegion(currencyId, [regionId]);
    };

    const categoriesSectionButtons = {
        remove: {
            label: translations.sectionButtons.remove,
            action: handleOpenUnassignModal,
            isDisabled: checkedRegionsIds.length === 0
        },
        add: {
            label: translations.sectionButtons.add,
            action: handleOpenAssignModal,
        }
    };

    return currencyRegions && regions ? (
        <React.Fragment>
            <RegionsTable
                regions={currencyRegions}
                withEditAction={false}
                withCheckbox={true}
                checkedCheckboxIds={checkedRegionsIds}
                setCheckedCheckboxIds={setCheckedRegionsIds}
                checkboxAction={handleCheckboxClick}
                headers={translations.tables.headers}
                removeAction={handleUnassignRegion}
                translations={{
                    tooltips: {
                        remove: translations.tables.tooltip
                    },
                    emptyTable: translations.tables.fillers.table,
                    deleteModal: translations.tables.deleteModal
                }}
                nameFiller={translations.tables.fillers.name}
            />
            <SectionAsideButtons
                buttons={categoriesSectionButtons}
            />
            <Modal
                isOpen={isAssignModalOpen}
                setIsOpen={setIsAssignModalOpen}
                header={translations.assignModal.header}
                body={
                    <React.Fragment>
                        <SearchableRegionsDropdown
                            regions={regions}
                            setRegionId={setRegionId}
                            regionId={regionId}
                            label={translations.assignModal.body}
                        />
                    </React.Fragment>
                }
                buttons={{
                    cancel: {
                        label: translations.assignModal.cancel,
                        color: 'secondary',
                    },
                    confirm: {
                        label: translations.assignModal.confirm,
                        color: 'primary',
                    }
                }}
                onConfirm={handleAssignRegion}
            />
        </React.Fragment>
    ) : null;
};

CurrencyEditRegions.propTypes = propTypes;

const mapStateToProps = state => ({
    forceRefetch: state.refetchers.currenciesRegions
});

const mapDispatch = {
    removeCurrencyRegions,
    addCurrencyRegion,
    getRegions,
    getCurrencyRegions,
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(CurrencyEditRegions));