import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectIsCategoryTreePresent } from '../../redux/selectors/categories';
import { getCategoryTree } from '../../redux/methods/categories';
import { performAttributeSearch, deleteAttributeById } from '../../redux/methods/attributes';
import useFetch from '../../hooks/useFetch';
import useSearchConfig from '../../hooks/useSearchConfig';

import { generateTranslatedFeaturesAttributesList } from '../../utils/attributes';

import { Container, Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';

import FeatureSearchForm from '../../features/attributes/features/FeatureSearchForm';
import FeatureTable from '../../features/attributes/features/FeatureTable';
import Paginator from '../../components/Paginator';

import { attributeAffiliationEnum } from '../../enums/attributes';
import { attributesFeaturesSearchConfig } from '../../config/searchConfigs';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object.isRequired,
    isCategoryTreePresent: PropTypes.bool,
    forceRefetch: PropTypes.bool,
    getCategoryTree: PropTypes.func,
    performAttributeSearch: PropTypes.func,
    storeDefaultLanguage: PropTypes.string,
    storeChosenLanguage: PropTypes.string,
    deleteAttributeById: PropTypes.func,
};

const Features = ({
    history, isCategoryTreePresent,  forceRefetch, getCategoryTree, performAttributeSearch,
    storeChosenLanguage, storeDefaultLanguage, deleteAttributeById
}) => {
    const intl = useIntl();
    const translations = intl.messages.features.view;

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig({ ...attributesFeaturesSearchConfig, attributeAffiliation: attributeAffiliationEnum.categoryFeature });
    const [ features, pages ] = useFetch(performAttributeSearch, searchConfig, [searchConfig], forceRefetch);

    useEffect(() => {
        if (!isCategoryTreePresent) {
            getCategoryTree();
        }
    }, []); // eslint-disable-line

    const handleAddNewFeature = () => {
        history.push(`/Features/Edit?featID=-2`);
    };

    const handleRemoveAction = featureId => {
        deleteAttributeById(featureId);
        changeSearchConfig({ curentPage: 0 });
    };

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    return (isCategoryTreePresent && features) ? (
        <React.Fragment>
            <Container fluid className={styles.fullScreenOnMobile}>
                <div className="animated fadeIn">
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <i className={clsx('fa fa-align-justify')}></i>
                                    <span>{translations.title}</span>
                                </CardHeader>
                                <CardBody>
                                    <main>
                                        <FeatureSearchForm
                                            performFeatureSearch={changeSearchConfig}
                                            searchConfig={searchConfig}
                                        />
                                        <FeatureTable
                                            performPageSizeSelect={handleChangePageSize}
                                            pageSize={searchConfig.pageSize}
                                            currentPage={searchConfig.curentPage + 1}
                                            removeHandler={handleRemoveAction}
                                            translatedFeaturesList={generateTranslatedFeaturesAttributesList(features, storeChosenLanguage || storeDefaultLanguage)}
                                            history={history}
                                        />
                                        {
                                            pages > 1 &&
                                                <Paginator
                                                    totalPages={pages}
                                                    currentPage={searchConfig.curentPage + 1}
                                                    onPageChange={changePage}
                                                />
                                        }
                                    </main>
                                    <aside className={styles.footer}>
                                        <Button color="primary" onClick={handleAddNewFeature}>
                                            <i className="fa fa-plus mr-2"/>
                                            <span>{translations.actions.addFeature}</span>
                                        </Button>
                                    </aside>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    ) : null;
};

Features.propTypes = propTypes;

const mapStateToProps = state => ({
    isCategoryTreePresent: selectIsCategoryTreePresent(state),
    forceRefetch: state.refetchers.attributes,
    storeChosenLanguage: state.persisted.storeLocale.storeChosenLanguage,
    storeDefaultLanguage: state.persisted.storeLocale.storeDefaultLanguage
});

const mapDispatch = {
    performAttributeSearch,
    getCategoryTree,
    deleteAttributeById
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Features));