import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TableGenerator from '../../generators/TableGenerator';

import { cookiesAgreementPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    taxRates: PropTypes.arrayOf(cookiesAgreementPropTypes),
};

const TaxRatesTable = ({ taxRates }) => {
    const translations = useIntl().messages.taxRates.table;

    const RateCell = value => {
        return (
            <React.Fragment>
                <span>{value.rate}%</span>
            </React.Fragment>
        );
    };

    return (
        <TableGenerator
            items={taxRates}
            additionalRowStyle={styles.rowDisabled}
            translations={{
                emptyTable: translations.emptyTable,
            }}
            columns={[
                {
                    header: translations.headerLabels.name,
                    value: 'name',
                },
                {
                    header: translations.headerLabels.code,
                    value: 'code',
                },
                {
                    type: 'custom',
                    header: translations.headerLabels.amount,
                    value: 'rate',
                    customCell: RateCell,
                },
            ]}
        />
    );
};

TaxRatesTable.propTypes = propTypes;

export default TaxRatesTable;