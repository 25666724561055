import { setIsLoading } from '../../../features/loaders/slice';
import { cAPI } from '../../../utils/cAPI';
import { generateTranslationsToSend } from '../../../utils/countries';
import { getData, addEditData } from '../generic';

export const getCountries = () => dispatch => getData(dispatch, cAPI.APGetCountries, null, 'countries');

export const updateCountryNames = (countryId, data, availableIsoList) => dispatch => {
    dispatch(setIsLoading());

    const formData = {
        countryId: countryId,
        name: generateTranslationsToSend(data, availableIsoList),
    };

    addEditData(dispatch, cAPI.APUpdateCountryNames, formData, 'countries');
};

export const updateCountryActive = (data) => dispatch => {
    dispatch(setIsLoading());

    addEditData(dispatch, cAPI.APUpdateCountryActive, data, 'countries');
};