import sectionEditFormButtons from './sectionEditFormButtons';
import textInputWithFlags from './textInputWithFlags';
import editFormModalWithTextInputFlags from './editFormModalWithTextInputFlags';
import editNameModal from './editNameModal';
import treeItem from './treeItem';
import tables from './tables';
import arrowsBetweenStrings from './arrowsBetweenStrings';
import colorEditionModal from './colorEditionModal';
import colorEditionController from './colorEditionController';
import colorPicker from './colorPicker';
import categorySelectModal from './categorySelectModal';
import confirmationModal from './confirmationModal';
import uploadFile from './uploadFile';
import mapLeaflet from './mapLeaflet';
import warningModal from './warningModal';
import previewModal from './previewModal';
import definingButton from './definingButton';
import formFieldTextWithCopy from './formFieldTextWithCopy';
import saveDataToast from './saveDataToast';
import mediaSelectionModal from './mediaSelectionModal';
import saveAndBackButtonsSection from './saveAndBackButtonsSection';

const sharedComponents = {
    sectionEditFormButtons,
    textInputWithFlags,
    editFormModalWithTextInputFlags,
    editNameModal,
    treeItem,
    tables,
    arrowsBetweenStrings,
    colorEditionModal,
    colorEditionController,
    colorPicker,
    categorySelectModal,
    confirmationModal,
    uploadFile,
    mapLeaflet,
    warningModal,
    previewModal,
    definingButton,
    formFieldTextWithCopy,
    saveDataToast,
    mediaSelectionModal,
	saveAndBackButtonsSection
};

export default sharedComponents;