import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useFetch from '../../../../../hooks/useFetch';
import useSearchConfig from '../../../../../hooks/useSearchConfig';
import { generateShopProductLink } from '../../../../../utils/products';
import { getTranslationForIso } from '../../../../../utils/components';

import ProductSearchTable from '../../../../../components/tables/ProductSearchTable';
import Paginator from '../../../../../components/Paginator';
import ProductStatsSearchForm from '../../ProductStatsSearchForm';

import { formUtilsHocPropTypes } from '../../../../../propTypes';

const propTypes = {
    translations: PropTypes.object,
    utils: formUtilsHocPropTypes,
    fetchMethod: PropTypes.func,
    config: PropTypes.object,
};

const ProductStatsSubSection = ({
    translations,
    config,
    fetchMethod,
    utils,
}) => {
    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig(config);
    const result = useFetch(fetchMethod, searchConfig, [ searchConfig ]);

    const [columnSortedByDescendingOrder, setColumnSortedByDescendingOrder ] = useState(null);

    const performSearch = form => {
        changeSearchConfig(
            {
                ...searchConfig,
                ...form,
            }
        );
    };

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    const handlePerformSort = (direction) => {
        changeSearchConfig({ ...searchConfig, OrderDirection: direction });
    };

    const showProductActionHandler = id => {
        const shopProductWindow = window.open(generateShopProductLink(id, utils.currentLanguage()), '_blank', 'noopener, noreferrer');
        if (shopProductWindow) {
            shopProductWindow.opener = null;
        }
    };

    const translateProductNames = (products) => {
        return products.map(product => ({
            ...product,
            name: getTranslationForIso(product.name,  utils.currentLanguage())
        }));
    };

    const pages = result[1];

    return result[0] ? (
        <div>
            <ProductStatsSearchForm
                searchConfig={searchConfig}
                handleSubmit={performSearch}
                translations={translations}
                currentLanguage={utils.currentLanguage()}
            />
            <ProductSearchTable
                performPageSizeSelect={handleChangePageSize}
                pageSize={searchConfig.pageSize}
                currentPage={searchConfig.curentPage + 1}
                onClick={showProductActionHandler}
                translations={translations}
                items={translateProductNames(result[0])}
                columnSortedByDescendingOrder={columnSortedByDescendingOrder}
                performSort={handlePerformSort}
                setColumnSortedByDescendingOrder={setColumnSortedByDescendingOrder}
            />
            {
                pages > 1 &&
                <Paginator
                    totalPages={pages}
                    currentPage={searchConfig.curentPage + 1}
                    onPageChange={changePage}
                />
            }
        </div>
    ) : null;
};

ProductStatsSubSection.propTypes = propTypes;

export default ProductStatsSubSection;