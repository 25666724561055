import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import EmailListControllerToFinalForm from '../../EmailListControllerToFinalForm';

const propTypes = {
    name: PropTypes.string,
    addButtonText: PropTypes.string,
};

const FormFieldEmailList = ({ name, addButtonText }) => {
    return (
        <Field name={name}>
            {
                props =>
                    <EmailListControllerToFinalForm
                        addButtonText={addButtonText}
                        emailList={props.input.value}
                        setEmailList={props.input.onChange}
                    />

            }
        </Field>
    );
};

FormFieldEmailList.propTypes = propTypes;

export default FormFieldEmailList;