import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateProductCategoryForFeatures } from '../../../../redux/methods/products';

import ArrowsBetweenStrings from '../../../../components/ArrowsBetweenStrings';
import Modal from '../../../../components/modals/Modal';

import { Button, UncontrolledTooltip, Badge } from 'reactstrap';

const propTypes = {
    updateProductCategoryForFeatures: PropTypes.func,
};

const AssignedCategoriesSection = ({ assignCategories, updateProductCategoryForFeatures }) => {
    const intl = useIntl();
    const translations = intl.messages.products.categoryAssign.categoriesSection;

    const { categories, categoryForAttributesId } = assignCategories;
    const [isOpen, setIsOpen] = useState(false);
    const [idToSend, setIdTosend] = useState(null);

    const handleClick = id => () => {
        setIdTosend(id);
        setIsOpen(true);
    };

    const handleChange = () => {
        updateProductCategoryForFeatures({ categoryForAttributesId: idToSend, productId: assignCategories.id });
    };

    return (
        <React.Fragment>
            <p className='mb-3'>{translations.title}</p>
            {
                categories ? categories.map(category => {
                    const isCategoryForFeature = categoryForAttributesId === category.categoryId;
                    return (
                        <React.Fragment key={category.categoryId}>
                            <UncontrolledTooltip placement="top" target={`categoriesButton-${category.categoryId}`}>
                                <ArrowsBetweenStrings arrayOfStrings={category.categories} />
                            </UncontrolledTooltip>
                            <p id={`categoriesButton-${category.categoryId}`}>
                                {isCategoryForFeature && <Badge color='primary'>{translations.categoryForFeatures}</Badge>}
                                <Button
                                    color={isCategoryForFeature ? 'primary' : 'secondary'}
                                    onClick={handleClick(category.categoryId)}
                                    disabled={isCategoryForFeature}
                                    className='w-100'
                                >
                                    {category.categories[0][0].value}
                                </Button>
                            </p>
                        </React.Fragment>
                    );
                }) : <small>{translations.empty}</small>
            }
            <Modal
                header={translations.changeModal.header}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                body={<p>{translations.changeModal.body}</p>}
                buttons={{
                    cancel: {
                        label: translations.changeModal.cancel,
                    },
                    confirm: {
                        label: translations.changeModal.confirm,
                        color: 'success'
                    }
                }}
                onConfirm={handleChange}
            />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    assignCategories: state.products.categoryAssigner.assignCategories,
});

const mapDispatch = {
    updateProductCategoryForFeatures,
};

AssignedCategoriesSection.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatch)(AssignedCategoriesSection);