const currencies = {
    view: {
        title: 'Currencies list',
        actions: {
            addCurrency: 'ADD CURRENCY',
        },
        buttons: {
            activate: 'Aktywuj',
            deactivate: 'Deaktywuj'
        },
        statuses: {
            erp: '[EN] ERP',
            shop: '[EN] Sklep'
        },
        headerLabels: {
            isFromErp: '[EN] Pochodzenie',
            name: 'Name',
            shortName: 'Abbrevation',
            isActive: 'Status',
            regions: 'Regions',
            actions: 'Actions',
        },
        emptyPlaceholders: {
            name: '[NO NAME]',
            shortName: '[NO ABBREVATION]',
            table: 'No currencies have been found',
            regions: '[NO REGIONS]',
        },
        currencyIsActiveText:{
            active: 'Active',
            inactive: 'Inactive',
        },
        tooltips: {
            edit: 'Edit currency',
            remove: 'Remove currency',
        },
        deleteModal: {
            header: 'Currency removal',
            body: 'Are you sure that you want to remove currency?',
            cancel: 'CANCEL',
            confirm: 'REMOVE',
        },
        errorModalText: 'Error occured while trying to remove currency'
    },
    edit: {
        tabSelectorTitle: 'Sections',
        tabs: {
            basic: {
                title: 'Basic data',
                labels: {
                    name: 'Name',
                    shortName: 'Short name',
                },
                dropdown: {
                    label: 'Currency status:',
                    active: 'Active',
                    inactive: 'Inactive',
                },
                showSymbol: {
                    main: 'Symbol',
                    true: 'Pokazuj symbol',
                    false: 'Pokazuj skróconą nazwę',
                },
                tooltip: 'W przypadku braku symbolu waluty, pokazana będzie skrocona nazwa',
            },
            regions: {
                title: 'Regions',
                sectionButtons: {
                    add: 'ASSIGN REGION',
                    remove: 'UNASSIGN REGION'
                },
                assignModal: {
                    header: 'Region assigning',
                    body: 'Select region to assign: ',
                    cancel: 'CANCEL',
                    confirm: 'ASSIGN',
                },
                deleteManyModal: {
                    header: 'Regions unassigning',
                    body: 'Are you sure that you want to unassign selected regions from currency?',
                },
                tables: {
                    deleteModal: {
                        header: 'Region unassigning',
                        body: 'Are you sure that you want to unassign selected region from currency?',
                        cancel: 'CANCEL',
                        confirm: 'UNASSIGN'
                    },
                    headers: {
                        name: 'Name',
                        actions: 'Actions',
                    },
                    tooltip: 'Unassign region',
                    fillers: {
                        name: '[NO NAME]',
                        table: 'No regions to display'
                    }
                }
            }
        }
    }
};

export default currencies;