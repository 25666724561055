const storeLocale = {
    languageDropdownSelector: {
        label: 'Store language:'
    },
};

export const languages = {
    Pl: 'Polish',
    En: 'English',
    Ru: 'Russian',
    Ua: 'Ukrainian'
};

export default storeLocale;