import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { handleCheckboxAction, generateNonIdsOptionsForDropdown } from '../../../utils/components';
import useFetch from '../../../hooks/useFetch';
import { getAllTagAttributes, getManufacturerFields, getListPresentation, updateListPresentation, getProductPresentationCart, updateProductPresentationCart } from '../../../redux/methods/products';
import { itemCountOptions } from '../../../config/_productListPresentation';

import { Container, Card, CardBody, CardHeader, Form, Button, TabContent } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import styles from './index.module.scss';

import ProductListDeviceSettings from '../../../features/products/ProductListDeviceSettings';

import TabulatorNav from '../../../components/TabulatorNav';

const propTypes = {
    getListPresentation: PropTypes.func,
    updateListPresentation: PropTypes.func,
    getProductAddtionalFields: PropTypes.func,
    getManufacturerFields: PropTypes.func,
    getProductPresentationCart: PropTypes.func,
    updateProductPresentationCart: PropTypes.func
};

const List = ({
    getListPresentation,
    updateListPresentation,
    getAllTagAttributes,
    getManufacturerFields,
    getProductPresentationCart,
    updateProductPresentationCart
}) => {
    const intl = useIntl();
    const translations = intl.messages.list.settings;
    const translationsAdditionalFields = intl.messages.card.view;

    const sortByOrder = (device, fields) => fields.sort((a, b) => (a.parameters.list[device].order < b.parameters.list[device].order) ? -1 : 1);

    const [ additionalFields ] = useFetch(getProductPresentationCart, 'list', []);
    const [ listPresentation ] = useFetch(getListPresentation);
    const [ allTagAttributes ] = useFetch(getAllTagAttributes);
    const [ manufacturerFields ] = useFetch(getManufacturerFields);

    const [ activeTab, setActiveTab ] = useState(0);

    const [ additionalFieldsToEditDesktop, setAdditionalFieldsToEditDesktop ] = useState([]);
    const [ selectedAdditionalFieldIdsDesktop, setSelectedAdditionalFieldIdsDesktop ] = useState([]);
    const [ selectedAdditionalField2IdsDesktop, setSelectedAdditionalField2IdsDesktop ] = useState([]);
    const [ selectedManufacturerFieldIdsDesktop, setSelectedManufacturerFieldIdsDesktop ] = useState([]);

    const [ additionalFieldsToEditTablet, setAdditionalFieldsToEditTablet ] = useState([]);
    const [ selectedAdditionalFieldIdsTablet, setSelectedAdditionalFieldIdsTablet ] = useState([]);
    const [ selectedAdditionalField2IdsTablet, setSelectedAdditionalField2IdsTablet ] = useState([]);
    const [ selectedManufacturerFieldIdsTablet, setSelectedManufacturerFieldIdsTablet ] = useState([]);

    const [ additionalFieldsToEditMobile, setAdditionalFieldsToEditMobile ] = useState([]);
    const [ selectedAdditionalFieldIdsMobile, setSelectedAdditionalFieldIdsMobile ] = useState([]);
    const [ selectedAdditionalField2IdsMobile, setSelectedAdditionalField2IdsMobile ] = useState([]);
    const [ selectedManufacturerFieldIdsMobile, setSelectedManufacturerFieldIdsMobile ] = useState([]);

    const handleOnSubmit = formData => {
        const updateObject = {
            desktop: {
                ...formData.desktop,
                additionalField: selectedAdditionalFieldIdsDesktop,
                additionalField2: selectedAdditionalField2IdsDesktop,
                manufacturerField: selectedManufacturerFieldIdsDesktop
            },
            tablet: {
                ...formData.tablet,
                additionalField: selectedAdditionalFieldIdsTablet,
                additionalField2: selectedAdditionalField2IdsTablet,
                manufacturerField: selectedManufacturerFieldIdsTablet
            },
            mobile: {
                ...formData.mobile,
                additionalField: selectedAdditionalFieldIdsMobile,
                additionalField2: selectedAdditionalField2IdsMobile,
                manufacturerField: selectedManufacturerFieldIdsMobile
            }
        };

        updateListPresentation(updateObject);

        const extractListParams = (tag, device, list) => {
            const entry = list.find(p => p.tag === tag);
            return {
                show: entry.parameters.list[device].show,
                order: list.indexOf(entry)
            };
        };

        const generatedArray = additionalFieldsToEditDesktop.map(param => ({
            ...param,
            parameters: {
                ...param.parameters,
                list: {
                    desktop: extractListParams(param.tag, 'desktop', additionalFieldsToEditDesktop),
                    tablet: extractListParams(param.tag, 'tablet', additionalFieldsToEditTablet),
                    mobile: extractListParams(param.tag, 'mobile', additionalFieldsToEditMobile)
                }
            }
        }));

        updateProductPresentationCart({
            updateMain: false,
            updateTab: false,
            updateList: true,
            updateTile: false,
            updateComparison: false,
            fields: generatedArray
        });
    };

    const handleAdditionalFieldsSelectionDesktop = id => handleCheckboxAction(id, selectedAdditionalFieldIdsDesktop, setSelectedAdditionalFieldIdsDesktop);
    const handleAdditionalFieldsSelectionTablet = id =>  handleCheckboxAction(id, selectedAdditionalFieldIdsTablet, setSelectedAdditionalFieldIdsTablet);
    const handleAdditionalFieldsSelectionMobile = id =>  handleCheckboxAction(id, selectedAdditionalFieldIdsMobile, setSelectedAdditionalFieldIdsMobile);

    const handleAdditionalFields2SelectionDesktop = id => handleCheckboxAction(id, selectedAdditionalField2IdsDesktop, setSelectedAdditionalField2IdsDesktop);
    const handleAdditionalFields2SelectionTablet = id =>  handleCheckboxAction(id, selectedAdditionalField2IdsTablet, setSelectedAdditionalField2IdsTablet);
    const handleAdditionalFields2SelectionMobile = id =>  handleCheckboxAction(id, selectedAdditionalField2IdsMobile, setSelectedAdditionalField2IdsMobile);

    const handleManufacturerFieldSelectionDesktop = id => handleCheckboxAction(id, selectedManufacturerFieldIdsDesktop, setSelectedManufacturerFieldIdsDesktop);
    const handleManufacturerFieldSelectionTablet = id => handleCheckboxAction(id, selectedManufacturerFieldIdsTablet, setSelectedManufacturerFieldIdsTablet);
    const handleManufacturerFieldSelectionMobile = id => handleCheckboxAction(id, selectedManufacturerFieldIdsMobile, setSelectedManufacturerFieldIdsMobile);

    useEffect(() => {
        if(listPresentation) {
            if(listPresentation.desktop && listPresentation.desktop.manufacturerField) {
                setSelectedAdditionalFieldIdsDesktop(listPresentation.desktop.additionalField);
                setSelectedAdditionalField2IdsDesktop(listPresentation.desktop.additionalField2);
                setSelectedManufacturerFieldIdsDesktop(listPresentation.desktop.manufacturerField);
            }
            if(listPresentation.tablet && listPresentation.tablet.manufacturerField) {
                setSelectedAdditionalFieldIdsTablet(listPresentation.tablet.additionalField);
                setSelectedAdditionalField2IdsTablet(listPresentation.tablet.additionalField2);
                setSelectedManufacturerFieldIdsTablet(listPresentation.tablet.manufacturerField);
            }
            if(listPresentation.mobile && listPresentation.mobile.manufacturerField) {
                setSelectedAdditionalFieldIdsMobile(listPresentation.mobile.additionalField);
                setSelectedAdditionalField2IdsMobile(listPresentation.mobile.additionalField2);
                setSelectedManufacturerFieldIdsMobile(listPresentation.mobile.manufacturerField);
            }
        }
    }, [listPresentation]);

    useEffect(() => {
        if(additionalFields){
            const { fields } = additionalFields;

            setAdditionalFieldsToEditDesktop(sortByOrder('desktop', [...(JSON.parse(JSON.stringify(fields)))]));
            setAdditionalFieldsToEditTablet(sortByOrder('tablet', [...(JSON.parse(JSON.stringify(fields)))]));
            setAdditionalFieldsToEditMobile(sortByOrder('mobile', [...(JSON.parse(JSON.stringify(fields)))]));
        }

        return () => {
            setAdditionalFieldsToEditDesktop([]);
            setAdditionalFieldsToEditTablet([]);
            setAdditionalFieldsToEditMobile([]);
        };
    }, [additionalFields]);

    const generatedItemCountOptions = itemCountOptions && generateNonIdsOptionsForDropdown(itemCountOptions);

    const allTagAttributesOptions = allTagAttributes && allTagAttributes.attributes && [ ...Object.keys(allTagAttributes.attributes).map(key => ({
        id: key,
        label: allTagAttributes.attributes[key]
    }))];

    const allTagAttributes2Options = allTagAttributes && allTagAttributes.features && [ ...Object.keys(allTagAttributes.features).map(key => ({
        id: key,
        label: allTagAttributes.features[key]
    }))];

    const additionalFieldSelectionLabel = selectedAdditionalFieldIds => selectedAdditionalFieldIds.length === 0
    ? '-- brak --'
    : allTagAttributesOptions
        .filter(m => selectedAdditionalFieldIds.includes(m.id))
        .map(m => m.label)
        .reduce((prev,curr) => prev + (prev ? ', ' : '') + curr, '');

    const additionalField2SelectionLabel = selectedAdditionalField2Ids => selectedAdditionalField2Ids.length === 0
    ? '-- brak --'
    : allTagAttributes2Options
        .filter(m => selectedAdditionalField2Ids.includes(m.id))
        .map(m => m.label)
        .reduce((prev,curr) => prev + (prev ? ', ' : '') + curr, '');

    const manufacturerSelectionLabel = selectedManufacturerFieldIds => selectedManufacturerFieldIds.length === 0
        ? '-- brak --'
        : manufacturerFields
            .filter(m => selectedManufacturerFieldIds.includes(m))
            .map(m => translations.manufacturerFields[m])
            .reduce((prev,curr) => prev + (prev ? ', ' : '') + curr, '');

    const count = itemCount => generatedItemCountOptions.find(item => item.id === itemCount);

    return listPresentation && allTagAttributes && manufacturerFields && additionalFieldsToEditDesktop  && additionalFieldsToEditTablet  && additionalFieldsToEditMobile ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Card>
                    <CardHeader>
                        <i className={'cil-list'}/>
                        <span>{translations.header}</span>
                    </CardHeader>
                    <CardBody>
                        <FinalForm
                            keepDirtyOnReinitialize
                            initialValues={{
                                desktop: { ...listPresentation.desktop },
                                tablet: { ...listPresentation.tablet },
                                mobile: { ...listPresentation.mobile },
                            }}
                            onSubmit={handleOnSubmit}
                            render={({ handleSubmit, form }) => {
                                const { values } = form.getState();

                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <TabulatorNav
                                            setActiveTab={setActiveTab}
                                            activeTab={activeTab}
                                            tabsList={[
                                                {iso: 0, label: 'Desktop'},
                                                {iso: 1, label: 'Tablet'},
                                                {iso: 2, label: 'Mobile'},
                                            ]}
                                            withoutFlag={true}
                                        />
                                        <TabContent>
                                            <div style={{padding: '10px', display: activeTab === 0 ? 'block' : 'none'}}>
                                                <ProductListDeviceSettings
                                                    prefix="desktop"
                                                    translations={translations}
                                                    translationsAdditionalFields={translationsAdditionalFields}
                                                    manufacturerFields={manufacturerFields}

                                                    selectedAdditionalFieldIds={selectedAdditionalFieldIdsDesktop}
                                                    additionalFieldSelectionLabel={additionalFieldSelectionLabel(selectedAdditionalFieldIdsDesktop)}
                                                    handleAdditionalFieldsSelection={handleAdditionalFieldsSelectionDesktop}

                                                    selectedAdditionalField2Ids={selectedAdditionalField2IdsDesktop}
                                                    additionalField2SelectionLabel={additionalField2SelectionLabel(selectedAdditionalField2IdsDesktop)}
                                                    handleAdditionalFields2Selection={handleAdditionalFields2SelectionDesktop}

                                                    selectedManufacturerFieldIds={selectedManufacturerFieldIdsDesktop}
                                                    manufacturerSelectionLabel={manufacturerSelectionLabel(selectedManufacturerFieldIdsDesktop)}
                                                    handleManufacturerFieldSelection={handleManufacturerFieldSelectionDesktop}

                                                    count={count(values.desktop.itemCount)}
                                                    generatedItemCountOptions={generatedItemCountOptions}
                                                    allTagAttributesOptions={allTagAttributesOptions}
                                                    allTagAttributes2Options={allTagAttributes2Options}
                                                    additionalFieldsToEdit={additionalFieldsToEditDesktop}
                                                    setAdditionalFieldsToEdit={setAdditionalFieldsToEditDesktop}
                                                    values={values}
                                                />
                                            </div>
                                            <div style={{padding: '10px', display: activeTab === 1 ? 'block' : 'none'}}>
                                                <ProductListDeviceSettings
                                                    prefix="tablet"
                                                    translations={translations}
                                                    translationsAdditionalFields={translationsAdditionalFields}
                                                    manufacturerFields={manufacturerFields}

                                                    selectedAdditionalFieldIds={selectedAdditionalFieldIdsTablet}
                                                    additionalFieldSelectionLabel={additionalFieldSelectionLabel(selectedAdditionalFieldIdsTablet)}
                                                    handleAdditionalFieldsSelection={handleAdditionalFieldsSelectionTablet}

                                                    selectedAdditionalField2Ids={selectedAdditionalField2IdsTablet}
                                                    additionalField2SelectionLabel={additionalField2SelectionLabel(selectedAdditionalField2IdsTablet)}
                                                    handleAdditionalFields2Selection={handleAdditionalFields2SelectionTablet}

                                                    selectedManufacturerFieldIds={selectedManufacturerFieldIdsTablet}
                                                    manufacturerSelectionLabel={manufacturerSelectionLabel(selectedManufacturerFieldIdsTablet)}
                                                    handleManufacturerFieldSelection={handleManufacturerFieldSelectionTablet}

                                                    count={count(values.tablet.itemCount)}
                                                    generatedItemCountOptions={generatedItemCountOptions}
                                                    allTagAttributesOptions={allTagAttributesOptions}
                                                    allTagAttributes2Options={allTagAttributes2Options}
                                                    additionalFieldsToEdit={additionalFieldsToEditTablet}
                                                    setAdditionalFieldsToEdit={setAdditionalFieldsToEditTablet}
                                                    values={values}
                                                />
                                            </div>
                                            <div style={{padding: '10px', display: activeTab === 2 ? 'block' : 'none'}}>
                                                <ProductListDeviceSettings
                                                    prefix="mobile"
                                                    translations={translations}
                                                    translationsAdditionalFields={translationsAdditionalFields}
                                                    manufacturerFields={manufacturerFields}

                                                    selectedAdditionalFieldIds={selectedAdditionalFieldIdsMobile}
                                                    additionalFieldSelectionLabel={additionalFieldSelectionLabel(selectedAdditionalFieldIdsMobile)}
                                                    handleAdditionalFieldsSelection={handleAdditionalFieldsSelectionMobile}

                                                    selectedAdditionalField2Ids={selectedAdditionalField2IdsMobile}
                                                    additionalField2SelectionLabel={additionalField2SelectionLabel(selectedAdditionalField2IdsMobile)}
                                                    handleAdditionalFields2Selection={handleAdditionalFields2SelectionMobile}

                                                    selectedManufacturerFieldIds={selectedManufacturerFieldIdsMobile}
                                                    manufacturerSelectionLabel={manufacturerSelectionLabel(selectedManufacturerFieldIdsMobile)}
                                                    handleManufacturerFieldSelection={handleManufacturerFieldSelectionMobile}

                                                    count={count(values.mobile.itemCount)}
                                                    generatedItemCountOptions={generatedItemCountOptions}
                                                    allTagAttributesOptions={allTagAttributesOptions}
                                                    allTagAttributes2Options={allTagAttributes2Options}
                                                    additionalFieldsToEdit={additionalFieldsToEditMobile}
                                                    setAdditionalFieldsToEdit={setAdditionalFieldsToEditMobile}
                                                    values={values}
                                                />
                                            </div>
                                        </TabContent>

                                        <aside className={styles.footer}>
                                            <Button color='success' onClick={form.submit}>
                                                <i className="fa fa-save mr-2"/>
                                                <span>{translations.button.save}</span>
                                            </Button>
                                        </aside>

                                    </Form>
                                );
                            }}
                        />
                    </CardBody>
                </Card>
            </div>
        </Container>
    ) : null;
};

List.propTypes = propTypes;

const mapDispatch = {
    getListPresentation,
    updateListPresentation,
    getAllTagAttributes,
    getManufacturerFields,
    getProductPresentationCart,
    updateProductPresentationCart
};

export default connect(null, mapDispatch)(List);