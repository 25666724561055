import React from 'react';
import PropTypes from 'prop-types';

import { generateEditLinkForButton } from '../../../utils/visualSettings';

import ButtonPreview from '../../ButtonPreview';
import TableGenerator from '../../generators/TableGenerator';

const propTypes = {
    buttons: PropTypes.array,
    headerLabels: PropTypes.object,
    translations: PropTypes.object,
    deleteAction: PropTypes.func,
    history: PropTypes.object,
};

const ButtonsTable = ({
    buttons,
    headerLabels,
    translations,
    deleteAction,
    history,
}) => {
    const PreviewCell = (item) => {
        return (
            <ButtonPreview button={item.style} previewTranslation={translations.inputs.previewButtonTranslation} />
        );
    };

    const handleDefaultAction = (id) => () => {
        history.push(generateEditLinkForButton(id));
    };

    return (
        <TableGenerator
            defaultAction={handleDefaultAction}
            expectedActions={['delete', 'edit']}
            actions={{deleteAction}}
            editLink={generateEditLinkForButton}
            items={buttons}
            translations={translations}
            columns={[
                {
                    header: headerLabels.name,
                    value: 'name',
                },
                {
                    header: headerLabels.preview,
                    type: 'custom',
                    customCell: PreviewCell,
                },
                {
                    header: headerLabels.actions,
                    type: 'actions'
                }
            ]}
        />
    );
};

ButtonsTable.propTypes = propTypes;

export default ButtonsTable;