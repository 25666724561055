import React from 'react';
import PropTypes from 'prop-types';

import Sorter from '../../../../tables/Sorter';

const propTypes = {
    sortAction: PropTypes.func,
    columnSortedByDescendingOrder: PropTypes.string,
    header: PropTypes.string,
    className: PropTypes.string,
    sortValue: PropTypes.string,
};

const SortableCellHeader = ({
    sortAction,
    columnSortedByDescendingOrder,
    sortValue,
    header,
    className
}) => {
    return (
        <div className={className}>
            <span>{header}</span>
            <Sorter
                onClickAction={sortAction(sortValue)}
                isCellSortedByDescendingOrder={columnSortedByDescendingOrder === sortValue}
            />
        </div>
    );
};

SortableCellHeader.propTypes = propTypes;

export default SortableCellHeader;