const card = {
    view: {
        title: '[EN] Prezentacja artykułów > Karta artykułu',
        actions: {
            save: '[EN] ZAPISZ ZMIANY',
        },
        headerLabels: {
            isVisible: '[EN] Widoczny',
            name: '[EN] Nazwa parametru',
        },
        tabs: {
            settings: '[EN] Ustawienia',
            tabs: '[EN] Zakładki',
        },
        table: {
            columnNames: {
                order: '[EN] Kolejność',
                name: '[EN] Nazwa',
                active: '[EN] Aktywna',
                default: {
                    label: '[EN] Domyślna',
                    tooltip: '[EN] Jako domyślną można ustawić przewidzianą, aktywną zakładkę'
                }
            },
            labels: {
                availability: '[EN] Dostępność',
                catalogueindex: '[EN] Indeks katalogowy',
                category: '[EN] Kategoria',
                cncode: '[EN] Kod CN',
                country: '[EN] Kraj pochodzenia',
                distinguisher: '[EN] Wyróżnik',
                ean: '[EN] Kod kreskowy',
                id: '[EN] Identyfikator',
                kgo: '[EN] KGO',
                localisation: '[EN] Lokalizacja',
                manufacturerLogo: '[EN] Logo',
                manufacturerName: '[EN] Producent',
                name: '[EN] Nazwa',
                pkwiu: '[EN] PKWiU',
                quantity: '[EN] Ilość',
                swwku: '[EN] SWW/KU',
                tax: '[EN] Podatek',
                tradeindex: '[EN] Indeks handlowy',
                weight: '[EN] Waga',
                measure: '[EN] Jednostka',
                manufacturerindex: '[EN] Indeks producenta',
                originalname: '[EN] Nazwa oryginalna',
                externalid: '[EN] Zewnętrzne id',
            }
        }
    },
};

export default card;