import React from 'react';
import PropTypes from 'prop-types';

import TableGenerator from '../../generators/TableGenerator';

import { stringOrNumberPropTypes, countryPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    countries: PropTypes.arrayOf(countryPropTypes),
    translations: PropTypes.shape({
        deleteModal: PropTypes.shape({
            header: PropTypes.string,
            body: PropTypes.string,
            confirm: PropTypes.string,
            cancel: PropTypes.string,
        }).isRequired,
        tooltips: {
            remove: PropTypes.string,
        },
        emptyTable: PropTypes.string
    }),
    headerLabels: PropTypes.shape({
        name: PropTypes.string,
        iso: PropTypes.string,
        actions: PropTypes.string,
    }).isRequired,
    removeAction: PropTypes.func,
    checkboxAction: PropTypes.func,
    checkedCheckboxList: PropTypes.arrayOf(stringOrNumberPropTypes).isRequired,
    emptyIsoPlaceholder: PropTypes.string,
    emptyNamePlaceholder: PropTypes.string,
    isRedOnHover: PropTypes.bool,
};

const CountriesRegionTable = ({
    countries,
    headerLabels,
    removeAction,
    setCheckedCountriesIds,
    checkedCheckboxList,
    translations,
    emptyIsoPlaceholder,
    emptyNamePlaceholder,
    isRedOnHover,
}) => {
    const deleteAction = id => () => {
        removeAction(id);
    };

    return (
        <TableGenerator
            items={countries}
            expectedActions={['delete']}
            actions={{deleteAction}}
            setCheckedItemsIds={setCheckedCountriesIds}
            checkedItemsIds={checkedCheckboxList}
            translations={translations}
            additionalRowStyle={isRedOnHover && styles.redBackground}
            columns={[
                {
                    type: 'checkbox'
                },
                {
                    header: headerLabels.name,
                    value: 'name',
                    filler: emptyNamePlaceholder
                },
                {
                    header: headerLabels.iso,
                    value: 'iso',
                    filler: emptyIsoPlaceholder
                },
                {
                    header: headerLabels.actions,
                    type: 'actions',
                    keyToPass: 'countries'
                }
            ]}
        />
    );
};

CountriesRegionTable.propTypes = propTypes;

export default CountriesRegionTable;