import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteMenuImage } from '../../../../redux/methods/menu';

import FileSender from '../../../../components/FileSender';

import { stringOrNumberPropTypes } from '../../../../propTypes';
import { standardImageExtensions } from '../../../../config';

const propTypes = {
    deleteMenuImage: PropTypes.func,
    translations: PropTypes.object,
    menuId: stringOrNumberPropTypes
};

const FileSenderMenu = ({
    deleteMenuImage,
    translations,
    menuId,
    setFile,
    image
}) => {
    const handleRemove = () => {
        deleteMenuImage(menuId);
    };

    return (
        <FileSender
            fileKey='Image'
            validateFormats={standardImageExtensions}
            handleRemove={handleRemove}
            withPreview
            buttons={translations.inputs.file}
            photo={image}
            setFile={setFile}
        />
    );
};

FileSenderMenu.propTypes = propTypes;

const mapDispatch = {
    deleteMenuImage
};

export default connect(null, mapDispatch)(FileSenderMenu);