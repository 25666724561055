import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { uploadImage, deleteProductImages, changeProductPhotoOrder, changeProductPhotoFlag, getProductImages } from "../../../../redux/methods/products";
import { selectStoreAvailableIsoList, selectStoreCurrentLanguage } from "../../../../redux/selectors/storeLocale";
import { generateTranslatedProductPhotoList } from "../../../../utils/products";
import useFetch from "../../../../hooks/useFetch";

import { Container, Button } from "reactstrap";

import ProductPhotoTable from "../../../../components/tables/ProductPhotoTable";
import EditImageModal from "./EditImageModal";

import { photoWithTranslatedAlternativeTextPropTypes, stringOrNumberPropTypes } from "../../../../propTypes";

import { withFormUtils } from '../../../../hoc/withFormUtils';

import styles from "./index.module.scss";

const propTypes = {
    photos: PropTypes.arrayOf(photoWithTranslatedAlternativeTextPropTypes),
    storeCurrentLanguage: PropTypes.string,
    productId: stringOrNumberPropTypes,
    uploadImage: PropTypes.func,
    deleteProductImages: PropTypes.func,
    changeProductPhotoOrder: PropTypes.func,
    changeProductPhotoFlag: PropTypes.func,
    getProductImages: PropTypes.func,
    forceRefetch: PropTypes.bool,
    isConnectedToERP: PropTypes.bool
};

const ProductEditImagesSection = ({
    utils,
    productId, uploadImage, deleteProductImages, storeCurrentLanguage,
    changeProductPhotoOrder, changeProductPhotoFlag, getProductImages, forceRefetch,
    isConnectedToERP
}) => {
    const [ photos ] = useFetch(getProductImages, productId, [], forceRefetch);
    const translatedPhotos = photos && generateTranslatedProductPhotoList(photos.productImages, storeCurrentLanguage);

    const defaultImageId = photos && photos.productImages.length > 0 && photos.productImages.find(photo => photo.isDefault)?.productImageId;
    const defaultDoubleImageId = photos && photos.productImages.length > 0 && photos.productImages.find(photo => photo.isDefaultDouble)?.productImageId;
    const [checkedIdList, setCheckedIdList] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [imageId, setImageId] = useState(null);
    const fileInputRef = React.createRef(null);

    useEffect(() => {
        setCheckedIdList([]);
    }, [photos]);

    useEffect(() => {
        if(fileInputRef && fileInputRef.current && fileInputRef.current.value){
            fileInputRef.current.value = null;
        }
    }, [fileInputRef]);

    const intl = useIntl();
    const translations = intl.messages.products.edit.tabs.photos;

    const deleteMultipleImages = () => {
        if (checkedIdList.length === 0) {
            return;
        }
        utils.confirm(
            () => deleteProductImages(productId, checkedIdList),
            translations.deleteModalTranslations.header,
            translations.deleteModalTranslations.body
        );
    };

    const handleCheckboxAction = id => {
        setCheckedIdList(checkedIdList.includes(id) ? checkedIdList.filter(item => item !== id) : [...checkedIdList, id]);
    };

    const handleRemoveAction = toRemoveId => {
        deleteProductImages(productId, [toRemoveId]);
    };

    const handleOnFileSendClick = () => {
        fileInputRef.current.click();
    };

    const handleFileUpload = e => {
        const data = new FormData();
        data.append("productId", productId);
        data.append("image", e.target.files[0]);
        uploadImage(data);
    };

    const showEditModal = id => {
        setImageId(id);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setImageId(null);
        setIsEditModalOpen(false);
    };

    const handleChangeDefault = (imageId, type = 'isDefault') => {
        changeProductPhotoFlag(productId, imageId, type, true);
    };

    const handleChangeProductPhotoOrder = (imageId, newIndex) => {
        changeProductPhotoOrder(productId, imageId, newIndex);
    };

    const handleChangeProductPhotoVisibility = (imageId, isVisible) => {
        changeProductPhotoFlag(productId, imageId, "isVisible", isVisible);
    };

    const isPhotosListPresent = photos && photos.productImages.length > 0;

    return photos ? (
        <Container fluid>
            <ProductPhotoTable
                photos={translatedPhotos}
                checkedCheckboxList={checkedIdList}
                isChangingDefaultAllowed={photos.canChangeDefault}
                selectedDefaultPhotoId={defaultImageId || ""}
                selectedDefaultDoublePhotoId={defaultDoubleImageId || ""}
                /*
                * TODO: change showDetail action
                */
                showDetailsAction={() => console.info("show")}
                checkboxAction={handleCheckboxAction}
                changeDefault={handleChangeDefault}
                removeAction={handleRemoveAction}
                editAction={showEditModal}
                defaultAlternativeTextForPhoto={translations.emptyAlternativeText}
                tooltipTranslations={translations.tooltipTranslations}
                deleteModalTranslations={translations.deleteSingleModalTranslations}
                columnNames={translations.columnNames}
                isDefaultText={translations.isDefaultText}
                storeCurrentLanguage={storeCurrentLanguage}
                emptyTranslation={translations.emptyTranslation}
                defaultAlternativeTextForPhotoName={translations.emptyAlternativeName}
                changeProductPhotoOrder={handleChangeProductPhotoOrder}
                changeProductPhotoVisibility={handleChangeProductPhotoVisibility}
                imageAlignmentTranslations={translations.imageAlignmentTranslations}
                alignmentText={translations.alignmentText}
                isConnectedToERP={isConnectedToERP}
                isExternalTranslations={translations.isExternalTranslations}
            />
            <aside className={clsx(styles.footer, !isPhotosListPresent && styles.footerAddButton)}>
                {isPhotosListPresent &&
                    <Button
                        outline
                        type="button"
                        onClick={deleteMultipleImages}
                        color="danger"
                        disabled={!(checkedIdList.length > 0)}
                    >
                        <i className="fa fa-remove mr-2"/>
                        <span>{translations.deletePhotoButton}</span>
                    </Button>
                }
                <div>
                    <input type="file" id="button-upload" className="d-none" ref={fileInputRef} onInput={handleFileUpload} />
                    <label htmlFor="button-upload" className={clsx("d-flex justify-content-center")}>
                        <Button color="primary" onClick={handleOnFileSendClick}>
                            <i className="fa fa-plus mr-2"/>
                            <span>{translations.addPhotoButton}</span>
                        </Button>
                    </label>
                </div>
            </aside>
            {isPhotosListPresent &&
                <React.Fragment>
                    {imageId &&
                        <EditImageModal
                            productId={productId}
                            imageId={imageId}
                            isOpen={isEditModalOpen}
                            closeEditModal={closeEditModal}
                        />
                    }
                </React.Fragment>
            }
        </Container>
    ) : null;
};

ProductEditImagesSection.propTypes = propTypes;

const mapStateToProps = (state) => ({
    storeAvailableIsoList: selectStoreAvailableIsoList(state),
    storeCurrentLanguage: selectStoreCurrentLanguage(state),
    forceRefetch: state.refetchers.productsImages,
    isConnectedToERP: state.appInit.isConnectedToERP
});

const mapDispatch = {
    uploadImage,
    deleteProductImages,
    changeProductPhotoOrder,
    changeProductPhotoFlag,
    getProductImages
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(ProductEditImagesSection));